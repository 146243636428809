import React from "react";
import { useTheme } from "styled-components";
export const VendorTerms = () => {
  const theme = useTheme();
  return (
    <div className="WordSection1">
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <b>
          <span style={{ fontSize: "18.0pt" }}>Vendor Terms of Service</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <b>
          <span style={{ fontSize: "14.5pt" }}>Effective: </span>
        </b>
        <b>
          <span style={{ fontSize: "14.5pt" }}>
            January 1<sup>st</sup>, 2017
          </span>
        </b>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          These Vendor Terms of Service (the&nbsp;<b>“Vendor Terms”</b>)
          describe your rights and responsibilities when using our online
          license management tool (the&nbsp;<b>“Services”</b>) to license your
          software products (“<b>Products</b>”). Please read them carefully. If
          you are a Vendor (defined below), these Vendor Terms govern your
          access and use of our Services. If you are being invited to manage
          licenses for software you purchased from a Vendor, the&nbsp;
          <a href="elm - Customer Terms of Service.htm">
            Customer Terms of Service
          </a>
          &nbsp;(the&nbsp;<b>“Customer Terms”</b>) govern your access and use of
          the Services. We are grateful you’re here.
        </span>
      </p>
      <h1>First Things First</h1>
      <h2>These “Vendor Terms” Form a Part of a Binding “Contract”</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          These Vendor Terms and any Order Form(s) (defined below) together form
          a binding&nbsp;<b>“Contract” </b>between Vendor and us. If any terms
          in the&nbsp;Vendor-Specific Supplement&nbsp;apply to Vendor (e.g., if
          Vendor is a U.S. government entity), those terms are also incorporated
          herein by reference and form part of the Contract. “We,” “our” and
          “us” currently refer to evoleap, LLC.
        </span>
      </p>
      <h2>Your Agreement On Behalf of “Vendor”</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          If you purchase subscription(s), or use our Services please make sure
          you have the necessary authority to enter into the Contract on behalf
          of Vendor before proceeding.
        </span>
      </p>
      <h1>Vendor Choices and Instructions</h1>
      <h2>Who is “Vendor”? </h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <b>
          <span style={{ fontSize: "14.5pt" }}>“Vendor”</span>
        </b>
        <span style={{ fontSize: "14.5pt" }}>
          &nbsp;is the organization that you represent in agreeing to the
          Contract. If you are someone who is not formally affiliated with an
          organization, Vendor is the individual creating the software vendor
          account. For example, if you signed up using a personal email address
          and invited a couple of friends to administer the software vendor
          account but haven't formed a company yet, you are the Vendor.
        </span>
      </p>
      <h2>Signing Up Using a Corporate Email Domain</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          If you signed up for a plan using your corporate email domain, your
          organization is Vendor, and Vendor can modify and re-assign roles of
          your account (including your role) and otherwise exercise its rights
          under the Contract. If Vendor elects to replace you as the
          representative with ultimate authority, we will provide you with
          notice following such election and you agree to take any actions
          reasonably requested by us or Vendor to facilitate the transfer of
          authority to a new representative of Vendor.
        </span>
      </p>
      <h2>What This Means for Vendor—and for Us</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Individuals authorized by Vendor to access the Services (an&nbsp;
          <b>“Authorized User”</b>) may submit content or information to the
          Services, such as software product identification keys and license
          keys. Additionally, your use of our API will provide additional
          information such as user and hardware information (collectively{" "}
          <b>“Vendor Data”</b>). Vendor may exclusively provide us with
          instructions on what to do with it. For example, Vendor may provision
          or deprovision access to the Services, add or remove Products, manage
          permissions, or modify license settings. These choices and
          instructions may result in the access, use, disclosure, modification
          or deletion of certain or all Vendor Data and impact Customers’ access
          to Products.
        </span>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Vendor will (a) inform Authorized Users of all Vendor policies and
          practices that are relevant to their use of the Services and of any
          settings that may impact the processing of Vendor Data and Customers’
          access to Products; and (b) obtain all rights, permissions or consents
          from Authorized Users and other Vendor personnel that are necessary to
          grant the rights and licenses in the Contract and for the lawful use
          and transmission of Vendor Data and the operation of the Services.
        </span>
      </p>
      <h2>Ordering Subscriptions</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          A subscription may be procured through the Services via an order form
          entered into between Vendor and us (each, an&nbsp;<b>“Order Form”</b>
          ). Each Vendor must agree to the&nbsp;<u>Vendor Terms</u>&nbsp;to
          activate their subscription. Subscriptions commence when we make them
          available to Vendor and continue for the term specified in the
          Services Order Form.{" "}
        </span>
      </p>
      <h2>Purchasing Decisions</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          We may share information about our future product plans because we
          like transparency. Our public statements about those product plans are
          an expression of intent, but do not rely on them when making a
          purchase. If Vendor decides to buy our Services, that decision should
          be based on the functionality or features we have made available today
          and not on the delivery of any future functionality or features.
        </span>
      </p>
      <h2>Choosing to be a Beta Tester</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Occasionally, we look for beta testers to help us test our new
          features. These features will be identified as “beta” or
          “pre-release,” or words or phrases with similar meanings (each,
          a&nbsp;<b>“Beta Product”</b>). Beta Products may not be ready for
          prime time so they are made available “as is,” and any warranties or
          contractual commitments we make for other Services do not apply.
          Should Vendor encounter any faults with our Beta Products, we would
          love to hear about them; our primary reason for running any beta
          programs is to iron out issues before making a new feature widely
          available.
        </span>
      </p>
      <h2>Feedback is Welcome</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The more suggestions our customers make, the better the Services
          become. If Vendor sends us any feedback or suggestions regarding the
          Services, there is a chance we will use it, so Vendor grants us (for
          itself and all of its Authorized Users and other Vendor personnel) an
          unlimited, irrevocable, perpetual, sub-licensable, transferable,
          royalty-free license to use any such feedback or suggestions for any
          purpose without any obligation or compensation to Vendor, any
          Authorized User or other Vendor personnel. If we choose not to
          implement the suggestion, please don’t take it personally. We
          appreciate it nonetheless.
        </span>
      </p>
      <h1>Vendor and Authorized Users</h1>
      <h2>Use of the Services</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Vendor must comply with the Contract and ensure that its Authorized
          Users comply with the Contract and the Customer Terms. We may review
          conduct for compliance purposes, but we have no obligation to do so.
          We aren't responsible for the content of any Vendor Data or the way
          Vendor or its Authorized Users choose to use the Services to store or
          process any Vendor Data.
        </span>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The Services are not intended for and should not be used by anyone
          under the age of 13. Vendor must ensure that all Authorized Users are
          over 13 years old.{" "}
        </span>
      </p>
      <h1>Payment Obligations</h1>
      <h2>Payment Terms</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          For customers that purchase our Services, fees are specified in the
          Services Order Form(s). Payment obligations are non-cancelable and,
          except as expressly stated in the Contract, fees paid are
          non-refundable.{" "}
        </span>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          We will invoice you by email and full payment must be received within
          thirty (30) days from the invoice date. Fees are stated exclusive of
          any taxes, levies, duties, or similar governmental assessments of any
          nature, including, for example, value-added, sales, use or withholding
          taxes, assessable by any jurisdiction (collectively,&nbsp;
          <b>“Taxes”</b>). Vendor will be responsible for paying all Taxes
          associated with its purchases, except for those taxes based on our net
          income.
        </span>
      </p>
      <h2>Credits</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Any credits that may accrue to Vendor’s account, will expire following
          expiration or termination of the applicable Contract, will have no
          currency or exchange value, and will not be transferable or
          refundable.{" "}
        </span>
      </p>
      <h2>Suspension for Non-Payment</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          If any fees owed to us by Vendor (excluding amounts disputed
          reasonably and in good faith) are thirty (30) days or more overdue, we
          may, without limiting our other rights and remedies, suspend Services
          until those amounts are paid in full, so long as we have given Vendor
          thirty (30) days’ prior notice that its account is overdue via
          Vendor’s administrative email accounts. Vendor acknowledges and agrees
          that a suspension will result in a loss of Customers’ access to
          Products and potential loss of access to Vendor Data.
        </span>
      </p>
      <h1>Our Responsibilities</h1>
      <h2>Providing the Services</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Vendor isn’t the only one with responsibilities; we have some, too. We
          will (a) make the Services available to Vendor and its Authorized
          Users as described in the Contract; and (b) not use or process Vendor
          Data for any purpose without Vendor’s prior written instructions;
          provided, however, that “prior written instructions” will be deemed to
          include use of the Services by Authorized Users and any processing
          related to such use or otherwise necessary for the performance of the
          Contract.
        </span>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Be assured that (a) the Services will perform materially in accordance
          with our then-current&nbsp;
        </span>
        <a href="https://evoleap.github.io/elmdocs">
          <span
            style={{
              fontSize: "14.5pt",

              color: theme.colors.textPrimary,
              textDecoration: "none"
            }}
          >
            Help Center
          </span>
        </a>
        <span style={{ fontSize: "14.5pt" }}>
          &nbsp;pages; and (b) subject to the “Suspension for Non-Payment”
          sections, we will not materially decrease the functionality of
          Services during a subscription term. For any breach of a warranty in
          this section, Vendor’s exclusive remedies are those described in the
          sections titled “Termination for Cause” and “Effect of Termination”.
        </span>
      </p>
      <h2>Keeping the Services Available</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          For some of our Services, we also offer specific uptime commitments
          paired with credits, if we fall short. In those cases, the credits
          will serve as what the lawyers call liquidated damages and will be
          Vendor’s sole remedy for the downtime and related inconvenience. For
          all Service plans, we will use commercially reasonable efforts to make
          the Services available 24 hours a day, 7 days a week, excluding
          planned downtime. We expect planned downtime to be infrequent but will
          endeavor to provide Vendor with advance notice (e.g., through the
          Services), if we think it may exceed five (5) continuous minutes.
        </span>
      </p>
      <h2>Protecting Vendor Data</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The protection of Vendor Data is a top priority for us so we will
          maintain administrative, physical, and technical safeguards. Those
          safeguards will include measures for preventing unauthorized access,
          use, modification, deletion and disclosure of Vendor Data by our
          personnel. Vendor (not us) bears sole responsibility for adequate
          security, protection and backup of Vendor Data when in Vendor’s or its
          representatives’ or agents’ possession or control to connect to the
          Services. We are not responsible for what Vendor’s Authorized Users do
          with Vendor Data. That is Vendor’s responsibility.
        </span>
      </p>
      <h1>Ownership and Proprietary Rights</h1>
      <h2>What’s Yours is Yours...</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          As between us on the one hand, and Vendor and any Authorized Users on
          the other, Vendor will own all Vendor Data. Subject to the terms and
          conditions of the Contract, Vendor (for itself and all of its
          Authorized Users) grants us a worldwide, non-exclusive, limited term
          license to access, use, process, copy, distribute, perform, export and
          display Vendor Data for its Authorized Users, only as reasonably
          necessary (a) to provide, maintain and improve the Services; (b) to
          prevent or address service, security, support or technical issues; (c)
          as required by law; and (d) as expressly permitted in writing by
          Vendor. Vendor represents and warrants that it has secured all rights
          in and to Vendor Data from its Authorized Users as may be necessary to
          grant this license.
        </span>
      </p>
      <h2>And What’s Ours is Ours</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          We own and will continue to own our Services, including all related
          intellectual property rights. We may make software components
          available (i.e. Client API) as part of the Services. We grant to
          Vendor a non-sublicensable, non-transferable, non-exclusive, limited
          license for Vendor and its Authorized Users to use the object code
          version of these components, but solely as necessary to use the
          Services and in accordance with the Contract. All of our rights not
          expressly granted by this license are hereby retained.
        </span>
      </p>
      <h1>Term and Termination</h1>
      <h2>Contract Term</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The Contract remains effective until all subscriptions ordered under
          the Contract have expired or been terminated or the Contract itself
          terminates. Termination of the Contract will terminate all
          subscriptions and all Order Forms.
        </span>
      </p>
      <h2>Auto-Renewal</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Unless an Order Form says something different, (a) all subscriptions
          automatically renew (without the need to execute a renewal Order Form)
          for additional periods equal to one (1) year or the preceding term,
          whichever is shorter; and (b) the per-unit pricing during any
          automatic renewal term will reflect the then-current pricing. Either
          party can give the other notice of non-renewal at least thirty (30)
          days before the end of a subscription term to stop the subscriptions
          from automatically renewing.
        </span>
      </p>
      <h2>Termination for Cause</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          We or Vendor may terminate the Contract on notice to the other party
          if the other party materially breaches the Contract and such breach is
          not cured within thirty (30) days after the non-breaching party
          provides notice of the breach. Vendor is responsible for its
          Authorized Users, including for any breaches of this Contract caused
          by its Authorized Users. We may terminate the Contract immediately on
          notice to Vendor if we reasonably believe that the Services are being
          used by Vendor or its Authorized Users in violation of applicable law.
        </span>
      </p>
      <h2>Effect of Termination</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Upon any termination for cause by Vendor, we will refund Vendor any
          prepaid fees covering the remainder of the term of all subscriptions
          after the effective date of termination. Upon any termination for
          cause by us, Vendor will pay any unpaid fees covering the remainder of
          the term of those subscriptions after the effective date of
          termination. In no event will any termination relieve Vendor of the
          obligation to pay any fees payable to us for the period prior to the
          effective date of termination.
        </span>
      </p>
      <h2>Data Portability and Deletion</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          We are custodians of Vendor Data. During the term of a subscription,
          Vendor will be permitted to export or share certain Vendor Data from
          the Services. Following termination or expiration of subscriptions, we
          will have no obligation to maintain or provide any Vendor Data beyond
          sixty (60) days after termination or expiration and may thereafter,
          unless legally prohibited, delete all Vendor Data in our systems or
          otherwise in our possession or under our control.{" "}
        </span>
      </p>
      <h1>Representations; Disclaimer of Warranties</h1>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Vendor represents and warrants that it has validly entered into the
          Contract and has the legal power to do so. Vendor further represents
          and warrants that it is responsible for the conduct of its Authorized
          Users and their compliance with the terms of this Contract and the
          Customer Terms. EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, THE SERVICES
          AND ALL RELATED COMPONENTS AND INFORMATION ARE PROVIDED ON AN “AS IS”
          AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND WE
          EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED,
          INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS
          FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. VENDOR ACKNOWLEDGES
          THAT WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED,
          TIMELY, SECURE, OR ERROR-FREE.
        </span>
      </p>
      <h2>Limitation of Liability</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          OTHER THAN IN CONNECTION WITH A PARTY’S INDEMNIFICATION OBLIGATIONS
          HEREUNDER, IN NO EVENT WILL EITHER VENDORS’S OR EVOLEAP’S AGGREGATE
          LIABILITY ARISING OUT OF OR RELATED TO THE CONTRACT OR THE USER TERMS
          (WHETHER IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY)
          EXCEED THE TOTAL AMOUNT PAID BY VENDOR HEREUNDER IN THE TWELVE (12)
          MONTHS PRECEDING THE LAST EVENT GIVING RISE TO LIABILITY. THE
          FOREGOING WILL NOT LIMIT VENDOR’S PAYMENT OBLIGATIONS UNDER THE
          “PAYMENT TERMS” SECTION ABOVE.
        </span>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          IN NO EVENT WILL EITHER VENDOR OR ANY MEMBER OF EVOLEAP HAVE ANY
          LIABILITY TO THE OTHER PARTY OR TO ANY THIRD PARTY FOR ANY LOST
          PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN
          CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR
          NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
          FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY
          APPLICABLE LAW.
        </span>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Vendor is responsible for all login credentials, including usernames
          and passwords, for administrator accounts as well the accounts of your
          Authorized Users. We will not be responsible for any damages, losses
          or liability to Vendor, Authorized Users, or anyone else, if such
          information is not kept confidential by Vendor or its Authorized
          Users, or if such information is correctly provided by an unauthorized
          third party logging into and accessing the Services.
        </span>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The limitations under this “Limitation of Liability” section apply
          with respect to all legal theories, whether in contract, tort or
          otherwise, and to the extent permitted by law. The provisions of this
          “Limitation of Liability” section allocate the risks under this
          Contract between the parties, and the parties have relied on these
          limitations in determining whether to enter into this Contract and the
          pricing for the Services.
        </span>
      </p>
      <h2>Our Indemnification of Vendor</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          We will defend Vendor from and against any and all third party claims,
          actions, suits, proceedings, and demands alleging that the use of the
          Services as permitted under the Contract infringes or misappropriates
          a third party’s intellectual property rights (a&nbsp;
          <b>“Claim Against Vendor”</b>), and will indemnify Vendor for all
          reasonable attorney’s fees incurred and damages and other costs
          finally awarded against Vendor in connection with or as a result of,
          and for amounts paid by Vendor under a settlement we approve of in
          connection with, a Claim Against Vendor; provided, however, that we
          will have no liability if a Claim Against Vendor arises from (a)
          Vendor Data; and (b) any modification, combination or development of
          the Services that is not performed by us, including in the use of any
          application programming interface (API). Vendor must provide us with
          prompt written notice of any Claim Against Vendor and allow us the
          right to assume the exclusive defense and control, and cooperate with
          any reasonable requests assisting our defense and settlement of such
          matter. This section states our sole liability with respect to, and
          Vendor’s exclusive remedy against us for, any Claim Against Vendor.
        </span>
      </p>
      <h2>Vendor’s Indemnification of Us</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Vendor will defend evoleap from and against any and all third party
          claims, actions, suits, proceedings, and demands arising from or
          related to Vendor’s or any of its Authorized Users’ violation of the
          Contract or the Customer Terms (a&nbsp;<b>“Claim Against Us”</b>), and
          will indemnify evoleap for all reasonable attorney’s fees incurred and
          damages and other costs finally awarded against evoleap in connection
          with or as a result of, and for amounts paid by evoleap under a
          settlement Vendor approves of in connection with, a Claim Against Us.
          We must provide Vendor with prompt written notice of any Claim Against
          Us and allow Vendor the right to assume the exclusive defense and
          control, and cooperate with any reasonable requests assisting Vendor’s
          defense and settlement of such matter. This section states your sole
          liability with respect to, and evoleap’s exclusive remedy against
          Vendor for, any Claim Against Us.
        </span>
      </p>
      <h2>Limitations On Indemnifications</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Notwithstanding anything contained in the two preceding sections, (a)
          an indemnified party will always be free to choose its own counsel if
          it pays for the cost of such counsel; and (b) no settlement may be
          entered into by an indemnifying party, without the express written
          consent of the indemnified parties (such consent not to be
          unreasonably withheld), if (i) the third party asserting the claim is
          a government agency, (ii) the settlement arguably involves the making
          of admissions by the indemnified parties, (iii) the settlement does
          not include a full release of liability for the indemnified parties,
          or (iv) the settlement includes terms other than a full release of
          liability for the indemnified parties and the payment of money.
        </span>
      </p>
      <h1>Confidentiality</h1>
      <h2>Confidential Information</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Each party (<b>“Disclosing Party”</b>) may disclose&nbsp;
          <b>“Confidential Information”</b>&nbsp;to the other party (
          <b>“Receiving Party”</b>) in connection with the Contract, which is
          anything that reasonably should be understood to be confidential given
          the nature of the information and the circumstances of disclosure
          including all Order Forms, as well as non-public business, product,
          technology and marketing information. Confidential Information of
          Vendor includes Vendor Data. If something is labeled “Confidential,”
          that’s a clear indicator to the Receiving Party that the material is
          confidential. Notwithstanding the above, Confidential Information does
          not include information that (a) is or becomes generally available to
          the public without breach of any obligation owed to the Disclosing
          Party; (b) was known to the Receiving Party prior to its disclosure by
          the Disclosing Party without breach of any obligation owed to the
          Disclosing Party; (c) is received from a third party without breach of
          any obligation owed to the Disclosing Party; or (d) was independently
          developed by the Receiving Party.
        </span>
      </p>
      <h2>Protection and Use of Confidential Information</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The Receiving Party will (a) take at least reasonable measures to
          prevent the unauthorized disclosure or use of Confidential
          Information, and limit access to those employees, affiliates and
          contractors who need to know such information in connection with the
          Contract ; and (b) not use or disclose any Confidential Information of
          the Disclosing Party for any purpose outside the scope of this
          Contract. Nothing above will prevent either party from sharing
          Confidential Information with financial and legal advisors; provided,
          however, that the advisors are bound to confidentiality obligations at
          least as restrictive as those in the Contract.
        </span>
      </p>
      <h2>Compelled Access or Disclosure</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The Receiving Party may access or disclose Confidential Information of
          the Disclosing Party if it is required by law; provided, however, that
          the Receiving Party gives the Disclosing Party prior notice of the
          compelled access or disclosure (to the extent legally permitted) and
          reasonable assistance, at the Disclosing Party's cost, if the
          Disclosing Party wishes to contest the access or disclosure. If the
          Receiving Party is compelled by law to access or disclose the
          Disclosing Party’s Confidential Information, the Disclosing Party will
          reimburse the Receiving Party for its reasonable cost of compiling and
          providing access to such Confidential Information as well as the
          reasonable cost for any support provided in connection with the
          Disclosing Party seeking a protective order or confidential treatment
          for the Confidential Information to be produced.
        </span>
      </p>
      <h1>Survival</h1>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The sections titled “Feedback is Welcome,” “Payment Terms,” “Credits,”
          “What’s Yours is Yours …,” “And What’s Ours is Ours,” “Effect of
          Termination,” “Data Portability and Deletion,” “Representations;
          Disclaimer of Warranties,” “Limitation of Liability,” “Our
          Indemnification of Vendor,” “Vendor’s Indemnification of Us,”
          “Limitations on Indemnifications,” “Confidentiality” and “Survival,”
          as well as all of the provisions under the general heading “General
          Provisions,” will survive any termination or expiration of the
          Contract.
        </span>
      </p>
      <h1>General Provisions</h1>
      <h2>Publicity</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Vendor grants us the right to use their company name and logo as a
          reference for marketing or promotional purposes on our website and in
          other public or private communications with our existing or potential
          customers, subject to Vendor’s standard trademark usage guidelines as
          provided to us from time-to-time. We don’t want to list customers who
          don’t want to be listed, so Vendor may send us an email to&nbsp;
        </span>
        <a href="mailto:feedback@evoleap.com">
          <span
            style={{
              fontSize: "14.5pt",

              color: theme.colors.textPrimary
            }}
          >
            feedback@evoleap.com
          </span>
        </a>
        <span style={{ fontSize: "14.5pt" }}>
          {" "}
          stating that it does not wish to be used as a reference.
        </span>
      </p>
      <h2>Force Majeure</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Neither us nor Vendor will be liable by reason of any failure or delay
          in the performance of its obligations on account of events beyond the
          reasonable control of a party, which may include denial-of-service
          attacks, a failure by a third party hosting provider or utility
          provider, strikes, shortages, riots, fires, acts of God, war,
          terrorism, and governmental action.
        </span>
      </p>
      <h2>Relationship of the Parties; No Third Party Beneficiaries</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The parties are independent contractors. The Contract does not create
          a partnership, franchise, joint venture, agency, fiduciary or
          employment relationship between the parties. There are no third party
          beneficiaries to the Contract.
        </span>
      </p>
      <h2>Email and evoleap Messages</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Except as otherwise set forth herein, all notices under the Contract
          will be by email, although we may instead choose to provide notice to
          Vendor through the Services. Notices to evoleap will be sent to&nbsp;
        </span>
        <a href="mailto:feedback@evoleap.com">
          <span
            style={{
              fontSize: "14.5pt",

              color: theme.colors.textPrimary
            }}
          >
            feedback@evoleap.com
          </span>
        </a>
        <span style={{ fontSize: "14.5pt" }}>
          , except for legal notices, such as notices of termination or an
          indemnifiable claim, which must be sent to{" "}
        </span>
        <a href="mailto:legal@evoleap.com">
          <span
            style={{
              fontSize: "14.5pt",

              color: theme.colors.textPrimary
            }}
          >
            legal@evoleap.com
          </span>
        </a>
        <span style={{ fontSize: "14.5pt" }}>
          . Notices will be deemed to have been duly given (a) the day after it
          is sent, in the case of notices through email; and (b) the same day,
          in the case of notices through the Services.
        </span>
      </p>
      <h2>Modifications</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          As our business evolves, we may change these Vendor Terms and the
          other components of the Contract (except any previously executed Order
          Forms). If we make a material change to the Contract, we will provide
          Vendor with reasonable notice prior to the change taking effect,
          either by emailing the email address associated with Vendor’s account
          or by messaging Vendor through the Services. Vendor can review the
          most current version of the Vendor Terms at any time by visiting this
          page and by visiting the most current versions of the other pages that
          are referenced in the Contract. The materially revised Contract will
          become effective on the date set forth in our notice, and all other
          changes will become effective upon posting of the change. If Vendor
          (or any Authorized User) accesses or uses the Services after the
          effective date, that use will constitute Vendor’s acceptance of any
          revised terms and conditions.
        </span>
      </p>
      <h2>Waiver</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          No failure or delay by either party in exercising any right under the
          Contract will constitute a waiver of that right. No waiver under the
          Contract will be effective unless made in writing and signed by an
          authorized representative of the party being deemed to have granted
          the waiver.
        </span>
      </p>
      <h2>Severability</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The Contract will be enforced to the fullest extent permitted under
          applicable law. If any provision of the Contract is held by a court of
          competent jurisdiction to be contrary to law, the provision will be
          modified by the court and interpreted so as best to accomplish the
          objectives of the original provision to the fullest extent permitted
          by law, and the remaining provisions of the Contract will remain in
          effect.
        </span>
      </p>
      <h2>Assignment</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Neither party may assign or delegate any of its rights or obligations
          hereunder, whether by operation of law or otherwise, without the prior
          written consent of the other party (not to be unreasonably withheld).
          Notwithstanding the foregoing, either party may assign the Contract in
          its entirety (including all Order Forms), without consent of the other
          party, to a corporate affiliate or in connection with a merger,
          acquisition, corporate reorganization, or sale of all or substantially
          all of its assets. Vendor will keep its billing and contact
          information current at all times by notifying evoleap of any changes.
          Any purported assignment in violation of this section is void. A
          party’s sole remedy for any purported assignment by the other party in
          breach of this section will be, at the non-assigning party’s election,
          termination of the Contract upon written notice to the assigning
          party. In the event of such a termination by Vendor, we will refund
          Vendor any prepaid fees covering the remainder of the term of all
          subscriptions after the effective date of termination. Subject to the
          foregoing, the Contract will bind and inure to the benefit of the
          parties, their respective successors and permitted assigns.
        </span>
      </p>
      <h2>Governing Law</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The Contract, and any disputes arising out of or related hereto, will
          be governed exclusively by the internal laws of the State of Texas,
          without regard to its conflicts of laws rules or the United Nations
          Convention on the International Sale of Goods.
        </span>
      </p>
      <h2>Venue; Waiver of Jury Trial; Fees</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The state and federal courts located in Harris County, Texas will have
          exclusive jurisdiction to adjudicate any dispute arising out of or
          relating to the Contract or its formation, interpretation or
          enforcement. Each party hereby consents and submits to the exclusive
          jurisdiction of such courts.&nbsp;
          <b>
            Each party also hereby waives any right to jury trial in connection
            with any action or litigation in any way arising out of or related
            to the Contract.
          </b>
          &nbsp;In any action or proceeding to enforce rights under the
          Contract, the prevailing party will be entitled to recover its
          reasonable costs and attorney’s fees.
        </span>
      </p>
      <h2>Entire Agreement</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The Contract, including these Vendor Terms and all referenced pages
          and Order Forms, if applicable, constitutes the entire agreement
          between the parties and supersedes all prior and contemporaneous
          agreements, proposals or representations, written or oral, concerning
          its subject matter. Without limiting the foregoing, the Contract
          supersedes the terms of any online agreement electronically accepted
          by Vendor or any Authorized Users. However, to the extent of any
          conflict or inconsistency between the provisions in these Vendor Terms
          and any other documents or pages referenced in these Vendor Terms, the
          following order of precedence will apply: (1) the terms of any Order
          Form (if any), (2) the portions of the Vendor-Specific Supplement
        </span>
        <span style={{ fontSize: "14.5pt" }}>
          &nbsp;that apply to Vendor (if any), (3) the Vendor Terms and (4)
          finally any other documents or pages referenced in the Vendor Terms.
          Notwithstanding any language to the contrary therein, no terms or
          conditions stated in a Vendor purchase order, vendor onboarding
          process or web portal, or any other Vendor order documentation
          (excluding Order Forms) will be incorporated into or form any part of
          the Contract, and all such terms or conditions will be null and void.
        </span>
      </p>
      <h2>Contacting evoleap</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Please also feel free to contact us if you have any questions about
          evoleap’s Vendor Terms of Service. You may contact us at&nbsp;
        </span>
        <a href="mailto:feedback@evoleap.com">
          <span
            style={{
              fontSize: "14.5pt",

              color: theme.colors.textPrimary
            }}
          >
            feedback@evoleap.com
          </span>
        </a>
        <span style={{ fontSize: "14.5pt" }}>
          &nbsp;or at our mailing address below:
        </span>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          evoleap, LLC
          <br />
          10333 Richmond Ave, Suite 180 <br />
          Houston, TX
          <br />
          77042
        </span>
      </p>
      <p className="MsoNormal">&nbsp;</p>
    </div>
  );
};
export const CustomerTerms = () => {
  const theme = useTheme();
  return (
    <div className="WordSection1">
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <b>
          <span style={{ fontSize: "18.0pt" }}>Customer Terms of Service</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <b>
          <span style={{ fontSize: "14.5pt" }}>Effective: </span>
        </b>
        <b>
          <span style={{ fontSize: "14.5pt" }}>
            January 1<sup>st</sup>, 2017
          </span>
        </b>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          These Vendor Terms of Service (the&nbsp;<b>“Customer Terms”</b>)
          govern your access and use of our online license management tool
          (the&nbsp;<b>“Services”</b>). Even though you are signing onto manage
          licenses created by software Vendors, these Customer Terms apply to
          you — the prospective user reading these words. We are grateful you’re
          here.
        </span>
      </p>
      <h1>First Things First</h1>
      <h2>These Customer Terms are Legally Binding</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          These Customer Terms are a legally binding contract between you and
          us. If you access or use the Services, or continue accessing or using
          the Services after being notified of a change to the Customer Terms or
          the Acceptable Use Policy, you confirm that you have read, understand
          and agree to be bound by the Customer Terms and the Acceptable Use
          Policy. “We”, “our” and “us” currently refers to evoleap, LLC.
        </span>
      </p>
      <h1>Customer’s Choices and Instructions</h1>
      <h2>You are an Authorized User associated with one or more Vendors</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          An organization or other third party that we refer to in these
          Customer Terms as &nbsp;<b>“Vendor”</b> has invited you to access the
          Services. A Vendor is a person or company using our Services to
          license one or more of their software products (&nbsp;
          <b>“Products”</b>) to you or your employer. As a Customer, the Vendor
          is giving you a limited ability to manage the licensing of their
          Products to you.
        </span>
      </p>
      <h2>Who is “Customer”?</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          &nbsp;<b>“Customer”</b> is the organization that you represent in
          agreeing to the Contract. If you are not formally affiliated with an
          organization, Customer is the individual accepting the invitation from
          the Vendor.
        </span>
      </p>
      <h2>Vendor’s Rights</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Vendor has separately agreed to our Vendor Terms of Service or entered
          into a written agreement with us (in either case, the &nbsp;
          <b>“Contract”</b>) that permitted Vendor to create and configure
          Products and licenses (collectively &nbsp;<b>“Vendor Data”</b>) that
          you and others could access (each invitee granted access to the
          Services, including you, is an &nbsp;<b>“Authorized User”</b>). The
          Contract contains our commitment to deliver the Services to Vendor,
          who may then invite Authorized Users from Customers to administer
          their licenses as allowed by Services. Vendor may also provision or
          deprovision access to the Services, add or remove Products, manage
          permissions, or modify license settings. Vendor’s actions may result
          in modifications to, including removal of, data that is accessible to
          you as well as Services being no longer available to you. This is
          solely up to the Vendor’s discretion.
        </span>
      </p>
      <h2>Customer’s Rights and Obligations</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          As an Authorized User of Customer, you have the right to view the data
          made available to you by the Services and also make modifications to
          the licenses that might affect the access of Products to users in your
          organization. Customer will (a) inform its Authorized Users of all
          Customer policies and practices that are relevant to their use of the
          Services and of any settings that may impact the Customer’s access to
          Products; and (b) obtain all rights, permissions or consents from
          Authorized Users and other Customer personnel that are necessary to
          grant the rights and licenses in the Contract and for the operation of
          the Services. Customer must comply with the Contract and ensure that
          its Authorized Users comply with the Contract and these Terms. We may
          review conduct for compliance purposes, but we have no obligation to
          do so. We aren't responsible for the content of any Vendor Data or the
          way Vendor or its Authorized Users choose to use the Services to store
          or process any Vendor Data. The Services are not intended for and
          should not be used by anyone under the age of 13. Customer must ensure
          that all Authorized Users are over 13 years old.
        </span>
      </p>
      <h2>You Are Here on Account of Vendor (and Us)</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          These Customer Terms remain effective until Vendor’s subscription for
          Services expires or terminates, or your access to the Services has
          been terminated by Vendor or us. Please contact Vendor if you at any
          time or for any reason wish to terminate your account, including due
          to a disagreement with any updates to these Customer Terms or the
          Acceptable Use Policy.
        </span>
      </p>
      <h1>Warranties</h1>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          EVOLEAP MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, TO YOU RELATING TO THE SERVICES, WHICH ARE
          PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS.
        </span>
      </p>
      <h1>Limitation of Liability</h1>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          IN NO EVENT WILL YOU OR WE HAVE ANY LIABILITY TO THE OTHER FOR ANY
          LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN
          CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR
          NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          UNLESS YOU ARE ALSO A VENDOR (AND WITHOUT LIMITATION TO OUR RIGHTS AND
          REMEDIES UNDER THE CONTRACT), YOU WILL HAVE NO FINANCIAL LIABILITY TO
          US FOR A BREACH OF THESE CUSTOMER TERMS. OUR MAXIMUM AGGREGATE
          LIABILITY TO YOU FOR ANY BREACH OF THE CUSTOMER TERMS IS ONE HUNDRED
          DOLLARS ($100) IN THE AGGREGATE. THE FOREGOING DISCLAIMERS WILL NOT
          APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW AND DO NOT LIMIT
          EITHER PARTY’S RIGHT TO SEEK AND OBTAIN EQUITABLE RELIEF.
        </span>
      </p>
      <h2>Survival</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The sections titled “Customer’s Rights and Obligations”, “Limitation
          of Liability”, and “Survival”, and all of the provisions under the
          general heading “General Provisions” will survive any termination or
          expiration of the Customer Terms.
        </span>
      </p>
      <h1>General Provisions</h1>
      <h2>Force Majeure</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          We will not be liable by reason of any failure or delay in the
          performance of our obligations on account of events beyond our
          reasonable control, which may include denial-of-service attacks, a
          failure by a third party hosting provider or utility provider,
          strikes, shortages, riots, fires, acts of God, war, terrorism, and
          governmental action.
        </span>
      </p>
      <h2>Relationship of the Parties; No Third Party Beneficiaries</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The parties are independent contractors. The Contract does not create
          a partnership, franchise, joint venture, agency, fiduciary or
          employment relationship between the parties. There are no third party
          beneficiaries to the Contract.
        </span>
      </p>
      <h2>Email and evoleap Messages</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Except as otherwise set forth herein, all notices under the Contract
          will be by email, although we may instead choose to provide notice to
          Vendor through the Services. Notices to evoleap will be sent to&nbsp;
        </span>
        <a href="mailto:feedback@evoleap.com">
          <span
            style={{
              fontSize: "14.5pt",

              color: theme.colors.textPrimary
            }}
          >
            feedback@evoleap.com
          </span>
        </a>
        <span style={{ fontSize: "14.5pt" }}>
          , except for legal notices, such as notices of termination or an
          indemnifiable claim, which must be sent to{" "}
        </span>
        <a href="mailto:legal@evoleap.com">
          <span
            style={{
              fontSize: "14.5pt",

              color: theme.colors.textPrimary
            }}
          >
            legal@evoleap.com
          </span>
        </a>
        <span style={{ fontSize: "14.5pt" }}>
          . Notices will be deemed to have been duly given (a) the day after it
          is sent, in the case of notices through email; and (b) the same day,
          in the case of notices through the Services.
        </span>
      </p>
      <h2>Modifications</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          As our business evolves, we may change these Customer Terms and the
          other components of the Contract. If we make a material change to the
          Contract, we will provide Customer with reasonable notice prior to the
          change taking effect, either by emailing the email address associated
          with Customer’s account or by messaging Customer through the Services.
          Customer can review the most current version of the Customer Terms at
          any time by visiting this page and by visiting the most current
          versions of the other pages that are referenced in the Contract. The
          materially revised Contract will become effective on the date set
          forth in our notice, and all other changes will become effective upon
          posting of the change. If Customer (or any Authorized User) accesses
          or uses the Services after the effective date, that use will
          constitute Customer’s acceptance of any revised terms and conditions.
        </span>
      </p>
      <h2>Waiver</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          No failure or delay by either party in exercising any right under the
          Contract will constitute a waiver of that right. No waiver under the
          Contract will be effective unless made in writing and signed by an
          authorized representative of the party being deemed to have granted
          the waiver.
        </span>
      </p>
      <h2>Severability</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The Contract will be enforced to the fullest extent permitted under
          applicable law. If any provision of the Contract is held by a court of
          competent jurisdiction to be contrary to law, the provision will be
          modified by the court and interpreted so as best to accomplish the
          objectives of the original provision to the fullest extent permitted
          by law, and the remaining provisions of the Contract will remain in
          effect.
        </span>
      </p>
      <h2>Assignment</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Neither party may assign or delegate any of its rights or obligations
          hereunder, whether by operation of law or otherwise, without the prior
          written consent of the other party (not to be unreasonably withheld).
          Notwithstanding the foregoing, either party may assign the Contract in
          its entirety, without consent of the other party, to a corporate
          affiliate or in connection with a merger, acquisition, corporate
          reorganization, or sale of all or substantially all of its assets. Any
          purported assignment in violation of this section is void. A party’s
          sole remedy for any purported assignment by the other party in breach
          of this section will be, at the non-assigning party’s election,
          termination of the Contract upon written notice to the assigning
          party. Subject to the foregoing, the Contract will bind and inure to
          the benefit of the parties, their respective successors and permitted
          assigns.
        </span>
      </p>
      <h2>Governing Law</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The Contract, and any disputes arising out of or related hereto, will
          be governed exclusively by the internal laws of the State of Texas,
          without regard to its conflicts of laws rules or the United Nations
          Convention on the International Sale of Goods.
        </span>
      </p>
      <h2>Venue; Waiver of Jury Trial; Fees</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The state and federal courts located in Harris County, Texas will have
          exclusive jurisdiction to adjudicate any dispute arising out of or
          relating to the Contract or its formation, interpretation or
          enforcement. Each party hereby consents and submits to the exclusive
          jurisdiction of such courts.&nbsp;
          <b>
            Each party also hereby waives any right to jury trial in connection
            with any action or litigation in any way arising out of or related
            to the Contract.
          </b>
          &nbsp;In any action or proceeding to enforce rights under the
          Contract, the prevailing party will be entitled to recover its
          reasonable costs and attorney’s fees.
        </span>
      </p>
      <h2>Entire Agreement</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          The Contract, including these Customer Terms and all referenced pages
          and Order Forms, if applicable, constitutes the entire agreement
          between the parties and supersedes all prior and contemporaneous
          agreements, proposals or representations, written or oral, concerning
          its subject matter. Without limiting the foregoing, the Contract
          supersedes the terms of any online agreement electronically accepted
          by Customer or any Authorized Users. However, to the extent of any
          conflict or inconsistency between the provisions in these Customer
          Terms and any other documents or pages referenced in these Customer
          Terms, the following order of precedence will apply: (1) the terms of
          any Order Form (if any), (2) the Customer Terms and (3) finally any
          other documents or pages referenced in the Terms. Notwithstanding any
          language to the contrary therein, no terms or conditions stated in a
          customer onboarding process or web portal, or any other documentation
          will be incorporated into or form any part of the Contract, and all
          such terms or conditions will be null and void.
        </span>
      </p>
      <h2>Contacting evoleap</h2>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          Please also feel free to contact us if you have any questions about
          evoleap’s Vendor Terms of Service. You may contact us at&nbsp;
        </span>
        <a href="mailto:feedback@evoleap.com">
          <span
            style={{
              fontSize: "14.5pt",

              color: theme.colors.textPrimary
            }}
          >
            feedback@evoleap.com
          </span>
        </a>
        <span style={{ fontSize: "14.5pt" }}>
          &nbsp;or at our mailing address below:
        </span>
      </p>
      <p className="MsoNormal" style={{ lineHeight: "normal" }}>
        <span style={{ fontSize: "14.5pt" }}>
          evoleap, LLC
          <br />
          10333 Richmond Ave, Suite 180 <br />
          Houston, TX
          <br />
          77042
        </span>
      </p>
    </div>
  );
};
