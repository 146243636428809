import styled from "styled-components";

export const sharedTabStyle = {
  //marginTop: "16px",
  marginRight: "0px",
  paddingLeft: "12px",
  paddingRight: "12px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box"
} as React.CSSProperties;

export const WidgetTitle = styled.span`
  color: ${props => props.theme.colors.warmGrey};
  font-size: ${props => props.theme.fontSizes.small};
`;
