/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type linkedUsersTableQueryVariables = {
  id: string;
};
export type linkedUsersTableQueryResponse = {
  readonly user: {
    readonly userLinks: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly linkedUser: {
          readonly id: string;
          readonly name: string | null;
          readonly owner: {
            readonly name: string | null;
          } | null;
        };
      } | null> | null;
    } | null;
    readonly linkedUsersCount: number;
  } | null;
};
export type linkedUsersTableQuery = {
  readonly response: linkedUsersTableQueryResponse;
  readonly variables: linkedUsersTableQueryVariables;
};

/*
query linkedUsersTableQuery(
  $id: ID!
) {
  user(id: $id) {
    userLinks {
      nodes {
        id
        linkedUser {
          id
          name
          owner {
            __typename
            name
            id
          }
        }
      }
    }
    linkedUsersCount
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "linkedUsersCount",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "linkedUsersTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "UserLinkConnection",
              kind: "LinkedField",
              name: "userLinks",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "UserLink",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: "User",
                      kind: "LinkedField",
                      name: "linkedUser",
                      plural: false,
                      selections: [
                        v2 /*: any*/,
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: null,
                          kind: "LinkedField",
                          name: "owner",
                          plural: false,
                          selections: [v3 /*: any*/],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v4 /*: any*/
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "linkedUsersTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "UserLinkConnection",
              kind: "LinkedField",
              name: "userLinks",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "UserLink",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: "User",
                      kind: "LinkedField",
                      name: "linkedUser",
                      plural: false,
                      selections: [
                        v2 /*: any*/,
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: null,
                          kind: "LinkedField",
                          name: "owner",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "__typename",
                              storageKey: null
                            },
                            v3 /*: any*/,
                            v2 /*: any*/
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v4 /*: any*/,
            v2 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "c0abc242e1902e8fcde5eb4bcd5152fb",
      id: null,
      metadata: {},
      name: "linkedUsersTableQuery",
      operationKind: "query",
      text:
        "query linkedUsersTableQuery(\n  $id: ID!\n) {\n  user(id: $id) {\n    userLinks {\n      nodes {\n        id\n        linkedUser {\n          id\n          name\n          owner {\n            __typename\n            name\n            id\n          }\n        }\n      }\n    }\n    linkedUsersCount\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "5445a55fbb241d4af8069e50bfd9258a";
export default node;
