/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type productSelectorQueryVariables = {};
export type productSelectorQueryResponse = {
  readonly products: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly id: string;
      readonly type: {
        readonly id: string;
        readonly name: string;
      };
    } | null> | null;
  } | null;
};
export type productSelectorQuery = {
  readonly response: productSelectorQueryResponse;
  readonly variables: productSelectorQueryVariables;
};

/*
query productSelectorQuery {
  products(sortColumn: ["product.name"], sortDirection: ["ASC"]) {
    nodes {
      name
      id
      type {
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: "Literal",
            name: "sortColumn",
            value: ["product.name"]
          },
          {
            kind: "Literal",
            name: "sortDirection",
            value: ["ASC"]
          }
        ],
        concreteType: "ProductConnection",
        kind: "LinkedField",
        name: "products",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "Product",
            kind: "LinkedField",
            name: "nodes",
            plural: true,
            selections: [
              v0 /*: any*/,
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: "ProductCategory",
                kind: "LinkedField",
                name: "type",
                plural: false,
                selections: [v1 /*: any*/, v0 /*: any*/],
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey:
          'products(sortColumn:["product.name"],sortDirection:["ASC"])'
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "productSelectorQuery",
      selections: v2 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "productSelectorQuery",
      selections: v2 /*: any*/
    },
    params: {
      cacheID: "0dddd9d4ccfc21dcae7f8176984a95a6",
      id: null,
      metadata: {},
      name: "productSelectorQuery",
      operationKind: "query",
      text:
        'query productSelectorQuery {\n  products(sortColumn: ["product.name"], sortDirection: ["ASC"]) {\n    nodes {\n      name\n      id\n      type {\n        id\n        name\n      }\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "a83ca0919f3895af33ecfa4f67952ac1";
export default node;
