/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type usersShortListTablePaginationQueryVariables = {
  count: number;
  cursor?: string | null | undefined;
  blade_scope?: string | null | undefined;
  search?: string | null | undefined;
  filterColumn?: Array<string> | null | undefined;
  filterValues?: Array<Array<string>> | null | undefined;
  filterOp?: Array<Array<string>> | null | undefined;
};
export type usersShortListTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"usersShortListTable_users">;
};
export type usersShortListTablePaginationQuery = {
  readonly response: usersShortListTablePaginationQueryResponse;
  readonly variables: usersShortListTablePaginationQueryVariables;
};

/*
query usersShortListTablePaginationQuery(
  $count: Int!
  $cursor: String
  $blade_scope: String
  $search: String
  $filterColumn: [String!]
  $filterValues: [[String!]!]
  $filterOp: [[String!]!]
) {
  ...usersShortListTable_users_1sDozd
}

fragment usersShortListTable_users_1sDozd on Query {
  usersShortListTableCount: usersCount(search: $search, bladeScope: $blade_scope, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues)
  usersShortListTableUsers: users(first: $count, after: $cursor, bladeScope: $blade_scope, search: $search, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues) {
    edges {
      node {
        id
        name
        email
        active
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "blade_scope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterOp"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    } as any,
    v6 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    } as any,
    v7 = {
      kind: "Variable",
      name: "filterColumn",
      variableName: "filterColumn"
    } as any,
    v8 = {
      kind: "Variable",
      name: "filterOp",
      variableName: "filterOp"
    } as any,
    v9 = {
      kind: "Variable",
      name: "filterValues",
      variableName: "filterValues"
    } as any,
    v10 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v11 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    v12 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      v11 /*: any*/,
      v7 /*: any*/,
      v8 /*: any*/,
      v9 /*: any*/,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      v10 /*: any*/
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "usersShortListTablePaginationQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "blade_scope",
              variableName: "blade_scope"
            },
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v7 /*: any*/,
            v8 /*: any*/,
            v9 /*: any*/,
            v10 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "usersShortListTable_users"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v1 /*: any*/,
        v2 /*: any*/,
        v0 /*: any*/,
        v6 /*: any*/,
        v3 /*: any*/,
        v5 /*: any*/,
        v4 /*: any*/
      ],
      kind: "Operation",
      name: "usersShortListTablePaginationQuery",
      selections: [
        {
          alias: "usersShortListTableCount",
          args: [
            v11 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            v9 /*: any*/,
            v10 /*: any*/
          ],
          kind: "ScalarField",
          name: "usersCount",
          storageKey: null
        },
        {
          alias: "usersShortListTableUsers",
          args: v12 /*: any*/,
          concreteType: "UserConnection",
          kind: "LinkedField",
          name: "users",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "UserEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "User",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "name",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "email",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "active",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: "usersShortListTableUsers",
          args: v12 /*: any*/,
          filters: [
            "bladeScope",
            "search",
            "filterColumn",
            "filterOp",
            "filterValues"
          ],
          handle: "connection",
          key: "UsersShortListTable_usersShortListTableUsers",
          kind: "LinkedHandle",
          name: "users"
        }
      ]
    },
    params: {
      cacheID: "dc614e9cf7abf61bc95d0a9810807b65",
      id: null,
      metadata: {},
      name: "usersShortListTablePaginationQuery",
      operationKind: "query",
      text:
        "query usersShortListTablePaginationQuery(\n  $count: Int!\n  $cursor: String\n  $blade_scope: String\n  $search: String\n  $filterColumn: [String!]\n  $filterValues: [[String!]!]\n  $filterOp: [[String!]!]\n) {\n  ...usersShortListTable_users_1sDozd\n}\n\nfragment usersShortListTable_users_1sDozd on Query {\n  usersShortListTableCount: usersCount(search: $search, bladeScope: $blade_scope, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues)\n  usersShortListTableUsers: users(first: $count, after: $cursor, bladeScope: $blade_scope, search: $search, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues) {\n    edges {\n      node {\n        id\n        name\n        email\n        active\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "4ae93e6536ae1112e1e4a1e40fb1bb08";
export default node;
