/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type editReleaseConfigurationQueryVariables = {
  id: string;
};
export type editReleaseConfigurationQueryResponse = {
  readonly product: {
    readonly id: string;
    readonly releaseConfigurations: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly releaseChannels: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly name: string | null;
                readonly currentRelease: {
                  readonly id: string;
                  readonly tag: string;
                  readonly createdAt: unknown;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type editReleaseConfigurationQuery = {
  readonly response: editReleaseConfigurationQueryResponse;
  readonly variables: editReleaseConfigurationQueryVariables;
};

/*
query editReleaseConfigurationQuery(
  $id: ID!
) {
  product(id: $id) {
    id
    releaseConfigurations {
      edges {
        node {
          releaseChannels {
            edges {
              node {
                id
                name
                currentRelease {
                  id
                  tag
                  createdAt
                }
              }
            }
          }
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      concreteType: "ReleaseChannelConnection",
      kind: "LinkedField",
      name: "releaseChannels",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ReleaseChannelEdge",
          kind: "LinkedField",
          name: "edges",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ReleaseChannel",
              kind: "LinkedField",
              name: "node",
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "name",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "Release",
                  kind: "LinkedField",
                  name: "currentRelease",
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "tag",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "createdAt",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "editReleaseConfigurationQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfigurationConnection",
              kind: "LinkedField",
              name: "releaseConfigurations",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseConfigurationEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ReleaseConfiguration",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [v3 /*: any*/],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "editReleaseConfigurationQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfigurationConnection",
              kind: "LinkedField",
              name: "releaseConfigurations",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseConfigurationEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ReleaseConfiguration",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [v3 /*: any*/, v2 /*: any*/],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "7a8f693552ea8fa2bc8643c963fb1a85",
      id: null,
      metadata: {},
      name: "editReleaseConfigurationQuery",
      operationKind: "query",
      text:
        "query editReleaseConfigurationQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    id\n    releaseConfigurations {\n      edges {\n        node {\n          releaseChannels {\n            edges {\n              node {\n                id\n                name\n                currentRelease {\n                  id\n                  tag\n                  createdAt\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "c812bb53dee035be20540090874f29e9";
export default node;
