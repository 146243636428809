import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import { getGateway } from "../../../../api";
import { IOnboardingState } from "store/types";
import { Avatar, message, Tooltip, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload";
import { graphql } from "babel-plugin-relay/macro";
import { getIcon } from "components/icons";
import { QueryRenderer } from "react-relay";
import { TourGuideId } from "blades/Welcome/utils/types";
import ElmInput from "components/elmInput";
import { SectionTitle } from "Licenses/blades/Entitlement/styles";
import { fileActionHandler, getBase64 } from "utils";
import { getEnvironment } from "api/relay";
import { LoaderContainer } from "components/helpers";
import {
  accountDialogQuery,
  accountDialogQueryResponse
} from "./__generated__/accountDialogQuery.graphql";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";

const relayEnvironment = getEnvironment();
const graphqlQuery = graphql`
  query accountDialogQuery {
    currentUser {
      email
      id
      name
      thumbUrl
      avatarUrl
      emailPreferences
    }
  }
`;

const UploadIcon = getIcon("arrow-up-from-bracket", "far");

export interface IAccountBladeProps {}

const ProfileImageSectionContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const StyledUserEmail = styled.p`
  margin: 0px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.colors.warmGrey};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledUserFullName = styled.span`
  margin: 0px;
  font-family: Inter;
  font-size: 26px;
  font-weight: ${props => props.theme.fontWeight.heavy};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.colors.textPrimary};
`;

const StyledContainer = styled.div`
  .text {
    color: ${props => props.theme.colors.textPrimary};
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    width: 100%;
    border-radius: 0 0 150px 150px;
    opacity: 0;
    transition: 0.5s ease;
    margin-top: 40px;
    background-color: ${props => props.theme.colors.lightGrey};
  }
  :hover .overlay {
    opacity: 1;
  }
  height: 80px;
  justify-content: center;
  position: relative;
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 526px;
  width: 339px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
interface IAccountDialogProps {
  id: string;
  userData: {
    name: string;
    email: string;
    avatar_url: string;
    thumb_url: string;
    role: string;
  };
}

interface IAccountDialogState {
  email: string;
  name: string;
  profileImageUrl: string;
  profileImage: File | Blob;
  profileImageLoading: boolean;
  profileImageLink: string;
  loading: boolean;
  imageUrl: string;
  password: string;
  passwordError: string;
  password_confirmation: string;
  password_confirm_error: string;
  current_password: string;
  current_pass_error: string;
}

export class AccountDialog extends DialogBase<
  IModalProps<IAccountDialogProps> & {
    appState?: { onboardingMode: IOnboardingState };
    result: accountDialogQueryResponse;
    retry: () => void;
    theme: any;
  },
  IAccountDialogState
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          minHeight: "320px",
          width: "330px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          border: "none"
        }
      }
    });
  }
  static getAppConnectOptions() {
    return super.getAppConnectOptions({
      selectors: {
        onboardingMode: "onboardingModeSelector"
      }
    });
  }
  state: IAccountDialogState = {
    name: null,
    email: null,
    loading: true,
    imageUrl: "",
    password: "",
    passwordError: "",
    password_confirmation: "",
    password_confirm_error: "",
    current_password: "",
    current_pass_error: "",
    profileImageUrl: "",
    profileImage: null,
    profileImageLoading: false,
    profileImageLink: ""
  };
  componentDidUpdate() {
    if (this.checkOnboardingMode()) {
      const accountInfo = document.getElementById(TourGuideId.AccountInfo);
      if (
        accountInfo &&
        this.props.appState.onboardingMode.index === 0 &&
        this.props.appState.onboardingMode.type === "SetupAccount"
      ) {
        this.props.appDispatch.appActions.onboardingContinue();
        return;
      }
    }
  }
  public componentDidMount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingPause();
  }

  public checkOnboardingMode = () => {
    return this.props.appState.onboardingMode?.status === "active";
  };
  public componentWillUnmount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingEnd();
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "AccountDialog"
    });
  };
  public editProfile = (
    userInfo: accountDialogQueryResponse["currentUser"]
  ) => {
    const gateway = getGateway();
    if (this.state.name) {
      gateway.request
        .editAdminUser(
          {
            email: this.state.email || userInfo.email,
            name: this.state.name || userInfo.name,
            avatar_url: userInfo.avatarUrl,
            thumb_url: userInfo.thumbUrl
          },
          {
            email: this.state.email || userInfo.email,
            name: this.state.name || userInfo.name,
            avatar_url: userInfo.avatarUrl,
            thumb_url: userInfo.thumbUrl,
            id: userInfo.id
          }
        )
        .then(res => {
          if (res.data) {
            if (res && !res.error) {
              renderSuccessNotification("Info changed");
              this.closeModal();
            }
          }
        })
        .catch(error => {
          renderErrorNotification("Info change failed");
          console.error(error);
        });
    }
    if (this.state.current_password && this.state.password_confirmation) {
      const dataToSend = {
        current_password: this.state.current_password,
        password: this.state.password_confirmation,
        password_confirmation: this.state.password_confirmation
      };
      const gateway = getGateway();
      gateway.request
        .changePassword(dataToSend)
        .then(res => {
          console.log(res);
          //@ts-ignore
          if (res?.data?.errors) {
            //@ts-ignore
            const errors = res.data?.errors;
            renderErrorNotification(
              "Validation failed, check the errors on input."
            );
            this.setState({
              ...this.state,
              current_pass_error: _.first(errors?.current_password) || "",
              passwordError: _.first(errors?.password) || "",
              password_confirm_error:
                _.first(errors?.password_confirmation) || ""
            });
            return;
          }
          renderSuccessNotification("Password changed.");
          this.closeModal();
        })
        .catch(err => {
          renderErrorNotification("Password change failed");
          console.error(err);
        });
    }
    return;
  };
  public getTitle = () => {
    return "Edit account";
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };
  public updateField = (
    type?: "current_password" | "password_confirmation"
  ) => (val: string) => {
    if (type === "current_password") {
      this.setState({ current_password: val });
    }
    if (type === "password_confirmation") {
      this.setState({ password_confirmation: val });
    }
  };
  public handleImageChange = async (
    info: UploadChangeParam<UploadFile<any>>,
    userInfo: accountDialogQueryResponse["currentUser"]
  ) => {
    if (info.file.status === "uploading") {
      this.setState({ profileImageLoading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (profileImageUrl, profileImage) =>
        this.setState({
          profileImageUrl,
          profileImage,
          profileImageLoading: false,
          profileImageLink: ""
        })
      );
      const gateway = getGateway();
      if (this.state.profileImage) {
        const formData = new FormData();
        const file = this.state.profileImage;
        formData.append("file", file, _.get(file, "name"));
        const uploadInfo = await gateway.request.uploadAdminUserImage(
          formData,
          {
            id: userInfo.id.toString()
          }
        );
      }
    }
  };
  public beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  };
  public fileAction = (file: RcFile) => {
    return fileActionHandler(file, (profileImageUrl, profileImage, res) =>
      this.setState(
        {
          profileImageUrl,
          profileImage,
          profileImageLoading: false,
          profileImageLink: ""
        },
        () => {
          res(profileImageUrl);
        }
      )
    );
  };
  public renderQueryRenderer = () => {
    const renderAccountDialog = (payload: {
      error: Error;
      props: accountDialogQueryResponse;
      retry: () => void;
    }) => {
      if (payload.error || !_.isObject(payload.props)) {
        return <DialogContainer>{LoaderContainer()}</DialogContainer>;
      }
      const userInfo = _.get(payload.props, "currentUser");
      const avatarName = _.get(userInfo, "name");
      const userEmail = _.get(userInfo, "email");
      const avatarUrl = _.get(userInfo, "avatarUrl", "");

      const isDisabled =
        !this.state.name &&
        (!this.state.current_password || !this.state.password_confirmation);

      return (
        <DialogContainer>
          <DialogTitle>{this.getTitle()}</DialogTitle>
          <Flex style={{ marginBottom: 18 }}>
            <Flex>
              <Flex style={{ flexDirection: "column" }}>
                <Flex justifyContent={"flex-end"}>
                  <ProfileImageSectionContainer>
                    <StyledContainer>
                      {avatarUrl ? (
                        <img
                          src={avatarUrl}
                          alt="avatar"
                          style={{ width: "100%", borderRadius: "50%" }}
                        />
                      ) : (
                        <Flex justifyContent={"center"} alignItems="center">
                          {!this.state.profileImageLoading && (
                            <Avatar
                              size={80}
                              style={{
                                backgroundColor: "#4DBF40",
                                fontSize: "24px"
                              }}
                            >
                              {avatarName
                                ? `${avatarName
                                    .split(" ")
                                    .map(name => name[0])
                                    .join("")
                                    .toUpperCase()}`
                                : "N/A"}
                            </Avatar>
                          )}
                        </Flex>
                      )}
                      <ImgCrop>
                        <Upload
                          showUploadList={false}
                          action={this.fileAction}
                          beforeUpload={this.beforeUpload}
                          onChange={info =>
                            this.handleImageChange(info, userInfo)
                          }
                        >
                          <div className="overlay">
                            <div className="text">
                              <UploadIcon
                                size={20}
                                style={{ display: "block" }}
                              />
                            </div>
                          </div>
                        </Upload>
                      </ImgCrop>
                    </StyledContainer>
                  </ProfileImageSectionContainer>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="center"
              style={{ marginLeft: "8px" }}
            >
              <Flex justifyContent="space-between" id={TourGuideId.AccountInfo}>
                <StyledUserFullName>
                  {userInfo
                    ? avatarName === null
                      ? "N/A"
                      : avatarName
                    : "N/A"}
                </StyledUserFullName>
              </Flex>

              <Tooltip title={userEmail}>
                <StyledUserEmail>{userInfo ? userEmail : ""}</StyledUserEmail>
              </Tooltip>
            </Flex>
          </Flex>
          <Flex
            flex={1}
            marginRight={20}
            marginBottom={10}
            flexDirection={"column"}
          >
            <ElmInput
              label={"Full name"}
              placeholder="John Doe"
              style={{ marginTop: "15px" }}
              defaultValue={avatarName}
              updatedValue={this.updateName}
            />
            <ElmInput
              label={"Email"}
              placeholder="exampleˇ@email.com"
              style={{ marginTop: "15px", opacity: 0.5 }}
              disabled={true}
              defaultValue={userEmail}
            />
          </Flex>
          <Flex
            flex={1}
            marginRight={20}
            flexDirection="column"
            style={{ marginBottom: "30px" }}
          >
            <SectionTitle style={{ marginBottom: 18, marginTop: 12 }}>
              Change password
            </SectionTitle>
            <Flex flexDirection="column" style={{ gap: 12 }}>
              <ElmInput
                label={"Current Password"}
                value={this.state.current_password}
                type="password"
                error={!!this.state.current_pass_error}
                errorMessage={this.state.current_pass_error}
                placeholder="********"
                controlled={true}
                autoComplete="current-password"
                updatedValue={this.updateField("current_password")}
              />

              <ElmInput
                label={"New Password"}
                value={this.state.password_confirmation}
                error={!!this.state.password_confirm_error}
                errorMessage={this.state.password_confirm_error}
                placeholder="********"
                type="password"
                controlled={true}
                autoComplete="new-password"
                updatedValue={this.updateField("password_confirmation")}
              />
            </Flex>
          </Flex>

          <Flex flex={1} alignItems="flex-end" justifyContent={"flex-end"}>
            {this.checkOnboardingMode() ? null : (
              <ElmButton
                label={"Cancel"}
                colorVariance={"outline-secondary"}
                onClick={this.closeModal}
              />
            )}
            <ElmButton
              colorVariance="primary"
              label={"Save"}
              onClick={() => this.editProfile(userInfo)}
              disabled={isDisabled}
            />
          </Flex>
        </DialogContainer>
      );
    };
    return (
      <QueryRenderer<accountDialogQuery>
        variables={{}}
        environment={relayEnvironment}
        query={graphqlQuery}
        render={renderAccountDialog}
      />
    );
  };

  public render() {
    return this.renderQueryRenderer();
  }
}
