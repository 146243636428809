/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licensesTable_licenses = {
  readonly licensesCount: number;
  readonly productNames?:
    | {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly product: {
              readonly name: string | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | null
    | undefined;
  readonly companyNames?:
    | {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly owner: {
              readonly name: string | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | null
    | undefined;
  readonly licenses: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly key: string;
        readonly owner: {
          readonly name: string | null;
        } | null;
        readonly product: {
          readonly id: string;
          readonly name?: string | null | undefined;
          readonly iconUrl?: string | null | undefined;
        } | null;
        readonly type: {
          readonly id: string;
        };
        readonly commercialModel: {
          readonly name: string;
        } | null;
        readonly isTrial: boolean;
        readonly isVendor: boolean;
        readonly isExpired: boolean;
        readonly createdAt: unknown;
        readonly latestTerm: {
          readonly endDate: unknown | null;
        } | null;
        readonly activeEntitlements: {
          readonly nodes: ReadonlyArray<{
            readonly latestTerm: {
              readonly endDate: unknown | null;
            } | null;
          } | null> | null;
        } | null;
        readonly totalSeatCount?: number | null | undefined;
        readonly numTotalUsers?: number | undefined;
        readonly numTotalInstances?: number | undefined;
        readonly instances?:
          | {
              readonly nodes: ReadonlyArray<{
                readonly active: boolean;
              } | null> | null;
            }
          | null
          | undefined;
        readonly numActiveUsers?: number | undefined;
        readonly approved: boolean | null;
        readonly licenseActions?:
          | {
              readonly nodes: ReadonlyArray<{
                readonly id: string;
                readonly action: string;
                readonly status: number;
              } | null> | null;
            }
          | null
          | undefined;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $refType": "licensesTable_licenses";
};
export type licensesTable_licenses$data = licensesTable_licenses;
export type licensesTable_licenses$key = {
  readonly " $data"?: licensesTable_licenses$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"licensesTable_licenses">;
};

const node: ReaderFragment = (function() {
  var v0 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    v1 = {
      kind: "Variable",
      name: "filterColumn",
      variableName: "filterColumn"
    } as any,
    v2 = {
      kind: "Variable",
      name: "filterOp",
      variableName: "filterOp"
    } as any,
    v3 = {
      kind: "Variable",
      name: "filterValues",
      variableName: "filterValues"
    } as any,
    v4 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v7 = [v6 /*: any*/],
    v8 = {
      alias: null,
      args: null,
      concreteType: null,
      kind: "LinkedField",
      name: "owner",
      plural: false,
      selections: v7 /*: any*/,
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      concreteType: "Term",
      kind: "LinkedField",
      name: "latestTerm",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "endDate",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v10 = [v0 /*: any*/];
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "blade_scope"
      },
      {
        defaultValue: 25,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "filterColumn"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "filterOp"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "filterValues"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      },
      {
        defaultValue: false,
        kind: "LocalArgument",
        name: "skipActionsColumn"
      },
      {
        defaultValue: false,
        kind: "LocalArgument",
        name: "skipCompany"
      },
      {
        defaultValue: false,
        kind: "LocalArgument",
        name: "skipProduct"
      },
      {
        defaultValue: false,
        kind: "LocalArgument",
        name: "skipUsageColumn"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "sortColumn"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "sortDirection"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["licenses"]
        }
      ]
    },
    name: "licensesTable_licenses",
    selections: [
      {
        alias: null,
        args: [
          v0 /*: any*/,
          v1 /*: any*/,
          v2 /*: any*/,
          v3 /*: any*/,
          v4 /*: any*/
        ],
        kind: "ScalarField",
        name: "licensesCount",
        storageKey: null
      },
      {
        alias: "licenses",
        args: [
          v0 /*: any*/,
          v1 /*: any*/,
          v2 /*: any*/,
          v3 /*: any*/,
          v4 /*: any*/,
          {
            kind: "Variable",
            name: "sortColumn",
            variableName: "sortColumn"
          },
          {
            kind: "Variable",
            name: "sortDirection",
            variableName: "sortDirection"
          }
        ],
        concreteType: "LicenseConnection",
        kind: "LinkedField",
        name: "__licensesTable_licenses_connection",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "LicenseEdge",
            kind: "LinkedField",
            name: "edges",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "License",
                kind: "LinkedField",
                name: "node",
                plural: false,
                selections: [
                  v5 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "key",
                    storageKey: null
                  },
                  v8 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    concreteType: "Product",
                    kind: "LinkedField",
                    name: "product",
                    plural: false,
                    selections: [
                      v5 /*: any*/,
                      {
                        condition: "skipProduct",
                        kind: "Condition",
                        passingValue: false,
                        selections: [
                          v6 /*: any*/,
                          {
                            alias: null,
                            args: null,
                            kind: "ScalarField",
                            name: "iconUrl",
                            storageKey: null
                          }
                        ]
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: "LicenseModel",
                    kind: "LinkedField",
                    name: "type",
                    plural: false,
                    selections: [v5 /*: any*/],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: "CommercialModel",
                    kind: "LinkedField",
                    name: "commercialModel",
                    plural: false,
                    selections: v7 /*: any*/,
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "isTrial",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "isVendor",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "isExpired",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "createdAt",
                    storageKey: null
                  },
                  v9 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    concreteType: "EntitlementConnection",
                    kind: "LinkedField",
                    name: "activeEntitlements",
                    plural: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        concreteType: "Entitlement",
                        kind: "LinkedField",
                        name: "nodes",
                        plural: true,
                        selections: [v9 /*: any*/],
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "approved",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "__typename",
                    storageKey: null
                  },
                  {
                    condition: "skipUsageColumn",
                    kind: "Condition",
                    passingValue: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "totalSeatCount",
                        storageKey: null
                      },
                      {
                        alias: "numTotalUsers",
                        args: null,
                        kind: "ScalarField",
                        name: "usersCount",
                        storageKey: null
                      },
                      {
                        alias: "numTotalInstances",
                        args: null,
                        kind: "ScalarField",
                        name: "instancesCount",
                        storageKey: null
                      },
                      {
                        alias: null,
                        args: null,
                        concreteType: "InstanceConnection",
                        kind: "LinkedField",
                        name: "instances",
                        plural: false,
                        selections: [
                          {
                            alias: null,
                            args: null,
                            concreteType: "Instance",
                            kind: "LinkedField",
                            name: "nodes",
                            plural: true,
                            selections: [
                              {
                                alias: null,
                                args: null,
                                kind: "ScalarField",
                                name: "active",
                                storageKey: null
                              }
                            ],
                            storageKey: null
                          }
                        ],
                        storageKey: null
                      },
                      {
                        alias: "numActiveUsers",
                        args: [
                          {
                            kind: "Literal",
                            name: "filterColumn",
                            value: ["users.active"]
                          },
                          {
                            kind: "Literal",
                            name: "filterOp",
                            value: [["="]]
                          },
                          {
                            kind: "Literal",
                            name: "filterValues",
                            value: [["true"]]
                          }
                        ],
                        kind: "ScalarField",
                        name: "usersCount",
                        storageKey:
                          'usersCount(filterColumn:["users.active"],filterOp:[["="]],filterValues:[["true"]])'
                      }
                    ]
                  },
                  {
                    condition: "skipActionsColumn",
                    kind: "Condition",
                    passingValue: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        concreteType: "LicenseActionConnection",
                        kind: "LinkedField",
                        name: "licenseActions",
                        plural: false,
                        selections: [
                          {
                            alias: null,
                            args: null,
                            concreteType: "LicenseAction",
                            kind: "LinkedField",
                            name: "nodes",
                            plural: true,
                            selections: [
                              v5 /*: any*/,
                              {
                                alias: null,
                                args: null,
                                kind: "ScalarField",
                                name: "action",
                                storageKey: null
                              },
                              {
                                alias: null,
                                args: null,
                                kind: "ScalarField",
                                name: "status",
                                storageKey: null
                              }
                            ],
                            storageKey: null
                          }
                        ],
                        storageKey: null
                      }
                    ]
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "cursor",
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: "PageInfo",
            kind: "LinkedField",
            name: "pageInfo",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "hasNextPage",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "endCursor",
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        condition: "skipProduct",
        kind: "Condition",
        passingValue: false,
        selections: [
          {
            alias: "productNames",
            args: v10 /*: any*/,
            concreteType: "LicenseConnection",
            kind: "LinkedField",
            name: "licenses",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "LicenseEdge",
                kind: "LinkedField",
                name: "edges",
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: "License",
                    kind: "LinkedField",
                    name: "node",
                    plural: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        concreteType: "Product",
                        kind: "LinkedField",
                        name: "product",
                        plural: false,
                        selections: v7 /*: any*/,
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  }
                ],
                storageKey: null
              }
            ],
            storageKey: null
          }
        ]
      },
      {
        condition: "skipCompany",
        kind: "Condition",
        passingValue: false,
        selections: [
          {
            alias: "companyNames",
            args: v10 /*: any*/,
            concreteType: "LicenseConnection",
            kind: "LinkedField",
            name: "licenses",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "LicenseEdge",
                kind: "LinkedField",
                name: "edges",
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: "License",
                    kind: "LinkedField",
                    name: "node",
                    plural: false,
                    selections: [v8 /*: any*/],
                    storageKey: null
                  }
                ],
                storageKey: null
              }
            ],
            storageKey: null
          }
        ]
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "ddd02b6f5c36e2925d54a61c5699d646";
export default node;
