/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EntitlementQueryVariables = {
  productId: string;
  entitlementId: string;
  skip: boolean;
};
export type EntitlementQueryResponse = {
  readonly product: {
    readonly name: string | null;
    readonly components: {
      readonly nodes: ReadonlyArray<{
        readonly name: string | null;
        readonly componentType: string;
        readonly id: string;
      } | null> | null;
    } | null;
    readonly componentGroups: {
      readonly nodes: ReadonlyArray<{
        readonly name: string | null;
        readonly id: string;
        readonly componentType: string;
        readonly components: {
          readonly nodes: ReadonlyArray<{
            readonly name: string | null;
            readonly id: string;
            readonly componentType: string;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly componentEntitlement?:
    | {
        readonly tokenCount: number | null;
        readonly currencyCount: number | null;
        readonly sessionCount: number | null;
        readonly numCurrencyUsed: number | null;
        readonly terms: {
          readonly nodes: ReadonlyArray<{
            readonly startDate: unknown | null;
            readonly endDate: unknown | null;
          } | null> | null;
        } | null;
        readonly components: {
          readonly nodes: ReadonlyArray<{
            readonly name: string | null;
            readonly componentType: string;
            readonly componentGroup: {
              readonly name: string | null;
            } | null;
            readonly id: string;
          } | null> | null;
        } | null;
        readonly componentGroup: {
          readonly id: string;
          readonly name: string | null;
          readonly componentType: string;
          readonly components: {
            readonly nodes: ReadonlyArray<{
              readonly name: string | null;
              readonly componentType: string;
              readonly componentGroup: {
                readonly name: string | null;
              } | null;
              readonly id: string;
            } | null> | null;
          } | null;
        } | null;
        readonly latestTerm: {
          readonly endDate: unknown | null;
          readonly startDate: unknown | null;
          readonly isActive: boolean;
        } | null;
      }
    | null
    | undefined;
};
export type EntitlementQuery = {
  readonly response: EntitlementQueryResponse;
  readonly variables: EntitlementQueryVariables;
};

/*
query EntitlementQuery(
  $productId: ID!
  $entitlementId: ID!
  $skip: Boolean!
) {
  product(id: $productId) {
    name
    components {
      nodes {
        name
        componentType
        id
      }
    }
    componentGroups {
      nodes {
        name
        id
        componentType
        components {
          nodes {
            name
            id
            componentType
          }
        }
      }
    }
    id
  }
  componentEntitlement(id: $entitlementId) @skip(if: $skip) {
    tokenCount
    currencyCount
    sessionCount
    numCurrencyUsed
    terms {
      nodes {
        startDate
        endDate
      }
    }
    components {
      nodes {
        name
        componentType
        componentGroup {
          name
          id
        }
        id
      }
    }
    componentGroup {
      id
      name
      componentType
      components {
        nodes {
          name
          componentType
          componentGroup {
            name
            id
          }
          id
        }
      }
    }
    latestTerm {
      endDate
      startDate
      isActive
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "entitlementId"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "productId"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "skip"
    } as any,
    v3 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "productId"
      } as any
    ],
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "componentType",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v4 /*: any*/, v5 /*: any*/, v6 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      concreteType: "ComponentGroupConnection",
      kind: "LinkedField",
      name: "componentGroups",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ComponentGroup",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v4 /*: any*/,
            v6 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentConnection",
              kind: "LinkedField",
              name: "components",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Component",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [v4 /*: any*/, v6 /*: any*/, v5 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v9 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "entitlementId"
      } as any
    ],
    v10 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "tokenCount",
      storageKey: null
    } as any,
    v11 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "currencyCount",
      storageKey: null
    } as any,
    v12 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "sessionCount",
      storageKey: null
    } as any,
    v13 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "numCurrencyUsed",
      storageKey: null
    } as any,
    v14 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "startDate",
      storageKey: null
    } as any,
    v15 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "endDate",
      storageKey: null
    } as any,
    v16 = {
      alias: null,
      args: null,
      concreteType: "TermConnection",
      kind: "LinkedField",
      name: "terms",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Term",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v14 /*: any*/, v15 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v17 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v4 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentGroup",
              kind: "LinkedField",
              name: "componentGroup",
              plural: false,
              selections: [v4 /*: any*/],
              storageKey: null
            },
            v6 /*: any*/
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v18 = {
      alias: null,
      args: null,
      concreteType: "Term",
      kind: "LinkedField",
      name: "latestTerm",
      plural: false,
      selections: [
        v15 /*: any*/,
        v14 /*: any*/,
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "isActive",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v19 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v4 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentGroup",
              kind: "LinkedField",
              name: "componentGroup",
              plural: false,
              selections: [v4 /*: any*/, v6 /*: any*/],
              storageKey: null
            },
            v6 /*: any*/
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: "Fragment",
      metadata: null,
      name: "EntitlementQuery",
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v4 /*: any*/, v7 /*: any*/, v8 /*: any*/],
          storageKey: null
        },
        {
          condition: "skip",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v9 /*: any*/,
              concreteType: "ComponentEntitlement",
              kind: "LinkedField",
              name: "componentEntitlement",
              plural: false,
              selections: [
                v10 /*: any*/,
                v11 /*: any*/,
                v12 /*: any*/,
                v13 /*: any*/,
                v16 /*: any*/,
                v17 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "ComponentGroup",
                  kind: "LinkedField",
                  name: "componentGroup",
                  plural: false,
                  selections: [
                    v6 /*: any*/,
                    v4 /*: any*/,
                    v5 /*: any*/,
                    v17 /*: any*/
                  ],
                  storageKey: null
                },
                v18 /*: any*/
              ],
              storageKey: null
            }
          ]
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/, v2 /*: any*/],
      kind: "Operation",
      name: "EntitlementQuery",
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v4 /*: any*/, v7 /*: any*/, v8 /*: any*/, v6 /*: any*/],
          storageKey: null
        },
        {
          condition: "skip",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v9 /*: any*/,
              concreteType: "ComponentEntitlement",
              kind: "LinkedField",
              name: "componentEntitlement",
              plural: false,
              selections: [
                v10 /*: any*/,
                v11 /*: any*/,
                v12 /*: any*/,
                v13 /*: any*/,
                v16 /*: any*/,
                v19 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "ComponentGroup",
                  kind: "LinkedField",
                  name: "componentGroup",
                  plural: false,
                  selections: [
                    v6 /*: any*/,
                    v4 /*: any*/,
                    v5 /*: any*/,
                    v19 /*: any*/
                  ],
                  storageKey: null
                },
                v18 /*: any*/,
                v6 /*: any*/
              ],
              storageKey: null
            }
          ]
        }
      ]
    },
    params: {
      cacheID: "3f4c2110452a5455d70102ee389bdacb",
      id: null,
      metadata: {},
      name: "EntitlementQuery",
      operationKind: "query",
      text:
        "query EntitlementQuery(\n  $productId: ID!\n  $entitlementId: ID!\n  $skip: Boolean!\n) {\n  product(id: $productId) {\n    name\n    components {\n      nodes {\n        name\n        componentType\n        id\n      }\n    }\n    componentGroups {\n      nodes {\n        name\n        id\n        componentType\n        components {\n          nodes {\n            name\n            id\n            componentType\n          }\n        }\n      }\n    }\n    id\n  }\n  componentEntitlement(id: $entitlementId) @skip(if: $skip) {\n    tokenCount\n    currencyCount\n    sessionCount\n    numCurrencyUsed\n    terms {\n      nodes {\n        startDate\n        endDate\n      }\n    }\n    components {\n      nodes {\n        name\n        componentType\n        componentGroup {\n          name\n          id\n        }\n        id\n      }\n    }\n    componentGroup {\n      id\n      name\n      componentType\n      components {\n        nodes {\n          name\n          componentType\n          componentGroup {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n    latestTerm {\n      endDate\n      startDate\n      isActive\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "70181e3bbea90560c52ae4eb36e056dc";
export default node;
