/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type usersAnalyticsRegistrationQueryVariables = {
  count?: number | null | undefined;
  productId: string;
};
export type usersAnalyticsRegistrationQueryResponse = {
  readonly registrationEntries: {
    readonly nodes: ReadonlyArray<{
      readonly user: {
        readonly displayName: string | null;
      } | null;
      readonly license: {
        readonly product: {
          readonly name: string | null;
          readonly iconUrl: string | null;
        } | null;
        readonly owner: {
          readonly name: string | null;
        } | null;
      } | null;
      readonly createdAt: unknown;
    } | null> | null;
  } | null;
};
export type usersAnalyticsRegistrationQuery = {
  readonly response: usersAnalyticsRegistrationQueryResponse;
  readonly variables: usersAnalyticsRegistrationQueryVariables;
};

/*
query usersAnalyticsRegistrationQuery(
  $count: Int
  $productId: String!
) {
  registrationEntries(first: $count, bladeScope: $productId, sortColumn: "createdAt", sortDirection: "DESC", filterColumn: ["licenses.is_vendor"], filterOp: [["="]], filterValues: [["false"]]) {
    nodes {
      user {
        displayName
        id
      }
      license {
        product {
          name
          iconUrl
          id
        }
        owner {
          __typename
          name
          id
        }
        id
      }
      createdAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "count"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "productId"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "productId"
      } as any,
      {
        kind: "Literal",
        name: "filterColumn",
        value: ["licenses.is_vendor"]
      } as any,
      {
        kind: "Literal",
        name: "filterOp",
        value: [["="]]
      } as any,
      {
        kind: "Literal",
        name: "filterValues",
        value: [["false"]]
      } as any,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      {
        kind: "Literal",
        name: "sortColumn",
        value: "createdAt"
      } as any,
      {
        kind: "Literal",
        name: "sortDirection",
        value: "DESC"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "displayName",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "iconUrl",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "createdAt",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "usersAnalyticsRegistrationQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "RegistrationEntryConnection",
          kind: "LinkedField",
          name: "registrationEntries",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "RegistrationEntry",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "User",
                  kind: "LinkedField",
                  name: "user",
                  plural: false,
                  selections: [v2 /*: any*/],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "License",
                  kind: "LinkedField",
                  name: "license",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Product",
                      kind: "LinkedField",
                      name: "product",
                      plural: false,
                      selections: [v3 /*: any*/, v4 /*: any*/],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: "LinkedField",
                      name: "owner",
                      plural: false,
                      selections: [v3 /*: any*/],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                v5 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "usersAnalyticsRegistrationQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "RegistrationEntryConnection",
          kind: "LinkedField",
          name: "registrationEntries",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "RegistrationEntry",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "User",
                  kind: "LinkedField",
                  name: "user",
                  plural: false,
                  selections: [v2 /*: any*/, v6 /*: any*/],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "License",
                  kind: "LinkedField",
                  name: "license",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Product",
                      kind: "LinkedField",
                      name: "product",
                      plural: false,
                      selections: [v3 /*: any*/, v4 /*: any*/, v6 /*: any*/],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: "LinkedField",
                      name: "owner",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        },
                        v3 /*: any*/,
                        v6 /*: any*/
                      ],
                      storageKey: null
                    },
                    v6 /*: any*/
                  ],
                  storageKey: null
                },
                v5 /*: any*/,
                v6 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "66387b6de7301cb9eb07ca96e8815067",
      id: null,
      metadata: {},
      name: "usersAnalyticsRegistrationQuery",
      operationKind: "query",
      text:
        'query usersAnalyticsRegistrationQuery(\n  $count: Int\n  $productId: String!\n) {\n  registrationEntries(first: $count, bladeScope: $productId, sortColumn: "createdAt", sortDirection: "DESC", filterColumn: ["licenses.is_vendor"], filterOp: [["="]], filterValues: [["false"]]) {\n    nodes {\n      user {\n        displayName\n        id\n      }\n      license {\n        product {\n          name\n          iconUrl\n          id\n        }\n        owner {\n          __typename\n          name\n          id\n        }\n        id\n      }\n      createdAt\n      id\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "a369b2d2685826beef731a5c6a0f1bde";
export default node;
