import * as _ from "lodash";
import * as React from "react";
import { Cell } from "react-table";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { Alert, RadioChangeEvent } from "antd";
import ElmCheckBox from "../../../../components/elmCheckBox";
import { getGateway } from "../../../../api";
import ElmRadio from "../../../../components/elmRadio";
import { ElmInput } from "../../../../components";
import { ElmDatePickerWithLabel } from "../../../../components/elmDate";
import dayjs, { Dayjs } from "dayjs";
import { DATE_FORMAT_ALT } from "const";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 254px;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;

export const InfoMessage = styled(Alert)`
  width: 290px;
  border-left: 3px solid #ffa033;
  border-radius: 0;
  & .ant-alert-description {
    font-size: 12px !important;
    line-height: 16px;
    color: ${props => props.theme.colors.alertDescription};
    backgroundcolor: #ffefdc;
  }
`;
export interface IEditComponentState {
  selected?: boolean;
  version?: string;
  isInherited?: boolean;
  versionType?: string;
  tokenCost: number;
  includeFreeTrial: boolean;
  freeTrialDate: string;
}
export class EditComponentDialog extends DialogBase<
  IModalProps,
  IEditComponentState
> {
  private tableColumns = [
    {
      Header: "Release Configuration",
      accessor: "model"
    },
    {
      Header: "Current Release",
      accessor: "model"
    },
    {
      Header: "Channel",
      accessor: "product"
    }
  ];
  constructor(props: IModalProps) {
    super(props);
    this.state = {
      selected: props?.payload?.selected,
      isInherited: props?.payload?.isInherited,
      versionType: props?.payload?.isInherited ? "inherited" : "custom",
      tokenCost: props?.payload?.tokenCount,
      includeFreeTrial: props?.payload?.freeTrial,
      freeTrialDate: props?.payload?.freeTrialDate || null
    };
  }
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          //height: "284px",
          width: "330px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public renderTableCell = (cell: Cell, index: number) => {
    return <p>{Math.random()}</p>;
  };
  public updateType = (e: RadioChangeEvent) => {
    this.setState({ versionType: e.target.value });
  };
  public toggleExclude = (e: RadioChangeEvent) => {
    this.setState({ selected: e.target.checked });
  };
  public toggleFreeTrial = (e: RadioChangeEvent) => {
    this.setState({ includeFreeTrial: e.target.checked });
  };

  public renderFormContent = () => {
    const updateTokenCost = (tokenCost: number) => {
      this.setState({ tokenCost });
    };

    const customEdit = () => {
      const onDateChange = (m: Dayjs) => {
        this.setState({
          freeTrialDate: m.format(DATE_FORMAT_ALT)
          // entitlement: {
          //   ...(this.state?.entitlement || {}),
          //   [type]: m.format("YYYY-MM-DD")
          // }
        });
      };
      return (
        <Flex flexDirection="column" style={{ marginTop: "15px" }}>
          <ElmCheckBox
            onChange={this.toggleExclude}
            defaultChecked={this.state.selected}
          >
            Exclude component from version
          </ElmCheckBox>
          {!this.state.selected && (
            <Flex flexDirection="column" marginBottom={"15px"}>
              <ElmInput
                defaultValue={this.state.tokenCost}
                label="Token cost"
                type="number"
                isDecimalNumber={true}
                updatedValue={val => updateTokenCost(+val)}
              />
              <ElmCheckBox
                onChange={this.toggleFreeTrial}
                defaultChecked={this.state.includeFreeTrial}
              >
                Include free trial
              </ElmCheckBox>
              {this.state.includeFreeTrial && (
                <ElmDatePickerWithLabel
                  defaultValue={
                    this.state.freeTrialDate
                      ? dayjs(this.state.freeTrialDate)
                      : null
                  }
                  onChange={onDateChange}
                  label="Free trial expiration (local time)"
                />
              )}
            </Flex>
          )}
        </Flex>
      );
    };

    return (
      <Flex flexDirection={"column"} style={{ marginTop: "16px" }}>
        {/* {this.state.isInherited ? ( */}
        <Flex flexDirection={"column"}>
          <ElmRadio.Group
            value={this.state.versionType}
            onChange={this.updateType}
          >
            {_.map(
              [
                {
                  text: "Same as previous version",
                  key: "inherited"
                },
                {
                  text: "Edit this version",
                  key: "custom"
                }
              ],
              op => {
                return <ElmRadio value={op.key}>{op.text}</ElmRadio>;
              }
            )}
          </ElmRadio.Group>
          {this.state.versionType === "custom" && customEdit()}
        </Flex>
        {/* ) : ( */}
        {/* {customEdit()} */}
        {/* )} */}
      </Flex>
    );
  };

  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditComponentDialog"
    });
  };

  public saveComponentVersion = () => {
    const editComponent = {
      free_trial: this.state.includeFreeTrial,
      free_trial_expiration: this.state.freeTrialDate,
      is_included: !this.state.selected,
      product_version_id: this.props.payload.product_version_id,
      token_count: this.state.tokenCost,
      is_inherited: this.props.payload?.licenseId
        ? false
        : this.state.versionType === "inherited"
        ? true
        : false
    };
    const gateway = getGateway();
    if (this.props.payload?.licenseId) {
      editComponent["license_id"] = this.props.payload?.licenseId;
    }
    gateway.request[
      this.props.payload?.licenseId
        ? "editLicenseComponentRequirement"
        : "editComponentRequirement"
    ](editComponent, {
      id: this.props.payload.component_id
    })
      .then(res => {
        if (_.get(res, "error")) {
          //this.setState({ error: true, disabled: true });
        } else {
          if (_.isFunction(this.props.payload.onConfirm)) {
            this.props.payload.onConfirm();
          }
          renderSuccessNotification(
            `Updated ${
              this.props.payload?.licenseId ? "license " : " "
            } component requirements.`
          );
          this.closeModal();
        }
      })
      .catch(err => {
        renderErrorNotification("Failed to update requirements");
        console.error(err);
      });
  };

  public render() {
    return (
      <DialogContainer>
        <DialogTitle>
          Edit Version {this.props?.payload?.version || ""}
        </DialogTitle>
        <InfoMessage
          //message="Warning Text"
          description="Editing the requirement for this version will affect any subsequent versions inherited from this version."
          type="warning"
        />
        <Flex flexDirection="column">{this.renderFormContent()}</Flex>

        <Flex justifyContent={"flex-end"} flex={1} flexDirection={"column"}>
          <Flex flexDirection="row" justifyContent={"flex-end"}>
            <ElmButton
              label={"Cancel"}
              onClick={this.closeModal}
              colorVariance="outline-secondary"
            />
            <Flex marginRight={10}>
              <ElmButton
                onClick={this.saveComponentVersion}
                colorVariance="primary"
                label={"Save changes"}
              />
            </Flex>
          </Flex>
        </Flex>
      </DialogContainer>
    );
  }
}
export default EditComponentDialog;
