import { SelectProps } from "antd/lib/select";
import _ from "lodash";
import React from "react";
import { licenseTypeTemplates } from "../../../utils";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../components/elmSelect";
import { getClassName } from "../../../components/helpers";

export const decodeProductTypeId = (id: string) => {
  let retVal = "";
  try {
    let decoded = atob(id);
    retVal = decoded.slice(decoded.indexOf("-") + 1);
  } catch (e) {
    //if decoded fails there's a chance that the actual id is one of the following 1,2,3,4 and it can be used like that.
    retVal = id;
  }
  return retVal;
};

export const LicenseEnforcementSelector = (
  props: SelectProps<any> & {
    className?: string;
    updateLicenseType: (licenseTypeId: any) => void;
    product: { type: { id: string } };
    label?: string;
    error?: boolean;
    errorMessage?: string;
    required?: boolean;
    useConversionRestriction?: boolean;
    selectedEnfocrmentModelId?: number;
  }
) => {
  let types = null;
  if (_.isObject(props.product)) {
    const enforcementModel = _.find(
      licenseTypeTemplates,
      licenseType =>
        licenseType.product_type_id.toString() ===
        decodeProductTypeId(props.product.type.id)
    );
    if (props.useConversionRestriction) {
      const allowedModelIds =
        _.find(
          enforcementModel.license_types,
          type => type.id === props.selectedEnfocrmentModelId
        )?.conversion_restrictions || [];
      const allowedLicenseTypes = enforcementModel.license_types.filter(model =>
        allowedModelIds.includes(model.id)
      );
      enforcementModel.license_types = allowedLicenseTypes;
    }
    if (_.isObject(enforcementModel)) {
      types = _.map(enforcementModel.license_types, licenseType => {
        return (
          <ElmSelectOption value={licenseType.id} label={licenseType.name}>
            {licenseType.name}
          </ElmSelectOption>
        );
      });
    }
  }
  // @ts-ignore
  return (
    <ElmSelectWithLabel
      {...props}
      onChange={props.updateLicenseType}
      className={getClassName({
        "elm-license-enforcement-selector": () => true,
        [props.className]: () => !!props.className,
        subtle: () => true
      })}
    >
      {types}
    </ElmSelectWithLabel>
  );
};
export default LicenseEnforcementSelector;
