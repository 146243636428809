import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenProductReleaseBlade
} from "../../../components/bladeManager/types";
import {
  BladeTemplate,
  BladeTitle,
  ElmButton,
  getIcon,
  TabView
} from "../../../components";
import { IAttributValuePair } from "../../../components/attributesBox";
import {
  ProductReleaseQuery,
  ProductReleaseQueryResponse
} from "./__generated__/ProductReleaseQuery.graphql";

import { deepEqual } from "fast-equals";
import { Input } from "antd";
import { ITab } from "components/tabView";
import styled, { useTheme } from "styled-components";
import theme from "../../../theme";
import { updateBladeUrlState } from "utils";

const { TextArea } = Input;

const graphqlQuery = graphql`
  query ProductReleaseQuery($id: ID!) {
    release(id: $id) {
      tag
      data
      releaseConfiguration {
        product {
          iconUrl
        }
      }
    }
  }
`;

const StyledProductComponentInfo = styled(Flex)`
  color: ${props => props.theme.colors.textPrimary};
`;
export interface IProductReleaseBladeProps
  extends IBladeBaseProps<IOpenProductReleaseBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}
export type state = {
  mode: "edit" | "normal";
  activeTabIndex: number;
};
export class ProductReleaseBlade extends React.Component<
  IProductReleaseBladeProps & {
    result: ProductReleaseQueryResponse;
    retry: () => void;
    theme: any;
  },
  state
> {
  state: state = {
    mode: "normal",
    activeTabIndex: 0
  };
  public shouldComponentUpdate(
    nextProps: IProductReleaseBladeProps & {
      result: ProductReleaseQueryResponse;
    },
    nextState: state
  ) {
    return (
      !deepEqual(this.state, nextState) ||
      !deepEqual(this.props.routeData, nextProps.routeData) ||
      !deepEqual(this.props.result, nextProps.result)
    );
  }
  public renderLeftSideHeader = (info: ProductReleaseQueryResponse) => () => {
    return (
      <Flex>
        <BladeTitle>{_.get(info, "release.tag")}</BladeTitle>
      </Flex>
    );
  };
  public navAway = () => {
    this.props.openBlade({
      routeName: "Customer portal",
      fromBladeIndex: this.props.index,
      route: "Overview",
      routeData: null
    });
  };

  public renderRightSideHeader = () => {
    const showEditReleaseDialog = () => {
      this.props.openDialog("NewProductReleaseDialog", {
        tag: this.props?.result?.release?.tag,
        data: this.props?.result?.release?.data,
        isEdit: true,
        onConfirm: () => {
          this.props.refreshAllOpenBlades();
        },
        configurationId: this.props.routeData.id
      });
    };

    const openDeleteDialog = () => {
      this.props.openDialog("DeleteDialog", {
        entityType: "Release",
        name: this.props?.result?.release?.tag,
        onConfirm: () => {
          this.props.gateway.request
            .deleteProductRelease(null, { id: this.props.routeData.id })
            .then(res => {
              if (!_.get(res, "error")) {
                this.props.closeDialog("DeleteDialog");
                this.props.closeBlade({ route: "ProductRelease" });
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
    };

    return (
      <Flex>
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon="pen"
          iconPrefix="fas"
          label={"Edit"}
          permissions="modify_assets"
          onClick={() => showEditReleaseDialog()}
        />

        <ElmButton
          style={{
            color: this.props.theme.colors.trashButtonColor
          }}
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon={"trash"}
          iconPrefix={"fas"}
          label={"Delete"}
          permissions="modify_assets"
          onClick={() => openDeleteDialog()}
        />
      </Flex>
    );
  };
  public renderAttributes = () => {
    const TagIcon = styled(getIcon("IoMdPricetag"))`
      color: ${props => props.theme.colors.productsBlue};
    `;
    //const CloseIcon = styled(closeIcon)``;
    const items = [
      {
        label: "Tag",
        value: _.get(this.props.result, "release.tag")
      },
      {
        label: "Active On: ",
        value: _.get(this.props.result, "release.tag")
      }
    ] as IAttributValuePair[];
    const renderItems = () => {
      if (_.isArray(items)) {
        return (
          <StyledProductComponentInfo>
            <Flex
              flexWrap="wrap"
              flex={1}
              style={_.merge(
                {
                  height: "max-content",
                  fontSize: "12px",
                  fontWeight: 400
                }
                //this.props.containerStyle
              )}
            >
              <div style={{ marginRight: "12px" }}>
                <TagIcon />
                <span style={{ color: theme.colors.warmGrey }}>
                  {items[0]?.value || "N/A"}
                </span>
              </div>
              <div style={{ marginRight: "12px" }}>
                {items[1].label}
                <span style={{ color: theme.colors.warmGrey }}>
                  {items[1]?.value || "N/A"}
                </span>
              </div>
            </Flex>
          </StyledProductComponentInfo>
        );
      }
      return null;
    };
    return (
      <div
        style={{
          padding: "10px 10px 10px 0px",
          //flex: "1",
          position: "relative"
        }}
      >
        {renderItems()}
      </div>
    );
  };

  public renderData = () => {
    return (
      <TextArea
        showCount
        disabled
        maxLength={250}
        value={_.get(this.props.result, "release.data")}
        style={{ height: 120, width: "100%", resize: "none" }}
        //onChange={onChange}
        placeholder="disable resize"
      />
    );
  };

  public getTabs = () => {
    let ret = [];
    ret.push({
      title: "Data",
      Component: this.renderData()
    });
    ret.push({
      title: "History",
      Component: () => <div>History table</div> //this.renderData()
    });

    return ret as ITab[];
  };

  public updateActiveTab = (activeTabIndex: number) => {
    updateBladeUrlState(this.props, activeTabIndex);
    this.setState({
      activeTabIndex
    });
  };

  public renderProductInfo = () => {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Releases"}
        bladeTypeName={"Releases"}
        accentColor={"productsBlue"}
        renderLeftSideHeader={this.renderLeftSideHeader(this.props.result)}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        loading={!this.props.result}
        closeBlade={this.props.closeBlade}
        bladeType="ProductRelease"
      >
        {/* <Flex style={{ maxHeight: "115px" }}>{this.renderAttributes()}</Flex> */}
        <Flex
          style={{
            maxHeight: "159",
            width: "100%",
            borderBottom: `1px solid ${theme.colors.whiteTwo}`
          }}
        ></Flex>
        <Flex
          style={{
            //marginTop: "24px",
            marginBottom: "35px",
            paddingLeft: "21px",
            paddingRight: "21px",
            flexDirection: "column",
            flex: 1
            //alignItems: "flex-start"
          }}
        >
          {this.renderAttributes()}
          <div style={{ display: "flex", flex: 1 }}>
            <TabView
              showBottomBorder={true}
              leftAlign={true}
              tabList={this.getTabs()}
              defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
              onUpdateActiveTab={this.updateActiveTab}
              orientation={"vertical"}
            />
          </div>
        </Flex>
      </BladeTemplate>
    );
  };

  public render() {
    return this.renderProductInfo();
  }
}
const RenderQuery = (props: IProductReleaseBladeProps) => {
  const theme = useTheme();
  const renderProductInfo = (payload: {
    error: Error;
    props: ProductReleaseQueryResponse;
    retry: () => void;
  }) => {
    return (
      <ProductReleaseBlade
        {...props}
        result={payload.props}
        theme={theme}
        retry={payload.retry}
      />
    );
  };
  return (
    <QueryRenderer<ProductReleaseQuery>
      environment={relayEnvironment}
      variables={{
        id: props.routeData.id
      }}
      query={graphqlQuery}
      render={renderProductInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
