export const REFRESH_KEY = {
  LicenseAttribute: "LicenseAttribute",
  LicenseUsersTable: "LicenseUsersTable",
  LicenseComponentsTable: "LicenseComponentsTable",
  LicenseComponentGroupsTable: "LicenseComponentGroupsTable",
  LicenseFeaturesTable: "LicenseFeaturesTable",
  LicenseFeatureGroupsTable: "LicenseFeatureGroupsTable",
  LicenseActiveSessionsTable: "LicenseActiveSessionsTable",
  LicenseReleaseTable: "LicenseReleaseTable",
  LicenseHistoryTable: "LicenseHistoryTable",
  LicenseChangeLogTable: "LicenseChangeLogTable",
  LicensesTable: "LicensesTable",
  CompanyLicensesTable: "CompanyLicensesTable",
  UserLicensesTable: "UserLicensesTable",
  OverviewUsersTable: "OverviewUsersTable",
  OverviewLicensesTable: "OverviewLicensesTable",
  ProductLicensesTable: "ProductLicensesTable",
  ProductsTable: "ProductsTable",
  ProductFeaturesTable: "ProductFeaturesTable",
  ProductFeatureGroupsTable: "ProductFeatureGroupsTable",
  ProductComponentsTable: "ProductComponentsTable",
  ProductComponentGroupsTable: "ProductComponentGroupsTable",
  ProductReleaseTable: "ProductReleaseConfigurationsTable",
  ProductCompaniesTable: "ProductCompaniesTable",
  ProductUsersTable: "ProductUsersTable",
  ProductAttribute: "ProductAttribute",
  ProductVersionsTable: "ProductVersionsTable",
  ComponentLicensesTable: "ProductComponentLicensesTable",
  ComponentCompaniesTable: "ProductComponentCompaniesTable",
  ComponentUsersTable: "ProductComponentUsersTable",
  CompanyUsersTable: "CompanyUsersTable",
  CompanyAdminsTable: "CompanyAdminsTable",
  CompanyBusinessUnitsTable: "CompanyBusinessUnitsTable",
  CompanyAttribute: "CompanyAttribute",
  CompaniesTable: "CompaniesTable",
  UsersTable: "UsersTable",
  UserFeaturesTable: "UserFeaturesTable",
  UserInstallsTable: "UserInstallsTable",
  UserProductHistoryTable: "UserProductHistoryTable",
  InstanceFeaturesTable: "InstanceFeaturesTable",
  ReportsTable: "ReportsTable"
} as const;

type ObjectValues<T> = T[keyof T];

export type EntityRefreshMap = ObjectValues<typeof REFRESH_KEY>;
