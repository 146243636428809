import axios, { AxiosRequestConfig } from "axios";
import jsZip from "jszip";
import * as _ from "lodash";
import appConfig from "../config";
import appActions from "../store/actions";
import {
  getAuthHeaders,
  deleteAuthHeaders,
  deleteAuthHeadersFromDeviceStorage
} from "../store/deviseAuth/services/auth";
import { asyncStorage, getSavedRoleFromLocal } from "../utils";
import ApiWorker from "./api.worker";
import {
  IerrorHandler,
  IFetchConfig,
  IGetParameters,
  IResponseObject,
  IUrlParameters
} from "./types";
import { IStore } from "../store/types";
import { Middleware } from "redux";
let storage = asyncStorage;
let SESSION_STORAGE_KEY = "evo-hou-sesh";
const SESSION_STATUS_FLAG = "evo-sesh-status"; // tracks if user session has been logged out so new tokens are not respected
// let elmRoleToken: string;
// let elmRoleType: string;

export const apiLogoutHandler = () => {
  import("../store/").then(({ getAppStore }) => {
    deleteAuthHeaders();
    deleteAuthHeadersFromDeviceStorage(storage);
    getAppStore().dispatch(appActions.deviseActions.signInRequestFailed());
  });
};
export const apiMiddleware: Middleware<
  any,
  IStore
> = store => next => action => {
  // if (action.type === appActionDefinitions.reportUpdateActiveRoleUpdate) {
  //   const state = store.getState();
  //   const role = getSavedRoleFromLocal()
  //   elmRoleToken = role.
  //   // elmRoleToken = appSelectors.activeRoleTokenSelector(state, {});
  //   // elmRoleType = appSelectors.activeRoleTypeSelector(state, {});
  // }
  return next(action);
};
axios.defaults.withCredentials = true;
export const services = {
  evoleapGateway: appConfig.gateway,
  evoleapGraphqlGateway: appConfig.graphql_gateway
};
export const postRequest = <T extends IResponseObject, T2 = any>(
  url: string,
  service?: keyof typeof services,
  downloadOptions?: IFetchConfig["downloadOptions"],
  headers?: { [key: string]: string },
  config?: Pick<IFetchConfig, "useMainThread">
) => (
  data?: T2,
  urlParameters?: IUrlParameters,
  errorHandler?: IerrorHandler
) =>
  getApi(service).post<T>(
    url,
    data,
    errorHandler,
    downloadOptions,
    urlParameters,
    headers,
    config
  );
export const putRequest = <T extends IResponseObject, T2 = any>(
  url: string,
  service?: keyof typeof services,
  downloadOptions?: IFetchConfig["downloadOptions"],
  headers?: { [key: string]: string },
  config?: Pick<IFetchConfig, "useMainThread">
) => (
  data?: T2,
  urlParameters?: IUrlParameters,
  errorHandler?: IerrorHandler
) =>
  getApi(service).put<T>(
    url,
    data,
    errorHandler,
    downloadOptions,
    urlParameters,
    headers,
    config
  );
export const deleteRequest = <T extends IResponseObject, T2 = any>(
  url: string
) => (
  data?: T2,
  urlParameters?: IUrlParameters,
  errorHandler?: IerrorHandler
) => getApi().delete<T>(url, data, errorHandler, urlParameters);

export const getRequest = <T extends IResponseObject>(url: string) => (
  params?: AxiosRequestConfig["params"],
  errorHandler?: IerrorHandler
) => getApi().get<T>(url, params, errorHandler);

export const getStorage = () => {
  return storage;
};
export const setStorageType = (newStorage: typeof asyncStorage) => {
  storage = newStorage;
};

// const extractToken = (response: AxiosResponse<IResponseObject>) => {
//   if (_.isObject(response)) {
//     const { data: body, headers } = response;
//     const token = headers[tokenHeader] || body.token;
//     return token;
//   }
//   return null;
// };

const executeRequestOnMainThread = (
  payload: AxiosRequestConfig,
  resolve: (val: any) => void,
  rej: (val: any) => void
) => {
  axios
    .request(payload)
    .then(async res => {
      if (_.isArrayBuffer(res.data)) {
        const zip = await jsZip.loadAsync(res.data);
        const zipBase64 = await zip.generateAsync({ type: "base64" });
        res.data = zipBase64;
      }
      resolve(res);
    })
    .catch(err => {
      rej({
        ...(_.pick(err.response, ["data", "headers"]) || {}),
        error: true
      });
    });
};
const Fetch = async <T extends IResponseObject>(
  config: IFetchConfig
): Promise<{ data: T; error: boolean; headers: JSON }> => {
  const authHeaders = await getAuthHeaders(asyncStorage);
  const userRole = await getSavedRoleFromLocal();
  const fetchOptions = {
    ..._.omit(config, ["useMainThread"]),
    withCredentials: true,
    headers: {
      ...config.headers,
      ...(authHeaders["access-token"] ? authHeaders : {}),
      "elm-role-token": _.get(userRole, "token"),
      "elm-role-type": _.get(userRole, "type")
    },
    // baseURL: `${services[config.service || 'evoleapGateway']}`,
    url: `${services[config.service || "evoleapGateway"]}${config.url}`
  } as AxiosRequestConfig;

  if (_.isObject(config.data)) {
    fetchOptions.data = config.data;
  }
  if (_.isObject(config.downloadOptions)) {
    fetchOptions.responseType = "arraybuffer";
  }
  return new Promise((res, rej) => {
    if (config.useMainThread) {
      executeRequestOnMainThread(fetchOptions, res, rej);
    } else {
      const apiWorker: Worker = new ApiWorker();
      apiWorker.postMessage({
        payload: JSON.stringify(fetchOptions)
      });
      apiWorker.addEventListener("message", e => {
        const response = e.data;
        apiWorker.terminate();
        try {
          const parsedResponse = _.isString(response)
            ? JSON.parse(response)
            : response;
          const {
            data: body,
            error: hasError,
            headers,
            status
          } = parsedResponse;

          if (hasError) {
            if (_.includes([401, 403], status)) {
              throw new Error("logout");
            } else {
              if (_.isFunction(config.errorHandler)) {
                config.errorHandler(e);
              }
            }
          }
          if (_.isObject(config.downloadOptions)) {
            const contentDisposition = _.isObject(headers)
              ? headers["Content-Disposition"] || headers["content-disposition"]
              : "";
            const fileName = contentDisposition.split("filename=")[1];
            jsZip.loadAsync(body, { base64: true }).then(zip => {
              if (_.isObject(zip)) {
                // @ts-ignore
                zip.generateAsync({ type: "blob" }).then(blob => {
                  const url = window.URL.createObjectURL(
                    // new Blob([(stringToArrayBuffer(body) as unknown) as BlobPart])
                    blob
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute(
                    "download",
                    `${fileName ||
                      _.get(config, "downloadOptions.downloadName")}`
                  );
                  document.body.appendChild(link);
                  link.click();
                });
              }
            });
          }
          res(parsedResponse);
        } catch (e) {
          apiLogoutHandler();
          res(response);
        }
      });
    }
  });
};
export const setGatewayEndpoint = (endpoint: string) => {
  services.evoleapGateway = endpoint;
};
export const setSessionStorageKey = (key: string) => {
  storage.removeItem(SESSION_STORAGE_KEY);
  SESSION_STORAGE_KEY = key;
};
export const getSessionStorageKey = () => {
  return SESSION_STORAGE_KEY;
};
export const getSessionToken = async () => {
  const token: string = await storage.getItem[SESSION_STORAGE_KEY];
  return token;
};
export const getSessionStatusFlag = (): string => {
  return SESSION_STATUS_FLAG;
};
const interPolateGetParameters = (
  url: string,
  parameters: IGetParameters = {}
) => {
  let newUrl = url;
  _.each(_.keys(parameters), parameterKey => {
    newUrl = newUrl.replace(`{${parameterKey}}`, parameters[parameterKey]);
  });
  return newUrl;
};
export const getApi = (service?: keyof typeof services) => {
  return {
    delete: <T extends IResponseObject>(
      url: string,
      data?: any,
      errorHandler?: IerrorHandler,
      urlParameters?: IUrlParameters
    ) => {
      return Fetch<T>({
        data,
        errorHandler,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "delete",
        service,
        url: interPolateGetParameters(url, urlParameters)
      });
    },
    get: <T extends IResponseObject>(
      url: string,
      urlParameters?: IUrlParameters,
      errorHandler?: IerrorHandler,
      downloadOptions?: IFetchConfig["downloadOptions"]
    ) =>
      Fetch<T>({
        downloadOptions,
        errorHandler,
        method: "get",
        url: interPolateGetParameters(url, urlParameters),
        service
        // url,
      }),
    post: <T extends IResponseObject>(
      url: string,
      data?: any,
      errorHandler?: IerrorHandler,
      downloadOptions?: IFetchConfig["downloadOptions"],
      urlParameters?: IUrlParameters,
      additionalHeaders?: { [key: string]: string },
      config?: Pick<IFetchConfig, "useMainThread">
    ) => {
      return Fetch<T>({
        data,
        downloadOptions,
        errorHandler,
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          ...(additionalHeaders || {})
        },
        method: "post",
        service,
        url: interPolateGetParameters(url, urlParameters),
        ...(config || {})
      });
    },
    put: <T extends IResponseObject>(
      url: string,
      data?: any,
      errorHandler?: IerrorHandler,
      downloadOptions?: IFetchConfig["downloadOptions"],
      urlParameters?: IUrlParameters,
      additionalHeaders?: { [key: string]: string },
      config?: Pick<IFetchConfig, "useMainThread">
    ) => {
      return Fetch<T>({
        data,
        downloadOptions,
        errorHandler,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...(additionalHeaders || {})
        },
        method: "put",
        service,
        url: interPolateGetParameters(url, urlParameters),
        ...(config || {})
      });
    }
  };
};
