import { NewEntitlementQueryResponse } from "../__generated__/NewEntitlementQuery.graphql";
import Fuse from "fuse.js";
import { getIcon } from "../../../../components/icons";
import React from "react";
import CheckableTag from "antd/lib/tag/CheckableTag";
import _ from "lodash";
import { EntitlementQueryResponse } from "Licenses/blades/Entitlement/__generated__/EntitlementQuery.graphql";
import { hooksFeaturesAndComponentsQueryResponse } from "../../AddLicense/__generated__/hooksFeaturesAndComponentsQuery.graphql";
import { useTheme } from "styled-components";
import { ComponentFilter } from "Licenses/blades/Entitlement";

const IndividualComponentSelection = (props: {
  result:
    | NewEntitlementQueryResponse
    | EntitlementQueryResponse
    | hooksFeaturesAndComponentsQueryResponse;
  targetKeys: string[];
  sourceSelectedKeys: string[];
  searchQuery: string;
  disabled: boolean;
  availableComponents?: string[];
  filter: ComponentFilter | null;
  globalFilter?: ComponentFilter | null;
  // onChange: (targetKeys: string[]) => void;
  onChange: (payload: {
    id: string;
    name: string;
    checked: boolean;
    type?: ComponentFilter;
  }) => void;
  onSelectChange: (targetKeys: string[]) => void;
}) => {
  const theme = useTheme();
  const getComponentNodes = (
    nodes: typeof props.result.product.components.nodes
  ) => {
    if (props.searchQuery) {
      const results = new Fuse(nodes, {
        keys: ["name"]
      }).search(props.searchQuery);
      return _.map(
        results,
        i => i.item as typeof props.result.product.components.nodes[number]
      );
    }
    return nodes;
  };
  const getComponents = () => {
    if (!_.get(props, "result.product.components.nodes")) {
      return [];
    }
    let enforcementType = "";
    if (
      _.isArray(props.sourceSelectedKeys) &&
      props.sourceSelectedKeys.length
    ) {
      const comp = _.find(
        props.result.product.components.nodes,
        node => node.id === props.sourceSelectedKeys[0]
      );
      enforcementType = comp.componentType;
    }
    let components = props.result.product.components.nodes;
    if (props.availableComponents) {
      components = props.result.product.components.nodes.filter(item =>
        props.availableComponents.includes(item.id)
      );
    }
    return _.map(getComponentNodes(components), n => ({
      id: n.id,
      key: n.name,
      title: n.name,
      type: n.componentType,
      disabled: enforcementType ? n.componentType !== enforcementType : false
    }));
  };
  const getComponentTypeColor = (type: ComponentFilter) => {
    switch (type) {
      case "Checked-out Token":
        return theme.colors.mango;
      case "Consumable Token":
        return theme.colors.orangeRed;
      case "Session":
        return theme.colors.userGreen;
    }
  };
  const handleChange = (
    id: string,
    name: string,
    checked: boolean,
    type?: ComponentFilter
  ) => {
    if (_.isFunction(props.onChange) && !props.disabled) {
      props.onChange({ id, name, checked, type });
    }
  };
  const tagsData = getComponents();
  const AddIcon = getIcon("plus", "fas");
  const CheckIcon = getIcon("check", "far");
  let retData;
  if (props.disabled) {
    //view mode
    retData = tagsData
      .filter(item => props.sourceSelectedKeys.includes(item.id))
      .map(item => item.title)
      .join(", ");
  } else {
    retData = tagsData
      .filter(item =>
        props?.globalFilter ? item.type === props.globalFilter : item
      )
      .map(item => {
        const isChecked = props.sourceSelectedKeys.indexOf(item.id) > -1;
        const isAvailable = props.filter ? props.filter === item.type : true;
        return (
          <CheckableTag
            style={{
              height: "24px",
              opacity: isAvailable ? 1 : 0.5,
              backgroundColor: isChecked
                ? theme.colors.filterBlue
                : theme.colors.lightGrey,
              borderLeft: `2px solid ${
                isChecked
                  ? theme.colors.filterBlue
                  : getComponentTypeColor(item.type)
              }`,
              fontFamily: "Inter",
              fontSize: "13px",
              marginBottom: "10px",
              marginRight: "6px",
              display: "inline-flex",
              alignItems: "center",
              pointerEvents: isAvailable ? "all" : "none",
              borderRadius: 0,
              color: isChecked ? theme.colors.white : theme.colors.pureBlack
            }}
            key={item.id}
            //checked={true}
            checked={isChecked}
            onChange={checked =>
              isAvailable
                ? handleChange(item.id, item.title, !isChecked, item.type)
                : () => null
            }
          >
            {item.title}

            {!isChecked ? (
              <AddIcon
                style={{ lineHeight: "24px", width: "12px", marginLeft: "8px" }}
              />
            ) : (
              <CheckIcon
                style={{ lineHeight: "24px", width: "12px", marginLeft: "8px" }}
              />
            )}
          </CheckableTag>
        );
      });
  }

  return (
    <div
      style={{
        display: "inline-block",
        border: "1px solid #CBCACB",
        padding: "10px",
        marginTop: "10px",
        color: props.disabled ? theme.colors.black : "unset"
      }}
    >
      {retData}
    </div>
  );
};

export default IndividualComponentSelection;
