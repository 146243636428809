/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type usersTable_users = {
  readonly usersTableCount: number;
  readonly usersTableUsers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly email: string | null;
        readonly createdAt: unknown;
        readonly instancesCount: number;
        readonly hasActiveSession: boolean;
        readonly lastActiveSession: {
          readonly endTime: unknown | null;
        } | null;
        readonly active: boolean;
        readonly owner: {
          readonly name: string | null;
          readonly id: string;
        } | null;
        readonly products: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly iconUrl: string | null;
            readonly name: string | null;
          } | null> | null;
        } | null;
        readonly productUsers: {
          readonly nodes: ReadonlyArray<{
            readonly product: {
              readonly id: string;
              readonly name: string | null;
            } | null;
            readonly enabled: boolean;
            readonly user: {
              readonly id: string;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $refType": "usersTable_users";
};
export type usersTable_users$data = usersTable_users;
export type usersTable_users$key = {
  readonly " $data"?: usersTable_users$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"usersTable_users">;
};

const node: ReaderFragment = (function() {
  var v0 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    v1 = [
      v0 /*: any*/,
      {
        kind: "Variable",
        name: "filterColumn",
        variableName: "filterColumn"
      } as any,
      {
        kind: "Variable",
        name: "filterOp",
        variableName: "filterOp"
      } as any,
      {
        kind: "Variable",
        name: "filterValues",
        variableName: "filterValues"
      } as any,
      {
        kind: "Variable",
        name: "search",
        variableName: "search"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "blade_scope"
      },
      {
        defaultValue: 25,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: ["users.active"],
        kind: "LocalArgument",
        name: "filterColumn"
      },
      {
        defaultValue: [["="]],
        kind: "LocalArgument",
        name: "filterOp"
      },
      {
        defaultValue: [["true"]],
        kind: "LocalArgument",
        name: "filterValues"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["usersTableUsers"]
        }
      ]
    },
    name: "usersTable_users",
    selections: [
      {
        alias: "usersTableCount",
        args: v1 /*: any*/,
        kind: "ScalarField",
        name: "usersCount",
        storageKey: null
      },
      {
        alias: "usersTableUsers",
        args: v1 /*: any*/,
        concreteType: "UserConnection",
        kind: "LinkedField",
        name: "__UsersTable_usersTableUsers_connection",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "UserEdge",
            kind: "LinkedField",
            name: "edges",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "User",
                kind: "LinkedField",
                name: "node",
                plural: false,
                selections: [
                  v2 /*: any*/,
                  v3 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "email",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "createdAt",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: [v0 /*: any*/],
                    kind: "ScalarField",
                    name: "instancesCount",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "hasActiveSession",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: "Session",
                    kind: "LinkedField",
                    name: "lastActiveSession",
                    plural: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "endTime",
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "active",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: null,
                    kind: "LinkedField",
                    name: "owner",
                    plural: false,
                    selections: [v3 /*: any*/, v2 /*: any*/],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: "ProductConnection",
                    kind: "LinkedField",
                    name: "products",
                    plural: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        concreteType: "Product",
                        kind: "LinkedField",
                        name: "nodes",
                        plural: true,
                        selections: [
                          v2 /*: any*/,
                          {
                            alias: null,
                            args: null,
                            kind: "ScalarField",
                            name: "iconUrl",
                            storageKey: null
                          },
                          v3 /*: any*/
                        ],
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: "ProductUserConnection",
                    kind: "LinkedField",
                    name: "productUsers",
                    plural: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        concreteType: "ProductUser",
                        kind: "LinkedField",
                        name: "nodes",
                        plural: true,
                        selections: [
                          {
                            alias: null,
                            args: null,
                            concreteType: "Product",
                            kind: "LinkedField",
                            name: "product",
                            plural: false,
                            selections: [v2 /*: any*/, v3 /*: any*/],
                            storageKey: null
                          },
                          {
                            alias: null,
                            args: null,
                            kind: "ScalarField",
                            name: "enabled",
                            storageKey: null
                          },
                          {
                            alias: null,
                            args: null,
                            concreteType: "User",
                            kind: "LinkedField",
                            name: "user",
                            plural: false,
                            selections: [v2 /*: any*/],
                            storageKey: null
                          }
                        ],
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "__typename",
                    storageKey: null
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "cursor",
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: "PageInfo",
            kind: "LinkedField",
            name: "pageInfo",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "hasNextPage",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "endCursor",
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "b133254e5de699673c54124a1c659ebf";
export default node;
