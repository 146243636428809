import { Menu } from "antd";
import Fuse from "fuse.js";
import * as _ from "lodash";
import React, { PureComponent } from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { IColumn, IFilterSelectProps, IFilterSelectState } from "./types";
import { getIcon } from "../icons";
import { FilterSelectionRow, FilterSearch } from "./helpers";
import {
  ClearAllText,
  ColumnFilterContainer,
  FilterTextContainer,
  SelectionRowBase,
  StyledDrop,
  StyledFilterText,
  StyledSubMenu,
  SubMenuTitle
} from "./styles";
import styles from "./elmTableStyle.module.scss";
import theme from "../../theme";

const DownIcon = getIcon("angle-down", "fal");
const DropDownIcon = styled(DownIcon)`
  //color: ${props => props.theme.colors.iconColor};
  color: inherit;
  .ant-dropdown-trigger & {
    height: unset;
    width: 10px;
  }
  transform: translateY(1px);
`;
const RightIcon = getIcon("angle-right", "fal");
const SubmenuExpandIcon = styled(RightIcon)``;
export const getCaretIcon = (direction: "down" | "right") => {
  if (direction === "down") return () => <DropDownIcon />;
  else return () => <SubmenuExpandIcon />;
};

export default class FilterSelect extends PureComponent<
  IFilterSelectProps,
  IFilterSelectState
> {
  state: IFilterSelectState = {
    isOpen: false,
    openSubMenu: "",
    searchFilterMap: {}
  };
  public getUniqueFiltersForColum = (column: IColumn) => {
    if (this.state.searchFilterMap[column.Header]) {
      const list = this.props.filterData[column.filterable.filterName];
      const results = new Fuse(list, {}).search(
        this.state.searchFilterMap[column.Header]
      );
      return _.map(results, i => i.item);
    }
    return this.props.filterData[column.filterable.filterName];
  };

  public getColumnFilterOption = (
    column: IColumn,
    key: keyof IColumn["filterable"]
  ) => {
    if (_.isObject(column.filterable)) {
      return _.get(column.filterable, key);
    }
    return null;
  };

  public isFilterChecked = (payload: { column: IColumn; value: string }) => {
    return _.includes(
      this.props.filterSelectedMap[payload.column.filterable.filterName],
      payload.value
    );
  };
  public handleFilterCheckboxChange = (payload: {
    column: IColumn;
    value: string;
  }) => (callbackValue: { value: string; isChecked: boolean }) => {
    if (_.isFunction(this.props.handleCheckboxFilterChange)) {
      this.props.handleCheckboxFilterChange({
        column: payload.column,
        isChecked: callbackValue.isChecked,
        value: payload.value
      });
    }
    this.handleVisibleChange(false);
  };
  public updateSearchFilter = (column: IColumn, searchFilterTerm: string) => {
    this.setState({
      searchFilterMap: {
        ...this.state.searchFilterMap,
        [column.Header]: searchFilterTerm
      }
    });
  };
  public renderFiltersForColumn = (column: IColumn) => {
    const uniqValuesForColumns = this.getUniqueFiltersForColum(column);

    const getFilterOptionComponentType = () => {
      const options = _.map(uniqValuesForColumns, (value, i) => {
        return (
          <FilterSelectionRow
            onChange={this.handleFilterCheckboxChange({ column, value })}
            isChecked={this.isFilterChecked({ column, value })}
            label={value}
            key={`fo-${i}`}
          />
        );
      });
      return (
        <Flex className={styles.filtersForColumnWrapper}>
          {options?.length ? (
            options
          ) : (
            <SelectionRowBase>
              <span>No results.</span>
            </SelectionRowBase>
          )}
        </Flex>
      );
    };
    const renderFilterSearch = () => {
      if (!this.props.filterData[column.filterable.filterName]) {
        return null;
      }
      if (this.props.filterData[column.filterable.filterName].length > 6) {
        return (
          <FilterSearch
            placeholder={`Search ${column.Header}`}
            onChange={value => this.updateSearchFilter(column, value)}
            searchContainerStyle={{
              border: "none",
              borderBottom: `1px solid ${theme.colors.whiteTwo}`,
              borderRadius: "0px",
              height: "32px"
            }}
          />
        );
      }
      return null;
    };
    return (
      <ColumnFilterContainer>
        {renderFilterSearch()}
        {getFilterOptionComponentType()}
      </ColumnFilterContainer>
    );
  };
  public getColumnFiltersCount = (column: IColumn) => {
    const uniqValuesForColumns = this.getUniqueFiltersForColum(column);
    return uniqValuesForColumns.length;
  };
  public openFilterSubmenu = (openSubMenu: string) => () => {
    this.setState({ openSubMenu });
  };
  public getMenuBody = (): any => {
    let menus: JSX.Element[] = [];
    _.each(this.props.columns, (column, i) => {
      const uniqValuesForColumns = this.getUniqueFiltersForColum(column);
      // ((uniqValuesForColumns && uniqValuesForColumns.length > 1) ||
      //   column.filterable?.force) &&
      (uniqValuesForColumns || column.filterable?.force) &&
        menus.push(
          <StyledSubMenu
            onTitleClick={this.openFilterSubmenu(column.Header)}
            title={<SubMenuTitle>{column.Header}</SubMenuTitle>}
            key={column.Header}
            isSelected={this.state.openSubMenu === column.Header}
            popupClassName={"elm-submenu-popout-container"}
          >
            {this.renderFiltersForColumn(column)}
          </StyledSubMenu>
        );
    });
    return menus;
  };
  public renderClearAllControl = () => (
    <ClearAllText
      onTitleClick={() => {
        this.props.handleClearAllFilters();
        this.closeFilter();
      }}
      title={<SubMenuTitle>Clear All</SubMenuTitle>}
      isSelected={false}
    />
  );

  public getMenu = () => {
    const openKeys = this.state.isOpen ? [this.state.openSubMenu] : [];
    return (
      <Menu
        expandIcon={getCaretIcon("right")()}
        openKeys={openKeys}
        mode="vertical"
      >
        {this.renderClearAllControl()}
        {this.getMenuBody()}
      </Menu>
    );
  };
  public openFilter = () => {
    this.setState({ isOpen: true });
  };
  public closeFilter = () => {
    this.setState({ isOpen: false });
  };
  public toggleFilter = () => {
    // if (!this.isDisabled()) {
    //   this.state.isOpen ? this.closeFilter() : this.openFilter();
    // }
  };
  public handleVisibleChange = (isOpen: boolean) => {
    this.setState({ isOpen });
  };
  public isDisabled = () => {
    if (_.isArray(this.props.columns)) {
      if (this.props.columns.length > 0) {
        return false;
      }
    }
    return true;
  };

  public render() {
    return (
      <div
        className={"elm-table-filter-select"}
        style={{ position: "relative" }}
      >
        <StyledDrop
          onVisibleChange={this.handleVisibleChange}
          visible={this.state.isOpen}
          overlay={this.getMenu()}
          disabled={this.isDisabled()}
          trigger={["click"]}
          className="filterBorder"
        >
          <FilterTextContainer disabled={this.isDisabled()}>
            <StyledFilterText className="ant-dropdown-link">
              Filters
            </StyledFilterText>
            {getCaretIcon("down")()}
          </FilterTextContainer>
        </StyledDrop>
      </div>
    );
  }
}
