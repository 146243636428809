import * as _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { appConnect, appDispatch } from "../../../store/appConnect";
import relayEnvironment from "api/relay";
import {
  AllowedChartBladeTypes,
  IBladeBaseProps,
  IOpenBladeFromChart,
  IOpenUserBlade
} from "../../../components/bladeManager/types";
import { ITab } from "../../../components/tabView";
import {
  BladeTemplate,
  BladeTitle,
  ElmButton,
  TabView,
  UserAttribute,
  UserInstallsTable,
  UserProductHistoryTable,
  AnalyticsCarousel
} from "../../../components";
import LicensesTable, {
  LicensesTableColumns,
  LicenseTableType
} from "../../../Licenses/components/licensesTable";
import {
  UserQuery,
  UserQueryResponse
} from "./__generated__/UserQuery.graphql";
import { productTableType } from "../../../Products/components/productsTable";
import { userInstallsTableType } from "../../components/userInstallsTable";
import UserFeaturesAndGroups from "../../components/userFeaturesAndGroups";
import UserProductActivationTable from "../../components/userProducts";
import FeedbackList from "../../components/feedbackList";
import { AnalyticsCarouselProps } from "components/analyticsCarousel";
import { AnalyticsComponentProps } from "components/analyticsComponent";
import {
  sessionCount,
  sessionDuration,
  topComponents
} from "utils/commonAnalytics";
import moment from "moment";
import { updateBladeUrlState } from "utils";
import AttributeActionsDropdown from "components/bladeAttributeActionsDropdown";
import { getGateway } from "api";
import LinkedUsersTable from "Users/components/linkedUsersTable";
import { TableCell } from "components/elmTable";
import ToggleActivation from "Users/components/userProducts/toggleActivation";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { REFRESH_KEY } from "components/bladeManager/entityRefreshMap";

const graphqlQuery = graphql`
  query UserQuery($id: ID!) {
    user(id: $id) {
      id
      email
      createdAt
      displayName
      name
      linkedUsersCount
      productUsers {
        nodes {
          modificationRecordsCount
        }
      }
      owner {
        name
        id
      }
      feedbackCount: feedback {
        __typename
      }
    }
  }
`;
export interface IUserBladeProps extends IBladeBaseProps<IOpenUserBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    activeTabIndex: number;
  };
}

export interface IActivationData {
  enabled: boolean;
  product_id: string;
}

export class UserBlade extends React.Component<
  IUserBladeProps & { result: UserQueryResponse; retry: () => void }
> {
  state = {
    activeTabIndex: 0
  } as IUserBladeProps["appState"];

  public navAway = () => {
    this.props.openBlade({
      routeName: "Customer portal",
      fromBladeIndex: this.props.index,
      route: "Overview",
      routeData: null
    });
  };
  public navToLicenseBlade = (license: LicenseTableType) => {
    this.props.openBlade({
      route: "License",
      routeName: "License",
      fromBladeIndex: this.props.index,
      routeData: {
        id: license.id,
        productId: license.product.id
      }
    });
  };
  public navToProductBlade = (product: productTableType) => {
    this.props.openBlade({
      route: "Product",
      routeName: product.name,
      fromBladeIndex: this.props.index,
      routeData: {
        id: product.id.toString()
      }
    });
  };
  public openBladeFromChart = (payload: IOpenBladeFromChart) => {
    if (!payload?.id || !AllowedChartBladeTypes.includes(payload.bladeType)) {
      return;
    }
    this.props.openBlade({
      route: payload.bladeType,
      routeName: `${payload.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: payload.id
      }
    });
  };
  public renderLeftSideHeader = () => {
    return (
      <Flex style={{ alignItems: "center" }}>
        <BladeTitle>
          {_.get(
            this.props.result,
            "user.displayName",
            this.props.routeData?.name
          )}
        </BladeTitle>
      </Flex>
    );
  };
  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: `${this.props.result.user.displayName} - Analytics`,
      fromBladeIndex: this.props.index,
      routeData: {
        type: "User",
        title: this.props.result.user.displayName,
        id: this.props.routeData.id,
        allTimeStartDate: `${this.props.result?.user?.createdAt}`
      }
    });
  };
  public navToInstanceBlade = (instance: userInstallsTableType) => {
    const hostName =
      _.get(instance.hardwareIdentifier?.info, "hostName") || "Instance";
    this.props.openBlade({
      route: "Instance",
      routeName: hostName,
      routeData: {
        id: instance.id
      },
      fromBladeIndex: this.props.index
    });
  };
  public navToCompanyBlade = (company: { id: string; name: string }) => {
    this.props.openBlade({
      route: "Company",
      routeName: company.name,
      routeData: {
        id: company.id
      },
      fromBladeIndex: this.props.index
    });
  };
  public renderRightSideHeader = () => {
    const showUserDialog = () => {
      const res = this.props.result;
      this.props.openDialog("EditUserDialog", {
        editMode: true,
        id: this.props.routeData.id,
        userData: {
          name: _.get(res, "user.displayName", _.get(res, "user.name", "user")),
          email: _.get(res, "user.email")
        },
        onConfirm: () => {
          this.props.closeDialog("EditUserDialog");
          this.props.refreshAllOpenBlades();
        }
      });
    };
    const showLinkingDialog = () => {
      const res = this.props.result;
      this.props.openDialog("UserLinkingDialog", {
        id: this.props.routeData.id,
        name: _.get(res, "user.displayName", _.get(res, "user.name", "user")),
        company: _.get(res, "user.owner.name"),
        onConfirm: () => {
          this.props.closeDialog("UserLinkingDialog");
        },
        refresh: this.props.refreshAllOpenBlades
      });
    };
    return (
      <Flex>
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon={"chart-mixed"}
          iconPrefix={"far"}
          label={"Analytics"}
          onClick={this.navigateToAnalytics}
        />
        <AttributeActionsDropdown
          permissions={["modify_assets"]}
          showLinkingDialog={showLinkingDialog}
          showEditDialog={showUserDialog}
          disabled={!this.props.result}
        />
      </Flex>
    );
  };
  public renderFeaturesAndGroups = () => {
    return (
      <UserFeaturesAndGroups
        id={this.props.routeData?.id}
        refreshAllOpenBlades={this.props.refreshAllOpenBlades}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.UserFeaturesTable
        )}
        refreshKey={REFRESH_KEY.UserFeaturesTable}
        removeRefreshFn={this.props.removeRefreshFn}
      />
    );
  };
  public renderLicenses = () => {
    return (
      <LicensesTable
        bladeName="User"
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.UserLicensesTable
        )}
        refreshKey={REFRESH_KEY.UserLicensesTable}
        removeRefreshFn={this.props.removeRefreshFn}
        bladeScope={this.props.routeData?.id}
        onRowClick={this.navToLicenseBlade}
        hideColumnsWithHeaders={[
          LicensesTableColumns.Company,
          LicensesTableColumns.Actions,
          LicensesTableColumns.LicenseKey,
          LicensesTableColumns.Usage
        ]}
        columnAndHeaderStyles={{
          justifyFirstHeaderColumnContent: "flex-start",
          justifyColumnContent: "flex-start",
          justifyLastHeaderColumnContent: "flex-end"
        }}
      />
    );
  };
  public renderUserInstalls = () => {
    return (
      <UserInstallsTable
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.UserInstallsTable
        )}
        refreshKey={REFRESH_KEY.UserInstallsTable}
        removeRefreshFn={this.props.removeRefreshFn}
        onRowClick={this.navToInstanceBlade}
        hideColumnsWithHeaders={this.props.isVendor ? ["ID"] : ["Active"]}
      />
    );
  };
  public renderProductHistory = () => {
    return (
      <UserProductHistoryTable
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.UserProductHistoryTable
        )}
        refreshKey={REFRESH_KEY.UserProductHistoryTable}
        removeRefreshFn={this.props.removeRefreshFn}
      />
    );
  };
  public renderLinkedUsers = () => {
    if (!this.props.result?.user?.linkedUsersCount) {
      return null;
    }
    const unlinkUser = (linked_user_id: string) => {
      this.props.openDialog("ConfirmationDialog", {
        title: "Remove link to user?",
        isSimpleConfirmation: true,
        onConfirm: () => {
          const gateway = getGateway();
          gateway.request["unlinkUser"](
            { id: this.props.routeData?.id, linked_user_id },
            { id: this.props.routeData?.id }
          )
            .then(res => {
              if (!res.error) {
                renderSuccessNotification("Removed link to user!");
                this.props.refreshBlade("User");
              }
            })
            .catch(err => {
              renderErrorNotification("Failed to remove link to user !");
              console.error(err);
            })
            .finally(() => {
              this.props.closeDialog("ConfirmationDialog");
            });
        }
      });
    };
    return (
      <LinkedUsersTable id={this.props.routeData?.id} unlinkUser={unlinkUser} />
    );
  };

  public renderFeedbackList = () => {
    return <FeedbackList id={this.props.routeData?.id} />;
  };

  public onActivationConfirm = (data: IActivationData) => {
    const dataBody = { product_id: data.product_id };
    const urlData = { id: this.props.routeData.id };
    if (data.enabled) {
      this.props.gateway.request
        .enableProduct(dataBody, urlData)
        .then(res => {
          if (!res.error) {
            renderSuccessNotification("Enabled product");
            this.props.refreshBlade("User");
          }
        })
        .catch(err => {
          console.error("Error on product activation: ", err);
        });
    }
    if (!data.enabled) {
      this.props.gateway.request
        .disableProduct(dataBody, urlData)
        .then(res => {
          if (!res.error) {
            renderSuccessNotification("Product disabled");
            this.props.refreshBlade("User");
          }
        })
        .catch(err => {
          console.error("Error on product deactivation: ", err);
        });
    }
  };

  public renderProducts = () => {
    return (
      <UserProductActivationTable
        rowHeight={26}
        className={"borderedTableRow"}
        bladeScope={this.props.routeData?.id}
        hideSearchBar
        renderRowActionButtons={payload => {
          const { id, enabled, name, totalEnabled } = payload;
          return (
            <TableCell>
              <ToggleActivation
                id={id}
                enabled={enabled}
                name={name}
                totalEnabled={totalEnabled}
                retry={this.onActivationConfirm}
                size="small"
              />
            </TableCell>
          );
        }}
      />
    );
  };
  public getHistoryCount = (res: UserQueryResponse) => {
    let count = 0;
    if (_.isObject(res)) {
      _.each(res.user.productUsers.nodes, node => {
        count = count + node.modificationRecordsCount;
      });
    }
    return count;
  };
  public renderTabs = (res: UserQueryResponse) => {
    const hasLinkedUsers = res?.user?.linkedUsersCount > 0;
    const tabs: ITab[] = [
      {
        title: "Products",
        Component: this.renderProducts()
      },
      {
        title: "Licenses",
        Component: this.renderLicenses()
        // supScript: _.get(res, "user.licensesCount")
      },
      {
        title: "Features",
        Component: this.renderFeaturesAndGroups()
        // supScript: _.get(res, "user.licensesCount")
      },
      {
        title: "Installs",
        Component: this.renderUserInstalls()
        // supScript: _.get(res, "user.instancesCount")
      },
      {
        title: "History",
        Component: this.renderProductHistory()
        // supScript: this.getHistoryCount(res)
      }
    ];
    this.props.isVendor &&
      hasLinkedUsers &&
      tabs.push({
        title: `Linked users ${
          res?.user?.linkedUsersCount ? `(${res?.user?.linkedUsersCount})` : ""
        }`,
        Component: this.renderLinkedUsers()
      });
    this.props.isVendor &&
      tabs.push({
        title: `Feedback ${
          res?.user?.feedbackCount?.length
            ? `(${res?.user?.feedbackCount?.length})`
            : ""
        }`,
        Component: this.renderFeedbackList()
      });

    return (
      <TabView
        showBottomBorder={true}
        leftAlign={true}
        tabList={tabs}
        defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
        onUpdateActiveTab={this.updateActiveTab}
        orientation={"vertical"}
      />
    );
  };

  public getAnalyticsQuery = (
    activeTabIndex: number
  ): AnalyticsComponentProps => {
    const startTime = moment()
      .utc()
      .clone();
    switch (activeTabIndex) {
      case 0: // Products
        return sessionCount(this.props.routeData.id, startTime, true);
      case 1: // Products
        return sessionDuration(this.props.routeData.id, startTime, true);
      case 2: // Components
        return topComponents(
          this.props.routeData.id,
          startTime,
          false,
          "users.name"
        );
      default:
        return sessionCount(this.props.routeData.id, startTime, true);
    }
  };
  public getChartIndex = () => {
    //return 0;
    switch (this.state.activeTabIndex) {
      case 0: // Products
        return 0;
      case 1: // Licenses
        return 1;
      case 2:
        return 2;
      default:
        return 0;
    }
  };

  public getAnalyticsCarouselProps = (): AnalyticsCarouselProps => {
    return {
      analyticsSlides: _.map(_.range(0, 3), i => this.getAnalyticsQuery(i)),
      currentIndex: this.getChartIndex(),
      openBlade: this.openBladeFromChart
    };
  };

  public updateActiveTab = (activeTabIndex: number) => {
    updateBladeUrlState(this.props, activeTabIndex);
    this.setState({
      activeTabIndex
    });
  };

  public renderUserInfo = () => {
    const res = this.props.result;
    const user = _.get(res, "user");
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"User"}
        //topAccentColor={"userGreen"}
        accentColor="userGreen"
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        closeBlade={this.props.closeBlade}
        bladeType="User"
        // loading={!res}
      >
        <Flex
          style={{
            maxHeight: "159",
            marginBottom: "5px",
            width: "100%"
          }}
        >
          <UserAttribute
            id={this.props.routeData?.id}
            onClickCompany={this.navToCompanyBlade}
            permissions="modify_assets"
          />
        </Flex>
        <Flex
          style={{
            marginBottom: "35px",
            marginLeft: "20px",
            marginRight: "40px",
            flexDirection: "column",
            flex: 1
          }}
        >
          <AnalyticsCarousel {...this.getAnalyticsCarouselProps()} />
          {this.renderTabs(res)}
        </Flex>
      </BladeTemplate>
    );
  };

  public render() {
    return this.renderUserInfo();
  }
}
const RenderQuery = (props: IUserBladeProps) => {
  const renderUserInfo = (payload: {
    error: Error;
    props: UserQueryResponse;
    retry: () => void;
  }) => {
    return (
      <UserBlade
        {...props}
        result={payload.props}
        {..._.omit(payload, "props")}
      />
    );
  };
  return (
    <QueryRenderer<UserQuery>
      environment={relayEnvironment}
      variables={{
        id: props.routeData.id
      }}
      query={graphqlQuery}
      render={renderUserInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
