import React, { Suspense, useState } from "react";
import { Flex } from "reflexbox/styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { getEnvironment } from "api/relay";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay";
import { ElmSelectOption, ElmSelectWithLabel } from "components/elmSelect";
import {
  productSelectQuery,
  productSelectQueryResponse
} from "./__generated__/productSelectQuery.graphql";
import { appConnect } from "store/appConnect";
import { base64EncodeId } from "utils/base64EncodeDecode";

interface ProductSelectProps {
  handleSelect: (
    product: productSelectQueryResponse["products"]["nodes"][number]
  ) => void;
  defaultValue?: string | number;
}

const graphqlQuery = graphql`
  query productSelectQuery {
    products {
      nodes {
        id
        name
        components {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;

const ProductSelect: React.FC<ProductSelectProps> = ({
  handleSelect,
  defaultValue
}) => {
  const res = useLazyLoadQuery<productSelectQuery>(
    graphqlQuery,
    {},
    { fetchPolicy: "network-only" }
  );
  const [selection, setSelection] = useState<string>(
    defaultValue ? base64EncodeId("Product", defaultValue) : ""
  );
  const options =
    res?.products?.nodes?.map(
      (node: productSelectQueryResponse["products"]["nodes"][number]) => {
        return { id: node.id, name: node.name };
      }
    ) || [];
  const handleSelection = (id: string) => {
    const target = res?.products?.nodes?.find(prod => prod.id === id);
    if (target) {
      handleSelect(target);
      setSelection(id);
    }
  };
  return (
    <Flex flexDirection={"column"} flex={1} style={{ margin: 12 }}>
      <ElmSelectWithLabel
        label="Product"
        placeholder="Select Product"
        optionFilterProp="label"
        value={selection || null}
        defaultValue={selection || null}
        onSelect={handleSelection}
        required={true}
        showSearch
      >
        {options?.map(node => {
          return (
            <ElmSelectOption key={node.id} value={node.id} label={node.name}>
              {node.name}
            </ElmSelectOption>
          );
        })}
      </ElmSelectWithLabel>
    </Flex>
  );
};

const relayEnvironment = getEnvironment();
const RenderQuery: React.FC<ProductSelectProps> = props => {
  return (
    <Suspense
      fallback={
        <Flex flexDirection={"column"} flex={1} style={{ margin: 12 }}>
          <ElmSelectWithLabel
            label="Product"
            placeholder="Select Product"
            required={true}
          />
        </Flex>
      }
    >
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <ProductSelect {...props} />
      </RelayEnvironmentProvider>
    </Suspense>
  );
};

export default appConnect(RenderQuery, {
  selectors: {}
});
