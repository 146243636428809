import { getEnvironment } from "../api/relay";
import { Alert, Input } from "antd";
import { OperationType } from "relay-runtime";
import { QueryRenderer, GraphQLTaggedNode } from "react-relay";
import _ from "lodash";
import countries from "i18n-iso-countries";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
//@ts-ignore
import { fadeIn, fadeOut, slideInRight, slideOutRight } from "react-animations";
import styled, { css, keyframes } from "styled-components";
import { getIcon } from "./icons";
import { Flex } from "reflexbox/styled-components";
import { IOpenBlade } from "./bladeManager/types";
import { getBladeColor } from "../utils";
import React from "react";
import Loader from "react-loaders";
import { ElmSelectWithLabel, ElmSelectOption } from "./elmSelect";
import Fuse from "fuse.js";
import { IUserRole } from "../store/types";
import { useSelector } from "react-redux";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export type getClassName = (payload: {
  [key: string]: () => boolean;
}) => string;
export const getClassName: getClassName = payload => {
  let className = "";
  _.each(_.keys(payload), k => {
    if (_.isFunction(payload[k])) {
      if (payload[k]()) {
        className = `${className} ${k}`;
      }
    }
  });
  return className;
};

export const ActiveCheck = styled(getIcon("FaRegCheckCircle"))`
  color: #4fdf2c;
`;
export const ActiveLabel = styled.text`
  width: 33px;
  height: 16px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #4fdf2c;
  margin-left: 3px;
`;
export function getComponentWithQueryRenderer<
  queryType extends OperationType,
  variablesType,
  componentProps
>(Component?: React.ComponentType<componentProps>) {
  const relayEnvironment = getEnvironment();
  return (props: {
    query: GraphQLTaggedNode;
    variables: variablesType;
    componentProps: componentProps;
    children?: (
      res: queryType["response"],
      retry: () => void
    ) => React.ReactNode;
  }) => {
    const renderFn = (payload: {
      error: Error;
      props: queryType["response"];
      retry: () => void;
    }) => {
      return (
        <Component
          {...props.componentProps}
          {...payload.props}
          retry={payload.retry}
          children={props.children(payload.props, payload.retry)}
        />
      );
    };
    return (
      <QueryRenderer<queryType>
        environment={relayEnvironment}
        variables={props.variables}
        fetchPolicy={"store-and-network"}
        query={props.query}
        render={renderFn}
      />
    );
  };
}
const countryList = countries.getNames("en", { select: "official" });
export class CountriesSelector extends React.Component<
  {
    onChange?: (payload: { countryCode: string; countryName: string }) => void;
    defaultValue?: any;
    value?: string;
  },
  { searchQuery: string; value: string }
> {
  state = {
    searchQuery: "",
    //@ts-ignore
    value: this.props.defaultValue,
    countries: countryList
  };
  public getCountries = () => {
    if (!this.state.countries) {
      return null;
    }

    let filtered: { key: string; value: string }[] = _.map(
      _.keys(countryList),
      c => ({ key: c, value: countryList[c] })
    );
    // _.sortBy(
    //   _.map(_.keys(countryList), (c) => ({ key: c, value: countryList[c] })),
    //   (p) => p.value
    // );
    // if (this.state.searchQuery) {
    //   // const results = new Fuse(filtered, {
    //   //   keys: ["value"],
    //   //   //isCaseSensitive: false,
    //   // }).search(this.state.searchQuery, { limit: 10 });
    //   // filtered = _.map(results, (i) => ({
    //   //   key: i.item.key,
    //   //   value: i.item.value,
    //   // }));
    // }
    return filtered.map(country => {
      return (
        <ElmSelectOption
          key={country.key}
          value={country.key}
          label={country.value}
        >
          {country.value}
        </ElmSelectOption>
      );
    });
  };
  private onChange = (payload: any) => {
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange({
        countryCode: payload as string,
        countryName: countryList[payload]
      });
    }
  };
  public handleSearch = (searchQuery: string) => {
    this.setState({ searchQuery });
  };

  public render = () => (
    <ElmSelectWithLabel
      onChange={this.onChange}
      className="country-selector"
      label="Country"
      showSearch={true}
      //onSearch={this.handleSearch}
      value={this.props.value}
      filterOption={(input, option) =>
        option?.label
          .toLocaleString()
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      defaultValue={this.props.defaultValue}
    >
      {this.getCountries()}
    </ElmSelectWithLabel>
  );
}
const transitionName = "loading-container";
const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;
const LoadingContainer = styled.section`
  height: 100%;
  &.${transitionName}-appear {
    animation: 1s ${fadeInAnimation};
  }

  &.${transitionName}-enter {
    animation: 1s ${fadeInAnimation};
  }

  &.${transitionName}-leave {
    animation: 0.5s ${fadeOutAnimation};
  }
`;
export const LoaderContainer = () => {
  return (
    <LoadingContainer>
      <Flex
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
          //marginLeft: "45px"
        }}
      >
        <Loader
          type="line-scale-pulse-out"
          active={true}
          color={"rgb(98, 98, 130)"}
          style={{ transform: "scale(1.0)" }}
        />
      </Flex>
    </LoadingContainer>
  );
};
// export function RenderQuery<ComponentProps,Query,QueryResponse> = (option: {renderComponent: ({error: Error}) => JSX.Element; }) => (props: ComponentProps) => {
//  const renderComponent = (payload: {
//    error: Error;
//    props: QueryResponse;
//    retry: () => void;
//  }) => {
//    return (
//      <Comp
//        {...props}
//        result={payload.props}
//        {..._.omit(payload, 'props')}
//      />
//    );
//  };
//  return (
//    <QueryRenderer<Query>
//      environment={relayEnvironment}
//      variables={{
//        id: props.routeData.id,
//        blade_scope: props.routeData.id,
//      }}
//      query={graphqlQuery}
//      render={option.renderComponent}
//    />
//  );
// };
export const ActiveBadge = () => (
  <Flex style={{ alignItems: "center", maxHeight: "16px" }}>
    <ActiveCheck />
    <ActiveLabel>Active</ActiveLabel>
  </Flex>
);
const SearchIcon = getIcon("magnifying-glass", "fal");

// const { Search } = Input;
type searchBarProps = {
  placeholder?: string;
  onSearch?: (searchTerm: string) => void;
  style?: React.CSSProperties;
  className?: string;
};
export const SearchBar = (props: searchBarProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (_.isFunction(props.onSearch)) {
      props.onSearch(e.target.value);
    }
  };
  return (
    <Input
      placeholder={props.placeholder || "Search"}
      style={props.style}
      prefix={<SearchIcon />}
      allowClear={true}
      onChange={onChange}
      className={props.className}
    />
  );
};
export const ScrollStyle = css`
  scrollbar-width: thin;
  scrollbar-color: ${props =>
    `${props.theme.colors.black} ${props.theme.colors.lightishBlue}`};
  padding-bottom: 5px;

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    height: 4px;
    width: 4px;
    border: none;
    background: ${props => props.theme.colors.greyish};
    cursor: pointer;
  }
  ::-webkit-scrollbar-track {
    border: none;
    border-radius: 0px;
  }

  *::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 0px;
    height: 4px;
    width: 4px;
    border: none;
    background: ${props => props.theme.colors.greyish};
    cursor: pointer;
  }
  *::-webkit-scrollbar-track {
    border: none;
    border-radius: 0px;
  }
`;
const LinkContainer = styled(Flex)<{ activeColor: string }>`
  max-width: max-content;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.lightishBlue};
  :hover {
    color: ${props => props.theme.colors[props.activeColor]};
  }
`;
export const getLink = (payload: {
  linkType: IOpenBlade["route"];
  label: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}) => {
  const activeColor = getBladeColor(payload.linkType);
  const Label = styled.text`
    color: inherit;
    cursor: pointer;
    min-width: max-content;
    height: 16px;
    font-style: normal;
    text-align: left;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    /* LightBlue */

    color: ${props => props.theme.colors.lightBlue};
  `;
  // const LinkIcon = styled(getIcon('FaLink'))`
  //   height: 12px;
  //   color: inherit;
  // `;
  if (!payload.label) {
    return null;
  }
  return (
    <LinkContainer activeColor={activeColor} onClick={payload.onClick}>
      <Label>{payload.label}</Label>
      {/* <LinkIcon /> */}
    </LinkContainer>
  );
};

const animations = {
  fadeIn,
  fadeOut,
  slideInRight,
  slideOutRight
};
type animationName = keyof typeof animations;
type animationPayload = {
  appear?: { duration: string; animationType: animationName };
  enter?: { duration: string; animationType: animationName };
  leave?: { duration: string; animationType: animationName };
};
type animationClass = keyof animationPayload;
const getAnimation = (animationType: animationName) =>
  keyframes`${animations[animationType]}`;

const getDefaultAnimationStyles = (
  animationDef: animationPayload,
  transitionName: string
) => {
  const types: animationClass[] = ["appear", "enter", "leave"];
  let style = { ...animationDef };
  let styleString = ``;
  let type: keyof animationPayload;

  _.each(types, t => {
    type = t as keyof animationPayload;
    style[type] = animationDef[type] || {
      duration: "0s",
      animationType: "slideInRight"
    };
  });

  return { style, styleString };
};
const AnimatedComp = styled.span.attrs(
  (props: { style: animationPayload }) => props
)`
  height: 100%;
  &.${transitionName}-appear {
    animation: ${props => css`
      ${props.style.appear.duration}
      ${getAnimation(props.style.appear.animationType)}
    `};
  }

  &.${transitionName}-enter {
    animation: ${props => css`
      ${props.style.enter.duration}
      ${getAnimation(props.style.enter.animationType)}
    `};
  }

  &.${transitionName}-leave {
    animation: ${props => css`
      ${props.style.leave.duration}
      ${getAnimation(props.style.leave.animationType)}
    `};
  }
`;
export const getAnimatedComponent = (options: {
  transitionName: string;
  element: keyof JSX.IntrinsicElements;
  animations: animationPayload;
}) => (props: any) => {
  const element = options.element || "div";
  const transitionName = options.transitionName || "elm-animated-component";
  const { style } = getDefaultAnimationStyles(
    options.animations,
    transitionName
  );
  // TODO: Cannot create styled components dynamically. Fix this.

  return (
    <ReactCSSTransitionGroup
      transitionName={transitionName}
      transitionAppear={true}
      transitionEnterTimeout={100}
      transitionLeaveTimeout={100}
      transitionAppearTimeout={100}
      component={React.Fragment}
    >
      <AnimatedComp style={style}>{props.children}</AnimatedComp>
    </ReactCSSTransitionGroup>
  );
};

type CheckPermissions = {
  userPermissions?: string[];
  requiredPermissions?: string | string[];
};
export const checkPermissions = ({
  userPermissions,
  requiredPermissions
}: CheckPermissions) => {
  const userPermission = userPermissions || [];
  if (
    userPermission &&
    requiredPermissions &&
    typeof requiredPermissions === "string"
  ) {
    //check the single permission in user role permissions
    return userPermission.includes(requiredPermissions);
  }
  if (userPermission && requiredPermissions && requiredPermissions?.length) {
    //check multiple permissions
    const requiredP = requiredPermissions as string[];
    const checkRequiredPermissions = (item: string) => {
      return requiredP.some(el => el === item);
    };
    return userPermission.some(item => checkRequiredPermissions(item));
  }
  //this is for the case when buttons don't have any specified permissions
  //for example button for opening up analytics / buttons that lead to specific views etc..
  return true;
};

type InfoType = "info" | "warning" | "success" | "error";
const getInfoTypeColor = (type: InfoType) => {
  switch (type) {
    case "info":
      return {
        leftBorder: "#3F68F6",
        borderColor: "#6F8FF7",
        backgroundColor: "#DAE2FC"
      };
    case "warning":
      return {
        leftBorder: "#FFA033",
        borderColor: "#FFA033",
        backgroundColor: "#FFEFDC"
      };
    case "success":
      return {
        leftBorder: "#4DBF40",
        borderColor: "#5EFF5E",
        backgroundColor: "#E9FFE9"
      };
    case "error":
      return {
        leftBorder: "#EB5740",
        borderColor: "#EF8879",
        backgroundColor: "#FFEBE8"
      };
    default:
      return {
        leftBorder: "#3F68F6",
        borderColor: "#FFA033",
        backgroundColor: "#FFEFDC"
      };
  }
};

const sharedStyle = css<{ type?: InfoType }>`
  border: 1px solid ${props => getInfoTypeColor(props.type).borderColor};
  background-color: ${props => getInfoTypeColor(props.type).backgroundColor};
  border-left: 3px solid ${props => getInfoTypeColor(props.type).leftBorder};
`;
export const InfoMessage = styled(Alert)<{
  type?: InfoType;
}>`
  max-width: 290px;
  align-items: center;
  border-radius: unset;
  &.ant-alert-info {
    ${sharedStyle}
  }
  &.ant-alert-error {
    ${sharedStyle}
  }
  &.ant-alert-warning {
    ${sharedStyle}
  }
  &.ant-alert-success {
    ${sharedStyle}
  }
  .ant-alert-description {
    font-size: 12px !important;
    line-height: 16px;
    color: ${props => props.theme.colors.alertDescription};
    //backgroundcolor: #ffefdc;
  }
`;

interface IAuthorization<T> {
  Component: React.FC<T>;
  containerProps: T;
}

export function AuthorizedComponent<
  T extends {
    permissions?: string | string[];
    disabled?: boolean;
    shouldHideOnPermissionMissing?: boolean;
  }
>(
  props: IAuthorization<T>
  //& { children?: ReactNode }
) {
  const userInfo = useSelector(
    (state: { app: { activeRole: IUserRole } }) => state.app.activeRole
  );

  const isAllowedByPermissions = checkPermissions({
    userPermissions: userInfo.permissions,
    requiredPermissions: props.containerProps.permissions
  });
  const isDisabled = _.get(props, "containerProps.disabled", false);

  const isHiddenByRole =
    userInfo?.type === "company" && props.containerProps?.permissions
      ? true
      : false;
  if (isHiddenByRole) return null;
  const modifiedProps = {
    ...props.containerProps,
    disabled: isDisabled || !isAllowedByPermissions
  };
  return <props.Component {...modifiedProps} />;
}
