/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CompaniesTableQueryVariables = {
  blade_scope: string;
  filterColumn?: Array<string> | null | undefined;
  filterOp?: Array<Array<string>> | null | undefined;
  filterValues?: Array<Array<string>> | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
  skipProduct: boolean;
};
export type CompaniesTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"CompaniesTable_companies">;
};
export type CompaniesTableQuery = {
  readonly response: CompaniesTableQueryResponse;
  readonly variables: CompaniesTableQueryVariables;
};

/*
query CompaniesTableQuery(
  $blade_scope: String!
  $filterColumn: [String!]
  $filterOp: [[String!]!]
  $filterValues: [[String!]!]
  $sortColumn: [String!]
  $sortDirection: [String!]
  $skipProduct: Boolean!
) {
  ...CompaniesTable_companies_3JFf3k
}

fragment CompaniesTable_companies_3JFf3k on Query {
  companiesCount(bladeScope: $blade_scope, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues)
  companies(first: 25, bladeScope: $blade_scope, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues, sortColumn: $sortColumn, sortDirection: $sortDirection) {
    edges {
      node {
        id
        name
        activeUserCount
        licensesCount
        activeLicenseCount
        products @skip(if: $skipProduct) {
          product: nodes {
            name
            id
            iconUrl
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "blade_scope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterOp"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "skipProduct"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v6 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v7 = {
      kind: "Variable",
      name: "filterColumn",
      variableName: "filterColumn"
    } as any,
    v8 = {
      kind: "Variable",
      name: "filterOp",
      variableName: "filterOp"
    } as any,
    v9 = {
      kind: "Variable",
      name: "filterValues",
      variableName: "filterValues"
    } as any,
    v10 = {
      kind: "Variable",
      name: "sortColumn",
      variableName: "sortColumn"
    } as any,
    v11 = {
      kind: "Variable",
      name: "sortDirection",
      variableName: "sortDirection"
    } as any,
    v12 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    v13 = [
      v12 /*: any*/,
      v7 /*: any*/,
      v8 /*: any*/,
      v9 /*: any*/,
      {
        kind: "Literal",
        name: "first",
        value: 25
      } as any,
      v10 /*: any*/,
      v11 /*: any*/
    ],
    v14 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v15 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "CompaniesTableQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "blade_scope",
              variableName: "blade_scope"
            },
            v7 /*: any*/,
            v8 /*: any*/,
            v9 /*: any*/,
            {
              kind: "Variable",
              name: "skipProduct",
              variableName: "skipProduct"
            },
            v10 /*: any*/,
            v11 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "CompaniesTable_companies"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v4 /*: any*/
      ],
      kind: "Operation",
      name: "CompaniesTableQuery",
      selections: [
        {
          alias: null,
          args: [v12 /*: any*/, v7 /*: any*/, v8 /*: any*/, v9 /*: any*/],
          kind: "ScalarField",
          name: "companiesCount",
          storageKey: null
        },
        {
          alias: null,
          args: v13 /*: any*/,
          concreteType: "CompanyConnection",
          kind: "LinkedField",
          name: "companies",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "CompanyEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Company",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    v14 /*: any*/,
                    v15 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "activeUserCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "licensesCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "activeLicenseCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    },
                    {
                      condition: "skipProduct",
                      kind: "Condition",
                      passingValue: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "ProductConnection",
                          kind: "LinkedField",
                          name: "products",
                          plural: false,
                          selections: [
                            {
                              alias: "product",
                              args: null,
                              concreteType: "Product",
                              kind: "LinkedField",
                              name: "nodes",
                              plural: true,
                              selections: [
                                v15 /*: any*/,
                                v14 /*: any*/,
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "iconUrl",
                                  storageKey: null
                                }
                              ],
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        }
                      ]
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v13 /*: any*/,
          filters: [
            "bladeScope",
            "filterColumn",
            "filterOp",
            "filterValues",
            "search",
            "sortColumn",
            "sortDirection"
          ],
          handle: "connection",
          key: "CompaniesTable_companies",
          kind: "LinkedHandle",
          name: "companies"
        }
      ]
    },
    params: {
      cacheID: "05a065e85b86a9ccc7f722b4ca84c1ab",
      id: null,
      metadata: {},
      name: "CompaniesTableQuery",
      operationKind: "query",
      text:
        "query CompaniesTableQuery(\n  $blade_scope: String!\n  $filterColumn: [String!]\n  $filterOp: [[String!]!]\n  $filterValues: [[String!]!]\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n  $skipProduct: Boolean!\n) {\n  ...CompaniesTable_companies_3JFf3k\n}\n\nfragment CompaniesTable_companies_3JFf3k on Query {\n  companiesCount(bladeScope: $blade_scope, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues)\n  companies(first: 25, bladeScope: $blade_scope, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues, sortColumn: $sortColumn, sortDirection: $sortDirection) {\n    edges {\n      node {\n        id\n        name\n        activeUserCount\n        licensesCount\n        activeLicenseCount\n        products @skip(if: $skipProduct) {\n          product: nodes {\n            name\n            id\n            iconUrl\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "bf7cef6882642050003e90d297b1604f";
export default node;
