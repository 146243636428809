import moment from "moment";
import React, { SetStateAction } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox";
import styled, { useTheme } from "styled-components";
import relayEnvironment, { getEnvironment } from "../../api/relay";
import { appConnect, appDispatch } from "../../store/appConnect";
import BladeTemplate from "../../components/bladeManager/bladeTemplate";
import { IBladeBaseProps } from "../../components/bladeManager/types";
import {
  BladeTitle,
  ElmButton,
  ElmToggle,
  getIcon,
  ProductsTable
} from "../../components";
import { ElmSelect, ElmSelectOption } from "../../components/elmSelect";
import AnalyticsComponent from "../../components/analyticsComponent";
import {
  OverviewQuery,
  OverviewQueryResponse,
  OverviewQueryVariables
} from "./__generated__/OverviewQuery.graphql";
import {
  AttributesBox,
  IAttributValuePair
} from "../../components/attributesBox";
import _ from "lodash";
import {
  checkPermissions,
  getAnimatedComponent,
  LoaderContainer
} from "../../components/helpers";
import { faDivide } from "@fortawesome/pro-light-svg-icons";
import AllProductsFilter from "../../components/analyticsComponent/filters/allProducts";
import AllLicensesFilter from "../../components/analyticsComponent/filters/allLicenses";
import AllUsersFilter from "../../components/analyticsComponent/filters/allUsers";
import {
  TimeSpanFilter,
  timeSpanDetail,
  getQueryTimeVariables
} from "../../components/analyticsComponent/filters/timeSpan";
import {
  getFriendlyLicenseName,
  licenseTypeId,
  licenseTypeToName
} from "../../utils";
// import { WidgetContainer } from "components/analyticsComponent/widgetContainer";
import { WidgetContainer } from "../../components/analyticsComponent/widgetContainer";
import { TabbedAnalyticsComponent } from "../../components/analyticsComponent/tabbedAnalytics";
import { Tabs } from "antd";
import LoadingWrapper from "../../components/elmChart/loadingWrapper";
import { LineChart } from "../../components/elmChart";
import ExportButton from "components/elmTable/exportButton";
import { UsersTable, userTableType } from "Users/components/usersTable";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import LicensesTable, {
  LicenseTableType,
  LicensesTableColumns
} from "Licenses/components/licensesTable";
import { REFRESH_KEY } from "components/bladeManager/entityRefreshMap";

const AnimatedContainer = getAnimatedComponent({
  element: "div",
  transitionName: "company-trend-container",
  animations: {
    appear: {
      duration: "1.5s",
      animationType: "slideInRight"
    },
    enter: {
      duration: "1s",
      animationType: "slideInRight"
    }
  }
});

const TabActionsContainer = styled(Flex)`
  position: relative;
  width: 125px;
  top: 8%;
  left: 80%;
  z-index: 1;
`;

const StatusUpdate = styled.span`
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.warmGrey};
`;

const graphqlQuery = graphql`
  query OverviewQuery {
    allUsersCount: usersCount
    allProductsCount: productsCount
    allLicensesCount: licensesCount
    allLicensesSeats: licenses {
      nodes {
        totalSeatCount
      }
    }
  }
`;

interface IQueryVariables extends timeSpanDetail, OverviewQueryVariables {
  sessionSetup: {
    category: string;
    group: string;
    value: string;
  };
  userSetup: {
    category: string;
    group: string;
    value: string;
  };
  productId: string;
}

export interface IOverviewBladeProps extends IBladeBaseProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
  updateVariables: React.Dispatch<SetStateAction<IQueryVariables>>;
  variables: OverviewQueryVariables;
  result: OverviewQueryResponse;
  retry: () => void;
}

type analyticsValue = {
  description: string;
  value: string;
  change?: { type: "up" | "down"; value: string };
};

type HighlightAnalyticsTab = "item-1" | "item-2";
type HighlightAnalyticsTableTab = "item-4" | "item-5";

type state = {
  mountMoment: moment.Moment;
  hideCompanyTrends: boolean;
  queryVariables: OverviewQueryVariables;
  timeSpanDetail: timeSpanDetail;
  bladeMountTime: moment.Moment;
  activeChartTab: HighlightAnalyticsTab;
  activeTableTab: HighlightAnalyticsTableTab;
  group: string;
};
const queryBodyEnvironment = getEnvironment();
const getDefaultTimeSpanDetail = () =>
  getQueryTimeVariables({ filterType: "month1", groupBy: "day" });
export class OverviewBlade extends React.Component<
  IOverviewBladeProps & {
    appState: {
      activeRole: any;
    };
    theme: any;
  },
  state
> {
  state: state = {
    mountMoment: moment(),
    hideCompanyTrends: true,
    queryVariables: this.props.variables,
    timeSpanDetail: getDefaultTimeSpanDetail(),
    bladeMountTime: moment().utc(),
    activeChartTab: "item-1",
    activeTableTab: "item-4",
    group: "products.name"
  };
  public navAway = () => {
    this.props.openBlade({
      fromBladeIndex: this.props.index,
      route: "Licenses",
      routeName: "Licenses",
      routeData: null
    });
  };
  public renderLeftSideHeader = () => {
    return (
      <div style={{ marginLeft: "18px" }}>
        <BladeTitle>Dashboard</BladeTitle>
      </div>
    );
  };
  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: `Dashboard Analytics`,
      fromBladeIndex: this.props.index,
      routeData: {
        type: "Overview",
        title: "Overview"
      }
    });
  };
  public renderUsersFilter = () => {
    const handleChange = (userId: string) => {
      if (userId !== this.state.queryVariables.userId) {
        this.setState({
          queryVariables: { ...this.state.queryVariables, userId }
        });
      }
    };
    return (
      <AllUsersFilter
        onChange={handleChange}
        disabled={this.state.activeChartTab === "item-1"}
      />
    );
  };
  public renderLicensesFilter = () => {
    const handleChange = (licenseId: string) => {
      if (licenseId !== this.state.queryVariables.licenseId) {
        this.setState({
          queryVariables: { ...this.state.queryVariables, licenseId }
        });
      }
    };
    return <AllLicensesFilter onChange={handleChange} />;
  };
  public renderProductsFilter = () => {
    const handleChange = (productId: string) => {
      if (productId !== this.state.queryVariables.productId) {
        this.setState({
          queryVariables: { ...this.state.queryVariables, productId }
        });
      }
    };
    return <AllProductsFilter onChange={handleChange} />;
  };
  public renderTimeSpanSelections = () => {
    const handleChange = (payload: timeSpanDetail) => {
      if (payload.filterType !== this.state.timeSpanDetail.filterType) {
        this.setState({
          timeSpanDetail: payload,
          queryVariables: {
            ...this.state.queryVariables,
            ...payload,
            sessionSetup: {
              category: `time.${payload.groupBy}`,
              group: "user_id",
              value: "sessions.usage_count"
            }
          }
        });
      }
    };

    return (
      <TimeSpanFilter
        onChange={handleChange}
        timeSpan={this.state.timeSpanDetail.filterType}
      />
    );
  };
  public getTimeSpan = () => {
    return this.state.timeSpanDetail.groupBy;
  };
  public getActualTimeSpan = (): string => {
    switch (this.state.timeSpanDetail.filterType) {
      case "last7":
        return "week";
      case "last30":
        return "month";
      case "last90":
        return "quarter";
      case "lastYear":
        return "year";
      default:
        return "period";
    }
  };
  public renderMetricsData = () => {
    const totalSeats = this.props.result?.allLicensesSeats?.nodes?.reduce(
      (total, node) => total + node.totalSeatCount,
      0
    );
    return (
      <div
        className="metrics-container"
        style={{ width: "100%", justifyContent: "space-between" }}
      >
        {this.props.result ? (
          <Flex>
            <p className="subheading-value">
              {_.get(this.props.result, "allUsersCount")}
              <span className="subheading-label">Total users</span>
            </p>
            <p className="subheading-value">
              <span className="bordered" />
              {_.get(this.props.result, "allLicensesCount")}
              <span className="subheading-label">Licenses</span>
            </p>
            <p className="subheading-value">
              <span className="bordered" />
              {_.get(this.props.result, "allProductsCount")}
              <span className="subheading-label">Products</span>
            </p>
            <p className="subheading-value">
              <span className="bordered" />
              {totalSeats}
              <span className="subheading-label">Total seats</span>
            </p>
          </Flex>
        ) : (
          <p className="subheading-value">
            <span className="subheading-label">Loading stats...</span>
          </p>
        )}
        <StatusUpdate>{`Last updated: ${this.state.mountMoment.fromNow()}`}</StatusUpdate>
      </div>
    );
  };
  public getActiveLicenseAnalyticsCardData = (
    result: OverviewQueryResponse
  ) => {
    if (!result || !result.OverviewCurrentActiveLicenses) {
      return null;
    }
    const activeLicenseCount = result.OverviewCurrentActiveLicensesCount as {
      licenseType: licenseTypeId;
      value: number;
    }[];
    const licenseGroups = _.map(activeLicenseCount, type => {
      return {
        name: licenseTypeToName(type.licenseType),
        count: type.value
      };
    });
    const currentCount = _.reduce(
      result.OverviewCurrentActiveLicensesCount,
      (sum, group: any) => {
        return sum + group.value;
      },
      0
    );
    const previousCount = _.reduce(
      result.OverviewPreviousActiveLicensesCount,
      (sum, group: any) => {
        return sum + group.value;
      },
      0
    );

    return {
      title: "Licenses",
      highlighted: {
        description: `Compared to ${previousCount} last ${this.getActualTimeSpan()}`,
        value: currentCount.toString()
      },
      details: _.map(licenseGroups, group => ({
        description: _.startCase(getFriendlyLicenseName(group.name)),
        value: group.count.toString()
      }))
    };
  };
  public getActiveUsersAnalyticsCardData = (result: OverviewQueryResponse) => {
    if (!result || !result.OverviewCurrentActiveLicenses) {
      return null;
    }
    const getCount = (values: { value: number }[]) =>
      _.reduce(
        values,
        (total, obj) => {
          total = obj.value + total;
          return total;
        },
        0
      );
    const currentActiveUsersCount = getCount(
      result.OverviewCurrentActiveUserCounts.values as {
        value: number;
      }[]
    );
    const previousActiveUsersCount = getCount(
      result.OverviewPreviousActiveUserCounts.values as {
        value: number;
      }[]
    );
    const currentRegistrationCount = result.OverviewCurrentRegistrationsCount;
    const sessionData = result.OverviewAverageSessionsData.values as {
      category: string;
      group: number;
      value: number;
    }[];
    const usersWithAtLeastOneSession = _.filter(
      sessionData,
      valueObj => valueObj.value !== 0
    );
    const groupedByUniqueUsers = _.groupBy(
      usersWithAtLeastOneSession,
      obj => obj.group
    );
    // const totalSessionsPerUser = _.map(
    //   groupedByUniqueUsers,
    //   obj =>
    // )
    return {
      title: "Licensed Users",
      highlighted: {
        description: `Compared to ${previousActiveUsersCount.toString()} last ${this.getActualTimeSpan()}`,
        value: currentActiveUsersCount.toString()
      },
      details: [
        {
          description: "Active Users",
          information: "Users with at least 5 sessions in the selected period",
          value: currentActiveUsersCount.toString()
        },
        {
          description: "New registrations",
          value: currentRegistrationCount.toString()
        }
      ]
    };
  };
  public renderRightSideHeader = () => {
    return (
      <Flex
        justifyContent="flex-end"
        className="selections-container"
        marginRight={22}
      >
        <Flex>{this.renderUsersFilter()}</Flex>
        <Flex>{this.renderLicensesFilter()}</Flex>
        <Flex>{this.renderProductsFilter()}</Flex>
      </Flex>
    );
  };
  public renderVendorInformation = () => {
    const items = [
      {
        label: "Owner",
        value: "support@evoleap.com"
      },
      {
        label: "Location",
        value: "10333 Richmond ave suite 450, 77075, Houston TX USA"
      }
    ] as IAttributValuePair[];
    return <AttributesBox items={items} title={"Information"} />;
  };

  public renderHighlightAnalyticsCard = (result: OverviewQueryResponse) => {
    const renderFooterValue = (footerValue: {
      description: string;
      value: number;
    }) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: "40px"
        }}
      >
        <span className={"footer-value"}>
          {footerValue.value < 1
            ? footerValue.value.toFixed(2)
            : footerValue.value.toFixed()}
        </span>
        <span className={"footer-description"}>{footerValue.description}</span>
      </div>
    );
    const sessionsData = this.getSessionsData(result);
    const toggleCompanyTrends = () => {
      this.setState({ hideCompanyTrends: !this.state.hideCompanyTrends });
    };
    return (
      <div className="elm-highlight-analytics-card">
        {this.state.hideCompanyTrends ? null : (
          <AnimatedContainer>
            <div className="company-trends-container">
              <span className="action-link" onClick={toggleCompanyTrends}>
                Hide
              </span>
              Trends
            </div>
          </AnimatedContainer>
        )}
        <div className="elm-highlight-analytics-card-header">
          <span className="elm-highlight-analytics-card-header-title">
            Sessions
          </span>
          <span
            className="elm-highlight-analytics-card-header-action-link"
            // onClick={toggleCompanyTrends}
          >
            {/* Show sessions per company */}
          </span>
        </div>
        <div className="elm-highlight-analytics-card-chart-container">
          <AnalyticsComponent
            analytics={{
              bladeScope: this.state.queryVariables.productId,
              valueTitle: "Session Count",
              setup: {
                category: `time.${this.state.timeSpanDetail.groupBy}`,
                value: "sessions.usage_count",
                group: null
              },
              startTime: this.state.queryVariables.currentTimeSpanStart,
              endTime: this.state.queryVariables.currentTimeSpanEnd
            }}
            type="line"
            containerStyle={{ height: "100%" }}
          />
        </div>

        <div className="elm-highlight-analytics-card-chart-footer">
          {renderFooterValue({
            description: "Average daily unique users",
            value: sessionsData.averageUniqueUsersPerDay
          })}
          {renderFooterValue({
            description: `Average sessions per ${this.state.timeSpanDetail.groupBy}`,
            value: sessionsData.averageSessionsPerDay
          })}
          {renderFooterValue({
            description: `Average sessions per ${this.state.timeSpanDetail.groupBy} per user`,
            value: sessionsData.sessionsPerDayPerUser
          })}
        </div>
      </div>
    );
  };
  public renderInfoBanner = (result: OverviewQueryResponse) => {
    if (!result || !result.OverViewCurrentExpiringLicensesCount) {
      return null;
    }
    const getActiveLicensesByType = () => {
      if (!result || !result.OverViewCurrentExpiringLicenses) {
        return null;
      }
      const types = _.groupBy(
        result.OverViewCurrentExpiringLicenses.nodes,
        node => node.type.name
      );
      const typeNames = _.keys(types);
      return _.map(typeNames, typeName => {
        return (
          <div className="expiring-container">
            <span className="expiring-value">{types[typeName].length}</span>
            <span className="expiring-label">
              {_.startCase(getFriendlyLicenseName(typeName))
                .split("License")[0]
                .trim()}
            </span>
          </div>
        );
      });
    };
    return (
      <div className="banner" onClick={this.navigateToLicenses}>
        <div className="accent" />
        <div className="message-container">
          <div className="message">
            <span className="current-expiring-value">
              {result.OverViewCurrentExpiringLicensesCount}
            </span>
            <span className="current-expiring-label">{`License${
              result.OverViewCurrentExpiringLicensesCount > 1 ? "s" : ""
            } expiring in the next 30 days`}</span>
          </div>
          <div className="extra-expiring-parent-container">
            <div className="extra-expiring-container">
              {getActiveLicensesByType()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  public renderAnalyticsCard = (payload: {
    title: string;
    highlighted: analyticsValue;
    details: analyticsValue[];
  }) => {
    if (!payload) {
      return null;
    }
    return (
      <div className="elm-analytics-card">
        <span className="elm-analytics-card-title">{payload.title}</span>
        <span className="elm-analytics-card-highligthed-value">
          {payload.highlighted.value}
        </span>
        <span className="elm-analytics-card-highligthed-description">
          {payload.highlighted.description}
        </span>
        <div className="elm-analytics-card-details">
          {_.map(payload.details, detail => (
            <div className="elm-analytics-card-detail">
              <span className="license-type-name">{detail.description}</span>
              <span>{detail.value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  public getSessionsData = (result: OverviewQueryResponse) => {
    if (!result || !result.OverviewAverageSessionsData) {
      return {
        averageUniqueUsersPerDay: 0,
        averageSessionsPerDay: 0,
        totalUniqueUsers: 0,
        sessionsPerDayPerUser: 0
      };
    }
    const sessionData = result.OverviewAverageSessionsData.values as {
      category: string;
      group: number;
      value: number;
    }[];
    const usersWithAtLeastOneSession = _.filter(
      sessionData,
      valueObj => valueObj.value !== 0
    );
    const groupedByDay = _.groupBy(
      usersWithAtLeastOneSession,
      obj => obj.category
    );
    const groupedByUniqueUsers = _.groupBy(
      usersWithAtLeastOneSession,
      obj => obj.group
    );
    const numberOfDays = _.keys(groupedByDay).length; // THIS IS WRONG.
    const numberOfUniqueUsers = _.keys(groupedByUniqueUsers).length;
    const { totalNumberOfSessions, totalNumberOfUsers } = _.reduce(
      groupedByDay,
      (total, day) => {
        total.totalNumberOfUsers = total.totalNumberOfUsers + day.length;
        _.each(day, entry => {
          total.totalNumberOfSessions =
            total.totalNumberOfSessions + entry.value;
        });
        return total;
      },
      { totalNumberOfSessions: 0, totalNumberOfUsers: 0 }
    );

    const averageUniqueUsersPerDay = _.floor(
      // THIS DOESN'T GUARANTEE UNIQUENESS
      numberOfUniqueUsers / numberOfDays,
      2
    );
    const averageSessionsPerDay = _.floor(
      totalNumberOfSessions / numberOfDays,
      2
    );
    return {
      averageUniqueUsersPerDay,
      averageSessionsPerDay,
      totalUniqueUsers: numberOfUniqueUsers,
      sessionsPerDayPerUser: _.floor(
        totalNumberOfSessions / numberOfDays / numberOfUniqueUsers,
        2
      )
    };
  };
  public getUserData = () => {};
  public renderBodyQueryRenderer = () => {
    return (
      <QueryRenderer<OverviewQuery>
        environment={queryBodyEnvironment}
        variables={this.state.queryVariables}
        query={graphqlQuery}
        render={this.renderBody}
      />
    );
  };

  public getHighlightAnalytics = () => {
    const filterColumn = ["is_vendor"];
    const filterValues = [["false"]];
    if (this.state.queryVariables?.productId) {
      filterColumn.push("products.id");
      filterValues.push([this.state.queryVariables.productId]);
    }
    if (
      this.state.activeChartTab === "item-2" &&
      this.state.queryVariables?.userId
    ) {
      filterColumn.push("users.id");
      filterValues.push([this.state.queryVariables.userId]);
    }
    return (
      <Tabs
        defaultActiveKey={this.state.activeChartTab}
        onChange={activeChartTab => this.setState({ activeChartTab })}
        style={{ marginLeft: "16px" }}
      >
        <Tabs.TabPane tab="User count" key="item-1">
          <div style={{ minHeight: "100%", height: "266px" }}>
            <LoadingWrapper>
              <LineChart
                showProductFilter={false}
                timeRangeOverride={this.props.variables?.filterType}
                exportOptions={{
                  // disableExport: true
                  showCustomExportButton: true
                }}
                queryParams={{
                  setup: {
                    category: "time.day",
                    value: "counts.active_user",
                    group: this.state.group
                  },
                  filterColumn,
                  filterValues,
                  bladeScope: this.state.queryVariables?.licenseId,
                  startTime: this.props.variables.currentTimeSpanStart,
                  endTime: this.props.variables.currentTimeSpanEnd
                }}
                options={{
                  chartMaxHeight: 200
                }}
                plot={
                  {
                    //title: "Session Count",
                  }
                }
              />
            </LoadingWrapper>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Session count" key="item-2">
          <div style={{ minHeight: "100%", height: "266px" }}>
            <LoadingWrapper>
              <LineChart
                showProductFilter={false}
                timeRangeOverride={this.props.variables?.filterType}
                exportOptions={{
                  // disableExport: true
                  showCustomExportButton: true
                }}
                queryParams={{
                  //bladeScope: this.props.productId,
                  setup: {
                    // category: `time.month`, //`time.${this.props.timespan}`,
                    // value: "sessions.usage_duration",
                    // group: "products.name",
                    category: "products.name",
                    group: "products.name", //"user_id",
                    value: "sessions.usage_count"
                  },
                  filterColumn,
                  filterValues,
                  bladeScope: this.state.queryVariables?.licenseId,
                  startTime: this.props.variables.currentTimeSpanStart,
                  endTime: this.props.variables.currentTimeSpanEnd
                }}
                options={{
                  chartMaxHeight: 200
                }}
                plot={
                  {
                    //title: "Session Count",
                  }
                }
              />
            </LoadingWrapper>
          </div>
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Unique users" key="item-3">
          <div style={{ minHeight: "100%" }}>
            <LoadingWrapper>
              <LineChart
                showProductFilter={false}
                hideTimeRangeFilter
                queryParams={{
                  //bladeScope: this.props.productId,
                  setup: {
                    category: `time.month`, //`time.${this.props.timespan}`,
                    value: "sessions.usage_duration",
                    group: "products.name",
                  },
                  startTime: this.props.variables.currentTimeSpanStart,
                  endTime: this.props.variables.currentTimeSpanEnd,
                }}
                options={{
                  chartMaxHeight: 400,
                }}
                plot={
                  {
                    //title: "Session Count",
                  }
                }
              />
            </LoadingWrapper>
          </div>
        </Tabs.TabPane> */}
      </Tabs>
    );
  };

  public getHighlightAnalyticsTable = () => {
    const res = this.props.result;
    const permissions = _.get(this.props.appState, "activeRole.permissions");
    const havePermissions = checkPermissions({
      userPermissions: permissions,
      requiredPermissions: ["modify_assets"]
    });
    const navToLicenseBlade = (license: LicenseTableType) => {
      this.props.openBlade({
        route: "License",
        routeName: "License",
        fromBladeIndex: this.props.index,
        routeData: {
          id: license.id,
          productId: license.product.id
        }
      });
    };
    const navToUserBlade = (user: userTableType) => {
      this.props.openBlade({
        route: "User",
        routeName: "User",
        fromBladeIndex: this.props.index,
        routeData: {
          id: user.id,
          name: user?.name
        }
      });
    };
    const renderEnableToggle = (payload: userTableType) => {
      const userId = _.get(payload, "id");
      let isEnabled = false;
      payload?.productUsers?.nodes?.forEach(node => {
        if (node?.enabled) {
          isEnabled = true;
        }
      });
      const callToAction = (
        activate: boolean,
        id: string,
        product_id: string
      ) => {
        if (activate) {
          this.props.gateway.request
            .enableProduct({ product_id }, { id })
            .then(res => {
              if (!res.error) {
                renderSuccessNotification("Enabled product");
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              renderErrorNotification("Error on product activation");
              console.error(err);
            });
        }
        if (!activate) {
          this.props.gateway.request
            .disableProduct({ product_id }, { id })
            .then(res => {
              if (!res.error) {
                renderSuccessNotification("Deactivated product");
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              renderErrorNotification("Error on product deactivation");
              console.error(err);
            });
        }
      };
      const handleChange = (activate: boolean) => {
        if (payload?.productUsers?.nodes?.length === 0) {
          return;
        }
        if (payload?.productUsers?.nodes?.length === 1) {
          const productId = _.get(payload, "productUsers.nodes[0].product.id");
          callToAction(activate, userId, productId);
        }
        if (payload?.productUsers?.nodes?.length > 1) {
          this.props.openDialog("SelectEnableProductDialog", {
            userId,
            productUsers: payload?.productUsers?.nodes
          });
        }
      };

      return (
        <Flex onClick={e => e.stopPropagation()}>
          <ElmToggle
            size="small"
            onChange={handleChange}
            checked={isEnabled}
            permissions={"modify_assets"}
          />
        </Flex>
      );
    };
    return (
      <>
        <TabActionsContainer>
          {this.state.activeTableTab === "item-4" ? (
            <ExportButton
              colorVariance={"outline-secondary"}
              tableType={"licensesTable"}
            />
          ) : null}
          {this.state.activeTableTab === "item-5" ? (
            <ExportButton
              colorVariance={"outline-secondary"}
              tableType={"users"}
            />
          ) : null}
          <ElmButton
            onClick={() => {
              if (this.state.activeTableTab === "item-4") {
                this.props.openBlade({
                  route: "Licenses",
                  routeName: "Licenses",
                  routeData: null,
                  fromBladeIndex: this.props.index
                });
              }
              if (this.state.activeTableTab === "item-5") {
                this.props.openBlade({
                  route: "Users",
                  routeName: "Users",
                  routeData: null,
                  fromBladeIndex: this.props.index
                });
              }
            }}
            icon="external-link-alt"
            iconPrefix="far"
            label=""
            variance="icon-button"
            colorVariance="primary"
          />
        </TabActionsContainer>
        <Tabs
          defaultActiveKey={this.state.activeTableTab}
          onChange={activeTableTab => this.setState({ activeTableTab })}
          style={{ marginLeft: "16px", marginRight: "16px" }}
        >
          <Tabs.TabPane
            tab="Active licenses"
            key="item-4"
            style={{ height: "336px" }}
          >
            <LicensesTable
              bladeName={"Overview"}
              setRefreshFn={this.props.setEntityRefreshFn(
                REFRESH_KEY.OverviewLicensesTable
              )}
              refreshKey={REFRESH_KEY.OverviewLicensesTable}
              removeRefreshFn={this.props.removeRefreshFn}
              onRowClick={navToLicenseBlade}
              hideColumnsWithHeaders={[
                LicensesTableColumns.CommercialModel,
                LicensesTableColumns.Company,
                LicensesTableColumns.Actions
              ]}
              columnAndHeaderStyles={{
                justifyFirstHeaderColumnContent: "flex-start",
                justifyColumnContent: "flex-start",
                justifyLastHeaderColumnContent: "center"
              }}
            />
            {/* <Flex>{this.renderProductsTable()}</Flex> */}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Active users"
            key="item-5"
            style={{ height: "336px" }}
          >
            <UsersTable
              hideColumnsWithHeaders={[
                "Company",
                "Email",
                "Actions",
                this.props.result?.allProductsCount <= 1 ? "Product" : null,
                !havePermissions ? "Enable" : null
              ]}
              onRowClick={navToUserBlade}
              renderToggleColumn={renderEnableToggle}
              bladeName={"Overview"}
              setRefreshFn={this.props.setEntityRefreshFn(
                REFRESH_KEY.OverviewUsersTable
              )}
              refreshKey={REFRESH_KEY.OverviewUsersTable}
              removeRefreshFn={this.props.removeRefreshFn}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  };

  public renderBody = (payload: {
    error: Error;
    props: OverviewQueryResponse;
  }) => {
    if (!payload.props || payload.error) {
      return <LoaderContainer />;
    }
    return (
      // <Flex
      //   style={{
      //     // marginBottom: '35px',
      //     paddingLeft: "32px",
      //     paddingRight: "38px",
      //     flexDirection: "column",
      //     flex: 1,
      //     overflowY: "scroll",
      //   }}
      //   className="license-analytics analytics-container"
      // >
      <>
        <div className="products-analytics analytics-container">
          <WidgetContainer size="x-large">
            {this.getHighlightAnalytics()}
          </WidgetContainer>
        </div>
        <Flex className="analytics-container">
          <WidgetContainer size="x-large">
            {this.getHighlightAnalyticsTable()}
          </WidgetContainer>
        </Flex>
      </>
      // </Flex>
    );
  };
  public render() {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        closeBlade={this.props.closeBlade}
        bladeType="Overview"
        title={"Customer portal"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        topAccentColor={"warmGrey"}
        hideCloseButton={true}
        hideBladeTypeName={true}
        refreshFn={this.props.retry}
        setRefreshFn={this.props.setRefreshFn}
        className="analytics-blade"
        // loading={!this.props.result}
      >
        <Flex
          style={{
            flexDirection: "column",
            flex: 1,
            overflowY: "scroll"
          }}
        >
          <Flex marginTop={12}>{this.renderMetricsData()}</Flex>
          <div
            className={`analytics-body`}
            style={{
              marginLeft: "18px",
              marginRight: "18px",
              height: "100%"
            }}
          >
            {this.renderBodyQueryRenderer()}
          </div>
        </Flex>
      </BladeTemplate>
    );
  }
}

const RenderQuery = (props: IOverviewBladeProps) => {
  const defaultTimeSpanDetail = getDefaultTimeSpanDetail();
  const [variables, updateVariables] = React.useState({
    ...defaultTimeSpanDetail,
    licensesExpiringTimeSpanStart: moment()
      .utc()
      .format(),
    licensesExpiringTimeSpanEnd: moment()
      .add(30, "days")
      .utc()
      .format(),
    sessionSetup: {
      category: `time.${defaultTimeSpanDetail.groupBy}`,
      group: "user_id",
      value: "sessions.usage_count"
    },
    userSetup: {
      category: "product_id",
      group: null,
      value: "counts.active_user"
    },
    productId: null
  });
  const theme = useTheme();
  const renderBlade = (payload: {
    error: Error;
    props: OverviewQueryResponse;
    retry: () => void;
  }) => {
    return (
      <OverviewBlade
        {...props}
        theme={theme}
        variables={variables}
        result={payload.props}
        retry={payload.retry}
        updateVariables={updateVariables}
      />
    );
  };
  return (
    <QueryRenderer<OverviewQuery>
      environment={relayEnvironment}
      variables={variables}
      query={graphqlQuery}
      render={renderBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {
    activeRole: "activeRoleSelector"
  }
});
