/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type licensesTableExportQueryVariables = {
  bladeScope?: string | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
  filterColumn?: Array<string> | null | undefined;
  filterOp?: Array<Array<string>> | null | undefined;
  filterValues?: Array<Array<string>> | null | undefined;
};
export type licensesTableExportQueryResponse = {
  readonly licenses: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly type: {
        readonly id: string;
      };
      readonly isVendor: boolean;
      readonly owner: {
        readonly name: string | null;
        readonly type: string | null;
      } | null;
      readonly product: {
        readonly id: string;
        readonly name: string | null;
        readonly iconUrl: string | null;
      } | null;
      readonly isTrial: boolean;
      readonly key: string;
      readonly latestTerm: {
        readonly endDate: unknown | null;
        readonly startDate: unknown | null;
      } | null;
    } | null> | null;
  } | null;
};
export type licensesTableExportQuery = {
  readonly response: licensesTableExportQueryResponse;
  readonly variables: licensesTableExportQueryVariables;
};

/*
query licensesTableExportQuery(
  $bladeScope: String
  $sortColumn: [String!]
  $sortDirection: [String!]
  $filterColumn: [String!]
  $filterOp: [[String!]!]
  $filterValues: [[String!]!]
) {
  licenses(bladeScope: $bladeScope, sortColumn: $sortColumn, sortDirection: $sortDirection, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues) {
    nodes {
      id
      type {
        id
      }
      isVendor
      owner {
        __typename
        name
        type
        id
      }
      product {
        id
        name
        iconUrl
      }
      isTrial
      key
      latestTerm {
        endDate
        startDate
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "bladeScope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterOp"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v6 = [
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "bladeScope"
      } as any,
      {
        kind: "Variable",
        name: "filterColumn",
        variableName: "filterColumn"
      } as any,
      {
        kind: "Variable",
        name: "filterOp",
        variableName: "filterOp"
      } as any,
      {
        kind: "Variable",
        name: "filterValues",
        variableName: "filterValues"
      } as any,
      {
        kind: "Variable",
        name: "sortColumn",
        variableName: "sortColumn"
      } as any,
      {
        kind: "Variable",
        name: "sortDirection",
        variableName: "sortDirection"
      } as any
    ],
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      concreteType: "LicenseModel",
      kind: "LinkedField",
      name: "type",
      plural: false,
      selections: [v7 /*: any*/],
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "isVendor",
      storageKey: null
    } as any,
    v10 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v11 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "type",
      storageKey: null
    } as any,
    v12 = {
      alias: null,
      args: null,
      concreteType: "Product",
      kind: "LinkedField",
      name: "product",
      plural: false,
      selections: [
        v7 /*: any*/,
        v10 /*: any*/,
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "iconUrl",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v13 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "isTrial",
      storageKey: null
    } as any,
    v14 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "key",
      storageKey: null
    } as any,
    v15 = {
      alias: null,
      args: null,
      concreteType: "Term",
      kind: "LinkedField",
      name: "latestTerm",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "endDate",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "startDate",
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "licensesTableExportQuery",
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: "LicenseConnection",
          kind: "LinkedField",
          name: "licenses",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "License",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v7 /*: any*/,
                v8 /*: any*/,
                v9 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: null,
                  kind: "LinkedField",
                  name: "owner",
                  plural: false,
                  selections: [v10 /*: any*/, v11 /*: any*/],
                  storageKey: null
                },
                v12 /*: any*/,
                v13 /*: any*/,
                v14 /*: any*/,
                v15 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/
      ],
      kind: "Operation",
      name: "licensesTableExportQuery",
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: "LicenseConnection",
          kind: "LinkedField",
          name: "licenses",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "License",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v7 /*: any*/,
                v8 /*: any*/,
                v9 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: null,
                  kind: "LinkedField",
                  name: "owner",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    },
                    v10 /*: any*/,
                    v11 /*: any*/,
                    v7 /*: any*/
                  ],
                  storageKey: null
                },
                v12 /*: any*/,
                v13 /*: any*/,
                v14 /*: any*/,
                v15 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "c61c3a5da55eac260e66ffff8a423699",
      id: null,
      metadata: {},
      name: "licensesTableExportQuery",
      operationKind: "query",
      text:
        "query licensesTableExportQuery(\n  $bladeScope: String\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n  $filterColumn: [String!]\n  $filterOp: [[String!]!]\n  $filterValues: [[String!]!]\n) {\n  licenses(bladeScope: $bladeScope, sortColumn: $sortColumn, sortDirection: $sortDirection, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues) {\n    nodes {\n      id\n      type {\n        id\n      }\n      isVendor\n      owner {\n        __typename\n        name\n        type\n        id\n      }\n      product {\n        id\n        name\n        iconUrl\n      }\n      isTrial\n      key\n      latestTerm {\n        endDate\n        startDate\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "8126635970743082303c42bbda3778b7";
export default node;
