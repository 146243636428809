import React from "react";

type defaultValue = {
  lastBladeIndex: number;
};
const defaultValue: defaultValue = {
  lastBladeIndex: null
};
export const context = React.createContext(defaultValue);
export default context;
