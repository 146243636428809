import React from "react";
import { Flex } from "reflexbox/styled-components";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenAddReportBlade
} from "../../../components/bladeManager/types";
import {
  BladeTemplate,
  ElmButton,
  BladeTitle,
  ElmInput,
  getIcon
} from "../../../components";
import { Switch, Tooltip } from "antd";
import { StyledLabel } from "components/elmInput/styles";
import styled, { useTheme } from "styled-components";
import { getGateway } from "api";
import { graphql } from "babel-plugin-relay/macro";
import { getEnvironment } from "api/relay";
import { QueryRenderer } from "react-relay";
import dayjs, { Dayjs } from "dayjs";
import { ElmDatePickerWithLabel } from "components/elmDate";
import {
  AddReportQuery,
  AddReportQueryResponse
} from "./__generated__/AddReportQuery.graphql";
import {
  REPORT_TYPE,
  Report,
  ReportType,
  TimeRangeOption,
  AggregateField,
  MaximumUsageReportDataType
} from "Reports/common/types";
import { ElmSelectOption, ElmSelectWithLabel } from "components/elmSelect";
import { ColumnsConfig, TimeRange } from "Reports/common/const";
import _ from "lodash";
import ProductSelect from "./components/productSelect";
import { productSelectQueryResponse } from "./components/productSelect/__generated__/productSelectQuery.graphql";
import CompaniesSelect from "./components/companiesSelect";
import ComponentsSelect from "./components/componentsSelect";
import ColumnSelect from "./components/columnSelect";
import { base64EncodeId } from "utils/base64EncodeDecode";
import {
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { getSavedRoleTypeFromLocalSync } from "utils";
import ElmRadio from "components/elmRadio";
import { RadioChangeEvent } from "antd/lib/radio";

const graphqlQuery = graphql`
  query AddReportQuery($id: ID!, $skip: Boolean!) {
    report(id: $id) @skip(if: $skip) {
      id
      name
      reportType
      options
      isPrivate
    }
  }
`;

const AdvancedOptionsTitle = styled.p`
  ${props => props.theme.fontType.visualizationSubTitle};
  margin-left: 12px;
`;

const FieldColumn = styled(Flex)`
  flex-direction: column;
  width: 320px;
  margin-inline: 28px;
`;

export interface IAddReportBladeProps
  extends IBladeBaseProps<IOpenAddReportBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}

interface IReportBladeState {
  reportId: string;
  reportType: ReportType | null;
  reportName: string;
  aggregateField: AggregateField | null;
  timeRange: TimeRangeOption;
  isTimeRangeOpen: boolean;
  startMoment: Dayjs;
  endMoment: Dayjs;
  includeCharts: boolean;
  isPrivate: boolean;
  isRawData: boolean;
  minimumSessionLength: number;
  reportingInterval: number;
  companies: string[];
  components: string[];
  product: string[];
  columns: string[];
}

class AddReportBlade extends React.Component<
  IAddReportBladeProps & {
    result?: AddReportQueryResponse;
    retry?: () => void;
    theme?: any;
  },
  IReportBladeState
> {
  private DEFAULT_SESSION_LENGTH = 10;
  private DEFAULT_REPORTING_INTERVAL = 1; // hours

  public state: IReportBladeState = {
    reportId: "",
    reportType: null,
    reportName: "",
    aggregateField: null,
    timeRange: TimeRange.month1,
    isTimeRangeOpen: false,
    startMoment: dayjs().subtract(TimeRange.month1.value, "days"),
    endMoment: null,
    includeCharts: false,
    isPrivate: false,
    isRawData: false,
    minimumSessionLength: this.DEFAULT_SESSION_LENGTH,
    reportingInterval: this.DEFAULT_REPORTING_INTERVAL,
    companies: [],
    product: [],
    components: [],
    columns: []
  };
  public navAway = () => {
    this.props.openBlade({
      routeName: "Customer portal",
      fromBladeIndex: this.props.index,
      route: "Overview",
      routeData: null
    });
  };
  public componentDidUpdate(_prevProps: any, prevState: IReportBladeState) {
    if (this.state.isTimeRangeOpen) {
      if (this.state.timeRange?.label === prevState.timeRange?.label) {
        return;
      }
      // Manually close dropdown on selecting a pre-defined option
      const validTimeRange =
        TimeRange[
          Object.keys(TimeRange).find(
            key => TimeRange[key]?.label === this.state.timeRange?.label
          )
        ];
      if (validTimeRange) {
        this.setState({ isTimeRangeOpen: false });
      }
    }
    if (!this.props.result?.report) {
      return;
    }
    if (this.props.routeData?.id === this.state.reportId) {
      return;
    }
    const res = this.props.result?.report;
    const startMoment = res?.options?.options?.filters?.startTime
      ? dayjs(res?.options?.options?.filters?.startTime)
      : dayjs().subtract(TimeRange.month1.value, "days");
    const endMoment = res?.options?.options?.filters?.endTime
      ? dayjs(res?.options?.options?.filters?.endTime)
      : null;
    const diff = res?.options?.options?.filters?.duration
      ? res?.options?.options?.filters?.duration
      : endMoment
      ? endMoment?.diff(startMoment, "days")
      : dayjs().diff(startMoment, "days");

    const mappedReportData: IReportBladeState = {
      ...this.state,
      reportId: _.get(res, "id", ""),
      reportType: _.get(res, "reportType", null),
      reportName: _.get(res, "name", ""),
      timeRange:
        TimeRange[
          Object.keys(TimeRange).find(key => TimeRange[key]?.value === diff)
        ],
      isTimeRangeOpen: false,
      startMoment,
      endMoment,
      includeCharts: _.get(res, "options.options.includeCharts", false),
      aggregateField: _.get(res, "options.options.aggregateField", null),
      isPrivate: _.get(res, "isPrivate", false),
      isRawData: _.get(res, "options.options.rawData", false),
      minimumSessionLength: _.get(
        res,
        "options.options.minimumSessionLength",
        this.DEFAULT_SESSION_LENGTH
      ),
      reportingInterval: _.get(
        res,
        "reportingInterval",
        this.DEFAULT_REPORTING_INTERVAL
      ),
      columns: _.get(res, "options.options.columns", []),
      companies: res?.options?.options?.filters?.companyId
        ? res?.options?.options?.filters?.companyId?.map(comp =>
            base64EncodeId("Company", comp)
          )
        : [],
      product: res?.options?.options?.filters?.productId
        ? [
            base64EncodeId(
              "Product",
              res?.options?.options?.filters?.productId[0]
            )
          ]
        : [],
      components: res?.options?.options?.filters?.componentId
        ? res?.options?.options?.filters?.componentId?.map(comp =>
            base64EncodeId("Component", comp)
          )
        : []
    };
    if (_.isEqual(this.state, mappedReportData)) {
      return;
    }

    this.setState({ ...mappedReportData });
  }
  public createReport = () => {
    const newReport: Report = {
      name: this.state.reportName,
      report_type: this.state.reportType,
      options: {
        filters: {
          company_id: this.state.companies,
          product_id: this.state.product,
          component_id: this.state.components,
          duration: this.state.endMoment ? null : this.state.timeRange?.value,
          start_time: this.state.startMoment,
          end_time: this.state.endMoment
        },
        columns: this.state.columns,
        include_charts: this.state.includeCharts,
        raw_data: this.state.isRawData,
        minimum_session_length: this.isMaximumUsageReportType()
          ? this.state.minimumSessionLength
          : null,
        aggregate_field: this.isCompany()
          ? AggregateField.User
          : this.state.aggregateField
      },
      reporting_interval: this.isMaximumUsageReportType()
        ? this.state.reportingInterval
        : null,
      duration: this.state.endMoment ? null : this.state.timeRange?.value,
      start_time: this.state.startMoment,
      end_time: this.state.endMoment,
      is_private: this.state.isPrivate
    };
    const gateway = getGateway();
    gateway.request["newReport"](newReport)
      .then((res: any) => {
        if (!res.error && res?.data?.id) {
          renderSuccessNotification("Successfully created Report !");
          this.props.closeBlade({ route: "AddReport" });
          this.props.openBlade({
            route: "Report",
            routeName: "Report",
            fromBladeIndex: this.props.index,
            routeData: {
              id: base64EncodeId("Report", res.data.id),
              name: res.data.name
            }
          });
          this.props.refreshAllOpenBlades();
        }
      })
      .catch(err => {
        renderFailureNotification("Report generation failed, please try again");
      });
  };
  public editReport = () => {
    if (!this.props.routeData?.id) {
      return;
    }
    const report: Report = {
      name: this.state.reportName,
      report_type: this.state.reportType,
      options: {
        filters: {
          company_id: this.state.companies,
          product_id: this.state.product,
          component_id: this.state.components,
          duration: this.state.endMoment ? null : this.state.timeRange?.value,
          start_time: this.state.startMoment,
          end_time: this.state.endMoment
        },
        columns: this.state.columns,
        include_charts: this.state.includeCharts,
        raw_data: this.state.isRawData,
        minimum_session_length: this.isMaximumUsageReportType()
          ? this.state.minimumSessionLength
          : null,
        aggregate_field: this.state.aggregateField
      },
      reporting_interval: this.isMaximumUsageReportType()
        ? this.state.reportingInterval
        : null,
      duration: this.state.endMoment ? null : this.state.timeRange?.value,
      start_time: this.state.startMoment,
      end_time: this.state.endMoment,
      is_private: this.state.isPrivate
    };
    const gateway = getGateway();
    gateway.request["editReport"](report, {
      id: atob(this.props.routeData?.id)?.replace("Report-", "")
    })
      .then((res: any) => {
        if (!res.error && res?.data?.id) {
          renderSuccessNotification("Successfully updated Report !");
          this.props.closeBlade({ route: "AddReport" });
          this.props.openBlade({
            route: "Report",
            routeName: "Report",
            fromBladeIndex: this.props.index,
            routeData: {
              id: base64EncodeId("Report", res.data.id),
              name: res.data.name
            }
          });
          this.props.refreshAllOpenBlades();
        }
      })
      .catch(err => {
        renderFailureNotification("Report update failed, please try again");
      });
  };
  public isCompany = () =>
    getSavedRoleTypeFromLocalSync() === "company" ||
    _.get(this.props, "appState.activeRole.type", "") === "company";

  public isMaximumUsageReportType = () =>
    // eslint-disable-next-line eqeqeq
    this.state.reportType == REPORT_TYPE.MaximumUsageReport;

  public handeSelectTimeRange(days: number) {
    if (!days) {
      return;
    }
    this.setState({
      startMoment: dayjs().subtract(days, "days"),
      endMoment: null,
      timeRange:
        TimeRange[
          Object.keys(TimeRange).find(key => TimeRange[key]?.value === days)
        ]
    });
  }
  public renderReportNameField = () => {
    const res = _.get(this.props.result, "report.name", "");
    return (
      <Flex flexDirection={"column"} flex={1} style={{ margin: 12 }}>
        <ElmInput
          label={"Report name"}
          placeholder={"Enter name"}
          value={this.state.reportName}
          required={true}
          defaultValue={res}
          updatedValue={value => this.setState({ reportName: value })}
        />
      </Flex>
    );
  };
  public renderAggregateUsageFilter = () => {
    if (this.isCompany()) {
      return null;
    }
    if (!this.state.reportType || this.isMaximumUsageReportType()) {
      return null;
    }
    const handleChange = (e: RadioChangeEvent) => {
      this.setState({ aggregateField: e.target.value });
    };
    return (
      <Flex marginLeft={12} flexDirection={"column"}>
        <StyledLabel>Aggregate usage by:</StyledLabel>
        <Flex marginTop={"6px"}>
          <ElmRadio
            onChange={handleChange}
            value={AggregateField.Company}
            checked={this.state.aggregateField === AggregateField.Company}
          >
            Company
          </ElmRadio>
          <ElmRadio
            onChange={handleChange}
            value={AggregateField.User}
            checked={this.state.aggregateField === AggregateField.User}
          >
            User
          </ElmRadio>
        </Flex>
      </Flex>
    );
  };
  public renderColumnSelect = () => {
    if (!this.state.reportType) {
      return null;
    }
    const defaultValues = [...ColumnsConfig[this.state.reportType].columns];
    if (this.isMaximumUsageReportType()) {
      ColumnsConfig[REPORT_TYPE.MaximumUsageReport]?.additionalColumns[
        this.state.isRawData
          ? MaximumUsageReportDataType.RawData
          : MaximumUsageReportDataType.Consolidated
      ].forEach(column => {
        defaultValues.push(column);
      });
    }
    const handleSelect = (columns: string[]) => {
      this.setState({ columns });
    };
    return (
      <ColumnSelect
        isRawData={this.state.isRawData}
        reportType={this.state.reportType}
        handleSelect={handleSelect}
        defaultValue={_.get(this.state, "columns", defaultValues)}
      />
    );
  };
  public renderRawDataFilter = () => {
    if (!this.isMaximumUsageReportType()) {
      return null;
    }
    const handleChange = (isRawData: boolean) => {
      const columns = [...ColumnsConfig[this.state.reportType].columns];
      ColumnsConfig[REPORT_TYPE.MaximumUsageReport]?.additionalColumns[
        isRawData
          ? MaximumUsageReportDataType.RawData
          : MaximumUsageReportDataType.Consolidated
      ].forEach(column => {
        columns.push(column);
      });
      this.setState({ isRawData, columns });
    };

    return (
      <Flex style={{ margin: 12 }}>
        <Switch
          size="small"
          style={{ width: 32, height: 16 }}
          checked={this.state.isRawData}
          onChange={isRawData => handleChange(isRawData)}
        />
        <StyledLabel style={{ marginLeft: 8 }}>Raw data</StyledLabel>
      </Flex>
    );
  };
  public renderMinimumSessionLengthFilter = () => {
    if (!this.isMaximumUsageReportType()) {
      return null;
    }
    return (
      <Flex style={{ margin: 12 }}>
        <ElmInput
          updatedValue={value =>
            this.setState({ minimumSessionLength: Number(value) })
          }
          defaultValue={_.get(
            this.state,
            "minimumSessionLength",
            this.DEFAULT_SESSION_LENGTH
          )}
          type="number"
          label={"Minimum session length (sec)"}
        />
      </Flex>
    );
  };
  public renderReportingIntervalFilter = () => {
    if (!this.isMaximumUsageReportType()) {
      return null;
    }
    return (
      <Flex style={{ margin: 12 }}>
        <ElmInput
          updatedValue={value =>
            this.setState({ reportingInterval: Number(value) })
          }
          defaultValue={_.get(
            this.state,
            "reportingInterval",
            this.DEFAULT_REPORTING_INTERVAL
          )}
          type="number"
          isDecimalNumber={true}
          label={"Reporting Interval (hour)"}
        />
      </Flex>
    );
  };
  public renderAdvancedOptions = () => {
    if (this.isMaximumUsageReportType()) {
      return (
        <>
          <AdvancedOptionsTitle>Advanced options</AdvancedOptionsTitle>
          {this.renderMinimumSessionLengthFilter()}
          {this.renderReportingIntervalFilter()}
        </>
      );
    }
    return null;
  };
  public renderProductSelect = () => {
    const handleSelect = (
      product: productSelectQueryResponse["products"]["nodes"][number]
    ) => {
      this.setState({
        product: [product?.id],
        components: []
      });
    };
    return (
      <ProductSelect
        handleSelect={handleSelect}
        defaultValue={_.get(
          this.props.result,
          "report.options.options.filters.productId[0]"
        )}
      />
    );
  };
  public renderCompaniesSelect = () => {
    if (this.isCompany()) {
      return null;
    }
    const handleSelect = (companies: string[]) => {
      this.setState({ companies });
    };
    return (
      <CompaniesSelect
        handleSelect={handleSelect}
        defaultValue={_.get(
          this.props.result,
          "report.options.options.filters.companyId"
        )}
      />
    );
  };
  public renderComponentSelect = () => {
    // eslint-disable-next-line eqeqeq
    if (this.state.reportType != REPORT_TYPE.ComponentUsage) {
      return null;
    }
    if (!this.state.product[0]) {
      return (
        <Flex style={{ margin: 12 }} flexDirection="column" flex={1}>
          <ElmSelectWithLabel
            label="Components"
            placeholder="Product has no components"
            disabled={true}
          />
        </Flex>
      );
    }
    const handleSelect = (components: string[]) => {
      this.setState({ components });
    };
    return (
      <ComponentsSelect
        productId={this.state.product[0]}
        handleSelect={handleSelect}
        defaultValue={_.get(
          this.props.result,
          "report.options.options.filters.componentId"
        )}
      />
    );
  };
  public toggleTimerangeOpen(isOpen: boolean) {
    this.setState({ isTimeRangeOpen: isOpen });
  }
  public renderTimeRangeFilter = () => {
    const options = Object.keys(TimeRange).map(key => ({
      key: TimeRange[key].key,
      label: TimeRange[key].label,
      value: TimeRange[key].value
    }));

    return (
      <Flex style={{ margin: 12 }} flexDirection="column" flex={1}>
        <ElmSelectWithLabel
          label="Time Range"
          value={this.state.timeRange?.label || "Custom"}
          onClick={() => this.toggleTimerangeOpen(true)}
          onBlur={() => this.toggleTimerangeOpen(false)}
          onSelect={(days: number | null) => this.handeSelectTimeRange(days)}
          open={this.state.isTimeRangeOpen}
          style={{ padding: 0, margin: 0 }}
        >
          {options?.map(node => {
            return (
              <ElmSelectOption
                key={node.key}
                value={node.value}
                label={node.label}
              >
                {node.label}
              </ElmSelectOption>
            );
          })}
          <ElmSelectOption>
            <Flex
              style={{
                margin: 0,
                padding: 5,
                border: `1px solid ${this.props.theme.colors.greyish}`
              }}
            >
              <ElmDatePickerWithLabel
                value={this.state.startMoment}
                defaultValue={this.state.startMoment}
                onChange={startMoment =>
                  this.setState({ startMoment, timeRange: null })
                }
                disabledDate={d =>
                  d.isAfter(
                    this.state.endMoment
                      ? dayjs(this.state.endMoment).subtract(1, "day")
                      : dayjs().subtract(1, "day")
                  )
                }
                disableUnderline={true}
                placeholder="Start date"
                allowClear
              />
            </Flex>
            <Flex
              style={{
                margin: 0,
                padding: 5,
                border: `1px solid ${this.props.theme.colors.greyish}`
              }}
            >
              <ElmDatePickerWithLabel
                value={this.state.endMoment}
                defaultValue={this.state.endMoment}
                onChange={endMoment =>
                  this.setState({ endMoment, timeRange: null })
                }
                disabledDate={d => {
                  return (
                    d.isAfter(dayjs().toDate()) ||
                    d.isBefore(dayjs(this.state.startMoment).add(1, "day"))
                  );
                }}
                disableUnderline={true}
                placeholder="End date"
                allowClear
              />
            </Flex>
          </ElmSelectOption>
        </ElmSelectWithLabel>
      </Flex>
    );
  };
  public renderIncludeChartsFilter() {
    return (
      <Flex flex={1} style={{ margin: 12 }}>
        <Switch
          size="small"
          style={{ width: 32, height: 16 }}
          checked={this.state.includeCharts}
          onChange={includeCharts => this.setState({ includeCharts })}
        />
        <StyledLabel style={{ marginLeft: 8 }}>Include charts</StyledLabel>
      </Flex>
    );
  }
  public renderPrivateReportFilter = () => {
    const InfoIcon = styled(getIcon("AiFillInfoCircle"))`
      width: 14px;
      height: 14px;
      margin-left: 5px;
      color: ${props => props.theme.colors.primary};
    `;
    return (
      <Flex flex={1} style={{ margin: 12, marginBottom: 36 }}>
        <Switch
          size="small"
          style={{ width: 32, height: 16 }}
          checked={this.state.isPrivate}
          onChange={isPrivate => this.setState({ isPrivate })}
        />
        <StyledLabel style={{ marginLeft: 8 }}>Private report</StyledLabel>
        <Tooltip title={"Report will be visible only to you"}>
          <InfoIcon />
        </Tooltip>
      </Flex>
    );
  };
  public renderReportTypeSelection = () => {
    const options = [
      {
        value: `${REPORT_TYPE.ComponentUsage}`,
        label: "Component usage report"
      },
      {
        value: `${REPORT_TYPE.SessionUsage}`,
        label: "Session usage report"
      },
      {
        value: `${REPORT_TYPE.ConcurrentSession}`,
        label: "Concurrent session report"
      },
      {
        value: `${REPORT_TYPE.MaximumUsageReport}`,
        label: "Maximum usage report"
      }
    ];
    const res = _.get(this.props.result, "report.reportType", "");
    const handleSelect = (reportType: ReportType) => {
      const columns = [...ColumnsConfig[reportType].columns];
      // eslint-disable-next-line eqeqeq
      if (reportType == REPORT_TYPE.MaximumUsageReport) {
        ColumnsConfig[REPORT_TYPE.MaximumUsageReport]?.additionalColumns[
          this.state.isRawData
            ? MaximumUsageReportDataType.RawData
            : MaximumUsageReportDataType.Consolidated
        ].forEach(column => {
          columns.push(column);
        });
      }
      this.setState({
        reportType,
        columns,
        components:
          // eslint-disable-next-line eqeqeq
          this.state.reportType != REPORT_TYPE.ComponentUsage
            ? []
            : [...this.state.components]
      });
    };
    return (
      <Flex flexDirection={"column"} flex={1} style={{ margin: 12 }}>
        <ElmSelectWithLabel
          label="Report Type"
          required={true}
          placeholder="Chose an option"
          value={
            options.find(opt => opt.value === `${this.state.reportType}`)?.label
          }
          defaultValue={options.find(opt => opt.value === `${res}`)?.label}
          onSelect={handleSelect}
        >
          {options?.map(node => {
            return (
              <ElmSelectOption
                key={node.value}
                value={node.value}
                label={node.label}
              >
                {node.label}
              </ElmSelectOption>
            );
          })}
        </ElmSelectWithLabel>
      </Flex>
    );
  };
  public renderReportForm = () => {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={this.props.result?.report ? "Edit Report" : "Add Report"}
        bladeType="AddReport"
        bladeTypeName={"REPORTS"}
        closeBlade={this.props.closeBlade}
        accentColor={"filterBlue"}
        topAccentColor={"filterBlue"}
        leftAccentColor={"filterBlue"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        loading={this.props.routeData?.id ? !this.props.result : false}
      >
        <Flex style={{ width: "100%", overflowY: "auto" }}>
          <FieldColumn>
            {this.renderReportTypeSelection()}
            {this.renderReportNameField()}
            {this.renderAggregateUsageFilter()}
            {this.renderRawDataFilter()}
            {this.renderColumnSelect()}
            {this.renderProductSelect()}
            {this.renderCompaniesSelect()}
            {this.renderComponentSelect()}
            {this.renderTimeRangeFilter()}
            {/* {this.renderIncludeChartsFilter()} */}
            {this.renderPrivateReportFilter()}
            <ElmButton
              label={this.props.result?.report ? "Update" : "Create"}
              variance="primary"
              colorVariance="primary"
              disabled={
                !this.state.reportType ||
                !this.state.reportName.length ||
                !this.state.product.length
              }
              style={{ maxWidth: "70px", height: "32px", margin: 12 }}
              onClick={
                this.props.result?.report ? this.editReport : this.createReport
              }
            />
          </FieldColumn>
          <FieldColumn>{this.renderAdvancedOptions()}</FieldColumn>
        </Flex>
      </BladeTemplate>
    );
  };

  public renderLeftSideHeader = () => (
    <Flex style={{ alignItems: "center" }} flexDirection={"column"}>
      <BladeTitle style={{ marginLeft: "18px" }}>
        {this.props.routeData?.id ? "Edit report" : "Create report"}
      </BladeTitle>
    </Flex>
  );

  public render() {
    return this.renderReportForm();
  }
}

const relayEnvironment = getEnvironment();
const RenderQuery = (props: IAddReportBladeProps) => {
  const theme = useTheme();
  const renderReportBlade = (payload: {
    error: Error;
    props: AddReportQueryResponse;
    retry: () => void;
  }) => {
    return (
      <AddReportBlade
        {...props}
        result={payload.props}
        retry={payload.retry}
        theme={theme}
      />
    );
  };
  return (
    <QueryRenderer<AddReportQuery>
      environment={relayEnvironment}
      variables={{ id: props.routeData?.id, skip: !props.routeData?.id }}
      query={graphqlQuery}
      render={renderReportBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
