import { graphql } from "babel-plugin-relay/macro";
import { tableGenerator } from "../../../utils/tableGenerator";
import { productReleasesTable_releases } from "./__generated__/productReleasesTable_releases.graphql";
import _ from "lodash";

export type productReleasesEdges = productReleasesTable_releases["releaseConfiguration"]["releases"]["edges"];
export type productReleaseTableType = productReleasesEdges[number]["node"];

export type IProductReleasesTableProps = {
  releases: productReleasesTable_releases;
};

export const ProductReleasesTable = tableGenerator<IProductReleasesTableProps>({
  columns: [
    {
      Header: "Tag",
      accessor: "tag",
      disableSortBy: true
    },
    {
      Header: "Created at",
      accessor: "createdAt",
      sortKey: "releases.createdAt",
      sortDirection: "DESC",
      isDateFormat: true,
      width: 0.35
    }
  ],

  tableName: "productReleasesTable",
  connectionQuery: graphql`
    query productReleasesTablePaginationQuery(
      $count: Int!
      $cursor: String
      $search: String
      $id: ID!
      $sortColumn: [String!]
      $sortDirection: [String!]
    ) {
      ...productReleasesTable_releases
        @arguments(
          count: $count
          cursor: $cursor
          search: $search
          id: $id
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        )
    }
  `,
  dataKey: "releases.releaseConfiguration.releases",
  fragmentSpec: {
    releases: graphql`
      fragment productReleasesTable_releases on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: null }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
          id: { type: "ID!" }
          sortColumn: {
            type: "[String!]"
            defaultValue: ["releases.createdAt"]
          }
          sortDirection: { type: "[String!]", defaultValue: ["DESC"] }
        ) {
        releaseConfiguration(id: $id) {
          releases(
            first: $count
            after: $cursor
            search: $search
            sortColumn: $sortColumn
            sortDirection: $sortDirection
          ) @connection(key: "productReleasesTable_releases") {
            edges {
              node {
                id
                tag
                releaseChannelsCount
                createdAt
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `
  }
});
export default ProductReleasesTable;
