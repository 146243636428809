import { graphql } from "babel-plugin-relay/macro";
import { tableGenerator } from "../../../utils/tableGenerator";
import { productReleaseChannelsTable_releaseChannels } from "./__generated__/productReleaseChannelsTable_releaseChannels.graphql";
import _ from "lodash";
import { TableCellProps } from "react-virtualized";
import { getIcon } from "../../../components";
import React from "react";
import { Tooltip } from "antd";
import { Flex } from "reflexbox";
import styled from "styled-components";
import { getTextWidth } from "../../../utils";

export type productReleaseChannelsEdges = productReleaseChannelsTable_releaseChannels["releaseConfiguration"]["releaseChannels"]["edges"];
export type productReleaseConfigTableType = productReleaseChannelsEdges[number]["node"];

export type IProductReleaseChannelsTableProps = {
  releaseChannels: productReleaseChannelsTable_releaseChannels;
};

const renderNameAndIcon = (payload: TableCellProps) => {
  const RotateIcon = styled(getIcon("rotate-left", "fas"))`
    color: ${props => props.theme.colors.lightBlue};
    font-size: ${props => props.theme.fontSizes.xxSmall};
    margin-left: 4px;
  `;
  const isFallback =
    payload.rowData.name ===
    payload.rowData.releaseConfiguration?.fallback?.name
      ? true
      : false;
  let showTooltipOnText = getTextWidth(payload.cellData) > 120 ? true : false;
  return (
    <Flex alignItems="center">
      <p
        style={{
          maxWidth: "120px",
          flex: "none",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          margin: "0px"
        }}
        title={payload.rowData.name}
      >
        {showTooltipOnText ? (
          <Tooltip placement="top" title={payload.rowData.name}>
            {" "}
            {payload.rowData.name}{" "}
          </Tooltip>
        ) : (
          payload.rowData.name
        )}
      </p>
      {isFallback && (
        <Tooltip
          placement="right"
          title={`Fallback set to ${payload.rowData.name}`}
        >
          <RotateIcon />
        </Tooltip>
      )}
    </Flex>
  );
};

export const ProductReleaseChannelsTable = tableGenerator<
  IProductReleaseChannelsTableProps
>({
  columns: [
    {
      Header: "Channel",
      accessor: "name",
      sortKey: "release_channels.name",
      cellRenderer: payload => renderNameAndIcon(payload)
    },
    {
      Header: "Current Release",
      accessor: "currentRelease.tag",
      disableSortBy: true
    },
    {
      Header: "Licenses",
      accessor: "licensesCount",
      disableSortBy: true
    },
    {
      Header: "Actions",
      accessor: "currentRelease.data",
      cellDataGetter: payload => {
        return payload.rowData;
      },
      cellRenderer: payload => {
        return "";
      },
      width: 0.15,
      disableSortBy: true
    }
  ],
  tableName: "productReleaseChannelsTable",
  connectionQuery: graphql`
    query productReleaseChannelsTablePaginationQuery(
      $count: Int!
      $cursor: String
      $search: String
      $id: ID!
      $sortColumn: [String!]
      $sortDirection: [String!]
    ) {
      ...productReleaseChannelsTable_releaseChannels
        @arguments(
          count: $count
          cursor: $cursor
          search: $search
          id: $id
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        )
    }
  `,
  dataKey: "releaseChannels.releaseConfiguration.releaseChannels",
  fragmentSpec: {
    releaseChannels: graphql`
      fragment productReleaseChannelsTable_releaseChannels on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 6 }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
          sortColumn: {
            type: "[String!]"
            defaultValue: ["release_channels.name"]
          }
          sortDirection: { type: "[String!]", defaultValue: ["ASC"] }
          id: { type: "ID!" }
        ) {
        releaseConfiguration(id: $id) {
          releaseChannels(
            first: $count
            after: $cursor
            search: $search
            sortColumn: $sortColumn
            sortDirection: $sortDirection
          ) @connection(key: "productReleaseChannelsTable_releaseChannels") {
            edges {
              node {
                id
                name
                currentRelease {
                  tag
                }
                licensesCount
                releaseConfiguration {
                  fallback {
                    id
                    name
                  }
                }
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `
  }
});
export default ProductReleaseChannelsTable;
