/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicensesQueryVariables = {
  activeLicenseFilterValues?: Array<Array<string>> | null | undefined;
  expiredFilterValues?: Array<Array<string>> | null | undefined;
  expiringSoonFilterValues?: Array<Array<string>> | null | undefined;
  skipTotalSeats: boolean;
};
export type LicensesQueryResponse = {
  readonly allLicensesCount: number;
  readonly activeLicensesCount: number;
  readonly expiredLicensesCount: number;
  readonly expiringSoonLicensesCount: number;
  readonly allLicensesSeats?:
    | {
        readonly nodes: ReadonlyArray<{
          readonly totalSeatCount: number | null;
        } | null> | null;
      }
    | null
    | undefined;
};
export type LicensesQuery = {
  readonly response: LicensesQueryResponse;
  readonly variables: LicensesQueryVariables;
};

/*
query LicensesQuery(
  $activeLicenseFilterValues: [[String!]!]
  $expiredFilterValues: [[String!]!]
  $expiringSoonFilterValues: [[String!]!]
  $skipTotalSeats: Boolean!
) {
  allLicensesCount: licensesCount
  activeLicensesCount: licensesCount(filterColumn: ["latestTerm.endDate", "latestTerm.startDate"], filterValues: $activeLicenseFilterValues, filterOp: [[">"], ["<", "="]])
  expiredLicensesCount: licensesCount(filterColumn: ["latestTerm.endDate"], filterOp: [["<"]], filterValues: $expiredFilterValues)
  expiringSoonLicensesCount: licensesCount(filterColumn: ["latestTerm.endDate", "latestTerm.endDate"], filterOp: [[">"], ["<"]], filterValues: $expiringSoonFilterValues)
  allLicensesSeats: licenses @skip(if: $skipTotalSeats) {
    nodes {
      totalSeatCount
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "activeLicenseFilterValues"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "expiredFilterValues"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "expiringSoonFilterValues"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "skipTotalSeats"
      } as any
    ],
    v1 = {
      alias: "allLicensesCount",
      args: null,
      kind: "ScalarField",
      name: "licensesCount",
      storageKey: null
    } as any,
    v2 = [">"],
    v3 = {
      alias: "activeLicensesCount",
      args: [
        {
          kind: "Literal",
          name: "filterColumn",
          value: ["latestTerm.endDate", "latestTerm.startDate"]
        },
        {
          kind: "Literal",
          name: "filterOp",
          value: [v2 /*: any*/, ["<", "="]]
        },
        {
          kind: "Variable",
          name: "filterValues",
          variableName: "activeLicenseFilterValues"
        }
      ],
      kind: "ScalarField",
      name: "licensesCount",
      storageKey: null
    } as any,
    v4 = ["<"],
    v5 = {
      alias: "expiredLicensesCount",
      args: [
        {
          kind: "Literal",
          name: "filterColumn",
          value: ["latestTerm.endDate"]
        },
        {
          kind: "Literal",
          name: "filterOp",
          value: [v4 /*: any*/]
        },
        {
          kind: "Variable",
          name: "filterValues",
          variableName: "expiredFilterValues"
        }
      ],
      kind: "ScalarField",
      name: "licensesCount",
      storageKey: null
    } as any,
    v6 = {
      alias: "expiringSoonLicensesCount",
      args: [
        {
          kind: "Literal",
          name: "filterColumn",
          value: ["latestTerm.endDate", "latestTerm.endDate"]
        },
        {
          kind: "Literal",
          name: "filterOp",
          value: [v2 /*: any*/, v4 /*: any*/]
        },
        {
          kind: "Variable",
          name: "filterValues",
          variableName: "expiringSoonFilterValues"
        }
      ],
      kind: "ScalarField",
      name: "licensesCount",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "totalSeatCount",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "LicensesQuery",
      selections: [
        v1 /*: any*/,
        v3 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        {
          condition: "skipTotalSeats",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: "allLicensesSeats",
              args: null,
              concreteType: "LicenseConnection",
              kind: "LinkedField",
              name: "licenses",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "License",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [v7 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ]
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "LicensesQuery",
      selections: [
        v1 /*: any*/,
        v3 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        {
          condition: "skipTotalSeats",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: "allLicensesSeats",
              args: null,
              concreteType: "LicenseConnection",
              kind: "LinkedField",
              name: "licenses",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "License",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [
                    v7 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ]
        }
      ]
    },
    params: {
      cacheID: "4deee9a42b482e81619ca1749dc4486f",
      id: null,
      metadata: {},
      name: "LicensesQuery",
      operationKind: "query",
      text:
        'query LicensesQuery(\n  $activeLicenseFilterValues: [[String!]!]\n  $expiredFilterValues: [[String!]!]\n  $expiringSoonFilterValues: [[String!]!]\n  $skipTotalSeats: Boolean!\n) {\n  allLicensesCount: licensesCount\n  activeLicensesCount: licensesCount(filterColumn: ["latestTerm.endDate", "latestTerm.startDate"], filterValues: $activeLicenseFilterValues, filterOp: [[">"], ["<", "="]])\n  expiredLicensesCount: licensesCount(filterColumn: ["latestTerm.endDate"], filterOp: [["<"]], filterValues: $expiredFilterValues)\n  expiringSoonLicensesCount: licensesCount(filterColumn: ["latestTerm.endDate", "latestTerm.endDate"], filterOp: [[">"], ["<"]], filterValues: $expiringSoonFilterValues)\n  allLicensesSeats: licenses @skip(if: $skipTotalSeats) {\n    nodes {\n      totalSeatCount\n      id\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "6d445b66a6733311d9a9e07a4dca0af4";
export default node;
