import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import {
  productVersionsTableQuery,
  productVersionsTableQueryResponse
} from "./__generated__/productVersionsTableQuery.graphql";
import ElmTable, { IColumn } from "components/elmTable";
import styled, { useTheme } from "styled-components";
import { getIcon } from "components";
import { Tooltip } from "antd";
import { searchInObjectArray } from "utils";
import { EmptyTableAction } from "Products/blades/Product";
import _ from "lodash";
import { shallowEqual } from "fast-equals";
import { EntityRefreshMap } from "components/bladeManager/entityRefreshMap";

enum ProductVersionsTableColumns {
  Version = "Version",
  Actions = "Actions"
}
export const ProductVersionsTableColumnConfig: Record<
  ProductVersionsTableColumns,
  IColumn
> = {
  Version: {
    Header: "Version",
    accessor: "versionString",
    disableSortBy: true,
    sortKey: null
  },
  Actions: {
    Header: "Actions",
    accessor: "",
    cellRenderer: () => "",
    disableSortBy: true,
    sortKey: null,
    width: 0.12
  }
};

const graphqlQuery = graphql`
  query productVersionsTableQuery($productId: ID!) {
    product(id: $productId) {
      productVersions {
        nodes {
          id
          versionString
        }
      }
    }
  }
`;

interface IProductVersionsTableProps {
  productId: string;
  setRefreshFn: (payload: () => void) => void;
  refreshKey: EntityRefreshMap;
  removeRefreshFn: (entity: EntityRefreshMap) => void;
  addNewProductVersion: () => void;
  editProductVersion: (id: string, version_string: string) => void;
  deleteProductVersion: (id: string, version_string: string) => void;
}

interface state {
  search_term?: string;
}

class ProductVersionsTable extends React.Component<
  IProductVersionsTableProps & {
    result: productVersionsTableQueryResponse;
    retry: () => void;
    theme: any;
  },
  state
> {
  state: Readonly<state> = {
    search_term: ""
  };
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    this.props.removeRefreshFn(this.props.refreshKey);
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render = () => {
    const EditIcon = styled(getIcon("pen", "far"))`
      width: 16px;
      height: 16px;
      color: ${this.props.theme.colors.warmGrey};
      margin-right: 8px;
    `;
    const DeleteIcon = styled(getIcon("trash-can", "far"))`
      width: 16px;
      height: 16px;
      color: ${this.props.theme.colors.warmGrey};
      margin-right: 8px;
    `;
    const nodes = _.get(this.props.result, "product.productVersions.nodes", []);

    const filteredData = this.state.search_term
      ? searchInObjectArray(this.state.search_term, nodes)
      : nodes;

    return (
      <ElmTable
        data={filteredData}
        onCriteriaChange={({ search_term }) => this.setState({ search_term })}
        columns={[
          ...Object.keys(ProductVersionsTableColumnConfig).map(
            key =>
              ProductVersionsTableColumnConfig[
                key as ProductVersionsTableColumns
              ]
          )
        ]}
        useDefaultAddButton={this.props.addNewProductVersion}
        renderRowActionButtons={data => (
          <Flex style={{ gap: 8 }}>
            <Tooltip placement="top" title={"Edit"}>
              <EditIcon
                onClick={() => {
                  this.props.editProductVersion(data?.id, data?.versionString);
                }}
              />
            </Tooltip>
            <Tooltip placement="top" title={"Delete"}>
              <DeleteIcon
                onClick={() => {
                  this.props.deleteProductVersion(
                    data?.id,
                    data?.versionString
                  );
                }}
              />
            </Tooltip>
          </Flex>
        )}
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message={"Product has no versions."}
            addButtonCallback={this.props.addNewProductVersion}
            actionMessage="Add new version"
          />
        )}
      />
    );
  };
}

const RenderQuery = (props: IProductVersionsTableProps) => {
  const theme = useTheme();
  const renderTable = (payload: {
    error: Error;
    props: productVersionsTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <ProductVersionsTable
        {...props}
        result={payload.props}
        retry={payload.retry}
        theme={theme}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<productVersionsTableQuery>
      environment={relayEnvironment}
      variables={{
        productId: props.productId
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default RenderQuery;
