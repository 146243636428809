import { Collapse } from "antd";
import styled from "styled-components";

export const StyledCollapse = styled(Collapse)`
  background-color: transparent;
  margin-bottom: 6px;
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
  }
  .ant-collapse-item {
    border: none;
    color: ${props => props.theme.colors.textPrimary};
  }
  .header-text {
    min-width: 291px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
  }
`;
export const FeatureTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  margin-left: 16px;
`;
export const FeatureLabel = styled.span`
  color: ${props => props.theme.colors.inputLabels};
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 16.5px;
`;
