import _ from "lodash";
import moment from "moment";
import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import {
  IGeneratedTableProps,
  tableGenerator
} from "../../../utils/tableGenerator";
import { licenseHistoryTable_terms } from "./__generated__/licenseHistoryTable_terms.graphql";
import { dollarString } from "../../../utils";
import { TableCellProps } from "react-virtualized";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import { Flex } from "reflexbox";
import {
  licenseHistoryTableQuery,
  licenseHistoryTableQueryResponse
} from "./__generated__/licenseHistoryTableQuery.graphql";

export type licenseHistoryTableTermEdges = licenseHistoryTable_terms["license"]["terms"]["edges"];
export type licenseTableType = licenseHistoryTableTermEdges[number]["node"];
//export type licenseLatestTerm = licenseHistoryTable_terms["license"]["latestTerm"];

export type ILicenseHistoryTableProps = {
  terms: licenseHistoryTable_terms;
};

export const renderCurrency = (payload: TableCellProps) => {
  const value = _.get(payload, "cellData", 0);
  //if(Number.isInteger(+value)) return
  return dollarString.format(+value);
};

const GeneratedTable = tableGenerator<ILicenseHistoryTableProps>({
  columns: [
    // {
    //   Header: "Renew",
    //   accessor: "",
    //   width: 0.2
    // },
    {
      Header: "Activation",
      accessor: "startDate",
      sortKey: "term.startDate",
      isDateFormat: true
    },
    {
      Header: "Expiration",
      accessor: "endDate",
      sortKey: "term.endDate",
      isDateFormat: true
    },
    {
      Header: "Value",
      accessor: "value",
      disableSortBy: true,
      width: 0.1,
      cellRenderer: payload => renderCurrency(payload) //dollarString.format(+value)
    },
    {
      Header: "Actions",
      accessor: "",
      disableSortBy: true,
      cellRenderer: () => "",
      width: 0.1
    }
    // {
    //   Header: "Duration",
    //   accessor: "",
    //   sortKey: "endDate",
    //   cellRenderer: cellProps => {
    //     // const { isExpired } = getTermDetails(
    //     //   _.get(cellProps.rowData, "endDate")
    //     // );
    //     const getAbsoluteDuration = (d: number) => Math.round(Math.abs(d));
    //     const endDate = _.get(cellProps.rowData, "endDate");
    //     const startDate = _.get(cellProps.rowData, "startDate");
    //     const termDiff = moment(endDate).diff(moment(startDate));
    //     const year = getAbsoluteDuration(moment.duration(termDiff).as("years"));
    //     const day = getAbsoluteDuration(moment.duration(termDiff).as("days"));
    //     const month = getAbsoluteDuration(
    //       moment.duration(termDiff).as("month")
    //     );
    //     const isActive = _.get(cellProps.rowData, "isActive");
    //     const getLabel = () => {
    //       if (isActive) {
    //         return "Active";
    //       }
    //       if (year > 0) {
    //         return `${year} Year${year > 1 ? "s" : ""}`;
    //       }
    //       if (month > 0) {
    //         return `${month} Month${month > 1 ? "s" : ""}`;
    //       }
    //       if (day > 0) {
    //         return `${day} Day${day > 1 ? "s" : ""}`;
    //       }
    //       return "";
    //     };
    //     return (
    //       <TableCellPTag style={{ color: isActive ? "#4DBF40" : "normal" }}>
    //         {getLabel()}
    //       </TableCellPTag>
    //     );
    //   }
    // },
  ],
  connectionQuery: graphql`
    query licenseHistoryTablePaginationQuery(
      $id: ID!
      $count: Int!
      $cursor: String #$search: String
    ) {
      ...licenseHistoryTable_terms
        @arguments(id: $id, count: $count, cursor: $cursor)
    }
  `,
  dataKey: "terms.license.terms",
  fragmentSpec: {
    terms: graphql`
      fragment licenseHistoryTable_terms on Query
        @argumentDefinitions(
          id: { type: "ID!" }
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          #search: { type: "String", defaultValue: null }
        ) {
        license(id: $id) {
          terms(first: $count, after: $cursor)
            @connection(key: "LicenseHistoryTable_terms") {
            edges {
              node {
                startDate
                endDate
                createdAt
                isActive
                value
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query licenseHistoryTableQuery($id: ID!) {
    ...licenseHistoryTable_terms @arguments(id: $id)
  }
`;

class LicenseTermsTable extends React.Component<
  IGeneratedTableProps & {
    result: licenseHistoryTableQueryResponse;
    retry: () => void;
  }
> {
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        terms={res}
      />
    );
  }
}

export const LicenseHistoryTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: licenseHistoryTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <LicenseTermsTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<licenseHistoryTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};
