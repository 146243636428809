import moment from "moment";
import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox";
import relayEnvironment from "../../api/relay";
import { appConnect } from "../../store/appConnect";
import {
  userAnalyticsQuery,
  userAnalyticsQueryResponse
} from "./__generated__/userAnalyticsQuery.graphql";
import _ from "lodash";
import { getAnimatedComponent } from "../../components/helpers";
import { TabbedAnalyticsComponent } from "../../components/analyticsComponent/tabbedAnalytics";
import {
  getFriendlyLicenseName,
  getSavedRoleTypeFromLocalSync
} from "../../utils";
import { WidgetContainer } from "../../components/analyticsComponent/widgetContainer";
import { TopCountFilter } from "../../components/analyticsComponent/filters/topCount";
import {
  AnalyticsComponentProps,
  queryType
} from "../../components/analyticsComponent/";
import { sharedTabStyle } from "./shared";
import { DateRange } from "blades/Dashboard/utils";

const AnimatedContainer = getAnimatedComponent({
  element: "div",
  transitionName: "license-banner",
  animations: {
    appear: {
      duration: "1.5s",
      animationType: "slideInRight"
    },
    enter: {
      duration: "1s",
      animationType: "slideInRight"
    }
  }
});

export interface IUserAnalyticsProps {
  result: userAnalyticsQueryResponse;
  retry: () => void;
  timespan: "week" | "month" | "day";
  userId: string;
  variables: {
    allTimeFilterStart?: string;
    timeRangeOverride: DateRange;
    currentTimeSpanStart: string;
    currentTimeSpanEnd: string;
    previousTimeSpanStart: string;
    previousTimeSpanEnd: string;
    productId: string | null;
  };
}

export class UserAnalytics extends React.Component<IUserAnalyticsProps> {
  public isVendor = () =>
    getSavedRoleTypeFromLocalSync() === "vendor" ||
    _.get(this.props, "appState.activeRole.type") === "vendor";

  public renderLicenseInfoBanner = () => {
    if (
      !this.props.result ||
      !this.props.result.UserAnalyticsCurrentExpiringLicenses
    ) {
      return null;
    }
    const getActiveLicensesByType = () => {
      if (
        !this.props.result ||
        !this.props.result.UserAnalyticsCurrentExpiringLicenses
      ) {
        return null;
      }
      const types = _.groupBy(
        this.props.result.UserAnalyticsCurrentExpiringLicenses.nodes,
        node => node.type.name
      );
      const typeNames = _.keys(types);
      return _.map(typeNames, typeName => {
        return (
          <div>
            <span className="expiring-value">{types[typeName].length}</span>
            <span className="expiring-label">
              {_.startCase(getFriendlyLicenseName(typeName))}
            </span>
          </div>
        );
      });
    };
    const navigateToLicenses = () => {
      // this.props.openBlade({
      //   route: 'Licenses',
      //   routeName: 'Licenses',
      //   routeData: null,
      //   fromBladeIndex: this.props.index,
      //   navigationType: 'reset',
      // });
    };
    return (
      <AnimatedContainer>
        <div className="banner" onClick={navigateToLicenses}>
          <div className="accent" />
          <div className="message-container">
            <span className="current-expiring-value">
              {this.props.result.UserAnalyticsCurrentExpiringLicenses}
            </span>
            <span className="current-expiring-label">{`License${
              this.props.result.UserAnalyticsCurrentExpiringLicenses.nodes
                .length > 1
                ? "s"
                : ""
            } expiring this ${this.props.timespan}`}</span>
            <div className="extra-expiring-container">
              {getActiveLicensesByType()}
            </div>
          </div>
        </div>
      </AnimatedContainer>
    );
  };
  public renderTabHeader = (header: string) => {
    return <span className={"session-tab-header"}>{header}</span>;
  };
  public getHighlightAnalytics = () => {
    return (
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session count",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              analytics: this.getSessionCountAnalyticsSetup()
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              analytics: this.getSessionDurationAnalyticsSetup(),
              type: "bar"
            }
          }
        ]}
      />
    );
  };
  public getSessionDurationAnalyticsSetup = () => {
    return {
      bladeScope: this.props.userId,
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_duration",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd,
      formatValue: (value: any) => {
        return moment.duration(value, "seconds").asHours();
      }
    } as queryType;
  };
  public getSessionCountAnalyticsSetup = () => {
    return {
      bladeScope: this.props.userId,
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_count",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd
    } as queryType;
  };
  public getTabAnalytics = (payload: {
    category: string;
    firstTabType?: AnalyticsComponentProps["type"];
    secondTabType?: AnalyticsComponentProps["type"];
  }) => {
    return (
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session count",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              analytics: {
                bladeScope: this.props.userId,
                setup: {
                  category: payload.category,
                  value: "sessions.usage_count",
                  group: null
                },
                startTime: this.props.variables.currentTimeSpanStart,
                endTime: this.props.variables.currentTimeSpanEnd
              },
              type: payload.firstTabType
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: {
                //boxSizing: "border-box",
                ...sharedTabStyle
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              analytics: {
                bladeScope: this.props.userId,
                setup: {
                  category: payload.category,
                  value: "sessions.usage_duration",
                  group: null
                },
                startTime: this.props.variables.currentTimeSpanStart,
                endTime: this.props.variables.currentTimeSpanEnd
              },
              type: payload.secondTabType
            }
          }
        ]}
      />
    );
  };
  public render() {
    return (
      <div className="user-analytics analytics-container">
        <WidgetContainer size="x-large">
          {this.getHighlightAnalytics()}
        </WidgetContainer>
        <WidgetContainer size="large">
          <Flex>
            <span className="widget-title">Top Components</span>
            {/* <TopCountFilter /> */}
          </Flex>
          {this.getTabAnalytics({
            category: "components.name",
            firstTabType: "bar"
          })}
        </WidgetContainer>
      </div>
    );
  }
}

const RenderQuery = (props: IUserAnalyticsProps) => {
  return <UserAnalytics {...props} variables={props.variables} />;
};
export default appConnect(RenderQuery, {
  selectors: {}
});
