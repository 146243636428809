import React, { useLayoutEffect, useRef } from "react";
import { IChart } from "./types";
import { customChartTheme, exportMenuItems, getChartData } from "./helpers";
import _ from "lodash";
import FiltersContainer from "./filtersContainer";
import uuid from "uuid";
import { useChartData } from "./hooks";
import { useTheme } from "styled-components";
import moment from "moment";
import NoDataPlaceholder from "./noDataPlaceholder";
import { getIcon } from "components";

function LineChartComponent(props: IChart) {
  const theme = useTheme();
  const hasData = useRef();
  let instanceId = uuid();

  const { data } = useChartData({
    ...props.queryParams,
    useLocalData: props.data ? true : false
  });
  useLayoutEffect(() => {
    let root = am5.Root.new(instanceId);
    const isDuration = props?.queryParams?.setup?.value?.includes("duration");
    isDuration && root.numberFormatter.set("durationFormat", "mm:ss");

    isDuration &&
      root.durationFormatter.setAll({
        baseUnit: "second",
        durationFormat: "m' minutes'",
        durationFields: ["valueY"]
      });

    var myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll(customChartTheme);

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        minHeight: 110,
        maxHeight: props.options?.chartMaxHeight || 170,
        panX: false,
        panY: false,
        layout: root.verticalLayout
      })
    );

    chart
      .get("colors")
      .set("colors", [
        am5.color("#0F649A"),
        am5.color("#FFA033"),
        am5.color("#68B6E8"),
        am5.color("#E3D626"),
        am5.color("#70D165"),
        am5.color("#EF8879"),
        am5.color("#F4F6F6")
      ]);

    //root.interfaceColors.set("fill", am5.color("#EF8879"));
    if (root._logo) {
      root._logo._privateSettings.visible = false;
    }

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        //extraMin & extraMax are relative values indicating how much of the automatically-calculated value range to add to either min or max
        // 0.1 will mean 10% of the current value range.
        //extraMin: 0.05,
        extraMax: 0.05,
        baseInterval: {
          timeUnit: "day",
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    var yRenderer = am5xy.AxisRendererY.new(root, {
      opposite: false
      //fill: am5.color("#FFA033"),
    });

    var yAxis = chart.yAxes.push(
      isDuration
        ? am5xy.DurationAxis.new(root, {
            renderer: yRenderer
          })
        : am5xy.ValueAxis.new(root, {
            maxDeviation: 1,
            renderer: yRenderer
          })
    );

    xAxis.get("renderer").labels.template.setAll({
      fill: theme.chartColors.labels
    });
    yAxis.get("renderer").labels.template.setAll({
      fill: theme.chartColors.labels
    });

    // yAxis.setAll({
    //   background: am5.Rectangle.new(root, {
    //     fill: am5.color("#0F649A"),
    //     //fillOpacity: 0.7

    //   })
    // })

    // Create modal for a "no data" note
    let modal = am5.Modal.new(root, {
      content: "The chart has no data"
    });

    // Add legend
    // let legend = chart.children.push(
    //   am5.Legend.new(root, {
    //     centerX: am5.percent(50),
    //     x: am5.percent(50),
    //     layout: root.gridLayout,
    //     //maxWidth: 150,
    //     //height: 25,
    //     //overflowY: "scroll",
    //     //height: am5.percent(20),
    //     // verticalScrollbar: am5.Scrollbar.new(root, {
    //     //   orientation: "vertical"
    //     // }),
    //     useDefaultMarker: true
    //   })
    // );

    // legend.markers.template.setAll({
    //   width: 16,
    //   height: 16
    // });

    const generatedData = getChartData(props.data ? props.data : data) as [];
    function createAxisAndSeries(groupName?: string) {
      if (chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set("syncWithAxis", chart?.yAxes.getIndex(0));
      }

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: groupName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: groupName,
          valueXField: "category",
          stacked: !!props.options?.stacked,
          //legendLabelText: "{name} : {valueX}",
          legendRangeLabelText: "{name}",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{name}: {valueY}"
          })
        })
      );
      if (!!props.options?.stacked) {
        series.fills.template.setAll({
          fillOpacity: 0.5,
          visible: true
        });
      }
      //series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
      series.strokes.template.setAll({
        strokeWidth: 2,
        strokeDasharray: props.strokeDasharray || []
      });

      series.events.on("datavalidated", ev => {
        var series = ev.target;
        if (ev.target.data.length < 1) {
          //modal.open();
        } else {
          modal.close();
        }
      });

      yRenderer.grid.template.set("strokeOpacity", 0.05);
      yRenderer.setAll({
        stroke: theme.chartColors.axes, //series.get("fill"),
        strokeOpacity: 1,
        opacity: 1
      });

      xAxis.get("renderer").setAll({
        stroke: theme.chartColors.axes,
        strokeOpacity: 1,
        opacity: 1
      });

      // Set up data processor to parse string dates
      // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["category"]
      });

      //series.data.setAll(dataObj);
      series.data.setAll(generatedData);
      //legend.data.push(series);
    }

    //legend.data.setAll(chart.series.values);

    // Add cursor

    const rawData = props.data ? props.data : data;
    rawData?.analytics?.groups.map(item => createAxisAndSeries(item));
    // createAxisAndSeries();
    chart.set("cursor", am5xy.XYCursor.new(root, { snapToSeriesBy: "x" }));
    // Make stuff animate on load
    chart.appear(1000, 100);
    const exportMenuIcon = getIcon("times", "fal");

    let exporting;
    const exportData = generatedData.map(item => {
      let date = moment(item?.category).format("MMM YY");

      return {
        "Month Year": date,
        ..._.omit(item, "category")
      };
    });
    if (
      !props.exportOptions?.showCustomExportButton &&
      !props.exportOptions?.disableExport
    ) {
      exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          //container: document.getElementById("exportdiv")
          useDefaultCSS: false
        }),
        dataSource: generatedData
      });
      exporting.events.on("dataprocessed", function(ev) {
        ev.data = exportData;
      });
      exporting.get("menu").set("items", exportMenuItems);
    }

    if (
      props.exportOptions?.showCustomExportButton &&
      !props.exportOptions?.disableExport &&
      _.isFunction(props?.setChartData)
    ) {
      props.setChartData({
        data: exportData,
        root
      });
    }
    return () => {
      root.dispose();
    };
  }, [data, props.data, theme]);

  hasData.current = _.get(data, "analytics.values", []).length;

  return (
    <>
      <div
        id={instanceId}
        style={{
          width: "100%",
          minHeight: "inherit",
          height: props.options?.chartMaxHeight || 170
        }}
        hidden={hasData.current ? false : true}
      ></div>
      {!hasData.current && <NoDataPlaceholder />}
    </>
  );
}

export default function LineChart(props: IChart) {
  return (
    <FiltersContainer containerProps={props} Component={LineChartComponent} />
  );
}
