import React from "react";
import * as _ from "lodash";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";

const AttributeContainer = styled(Flex)<{ borderColor?: string }>`
  border: 1px solid
    ${props => props.borderColor || props.theme.colors.textPrimary};
  padding: 10px;
  flex: 1;
  position: relative;
  margin-top: 12px;
`;
export const AttributeLabel = styled.p<{ labelColor?: string }>`
  ${props => props.theme.fontType.paragraph}
  color: ${props => props.labelColor || props.theme.colors.textPrimary};
`;
const Value = styled.p`
  ${props => props.theme.fontType.fieldText}
`;
const AttributeTitle = styled.p<{ titleColor?: string }>`
  ${props => props.theme.fontType.attributeTitle}
  color: ${props => props.titleColor || props.theme.colors.textPrimary};
  position: absolute;
  left: 50%;
  width: 100px;
  margin-left: -50px;
  text-align: center;
  top: -10px;
  background-color: white;
  padding-left: 7px;
  padding-right: 7px;
`;
export interface IAttributValuePair {
  label: JSX.Element | string;
  value: JSX.Element | string;
  itemStyle?: React.CSSProperties;
}
export interface IAttributeColumn {
  top?: IAttributValuePair;
  bottom?: IAttributValuePair;
}
export interface IAttributesBoxProps {
  items?: IAttributValuePair[];
  mode?: "edit" | "normal";
  title?: string;
  borderColor?: string;
  titleColor?: string;
  labelColor?: string;
  containerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}
export class AttributesBox extends React.Component<IAttributesBoxProps> {
  public renderValueLabelPair = (
    payload: IAttributValuePair,
    index: number
  ) => {
    if (!_.isObject(payload)) {
      return null;
    }
    return (
      <Flex
        flexDirection={"column"}
        style={_.merge(
          {
            margin: "5px",
            minWidth: "calc(50% - 10px)",
            flexShrink: 2,
            flexGrow: 1
          },
          payload.itemStyle || {}
        )}
      >
        {_.isString(payload.value) ? (
          <Value>{payload.value || "N/A"}</Value>
        ) : (
          payload.value || "N/A"
        )}
        {_.isString(payload.label) ? (
          <AttributeLabel labelColor={this.props.labelColor}>
            {payload.label}
          </AttributeLabel>
        ) : (
          payload.label
        )}
      </Flex>
    );
  };
  public renderItems = () => {
    if (_.isArray(this.props.items)) {
      return (
        <Flex
          flexWrap="wrap"
          flex={1}
          style={_.merge({ height: "max-content" }, this.props.containerStyle)}
        >
          {_.map(this.props.items, (item, index) =>
            this.renderValueLabelPair(item, index)
          )}
        </Flex>
      );
    }

    return null;
  };
  public renderBoxTitle = () => {
    if (_.isString(this.props.title)) {
      return this.props.title;
    }
    return "Attributes";
  };
  public render() {
    return (
      <AttributeContainer
        borderColor={this.props.borderColor}
        justifyContent={"space-between"}
        style={this.props.style}
      >
        <AttributeTitle titleColor={this.props.titleColor}>
          {this.renderBoxTitle()}
        </AttributeTitle>
        {this.renderItems()}
      </AttributeContainer>
    );
  }
}

export default AttributesBox;
