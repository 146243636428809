import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import React from "react";
import { TableCellProps } from "react-virtualized";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  IColumn,
  TableCell,
  TableCellPTag
} from "../../../components/elmTable";
import { getIcon } from "../../../components/icons";
import {
  getTermDetails,
  licenseTypeToName,
  licenseTypeId,
  tableGenerator,
  getTextWidth
} from "../../../utils";
import {
  licensesTableQuery,
  licensesTableQueryResponse
} from "./__generated__/licensesTableQuery.graphql";
import { licensesTable_licenses } from "./__generated__/licensesTable_licenses.graphql";
import moment from "moment";
import {
  VendorIcon,
  BusinessUnitIcon,
  TrialIcon,
  DangerSignIcon,
  WarningSignIcon
} from "../../../assets";
import { Tooltip } from "antd";
import renderProductImages from "../../../components/productImages";
import { renderSuccessNotification } from "utils/ant-notifications";
import { enforcementModel } from "Licenses/blades/AddLicense";
import { DATE_FORMAT, DATE_FORMAT_ALT } from "const";
import { commercialModel as cM } from "Licenses/common";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import { IGeneratedTableProps } from "utils/tableGenerator";
import dayjs from "dayjs";
import { shallowEqual } from "fast-equals";

export type LicenseTableType = licensesTable_licenses["licenses"]["edges"][number]["node"];
type EntitlementNode = LicenseTableType["activeEntitlements"]["nodes"][number];
type InstanceNode = LicenseTableType["instances"]["nodes"][number];

const CopyIcon = styled(getIcon("MdContentCopy"))`
  color: ${props => props.theme.colors.iconColor};
`;
const CopiedIcon = styled(getIcon("FaCheck"))`
  margin-right: 5.5px;
  color: inherit;
`;
const InfoIcon = styled(getIcon("AiFillInfoCircle"))`
  width: 14px;
  height: 14px;
  margin-left: 4px;
  margin-top: 2px;
  font-weight: bold;
  color: ${props => props.theme.colors.greyish};
`;
const renderLicenseType = (cellProps: TableCellProps): any => {
  const commercialModel = _.get(cellProps.rowData, "commercialModel.name");
  const { isExpired } = getTermDetails(
    _.get(cellProps.rowData, "latestTerm.endDate"),
    { commercialModel }
  );
  return (
    <TableCell>
      <Flex flexDirection={"column"}>
        <TableCellPTag style={{ margin: "0px" }}>
          <TableText isExpired={isExpired}>
            {licenseTypeToName(cellProps.cellData)}
          </TableText>
          {_.get(cellProps, "rowData.isTrial", false) && (
            <Tooltip placement="right" title={"Trial"}>
              <img style={{ marginLeft: "4px" }} src={TrialIcon} alt="trial" />
            </Tooltip>
          )}
        </TableCellPTag>
      </Flex>
    </TableCell>
  );
};
const CopyContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  .copyMessage {
    color: #4dbf40;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
  }
  .licenseKey {
    display: block;
  }
  .licenseKeyMessage {
    display: none;
    color: ${props => props.theme.colors.linkButtonLabel};
  }

  :hover {
    color: #3f68f6;
    cursor: pointer;
    .licenseKey {
      display: none;
    }
    .license-copy-icon {
      display: none;
    }
    .licenseKeyMessage {
      display: block;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: ${props => props.theme.colors.linkButtonLabel};
    }
  }
`;

export const TableText = styled.p<{
  maxWidth?: React.CSSProperties["maxWidth"];
  isExpired?: boolean;
}>(props => ({
  maxWidth: props?.maxWidth || "120px",
  flex: "none",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  margin: "0px",
  opacity: props.isExpired ? 0.5 : 1,
  fontSize: props.theme.fontSizes.ySmall
}));
const LicenseKey = (props: { licenseKey: string; fullKey: string }) => {
  const [showCopiedMessage, updateShowCopyMessage] = React.useState(false);
  const openCopiedMessage = () => {
    updateShowCopyMessage(true);
    renderSuccessNotification("License copied to clipboard");
    _.delay(() => {
      updateShowCopyMessage(false);
    }, 5000);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <TableCell>
      <CopyToClipboard text={props.fullKey} onCopy={openCopiedMessage}>
        <CopyContainer onClick={handleClick}>
          {showCopiedMessage ? (
            <div
              className="copyMessage"
              style={{
                display: showCopiedMessage ? "flex" : "none",
                alignItems: "center"
              }}
            >
              <CopiedIcon />
              <span>Copied!</span>
            </div>
          ) : (
            <React.Fragment>
              <div className="licenseKey">{`${props.licenseKey}...`}</div>
              <div className="licenseKeyMessage">{`Click to copy`}</div>
              <CopyIcon className="license-copy-icon" />
            </React.Fragment>
          )}
        </CopyContainer>
      </CopyToClipboard>
    </TableCell>
  );
};
const renderLicenseKey = (cellProps: TableCellProps) => {
  const fullKey = cellProps.cellData;
  const key = _.isString(fullKey) ? fullKey.substring(0, 5) : "";
  const commercialModel = _.get(cellProps.rowData, "commercialModel.name");
  const { isExpired } = getTermDetails(
    _.get(cellProps.rowData, "latestTerm.endDate"),
    { commercialModel }
  );

  return (
    <Tooltip placement="top" title={fullKey}>
      <TableText isExpired={isExpired}>
        <LicenseKey licenseKey={key} fullKey={fullKey} />
      </TableText>
    </Tooltip>
  );
};

const renderCommercialModel = (payload: TableCellProps) => {
  const commercialModel = _.get(payload.rowData, "commercialModel.name");
  const { isExpired } = getTermDetails(
    _.get(payload.rowData, "latestTerm.endDate"),
    { commercialModel }
  );
  if (commercialModel) {
    let showTooltipOnText = getTextWidth(commercialModel) > 90 ? true : false;
    return (
      <TableText title={commercialModel} isExpired={isExpired}>
        {showTooltipOnText ? (
          <Tooltip placement="top" title={commercialModel}>
            {commercialModel}
          </Tooltip>
        ) : (
          commercialModel
        )}
      </TableText>
    );
  }
  return <TableText isExpired={isExpired}>Classic</TableText>;
};

const renderNameAndIcon = (payload: TableCellProps) => {
  const isVendor = _.get(payload, "rowData.isVendor", false);
  const isBusinessUnit =
    _.get(payload, "rowData.owner.type", "") === "BusinessUnit";
  const commercialModel = _.get(payload.rowData, "commercialModel.name");
  const { isExpired } = getTermDetails(
    _.get(payload.rowData, "latestTerm.endDate"),
    { commercialModel }
  );
  let defaultCellWidth = 120;
  if (isVendor) defaultCellWidth -= 20;
  if (isBusinessUnit) defaultCellWidth -= 20;
  let showTooltipOnText =
    getTextWidth(payload.cellData) > defaultCellWidth ? true : false;
  return (
    <div style={{ display: "flex", width: "inherit" }}>
      <TableText
        title={payload.cellData}
        maxWidth={defaultCellWidth}
        isExpired={isExpired}
      >
        {showTooltipOnText ? (
          <Tooltip placement="top" title={payload.cellData}>
            {payload.cellData}
          </Tooltip>
        ) : (
          payload.cellData
        )}
      </TableText>
      <div>
        {_.get(payload, "rowData.isVendor", false) && (
          <Tooltip placement="right" title={"Vendor"}>
            <img style={{ marginLeft: "4px" }} src={VendorIcon} alt="vendor" />
          </Tooltip>
        )}
        {_.get(payload, "rowData.owner.type", "") === "BusinessUnit" && (
          <Tooltip placement="right" title={"Business Unit"}>
            <img
              style={{ marginLeft: "4px" }}
              src={BusinessUnitIcon}
              alt="Business unit"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const renderTermTag = (payload: TableCellProps) => {
  const timestamp = _.get(payload.rowData, "latestTerm.endDate");
  if (!timestamp) {
    return null;
  }
  const { diffInDays, date, humanized } = getTermDetails(timestamp);
  const commercialModel = _.get(payload.rowData, "commercialModel.name");
  const { isExpired } = getTermDetails(_.get(payload.cellData, "endDate"), {
    commercialModel
  });
  const isPerpetual =
    _.get(payload.rowData, "commercialModel.name") === cM.perpetualMS;
  const isLease = _.get(payload.rowData, "commercialModel.name") === cM.lease;
  const isFreeware =
    _.get(payload.rowData, "commercialModel.name") === cM.freeware;
  if (isFreeware) {
    return null;
  }
  const getSuffixType = (): "critical" | "warning" | "info" => {
    if (isPerpetual) {
      return "info";
    }
    if (isLease) {
      const entitlements = _.get(payload.rowData, "activeEntitlements");
      if (entitlements?.nodes?.length <= 1) {
        return null;
      }
      let retVal: "critical" | "warning" | "info" = "info";
      entitlements?.nodes?.forEach((node: EntitlementNode) => {
        const { diffInDays: entDiff } = getTermDetails(
          `${node?.latestTerm?.endDate}`
        );
        if (entDiff <= 7) {
          retVal = "critical";
        }
        if (entDiff <= 90) {
          retVal = "warning";
        }
      });
      return retVal;
    }
    if (diffInDays <= 7) {
      return "critical";
    }
    if (diffInDays <= 90) {
      return "warning";
    }
    return null;
  };
  const getTermMessage = () => {
    if (isPerpetual) {
      return "Expiration date is for maintenance and support, license will remain active after this date";
    }
    if (isLease) {
      const entitlements = _.get(payload.rowData, "activeEntitlements");
      if (entitlements?.nodes?.length <= 1) {
        return null;
      }
      const terms: string[] = [];
      entitlements?.nodes?.forEach((node: EntitlementNode) => {
        const eM = licenseTypeToName(_.get(payload.rowData, "type.id"));
        let seats = 0;
        if (eM === enforcementModel.namedUser) {
          seats = _.get(node, "userCount", 0);
        }
        if (eM === enforcementModel.concurrent) {
          seats = _.get(node, "sessionCount", 0);
        }
        if (eM === enforcementModel.nodeLocked) {
          seats = _.get(node, "instanceCount", 0);
        }
        !!node?.latestTerm?.endDate &&
          terms.push(
            `Entitlement for ${seats} seats expires on ${dayjs(
              `${node?.latestTerm?.endDate}`
            )?.format(DATE_FORMAT)}`
          );
      });
      return terms?.map(item => <div>{item}</div>) || "";
    }
    if (diffInDays > 90) {
      return "";
    }
    if (diffInDays > 0) {
      if (diffInDays <= 7) {
        return `Expires in ${_.round(diffInDays)} days`;
      }
      if (diffInDays <= 90) {
        return "Licenses expiring within 90 days";
      }
    }

    return humanized;
  };
  const type = getSuffixType();
  return (
    <Flex
      alignItems="center"
      style={{ minWidth: "max-content", flexDirection: "column" }}
    >
      <TableCellPTag style={{ margin: "0px", alignSelf: "flex-start" }}>
        <TableText isExpired={isExpired}>{date}</TableText>
        <Tooltip placement="top" title={getTermMessage()}>
          {type === "info" && <InfoIcon />}
          {type === "critical" && (
            <img
              style={{ marginLeft: "4px" }}
              src={DangerSignIcon}
              alt="critical"
            />
          )}
          {type === "warning" && (
            <img
              style={{ marginLeft: "4px" }}
              src={WarningSignIcon}
              alt="warning"
            />
          )}
        </Tooltip>
      </TableCellPTag>
    </Flex>
  );
};
const renderUsageCount = (cell: TableCellProps) => {
  const timestamp = _.get(cell.rowData, "latestTerm.endDate");
  const commercialModel = _.get(cell.rowData, "commercialModel.name");
  const { isExpired } = getTermDetails(timestamp, { commercialModel });
  const licenseType = _.get(cell.rowData, "type.id");
  const eM = licenseTypeToName(licenseType);
  if (eM === enforcementModel.namedUser) {
    return (
      <TableText isExpired={isExpired}>
        {`${_.get(cell.rowData, "numActiveUsers", 0)}/${_.get(
          cell.rowData,
          "numTotalUsers",
          0
        )}`}
      </TableText>
    );
  }
  if (eM === enforcementModel.concurrent) {
    return (
      <TableText isExpired={isExpired}>
        {`${_.get(cell.rowData, "totalSeatCount", 0)}`}
      </TableText>
    );
  }
  if (eM === enforcementModel.nodeLocked) {
    /**
     * TODO: check if the instancesCount with active filters query is fixed
     * on the backend, to avoid manually counting active instances
     */
    let activeInstances = 0;
    cell.rowData?.instances?.nodes?.forEach((node: InstanceNode) => {
      if (node?.active) {
        activeInstances++;
      }
    });
    return (
      <TableText isExpired={isExpired}>
        {`${activeInstances}/${_.get(cell.rowData, "numTotalInstances", 0)}`}
      </TableText>
    );
  }
  return null;
};

export const LicensesTableColumns = {
  Company: "Company",
  Product: "Product",
  CommercialModel: "Commercial Model",
  EnforcementModel: "Enforcement Model",
  Expiration: "Expiration",
  Usage: "Usage",
  LicenseKey: "Key",
  Actions: "Actions"
} as const;
export const LicensesTableColumnConfig: Record<
  keyof typeof LicensesTableColumns,
  IColumn
> = {
  Company: {
    accessor: "owner.name",
    sortKey: "owner.name",
    Header: LicensesTableColumns.Company,
    width: 0.25,
    filterable: {
      filterName: "owner.name",
      dataKey: "licenses.companyNames.edges",
      dataMap: payload => _.get(payload, "node.owner.name")
    },
    cellRenderer: payload => renderNameAndIcon(payload)
  },
  Product: {
    Header: LicensesTableColumns.Product,
    accessor: "product",
    sortKey: "product.name",
    width: 0.1,
    filterable: {
      filterName: "product.name",
      dataKey: "licenses.productNames.edges",
      dataMap: payload => _.get(payload, "node.product.name")
    },
    cellRenderer: payload => {
      const dataToRender = [
        {
          id: _.get(payload.cellData, "id"),
          iconUrl: _.get(payload.cellData, "iconUrl"),
          name: _.get(payload.cellData, "name", "Name not specified.")
        }
      ];
      return renderProductImages(dataToRender);
    }
  },
  CommercialModel: {
    Header: LicensesTableColumns.CommercialModel,
    accessor: "commercialModel.name",
    disableSortBy: true,
    width: 0.25,
    cellRenderer: payload => renderCommercialModel(payload)
  },
  EnforcementModel: {
    Header: LicensesTableColumns.EnforcementModel,
    accessor: "type.id",
    disableSortBy: true,
    cellRenderer: payload => renderLicenseType(payload),
    filterable: {
      filterName: "type.id",
      dataKey: null,
      dataMap: payload => licenseTypeToName(payload),
      overrideData: () => ["1", "2", "3", "4"],
      filterGenerator: payload => {
        const filterValues: string[] = [];
        _.each([1, 2, 3, 4], licenseTypeId => {
          if (
            payload.filterColumnSelection.includes(
              licenseTypeToName(licenseTypeId as licenseTypeId)
            )
          ) {
            filterValues.push(licenseTypeId.toString());
          }
        });
        if (filterValues.length) {
          payload.filterState.filterColumn.push("type.id");
          payload.filterState.filterOp.push(["="]);
          payload.filterState.filterValues.push(filterValues);
        }
        return payload.filterState;
      }
    }
  },
  Expiration: {
    Header: LicensesTableColumns.Expiration,
    filterable: {
      filterName: "latestTerm.endDate",
      overrideData: () => ["Active", "Expired", "Expiring Soon"],
      filterGenerator: payload => {
        const includeActive = payload.filterColumnSelection.includes("Active");
        const includeExpired = payload.filterColumnSelection.includes(
          "Expired"
        );
        const includeExpiringSoon = payload.filterColumnSelection.includes(
          "Expiring Soon"
        );
        if (includeActive && includeExpired)
          // that's all licenses, so no filter on term
          return payload.filterState;
        if (includeActive /* && whether or not expiring soon is selected */) {
          payload.filterState.filterColumn.push("latestTerm.endDate");
          payload.filterState.filterValues.push([
            moment().format(DATE_FORMAT_ALT)
          ]);
          payload.filterState.filterOp.push([">"]);

          payload.filterState.filterColumn.push("latestTerm.startDate");
          payload.filterState.filterValues.push([
            moment().format(DATE_FORMAT_ALT),
            moment().format(DATE_FORMAT_ALT)
          ]);
          payload.filterState.filterOp.push(["<", "="]);
          return payload.filterState;
        }
        if (includeExpired && !includeExpiringSoon) {
          payload.filterState.filterColumn.push("latestTerm.endDate");
          payload.filterState.filterValues.push([
            moment().format(DATE_FORMAT_ALT)
          ]);
          payload.filterState.filterOp.push(["<"]);
          return payload.filterState;
        }
        if (includeExpiringSoon && !includeExpired) {
          payload.filterState.filterColumn.push("latestTerm.endDate");
          payload.filterState.filterColumn.push("latestTerm.endDate");

          payload.filterState.filterValues.push([
            moment().format(DATE_FORMAT_ALT)
          ]);
          payload.filterState.filterOp.push([">"]);

          payload.filterState.filterValues.push([
            moment()
              .add(90, "d")
              .format(DATE_FORMAT_ALT)
          ]);
          payload.filterState.filterOp.push(["<"]);
          return payload.filterState;
        }
        if (includeExpired && includeExpiringSoon) {
          payload.filterState.filterColumn.push("latestTerm.endDate");
          payload.filterState.filterValues.push([
            moment()
              .add(90, "d")
              .format(DATE_FORMAT_ALT)
          ]);
          payload.filterState.filterOp.push(["<"]);
        }
        return payload.filterState;
      },

      defaultSelected: ["Active"],
      dataKey: null,
      dataMap: payload => payload
    },
    sortKey: "latestTerm.endDate",
    accessor: "latestTerm",
    cellRenderer: payload => renderTermTag(payload)
  },
  Usage: {
    Header: LicensesTableColumns.Usage,
    width: 0.12,
    accessor: "",
    disableSortBy: true,
    cellRenderer: payload => renderUsageCount(payload)
  },
  LicenseKey: {
    Header: LicensesTableColumns.LicenseKey,
    width: 0.18,
    accessor: "key",
    disableSortBy: true,
    cellRenderer: payload => renderLicenseKey(payload)
  },
  Actions: {
    Header: LicensesTableColumns.Actions,
    accessor: "approved",
    disableSortBy: true,
    cellRenderer: () => "",
    width: 0.1
  }
};

const GeneratedTable = tableGenerator<{
  licenses: licensesTable_licenses;
}>({
  tableName: "licensesTable",
  dataKey: "licenses.licenses",
  getTotalCount: data => {
    return data?.licenses?.licensesCount || 0;
  },
  columns: [
    ...Object.keys(LicensesTableColumnConfig).map(
      key =>
        LicensesTableColumnConfig[key as keyof typeof LicensesTableColumnConfig]
    )
  ],
  connectionQuery: graphql`
    query licensesTablePaginationQuery(
      $count: Int!
      $cursor: String
      $blade_scope: String
      $filterColumn: [String!]
      $filterValues: [[String!]!]
      $filterOp: [[String!]!]
      $search: String
      $sortColumn: [String!]
      $sortDirection: [String!]
      $skipActionsColumn: Boolean!
      $skipUsageColumn: Boolean!
      $skipCompany: Boolean!
      $skipProduct: Boolean!
    ) {
      ...licensesTable_licenses
        @arguments(
          count: $count
          cursor: $cursor
          blade_scope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
          skipActionsColumn: $skipActionsColumn
          skipUsageColumn: $skipUsageColumn
          skipCompany: $skipCompany
          skipProduct: $skipProduct
        )
    }
  `,
  fragmentSpec: {
    licenses: graphql`
      fragment licensesTable_licenses on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          blade_scope: { type: "String", defaultValue: null }
          filterColumn: { type: "[String!]", defaultValue: null }
          filterOp: { type: "[[String!]!]", defaultValue: null }
          filterValues: { type: "[[String!]!]", defaultValue: null }
          search: { type: "String", defaultValue: null }
          sortColumn: { type: "[String!]", defaultValue: null }
          sortDirection: { type: "[String!]", defaultValue: null }
          skipUsageColumn: { type: "Boolean!", defaultValue: false }
          skipActionsColumn: { type: "Boolean!", defaultValue: false }
          skipCompany: { type: "Boolean!", defaultValue: false }
          skipProduct: { type: "Boolean!", defaultValue: false }
        ) {
        licensesCount(
          search: $search
          bladeScope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        )
        productNames: licenses(bladeScope: $blade_scope)
          @skip(if: $skipProduct) {
          edges {
            node {
              product {
                name
              }
            }
          }
        }
        companyNames: licenses(bladeScope: $blade_scope)
          @skip(if: $skipCompany) {
          edges {
            node {
              owner {
                name
              }
            }
          }
        }
        licenses(
          first: $count
          after: $cursor
          bladeScope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        ) @connection(key: "licensesTable_licenses") {
          edges {
            node {
              id
              key
              owner {
                name
              }
              product {
                id
                name @skip(if: $skipProduct)
                iconUrl @skip(if: $skipProduct)
              }
              type {
                id
              }
              commercialModel {
                name
              }
              isTrial
              isVendor
              isExpired
              createdAt
              latestTerm {
                endDate
              }
              activeEntitlements {
                nodes {
                  latestTerm {
                    endDate
                  }
                }
              }
              totalSeatCount @skip(if: $skipUsageColumn)
              numTotalUsers: usersCount @skip(if: $skipUsageColumn)
              numTotalInstances: instancesCount @skip(if: $skipUsageColumn)
              instances @skip(if: $skipUsageColumn) {
                nodes {
                  active
                }
              }
              numActiveUsers: usersCount(
                filterColumn: ["users.active"]
                filterOp: [["="]]
                filterValues: [["true"]]
              ) @skip(if: $skipUsageColumn)
              approved
              licenseActions @skip(if: $skipActionsColumn) {
                nodes {
                  id
                  action
                  status
                }
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query licensesTableQuery(
    $blade_scope: String!
    $filterColumn: [String!]
    $filterOp: [[String!]!]
    $filterValues: [[String!]!]
    $sortColumn: [String!]
    $sortDirection: [String!]
    $skipActionsColumn: Boolean!
    $skipUsageColumn: Boolean!
    $skipCompany: Boolean!
    $skipProduct: Boolean!
  ) {
    ...licensesTable_licenses
      @arguments(
        blade_scope: $blade_scope
        filterColumn: $filterColumn
        filterOp: $filterOp
        filterValues: $filterValues
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        skipActionsColumn: $skipActionsColumn
        skipUsageColumn: $skipUsageColumn
        skipCompany: $skipCompany
        skipProduct: $skipProduct
      )
  }
`;

class LicensesPaginationTable extends React.Component<
  IGeneratedTableProps & {
    result: licensesTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        licenses={res}
      />
    );
  }
}

export const LicensesTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: licensesTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <LicensesPaginationTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<licensesTableQuery>
      environment={relayEnvironment}
      variables={{
        blade_scope: props.bladeScope || "",
        sortColumn: ["owner.name"],
        sortDirection: ["ASC"],
        skipActionsColumn:
          props.hideColumnsWithHeaders?.includes(
            LicensesTableColumns.Actions
          ) || false,
        skipUsageColumn:
          props.hideColumnsWithHeaders?.includes(LicensesTableColumns.Usage) ||
          false,
        skipCompany:
          props.hideColumnsWithHeaders?.includes(
            LicensesTableColumns.Company
          ) || false,
        skipProduct:
          props.hideColumnsWithHeaders?.includes(
            LicensesTableColumns.Product
          ) || false,
        filterValues: [[moment().format(DATE_FORMAT_ALT)]],
        filterColumn: ["latestTerm.endDate"],
        filterOp: [[">"]]
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default LicensesTable;
