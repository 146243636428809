import React, { useLayoutEffect, useRef, useState } from "react";
import uuid from "uuid";
import { IChart } from "./types";
import { useChartData } from "./hooks";
import { customChartTheme, exportMenuItems } from "./helpers";
import _ from "lodash";
import FiltersContainer from "./filtersContainer";
import { useTheme } from "styled-components";
import NoDataPlaceholder from "./noDataPlaceholder";

function consolidateData(
  chartData: { [key: string]: any }[],
  maxCategories: number,
  maxGroups: number
) {
  const getAggregates = (key: string) => {
    var keys = _.uniq(_.map(chartData, c => _.get(c, key)));
    return _.map(keys, k => {
      const filtered = _.filter(chartData, c => c[key] == k);
      const sum = _.sumBy(filtered, f => f["value"]);
      let ret = {};
      ret[key] = k;
      ret["value"] = sum;
      return ret;
    });
  };
  const categoryAggregates = _.reverse(
    _.sortBy(getAggregates("category"), c => c["value"])
  );
  const groupAggregates = _.reverse(
    _.sortBy(getAggregates("group"), c => c["value"])
  );
  const topCategories = _.map(
    _.slice(categoryAggregates, 0, maxCategories),
    c => c["category"]
  );
  const topGroups = _.map(
    _.slice(groupAggregates, 0, maxGroups),
    c => c["group"]
  );
  let ret: { [key: string]: any }[] = [];
  _.each(chartData, c => {
    let category = c["category"];
    let group = c["group"];
    if (!_.includes(topCategories, category)) category = "Other";
    if (!_.includes(topGroups, group)) group = "Other";
    const value = c["value"];
    if (category !== "Other" && group !== "Other")
      ret.push({ category, group, value });
    else {
      const existing = _.find(
        ret,
        r => r["category"] == category && r["group"] == group
      );
      if (existing) {
        existing["value"] += value;
      } else ret.push({ category, group, value });
    }
  });
  return ret;
}

function HeatMapChartComponent(props: IChart) {
  const hasData = useRef();

  const theme = useTheme();

  let instanceId = uuid();
  const { data } = useChartData({
    ...props.queryParams,
    useLocalData: props.data ? true : false
  });
  // This code will only run one time
  useLayoutEffect(() => {
    let root = am5.Root.new(instanceId);

    let myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll(customChartTheme);

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    if (root._logo) {
      root._logo._privateSettings.visible = false;
    }

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        minHeight: 110,
        maxHeight: props.options?.chartMaxHeight || 170,
        layout: root.verticalLayout
      })
    );

    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );
    var yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 20 });

    yRenderer.grid.template.set("visible", false);
    yRenderer.labels.template.setAll({
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      fill: theme.chartColors.labels,
      inversed: true
      // add rotation
    });
    yRenderer.setAll({
      stroke: theme.chartColors.axes
    });
    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        visible: _.first(props.plot?.yAxes)?.hideLabel ? false : true //show/hide y labels
      })
    );

    //   yAxis.get("renderer").labels.template.setAll({
    //     centerX: am5.p50,
    //     x: am5.p50
    // });

    const maxGroups = props.options?.maxGroups || 12;
    const maxCategories = props.options?.maxCategories || 6;
    const consolidatedData = consolidateData(
      data?.analytics?.values,
      maxCategories,
      maxGroups
    );

    var xRenderer = am5xy.AxisRendererX.new(root, {
      visible: false,
      minGridDistance: 30
      // opposite: true
    });
    xRenderer.grid.template.set("visible", false);
    xRenderer.labels.template.setAll({
      visible: false
    });
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "group"
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      fill: theme.chartColors.labels
    });

    xAxis.get("renderer").setAll({
      stroke: theme.chartColors.axes
    });

    // Add heat legend
    // https://www.amcharts.com/docs/v5/concepts/legend/heat-legend/
    // var heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
    //     orientation: "horizontal",
    //     endColor: am5.color(0xfffb77),
    //     startColor: am5.color(0xfe131a)
    // }));
    function createSeries(field, name) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: field,
          xAxis: xAxis,
          yAxis: yAxis,
          stroke: am5.color(0xffffff),
          categoryXField: "group",
          categoryYField: "category",
          valueField: "value",
          calculateAggregates: true
        })
      );

      series.columns.template.setAll({
        tooltipText: "{categoryX}: {value}",
        strokeOpacity: 1,
        strokeWidth: 2,
        width: am5.percent(100),
        height: am5.percent(100)
      });

      var cellSize = 30;
      series.events.on("datavalidated", function(ev) {
        var series = ev.target;
        var chart = series.chart;
        //var xAxis = chart.xAxes.getIndex(0);
        var yAxis = chart.yAxes.getIndex(0);

        // Calculate how we need to adjust chart height
        var chartHeight =
          series.data.length * cellSize +
          yAxis.height() +
          chart.get("paddingTop", 0) +
          chart.get("paddingBottom", 0);

        // Set it on chart's container
        chart.root.dom.style.height = chartHeight + "px";
        // heatLegend.set("startValue", series.getPrivate("valueHigh"));
        // heatLegend.set("endValue", series.getPrivate("valueLow"));
      });

      series.data.setAll(consolidatedData);
      yAxis.data.setAll(
        _.map(_.uniq(_.map(consolidatedData, c => c.category)), c => ({
          category: c
        }))
      );
      let groups = _.uniq(_.map(consolidatedData, c => c.group));
      const otherIndex = _.findIndex(groups, c => c == "Other");
      if (otherIndex >= 0) {
        groups.splice(otherIndex, 1);
        groups = _.sortBy(groups, g => g).concat(["Other"]);
      } else groups = _.sortBy(groups, g => g);
      xAxis.data.setAll(
        _.map(groups, c => ({
          group: c
        }))
      );
      series.set("heatRules", [
        {
          target: series.columns.template,
          min: am5.color(0x0000ff),
          max: am5.color(0xff0000),
          dataField: "value",
          key: "fill"
        }
      ]);

      series.appear();

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          //container: document.getElementById("exportdiv")
        }),
        dataSource: consolidatedData
      });

      exporting.events.on("dataprocessed", function(ev) {
        // This will override export data, but will not modify original data source
        // const exData = [...consolidatedData];
        // let test = exData.map((item) => {
        //   return item;
        // });
        // ev.data = test;
      });

      exporting.get("menu").set("items", exportMenuItems);

      // !props.plot.hideLegend && legend.data.push(series);
      return series;
    }
    createSeries("category", "value");
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, props.data, theme]);

  hasData.current = _.get(data, "analytics.values", []).length;

  return (
    <>
      <div
        id={instanceId}
        style={{
          width: "100%",
          minHeight: "inherit"
        }}
        hidden={hasData.current ? false : true}
      ></div>
      {!hasData.current && <NoDataPlaceholder />}
    </>
  );
}

export default function HeatMapChart(props: IChart) {
  return (
    <FiltersContainer
      containerProps={props}
      Component={HeatMapChartComponent}
    />
  );
}
