import React, { useLayoutEffect, useRef } from "react";
import uuid from "uuid";
import { useChartData } from "./hooks";
import { IChart } from "./types";
import { customChartTheme, exportMenuItems, getChartData } from "./helpers";
import FiltersContainer from "./filtersContainer";
import { useTheme } from "styled-components";
import _ from "lodash";
import NoDataPlaceholder from "./noDataPlaceholder";

enum PlotTitle {
  Category = "Name",
  Data = "Total",
  Percentage = "Percentage"
}

interface GeneratedData {
  [key: string]: any;
  category?: string;
  data?: number;
  percentage?: number;
}

function PieChartComponent(props: IChart) {
  const theme = useTheme();
  let instanceId = uuid();

  const { data } = useChartData({
    ...props.queryParams,
    useLocalData: props.data ? true : false
  });

  const hasData = useRef();

  useLayoutEffect(() => {
    const root = am5.Root.new(instanceId);

    if (root._logo) {
      root._logo._privateSettings.visible = false;
    }
    // Set themes
    let myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll(customChartTheme);

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        //layout: root.gridLayout,
        layout: root.horizontalLayout,
        innerRadius: props?.options?.pieChartType === "donut" ? 60 : 0,
        minHeight: 150,
        maxHeight: props.options?.chartMaxHeight || 170,
        fixedWidthGrid: true
      })
    );

    let generatedData: GeneratedData[] = getChartData(
      props.data ? props.data : data
    );
    const defaultShowMax = props?.options?.showMaxItems || 5;
    if (
      //props?.options?.showMaxItems &&
      generatedData?.length >= defaultShowMax
    ) {
      let sortedData = generatedData.sort((a, b) => b?.data - a?.data);
      let changed = [];
      for (let i = 0; i < sortedData?.length; i++) {
        if (i < defaultShowMax) changed.push(sortedData[i]);
        else {
          let dataSum =
            (changed[defaultShowMax]?.data || 0) + sortedData[i]?.data;
          changed[defaultShowMax] = {
            category: props?.options?.consolidatedTitle || "Other",
            data: dataSum
          };
        }
      }
      generatedData = changed;
    }
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "data",
        categoryField: "category",
        legendLabelText: "[{fill}]{category}[/]",
        legendValueText: "[bold {fill}]{value}[/]"
      })
    );

    series
      .get("colors")
      .set("colors", [
        am5.color("#0F649A"),
        am5.color("#FFA033"),
        am5.color("#68B6E8"),
        am5.color("#E3D626"),
        am5.color("#70D165"),
        am5.color("#EF8879"),
        am5.color("#F4F6F6")
      ]);

    series.labels.template.setAll({
      oversizedBehavior: "wrap",
      minScale: 0.7,
      maxWidth: 90,
      textAlign: "left",
      fill: theme.chartColors.labels
    });
    series.ticks.template.setAll({
      stroke: theme.chartColors.labels
    });

    if (props?.options?.pieChartType === "donut") {
      //number that appears inside dounut chart is a total sum of values from generated data.
      const valueSum = generatedData?.reduce(
        (prev, next) => prev + next.value,
        0
      );
      var label = root.tooltipContainer.children.push(
        am5.Label.new(root, {
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
          fill: am5.color(0x000000),
          fontSize: 34
        })
      );
      if (valueSum) label.set("text", valueSum);
    }

    series.labels.template.adapters.add("y", function(y, target) {
      const dataItem = target.dataItem;
      if (dataItem) {
        const tick = dataItem.get("tick");
        if (tick && props.options?.hideLabels) {
          target.set("forceHidden", true);
          tick.set("forceHidden", true);
        }
        return y;
      }
    });
    series.data.setAll(generatedData);

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        layout: root.verticalLayout
      })
    );
    props?.options?.showLegend && legend.data.setAll(series.dataItems);

    if (_.isFunction(props.openBlade)) {
      series.setAll({ cursorOverStyle: "pointer" });
      series.slices?._values?.forEach(slice =>
        slice?.events?.on("click", ev => {
          const identifier = ev?.target?._dataItem?.dataContext?.category;
          const targetIndex = data?.analytics?.categories?.findIndex(
            item => item === identifier
          );
          if (
            targetIndex >= 0 &&
            data?.analytics?.categoryMetadata[targetIndex]?.id
          ) {
            props.openBlade({
              name: identifier,
              bladeType: data.analytics.categoryMetadata[targetIndex].bladeType,
              id: data.analytics.categoryMetadata[targetIndex].id
            });
          }
        })
      );
    }

    series.appear(1000, 100);

    const prepareData = () => {
      const valueSum = generatedData?.reduce(
        (prev, next) => prev + next.data,
        0
      );
      let categoryName = _.get(
        props,
        "queryParams.setup.category",
        PlotTitle.Category
      );
      if (categoryName.includes(".")) {
        categoryName = categoryName.slice(0, categoryName.indexOf("."));
      }
      return generatedData
        .map(({ data }, index) => ({
          ...generatedData[index],
          [PlotTitle.Percentage]: Number((100 * data) / valueSum).toFixed(2)
        }))
        .map(item => {
          let dataTitle = `${_.get(props, "plot.title", PlotTitle.Data)}`;
          return {
            [categoryName]: _.get(item, "category", ""),
            [dataTitle]: _.get(item, "data", ""),
            [PlotTitle.Percentage]: _.get(item, PlotTitle.Percentage, "")
          };
        });
    };

    let exporting;
    if (
      !props.exportOptions?.showCustomExportButton &&
      !props.exportOptions?.disableExport
    ) {
      exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          //container: document.getElementById("exportdiv")
        }),
        dataSource: generatedData
      });
      exporting.events.on("dataprocessed", function(ev: {
        data: GeneratedData[];
      }) {
        ev.data = prepareData();
      });
      exporting.get("menu").set("items", exportMenuItems);
    }

    if (
      props.exportOptions?.showCustomExportButton &&
      !props.exportOptions?.disableExport &&
      _.isFunction(props?.setChartData)
    ) {
      props.setChartData({
        data: prepareData(),
        root
      });
    }

    return () => {
      root.dispose();
    };
  }, [data, theme]);

  hasData.current = _.get(data, "analytics.values", []).length;

  return (
    <>
      <div
        id={instanceId}
        style={{
          width: "100%",
          minHeight: "inherit",
          height: props.options?.chartMaxHeight || 170
        }}
        hidden={hasData.current ? false : true}
      ></div>
      {!hasData.current && <NoDataPlaceholder />}
    </>
  );
}

export default function PieChart(props: IChart) {
  return (
    <FiltersContainer containerProps={props} Component={PieChartComponent} />
  );
}
