import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import ElmInput from "../../../../components/elmInput";
import { getGateway } from "../../../../api";
import {
  DialogContainer,
  DialogTitle
} from "../../../../components/bladeManager/appDialog/helpers";
import { ILicenseTemplate } from "api/gatewayMap";
import { Tooltip } from "antd";
import { base64EncodeId } from "utils/base64EncodeDecode";
import {
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";

const SectionTitle = styled.p(props => ({
  color: props.theme.colors.warmGrey,
  fontSize: props.theme.fontSizes.xSmall,
  fontWeight: 500,
  letterSpacing: 1.5,
  textTransform: "uppercase",
  lineHeight: "15px",
  paddingBottom: "8px",
  marginTop: "20px",
  borderBottom: `1px solid ${props.theme.colors.whiteTwo}`
}));

const SectionDetail = styled.span(props => ({
  color: props.theme.colors.warmGrey,
  fontSize: props.theme.fontSizes.ySmall,
  fontWeight: 400,
  lineHeight: "20px",
  marginBottom: "8px"
}));

const SectionDetailHighlited = styled.span(props => ({
  color: props.theme.colors.pureBlack
  // fontSize: props.theme.fontSizes.ySmall,
  // fontWeight: 400,
  // lineHeight: "20px",
  // marginBottom: "8px"
}));

export interface INewProductReleaseConfigDialogProps {}
type state = {};
export class AddTemplateDialog extends DialogBase<
  IModalProps<INewProductReleaseConfigDialogProps>,
  state
> {
  state: state = {
    loading: false,
    templateName: ""
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 999,
          background: "rgb(255, 255, 255, 0.7)"
        },
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "AddTemplateDialog"
    });
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };

  public createTemplate = () => {
    const data = _.get(this.props, "payload.templateData");
    const templateData: ILicenseTemplate = {
      product_id: _.get(this.props, "payload.templateData.productId", null),
      name: _.get(this.state, "templateName", ""),
      template_body: JSON.stringify({
        ...data,
        commercial_model_id: base64EncodeId(
          "CommercialModel",
          data.commercial_model_id
        )
      })
    };
    const gateway = getGateway();
    gateway.request
      .create_template(templateData)
      .then(res => {
        if (!res.error) {
          renderSuccessNotification("New template added");
          this.closeModal();
        }
      })
      .catch(e => {
        renderFailureNotification("Error adding template !");
        console.error(e);
      });
  };

  public renderQueryRenderer = () => {
    const templateData = Object.assign(
      {},
      _.get(this.props, "payload.templateData")
    );
    const {
      commercialModel,
      enforcementModel,
      features = [],
      feature_group = [],
      components = [],
      component_group = [],
      product
    } = templateData;
    const renderComponentGroup = () =>
      //   payload: {
      //   error: Error;
      //   props: editReleaseConfigQueryResponse;
      // }
      {
        // if (payload.error || !_.isObject(payload.props)) {
        //   return <DialogContainer>{LoaderContainer()}</DialogContainer>;
        // }
        const setSelectedChannel = (payload: any) => {
          this.setState({
            currentRelease: { id: payload?.id, tag: payload?.tag }
          });
        };

        const getShortListDetails = (items: { id: string; name: string }[]) => {
          const names = _.map(items, r => r.name) || [];
          const comps = names.slice(0, 1);
          const first3Components = _.join(comps, ",");
          const restNames = names.slice(1);
          const moreList = _.join(restNames, ",");
          const suffix = () => {
            if (names.length <= 1) {
              return "";
            }
            return `+ ${names.length - comps.length} more.`;
          };
          return (
            <span>
              {first3Components}{" "}
              <Tooltip placement="right" title={moreList}>
                {" "}
                {suffix()}{" "}
              </Tooltip>
            </span>
          );
        };
        let compList = getShortListDetails([...components, ...component_group]);
        let featureList = getShortListDetails([...features, ...feature_group]);
        return (
          <DialogContainer>
            <DialogTitle>Add to templates</DialogTitle>

            <Flex
              flexDirection="column"
              flex={1}
              style={{
                width: "100%",
                marginTop: "5px",
                marginBottom: "5px",
                height: "190px"
              }}
            >
              <ElmInput
                label="Template name"
                placeholder="e.g. flotools Enterprise"
                note="Name your template for easier recognisibility"
                updatedValue={value => this.setState({ templateName: value })}
              />
              <SectionTitle>Details</SectionTitle>
              <Flex flexDirection="column">
                <SectionDetail>
                  Product:{" "}
                  <SectionDetailHighlited>
                    {product?.name || "N/A"}
                  </SectionDetailHighlited>
                </SectionDetail>
                <SectionDetail>
                  Commercial model:{" "}
                  <SectionDetailHighlited>
                    {commercialModel || "N/A"}
                  </SectionDetailHighlited>{" "}
                </SectionDetail>
                <SectionDetail>
                  Enforcement model:{" "}
                  <SectionDetailHighlited>
                    {enforcementModel || "N/A"}
                  </SectionDetailHighlited>{" "}
                </SectionDetail>
                <SectionDetail>
                  Features:{" "}
                  <SectionDetailHighlited>
                    {featureList || "N/A"}
                  </SectionDetailHighlited>
                </SectionDetail>
                <SectionDetail>
                  Components:{" "}
                  <SectionDetailHighlited>
                    {compList || "N/A"}
                  </SectionDetailHighlited>
                </SectionDetail>
              </Flex>
            </Flex>
            <Flex alignItems="flex-end" justifyContent={"flex-end"}>
              <ElmButton
                label={"Cancel"}
                colorVariance={"outline-secondary"}
                onClick={this.closeModal}
              />
              <ElmButton
                //disabled={this.state.loading}
                label={"Add"}
                colorVariance="primary"
                onClick={() => this.createTemplate()}
                //onClick={this.updateRelease}
              />
            </Flex>
          </DialogContainer>
        );
      };
    return renderComponentGroup();
    // return (
    //   <QueryRenderer<editReleaseConfigQuery>
    //     variables={{
    //       id: _.get(this.props.payload, "id")
    //     }}
    //     environment={relayEnvironment}
    //     query={graphqlQuery}
    //     render={renderComponentGroup}
    //   />
    // );
  };

  public render() {
    return this.renderQueryRenderer();
  }
}
