import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Flex } from "reflexbox/styled-components";
import { tableGenerator } from "../../../utils";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import { companyUsersTable_users } from "./__generated__/companyUsersTable_users.graphql";
import { UsersTableColumnConfig } from "Users/components/usersTable";
import _ from "lodash";
import {
  companyUsersTableQuery,
  companyUsersTableQueryResponse
} from "./__generated__/companyUsersTableQuery.graphql";
import { IGeneratedTableProps } from "utils/tableGenerator";

export type CompanyUsersTableType = companyUsersTable_users["users"]["edges"][number]["node"];

const GeneratedTable = tableGenerator<{
  users: companyUsersTable_users;
}>({
  tableName: "companyUsersTable",
  dataKey: "users.users",
  getTotalCount: data => {
    return _.get(data, "users.usersCount") || 0;
  },
  columns: [
    UsersTableColumnConfig.Status,
    UsersTableColumnConfig.User,
    UsersTableColumnConfig.Email,
    UsersTableColumnConfig.Installs
  ],
  connectionQuery: graphql`
    query companyUsersTablePaginationQuery(
      $count: Int!
      $cursor: String
      $blade_scope: String
      $search: String
      $filterColumn: [String!]
      $filterValues: [[String!]!]
      $filterOp: [[String!]!]
    ) {
      ...companyUsersTable_users
        @arguments(
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          count: $count
          cursor: $cursor
          blade_scope: $blade_scope
          search: $search
        )
    }
  `,
  fragmentSpec: {
    users: graphql`
      fragment companyUsersTable_users on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          blade_scope: { type: "String", defaultValue: null }
          search: { type: "String", defaultValue: null }
          filterColumn: { type: "[String!]", defaultValue: ["users.active"] }
          filterOp: { type: "[[String!]!]", defaultValue: [["="]] }
          filterValues: { type: "[[String!]!]", defaultValue: [["true"]] }
        ) {
        usersCount(
          search: $search
          bladeScope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        )
        users(
          first: $count
          after: $cursor
          bladeScope: $blade_scope
          search: $search
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        ) @connection(key: "companyUsersTable_users") {
          edges {
            node {
              id
              name
              email
              createdAt
              instancesCount(bladeScope: $blade_scope)
              active
              products {
                nodes {
                  id
                  iconUrl
                  name
                }
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query companyUsersTableQuery($blade_scope: String!) {
    ...companyUsersTable_users @arguments(blade_scope: $blade_scope)
  }
`;

class UsersTable extends React.Component<
  IGeneratedTableProps & {
    result: companyUsersTableQueryResponse;
    retry: () => void;
  }
> {
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        users={res}
      />
    );
  }
}

export const CompanyUsersTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: companyUsersTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <UsersTable {...props} result={payload.props} retry={payload.retry} />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<companyUsersTableQuery>
      environment={relayEnvironment}
      variables={{
        blade_scope: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};
