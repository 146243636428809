import {
  FetchFunction,
  GraphQLResponse,
  Environment,
  Network,
  RecordSource,
  Store
} from "relay-runtime";
import { getGateway } from "./";
import appActions from "../store/actions";
import { appDispatch } from "../store/appConnect";

const gateway = getGateway();
const fetchQuery: FetchFunction = (operation, variables) => {
  return gateway.request
    .executeGraphqlRequest({
      query: operation.text,
      variables
    })
    .then(res => {
      return res.data as GraphQLResponse;
    })
    .catch<GraphQLResponse>(e => {
      appDispatch(appActions.deviseActions.requestSignOut());
      return null;
    });
};

export const getEnvironment = () =>
  new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource())
  });
const environment = getEnvironment();
environment.getStore().snapshot();

export function clearRelayStore(env: Environment) {
  env.getStore().restore();
  env.getStore().snapshot();
}

export default environment;
