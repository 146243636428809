import React from "react";
import _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import styled, { useTheme } from "styled-components";
import { appConnect, appDispatch } from "../../../store/appConnect";
import relayEnvironment from "../../../api/relay";
import BladeTemplate from "../../../components/bladeManager/bladeTemplate";
import {
  IBladeBaseProps,
  IOpenEntitlementBlade
} from "../../../components/bladeManager/types";
import {
  AnalyticsCarousel,
  BladeTitle,
  ElmButton,
  ElmTable,
  TabView,
  getIcon
} from "../../../components";
import { IAttributValuePair } from "../../../components/attributesBox";

import ElmInput from "../../../components/elmInput";

import ElmRadio from "../../../components/elmRadio";
import { ElmToggle } from "../../../components/elmToggle";
import { ActiveBadge, getClassName } from "../../../components/helpers";
import { componentTypeToId, getTermDetails } from "../../../utils";
import { SearchBar } from "../../../components/helpers";
import {
  EntitlementQuery,
  EntitlementQueryResponse
} from "./__generated__/EntitlementQuery.graphql";
import Radio, { RadioChangeEvent } from "antd/lib/radio";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { AutoSizer } from "react-virtualized";

import Fuse from "fuse.js";
import { Tooltip } from "antd";
import CollapsibleAttributeBox from "../../../components/collapsibleAttributesBox";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../components/elmSelect";
import IndividualComponentSelection from "../NewEntitlement/components/IndividualComponentSelection";
import { ElmDatePickerWithLabel } from "../../../components/elmDate";
import {
  BladeDescription,
  GroupName,
  GroupSelectionContainer,
  GroupSelectionDesc,
  SectionTitle,
  ValueLabel,
  ValueText
} from "./styles";
import GroupSelection from "../NewEntitlement/components/GroupSelection";
import theme from "../../../theme";
import dayjs, { Dayjs } from "dayjs";
import AttributeActionsDropdown from "components/bladeAttributeActionsDropdown";
import { DATE_FORMAT } from "const";
import moment from "moment";
import { componentEntitlementCheckouts } from "utils/commonAnalytics";
import {
  AdditionalInfo,
  LicenseStatus,
  Separator
} from "Licenses/components/licenseAttribute";

export type ComponentFilter =
  | "Checked-out Token"
  | "Consumable Token"
  | "Session";

const groupSelectionQuery = graphql`
  query EntitlementQuery(
    $productId: ID!
    $entitlementId: ID!
    $skip: Boolean!
  ) {
    product(id: $productId) {
      name
      components {
        nodes {
          name
          componentType
          id
        }
      }
      componentGroups {
        nodes {
          name
          id
          componentType
          components {
            nodes {
              name
              id
              componentType
            }
          }
        }
      }
    }

    componentEntitlement(id: $entitlementId) @skip(if: $skip) {
      tokenCount
      currencyCount
      sessionCount
      numCurrencyUsed
      terms {
        nodes {
          startDate
          endDate
        }
      }
      components {
        nodes {
          name
          componentType
          componentGroup {
            name
          }
          id
        }
      }
      componentGroup {
        id
        name
        componentType
        components {
          nodes {
            name
            componentType
            componentGroup {
              name
            }
            id
          }
        }
      }
      latestTerm {
        endDate
        startDate
        isActive
      }
    }
  }
`;

const getNodes = (
  props: EntitlementQueryResponse & { searchQuery: string }
) => {
  let nodes = _.get(
    props,
    "componentEntitlement.components.nodes"
  ) as typeof props.componentEntitlement.components.nodes;
  if (!nodes?.length) {
    nodes = _.get(
      props,
      "componentEntitlement.componentGroup.components.nodes"
    ) as typeof props.componentEntitlement.components.nodes;
  }

  if (props.searchQuery) {
    const results = new Fuse(nodes, { keys: ["name"] }).search(
      props.searchQuery
    );
    return _.map(
      results,
      i => i.item as typeof props.componentEntitlement.components.nodes[number]
    );
  }
  return nodes;
};
const GroupSelectionView = (
  props: EntitlementQueryResponse & {
    type: state["type"];
    searchQuery: string;
    defaultValue?: string;
    onChange: (payload: { checked: boolean; id: string; name: string }) => void;
  }
) => {
  let content;
  const handleSelection = (node: { id: string; name: string }) => (
    e: CheckboxChangeEvent
  ) => {
    if (_.isFunction(props.onChange)) {
      props.onChange({
        ...node,
        checked: e.target.checked
      });
    }
  };
  content = _.map(getNodes(props), node => {
    return (
      <Flex
        flexDirection="column"
        style={{
          marginTop: "14px"
          // alignItems: 'center',
        }}
      >
        <Flex
          style={{
            height: "18px",
            alignItems: "center",
            marginBottom: "4.5px",
            paddingLeft: "20px"
          }}
        >
          {/* <ElmRadio
            style={{ marginRight: '9.25px', marginLeft: '10.2px' }}
            onChange={handleSelection(node)}
            value={node.id}
          /> */}
          <GroupSelectionDesc>{node.name}</GroupSelectionDesc>
        </Flex>
      </Flex>
    );
  });
  const getGroupName = () => {
    if (props) {
      return props.componentEntitlement.componentGroup ? (
        <GroupName>{props.componentEntitlement.componentGroup.name}</GroupName>
      ) : null;
    }
    return null;
  };
  return (
    <GroupSelectionContainer>
      {getGroupName}
      {content}
    </GroupSelectionContainer>
  );
};

export interface IEntitlementBladeProps
  extends IBladeBaseProps<IOpenEntitlementBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}
type EntitlementPartial = {
  allow_unlimited_usage: boolean;
  component_group_id: string;
  component_ids: string[];
  end_date: string;
  start_date: string;
  session_count: number;
  token_count: number;
  currency_count: number;
  overage_allowed_count: number;
  overage_allowed_percentage: number;
};
interface IDataToEdit {
  entitlement: EntitlementPartial & {
    id: string;
  };
}

interface IDataToCreate {
  license_id: string;
  entitlement: EntitlementPartial & {
    license_id: string;
  };
}

type state = {
  type: "componentGroup" | "component";
  globalFilter: ComponentFilter | null;
  componentFilter: ComponentFilter | null;
  selections: { id: string; name?: string; componentType?: string }[];
  selectedGroupId: string;
  entitlementModel: number;
  entitlementModelType: "checked_out" | "consumable" | "session";
  //renewEvery: boolean;
  renewEvery: number;
  renew: "one_time" | "renew"; //
  renewType: "day" | "week" | "month" | "year";
  startType: string; // false - specific date
  overageType: "%" | "count";
  searchQuery: string;
  disabled: boolean;
  error: boolean;
  count: number;
  entitlement: {
    name: string;
    end_date: string;
    start_date: string;
    components: string[];
    overage_allowed_count: number;
    overage_allowed_percentage: number;
    renewal_period_count: number;
    renewal_period_type: number;
    session_count: number;
    tokenCount: number;
    currencyCount: number;
  };
  usageType: "unlimited" | "limited";
  termType: "unlimited" | "limited";
  individualComponentNames: string[];
  sourceSelectedKeys: string[];
  isEditMode: boolean;
  isAddMode: boolean;
  isLoading: boolean;
};
export class EntitlementBlade extends React.Component<
  IEntitlementBladeProps & {
    result: EntitlementQueryResponse;
    theme: any;
    retry: () => void;
  },
  state
> {
  entitlement = this.props?.result?.componentEntitlement;
  product = this.props?.result?.product;
  type = this.entitlement?.components?.nodes.length
    ? "component"
    : "componentGroup";

  selections =
    this.type === "component"
      ? this.entitlement?.components?.nodes || []
      : this.entitlement?.componentGroup?.components?.nodes || [];

  mapEntitlementComponentType = () => {
    const entitlementType =
      _.get(this.selections[0], "componentType") ||
      _.get(this.selections[0], "components.nodes[0].componentType");
    switch (entitlementType) {
      case "Consumable Token":
        return "consumable";
      case "Checked-out Token":
        return "checked_out";
      default:
        return "session";
    }
  };

  state: state = {
    disabled: false,
    error: false,
    type: this.entitlement?.components?.nodes.length
      ? "component"
      : "componentGroup",
    globalFilter: null,
    componentFilter: _.get(this.selections[0], "componentType", null),
    count:
      this.entitlement?.tokenCount ||
      this.entitlement?.sessionCount ||
      this.entitlement?.currencyCount ||
      0,
    selections: [...this.selections],
    selectedGroupId:
      this.type === "componentGroup"
        ? this.entitlement?.componentGroup?.id
        : null,

    entitlement: this.entitlement
      ? {
          name: "",
          components: this.entitlement?.components?.nodes.map(i => i.id) || [],
          overage_allowed_count: 0,
          overage_allowed_percentage: 0,
          renewal_period_count: 0,
          renewal_period_type: 0,
          session_count: _.get(this.entitlement, "sessionCount", 0),
          tokenCount: _.get(this.entitlement, "tokenCount", 0),
          currencyCount: _.get(this.entitlement, "currencyCount", 0),
          end_date: this.entitlement?.latestTerm?.endDate
            ? (this.entitlement.latestTerm.endDate as string)
            : "",
          start_date: this.entitlement?.latestTerm?.startDate
            ? (this.entitlement.latestTerm.startDate as string)
            : dayjs().toISOString()
        }
      : null,
    entitlementModel: 0,
    entitlementModelType: this.mapEntitlementComponentType() || "checked_out",
    //renewEvery: false,
    renewEvery: 1,
    renew: "renew", //
    renewType: "month",
    overageType: "count",
    searchQuery: "",
    //usageType: "unlimited",
    //termType: "unlimited",
    isEditMode: this.props.routeData.entitlementId ? false : true,
    isAddMode: !this.props.routeData.entitlementId ? true : false,
    isLoading: false,
    usageType:
      this.entitlement?.tokenCount ||
      this.entitlement?.currencyCount ||
      this.entitlement?.sessionCount
        ? "limited"
        : "unlimited",
    termType: this.entitlement?.latestTerm?.endDate ? "limited" : "unlimited",
    individualComponentNames: [],
    sourceSelectedKeys: [],
    startType: this.entitlement?.latestTerm?.startDate
      ? "specific_date"
      : "immediately"
  };

  public updateRequest = (key: keyof state["entitlement"]) => (val: any) => {
    const entitlement = {};
    entitlement[key] = val;

    this.setState({
      entitlement: _.merge(this.state.entitlement, entitlement)
    });
  };
  public deleteEntitlement = () => {
    this.setState(
      {
        disabled: true,
        error: false
      },
      () => {
        this.props.gateway.request
          .deleteEntitlement(null, { id: this.props.routeData.entitlementId })
          .then(res => {
            if (res.error) {
              this.setState({ disabled: false }, () => {
                console.error(res.error);
              });
            } else {
              this.props.closeDialog("DeleteDialog");
              this.props.refreshAllOpenBlades();
              this.props.closeBlade({ fromBladeIndex: this.props.index });
            }
          })
          .catch(err => {
            console.error(err);
          });
      }
    );
  };

  public updateSelection = (payload: {
    id: string;
    name: string;
    checked: boolean;
    type?: ComponentFilter;
  }) => {
    if (payload.checked) {
      let selections = [
        ...(this.state.type === "component" ? this.state.selections : []),
        { name: payload.name, id: payload.id, componentType: payload.type }
      ];
      if (payload.type) {
        selections = selections.filter(
          item => item.componentType === payload.type
        );
      }
      let selectedGroupId =
        this.state.type === "componentGroup" ? payload.id : null;
      let componentFilter = payload.type;
      let entitlementModelType: state["entitlementModelType"];
      switch (payload.type) {
        case "Session":
          entitlementModelType = "session";
          break;
        case "Checked-out Token":
          entitlementModelType = "checked_out";
          break;
        case "Consumable Token":
          entitlementModelType = "consumable";
          break;
      }
      if (this.state.type === "componentGroup") {
        selections = [];
      }
      this.setState({
        selections,
        selectedGroupId,
        componentFilter,
        entitlementModelType
      });
    } else {
      let selections = _.filter(
        this.state.selections,
        f => f.id !== payload.id
      );
      if (payload.type) {
        selections = selections.filter(
          item => item.componentType === payload.type
        );
      }
      this.setState({
        selections,
        componentFilter: selections?.length ? payload.type : null,
        entitlementModelType: selections?.length
          ? this.state.entitlementModelType
          : "checked_out"
      });
    }
  };

  public renderSelection = () => {
    if (!this.props.result) {
      return null;
    }

    if (this.state.type === "component") {
      const selected = this.state.selections?.map(item => item.id);
      const onSelectChange = (sourceSelectedKeys: string[]) => {
        this.setState({
          ...this.state,
          sourceSelectedKeys: sourceSelectedKeys
        });
      };
      return (
        <IndividualComponentSelection
          result={this.props.result}
          onChange={this.updateSelection}
          onSelectChange={onSelectChange}
          targetKeys={this.state.individualComponentNames}
          searchQuery={this.state.searchQuery}
          disabled={!this.state.isEditMode}
          sourceSelectedKeys={selected}
          globalFilter={this.state.globalFilter}
          filter={this.state.componentFilter}
          availableComponents={
            this.state.isAddMode
              ? this.props.routeData.availableComponents
              : null
          }
        />
      );
    }

    const selectedGroup =
      this.state.type === "componentGroup"
        ? this.state.selectedGroupId
        : this.state.selections[0]?.id;
    const groupSelection = this.state.isEditMode ? (
      <GroupSelection
        {...this.props.result}
        type={this.state.type}
        defaultValue={selectedGroup}
        onChange={this.updateSelection}
        searchQuery={this.state.searchQuery}
        availableComponents={
          this.state.isAddMode ? this.props.routeData.availableComponents : null
        }
      />
    ) : (
      <GroupSelectionView
        {...this.props.result}
        type={this.state.type}
        defaultValue={selectedGroup}
        onChange={this.updateSelection}
        searchQuery={this.state.searchQuery}
      />
    );
    return groupSelection;
  };
  public renderComponentTypeFilters = () => {
    const btnStyle = {
      height: 24,
      padding: "6px, 10px, 6px, 10px",
      borderRadius: 6
    };
    const handleComponentFilterChange = (globalFilter: ComponentFilter) => {
      if (globalFilter && globalFilter !== this.state.componentFilter) {
        this.setState({ selections: [], componentFilter: null });
      }
      switch (globalFilter) {
        case "Session":
          this.setState({ entitlementModelType: "session" });
          break;
        case "Checked-out Token":
          this.setState({ entitlementModelType: "checked_out" });
          break;
        case "Consumable Token":
          this.setState({ entitlementModelType: "consumable" });
          break;
      }
      this.setState({
        globalFilter
      });
    };
    return (
      <Flex marginTop={20}>
        <ElmButton
          variance={"primary"}
          colorVariance={"primary"}
          onClick={() => handleComponentFilterChange(null)}
          style={btnStyle}
          label="ALL"
        />
        <ElmButton
          variance={"primary"}
          colorVariance={"subtle"}
          style={{ ...btnStyle, color: this.props.theme.colors.userGreen }}
          onClick={() => handleComponentFilterChange("Session")}
          className="darkModeTransparentBtn"
          label="SESSION"
        />
        <ElmButton
          variance={"primary"}
          colorVariance={"subtle"}
          style={{ ...btnStyle, color: this.props.theme.colors.orangeRed }}
          onClick={() => handleComponentFilterChange("Consumable Token")}
          className="darkModeTransparentBtn"
          label="CONSUMABLE"
        />
        <ElmButton
          variance={"primary"}
          colorVariance={"subtle"}
          style={{ ...btnStyle, color: this.props.theme.colors.mango }}
          onClick={() => handleComponentFilterChange("Checked-out Token")}
          className="darkModeTransparentBtn"
          label="CHECKED-OUT"
        />
      </Flex>
    );
  };
  public renderLeftSideHeader = () => {
    return (
      <Flex>
        <BladeTitle>
          {this.state.isAddMode ? "Create an entitlement" : "Entitlement"}
        </BladeTitle>
      </Flex>
    );
  };
  public updateEntitlementModel = (entitlementModel: any) => {
    this.setState({ entitlementModel: parseInt(entitlementModel, 10) });
  };
  public updateRenewEvery = (renewEvery: boolean) => {
    this.setState({ renewEvery });
  };
  public updateQuery = (searchQuery: string) => {
    this.setState({ searchQuery });
  };

  public updateRenewType = (val: any) => {
    const renewType = val as state["renewType"];
    this.setState({ renewType });
  };

  public updateEntitlementType = (val: any) => {
    const entitlementModelType = val as state["entitlementModelType"];
    this.setState({ entitlementModelType });
  };

  public updateOverageType = (val: any) => {
    const overageType = val as state["overageType"];
    this.setState({ overageType });
  };
  public updateCount = (count: any) => {
    this.setState({ count });
  };
  public getAttributesItems = (
    props: EntitlementQueryResponse
  ): IAttributValuePair[] => {
    if (!props || !props.componentEntitlement) {
      return null;
    }

    const entitlementType =
      _.get(props, "componentEntitlement.components.nodes[0].componentType") ||
      _.get(
        props,
        "componentEntitlement.componentGroup.components.nodes[0].componentType"
      );
    const itemStyle = {
      minWidth: "max-content",
      maxWidth: "max-content",
      marginRight: "25px"
    } as React.CSSProperties;
    const items: IAttributValuePair[] = [];
    // {
    //   label: "Components",
    //   itemStyle,
    //   value: (
    //     <span>
    //       {getNodes({ ...props, searchQuery: this.state.searchQuery })
    //         ?.length || 0}
    //     </span>
    //   ),
    // },
    items.push({
      label: props.componentEntitlement?.latestTerm?.endDate
        ? "Activation"
        : "Term",
      itemStyle,
      value: (
        <span>
          {props.componentEntitlement.latestTerm?.startDate &&
          props.componentEntitlement.latestTerm?.endDate
            ? dayjs(
                props.componentEntitlement.latestTerm?.startDate as string
              )?.format(DATE_FORMAT)
            : "Unlimited"}
        </span>
      )
    });
    if (props.componentEntitlement.latestTerm?.endDate) {
      items.push({
        label: "Expiration",
        itemStyle,
        value: (
          <span>
            {dayjs(
              props.componentEntitlement.latestTerm.endDate as string
            )?.format(DATE_FORMAT)}
          </span>
        )
      });
    }
    items.push({
      label: "Usage",
      itemStyle,
      value: <span>{this.isUsageLimited() ? "Limited" : "Unlimited"}</span>
    });
    if (props.componentEntitlement?.currencyCount) {
      items.push(
        {
          label: "Available tokens",
          itemStyle,
          value: (
            <span>
              {(props.componentEntitlement?.currencyCount || 0) -
                (props.componentEntitlement?.numCurrencyUsed || 0)}
            </span>
          )
        },
        {
          label: "Used tokens",
          itemStyle,
          value: <span>{props.componentEntitlement?.numCurrencyUsed || 0}</span>
        }
      );
    }
    items.push({
      label: "Licensing Model",
      itemStyle,
      value: <span>{entitlementType}</span>
    });
    return items;
  };
  public updateType = (e: RadioChangeEvent) => {
    this.setState({
      type: e.target.value,
      selections: [],
      selectedGroupId: null,
      globalFilter: null,
      entitlementModelType: null,
      componentFilter: null
    });
  };

  public renderAttributesBox = (props: EntitlementQueryResponse) => {
    const attributeBoxHeight = 113;
    if (!props || !props.componentEntitlement) {
      return null;
    }
    const renderRowHeader = () => {
      const renderAdditionalInfo = (info: string) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "max-content"
          }}
        >
          <Separator />
          <AdditionalInfo>{info}</AdditionalInfo>
        </div>
      );
      const isActive = props.componentEntitlement?.latestTerm?.isActive;
      const entitlementType =
        _.get(
          props,
          "componentEntitlement.components.nodes[0].componentType"
        ) ||
        _.get(
          props,
          "componentEntitlement.componentGroup.components.nodes[0].componentType"
        );
      return (
        <div style={{ display: "flex", alignItems: "center", height: "32px" }}>
          <Flex marginRight={"8px"} marginTop={"6px"}>
            <ElmToggle size={"small"} checked={isActive} />
          </Flex>
          <LicenseStatus
            className={getClassName({
              active: () => isActive
            })}
          >
            {isActive ? "Active" : "Expired"}
          </LicenseStatus>
          {isActive
            ? renderAdditionalInfo(
                `Expires ${dayjs(
                  props.componentEntitlement.latestTerm.endDate as string
                ).format(DATE_FORMAT)}`
              )
            : null}
          {renderAdditionalInfo(entitlementType)}
          {props.componentEntitlement?.currencyCount
            ? renderAdditionalInfo(
                `Tokens: ${props.componentEntitlement?.numCurrencyUsed || 0}/${
                  props.componentEntitlement?.currencyCount
                }`
              )
            : null}
        </div>
      );
    };
    const renderBox = () => {
      return (
        <Flex
          style={{
            maxHeight: `${attributeBoxHeight}px`,
            width: "100%",
            marginBottom: 20
          }}
        >
          <CollapsibleAttributeBox
            className="entitlement-attribute-box"
            items={this.getAttributesItems(props)}
            // topToggleText={"Show entitlement details"}
            // bottomToggleText={"Hide entitlement details"}
            bottomToggleText={""}
            topToggleText={""}
            renderHeaderRow={renderRowHeader}
            isCollapsedDefault={false}
          />
        </Flex>
      );
    };
    return renderBox();
  };
  public renderControlBox = () => {
    return (
      <div style={{ marginTop: "14px", marginBottom: "20px" }}>
        <ElmRadio.Group value={this.state.type} onChange={this.updateType}>
          {_.map(
            [
              {
                text: "Component Group",
                key: "componentGroup"
              },
              {
                text: "Components",
                key: "component"
              }
            ],
            op => {
              return <ElmRadio value={op.key}>{op.text}</ElmRadio>;
            }
          )}
        </ElmRadio.Group>
      </div>
    );
  };
  public renderLimitedTermOptions = (props: EntitlementQueryResponse) => {
    if (!props || !props.componentEntitlement?.latestTerm.endDate) {
      return null;
    }
    return (
      <Flex
        flexDirection="column"
        style={{ maxWidth: "200px", marginTop: "14px" }}
      >
        <ValueText>
          {
            getTermDetails(
              props.componentEntitlement.latestTerm.startDate as string
            ).date
          }
        </ValueText>
        <ValueLabel>Activation</ValueLabel>
        <ValueText>
          {
            getTermDetails(
              props.componentEntitlement.latestTerm.endDate as string
            ).date
          }
        </ValueText>
        <ValueLabel>Expiration</ValueLabel>
      </Flex>
    );
  };
  public renderLimitedUsageOptions = (props: EntitlementQueryResponse) => {
    if (!props) {
      return null;
    }
    const renderUsageOption = (payload: { key: string; label: string }) => {
      return props.componentEntitlement[payload.key] ? (
        <Flex
          flexDirection="column"
          style={{ maxWidth: "200px", marginTop: "14px" }}
        >
          <ValueText>{props.componentEntitlement[payload.key]}</ValueText>
          <ValueLabel>{payload.label}</ValueLabel>
        </Flex>
      ) : null;
    };
    return (
      <React.Fragment>
        {renderUsageOption({ key: "tokenCount", label: "Token Count" })}
        {renderUsageOption({ key: "currencyCount", label: "Currency Count" })}
        {renderUsageOption({ key: "sessionCount", label: "Session Count" })}
      </React.Fragment>
    );
  };
  public updateTermAndUsageType = (type: "usageType" | "termType") => (
    state: boolean
  ) => {
    this.setState({ [type]: state ? "limited" : "unlimited" });
  };
  public editModeSwitch = () => {
    this.setState({
      isLoading: !this.state.isLoading,
      isEditMode: !this.state.isEditMode,
      selections: [...this.selections]
    });
  };
  public renderRightSideHeader = () => {
    //when entitlementId is ommited, Add view is shown and edit/delete buttons should be hidden.
    if (this.state.isAddMode) return null;
    const showDeleteDialog = () => {
      this.props.openDialog("DeleteDialog", {
        entityType: "entitlement",
        name: "confirm",
        isSimpleConfirmation: true,
        onConfirm: this.deleteEntitlement
      });
    };

    return (
      <Flex>
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          permissions={"modify_assets"}
          icon="pen"
          iconPrefix="fas"
          label="Edit"
          disabled={this.state.isEditMode}
          onClick={() => this.editModeSwitch()}
        />
        <AttributeActionsDropdown
          permissions={["modify_assets"]}
          showDeleteDialog={showDeleteDialog}
          disabled={!this.props.result}
        />
      </Flex>
    );
  };
  public isUsageLimited = () => {
    return (
      _.get(this.props.result, "componentEntitlement.tokenCount") ||
      _.get(this.props.result, "componentEntitlement.currencyCount") ||
      _.get(this.props.result, "componentEntitlement.sessionCount")
    );
  };
  public getTabs = (res: any) => {
    if (res === null) return [];
    let ret = [];
    ret.push({
      title: "Components",
      Component: this.renderComponentsTab(res)
      // supScript: _.get(res, 'product.componentsCount'),
    });
    ret.push({
      title: "Usage",
      Component: this.renderUsageTab(res)
      // supScript: _.get(res, 'product.componentsCount'),
    });
    ret.push({
      title: "History",
      Component: this.renderHistoryTab(res)
      // supScript: _.get(res, 'product.componentsCount'),
    });
    return ret;
  };
  public renderComponentsTab = (res: any) => {
    if (!this.props.result) {
      return null;
    }
    return (
      <ElmTable
        data={
          this.props.result?.componentEntitlement?.componentGroup?.components
            ?.nodes ||
          this.props.result?.componentEntitlement?.components?.nodes
        }
        noNav
        hideColumnsWithHeaders={
          this.props.isVendor ? [] : ["Enable", "Actions"]
        }
        columns={[
          {
            Header: "Enable",
            accessor: "",
            sortKey: null,
            disableSortBy: true,
            width: 0.08,
            cellRenderer: () => (
              <Flex onClick={e => e.stopPropagation()}>
                <ElmToggle
                  size="small"
                  // onChange={handleChange}
                  checked={true}
                  permissions={"modify_assets"}
                />
              </Flex>
            )
          },
          {
            Header: "Name",
            accessor: "name",
            sortKey: null,
            disableSortBy: true
          },
          {
            Header: "Group Name",
            accessor: "componentGroup.name",
            sortKey: null,
            disableSortBy: true
          },
          {
            Header: "License Model",
            accessor: "componentType",
            sortKey: null,
            disableSortBy: true
          },
          {
            Header: "Actions",
            accessor: "",
            sortKey: null,
            disableSortBy: true,
            width: 0.08,
            cellRenderer: () => (
              <ElmButton
                style={{
                  color: this.props.theme.colors.warmGrey
                }}
                label=""
                colorVariance="subtle"
                className="darkModeTransparentBtn"
                variance={"icon-button"}
                icon="trash-can"
                iconPrefix="far"
              />
            )
          }
        ]}
      />
    );
  };
  public renderUsageTab = (res: any) => {
    return null;
    if (!this.props.result) {
      return null;
    }
    return (
      <ElmTable
        data={this.props.result?.componentEntitlement?.terms?.nodes}
        noNav
        columns={[
          {
            Header: "Activation",
            accessor: "startDate",
            isDateFormat: true,
            sortKey: null,
            disableSortBy: true
          },
          {
            Header: "Expiration",
            accessor: "endDate",
            isDateFormat: true,
            sortKey: null,
            disableSortBy: true
          },
          {
            Header: "Created by",
            accessor: "",
            sortKey: null,
            disableSortBy: true
          }
        ]}
      />
    );
  };
  public renderHistoryTab = (res: any) => {
    if (!this.props.result) {
      return null;
    }
    return (
      <ElmTable
        data={this.props.result?.componentEntitlement?.terms?.nodes}
        noNav
        columns={[
          {
            Header: "Activation",
            accessor: "startDate",
            isDateFormat: true,
            sortKey: null,
            disableSortBy: true
          },
          {
            Header: "Expiration",
            accessor: "endDate",
            isDateFormat: true,
            sortKey: null,
            disableSortBy: true
          },
          {
            Header: "Created by",
            accessor: "",
            sortKey: null,
            disableSortBy: true
          }
        ]}
      />
    );
  };
  // public updateActiveTab = (activeTabIndex: number) => {
  //   updateBladeUrlState(this.props, activeTabIndex);
  //   this.setState({
  //     activeTabIndex
  //     // analytics: this.getAnalyticsQuery(activeTabIndex),
  //     // analyticsType: this.getAnalyticsType(activeTabIndex)
  //   });
  // };
  public renderBody = () => {
    return (
      <React.Fragment>
        {this.renderAttributesBox(this.props.result)}
        <div
          style={{
            paddingLeft: "30px",
            paddingRight: "34px",
            display: "flex",
            overflowY: "scroll",
            flexDirection: "column"
          }}
        >
          <div style={{ display: "flex", flex: 1 }}>
            <AutoSizer defaultHeight={300}>
              {({ height, width }) => (
                <TabView
                  showBottomBorder={true}
                  leftAlign={true}
                  tabList={this.getTabs(this.props.result)}
                  defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
                  // onUpdateActiveTab={this.updateActiveTab}
                  orientation={"vertical"}
                  style={{
                    maxHeight: height,
                    minHeight: height,
                    maxWidth: width,
                    minWidth: width
                  }}
                />
              )}
            </AutoSizer>
          </div>

          {/* <SectionTitle style={{ marginTop: "14px" }}>Components</SectionTitle>
          {this.state.isAddMode || this.state.isEditMode ? (
            <SearchBar
              style={{ minHeight: "30px" }}
              onSearch={this.updateQuery}
            />
          ) : null} */}
          {/* {this.renderSelection()} */}
          <Flex flexDirection="row">
            <Flex flexDirection="column" flex="1" style={{ marginTop: "30px" }}>
              <SectionTitle>Term</SectionTitle>
              <div style={{ width: "100px" }}>
                <ElmToggle
                  // onChange={this.updateTermAndUsageType('termType')}
                  rightLabel="Limited"
                  leftLabel="Unlimited"
                  size="small"
                  checked={_.get(
                    this.props.result,
                    "componentEntitlement.latestTerm.endDate"
                  )}
                  disabled={true}
                />
              </div>
              {this.renderLimitedTermOptions(this.props.result)}
            </Flex>
            <Flex flexDirection="column" flex="1" style={{ marginTop: "30px" }}>
              <SectionTitle>Usage</SectionTitle>
              <div style={{ width: "100px" }}>
                <ElmToggle
                  // onChange={this.updateTermAndUsageType('usageType')}
                  disabled={true}
                  size="small"
                  checked={this.isUsageLimited()}
                  rightLabel="Limited"
                  leftLabel="Unlimited"
                />
              </div>
              {this.renderLimitedUsageOptions(this.props.result)}
            </Flex>
          </Flex>
        </div>
      </React.Fragment>
    );
  };

  public getDataForEntitlement = (typeOfAction: "Edit" | "Create") => {
    const dataToSend = {
      entitlement: {
        allow_unlimited_usage: this.state.usageType === "unlimited",
        start_date:
          this.state.termType === "unlimited"
            ? dayjs().toISOString()
            : this.state.entitlement.start_date,
        end_date:
          this.state.termType === "unlimited"
            ? null
            : _.get(this.state, "entitlement.end_date", dayjs().toISOString()),
        component_group_id:
          this.state.type === "componentGroup"
            ? _.get(this.state, "selectedGroupId", null)
            : null,
        component_ids:
          this.state.type === "component"
            ? _.map(this.state.selections, s => s.id)
            : null,
        overage_allowed_count: 0,
        overage_allowed_percentage: 0
      }
    };

    if (this.state.entitlementModelType === "session") {
      dataToSend.entitlement["session_count"] = _.get(this.state, "count", 0);
    }
    if (this.state.entitlementModelType === "consumable") {
      dataToSend.entitlement["currency_count"] =
        this.state.usageType === "unlimited"
          ? null
          : _.get(this.state, "count", 0);
    }
    if (this.state.entitlementModelType === "checked_out") {
      dataToSend.entitlement["token_count"] =
        this.state.usageType === "unlimited"
          ? null
          : _.get(this.state, "count", 0);
    }

    if (typeOfAction === "Create") {
      dataToSend["license_id"] = this.props.routeData.licenseId;
      dataToSend.entitlement["license_id"] = this.props.routeData.licenseId;

      return dataToSend;
    }
    if (typeOfAction === "Edit") {
      dataToSend.entitlement["id"] = this.props.routeData.entitlementId;
      return dataToSend;
    }
  };

  public editEntitlement = () => {
    const dataToSend = this.getDataForEntitlement("Edit") as IDataToEdit;
    this.setState(
      {
        disabled: true,
        error: false
      },
      () => {
        if (this.props.routeData.entitlementId) {
          //edit / update data
          this.props.gateway.request
            .editEntitlement(dataToSend, {
              id: this.props.routeData.entitlementId
            })
            .then(res => {
              if (res.error) {
                this.setState({ disabled: false }, () => {
                  this.props.openDialog("ErrorDialog", {
                    description: res.data?.error || res.data?.errors[0]
                  });
                  console.error(res.error);
                });
              } else {
                this.props.refreshAllOpenBlades();
                this.setState({ isEditMode: false });
                //this.props.closeBlade({ fromBladeIndex: this.props.index });
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      }
    );
  };

  public createNewEntitlement = () => {
    const dataToSend = this.getDataForEntitlement("Create") as IDataToCreate;

    this.setState(
      {
        disabled: true,
        error: false
      },
      () => {
        this.props.gateway.request
          .newComponentEntitlement(dataToSend)
          .then(res => {
            if (res.error) {
              this.setState({ disabled: false }, () => {
                this.props.openDialog("ErrorDialog", {
                  description:
                    res?.data?.error || _.get(res, "data.errors[0]", "")
                });
                console.error(res.error);
              });
            } else {
              this.props.refreshAllOpenBlades();
              this.props.closeBlade({ fromBladeIndex: this.props.index });
            }
          })
          .catch(err => {
            console.error(err);
          });
      }
    );
  };

  public renderLimitedUsageOptionsEdit = () => {
    if (this.state.usageType === "unlimited") {
      return null;
    }
    return (
      <Flex
        flexDirection="column"
        style={{ maxWidth: "290px", marginTop: "14px" }}
      >
        <Flex flexDirection={"column"}>
          <ElmInput
            type={"number"}
            style={{ maxHeight: "32px", marginBottom: "17px" }}
            updatedValue={this.updateCount}
            label={"Count"}
            controlled
            defaultValue={this.state.count}
          />

          {this.state.entitlementModelType === "consumable" && (
            <Flex flexDirection={"column"}>
              <Flex style={{ marginBottom: "24px" }} flex="1">
                <Radio.Group
                  style={{ width: "100%" }}
                  value={this.state.renew}
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      renew: e.target.value
                    });
                  }}
                >
                  <Radio value={"one_time"}>One time</Radio>
                  <Radio value={"renew"}>Renew</Radio>
                </Radio.Group>
              </Flex>
              <Flex style={{ width: "290px" }}>
                {this.state.renew === "renew" && (
                  <>
                    <ElmInput
                      type={"number"}
                      controlled
                      defaultValue={this.state.renewEvery}
                      style={{
                        maxHeight: "32px",
                        marginBottom: "17px",
                        marginRight: "8px"
                      }}
                      updatedValue={value => this.updateRenewEvery(+value)}
                      label={"Renew every"}
                    />
                    <ElmSelectWithLabel
                      onChange={this.updateRenewType}
                      //style={{ width: "100px" }}
                      className="subtle"
                      label=" "
                      value={this.state.renewType}
                    >
                      {_.map(
                        [
                          {
                            label: "Day",
                            key: "day"
                          },
                          {
                            label: "Week",
                            key: "week"
                          },
                          {
                            label: "Month",
                            key: "month"
                          },
                          {
                            label: "Year",
                            key: "year"
                          }
                        ],
                        op => (
                          <ElmSelectOption value={op.key} label={op.label}>
                            {op.label}
                          </ElmSelectOption>
                        )
                      )}
                    </ElmSelectWithLabel>
                  </>
                )}
              </Flex>
              <Flex style={{ width: "290px" }}>
                <ElmInput
                  type={"number"}
                  label={"Overage"}
                  isDecimalNumber={true}
                  style={{ marginRight: "8px" }}
                  updatedValue={this.updateRequest(
                    this.state.overageType === "count"
                      ? "overage_allowed_count"
                      : "overage_allowed_percentage"
                  )}
                />
                <ElmSelectWithLabel
                  onChange={this.updateOverageType}
                  //style={{ width: "100px" }}
                  className="subtle"
                  label=" "
                  value={this.state.overageType}
                >
                  {_.map(
                    [
                      {
                        label: "Count",
                        key: "count"
                      },
                      {
                        label: "%",
                        key: "%"
                      }
                    ],
                    op => (
                      <ElmSelectOption value={op.key} label={op.label}>
                        {op.label}
                      </ElmSelectOption>
                    )
                  )}
                </ElmSelectWithLabel>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  };

  public onTermDateChange = (type: "start_date" | "end_date") => (m: Dayjs) => {
    this.setState({
      entitlement: {
        ...(this.state?.entitlement || {}),
        [type]: m.toISOString()
      }
    });
  };

  public renderLimitedTermOptionsEdit = () => {
    if (this.state.termType === "unlimited") {
      return null;
    }

    return (
      <Flex style={{ marginTop: "14px" }} flexDirection="column">
        <Flex style={{ marginBottom: "24px" }}>
          <Radio.Group
            value={this.state.startType}
            onChange={e => {
              this.setState({
                ...this.state,
                startType: e.target.value
              });
            }}
          >
            <Radio value={"immediately"}>Immediately</Radio>
            <Radio value={"specific_date"}>Specific date</Radio>
          </Radio.Group>
        </Flex>
        {this.state.startType === "specific_date" && (
          <Flex marginBottom={12}>
            <ElmDatePickerWithLabel
              label="Start date"
              style={{ width: "290px" }}
              format={DATE_FORMAT}
              onChange={this.onTermDateChange("start_date")}
              defaultValue={
                this.state?.entitlement?.start_date
                  ? dayjs(this.state.entitlement.start_date)
                  : dayjs()
              }
            />
          </Flex>
        )}
        <Flex>
          <ElmDatePickerWithLabel
            label="End date"
            defaultValue={
              this.state?.entitlement?.end_date
                ? dayjs(this.state.entitlement.end_date)
                : dayjs()
            }
            style={{ width: "290px" }}
            format={DATE_FORMAT}
            onChange={this.onTermDateChange("end_date")}
          />
        </Flex>
      </Flex>
    );
  };

  public renderEditElements = () => {
    const InfoIcon = styled(getIcon("AiFillInfoCircle"))`
      width: 14px;
      height: 14px;
      margin-right: 7px;
      margin-top: -10px;
      color: ${props => props.theme.colors.buttonIcon};
    `;
    return (
      <Flex flexDirection={"column"} flex={1} style={{ overflow: "scroll " }}>
        <BladeDescription>
          {this.state.isAddMode
            ? `Select component group or individual components of similar licensing model to create the entitlement.`
            : `Edit component group or individual components of similar licensing
          model for this entitlement`}
        </BladeDescription>
        {this.renderControlBox()}
        <SearchBar
          className="elm-table-search-bar"
          onSearch={this.updateQuery}
        />
        {this.state.type === "component"
          ? this.renderComponentTypeFilters()
          : null}
        {this.renderSelection()}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginTop: "24px"
          }}
        >
          <Flex flexDirection="column" flex="1">
            <SectionTitle>
              Term
              <Tooltip
                placement="right"
                color={this.props.theme.colors.tooltipBackground}
                title={() => {
                  return (
                    <Flex flexDirection={"column"}>
                      <Flex
                        flexDirection={"column"}
                        style={{ color: this.props.theme.colors.textPrimary }}
                      >
                        <h3>Term</h3>
                        <p>Term description.</p>
                      </Flex>
                    </Flex>
                  );
                }}
              >
                <InfoIcon />
              </Tooltip>
            </SectionTitle>
            <div style={{ width: "100px" }}>
              <ElmToggle
                style={{ fontSize: "13px", color: "red" }}
                size="small"
                onChange={this.updateTermAndUsageType("termType")}
                rightLabel="Limited"
                leftLabel="Unlimited"
                checked={this.state.termType === "limited"}
              />
            </div>
            {this.renderLimitedTermOptionsEdit()}
          </Flex>
          <Flex flexDirection="column" flex="1" marginTop={24}>
            <SectionTitle>
              Usage
              <Tooltip
                placement="right"
                color={this.props.theme.colors.tooltipBackground}
                title={() => {
                  return (
                    <Flex flexDirection={"column"}>
                      <Flex
                        flexDirection={"column"}
                        style={{ color: this.props.theme.colors.textPrimary }}
                      >
                        <h3>Unlimited usage</h3>
                        <p>
                          With unlimited usage, users do not have to be worried
                          about the token count for accessing any component of
                          this entitlement.
                        </p>
                      </Flex>
                      <Flex
                        flexDirection={"column"}
                        style={{ color: this.props.theme.colors.textPrimary }}
                      >
                        <h3>Limited usage</h3>
                        <p>
                          With limited usage, users can use any component as
                          long as they have tokens left to use.
                        </p>
                      </Flex>
                    </Flex>
                  );
                }}
              >
                <InfoIcon />
              </Tooltip>
            </SectionTitle>
            <div style={{ width: "100px" }}>
              <ElmToggle
                style={{ fontSize: "13px", width: "100px" }}
                size="small"
                checked={this.state.usageType === "limited"}
                onChange={this.updateTermAndUsageType("usageType")}
                rightLabel="Limited"
                leftLabel="Unlimited"
              />
            </div>

            {this.renderLimitedUsageOptionsEdit()}
          </Flex>
        </div>

        <Flex
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "24px"
          }}
        >
          {!this.state.isAddMode && (
            <ElmButton
              label="Cancel"
              colorVariance="outline-secondary"
              onClick={this.editModeSwitch}
            />
          )}
          <ElmButton
            label={this.state.isAddMode ? "Create" : "Save changes"}
            colorVariance="primary"
            onClick={
              this.state.isAddMode
                ? this.createNewEntitlement
                : this.editEntitlement
            }
          />
        </Flex>
      </Flex>
    );
  };

  public renderEditModeBody = () => {
    return (
      <div
        style={{
          paddingLeft: "30px",
          paddingRight: "34px",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll"
        }}
      >
        {this.renderEditElements()}
      </div>
    );
  };
  public getAnalyticsQuery = () => {
    const startTime = moment();
    return componentEntitlementCheckouts(
      this.props.routeData?.licenseId,
      startTime,
      false,
      "components.name"
    );
  };
  public getAnalyticsCarouselProps = () => {
    return {
      analyticsSlides: [this.getAnalyticsQuery()],
      currentIndex: 0
    };
  };
  public render() {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        accentColor={"mango"}
        title={"Entitlement"}
        bladeType={"Entitlement"}
        closeBlade={this.props.closeBlade}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
      >
        <Flex
          style={{
            maxHeight: "159",
            marginBottom: "5px",
            width: "100%"
          }}
        >
          {this.renderAttributesBox(this.props.result)}
        </Flex>
        <Flex
          style={{
            // paddingLeft: "21px",
            // paddingRight: "21px",
            flexDirection: "column",
            flex: 1
          }}
        >
          {this.state.isEditMode ? (
            this.renderEditModeBody()
          ) : (
            <>
              <Flex
                style={{
                  paddingLeft: "21px",
                  paddingRight: "21px",
                  flexDirection: "column",
                  flex: 1
                }}
              >
                {/* <AnalyticsCarousel {...this.getAnalyticsCarouselProps()} /> */}
                <div style={{ display: "flex", flex: 1 }}>
                  <AutoSizer defaultHeight={300}>
                    {({ height, width }) => (
                      <TabView
                        showBottomBorder={true}
                        leftAlign={true}
                        tabList={this.getTabs(this.props.result)}
                        defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
                        // onUpdateActiveTab={this.updateActiveTab}
                        orientation={"vertical"}
                        style={{
                          maxHeight: height,
                          minHeight: height,
                          maxWidth: width,
                          minWidth: width
                        }}
                      />
                    )}
                  </AutoSizer>
                </div>
              </Flex>
            </>
          )}
        </Flex>
      </BladeTemplate>
    );
  }
}
const RenderQuery = (props: IEntitlementBladeProps) => {
  const theme = useTheme();
  const renderBlade = (payload: {
    error: Error;
    props: EntitlementQueryResponse;
    retry: () => void;
  }) => {
    if (!payload.props)
      return (
        <BladeTemplate
          bladeIndex={2}
          renderLeftSideHeader={() => null}
          renderRightSideHeader={() => null}
          hideBladeTypeName={true}
          title={"Account"}
          closeBlade={() => null}
          setRefreshFn={() => null}
          refreshFn={() => null}
          bladeType="Entitlement"
          loading={true}
        ></BladeTemplate>
      );
    return (
      <EntitlementBlade
        {...props}
        result={payload.props}
        theme={theme}
        {..._.omit(payload, "props")}
      />
    );
  };
  return (
    <QueryRenderer<EntitlementQuery>
      variables={{
        productId: _.get(props.routeData, "productId"),
        entitlementId: _.get(props.routeData, "entitlementId") || "-1",
        skip: _.get(props.routeData, "entitlementId") ? false : true
      }}
      environment={relayEnvironment}
      query={groupSelectionQuery}
      render={renderBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
