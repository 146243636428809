import React from "react";
import { useTheme } from "styled-components";
import { MenuProps } from "antd";
import { Dropdown } from "antd";
import { ElmButton } from "components/elmButton";
import { useExportButton, tableType } from "components/elmTable/exportButton";

const BladePrimaryActionsDropdown = (props: {
  analyticsAction: () => void;
  export: tableType;
}) => {
  const theme = useTheme();
  const btnStyleOverride = {
    color: theme.colors.black,
    padding: 3,
    paddingLeft: 0,
    margin: 0
  };

  const { renderExportButton, setExportRunning } = useExportButton({
    tableType: props.export,
    style: btnStyleOverride
  });

  let key = 0;

  const items: MenuProps["items"] = [
    {
      key: key++,
      onClick: props.analyticsAction,
      label: (
        <ElmButton
          style={btnStyleOverride}
          className={"darkModeTransparentBtn"}
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon={"chart-mixed"}
          iconPrefix={"far"}
          label={"Analytics"}
        />
      )
    },
    {
      key: key++,
      onClick: () => setExportRunning(true),
      label: renderExportButton()
    }
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <ElmButton
        style={{
          ...btnStyleOverride,
          paddingLeft: 7,
          marginLeft: 4,
          background: theme.colors.highlight
        }}
        variance={"plain-icon-button"}
        colorVariance={"subtle"}
        icon={"ellipsis"}
        iconPrefix={"fas"}
        label={""}
      />
    </Dropdown>
  );
};

export default BladePrimaryActionsDropdown;
