import { Collapse } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import { Flex } from "reflexbox/styled-components";
import * as React from "react";
import relayEnvironment from "../../../api/relay";
import styled, { useTheme } from "styled-components";
import { QueryRenderer } from "react-relay";
import {
  featuresAndGroupsQuery,
  featuresAndGroupsQueryResponse
} from "./__generated__/featuresAndGroupsQuery.graphql";
import Loader from "react-loaders";
import Fuse from "fuse.js";
import { ElmToggle } from "../../../components/elmToggle";
import { SearchBar } from "../../../components/helpers";

export interface IFeaturesAndGroupsProps {
  license: featuresAndGroupsQueryResponse["license"];
  retry: () => void;
  onChange?: (payload: {
    type: "feature" | "group";
    id: string;
    name: string;
    value: boolean;
    updateQuery: () => void;
  }) => void;
}
const StyledCollapse = styled(Collapse)`
  background-color: transparent;
  margin-bottom: 6px;
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
  }
  .ant-collapse-item {
    border: none;
    color: ${props => props.theme.colors.textPrimary};
  }
  .header-text {
    min-width: 291px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
  }
`;
const FeatureTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  margin-left: 16px;
`;
const FeatureLabel = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 16.5px;
`;
const Heading = styled.p`
  height: 12px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
`;
const HeadingContainer = styled.div`
  width: 154.5px;
  height: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  padding-bottom: 6.7px;
  margin-bottom: 6.3px;
`;
const ToggleContainer = styled.div`
  margin-right: 10px;
`;
type featureGroups = featuresAndGroupsQueryResponse["license"]["product"]["featureGroups"]["nodes"];
type features = featuresAndGroupsQueryResponse["license"]["product"]["features"]["nodes"];
type featureGroupType = featureGroups[number];
type featureType = features[number];

const { Panel } = Collapse;
const StyledLoader = styled(Loader)`
  .loader-inner {
    div {
      border-color: ${props => props.theme.colors.lightishBlue};
      width: 15px;
      height: 15px;
    }
  }
`;

const ToggleRow = (props: {
  row: featureGroupType | featureType;
  type: "group" | "feature";
  isChecked: (payload: { id: string; type: "feature" | "group" }) => boolean;
  reportChange: (
    type: "group" | "feature",
    id: string,
    name: string
  ) => (value: boolean) => void;
}) => {
  const isChecked = props.isChecked({
    id: props.row.id,
    type: props.type
  });
  const [requestState, updateRequestState] = React.useState({
    requesting: false,
    toggleState: isChecked
  });
  const handleChange = (value: boolean) => {
    updateRequestState({ requesting: true, toggleState: value });
    props.reportChange(props.type, props.row.id, props.row.name)(value);
  };
  const renderLoader = () => {
    if (requestState.requesting && isChecked !== requestState.toggleState) {
      return (
        <StyledLoader type="ball-clip-rotate" active={true} color={"#0F62FE"} />
      );
    }
    return null;
  };
  if (props.type === "feature") {
    // const handleToggleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    //   e.stopPropagation();
    //   e.preventDefault();
    // };
    return (
      <Flex height="31px">
        <FeatureTitle style={{ minWidth: "310px" }}>
          {props.row.name}
        </FeatureTitle>
        <Flex lineHeight="24px">
          <ElmToggle
            size={"small"}
            checked={isChecked}
            onChange={handleChange}
          />
          {renderLoader()}
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex height="31px">
      <span className={"header-text"}>{props.row.name}</span>
      <ElmToggle size={"small"} checked={isChecked} onChange={handleChange} />
      {renderLoader()}
    </Flex>
  );
};
export class FeaturesAndGroups extends React.Component<
  IFeaturesAndGroupsProps & { theme: any },
  { search_term: string }
> {
  state: { search_term: string } = {
    search_term: ""
  };
  public reportChange = (
    type: "group" | "feature",
    id: string,
    name: string
  ) => (value: boolean) => {
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange({
        type,
        id,
        name,
        value,
        updateQuery: this.props.retry
      });
    }
  };
  public isChecked = (payload: { id: string; type: "feature" | "group" }) => {
    const type = payload.type === "feature" ? "features" : "featureGroups";
    const filtered = _.filter(
      this.props.license[type].nodes,
      n => n.id === payload.id
    );

    return !!filtered.length;
  };
  public isFeatureDisabled = (id: string) => {
    let isDisabled = false;
    _.map(this.props.license.featureGroups.nodes, n => {
      _.each(n.features.nodes, f => {
        if (f.id === id) {
          isDisabled = true;
        }
      });
    });

    return isDisabled;
  };
  public renderFeatures = () => {
    const featuresWithoutGroups = _.map(
      this.getFeaturesWithoutGroups(),
      (feature: featureType) => (
        <ToggleRow
          isChecked={this.isChecked}
          reportChange={this.reportChange}
          row={feature}
          type={"feature"}
        />
      )
    );
    return (
      <Flex
        style={{
          flexDirection: "column",
          flex: 1,
          minHeight: "max-content",
          color: this.props.theme.colors.textPrimary
        }}
      >
        <FeatureLabel>Other features available in this product.</FeatureLabel>
        <div
          style={{
            minHeight: "max-content",
            width: "100%",
            position: "relative",
            maxHeight: "100%"
          }}
        >
          {featuresWithoutGroups}
        </div>
      </Flex>
    );
  };
  public renderFeatureGroups = () => {
    let groupNodes = _.get(this.props.license, "product.featureGroups.nodes");
    if (this.state.search_term) {
      const results = new Fuse(
        _.get(this.props.license, "product.featureGroups.nodes"),
        { keys: ["name"] }
      ).search(this.state.search_term);
      groupNodes = _.map(results, i => i.item);
    }
    return (
      <StyledCollapse bordered={false} accordion={true}>
        {_.map(groupNodes, (featureGroup: featureGroupType) => {
          return (
            <Panel
              header={
                <ToggleRow
                  isChecked={this.isChecked}
                  reportChange={this.reportChange}
                  row={featureGroup}
                  type={"group"}
                />
              }
              key={featureGroup.id}
              className={"featureGroup"}
            >
              <div
                style={{
                  paddingLeft: "23px",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                {_.map(featureGroup.features.nodes, feature => {
                  return <span>{feature.name}</span>;
                })}
              </div>
            </Panel>
          );
        })}
      </StyledCollapse>
    );
  };
  public getFeaturesWithoutGroups = () => {
    // const productFeatureGroups = _.get(
    //   this.props.license,
    //   'product.featureGroups.nodes'
    // ) as featureGroups;
    const productFeatures = _.get(
      this.props.license,
      "product.features.nodes"
    ) as features;
    let featuresWithoutGroups = _.filter(
      productFeatures,
      feature => !feature.featureGroupsCount
    );
    if (this.state.search_term) {
      const results = new Fuse(featuresWithoutGroups, {
        keys: ["name"]
      }).search(this.state.search_term);
      featuresWithoutGroups = _.map(results, i => i.item);
    }
    return featuresWithoutGroups;
  };
  public updateSearchTerm = (search_term: string) => {
    this.setState({ search_term });
  };
  public render() {
    return (
      <Flex
        flexDirection={"column"}
        flex={1}
        style={{ overflowY: "scroll", maxHeight: "100%" }}
      >
        <SearchBar
          className="global-search-bar"
          onSearch={this.updateSearchTerm}
          style={{ marginBottom: "10px" }}
        />
        {this.renderFeatureGroups()}
        {this.renderFeatures()}
      </Flex>
    );
  }
}

const graphqlQuery = graphql`
  query featuresAndGroupsQuery($id: ID!) {
    license(id: $id) {
      features {
        nodes {
          id
          name
        }
      }
      featureGroups {
        nodes {
          id
          name
          features {
            nodes {
              id
              name
            }
          }
        }
      }
      product {
        features {
          nodes {
            id
            name
            featureGroupsCount
          }
        }
        featureGroups {
          nodes {
            id
            name
            features {
              nodes {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
const RenderQueryRenderer = (props: {
  id: string;
  theme: any;
  onChange?: (payload: {
    type: "feature" | "group";
    id: string;
    name: string;
    value: boolean;
    updateQuery: () => void;
  }) => void;
}) => {
  const renderFeatures = (payload: {
    props: featuresAndGroupsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <FeaturesAndGroups
        license={_.get(payload.props, "license")}
        onChange={props.onChange}
        retry={payload.retry}
        theme={props.theme}
      />
    );
  };
  return (
    <QueryRenderer<featuresAndGroupsQuery>
      variables={{ id: props.id }}
      query={graphqlQuery}
      render={renderFeatures}
      environment={relayEnvironment}
    />
  );
};

export default function RenderQueryRendererComponent(props) {
  const theme = useTheme();

  return <RenderQueryRenderer {...props} theme={theme} />;
}
