import React, { useEffect, useState } from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import ElmCheckBox from "components/elmCheckBox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ElmSelectOption, ElmSelectWithLabel } from "components/elmSelect";
import { formatInlineDisplayValues } from "Reports/common/utils";
import { appConnect } from "store/appConnect";
import { ColumnsConfig } from "Reports/common/const";
import {
  MaximumUsageReportDataType,
  REPORT_TYPE,
  ReportType
} from "Reports/common/types";
import { getLabelFromKey } from "Reports/blades/Report";

interface IColumnSelectProps {
  reportType: ReportType;
  isRawData: boolean;
  handleSelect: (columns: string[]) => void;
  defaultValue?: string[];
}

const SelectionContainer = styled(Flex)`
  color: ${props => props.theme.colors.textPrimary};
`;

const ColumnSelect: React.FC<IColumnSelectProps> = ({
  isRawData,
  reportType,
  handleSelect,
  defaultValue
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<{ id: string; name: string }[]>(
    defaultValue?.map(option => ({
      id: option,
      name: getLabelFromKey({ key: option })
    })) || []
  );
  const [selection, setSelection] = useState<string[]>(defaultValue || []);

  const toggleSelect = (state: boolean) => {
    setOpen(state);
  };

  const handleSelection = (payload: string[]) => {
    setSelection(payload);
    handleSelect(payload);
  };

  useEffect(() => {
    const newOptions = ColumnsConfig[reportType]?.columns?.map(option => ({
      id: option,
      name: getLabelFromKey({ key: option })
    }));
    // eslint-disable-next-line eqeqeq
    if (reportType == REPORT_TYPE.MaximumUsageReport) {
      ColumnsConfig[REPORT_TYPE.MaximumUsageReport]?.additionalColumns[
        isRawData
          ? MaximumUsageReportDataType.RawData
          : MaximumUsageReportDataType.Consolidated
      ].forEach(key => {
        newOptions.push({
          id: key,
          name: getLabelFromKey({ key })
        });
      });
    }
    setOptions(newOptions);
    setSelection(defaultValue);
  }, [isRawData, reportType, defaultValue]);

  const updateSelection = (payload: {
    id: string;
    name: string;
    checked: boolean;
  }) => {
    if (payload.checked) {
      const newSelection = [...selection, payload.id];
      handleSelection(newSelection);
    } else {
      const filtered = options.filter(col => {
        if (col.id !== payload.id) return true;
        return false;
      });
      handleSelection(filtered.map(({ id }) => id));
    }
  };
  const captureSelectValue = (id: string) => {
    const exists = selection.find(option => option === id);
    if (exists) {
      const newColumns = [...selection].filter(item => item !== exists);
      handleSelection(newColumns);
      return;
    }
    const target = options.find(option => option.id === id)?.id;
    if (target) {
      handleSelection([...selection, target]);
    }
  };
  const handleChange = (selection: { id: string; name: string }) => (
    e: CheckboxChangeEvent
  ) => {
    updateSelection({
      checked: e.target.checked,
      ...selection
    });
  };
  return (
    <SelectionContainer onClick={e => e.preventDefault()}>
      <Flex style={{ margin: 12 }} flexDirection="column" flex={1}>
        <ElmSelectWithLabel
          label="Include in report"
          placeholder="Columns"
          onClick={() => toggleSelect(true)}
          onBlur={() => toggleSelect(false)}
          onSelect={e => captureSelectValue(e)}
          open={open}
          value={
            selection?.length > 0
              ? formatInlineDisplayValues(
                  selection.map(item => getLabelFromKey({ key: item }))
                )
              : null
          }
          defaultValue={
            selection?.length > 0
              ? formatInlineDisplayValues(
                  selection.map(item => getLabelFromKey({ key: item }))
                )
              : null
          }
        >
          {options?.map(node => {
            return (
              <ElmSelectOption key={node.id} value={node.id} label={node.name}>
                <ElmCheckBox
                  onChange={handleChange(node)}
                  checked={
                    selection.find(item => item === node.id) ? true : false
                  }
                >
                  {node.name}
                </ElmCheckBox>
              </ElmSelectOption>
            );
          })}
        </ElmSelectWithLabel>
      </Flex>
    </SelectionContainer>
  );
};
export default appConnect(ColumnSelect, {
  selectors: {}
});
