import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox";
import relayEnvironment from "../../api/relay";
import { appConnect } from "../../store/appConnect";
import { WidgetContainer } from "../../components/analyticsComponent/widgetContainer";
import {
  companyAnalyticsQuery,
  companyAnalyticsQueryResponse
} from "./__generated__/companyAnalyticsQuery.graphql";
import _ from "lodash";
import { getAnimatedComponent } from "../../components/helpers";
import { getFriendlyLicenseName } from "../../utils";
import {
  TopCountFilter,
  topCounts
} from "../../components/analyticsComponent/filters/topCount";
import { TabbedAnalyticsComponent } from "../../components/analyticsComponent/tabbedAnalytics";
import {
  AnalyticsComponentProps,
  queryType
} from "../../components/analyticsComponent";
import { Tabs } from "antd";
import LoadingWrapper from "../../components/elmChart/loadingWrapper";
import { BarChartLogo, LineChart } from "../../components/elmChart";
import HorizontalBarChart from "../../components/elmChart/horizontalBarChart";
import { sharedTabStyle } from "./shared";
import {
  AllowedChartBladeTypes,
  IBladeBaseProps,
  IOpenBladeFromChart
} from "components/bladeManager/types";
import { DateRange } from "blades/Dashboard/utils";

const AnimatedContainer = getAnimatedComponent({
  element: "div",
  transitionName: "license-banner",
  animations: {
    appear: {
      duration: "1.5s",
      animationType: "slideInRight"
    },
    enter: {
      duration: "1s",
      animationType: "slideInRight"
    }
  }
});

const graphqlQuery = graphql`
  query companiesAnalyticsQuery(
    $currentTimeSpanStart: String!
    $currentTimeSpanEnd: String!
    $productId: String!
  ) {
    LicenseAnalyticsCurrentExpiringLicenses: licenses(
      filterColumn: ["latestTerm.endDate", "latestTerm.endDate"]
      filterOp: [[">", "="], ["<", "="]]
      filterValues: [
        [$currentTimeSpanStart, $currentTimeSpanStart]
        [$currentTimeSpanEnd, $currentTimeSpanEnd]
      ]
      bladeScope: $productId
    ) {
      nodes {
        type {
          name
          id
        }
      }
    }
  }
`;

export interface ICompanyAnalyticsProps extends IBladeBaseProps {
  result: companyAnalyticsQueryResponse;
  retry: () => void;
  timespan: "week" | "month" | "day";
  licenseId: string;
  variables: {
    timeRangeOverride?: DateRange;
    currentTimeSpanStart: string;
    currentTimeSpanEnd: string;
    previousTimeSpanStart: string;
    previousTimeSpanEnd: string;
    productId: string | null;
  };
}

type state = {
  topUsersCount: number;
  topComponentsCount: number;
};
export class CompaniesAnalytics extends React.Component<
  ICompanyAnalyticsProps
> {
  state: state = {
    topComponentsCount: 5,
    topUsersCount: 5
  };
  public renderLicenseInfoBanner = () => {
    if (
      !this.props.result ||
      !this.props.result.LicenseAnalyticsCurrentExpiringLicenses
    ) {
      return null;
    }
    const getActiveLicensesByType = () => {
      if (
        !this.props.result ||
        !this.props.result.LicenseAnalyticsCurrentExpiringLicenses
      ) {
        return null;
      }
      const types = _.groupBy(
        this.props.result.LicenseAnalyticsCurrentExpiringLicenses.nodes,
        node => node.type.name
      );
      const typeNames = _.keys(types);
      return _.map(typeNames, typeName => {
        return (
          <div>
            <span className="expiring-value">{types[typeName].length}</span>
            <span className="expiring-label">
              {_.startCase(getFriendlyLicenseName(typeName))}
            </span>
          </div>
        );
      });
    };
    const navigateToLicenses = () => {
      // this.props.openBlade({
      //   route: 'Licenses',
      //   routeName: 'Licenses',
      //   routeData: null,
      //   fromBladeIndex: this.props.index,
      //   navigationType: 'reset',
      // });
    };
    return (
      <AnimatedContainer>
        <div className="banner" onClick={navigateToLicenses}>
          <div className="accent" />
          <div className="message-container">
            <span className="current-expiring-value">
              {/* {this.props.result.LicenseAnalyticsCurrentExpiringLicenses} */}
            </span>
            <span className="current-expiring-label">{`License${
              _.get(
                this.props,
                "result.LicenseAnalyticsCurrentExpiringLicenses.nodes",
                []
              ).length > 1
                ? "s"
                : ""
            } expiring this ${this.props.timespan}`}</span>
            <div className="extra-expiring-container">
              {getActiveLicensesByType()}
            </div>
          </div>
        </div>
      </AnimatedContainer>
    );
  };
  public openBladeFromChart = (payload: IOpenBladeFromChart) => {
    if (!payload?.id || !AllowedChartBladeTypes.includes(payload.bladeType)) {
      return;
    }
    this.props.openBlade({
      route: payload.bladeType,
      routeName: `${payload.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: payload.id
      }
    });
  };
  public getHighlightAnalytics = () => {
    return (
      <TabbedAnalyticsComponent
        style={{ height: "417px" }}
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session count",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              analytics: this.getSessionCountAnalyticsSetup(),
              type: "line",
              allowVendorAnalytics: true
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              analytics: this.getSessionDurationAnalyticsSetup(),
              type: "bar",
              allowVendorAnalytics: true
            }
          }
        ]}
      />
    );
  };
  public getSessionDurationAnalyticsSetup = () => {
    return {
      bladeScope: this.props.licenseId,
      valueTitle: "Session Duration",
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_duration",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd
    } as queryType;
  };
  public getSessionCountAnalyticsSetup = () => {
    //         <LineChart
    //           showProductFilter={false}
    //           hideTimeRangeFilter
    //           queryParams={{
    //             //bladeScope: this.props.productId,
    //             setup: {
    //               category: `time.${this.props.timespan}`,
    //               value: "sessions.usage_count",
    //               group: null
    //             },
    //             first: this.state.topUsersCount,
    //             startTime: this.props.variables.currentTimeSpanStart,
    //             endTime: this.props.variables.currentTimeSpanEnd
    //           }}
    //           plot={
    //             {
    //               //title: "Session Count",
    //             }
    //           }
    //         />
    return {
      bladeScope: null,
      valueTitle: "Session Count",
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_count",
        group: "products.name" //null
      },
      options: {
        //lineWidth: 25,
        chartMaxHeight: 300
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd
    } as queryType;
  };
  public getTabAnalytics = (payload: {
    category: string;
    firstTabType?: AnalyticsComponentProps["type"];
    secondTabType?: AnalyticsComponentProps["type"];
    first: number;
  }) => {
    return (
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session count",
              style: {
                height: "40px",
                display: "flex",
                alignItems: "center"
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              analytics: {
                bladeScope: this.props.licenseId,
                valueTitle: "Session Count",
                setup: {
                  category: payload.category,
                  value: "sessions.usage_count",
                  group: null
                },
                first: payload.first,
                sortColumn: "value",
                sortDirection: "DESC",
                startTime: this.props.variables.currentTimeSpanStart,
                endTime: this.props.variables.currentTimeSpanEnd
              },
              type: payload.firstTabType,
              allowVendorAnalytics: true
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: {
                height: "40px",
                display: "flex",
                alignItems: "center"
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              analytics: {
                bladeScope: this.props.licenseId,
                valueTitle: "Session Duration",
                setup: {
                  category: payload.category,
                  value: "sessions.usage_duration",
                  group: null
                },
                first: payload.first,
                sortColumn: "value",
                sortDirection: "DESC",
                startTime: this.props.variables.currentTimeSpanStart,
                endTime: this.props.variables.currentTimeSpanEnd
              },
              type: payload.secondTabType,
              allowVendorAnalytics: true
            }
          }
        ]}
      />
    );
  };
  public updateTopCount = (type: keyof state) => (count: topCounts) => {
    this.setState({ [type]: parseInt(count, 10) });
  };
  public render() {
    return (
      <div className="license-analytics analytics-container">
        {/* Query doesn't return the data correctly */}
        {/* {this.renderLicenseInfoBanner()} */}
        <WidgetContainer size="x-large">
          {this.getHighlightAnalytics()}
        </WidgetContainer>
        {/* <WidgetContainer size="medium-large">
          <Flex>
            <span className="widget-title">Top Users</span>
            <TopCountFilter onChange={this.updateTopCount("topUsersCount")} />
          </Flex>
          {this.getTabAnalytics({
            category: "users.name",
            firstTabType: "bar",
            secondTabType: "bar",
            first: this.state.topUsersCount
          })}
        </WidgetContainer> */}
        <WidgetContainer size="medium-large">
          <Flex>
            <span className="widget-title">For upgrade</span>
            <TopCountFilter onChange={this.updateTopCount("topUsersCount")} />
          </Flex>
          <div
          // style={{ height: "100%", width: "100%" }}
          >
            <LoadingWrapper>
              <HorizontalBarChart
                openBlade={this.openBladeFromChart}
                timeRangeOverride={this.props.variables?.timeRangeOverride}
                hideTimeRangeFilter
                queryParams={{
                  setup: {
                    category: "time.month",
                    value: "sessions.usage_count",
                    group: "users.name"
                  },
                  sortColumn: "value",
                  sortDirection: "DESC",
                  first: 5,
                  startTime: this.props.variables.currentTimeSpanStart,
                  endTime: this.props.variables.currentTimeSpanEnd
                }}
                options={{
                  useStackedBar: false
                }}
                plot={{
                  hideLegend: true,
                  yAxes: [
                    {
                      hideLabel: true
                    }
                  ],
                  series: [
                    {
                      options: {
                        lineWidth: 70, // percent value
                        fill: "#0F649A"
                      }
                    }
                  ]
                }}
              />
            </LoadingWrapper>
          </div>
        </WidgetContainer>
        <WidgetContainer size="medium-large">
          <Flex>
            <span className="widget-title">On trial</span>
            <TopCountFilter
              onChange={this.updateTopCount("topComponentsCount")}
            />
          </Flex>
          <div style={{ height: "180px", width: "100%" }}>
            <LoadingWrapper>
              <HorizontalBarChart
                openBlade={this.openBladeFromChart}
                timeRangeOverride={this.props.variables?.timeRangeOverride}
                hideTimeRangeFilter
                queryParams={{
                  setup: {
                    category: "time.month",
                    value: "sessions.usage_count",
                    group: "users.name"
                  },
                  sortColumn: "value",
                  sortDirection: "DESC",
                  first: 5,
                  startTime: this.props.variables.currentTimeSpanStart,
                  endTime: this.props.variables.currentTimeSpanEnd
                }}
                options={{
                  useStackedBar: false
                }}
                plot={{
                  hideLegend: true,
                  yAxes: [
                    {
                      hideLabel: true
                    }
                  ],
                  series: [
                    {
                      options: {
                        lineWidth: 70, // percent value
                        fill: "#FFA033"
                      }
                    }
                  ]
                }}
              />
            </LoadingWrapper>
          </div>
        </WidgetContainer>
        <WidgetContainer size="medium-large">
          <Flex>
            <span className="widget-title">For renewal</span>
            <TopCountFilter
              onChange={this.updateTopCount("topComponentsCount")}
            />
          </Flex>
        </WidgetContainer>
        <WidgetContainer size="medium-large">
          <Flex>
            <span className="widget-title">Fast adopters</span>
            <TopCountFilter
              onChange={this.updateTopCount("topComponentsCount")}
            />
          </Flex>
          <div style={{ minHeight: "250px", width: "100%" }}>
            <LoadingWrapper>
              <BarChartLogo
                hideTimeRangeFilter
                timeRangeOverride={this.props.variables?.timeRangeOverride}
                queryParams={{
                  setup: {
                    category: "time.month",
                    value: "sessions.usage_count",
                    group: "users.name"
                  },
                  sortColumn: "value",
                  sortDirection: "DESC",
                  first: 5
                }}
                options={{
                  //lineWidth: 25,
                  chartMaxHeight: 300
                }}
                // options={{
                //   useStackedBar:false
                // }}
                // plot={{
                //   hideLegend: true,
                //   yAxes: [{
                //     hideLabel: true,
                //   }],
                //   series: [
                //     {
                //       options: {
                //         lineWidth: 70, // percent value
                //         fill: "#FFA033"
                //       }
                //     }
                //   ],
                // }}
              />
            </LoadingWrapper>
          </div>
        </WidgetContainer>
      </div>
    );
  }
}

const RenderQuery = (props: ICompanyAnalyticsProps) => {
  const variables = { ..._.omit(props.variables, "productId"), productId: "" };
  const renderBlade = (payload: {
    error: Error;
    props: companyAnalyticsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <CompaniesAnalytics
        {...props}
        variables={variables}
        result={payload.props}
        retry={payload.retry}
        // updateVariables={updateVariables}
      />
    );
  };
  return (
    <QueryRenderer<companyAnalyticsQuery>
      environment={relayEnvironment}
      variables={variables}
      query={graphqlQuery}
      render={renderBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
