import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, message, notification } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled, { useTheme } from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import ImgCrop from "antd-img-crop";
import { QueryRenderer } from "react-relay";
import { getGateway } from "../../../../api";
import relayEnvironment from "../../../../api/relay";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { ElmButton } from "../../../../components/elmButton";
import { ElmInput } from "../../../../components/elmInput";
import { ElmCheckBox } from "../../../../components/elmCheckBox";
import { CountriesSelector } from "../../../../components/helpers";
import {
  editCompanyQuery,
  editCompanyQueryResponse
} from "./__generated__/editCompanyQuery.graphql";
import { fileActionHandler, getBase64 } from "../../../../utils";
import { useEffect, useRef } from "react";
import { LogoPlaceholder } from "../../../../assets";
//@ts-ignore
import { useSelector } from "react-redux";
import { selectors } from "store/ducks/app";
import { IOnboardingState } from "store/types";
import { DialogContainer } from "components/bladeManager/appDialog/helpers";
import {
  renderErrorNotification,
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";

const graphqlQuery = graphql`
  query editCompanyQuery($id: ID!) {
    company(id: $id) {
      logoUrl
      name
      contactPhone
      contactEmail
      contactName
      id
      location {
        city
        countryCode
      }
    }
  }
`;

const DialogTitle = styled.span`
  ${props => props.theme.fontType.visualizationTitle}
`;
const ImageDescription = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  letter-spacing: 0.16px;

  /* text / text-02 */

  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 11px;
`;

const ImageSectionContainer = styled.div`
  width: 64px;
  height: 66px;
  margin-right: 17px;
`;

const PlacesSearch = styled.input`
  &.pac-target-input {
    border: none;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.greyish};
  }
  :focus-visible {
    border: none;
    outline: none;
    border-bottom: 1px solid ${props => props.theme.colors.lightBlue};
  }
  :focus-visible::placeholder {
    color: ${props => props.theme.colors.inputTextActive};
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.theme.colors.inputTextDefault};
    opacity: 1; /* Firefox */
  }
`;

const AutoComplete = (props: { onChange: (obj: any) => void }) => {
  const theme = useTheme();
  const autoCompleteRef = useRef(null);
  const inputRef = useRef();
  const options = {
    fields: [
      "address_components",
      "formatted_address",
      "icon",
      "name",
      "website"
    ]
  };
  const listener = async function() {
    const place = await autoCompleteRef?.current.getPlace();
    const { address_components: address } = place;
    const country_code = address.filter(
      (item: { types: string | string[] }) =>
        item.types.indexOf("country") !== -1
    );
    const city = address.filter(
      (item: { types: string | string[] }) =>
        item.types.indexOf("locality") !== -1
    );

    props.onChange({
      location: {
        country_code: _.first(country_code)?.short_name || "",
        city: _.first(city)?.long_name || "",
        address: place?.formatted_address || ""
      },
      website: place?.website || ""
    });
  };

  useEffect(() => {
    const placeApi = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current = placeApi;
    autoCompleteRef?.current.addListener("place_changed", listener);

    // return () => {
    //   //window.google.maps.event.removeListener("place_changed", listener);
    //   //autoCompleteRef?.current.removeEventListener("place_changed", listener);
    // }
  }, []);
  return (
    <Flex flexDirection={"column"} flex={1}>
      <span style={{ fontSize: "12px", color: theme.colors.warmGrey }}>
        Location
      </span>
      <PlacesSearch
        type={"text"}
        defaultValue={""}
        placeholder="Enter location"
        style={{ marginBottom: "20px", fontSize: "12px" }}
        ref={inputRef}
      />
    </Flex>
  );
};

type state = {
  imageUrl: string;
  image: File | Blob;
  loading: boolean;
  productImageLink: string;
  productTypes: { id: number; description: string }[];
  productName: string;
  error: boolean;
  error_email: boolean;
  setLocationManually: boolean;
  website: string;
  location: {
    country_code: string;
    city: string;
    address: string;
  };
  editCompany: {
    name: string;
    contact_name: string;
    contact_email: string;
    contact_phone: string;
  };
};

export type IeditCompanyDialogProps = {
  companyId: string;
  editMode?: boolean;
};
export class EditCompanyDialog extends DialogBase<
  IModalProps<IeditCompanyDialogProps> & {
    result?: editCompanyQueryResponse;
    onboardingState?: IOnboardingState;
  },
  state
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          width: "330px"
        }
      }
    });
  }
  static getAppConnectOptions() {
    return super.getAppConnectOptions({
      selectors: {
        onboardingMode: "onboardingModeSelector"
      }
    });
  }
  componentDidMount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingPause();
  }
  componentWillUnmount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingContinue();
  }
  public checkOnboardingMode = () => {
    return this.props.onboardingState?.status === "active";
  };
  company: any = this.props?.result?.company || {};

  state: state = {
    error: false,
    error_email: false,
    imageUrl: this.company?.logoUrl || null,
    image: null,
    loading: false,
    productImageLink: "",
    productTypes: null,
    productName: "",
    setLocationManually: false,
    website: "",
    location: {
      country_code: _.get(this.company, "location.countryCode", null),
      city: _.get(this.company, "location.city", null),
      address: _.get(this.company, "location.address", null)
    },
    editCompany: {
      name: this.company.name || "",
      contact_name: this.company.contactName || "",
      contact_email: this.company.contactEmail || "",
      contact_phone: this.company.contactPhone || ""
    }
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditCompanyDialog"
    });
  };
  public editAdmin = () => {
    if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
      this.props.payload.onConfirm(this.state);
    }
  };
  public getTitle = () => {
    return "Add Company";
  };
  public getDescription = () => {
    return `Change the role you would like your new administrator to have.`;
  };

  public getSelectOptions = () => {
    return [
      {
        label: "Administrator",
        value: "license_admin"
      },
      {
        label: "Owner",
        value: "owner"
      },
      {
        label: "Stakeholder",
        value: "stakeholder"
      }
    ];
  };
  public handleImageChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl, image) =>
        this.setState({
          imageUrl,
          image,
          loading: false,
          productImageLink: ""
        })
      );
    }
  };

  public beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  };
  public validateEmail = (input: string) => {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input);
    if (!isValid) {
      this.setState({ error_email: true });
      return false;
    }
    this.setState({ error_email: false });
    return true;
  };

  public editCompany = async () => {
    const isValid = this.validateEmail(this.state.editCompany?.contact_email);
    if (!isValid) {
      renderErrorNotification("Email not valid !");
      return;
    }
    const gateway = getGateway();
    let companyResponse: { graphql_id?: string; id: string };
    const dataToSend = {
      ...this.state.editCompany,
      website: this.state.website,
      location: { ...this.state.location }
    };
    try {
      const dataInfo = this.props.payload.editMode
        ? await gateway.request.editCompany(dataToSend, {
            id: this.props.payload.companyId
          })
        : await gateway.request.newCompany(dataToSend);

      if (dataInfo.error) {
        console.error("Company data save failed: ", dataInfo.error);
        renderFailureNotification("Company data save failed !");
        throw new Error("Error");
      }
      companyResponse = dataInfo.data;
      if (this.state.image) {
        const formData = new FormData();
        const file = this.state.image;
        formData.append("file", file, _.get(file, "name"));
        const uploadInfo = await gateway.request.companyUploadImage(formData, {
          id: dataInfo.data.id
        });
      }
      renderSuccessNotification(
        this.props.payload.editMode
          ? `Company info updated !`
          : `New Company created !`
      );
      if (_.isFunction(_.get(this.props.payload, "onConfirm"))) {
        this.props.payload.onConfirm(companyResponse);
      }
      this.closeModal();
    } catch (error) {
      console.error("Company overall data save failed: ", error);
    }
  };

  public getUpdateFunction = (value: keyof state["editCompany"]) => (
    val: string
  ) => {
    const editCompany = this.state.editCompany;
    editCompany[value] = val;
    this.setState({ editCompany, error_email: false });
  };
  public fileAction = (file: RcFile) => {
    return fileActionHandler(file, (imageUrl, image, res) =>
      this.setState(
        {
          imageUrl,
          image,
          loading: false,
          productImageLink: ""
        },
        () => {
          res(imageUrl);
        }
      )
    );
  };
  public autopopulate = (obj: any) => {
    this.setState({
      ...this.state,
      location: obj.location,
      website: obj.website
    });
  };
  public renderCompanyInfo = () => {
    const removeImageUrl = () => {
      this.setState({ image: null, imageUrl: "" });
    };
    const updateCity = (city: string) => {
      this.setState({
        location: {
          ...this.state.location,
          city
        }
      });
    };
    const updateAddress = (address: string) => {
      this.setState({
        location: {
          ...this.state.location,
          address
        }
      });
    };
    const updateWebsite = (website: string) => {
      this.setState({
        ...this.state,
        website
      });
    };
    const onCountryChange = (payload: {
      countryCode: string;
      countryName: string;
    }) => {
      this.setState({
        location: {
          ...this.state.location,
          country_code: payload.countryCode || null
        }
      });
    };
    const {
      name,
      contact_email,
      contact_name,
      contact_phone
    } = this.state.editCompany;
    const { location, setLocationManually, website } = this.state;
    return (
      <div>
        <Flex style={{ flex: 1, marginBottom: "10px" }}>
          <ElmInput
            placeholder={"Name"}
            label={"Enter name"}
            updatedValue={this.getUpdateFunction("name")}
            defaultValue={name}
          />
        </Flex>
        <Flex>
          <AutoComplete onChange={this.autopopulate} />
        </Flex>
        <Flex>
          <ElmCheckBox
            checked={setLocationManually}
            onChange={e => {
              this.setState({
                setLocationManually: e.target.checked
              });
            }}
            style={{ marginBottom: "12px" }}
          >
            Set location manually
          </ElmCheckBox>
        </Flex>
        {setLocationManually && (
          <Flex flexDirection={"column"} flex={1}>
            <div style={{ marginBottom: "20px" }}>
              <CountriesSelector
                value={location.country_code}
                onChange={onCountryChange}
                defaultValue={location.country_code}
              />
            </div>

            <ElmInput
              updatedValue={updateCity}
              placeholder={"Enter city"}
              label="City"
              controlled={true}
              style={{ marginBottom: "20px" }}
              value={location.city}
              //defaultValue={location.city}
            />
            <ElmInput
              //id="autocomplete"
              updatedValue={updateAddress}
              controlled={true}
              placeholder={"Enter address"}
              label="Address"
              style={{ marginBottom: "20px" }}
              //defaultValue={location.city}
              value={location.address}
            />
            <ElmInput
              //id="autocomplete"
              updatedValue={updateWebsite}
              controlled={true}
              placeholder={"Enter website url"}
              label="Website"
              style={{ marginBottom: "20px" }}
              //defaultValue={location.city}
              value={website}
            />
          </Flex>
        )}

        <ElmInput
          placeholder={"Enter contact name"}
          label="Primary Contact"
          updatedValue={this.getUpdateFunction("contact_name")}
          style={{ marginBottom: "20px" }}
          defaultValue={contact_name}
        />
        <ElmInput
          placeholder={"Email"}
          label="Email"
          updatedValue={this.getUpdateFunction("contact_email")}
          style={{ marginBottom: "20px" }}
          error={this.state.error_email}
          errorMessage={"Email is not valid"}
          defaultValue={contact_email}
        />
        <ElmInput
          defaultValue={contact_phone}
          placeholder={"Phone number"}
          style={{ marginBottom: "20px" }}
          label="Phone"
          type="tel"
          updatedValue={this.getUpdateFunction("contact_phone")}
        />
        <Flex style={{ marginBottom: "30px" }}>
          <ImageSectionContainer>
            <ImgCrop>
              {this.state.imageUrl ? (
                <img
                  src={this.state.imageUrl}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              ) : (
                <Flex justifyContent={"center"} alignItems="center">
                  {this.state.loading && <LoadingOutlined />}
                  {!this.state.loading && (
                    <img src={LogoPlaceholder} alt="logo placeholder" />
                  )}
                </Flex>
              )}
            </ImgCrop>
          </ImageSectionContainer>
          <Flex style={{ flexDirection: "column" }}>
            <span className="title">Logo</span>
            <ImageDescription>
              Only .jpg and .png files. Aspect ratio 1:1
            </ImageDescription>
            <Flex justifyContent={"flex-start"}>
              <ImgCrop>
                <Upload
                  showUploadList={false}
                  action={this.fileAction}
                  beforeUpload={this.beforeUpload}
                  onChange={this.handleImageChange}
                >
                  <ElmButton
                    style={{
                      //minWidth: "127px",
                      marginLeft: "0px"
                    }}
                    label={"Choose logo"}
                    colorVariance="outline-secondary"
                  />
                </Upload>
              </ImgCrop>

              {this.state.imageUrl && (
                <ElmButton
                  label={"Remove"}
                  colorVariance="outline-secondary"
                  onClick={removeImageUrl}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </div>
    );
  };
  public render() {
    return (
      <DialogContainer className={"add-license-dialog"}>
        <DialogTitle>
          {this.props.payload.editMode ? "Edit Company" : "Add Company"}
        </DialogTitle>
        {this.renderCompanyInfo()}
        <Flex justifyContent={"flex-end"}>
          {this.checkOnboardingMode() ? null : (
            <ElmButton
              label={"Cancel"}
              colorVariance={"outline-secondary"}
              onClick={this.closeModal}
            />
          )}
          <ElmButton
            style={{ minWidth: "116px" }}
            label={this.props.payload.editMode ? "Update" : "Add Company"}
            colorVariance="primary"
            onClick={this.editCompany}
          />
        </Flex>
      </DialogContainer>
    );
  }
}

export const DataContainer = (props: IModalProps<IeditCompanyDialogProps>) => {
  const onboardingState = useSelector(selectors.onboardingModeSelector);
  const renderEditCompany = (payload: { props: editCompanyQueryResponse }) => {
    if (!payload.props) {
      return null;
    }
    return <EditCompanyDialog {...props} result={payload.props} />;
  };
  return (
    <DialogContainer>
      {props?.payload && !props.payload.editMode && (
        <EditCompanyDialog {...props} onboardingState={onboardingState} />
      )}
      {props?.payload && props.payload.editMode && (
        <QueryRenderer<editCompanyQuery>
          environment={relayEnvironment}
          variables={{
            id: _.get(props, "payload.companyId")
          }}
          query={graphqlQuery}
          render={renderEditCompany}
        />
      )}
    </DialogContainer>
  );
};

export default DataContainer;
