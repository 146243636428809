/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type hooksLicenseComponentsAndEntitlementsQueryVariables = {
  licenseId: string;
};
export type hooksLicenseComponentsAndEntitlementsQueryResponse = {
  readonly license: {
    readonly components: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly componentType: string;
        readonly componentGroup: {
          readonly name: string | null;
          readonly id: string;
        } | null;
        readonly productComponentRequirements: ReadonlyArray<{
          readonly canInherit: boolean;
          readonly freeTrial: boolean;
          readonly freeTrialActive: boolean | null;
          readonly freeTrialExpiration: unknown | null;
          readonly isIncluded: boolean;
          readonly isInherited: boolean;
          readonly productVersionId: string;
          readonly tokenCount: number | null;
          readonly wouldInheritFromBaseLicense: boolean;
        }> | null;
        readonly licenseComponentRequirements: ReadonlyArray<{
          readonly canInherit: boolean;
          readonly freeTrial: boolean;
          readonly freeTrialActive: boolean | null;
          readonly freeTrialExpiration: unknown | null;
          readonly isIncluded: boolean;
          readonly isInherited: boolean;
          readonly productVersionId: string;
          readonly tokenCount: number | null;
          readonly wouldInheritFromBaseLicense: boolean;
        }> | null;
      } | null> | null;
    } | null;
    readonly componentEntitlements: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly tokenCount: number | null;
        readonly sessionCount: number | null;
        readonly currencyCount: number | null;
        readonly numCurrencyUsed: number | null;
        readonly latestTerm: {
          readonly startDate: unknown | null;
          readonly endDate: unknown | null;
          readonly isActive: boolean;
        } | null;
        readonly components: {
          readonly nodes: ReadonlyArray<{
            readonly name: string | null;
            readonly componentType: string;
            readonly id: string;
          } | null> | null;
        } | null;
        readonly componentGroup: {
          readonly id: string;
          readonly name: string | null;
          readonly componentType: string;
          readonly components: {
            readonly nodes: ReadonlyArray<{
              readonly name: string | null;
              readonly componentType: string;
              readonly id: string;
            } | null> | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type hooksLicenseComponentsAndEntitlementsQuery = {
  readonly response: hooksLicenseComponentsAndEntitlementsQueryResponse;
  readonly variables: hooksLicenseComponentsAndEntitlementsQueryVariables;
};

/*
query hooksLicenseComponentsAndEntitlementsQuery(
  $licenseId: ID!
) {
  license(id: $licenseId) {
    components {
      nodes {
        id
        name
        componentType
        componentGroup {
          name
          id
        }
        productComponentRequirements {
          canInherit
          freeTrial
          freeTrialActive
          freeTrialExpiration
          isIncluded
          isInherited
          productVersionId
          tokenCount
          wouldInheritFromBaseLicense
        }
        licenseComponentRequirements {
          canInherit
          freeTrial
          freeTrialActive
          freeTrialExpiration
          isIncluded
          isInherited
          productVersionId
          tokenCount
          wouldInheritFromBaseLicense
        }
      }
    }
    componentEntitlements {
      nodes {
        id
        tokenCount
        sessionCount
        currencyCount
        numCurrencyUsed
        latestTerm {
          startDate
          endDate
          isActive
        }
        components {
          nodes {
            name
            componentType
            id
          }
        }
        componentGroup {
          id
          name
          componentType
          components {
            nodes {
              name
              componentType
              id
            }
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "licenseId"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "licenseId"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "componentType",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "tokenCount",
      storageKey: null
    } as any,
    v6 = [
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "canInherit",
        storageKey: null
      } as any,
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "freeTrial",
        storageKey: null
      } as any,
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "freeTrialActive",
        storageKey: null
      } as any,
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "freeTrialExpiration",
        storageKey: null
      } as any,
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "isIncluded",
        storageKey: null
      } as any,
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "isInherited",
        storageKey: null
      } as any,
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "productVersionId",
        storageKey: null
      } as any,
      v5 /*: any*/,
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "wouldInheritFromBaseLicense",
        storageKey: null
      } as any
    ],
    v7 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentGroup",
              kind: "LinkedField",
              name: "componentGroup",
              plural: false,
              selections: [v3 /*: any*/, v2 /*: any*/],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "ComponentRequirement",
              kind: "LinkedField",
              name: "productComponentRequirements",
              plural: true,
              selections: v6 /*: any*/,
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "ComponentRequirement",
              kind: "LinkedField",
              name: "licenseComponentRequirements",
              plural: true,
              selections: v6 /*: any*/,
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v3 /*: any*/, v4 /*: any*/, v2 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      concreteType: "ComponentEntitlementConnection",
      kind: "LinkedField",
      name: "componentEntitlements",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ComponentEntitlement",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "sessionCount",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "currencyCount",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "numCurrencyUsed",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "Term",
              kind: "LinkedField",
              name: "latestTerm",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "startDate",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endDate",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "isActive",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentGroup",
              kind: "LinkedField",
              name: "componentGroup",
              plural: false,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                v4 /*: any*/,
                v8 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "hooksLicenseComponentsAndEntitlementsQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [v7 /*: any*/, v9 /*: any*/],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "hooksLicenseComponentsAndEntitlementsQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [v7 /*: any*/, v9 /*: any*/, v2 /*: any*/],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "e3e7ff229bb537fcd2f7f27fab82b708",
      id: null,
      metadata: {},
      name: "hooksLicenseComponentsAndEntitlementsQuery",
      operationKind: "query",
      text:
        "query hooksLicenseComponentsAndEntitlementsQuery(\n  $licenseId: ID!\n) {\n  license(id: $licenseId) {\n    components {\n      nodes {\n        id\n        name\n        componentType\n        componentGroup {\n          name\n          id\n        }\n        productComponentRequirements {\n          canInherit\n          freeTrial\n          freeTrialActive\n          freeTrialExpiration\n          isIncluded\n          isInherited\n          productVersionId\n          tokenCount\n          wouldInheritFromBaseLicense\n        }\n        licenseComponentRequirements {\n          canInherit\n          freeTrial\n          freeTrialActive\n          freeTrialExpiration\n          isIncluded\n          isInherited\n          productVersionId\n          tokenCount\n          wouldInheritFromBaseLicense\n        }\n      }\n    }\n    componentEntitlements {\n      nodes {\n        id\n        tokenCount\n        sessionCount\n        currencyCount\n        numCurrencyUsed\n        latestTerm {\n          startDate\n          endDate\n          isActive\n        }\n        components {\n          nodes {\n            name\n            componentType\n            id\n          }\n        }\n        componentGroup {\n          id\n          name\n          componentType\n          components {\n            nodes {\n              name\n              componentType\n              id\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "a2344ae0aa62f01d3d003665566b8cf2";
export default node;
