import * as _ from "lodash";
import { Flex } from "reflexbox/styled-components";
import React, { useState } from "react";
import styled from "styled-components";
import { appConnect } from "../../store/appConnect";
import { IUserRole } from "../../store/types";
import { IConnectedComponent } from "../types";
import { Select } from "antd";
import { IBladeBaseProps } from "./types";
import { getIcon } from "components/icons";
import { asyncStorage } from "utils";
export interface IConnectedRoleSelectorProps {
  activeRole: IUserRole;
  companies: string[];
  roles: IUserRole[];
  activeRoleToken: string;
  textPositionInversed?: boolean;
}

const { Option } = Select;

export const StyledSelect = styled(Select)`
  min-width: 215px;
  letter-spacing: -0.5px;

  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid ${props => props.theme.colors.white};
    color: white !important;
    .ant-select-selection-item {
      color: white !important;
    }
  }
  .ant-select-arrow {
    color: white !important;
  }
`;
export const StyledOption = styled(Option)`
  max-width: 100%;
  min-width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: ${props => props.theme.colors.highlight} !important;
  :hover {
    background-color: ${props => props.theme.colors.hover} !important;
  }
`;

export const CompanyName = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 22px;
  max-width: 160px;
  font-size: ${props => props.theme.fontSizes.ySmall};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const RoleType = styled(Flex)`
  line-height: 22px;
  font-size: ${props => props.theme.fontSizes.xxSmall};
  font-weight: ${props => props.theme.fontWeight.normal};
  text-transform: capitalize;
`;

const Down = getIcon("chevron-down", "fas");
const Up = getIcon("chevron-up", "fas");

const UsersCompanySelector = (
  props: IConnectedComponent<IConnectedRoleSelectorProps> &
    IBladeBaseProps & {
      textPositionInversed?: boolean;
    }
) => {
  // const selectorData = useSelector(selectors.activeRoleTypeSelector);
  // const activeRoleToken = props.appState.activeRoleToken;
  // const { openBlade } = props;
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  // useEffect(() => {
  //   activeRoleToken && openBlade({
  //     route: selectorData === "company" ? "Overview" : "Dashboard",
  //     routeName: selectorData === "company" ? "Overview" : "Dashboard",
  //     navigationType: "reset",
  //     fromBladeIndex: null,
  //     routeData: null
  //   });
  // }, []);

  const handleOnChange = (token: any) => {
    const roles = props?.appState?.roles;
    const target = roles.find(role => role.token === token);
    if (!target) {
      return;
    }
    setMenuOpen(false);
    asyncStorage.removeItem("elm_user_role");
    props.appDispatch.appActions.updateActiveCompany(target.token);
    props.openBlade({
      route: "Dashboard",
      fromBladeIndex: 0,
      navigationType: "reset",
      routeName: "Customer portal",
      routeData: null
    });

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const getValue = () => props.appState.activeRole.name;
  const roles = props?.appState?.roles;

  const renderValues = () => {
    if (_.isArray(roles)) {
      return _.map(roles, role => (
        <StyledOption value={role?.token} label={role?.name} key={role?.name}>
          <Flex flex={1} justifyContent="space-between">
            <CompanyName>{role?.name}</CompanyName>
            <RoleType>{role?.type}</RoleType>
          </Flex>
        </StyledOption>
      ));
    }
    return null;
  };
  if (roles?.length === 1) {
    return null;
  }
  return (
    <StyledSelect
      optionLabelProp="label"
      popupClassName={"company-selector-dropdown"}
      className={props.textPositionInversed ? "styled-select-inversed" : ""}
      dropdownStyle={{ zIndex: 1051 }} // Notifications Dropdown z-index is 1050
      onChange={handleOnChange}
      onClick={() => setMenuOpen(!isMenuOpen)}
      open={isMenuOpen}
      onBlur={() => setMenuOpen(false)}
      defaultValue={getValue()}
      value={getValue()}
      suffixIcon={isMenuOpen ? <Up /> : <Down />} //<i className="fa-regular fa-user"></i>
      //getPopupContainer={() => document.getElementById("area")}
    >
      {renderValues()}
    </StyledSelect>
  );
};

export default appConnect(UsersCompanySelector, {
  selectors: {
    activeRole: "activeRoleSelector",
    companies: "companiesFromRolesSelector",
    activeRoleType: "activeRoleTypeSelector",
    roles: "userRolesSelector",
    activeRoleToken: "activeRoleTokenSelector"
  }
});
