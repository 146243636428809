class IconColorsMap {
  private static instance: IconColorsMap;
  private colors: Record<string, string>;

  private constructor() {
    this.colors = {};
  }

  public static getInstance(): IconColorsMap {
    if (!IconColorsMap.instance) {
      IconColorsMap.instance = new IconColorsMap();
    }
    return IconColorsMap.instance;
  }

  public getColors() {
    return this.colors;
  }

  public getColorHash(key: string) {
    return this.colors[key];
  }

  public setColorHash(key: string, value: string) {
    this.colors[key] = value;
  }
}

export default IconColorsMap.getInstance();
