import React from "react";
import { Flex } from "reflexbox/styled-components";
import styled, { useTheme } from "styled-components";
import elmLogoLight from "./logo.svg";
import elmLogoDark from "./logo-dark.svg";
import {
  CompanyName,
  RoleType,
  StyledOption
} from "components/bladeManager/companySelector";
import { ActiveMode, IUserRole, UserRoleType } from "store/types";
import { ElmSelectWithLabel } from "components/elmSelect";
import { ElmButton } from "components";
import { appConnect } from "store/appConnect";
import { IConnectedComponent } from "components/types";
import { saveUserRoleTypeToLocalSync } from "utils";

const DialogBodyFull = styled(Flex)`
  flex: 1;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;
const DialogBoxPadding = 40;
const DialogContainer = styled.div`
  width: ${324 - DialogBoxPadding * 2}px;
  height: ${324 - DialogBoxPadding * 2}px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  padding-top: 32px;
  padding-bottom: 32px;
  overflow: hidden;
`;
const DialogTitle = styled.p(props => ({
  "font-weight": 400,
  "margin-bottom": "48px",
  "align-self": "center",
  display: "flex",
  marginTop: "12px"
}));

interface IDialogProps {
  roles: IUserRole[];
  activeMode: ActiveMode;
  updateRole: (token: string) => void;
  onConfirm: () => void;
  disabled: boolean;
}

const RoleSelectDialog: React.FC<IDialogProps> = ({
  roles,
  activeMode,
  updateRole,
  onConfirm,
  disabled
}) => {
  const theme = useTheme();

  return (
    <DialogBodyFull
      style={{
        backgroundColor: theme.colors.white,
        fontFamily: theme.font.main,
        fontSize: theme.fontSizes.ySmall
      }}
    >
      <DialogContainer
        style={{
          boxShadow: `0px 4px 32px -8px ${theme.colors.loginBoxShadow}`
        }}
      >
        <img
          src={activeMode === "light" ? elmLogoLight : elmLogoDark}
          style={{ display: "flex", justifySelf: "self", height: "82px" }}
          alt="Elm logo"
        />
        <DialogTitle style={{ color: theme.colors.textPrimary }}>
          a better way to license your software
        </DialogTitle>
        <ElmSelectWithLabel
          onChange={updateRole}
          label="Role"
          placeholder="Select role"
        >
          {roles.map(op => (
            <StyledOption value={op.token} label={`${op?.name} (${op?.type})`}>
              <Flex flex={1} justifyContent="space-between">
                <CompanyName>{op?.name}</CompanyName>
                <RoleType>{op?.type}</RoleType>
              </Flex>
            </StyledOption>
          ))}
        </ElmSelectWithLabel>
        <Flex justifyContent={"center"} marginTop="15px">
          <ElmButton
            label={"Continue"}
            style={{ width: "100%", margin: 0 }}
            onClick={onConfirm}
            disabled={disabled}
          />
        </Flex>
      </DialogContainer>
    </DialogBodyFull>
  );
};

type state = {
  token: string;
  type?: UserRoleType;
};
class RoleSelectDialogPage extends React.Component<IConnectedComponent, state> {
  state: state = {
    token: ""
  };
  componentDidMount(): void {
    const roles: IUserRole[] = this.props.appState.roles;
    if (roles.length === 1) {
      this.props.appDispatch.appActions.updateActiveCompany(roles[0].token);
      saveUserRoleTypeToLocalSync(roles[0].type);
    }
  }
  componentDidUpdate(): void {
    const roles: IUserRole[] = this.props.appState.roles;
    if (roles.length === 1) {
      this.props.appDispatch.appActions.updateActiveCompany(roles[0].token);
      saveUserRoleTypeToLocalSync(roles[0].type);
      window.location.reload();
    }
  }
  public onConfirm = () => {
    this.props.appDispatch.appActions.updateActiveCompany(this.state.token);
    saveUserRoleTypeToLocalSync(this.state.type);
    window.location.reload();
  };
  public updateRole = (token: string) => {
    const roles: IUserRole[] = this.props.appState.roles;
    const target = roles.find(role => role.token === token);
    if (target) {
      this.setState({ token: target.token, type: target.type });
    }
  };

  public render() {
    const roles: IUserRole[] = this.props.appState.roles;
    return (
      <RoleSelectDialog
        roles={roles}
        activeMode={this.props.appState.activeMode}
        updateRole={this.updateRole}
        onConfirm={this.onConfirm}
        disabled={!this.state.token}
      />
    );
  }
}

export default appConnect(RoleSelectDialogPage, {
  selectors: {
    roles: "userRolesSelector",
    activeMode: "activeModeSelector"
  }
});
