/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type featuresAndGroupsQueryVariables = {
  id: string;
};
export type featuresAndGroupsQueryResponse = {
  readonly license: {
    readonly features: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
      } | null> | null;
    } | null;
    readonly featureGroups: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly features: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    readonly product: {
      readonly features: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly name: string | null;
          readonly featureGroupsCount: number;
        } | null> | null;
      } | null;
      readonly featureGroups: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly name: string | null;
          readonly features: {
            readonly nodes: ReadonlyArray<{
              readonly id: string;
              readonly name: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type featuresAndGroupsQuery = {
  readonly response: featuresAndGroupsQueryResponse;
  readonly variables: featuresAndGroupsQueryVariables;
};

/*
query featuresAndGroupsQuery(
  $id: ID!
) {
  license(id: $id) {
    features {
      nodes {
        id
        name
      }
    }
    featureGroups {
      nodes {
        id
        name
        features {
          nodes {
            id
            name
          }
        }
      }
    }
    product {
      features {
        nodes {
          id
          name
          featureGroupsCount
        }
      }
      featureGroups {
        nodes {
          id
          name
          features {
            nodes {
              id
              name
            }
          }
        }
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      concreteType: "FeatureConnection",
      kind: "LinkedField",
      name: "features",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Feature",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v2 /*: any*/, v3 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      concreteType: "FeatureGroupConnection",
      kind: "LinkedField",
      name: "featureGroups",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "FeatureGroup",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      concreteType: "FeatureConnection",
      kind: "LinkedField",
      name: "features",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Feature",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "featureGroupsCount",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "featuresAndGroupsQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            v4 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [v6 /*: any*/, v5 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "featuresAndGroupsQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            v4 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [v6 /*: any*/, v5 /*: any*/, v2 /*: any*/],
              storageKey: null
            },
            v2 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "3627ee0712d45fa7516dd7988ab1c17e",
      id: null,
      metadata: {},
      name: "featuresAndGroupsQuery",
      operationKind: "query",
      text:
        "query featuresAndGroupsQuery(\n  $id: ID!\n) {\n  license(id: $id) {\n    features {\n      nodes {\n        id\n        name\n      }\n    }\n    featureGroups {\n      nodes {\n        id\n        name\n        features {\n          nodes {\n            id\n            name\n          }\n        }\n      }\n    }\n    product {\n      features {\n        nodes {\n          id\n          name\n          featureGroupsCount\n        }\n      }\n      featureGroups {\n        nodes {\n          id\n          name\n          features {\n            nodes {\n              id\n              name\n            }\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "dda3b83f5bed6e0c145442fed7a082c5";
export default node;
