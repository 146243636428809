import { Radio } from "antd";
import {
  BladeTemplate,
  BladeTitle,
  ElmButton,
  ElmInput
} from "../../components";
import React from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { Flex } from "reflexbox";
import styled, { useTheme } from "styled-components";
import relayEnvironment from "../../api/relay";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../components/elmSelect";

const renderLeftSideHeader = () => {
  return (
    <div style={{ marginLeft: "18px", marginTop: "-45px" }}>
      <BladeTitle>Session & User Classification</BladeTitle>
    </div>
  );
};

const SectionTitle = styled.p(props => ({
  color: props.theme.colors.warmGrey,
  fontSize: props.theme.fontSizes.xSmall,
  fontWeight: 500,
  letterSpacing: 1.5,
  textTransform: "uppercase",
  lineHeight: "15px"
}));

const SectionText = styled.p(props => ({
  color: props.theme.colors.black,
  fontSize: props.theme.fontSizes.ySmall,
  //fontWeight: 500,
  lineHeight: "21px",
  maxWidth: "430px"
}));

const TextBetweenInputs = styled.span(props => ({
  fontSize: props.theme.fontSizes.ySmall,
  marginBottom: "1px"
}));

export function ConfigurationBlade(props: any) {
  const theme = useTheme();
  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <BladeTemplate
        bladeIndex={0}
        closeBlade={() => console.log("Close dasboard blade")}
        bladeType="Configuration"
        title={""}
        renderLeftSideHeader={renderLeftSideHeader}
        //renderRightSideHeader={() => renderRightSideHeader(hideShowFilters)}
        //topAccentColor={"pink"}
        //hideCloseButton={true}
        hideBladeTypeName={true}
        // refreshFn={() => console.log("")}
        // setRefreshFn={() => console.log("Refresh fn")}
        className="dashboard-blade"
        loading={false}
      >
        <Flex
          style={{
            margin: "0px 40px 35px 40px",
            flexDirection: "column",
            flex: 1
          }}
        >
          <Flex flexDirection="column">
            <SectionTitle>Idle session QC</SectionTitle>
            <Flex
              justifyContent="space-between"
              justifyItems="center"
              alignItems="center"
            >
              <Flex style={{ maxWidth: "430px" }}>
                <SectionText>
                  What is the maximum session length that should be used in
                  analyses when sessions are not closed after the most recent
                  validation?
                </SectionText>
              </Flex>
              <Flex alignItems="flex-start" justifyItems="center">
                <Flex>
                  <ElmInput label="Max idle session length" />
                </Flex>
                <Flex
                  style={{ width: "82px", marginLeft: "8px", height: "38px" }}
                >
                  <ElmSelectWithLabel style={{ height: "38px" }} label="&nbsp;">
                    <ElmSelectOption key={1}>{"hours"}</ElmSelectOption>
                  </ElmSelectWithLabel>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              style={{ marginTop: "24px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex>
                <SectionText>
                  When the session lasts for multiple days, apply the maximum
                  idle session length to?
                </SectionText>
              </Flex>
              <Flex>
                <Radio.Group
                  style={{ width: "100%" }}
                  //   value={null}
                  //   onChange={}
                >
                  <Radio value={"firstDay"}>Only the 1st day</Radio>
                  <Radio value={"eachDay"}>Each day</Radio>
                </Radio.Group>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            style={{ marginTop: "48px", color: theme.colors.textPrimary }}
          >
            <SectionTitle>Active user definition</SectionTitle>
            <SectionText>
              What is the minimum threshold that defines ‘Active’ user?
            </SectionText>
            <Flex
              alignItems="flex-end"
              height={50}
              flex={1}
              justifyContent="space-between"
            >
              <Flex>
                <Radio value={"Average"}>Average</Radio>
              </Flex>
              <Flex
                alignItems="flex-end"
                justifyItems="center"
                justifyContent="space-evenly"
                flex={1}
              >
                <Flex>
                  <ElmInput style={{ height: "38px" }} label="&nbsp;" />
                </Flex>
                <Flex style={{ width: "82px", marginLeft: "8px" }}>
                  <ElmSelectWithLabel value="hours" label="&nbsp;">
                    <ElmSelectOption key={1}>{"hours"}</ElmSelectOption>
                  </ElmSelectWithLabel>
                </Flex>
                <TextBetweenInputs>per</TextBetweenInputs>

                <Flex style={{ width: "82px", marginLeft: "8px" }}>
                  <ElmSelectWithLabel value="month" label="&nbsp;">
                    <ElmSelectOption key={1}>{"month"}</ElmSelectOption>
                  </ElmSelectWithLabel>
                </Flex>
                <TextBetweenInputs>in the last</TextBetweenInputs>
                <Flex style={{ width: "82px", marginLeft: "8px" }}>
                  <ElmSelectWithLabel value="year" label="&nbsp;">
                    <ElmSelectOption key={1}>{"year"}</ElmSelectOption>
                  </ElmSelectWithLabel>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              alignItems="flex-end"
              height={50}
              flex={1}
              justifyContent="stretch"
            >
              <Flex alignItems="flex-end">
                <Radio value={"atLeast"}>At least</Radio>
              </Flex>
              <Flex
                alignItems="flex-end"
                justifyItems="center"
                justifyContent="space-evenly"
                flex={1}
              >
                <Flex>
                  <ElmInput style={{ height: "38px" }} label="&nbsp;" />
                </Flex>
                <Flex style={{ width: "82px", marginLeft: "8px" }}>
                  <ElmSelectWithLabel value="hours" label="&nbsp;">
                    <ElmSelectOption key={1}>{"hours"}</ElmSelectOption>
                  </ElmSelectWithLabel>
                </Flex>
                <TextBetweenInputs>in any</TextBetweenInputs>

                <Flex style={{ width: "82px", marginLeft: "8px" }}>
                  <ElmSelectWithLabel value="month" label="&nbsp;">
                    <ElmSelectOption key={1}>{"month"}</ElmSelectOption>
                  </ElmSelectWithLabel>
                </Flex>
                <TextBetweenInputs>in the last</TextBetweenInputs>
                <Flex style={{ width: "82px", marginLeft: "8px" }}>
                  <ElmSelectWithLabel value="year" label="&nbsp;">
                    <ElmSelectOption key={1}>{"year"}</ElmSelectOption>
                  </ElmSelectWithLabel>
                </Flex>
              </Flex>
            </Flex>
            <span
              style={{
                marginLeft: "84px",
                marginTop: "24px",
                fontSize: theme.fontSizes.ySmall
              }}
            >
              {" "}
              AND has had at least
            </span>
            <Flex
              //alignItems="center"
              flex={1}
              justifyContent="stretch"
              marginLeft={84}
              height={40}
              alignItems="flex-end"
            >
              {/* <Flex alignItems="flex-start" justifyItems="center" justifyContent="space-evenly" flex={1}> */}
              <Flex>
                <ElmInput label="&nbsp;" />
              </Flex>
              <span
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  fontSize: theme.fontSizes.ySmall
                }}
              >
                session in the last{" "}
              </span>
              <Flex>
                <ElmInput label="&nbsp;" />
              </Flex>
              <span
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  fontSize: theme.fontSizes.ySmall
                }}
              >
                {" "}
                days{" "}
              </span>
              {/* </Flex> */}
            </Flex>
          </Flex>
          <Flex
            marginTop={50}
            flexDirection="column"
            style={{ color: theme.colors.textPrimary }}
          >
            <SectionTitle>New user definition</SectionTitle>
            <Flex alignItems="flex-end" height={40}>
              <span style={{ fontSize: theme.fontSizes.ySmall }}>
                {" "}
                A new user is defined as having the first session occur within
                the last
              </span>
              <Flex style={{ marginLeft: "16px", marginRight: "16px" }}>
                <ElmInput label="&nbsp;" />
              </Flex>
              <span style={{ fontSize: theme.fontSizes.ySmall }}>days</span>
            </Flex>
          </Flex>
          <Flex marginTop={40}>
            <ElmButton
              colorVariance={"outline-secondary"}
              label={"Cancel"}
              style={{ marginLeft: "0px" }}
              //onClick={this.toggleMode}
            />
            <ElmButton label={"Save"} />
          </Flex>
        </Flex>
      </BladeTemplate>
    </RelayEnvironmentProvider>
  );
}
