import * as _ from "lodash";
import React, { useEffect } from "react";
import { Redirect, RouteChildrenProps } from "react-router-dom";
import { Flex } from "reflexbox";
import styled from "styled-components";
import { appConnect, appDispatch } from "./store/appConnect";
import { ElmButton, ElmInput, getIcon, TabView } from "./components";
import { ITab } from "./components/tabView";

import { getGateway } from "./api";
import { useSelector } from "react-redux";
import { AppModalConductor } from "./components/bladeManager/appDialog";
import { IBladeBaseProps } from "./components/bladeManager/types";
import {
  getSavedRoleFromLocalSync,
  saveUserRoleTypeToLocalSync
} from "./utils";
import { InfoMessage } from "./components/helpers";
import elmLogoLight from "./logo.svg";
import elmLogoDark from "./logo-dark.svg";
import { Alert } from "antd";
import { PropertySafetyFilled } from "@ant-design/icons";
import { ActiveMode, IUserRole } from "store/types";

export interface ILoginProps extends RouteChildrenProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    isSignedIn: boolean;
    signedInFailed: boolean;
    isLoading: boolean;
    roles: string[];
    activeRole: any;
    activeMode: ActiveMode;
  };
}
export interface ILoginState {
  signUp: {
    email: string;
    password: string;
    confirmPassword: string;
  };
  signIn: {
    email: string;
    password: string;
  };
  showPasswordResetFlow: boolean;
  emailReset: string;
  isEmailSent: boolean;
}

const LoginBoxPadding = 32;
export const FormPadding = 24;
export const LoginBox = styled.div`
  width: ${324 - LoginBoxPadding * 2}px;
  height: ${440 - LoginBoxPadding * 2}px;
  border-radius: 3px;
  box-shadow: 0px 4px 32px -8px ${props => props.theme.colors.loginBoxShadow};
  display: flex;
  flex-direction: column;
  padding: ${LoginBoxPadding + 8}px;
  padding-top: ${LoginBoxPadding}px;
  padding-bottom: ${LoginBoxPadding}px;
  overflow: hidden;
`;
export const LoginContainer = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.bladeContainer};
`;

export const TagLine = styled.p(props => ({
  "font-family": props.theme.font.main,
  "font-size": props.theme.fontSizes.ySmall,
  "font-weight": 400,
  "margin-bottom": "24px",
  "align-self": "center",
  display: "flex",
  color: props.theme.colors.textPrimary,
  marginTop: "12px"
}));
export const ForgotPassword = styled.p(props => ({
  color: props.theme.colors.linkButtonLabel,
  "font-family": props.theme.font.main,
  "font-size": props.theme.fontSizes.small,
  "margin-bottom": "0px",
  "margin-top": "4px",
  ":hover": {
    textDecoration: "none",
    cursor: "pointer"
  }
}));

export const BulbContainer = styled.div`
  border-radius: 50%;
  background-color: ${props => props.theme.colors.lightBlue};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const BulbIcon = styled(getIcon("FaRegLightbulb"))`
  color: ${props => props.theme.colors.white};
  width: 9px;
`;

const localRole = getSavedRoleFromLocalSync();
const UserRoleWatcher = (props: {
  assignRole?: () => void;
  openDialog?: () => void;
}) => {
  const roles = useSelector(state => state.app.userRoles);
  useEffect(() => {
    if (roles?.length && !localRole?.name) {
      props?.assignRole();
      _.isFunction(props?.openDialog) && props.openDialog();
    }
  }, [roles, localRole?.name]);

  return <></>;
};

export class LoginBlade extends React.Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props);
    this.state = {
      signIn: {
        email: "",
        password: ""
      },
      signUp: {
        confirmPassword: "",
        email: "",
        password: ""
      },
      showPasswordResetFlow: false,
      emailReset: "",
      isEmailSent: false
    };
  }
  public componentDidMount(): void {
    this.props.appDispatch.deviseActions.requestVerifyCredentials();
  }
  componentDidUpdate(): void {
    if (this.props.appState?.isSignedIn) {
      const roles: IUserRole[] = this.props.appState.roles;
      if (roles.length === 1) {
        this.props.appDispatch.appActions.updateActiveCompany(roles[0].token);
        saveUserRoleTypeToLocalSync(roles[0].type);
      }
    }
  }
  public login = () => {
    this.props.appDispatch.deviseActions.requestSignIn(this.state.signIn);
  };
  public requestNewPassword = () => {
    const gateway = getGateway();
    const data = {
      email: this.state.emailReset,
      redirect_url: document.location.origin + "/auth/password_update"
    };
    if (!this.state.emailReset) {
      alert("Email field can't be empty.");
      return;
    }
    gateway.request
      .requireNewPassword(data)
      .then(res => {
        if (res && res.error) {
          alert("Invalid email address");
        }
        if (res.data && res?.data?.success) {
          this.setState({ isEmailSent: true });
        }
      })
      .catch(err => console.error(err));
  };
  public onSignUp = (e: React.FormEvent) => {
    e.preventDefault();
  };
  public onSignIn = (e: React.FormEvent) => {
    e.preventDefault();
    this.login();
  };
  public updateSignUpTextField = (type: keyof ILoginState["signUp"]) => (
    updatedValue: string
  ) => {
    const newState = _.cloneDeep(this.state);
    newState.signUp[type] = updatedValue;
    this.setState(newState);
  };
  public updateSignInTextField = (type: keyof ILoginState["signIn"]) => (
    updatedValue: string
  ) => {
    const newState = { ...this.state };
    newState.signIn[type] = updatedValue;
    this.setState(newState);
  };
  public onRequestedPasswordReset = (e: React.FormEvent) => {
    e.preventDefault();
    this.requestNewPassword();
  };

  public renderSignUp = () => {
    return (
      <form onSubmit={this.onSignUp} style={{ flex: 1 }}>
        <Flex
          className="signUpContainer"
          flex={1}
          flexDirection={"column"}
          justifyContent={"center"}
          marginTop={"5px"}
        >
          <ElmInput
            updatedValue={this.updateSignUpTextField("email")}
            label={"Email"}
            placeholder={"youremail@email.com"}
            style={{ marginBottom: "16px" }}
          />
          <ElmInput
            label={"Password"}
            placeholder={"************"}
            type={"password"}
            updatedValue={this.updateSignUpTextField("password")}
            style={{ marginBottom: "16px" }}
          />
          <ElmInput
            label={"Confirm Password"}
            updatedValue={this.updateSignUpTextField("confirmPassword")}
            placeholder={"************"}
            type={"password"}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px"
            }}
          >
            <ElmButton
              style={{
                minWidth: "100%",
                maxHeight: "32px",
                padding: "9px",
                margin: "0px"
              }}
              label={"Sign Up"}
            />
          </div>
        </Flex>
      </form>
    );
  };
  public renderSignIn = () => {
    return (
      <form onSubmit={this.onSignIn} style={{ flex: 1 }}>
        <Flex
          className="signInContainer"
          flex={1}
          flexDirection={"column"}
          justifyContent={"space-between"}
          marginTop={FormPadding}
          paddingBottom={FormPadding}
          height={"100%"}
        >
          <div>
            <ElmInput
              updatedValue={this.updateSignInTextField("email")}
              label={"Email"}
              placeholder={"youremail@email.com"}
              error={this.props.appState.signedInFailed}
              style={{ marginBottom: "12px" }}
            />
            <ElmInput
              label={"Password"}
              placeholder={"************"}
              type={"password"}
              updatedValue={this.updateSignInTextField("password")}
              error={this.props.appState.signedInFailed}
              errorMessage={"Wrong credentials"}
            />
          </div>
          <Flex alignItems="center" flexDirection="column">
            <ElmButton
              disabled={this.props.appState.isLoading}
              type={"submit"}
              label={"Sign In"}
              style={{
                minWidth: "100%",
                maxHeight: "32px",
                padding: "9px",
                margin: "0px 0px 8px 0px"
              }}
            />
            <ForgotPassword
              onClick={() => this.setState({ showPasswordResetFlow: true })}
            >
              Forgot password ?
            </ForgotPassword>
          </Flex>
        </Flex>
      </form>
    );
  };
  public renderPasswordReset = () => {
    return (
      <form
        onSubmit={this.onRequestedPasswordReset}
        style={{ display: "flex", flex: 1 }}
      >
        <Flex
          className="signInContainer"
          flex={1}
          flexDirection={"column"}
          justifyContent={"space-between"}
          marginTop={FormPadding}
          paddingBottom={FormPadding}
          height={"100%"}
        >
          <ElmInput
            updatedValue={value =>
              this.setState({ ...this.state, emailReset: value })
            }
            label={"Email"}
            type={"email"}
            required
            placeholder={"Enter your email address"}
          />

          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
            style={{
              marginTop: "40px"
            }}
          >
            <ElmButton
              type={"submit"}
              label={"Reset password"}
              style={{
                paddingRight: "16px",
                paddingLeft: "16px",
                maxWidth: "150px",
                minWidth: "100%",
                maxHeight: "32px",
                padding: "9px",
                margin: "0px 0px 8px 0px"
              }}
            />
            <ForgotPassword
              onClick={() => this.setState({ showPasswordResetFlow: false })}
            >
              Return to sign in
            </ForgotPassword>
          </Flex>
        </Flex>
      </form>
    );
  };
  /**
   * Not used since we do have /auth/register page now
   
  public getTabs = (): ITab[] => {
    const tabStyle = {
      lineHeight: "14px",
      fontSize: "14px",
      padding: "12px",
      marginRight: "0px"
    };
    return [
      {
        title: "Sign In",
        Component: this.renderSignIn(),
        style: tabStyle
      },

      {
        title: "Sign Up",
        Component: this.renderSignUp(),
        style: tabStyle
      }
    ];
  };
  */
  public closeDialog: IBladeBaseProps["closeDialog"] = name => {
    this.props.appDispatch.bladeManagerActions.closeDialog({ name });
  };
  public assignRole = () => {
    if (this.props.appState?.roles?.length === 1) {
      saveUserRoleTypeToLocalSync((this.props.appState?.roles[0] as any)?.type);
      this.props.appDispatch.appActions.updateActiveCompany(
        (this.props.appState?.roles[0] as any)?.name
      );
      return;
    }
  };
  public render() {
    if (
      this.props.appState.isSignedIn &&
      this.props.appState.activeRole?.name
    ) {
      let path = "";
      if (this.props.location.state) {
        path = _.get(this.props.location.state, "from") || path;
      }
      return (
        <Redirect
          to={{
            pathname: path
          }}
        />
      );
    }
    const openDialog = () => {
      this.assignRole();
      this.props.appDispatch.bladeManagerActions.openDialog({
        name: "RoleSelectorDialog",
        payload: {
          roles: this.props.appState.roles,
          onConfirm: ({ name, type }) => {
            saveUserRoleTypeToLocalSync(type);
            this.props.appDispatch.appActions.updateActiveCompany(name);
            this.props.appDispatch.bladeManagerActions.closeDialog({
              name: "RoleSelectorDialog"
            });
            window.location.reload();
          },
          onCancel: () => {
            localStorage.clear();
            this.props.history.push("/login");
            window.location.reload();
          }
        }
      });
    };
    return (
      <LoginContainer
        flex={1}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <AppModalConductor closeModal={this.closeDialog} />
        <UserRoleWatcher assignRole={this.assignRole} />
        <LoginBox>
          <img
            src={
              this.props.appState.activeMode === "light"
                ? elmLogoLight
                : elmLogoDark
            }
            style={{ display: "flex", justifySelf: "self", height: "82px" }}
            alt="Elm logo"
          />
          <TagLine>a better way to license your software</TagLine>
          {this.state.showPasswordResetFlow ? (
            <>
              {this.state.isEmailSent ? (
                <Flex
                  flexDirection="column"
                  justifyContent="flex-end"
                  alignItems="center"
                  flex={1}
                >
                  <InfoMessage
                    icon={
                      <BulbContainer>
                        <BulbIcon />
                      </BulbContainer>
                    }
                    showIcon
                    description="An email has been sent with password reset instructions."
                    type="info"
                    style={{ marginBottom: "40%" }}
                  />
                  <ForgotPassword
                    style={{ marginTop: "1em" }}
                    onClick={() =>
                      this.setState({ showPasswordResetFlow: false })
                    }
                  >
                    Return to sign in
                  </ForgotPassword>
                </Flex>
              ) : (
                this.renderPasswordReset()
              )}
            </>
          ) : (
            this.renderSignIn()
            // <TabView
            //   defaultTab={0}
            //   tabList={this.getTabs()}
            //   style={{ height: "291px", marginTop: "0px" }}
            // />
          )}
        </LoginBox>
      </LoginContainer>
    );
  }
}

export default appConnect(LoginBlade, {
  selectors: {
    isSignedIn: "isSignedInSelector",
    isLoading: "isUserLoadingSelector",
    signedInFailed: "signedInFailed",
    roles: "userRolesSelector",
    activeRole: "activeRoleSelector",
    activeMode: "activeModeSelector"
  }
});
