/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseChangeLogTableQueryVariables = {
  blade_scope: string;
};
export type licenseChangeLogTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<
    "licenseChangeLogTable_licenseActions"
  >;
};
export type licenseChangeLogTableQuery = {
  readonly response: licenseChangeLogTableQueryResponse;
  readonly variables: licenseChangeLogTableQueryVariables;
};

/*
query licenseChangeLogTableQuery(
  $blade_scope: String!
) {
  ...licenseChangeLogTable_licenseActions_6Y2LU
}

fragment licenseChangeLogTable_licenseActions_6Y2LU on Query {
  licenseActions(bladeScope: $blade_scope, sortColumn: ["createdAt"], sortDirection: ["desc"]) {
    edges {
      node {
        description
        createdAt
        vendorSystemUser {
          name
          email
          id
        }
        id
        requireApproval
        status
        parameters
        comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "blade_scope"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "blade_scope"
      } as any,
      {
        kind: "Literal",
        name: "sortColumn",
        value: ["createdAt"]
      } as any,
      {
        kind: "Literal",
        name: "sortDirection",
        value: ["desc"]
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "licenseChangeLogTableQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "blade_scope",
              variableName: "blade_scope"
            }
          ],
          kind: "FragmentSpread",
          name: "licenseChangeLogTable_licenseActions"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "licenseChangeLogTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "LicenseActionConnection",
          kind: "LinkedField",
          name: "licenseActions",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "LicenseActionEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "LicenseAction",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "description",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "createdAt",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "VendorSystemUser",
                      kind: "LinkedField",
                      name: "vendorSystemUser",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "name",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "email",
                          storageKey: null
                        },
                        v2 /*: any*/
                      ],
                      storageKey: null
                    },
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "requireApproval",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "status",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "parameters",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "comment",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v1 /*: any*/,
          filters: [
            "bladeScope",
            "filterColumn",
            "filterOp",
            "filterValues",
            "search",
            "sortColumn",
            "sortDirection"
          ],
          handle: "connection",
          key: "licenseChangeLogTable_licenseActions",
          kind: "LinkedHandle",
          name: "licenseActions"
        }
      ]
    },
    params: {
      cacheID: "d11db88cc74868277760e6a654b209d7",
      id: null,
      metadata: {},
      name: "licenseChangeLogTableQuery",
      operationKind: "query",
      text:
        'query licenseChangeLogTableQuery(\n  $blade_scope: String!\n) {\n  ...licenseChangeLogTable_licenseActions_6Y2LU\n}\n\nfragment licenseChangeLogTable_licenseActions_6Y2LU on Query {\n  licenseActions(bladeScope: $blade_scope, sortColumn: ["createdAt"], sortDirection: ["desc"]) {\n    edges {\n      node {\n        description\n        createdAt\n        vendorSystemUser {\n          name\n          email\n          id\n        }\n        id\n        requireApproval\n        status\n        parameters\n        comment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "3eae85eed279ca91ce546c0a1ed1248d";
export default node;
