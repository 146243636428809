import React from "react";
import { RouteChildrenProps } from "react-router-dom";
import { appDispatch } from "../../store/appConnect";
import { ActiveMode, IOnboardingState, IUserRole } from "../../store/types";
import { getGateway } from "../../api";
import { AnalyticsComponentProps } from "../analyticsComponent";
import { modalTypes } from "./appDialog/types";
import bladeTypes from "./bladeTypes";
import { IUpdateBladeManagerUrl } from ".";
import { EntityRefreshMap } from "./entityRefreshMap";

export type AppContext = {
  refreshCount: number;
};

export type EmailNotification = {
  category: string;
  label: string;
  enabled: boolean;
  frequency: number[];
  reasons: { reason: string; label: string; enabled: boolean }[];
};
export interface IBladeManagerProps extends RouteChildrenProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    isAuthenticated: boolean;
    email: string;
    activeRole: IUserRole;
    activeMode: ActiveMode;
    userDefinedMode: boolean;
    onboardingMode: IOnboardingState;
  };
  [props: string]: any;
}
export interface IBladeManagerState {
  appHasError: boolean;
  error: Error | null;
  loader: {
    show: boolean;
  };
  userInfo: {
    id: number;
    email: string;
    role: string;
    name: string;
    avatar_url: string;
    thumb_url: string;
  };
}

export type bladeType = keyof typeof bladeTypes;

export interface IOpen {
  route: bladeType;
  routeData: {
    id?: string;
    releaseId?: string;
    tabIndex?: number;
    //onConfirm?: () => void;
  };
  routeName: string;
  navigationType?: "reset" | "replace";
  fromBladeIndex: number;
}
export interface IOpenLicenseBlade extends IOpen {
  route: "License";
  routeData: {
    id: string;
    blade_scope?: string;
    productId?: string;
    tabIndex?: number;
    isBladeUsedForReview?: boolean;
    licenseAction?: string;
    havePendingActions?: boolean;
    openInChangelogTab?: boolean;
  };
}

export interface IOpenApprovalBlade extends IOpen {
  route: "Approval";
  routeData: null;
}

export interface IOpenAddLicenseBlade extends IOpen {
  route: "AddLicense";
  routeData: {
    id?: string;
    templateId?: string;
    isUsedForTemplates?: boolean;
  };
}

export interface IOpenLicenseComponentBlade extends IOpen {
  route: "LicenseComponent";
  routeData: {
    id: string;
    licenseId?: string;
    name: string;
  };
}
export interface IOpenProductReleaseConfigBlade extends IOpen {
  route: "ProductReleaseConfig";
  routeData: {
    id: string;
    name: string;
  };
}
export interface IOpenProductReleaseChannelBlade extends IOpen {
  route: "ProductReleaseChannel";
}
export interface IOpenProductComponentBlade extends IOpen {
  route: "ProductComponent";
  routeData: {
    id: string;
    licenseId?: string;
    name?: string;
    isGroup?: boolean;
  };
}
export interface IOpenNewEntitlementBlade extends IOpen {
  route: "NewEntitlement";
  routeData: {
    id: null;
    productId: string;
    licenseId: string;
    entitlementId?: string;
  };
}
export interface IOpenEntitlementBlade extends IOpen {
  route: "Entitlement";
  routeData: {
    id: null;
    productId: string;
    entitlementId: string;
    licenseId: string;
    availableComponents?: string[];
  };
}
export interface IOpenLicensesBlade extends IOpen {
  route: "Licenses";
  routeData: null;
}
export interface IOpenReportsBlade extends IOpen {
  route: "Reports";
  routeData: null;
}
export interface IOpenReportBlade extends IOpen {
  route: "Report";
  routeData: { id: string; name?: string };
}
export interface IOpenAddReportBlade extends IOpen {
  route: "AddReport";
  routeData: { id?: string; name?: string };
}
export interface IOpenNewProductBlade extends IOpen {
  route: "NewProduct";
  routeData: null;
}
export interface IOpenProductsBlade extends IOpen {
  route: "Products";
  routeData: null;
}
export interface IOpenProductBlade extends IOpen {
  route: "Product";
  routeData: {
    id: string;
    name?: string;
  };
}
export interface IOpenCompaniesBlade extends IOpen {
  route: "Companies";
  routeData: null;
}
export interface IOpenCompanyBlade extends IOpen {
  route: "Company";
  routeData: {
    id: string;
    name?: string;
  };
}
export interface IOpenUsersBlade extends IOpen {
  route: "Users";
  routeData: null;
}
export interface IOpenUserBlade extends IOpen {
  route: "User";
  routeData: {
    id: string;
    name?: string;
    tabIndex?: number;
  };
}
export interface IOpenOverviewBlade extends IOpen {
  route: "Overview";
  routeData: null;
}

export interface IOpenDashboardBlade extends IOpen {
  route: "Dashboard";
  routeData: null;
}
export interface IOpenAnalyticsBlade extends IOpen {
  route: "Analytics";
  routeData: {
    type: bladeType;
    title?: string;
    imageSrc?: string;
    subTitle?: string;
    id?: string;
    allTimeStartDate?: string;
  };
}
export interface IOpenAccountBlade extends IOpen {
  route: "Account";
  routeData: {
    id?: string;
    showTourGuide: boolean;
    stepsType: string | null;
  };
}
export interface IOpenConfigurationBlade extends IOpen {
  route: "Configuration";
}

export interface IOpenWelcomeBlade extends IOpen {
  route: "Welcome";
}
export interface IOpenTosBlade extends IOpen {
  route: "Tos";
}
export interface IOpenInstanceBlade extends IOpen {
  route: "Instance";
}
export interface IOpenProductReleaseBlade extends IOpen {
  route: "ProductRelease";
}

export interface IOpenEmailPreferencesBlade extends IOpen {
  route: "EmailPreferences";
  routeData: {
    id?: string;
    releaseId?: string;
    userId: number;
    emailPreferences: EmailNotification[];
  };
}

export type IOpenBlade =
  | IOpenDashboardBlade
  | IOpenOverviewBlade
  | IOpenAccountBlade
  | IOpenAnalyticsBlade
  | IOpenUsersBlade
  | IOpenUserBlade
  | IOpenLicensesBlade
  | IOpenLicenseComponentBlade
  | IOpenLicenseBlade
  | IOpenCompaniesBlade
  | IOpenCompanyBlade
  | IOpenNewProductBlade
  | IOpenProductsBlade
  | IOpenProductBlade
  | IOpenProductReleaseBlade
  | IOpenProductReleaseConfigBlade
  | IOpenProductReleaseChannelBlade
  | IOpenProductComponentBlade
  | IOpenReportsBlade
  | IOpenReportBlade
  | IOpenAddReportBlade
  | IOpenTosBlade
  | IOpenInstanceBlade
  | IOpenNewEntitlementBlade
  | IOpenEntitlementBlade
  | IOpenConfigurationBlade
  | IOpenWelcomeBlade
  | IOpenEmailPreferencesBlade
  | IOpenAddLicenseBlade
  | IOpenApprovalBlade;

type AllowedChartBlade = (
  | IOpenLicenseBlade
  | IOpenUserBlade
  | IOpenProductBlade
  | IOpenCompanyBlade
  | IOpenProductComponentBlade
)["route"];
export const AllowedChartBladeTypes: readonly AllowedChartBlade[] = [
  "License",
  "User",
  "Product",
  "Company",
  "ProductComponent"
] as const;
export const OPEN_FROM_DASHBOARD_INDEX = -1;
export interface IOpenBladeFromChart {
  id: string;
  name: string;
  bladeType: AllowedChartBlade;
}
export interface IBladeManagerRoutesState {
  openBlades: IOpenBlade[];
}

export type TOpenDialog = (
  name: modalTypes,
  payload?: {
    onConfirm?: (state: any) => void;
    [key: string]: any;
  }
) => void;

export interface IBladeBaseProps<T extends IOpenBlade = IOpenBlade> {
  gateway: ReturnType<typeof getGateway>;
  setRefreshFn: (fn: any) => void;
  setEntityRefreshFn: (entity: EntityRefreshMap) => (fn: any) => void;
  removeRefreshFn: (entity: EntityRefreshMap) => void;
  refreshBlade: (route: IOpen["route"]) => void;
  refreshAllOpenBlades: () => void;
  index: number;
  isVendor?: boolean;
  routeData: T["routeData"];
  closeBlade: (
    payload:
      | {
          route?: IOpen["route"];
          fromBladeIndex: number;
        }
      | {
          route: IOpen["route"];
          fromBladeIndex?: number;
        }
  ) => void;
  openBlade: (payload: IOpenBlade) => void;
  closeDialog: (name: modalTypes) => void;
  updateBladeManagerState: (payload: IUpdateBladeManagerUrl) => void;
  openDialog: TOpenDialog;
  showScreenLoader: () => void;
  hideScreenLoader: () => void;
}
