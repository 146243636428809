import _ from "lodash";
import { Flex } from "reflexbox/styled-components";
import React, { Suspense, useState } from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay";
import relayEnvironment from "../../api/relay";
import {
  ActionNode,
  ActionNodes,
  generateItem,
  graphqlQuery,
  isApprovableAction
} from "./Approval";
import { useSelector } from "react-redux";
import LoadingWrapper from "../../components/elmChart/loadingWrapper";
import { ApprovalActionListQuery } from "./__generated__/ApprovalActionListQuery.graphql";
import { IOpenBlade } from "components/bladeManager/types";
import { Dropdown } from "antd";
import styled from "styled-components";
import { getIcon } from "components/icons";
import { selectors } from "store/ducks/app";
import { checkPermissions } from "components/helpers";

const WarningSignIcon = styled.p`
  background: #eb5740;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  position: relative;
  bottom: 10px;
`;

const ApprovalListWrapper = styled(Flex)`
  background-color: ${props => props.theme.colors.filterBackground};
  width: 384px;
  height: 348px;
  padding: 12px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  flex-direction: column;
`;

const ViewAllButton = styled.span`
  align-items: center;
  justify-content: left;
  font-size: ${props => props.theme.fontSizes.ySmall};
  margin-top: 12px;
  color: ${props => props.theme.colors.warmGrey};
`;

interface ApprovalItemProps {
  openBlade: (p: IOpenBlade) => void;
  bladeIndex: number;
  openDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const ApprovalPendingItems: React.FC<ApprovalItemProps> = ({
  openBlade,
  bladeIndex,
  openDropdown
}) => {
  const res = useLazyLoadQuery(graphqlQuery, {
    sortColumn: ["createdAt"],
    sortDirection: ["DESC"]
  });

  const nodes = _.get(res, "licenseActions.nodes", []) as ActionNodes;

  const executeLicenseAction = (action: ActionNode, action_type: string) => {
    openBlade({
      route: "License",
      routeName: "License",
      fromBladeIndex: bladeIndex,
      routeData: {
        id: action?.license.id,
        productId: action?.license?.product?.id,
        openInChangelogTab: true
      }
    });
    openDropdown(false);
  };
  const items = nodes
    .filter(item => item.requireApproval === true)
    .map(item =>
      generateItem({
        node: item,
        callToActionCallback: executeLicenseAction
      })
    );
  return (
    <Flex
      flexDirection="column"
      flex={1}
      height={600}
      style={{ overflowY: "scroll" }}
    >
      {items}
    </Flex>
  );
};

const PendingIcon: React.FC = () => {
  const res = useLazyLoadQuery<ApprovalActionListQuery>(graphqlQuery, {});

  const nodes = res?.licenseActions?.nodes?.filter(
    item => item.requireApproval === true && isApprovableAction(item)
  );

  return nodes?.length ? <WarningSignIcon /> : null;
};

const ApprovalPendingList: React.FC<{
  openBlade: (p: IOpenBlade) => void;
  index: number;
}> = ({ openBlade, index }) => {
  const NotificationBell = styled(getIcon("bell", "fas"))`
    color: #ffffff;
    margin-left: 31px;
  `;

  const [isDropdownOpen, openDropdown] = useState<boolean>(false);

  const permissions = useSelector(selectors.activeUserPermissions);
  const havePermissions = checkPermissions({
    userPermissions: permissions,
    requiredPermissions: ["modify_licenses"]
  });

  return (
    <Dropdown
      overlayClassName="dropdownNotifications"
      overlayStyle={{ cursor: "pointer" }}
      trigger={["hover"]}
      open={isDropdownOpen}
      dropdownRender={() => (
        <ApprovalListWrapper className="notificationsContainer">
          <RelayEnvironmentProvider environment={relayEnvironment}>
            <LoadingWrapper>
              <ApprovalPendingItems
                openBlade={openBlade}
                bladeIndex={index}
                openDropdown={openDropdown}
              />
            </LoadingWrapper>
          </RelayEnvironmentProvider>
          <Flex>
            <ViewAllButton
              onClick={() => {
                openDropdown(false);
                openBlade({
                  routeName: "Approval",
                  fromBladeIndex: 0,
                  route: "Approval",
                  navigationType: "reset",
                  routeData: null
                });
              }}
            >
              View all actions
            </ViewAllButton>
          </Flex>
        </ApprovalListWrapper>
      )}
    >
      <Flex
        style={{ cursor: "pointer", alignItems: "center" }}
        onClick={() => openDropdown(!isDropdownOpen)}
      >
        <RelayEnvironmentProvider environment={relayEnvironment}>
          <Suspense fallback={<NotificationBell />}>
            <NotificationBell />
            {havePermissions ? <PendingIcon /> : null}
          </Suspense>
        </RelayEnvironmentProvider>
      </Flex>
    </Dropdown>
  );
};

export default ApprovalPendingList;
