import React, { useLayoutEffect, useRef, useState } from "react";
import uuid from "uuid";
import { IChart } from "./types";
import { useChartData } from "./hooks";
import { customChartTheme, exportMenuItems } from "./helpers";
import _ from "lodash";
import FiltersContainer from "./filtersContainer";
import { useTheme } from "styled-components";
import NoDataPlaceholder from "./noDataPlaceholder";
import moment from "moment";
import { AiFillHtml5 } from "react-icons/ai";

function getFormattedDate(
  date: moment.Moment,
  period: "day" | "week" | "month"
) {
  switch (period) {
    case "day":
      return date.format("MMM DD, YYYY");
    //return date.format("YYYY-MM-DD");
    case "week":
      return date.format("YYYY-[W]WW");
    case "month":
      return date.format("YYYY MMMM");
    //return date.format("YYYY-MM");
  }
}
function groupUserIdsByRegistrationTime(
  payload: { userId: number; registrationTime: string }[],
  period: "day" | "week" | "month"
) {
  const grouped = _.groupBy(payload, item => {
    const date = moment(item.registrationTime);
    return getFormattedDate(date, period);
  });
  return grouped;
}

function createCohortData(
  registrationData: { userId: number; registrationTime: string }[],
  usageData: { userId: number; time: string; sessionCount: number }[],
  period: "day" | "week" | "month"
) {
  const grouped = groupUserIdsByRegistrationTime(registrationData, period);

  const startDate = moment(
    _.minBy(registrationData, item => item?.registrationTime)!.registrationTime
  );
  const endDate = moment(_.maxBy(usageData, item => item.time)!.time);

  const chartValuesPerCategory: number[][] = [];
  const cohorts: any[] = [];

  let currentDate = startDate;
  while (currentDate.isBefore(endDate.clone().add(1, period))) {
    const cohort = getFormattedDate(currentDate, period);
    const cohortUsers = grouped[cohort];
    if (cohortUsers) {
      cohorts.push(cohortUsers);
      chartValuesPerCategory.push([]);
    }

    _.each(cohorts, (thisCohort, index) => {
      const cohortUsageData = _.filter(
        usageData,
        item =>
          thisCohort.find(cohortItem => cohortItem.userId === item.userId) &&
          moment(item.time).isSame(moment(cohort)) &&
          item.sessionCount > 0
      );
      const cohortUsageCount = _.uniqBy(cohortUsageData, item => item.userId)
        ?.length;
      const cohortUserCount = thisCohort?.length;
      chartValuesPerCategory[index].push(cohortUsageCount / cohortUserCount);
    });
    currentDate = currentDate.clone().add(1, period);
  }
  return chartValuesPerCategory;
}

function consolidateData(
  chartData: { [key: string]: any }[],
  maxCategories: number,
  maxGroups: number
) {
  const getAggregates = (key: string) => {
    var keys = _.uniq(_.map(chartData, c => _.get(c, key)));
    return _.map(keys, k => {
      const filtered = _.filter(chartData, c => c[key] == k);
      const sum = _.sumBy(filtered, f => f["value"]);
      let ret = {};
      ret[key] = k;
      ret["value"] = sum;
      return ret;
    });
  };
  const categoryAggregates = _.reverse(
    _.sortBy(getAggregates("category"), c => c["value"])
  );
  const groupAggregates = _.reverse(
    _.sortBy(getAggregates("group"), c => c["value"])
  );
  const topCategories = _.map(
    _.slice(categoryAggregates, 0, maxCategories),
    c => c["category"]
  );
  const topGroups = _.map(
    _.slice(groupAggregates, 0, maxGroups),
    c => c["group"]
  );
  let ret: { [key: string]: any }[] = [];
  _.each(chartData, c => {
    let category = c["category"];
    let group = c["group"];
    //if (!_.includes(topCategories, category)) category = "Other";
    //if (!_.includes(topGroups, group)) group = "Other";
    const value = c["value"];
    if (category !== "Other" && group !== "Other")
      ret.push({ category, group, value });
    else {
      const existing = _.find(
        ret,
        r => r["category"] == category && r["group"] == group
      );
      if (existing) {
        existing["value"] += value;
      } else ret.push({ category, group, value });
    }
  });
  return ret;
}

function CohortChartComponent(
  props: IChart & {
    registrationData?: any[];
    timePeriod?: "day" | "week" | "month";
  }
) {
  const theme = useTheme();
  const hasData = useRef();

  let instanceId = uuid();

  const { data } = useChartData({
    ...props.queryParams,
    useLocalData: props.data ? true : false
  });
  let usageData = [] as {
    userId: number;
    time: string;
    sessionCount: number;
  }[];

  data?.analytics?.values.map(item => {
    usageData.push({
      userId: item.group,
      time: getFormattedDate(moment(item.category), props.timePeriod),
      sessionCount: item.value
    });
  });

  let chartValuesPerCategory = [];
  let categories = [] as string[];
  let cohortChartData = [] as {
    category: string;
    value: number;
    group: number;
  }[];

  if (props?.registrationData?.length !== 0) {
    chartValuesPerCategory = createCohortData(
      props?.registrationData,
      usageData,
      props.timePeriod
    );
    const groupedByDates = groupUserIdsByRegistrationTime(
      props?.registrationData,
      props.timePeriod
    );
    Object.entries(groupedByDates).forEach(([key, value]) =>
      categories.push(key)
    );
    categories = categories.reverse();

    chartValuesPerCategory.map((categoryValues, i) => {
      categoryValues.map((item, index) => {
        cohortChartData.push({
          category: categories[i],
          value: Number(item.toFixed(1)) * 100,
          group: index
        });
      });
    });
  }
  // This code will only run one time
  useLayoutEffect(() => {
    let root = am5.Root.new(instanceId);

    let myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll(customChartTheme);

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    if (root._logo) {
      root._logo._privateSettings.visible = false;
    }

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        minHeight: 110,
        maxHeight: props.options?.chartMaxHeight || 170,
        layout: root.verticalLayout
      })
    );

    var yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 30 });

    yRenderer.grid.template.set("visible", false);
    yRenderer.labels.template.setAll({
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      fill: theme.chartColors.labels,
      inversed: true,
      text: `{category}`
      // add rotation
    });
    yRenderer.setAll({
      stroke: theme.chartColors.axes
    });
    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        visible: _.first(props.plot?.yAxes)?.hideLabel ? false : true //show/hide y labels
      })
    );

    const maxGroups = props.options?.maxGroups || 12;
    const maxCategories = props.options?.maxCategories || 6;

    const consolidatedData = consolidateData(
      cohortChartData,
      maxCategories,
      maxGroups
    );

    var xRenderer = am5xy.AxisRendererX.new(root, {
      visible: false,
      minGridDistance: 30,
      opposite: true
    });
    xRenderer.grid.template.set("visible", false);
    xRenderer.labels.template.setAll({
      visible: true
    });
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "group"
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      fill: theme.chartColors.labels,
      text: `${props.timePeriod.charAt(0).toUpperCase() +
        props.timePeriod.slice(1)} {group}`
    });

    xAxis.get("renderer").setAll({
      stroke: theme.chartColors.axes
    });

    function createSeries(field, name) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: field,
          xAxis: xAxis,
          yAxis: yAxis,
          stroke: am5.color(0xffffff),
          categoryXField: "group",
          categoryYField: "category",
          valueField: "value",
          calculateAggregates: true
        })
      );

      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{value}%",
            fill: am5.color(0xffffff),
            fontSize: 13,
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            textAlign: "center",
            populateText: true
          })
        });
      });

      series.columns.template.setAll({
        tooltipText: `${props.timePeriod.charAt(0).toUpperCase() +
          props.timePeriod.slice(1)} {categoryX}: {value}%`,
        strokeOpacity: 0.2,
        strokeWidth: 1,
        stroke: root.interfaceColors.get("background"),
        width: am5.percent(100),
        height: am5.percent(100)
      });

      var cellSize = 30;
      series.events.on("datavalidated", function(ev) {
        var series = ev.target;
        var chart = series.chart;
        //var xAxis = chart.xAxes.getIndex(0);
        var yAxis = chart.yAxes.getIndex(0);

        // Calculate how we need to adjust chart height
        var chartHeight =
          categories.length * cellSize +
          yAxis.height() +
          chart.get("paddingTop", 0) +
          chart.get("paddingBottom", 0);

        chart.root.dom.style.height = chartHeight + "px";
      });

      series.data.setAll(cohortChartData.reverse());
      yAxis.data.setAll(
        _.map(_.uniq(_.map(cohortChartData, c => c.category)), c => ({
          category: c
        }))
      );
      let groups = _.uniq(_.map(cohortChartData, c => c.group));
      const otherIndex = _.findIndex(groups, c => c == "Other");
      if (otherIndex >= 0) {
        groups.splice(otherIndex, 1);
        groups = _.sortBy(groups, g => g).concat(["Other"]);
      } else groups = _.sortBy(groups, g => g);
      xAxis.data.setAll(
        _.map(groups, c => ({
          group: c
        }))
      );
      series.set("heatRules", [
        {
          target: series.columns.template,
          min: am5.color(0x68b6e8),
          max: am5.color(0x0f649a),
          dataField: "value",
          key: "fill"
        }
      ]);

      series.appear();

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          //container: document.getElementById("exportdiv")
        }),
        dataSource: consolidatedData
      });

      exporting.events.on("dataprocessed", function(ev) {
        // This will override export data, but will not modify original data source
        // const exData = [...consolidatedData];
        // let test = exData.map((item) => {
        //   return item;
        // });
        // ev.data = test;
      });

      exporting.get("menu").set("items", exportMenuItems);

      // !props.plot.hideLegend && legend.data.push(series);
      return series;
    }
    createSeries("category", "value");
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [
    data,
    instanceId,
    props.data,
    props.options?.maxCategories,
    props.options?.maxGroups,
    props.plot?.yAxes,
    props.registrationData,
    props.timePeriod,
    theme
  ]);

  hasData.current = _.get(data, "analytics.values", []).length;

  return (
    <>
      <div
        id={instanceId}
        style={{
          width: "100%",
          minHeight: "inherit"
        }}
        hidden={cohortChartData.length === 0 ? true : false}
      ></div>
      {cohortChartData.length === 0 && <NoDataPlaceholder />}
    </>
  );
}

export default function CohortChart(
  props: IChart & {
    registrationData?: any[];
    timePeriod?: "day" | "week" | "month";
  }
) {
  return (
    <FiltersContainer containerProps={props} Component={CohortChartComponent} />
  );
}
