/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type fragments_companiesTable = {
  readonly companiesCount: number;
  readonly companiesTableCompanies: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly activeUserCount: number;
        readonly licensesCount: number;
        readonly activeLicenseCount: number;
        readonly products?:
          | {
              readonly product: ReadonlyArray<{
                readonly name: string | null;
                readonly id: string;
                readonly iconUrl: string | null;
              } | null> | null;
            }
          | null
          | undefined;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $refType": "fragments_companiesTable";
};
export type fragments_companiesTable$data = fragments_companiesTable;
export type fragments_companiesTable$key = {
  readonly " $data"?: fragments_companiesTable$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"fragments_companiesTable">;
};

const node: ReaderFragment = (function() {
  var v0 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    v1 = {
      kind: "Variable",
      name: "filterColumn",
      variableName: "filterColumn"
    } as any,
    v2 = {
      kind: "Variable",
      name: "filterOp",
      variableName: "filterOp"
    } as any,
    v3 = {
      kind: "Variable",
      name: "filterValues",
      variableName: "filterValues"
    } as any,
    v4 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "blade_scope"
      },
      {
        defaultValue: 25,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: ["companies.active_license_count"],
        kind: "LocalArgument",
        name: "filterColumn"
      },
      {
        defaultValue: [[">"]],
        kind: "LocalArgument",
        name: "filterOp"
      },
      {
        defaultValue: [["0"]],
        kind: "LocalArgument",
        name: "filterValues"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      },
      {
        defaultValue: false,
        kind: "LocalArgument",
        name: "skipProduct"
      },
      {
        defaultValue: ["companies.name"],
        kind: "LocalArgument",
        name: "sortColumn"
      },
      {
        defaultValue: ["asc"],
        kind: "LocalArgument",
        name: "sortDirection"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["companiesTableCompanies"]
        }
      ]
    },
    name: "fragments_companiesTable",
    selections: [
      {
        alias: null,
        args: [
          v0 /*: any*/,
          v1 /*: any*/,
          v2 /*: any*/,
          v3 /*: any*/,
          v4 /*: any*/
        ],
        kind: "ScalarField",
        name: "companiesCount",
        storageKey: null
      },
      {
        alias: "companiesTableCompanies",
        args: [
          v0 /*: any*/,
          v1 /*: any*/,
          v2 /*: any*/,
          v3 /*: any*/,
          v4 /*: any*/,
          {
            kind: "Variable",
            name: "sortColumn",
            variableName: "sortColumn"
          },
          {
            kind: "Variable",
            name: "sortDirection",
            variableName: "sortDirection"
          }
        ],
        concreteType: "CompanyConnection",
        kind: "LinkedField",
        name: "__companiesTable_companiesTableCompanies_connection",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "CompanyEdge",
            kind: "LinkedField",
            name: "edges",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "Company",
                kind: "LinkedField",
                name: "node",
                plural: false,
                selections: [
                  v5 /*: any*/,
                  v6 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "activeUserCount",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "licensesCount",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "activeLicenseCount",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "__typename",
                    storageKey: null
                  },
                  {
                    condition: "skipProduct",
                    kind: "Condition",
                    passingValue: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        concreteType: "ProductConnection",
                        kind: "LinkedField",
                        name: "products",
                        plural: false,
                        selections: [
                          {
                            alias: "product",
                            args: null,
                            concreteType: "Product",
                            kind: "LinkedField",
                            name: "nodes",
                            plural: true,
                            selections: [
                              v6 /*: any*/,
                              v5 /*: any*/,
                              {
                                alias: null,
                                args: null,
                                kind: "ScalarField",
                                name: "iconUrl",
                                storageKey: null
                              }
                            ],
                            storageKey: null
                          }
                        ],
                        storageKey: null
                      }
                    ]
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "cursor",
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: "PageInfo",
            kind: "LinkedField",
            name: "pageInfo",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "hasNextPage",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "endCursor",
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "f2806f5266916248ca8791284a70b40f";
export default node;
