import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import { getIcon } from "../icons";
import { Flex } from "reflexbox";
//import moment from "moment";
import dayjs from "dayjs";
import theme from "../../theme";

const { RangePicker } = DatePicker;
// dayjs.updateLocale("en", {
//   weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"]
// });
const CalendarIcon = styled(getIcon("calendar-alt", "fal"))`
  .ant-picker:not(.ant-picker-focused) & {
    color: ${props => props.theme.colors.warmGrey};
  }
  .ant-picker.ant-picker-focused & {
    color: ${props => props.theme.colors.lightBlue};
  }
`;
const CalendarIconNode = () => <CalendarIcon />;

export declare type ElmDatePickerProps = Omit<DatePickerProps, "picker"> & {
  error?: boolean;
  errorMessage?: string;
  disableUnderline?: boolean;
};

export declare type ElmDateRangePickerProps = Omit<
  DatePickerProps,
  "picker"
> & {
  label?: string;
  error?: boolean;
  errorMessage?: string;
};

const ElmDatePickerBase: React.FunctionComponent<ElmDatePickerProps> = props => (
  <DatePicker
    {...props}
    suffixIcon={CalendarIconNode()}
    placeholder={props.placeholder || "mm/dd/yyyy"}
    allowClear={false}
    bordered={false}
    //picker="date"
    defaultValue={props.defaultValue}
  />
);
const ElmDatePicker = styled(ElmDatePickerBase)`
  &.ant-picker.ant-picker-borderless {
    padding: 0px;

    .ant-picker-input {
      border: none;
      border-radius: unset;
      border-bottom: 1px solid
        ${props =>
          props.error
            ? props.theme.colors.orangeRed
            : props.disableUnderline
            ? "transparent"
            : props.theme.colors.greyish} !important;
      :focus-within {
        outline: none;
        border: none;
        border-width: initial;
        border-bottom: 1px solid
          ${props =>
            props.error
              ? props.theme.colors.orangeRed
              : props.disableUnderline
              ? "transparent"
              : props.theme.colors.lightBlue} !important;
      }
      input {
        ${props => props.theme.fontType.input}
        border: none;
        padding: 1px;
        font-family: ${props => props.theme.font.main};
        :not(:placeholder-shown) {
          color: ${props => props.theme.colors.realBlack};
        }
      }
      input::placeholder {
        color: ${props => props.theme.colors.greyish};
      }
    }
  }
`;
export const ElmDatePickerWithLabel: React.FunctionComponent<ElmDatePickerProps & {
  label?: string;
  disableUnderline?: boolean;
}> = props => {
  return (
    <Flex className={"elm-date-picker-with-label"} flexDirection="column">
      {!!props.label ? <span className="label">{props.label}</span> : null}
      <ElmDatePicker {...props} />
      {props.status === "error" && (
        <span className="label" style={{ color: theme.colors.orangeyRed }}>
          {props.errorMessage}
        </span>
      )}
    </Flex>
  );
};
const ElmRangeDatePickerComponent = RangePicker;
const ElmRangeDatePicker = (props: ElmDateRangePickerProps) => (
  <Flex flexDirection="column" maxWidth={300}>
    {!!props.label ? <span className="label">{props.label}</span> : null}
    <ElmRangeDatePickerComponent separator={""} bordered={false} />
    {props.status === "error" && (
      <span className="label" style={{ color: theme.colors.orangeyRed }}>
        {props.errorMessage}
      </span>
    )}
  </Flex>
);
export { ElmRangeDatePicker, ElmDatePicker };
export default ElmDatePicker;
