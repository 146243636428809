/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type hooksfeaturesAndGroupsToggleQueryVariables = {
  licenseId: string;
  productId: string;
  skipLicense: boolean;
  skipProduct: boolean;
};
export type hooksfeaturesAndGroupsToggleQueryResponse = {
  readonly license?:
    | {
        readonly features: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
          } | null> | null;
        } | null;
        readonly featureGroups: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
            readonly features: {
              readonly nodes: ReadonlyArray<{
                readonly id: string;
                readonly name: string | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        readonly product: {
          readonly features: {
            readonly nodes: ReadonlyArray<{
              readonly id: string;
              readonly name: string | null;
              readonly featureGroupsCount: number;
            } | null> | null;
          } | null;
          readonly featureGroups: {
            readonly nodes: ReadonlyArray<{
              readonly id: string;
              readonly name: string | null;
              readonly features: {
                readonly nodes: ReadonlyArray<{
                  readonly id: string;
                  readonly name: string | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      }
    | null
    | undefined;
  readonly product?:
    | {
        readonly features: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
            readonly featureGroupsCount: number;
          } | null> | null;
        } | null;
        readonly featureGroups: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
            readonly features: {
              readonly nodes: ReadonlyArray<{
                readonly id: string;
                readonly name: string | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | null
    | undefined;
};
export type hooksfeaturesAndGroupsToggleQuery = {
  readonly response: hooksfeaturesAndGroupsToggleQueryResponse;
  readonly variables: hooksfeaturesAndGroupsToggleQueryVariables;
};

/*
query hooksfeaturesAndGroupsToggleQuery(
  $licenseId: ID!
  $productId: ID!
  $skipLicense: Boolean!
  $skipProduct: Boolean!
) {
  license(id: $licenseId) @skip(if: $skipLicense) {
    features {
      nodes {
        id
        name
      }
    }
    featureGroups {
      nodes {
        id
        name
        features {
          nodes {
            id
            name
          }
        }
      }
    }
    product {
      features {
        nodes {
          id
          name
          featureGroupsCount
        }
      }
      featureGroups {
        nodes {
          id
          name
          features {
            nodes {
              id
              name
            }
          }
        }
      }
      id
    }
    id
  }
  product(id: $productId) @skip(if: $skipProduct) {
    features {
      nodes {
        id
        name
        featureGroupsCount
      }
    }
    featureGroups {
      nodes {
        id
        name
        features {
          nodes {
            id
            name
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "licenseId"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "productId"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "skipLicense"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "skipProduct"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "licenseId"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      concreteType: "FeatureConnection",
      kind: "LinkedField",
      name: "features",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Feature",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v2 /*: any*/, v3 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      concreteType: "FeatureGroupConnection",
      kind: "LinkedField",
      name: "featureGroups",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "FeatureGroup",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      concreteType: "FeatureConnection",
      kind: "LinkedField",
      name: "features",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Feature",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "featureGroupsCount",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v7 = [v6 /*: any*/, v5 /*: any*/],
    v8 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "productId"
      } as any
    ],
    v9 = [v6 /*: any*/, v5 /*: any*/, v2 /*: any*/];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "hooksfeaturesAndGroupsToggleQuery",
      selections: [
        {
          condition: "skipLicense",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: "License",
              kind: "LinkedField",
              name: "license",
              plural: false,
              selections: [
                v4 /*: any*/,
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: v7 /*: any*/,
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ]
        },
        {
          condition: "skipProduct",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v8 /*: any*/,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: v7 /*: any*/,
              storageKey: null
            }
          ]
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "hooksfeaturesAndGroupsToggleQuery",
      selections: [
        {
          condition: "skipLicense",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: "License",
              kind: "LinkedField",
              name: "license",
              plural: false,
              selections: [
                v4 /*: any*/,
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: v9 /*: any*/,
                  storageKey: null
                },
                v2 /*: any*/
              ],
              storageKey: null
            }
          ]
        },
        {
          condition: "skipProduct",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v8 /*: any*/,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: v9 /*: any*/,
              storageKey: null
            }
          ]
        }
      ]
    },
    params: {
      cacheID: "0f326b839b722f6ac4459f3298eae2fb",
      id: null,
      metadata: {},
      name: "hooksfeaturesAndGroupsToggleQuery",
      operationKind: "query",
      text:
        "query hooksfeaturesAndGroupsToggleQuery(\n  $licenseId: ID!\n  $productId: ID!\n  $skipLicense: Boolean!\n  $skipProduct: Boolean!\n) {\n  license(id: $licenseId) @skip(if: $skipLicense) {\n    features {\n      nodes {\n        id\n        name\n      }\n    }\n    featureGroups {\n      nodes {\n        id\n        name\n        features {\n          nodes {\n            id\n            name\n          }\n        }\n      }\n    }\n    product {\n      features {\n        nodes {\n          id\n          name\n          featureGroupsCount\n        }\n      }\n      featureGroups {\n        nodes {\n          id\n          name\n          features {\n            nodes {\n              id\n              name\n            }\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n  product(id: $productId) @skip(if: $skipProduct) {\n    features {\n      nodes {\n        id\n        name\n        featureGroupsCount\n      }\n    }\n    featureGroups {\n      nodes {\n        id\n        name\n        features {\n          nodes {\n            id\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "b85838d917140caa1c2d115a7f3e7a72";
export default node;
