import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  AllowedChartBladeTypes,
  IBladeBaseProps,
  IOpenBladeFromChart,
  IOpenCompanyBlade
} from "../../../components/bladeManager/types";
import { ITab } from "../../../components/tabView";
import {
  AnalyticsCarousel,
  BladeTemplate,
  CompanyBusinessUnitsTable,
  CompanyAttribute,
  BladeTitle,
  ElmButton,
  TabView
} from "../../../components";
import LicensesTable, {
  LicenseTableType,
  LicensesTableColumns
} from "../../../Licenses/components/licensesTable";
import { productTableType } from "../../../Products/components/productsTable";
import { userTableType } from "../../../Users/components/usersTable";
import {
  CompanyQuery,
  CompanyQueryResponse
} from "./__generated__/CompanyQuery.graphql";
import { AnalyticsCarouselProps } from "../../../components/analyticsCarousel";
import moment from "moment";
import { deepEqual } from "fast-equals";
import { getStaticMediaFile, updateBladeUrlState } from "../../../utils";
import { AnalyticsComponentProps } from "../../../components/analyticsComponent";
import {
  sessionCount,
  topUsers,
  activeUserCount,
  topComponents
} from "../../../utils/commonAnalytics";
import { useTheme } from "styled-components";
import { AdminUser } from "blades/Account";
import CompanyAdminsTable from "Companies/components/AdminsTable";
import AttributeActionsDropdown from "components/bladeAttributeActionsDropdown";
import { EmptyTableAction } from "Products/blades/Product";
import { base64DecodeId } from "utils/base64EncodeDecode";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { ElmAppImage } from "utils/AppImageDictionary/ElmAppImage";
import { CompanyUsersTable } from "Companies/components/companyUsersTable";
import { CompanyProductsTable } from "Companies/components/companyProductsTable";
import { REFRESH_KEY } from "components/bladeManager/entityRefreshMap";
export interface ICompanyBladeProps extends IBladeBaseProps<IOpenCompanyBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}
export type state = {
  mode: "edit" | "normal";
  activeTabIndex: number;
  bladeMountTime: moment.Moment;
  adminUsers: AdminUser[];
};

const graphqlQuery = graphql`
  query CompanyQuery($id: ID!) {
    company(id: $id) {
      logoUrl
      productsCount
      businessUnitsCount
      licensesCount
      usersCount
      createdAt
      name
    }
  }
`;
export class CompanyBlade extends React.Component<
  ICompanyBladeProps & {
    result: CompanyQueryResponse;
    retry: () => void;
    theme: any;
  },
  state
> {
  state = {
    mode: "normal",
    queryRes: null,
    activeTabIndex: 0,
    bladeMountTime: moment().utc(),
    adminUsers: []
  } as state;
  public toggleMode = () => {
    this.setState({
      mode: this.state.mode === "normal" ? "edit" : "normal"
    });
  };
  public componentDidMount() {
    this.getAdminUsers();
  }
  public shouldComponentUpdate(
    nextProps: ICompanyBladeProps & {
      result: CompanyQueryResponse;
      retry: () => void;
    },
    nextState: state
  ) {
    return (
      !deepEqual(this.state, nextState) ||
      !deepEqual(this.props.routeData, nextProps.routeData) ||
      !deepEqual(this.props.result, nextProps.result)
    );
  }
  public getAdminUsers = () => {
    this.props.gateway.request
      .getAdminUsers()
      .then(res => {
        if (_.isArray(res.data)) {
          this.setState({ adminUsers: res.data });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  public navToLicenseBlade = (license: LicenseTableType) => {
    this.props.openBlade({
      fromBladeIndex: this.props.index,
      route: "License",
      routeName: "License",
      routeData: {
        id: license.id,
        productId: license.product.id
      }
    });
  };
  public navToUserBlade = (user: userTableType) => {
    this.props.openBlade({
      route: "User",
      routeName: `${user.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: user.id,
        name: user.name
      }
    });
  };
  public openBladeFromChart = (payload: IOpenBladeFromChart) => {
    if (!payload?.id || !AllowedChartBladeTypes.includes(payload.bladeType)) {
      return;
    }
    this.props.openBlade({
      route: payload.bladeType,
      routeName: `${payload.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: payload.id
      }
    });
  };
  public navToProductBlade = (product: productTableType) => {
    this.props.openBlade({
      fromBladeIndex: this.props.index,
      route: "Product",
      routeName: product.name,
      routeData: {
        id: product.id
      }
    });
  };
  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: "analytics",
      fromBladeIndex: this.props.index,
      routeData: {
        type: "Company",
        title: this.props.result.company.name,
        imageSrc: getStaticMediaFile({
          url: this.props.result.company.logoUrl
        }),
        id: this.props.routeData.id,
        allTimeStartDate: `${this.props.result?.company?.createdAt}`
      }
    });
  };
  public renderActionButtons = () => {
    if (this.state.mode === "normal") {
      return (
        <ElmButton
          variance={"icon-button"}
          icon={"FaEdit"}
          label={"Edit"}
          onClick={this.toggleMode}
        />
      );
    }
    return (
      <Flex>
        <ElmButton label={"Save"} onClick={this.toggleMode} />
        <ElmButton
          colorVariance={"secondary"}
          label={"Discard"}
          onClick={this.toggleMode}
        />
      </Flex>
    );
  };
  public renderRightSideHeader = () => {
    const showEditDialog = () => {
      this.props.openDialog("EditCompanyDialog", {
        editMode: true,
        companyId: this.props.routeData.id,
        onConfirm: res => {
          this.props.refreshAllOpenBlades();
        }
      });
    };
    const showDeleteDialog = () => {
      this.props.openDialog("DeleteDialog", {
        entityType: "company",
        name: this.props.result.company.name,
        onConfirm: () => {
          this.props.gateway.request
            .deleteCompany(null, {
              id: this.props.routeData.id
            })
            .then(res => {
              if (res.error) {
                console.error(res.error);
              } else {
                this.props.closeBlade({
                  route: "Company",
                  fromBladeIndex: this.props.index
                });
                this.props.closeDialog("DeleteDialog");
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      });
    };
    return (
      <Flex>
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon={"chart-mixed"}
          iconPrefix={"far"}
          label={"Analytics"}
          onClick={this.navigateToAnalytics}
        />
        <AttributeActionsDropdown
          permissions={["modify_assets"]}
          showEditDialog={showEditDialog}
          showDeleteDialog={showDeleteDialog}
          disabled={!this.props.result}
        />
      </Flex>
    );
  };
  public renderUsers = () => {
    return (
      <CompanyUsersTable
        bladeName="Company"
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.CompanyUsersTable
        )}
        refreshKey={REFRESH_KEY.CompanyUsersTable}
        removeRefreshFn={this.props.removeRefreshFn}
        bladeScope={this.props.routeData?.id}
        // users={res}
        // products={res}
        // companies={res}
        // hideColumnsWithHeaders={[
        //   "Email",
        //   "Company",
        //   "Enable",
        //   "Last active session",
        //   "Actions"
        // ]}
        onRowClick={this.navToUserBlade}
      />
    );
  };
  // public renderProducts = () => {
  //   return (
  //     <ProductsTable
  //       bladeName={"Company"}
  //       columnAndHeaderStyles={{ justifyLastHeaderColumnContent: "flex-end" }}
  //       hideColumnsWithHeaders={[ProductsTableColumns.ActiveLicenses]}
  //       bladeScope={this.props.routeData?.id}
  //       onRowClick={this.navToProductBlade}
  //     />
  //   );
  // };
  public renderLicenses = () => {
    return (
      <LicensesTable
        bladeName="Company"
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.CompanyLicensesTable
        )}
        refreshKey={REFRESH_KEY.CompanyLicensesTable}
        removeRefreshFn={this.props.removeRefreshFn}
        bladeScope={this.props.routeData?.id}
        onRowClick={this.navToLicenseBlade}
        hideColumnsWithHeaders={[
          LicensesTableColumns.Company,
          LicensesTableColumns.Actions,
          LicensesTableColumns.LicenseKey,
          LicensesTableColumns.Usage
        ]}
        columnAndHeaderStyles={{
          justifyFirstHeaderColumnContent: "flex-start",
          justifyColumnContent: "flex-start",
          justifyLastHeaderColumnContent: "center"
        }}
      />
    );
  };
  public renderBusinessUnits = () => {
    const showEditDialog = (payload: any) => {
      // this.props.openDialog("EditBusinessUnitDialog", {
      //   editMode: true,
      //   companyId: this.props.routeData.id,
      //   onConfirm: res => {
      //     this.props.refreshAllOpenBlades();
      //   }
      // });
    };
    const showDeleteDialog = (payload: any) => {
      if (!payload) {
        return;
      }
      this.props.openDialog("DeleteDialog", {
        name: payload?.name,
        entityType: "business unit",
        onConfirm: () => {
          this.props.gateway.request
            .deleteBusinessUnit(null, {
              id: base64DecodeId(payload.id)
            })
            .then(res => {
              renderSuccessNotification("Deleted business unit!");
              this.props.closeDialog("DeleteDialog");
              this.props.refreshAllOpenBlades();
            })
            .catch(error => {
              renderErrorNotification("Failed to remove business unit!");
              console.error(error);
            });
        }
      });
    };
    const addNewBusinessUnit = () => {
      this.props.openDialog("EditBusinessUnitDialog", {
        companyId: this.props.routeData.id,
        onConfirm: res => {
          this.props.refreshAllOpenBlades();
        }
      });
    };
    return (
      <CompanyBusinessUnitsTable
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.CompanyBusinessUnitsTable
        )}
        refreshKey={REFRESH_KEY.CompanyBusinessUnitsTable}
        removeRefreshFn={this.props.removeRefreshFn}
        noNav
        onRowClick={showEditDialog}
        useDefaultAddButton={addNewBusinessUnit}
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message={"We couldn't find any data"}
            addButtonCallback={addNewBusinessUnit}
            actionMessage="Add business unit?"
          />
        )}
        renderRowActionButtons={payload => (
          <Flex
            style={{
              display: "flex"
            }}
            padding={"2px"}
          >
            <ElmButton
              className="darkModeTransparentBtn"
              style={{
                color: this.props.theme.colors.iconColor
              }}
              variance="icon-button"
              icon={"trash-can"}
              iconPrefix={"far"}
              alt="Delete"
              label=""
              colorVariance={"subtle"}
              permissions="modify_assets"
              onClick={() => showDeleteDialog(payload)}
            />
          </Flex>
        )}
      />
    );
  };

  public renderAdminListTable = () => {
    const handleAddAdmin = () => {
      this.props.openDialog("EditCompanyAdminDialog", {
        onConfirm: (payload: { email: string }) => {
          this.props.gateway.request
            .newCompanyAdmin(payload, { id: this.props.routeData.id })
            .then(res => {
              if (res.data) {
                renderSuccessNotification("Added company admin");
                this.props.closeDialog("EditCompanyAdminDialog");
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      });
    };

    const handleDeleteAdmin = (rowData: any) => {
      const { email = "", id } = rowData;
      this.props.openDialog("ConfirmationDialog", {
        entityType: "delete",
        isSimpleConfirmation: true,
        warningMessage: `Are you sure you want to delete ${email} ?`,
        onConfirm: () => {
          this.props.gateway.request
            .deleteCompanyAdmin(
              { admin_id: id },
              {
                id: this.props.routeData.id
              }
            )
            .then(res => {
              if (res.error) {
                renderErrorNotification("Failed to remove admin");
                console.error(res.error);
              } else {
                renderSuccessNotification("Removed admin");
                this.props.closeDialog("ConfirmationDialog");
                this.props.refreshAllOpenBlades();
                return true;
              }
            })
            .catch(err => {
              console.error(err);
              return false;
            });
        }
      });
    };

    const renderRowActionButtons = (rowData: { email: string; id: string }) => {
      return (
        <Flex
          style={{
            display: "flex"
          }}
          padding={"2px"}
        >
          <ElmButton
            className="darkModeTransparentBtn"
            style={{
              color: this.props.theme.colors.iconColor
            }}
            //variance={"plain-icon-button"}
            variance="icon-button"
            icon={"trash"}
            iconPrefix={"fas"}
            alt="Delete"
            label=""
            colorVariance={"subtle"}
            permissions="modify_assets"
            onClick={() => handleDeleteAdmin(rowData)}
          />
        </Flex>
      );
    };
    return (
      <CompanyAdminsTable
        //hideSearchBar
        useDefaultAddButton={handleAddAdmin}
        renderRowActionButtons={renderRowActionButtons}
        id={this.props.routeData?.id}
        blade_scope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.CompanyAdminsTable
        )}
        refreshKey={REFRESH_KEY.CompanyAdminsTable}
        removeRefreshFn={this.props.removeRefreshFn}
      />
    );
    // return (
    //   <ElmTable
    //     // data={
    //     //   this.state.searchedAdminUsers.length
    //     //     ? this.state.searchedAdminUsers
    //     //     : this.state.adminUsers
    //     // }
    //     data={this.state.adminUsers}
    //     totalCount={this.state.adminUsers.length}
    //     useDefaultAddButton={handleAddAdmin}
    //     //onCriteriaChange={this.onSearchAdminUsers}
    //     //renderRowActionButtons={this.renderActionButtons("adminUser")}
    //     columns={[
    //       {
    //         Header: "Email",
    //         accessor: "email",
    //         sortKey: "email"
    //       },
    //       // {
    //       //   Header: "Role",
    //       //   accessor: "role",
    //       //   cellRenderer: payload => (
    //       //     <StyledText>
    //       //       <span>{this.getNiceRoleText(payload.rowData.role)}</span>
    //       //     </StyledText>
    //       //   )
    //       // },
    //       // {
    //       //   Header: "Actions",
    //       //   accessor: "",
    //       //   cellRenderer: () => "",
    //       //   width: 0.15
    //       // }
    //     ]}
    //   />
    // );
  };

  public getTabs = (res: CompanyQueryResponse) => {
    const company = _.get(res, "company");
    if (!company) return [];
    const tabs = [
      {
        title: "Licenses",
        Component: this.renderLicenses()
        // supScript: _.get(res, "company.licensesCount")
      }
    ];
    tabs.push({
      title: "Units",
      Component: this.renderBusinessUnits()
      // supScript: _.get(res, "company.businessUnitsCount")
    });
    tabs.push({
      title: "Users",
      Component: this.renderUsers()
      // supScript: _.get(res, "company.usersCount")
    });
    tabs.push({
      title: "Admins",
      Component: this.renderAdminListTable()
    });
    return tabs as ITab[];
  };
  public renderLeftSideHeader = (res: CompanyQueryResponse) => () => {
    return (
      <Flex style={{ alignItems: "center", gap: 10 }}>
        <ElmAppImage
          id={this.props.routeData?.id || res?.company?.name}
          url={res?.company?.logoUrl}
          name={res?.company?.name}
          size={24}
        />
        <BladeTitle>{_.get(res, "company.name")}</BladeTitle>
      </Flex>
    );
  };
  public getAnalyticsQuery = (
    activeTabIndex: number
  ): AnalyticsComponentProps => {
    const startTime = this.state.bladeMountTime.clone();
    switch (activeTabIndex) {
      case 0: // Products
        return sessionCount(this.props.routeData.id, startTime, true);
      case 1: // Licenses
        return activeUserCount(this.props.routeData.id, startTime, true);
      case 2: // Top users
        return topUsers(this.props.routeData.id, startTime, true);
      case 3: // Components
        return topComponents(
          this.props.routeData.id,
          startTime,
          false,
          "users.name"
        );
      default:
        return sessionCount(this.props.routeData.id, startTime, true);
    }
  };
  public getChartIndex = () => {
    switch (this.state.activeTabIndex) {
      case 0: // Products
        return 0;
      case 1: // Licenses
        return 1;
      case 2:
      case 3:
        return 2;
      default:
        return 0;
    }
  };
  public getAnalyticsCarouselProps = (): AnalyticsCarouselProps => {
    return {
      analyticsSlides: _.map(_.range(0, 4), i => this.getAnalyticsQuery(i)),
      currentIndex: this.getChartIndex(),
      openBlade: this.openBladeFromChart
    };
  };

  public updateActiveTab = (activeTabIndex: number) => {
    updateBladeUrlState(this.props, activeTabIndex);
    this.setState({
      activeTabIndex
    });
  };
  public renderCompanyInfo = () => {
    const res = this.props.result;
    const company = _.get(res, "company");
    // if (!payload.props) {
    //   this.props.showScreenLoader();
    //   return null;
    // }
    // this.props.hideScreenLoader();
    // const company = payload.props.company;
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Company"}
        closeBlade={this.props.closeBlade}
        bladeType={"Company"}
        //topAccentColor={"companyRed"}
        accentColor={"orangeRed"}
        renderLeftSideHeader={this.renderLeftSideHeader(res)}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        loading={!res}
      >
        <Flex
          style={{
            maxHeight: "159",

            marginBottom: "5px",
            width: "100%"
          }}
        >
          <CompanyAttribute
            id={this.props.routeData?.id}
            setRefreshFn={this.props.setEntityRefreshFn(
              REFRESH_KEY.CompanyAttribute
            )}
            refreshKey={REFRESH_KEY.CompanyAttribute}
            removeRefreshFn={this.props.removeRefreshFn}
          />
        </Flex>
        <Flex
          style={{
            paddingLeft: "21px",
            paddingRight: "21px",
            flexDirection: "column",
            flex: 1
          }}
        >
          <AnalyticsCarousel {...this.getAnalyticsCarouselProps()} />
          <TabView
            showBottomBorder={true}
            leftAlign={true}
            tabList={this.getTabs(res)}
            defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
            onUpdateActiveTab={this.updateActiveTab}
            orientation={"vertical"}
          />
        </Flex>
      </BladeTemplate>
    );
  };

  public render() {
    return this.renderCompanyInfo();
  }
}
const RenderQuery = (props: ICompanyBladeProps) => {
  const theme = useTheme();
  const renderInfo = (payload: {
    error: Error;
    props: CompanyQueryResponse;
    retry: () => void;
  }) => {
    return (
      <CompanyBlade
        {...props}
        result={payload.props}
        theme={theme}
        retry={payload.retry}
        {..._.omit(payload, "props")}
      />
    );
  };
  return (
    <QueryRenderer<CompanyQuery>
      environment={relayEnvironment}
      variables={{
        id: props.routeData.id
      }}
      query={graphqlQuery}
      render={renderInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
