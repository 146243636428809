import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import { getEnvironment } from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  AllowedChartBladeTypes,
  IBladeBaseProps,
  IOpenBladeFromChart,
  IOpenProductBlade
} from "../../../components/bladeManager/types";
import { ITab } from "../../../components/tabView";
import {
  AnalyticsCarousel,
  BladeTemplate,
  BladeTitle,
  CompaniesTable,
  ProductAttribute,
  TabView
} from "../../../components";
import { ElmButton } from "../../../components/elmButton";
import {
  ProductQuery,
  ProductQueryResponse
} from "./__generated__/ProductQuery.graphql";
import {
  AnalyticsComponentProps,
  queryType
} from "../../../components/analyticsComponent";
import { AnalyticsCarouselProps } from "../../../components/analyticsCarousel";
import { productReleaseConfigTableType } from "../../components/productReleaseConfigurationsTable";
import moment from "moment";
import { deepEqual } from "fast-equals";
import { AutoSizer } from "react-virtualized";
import { getStaticMediaFile, updateBladeUrlState } from "../../../utils";
import {
  topUsers,
  topCompanies,
  topComponents,
  activeUserCount
} from "../../../utils/commonAnalytics";
import ReactImageFallback from "react-image-fallback";
import styled, { useTheme } from "styled-components";
import { sharedTabStyle } from "blades/Analytics/shared";
import NoDataPlaceholder from "components/elmChart/noDataPlaceholder";
import { useSelector } from "react-redux";
import { IUserRole } from "../../../store/types";
import { checkPermissions } from "components/helpers";
import AttributeActionsDropdown from "components/bladeAttributeActionsDropdown";
import {
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import {
  ProductUsersTable,
  ProductUsersTableType
} from "Products/components/productUsersTable";
import ProductFeaturesTable from "Products/components/productFeaturesTable";
import ProductFeatureGroupsTable from "Products/components/productFeatureGroupsTable";
import ProductComponentsTable from "Products/components/productComponentsTable";
import ProductComponentGroupsTable from "Products/components/productComponentGroupsTable";
import { ProductReleaseConfigurationsTable } from "Products/components/producReleaseConfigurationsTable";
import ProductVersionsTable from "Products/components/productVersionsTable";
import LicensesTable, {
  LicenseTableType,
  LicensesTableColumns
} from "Licenses/components/licensesTable";
import {
  CompaniesTableColumns,
  companyTableType
} from "Companies/components/CompaniesTable";
import { ElmAppImage } from "utils/AppImageDictionary/ElmAppImage";
import { REFRESH_KEY } from "components/bladeManager/entityRefreshMap";

const StyledNoDataText = styled.span`
  color: ${props => props.theme.colors.warmGrey};
  font-size: ${props => props.theme.fontSizes.ySmall};
  text-align: center;
`;
interface IEmptyTableAction {
  message: string;
  addButtonCallback: () => void;
  actionMessage: string;
}
export const EmptyTableAction = ({
  message,
  addButtonCallback,
  actionMessage
}: IEmptyTableAction) => {
  const userInfo = useSelector(
    (state: { app: { activeRole: IUserRole } }) => state.app.activeRole
  );
  const havePermissions = checkPermissions({
    userPermissions: userInfo.permissions,
    requiredPermissions: "modify_products"
  });

  return (
    <Flex flexDirection="column" flex={1} width="250px" justifyContent="center">
      <NoDataPlaceholder message={message} />
      {havePermissions ? (
        <>
          <Flex justifyContent="center">
            <StyledNoDataText>Would you like to add it now ?</StyledNoDataText>
          </Flex>
          <Flex justifyContent="center">
            <ElmButton
              label={actionMessage}
              variance="plain-icon-button"
              icon="plus"
              iconPrefix="fas"
              onClick={addButtonCallback}
              style={{ marginTop: "24px", maxWidth: "250px" }}
            />
          </Flex>
        </>
      ) : null}
    </Flex>
  );
};

const graphqlQuery = graphql`
  query ProductQuery($id: ID!) {
    product(id: $id) {
      usersCount
      licensesCount
      licenseeCount
      name
      createdAt
      iconUrl
    }
  }
`;
export interface IProductBladeProps extends IBladeBaseProps<IOpenProductBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}
export type state = {
  mode: "edit" | "normal";
  activeTabIndex: number;
  bladeMountTime: moment.Moment;
  search_term?: string;
};
export class ProductBlade extends React.Component<
  IProductBladeProps & {
    result: ProductQueryResponse;
    retry: () => void;
    theme: any;
  },
  state
> {
  constructor(
    props: IProductBladeProps & {
      result: ProductQueryResponse;
      retry: () => void;
    }
  ) {
    super(props);
    this.state = {
      mode: "normal",
      activeTabIndex: 0,
      bladeMountTime: moment().utc()
    };
  }
  public shouldComponentUpdate(
    nextProps: IProductBladeProps & { result: ProductQueryResponse },
    nextState: state
  ) {
    return (
      !deepEqual(this.state, nextState) ||
      !deepEqual(this.props.routeData, nextProps.routeData) ||
      !deepEqual(this.props.result, nextProps.result)
    );
  }
  public navAway = () => {
    this.props.openBlade({
      routeName: "Customer portal",
      fromBladeIndex: this.props.index,
      route: "Overview",
      routeData: null
    });
  };
  public componentDidMount() {}
  public getAnalyticsQuery = (
    activeTabIndex: number
  ): AnalyticsComponentProps => {
    const startTime = this.state.bladeMountTime.clone();
    const query: queryType = {
      setup: {
        category: "time.month",
        value: "sessions.usage_count",
        group: null
      },
      filterColumn: ["is_vendor"],
      filterValues: [["false"]],
      startTime: startTime.subtract(3, "M").format(),
      bladeScope: this.props.routeData.id,
      sortColumn: "value",
      sortDirection: "DESC",
      first: 5
    };
    switch (activeTabIndex) {
      case 0: // Companies
        return topCompanies(this.props.routeData.id, startTime, false);
      case 1: // Users
        return topUsers(this.props.routeData.id, startTime, false);
      case 2: // Licenses
        query.setup.category = "licenses.type";
        query.setup.value = "counts.active_user";
        query.setup.group = null;
        query.startTime = startTime.subtract(1, "d").format();
        return {
          analytics: query,
          type: "pie",
          title: "Users by License Type"
        };
      case 3: // Components
        return topComponents(
          this.props.routeData.id,
          startTime,
          false,
          "companies.name"
        );
      case 4: // Weekly and monthly active user count
        query.setup.category = "time.week";
        query.setup.group = "users.name";
        query.sortColumn = "category";
        query.sortDirection = "ASC";
        return {
          analytics: query,
          type: "dataValue",
          title: "Active user count",
          options: {
            groups: [
              { name: "Week", value: 1, statistic: "distinctGroupCount" },
              { name: "Month", value: 4, statistic: "distinctGroupCount" }
            ],
            skipLast: true
          }
        };
      default:
        return activeUserCount(this.props.routeData.id, startTime, false);
    }
  };
  public getAnalyticsTitle = (activeTabIndex: number) => {
    let title = "";
    switch (activeTabIndex) {
      case 0:
        title = "Users by Owner";
        break;
      case 1:
        title = "Top 5 Users";
        break;
      case 2:
        title = "Users by License Type";
        break;
      case 3:
        title = "Most Used Components";
        break;
      case 4:
        title = "Active user count";
        break;
      default:
        title = "Sessions per Month";
        break;
    }

    return title;
  };
  public renderLeftSideHeader = (res: ProductQueryResponse) => () => {
    if (!res) {
      return <></>;
    }
    const product = _.get(res, "product");
    return (
      <Flex style={{ alignItems: "center", gap: 8 }}>
        <ElmAppImage
          id={this.props.routeData?.id || product?.name}
          url={product?.iconUrl}
          name={product?.name}
          size={24}
        />
        <BladeTitle style={{ width: 300 }}>
          {_.get(product, "name", this.props?.routeData?.name)}
        </BladeTitle>
      </Flex>
    );
  };
  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: `${this.props.result.product.name} - Analytics`,
      fromBladeIndex: this.props.index,
      routeData: {
        type: "Product",
        title: this.props.result.product.name,
        id: this.props.routeData.id,
        allTimeStartDate: `${this.props.result?.product?.createdAt}`,
        imageSrc: getStaticMediaFile({
          url: _.get(this.props.result, "product.iconUrl")
        })
      }
    });
  };
  public navToLicenseBlade = (license: LicenseTableType) => {
    this.props.openBlade({
      route: "License",
      routeName: "License",
      fromBladeIndex: this.props.index,
      routeData: {
        id: license.id,
        productId: license.product.id
      }
    });
  };
  public navToUserBlade = (user: ProductUsersTableType) => {
    this.props.openBlade({
      route: "User",
      routeName: `${user.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: user.id
      }
    });
  };
  public navToCompanyBlade = (company: companyTableType) => {
    this.props.openBlade({
      route: "Company",
      routeName: company.name,
      fromBladeIndex: this.props.index,
      routeData: {
        id: company.id
      }
    });
  };
  public renderRightSideHeader = () => {
    const showEditDialog = () => {
      this.props.openDialog("EditProductDialog", {
        editMode: true,
        productId: this.props.routeData.id,
        onConfirm: () => {
          this.props.closeDialog("EditProductDialog");
          this.props.refreshAllOpenBlades();
        }
      });
    };
    const showDeleteDialog = () => {
      this.props.openDialog("DeleteDialog", {
        entityType: "product",
        name: this.props.result.product.name,
        onConfirm: () => {
          this.props.gateway.request
            .deleteProduct(null, {
              id: this.props.routeData.id
            })
            .then(res => {
              if (res.error) {
                console.error(res.error);
              } else {
                this.props.closeDialog("DeleteDialog");
                this.props.closeBlade({
                  route: "Product",
                  fromBladeIndex: this.props.index
                });
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      });
    };
    return (
      <Flex>
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon={"chart-mixed"}
          iconPrefix={"far"}
          label={"Analytics"}
          onClick={this.navigateToAnalytics}
        />
        <AttributeActionsDropdown
          permissions={["modify_products"]}
          showEditDialog={showEditDialog}
          showDeleteDialog={showDeleteDialog}
          disabled={!this.props.result}
        />
      </Flex>
    );
  };
  public renderUsersTable = () => {
    return (
      <ProductUsersTable
        bladeName={"Product"}
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ProductUsersTable
        )}
        refreshKey={REFRESH_KEY.ProductUsersTable}
        removeRefreshFn={this.props.removeRefreshFn}
        onRowClick={this.navToUserBlade}
      />
    );
  };
  public renderLicensesTable = () => {
    return (
      <LicensesTable
        bladeName={"Product"}
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ProductLicensesTable
        )}
        refreshKey={REFRESH_KEY.ProductLicensesTable}
        removeRefreshFn={this.props.removeRefreshFn}
        onRowClick={this.navToLicenseBlade}
        hideColumnsWithHeaders={[
          LicensesTableColumns.Product,
          LicensesTableColumns.Actions,
          LicensesTableColumns.LicenseKey,
          LicensesTableColumns.Usage
        ]}
        columnAndHeaderStyles={{
          justifyFirstHeaderColumnContent: "flex-start",
          justifyColumnContent: "flex-start",
          justifyLastHeaderColumnContent: "flex-end"
        }}
      />
    );
  };
  public renderCompaniesTable = () => {
    return (
      <CompaniesTable
        bladeName={"Product"}
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ProductCompaniesTable
        )}
        refreshKey={REFRESH_KEY.ProductCompaniesTable}
        removeRefreshFn={this.props.removeRefreshFn}
        onRowClick={this.navToCompanyBlade}
        hideColumnsWithHeaders={[CompaniesTableColumns.Product]}
        columnAndHeaderStyles={{
          justifyFirstHeaderColumnContent: "flex-start",
          justifyColumnContent: "flex-start",
          justifyLastHeaderColumnContent: "center"
        }}
      />
    );
  };
  public renderComponentsTab = (res: ProductQueryResponse) => {
    const views = [
      {
        title: "Components",
        Component: this.renderProductComponents(res),
        style: sharedTabStyle
      },
      {
        title: "Groups",
        Component: this.renderProductComponentGroups(res),
        style: sharedTabStyle
      }
    ] as ITab[];
    return (
      <TabView
        titleContainerStyle={{ justifyContent: "flex-start" }}
        tabList={views}
      />
    );
  };
  public renderProductComponentGroups = (res: ProductQueryResponse) => {
    const showAddNewComponentGroupDialog = () => {
      this.props.openDialog("NewComponentGroupDialog", {
        onConfirm: () => {
          this.props.refreshAllOpenBlades();
        },
        productId: this.props.routeData.id,
        productName: res.product.name
      });
    };

    const renderRowActionButtons = (rowData: {
      id: string;
      groupName: string;
    }) => {
      const showEditComponentGroupDialog = () => {
        this.props.openDialog("EditComponentGroupDialog", {
          refreshFn: this.props.refreshAllOpenBlades,
          onConfirm: () => this.props.refreshAllOpenBlades(),
          productId: this.props.routeData.id,
          productName: res.product.name,
          componentGroupId: rowData?.id
        });
      };
      const showDeleteComponentGroupDialog = () => {
        this.props.openDialog("DeleteDialog", {
          onConfirm: () => {
            this.props.gateway.request
              .deleteComponentGroup(rowData, { id: rowData.id })
              .then(res => {
                if (res.error) {
                  console.error(res);
                } else {
                  this.props.closeDialog("DeleteDialog");
                  this.props.refreshAllOpenBlades();
                }
              })
              .catch(e => {
                console.error(e);
              });
          },
          entityType: "Component Group",
          name: rowData.groupName
        });
      };
      return (
        <Flex
          style={{
            display: "flex",
            padding: "2px"
          }}
        >
          <ElmButton
            className="darkModeTransparentBtn"
            variance="icon-button"
            icon="pen"
            iconPrefix="fas"
            style={{
              color: this.props.theme.colors.iconColor
            }}
            label=""
            alt="Edit"
            colorVariance="subtle"
            permissions="modify_products"
            onClick={showEditComponentGroupDialog}
          />
          <ElmButton
            className="darkModeTransparentBtn"
            style={{
              color: this.props.theme.colors.iconColor
            }}
            variance="icon-button"
            icon={"trash"}
            iconPrefix={"fas"}
            alt="Delete"
            label=""
            colorVariance={"subtle"}
            permissions="modify_products"
            onClick={showDeleteComponentGroupDialog}
          />
        </Flex>
      );
    };
    return (
      <ProductComponentGroupsTable
        bladeName={"Product"}
        addComponentGroup={showAddNewComponentGroupDialog}
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ProductComponentGroupsTable
        )}
        refreshKey={REFRESH_KEY.ProductComponentGroupsTable}
        removeRefreshFn={this.props.removeRefreshFn}
        useDefaultAddButton={showAddNewComponentGroupDialog}
        renderRowActionButtons={renderRowActionButtons}
        noNav
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message={"We couldn't find component groups."}
            addButtonCallback={showAddNewComponentGroupDialog}
            actionMessage="Add component group"
          />
        )}
      />
    );
  };
  public renderProductComponents = (res: ProductQueryResponse) => {
    const showAddNewComponentDialog = () => {
      this.props.openDialog("NewComponentDialog", {
        onConfirm: () => {
          this.props.refreshAllOpenBlades();
        },
        productId: this.props.routeData.id,
        productName: res.product.name
      });
    };
    const openProductComponentBlade = (routeData: {
      id: string;
      name: string;
    }) => {
      this.props.openBlade({
        route: "ProductComponent",
        routeName: `${routeData.name}`,
        routeData: {
          ...routeData
        },
        fromBladeIndex: this.props.index
      });
    };

    return (
      <ProductComponentsTable
        bladeName={"Product"}
        addNewComponent={showAddNewComponentDialog}
        bladeScope={this.props.routeData?.id}
        onRowClick={openProductComponentBlade}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ProductComponentsTable
        )}
        refreshKey={REFRESH_KEY.ProductComponentsTable}
        removeRefreshFn={this.props.removeRefreshFn}
      />
    );
  };
  public renderFeatureGroupsTable = () => {
    const showNewFeatureGroupDialog = (row: any, editMode?: boolean) => {
      this.props.openDialog("NewProductFeatureGroupDialog", {
        onConfirm: () => {
          this.props.refreshAllOpenBlades();
        },
        editMode,
        productId: this.props.routeData.id,
        featureGroupId: row.id
      });
    };

    return (
      <ProductFeatureGroupsTable
        editableRow={true}
        bladeName={"Product"}
        bladeScope={this.props.routeData?.id}
        addNewGroup={() => showNewFeatureGroupDialog({}, false)}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ProductFeatureGroupsTable
        )}
        refreshKey={REFRESH_KEY.ProductFeatureGroupsTable}
        removeRefreshFn={this.props.removeRefreshFn}
        onEditClick={(row, index) => showNewFeatureGroupDialog(row, true)}
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message={"We couldn't find feature groups."}
            addButtonCallback={() => showNewFeatureGroupDialog({}, false)}
            actionMessage="Add feature group"
          />
        )}
        useDefaultAddButton={() => showNewFeatureGroupDialog({}, false)}
      />
    );
  };
  public renderFeaturesTable = () => {
    const showNewFeatureDialog = (row: any, editMode?: boolean) => {
      this.props.openDialog("NewProductFeatureDialog", {
        onConfirm: () => this.props.refreshAllOpenBlades(),
        refreshFn: this.props.refreshAllOpenBlades,
        productId: this.props.routeData.id,
        productName: " ", //res.product.name,
        id: row.id,
        editMode: editMode,
        name: row.name
      });
    };

    const showFeatureDeleteConfirmation = (row: {
      name: string;
      id: string;
    }) => {
      this.props.openDialog("ConfirmationDialog", {
        isSimpleConfirmation: true,
        warningMessage: "Are you sure you want to delete it ?",
        onConfirm: () => {
          this.props.gateway.request
            .deleteFeature(null, { id: row.id })
            .then(res => {
              if (res.error) {
                console.error(res);
              } else {
                this.props.closeDialog("ConfirmationDialog");
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(e => {
              console.error(e);
            });
        },
        entityType: "feature delete",
        name: row.name
      });
    };

    const renderRowActionButtons = (rowData: { id: string; name: string }) => {
      return (
        <Flex
          style={{
            display: "flex",
            padding: "2px"
          }}
        >
          <ElmButton
            className="darkModeTransparentBtn"
            variance="icon-button"
            icon="pen"
            iconPrefix="fas"
            style={{
              color: this.props.theme.colors.iconColor
            }}
            label=""
            alt="Edit"
            colorVariance="subtle"
            permissions="modify_products"
            onClick={() => showNewFeatureDialog(rowData, true)}
          />
          <ElmButton
            className="darkModeTransparentBtn"
            style={{
              color: this.props.theme.colors.iconColor
            }}
            variance="icon-button"
            icon={"trash"}
            iconPrefix={"fas"}
            alt="Delete"
            label=""
            colorVariance={"subtle"}
            permissions="modify_products"
            onClick={() => showFeatureDeleteConfirmation(rowData)}
          />
        </Flex>
      );
    };

    return (
      <ProductFeaturesTable
        bladeName={"Product"}
        addNewFeature={() => showNewFeatureDialog({}, false)}
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ProductFeaturesTable
        )}
        refreshKey={REFRESH_KEY.ProductFeaturesTable}
        removeRefreshFn={this.props.removeRefreshFn}
        renderRowActionButtons={renderRowActionButtons}
        useDefaultAddButton={() => showNewFeatureDialog({}, false)}
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message={"We couldn't find features."}
            addButtonCallback={() => showNewFeatureDialog({}, false)}
            actionMessage="Add feature"
          />
        )}
        columnAndHeaderStyles={{
          justifyLastHeaderColumnContent: "flex-start",
          justifyLastRowColumnContent: "flex-start"
        }}
      />
    );
  };

  public renderFeaturesTab = (res: ProductQueryResponse) => {
    const views = [
      {
        title: "Features",
        Component: this.renderFeaturesTable(),
        style: sharedTabStyle
      },
      {
        title: "Groups",
        Component: this.renderFeatureGroupsTable(),
        style: sharedTabStyle
      }
    ] as ITab[];
    return (
      <TabView
        tabList={views}
        titleContainerStyle={{ justifyContent: "flex-start" }}
      />
    );
  };
  public navToReleaseConfig = (payload: productReleaseConfigTableType) => {
    this.props.openBlade({
      route: "ProductReleaseConfig",
      routeName: `${payload.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: payload.id,
        name: payload.name
      }
    });
  };
  public renderReleaseConfigurationsTable = () => {
    const showAddNewProductRelease = () => {
      this.props.openDialog("NewProductReleaseConfigDialog", {
        onConfirm: () => {
          this.props.refreshAllOpenBlades();
        },
        productId: this.props.routeData.id,
        productName: this.props.result.product.name
      });
    };

    return (
      <ProductReleaseConfigurationsTable
        useDefaultAddButton={showAddNewProductRelease}
        bladeScope={this.props.routeData?.id}
        bladeName={"Product"}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ProductReleaseTable
        )}
        refreshKey={REFRESH_KEY.ProductReleaseTable}
        removeRefreshFn={this.props.removeRefreshFn}
        onRowClick={this.navToReleaseConfig}
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message={"We couldn't find release configuration."}
            addButtonCallback={showAddNewProductRelease}
            actionMessage="Add release configuration"
          />
        )}
      />
    );
  };
  public renderProductVersionsTable = () => {
    const addNewProductVersion = () => {
      this.props.openDialog("ProductVersionDialog", {
        product_id: this.props.routeData?.id,
        onConfirm: () => {
          this.props.closeDialog("ProductVersionDialog");
          this.props.refreshAllOpenBlades();
        }
      });
    };
    const editProductVersion = (id: string, version_string: string) => {
      this.props.openDialog("ProductVersionDialog", {
        product_id: this.props.routeData?.id,
        version_id: id,
        version_string,
        onConfirm: () => {
          this.props.closeDialog("ProductVersionDialog");
          this.props.refreshAllOpenBlades();
        }
      });
    };
    const deleteProductVersion = (id: string, version_string: string) => {
      const b64DecodeVersionId = atob(id)?.replace("ProductVersion-", "");
      this.props.openDialog("DeleteDialog", {
        entityType: "version",
        name: version_string,
        onConfirm: () => {
          this.props.gateway.request
            .deleteProductVersion(null, { id: b64DecodeVersionId })
            .then(res => {
              if (res.error) {
                console.error(res.error);
                renderFailureNotification("Delete Failed !");
              } else {
                renderSuccessNotification("Product Version Deleted !");
                this.props.closeDialog("DeleteDialog");
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      });
    };

    return (
      <ProductVersionsTable
        productId={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ProductVersionsTable
        )}
        refreshKey={REFRESH_KEY.ProductVersionsTable}
        removeRefreshFn={this.props.removeRefreshFn}
        addNewProductVersion={addNewProductVersion}
        deleteProductVersion={deleteProductVersion}
        editProductVersion={editProductVersion}
      />
    );
  };
  public getTabs = (res: ProductQueryResponse) => {
    if (res === null) return [];
    let ret = [];
    if (res?.product?.licensesCount) {
      ret.push({
        title: "Companies",
        Component: this.renderCompaniesTable()
        // supScript: _.get(res, 'product.licenseeCount'),
      });
      if (res.product.usersCount) {
        ret.push({
          title: "Users",
          Component: this.renderUsersTable()
          // supScript: _.get(res, 'product.usersCount'),
        });
      }
      ret.push({
        title: "Licenses",
        Component: this.renderLicensesTable()
        // supScript: _.get(res, 'product.licensesCount'),
      });
    }
    ret.push({
      title: "Components",
      Component: this.renderComponentsTab(res)
      // supScript: _.get(res, 'product.componentsCount'),
    });
    ret.push({
      title: "Features",
      Component: this.renderFeaturesTab(res)
      // supScript: _.get(res, 'product.featuresCount'),
    });

    ret.push({
      title: "Release",
      // supScript: _.get(res, 'product.releaseConfigurationsCount'),
      Component: this.renderReleaseConfigurationsTable()
    });
    ret.push({
      title: "Versions",
      Component: this.renderProductVersionsTable()
    });
    return ret as ITab[];
  };
  public updateActiveTab = (activeTabIndex: number) => {
    updateBladeUrlState(this.props, activeTabIndex);
    this.setState({
      activeTabIndex
      // analytics: this.getAnalyticsQuery(activeTabIndex),
      // analyticsType: this.getAnalyticsType(activeTabIndex)
    });
  };
  public getChartIndex = () => {
    switch (this.state.activeTabIndex) {
      case 0: // Companies
        return 0;
      case 1: // Users
        return 1;
      case 2: // Licenses
        return 2;
      case 3: // Components
        return 3;
      default:
        return 4;
    }
  };
  public getAnalyticsCarouselProps = (): AnalyticsCarouselProps => {
    return {
      analyticsSlides: _.map(_.range(0, 5), i => ({
        ...this.getAnalyticsQuery(i)
      })),
      currentIndex: this.getChartIndex(),
      openBlade: this.openBladeFromChart
    };
  };
  public openBladeFromChart = (payload: IOpenBladeFromChart) => {
    if (!payload?.id || !AllowedChartBladeTypes.includes(payload.bladeType)) {
      return;
    }
    this.props.openBlade({
      route: payload.bladeType,
      routeName: `${payload.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: payload.id
      }
    });
  };
  public renderProductInfo = () => {
    const res = this.props.result;
    const product = _.get(res, "product");
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Product"}
        bladeType={"Product"}
        accentColor={"productsBlue"}
        renderLeftSideHeader={this.renderLeftSideHeader(res)}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        closeBlade={this.props.closeBlade}
        loading={!res}
      >
        <Flex
          style={{
            maxHeight: "159",
            marginBottom: "5px",
            width: "100%"
          }}
        >
          <ProductAttribute
            id={this.props.routeData?.id}
            setRefreshFn={this.props.setEntityRefreshFn(
              REFRESH_KEY.ProductAttribute
            )}
            refreshKey={REFRESH_KEY.ProductAttribute}
            removeRefreshFn={this.props.removeRefreshFn}
          />
        </Flex>

        <Flex
          style={{
            paddingLeft: "21px",
            paddingRight: "21px",
            flexDirection: "column",
            flex: 1
          }}
        >
          <AnalyticsCarousel {...this.getAnalyticsCarouselProps()} />
          <div style={{ display: "flex", flex: 1 }}>
            <AutoSizer defaultHeight={300}>
              {({ height, width }) => (
                <TabView
                  showBottomBorder={true}
                  leftAlign={true}
                  tabList={this.getTabs(this.props.result)}
                  defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
                  onUpdateActiveTab={this.updateActiveTab}
                  orientation={"vertical"}
                  style={{
                    maxHeight: height,
                    minHeight: height,
                    maxWidth: width,
                    minWidth: width
                  }}
                />
              )}
            </AutoSizer>
          </div>
        </Flex>
      </BladeTemplate>
    );
  };

  public render() {
    return this.renderProductInfo();
  }
}
const relayEnvironment = getEnvironment();
const RenderQuery = (props: IProductBladeProps) => {
  const theme = useTheme();
  const renderProductInfo = (payload: {
    error: Error;
    props: ProductQueryResponse;
    retry: () => void;
  }) => {
    return (
      <ProductBlade
        {...props}
        result={payload.props}
        retry={payload.retry}
        theme={theme}
      />
    );
  };
  return (
    <QueryRenderer<ProductQuery>
      environment={relayEnvironment}
      cacheConfig={{
        force: true
      }}
      variables={{
        id: props.routeData.id
      }}
      query={graphqlQuery}
      render={renderProductInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
