/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type companyBusinessUnitsTableQueryVariables = {
  id: string;
};
export type companyBusinessUnitsTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"companyBusinessUnitsTable_units">;
};
export type companyBusinessUnitsTableQuery = {
  readonly response: companyBusinessUnitsTableQueryResponse;
  readonly variables: companyBusinessUnitsTableQueryVariables;
};

/*
query companyBusinessUnitsTableQuery(
  $id: ID!
) {
  ...companyBusinessUnitsTable_units_1Bmzm5
}

fragment companyBusinessUnitsTable_units_1Bmzm5 on Query {
  company(id: $id) {
    businessUnits(first: 6, sortColumn: ["business_units.name"], sortDirection: ["ASC"]) {
      edges {
        node {
          id
          name
          contactName
          contactEmail
          contactPhone
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = [
      {
        kind: "Literal",
        name: "first",
        value: 6
      } as any,
      {
        kind: "Literal",
        name: "sortColumn",
        value: ["business_units.name"]
      } as any,
      {
        kind: "Literal",
        name: "sortDirection",
        value: ["ASC"]
      } as any
    ],
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "companyBusinessUnitsTableQuery",
      selections: [
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "companyBusinessUnitsTable_units"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "companyBusinessUnitsTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Company",
          kind: "LinkedField",
          name: "company",
          plural: false,
          selections: [
            {
              alias: null,
              args: v2 /*: any*/,
              concreteType: "BusinessUnitConnection",
              kind: "LinkedField",
              name: "businessUnits",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "BusinessUnitEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "BusinessUnit",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "name",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "contactName",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "contactEmail",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "contactPhone",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey:
                'businessUnits(first:6,sortColumn:["business_units.name"],sortDirection:["ASC"])'
            },
            {
              alias: null,
              args: v2 /*: any*/,
              filters: ["search", "sortColumn", "sortDirection"],
              handle: "connection",
              key: "CompanyBusinessUnitsTable_businessUnits",
              kind: "LinkedHandle",
              name: "businessUnits"
            },
            v3 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "763cc7e4cc8f462d804d66c0b16f852c",
      id: null,
      metadata: {},
      name: "companyBusinessUnitsTableQuery",
      operationKind: "query",
      text:
        'query companyBusinessUnitsTableQuery(\n  $id: ID!\n) {\n  ...companyBusinessUnitsTable_units_1Bmzm5\n}\n\nfragment companyBusinessUnitsTable_units_1Bmzm5 on Query {\n  company(id: $id) {\n    businessUnits(first: 6, sortColumn: ["business_units.name"], sortDirection: ["ASC"]) {\n      edges {\n        node {\n          id\n          name\n          contactName\n          contactEmail\n          contactPhone\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n    id\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "d9d7ef6777cd536f6d4138fa0feaec08";
export default node;
