/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type productsTableExportQueryVariables = {
  bladeScope?: string | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
};
export type productsTableExportQueryResponse = {
  readonly products: {
    readonly nodes: ReadonlyArray<{
      readonly activeLicenseCount: number;
      readonly id: string;
      readonly name: string | null;
      readonly activeUsers: number;
      readonly type: {
        readonly name: string;
      };
    } | null> | null;
  } | null;
};
export type productsTableExportQuery = {
  readonly response: productsTableExportQueryResponse;
  readonly variables: productsTableExportQueryVariables;
};

/*
query productsTableExportQuery(
  $bladeScope: String
  $sortColumn: [String!]
  $sortDirection: [String!]
) {
  products(bladeScope: $bladeScope, sortColumn: $sortColumn, sortDirection: $sortDirection) {
    nodes {
      activeLicenseCount
      id
      name
      activeUsers
      type {
        name
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "bladeScope"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "sortColumn"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "sortDirection"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "bladeScope"
      } as any,
      {
        kind: "Variable",
        name: "sortColumn",
        variableName: "sortColumn"
      } as any,
      {
        kind: "Variable",
        name: "sortDirection",
        variableName: "sortDirection"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "activeLicenseCount",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "activeUsers",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "productsTableExportQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ProductConnection",
          kind: "LinkedField",
          name: "products",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "ProductCategory",
                  kind: "LinkedField",
                  name: "type",
                  plural: false,
                  selections: [v4 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "productsTableExportQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ProductConnection",
          kind: "LinkedField",
          name: "products",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "ProductCategory",
                  kind: "LinkedField",
                  name: "type",
                  plural: false,
                  selections: [v4 /*: any*/, v3 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "5a1551ca5e26b8d5df9ddbcec8ad29ab",
      id: null,
      metadata: {},
      name: "productsTableExportQuery",
      operationKind: "query",
      text:
        "query productsTableExportQuery(\n  $bladeScope: String\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n) {\n  products(bladeScope: $bladeScope, sortColumn: $sortColumn, sortDirection: $sortDirection) {\n    nodes {\n      activeLicenseCount\n      id\n      name\n      activeUsers\n      type {\n        name\n        id\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "3f362bccdd778b36e0b654498b668850";
export default node;
