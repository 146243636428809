import React, { useEffect, useMemo, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { allProductsQueryResponse } from "./__generated__/allProductsQuery.graphql";
import _ from "lodash";
import { ElmFilterSelect } from "../../elmSelect";
import { ElmSelectOption } from "../../elmSelect";
import clsx from "clsx";
import { useLazyLoadQuery } from "react-relay";

const graphqlQuery = graphql`
  query productsFilterQuery {
    allProductsFilter: products(
      sortColumn: ["product.name"]
      sortDirection: ["asc"]
    ) {
      nodes {
        name
        id
      }
    }
  }
`;

export interface IFilterProp {
  productId?: string;
  onChange?: (productId: string, productName: string) => void;
}
export default function ProductsFilter({ productId, onChange }: IFilterProp) {
  const [localState, setLocalState] = useState({
    productId: productId || "-1",
    filtered: productId && productId !== "-1"
  });

  useEffect(() => {
    if (productId !== localState.productId) {
      setLocalState({ ...localState, productId: productId });
    }
  }, [productId]);

  const data = useLazyLoadQuery(graphqlQuery, {}) as allProductsQueryResponse;
  const dataNodes = _.get(data, "allProductsFilter.nodes", []);
  const options = [
    {
      id: -1,
      name: "All products"
    },
    ...dataNodes
  ];
  const handleChange = (payload: any) => {
    let productId: string = payload;
    let filtered: boolean = true;
    if (payload === "-1") {
      productId = null;
      filtered = false;
    }
    setLocalState({
      productId,
      filtered
    });
    let productName = options.filter(item => item.id === productId);
    // this.setState({ productId, filtered }, () => {
    if (_.isFunction(onChange)) {
      onChange(productId, productName[0]?.name || "");
    }
    // });
  };
  const renderFilter = useMemo(() => {
    if (!data || !data.allProductsFilter) {
      return null;
    }
    return (
      <ElmFilterSelect
        value={localState.productId || "-1"}
        onChange={handleChange}
        className={clsx(localState.filtered ? "filtered" : "", "filterBorder")}
        dropdownMatchSelectWidth={false}
      >
        {_.map(options, node => {
          return <ElmSelectOption key={node.id}>{node.name}</ElmSelectOption>;
        })}
      </ElmFilterSelect>
    );
  }, [data, localState.productId, productId]);

  return renderFilter;
}
