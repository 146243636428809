import { Dropdown, Avatar, MenuProps } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox";
import styled from "styled-components";

import { appConnect, appDispatch } from "../../store/appConnect";
import { getIcon } from "../icons";
import { IBladeManagerState } from "./types";
import { ElmToggle } from "../elmToggle";
import ImgCrop from "antd-img-crop";
import { ActiveMode, IUserRole } from "store/types";
import { getGateway } from "api";
import AppImageDictionary from "utils/AppImageDictionary/AppImageDictionary";

const ProfileImageSectionContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;
const UserEmail = styled.p`
  margin: 0px;
  height: 15px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.colors.topBarText};
`;

const SignOutContainer = styled(Flex)`
  :hover {
    cursor: pointer;
  }
  color: ${props => props.theme.colors.textPrimary};
`;

interface UserContainerProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    isAuthenticated: boolean;
    email: string;
    activeRole: IUserRole;
    activeMode: ActiveMode;
  };
}

interface UserContainerState {
  userInfo: IBladeManagerState["userInfo"];
}

class UserInfoContainer extends React.Component<UserContainerProps> {
  state: UserContainerState = {
    userInfo: null
  };
  public componentDidUpdate = () => {
    if (this.state.userInfo) {
      return;
    }
    this.getUserEmail();
  };
  public getUserEmail = async () => {
    const activeRoleType = _.get(this.props, "appState.activeRole.type");
    if (!activeRoleType || activeRoleType === "company") return;
    const gateway = getGateway();
    const userInfo = await gateway.request.getCurrentUser();
    if (_.isObject(userInfo.data)) {
      const thumb_url = await AppImageDictionary.getImageSrc(
        `user-${userInfo.data?.id}`,
        userInfo.data?.thumb_url
      );
      this.setState({ userInfo: { ...userInfo.data, thumb_url } });
    }
  };
  public render = () => {
    const SignOutIcon = styled(getIcon("arrow-right-from-bracket", "fas"))`
      color: ${props => props.theme.colors.warmGrey};
      margin-right: 8px;
    `;
    const signOutHandler = () => {
      this.props.appDispatch.deviseActions.requestSignOut();
    };
    const handleOnModeChange = (mode: any) => {
      const theme = mode ? "dark" : "light";
      this.props.appDispatch.appActions.updateActiveMode(theme);
      this.props.appDispatch.appActions.updateUserDefinedMode(true);
    };
    const items: MenuProps["items"] = [
      {
        key: "1",
        label: (
          <ElmToggle
            size="small"
            leftLabel={<span style={{ fontWeight: "normal" }}>Dark mode</span>}
            title="Choose theme"
            onChange={(state: boolean) => handleOnModeChange(state)}
            checked={this.props.appState.activeMode === "dark" ? true : false}
          />
        )
      },
      {
        key: "2",
        label: (
          <SignOutContainer
            style={{
              alignItems: "center",
              justifyContent: "left",
              fontSize: "13px"
            }}
            onClick={signOutHandler}
          >
            <SignOutIcon />
            <span>Sign Out</span>
          </SignOutContainer>
        )
      }
    ];

    const avatarName = _.get(
      this.props.appState,
      "currentUser.attributes.name",
      ""
    );

    return (
      <Dropdown
        overlayClassName="dropdownLogout"
        overlayStyle={{ cursor: "pointer" }}
        trigger={["click"]}
        menu={{ items }}
      >
        <Flex style={{ cursor: "pointer", marginLeft: "35px" }}>
          <ProfileImageSectionContainer>
            <ImgCrop>
              {this.state.userInfo?.thumb_url ? (
                <img
                  src={this.state.userInfo.thumb_url}
                  alt="avatar"
                  style={{ width: "100%", borderRadius: "50%" }}
                />
              ) : (
                <Flex justifyContent={"center"} alignItems="center">
                  <Avatar
                    size={36}
                    style={{
                      width: 36,
                      height: 36,
                      display: "block",
                      backgroundColor: "#4DBF40",
                      fontSize: "10.8px"
                    }}
                  >
                    {avatarName
                      ? `${avatarName
                          .split(" ")
                          .map((name: string) => name[0])
                          .join("")
                          .toUpperCase()}`
                      : "N/A"}
                  </Avatar>
                </Flex>
              )}
            </ImgCrop>
          </ProfileImageSectionContainer>
          <Flex
            flexDirection="column"
            justifyContent="center"
            style={{ color: "#ffffff", marginLeft: "8px" }}
          >
            <Flex
              style={{
                color: "#BEBDBC",
                fontSize: "10px",
                textTransform: "capitalize"
              }}
            >
              {this.props.appState?.activeRole?.type || "N/A"}
            </Flex>
            <Flex>
              <UserEmail>{this.props.appState.email}</UserEmail>
            </Flex>
          </Flex>
        </Flex>
      </Dropdown>
    );
  };
}

export default appConnect(UserInfoContainer, {
  selectors: {
    email: "userEmailSelector",
    activeRole: "activeRoleSelector",
    currentUser: "currentUserSelector",
    name: "userNameSelector",
    activeMode: "activeModeSelector"
  }
});
