/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type producReleaseConfigurationsTableQueryVariables = {
  bladeScope: string;
};
export type producReleaseConfigurationsTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<
    "producReleaseConfigurationsTable_releaseConfigurations"
  >;
};
export type producReleaseConfigurationsTableQuery = {
  readonly response: producReleaseConfigurationsTableQueryResponse;
  readonly variables: producReleaseConfigurationsTableQueryVariables;
};

/*
query producReleaseConfigurationsTableQuery(
  $bladeScope: String!
) {
  ...producReleaseConfigurationsTable_releaseConfigurations_2aAGdB
}

fragment producReleaseConfigurationsTable_releaseConfigurations_2aAGdB on Query {
  releaseConfigurations(bladeScope: $bladeScope, first: 6) {
    edges {
      node {
        id
        name
        releasesCount
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "bladeScope"
      } as any
    ],
    v1 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "bladeScope"
    } as any,
    v2 = [
      v1 /*: any*/,
      {
        kind: "Literal",
        name: "first",
        value: 6
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "producReleaseConfigurationsTableQuery",
      selections: [
        {
          args: [v1 /*: any*/],
          kind: "FragmentSpread",
          name: "producReleaseConfigurationsTable_releaseConfigurations"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "producReleaseConfigurationsTableQuery",
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: "ReleaseConfigurationConnection",
          kind: "LinkedField",
          name: "releaseConfigurations",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfigurationEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseConfiguration",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "name",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "releasesCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v2 /*: any*/,
          filters: ["bladeScope", "search"],
          handle: "connection",
          key: "producReleaseConfigurationsTable_releaseConfigurations",
          kind: "LinkedHandle",
          name: "releaseConfigurations"
        }
      ]
    },
    params: {
      cacheID: "1b0c498d0843917af3ca5ebd56ca3520",
      id: null,
      metadata: {},
      name: "producReleaseConfigurationsTableQuery",
      operationKind: "query",
      text:
        "query producReleaseConfigurationsTableQuery(\n  $bladeScope: String!\n) {\n  ...producReleaseConfigurationsTable_releaseConfigurations_2aAGdB\n}\n\nfragment producReleaseConfigurationsTable_releaseConfigurations_2aAGdB on Query {\n  releaseConfigurations(bladeScope: $bladeScope, first: 6) {\n    edges {\n      node {\n        id\n        name\n        releasesCount\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "5e781794594542adb2f751f1f857f622";
export default node;
