import React from "react";
import styled, { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import { checkPermissions } from "components/helpers";
import { MenuProps } from "antd";
import { Dropdown, Tooltip } from "antd";
import { getFaIcon } from "components/icons";
import { IUserRole } from "store/types";
import { ElmButton } from "components/elmButton";
import _ from "lodash";

const BanIcon = getFaIcon({ iconName: "ban", prefix: "fas" });

const AttributeActionsDropdown = (props: {
  permissions?: string[];
  license?: {
    isLicenseTerminateAppliable: boolean;
    isExpired: boolean;
    isPending: boolean;
    requestedBy: string;
  };
  showLinkingDialog?: () => void;
  showEditDialog?: () => void;
  showDeleteDialog?: () => void;
  showTerminateDialog?: (havePermission?: boolean) => void;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const userInfo = useSelector(
    (state: { app: { activeRole: IUserRole } }) => state.app.activeRole
  );

  const havePermissions = checkPermissions({
    userPermissions: userInfo.permissions,
    requiredPermissions: props.permissions
  });

  const terminatePermissions = checkPermissions({
    userPermissions: userInfo.permissions,
    requiredPermissions: ["modify_licenses"]
  });

  const isLinkingAvailable =
    havePermissions && _.isFunction(props.showLinkingDialog);
  const isEditAvailable = havePermissions && _.isFunction(props.showEditDialog);
  const isDeleteAvailable =
    havePermissions && _.isFunction(props.showDeleteDialog);
  const isTerminateAvailable =
    props.license?.isLicenseTerminateAppliable &&
    havePermissions &&
    _.isFunction(props.showTerminateDialog);

  const isTerminateOptionDisabled =
    props.license?.isExpired || props?.license?.isPending || false;

  if (
    !isLinkingAvailable &&
    !isEditAvailable &&
    !isDeleteAvailable &&
    !isTerminateAvailable
  ) {
    return null;
  }

  let key = 0;

  const btnStyleOverride = {
    color: theme.colors.black,
    padding: 3,
    paddingLeft: 0,
    margin: 0
  };

  const disabledOption = {
    color: theme.colors.buttonDisabled,
    cursor: "not-allowed",
    opacity: 0.6
  };

  const handleTerminateAction = () => {
    props.showTerminateDialog(terminatePermissions);
  };

  const items: MenuProps["items"] = [
    isLinkingAvailable
      ? {
          key: key++,
          onClick: props.showLinkingDialog,
          label: (
            <ElmButton
              style={btnStyleOverride}
              className={"darkModeTransparentBtn"}
              variance={"plain-icon-button"}
              colorVariance={"subtle"}
              icon={"link-simple"}
              iconPrefix={"far"}
              label={"Link User"}
              permissions={props.permissions}
            />
          )
        }
      : null,
    isEditAvailable
      ? {
          key: key++,
          onClick: props.showEditDialog,
          label: (
            <ElmButton
              style={btnStyleOverride}
              className={"darkModeTransparentBtn"}
              variance={"plain-icon-button"}
              colorVariance={"subtle"}
              icon={"pen"}
              iconPrefix={"far"}
              label={"Edit"}
              permissions={props.permissions}
            />
          )
        }
      : null,
    isDeleteAvailable
      ? {
          key: key++,
          onClick: props.showDeleteDialog,
          label: (
            <ElmButton
              style={btnStyleOverride}
              className={"darkModeTransparentBtn"}
              variance={"plain-icon-button"}
              colorVariance={"subtle"}
              icon={"trash-can"}
              iconPrefix={"far"}
              label={"Delete"}
              permissions={props.permissions}
            />
          )
        }
      : null,
    isTerminateAvailable
      ? {
          key: key++,
          onClick: isTerminateOptionDisabled
            ? () => null
            : handleTerminateAction,
          label: isTerminateOptionDisabled ? (
            <Tooltip
              placement="bottom"
              overlayInnerStyle={{ padding: "16px" }}
              title={() => {
                if (props?.license?.isPending) {
                  return "Action already requested";
                }
                if (props?.license?.isExpired) {
                  return "License has expired";
                }
                if (!props?.license?.isLicenseTerminateAppliable) {
                  return "Action is not appliable";
                }
                return "";
              }}
            >
              <div style={disabledOption}>
                <BanIcon /> Terminate
              </div>
            </Tooltip>
          ) : (
            <ElmButton
              style={btnStyleOverride}
              className={"darkModeTransparentBtn"}
              variance={"plain-icon-button"}
              colorVariance={"subtle"}
              icon={"ban"}
              iconPrefix={"fas"}
              label={"Terminate"}
              permissions={props.permissions}
              disabled={isTerminateOptionDisabled}
            />
          )
        }
      : null
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} disabled={props.disabled}>
      <ElmButton
        style={{
          ...btnStyleOverride,
          paddingLeft: 7,
          marginLeft: 4,
          background: theme.colors.highlight
        }}
        disabled={props.disabled}
        permissions={props.permissions}
        variance={"plain-icon-button"}
        colorVariance={"subtle"}
        icon={"ellipsis"}
        iconPrefix={"fas"}
        label={""}
      />
    </Dropdown>
  );
};

export default AttributeActionsDropdown;
