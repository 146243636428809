/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type allCompaniesQueryVariables = {};
export type allCompaniesQueryResponse = {
  readonly allCompaniesFilter: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly id: string;
    } | null> | null;
  } | null;
};
export type allCompaniesQuery = {
  readonly response: allCompaniesQueryResponse;
  readonly variables: allCompaniesQueryVariables;
};

/*
query allCompaniesQuery {
  allCompaniesFilter: companies(sortColumn: ["companies.name"], sortDirection: ["asc"]) {
    nodes {
      name
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
    {
      alias: "allCompaniesFilter",
      args: [
        {
          kind: "Literal",
          name: "sortColumn",
          value: ["companies.name"]
        },
        {
          kind: "Literal",
          name: "sortDirection",
          value: ["asc"]
        }
      ],
      concreteType: "CompanyConnection",
      kind: "LinkedField",
      name: "companies",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Company",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "name",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey:
        'companies(sortColumn:["companies.name"],sortDirection:["asc"])'
    } as any
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "allCompaniesQuery",
      selections: v0 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "allCompaniesQuery",
      selections: v0 /*: any*/
    },
    params: {
      cacheID: "c7901780400d744aac18cbff4f7b91fd",
      id: null,
      metadata: {},
      name: "allCompaniesQuery",
      operationKind: "query",
      text:
        'query allCompaniesQuery {\n  allCompaniesFilter: companies(sortColumn: ["companies.name"], sortDirection: ["asc"]) {\n    nodes {\n      name\n      id\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "cb31955501e1fd02bea99b460260f262";
export default node;
