import * as _ from "lodash";
import * as React from "react";
import { Suspense } from "react";
import { RouteChildrenProps } from "react-router-dom";
import Loader from "react-loaders";
import { appConnect, appDispatch } from "../../store/appConnect";
import elmLogoLight from "../../logo.svg";
import elmLogoDark from "../../logo-dark.svg";
import styled from "styled-components";
import { ActiveMode } from "store/types";
// import BladeManager from "../bladeManager";

interface IGateKeeperProps extends RouteChildrenProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    isAuthenticated: boolean;
    isSignedIn: boolean;
    hasVerificationBeenAttempted: boolean;
    activeMode: ActiveMode;
  };
}

const StyledScreenLoaderContainer = styled.div`
  background-color: ${props => props.theme.colors.bladeContainer};
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 99999;
`;

const BladeManager = React.lazy(() => import("../bladeManager"));
class GateKeeper extends React.Component<IGateKeeperProps> {
  public componentDidMount(): void {
    const { isSignedIn, hasVerificationBeenAttempted } = this.props.appState;
    if (!isSignedIn && !hasVerificationBeenAttempted) {
      this.props.appDispatch.deviseActions.requestVerifyCredentials();
    }
  }
  public componentDidUpdate(nextProps: IGateKeeperProps): void {
    this.checkStatus(this.props);
  }
  public checkStatus = (props: IGateKeeperProps) => {
    const { hasVerificationBeenAttempted, isSignedIn } = props.appState;
    const { history, location } = props;
    const isOnLogin = location.pathname.includes("login");
    if (hasVerificationBeenAttempted && !isSignedIn && !isOnLogin) {
      history.push("/login", { from: location.pathname });
      // @ts-ignore
      FreshworksWidget("close");
      // @ts-ignore
      FreshworksWidget("hide", "launcher");
    }
  };
  public renderScreenLoader = () => {
    return (
      <StyledScreenLoaderContainer>
        <div
        // style={{
        //   alignItems: "center",
        //   display: "flex",
        //   flexDirection: "column",
        //   height: `100%`,
        //   justifyContent: "center",
        //   position: "absolute",
        //   width: "100%",
        //   zIndex: 99999
        // }}
        >
          <img
            src={
              this.props.appState.activeMode === "light"
                ? elmLogoLight
                : elmLogoDark
            }
            alt="Evoleap logo"
            style={{ display: "flex", justifySelf: "self" }}
          />
          <Loader
            type="ball-beat"
            active={true}
            color={"rgb(98, 98, 130)"}
            style={{ transform: "scale(0.5)" }}
          />
        </div>
      </StyledScreenLoaderContainer>
    );
  };
  public handleRender = () => {
    this.checkStatus(this.props); //
    const { hasVerificationBeenAttempted, isSignedIn } = this.props.appState;
    const canLogIn = hasVerificationBeenAttempted && isSignedIn;
    if (canLogIn) {
      //true
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <BladeManager
            history={this.props.history}
            location={this.props.location}
            match={this.props.match}
          />
        </Suspense>
      );
    }
    return this.renderScreenLoader();
  };
  public render(): JSX.Element {
    return this.handleRender();
  }
}

export default appConnect(GateKeeper, {
  selectors: {
    isSignedIn: "isSignedInSelector",
    hasVerificationBeenAttempted: "hasVerificationBeenAttemptedSelector",
    activeMode: "activeModeSelector"
  }
});
