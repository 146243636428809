import React, { useEffect, useState } from "react";

import _ from "lodash";
import { Flex } from "reflexbox";
import { ExportData } from "./exportHook";
import { ElmButton, IElmButtonProps } from "../elmButton";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { CSVDownload } from "react-csv";
import { licenseTypeToName } from "../../utils";
import moment from "moment";
import { useTheme } from "styled-components";
import { DATE_FORMAT } from "const";

const tableHeaders = {
  //tableName prop inside tableGenerator is used for keys
  users: [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Company", key: "company" },
    { label: "Product", key: "products" }
  ],
  products: [
    { label: "Product", key: "product" },
    { label: "Active licenses", key: "active_licenses" },
    { label: "Active users", key: "active_users" },
    { label: "Type", key: "type" }
  ],
  companies: [
    { label: "Company", key: "company" },
    { label: "Products", key: "products" },
    { label: "Active licenses", key: "active_licenses" },
    { label: "Active users", key: "active_users" }
  ],
  licensesTable: [
    { label: "Company", key: "company" },
    { label: "Product", key: "product" },
    { label: "Licensing model", key: "license_model" },
    { label: "Expiration", key: "expiration" },
    { label: "Key", key: "key" }
  ]
};
export type tableType = "users" | "companies" | "products" | "licensesTable";
interface IExportButton {
  tableType: tableType;
  style?: React.CSSProperties;
  colorVariance?: IElmButtonProps["colorVariance"];
}
interface IDataExporter extends IExportButton {
  dispose: () => void;
}

const mappers = {
  users: (item: any) => {
    return {
      name: _.get(item, "name", ""),
      email: _.get(item, "email", ""),
      company: _.get(item, "owner.name", ""),
      products: item?.products?.nodes?.map(item => item.name).join()
    };
  },
  licensesTable: (item: any) => {
    let expirationDate = _.get(item, "latestTerm.endDate");
    return {
      company: `${_.get(item, "owner.name", "")} ${
        _.get(item, "isVendor", false) ? "- vendor" : ""
      }`,
      product: _.get(item, "product.name", ""),
      license_model: licenseTypeToName(_.get(item, "type.id", "")),
      expiration: moment(expirationDate).format(DATE_FORMAT),
      key: _.get(item, "key", "")
    };
  },
  companies: (item: any) => {
    return {
      company: _.get(item, "name", ""),
      products: _.get(item, "products.nodes", [])
        .map(product => product.name)
        .join(),
      active_licenses: _.get(item, "activeLicenseCount", 0),
      active_users: _.get(item, "activeUserCount", 0)
    };
  },
  products: (item: any) => {
    return {
      product: _.get(item, "name", ""),
      active_licenses: _.get(item, "activeLicenseCount", 0),
      active_users: _.get(item, "activeUsers", 0),
      type: _.get(item, "type.name", "")
    };
  }
};

const getDataMappedByTableType = (nodes: any, mapper: any) => {
  return _.map(nodes, mapper);
};

function DataExporter({ tableType, dispose }: IDataExporter) {
  const filters = window[tableType];
  const mapper = mappers[tableType];
  const headers = tableHeaders[tableType];

  const data = ExportData({ ...filters, tableType });

  useEffect(() => {
    return dispose();
  }, [filters, dispose]);

  if (data) {
    let key = Object.keys(data)[0]; // key from query or a first prop inside object, examples: licenses, users, companies, products
    let res = _.get(data, `${key}.nodes`);
    let dataMapped = getDataMappedByTableType(res, mapper);

    const csvReport = {
      data: dataMapped,
      headers: headers,
      filename: "TableExport.csv"
    };
    return <CSVDownload {...csvReport} />;
  }

  return <></>;
}

export function useExportButton({
  tableType,
  style,
  colorVariance
}: IExportButton) {
  const [exportRunning, setExportRunning] = useState<boolean>(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const theme = useTheme();

  const loader = (
    <Flex alignItems="center">
      <Spin indicator={antIcon}>Exporting..</Spin>
    </Flex>
  );

  const renderExportButton = () => (
    <React.Suspense fallback={loader}>
      <ElmButton
        className={"darkModeTransparentBtn"}
        variance={"plain-icon-button"}
        colorVariance={colorVariance || "subtle"}
        icon={"download"}
        iconPrefix={"far"}
        label={".CSV"}
        onClick={() => setExportRunning(true)}
        style={{ color: theme.colors.black, ...style }}
      />
      {exportRunning && (
        <DataExporter
          tableType={tableType}
          dispose={() => setExportRunning(false)}
        />
      )}
    </React.Suspense>
  );

  return { renderExportButton, setExportRunning } as const;
}

export function ExportButton(props: IExportButton) {
  const { renderExportButton } = useExportButton({ ...props });
  return renderExportButton();
}

export default ExportButton;
