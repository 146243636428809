import * as _ from "lodash";
import * as React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Flex } from "reflexbox/styled-components";
// @ts-ignore
import { slideInRight, slideOutRight } from "react-animations";
import styled, { keyframes } from "styled-components";
import { toSuperScript } from "../../utils";
import { getClassName } from "../helpers";

export interface ITab {
  title: string;
  disabled?: boolean;
  supScript?: string | number;
  Component: React.ComponentElement<any, any>;
  marginRight?: string;
  style?: React.CSSProperties;
  id?: string;
  tourGuideId?: string;
  renderHeader?: () => JSX.Element;
}
export interface ITabViewProps {
  tabList: ITab[];
  leftAlign?: boolean;
  showBottomBorder?: boolean;
  defaultTab?: number;
  titleContainerStyle?: React.CSSProperties;
  orientation?: "vertical" | "horizontal";
  onUpdateActiveTab?: (activeTab: number, tabName?: string) => void;
  style?: React.CSSProperties;
}
export interface ITabViewState {
  activeTab: number;
}

const transitionName = "tab";

const slideInAnimation = keyframes`${slideInRight}`;
const slideOutAnimation = keyframes`${slideOutRight}`;

const TransitionContainer = styled.div`
  align-items: baseline;
  flex: 1;
  padding: 5px;
  width: 100%;
  overflow-x: hidden;
`;
const TabBody = styled(Flex)`
  //overflow-y: hidden;
  flex: 1;
  height: 100%;
  &.${transitionName}-enter {
    width: 100%;
    animation: 0.6s ${slideInAnimation};
  }
  &.${transitionName}-appear {
    animation: 0.5s ${slideInAnimation};
  }

  &.${transitionName}-leave {
    width: 0px;
    animation: 1s ${slideOutAnimation};
  }
`;

const Tab = styled.div<any>`
  ${props => props.theme.fontType.tab}

  text-align: center;
  border-bottom: ${props =>
    _.get(props, "showBottomBorder") ? `none` : `1px #DDDBDA solid`};
  &.vertical {
    border-bottom: none;
    border-left: 2px solid #e5e5e5;
    display: flex;
    text-align: left;
    height: 40px;
    margin-bottom: 5px;
  }
  &.active {
    color: ${props => props.theme.colors.activeTab};
    font-weight: 600;
    border-bottom: 2px ${props => props.theme.colors.lightBlue} solid;
    &.vertical {
      border-bottom: none;
      border-left: 2px solid ${props => props.theme.colors.lightBlue};
    }
  }
  &.inactive {
    color: ${props => props.theme.colors.textPrimary};
    padding-bottom: 1px;
  }
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.hover};
  }
`;
const TabHeaderContainer = styled<any>(Flex)`
  border-bottom: ${props =>
    _.get(props, "showBottomBorder") ? `1px #DDDBDA solid` : "none"};
  width: 100%;
`;
const SuperScript = styled.p`
  line-height: 18;
  margin: 0px;
`;
export class TabView extends React.Component<ITabViewProps, ITabViewState> {
  constructor(props: ITabViewProps) {
    super(props);
    this.state = {
      activeTab: _.isFinite(this.props.defaultTab) ? this.props.defaultTab : 0
    };
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    window.onpopstate = () => {
      this.setState({ activeTab: this.props.defaultTab });
    };
  }

  public updateActiveTab = (activeTab: number) => () => {
    if (!this.props.tabList[activeTab].disabled) {
      this.setState({ activeTab }, () => {
        if (_.isFunction(this.props.onUpdateActiveTab)) {
          const activeTabTitle = _.get(
            this.props,
            `tabList[${activeTab}].title`,
            ""
          );
          this.props.onUpdateActiveTab(activeTab, activeTabTitle);
        }
      });
    }
  };
  public getSupScriptValue = (tab: ITab) => {
    return _.isFinite(tab.supScript)
      ? tab.supScript.toString()
      : (tab.supScript as string);
  };
  public renderTabTitles = () => {
    if (this.props.orientation === "vertical") {
      return (
        <TabHeaderContainer
          style={
            this.props.titleContainerStyle || {
              flexDirection: "column",
              width: "max-content"
            }
          }
        >
          {_.map(this.props.tabList, (tab, index) => {
            return (
              <Tab
                key={tab.title}
                id={tab.tourGuideId || tab.id}
                onClick={this.updateActiveTab(index)}
                className={getClassName({
                  vertical: () => this.props.orientation === "vertical",
                  active: () => this.state.activeTab === index,
                  inactive: () => this.state.activeTab !== index
                })}
                showBottomBorder={this.props.showBottomBorder}
                style={{
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  alignItems: "center"
                }}
              >
                {_.isFunction(tab.renderHeader)
                  ? tab.renderHeader()
                  : tab.supScript
                  ? `${tab.title} ${toSuperScript(this.getSupScriptValue(tab))}`
                  : tab.title}
              </Tab>
            );
          })}
        </TabHeaderContainer>
      );
    }
    return (
      <TabHeaderContainer
        justifyContent={this.props.leftAlign ? "start" : "center"}
        showBottomBorder={this.props.showBottomBorder}
        style={this.props.titleContainerStyle || {}}
      >
        {_.map(this.props.tabList, (tab, index) => {
          return (
            <Tab
              key={tab.title}
              id={tab.id}
              onClick={this.updateActiveTab(index)}
              className={index === this.state.activeTab ? "active" : "inactive"}
              showBottomBorder={this.props.showBottomBorder}
              style={{
                marginRight: "20px",
                ...(tab.style || {})
              }}
            >
              {tab.supScript
                ? `${tab.title} ${toSuperScript(this.getSupScriptValue(tab))}`
                : tab.title}
            </Tab>
          );
        })}
      </TabHeaderContainer>
    );
  };
  public renderTabBody = () => {
    const activeTab = _.find(
      this.props.tabList,
      (tab, indx) => indx === this.state.activeTab
    );
    if (!activeTab) {
      return null;
    }
    return (
      <TabBody className="tabview-body" key={activeTab.title} flex={1}>
        {activeTab.Component}
      </TabBody>
    );
  };
  public render() {
    return (
      <Flex
        flexDirection={this.props.orientation === "vertical" ? "row" : "column"}
        justifyContent={"baseline"}
        flex={1}
        className={"tabview-container"}
        style={this.props.style}
      >
        {this.renderTabTitles()}
        <ReactCSSTransitionGroup
          transitionName={transitionName}
          transitionAppear={true}
          transitionEnterTimeout={100}
          transitionLeaveTimeout={100}
          component={TransitionContainer}
        >
          {this.renderTabBody()}
        </ReactCSSTransitionGroup>
      </Flex>
    );
  }
}

export default TabView;
