import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { getGateway } from "../../../../api";
import { LoaderContainer } from "../../../../components/helpers";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../../api/relay";
import { graphql } from "babel-plugin-relay/macro";
import { Divider, notification } from "antd";
import { getFaIcon } from "components/icons";
import UsersShortListTable from "Users/components/usersShortListTable";
import {
  userLinkingQuery,
  userLinkingQueryResponse
} from "./__generated__/userLinkingQuery.graphql";
import ElmRadio from "components/elmRadio";

const graphqlQuery = graphql`
  query userLinkingQuery {
    ...usersShortListTable_users
    usersCount
  }
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 480px;
  width: 440px;
`;
const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;

const DialogSubtitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
  color: ${props => props.theme.colors.warmGrey}
`;

const Paragraph = styled.p`
  ${props => props.theme.fontType.paragraph}
  color: ${props => props.theme.colors.warmGrey}
`;

const BriefcaseIcon = styled(
  getFaIcon({ iconName: "briefcase", prefix: "far" })
)`
  color: ${props => props.theme.colors.primary};
`;

interface IUserLinkingDialogProps {
  id: string;
  name: string;
  company: string;
  refresh?: () => void;
}
type state = {
  userId: string;
  name: string;
  company: string;
  loading: boolean;
  search: string;
  selected: string;
};
export class UserLinkingDialog extends DialogBase<
  IModalProps<IUserLinkingDialogProps> & {
    result: userLinkingQueryResponse;
    retry: () => void;
    theme: any;
  },
  state
> {
  state: state = {
    userId: this.props.payload.id,
    name: this.props.payload.name,
    company: this.props.payload.company,
    selected: null,
    loading: false,
    search: null
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "UserLinkingDialog"
    });
  };
  public getTitle = () => {
    return `Link ${this.state.name}`;
  };
  public getCompany = () => {
    return `${this.state.company}`;
  };
  public handleClick = (row: IUserLinkingDialogProps, _index: number) => {
    if (this.state.selected === row.id) {
      this.setState({ selected: null });
      return;
    }
    this.setState({ selected: row.id });
  };
  public handleLinkUser = () => {
    const gateway = getGateway();
    this.state.selected &&
      gateway.request["linkUser"](
        { id: this.state.userId, linked_user_id: this.state.selected },
        {
          id: this.state.userId
        }
      )
        .then(res => {
          if (!res.error) {
            notification.open({
              type: "success",
              message: "Success",
              description: "User successfully linked !"
            });
            this.props.payload.refresh();
            this.closeModal();
          }
        })
        .catch(err => {
          notification.open({
            type: "error",
            message: "Failure",
            description: "Link user failed !"
          });
        });
  };

  public renderQueryRenderer = () => {
    const renderComponentGroup = (payload: {
      error: Error;
      props: userLinkingQueryResponse;
      retry: () => void;
    }) => {
      if (payload.error || !_.isObject(payload.props)) {
        return <DialogContainer>{LoaderContainer()}</DialogContainer>;
      }

      return (
        <DialogContainer>
          <DialogTitle>{this.getTitle()}</DialogTitle>

          <Flex
            flexDirection="column"
            flex={1}
            style={{
              width: "100%",
              marginTop: 24,
              marginBottom: 0
            }}
          >
            <UsersShortListTable
              users={payload.props}
              onRowClick={this.handleClick}
              renderRowActionButtons={data => (
                <Flex alignItems={"center"}>
                  <ElmRadio checked={data?.id === this.state.selected} />
                </Flex>
              )}
            />
          </Flex>
          <Flex>
            <DialogSubtitle>CURRENT COMPANY</DialogSubtitle>
          </Flex>
          <Divider style={{ marginTop: 0, marginBottom: 12 }} />
          <Flex alignItems={"center"} style={{ gap: 4 }}>
            <BriefcaseIcon />
            <Paragraph>{this.getCompany()}</Paragraph>
          </Flex>
          <Flex alignItems="flex-end" justifyContent={"flex-end"}>
            <ElmButton
              label={"Cancel"}
              colorVariance={"outline-secondary"}
              onClick={this.closeModal}
            />
            <ElmButton
              disabled={this.state.loading || !this.state.selected}
              label={"Update"}
              colorVariance="primary"
              onClick={this.handleLinkUser}
            />
          </Flex>
        </DialogContainer>
      );
    };
    return (
      <QueryRenderer<userLinkingQuery>
        variables={{ count: 25, search: this.state.search }}
        environment={relayEnvironment}
        query={graphqlQuery}
        render={renderComponentGroup}
      />
    );
  };

  public render() {
    return this.renderQueryRenderer();
  }
}
