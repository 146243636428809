import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ElmRadio from "../../../../components/elmRadio";
import Fuse from "fuse.js";
import _ from "lodash";
import React, { useState } from "react";
import { Flex } from "reflexbox";
import styled from "styled-components";
import theme from "../../../../theme";
import { Radio, RadioChangeEvent } from "antd";
import { ComponentFilter } from "Licenses/blades/Entitlement";

const GroupSelectionDesc = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  letter-spacing: 0.16px;

  /* text / text-01 */

  color: ${props => props.theme.colors.textPrimary};
  height: 18px;
`;

const GroupComponentName = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  /* Black */

  color: ${props => props.theme.colors.black};
`;

const GroupSelectionContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.whiteTwo};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 202px;
  overflow-y: scroll;
  margin-top: 10px;
`;

interface IGroupSelection {
  type: "componentGroup" | "component";
  searchQuery: string;
  defaultValue?: string;
  onChange: (payload: {
    checked: boolean;
    id: string;
    name: string;
    type: ComponentFilter;
  }) => void;
  availableComponents: string[];
}

const GroupSelection = <T extends object>(props: T & IGroupSelection) => {
  const [value, setValue] = useState(props.defaultValue);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  let content;
  const handleSelection = (node: {
    id: string;
    name: string;
    type: ComponentFilter;
  }) => (e: CheckboxChangeEvent) => {
    if (_.isFunction(props.onChange)) {
      props.onChange({
        ...node,
        checked: e.target.checked
      });
    }
  };

  const getNodes = () => {
    let groups = _.get(
      props.product,
      "componentGroups.nodes"
    ) as typeof props.product.componentGroups.nodes;
    if (props.availableComponents) {
      groups = groups?.filter(group => {
        let isAvailable = false;
        group.components?.nodes?.forEach(c => {
          if (props.availableComponents.includes(c.id)) {
            isAvailable = true;
          }
        });
        return isAvailable;
      });
    }
    if (props.searchQuery && groups) {
      const results = new Fuse(groups, {
        keys: ["name", "components.nodes.name"]
      }).search(props.searchQuery);
      return _.map(
        results,
        i => i.item as typeof props.product.componentGroups.nodes[number]
      );
    }
    return groups;
  };
  const getComponentNodes = (
    nodes: typeof props.product.componentGroups.nodes[number]["components"]["nodes"]
  ) => {
    if (props.searchQuery && nodes) {
      const results = new Fuse(nodes, {
        keys: ["name"]
      }).search(props.searchQuery);
      return _.map(
        results,
        i =>
          i.item as typeof props.product.componentGroups.nodes[number]["components"]["nodes"][number]
      );
    }
    return nodes;
  };

  content = _.map(getNodes(), node => {
    return (
      <Flex
        flexDirection="column"
        style={{
          borderBottom: `1px solid ${theme.colors.highlight}`,
          marginTop: "14px"
          // alignItems: 'center',
        }}
      >
        <Flex
          style={{
            height: "18px",
            alignItems: "center",
            marginBottom: "4.5px"
          }}
        >
          <Radio.Group
            onChange={onChange}
            value={value}
            defaultValue={props.defaultValue}
          >
            <Radio
              style={{ marginRight: "9.25px", marginLeft: "10.2px" }}
              onChange={handleSelection({ ...node, type: node.componentType })}
              value={node.id}
            >
              <GroupSelectionDesc>{node.name}</GroupSelectionDesc>
            </Radio>
          </Radio.Group>
        </Flex>
        {_.map(getComponentNodes(node.components.nodes), componentNode => (
          <GroupComponentName style={{ marginLeft: "46px" }}>
            {componentNode.name}
          </GroupComponentName>
        ))}
      </Flex>
    );
  });

  return (
    <GroupSelectionContainer>
      <ElmRadio.Group>{content}</ElmRadio.Group>
    </GroupSelectionContainer>
  );
};

export default GroupSelection;
