/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userProductsActivationTableQueryVariables = {
  id: string;
};
export type userProductsActivationTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"userProductsTable_activation">;
};
export type userProductsActivationTableQuery = {
  readonly response: userProductsActivationTableQueryResponse;
  readonly variables: userProductsActivationTableQueryVariables;
};

/*
query userProductsActivationTableQuery(
  $id: ID!
) {
  ...userProductsTable_activation_1Bmzm5
}

fragment userProductsTable_activation_1Bmzm5 on Query {
  status: user(id: $id) {
    displayName
    productUsers(first: 25) {
      edges {
        node {
          product {
            id
            name
          }
          enabled
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    instances {
      edges {
        node {
          id
          license {
            product {
              id
              name
            }
            id
          }
          modificationRecords(last: 1) {
            edges {
              node {
                enabled
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = [
      {
        kind: "Literal",
        name: "first",
        value: 25
      } as any
    ],
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      concreteType: "Product",
      kind: "LinkedField",
      name: "product",
      plural: false,
      selections: [
        v3 /*: any*/,
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "name",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "enabled",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "userProductsActivationTableQuery",
      selections: [
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "userProductsTable_activation"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "userProductsActivationTableQuery",
      selections: [
        {
          alias: "status",
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "displayName",
              storageKey: null
            },
            {
              alias: null,
              args: v2 /*: any*/,
              concreteType: "ProductUserConnection",
              kind: "LinkedField",
              name: "productUsers",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ProductUserEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ProductUser",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v4 /*: any*/,
                        v5 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: "productUsers(first:25)"
            },
            {
              alias: null,
              args: v2 /*: any*/,
              filters: ["search"],
              handle: "connection",
              key: "userProductsTable_productUsers",
              kind: "LinkedHandle",
              name: "productUsers"
            },
            {
              alias: null,
              args: null,
              concreteType: "InstanceConnection",
              kind: "LinkedField",
              name: "instances",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "InstanceEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Instance",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: "License",
                          kind: "LinkedField",
                          name: "license",
                          plural: false,
                          selections: [v4 /*: any*/, v3 /*: any*/],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: [
                            {
                              kind: "Literal",
                              name: "last",
                              value: 1
                            }
                          ],
                          concreteType: "ModificationRecordConnection",
                          kind: "LinkedField",
                          name: "modificationRecords",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "ModificationRecordEdge",
                              kind: "LinkedField",
                              name: "edges",
                              plural: true,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  concreteType: "ModificationRecord",
                                  kind: "LinkedField",
                                  name: "node",
                                  plural: false,
                                  selections: [v5 /*: any*/],
                                  storageKey: null
                                }
                              ],
                              storageKey: null
                            }
                          ],
                          storageKey: "modificationRecords(last:1)"
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v3 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "b096a6cc8fcafdb9e4f31a7b5e407165",
      id: null,
      metadata: {},
      name: "userProductsActivationTableQuery",
      operationKind: "query",
      text:
        "query userProductsActivationTableQuery(\n  $id: ID!\n) {\n  ...userProductsTable_activation_1Bmzm5\n}\n\nfragment userProductsTable_activation_1Bmzm5 on Query {\n  status: user(id: $id) {\n    displayName\n    productUsers(first: 25) {\n      edges {\n        node {\n          product {\n            id\n            name\n          }\n          enabled\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    instances {\n      edges {\n        node {\n          id\n          license {\n            product {\n              id\n              name\n            }\n            id\n          }\n          modificationRecords(last: 1) {\n            edges {\n              node {\n                enabled\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "09721cd338600b7941bbf64a1b7c5e19";
export default node;
