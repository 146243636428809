import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenProductReleaseChannelBlade
} from "../../../components/bladeManager/types";
import {
  BladeTemplate,
  BladeTitle,
  ElmButton,
  getIcon,
  TabView
} from "../../../components";
import { IAttributValuePair } from "../../../components/attributesBox";
import {
  ProductReleaseChannelQuery,
  ProductReleaseChannelQueryResponse
} from "./__generated__/ProductReleaseChannelQuery.graphql";

import { deepEqual } from "fast-equals";
import { LicenseTableType } from "../../../Licenses/components/licensesTable";
import { ITab } from "../../../components/tabView";
import ReleaseLicensesTable from "./releaseLicenseOwnerTable";
import styled, { useTheme } from "styled-components";
import theme from "../../../theme";
import { AutoSizer } from "react-virtualized";
import { updateBladeUrlState } from "utils";

const StyledProductComponentInfo = styled(Flex)`
  color: ${props => props.theme.colors.textPrimary};
`;

const graphqlQuery = graphql`
  query ProductReleaseChannelQuery($id: ID!) {
    ...releaseLicenseOwnerTable_licenses @arguments(id: $id)
    releaseChannel(id: $id) {
      name
      currentRelease {
        id
        tag
      }
      releaseConfiguration {
        product {
          iconUrl
        }
      }
    }
  }
`;
export interface IProductReleaseChannelBladeProps
  extends IBladeBaseProps<IOpenProductReleaseChannelBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}
export type state = {
  mode: "edit" | "normal";
  activeTabIndex: number;
};
export class ProductReleaseChannelBlade extends React.Component<
  IProductReleaseChannelBladeProps & {
    result: ProductReleaseChannelQueryResponse;
    retry: () => void;
    theme: any;
  },
  state
> {
  state: state = {
    mode: "normal",
    activeTabIndex: 0
  };
  public shouldComponentUpdate(
    nextProps: IProductReleaseChannelBladeProps & {
      result: ProductReleaseChannelQueryResponse;
    },
    nextState: state
  ) {
    return (
      !deepEqual(this.state, nextState) ||
      !deepEqual(this.props.routeData, nextProps.routeData) ||
      !deepEqual(this.props.result, nextProps.result)
    );
  }
  public renderLeftSideHeader = () => {
    return (
      <Flex>
        <BladeTitle>
          {_.get(this.props.result, "releaseChannel.name")}
        </BladeTitle>
      </Flex>
    );
  };
  public navAway = () => {
    this.props.openBlade({
      routeName: "Customer portal",
      fromBladeIndex: this.props.index,
      route: "Overview",
      routeData: null
    });
  };

  public renderRightSideHeader = () => {
    const updateReleaseConfig = () => {
      this.props.openDialog("EditProductReleaseConfigDialog", {
        entityType: "release configuration",
        name: _.get(this.props.result, "releaseChannel.name"),
        id: this.props.routeData.releaseId,
        releaseChannelId: this.props.routeData.id,
        currentRelease: _.get(
          this.props.result,
          "releaseChannel.currentRelease"
        ),
        isEdit: true,
        onConfirm: () => {
          this.props.refreshAllOpenBlades();
        }
      });
    };

    const openDeleteDialog = () => {
      this.props.openDialog("DeleteDialog", {
        entityType: "Release channel",
        name: _.get(this.props.result, "releaseChannel.name"),
        onConfirm: () => {
          this.props.gateway.request
            .deleteReleaseChannel(null, { id: this.props.routeData.id })
            .then(res => {
              if (!_.get(res, "error")) {
                this.props.closeBlade({ route: "ProductReleaseChannel" });
                this.props.closeDialog("DeleteDialog");
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
    };

    return (
      <Flex>
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon="pen"
          iconPrefix="fas"
          label={"Edit"}
          permissions="modify_assets"
          onClick={() => updateReleaseConfig()}
        />

        <ElmButton
          style={{
            color: this.props.theme.colors.trashButtonColor
          }}
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon={"trash"}
          iconPrefix={"fas"}
          label={"Delete"}
          permissions="modify_assets"
          onClick={() => openDeleteDialog()}
        />
      </Flex>
    );
  };
  public renderAttributes = () => {
    const TagIcon = styled(getIcon("IoMdPricetag"))`
      color: ${props => props.theme.colors.productsBlue};
    `;
    const DotIcon = styled(getIcon("FaDotCircle"))`
      color: ${props => props.theme.colors.whiteTwo};
      font-size: 4px;
      display: flex;
      align-items: center;
    `;
    const items = [
      {
        label: "Tag",
        value: _.get(this.props.result, "releaseChannel.currentRelease.tag")
      },
      {
        label: "Active On",
        value: _.get(this.props.result, "release.tag")
      }
    ] as IAttributValuePair[];
    const renderItems = () => {
      if (_.isArray(items)) {
        return (
          <Flex
            flexWrap="wrap"
            flex={1}
            style={_.merge({
              height: "max-content",
              fontSize: "12px",
              fontWeight: 400
            })}
          >
            <div style={{ marginRight: "12px" }}>
              <TagIcon />
              <span style={{ color: theme.colors.warmGrey, marginLeft: "5px" }}>
                {items[0]?.value || "N/A"}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <DotIcon />
            </div>
            <div
              style={{
                marginRight: "12px",
                marginLeft: "12px"
              }}
            >
              {items[1].label}
              <span style={{ color: theme.colors.warmGrey, marginLeft: "5px" }}>
                {items[1]?.value || "N/A"}
              </span>
            </div>
          </Flex>
        );
      }
      return null;
    };
    return (
      <Flex
        style={{
          padding: "0px 10px 10px 0px",
          position: "relative",
          alignItems: "center"
        }}
      >
        {renderItems()}
      </Flex>
    );
  };
  public navToLicenseBlade = (license: LicenseTableType) => {
    this.props.openBlade({
      route: "License",
      routeName: "License",
      fromBladeIndex: this.props.index,
      routeData: {
        id: license.id
      }
    });
  };

  public renderReleaseChannelLicenses = () => {
    return (
      <ReleaseLicensesTable
        // names={this.props.result}
        // products={this.props.result}
        onRowClick={this.navToLicenseBlade}
        licenses={this.props.result}
      />
    );
  };

  public renderReleaseChannelHistory = () => {
    return (
      <div>Release channel history</div>
      // <ReleaseLicensesTable
      //   // names={this.props.result}
      //   // products={this.props.result}
      //   onRowClick={this.navToLicenseBlade}
      //   licenses={this.props.result}
      // />
    );
  };

  public getTabs = () => {
    //if (res === null) return [];
    let ret = [];
    ret.push({
      title: "Licenses",
      Component: this.renderReleaseChannelLicenses()
      // supScript: _.get(res, 'product.componentsCount'),
    });
    ret.push({
      title: "History",
      Component: this.renderReleaseChannelHistory()
      // supScript: _.get(res, 'product.featuresCount'),
    });

    return ret as ITab[];
  };

  public updateActiveTab = (activeTabIndex: number) => {
    updateBladeUrlState(this.props, activeTabIndex);
    this.setState({
      activeTabIndex
    });
  };

  public renderProductInfo = () => {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Releases"}
        bladeTypeName={"Releases"}
        accentColor={"productsBlue"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        loading={!this.props.result}
        closeBlade={this.props.closeBlade}
        bladeType="ProductReleaseChannel"
      >
        <Flex
          style={{
            maxHeight: "159",
            width: "100%",
            borderBottom: `1px solid ${theme.colors.whiteTwo}`
          }}
        ></Flex>
        {/* {this.renderTable()} */}
        <Flex
          style={{
            marginTop: "12px",
            marginBottom: "35px",
            paddingLeft: "21px",
            paddingRight: "21px",
            flexDirection: "column",
            flex: 1
          }}
        >
          {this.renderAttributes()}
          <div style={{ display: "flex", flex: 1, marginTop: "12px" }}>
            <AutoSizer defaultHeight={300}>
              {({ height, width }) => (
                <TabView
                  showBottomBorder={true}
                  leftAlign={true}
                  tabList={this.getTabs()}
                  defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
                  onUpdateActiveTab={this.updateActiveTab}
                  orientation={"vertical"}
                  style={{
                    maxHeight: height,
                    minHeight: height,
                    maxWidth: width,
                    minWidth: width
                    // paddingLeft: "21px",
                    // paddingRight: "21px"
                  }}
                />
              )}
            </AutoSizer>
          </div>
        </Flex>
        <StyledProductComponentInfo></StyledProductComponentInfo>
      </BladeTemplate>
    );
  };

  public render() {
    return this.renderProductInfo();
  }
}
const RenderQuery = (props: IProductReleaseChannelBladeProps) => {
  const theme = useTheme();
  const renderProductInfo = (payload: {
    error: Error;
    props: ProductReleaseChannelQueryResponse;
    retry: () => void;
  }) => {
    return (
      <ProductReleaseChannelBlade
        {...props}
        result={payload.props}
        theme={theme}
        retry={payload.retry}
      />
    );
  };
  return (
    <QueryRenderer<ProductReleaseChannelQuery>
      environment={relayEnvironment}
      variables={{
        id: props.routeData.id
        //blade_scope: props.routeData.id
      }}
      query={graphqlQuery}
      render={renderProductInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
