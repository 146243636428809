/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ProductQueryVariables = {
  id: string;
};
export type ProductQueryResponse = {
  readonly product: {
    readonly usersCount: number;
    readonly licensesCount: number;
    readonly licenseeCount: number;
    readonly name: string | null;
    readonly createdAt: unknown;
    readonly iconUrl: string | null;
  } | null;
};
export type ProductQuery = {
  readonly response: ProductQueryResponse;
  readonly variables: ProductQueryVariables;
};

/*
query ProductQuery(
  $id: ID!
) {
  product(id: $id) {
    usersCount
    licensesCount
    licenseeCount
    name
    createdAt
    iconUrl
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "usersCount",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "licensesCount",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "licenseeCount",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "createdAt",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "iconUrl",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "ProductQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "ProductQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "42813651392310de889d29c1a4703512",
      id: null,
      metadata: {},
      name: "ProductQuery",
      operationKind: "query",
      text:
        "query ProductQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    usersCount\n    licensesCount\n    licenseeCount\n    name\n    createdAt\n    iconUrl\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "337eacce631ee17d6e0f046f05d36829";
export default node;
