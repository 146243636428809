/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ProductsQueryVariables = {};
export type ProductsQueryResponse = {
  readonly allProductsCount: number;
  readonly allProducts: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly licensesCount: number;
    } | null> | null;
  } | null;
};
export type ProductsQuery = {
  readonly response: ProductsQueryResponse;
  readonly variables: ProductsQueryVariables;
};

/*
query ProductsQuery {
  allProductsCount: productsCount
  allProducts: products {
    nodes {
      name
      licensesCount
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      alias: "allProductsCount",
      args: null,
      kind: "ScalarField",
      name: "productsCount",
      storageKey: null
    } as any,
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "licensesCount",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "ProductsQuery",
      selections: [
        v0 /*: any*/,
        {
          alias: "allProducts",
          args: null,
          concreteType: "ProductConnection",
          kind: "LinkedField",
          name: "products",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [v1 /*: any*/, v2 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "ProductsQuery",
      selections: [
        v0 /*: any*/,
        {
          alias: "allProducts",
          args: null,
          concreteType: "ProductConnection",
          kind: "LinkedField",
          name: "products",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v1 /*: any*/,
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "id",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "bcaf788a269d1485dca15de0fe13a9d5",
      id: null,
      metadata: {},
      name: "ProductsQuery",
      operationKind: "query",
      text:
        "query ProductsQuery {\n  allProductsCount: productsCount\n  allProducts: products {\n    nodes {\n      name\n      licensesCount\n      id\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "65beb3f1985e42bc50d2b27582c73bba";
export default node;
