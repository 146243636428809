import { analyticsComponentQueryResponse } from "../../components/analyticsComponent/__generated__/analyticsComponentQuery.graphql";
import moment from "moment";
import { IChartQuery } from "./types";
import _ from "lodash";
import { DEFAULT_ALL_TIME_START_YEAR } from "components/analyticsComponent/filters/timeSpan";

export const getTimeFilter = (timeRange: string) => {
  const t = moment()
    .utc()
    .startOf("day");
  const t_1 = moment()
    .utc()
    .day(1)
    .startOf("d");
  const t_m = moment()
    .utc()
    .endOf("M");
  switch (timeRange) {
    case "last24":
      return {
        startTime: moment()
          .utc()
          .startOf("hour")
          .subtract(24, "h")
          .toISOString(),
        endTime: moment()
          .utc()
          .startOf("hour")
          .toISOString(),
        category: "time.hour"
      };
    case "days7":
    case "last7":
      return {
        startTime: moment()
          .utc()
          .startOf("day")
          .subtract(7, "d")
          .toISOString(),
        endTime: t.toISOString(),
        category: "time.day"
      };
    case "month1":
    case "last30":
      return {
        startTime: moment()
          .utc()
          .startOf("day")
          .subtract(30, "d")
          .toISOString(),
        endTime: t.toISOString(),
        category: "time.day"
      };
    case "months3":
    case "last90":
      return {
        startTime: t_1.subtract(12, "w").toISOString(),
        endTime: t.toISOString(),
        category: "time.week"
      };
    case "months6":
    case "last180":
      return {
        startTime: t_1.subtract(6, "M").toISOString(),
        endTime: t.toISOString(),
        category: "time.week"
      };
    case "year1":
    case "lastYear":
      return {
        startTime: t_m.subtract(12, "M").toISOString(),
        endTime: t.toISOString(),
        category: "time.month"
      };
    case "year2":
      return {
        startTime: t_m.subtract(24, "M").toISOString(),
        endTime: t.toISOString(),
        category: "time.month"
      };
    case "allTime":
      return {
        startTime: t_m
          .set("year", DEFAULT_ALL_TIME_START_YEAR)
          .startOf("year")
          .toISOString(),
        endTime: t.toISOString(),
        category: "time.month"
      };
    default:
      return {
        startTime: moment()
          .utc()
          .startOf("hour")
          .subtract(24, "h")
          .toISOString(),
        endTime: moment()
          .utc()
          .startOf("hour")
          .toISOString(),
        category: "time.hour"
      };
  }
};

let queryParametersObj = {
  bladeScope: null,
  setup: {
    category: "",
    value: "",
    group: ""
  },
  filterColumn: null,
  filterValues: null,
  variables: null,
  sortColumn: null,
  sortDirection: null,
  first: null,
  startTime: null,
  endTime: null
} as IChartQuery;

export const generateQueryFilter = (
  currentParams: IChartQuery,
  timeRangeOverride: string,
  productName: string,
  companyName?: string,
  allTimeFilterStart?: string
) => {
  let params = { ...queryParametersObj, ...currentParams };

  //get the filter options
  if (timeRangeOverride && timeRangeOverride !== "allTime") {
    const timeFilter = getTimeFilter(timeRangeOverride);
    params.startTime = timeFilter.startTime;
    params.endTime = timeFilter.endTime;
    params.setup = {
      ...params.setup,
      category: params.setup.category.startsWith("time")
        ? timeFilter.category
        : params.setup.category
      //category: timeFilter.category
    };
  }
  if (timeRangeOverride === "allTime") {
    const timeFilter = getTimeFilter(timeRangeOverride);
    params.startTime = allTimeFilterStart
      ? allTimeFilterStart
      : timeFilter.startTime;
    params.endTime = timeFilter.endTime;
    params.setup = {
      ...params.setup,
      category: params.setup.category.startsWith("time")
        ? timeFilter.category
        : params.setup.category
    };
  }

  if (productName && productName !== "-1") {
    let filterColumn = [...params.filterColumn, "products.name"];
    let filterValues = [...params.filterValues, [productName]];
    params.filterColumn = filterColumn;
    params.filterValues = filterValues;
  }

  if (companyName && companyName !== "-1") {
    let filterColumn = [...params.filterColumn, "companies.name"];
    let filterValues = [...params.filterValues, [companyName]];
    params.filterColumn = filterColumn;
    params.filterValues = filterValues;
  }

  return params;
};

export const customChartTheme = {
  fontSize: "12px",
  fontFamily: "Inter",
  fontWeight: "400"
};

export const getChartData = (res: analyticsComponentQueryResponse) => {
  if (!res?.analytics) return [];
  const values = res.analytics.values;
  const data = [];
  let dataForCategory;

  if (_.isArray(res.analytics.categories)) {
    for (const c of res.analytics.categories) {
      const dataPoint: { [key: string]: any } = {};
      dataForCategory = _.filter(values, v => {
        return v["category"] == c;
      });
      for (const g of res.analytics.groups) {
        dataPoint["category"] = c;
        let thisValue = _.map(
          _.filter(dataForCategory, v => {
            return v["group"] === g;
          }),
          v => {
            return _.get(v, "value");
          }
        );
        if (thisValue.length > 0) {
          dataPoint[g] = thisValue[0];
        }
      }
      if (_.keys(dataPoint).length > 1) data.push(dataPoint);
    }
  }
  return data;
};

export const exportMenuItems = [
  {
    type: "separator",
    label: "Data"
  },
  {
    type: "format",
    format: "xlsx",
    label: "CSV"
  },
  {
    type: "separator",
    label: "Image"
  },
  {
    type: "format",
    format: "png",
    label: "PNG"
  }
];
