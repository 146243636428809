import * as _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import appActions from "./actions";
import selectors from "./selectors";
import { IStore } from "./types";

// const loadFileTypes = (type: "selector") => {
//   let searchRegex;
//   const typesCatalog = {};
//   switch (type) {
//     case "selector":
//       searchRegex = /^\.\/\w+\/(selector.ts)/;
//       break;
//   }
//   const storeDirContext = require.context(".", true, searchRegex);
//   storeDirContext.keys().forEach((f: string) => {
//     const exportsForType = storeDirContext(f);
//     const componentName = f.split("/")[1];
//     typesCatalog[componentName] = exportsForType;
//   });
//   return typesCatalog;
// };
type ArgumentTypes<F> = F extends (...args: infer A) => any ? A : never;
export type IActions<T> = {
  [p in keyof T]: {
    [k in keyof T[p]]: (
      ...args: ArgumentTypes<T[p][k]>
    ) => { type: string; data: any };
  };
};
export type selectorKey = keyof typeof selectors;
export interface ISelectorMap {
  [prop: string]: selectorKey;
}
export interface IAppConnectOptions<T = any> {
  selectors: ISelectorMap;
}
const appStateToProps = (opts: IAppConnectOptions) => {
  return (state: IStore, ownProps: any) => ({
    appState: _.mapValues(opts.selectors, selectorName => {
      if (
        _.has(selectors, selectorName) &&
        _.isFunction(selectors[selectorName])
      ) {
        return selectors[selectorName](state, ownProps);
      }
      return null;
    })
  });
};
export type appDispatch = IActions<typeof appActions>;
export const appDispatch = (dispatch: any) => {
  // automatically pick up all actions defined
  // const actions = _.isObject(appActions) ? appActions : { filler: () => ({}) };
  return _.mapValues(appActions, actionSet =>
    bindActionCreators(actionSet, dispatch)
  ) as appDispatch;
};
const mergeProps = (stateProps: any, dispatchProps: any, ownProps: any) => ({
  ...stateProps,
  ...ownProps,
  appDispatch: {
    ...dispatchProps
  }
});
// const t = appDispatch("").deviseActions.;
export const appConnect = <P = any>(
  Component: any,
  opts: IAppConnectOptions
) => {
  const options = opts || ({} as IAppConnectOptions);
  if (!_.has(options, "selectors")) {
    options.selectors = {};
  }
  return connect<any, {}, P, {}, IStore>(
    appStateToProps(options),
    appDispatch,
    mergeProps
  )(Component);
};
