import * as _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { ElmRadio } from "../../../elmRadio";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import relayEnvironment from "../../../../api/relay";
import { getGateway } from "../../../../api";
import {
  selectReleaseQuery,
  selectReleaseQueryResponse
} from "./__generated__/selectReleaseQuery.graphql";
import { RadioChangeEvent } from "antd/lib/radio";
import theme from "../../../../theme";
import { getIcon } from "../../../../components";

const SectionTitle = styled.p`
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.colors.warmGrey};
  border-bottom: 1px solid ${props => props.theme.colors.greyish};
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 480px;
  width: 440px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const DialogSubTitle = styled.p`
  ${props => props.theme.fontType.visualizationSubTitle}
`;
const groupSelectionQuery = graphql`
  query selectReleaseQuery($id: ID!) {
    releaseChannel(id: $id) {
      name
      currentRelease {
        id
        tag
      }
      releaseConfiguration {
        releases {
          nodes {
            id
            tag
          }
        }
        product {
          iconUrl
        }
      }
    }
  }
`;
const GroupSelectionHeader = styled.p`
  height: 12px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  margin: 0px;
`;
const GroupSelectionDesc = styled.p`
  height: 15px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  margin: 0px;
`;
const StyledProductComponentInfo = styled(Flex)`
  color: ${props => props.theme.colors.textPrimary};
`;

type releaseSelectionType = selectReleaseQueryResponse["releaseChannel"]["releaseConfiguration"]["releases"]["nodes"][number];
const ReleaseSelection = (
  props: selectReleaseQueryResponse & {
    onChange: (n: releaseSelectionType & { selected: boolean }) => void;
    currentSelection: releaseSelectionType & { selected: boolean };
  }
) => {
  let content;
  const getOnChange = (e: RadioChangeEvent) => {
    if (_.isFunction(props.onChange)) {
      props.onChange({
        ...e.target.value,
        selected: e.target.checked
      });
    }
  };
  if (props.releaseChannel) {
    if (props.releaseChannel.releaseConfiguration.releases.nodes.length) {
      content = (
        <Flex
          style={{
            flexDirection: "column",
            height: "200px",
            overflowY: "scroll"
          }}
        >
          {_.map(
            props.releaseChannel.releaseConfiguration.releases.nodes,
            node => {
              return (
                <Flex style={{ alignItems: "center" }}>
                  <ElmRadio
                    checked={_.get(props.currentSelection, "id") === node.id}
                    onChange={getOnChange}
                    value={node}
                    style={{ marginRight: "40px" }}
                  />
                  <GroupSelectionDesc>{node.tag}</GroupSelectionDesc>
                </Flex>
              );
            }
          )}
        </Flex>
      );
    } else {
      content = (
        <Flex
          style={{ marginTop: "10px", justifyContent: "center" }}
        >{`No releases found for ${props.releaseChannel.name}. Create one.`}</Flex>
      );
    }
  }
  return (
    <Flex
      style={{ height: "158px", marginTop: "7px", flexDirection: "column" }}
    >
      <Flex
        flex={1}
        style={{
          borderBottom: "solid 1px #302d28",
          maxHeight: "15px",
          paddingBottom: "6.7px",
          marginBottom: "7px",
          paddingLeft: "10px",
          width: "100%",
          overflow: "scroll"
        }}
      >
        <GroupSelectionHeader style={{ marginLeft: "50px" }}>
          Tag
        </GroupSelectionHeader>
      </Flex>
      {content}
    </Flex>
  );
};
export interface ISelectReleaseDialogProps {
  title?: string;
  description?: string;
  releaseChannelName?: string;
  releaseChannelId: string;
}
type state = {
  name: string;
  disabled: boolean;
  selection: releaseSelectionType & { selected: boolean };
};
export class SelectReleaseDialog extends DialogBase<
  IModalProps<ISelectReleaseDialogProps>,
  state
> {
  state: state = {
    name: "",
    disabled: false,
    selection: null
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          // height: "340px",
          // width: "394px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "SelectReleaseDialog"
    });
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };
  public getTitle = () => {
    return "Update Current Release";
  };
  public renderQueryRenderer = () => {
    const renderGroupSelection = (payload: {
      error: Error;
      props: selectReleaseQueryResponse;
    }) => {
      if (payload.error) {
        return null;
      }
      return (
        <ReleaseSelection
          {...payload.props}
          onChange={this.onUpdateSelection}
          currentSelection={this.state.selection}
        />
      );
    };
    return (
      <QueryRenderer<selectReleaseQuery>
        variables={{
          id: _.get(this.props.payload, "releaseChannelId")
        }}
        environment={relayEnvironment}
        query={groupSelectionQuery}
        render={renderGroupSelection}
      />
    );
  };
  public getDescription = () => {
    return (
      <React.Fragment>
        {`Update the current release for ${_.get(
          this.props,
          "payload.releaseChannelName"
        )} to:`}
        <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
          {_.get(this.state.selection, "tag") || ""}{" "}
        </span>
      </React.Fragment>
    );
  };
  public createSelectRelease = () => {
    this.setState(
      {
        disabled: true
      },
      () => {
        const gateway = getGateway();
        gateway.request
          .updateReleaseForChannel(
            {
              id: this.props.payload.releaseChannelId,
              release_id: this.state.selection.id
            },
            { id: this.props.payload.releaseChannelId }
          )
          .then(res => {
            if (_.isObject(res.data)) {
              if (res.data.id) {
                if (_.isFunction(this.props.payload.onConfirm)) {
                  this.props.payload.onConfirm();
                }
                return this.closeModal();
              }
            }
            this.setState({ disabled: false });
            return false;
          })
          .catch(err => {
            console.error(err);
          });
      }
    );
  };
  public onUpdateSelection = (
    node: releaseSelectionType & { selected: boolean }
  ) => {
    if (node.selected) {
      this.setState({ selection: node });
    } else {
      this.setState({ selection: null });
    }
  };

  public renderItems = () => {
    const TagIcon = styled(getIcon("IoMdPricetag"))`
      color: ${props => props.theme.colors.productsBlue};
    `;
    const DotIcon = styled(getIcon("FaDotCircle"))`
      color: ${props => props.theme.colors.whiteTwo};
      font-size: 4px;
      display: flex;
      align-items: center;
    `;
    const selectedRelease = this.state.selection;
    return (
      <StyledProductComponentInfo>
        <Flex
          flexWrap="wrap"
          flex={1}
          style={_.merge({
            height: "max-content",
            fontSize: "12px",
            fontWeight: 400
          })}
        >
          <div style={{ color: theme.colors.pureBlack, marginRight: "12px" }}>
            <TagIcon />
            <span style={{ color: theme.colors.warmGrey, marginLeft: "5px" }}>
              {selectedRelease?.tag || "N/A"}
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DotIcon />
          </div>
          <div
            style={{
              color: theme.colors.pureBlack,
              marginRight: "12px",
              marginLeft: "12px"
            }}
          >
            Created at:
            <span style={{ color: theme.colors.warmGrey, marginLeft: "5px" }}>
              {selectedRelease?.created_at || "N/A"}
            </span>
          </div>
        </Flex>
      </StyledProductComponentInfo>
    );
  };

  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <DialogSubTitle>{this.getDescription()}</DialogSubTitle>
        <Flex
          flexDirection="column"
          flex={1}
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px"
            //height: "190px",
          }}
        >
          {this.renderQueryRenderer()}
        </Flex>
        <SectionTitle>Selected</SectionTitle>
        <Flex
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            height: "70px"
          }}
        >
          {this.state.selection ? this.renderItems() : null}
        </Flex>
        <Flex alignItems="flex-end" justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            disabled={this.state.disabled || !_.isObject(this.state.selection)}
            label={"Update release"}
            colorVariance="primary"
            onClick={this.createSelectRelease}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
