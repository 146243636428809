import * as _ from "lodash";
import styled from "styled-components";
import React from "react";
import ReactImageFallback from "react-image-fallback";
import { Flex } from "reflexbox/styled-components";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenProductBlade
} from "../../../components/bladeManager/types";
import { ITab } from "../../../components/tabView";
import AttributesBox, {
  IAttributValuePair
} from "../../../components/attributesBox";
import {
  BladeTemplate,
  ElmButton,
  TabView,
  ElmInput
} from "../../../components";
import { LicensePlaceHolderLogo } from "../../../utils";

import { getIcon } from "../../../components/icons";
import { AttributeLabel } from "../../../components/attributesBox";
import { ElmSelect, ElmSelectOption } from "../../../components/elmSelect";
import theme from "../../../theme";
import { generateProductIcon } from "components/productImages/productImages";

export interface INewProductBladeProps
  extends IBladeBaseProps<IOpenProductBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}

export interface INewProductBladeState {
  newProduct: {
    name: string;
    product_type_id: number;
  };
  productTypes: { id: number; description: string }[];
}

const PublicKeyContainer = styled.div`
  width: 100%;
  height: 69px;
  border: solid 1px ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.bladeContainer};
  display: flex;
  flex-direction: column;
  overflow: scroll;
  flex-wrap: wrap;
  overflow-x: hidden;
`;
const PublicKeyText = styled.p`
  height: 62px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  margin: 0px;
`;
const CopyContainer = styled(Flex)`
  align-items: center;
  padding: 2px;
  background-color: ${props => props.theme.colors.lightishBlue};
  color: ${props => props.theme.colors.white};
  margin-top: 5px;
  border-radius: 3px;
  width: 89px;
  height: 18px;
  :hover {
    cursor: pointer;
  }

  span {
    height: 15px;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: right;
  }
`;
const ClipboardIcon = styled(getIcon("FaClipboard"))`
  color: ${props => props.theme.colors.white};
`;
const attributeBoxHeight = "159px";
export class NewProductBlade extends React.Component<
  INewProductBladeProps,
  INewProductBladeState
> {
  constructor(props: INewProductBladeProps) {
    super(props);
    this.state = {
      newProduct: {
        name: "",
        product_type_id: null
      },
      productTypes: null
    };
  }
  public componentDidMount() {
    this.props.gateway.request.getProductTypes().then(res => {
      if (res.error) {
      } else {
        this.setState({ productTypes: res.data });
      }
    });
  }
  public closeBlade = () => {
    this.props.closeBlade({
      fromBladeIndex: this.props.index
    });
  };
  public createNewProduct = () => {
    this.props.gateway.request
      .newProduct(this.state.newProduct)
      .then(res => {
        if (res.error) {
          console.error(res.error);
        } else {
          this.closeBlade();
          _.delay(() => {
            this.props.refreshAllOpenBlades();
          }, 500);
        }
      })
      .catch(e => {
        console.error(e);
      });
  };
  public updateType = (type: any) => {
    this.setState({
      newProduct: {
        ...this.state.newProduct,
        product_type_id: type
      }
    });
  };
  public renderProductAttribute = () => {
    const productTypes = _.map(this.state.productTypes, type => {
      return (
        <ElmSelectOption key={type.id}>{type.description}</ElmSelectOption>
      );
    });
    const items = [
      {
        label: "Contact Email",
        value: <ElmInput />,
        itemStyle: {
          width: "100%"
        }
      },
      {
        label: "Platform",
        value: <ElmSelect onChange={this.updateType}>{productTypes}</ElmSelect>,
        itemStyle: {
          width: "100%"
        }
      }
    ] as IAttributValuePair[];
    return (
      <Flex
        style={{
          width: "233px",
          height: attributeBoxHeight,
          marginRight: "7px"
        }}
      >
        <AttributesBox
          titleColor={theme.colors.lightishBlue}
          borderColor={theme.colors.lightishBlue}
          items={items}
        />
      </Flex>
    );
  };

  public renderProductAttributeAdvanced = () => {
    const items = [
      {
        label: (
          <Flex
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <AttributeLabel>Public Key</AttributeLabel>
            {/* <CopyToClipboard
           text={this.props.product.publicKey}
           onCopy={this.openCopiedMessage}
         > */}
            <CopyContainer>
              <ClipboardIcon />
              <span>To clipboard</span>
            </CopyContainer>
            {/* </CopyToClipboard> */}
          </Flex>
        ),
        value: (
          <PublicKeyContainer>
            <PublicKeyText>
              -----BEGIN PUBLIC KEY-----
              <p> Will automatically be generated on creation</p>
            </PublicKeyText>
          </PublicKeyContainer>
        )
      }
    ] as IAttributValuePair[];
    return (
      <Flex
        style={{
          width: "480px",
          height: attributeBoxHeight,
          marginRight: "7px"
        }}
      >
        <AttributesBox
          titleColor={theme.colors.lightishBlue}
          borderColor={theme.colors.lightishBlue}
          title="Advanced"
          items={items}
        />
      </Flex>
    );
  };
  public renderProductInfo = () => {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Product"}
        topAccentColor={"productsBlue"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        closeBlade={this.props.closeBlade}
        bladeType="NewProduct"
      >
        <Flex style={{ marginBottom: "30px" }}>
          {this.renderProductAttribute()}
          {this.renderProductAttributeAdvanced()}
        </Flex>
        <Flex style={{ position: "relative", flex: 1 }}>
          <div
            style={{
              backgroundColor: theme.colors.white,
              opacity: 0.3,
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "-5px"
            }}
          />
          <TabView
            showBottomBorder={true}
            leftAlign={true}
            tabList={this.getTabs()}
          />
        </Flex>
      </BladeTemplate>
    );
  };
  public updateName = (name: string) => {
    this.setState({
      newProduct: {
        ...this.state.newProduct,
        name
      }
    });
  };
  public renderLeftSideHeader = () => {
    return (
      <Flex>
        <div
          style={{
            height: "52px",
            width: "55px",
            marginRight: "10px"
          }}
        >
          <ReactImageFallback
            src={""}
            fallbackImage={generateProductIcon(this.state.newProduct?.name)}
            initialImage={generateProductIcon(this.state.newProduct?.name)}
            height={"100%"}
            width={"100%"}
          />
        </div>
        <Flex flexDirection="column">
          <ElmInput
            placeholder={"Product Name"}
            updatedValue={this.updateName}
          />
        </Flex>
      </Flex>
    );
  };
  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: "Product Analytics",
      routeData: null,
      fromBladeIndex: this.props.index
    });
  };

  public renderRightSideHeader = () => {
    return (
      <Flex>
        <Flex style={{ marginRight: "25px" }}>
          <ElmButton
            variance={"primary"}
            label={"Save"}
            onClick={this.createNewProduct}
          />
        </Flex>
        <ElmButton
          colorVariance={"secondary"}
          label={"Discard"}
          onClick={this.closeBlade}
        />
      </Flex>
    );
  };

  public getSuperScript = (value: number) => {
    return _.isFinite(value) && value > 0 ? value.toString() : "";
  };
  public getTabs = () => {
    return [
      {
        title: "Users",
        Component: null,
        supScript: ""
      },
      {
        disabled: true,
        title: "Components",
        Component: null,
        supScript: ""
      },
      {
        disabled: true,
        title: "Features",
        Component: null,
        supScript: ""
      },
      {
        title: "Release Configs",
        Component: null,
        supScript: ""
      },
      {
        title: "History",
        Component: null,
        supScript: ""
      },
      {
        Component: null,
        supScript: ""
      }
    ] as ITab[];
  };
  public render() {
    return this.renderProductInfo();
  }
}

export default appConnect(NewProductBlade, {
  selectors: {}
});
