/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicenseComponentQueryVariables = {
  id: string;
};
export type LicenseComponentQueryResponse = {
  readonly component: {
    readonly name: string | null;
    readonly componentType: string;
    readonly componentGroup: {
      readonly name: string | null;
    } | null;
    readonly product: {
      readonly name: string | null;
      readonly licenses: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly owner: {
            readonly name: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type LicenseComponentQuery = {
  readonly response: LicenseComponentQueryResponse;
  readonly variables: LicenseComponentQueryVariables;
};

/*
query LicenseComponentQuery(
  $id: ID!
) {
  component(id: $id) {
    name
    componentType
    componentGroup {
      name
      id
    }
    product {
      name
      licenses {
        nodes {
          id
          owner {
            __typename
            name
            id
          }
        }
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "componentType",
      storageKey: null
    } as any,
    v4 = [v2 /*: any*/],
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "LicenseComponentQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Component",
          kind: "LinkedField",
          name: "component",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentGroup",
              kind: "LinkedField",
              name: "componentGroup",
              plural: false,
              selections: v4 /*: any*/,
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "LicenseConnection",
                  kind: "LinkedField",
                  name: "licenses",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "License",
                      kind: "LinkedField",
                      name: "nodes",
                      plural: true,
                      selections: [
                        v5 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: null,
                          kind: "LinkedField",
                          name: "owner",
                          plural: false,
                          selections: v4 /*: any*/,
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "LicenseComponentQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Component",
          kind: "LinkedField",
          name: "component",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentGroup",
              kind: "LinkedField",
              name: "componentGroup",
              plural: false,
              selections: [v2 /*: any*/, v5 /*: any*/],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "LicenseConnection",
                  kind: "LinkedField",
                  name: "licenses",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "License",
                      kind: "LinkedField",
                      name: "nodes",
                      plural: true,
                      selections: [
                        v5 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: null,
                          kind: "LinkedField",
                          name: "owner",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "__typename",
                              storageKey: null
                            },
                            v2 /*: any*/,
                            v5 /*: any*/
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                v5 /*: any*/
              ],
              storageKey: null
            },
            v5 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "10b18b66cbe02acfeac4403079665d17",
      id: null,
      metadata: {},
      name: "LicenseComponentQuery",
      operationKind: "query",
      text:
        "query LicenseComponentQuery(\n  $id: ID!\n) {\n  component(id: $id) {\n    name\n    componentType\n    componentGroup {\n      name\n      id\n    }\n    product {\n      name\n      licenses {\n        nodes {\n          id\n          owner {\n            __typename\n            name\n            id\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "a5e0d37b9dde1b91ea13bc595a16b37c";
export default node;
