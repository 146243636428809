import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { tableGenerator } from "../../../utils";
import { companyBusinessUnitsTable_units } from "./__generated__/companyBusinessUnitsTable_units.graphql";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { Flex } from "reflexbox";
import {
  companyBusinessUnitsTableQuery,
  companyBusinessUnitsTableQueryResponse
} from "./__generated__/companyBusinessUnitsTableQuery.graphql";
import { shallowEqual } from "fast-equals";
import _ from "lodash";

const GeneratedTable = tableGenerator<{
  units: companyBusinessUnitsTable_units;
}>({
  columns: [
    {
      Header: "Name",
      accessor: "name",
      sortKey: "business_units.name"
    },
    {
      Header: "Contact Name",
      accessor: "contactName",
      disableSortBy: true
    },
    {
      Header: "Contact Phone",
      accessor: "contactPhone",
      disableSortBy: true
    },
    {
      Header: "Actions",
      accessor: "",
      cellRenderer: () => "",
      disableSortBy: true,
      width: 0.07
    }
  ],
  connectionQuery: graphql`
    query companyBusinessUnitsTablePaginationQuery(
      $id: ID!
      $count: Int!
      $cursor: String
      $search: String
      $sortColumn: [String!]
      $sortDirection: [String!]
    ) {
      ...companyBusinessUnitsTable_units
        @arguments(
          id: $id
          count: $count
          cursor: $cursor
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        )
    }
  `,
  dataKey: "units.company.businessUnits",
  fragmentSpec: {
    units: graphql`
      fragment companyBusinessUnitsTable_units on Query
        @argumentDefinitions(
          id: { type: "ID!" }
          count: { type: "Int", defaultValue: 6 }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
          sortColumn: {
            type: "[String!]"
            defaultValue: ["business_units.name"]
          }
          sortDirection: { type: "[String!]", defaultValue: ["ASC"] }
        ) {
        company(id: $id) {
          businessUnits(
            first: $count
            after: $cursor
            search: $search
            sortColumn: $sortColumn
            sortDirection: $sortDirection
          ) @connection(key: "CompanyBusinessUnitsTable_businessUnits") {
            edges {
              node {
                id
                name
                contactName
                contactEmail
                contactPhone
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query companyBusinessUnitsTableQuery($id: ID!) {
    ...companyBusinessUnitsTable_units @arguments(id: $id)
  }
`;

class BusinessUnitsTable extends React.Component<
  IGeneratedTableProps & {
    result: companyBusinessUnitsTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        units={res}
      />
    );
  }
}

const CompanyBusinessUnitsTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: companyBusinessUnitsTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <BusinessUnitsTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<companyBusinessUnitsTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default CompanyBusinessUnitsTable;
