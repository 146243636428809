import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import { appModalRegistry, modalTypes, IAppModalConductorProps } from "./types";

export * from "./types";

export class AppModalConductor extends React.Component<
  IAppModalConductorProps
> {
  public closeModal = (modal: modalTypes) => {
    if (_.isFunction(this.props.closeModal)) this.props.closeModal(modal);
  };
  public renderModals = () => {
    let Comp;
    return (
      <Flex>
        {_.map(_.keys(appModalRegistry), key => {
          Comp = appModalRegistry[key];
          return <Comp key={key} />;
        })}
      </Flex>
    );
  };
  public render() {
    return this.renderModals();
  }
}
