import { getIcon } from "../icons";
import { Table } from "react-virtualized";
import styled, { css } from "styled-components";
import { IOverrideTableStyles } from "./types";
import { Flex } from "reflexbox/styled-components";
import { Dropdown, Menu } from "antd";

const TableFooterHeight = 48;

const fontBaseStyle = css`
  font-family: ${props => props.theme.font.main};
  font-style: normal;
  font-weight: ${props => props.theme.fontWeight.normal};
`;

export const ScrollStyle = css`
  scrollbar-width: thin;
  scrollbar-color: ${props =>
    `${props.theme.colors.black} ${props.theme.colors.lightishBlue}`};
  padding-bottom: 5px;

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    height: 4px;
    width: 4px;
    border: none;
    background: ${props => props.theme.colors.greyish};
    cursor: pointer;
  }
  ::-webkit-scrollbar-track {
    border: none;
    border-radius: 0px;
  }

  *::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 0px;
    height: 4px;
    width: 4px;
    border: none;
    background: ${props => props.theme.colors.greyish};
    cursor: pointer;
  }
  *::-webkit-scrollbar-track {
    border: none;
    border-radius: 0px;
  }
`;

export const StyledTable = styled(Table)<{
  columnAndHeaderStyles: IOverrideTableStyles;
}>`
  padding-bottom: 0px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border: none;
  }
  .ReactVirtualized__Table__Grid {
    :focus {
      outline: none;
    }
    .ReactVirtualized__Grid__innerScrollContainer {
      ${ScrollStyle}
    }
  }
  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    display: flex;
    justify-content: left;
    margin-left: 12px;
  }
`;
export const TableHeader = styled.span`
  ${props => props.theme.fontType.tableHeader}
  text-transform: none;

  position: static;
  height: 40px;
  //padding: 12px;
  left: 0px;
  top: 0px;

  ${fontBaseStyle}
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  /* Black - darker */

  color: ${props => props.theme.colors.warmGrey};

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
  margin: 0px;
  &.active {
    color: ${props => props.theme.colors.textPrimary};
    font-weight: ${props => props.theme.fontWeight.medium};
  }
`;

export const TableHeadSection = styled.thead`
  width: 699px;
  height: 40px;
  left: 40px;
  top: 201px;

  /* white */

  // background: ${props => props.theme.colors.white};
  background: inherit;
  border-bottom: 1px solid lightgrey;
  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  :focus {
    outline: none;
  }
  .ReactVirtualized__Table__headerColumn {
    :focus {
      outline: none;
    }
  }
`;

export const TableCell = styled.td`
  height: 100%;
  line-height: 15px;
  text-align: left;
  color: ${props => props.theme.colors.textPrimary};

  left: 0px;
  top: 0px;

  //flex: none;
  //order: 0;
  //align-self: flex-start;

  ${fontBaseStyle}
  font-size: 13px !important;
  line-height: 20px;
  /* identical to box height, or 167% */

  //display: flex;
  //align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TableCellPTag = styled.p`
  height: 15px;
  ${fontBaseStyle}
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: ${props => props.theme.colors.textPrimary};
  margin: 0px;

  height: 20px;
  left: 0px;
  top: calc(50% - 20px / 2);
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  /* Black */

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
  margin: 4px 0px;
`;

export const ArrowUp = styled(getIcon("ElmAsc"))`
  width: 12px;
  height: 8px;
  color: ${props => props.theme.colors.primary};
`;
export const ArrowDown = styled(getIcon("ElmDsc"))`
  width: 12px;
  height: 8px;
  color: ${props => props.theme.colors.primary};
`;
export const TableFooter = styled.div`
  background: ${props => props.theme.colors.white};
  border-top: 1px solid lightgrey;
  position: absolute;
  width: calc(100% - 2px);
  margin-left: 1px;
  margin-bottom: 1px;
  height: ${TableFooterHeight}px;
  bottom: 0px;
  display: flex;
  align-items: center;

  span {
    ${fontBaseStyle}
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    letter-spacing: 0.16px;

    /* Black */

    color: ${props => props.theme.colors.warmGrey};
    margin-left: 12px;
  }
`;

/* filterSelect styles start*/
const { SubMenu } = Menu;
const styledDropShared = css`
  display: flex;
  flex-direction: row;
  padding: 3px 6px;
  position: static;
  height: 32px;
  left: 317px;
  top: 0px;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 4;
  align-self: center;
  margin: 2px 0px;
  margin-left: 4px;
  margin-right: 2px;
`;

export const StyledDrop = styled(Dropdown)`
  ${styledDropShared}
  box-sizing: border-box;
  width: 70.16px;
  height: 32px;
  left: 317px;
  top: 0px;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.warmGrey};
  :hover {
    box-shadow: 0px 0px 0px 2px
      ${props => props.theme.colors.highlightBoxShadow};
  }
`;

export const StyledSubMenu = styled(SubMenu)<{ isSelected: boolean }>`
  min-width: 134px;
  max-width: 156px;
  //max-height: 168px;
  height: 34px;
  font-size: 13px;
  background-color: ${props =>
    props.isSelected
      ? props.theme.colors.filterBackground
      : props.theme.colors.filterBackground};
  div {
    :hover {
      background-color: ${props => props.theme.colors.hover} !important;
    }
    &.ant-dropdown-menu-submenu-title {
      display: flex;
      align-items: center;
      padding-right: 14px;
      background-color: ${props => props.theme.colors.filterBackground};
      color: ${props => props.theme.colors.textPrimary};
    }
  }
`;
export const ColumnFilterContainer = styled(Flex)`
  align-items: flex-start;
  min-width: 156px;
  max-width: 166px;
  flex-direction: column;
  max-height: 175px;
  padding-bottom: 5px;
  border-radius: 0px;
  overflow: hidden;
  box-shadow: none;
  ul {
    margin: 0px;
    left: -4px;
  }
`;
const StyledFilterTextShared = css`
  height: 24px;
  left: 6px;
  top: 3px;
  color: ${props => props.theme.colors.textPrimary};
  line-height: 24px;
  margin: 10px 0px;
`;
export const StyledFilterText = styled.text`
  ${StyledFilterTextShared}
  width: 36px;

  font-weight: 500;
  font-size: ${props => props.theme.fontSizes.small};

  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  text-align: right;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
`;

const FilterTextContainerShared = css`
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 3px 6px;
  position: static;

  box-sizing: border-box;
  border-radius: 4px;

  flex: none;
  order: 4;
  align-self: center;

  margin-left: 4px;
  :hover {
    cursor: pointer;
  }
`;

export const FilterTextContainer = styled(Flex)<{ disabled: boolean }>`
  ${FilterTextContainerShared}
  width: 70.16px;
  height: 32px;
  left: 317px;
  top: 0px;

  background: ${props => props.theme.colors.white};

  border: 1px solid ${props => props.theme.colors.warmGrey};
  color: ${props => props.theme.colors.textPrimary};

  /* Inside Auto Layout */
`;

export const FilterTextContainerSearchBar = styled(Flex)<{
  disabled: boolean;
  variant: string;
}>`
  ${FilterTextContainerShared}
  .ant-dropdown-link {
    //margin-right: 11px;
    color: inherit;
    font-size: ${props => props.theme.fontSizes.small};
    font-weight: 400;
  }
  background-color: ${props =>
    props.variant === "outlined"
      ? props.theme.colors.white
      : props.theme.colors.highlight};
  color: ${props =>
    props.variant === "outlined"
      ? props.theme.colors.lightBlue
      : props.theme.colors.black};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: 400;
  border: 1px solid
    ${props =>
      props.variant === "outlined"
        ? props.theme.colors.lightBlue
        : props.theme.colors.greyish};
  max-width: 120px;
  overflow: hidden;
  margin-left: 4px;
  :hover {
    box-shadow: 0px 0px 0px 2px
      ${props => props.theme.colors.highlightBoxShadow};
    background-color: ${props => props.theme.colors.highlight};
    color: ${props => props.theme.colors.black};
  }
`;

export const SubMenuTitle = styled.span`
  font-size: ${props => props.theme.fontSizes.ySmall};
  line-height: 15px;

  /* Black */

  color: ${props => props.theme.colors.textPrimary};
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const ClearAllText = styled(StyledSubMenu)`
  div > svg {
    display: none;
  }
`;
/* filterSelect styles end */

/* elmTableSearchBar  start */
export const StyledFilterTextSearchBar = styled.span`
  ${StyledFilterTextShared}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-flex: unset;
  min-width: 0;

  align-items: center;
  text-align: right;

  /* Inside Auto Layout */
  order: 0;
  align-self: center;
`;

export const StyledDropSearchBar = styled(Dropdown)`
  ${styledDropShared}

  max-width: 120px;
`;

export const SelectionContainer = styled(Flex)`
  ::-webkit-scrollbar {
    height: 0px !important;
    width: 0px !important;
  }
`;
export const FilterSectionContainer = styled(Flex)`
  align-items: center;
  height: 32px;
  // min-height: 30px;
  // max-height: 32px;
  flex: 1;
`;

export const FilterSelectionOptionBox = styled(Flex)`
  display: flex;
  flex-direction: row;
  padding: 5px 10px;

  position: static;
  width: max-content;
  height: 30px;
  left: 0px;
  top: 0px;

  /* white */

  background: ${props => props.theme.colors.white};
  /* warmGrey */

  border: 1px solid ${props => props.theme.colors.warmGrey};
  box-sizing: border-box;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
  align-items: center;
  margin: 10px 0px;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;
export const FilterSelectionCloseIcon = styled(getIcon("FaTimes"))`
  width: 9.2px;
  height: 9.2px;
  object-fit: contain;
`;
export const FilterSelectionOptionText = styled.text`
  position: static;
  height: 20px;
  left: 10px;
  right: 10px;
  top: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 0.32px;

  /* Black */

  color: ${props => props.theme.colors.textPrimary};

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
  margin: 10px 0px;
  margin-right: 7px;
`;

export const TotalContainer = styled(Flex)`
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  padding: 4px;
`;

export const TotalText = styled.p`
  ${props => props.theme.fontType.paragraph}
`;
export const TotalLabel = styled.p`
  ${props => props.theme.fontType.buttonText}
  margin-right: 2px;
`;

export const ClearAllContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.whiteTwo};
  display: flex;
  align-items: flex-start;
  padding-left: 7.26px;
  padding-top: 9px;
  padding-bottom: 9px;
  width: 92%;
  background-color: ${props => props.theme.colors.filterBackground};
  align-self: flex-start;
  margin: 0px 0px;
  span {
    font-size: 13px;
    line-height: 14.52px;
    color: ${props => props.theme.colors.textPrimary};
  }
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.highlight};
  }
`;

export const StyledFilterNumber = styled.text`
  margin-right: 9px;
  margin-left: 2px;
`;
/* elmTableSearchBar  end */

/* tableRow start*/
export const StyledRow = styled.tr<{
  isSelected?: boolean;
  isHovered?: boolean;
  noNav?: boolean;
}>`
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: ${props =>
    props.isSelected
      ? props.theme.colors.highlight
      : props.isHovered
      ? props.theme.colors.hover
      : "transparent"};
  color: ${props => props.theme.colors.textPrimary};

  :hover {
    cursor: ${props => (props.noNav ? "normal" : "pointer")};
    margin-bottom: 4px;
    margin-top: 4px;
  }
`;
/* tableRow end*/

/* helpers  */
export const SearchContainer = styled.div`
  background: ${props => props.theme.colors.filterBackground};
  border-bottom: 1px solid ${props => props.theme.colors.whiteTwo};
  box-sizing: border-box;
  display: flex;
  //flex: 1;
  align-items: center;
  padding-left: 12px;
  height: 100%;
  & > svg {
    width: 16px;
    height: 16px;
  }
`;
export const SearchInput = styled.input`
  border: none;
  margin-left: 11px;
  margin-right: 2px;
  font-size: ${props => props.theme.fontSizes.ySmall};
  line-height: 13px;

  display: flex;
  flex: 1;

  :focus {
    outline-color: transparent;
  }
  ::placeholder {
    color: ${props => props.theme.colors.greyish};
    opacity: 1;
  }
`;
export const SelectionRowBase = styled.div`
  max-width: 100%;
  padding-left: 7px;
  height: 34px;
  min-height: 34px;
  max-height: 34px;
  align-items: flex-end;
  display: flex;
  flex: 1;
  align-items: center;

  & > span {
    font-size: ${props => props.theme.fontSizes.ySmall};
    line-height: 15px;

    color: ${props => props.theme.colors.textPrimary};
    margin-left: 6px;
    max-width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const SelectionRow = styled(SelectionRowBase)`
  :hover {
    background-color: ${props => props.theme.colors.highlight};
    cursor: pointer;
  }
`;

export const TimeStyled = styled.span(props => ({
  fontSize: props.theme.fontSizes.xxSmall,
  color: props.theme.colors.warmGrey,
  lineHeight: "20px",
  fontWeight: 400
}));
export const DateStyled = styled.span(props => ({
  fontSize: props.theme.fontSizes.ySmall,
  color: props.theme.colors.black,
  lineHeight: props.theme.fontSizes.ySmall,
  fontWeight: 400,
  marginRight: "4px"
}));

/* helpers end */
