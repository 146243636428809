/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseChangeLogTablePaginationQueryVariables = {
  count: number;
  cursor?: string | null | undefined;
  blade_scope?: string | null | undefined;
  filterColumn?: Array<string> | null | undefined;
  filterValues?: Array<Array<string>> | null | undefined;
  filterOp?: Array<Array<string>> | null | undefined;
  search?: string | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
};
export type licenseChangeLogTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<
    "licenseChangeLogTable_licenseActions"
  >;
};
export type licenseChangeLogTablePaginationQuery = {
  readonly response: licenseChangeLogTablePaginationQueryResponse;
  readonly variables: licenseChangeLogTablePaginationQueryVariables;
};

/*
query licenseChangeLogTablePaginationQuery(
  $count: Int!
  $cursor: String
  $blade_scope: String
  $filterColumn: [String!]
  $filterValues: [[String!]!]
  $filterOp: [[String!]!]
  $search: String
  $sortColumn: [String!]
  $sortDirection: [String!]
) {
  ...licenseChangeLogTable_licenseActions_1Evf64
}

fragment licenseChangeLogTable_licenseActions_1Evf64 on Query {
  licenseActions(first: $count, after: $cursor, bladeScope: $blade_scope, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues, search: $search, sortColumn: $sortColumn, sortDirection: $sortDirection) {
    edges {
      node {
        description
        createdAt
        vendorSystemUser {
          name
          email
          id
        }
        id
        requireApproval
        status
        parameters
        comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "blade_scope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterOp"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    } as any,
    v6 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    } as any,
    v7 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v8 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v9 = {
      kind: "Variable",
      name: "filterColumn",
      variableName: "filterColumn"
    } as any,
    v10 = {
      kind: "Variable",
      name: "filterOp",
      variableName: "filterOp"
    } as any,
    v11 = {
      kind: "Variable",
      name: "filterValues",
      variableName: "filterValues"
    } as any,
    v12 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v13 = {
      kind: "Variable",
      name: "sortColumn",
      variableName: "sortColumn"
    } as any,
    v14 = {
      kind: "Variable",
      name: "sortDirection",
      variableName: "sortDirection"
    } as any,
    v15 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "blade_scope"
      } as any,
      v9 /*: any*/,
      v10 /*: any*/,
      v11 /*: any*/,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      v12 /*: any*/,
      v13 /*: any*/,
      v14 /*: any*/
    ],
    v16 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "licenseChangeLogTablePaginationQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "blade_scope",
              variableName: "blade_scope"
            },
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v9 /*: any*/,
            v10 /*: any*/,
            v11 /*: any*/,
            v12 /*: any*/,
            v13 /*: any*/,
            v14 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "licenseChangeLogTable_licenseActions"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v1 /*: any*/,
        v2 /*: any*/,
        v0 /*: any*/,
        v3 /*: any*/,
        v5 /*: any*/,
        v4 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/
      ],
      kind: "Operation",
      name: "licenseChangeLogTablePaginationQuery",
      selections: [
        {
          alias: null,
          args: v15 /*: any*/,
          concreteType: "LicenseActionConnection",
          kind: "LinkedField",
          name: "licenseActions",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "LicenseActionEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "LicenseAction",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "description",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "createdAt",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "VendorSystemUser",
                      kind: "LinkedField",
                      name: "vendorSystemUser",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "name",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "email",
                          storageKey: null
                        },
                        v16 /*: any*/
                      ],
                      storageKey: null
                    },
                    v16 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "requireApproval",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "status",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "parameters",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "comment",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v15 /*: any*/,
          filters: [
            "bladeScope",
            "filterColumn",
            "filterOp",
            "filterValues",
            "search",
            "sortColumn",
            "sortDirection"
          ],
          handle: "connection",
          key: "licenseChangeLogTable_licenseActions",
          kind: "LinkedHandle",
          name: "licenseActions"
        }
      ]
    },
    params: {
      cacheID: "14ac3ab5f86565ebe0796b9d672388c5",
      id: null,
      metadata: {},
      name: "licenseChangeLogTablePaginationQuery",
      operationKind: "query",
      text:
        "query licenseChangeLogTablePaginationQuery(\n  $count: Int!\n  $cursor: String\n  $blade_scope: String\n  $filterColumn: [String!]\n  $filterValues: [[String!]!]\n  $filterOp: [[String!]!]\n  $search: String\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n) {\n  ...licenseChangeLogTable_licenseActions_1Evf64\n}\n\nfragment licenseChangeLogTable_licenseActions_1Evf64 on Query {\n  licenseActions(first: $count, after: $cursor, bladeScope: $blade_scope, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues, search: $search, sortColumn: $sortColumn, sortDirection: $sortDirection) {\n    edges {\n      node {\n        description\n        createdAt\n        vendorSystemUser {\n          name\n          email\n          id\n        }\n        id\n        requireApproval\n        status\n        parameters\n        comment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "2a1ae0d94cb41383ca7398488ee783b7";
export default node;
