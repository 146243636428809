/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type selectReleaseQueryVariables = {
  id: string;
};
export type selectReleaseQueryResponse = {
  readonly releaseChannel: {
    readonly name: string | null;
    readonly currentRelease: {
      readonly id: string;
      readonly tag: string;
    } | null;
    readonly releaseConfiguration: {
      readonly releases: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly tag: string;
        } | null> | null;
      } | null;
      readonly product: {
        readonly iconUrl: string | null;
      } | null;
    } | null;
  } | null;
};
export type selectReleaseQuery = {
  readonly response: selectReleaseQueryResponse;
  readonly variables: selectReleaseQueryVariables;
};

/*
query selectReleaseQuery(
  $id: ID!
) {
  releaseChannel(id: $id) {
    name
    currentRelease {
      id
      tag
    }
    releaseConfiguration {
      releases {
        nodes {
          id
          tag
        }
      }
      product {
        iconUrl
        id
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v4 = [
      v3 /*: any*/,
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "tag",
        storageKey: null
      } as any
    ],
    v5 = {
      alias: null,
      args: null,
      concreteType: "Release",
      kind: "LinkedField",
      name: "currentRelease",
      plural: false,
      selections: v4 /*: any*/,
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      concreteType: "ReleaseConnection",
      kind: "LinkedField",
      name: "releases",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Release",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: v4 /*: any*/,
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "iconUrl",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "selectReleaseQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ReleaseChannel",
          kind: "LinkedField",
          name: "releaseChannel",
          plural: false,
          selections: [
            v2 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfiguration",
              kind: "LinkedField",
              name: "releaseConfiguration",
              plural: false,
              selections: [
                v6 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: [v7 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "selectReleaseQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ReleaseChannel",
          kind: "LinkedField",
          name: "releaseChannel",
          plural: false,
          selections: [
            v2 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfiguration",
              kind: "LinkedField",
              name: "releaseConfiguration",
              plural: false,
              selections: [
                v6 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: [v7 /*: any*/, v3 /*: any*/],
                  storageKey: null
                },
                v3 /*: any*/
              ],
              storageKey: null
            },
            v3 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "9a2a5de61ba4854b29d8fe83d1357a0b",
      id: null,
      metadata: {},
      name: "selectReleaseQuery",
      operationKind: "query",
      text:
        "query selectReleaseQuery(\n  $id: ID!\n) {\n  releaseChannel(id: $id) {\n    name\n    currentRelease {\n      id\n      tag\n    }\n    releaseConfiguration {\n      releases {\n        nodes {\n          id\n          tag\n        }\n      }\n      product {\n        iconUrl\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "6fbc5f037aaed4810203439579ee73e0";
export default node;
