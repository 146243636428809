import { gatewayMap } from "./gatewayMap";
import {
  getRequest,
  getSessionStorageKey,
  getStorage,
  postRequest,
  setGatewayEndpoint,
  deleteRequest
  // setLogOutHandler,
} from "./helpers";
import { IGateway, IGatewayClientOpts } from "./types";

export {
  apiLogoutHandler,
  getRequest,
  getSessionStatusFlag,
  getSessionToken,
  postRequest,
  setGatewayEndpoint
} from "./helpers";
export * from "./types";

export class GateWayClient<T> {
  public request = {} as typeof gatewayMap;
  // private gateway = {} as IGateway<typeof gatewayMap>;
  constructor(opts?: IGatewayClientOpts<T>) {
    if (opts) {
      this.initializeGateway(
        opts.additionalEndPoints,
        opts.serviceEndPoint
        // opts.onLogOut
      );
    } else {
      this.resetGateWay(null);
    }
  }
  public prepareGenericRequest = (
    endpoint: string,
    type: "get" | "post" | "delete"
  ) => {
    let request;
    switch (type) {
      case "get":
        request = getRequest(endpoint);
        break;
      case "post":
        request = postRequest(endpoint);
        break;
      case "delete":
        request = deleteRequest(endpoint);
        break;
    }
    return request;
  };
  public setGatewayEndpoint = (endpoint: string) => {
    setGatewayEndpoint(endpoint);
  };
  public getStorageKey = () => {
    return getSessionStorageKey();
  };
  public getStorage = () => {
    return getStorage();
  };
  public initializeGateway = (
    additionalEndPoints: IGateway<T>,
    serviceEndPoint: string
    // onLogOut: () => void
  ) => {
    setGatewayEndpoint(serviceEndPoint);
    // setLogOutHandler(onLogOut);
    this.resetGateWay(additionalEndPoints);
    return this.request;
  };
  private resetGateWay = (map: IGateway<T> = {} as IGateway<T>) => {
    const newGateWayMap = this.getGatewayMap();
    this.request = newGateWayMap;
  };
  private getGatewayMap = () => {
    return gatewayMap;
  };
}

const appGateway = new GateWayClient<typeof gatewayMap>();
export const getGateway = () => appGateway;
export default GateWayClient;
