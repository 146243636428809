/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type producReleaseConfigurationsTable_releaseConfigurations = {
  readonly releaseConfigurations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly releasesCount: number;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $refType": "producReleaseConfigurationsTable_releaseConfigurations";
};
export type producReleaseConfigurationsTable_releaseConfigurations$data = producReleaseConfigurationsTable_releaseConfigurations;
export type producReleaseConfigurationsTable_releaseConfigurations$key = {
  readonly " $data"?:
    | producReleaseConfigurationsTable_releaseConfigurations$data
    | undefined;
  readonly " $fragmentRefs": FragmentRefs<
    "producReleaseConfigurationsTable_releaseConfigurations"
  >;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: "",
      kind: "LocalArgument",
      name: "bladeScope"
    },
    {
      defaultValue: 6,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: "cursor",
        direction: "forward",
        path: ["releaseConfigurations"]
      }
    ]
  },
  name: "producReleaseConfigurationsTable_releaseConfigurations",
  selections: [
    {
      alias: "releaseConfigurations",
      args: [
        {
          kind: "Variable",
          name: "bladeScope",
          variableName: "bladeScope"
        },
        {
          kind: "Variable",
          name: "search",
          variableName: "search"
        }
      ],
      concreteType: "ReleaseConfigurationConnection",
      kind: "LinkedField",
      name:
        "__producReleaseConfigurationsTable_releaseConfigurations_connection",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ReleaseConfigurationEdge",
          kind: "LinkedField",
          name: "edges",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfiguration",
              kind: "LinkedField",
              name: "node",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "id",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "name",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "releasesCount",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "__typename",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "cursor",
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: null,
          concreteType: "PageInfo",
          kind: "LinkedField",
          name: "pageInfo",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "hasNextPage",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "endCursor",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "d8a01f9f920e807d4cc6e388ab0f2720";
export default node;
