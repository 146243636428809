import _ from "lodash";
import React from "react";
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { Flex } from "reflexbox/styled-components";
import { getEnvironment } from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import { IBladeBaseProps } from "../../../components/bladeManager/types";
import {
  BladeTitle,
  BladeTemplate,
  ProductsTable,
  ElmButton
} from "../../../components";
import { productTableType } from "../../components/productsTable";
import {
  ProductsQuery,
  ProductsQueryResponse
} from "./__generated__/ProductsQuery.graphql";
import BladePrimaryActionsDropdown from "components/bladePrimaryActionsDropdown";
import { TourGuideId } from "blades/Welcome/utils/types";
import { IOnboardingState } from "store/types";
import { notification } from "antd";
import { REFRESH_KEY } from "components/bladeManager/entityRefreshMap";
export interface IProductsBladeProps extends IBladeBaseProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    onboardingMode: IOnboardingState;
  };
}
const graphqlQuery = graphql`
  query ProductsQuery {
    allProductsCount: productsCount
    allProducts: products {
      nodes {
        name
        licensesCount
      }
    }
  }
`;
export class ProductsBlade extends React.Component<
  IProductsBladeProps & { result: ProductsQueryResponse; retry: () => void }
> {
  componentDidMount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingPause();
  }
  componentDidUpdate() {
    if (this.checkOnboardingMode()) {
      const target = document.getElementById(TourGuideId.CreateProduct);
      if (this.props.appState.onboardingMode.index === 0 && target) {
        this.props.appDispatch.appActions.onboardingContinue();
      }
    }
  }
  public checkOnboardingMode = () => {
    return this.props.appState.onboardingMode?.status === "active";
  };
  public componentWillUnmount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingEnd();
  }
  public navToProductBlade = (
    product: Pick<productTableType, "id" | "name">
  ) => {
    this.props.openBlade({
      route: "Product",
      routeName: product?.name,
      fromBladeIndex: this.props.index,
      routeData: {
        id: product.id.toString(),
        name: product?.name
      }
    });
  };

  public renderProductsTable = () => {
    return (
      <div
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
          display: "flex",
          flex: 1
        }}
      >
        <ProductsTable
          setRefreshFn={this.props.setEntityRefreshFn(
            REFRESH_KEY.ProductsTable
          )}
          refreshKey={REFRESH_KEY.ProductsTable}
          removeRefreshFn={this.props.removeRefreshFn}
          onRowClick={this.navToProductBlade}
          bladeName={"Products"}
        />
      </div>
    );
  };

  public renderLeftSideHeader = () => {
    return (
      <div style={{ marginLeft: "18px" }}>
        <BladeTitle>Products</BladeTitle>
      </div>
    );
  };
  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: `Products - Analytics`,
      fromBladeIndex: this.props.index,
      routeData: {
        type: "Products",
        title: "Products"
      }
    });
  };
  public renderRightSideHeader = () => {
    const openNewProductBlade = () => {
      this.props.openDialog("EditProductDialog", {
        editMode: false,
        onConfirm: payload => {
          this.props.closeDialog("EditProductDialog");
          notification.open({
            type: "success",
            message: "Success",
            description: `Product created !`
          });
          if (this.checkOnboardingMode()) {
            this.props.appDispatch.appActions.onboardingCompleted({
              productId: _.get(payload, "id")
            });
            this.props.closeBlade({
              route: "Products"
            });
            this.props.appDispatch.bladeManagerActions.openDialog({
              name: "ProductCreatedDialog",
              payload: {
                productId: _.get(payload, "guid"),
                publicKey: _.get(payload, "public_key")
              }
            });
          }
          this.props.refreshAllOpenBlades();
          this.navToProductBlade({
            id:
              typeof payload?.id === "number"
                ? btoa(`Product-${payload.id}`)
                : payload.id,
            name: payload.name
          });
        }
      });
    };
    return (
      <Flex>
        <ElmButton
          id={TourGuideId.CreateProduct}
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon={"plus"}
          iconPrefix={"fas"}
          label={"Product"}
          permissions={"modify_assets"}
          onClick={openNewProductBlade}
        />
        <BladePrimaryActionsDropdown
          analyticsAction={this.navigateToAnalytics}
          export={"products"}
        />
      </Flex>
    );
  };
  public getLicensesCount = () => {
    let count = 0;
    _.each(
      this.props?.result?.allProducts?.nodes,
      node => (count += node.licensesCount)
    );
    return count;
  };
  public render() {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Products"}
        bladeType={"Products"}
        leftAccentColor={"productsBlue"}
        topAccentColor={"productsBlue"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        // loading={!this.props.result}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        closeBlade={props => {
          this.checkOnboardingMode() &&
            this.props.appDispatch.appActions.onboardingEnd();
          this.props.closeBlade(props);
        }}
        hideBladeTypeName={true}
      >
        <div
          className={`metrics-container ${
            !this.props.result ? "loading" : null
          }`}
        >
          {this.props.result ? (
            <>
              <p className="subheading-value">
                {this.props.result.allProducts?.nodes?.length || 0}
                <span className="subheading-label">Products</span>
              </p>
              <p className="subheading-value">
                <span className="bordered" />
                {this.getLicensesCount()}
                <span className="subheading-label">Licenses</span>
              </p>
            </>
          ) : (
            <p className="subheading-value">
              <span className="subheading-label">Loading stats...</span>
            </p>
          )}
        </div>
        {this.renderProductsTable()}
      </BladeTemplate>
    );
  }
}
const relayEnvironment = getEnvironment();
const RenderQuery = (props: IProductsBladeProps) => {
  const renderProductsInfo = (payload: {
    error: Error;
    props: ProductsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <ProductsBlade {...props} result={payload.props} retry={payload.retry} />
    );
  };
  return (
    <QueryRenderer<ProductsQuery>
      environment={relayEnvironment}
      variables={{}}
      query={graphqlQuery}
      render={renderProductsInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {
    onboardingMode: "onboardingModeSelector"
  }
});
