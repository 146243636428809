import { graphql } from "babel-plugin-relay/macro";

export const licensesTableExportQuery = graphql`
  query licensesTableExportQuery(
    $bladeScope: String
    $sortColumn: [String!]
    $sortDirection: [String!]
    $filterColumn: [String!]
    $filterOp: [[String!]!]
    $filterValues: [[String!]!]
  ) {
    licenses(
      bladeScope: $bladeScope
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filterColumn: $filterColumn
      filterOp: $filterOp
      filterValues: $filterValues
    ) {
      nodes {
        id
        type {
          id
        }
        isVendor
        owner {
          name
          type
        }
        product {
          id
          name
          iconUrl
        }
        isTrial
        key
        latestTerm {
          endDate
          startDate
        }
      }
    }
  }
`;
