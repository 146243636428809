import _ from "lodash";
import moment from "moment";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import {
  AutoSizer,
  Column,
  InfiniteLoader,
  InfiniteLoaderChildProps,
  TableCellRenderer,
  TableCellDataGetter,
  TableHeaderProps,
  TableHeaderRowRenderer,
  TableRowRenderer
} from "react-virtualized";
import "react-virtualized/styles.css";
import { ElmButton } from "../elmButton";
import { TableRow } from "./tableRow";
import ElmTableSearchBar from "./elmTableSearchBar";
import {
  IColumn,
  IElmTableProps,
  IElmTableState,
  IFilterSearchProps
} from "./types";
import { getSavedDataForTable } from "./utils";
import clsx from "clsx";
import {
  ArrowDown,
  ArrowUp,
  StyledTable,
  TableCell,
  TableFooter,
  TableHeader,
  TableHeadSection
} from "./styles";
import styles from "./elmTableStyle.module.scss";
import { Tooltip } from "antd";
import { getTextWidth } from "../../utils";
import theme from "../../theme";
import { getDateTimeFormatted } from "./helpers";
import { TourGuideId } from "blades/Welcome/utils/types";
import styled from "styled-components";
import { getFaIcon } from "components/icons";

const TableFooterHeight = 48;
const SearchBarHeight = 32;

const InfoIcon = styled(getFaIcon({ iconName: "info", prefix: "far" }))`
  padding: 2px;
  border-radius: 6px;
  background: ${props => props.theme.colors.mango};
  color: #ffffff;
  width: 8px;
  height: 8px;
`;

export class ElmTable extends React.Component<IElmTableProps, IElmTableState> {
  state: IElmTableState = {
    search_term: null,
    currentCount: 0,
    currentClicked: null,
    hoveredRowIndex: null,
    sortBy: this.props.defaultSortBy,
    sortDirection: this.props.defaultSortDirection || "ASC",
    filterValues: null
  };

  public componentDidMount() {
    const lastClicked = getSavedDataForTable({
      type: "selectedRow",
      bladeName: this.props.bladeName,
      tableName: this.props.tableName
    });
    if (lastClicked && !_.isFinite(this.state.currentClicked)) {
      this.setState({ currentClicked: parseInt(lastClicked, 10) });
    }
  }
  public handleSort = (info: { sortBy: string; sortDirection: string }) => {
    const sortToUse = this.state.sortDirection || info.sortDirection;
    const sortDirection = sortToUse === "ASC" ? "DESC" : "ASC";
    const column = _.find(this.props.columns, c => c.accessor == info.sortBy);
    this.setState(
      {
        sortBy: column?.sortKey || info.sortBy,
        sortDirection
      },
      this.reportChanges
    );
  };
  // public sortState = this.createMultiSort(this.handleSort);
  public renderColumnHeader = (column: IColumn) => (
    payload: TableHeaderProps
  ) => {
    const showSortIndicator = this.state.sortBy === column.sortKey;
    let Icon;
    if (showSortIndicator) {
      Icon = payload.sortDirection === "DESC" ? <ArrowDown /> : <ArrowUp />;
    }
    return (
      <Flex
        style={{ alignItems: "center" }}
        className={clsx({ number: column.isNumber })}
      >
        {column.isNumber ? (
          <div
            style={{ minWidth: "16px", minHeight: "16px" }}
            className={"elm-table-sort-icon"}
          >
            {Icon}
          </div>
        ) : null}
        <TableHeader className={clsx({ active: showSortIndicator })}>
          {payload.label}
        </TableHeader>
        {column.HeaderInfoMessage ? (
          <Flex marginLeft={"6px"} marginRight={"6px"}>
            <Tooltip title={column.HeaderInfoMessage}>
              <InfoIcon />
            </Tooltip>
          </Flex>
        ) : null}
        {column.isNumber ? null : (
          <div
            style={{ minWidth: "16px", minHeight: "16px" }}
            className={"elm-table-sort-icon"}
          >
            {Icon}
          </div>
        )}
      </Flex>
    );
  };

  public defaultCellRenderer = (
    column: IColumn,
    colWidth?: number
  ): TableCellRenderer => payload => {
    const cellValue = _.get(payload.rowData, column.accessor);
    const className = clsx({
      number: column.isNumber,
      zero: column.showZeroAsInactive && cellValue === 0
    });
    if (_.isFunction(this.props.renderToggleColumn)) {
      if (
        column.Header === "Enable" ||
        column.Header === "Include" ||
        column.Header === "Renew"
      ) {
        return this.props.renderToggleColumn(payload.rowData);
      }
    }
    if (_.isFunction(column.cellRenderer)) {
      if (
        column.Header === "Actions" ||
        column.Header === "Action" ||
        column.accessor === "action"
      ) {
        if (this.props.editableRow) {
          return (
            <div style={{ padding: "2px" }}>
              <ElmButton
                className="darkModeTransparentBtn"
                //style={{ color: theme.colors.black }}
                style={{
                  color: theme.colors.iconColor
                }}
                colorVariance="subtle"
                onClick={() =>
                  this.props.onEditClick(payload.rowData, payload.rowIndex)
                }
                permissions="modify_assets"
                label=""
                variance="icon-button"
                icon="pen"
                iconPrefix="fas"
              />
            </div>
          );
        }
        if (_.isFunction(this.props.renderRowActionButtons)) {
          return this.props.renderRowActionButtons(payload.rowData);
        }
      }
      return <TableCell>{column.cellRenderer(payload)}</TableCell>;
    }

    const renderCellVal = (val: any) => {
      if (_.isString(val) || _.isFinite(val)) {
        if (_.isString(val)) {
          if (column.isDateFormat && moment(val).isValid()) {
            if (column.dateFormat) return moment(val).format(column.dateFormat);
            if (!column.dateFormat) return getDateTimeFormatted(val);
          }
        }
        return val;
      }
    };
    if (_.isArray(cellValue)) {
      const isLast = (ii: number) => ii === cellValue.length - 1;
      return _.map(cellValue, (cell, i) => (
        <TableCell
          style={{ marginRight: isLast(i) ? "0px" : "3px" }}
          className={className}
        >{`${renderCellVal(cell)}${!isLast(i) ? ", " : ""}`}</TableCell>
      ));
    }
    if (_.isString(cellValue) || _.isFinite(cellValue)) {
      const valToRender = renderCellVal(cellValue);
      const textWidth = getTextWidth(valToRender) as number;
      const showTooltip = textWidth > colWidth - 22 ? true : false; //22 is the total margin right/left specified in .ReactVirtualized__Table__rowColumn
      if (showTooltip) {
        return (
          <TableCell className={className}>
            <Tooltip placement="top" title={valToRender}>
              {valToRender}
            </Tooltip>
          </TableCell>
        );
      }
      return <TableCell className={className}>{valToRender}</TableCell>;
    }
    return <TableCell></TableCell>;
  };
  public defaultCellDataGetter: TableCellDataGetter = payload => {
    return _.get(payload.rowData, payload.dataKey);
  };

  public defaultRowRenderer: TableRowRenderer = payload => {
    const handleClick = (e: React.MouseEvent<any>) => {
      if (_.isFunction(this.props.onRowClick)) {
        this.setState(
          {
            currentClicked: payload.index
          },
          () => {
            // saveDataForTable(
            //   {
            //     type: "selectedRow",
            //     bladeName: this.props.bladeName,
            //     tableName: this.props.tableName
            //   },
            //   payload.index.toString()
            // );
            payload.onRowClick({
              index: payload.index,
              // @ts-ignore
              event: e,
              rowData: payload.rowData
            });
          }
        );
      }
    };

    const handleOnEditClick = () => {
      if (_.isFunction(this.props.onEditClick)) {
        this.props.onEditClick(payload.rowData, payload.index);
      }
    };
    if (_.isFunction(this.props.rowRenderer)) {
      return this.props.rowRenderer(payload);
    }
    return (
      <TableRow
        {...payload}
        className={clsx({
          [payload.className]: !!payload.className,
          [this.props.className]: !!this.props.className,
          inactive: () => {
            if (_.isFunction(this.props.isRowInactive)) {
              return this.props.isRowInactive(payload.rowData);
            }
            return false;
          }
        })}
        noNav={this.props.noNav}
        isSelected={payload.index === this.state.currentClicked}
        handleClick={handleClick}
        handleEditClick={handleOnEditClick}
        editableRow={this.props.editableRow}
        renderActionButtons={this.props.renderRowActionButtons}
      />
    );
  };
  public defaultHeaderRowRenderer: TableHeaderRowRenderer = payload => {
    return (
      <TableHeadSection
        style={payload.style}
        className={clsx({
          [payload.className]: !!payload.className,
          "elm-table-header": true
        })}
      >
        {payload.columns}
      </TableHeadSection>
    );
  };
  public getTableColumns = () => {
    let columns = this.props.columns;
    if (_.isArray(this.props.hideColumnsWithHeaders)) {
      columns = _.filter(
        this.props.columns,
        column => !_.includes(this.props.hideColumnsWithHeaders, column.Header)
      );
    }

    columns = _.filter(columns, column => {
      if (_.has(column, "show")) {
        return column.show;
      }
      return true;
    });
    return columns;
  };
  public renderColumns = (payload: { height: number; width: number }) => {
    const tableColumns = this.getTableColumns();
    const columnsWithWidths =
      _.filter(tableColumns, column => _.isFinite(column.width)) || [];
    const startWidth = payload.width;
    const generalWidthLeft = _.reduce(
      columnsWithWidths,
      (widthLeft, col) => {
        if (col.width <= 1) {
          widthLeft = (1 - col.width) * widthLeft;
        }
        return widthLeft;
      },
      startWidth
    );
    const getWidthForColumn = (col: IColumn) => {
      /* 
      *************************************************************************************
      Important: if table have hideColumnsWithHeaders property specified then fixed width in 
      tableGenerator column definition shouldn't be used on all columns, otherwise it will
      have wrong calculation for column widths
      *************************************************************************************
      */
      if (_.isFinite(col.width)) {
        if (col.width <= 1) {
          return col.width * payload.width;
        }
      }
      if (_.isArray(columnsWithWidths)) {
        return (
          generalWidthLeft / (tableColumns.length - columnsWithWidths.length)
        );
      }
      return generalWidthLeft / tableColumns.length;
    };
    return _.map(tableColumns, column => {
      const colWidth = getWidthForColumn(column);
      return (
        <Column
          width={colWidth}
          label={column.Header}
          dataKey={column.accessor}
          headerRenderer={this.renderColumnHeader(column)}
          key={column.Header}
          disableSort={column.disableSortBy}
          cellRenderer={this.defaultCellRenderer(column, colWidth)}
          cellDataGetter={column.cellDataGetter || this.defaultCellDataGetter}
          className={clsx({
            "elm-table-column": true,
            number: column.isNumber
          })}
          headerClassName={clsx({
            "elm-header-column": true,
            number: column.isNumber
          })}
        />
      );
    });
  };
  public handleRowClick = (payload: {
    event: React.MouseEvent<any>;
    index: number;
    rowData: any;
  }) => {
    if (_.isFunction(this.props.onRowClick)) {
      this.props.onRowClick(payload.rowData);
    }
  };
  public getTableRow = (payload: { index: number }) => {
    return this.props.data[payload.index];
  };
  public handleMoreRows = (payload: {
    startIndex: number;
    stopIndex: number;
  }) => {
    if (_.isFunction(this.props.loadMoreItems)) {
      return this.props.loadMoreItems(payload);
    }
    return new Promise<void>((res, rej) => res());
  };
  public renderInfiniteLoaderChild = (getChildProps: {
    height: number;
    width: number;
  }) => (payload: InfiniteLoaderChildProps) => {
    return (
      <StyledTable
        sort={this.handleSort}
        sortBy={this.state.sortBy}
        sortDirection={this.state.sortDirection}
        rowGetter={this.getTableRow}
        rowHeight={this.props.rowHeight || 42}
        rowCount={this.props.data ? this.props.data.length : 0}
        rowRenderer={this.defaultRowRenderer}
        headerHeight={this.props.rowHeight || 30}
        headerRowRenderer={this.defaultHeaderRowRenderer}
        width={getChildProps.width}
        height={getChildProps.height}
        onRowClick={this.handleRowClick}
        onRowsRendered={payload.onRowsRendered}
        overscanRowCount={10}
        ref={payload.registerChild}
        columnAndHeaderStyles={this.props.columnAndHeaderStyles}
      >
        {this.renderColumns({
          height: getChildProps.height,
          width: getChildProps.width
        })}
      </StyledTable>
    );
  };

  public itemCount = () => {
    if (!this.props.data) {
      return 0;
    }
    return this.props.hasNextPage
      ? this.props.data.length + 1
      : this.props.data.length;
  };
  public isItemLoaded = (payload: { index: number }) => {
    if (!this.props.data.length) {
      return false;
    }
    return !this.props.hasNextPage || payload.index < this.props.data.length;
  };
  public handleSearchChange = (search_term: string) => {
    this.setState({ search_term }, this.reportChanges);
  };
  public reportChanges = () => {
    if (_.isFunction(this.props.onCriteriaChange)) {
      this.props.onCriteriaChange({ ...this.state });
    }
  };
  public handleFilterChange: IFilterSearchProps["onFilterChange"] = payload => {
    this.setState({ ...payload }, this.reportChanges);
  };
  public renderDefaultAddButton = (label?: string) => {
    return (
      <Flex
        style={{ height: "35px", alignItems: "center", paddingRight: "2px" }}
      >
        <ElmButton
          variance="plain-icon-button"
          label={label || "Add"}
          id={TourGuideId.AddButton}
          icon={"plus"}
          iconPrefix="fas"
          permissions={this.props?.permissions || "modify_assets"}
          onClick={this.props.useDefaultAddButton}
        />
      </Flex>
    );
  };
  public renderFooter = () => {
    if (this.props.hideFooter) {
      return null;
    }
    const getCount = () => {
      if (_.isFinite(this.props.totalCount)) {
        return `${this.props.totalCount} items`;
      }
      return "";
    };
    return (
      <TableFooter>
        <span>{getCount()}</span>
      </TableFooter>
    );
  };
  public getTableHeight = (height: number) => {
    let tableHeight = height;
    if (!this.props.hideFooter) {
      tableHeight = tableHeight - TableFooterHeight;
    }
    if (
      _.some(this.props.columns, column => _.isObject(column.filterable)) &&
      _.keys(this.state.filterValues).length
    ) {
      tableHeight = tableHeight - 32;
    } else {
      tableHeight = tableHeight - 15;
    }
    return tableHeight - SearchBarHeight;
  };
  public render() {
    return (
      <div className={styles.virtualWrapper}>
        <AutoSizer defaultHeight={300}>
          {({ height, width }) => (
            <div
              className={"elm-table"}
              style={{
                width,
                height,
                display: "block",
                overflow: "hidden",
                position: "relative"
              }}
            >
              {_.isFunction(this.props.renderBeforeSearchBar)
                ? this.props.renderBeforeSearchBar()
                : null}
              {this.props.hideSearchBar ? null : (
                <Flex className={styles.searchBarWrapper}>
                  {_.isFunction(this.props.renderLeftOfSearchBar)
                    ? this.props.renderLeftOfSearchBar()
                    : null}
                  <ElmTableSearchBar
                    count={
                      _.isFinite(this.props.totalCount)
                        ? this.props.totalCount
                        : this.props.data
                        ? this.props.data.length
                        : 0
                    }
                    tableName={this.props.tableName}
                    tableColumns={this.props.columns}
                    filterData={this.props.filterData}
                    onChange={this.handleSearchChange}
                    onFilterChange={this.handleFilterChange}
                    bladeName={this.props.bladeName}
                  />
                  {_.isFunction(this.props.renderRightOfSearchBar)
                    ? this.props.renderRightOfSearchBar()
                    : _.isFunction(this.props.useDefaultAddButton)
                    ? this.renderDefaultAddButton()
                    : null}
                </Flex>
              )}
              {_.isFunction(this.props.renderBeforeTable)
                ? this.props.renderBeforeTable()
                : null}
              {_.get(this.props, "data", [])?.length ? (
                <InfiniteLoader
                  isRowLoaded={this.isItemLoaded}
                  rowCount={this.itemCount()}
                  loadMoreRows={this.handleMoreRows}
                >
                  {this.renderInfiniteLoaderChild({
                    height: this.getTableHeight(height),
                    width
                  })}
                </InfiniteLoader>
              ) : (
                <Flex flex={1} marginTop="10%">
                  {_.isFunction(this.props.renderEmptyTableAction)
                    ? this.props.renderEmptyTableAction()
                    : null}
                </Flex>
              )}

              {_.isFunction(this.props.renderAfterTable)
                ? this.props.renderAfterTable()
                : null}
            </div>
          )}
        </AutoSizer>
        {this.renderFooter()}
      </div>
    );
  }
}

export default ElmTable;
