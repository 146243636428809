import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import ElmInput from "../../../elmInput";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 290px;
`;

export const MessageDetails = styled.span(props => ({
  fontSize: props.theme.fontSizes.medium,
  color: props.theme.colors.black,
  fontWeight: 400
}));

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
export interface IDeleteDialogProps {
  name?: string;
  description?: string;
  warningMessage?: string;
  entityType: string;
  isSimpleConfirmation?: boolean;
}
type state = {
  name: string;
};
export class DeleteDialog extends DialogBase<
  IModalProps<IDeleteDialogProps>,
  state
> {
  state: state = {
    name: ""
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          height: "max-content",
          //width: "444px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "DeleteDialog"
    });
  };
  public onConfirm = () => {
    if (_.isFunction(this.props.payload.onConfirm)) {
      this.props.payload.onConfirm();
    }
  };
  public getTitle = () => {
    return `Delete ${_.get(this.props.payload, "entityType")}`;
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };
  public isVowel(character: string) {
    if (character && character.length > 1) {
      character = character[0];
    }
    return ["a", "e", "i", "o", "u"].indexOf(character.toLowerCase()) !== -1;
  }
  public getDescription = () => {
    if (
      _.get(this.props.payload, "warningMessage") &&
      _.isString(_.get(this.props.payload, "warningMessage"))
    ) {
      return _.get(this.props.payload, "warningMessage");
    }
    const entityType = _.get(this.props.payload, "entityType");
    return `Warning: Deleting ${
      this.isVowel(entityType ? entityType[0] : "c") ? "an" : "a"
    } ${entityType} will delete all of its associated resources. This action is permanent and irreversible`;
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        {/* <InfoMessage description={this.getDescription()} type="warning" /> */}
        <MessageDetails>{this.getDescription()}</MessageDetails>
        {!this.props?.payload?.isSimpleConfirmation && (
          <>
            <Flex marginTop="10px">{`Type the ${_.get(
              this.props.payload,
              "entityType"
            )} ${_.get(this.props.payload, "description") ||
              "name"} to confirm.`}</Flex>
            <p
              style={{
                fontStyle: "italic",
                margin: 10,
                marginInline: 0,
                fontSize: "14px"
              }}
            >{`${_.get(this.props.payload, "name")}`}</p>
            <Flex style={{ margin: 5, marginInline: 0 }}>
              <ElmInput
                updatedValue={this.updateName}
                // placeholder={this.props.payload.name}
              />
            </Flex>
          </>
        )}

        <Flex justifyContent={"flex-end"} marginTop="15px">
          <ElmButton
            label={"Cancel"}
            colorVariance="outline-secondary"
            onClick={this.closeModal}
          />
          <ElmButton
            label={"Confirm"}
            onClick={this.onConfirm}
            disabled={
              !this.props?.payload?.isSimpleConfirmation &&
              this.state.name !== _.get(this.props.payload, "name")
            }
          />
        </Flex>
      </DialogContainer>
    );
  }
}
