import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox";
import { ElmButton, ElmInput } from "./components";
import { renderErrorNotification } from "utils/ant-notifications";
import { RouteChildrenProps } from "react-router-dom";
import { InfoMessage } from "components/helpers";
import { getGateway } from "./api";
import { withRouter } from "react-router";
import elmLogoLight from "./logo.svg";
import elmLogoDark from "./logo-dark.svg";
import {
  LoginBox,
  LoginContainer,
  TagLine,
  ForgotPassword as ReturnToLogin,
  BulbContainer,
  BulbIcon,
  FormPadding
} from "login";
import { appConnect } from "store/appConnect";
import { ActiveMode } from "store/types";

interface IState {
  password: string;
  password_confirmation: string;
  error: Record<"password_confirmation" | "min_length" | "invalid", boolean>;
  isRequestSent: boolean;
}

interface IProps extends RouteChildrenProps {
  appState: {
    activeMode: ActiveMode;
  };
}

export class PasswordUpdateBlade extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      password: "",
      password_confirmation: "",
      isRequestSent: false,
      error: {
        password_confirmation: false,
        min_length: false,
        invalid: false
      }
    };
  }
  PASSWORD_MIN_LENGTH = 8;

  public requestNewPassword = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const gateway = getGateway();
    gateway.request
      .resetPassword({
        "access-token": searchParams.get("access-token"),
        client: searchParams.get("client"),
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
        uid: searchParams.get("uid")
      })
      .then(res => {
        if (res?.error) {
          renderErrorNotification("Failed to update password");
          this.setState({ error: { ...this.state.error, invalid: true } });
        }
        // @ts-ignore
        if (res?.data?.success) {
          this.setState({
            isRequestSent: true,
            error: {
              password_confirmation: false,
              min_length: false,
              invalid: false
            }
          });
        }
      })
      .catch(err => console.error(err));
  };
  public onRequestedPasswordReset = (e: React.FormEvent) => {
    e.preventDefault();
    if (this.state.password?.length < this.PASSWORD_MIN_LENGTH) {
      this.setState({
        error: { ...this.state.error, min_length: true }
      });
      return;
    }
    if (!_.isEqual(this.state.password, this.state.password_confirmation)) {
      this.setState({
        error: { ...this.state.error, password_confirmation: true }
      });
      return;
    }
    this.setState({
      error: {
        ...this.state.error,
        password_confirmation: false,
        min_length: false
      }
    });
    this.requestNewPassword();
  };

  public render() {
    return (
      <LoginContainer
        flex={1}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <LoginBox>
          <img
            src={
              this.props.appState.activeMode === "light"
                ? elmLogoLight
                : elmLogoDark
            }
            style={{ display: "flex", justifySelf: "self", height: "82px" }}
            alt="Elm logo"
          />
          <TagLine>a better way to license your software</TagLine>
          <form onSubmit={this.onRequestedPasswordReset} style={{ flex: 1 }}>
            <Flex
              flex={1}
              className="signInContainer"
              flexDirection={"column"}
              justifyContent={"space-between"}
              marginTop={FormPadding}
              paddingBottom={FormPadding}
              height={"100%"}
            >
              {this.state.isRequestSent ? (
                <InfoMessage
                  icon={
                    <BulbContainer>
                      <BulbIcon />
                    </BulbContainer>
                  }
                  showIcon
                  description="Your password has been successfully changed"
                  type="info"
                  style={{ marginBottom: 70 }}
                />
              ) : (
                <div>
                  <ElmInput
                    updatedValue={password =>
                      this.setState({
                        password,
                        error: {
                          ...this.state.error,
                          min_length: false,
                          password_confirmation: false
                        }
                      })
                    }
                    label={"New password"}
                    placeholder={"************"}
                    type={"password"}
                    error={this.state.error.min_length}
                    errorMessage={"Password must be at least 8 characters long"}
                    style={{ marginBottom: "12px" }}
                  />
                  <ElmInput
                    updatedValue={password_confirmation =>
                      this.setState({
                        password_confirmation,
                        error: {
                          ...this.state.error,
                          password_confirmation: false
                        }
                      })
                    }
                    label={"Confirm new password"}
                    placeholder={"************"}
                    type={"password"}
                    error={this.state.error.password_confirmation}
                    errorMessage={"Passwords do not match"}
                  />
                </div>
              )}

              <Flex alignItems="center" flexDirection="column">
                {this.state.isRequestSent ? null : (
                  <ElmButton
                    type={"submit"}
                    label={"Continue"}
                    style={{
                      minWidth: "100%",
                      maxHeight: "32px",
                      padding: "9px",
                      margin: "0px 0px 8px 0px"
                    }}
                  />
                )}

                <ReturnToLogin
                  onClick={() => this.props.history.push("/login")}
                >
                  Return to sign in
                </ReturnToLogin>
              </Flex>
            </Flex>
          </form>
        </LoginBox>
      </LoginContainer>
    );
  }
}

export default appConnect(withRouter(PasswordUpdateBlade), {
  selectors: {
    activeMode: "activeModeSelector"
  }
});
