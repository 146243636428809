/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type featuresTablePaginationQueryVariables = {
  count: number;
  cursor?: string | null | undefined;
  id: string;
  search?: string | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
};
export type featuresTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"featuresTable_features">;
};
export type featuresTablePaginationQuery = {
  readonly response: featuresTablePaginationQueryResponse;
  readonly variables: featuresTablePaginationQueryVariables;
};

/*
query featuresTablePaginationQuery(
  $count: Int!
  $cursor: String
  $id: ID!
  $search: String
  $sortColumn: [String!]
  $sortDirection: [String!]
) {
  ...featuresTable_features_4nReOr
}

fragment featuresTable_features_4nReOr on Query {
  product(id: $id) {
    features(first: $count, after: $cursor, search: $search, sortColumn: $sortColumn, sortDirection: $sortDirection) {
      edges {
        node {
          id
          name
          featureGroups {
            nodes {
              name
              id
            }
          }
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "count"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "sortColumn"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "sortDirection"
      } as any
    ],
    v1 = {
      kind: "Variable",
      name: "id",
      variableName: "id"
    } as any,
    v2 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v3 = {
      kind: "Variable",
      name: "sortColumn",
      variableName: "sortColumn"
    } as any,
    v4 = {
      kind: "Variable",
      name: "sortDirection",
      variableName: "sortDirection"
    } as any,
    v5 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      v2 /*: any*/,
      v3 /*: any*/,
      v4 /*: any*/
    ],
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "featuresTablePaginationQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v1 /*: any*/,
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "featuresTable_features"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "featuresTablePaginationQuery",
      selections: [
        {
          alias: null,
          args: [v1 /*: any*/],
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            {
              alias: null,
              args: v5 /*: any*/,
              concreteType: "FeatureConnection",
              kind: "LinkedField",
              name: "features",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "FeatureEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Feature",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v6 /*: any*/,
                        v7 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: "FeatureGroupConnection",
                          kind: "LinkedField",
                          name: "featureGroups",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "FeatureGroup",
                              kind: "LinkedField",
                              name: "nodes",
                              plural: true,
                              selections: [v7 /*: any*/, v6 /*: any*/],
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: v5 /*: any*/,
              filters: ["search", "sortColumn", "sortDirection"],
              handle: "connection",
              key: "featuresTable_features",
              kind: "LinkedHandle",
              name: "features"
            },
            v6 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "d4189b69eac18c772bbaa088ee7cb663",
      id: null,
      metadata: {},
      name: "featuresTablePaginationQuery",
      operationKind: "query",
      text:
        "query featuresTablePaginationQuery(\n  $count: Int!\n  $cursor: String\n  $id: ID!\n  $search: String\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n) {\n  ...featuresTable_features_4nReOr\n}\n\nfragment featuresTable_features_4nReOr on Query {\n  product(id: $id) {\n    features(first: $count, after: $cursor, search: $search, sortColumn: $sortColumn, sortDirection: $sortDirection) {\n      edges {\n        node {\n          id\n          name\n          featureGroups {\n            nodes {\n              name\n              id\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "26a55c26b93b06b3fc57fa3d5181b1b6";
export default node;
