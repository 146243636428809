import styled from "styled-components";
import _ from "lodash";
import React from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ElmCheckBox } from "../elmCheckBox";
import { getIcon } from "../icons";
import { Input } from "antd";
import {
  DateStyled,
  SearchContainer,
  SearchInput,
  SelectionRow,
  TimeStyled
} from "./styles";
import moment from "moment";
import { Flex } from "reflexbox";
import { DATE_FORMAT, TIME_FORMAT } from "const";

export const CategorySearchContainer = styled.div`
  height: 34px;
  background: ${props => props.theme.colors.white};
`;

const SearchIcon = getIcon("magnifying-glass", "fal");

export const FilterSearch = (props: {
  placeholder?: string;
  onChange: (value: string) => void;
  searchContainerStyle?: React.CSSProperties;
}) => {
  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (_.isFunction(props.onChange)) {
      props.onChange(e.target.value);
    }
  };
  return (
    <SearchContainer style={props.searchContainerStyle}>
      <SearchIcon />
      <SearchInput onChange={handleOnSearch} placeholder={props.placeholder} />
    </SearchContainer>
  );
};

export const FilterSelectionRow = (props: {
  label: string;
  onChange: (payload: { value: string; isChecked: boolean }) => void;
  isChecked: boolean;
}) => {
  const handleOnChange = (e: CheckboxChangeEvent) => {
    props.onChange({ value: props.label, isChecked: e.target.checked });
  };
  const handleRowClick = () => {
    props.onChange({ value: props.label, isChecked: !props.isChecked });
  };
  return (
    <SelectionRow onClick={handleRowClick}>
      <ElmCheckBox
        checked={props.isChecked}
        onChange={handleOnChange}
        style={{ alignItems: "center" }}
      />
      <span>{props.label}</span>
    </SelectionRow>
  );
};

/*to do: check all implementation on SearchBar, remove the duplicates
note: Storybook fails on import from helpers.tsx inside components folder 
so the SearchBar for table is moved here.
*/
type searchBarProps = {
  placeholder?: string;
  onSearch?: (searchTerm: string) => void;
  style?: React.CSSProperties;
  className?: string;
};

export const SearchBar: React.FC<searchBarProps> = (props: searchBarProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (_.isFunction(props.onSearch)) {
      props.onSearch(e.target.value);
    }
  };
  return (
    <Input
      placeholder={props.placeholder || "Search"}
      style={props.style}
      prefix={<SearchIcon />}
      allowClear={true}
      onChange={onChange}
      className={props.className}
    />
  );
};

export const getDateTimeFormatted = (dateTime: string) => {
  const date = !moment.isMoment(dateTime)
    ? moment(dateTime).format(DATE_FORMAT)
    : "";
  const time = !moment.isMoment(dateTime)
    ? moment(dateTime).format(TIME_FORMAT)
    : "";
  return (
    <Flex alignItems="center">
      <DateStyled>{date}</DateStyled>
      <TimeStyled>{time}</TimeStyled>
    </Flex>
  );
};
