import { Tooltip } from "antd";
import _ from "lodash";
import React from "react";
import ReactImageFallback from "react-image-fallback";
import { Flex } from "reflexbox";
import styled, { useTheme } from "styled-components";
import { getStaticMediaFile, LicensePlaceHolderLogo } from "../../utils";
import ColorsMap from "./IconColorsMap";
import { ElmAppImage } from "../../utils/AppImageDictionary/ElmAppImage";
import AppImageDictionary from "../../utils/AppImageDictionary/AppImageDictionary";

export const ProductMoreMessage = styled.p`
  height: 15px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.colors.textPrimary};
  margin: 0px;
`;

type product = {
  iconUrl: string;
  name: string;
};

const GeneratedIcon: React.FC<{
  nameKey: string;
  value: string;
}> = ({ nameKey, value }) => {
  const theme = useTheme();

  return (
    <Tooltip placement="top" title={nameKey}>
      <div
        style={{
          height: "100%",
          width: "100%",
          maxWidth: 28,
          maxHeight: 26,
          minHeight: 16,
          minWidth: 18,
          fontSize: "70%",
          borderRadius: "10%",
          backgroundColor: `rgba(${ColorsMap.getColorHash(nameKey)}, ${theme
            ?.customBgOpacity?.opacity || 0.2})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: theme?.colors?.black
        }}
      >
        {value.toUpperCase()}
      </div>
    </Tooltip>
  );
};

const formatMultipartName = (name: string) => {
  if (!name) {
    return "";
  }

  const formatWith = (target: string) => {
    const [first, second, ...rest] = name.split(target);
    return `${first[0].length === 1 ? first[0] : first[0][0]}${
      second[0]?.length === 1 ? second[0] : second[0][0]
    }`;
  };

  if (name.includes(" - ")) {
    return formatWith(" - ");
  }
  if (name.includes("-")) {
    return formatWith("-");
  }
  if (name.includes(" ")) {
    return formatWith(" ");
  }
  if (name.includes("_")) {
    return formatWith("_");
  }
  if (name.includes("&")) {
    return formatWith("&");
  }
  if (name.includes(".")) {
    return formatWith(".");
  }

  return `${name[0]}${name[1]}`;
};

export const generateProductIcon = (name: string) => {
  if (!name) {
    return "";
  }

  const isMultipartName =
    name.length > 2 ||
    name.includes(" ") ||
    name.includes("-") ||
    name.includes("_") ||
    name.includes("&") ||
    name.includes(".") ||
    name.includes(" - ");

  let displayName = isMultipartName
    ? formatMultipartName(name)
    : `${name[0]}${name[1]}`;

  let red =
    (Math.floor(Math.random() * (255 - 155 + 1)) + 155) *
    Math.round(Math.random() * Math.round(Math.random()));
  let grn =
    (Math.floor(Math.random() * (255 - 155 + 1)) + 155) *
    Math.round(Math.random());
  let blu =
    (Math.floor(Math.random() * (255 - 155 + 1)) + 155) *
    Math.round(Math.random());

  let displayColor = `${red}, ${grn}, ${blu}`;

  if (!ColorsMap.getColorHash(name)) {
    ColorsMap.setColorHash(name, displayColor);
  }

  return <GeneratedIcon nameKey={name} value={displayName} />;
};

const renderProductImages = (products: product[]) => {
  const extraCount = products.length - 2;
  const productImages = _.map(
    _.slice(products, 0, 2),
    (item: { id: string; iconUrl: string; name: string }) => {
      return (
        <Flex alignItems="center" style={{ minWidth: "max-content" }}>
          <div
            key={item.name}
            style={{
              marginRight: "5px",
              height: "20",
              width: "20px",
              padding: "1px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Tooltip
              placement="top"
              title={item.name ? item.name : "Name not specified."}
            >
              {AppImageDictionary.getImage(item?.id) ? (
                <ReactImageFallback
                  src={`${getStaticMediaFile({
                    url: AppImageDictionary.getImage(item.id)
                  })}`}
                  key={item.id}
                  fallbackImage={generateProductIcon(item?.name)}
                  initialImage={generateProductIcon(item?.name)}
                  alt={item.name || "Name not specified"}
                  height={"16px"}
                  width={"16px"}
                />
              ) : (
                <ElmAppImage
                  id={item?.id || item?.name}
                  url={item?.iconUrl}
                  name={item?.name}
                />
              )}
            </Tooltip>
          </div>
        </Flex>
      );
    }
  );

  const productsList = _.map(
    _.slice(products, 2),
    (item: { name: string }) => item.name
  ).join(", ");

  return (
    <Flex style={{ alignItems: "center" }}>
      {productImages}
      {extraCount > 0 ? (
        <Tooltip placement="top" title={productsList}>
          <ProductMoreMessage>{`+ ${extraCount} more`}</ProductMoreMessage>
        </Tooltip>
      ) : null}
    </Flex>
  );
};

export default renderProductImages;
