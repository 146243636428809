import { notification } from "antd";

export const renderNotification = (description: string) => {
  notification.open({
    message: "Success",
    description
  });
};

export const renderSuccessNotification = (description: string) => {
  notification.open({
    type: "success",
    message: "Success",
    description
  });
};

export const renderInfoNotification = (description: string) => {
  notification.open({
    message: "Info",
    description
  });
};

export const renderWarningNotification = (description: string) => {
  notification.open({
    message: "Warning",
    description
  });
};

export const renderErrorNotification = (description: string) => {
  notification.open({
    type: "error",
    message: "Error",
    description
  });
};

export const renderFailureNotification = (description: string) => {
  notification.open({
    type: "error",
    message: "Failure",
    description
  });
};
