import { graphql } from "babel-plugin-relay/macro";

export const usersTableExportQuery = graphql`
  query usersTableExportQuery(
    $bladeScope: String
    $sortColumn: [String!]
    $sortDirection: [String!]
    $filterColumn: [String!]
    $filterOp: [[String!]!]
    $filterValues: [[String!]!]
  ) {
    users(
      bladeScope: $bladeScope
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filterColumn: $filterColumn
      filterOp: $filterOp
      filterValues: $filterValues
    ) {
      nodes {
        id
        name
        email
        active
        owner {
          id
          name
        }
        products {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;
