/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type fragments_adminsTable = {
  readonly company: {
    readonly companySystemUsers: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly name: string | null;
          readonly email: string | null;
          readonly lastLogin: unknown | null;
          readonly avatarUrl: string | null;
          readonly thumbUrl: string | null;
          readonly id: string;
        } | null;
      } | null> | null;
    } | null;
    readonly companySystemUsersCount: number;
  } | null;
  readonly " $refType": "fragments_adminsTable";
};
export type fragments_adminsTable$data = fragments_adminsTable;
export type fragments_adminsTable$key = {
  readonly " $data"?: fragments_adminsTable$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"fragments_adminsTable">;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "blade_scope"
    },
    {
      defaultValue: 20,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    },
    {
      defaultValue: ["system_users.email"],
      kind: "LocalArgument",
      name: "sortColumn"
    },
    {
      defaultValue: ["ASC"],
      kind: "LocalArgument",
      name: "sortDirection"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: null,
        direction: "forward",
        path: ["company", "companySystemUsers"]
      }
    ]
  },
  name: "fragments_adminsTable",
  selections: [
    {
      alias: null,
      args: [
        {
          kind: "Variable",
          name: "id",
          variableName: "id"
        }
      ],
      concreteType: "Company",
      kind: "LinkedField",
      name: "company",
      plural: false,
      selections: [
        {
          alias: "companySystemUsers",
          args: [
            {
              kind: "Variable",
              name: "bladeScope",
              variableName: "blade_scope"
            },
            {
              kind: "Variable",
              name: "search",
              variableName: "search"
            },
            {
              kind: "Variable",
              name: "sortColumn",
              variableName: "sortColumn"
            },
            {
              kind: "Variable",
              name: "sortDirection",
              variableName: "sortDirection"
            }
          ],
          concreteType: "CompanySystemUserConnection",
          kind: "LinkedField",
          name: "__adminsTable_companySystemUsers_connection",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "CompanySystemUserEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "CompanySystemUser",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "name",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "email",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "lastLogin",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "avatarUrl",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "thumbUrl",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "companySystemUsersCount",
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "777329b3ebeb23ac7ec554698ddc5f3f";
export default node;
