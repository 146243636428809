import styled from "styled-components";
import AnalyticsCarousel from "./analyticsCarousel";
import AnalyticsChart from "./analyticsComponent";
import BladeTemplate from "./bladeManager/bladeTemplate";
import BladeManager from "./bladeManager";
import CompaniesTable from "../Companies/components/CompaniesTable";
import CompanyAttribute from "../Companies/components/companyAttribute";
import CompanyBusinessUnitsTable from "../Companies/components/companyBusinessUnitsTable";
import ElmChart from "./elmChart";
import ElmCounter from "./elmCounter";
import ElmDate from "./elmDate";
import ElmLabel from "./elmLabel";
import ElmInput from "./elmInput";
import ElmDefaultSelect from "./elmSelect";
import ElmFilterSelect from "./elmSelect";
import ElmSelect from "./elmSelect";
import ElmTable from "./elmTable";
import { ElmToggle } from "./elmToggle";
import FeaturesAndGroups from "../Products/components/featuresAndGroups";
import FeaturesTable from "../Products/components/featuresTable";
import FeatureGroupsTable from "../Products/components/featureGroupsTable";
import GateKeeper from "./gateKeeper";
import InstanceHistoryTable from "./instanceHistoryTable";
import LicensesTable from "../Licenses/components/licensesTable";
import LicenseEntitlementsListTable from "../Licenses/components/licenseEntitlementsListTable";
import LicenseReleaseTable from "../Licenses/components/licenseReleaseTable";
import LicenseActiveSessionsTable from "../Licenses/components/licenseActiveSessionsTable";
import ProductAttribute from "../Products/components/productAttribute";
import ProductAttributeAdvanced from "../Products/components/productAttributeAdvanced";
import ProductsTable from "../Products/components/productsTable";
import ProductReleasesTable from "../Products/components/productReleasesTable";
import ProductReleaseConfigurationsTable from "../Products/components/productReleaseConfigurationsTable";
import ProductReleaseChannelsTable from "../Products/components/productReleaseChannelsTable";
import { getIcon } from "./icons";
import InstanceAttribute from "./instanceAttribute";
import InstanceAttributeAdvanced from "./instanceAttributeAdvanced";
import LicenseAttribute from "../Licenses/components/licenseAttribute";
import LicenseAttributeAdvanced from "../Licenses/components/licenseAttributeAdvanced";
import UserAttribute from "../Users/components/userAttribute";
import UserAttributeAdvanced from "../Users/components/userAttributeAdvanced";
import UserInstallsTable from "../Users/components/userInstallsTable";
import UserProductHistoryTable from "../Users/components/userProductHistoryTable";
import TabView from "./tabView";
import ToggleView from "./toggleView";
import EntitlementsComponentsList from "../Licenses/components/entitlementComponentsList";
import renderProductImages from "../components/productImages";
import { ElmButton } from "./elmButton";

const BladeTitle = styled.span`
  color: ${props => props.theme.colors.pureBlack};
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
`;
BladeTitle.defaultProps = {
  className: "blade-title"
};
const BladeSubTitle = styled.p`
  ${props => props.theme.fontType.objectSubTitle}
`;
BladeSubTitle.defaultProps = {
  className: "blade-sub-title"
};
const VisualTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const BladeFeatureImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;
export {
  AnalyticsCarousel,
  AnalyticsChart,
  BladeFeatureImage,
  BladeManager,
  BladeTemplate,
  BladeSubTitle,
  BladeTitle,
  CompanyAttribute,
  CompanyBusinessUnitsTable,
  CompaniesTable,
  ElmButton,
  ElmChart,
  ElmCounter,
  ElmDate,
  ElmLabel,
  ElmInput,
  ElmDefaultSelect,
  ElmFilterSelect,
  ElmSelect,
  ElmTable,
  ElmToggle,
  EntitlementsComponentsList,
  FeaturesAndGroups,
  FeatureGroupsTable,
  FeaturesTable,
  GateKeeper,
  getIcon,
  InstanceAttribute,
  InstanceAttributeAdvanced,
  InstanceHistoryTable,
  LicenseAttribute,
  LicenseAttributeAdvanced,
  LicenseActiveSessionsTable,
  LicenseEntitlementsListTable,
  LicenseReleaseTable,
  LicensesTable,
  ProductsTable,
  ProductAttribute,
  ProductAttributeAdvanced,
  ProductReleasesTable,
  ProductReleaseChannelsTable,
  ProductReleaseConfigurationsTable,
  ToggleView,
  UserAttribute,
  UserAttributeAdvanced,
  UserInstallsTable,
  UserProductHistoryTable,
  TabView,
  VisualTitle,
  renderProductImages
};
