import { SelectProps } from "antd/lib/select";
import * as _ from "lodash";
import * as React from "react";
import { QueryRenderer } from "react-relay";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { getIcon } from "../../../components/icons";
import relayEnvironment from "../../../api/relay";
import { appConnect } from "../../../store/appConnect";
import {
  ElmSelect,
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../components/elmSelect";
import { IConnectedComponent } from "../../../components/types";
import {
  productSelectorQuery,
  productSelectorQueryResponse
} from "./__generated__/productSelectorQuery.graphql";
import { getClassName } from "../../../components/helpers";

export type productType = productSelectorQueryResponse["products"]["nodes"][number];
export interface IProductSelectorProps extends SelectProps<any> {
  onChange?: (product: productType) => void;
  style?: React.CSSProperties;
  className?: string;
  label?: string;
}

const graphqlQuery = graphql`
  query productSelectorQuery {
    products(sortColumn: ["product.name"], sortDirection: ["ASC"]) {
      nodes {
        name
        id
        type {
          id
          name
        }
      }
    }
  }
`;
export class ProductSelector extends React.Component<
  IConnectedComponent & IProductSelectorProps
> {
  private productNodes: any;
  public getproducts = (res: productSelectorQueryResponse) => {
    return _.map(res.products.nodes, node => {
      return (
        <ElmSelectOption key={node.id} value={node.id} label={node.name}>
          {node.name}
        </ElmSelectOption>
      );
    });
  };
  public handleChange = (productId: string) => {
    if (_.isFunction(this.props.onChange)) {
      const product = _.find(this.productNodes, node => node.id === productId);
      this.props.onChange(product);
    }
  };
  public renderSelector = (payload: {
    error: Error;
    props: productSelectorQueryResponse;
  }) => {
    if (!payload.props) {
      return <ElmSelectWithLabel {...this.props}>{null}</ElmSelectWithLabel>;
    }
    this.productNodes = payload.props.products.nodes;
    return (
      <ElmSelectWithLabel
        {...this.props}
        defaultValue={this.props.defaultValue}
        className={getClassName({
          "elm-product-selector": () => true,
          [this.props.className]: () => !!this.props.className
        })}
        // @ts-ignore
        onChange={this.handleChange}
      >
        {this.getproducts(payload.props)}
      </ElmSelectWithLabel>
    );
  };
  public render() {
    return (
      <QueryRenderer<productSelectorQuery>
        environment={relayEnvironment}
        variables={{}}
        query={graphqlQuery}
        fetchPolicy={"store-and-network"}
        render={this.renderSelector}
      />
    );
  }
}

export default appConnect<IProductSelectorProps>(ProductSelector, {
  selectors: {}
});
