/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CompanyQueryVariables = {
  id: string;
};
export type CompanyQueryResponse = {
  readonly company: {
    readonly logoUrl: string | null;
    readonly productsCount: number;
    readonly businessUnitsCount: number;
    readonly licensesCount: number;
    readonly usersCount: number;
    readonly createdAt: unknown;
    readonly name: string | null;
  } | null;
};
export type CompanyQuery = {
  readonly response: CompanyQueryResponse;
  readonly variables: CompanyQueryVariables;
};

/*
query CompanyQuery(
  $id: ID!
) {
  company(id: $id) {
    logoUrl
    productsCount
    businessUnitsCount
    licensesCount
    usersCount
    createdAt
    name
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "logoUrl",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "productsCount",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "businessUnitsCount",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "licensesCount",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "usersCount",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "createdAt",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "CompanyQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Company",
          kind: "LinkedField",
          name: "company",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "CompanyQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Company",
          kind: "LinkedField",
          name: "company",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "c8932795909eea4077520afd4cf2a73e",
      id: null,
      metadata: {},
      name: "CompanyQuery",
      operationKind: "query",
      text:
        "query CompanyQuery(\n  $id: ID!\n) {\n  company(id: $id) {\n    logoUrl\n    productsCount\n    businessUnitsCount\n    licensesCount\n    usersCount\n    createdAt\n    name\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "083e3b9d20d43d0aebea058f470da693";
export default node;
