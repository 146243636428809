type Entity =
  | "License"
  | "Product"
  | "Company"
  | "Component"
  | "Report"
  | "CommercialModel";

export const base64EncodeId = (entity: Entity, id: string | number) => {
  let retVal = "";
  try {
    retVal = btoa(`${entity}-${id}`);
  } catch {
    retVal = `${id}`;
  }
  return retVal;
};

export const base64DecodeId = (id: string) => {
  let retVal = "";
  try {
    let decoded = atob(id);
    retVal = decoded.slice(decoded.indexOf("-") + 1);
  } catch {
    retVal = id;
  }
  return retVal;
};
