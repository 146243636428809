/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type usersTable_products = {
  readonly usersTableProductsList: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null;
        readonly id: string;
        readonly iconUrl: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $refType": "usersTable_products";
};
export type usersTable_products$data = usersTable_products;
export type usersTable_products$key = {
  readonly " $data"?: usersTable_products$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"usersTable_products">;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "blade_scope"
    }
  ],
  kind: "Fragment",
  metadata: null,
  name: "usersTable_products",
  selections: [
    {
      alias: "usersTableProductsList",
      args: [
        {
          kind: "Variable",
          name: "bladeScope",
          variableName: "blade_scope"
        }
      ],
      concreteType: "ProductConnection",
      kind: "LinkedField",
      name: "products",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ProductEdge",
          kind: "LinkedField",
          name: "edges",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "node",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "name",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "id",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "iconUrl",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "3c4e62730142ce44d04ec4ced3b2d13f";
export default node;
