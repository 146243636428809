import { Collapse } from "antd";
import { Flex } from "reflexbox/styled-components";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { ElmButton } from "../../../components/elmButton";
import { ElmTable } from "../../../components/elmTable";
import { LoaderContainer } from "../../../components/helpers";
import { LicenseQueryResponse } from "Licenses/blades/License/__generated__/LicenseQuery.graphql";
import moment from "moment";
import { TableCellProps } from "react-virtualized";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { getEnvironment } from "api/relay";
import {
  entitlementComponentsListQuery,
  entitlementComponentsListQueryResponse
} from "./__generated__/entitlementComponentsListQuery.graphql";
import { appConnect } from "store/appConnect";
import { bladeWidth } from "components/bladeManager/bladeTemplate";

const StyledCollapse = styled(Collapse)`
  background-color: transparent;
  border: none;
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: ${props => props.theme.colors.textPrimary};
  }
  .ant-collapse-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    /* Black */
    color: ${props => props.theme.colors.textPrimary};
  }
  .ant-collapse-item {
    border: none;
    color: ${props => props.theme.colors.textPrimary};
  }
  .ant-collapse-content-active {
    border-top: none;
  }
  .ant-collapse-content-active,
  .ant-collapse-content-box {
    height: 258px;
    max-height: 258px;
    background-color: ${props => props.theme.colors.bladeContainer};
  }
  .header-text {
    min-width: 291px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: ${props => props.theme.colors.textPrimary};
  }
`;
const { Panel } = Collapse;

const AdditionalComponentsDesc = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;

  /* warmGrey */

  color: ${props => props.theme.colors.warmGrey};
  margin-left: 5px;
`;

type entitlementComponentsList = LicenseQueryResponse["license"]["componentEntitlements"];

const relayEnvironment = getEnvironment();
const graphqlQuery = graphql`
  query entitlementComponentsListQuery($id: ID!) {
    license(id: $id) {
      componentEntitlements {
        nodes {
          id
          tokenCount
          sessionCount
          numCurrencyUsed
          latestTerm {
            startDate
            endDate
          }
          components {
            nodes {
              name
              componentType
              id
            }
          }
          componentGroup {
            id
            name
            componentType
            components {
              nodes {
                name
                componentType
                id
              }
            }
          }
        }
      }
      activeEntitlements {
        nodes {
          id
          value
          latestTerm {
            startDate
            endDate
          }
          instanceCount
          sessionCount
          userCount
        }
      }
      product {
        id
        name
        iconUrl
        componentsCount
        featuresCount
        components {
          nodes {
            name
            componentType
            id
          }
        }
        componentGroups {
          nodes {
            name
            id
            components {
              nodes {
                name
                id
                componentType
              }
            }
          }
        }
      }
    }
  }
`;
export interface IEntitlementsComponentsListProps {
  licenseId: string;
  isVendor: boolean;
  onChangeSearch?: (searchTerm: string) => void;
  entitlements: entitlementComponentsList; //entitlementComponentsList_entitlements;
  productComponentGroups: LicenseQueryResponse["productComponents"]; //entitlementComponentsList_productComponentGroups;
  onAddNewEntitlement?: (notIncluded: string[]) => void;
  onClickEntitlement?: (
    rowData: entitlementComponentsList["nodes"][number]
  ) => void;
}
export interface IEntitlementsComponentsListState {
  searchString: string;
}
export class EntitlementsComponentsList extends React.Component<
  IEntitlementsComponentsListProps & {
    result: entitlementComponentsListQueryResponse;
    retry: () => void;
  },
  IEntitlementsComponentsListState
> {
  state: IEntitlementsComponentsListState = {
    searchString: ""
  };
  public onChangeSearchString = (searchString: string) => {
    this.setState({ searchString });
  };
  public getEntitlementNodes = (
    getExpired?: boolean
  ): entitlementComponentsList["nodes"] => {
    const componentEntitlements = this.props.entitlements;
    if (!componentEntitlements) {
      return [];
    }
    if (getExpired) {
      const expiredEntitlements = _.get(componentEntitlements, "nodes").filter(
        node => moment(node.latestTerm?.endDate) <= moment()
      );
      return expiredEntitlements;
    }
    const activeEntitlements = _.get(componentEntitlements, "nodes").filter(
      node => moment(node.latestTerm?.endDate) >= moment()
    );
    return activeEntitlements;
  };
  public renderIncludedTable = (isExpired?: boolean) => {
    const onRowClick = (
      rowData: entitlementComponentsList["nodes"][number]
    ) => {
      if (_.isFunction(this.props.onClickEntitlement)) {
        this.props.onClickEntitlement(rowData);
      }
    };
    const renderItemName = (
      rowData: entitlementComponentsList["nodes"][number]
    ) => {
      if (rowData.componentGroup) {
        return <span>{rowData.componentGroup.name}</span>;
      }
      return (
        <div style={{ display: "flex" }}>
          <span>{_.get(rowData, "components.nodes[0].name")}</span>
          {rowData.components.nodes.length > 1 ? (
            <AdditionalComponentsDesc>
              {`+${rowData.components.nodes.length - 1} more`}
            </AdditionalComponentsDesc>
          ) : null}
        </div>
      );
    };
    const renderLicensingModel = (
      rowData: entitlementComponentsList["nodes"][number]
    ) => {
      if (rowData.componentGroup) {
        return <span>{rowData.componentGroup?.componentType}</span>;
      }
      return <span>{_.get(rowData, "components.nodes[0].componentType")}</span>;
    };
    const renderUsageCount = (cell: TableCellProps) => {
      if (cell?.rowData.componentGroup) {
        if (
          cell?.rowData.componentGroup?.componentType !== "Consumable Token"
        ) {
          return null;
        }
        return (
          <span>
            {`${cell?.rowData?.numCurrencyUsed || 0}/${cell?.rowData
              ?.tokenCount || 0}`}
          </span>
        );
      }
      if (
        _.get(cell, "rowData.components.nodes[0].componentType") !==
        "Consumable Token"
      ) {
        return null;
      }
      return (
        <span>
          {`${cell?.rowData?.numCurrencyUsed || 0}/${cell?.rowData
            ?.tokenCount || 0}`}
        </span>
      );
    };
    return (
      <ElmTable
        data={
          isExpired
            ? this.getEntitlementNodes(true)
            : this.getEntitlementNodes()
        }
        hideSearchBar={true}
        hideFooter={true}
        onRowClick={onRowClick}
        columns={[
          {
            Header: "Items",
            accessor: "",
            cellRenderer: (cell: {
              rowData: entitlementComponentsList["nodes"][number];
            }) => renderItemName(cell.rowData),
            disableSortBy: true,
            sortKey: null
          },
          {
            Header: "Licensing Model",
            accessor: "",
            cellRenderer: (cell: {
              rowData: entitlementComponentsList["nodes"][number];
            }) => renderLicensingModel(cell.rowData),
            disableSortBy: true,
            sortKey: null
          },
          {
            Header: "Term",
            accessor: "latestTerm.endDate",
            cellRenderer: cell => {
              return cell.cellData ? "Limited" : "Unlimited";
            },
            disableSortBy: true,
            sortKey: null
          },
          {
            Header: "Usage",
            accessor: "",
            sortKey: null,
            disableSortBy: true,
            cellRenderer: cell => renderUsageCount(cell)
          }
        ]}
      />
    );
  };
  public isIncluded = (id: string, type?: "group" | "component") => {
    const componentEntitlements = this.props.entitlements;
    let isIncluded = false;
    if (componentEntitlements) {
      if (type === "group") {
        isIncluded = !!_.find(componentEntitlements.nodes, node =>
          node.componentGroup ? node.componentGroup.id === id : false
        );
      } else {
        isIncluded = !!_.find(componentEntitlements.nodes, node => {
          if (node.componentGroup) {
            //for the case when the component is included as part of the ComponentGroup
            return !!_.find(
              node.componentGroup.components.nodes,
              groupCompNode => groupCompNode?.id === id
            );
          } else {
            return !!_.find(
              node.components.nodes,
              compNode => compNode.id === id
            );
          }
        });
      }
    }
    return isIncluded;
  };

  public getTableData = (): {
    id: string;
    name: string;
    componentType: string;
  }[] => {
    if (!this.props.productComponentGroups) {
      return null;
    }
    const groupsNotIncluded = _.filter(
      this.props.productComponentGroups.componentGroups?.nodes,
      node => !this.isIncluded(node.id, "group")
    );
    const compsNotIncluded = _.filter(
      this.props.productComponentGroups.components?.nodes,
      node => !this.isIncluded(node.id, "component")
    );
    const groups = _.map(groupsNotIncluded, group => ({
      id: group.id,
      name: group.name,
      componentType: group[0]?.componentType
    }));
    const comps = _.map(compsNotIncluded, comp => ({
      id: comp.id,
      name: comp.name,
      componentType: comp.componentType
    }));
    return [...(groups || []), ...(comps || [])];
  };
  public getCustomerTableData = (): {
    id: string;
    name: string;
    componentType: string; // "Session" | "Checked-out Token" | "Consumable Token"
    tokenCount: number;
    sessionCount: number;
  }[] => {
    const license = _.get(this.props.result, "license");
    const componentEntitlements = _.get(license, "componentEntitlements");
    let retVal: {
      id: string;
      name: string;
      componentType: string; // "Session" | "Checked-out Token" | "Consumable Token"
      tokenCount: number;
      sessionCount: number;
    }[] = [];
    const componentIds: string[] = [];

    componentEntitlements?.nodes?.forEach(node => {
      node?.components?.nodes?.forEach(c => {
        retVal.push({
          id: c.id,
          name: c.name,
          componentType: c.componentType,
          tokenCount: node.tokenCount || 0,
          sessionCount: node.sessionCount || 0
        });
        componentIds.push(c.id);
      });
      node?.componentGroup?.components?.nodes?.forEach(c => {
        retVal.push({
          id: c.id,
          name: c.name,
          componentType: c.componentType,
          tokenCount: node.tokenCount || 0,
          sessionCount: node.sessionCount || 0
        });
        componentIds.push(c.id);
      });
    });
    const product = _.get(license, "product");
    product?.components?.nodes?.forEach(c => {
      if (!componentIds.includes(c.id)) {
        retVal.push({
          id: c.id,
          name: c.name,
          componentType: c.componentType,
          tokenCount: 0,
          sessionCount: 0
        });
        componentIds.push(c.id);
      }
    });
    product?.componentGroups?.nodes?.forEach(n => {
      n?.components?.nodes?.forEach(c => {
        if (!componentIds.includes(c.id)) {
          retVal.push({
            id: c.id,
            name: c.name,
            componentType: c.componentType,
            tokenCount: 0,
            sessionCount: 0
          });
          componentIds.push(c.id);
        }
      });
    });
    return retVal;
  };
  public renderTableForCustomerRole = () => {
    if (!this.props.result) {
      return LoaderContainer();
    }
    return (
      <ElmTable
        data={this.getCustomerTableData()}
        hideSearchBar={true}
        hideFooter={true}
        columns={[
          {
            Header: "Component",
            accessor: "",
            cellRenderer: (cell: {
              rowData: { name: string; componentType: string };
            }) => <span>{cell.rowData.name}</span>,
            disableSortBy: true,
            sortKey: null
          },
          {
            Header: "Tokens",
            disableSortBy: true,
            accessor: "tokenCount",
            isNumber: true,
            sortKey: null,
            cellRenderer: ({ rowData }) =>
              rowData?.componentType === "Checked-out Token"
                ? rowData.tokenCount
                : null
          },
          {
            Header: "Currency",
            HeaderInfoMessage: "Consumable token",
            disableSortBy: true,
            accessor: "tokenCount",
            isNumber: true,
            sortKey: null,
            cellRenderer: ({ rowData }) =>
              rowData?.componentType === "Consumable Token"
                ? rowData.tokenCount
                : null
          },
          {
            Header: "Sessions",
            disableSortBy: true,
            isNumber: true,
            accessor: "sessionCount",
            sortKey: null
          }
        ]}
      />
    );
  };

  public renderNotIncludedTable = () => {
    return (
      <ElmTable
        data={this.getTableData()}
        hideSearchBar={true}
        hideFooter={true}
        // onRowClick={onRowClick}
        columns={[
          {
            Header: "Items",
            accessor: "",
            cellRenderer: (cell: {
              rowData: { name: string; componentType: string };
            }) => <span>{cell.rowData.name}</span>,
            disableSortBy: true,
            sortKey: null
          },
          {
            Header: "Licensing Model",
            cellRenderer: (cell: {
              rowData: { name: string; componentType: string };
            }) => <span>{cell.rowData.componentType}</span>,
            disableSortBy: true,
            accessor: "",
            sortKey: null
          }
        ]}
      />
    );
  };

  public onAddNew = () => {
    const notIncluded = this.getTableData();
    const availableComponents = notIncluded.map(item => item.id);
    this.props.onAddNewEntitlement(availableComponents);
  };

  public render() {
    return (
      <div
        style={{
          position: "relative",
          minHeight: "100%",
          width: bladeWidth - 210
        }}
      >
        {/* <Flex
          alignItems="flex-end"
          justifyContent="flex-end"
          style={{ marginBottom: "6px", maxHeight: "30px", minHeight: "30px" }}
        > */}
        {/* <SearchBar onSearch={this.onChangeSearchString} /> */}
        {/* <ElmButton
            style={{ minWidth: "108px" }}
            label={"Entitlement"}
            onClick={() => this.onAddNew()}
            icon="plus"
            iconPrefix="fas"
            permissions="modify_assets"
            variance="plain-icon-button"
          />
        </Flex> */}
        {/* {this.props.isVendor ? (
          <Flex flexDirection="column">
            <StyledCollapse accordion={true}>
              <Panel
                key={"included"}
                header={"Active"}
                disabled={!this.getEntitlementNodes().length}
              >
                {this.renderIncludedTable()}
              </Panel>
              <Panel key={"not included"} header={"Not included"}>
                {this.renderNotIncludedTable()}
              </Panel>
              <Panel key={"expired"} header={"Expired"}>
                {this.renderIncludedTable(true)}
              </Panel>
            </StyledCollapse>
          </Flex>
        ) : ( */}
        {this.renderTableForCustomerRole()}
        {/* )} */}
      </div>
    );
  }
}

const RenderQuery = (props: IEntitlementsComponentsListProps) => {
  const renderAccountInfo = (payload: {
    error: Error;
    props: entitlementComponentsListQueryResponse;
    retry: () => void;
  }) => {
    return (
      <EntitlementsComponentsList
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    );
  };
  return (
    <QueryRenderer<entitlementComponentsListQuery>
      environment={relayEnvironment}
      cacheConfig={{
        force: true
      }}
      variables={{
        id: props.licenseId
      }}
      query={graphqlQuery}
      render={renderAccountInfo}
    />
  );
};

export default appConnect(RenderQuery, {
  selectors: {}
});
