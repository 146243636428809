/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReportsQueryVariables = {};
export type ReportsQueryResponse = {
  readonly currentUser: {
    readonly id: string;
    readonly name: string | null;
    readonly email: string | null;
  } | null;
  readonly " $fragmentRefs": FragmentRefs<"Reports_reports">;
};
export type ReportsQuery = {
  readonly response: ReportsQueryResponse;
  readonly variables: ReportsQueryVariables;
};

/*
query ReportsQuery {
  ...Reports_reports
  currentUser {
    __typename
    id
    name
    email
  }
}

fragment Reports_reports on Query {
  reportsCount
  reports(first: 25, sortColumn: ["reports.createdAt"], sortDirection: ["DESC"]) {
    edges {
      node {
        id
        name
        options
        createdAt
        reportType
        isPrivate
        vendorSystemUser {
          id
          name
          email
        }
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "email",
      storageKey: null
    } as any,
    v3 = [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
    v4 = [
      {
        kind: "Literal",
        name: "first",
        value: 25
      } as any,
      {
        kind: "Literal",
        name: "sortColumn",
        value: ["reports.createdAt"]
      } as any,
      {
        kind: "Literal",
        name: "sortDirection",
        value: ["DESC"]
      } as any
    ],
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "__typename",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "ReportsQuery",
      selections: [
        {
          alias: null,
          args: null,
          concreteType: null,
          kind: "LinkedField",
          name: "currentUser",
          plural: false,
          selections: v3 /*: any*/,
          storageKey: null
        },
        {
          args: null,
          kind: "FragmentSpread",
          name: "Reports_reports"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "ReportsQuery",
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "reportsCount",
          storageKey: null
        },
        {
          alias: null,
          args: v4 /*: any*/,
          concreteType: "ReportConnection",
          kind: "LinkedField",
          name: "reports",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ReportEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Report",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    v0 /*: any*/,
                    v1 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "options",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "createdAt",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "reportType",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "isPrivate",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "VendorSystemUser",
                      kind: "LinkedField",
                      name: "vendorSystemUser",
                      plural: false,
                      selections: v3 /*: any*/,
                      storageKey: null
                    },
                    v5 /*: any*/
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey:
            'reports(first:25,sortColumn:["reports.createdAt"],sortDirection:["DESC"])'
        },
        {
          alias: null,
          args: v4 /*: any*/,
          filters: ["search", "bladeScope", "sortColumn", "sortDirection"],
          handle: "connection",
          key: "Reports_reports",
          kind: "LinkedHandle",
          name: "reports"
        },
        {
          alias: null,
          args: null,
          concreteType: null,
          kind: "LinkedField",
          name: "currentUser",
          plural: false,
          selections: [v5 /*: any*/, v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "3aa3343c0e8d81f969f048aa26881b26",
      id: null,
      metadata: {},
      name: "ReportsQuery",
      operationKind: "query",
      text:
        'query ReportsQuery {\n  ...Reports_reports\n  currentUser {\n    __typename\n    id\n    name\n    email\n  }\n}\n\nfragment Reports_reports on Query {\n  reportsCount\n  reports(first: 25, sortColumn: ["reports.createdAt"], sortDirection: ["DESC"]) {\n    edges {\n      node {\n        id\n        name\n        options\n        createdAt\n        reportType\n        isPrivate\n        vendorSystemUser {\n          id\n          name\n          email\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "f32bc2b63f674d14bfc6ca58f0a7943d";
export default node;
