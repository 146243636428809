import React from "react";
import { RouteChildrenProps } from "react-router-dom";
import { getGateway } from "./api";
import { withRouter } from "react-router";

class PasswordUpdateRedirect extends React.Component<RouteChildrenProps> {
  componentDidMount(): void {
    const searchParams = new URLSearchParams(this.props.location.search);
    const gateway = getGateway();
    gateway.request
      .getResetPasswordAuth({
        config: searchParams.get("config"),
        redirect_url: searchParams.get("redirect_url"),
        reset_password_token: searchParams.get("reset_password_token")
      })
      .then(res => {
        if (res?.data?.redirect_url) {
          window.location.replace(res.data.redirect_url);
        } else {
          alert("Something went wrong, please try again!");
          window.location.replace("/login");
        }
      });
  }

  public render() {
    return <></>;
  }
}

export default withRouter(PasswordUpdateRedirect);
