import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { LoaderContainer } from "../../../../components/helpers";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../../api/relay";
import { graphql } from "babel-plugin-relay/macro";
import { DialogTitle } from "../../../../components/bladeManager/appDialog/helpers";
import { LicenseTemplatesTable } from "../../licenseTemplatesTable";
import {
  selectTemplateQuery,
  selectTemplateQueryResponse
} from "./__generated__/selectTemplateQuery.graphql";
import {
  renderInfoNotification,
  renderWarningNotification
} from "utils/ant-notifications";
const graphqlQuery = graphql`
  query selectTemplateQuery {
    ...licenseTemplatesTable_licenseTemplates
  }
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 480px;
  width: 440px;
`;

export interface INewProductReleaseConfigDialogProps {}
type state = {
  loading: boolean;
  selectedTemplate: string;
};
export class SelectTemplateDialog extends DialogBase<
  IModalProps<INewProductReleaseConfigDialogProps>,
  state
> {
  state: state = {
    loading: false,
    selectedTemplate: null
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 999,
          background: "rgb(255, 255, 255, 0.7)"
        },
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "SelectTemplateDialog"
    });
  };

  public renderQueryRenderer = () => {
    const renderComponentGroup = (payload: {
      error: Error;
      props: selectTemplateQueryResponse;
    }) => {
      if (payload.error || !_.isObject(payload.props)) {
        return <DialogContainer>{LoaderContainer()}</DialogContainer>;
      }

      const selectTemplate = (row: any) => {
        const templateData = _.get(row, "templateBody", null);
        if (templateData) {
          this.setState({ selectedTemplate: templateData });
        } else {
          renderWarningNotification("Template is not valid");
        }
      };

      const applySelectedTemplate = () => {
        if (this.state.selectedTemplate) {
          this.props.payload.onConfirm(JSON.parse(this.state.selectedTemplate));
        } else {
          renderInfoNotification("Please select template");
        }
      };

      return (
        <DialogContainer>
          <DialogTitle>Select template</DialogTitle>

          <Flex
            flexDirection="column"
            flex={1}
            style={{
              width: "100%",
              marginTop: "5px",
              marginBottom: "5px",
              height: "190px"
            }}
          >
            <LicenseTemplatesTable
              templates={payload.props}
              onRowClick={selectTemplate}
              hideColumnsWithHeaders={["Actions"]}
              hideFooter
            />
          </Flex>
          <Flex alignItems="flex-end" justifyContent={"flex-end"}>
            <ElmButton
              label={"Cancel"}
              colorVariance={"outline-secondary"}
              onClick={this.closeModal}
            />
            <ElmButton
              //disabled={this.state.loading}
              label={"Select"}
              colorVariance="primary"
              onClick={applySelectedTemplate}
            />
          </Flex>
        </DialogContainer>
      );
    };
    return (
      <QueryRenderer<selectTemplateQuery>
        variables={{
          id: null
        }}
        environment={relayEnvironment}
        query={graphqlQuery}
        render={renderComponentGroup}
      />
    );
  };

  public render() {
    return this.renderQueryRenderer();
  }
}
