/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type featureGroupsTablePaginationQueryVariables = {
  count: number;
  cursor?: string | null | undefined;
  bladeScope: string;
};
export type featureGroupsTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"featureGroupsTable_featureGroups">;
};
export type featureGroupsTablePaginationQuery = {
  readonly response: featureGroupsTablePaginationQueryResponse;
  readonly variables: featureGroupsTablePaginationQueryVariables;
};

/*
query featureGroupsTablePaginationQuery(
  $count: Int!
  $cursor: String
  $bladeScope: String!
) {
  ...featureGroupsTable_featureGroups_20X9VB
}

fragment featureGroupsTable_featureGroups_20X9VB on Query {
  featureGroups(first: $count, after: $cursor, bladeScope: $bladeScope) {
    edges {
      node {
        name
        id
        features {
          nodes {
            id
            name
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "bladeScope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v3 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "bladeScope"
    } as any,
    v4 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      v3 /*: any*/,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any
    ],
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: "Fragment",
      metadata: null,
      name: "featureGroupsTablePaginationQuery",
      selections: [
        {
          args: [
            v3 /*: any*/,
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            }
          ],
          kind: "FragmentSpread",
          name: "featureGroupsTable_featureGroups"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [v1 /*: any*/, v2 /*: any*/, v0 /*: any*/],
      kind: "Operation",
      name: "featureGroupsTablePaginationQuery",
      selections: [
        {
          alias: null,
          args: v4 /*: any*/,
          concreteType: "FeatureGroupConnection",
          kind: "LinkedField",
          name: "featureGroups",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "FeatureGroupEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "FeatureGroup",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    v5 /*: any*/,
                    v6 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: "FeatureConnection",
                      kind: "LinkedField",
                      name: "features",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "Feature",
                          kind: "LinkedField",
                          name: "nodes",
                          plural: true,
                          selections: [v6 /*: any*/, v5 /*: any*/],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v4 /*: any*/,
          filters: ["bladeScope"],
          handle: "connection",
          key: "featureGroupsTable_featureGroups",
          kind: "LinkedHandle",
          name: "featureGroups"
        }
      ]
    },
    params: {
      cacheID: "541db32b99ad2d500ae1ad29af977271",
      id: null,
      metadata: {},
      name: "featureGroupsTablePaginationQuery",
      operationKind: "query",
      text:
        "query featureGroupsTablePaginationQuery(\n  $count: Int!\n  $cursor: String\n  $bladeScope: String!\n) {\n  ...featureGroupsTable_featureGroups_20X9VB\n}\n\nfragment featureGroupsTable_featureGroups_20X9VB on Query {\n  featureGroups(first: $count, after: $cursor, bladeScope: $bladeScope) {\n    edges {\n      node {\n        name\n        id\n        features {\n          nodes {\n            id\n            name\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "5734152a5e070703ec697b6eda6e9346";
export default node;
