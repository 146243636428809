/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type OverviewQueryVariables = {};
export type OverviewQueryResponse = {
  readonly allUsersCount: number;
  readonly allProductsCount: number;
  readonly allLicensesCount: number;
  readonly allLicensesSeats: {
    readonly nodes: ReadonlyArray<{
      readonly totalSeatCount: number | null;
    } | null> | null;
  } | null;
};
export type OverviewQuery = {
  readonly response: OverviewQueryResponse;
  readonly variables: OverviewQueryVariables;
};

/*
query OverviewQuery {
  allUsersCount: usersCount
  allProductsCount: productsCount
  allLicensesCount: licensesCount
  allLicensesSeats: licenses {
    nodes {
      totalSeatCount
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      alias: "allUsersCount",
      args: null,
      kind: "ScalarField",
      name: "usersCount",
      storageKey: null
    } as any,
    v1 = {
      alias: "allProductsCount",
      args: null,
      kind: "ScalarField",
      name: "productsCount",
      storageKey: null
    } as any,
    v2 = {
      alias: "allLicensesCount",
      args: null,
      kind: "ScalarField",
      name: "licensesCount",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "totalSeatCount",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "OverviewQuery",
      selections: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        {
          alias: "allLicensesSeats",
          args: null,
          concreteType: "LicenseConnection",
          kind: "LinkedField",
          name: "licenses",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "License",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [v3 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "OverviewQuery",
      selections: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        {
          alias: "allLicensesSeats",
          args: null,
          concreteType: "LicenseConnection",
          kind: "LinkedField",
          name: "licenses",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "License",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "id",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "5800e3163a9b94296901819c212f7018",
      id: null,
      metadata: {},
      name: "OverviewQuery",
      operationKind: "query",
      text:
        "query OverviewQuery {\n  allUsersCount: usersCount\n  allProductsCount: productsCount\n  allLicensesCount: licensesCount\n  allLicensesSeats: licenses {\n    nodes {\n      totalSeatCount\n      id\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "4d87fca9be2d5cd74ad2c2e29c9a2116";
export default node;
