import _ from "lodash";
import { tableGenerator } from "../../../utils";
import { graphql } from "babel-plugin-relay/macro";
import { usersShortListTable_users } from "./__generated__/usersShortListTable_users.graphql";

export type userTableEdges = usersShortListTable_users["usersShortListTableUsers"]["edges"];
export type userTableType = userTableEdges[number]["node"];
export type IUserTableProps = {
  users: usersShortListTable_users;
};

const UsersShortListTable = tableGenerator<IUserTableProps>({
  columns: [
    {
      Header: "",
      accessor: "action",
      disableSortBy: true,
      cellRenderer: () => "",
      width: 0.05
    },
    {
      Header: "User",
      accessor: "name",
      disableSortBy: true,
      width: 0.45
    },
    {
      Header: "Email",
      accessor: "email",
      disableSortBy: true
    }
  ],
  dataKey: "users.usersShortListTableUsers",
  connectionQuery: graphql`
    query usersShortListTablePaginationQuery(
      $count: Int!
      $cursor: String
      $blade_scope: String
      $search: String
      $filterColumn: [String!]
      $filterValues: [[String!]!]
      $filterOp: [[String!]!]
    ) {
      ...usersShortListTable_users
        @arguments(
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          count: $count
          cursor: $cursor
          blade_scope: $blade_scope
          search: $search
        )
    }
  `,
  getTotalCount: data => {
    return _.get(data, "users.usersShortListTableCount") || 0;
  },
  fragmentSpec: {
    users: graphql`
      fragment usersShortListTable_users on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: null }
          cursor: { type: "String" }
          blade_scope: { type: "String", defaultValue: null }
          search: { type: "String", defaultValue: null }
          filterColumn: { type: "[String!]", defaultValue: ["users.active"] }
          filterOp: { type: "[[String!]!]", defaultValue: [["="]] }
          filterValues: { type: "[[String!]!]", defaultValue: [["true"]] }
        ) {
        usersShortListTableCount: usersCount(
          search: $search
          bladeScope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        )
        usersShortListTableUsers: users(
          first: $count
          after: $cursor
          bladeScope: $blade_scope
          search: $search
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        ) @connection(key: "UsersShortListTable_usersShortListTableUsers") {
          edges {
            node {
              id
              name
              email
              active
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  },
  tableName: "usersShortList"
});

export default UsersShortListTable;
