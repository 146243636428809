import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { ProductCountWatcherQuery } from "./__generated__/ProductCountWatcherQuery.graphql";
import { getOnboardingConfigSync } from "./storage";

const query = graphql`
  query ProductCountWatcherQuery {
    productsCount
  }
`;

export const ProductCountWatcher = (props: { alertCount: () => void }) => {
  const res = useLazyLoadQuery<ProductCountWatcherQuery>(query, {});
  React.useEffect(() => {
    const isProductSetupComplete =
      getOnboardingConfigSync("CreateProduct")?.status === "completed";
    const isOnboardingPending =
      getOnboardingConfigSync("Status")?.status === "pending";
    const isOnboardingSkip =
      getOnboardingConfigSync("Status")?.status === "skip";
    if (isProductSetupComplete) {
      return;
    }
    if (isOnboardingPending) {
      return;
    }
    if (isOnboardingSkip) {
      return;
    }
    if (!res?.productsCount) {
      props.alertCount();
    }
  }, []);

  return <></>;
};
