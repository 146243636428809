export const formatInlineDisplayValues = (input: string[]) => {
  const MAX_DISPLAY_VALUE = 28;

  if (input.length < 3) {
    const display = input.join(", ");
    const output =
      display.length > MAX_DISPLAY_VALUE
        ? `${display.substring(0, MAX_DISPLAY_VALUE - 1)}...`
        : display;
    return output;
  }

  const [first, second, ...rest] = input;
  const display = `${first}, ${second}`;

  const output =
    display.length > MAX_DISPLAY_VALUE
      ? `${display.substring(0, MAX_DISPLAY_VALUE - 1)}...`
      : display;

  return `${output} + ${rest.length} more`;
};
