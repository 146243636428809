import { Collapse } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import { Flex } from "reflexbox/styled-components";
import * as React from "react";
import relayEnvironment from "../../../api/relay";
import styled, { useTheme } from "styled-components";
import { QueryRenderer } from "react-relay";
import {
  featuresAndGroupsToggleQuery,
  featuresAndGroupsToggleQueryResponse
} from "./__generated__/featuresAndGroupsToggleQuery.graphql";
import Loader from "react-loaders";
import Fuse from "fuse.js";
import { ElmToggle } from "../../../components/elmToggle";
import { SearchBar } from "../../../components/helpers";
import { FeatureLabel, FeatureTitle, StyledCollapse } from "./styles";
import { useEffect, useState } from "react";
import {
  useComponentsAndFeaturesData,
  useFeaturesAndGroupsData
} from "../../blades/AddLicense/hooks";
import { hooksFeaturesAndComponentsQueryResponse } from "Licenses/blades/AddLicense/__generated__/hooksFeaturesAndComponentsQuery.graphql";

export type FeatureOrGroup = "feature" | "group";
export type ComponentOrGroup = "component" | "group";
export interface IFeaturesAndGroupsProps {
  license?: featuresAndGroupsToggleQueryResponse["license"];
  features?: { id: string; name: string }[];
  productId?: string;
  licenseId?: string;
  templateId?: string;
  featureGroup?: { id: string; name: string }[];
  retry?: () => void;
  onChange?: (payload: {
    type: FeatureOrGroup;
    id: string;
    name: string;
    value: boolean;
    updateQuery: () => void;
  }) => void;
}

export interface IComponentsAndGroupsProps {
  license?: featuresAndGroupsToggleQueryResponse["license"];
  components?: { id: string; name: string }[];
  productId?: string;
  licenseId?: string;
  templateId?: string;
  componentGroup?: { id: string; name: string }[];
  retry?: () => void;
  onChange?: (payload: {
    type: ComponentOrGroup;
    id: string;
    name: string;
    value: boolean;
    updateQuery: () => void;
  }) => void;
}

type featureGroups = featuresAndGroupsToggleQueryResponse["license"]["product"]["featureGroups"]["nodes"];
type features = featuresAndGroupsToggleQueryResponse["license"]["product"]["features"]["nodes"];
export type featureGroupType = featureGroups[number];
export type featureType = features[number];

type componentGroups = hooksFeaturesAndComponentsQueryResponse["product"]["componentGroups"]["nodes"];
type components = hooksFeaturesAndComponentsQueryResponse["product"]["components"]["nodes"];
type componentGroupType = componentGroups[number];
type componentType = components[number];

const { Panel } = Collapse;
const StyledLoader = styled(Loader)`
  .loader-inner {
    div {
      border-color: ${props => props.theme.colors.lightishBlue};
      width: 15px;
      height: 15px;
    }
  }
`;

interface IFeatureToggle {
  enabled: boolean;
  id: string;
  name?: string;
  type: FeatureOrGroup;
  totalEnabled?: number;
  disabled?: boolean;
  helou?: string;
  onReportChange: (
    type: FeatureOrGroup,
    id: string,
    name: string
  ) => (value: boolean) => void;
}

interface IComponentToggle {
  enabled: boolean;
  id: string;
  name?: string;
  type: ComponentOrGroup;
  totalEnabled?: number;
  disabled?: boolean;
  helou?: string;
  onReportChange: (
    type: ComponentOrGroup,
    id: string,
    name: string
  ) => (value: boolean) => void;
}

type IToggle = IFeatureToggle | IComponentToggle;

type Status = {
  [key: string]: boolean;
  isLoading: boolean;
};

const StyledLabel = styled.span`
  font-size: ${props => props.theme.fontSizes.xSmall};
  font-weight: 400;
  //color: ${props => props.theme.colors.lightBlue};
  line-height: 14.5px;
  width: 340px;
`;

const getActivationLabel = (activeInstalls: number) => {
  if (activeInstalls <= 0) return "No active installs";
  if (activeInstalls > 0)
    return `${activeInstalls} active install${activeInstalls > 1 ? "s" : ""}`;
  return "";
};

function ToggleActivation({
  enabled,
  id,
  name,
  type,
  totalEnabled,
  //retry,
  onReportChange,
  disabled
}: IToggle) {
  const [status, setStatus] = useState<Status>({
    [id]: enabled,
    isLoading: false
  });

  useEffect(() => {
    setStatus({ [id]: enabled, isLoading: false });
  }, [enabled, id]);

  const labelValue = !!enabled ? getActivationLabel(totalEnabled) : "";

  const handleActivation = (state: boolean, id: string) => {
    setStatus({ [id]: state, isLoading: false });
    onReportChange(type, id, name)(state);
    // @ts-ignore
    //retry({ product_id: id, enabled: state });
  };
  return (
    <ElmToggle
      key={id}
      //loading={status.isLoading}
      size="small"
      className="elm-toggle-features"
      leftLabel={<StyledLabel>{name}</StyledLabel>}
      //rightLabel={<StyledLabel>{labelValue}</StyledLabel>}
      onChange={(state: boolean) => handleActivation(state, id)}
      defaultChecked={enabled}
      checked={status[id]}
      disabled={disabled}
      //style={{ display: "flex", color: "red"}}
      //switchStyle={{ display: "flex", color: "red", backgroundColor:"red"}}
    />
  );
}

const ToggleRow = (props: {
  row: featureGroupType | featureType;
  type: FeatureOrGroup;
  isChecked: (payload: { id: string; type: FeatureOrGroup }) => boolean;
  reportChange: (
    type: FeatureOrGroup,
    id: string,
    name: string
  ) => (value: boolean) => void;
}) => {
  const isChecked = props.isChecked({
    id: props.row.id,
    type: props.type
  });
  const [requestState, updateRequestState] = React.useState({
    requesting: false,
    toggleState: isChecked
  });
  const handleChange = (value: boolean) => {
    updateRequestState({ requesting: true, toggleState: value });
    props.reportChange(props.type, props.row.id, props.row.name)(value);
  };
  const renderLoader = () => {
    if (requestState.requesting && isChecked !== requestState.toggleState) {
      return (
        <StyledLoader type="ball-clip-rotate" active={true} color={"#0F62FE"} />
      );
    }
    return null;
  };
  if (props.type === "feature") {
    // const handleToggleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    //   e.stopPropagation();
    //   e.preventDefault();
    // };
    return (
      <Flex style={{ height: "24px", marginBottom: "10px" }}>
        <FeatureTitle style={{ minWidth: "310px" }}>
          {props.row.name}
        </FeatureTitle>
        <div>
          <ElmToggle
            size={"small"}
            //checked={isChecked}
            onChange={handleChange}
            //loading=
          />
        </div>
        {/* {renderLoader()} */}
      </Flex>
    );
  }
  return (
    <Flex>
      <span className={"header-text"}>{props.row.name}</span>
      <ElmToggle size={"small"} checked={isChecked} onChange={handleChange} />
      {renderLoader()}
    </Flex>
  );
};

interface IFeaturesAndGroupsState {
  search_term: string;
  features: { id: string; name: string }[];
  featureGroup: { id: string; name: string }[];
}
interface IComponentsAndGroupsState {
  search_term: string;
  components: { id: string; name: string }[];
  componentGroup: { id: string; name: string }[];
}
export function FeaturesAndGroupsToggle({
  features,
  featureGroup,
  onChange,
  productId,
  templateId,
  licenseId
}: IFeaturesAndGroupsProps) {
  // extends React.Component<
  //   IFeaturesAndGroupsProps & { theme: any },
  //   IFeaturesAndGroupsState
  // >
  // public state: IFeaturesAndGroupsState = {
  //   search_term: "",
  //   features: this.props?.features || []
  // };
  const [state, setState] = useState<IFeaturesAndGroupsState>({
    search_term: "",
    features: features || [],
    featureGroup: featureGroup || []
  });

  useEffect(() => {
    setState({ ...state, features: features });
  }, [templateId]);

  const data = useFeaturesAndGroupsData({
    productId: productId,
    licenseId: "" //licenseId
  });

  const reportChange = (type: FeatureOrGroup, id: string, name: string) => (
    value: boolean
  ) => {
    let features = [...state.features];
    let featureGroup = [...state.featureGroup];
    if (value) {
      //feature enabled add to the enabled features
      type === "feature" && features.push({ id, name });
      if (type === "group") {
        //get features from the group
        // const featuresFromGroup = _.get(data, "product.featureGroups.nodes", []);
        // features.push(...featuresFromGroup);
        featureGroup.push({ id, name });
      }
    }
    if (!value) {
      //feature disabled, remove from enabled features
      if (type === "feature")
        features = _.filter(features, item => item.id !== id);
      if (type === "group")
        featureGroup = _.filter(featureGroup, item => item.id !== id);
    }
    setState({ ...state, features, featureGroup });
    if (_.isFunction(onChange)) {
      onChange({
        type,
        features,
        featureGroup
        // id,
        // name,
        // value,
        // updateQuery: this.props.retry,
      });
    }
  };
  const isChecked = (payload: { id: string; type: FeatureOrGroup }) => {
    //const type = payload.type === "feature" ? "features" : "featureGroup";
    if (payload.type === "feature") {
      const filtered = _.filter(
        state.features,
        //this.props.license[type].nodes,
        n => n.id === payload.id
      );

      return !!filtered.length;
    }
    if (payload.type === "group") {
      const filtered = _.filter(
        state.featureGroup,
        //this.props.license[type].nodes,
        n => n.id === payload.id
      );

      return !!filtered.length;
    }
    return false;
  };
  // const isFeatureDisabled = (id: string) => {
  //   let isDisabled = false;
  //   _.map(this.props.license.featureGroups.nodes, n => {
  //     _.each(n.features.nodes, f => {
  //       if (f.id === id) {
  //         isDisabled = true;
  //       }
  //     });
  //   });

  //   return isDisabled;
  // };
  const renderFeatures = () => {
    const featuresWithoutGroups = _.map(
      getFeaturesWithoutGroups(),
      (feature: featureType) => (
        // <ToggleRow
        //   isChecked={this.isChecked}
        //   reportChange={this.reportChange}
        //   row={feature}
        //   type={"feature"}
        // />
        <ToggleActivation
          type={"feature"}
          id={feature.id}
          name={feature.name}
          enabled={isChecked({ id: feature.id, type: "feature" })}
          //enabled={false}
          onReportChange={reportChange}
          //retry={payload.rowData.retry}
        />
      )
    );
    return (
      <Flex
        style={{
          flexDirection: "column",
          flex: 1,
          minHeight: "max-content"
        }}
      >
        {featuresWithoutGroups?.length ? (
          <FeatureLabel>Other features available in this product.</FeatureLabel>
        ) : null}
        <div
          style={{
            minHeight: "max-content",
            width: "340px",
            position: "relative",
            maxHeight: "100%"
          }}
        >
          {featuresWithoutGroups}
        </div>
      </Flex>
    );
  };
  const renderFeatureGroups = () => {
    let groupNodes = _.get(data, "product.featureGroups.nodes");
    if (state.search_term) {
      const results = new Fuse(_.get(data, "product.featureGroups.nodes"), {
        keys: ["name"]
      }).search(state.search_term);
      groupNodes = _.map(results, i => i.item);
    }
    return (
      <StyledCollapse
        bordered={false}
        accordion={true}
        style={{ marginTop: "21px" }}
      >
        {_.map(groupNodes, (featureGroup: featureGroupType) => {
          const groupChecked = isChecked({
            id: featureGroup.id,
            type: "group"
          });

          const numberOfFeaturesChecked = featureGroup.features.nodes.filter(
            item => isChecked({ id: item.id, type: "feature" })
          );
          const numberOfChecked = groupChecked
            ? featureGroup.features.nodes.length
            : numberOfFeaturesChecked.length;
          return (
            <Panel
              style={{
                //flex: 1
                width: "340px"
              }}
              header={
                <ToggleActivation
                  type={"group"}
                  id={featureGroup.id}
                  name={`${featureGroup.name} (${numberOfChecked}/${featureGroup.features.nodes.length})`}
                  enabled={isChecked({
                    id: featureGroup.id,
                    type: "group"
                  })}
                  //onReportChange={reportChange}
                  onReportChange={() =>
                    reportChange("group", featureGroup.id, featureGroup.name)
                  }
                  //retry={payload.rowData.retry}
                />
              }
              key={featureGroup.id}
              className={"featureGroup"}
            >
              <div
                style={{
                  paddingLeft: "23px",
                  display: "flex",
                  flexDirection: "column",
                  width: "260px"
                }}
              >
                {_.map(featureGroup.features.nodes, feature => {
                  return (
                    <ToggleActivation
                      type={"feature"}
                      id={feature.id}
                      name={feature.name}
                      enabled={
                        groupChecked ||
                        isChecked({ id: feature.id, type: "feature" })
                      }
                      onReportChange={reportChange}
                      // disabled={!!groupChecked}
                      //retry={payload.rowData.retry}
                    />
                  );
                })}
              </div>
            </Panel>
          );
        })}
      </StyledCollapse>
    );
  };
  const getFeaturesWithoutGroups = () => {
    // const productFeatureGroups = _.get(
    //   this.props.license,
    //   'product.featureGroups.nodes'
    // ) as featureGroups;
    const productFeatures = _.get(data, "product.features.nodes") as features;
    let featuresWithoutGroups = _.filter(
      productFeatures,
      feature => !feature.featureGroupsCount
    );
    if (state.search_term) {
      const results = new Fuse(featuresWithoutGroups, {
        keys: ["name"]
      }).search(state.search_term);
      featuresWithoutGroups = _.map(results, i => i.item);
    }
    return featuresWithoutGroups;
  };
  const updateSearchTerm = (search_term: string) => {
    setState({ ...state, search_term });
  };
  const renderFields = () => {
    const productFeatures = _.get(data, "product.features.nodes") || [];
    const featureGroups = _.get(data, "product.featureGroups.nodes") || [];

    if (!productFeatures?.length && !featureGroups?.length) {
      return (
        <FeatureLabel>
          There are no features or feature groups for this product.
        </FeatureLabel>
      );
    }
    return (
      <>
        {renderFeatureGroups()}
        {renderFeatures()}
      </>
    );
  };
  return (
    <Flex
      flexDirection={"column"}
      flex={1}
      style={{ overflowY: "scroll", maxHeight: "100%" }}
    >
      <SearchBar className="elm-table-search-bar" onSearch={updateSearchTerm} />
      {renderFields()}
    </Flex>
  );
}

export function ComponentsAndGroupsToggle({
  components,
  componentGroup,
  onChange,
  productId
}: IComponentsAndGroupsProps) {
  const [state, setState] = useState<IComponentsAndGroupsState>({
    search_term: "",
    components: components || [],
    componentGroup: componentGroup || []
  });

  const data = useComponentsAndFeaturesData({
    productId
  });

  const reportChange = (type: ComponentOrGroup, id: string, name: string) => (
    value: boolean
  ) => {
    let components = [...state.components];
    let componentGroup = [...state.componentGroup];
    if (value) {
      type === "component" && components.push({ id, name });
      if (type === "group") {
        componentGroup.push({ id, name });
      }
    }
    if (!value) {
      if (type === "component")
        components = _.filter(components, item => item.id !== id);
      if (type === "group")
        componentGroup = _.filter(componentGroup, item => item.id !== id);
    }
    setState({ ...state, components, componentGroup });
    if (_.isFunction(onChange)) {
      onChange({
        type,
        components,
        componentGroup: componentGroup
      });
    }
  };
  const isChecked = (payload: { id: string; type: ComponentOrGroup }) => {
    if (payload.type === "component") {
      const filtered = _.filter(state.components, n => n.id === payload.id);

      return !!filtered.length;
    }
    if (payload.type === "group") {
      const filtered = _.filter(state.componentGroup, n => n.id === payload.id);

      return !!filtered.length;
    }
    return false;
  };

  const renderComponents = () => {
    const componentsWithoutGroups = _.map(
      getComponentsWithoutGroups(),
      (component: componentType) => (
        <ToggleActivation
          type={"component"}
          id={component.id}
          name={component.name}
          enabled={isChecked({ id: component.id, type: "component" })}
          onReportChange={reportChange}
        />
      )
    );
    return (
      <Flex
        style={{
          flexDirection: "column",
          flex: 1,
          minHeight: "max-content"
        }}
      >
        {componentsWithoutGroups?.length ? (
          <FeatureLabel>
            Other components available in this product.
          </FeatureLabel>
        ) : null}
        <div
          style={{
            minHeight: "max-content",
            width: "340px",
            position: "relative",
            maxHeight: "100%"
          }}
        >
          {componentsWithoutGroups}
        </div>
      </Flex>
    );
  };
  const renderComponentGroups = () => {
    let groupNodes = _.get(data, "product.componentGroups.nodes");
    if (state.search_term) {
      const results = new Fuse(_.get(data, "product.componentGroups.nodes"), {
        keys: ["name"]
      }).search(state.search_term);
      groupNodes = _.map(results, i => i.item);
    }
    return (
      <StyledCollapse
        bordered={false}
        accordion={true}
        style={{ marginTop: "21px" }}
      >
        {_.map(groupNodes, (group: componentGroupType) => {
          const groupChecked = isChecked({
            id: group.id,
            type: "group"
          });

          const numberOfComponentsChecked = group.components.nodes.filter(
            item => isChecked({ id: item.id, type: "component" })
          );
          const numberOfChecked = groupChecked
            ? group.components.nodes.length
            : numberOfComponentsChecked.length;
          return (
            <Panel
              style={{ width: "340px" }}
              header={
                <ToggleActivation
                  type={"group"}
                  id={group.id}
                  name={`${group.name} (${numberOfChecked}/${group.components.nodes.length})`}
                  enabled={isChecked({
                    id: group.id,
                    type: "group"
                  })}
                  onReportChange={() =>
                    reportChange("group", group.id, group.name)
                  }
                />
              }
              key={group.id}
              className={"componentGroup"}
            >
              <div
                style={{
                  paddingLeft: "23px",
                  display: "flex",
                  flexDirection: "column",
                  width: "260px"
                }}
              >
                {_.map(group.components.nodes, component => (
                  <ToggleActivation
                    type={"component"}
                    id={component.id}
                    name={component.name}
                    enabled={
                      groupChecked ||
                      isChecked({ id: component.id, type: "component" })
                    }
                    onReportChange={reportChange}
                  />
                ))}
              </div>
            </Panel>
          );
        })}
      </StyledCollapse>
    );
  };
  const getComponentsWithoutGroups = () => {
    const productComponents = _.get(
      data,
      "product.components.nodes"
    ) as components;
    let componentsWithoutGroups = _.filter(
      productComponents,
      feature => !feature.componentGroup
    );
    if (state.search_term) {
      const results = new Fuse(componentsWithoutGroups, {
        keys: ["name"]
      }).search(state.search_term);
      componentsWithoutGroups = _.map(results, i => i.item);
    }
    return componentsWithoutGroups;
  };
  const updateSearchTerm = (search_term: string) => {
    setState({ ...state, search_term });
  };
  const renderFields = () => {
    const productComponents = _.get(data, "product.components.nodes") || [];
    const componentGroups = _.get(data, "product.componentGroups.nodes") || [];

    if (!productComponents?.length && !componentGroups?.length) {
      return (
        <FeatureLabel>
          There are no components or component groups for this product.
        </FeatureLabel>
      );
    }
    return (
      <>
        {renderComponentGroups()}
        {renderComponents()}
      </>
    );
  };
  return (
    <Flex
      flexDirection={"column"}
      flex={1}
      style={{ overflowY: "scroll", maxHeight: "100%" }}
    >
      <SearchBar className="elm-table-search-bar" onSearch={updateSearchTerm} />
      {renderFields()}
    </Flex>
  );
}

// const RenderQueryRenderer = (props: {
//   id?: string;
//   productId?: string;
//   theme: any;
//   features: { id: string; name:string }[];
//   onChange?: (payload: {
//     type: "feature" | "group";
//     id: string;
//     name: string;
//     value: boolean;
//     features: { id: string; name:string }[];
//     updateQuery: () => void;
//   }) => void;
// }) => {
//   const renderFeatures = (payload: {
//     props: featuresAndGroupsToggleQueryResponse;
//     retry: () => void;
//   }) => {
//     return (
//       <FeaturesAndGroupsToggle
//         license={_.get(payload.props, "license")}
//         features={_.get(props, "features", [])}
//         onChange={props.onChange}
//         retry={payload.retry}
//         theme={props.theme}
//       />
//     );
//   };
//   return (
//     <QueryRenderer<featuresAndGroupsToggleQuery>
//       variables={{
//         licenseId: _.get(props, "id", ""),
//         productId: _.get(props, "productId", ""),
//         skipLicense: !props.id,
//         skipProduct: !props.productId
//       }}
//       query={graphqlQuery}
//       render={renderFeatures}
//       environment={relayEnvironment}
//     />
//   );
// };

// export default function RenderQueryRendererComponent(props) {
//   const theme = useTheme();

//   return <RenderQueryRenderer {...props} theme={theme} />;
// }
