import { SelectProps } from "antd/lib/select";
import * as _ from "lodash";
import * as React from "react";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../components/elmSelect";
import { productSelectorQueryResponse } from "./__generated__/productSelectorQuery.graphql";
import { getClassName } from "../../../components/helpers";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";

export type productType = productSelectorQueryResponse["products"]["nodes"][number];
export interface IProductSelectorProps extends SelectProps<any> {
  onChange?: (product: productType) => void;
  style?: React.CSSProperties;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  className?: string;
  label?: string;
}

const query = graphql`
  query productSelectorComponentQuery(
    $sortColumn: [String!]
    $sortDirection: [String!]
  ) {
    products(sortColumn: $sortColumn, sortDirection: $sortDirection) {
      nodes {
        name
        id
        type {
          id
          name
        }
      }
    }
  }
`;

const ProductSelectorComponent = (props: IProductSelectorProps) => {
  const data = useLazyLoadQuery(query, {});
  const getproducts = () => {
    const nodes = _.get(data, "products.nodes", []);
    return _.map(nodes, node => {
      return (
        <ElmSelectOption key={node.id} value={node.id} label={node.name}>
          {node.name}
        </ElmSelectOption>
      );
    });
  };
  const handleChange = (productId: string) => {
    if (_.isFunction(props.onChange)) {
      const product = _.find(
        data?.products?.nodes,
        node => node.id === productId
      );
      props.onChange(product);
    }
  };

  return (
    <ElmSelectWithLabel
      {...props}
      defaultValue={props.defaultValue}
      className={getClassName({
        "elm-product-selector": () => true
        //[props.className]: () => !!props.className
      })}
      // @ts-ignore
      onChange={handleChange}
    >
      {getproducts()}
    </ElmSelectWithLabel>
  );
};

export default ProductSelectorComponent;
