/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type productsFilterQueryVariables = {};
export type productsFilterQueryResponse = {
  readonly allProductsFilter: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly id: string;
    } | null> | null;
  } | null;
};
export type productsFilterQuery = {
  readonly response: productsFilterQueryResponse;
  readonly variables: productsFilterQueryVariables;
};

/*
query productsFilterQuery {
  allProductsFilter: products(sortColumn: ["product.name"], sortDirection: ["asc"]) {
    nodes {
      name
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
    {
      alias: "allProductsFilter",
      args: [
        {
          kind: "Literal",
          name: "sortColumn",
          value: ["product.name"]
        },
        {
          kind: "Literal",
          name: "sortDirection",
          value: ["asc"]
        }
      ],
      concreteType: "ProductConnection",
      kind: "LinkedField",
      name: "products",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Product",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "name",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: 'products(sortColumn:["product.name"],sortDirection:["asc"])'
    } as any
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "productsFilterQuery",
      selections: v0 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "productsFilterQuery",
      selections: v0 /*: any*/
    },
    params: {
      cacheID: "c41d6710fd5a987cc97aac55b7477f77",
      id: null,
      metadata: {},
      name: "productsFilterQuery",
      operationKind: "query",
      text:
        'query productsFilterQuery {\n  allProductsFilter: products(sortColumn: ["product.name"], sortDirection: ["asc"]) {\n    nodes {\n      name\n      id\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "f1acc773a28e674cb55e43ec2b306115";
export default node;
