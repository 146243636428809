/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseChangeLogTable_licenseActions = {
  readonly licenseActions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: string | null;
        readonly createdAt: unknown;
        readonly vendorSystemUser: {
          readonly name: string | null;
          readonly email: string | null;
        } | null;
        readonly id: string;
        readonly requireApproval: boolean;
        readonly status: number;
        readonly parameters: unknown | null;
        readonly comment: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $refType": "licenseChangeLogTable_licenseActions";
};
export type licenseChangeLogTable_licenseActions$data = licenseChangeLogTable_licenseActions;
export type licenseChangeLogTable_licenseActions$key = {
  readonly " $data"?: licenseChangeLogTable_licenseActions$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<
    "licenseChangeLogTable_licenseActions"
  >;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "blade_scope"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterOp"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    },
    {
      defaultValue: ["createdAt"],
      kind: "LocalArgument",
      name: "sortColumn"
    },
    {
      defaultValue: ["desc"],
      kind: "LocalArgument",
      name: "sortDirection"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: "cursor",
        direction: "forward",
        path: ["licenseActions"]
      }
    ]
  },
  name: "licenseChangeLogTable_licenseActions",
  selections: [
    {
      alias: "licenseActions",
      args: [
        {
          kind: "Variable",
          name: "bladeScope",
          variableName: "blade_scope"
        },
        {
          kind: "Variable",
          name: "filterColumn",
          variableName: "filterColumn"
        },
        {
          kind: "Variable",
          name: "filterOp",
          variableName: "filterOp"
        },
        {
          kind: "Variable",
          name: "filterValues",
          variableName: "filterValues"
        },
        {
          kind: "Variable",
          name: "search",
          variableName: "search"
        },
        {
          kind: "Variable",
          name: "sortColumn",
          variableName: "sortColumn"
        },
        {
          kind: "Variable",
          name: "sortDirection",
          variableName: "sortDirection"
        }
      ],
      concreteType: "LicenseActionConnection",
      kind: "LinkedField",
      name: "__licenseChangeLogTable_licenseActions_connection",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "LicenseActionEdge",
          kind: "LinkedField",
          name: "edges",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "LicenseAction",
              kind: "LinkedField",
              name: "node",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "description",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "createdAt",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "VendorSystemUser",
                  kind: "LinkedField",
                  name: "vendorSystemUser",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "name",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "email",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "id",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "requireApproval",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "status",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "parameters",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "comment",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "__typename",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "cursor",
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: null,
          concreteType: "PageInfo",
          kind: "LinkedField",
          name: "pageInfo",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "endCursor",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "hasNextPage",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "a8a7f91ec0b989ef112f040496e64197";
export default node;
