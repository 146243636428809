import * as React from "react";
import styled from "styled-components";

type pProps = React.HTMLProps<HTMLParagraphElement>;
export interface IElmLabelProps {
  label: string;
  uppercase?: boolean;
}

const StyledLabel = styled.span<IElmLabelProps>`
  opacity: 0.7;
  font-size: ${props => props.theme.fontSizes.xxSmall};
  font-family: ${props => props.theme.font.main};
  color: ${props => props.theme.colors.warmGrey};
  margin-top: 3px;
  //margin-bottom: 10px;
  text-transform: ${props => (props.uppercase ? "uppercase" : "inherit")};
`;

export const ElmLabel = (props: IElmLabelProps) => (
  <StyledLabel {...props}>{props.label}</StyledLabel>
);
export default ElmLabel;
