import * as deviseActions from "./deviseAuth/actions";
import { actions as bladeManagerActions } from "./ducks/bladeManager";
import { actions as appActions } from "./ducks/app";
import { actions as analyticsActions } from "./ducks/analytics";

const actions = {
  appActions,
  bladeManagerActions,
  deviseActions,
  analyticsActions
};

export default actions;
