/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseHistoryTableQueryVariables = {
  id: string;
};
export type licenseHistoryTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"licenseHistoryTable_terms">;
};
export type licenseHistoryTableQuery = {
  readonly response: licenseHistoryTableQueryResponse;
  readonly variables: licenseHistoryTableQueryVariables;
};

/*
query licenseHistoryTableQuery(
  $id: ID!
) {
  ...licenseHistoryTable_terms_1Bmzm5
}

fragment licenseHistoryTable_terms_1Bmzm5 on Query {
  license(id: $id) {
    terms(first: 25) {
      edges {
        node {
          startDate
          endDate
          createdAt
          isActive
          value
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = [
      {
        kind: "Literal",
        name: "first",
        value: 25
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "licenseHistoryTableQuery",
      selections: [
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "licenseHistoryTable_terms"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "licenseHistoryTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            {
              alias: null,
              args: v2 /*: any*/,
              concreteType: "TermConnection",
              kind: "LinkedField",
              name: "terms",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "TermEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Term",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "startDate",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "endDate",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "createdAt",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "isActive",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "value",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: "terms(first:25)"
            },
            {
              alias: null,
              args: v2 /*: any*/,
              filters: null,
              handle: "connection",
              key: "LicenseHistoryTable_terms",
              kind: "LinkedHandle",
              name: "terms"
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "7be8034c8a0ab8656b12bee2b351a976",
      id: null,
      metadata: {},
      name: "licenseHistoryTableQuery",
      operationKind: "query",
      text:
        "query licenseHistoryTableQuery(\n  $id: ID!\n) {\n  ...licenseHistoryTable_terms_1Bmzm5\n}\n\nfragment licenseHistoryTable_terms_1Bmzm5 on Query {\n  license(id: $id) {\n    terms(first: 25) {\n      edges {\n        node {\n          startDate\n          endDate\n          createdAt\n          isActive\n          value\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "f855371cab1eea003fac07d554adaa55";
export default node;
