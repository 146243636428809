/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type editCompanyQueryVariables = {
  id: string;
};
export type editCompanyQueryResponse = {
  readonly company: {
    readonly logoUrl: string | null;
    readonly name: string | null;
    readonly contactPhone: string | null;
    readonly contactEmail: string | null;
    readonly contactName: string | null;
    readonly id: string;
    readonly location: {
      readonly city: string | null;
      readonly countryCode: string | null;
    } | null;
  } | null;
};
export type editCompanyQuery = {
  readonly response: editCompanyQueryResponse;
  readonly variables: editCompanyQueryVariables;
};

/*
query editCompanyQuery(
  $id: ID!
) {
  company(id: $id) {
    logoUrl
    name
    contactPhone
    contactEmail
    contactName
    id
    location {
      city
      countryCode
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "logoUrl",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "contactPhone",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "contactEmail",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "contactName",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "city",
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "countryCode",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "editCompanyQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Company",
          kind: "LinkedField",
          name: "company",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Location",
              kind: "LinkedField",
              name: "location",
              plural: false,
              selections: [v8 /*: any*/, v9 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "editCompanyQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Company",
          kind: "LinkedField",
          name: "company",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Location",
              kind: "LinkedField",
              name: "location",
              plural: false,
              selections: [v8 /*: any*/, v9 /*: any*/, v7 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "f24654b3de3a528170251c5bf721e05f",
      id: null,
      metadata: {},
      name: "editCompanyQuery",
      operationKind: "query",
      text:
        "query editCompanyQuery(\n  $id: ID!\n) {\n  company(id: $id) {\n    logoUrl\n    name\n    contactPhone\n    contactEmail\n    contactName\n    id\n    location {\n      city\n      countryCode\n      id\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "3faf7e782c1f2f140f5f3fb70eb3233a";
export default node;
