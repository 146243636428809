import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../api/relay";
import { appConnect } from "../../store/appConnect";
import {
  WidgetContainer,
  getWidgetContainer
} from "../../components/analyticsComponent/widgetContainer";
import {
  licensesAnalyticsQuery,
  licensesAnalyticsQueryResponse
} from "./__generated__/licensesAnalyticsQuery.graphql";
import {
  licensesAnalyticsRegistrationQuery,
  licensesAnalyticsRegistrationQueryVariables
} from "./__generated__/licensesAnalyticsRegistrationQuery.graphql";
import _ from "lodash";
import { getAnimatedComponent } from "../../components/helpers";
import { getFriendlyLicenseName } from "../../utils";
import { topCounts } from "../../components/analyticsComponent/filters/topCount";
import { TabbedAnalyticsComponent } from "../../components/analyticsComponent/tabbedAnalytics";
import { queryType } from "../../components/analyticsComponent/";
import { sharedTabStyle } from "./shared";
import { DateRange } from "blades/Dashboard/utils";

const AnimatedContainer = getAnimatedComponent({
  element: "div",
  transitionName: "licenses-banner",
  animations: {
    appear: {
      duration: "1.5s",
      animationType: "slideInRight"
    },
    enter: {
      duration: "1s",
      animationType: "slideInRight"
    }
  }
});

const registrationsQuery = graphql`
  query licensesAnalyticsRegistrationQuery($count: Int, $productId: String!) {
    registrationEntries(first: $count, bladeScope: $productId) {
      nodes {
        user {
          displayName
        }
        license {
          product {
            name
          }
          owner {
            name
          }
        }
      }
    }
  }
`;
const RegistrationsWidget = getWidgetContainer<
  licensesAnalyticsRegistrationQuery,
  licensesAnalyticsRegistrationQueryVariables
>();
const graphqlQuery = graphql`
  query licensesAnalyticsQuery(
    $currentTimeSpanStart: String!
    $currentTimeSpanEnd: String!
    $productId: String!
  ) {
    LicensesAnalyticsCurrentExpiringLicenses: licenses(
      filterColumn: ["license.latestTerm.endDate", "license.latestTerm.endDate"]
      filterOp: [[">", "="], ["<", "="]]
      filterValues: [
        [$currentTimeSpanStart, $currentTimeSpanStart]
        [$currentTimeSpanEnd, $currentTimeSpanEnd]
      ]
      bladeScope: $productId
    ) {
      nodes {
        type {
          name
          id
        }
      }
    }
  }
`;

export interface ILicensesAnalyticsProps {
  result: licensesAnalyticsQueryResponse;
  retry: () => void;
  timespan: "week" | "month" | "day";
  productId: string;
  variables: {
    timeRangeOverride?: DateRange;
    currentTimeSpanStart: string;
    currentTimeSpanEnd: string;
    previousTimeSpanStart: string;
    previousTimeSpanEnd: string;
    productId: string | null;
  };
}

type state = {
  registrationCount: number;
};
export class LicensesAnalytics extends React.Component<
  ILicensesAnalyticsProps,
  state
> {
  state: state = {
    registrationCount: 5
  };
  public renderLicensesInfoBanner = () => {
    if (
      !this.props.result ||
      !this.props.result.LicensesAnalyticsCurrentExpiringLicenses
    ) {
      return null;
    }
    const getActiveLicensesByType = () => {
      if (
        !this.props.result ||
        !this.props.result.LicensesAnalyticsCurrentExpiringLicenses
      ) {
        return null;
      }
      const types = _.groupBy(
        this.props.result.LicensesAnalyticsCurrentExpiringLicenses.nodes,
        node => node.type.name
      );
      const typeNames = _.keys(types);
      return _.map(typeNames, typeName => {
        return (
          <div>
            <span className="expiring-value">{types[typeName].length}</span>
            <span className="expiring-label">
              {_.startCase(getFriendlyLicenseName(typeName))}
            </span>
          </div>
        );
      });
    };
    const navigateToLicenses = () => {
      // this.props.openBlade({
      //   route: 'Licensess',
      //   routeName: 'Licensess',
      //   routeData: null,
      //   fromBladeIndex: this.props.index,
      //   navigationType: 'reset',
      // });
    };
    return (
      <AnimatedContainer>
        {/* <div className="banner" onClick={navigateToLicenses}>
          <div className="accent" />
          <div className="message-container">
            <span className="current-expiring-value">
              {this.props.result.LicensesAnalyticsCurrentExpiringLicenses}
            </span>
            <span className="current-expiring-label">{`License${
              this.props.result.LicensesAnalyticsCurrentExpiringLicenses.nodes
                .length > 1
                ? "s"
                : ""
            } expiring this ${this.props.timespan}`}</span>
            <div className="extra-expiring-container">
              {getActiveLicensesByType()}
            </div>
          </div>
        </div> */}
      </AnimatedContainer>
    );
  };
  public getHighlightAnalytics = () => {
    return (
      <TabbedAnalyticsComponent
        //style={{ height: "417px" }}
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "New licenses",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              analytics: this.getSessionCountAnalyticsSetup(),
              type: "line",
              allowVendorAnalytics: true
            }
          },
          {
            tabProps: {
              title: "Release upgrades",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              analytics: this.getSessionDurationAnalyticsSetup(),
              type: "line",
              allowVendorAnalytics: true
            }
          }
        ]}
      />
    );
  };
  public getSessionDurationAnalyticsSetup = () => {
    return {
      bladeScope: this.props.productId,
      valueTitle: "Session Duration",
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_duration",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd,
      options: {
        chartMaxHeight: 400
      }
    } as queryType;
  };
  public getSessionCountAnalyticsSetup = () => {
    return {
      bladeScope: this.props.productId,
      valueTitle: "New licenses",
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_count",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd,
      options: {
        chartMaxHeight: 400
      }
    } as queryType;
  };

  public updateTopCount = (countType: "userRegistrationsCount") => (
    payload: topCounts
  ) => {
    this.setState({ registrationCount: parseInt(payload, 10) });
  };
  public render() {
    return (
      <div className="licenses-analytics analytics-container">
        {this.renderLicensesInfoBanner()}
        <WidgetContainer size="x-large">
          {this.getHighlightAnalytics()}
        </WidgetContainer>
      </div>
    );
  }
}

const RenderQuery = (props: ILicensesAnalyticsProps) => {
  const renderBlade = (payload: {
    error: Error;
    props: licensesAnalyticsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <LicensesAnalytics
        {...props}
        variables={props.variables}
        result={payload.props}
        retry={payload.retry}
        // updateVariables={updateVariables}
      />
    );
  };
  return (
    <QueryRenderer<licensesAnalyticsQuery>
      environment={relayEnvironment}
      variables={props.variables}
      query={graphqlQuery}
      render={renderBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
