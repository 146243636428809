/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type companyAttributeQueryVariables = {
  id: string;
};
export type companyAttributeQueryResponse = {
  readonly company: {
    readonly name: string | null;
    readonly contactEmail: string | null;
    readonly contactName: string | null;
    readonly contactPhone: string | null;
    readonly location: {
      readonly city: string | null;
      readonly countryCode: string | null;
    } | null;
  } | null;
};
export type companyAttributeQuery = {
  readonly response: companyAttributeQueryResponse;
  readonly variables: companyAttributeQueryVariables;
};

/*
query companyAttributeQuery(
  $id: ID!
) {
  company(id: $id) {
    name
    contactEmail
    contactName
    contactPhone
    location {
      city
      countryCode
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "contactEmail",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "contactName",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "contactPhone",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "city",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "countryCode",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "companyAttributeQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Company",
          kind: "LinkedField",
          name: "company",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Location",
              kind: "LinkedField",
              name: "location",
              plural: false,
              selections: [v6 /*: any*/, v7 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "companyAttributeQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Company",
          kind: "LinkedField",
          name: "company",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Location",
              kind: "LinkedField",
              name: "location",
              plural: false,
              selections: [v6 /*: any*/, v7 /*: any*/, v8 /*: any*/],
              storageKey: null
            },
            v8 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "b61ab9c542efffed8cca39a6902057e5",
      id: null,
      metadata: {},
      name: "companyAttributeQuery",
      operationKind: "query",
      text:
        "query companyAttributeQuery(\n  $id: ID!\n) {\n  company(id: $id) {\n    name\n    contactEmail\n    contactName\n    contactPhone\n    location {\n      city\n      countryCode\n      id\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "4f3fc3a3e575e4755f41d75fa6537284";
export default node;
