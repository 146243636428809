import styled from "styled-components";
import { getFaIcon } from "../../components/icons";

export const ApproveLicenseIcon = styled(
  getFaIcon({ iconName: "lightbulb", prefix: "far" })
)(props => ({
  color: props.theme.colors.white,
  height: "12px",
  width: "12px"
}));
export const TermLicenseIcon = styled(
  getFaIcon({ iconName: "info", prefix: "far" })
)(props => ({
  color: props.theme.colors.white,
  height: "12px",
  width: "12px"
}));
export const DeactivateLicenseIcon = styled(
  getFaIcon({ iconName: "triangle-exclamation", prefix: "far" })
)(props => ({
  color: props.theme.colors.white,
  height: "12px",
  width: "12px"
}));

export const ApprovedLicenseIcon = styled(
  getFaIcon({ iconName: "check", prefix: "far" })
)(props => ({
  color: props.theme.colors.white,
  height: "12px",
  width: "12px"
}));

export const ActionDescription = styled.span(props => ({
  fontSize: props.theme.fontSizes.small,
  color: props.theme.colors.black,
  lineHeight: "24px",
  fontWeight: props.theme.fontWeight.heavy
}));

export const ApprovalSubheader = styled.span(props => ({
  color: props.theme.colors.warmGrey,
  fontSize: props.theme.fontSizes.xSmall,
  display: "block"
}));

export const ActionComment = styled.span(props => ({
  fontSize: props.theme.fontSizes.xSmall,
  color: props.theme.colors.black,
  lineHeight: "18px",
  fontWeight: props.theme.fontWeight.normal
}));

export const ActionCreatedAt = styled.span(props => ({
  fontSize: props.theme.fontSizes.xSmall,
  color: props.theme.colors.warmGrey,
  lineHeight: "18px",
  fontWeight: props.theme.fontWeight.normal,
  letterSpacing: 0.16
}));

export const CircleWrap = styled.div<{ color?: string }>(props => ({
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: props.color,
  marginTop: "2px"
}));

export const licenseActionsColor = {
  create_license: "#3F68F6",
  approve_action: "#3F68F6",
  create_term: "#BEBDBC",
  modify_entitlement: "#3F68F6",
  modify_license: "#3F68F6",
  activate_license: "#3F68F6",
  deactivate_license: "#FFA033",
  approved: "#4DBF40"
};

export const CallToAction = styled.span(props => ({
  fontSize: props.theme.fontSizes.xSmall,
  color: props?.color || props.theme.colors.lightBlue,
  lineHeight: "18px",
  cursor: "pointer",
  fontWeight: props.theme.fontWeight.normal,
  letterSpacing: 0.16,
  marginLeft: 10
}));
