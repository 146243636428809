import dayjs from "dayjs";

export const REPORT_TYPE = {
  ComponentUsage: 1,
  SessionUsage: 2,
  ConcurrentSession: 3,
  MaximumUsageReport: 4
} as const;

type ObjectValues<T> = T[keyof T];

export type ReportType = ObjectValues<typeof REPORT_TYPE>;

export enum MaximumUsageReportDataType {
  RawData = "rawData",
  Consolidated = "consolidated"
}

export interface TimeRangeOption {
  key: string;
  label: string;
  value?: number;
}

export enum AggregateField {
  Company = "Company",
  User = "User"
}

export interface FilterOptions {
  product_id?: number[] | string[];
  company_id?: number[] | string[];
  component_id?: number[] | string[];
  start_time: dayjs.Dayjs;
  end_time?: dayjs.Dayjs;
  duration: number;
}

interface ReportOptions {
  filters?: FilterOptions;
  columns?: string[];
  include_charts?: boolean;
  raw_data?: boolean;
  minimum_session_length?: number;
  aggregate_field?: AggregateField;
}

export interface Report {
  name: string;
  report_type: ReportType;
  options: ReportOptions;
  is_private: boolean;
  reporting_interval?: number;
  start_time: dayjs.Dayjs;
  end_time: dayjs.Dayjs;
  duration: number;
}
