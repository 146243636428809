import React, { Suspense } from "react";
import _, { result } from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import styled, { useTheme } from "styled-components";
import { appConnect, appDispatch } from "../../../store/appConnect";
import { getEnvironment } from "../../../api/relay";
import BladeTemplate from "../../../components/bladeManager/bladeTemplate";
import {
  IBladeBaseProps,
  IOpenNewEntitlementBlade
} from "../../../components/bladeManager/types";
import { BladeTitle, ElmButton, getIcon } from "../../../components";
import { ElmDatePickerWithLabel } from "../../../components/elmDate";
import ElmInput from "../../../components/elmInput";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../components/elmSelect";
import ElmRadio from "../../../components/elmRadio";
import { ElmToggle } from "../../../components/elmToggle";
import { ActiveBadge } from "../../../components/helpers";
import { componentTypeToId } from "../../../utils";
import { SearchBar } from "../../../components/helpers";
import {
  NewEntitlementQuery,
  NewEntitlementQueryResponse
} from "./__generated__/NewEntitlementQuery.graphql";
import Radio, { RadioChangeEvent } from "antd/lib/radio";
import { AutoSizer } from "react-virtualized";
import { Tooltip } from "antd";
import IndividualComponentSelection from "./components/IndividualComponentSelection";
import GroupSelection from "./components/GroupSelection";
import dayjs, { Dayjs } from "dayjs";
import { DATE_FORMAT, DATE_FORMAT_ALT } from "const";

const SectionTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  /* Black */

  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 12px;
`;
const groupSelectionQuery = graphql`
  query NewEntitlementQuery($id: ID!, $entitlementId: ID!) {
    product(id: $id) {
      name
      components {
        nodes {
          name
          componentType
          id
        }
      }
      productGroups: componentGroups {
        nodes {
          name
          id
          components {
            nodes {
              name
              id
              componentType
            }
          }
        }
      }
    }
    componentEntitlement(id: $entitlementId) {
      tokenCount
      currencyCount
      sessionCount
      components {
        nodes {
          name
          componentType
          id
        }
      }
      componentGroup {
        name
        id
        components {
          nodes {
            name
            componentType
            id
          }
        }
      }
      latestTerm {
        endDate
        startDate
      }
    }
  }
`;

const dataNewEntitlement = graphql`
  query NewEntitlementCreateQuery($id: ID!) {
    product(id: $id) {
      name
      components {
        nodes {
          name
          componentType
          id
        }
      }
      productGroups: componentGroups {
        nodes {
          name
          id
          components {
            nodes {
              name
              id
              componentType
            }
          }
        }
      }
    }
    # componentEntitlement(id: $entitlementId) {
    #   tokenCount
    #   currencyCount
    #   sessionCount
    #   components {
    #     nodes {
    #       name
    #       componentType
    #       id
    #     }
    #   }
    #   componentGroup {
    #     name
    #     id
    #     components {
    #       nodes {
    #         name
    #         componentType
    #         id
    #       }
    #     }
    #   }
    #   latestTerm {
    #     endDate
    #     startDate
    #   }
    # }
  }
`;

const BladeDescription = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Greyish */

  color: ${props => props.theme.colors.warmGrey};
  margin-top: 9.5px;
  margin-bottom: 23px;
`;
export interface IEntitlementBladeProps
  extends IBladeBaseProps<IOpenNewEntitlementBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}
type state = {
  type: "componentGroup" | "component";
  selections: { id: string; name?: string; componentType?: string }[];
  entitlementModel: number;
  entitlementModelType: "Checked-out Token" | "consumable" | "session";
  renewEvery: number;
  renew: "one_time" | "renew"; //
  renewType: "day" | "week" | "month" | "year";
  startType: string; // false - specific date

  overageType: "%" | "count";
  searchQuery: string;
  disabled: boolean;
  error: boolean;
  count: number;
  entitlement: {
    name: string;
    end_date: string;
    start_date: string;
    components: string[];
    overage_allowed_count: number;
    overage_allowed_percentage: number;
    renewal_period_count: number;
    renewal_period_type: number;
    session_count: number;
  };
  usageType: "unlimited" | "limited";
  termType: "unlimited" | "limited";
  individualComponentNames: string[];
  sourceSelectedKeys: string[];
  isEditMode: boolean;
};
export class NewEntitlementBlade extends React.Component<
  IEntitlementBladeProps & {
    result?: NewEntitlementQueryResponse;
    theme?: any;
  },
  state
> {
  entitlement = this.props?.result?.componentEntitlement;
  //({} as NewEntitlementEditQueryResponse["componentEntitlement"]);
  // selections = (this.entitlement?.components?.nodes.length
  //   ? this.entitlement.components.nodes
  //   : [this.entitlement.componentGroup]);
  selections = this.entitlement?.componentGroup
    ? [this.entitlement.componentGroup]
    : [];
  entitlementType =
    _.get(this.selections[0], "componentType") ||
    _.get(this.selections[0], "components.nodes[0].componentType");

  state: state = {
    disabled: false,
    error: false,
    type: this.entitlement?.components?.nodes.length
      ? "component"
      : "componentGroup",
    count: this.entitlement?.tokenCount || 0,
    selections:
      this.selections.length > 0
        ? this.selections.map((i: { id: any }) => {
            return { id: i.id };
          })
        : [],
    entitlement: this.entitlement
      ? {
          name: "",
          components: this.entitlement?.components?.nodes.map(i => i.id) || [],
          overage_allowed_count: 0,
          overage_allowed_percentage: 0,
          renewal_period_count: 0,
          renewal_period_type: 0,
          session_count: 0,
          end_date: this.entitlement?.latestTerm?.endDate
            ? (this.entitlement.latestTerm.endDate as string)
            : "",
          start_date: this.entitlement?.latestTerm?.startDate
            ? (this.entitlement.latestTerm.startDate as string)
            : ""
        }
      : null,
    entitlementModel: 0,
    entitlementModelType: this.entitlementType || "consumable",
    renewEvery: 1,
    renew: "renew",
    renewType: "month",
    overageType: "count",
    searchQuery: "",
    usageType:
      this.entitlement?.tokenCount || this.entitlement?.currencyCount
        ? "limited"
        : "unlimited",
    termType: this.entitlement?.latestTerm?.endDate ? "limited" : "unlimited",
    individualComponentNames: [],
    sourceSelectedKeys: [],
    startType: this.entitlement?.latestTerm?.startDate
      ? "specific_date"
      : "immediately",
    isEditMode: this.props.routeData.entitlementId ? true : false
  };

  public updateRequest = (key: keyof state["entitlement"]) => (val: any) => {
    const entitlement = {};
    entitlement[key] = val;
    this.setState({
      entitlement: _.merge(this.state.entitlement, entitlement)
    });
  };
  public createNewEntitlement = () => {
    const dataToSend = {
      license_id: this.props.routeData.licenseId,
      id: this.props.routeData.entitlementId,
      entitlement: {
        //...this.state.entitlement,
        allow_unlimited_usage: this.state.usageType === "unlimited",
        start_date:
          this.state.termType === "unlimited"
            ? null
            : this.state.entitlement.start_date,
        end_date:
          this.state.termType === "unlimited"
            ? null
            : this.state.entitlement.end_date,
        license_id: this.props.routeData.licenseId,
        currency_count:
          this.state.usageType === "unlimited"
            ? null
            : this.state.entitlementModelType === "session"
            ? this.state.count
            : null,
        token_count:
          this.state.usageType === "unlimited"
            ? null
            : this.state.entitlementModelType !== "session"
            ? this.state.count
            : null,
        //session_count: null,
        component_group_id:
          this.state.type === "componentGroup"
            ? this.state.selections[0].id
            : null,
        component_ids:
          this.state.type === "component"
            ? _.map(this.state.selections, s => s.id)
            : null,
        overage_allowed_count: 0,
        overage_allowed_percentage: 0
      }
    };

    this.setState(
      {
        disabled: true,
        error: false
      },
      () => {
        this.props.gateway.request
          .newComponentEntitlement(dataToSend)
          .then(res => {
            if (res.error) {
              this.setState({ disabled: false }, () => {
                this.props.openDialog("ErrorDialog", {
                  description: res.data?.error || res.data?.errors[0]
                });
                console.error(res.error);
              });
            } else {
              this.props.refreshAllOpenBlades();
              this.props.closeBlade({ fromBladeIndex: this.props.index });
            }
          })
          .catch(err => {
            console.error(err);
          });
      }
    );
  };

  public updateSelection = (payload: {
    id: string;
    name: string;
    checked: boolean;
  }) => {
    if (payload.checked) {
      const selections = [
        ...(this.state.type === "component" ? this.state.selections : []),
        { name: payload.name, id: payload.id }
      ];
      this.setState({
        selections
      });
    } else {
      const selections = _.filter(
        this.state.selections,
        f => f.id !== payload.id
      );
      this.setState({
        selections
      });
    }
  };
  public renderSelection = () => {
    const renderGroupSelection = () => {
      if (!this.props.result) {
        return null;
      }
      if (this.state.type === "component") {
        const onChange = (individualComponentNames: string[]) => {
          const components = _.filter(
            this.props.result.product.components.nodes,
            node => _.includes(individualComponentNames, node.name)
          );
          this.setState(
            { individualComponentNames, selections: components },
            () => {
              if (_.isArray(components) && components.length) {
                this.updateEntitlementModel(
                  componentTypeToId(components[0].componentType)
                );
              }
            }
          );
        };
        const onSelectChange = (sourceSelectedKeys: string[]) => {
          this.setState({
            ...this.state,
            sourceSelectedKeys: sourceSelectedKeys
          });
        };
        const selection = this.state.selections.map(i => i.id);
        return (
          <IndividualComponentSelection
            result={this.props.result}
            onChange={this.updateSelection}
            onSelectChange={onSelectChange}
            targetKeys={this.state.individualComponentNames}
            searchQuery={this.state.searchQuery}
            // sourceSelectedKeys={this.state.sourceSelectedKeys}
            sourceSelectedKeys={selection}
          />
        );
      }
      return (
        <GroupSelection
          {...this.props.result}
          type={this.state.type}
          defaultValue={this.state?.selections[0]?.id || ""}
          onChange={this.updateSelection}
          searchQuery={this.state.searchQuery}
        />
      );
    };
    return renderGroupSelection();
  };
  public renderLeftSideHeader = () => {
    return (
      <Flex>
        <BladeTitle>
          {this.state.isEditMode
            ? "Edit Entitlement"
            : "Create New Entitlement"}{" "}
        </BladeTitle>
      </Flex>
    );
  };
  public updateEntitlementModel = (entitlementModel: any) => {
    this.setState({ entitlementModel: parseInt(entitlementModel, 10) });
  };
  public updateRenewEvery = (renewEvery: number) => {
    this.setState({ renewEvery });
  };
  public updateQuery = (searchQuery: string) => {
    this.setState({ searchQuery });
  };
  public updateOverageType = (val: any) => {
    const overageType = val as state["overageType"];
    this.setState({ overageType });
  };
  public updateRenewType = (val: any) => {
    const renewType = val as state["renewType"];
    this.setState({ renewType });
  };
  public updateEntitlementType = (val: any) => {
    const entitlementModelType = val as state["entitlementModelType"];
    this.setState({ entitlementModelType });
  };
  public updateCount = (count: any) => {
    this.setState({ count });
  };

  public updateType = (e: RadioChangeEvent) => {
    this.setState({ type: e.target.value, selections: [] });
  };

  public renderControlBox = () => {
    return (
      <div style={{ marginTop: "14px", marginBottom: "20px" }}>
        <ElmRadio.Group value={this.state.type} onChange={this.updateType}>
          {_.map(
            [
              {
                text: "Component Group",
                key: "componentGroup"
              },
              {
                text: "Individual component",
                key: "component"
              }
            ],
            op => {
              return <ElmRadio value={op.key}>{op.text}</ElmRadio>;
            }
          )}
        </ElmRadio.Group>
      </div>
    );
  };
  public renderLimitedTermOptions = (result: any) => {
    if (this.state.termType === "unlimited") {
      return null;
    }
    const onChange = (type: "start_date" | "end_date") => (m: Dayjs) => {
      this.setState({
        entitlement: {
          ...(this.state?.entitlement || {}),
          [type]: m.format(DATE_FORMAT_ALT)
        }
      });
    };
    // let entitlementP = result?.componentEntitlement;
    // let endDate = entitlementP.latestTerm.endDate ? moment(entitlementP.latestTerm.endDate) : "";
    return (
      <Flex style={{ marginTop: "14px" }} flexDirection="column">
        <Flex style={{ marginBottom: "24px" }}>
          <Radio.Group
            value={this.state.startType}
            onChange={e => {
              this.setState({
                ...this.state,
                startType: e.target.value
              });
            }}
          >
            <Radio value={"immediately"}>Immediately</Radio>
            <Radio value={"specific_date"}>Specific date</Radio>
          </Radio.Group>
        </Flex>
        {this.state.startType === "specific_date" && (
          <Flex>
            <ElmDatePickerWithLabel
              label="Start date"
              style={{ width: "290px" }}
              format={DATE_FORMAT}
              onChange={onChange("start_date")}
              defaultValue={
                this.state?.entitlement?.start_date
                  ? dayjs(this.state.entitlement.start_date)
                  : dayjs()
              }
            />
          </Flex>
        )}
        <Flex>
          <ElmDatePickerWithLabel
            label="End date"
            defaultValue={
              this.state?.entitlement?.end_date
                ? dayjs(this.state.entitlement.end_date)
                : dayjs()
            }
            style={{ width: "290px" }}
            format={DATE_FORMAT}
            onChange={onChange("end_date")}
          />
        </Flex>
      </Flex>
    );
  };
  public renderLimitedUsageOptions = () => {
    if (this.state.usageType === "unlimited") {
      return null;
    }
    return (
      <Flex
        flexDirection="column"
        style={{ maxWidth: "290px", marginTop: "14px" }}
      >
        <Flex style={{ marginTop: "24px", marginBottom: "24px" }}>
          <ElmSelectWithLabel
            onChange={this.updateEntitlementType}
            //style={{ width: "100px" }}
            className="subtle"
            label="Entitlement model"
            value={this.state.entitlementModelType}
          >
            {_.map(
              [
                {
                  label: "Checked-out tokens",
                  value: "checked_out",
                  key: "checked_out"
                },
                {
                  label: "Consumable token",
                  value: "consumable",
                  key: "consumable"
                },
                {
                  label: "Session component",
                  value: "session",
                  key: "session"
                }
              ],
              op => (
                <ElmSelectOption value={op.key} label={op.label}>
                  {op.label}
                </ElmSelectOption>
              )
            )}
          </ElmSelectWithLabel>
        </Flex>
        <Flex>
          {(this.state.entitlementModelType === "Checked-out Token" ||
            this.state.entitlementModelType === "session") && (
            <ElmInput
              type={"number"}
              style={{ maxHeight: "32px", marginBottom: "17px" }}
              updatedValue={this.updateCount}
              label={"Count"}
              controlled
              defaultValue={this.state.count}
            />
          )}

          {this.state.entitlementModelType === "consumable" && (
            <Flex flexDirection={"column"}>
              <Flex style={{ marginBottom: "24px" }} flex="1">
                <Radio.Group
                  style={{ width: "100%" }}
                  value={this.state.renew}
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      renew: e.target.value
                    });
                  }}
                >
                  <Radio value={"one_time"}>One time</Radio>
                  <Radio value={"renew"}>Renew</Radio>
                </Radio.Group>
              </Flex>
              <Flex style={{ width: "290px" }}>
                {this.state.renew === "renew" && (
                  <>
                    <ElmInput
                      type={"number"}
                      controlled
                      defaultValue={this.state.renewEvery}
                      style={{
                        maxHeight: "32px",
                        marginBottom: "17px",
                        marginRight: "8px"
                      }}
                      updatedValue={value => this.updateRenewEvery(+value)}
                      label={"Renew every"}
                    />
                    <ElmSelectWithLabel
                      onChange={this.updateRenewType}
                      //style={{ width: "100px" }}
                      className="subtle"
                      label=" "
                      value={this.state.renewType}
                    >
                      {_.map(
                        [
                          {
                            label: "Day",
                            key: "day"
                          },
                          {
                            label: "Week",
                            key: "week"
                          },
                          {
                            label: "Month",
                            key: "month"
                          },
                          {
                            label: "Year",
                            key: "year"
                          }
                        ],
                        op => (
                          <ElmSelectOption value={op.key} label={op.label}>
                            {op.label}
                          </ElmSelectOption>
                        )
                      )}
                    </ElmSelectWithLabel>
                  </>
                )}
              </Flex>
              <Flex style={{ width: "290px" }}>
                <ElmInput
                  type={"number"}
                  label={"Overage"}
                  isDecimalNumber={true}
                  style={{ marginRight: "8px" }}
                  updatedValue={this.updateRequest(
                    this.state.overageType === "count"
                      ? "overage_allowed_count"
                      : "overage_allowed_percentage"
                  )}
                />
                <ElmSelectWithLabel
                  onChange={this.updateOverageType}
                  //style={{ width: "100px" }}
                  className="subtle"
                  label=" "
                  value={this.state.overageType}
                >
                  {_.map(
                    [
                      {
                        label: "Count",
                        key: "count"
                      },
                      {
                        label: "%",
                        key: "%"
                      }
                    ],
                    op => (
                      <ElmSelectOption value={op.key} label={op.label}>
                        {op.label}
                      </ElmSelectOption>
                    )
                  )}
                </ElmSelectWithLabel>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  };
  public updateTermAndUsageType = (type: "usageType" | "termType") => (
    state: boolean
  ) => {
    this.setState({ [type]: state ? "limited" : "unlimited" });
  };
  public closeBlade = () => {
    this.props.closeBlade({
      route: "NewEntitlement",
      fromBladeIndex: this.props.index
    });
  };
  public render() {
    const InfoIcon = styled(getIcon("AiFillInfoCircle"))`
      width: 14px;
      height: 14px;
      margin-right: 7px;
      margin-top: -10px;
      color: ${props => props.theme.colors.buttonIcon};
    `;
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        //topAccentColor={"mango"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        bladeTypeName="Entitlement"
        accentColor="mango"
        hideBladeTypeName={false}
        title={"Account"}
        closeBlade={this.props.closeBlade}
        bladeType="NewEntitlement"
      >
        <div
          style={{
            paddingLeft: "22px",
            paddingRight: "34px",
            display: "flex",
            flexDirection: "column",
            flex: 1
          }}
        >
          <BladeDescription>
            {this.state.isEditMode
              ? `Edit component group or individual components of similar licensing model for this entitlement `
              : `Select component group or individual components of similar licensing
            model to create the entitlement.`}
          </BladeDescription>
          {this.renderControlBox()}
          <SearchBar
            style={{ minHeight: "30px" }}
            onSearch={this.updateQuery}
          />
          {this.renderSelection()}
          <div style={{ display: "flex", flex: 1 }}>
            <AutoSizer defaultHeight={300}>
              {({ height, width }) => (
                <div
                  style={{
                    height,
                    width,
                    // flex: 1,
                    overflowY: "scroll"
                    // display: 'flex',
                    // flexDirection: 'column',
                  }}
                >
                  <Flex flexDirection="column" style={{ marginTop: "30px" }}>
                    <SectionTitle>
                      Term
                      <Tooltip
                        placement="right"
                        title={() => {
                          return (
                            <Flex flexDirection={"column"}>
                              <Flex
                                flexDirection={"column"}
                                style={{
                                  color: this.props.theme.colors.textPrimary
                                }}
                              >
                                <h3>Term</h3>
                                <p>Term description.</p>
                              </Flex>
                            </Flex>
                          );
                        }}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </SectionTitle>
                    <ElmToggle
                      style={{ fontSize: "13px", color: "red" }}
                      size="small"
                      onChange={this.updateTermAndUsageType("termType")}
                      rightLabel="Limited"
                      leftLabel="Unlimited"
                      checked={this.state.termType === "limited"}
                    />
                    {this.renderLimitedTermOptions(this.props.result)}
                  </Flex>
                  <Flex flexDirection="column" style={{ marginTop: "24px" }}>
                    <SectionTitle>
                      Usage
                      <Tooltip
                        placement="right"
                        color={this.props.theme.colors.tooltipBackground}
                        title={() => {
                          return (
                            <Flex flexDirection={"column"}>
                              <Flex
                                flexDirection={"column"}
                                style={{
                                  color: this.props.theme.colors.textPrimary
                                }}
                              >
                                <h3>Unlimited usage</h3>
                                <p>
                                  With unlimited usage, users do not have to be
                                  worried about the token count for accessing
                                  any component of this entitlement.
                                </p>
                              </Flex>
                              <Flex
                                flexDirection={"column"}
                                style={{
                                  color: this.props.theme.colors.textPrimary
                                }}
                              >
                                <h3>Limited usage</h3>
                                <p>
                                  With limited usage, users can use any
                                  component as long as they have tokens left to
                                  use.
                                </p>
                              </Flex>
                            </Flex>
                          );
                        }}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </SectionTitle>
                    <ElmToggle
                      style={{ fontSize: "13px" }}
                      size="small"
                      checked={this.state.usageType === "limited"}
                      onChange={this.updateTermAndUsageType("usageType")}
                      rightLabel="Limited"
                      leftLabel="Unlimited"
                    />
                    {this.renderLimitedUsageOptions()}
                  </Flex>
                </div>
              )}
            </AutoSizer>
          </div>
          <Flex>
            <ElmButton
              label="Cancel"
              colorVariance="outline-secondary"
              onClick={this.closeBlade}
            />
            <ElmButton
              style={{ minWidth: "117px" }}
              label={this.state.isEditMode ? "Save changes" : "Create"}
              colorVariance="primary"
              onClick={this.createNewEntitlement}
            />
          </Flex>
        </div>
      </BladeTemplate>
    );
  }
}
const relayEnvironment = getEnvironment();
const RenderQuery = (props: IEntitlementBladeProps) => {
  const theme = useTheme();
  const renderBlade = (payload: {
    error: Error;
    props: NewEntitlementQueryResponse;
    retry: () => void;
  }) => {
    if (!payload.props) {
      return null;
    }
    return (
      <NewEntitlementBlade
        {...props}
        result={payload.props}
        theme={theme}
        {..._.omit(payload, "props")}
      />
    );
  };
  return (
    <>
      <QueryRenderer<NewEntitlementQuery>
        variables={
          //   props.routeData.entitlementId ? {
          //   id: _.get(props.routeData, "productId"),
          //   entitlementId: props.routeData.entitlementId || "",
          // }:
          {
            id: _.get(props.routeData, "productId"),
            entitlementId: props.routeData.entitlementId || ""
          }
        }
        environment={relayEnvironment}
        //query={groupSelectionQuery}
        query={
          props.routeData.entitlementId
            ? groupSelectionQuery
            : dataNewEntitlement
        }
        render={renderBlade}
      />
      {/* )} */}
    </>
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
