/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type companiesTableExportQueryVariables = {
  bladeScope?: string | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
  filterColumn?: Array<string> | null | undefined;
  filterOp?: Array<Array<string>> | null | undefined;
  filterValues?: Array<Array<string>> | null | undefined;
};
export type companiesTableExportQueryResponse = {
  readonly companies: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
      readonly activeUserCount: number;
      readonly licensesCount: number;
      readonly activeLicenseCount: number;
      readonly products: {
        readonly nodes: ReadonlyArray<{
          readonly name: string | null;
          readonly id: string;
          readonly iconUrl: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};
export type companiesTableExportQuery = {
  readonly response: companiesTableExportQueryResponse;
  readonly variables: companiesTableExportQueryVariables;
};

/*
query companiesTableExportQuery(
  $bladeScope: String
  $sortColumn: [String!]
  $sortDirection: [String!]
  $filterColumn: [String!]
  $filterOp: [[String!]!]
  $filterValues: [[String!]!]
) {
  companies(bladeScope: $bladeScope, sortColumn: $sortColumn, sortDirection: $sortDirection, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues) {
    nodes {
      id
      name
      activeUserCount
      licensesCount
      activeLicenseCount
      products {
        nodes {
          name
          id
          iconUrl
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "bladeScope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterOp"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v8 = [
      {
        alias: null,
        args: [
          {
            kind: "Variable",
            name: "bladeScope",
            variableName: "bladeScope"
          },
          {
            kind: "Variable",
            name: "filterColumn",
            variableName: "filterColumn"
          },
          {
            kind: "Variable",
            name: "filterOp",
            variableName: "filterOp"
          },
          {
            kind: "Variable",
            name: "filterValues",
            variableName: "filterValues"
          },
          {
            kind: "Variable",
            name: "sortColumn",
            variableName: "sortColumn"
          },
          {
            kind: "Variable",
            name: "sortDirection",
            variableName: "sortDirection"
          }
        ],
        concreteType: "CompanyConnection",
        kind: "LinkedField",
        name: "companies",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "Company",
            kind: "LinkedField",
            name: "nodes",
            plural: true,
            selections: [
              v6 /*: any*/,
              v7 /*: any*/,
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "activeUserCount",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "licensesCount",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "activeLicenseCount",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: "ProductConnection",
                kind: "LinkedField",
                name: "products",
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: "Product",
                    kind: "LinkedField",
                    name: "nodes",
                    plural: true,
                    selections: [
                      v7 /*: any*/,
                      v6 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "iconUrl",
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  }
                ],
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "companiesTableExportQuery",
      selections: v8 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/
      ],
      kind: "Operation",
      name: "companiesTableExportQuery",
      selections: v8 /*: any*/
    },
    params: {
      cacheID: "9df0a16ea850e39f92dfef587b713cbb",
      id: null,
      metadata: {},
      name: "companiesTableExportQuery",
      operationKind: "query",
      text:
        "query companiesTableExportQuery(\n  $bladeScope: String\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n  $filterColumn: [String!]\n  $filterOp: [[String!]!]\n  $filterValues: [[String!]!]\n) {\n  companies(bladeScope: $bladeScope, sortColumn: $sortColumn, sortDirection: $sortDirection, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues) {\n    nodes {\n      id\n      name\n      activeUserCount\n      licensesCount\n      activeLicenseCount\n      products {\n        nodes {\n          name\n          id\n          iconUrl\n        }\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "220c4670da055005604ac5e848e37a48";
export default node;
