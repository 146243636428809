/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type allUsersQueryVariables = {};
export type allUsersQueryResponse = {
  readonly allUsersFilter: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly id: string;
    } | null> | null;
  } | null;
};
export type allUsersQuery = {
  readonly response: allUsersQueryResponse;
  readonly variables: allUsersQueryVariables;
};

/*
query allUsersQuery {
  allUsersFilter: users(sortColumn: ["product.name"], sortDirection: ["asc"]) {
    nodes {
      name
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
    {
      alias: "allUsersFilter",
      args: [
        {
          kind: "Literal",
          name: "sortColumn",
          value: ["product.name"]
        },
        {
          kind: "Literal",
          name: "sortDirection",
          value: ["asc"]
        }
      ],
      concreteType: "UserConnection",
      kind: "LinkedField",
      name: "users",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "User",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "name",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: 'users(sortColumn:["product.name"],sortDirection:["asc"])'
    } as any
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "allUsersQuery",
      selections: v0 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "allUsersQuery",
      selections: v0 /*: any*/
    },
    params: {
      cacheID: "c72bb66d871a79bd10ef96a98a20e108",
      id: null,
      metadata: {},
      name: "allUsersQuery",
      operationKind: "query",
      text:
        'query allUsersQuery {\n  allUsersFilter: users(sortColumn: ["product.name"], sortDirection: ["asc"]) {\n    nodes {\n      name\n      id\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "713f24fef751f2032fd300d36fb190ce";
export default node;
