import axios from "axios";
import * as _ from "lodash";
import { invertMapKeysAndValues } from "./utility";
import {
  AuthHeaders,
  AuthResponse,
  DeviceStorage,
  SingleLayerStringMap
} from "../types";

const authHeaderKeys: string[] = [
  "access-token",
  "token-type",
  "client",
  "expiry",
  "uid"
];

export const setAuthHeaders = (headers: AuthHeaders): void => {
  authHeaderKeys.forEach((key: string) => {
    axios.defaults.headers.common[key] = headers[key];
  });
};

export const getAuthHeaders = async (Storage: DeviceStorage) => {
  return _.reduce(
    authHeaderKeys,
    async (headerPromise, key) => {
      const headers = await headerPromise;
      headers[key] = await Storage.getItem(key);
      return headers;
    },
    {}
  );
};

export const persistAuthHeadersInDeviceStorage = (
  Storage: DeviceStorage,
  headers: AuthHeaders
): void => {
  authHeaderKeys.forEach((key: string) => {
    if (headers[key]) {
      Storage.setItem(key, headers[key]);
    }
  });
};

export const deleteAuthHeaders = (): void => {
  authHeaderKeys.forEach((key: string) => {
    delete axios.defaults.headers.common[key];
  });
};

export const deleteAuthHeadersFromDeviceStorage = async (
  Storage: DeviceStorage
): Promise<void> => {
  authHeaderKeys.forEach((key: string) => {
    Storage.removeItem(key);
  });
  Storage.removeItem("elm_user_role_type");
};

export const getUserAttributesFromResponse = (
  userAttributes: SingleLayerStringMap,
  response: AuthResponse
): SingleLayerStringMap => {
  const invertedUserAttributes: SingleLayerStringMap = invertMapKeysAndValues(
    userAttributes
  );
  const userAttributesBackendKeys: string[] = Object.keys(
    invertedUserAttributes
  );
  const userAttributesToReturn: SingleLayerStringMap = {};
  Object.keys(response.data.data).forEach((key: string) => {
    if (userAttributesBackendKeys.indexOf(key) !== -1) {
      userAttributesToReturn[invertedUserAttributes[key]] =
        response.data.data[key];
    }
  });
  return userAttributesToReturn;
};
