/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type productUsersTableQueryVariables = {
  blade_scope: string;
};
export type productUsersTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"productUsersTable_users">;
};
export type productUsersTableQuery = {
  readonly response: productUsersTableQueryResponse;
  readonly variables: productUsersTableQueryVariables;
};

/*
query productUsersTableQuery(
  $blade_scope: String!
) {
  ...productUsersTable_users_6Y2LU
}

fragment productUsersTable_users_6Y2LU on Query {
  usersCount(bladeScope: $blade_scope, filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]])
  users(first: 25, bladeScope: $blade_scope, filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]]) {
    edges {
      node {
        id
        name
        email
        active
        instancesCount(bladeScope: $blade_scope)
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "blade_scope"
      } as any
    ],
    v1 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    v2 = {
      kind: "Literal",
      name: "filterColumn",
      value: ["users.active"]
    } as any,
    v3 = {
      kind: "Literal",
      name: "filterOp",
      value: [["="]]
    } as any,
    v4 = {
      kind: "Literal",
      name: "filterValues",
      value: [["true"]]
    } as any,
    v5 = [
      v1 /*: any*/,
      v2 /*: any*/,
      v3 /*: any*/,
      v4 /*: any*/,
      {
        kind: "Literal",
        name: "first",
        value: 25
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "productUsersTableQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "blade_scope",
              variableName: "blade_scope"
            }
          ],
          kind: "FragmentSpread",
          name: "productUsersTable_users"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "productUsersTableQuery",
      selections: [
        {
          alias: null,
          args: [v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
          kind: "ScalarField",
          name: "usersCount",
          storageKey: null
        },
        {
          alias: null,
          args: v5 /*: any*/,
          concreteType: "UserConnection",
          kind: "LinkedField",
          name: "users",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "UserEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "User",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "name",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "email",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "active",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: [v1 /*: any*/],
                      kind: "ScalarField",
                      name: "instancesCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v5 /*: any*/,
          filters: [
            "bladeScope",
            "search",
            "filterColumn",
            "filterOp",
            "filterValues"
          ],
          handle: "connection",
          key: "productUsersTable_users",
          kind: "LinkedHandle",
          name: "users"
        }
      ]
    },
    params: {
      cacheID: "c7db4a2d5caf9ce980e29238039f23b0",
      id: null,
      metadata: {},
      name: "productUsersTableQuery",
      operationKind: "query",
      text:
        'query productUsersTableQuery(\n  $blade_scope: String!\n) {\n  ...productUsersTable_users_6Y2LU\n}\n\nfragment productUsersTable_users_6Y2LU on Query {\n  usersCount(bladeScope: $blade_scope, filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]])\n  users(first: 25, bladeScope: $blade_scope, filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]]) {\n    edges {\n      node {\n        id\n        name\n        email\n        active\n        instancesCount(bladeScope: $blade_scope)\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "2680e6375437db08c51dd01feb5bc40a";
export default node;
