import React, { Suspense, useState } from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { getEnvironment } from "api/relay";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay";
import ElmCheckBox from "components/elmCheckBox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ElmSelectOption, ElmSelectWithLabel } from "components/elmSelect";
import { SearchBar } from "components/elmTable/helpers";
import { formatInlineDisplayValues } from "Reports/common/utils";
import { appConnect } from "store/appConnect";
import {
  componentsSelectQuery,
  componentsSelectQueryResponse
} from "./__generated__/componentsSelectQuery.graphql";
import { base64EncodeId } from "utils/base64EncodeDecode";

interface IComponentsSelectProps {
  productId: string;
  handleSelect: (
    components: componentsSelectQueryResponse["product"]["components"]["nodes"][number]["id"][]
  ) => void;
  defaultValue?: (string | number)[];
}

const graphqlQuery = graphql`
  query componentsSelectQuery($id: ID!, $skip: Boolean!) {
    product(id: $id) @skip(if: $skip) {
      components {
        nodes {
          id
          name
        }
      }
    }
  }
`;

const SelectionContainer = styled(Flex)`
  color: ${props => props.theme.colors.textPrimary};
`;

const ComponentsSelect: React.FC<IComponentsSelectProps> = ({
  productId,
  handleSelect,
  defaultValue
}) => {
  const res = useLazyLoadQuery<componentsSelectQuery>(
    graphqlQuery,
    {
      id: productId,
      skip: !!!productId
    },
    { fetchPolicy: "network-only" }
  );

  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [selection, setSelection] = useState<string[]>(
    defaultValue
      ?.map(componentId => {
        return res?.product?.components?.nodes?.find(
          comp => comp.id === base64EncodeId("Component", componentId)
        )?.id;
      })
      ?.filter(item => item) || []
  );

  const toggleSelect = (state: boolean) => {
    setOpen(state);
  };

  const handleSelection = (payload: string[]) => {
    setSelection(payload);
    handleSelect(payload);
  };

  const updateSelection = (payload: {
    id: string;
    name: string;
    checked: boolean;
  }) => {
    if (payload.checked) {
      const newSelection = [...selection, payload.id];
      handleSelection(newSelection);
    } else {
      const filtered = selection?.filter(comp => {
        if (comp !== payload.id) return true;
        return false;
      });
      handleSelection(filtered);
    }
  };
  const options = [...res?.product?.components?.nodes] || [];

  const captureSelectValue = (id: string) => {
    const exists = selection?.find(option => option === id);
    if (exists) {
      const newComponents = [...selection].filter(item => item !== exists);
      handleSelection(newComponents);
      return;
    }
    const target = options.find(option => option.id === id)?.id;
    if (target) {
      handleSelection([...selection, target]);
    }
  };
  const handleChange = (selection: { id: string; name: string }) => (
    e: CheckboxChangeEvent
  ) => {
    updateSelection({
      checked: e.target.checked,
      ...selection
    });
  };
  return (
    <SelectionContainer onClick={e => e.preventDefault()}>
      <Flex style={{ margin: 12 }} flexDirection="column" flex={1}>
        <ElmSelectWithLabel
          label="Components"
          placeholder={"All components"}
          onClick={() => toggleSelect(true)}
          onBlur={() => toggleSelect(false)}
          onSelect={e => captureSelectValue(e)}
          disabled={options?.length === 0}
          open={open}
          value={
            options?.length === 0
              ? "Product has no components"
              : selection?.length > 0
              ? formatInlineDisplayValues(
                  selection?.map(
                    item =>
                      res?.product?.components?.nodes?.find(
                        comp => comp.id === item
                      )?.name
                  )
                )
              : null
          }
          defaultValue={
            options?.length === 0
              ? "Product has no components"
              : selection?.length > 0
              ? formatInlineDisplayValues(
                  selection?.map(
                    item =>
                      res?.product?.components?.nodes?.find(
                        comp => comp.id === item
                      )?.name
                  )
                )
              : null
          }
        >
          {options?.length ? (
            <ElmSelectOption key={"search"} value={"search"}>
              <SearchBar
                onSearch={search => setSearch(search.toLowerCase())}
                style={{ margin: 0 }}
              />
            </ElmSelectOption>
          ) : null}
          {options
            ?.filter(node => node.name?.toLowerCase().includes(search))
            ?.map(node => {
              return (
                <ElmSelectOption
                  key={node.id}
                  value={node.id}
                  label={node.name}
                >
                  <ElmCheckBox
                    onChange={handleChange(node)}
                    checked={
                      selection?.find(item => item === node.id) ? true : false
                    }
                  >
                    {node.name}
                  </ElmCheckBox>
                </ElmSelectOption>
              );
            })}
        </ElmSelectWithLabel>
      </Flex>
    </SelectionContainer>
  );
};

const relayEnvironment = getEnvironment();
const RenderQuery: React.FC<IComponentsSelectProps> = props => {
  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <Suspense
        fallback={
          <Flex style={{ margin: 12 }} flexDirection="column" flex={1}>
            <ElmSelectWithLabel
              label="Components"
              placeholder="All components"
              disabled={true}
            />
          </Flex>
        }
      >
        <ComponentsSelect {...props} />
      </Suspense>
    </RelayEnvironmentProvider>
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
