import styled from "styled-components";

const getTypeColor = {
  request: "#FFA033",
  bug: "#EB5740",
  feedback: "#1F89CC",
  idea: "#1F89CC"
};

export const VersionInfo = styled.span(props => ({
  color: props.theme.colors.greyish,
  fontSize: props.theme.fontSizes.xSmall
}));

export const Message = styled.span(props => ({
  marginTop: "12px",
  marginBottom: "8px",
  textAlign: "justify",
  fontWeight: 400,
  fontSize: "14px",
  color: props.theme.colors.textPrimary
}));

export const TimeInfo = styled.span(props => ({
  color: props.theme.colors.greyish,
  fontSize: props.theme.fontSizes.xSmall
}));

export const ProductInfo = styled.span(props => ({
  color: props.theme.colors.textPrimary,
  fontSize: props.theme.fontSizes.small,
  fontWeight: props.theme.fontWeight.medium,
  marginRight: "6px"
}));

export const TypeInfo = styled.span<{ type: string }>(props => ({
  color: getTypeColor[props.type],
  fontWeight: 600,
  letterSpacing: "0.15em",
  textTransform: "uppercase",
  fontSize: props.theme.fontSizes.xxSmall
}));
