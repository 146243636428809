import { ElmToggle } from "../../../components/elmToggle";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SwitchSize } from "antd/es/switch";

interface IToggle {
  enabled: boolean;
  id: string;
  name?: string;
  totalEnabled: number;
  retry: (data?: any) => void;
  size?: SwitchSize;
}

type Status = {
  [key: string]: boolean;
  isLoading: boolean;
};

const StyledLabel = styled.span`
  font-size: ${props => props.theme.fontSizes.xSmall};
  font-weight: 400;
  color: ${props => props.theme.colors.lightBlue};
  line-height: 14.5px;
`;

const getActivationLabel = (activeInstalls: number) => {
  if (activeInstalls <= 0) return "No active installs";
  if (activeInstalls > 0)
    return `${activeInstalls} active install${activeInstalls > 1 ? "s" : ""}`;
  return "";
};

export default function ToggleActivation({
  enabled,
  id,
  //name,
  totalEnabled,
  retry,
  size
}: IToggle) {
  const [status, setStatus] = useState<Status>({
    [id]: enabled,
    isLoading: false
  });

  useEffect(() => {
    setStatus({ [id]: enabled, isLoading: false });
  }, [enabled, id]);

  const labelValue = !!enabled ? getActivationLabel(totalEnabled) : "";

  const handleActivation = (state: boolean, id: string) => {
    setStatus({ [id]: state, isLoading: true });
    // @ts-ignore
    retry({ product_id: id, enabled: state });
  };
  return (
    <ElmToggle
      loading={status.isLoading}
      size={size || "default"}
      rightLabel={<StyledLabel>{labelValue}</StyledLabel>}
      onChange={(state: boolean) => handleActivation(state, id)}
      checked={enabled}
      permissions="modify_assets"
    />
  );
}
