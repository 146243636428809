import { Radio } from "antd";

import styled, { css } from "styled-components";
import { RadioProps } from "antd/lib/radio";

const NoOutline = css<{ borderColor?: string }>`
  :focus {
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: transparent;
    border-bottom: 2px solid
      ${props => props.borderColor || props.theme.colors.lightishBlue};
    outline: none;
    box-shadow: none;
    border-bottom-width: 2px;
    opacity: 1;
  }
`;
const ElmRadio = styled(Radio)<{ borderColor?: string } & RadioProps>`
  .ant-select-selection {
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 2px;
    opacity: 0.6;
    border-bottom: 2px solid
      ${props => props.borderColor || props.theme.colors.black};
    ${NoOutline}
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    ${NoOutline}
  }
  li {
    :hover {
      background-color: ${props => props.theme.colors.lightishBlue};
      color: white;
    }
  }
`;

export { ElmRadio };
export default ElmRadio;
