import moment from "moment";
import React, { SetStateAction } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer, RelayEnvironmentProvider } from "react-relay";
import { Flex } from "reflexbox";
import styled from "styled-components";
import relayEnvironment from "../../api/relay";
import { appConnect } from "../../store/appConnect";
import { getIcon, renderProductImages } from "../../components";
import {
  WidgetContainer,
  getWidgetContainer
} from "../../components/analyticsComponent/widgetContainer";
import {
  ElmDefaultSelect,
  ElmFilterSelect,
  ElmSelect,
  ElmSelectOption
} from "../../components/elmSelect";
import {
  usersAnalyticsQuery,
  usersAnalyticsQueryResponse,
  usersAnalyticsQueryVariables
} from "./__generated__/usersAnalyticsQuery.graphql";
import _ from "lodash";
import {
  usersAnalyticsRegistrationQuery,
  usersAnalyticsRegistrationQueryVariables
} from "./__generated__/usersAnalyticsRegistrationQuery.graphql";
import { getAnimatedComponent } from "../../components/helpers";
import { getFriendlyLicenseName } from "../../utils";
import {
  TopCountFilter,
  topCounts
} from "../../components/analyticsComponent/filters/topCount";
import {
  TabbedAnalyticsComponent,
  ITabbedAnalyticsComponentProps
} from "../../components/analyticsComponent/tabbedAnalytics";
import AnalyticsComponent from "../../components/analyticsComponent";
import {
  AnalyticsComponentProps,
  queryType
} from "../../components/analyticsComponent/";
import { TableAnalyticsComponent } from "../../components/analyticsComponent/tableAnalyticsComponent";
import { Tabs } from "antd";
import LoadingWrapper from "../../components/elmChart/loadingWrapper";
import { LineChart } from "../../components/elmChart";
import ProductsFilter from "components/analyticsComponent/filters/productsFilter";
import { sharedTabStyle } from "./shared";
import { DateRange } from "blades/Dashboard/utils";
import { IOpenBladeFromChart } from "components/bladeManager/types";

const registrationsQuery = graphql`
  query usersAnalyticsRegistrationQuery($count: Int, $productId: String!) {
    registrationEntries(
      first: $count
      bladeScope: $productId
      sortColumn: "createdAt"
      sortDirection: "DESC"
      filterColumn: ["licenses.is_vendor"]
      filterOp: [["="]]
      filterValues: [["false"]]
    ) {
      nodes {
        user {
          displayName
        }
        license {
          product {
            name
            iconUrl
          }
          owner {
            name
          }
        }
        createdAt
      }
    }
  }
`;
const RegistrationsWidget = getWidgetContainer<
  usersAnalyticsRegistrationQuery,
  usersAnalyticsRegistrationQueryVariables
>();

const graphqlQuery = graphql`
  query usersAnalyticsQuery(
    $currentTimeSpanStart: String!
    $currentTimeSpanEnd: String!
    $productId: String!
  ) {
    UsersAnalyticsCurrentExpiringUsers: licenses(
      filterColumn: ["latestTerm.endDate", "latestTerm.endDate"]
      filterOp: [[">", "="], ["<", "="]]
      filterValues: [
        [$currentTimeSpanStart, $currentTimeSpanStart]
        [$currentTimeSpanEnd, $currentTimeSpanEnd]
      ]
      bladeScope: $productId
    ) {
      nodes {
        type {
          name
          id
        }
      }
    }
  }
`;

export interface IUsersAnalyticsProps {
  result: usersAnalyticsQueryResponse;
  retry: () => void;
  openBlade?: (payload: IOpenBladeFromChart) => void;
  timespan: "week" | "month" | "day";
  productId: string;
  variables: {
    timeRangeOverride?: DateRange;
    currentTimeSpanStart: string;
    currentTimeSpanEnd: string;
    previousTimeSpanStart: string;
    previousTimeSpanEnd: string;
    productId: string | null;
  };
}
type state = {
  topUsersCount: number;
  topTotalUsersCount: number;
  userRegistrationsCount: number;
};
export class UsersAnalytics extends React.Component<IUsersAnalyticsProps> {
  state: state = {
    topUsersCount: 5,
    topTotalUsersCount: 5,
    userRegistrationsCount: 5
  };
  public getHighlightAnalytics = () => {
    return (
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          // {
          //   tabProps: {
          //     title: "Session count",
          //     style: {
          //       marginRight: "0px",
          //       paddingTop: "10px",
          //       paddingBottom: "10px",
          //       paddingLeft: "12px",
          //       paddingRight: "12px",
          //       height: "40px",
          //       display: "flex",
          //       alignItems: "center"
          //     }
          //   },
          //   analyticsComponentProps: {
          //     analytics: this.getSessionCountAnalyticsSetup()
          //   }
          // },
          {
            tabProps: {
              title: "Session duration",
              style: {
                marginRight: "0px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "12px",
                paddingRight: "12px",
                height: "40px",
                display: "flex",
                alignItems: "center"
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              analytics: this.getSessionDurationAnalyticsSetup(),
              openBlade: this.props.openBlade,
              type: "bar"
            }
          }
        ]}
      />
    );
  };
  public getSessionDurationAnalyticsSetup = () => {
    return {
      bladeScope: this.props.productId,
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_duration",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd,
      options: {
        chartMaxHeight: 400
      }
    } as queryType;
  };
  public getSessionCountAnalyticsSetup = () => {
    return {
      bladeScope: this.props.productId,
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_count",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd
    } as queryType;
  };
  public getTabAnalytics = (payload: any) => {
    return (
      // <RelayEnvironmentProvider environment={relayEnvironment}>
      //   <Tabs>
      //     <Tabs.TabPane tab="Session count" key="item-1">
      //       <div style={{ minHeight: "100%" }}>
      //         <LoadingWrapper>
      //           <LineChart
      //             showProductFilter={false}
      //             hideTimeRangeFilter
      //             queryParams={{
      //               bladeScope: this.props.productId,
      //               setup: {
      //                 category: `time.${this.props.timespan}`,
      //                 value: "sessions.usage_count",
      //                 group: null
      //               },
      //               first: this.state.topUsersCount,
      //               startTime: this.props.variables.currentTimeSpanStart,
      //               endTime: this.props.variables.currentTimeSpanEnd
      //             }}
      //             plot={
      //               {
      //                 //title: "Session Count",
      //               }
      //             }
      //           />
      //         </LoadingWrapper>
      //       </div>
      //     </Tabs.TabPane>
      //     <Tabs.TabPane tab="Session duration" key="item-2">
      //       <div style={{ minHeight: "100%" }}>
      //         <LoadingWrapper>
      //           <LineChart
      //             showProductFilter={false}
      //             hideTimeRangeFilter
      //             queryParams={{
      //               bladeScope: this.props.productId,
      //               setup: {
      //                 category: `time.${this.props.timespan}`,
      //                 value: "sessions.usage_duration",
      //                 group: null
      //               },
      //               first: this.state.topUsersCount,
      //               startTime: this.props.variables.currentTimeSpanStart,
      //               endTime: this.props.variables.currentTimeSpanEnd
      //             }}
      //             plot={
      //               {
      //                 //title: "Session Count",
      //               }
      //             }
      //           />
      //         </LoadingWrapper>
      //       </div>
      //     </Tabs.TabPane>
      //     <Tabs.TabPane tab="By Product" key="item-3">
      //       <div style={{ minHeight: "100%" }}>
      //         <LoadingWrapper>
      //           <LineChart
      //             showProductFilter={false}
      //             hideTimeRangeFilter
      //             queryParams={{
      //               bladeScope: this.props.productId,
      //               setup: {
      //                 category: `time.${this.props.timespan}`,
      //                 value: "sessions.usage_duration",
      //                 group: null
      //               },
      //               first: this.state.topUsersCount,
      //               startTime: this.props.variables.currentTimeSpanStart,
      //               endTime: this.props.variables.currentTimeSpanEnd
      //             }}
      //             plot={
      //               {
      //                 //title: "Session Count",
      //               }
      //             }
      //           />
      //         </LoadingWrapper>
      //       </div>
      //     </Tabs.TabPane>
      //     <Tabs.TabPane tab="By component" key="item-4">
      //       <div style={{ minHeight: "100%" }}>
      //         <LoadingWrapper>
      //           <LineChart
      //             showProductFilter={false}
      //             hideTimeRangeFilter
      //             queryParams={{
      //               bladeScope: this.props.productId,
      //               setup: {
      //                 category: `time.${this.props.timespan}`,
      //                 value: "sessions.usage_duration",
      //                 group: null
      //               },
      //               first: this.state.topUsersCount,
      //               startTime: this.props.variables.currentTimeSpanStart,
      //               endTime: this.props.variables.currentTimeSpanEnd
      //             }}
      //             plot={
      //               {
      //                 //title: "Session Count",
      //               }
      //             }
      //           />
      //         </LoadingWrapper>
      //       </div>
      //     </Tabs.TabPane>
      //   </Tabs>
      // </RelayEnvironmentProvider>
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session count",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              openBlade: this.props.openBlade,
              analytics: {
                bladeScope: this.props.productId,
                setup: {
                  category: payload.category,
                  value: "sessions.usage_count",
                  group: null
                },
                first: payload.first,
                sortColumn: "value",
                sortDirection: "DESC",
                startTime: this.props.variables.currentTimeSpanStart,
                endTime: this.props.variables.currentTimeSpanEnd,
                options: {
                  chartMaxHeight: 400
                }
              },
              type: payload.firstTabType
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              openBlade: this.props.openBlade,
              analytics: {
                bladeScope: null, //this.props.productId,
                setup: {
                  category: `time.month`, //payload.category,
                  value: "sessions.usage_duration",
                  group: "products.name"
                },
                first: payload.first,
                sortColumn: "value",
                sortDirection: "DESC",
                startTime: this.props.variables.currentTimeSpanStart,
                endTime: this.props.variables.currentTimeSpanEnd,
                options: {
                  chartMaxHeight: 400
                }
              },
              type: payload.secondTabType
            }
          }
        ]}
      />
    );
  };
  public updateTopCount = (type: keyof state) => (count: topCounts) => {
    this.setState({ [type]: parseInt(count, 10) });
  };
  public render() {
    return (
      <div className="users-analytics analytics-container">
        {/* className="users-analytics analytics-container" */}
        <WidgetContainer size="large">
          <Flex justifyContent="space-between" alignItems="center">
            <ElmDefaultSelect
              //value={1}
              //onChange={handleChange}
              // className={clsx(
              //   this.state.filtered ? "filtered" : "",
              //   "filterBorder"
              // )}
              style={{ width: "160px", borderBottom: "none" }}
              dropdownMatchSelectWidth={false}
            >
              {[
                { id: 1, name: "Top users" },
                { id: 2, name: "Worst users" },
                { id: 3, name: "New users" }
              ].map(node => {
                return (
                  <ElmSelectOption
                    key={node.name}
                    value={node.name}
                    label={node.name}
                  >
                    <span className="filterOption">{node.name}</span>
                  </ElmSelectOption>
                );
              })}
            </ElmDefaultSelect>
            {/* <span className="widget-title">Top Users</span> */}
            <TopCountFilter onChange={this.updateTopCount("topUsersCount")} />
          </Flex>
          {this.getTabAnalytics({
            category: "users.name",
            secondTabType: "line",
            firstTabType: "bar",
            first: this.state.topUsersCount
          })}
          {/* {this.getTabAnalytics()} */}
        </WidgetContainer>
        {/* <WidgetContainer size="medium-large">
          <Flex>
            <span className="widget-title">Registered user growth</span>
          </Flex>
        </WidgetContainer> */}
        {/* <WidgetContainer size="large">
          <Flex>
            <span className="widget-title">Total Users</span>
            <TopCountFilter
              onChange={this.updateTopCount("topTotalUsersCount")}
            />
          </Flex>
          <AnalyticsComponent
            analytics={{
              bladeScope: this.props.productId,
              setup: {
                category: "companies.name",
                value: "counts.total_user",
                group: null
              },
              sortColumn: "value",
              sortDirection: "DESC",
              first: this.state.topTotalUsersCount,
              startTime: this.props.variables.currentTimeSpanStart,
              endTime: this.props.variables.currentTimeSpanEnd
            }}
            type="pie"
          />
        </WidgetContainer> */}
        {/* <RegistrationsWidget
          widgetContainerProps={{ size: "x-large" }}
          query={registrationsQuery}
          variables={{
            ...this.props.variables,
            count: this.state.userRegistrationsCount,
            productId: this.props.variables.productId || ""
          }}
        >
          {res => (
            <React.Fragment>
              <Flex style={{ marginBottom: "16px" }}>
                <span className="widget-title">
                  Most recent user registrations
                </span>
                <ProductsFilter />
                <TopCountFilter
                  onChange={this.updateTopCount("userRegistrationsCount")}
                />
              </Flex>
              <TableAnalyticsComponent
                headers={[
                  {
                    key: "user.displayName",
                    title: "Username"
                  },
                  { key: "license.owner.name", title: "Company" },
                  {
                    key: "license.product",
                    title: "Product",
                    renderContentFn: (value: {
                      name: string;
                      iconUrl: string;
                    }) => (
                      <span style={{ padding: "11.5px 12px" }}>
                        {renderProductImages([value])}
                      </span>
                    )
                  },
                  {
                    key: "createdAt",
                    title: "Registered at",
                    renderContentFn: (value: string) => (
                      <span className="row">
                        {moment
                          .duration(moment(value).diff(moment()))
                          .humanize(true)}
                      </span>
                    )
                  }
                ]}
                rows={_.get(res, "registrationEntries.nodes")}
              />
            </React.Fragment>
          )}
        </RegistrationsWidget> */}
      </div>
    );
  }
}

const RenderQuery = (props: IUsersAnalyticsProps) => {
  // const [variables, updateVariables] = React.useState({
  //   ...getQueryTimeVariables("week"),

  //   sessionSetup: {
  //     category: "time.day",
  //     group: "user_id",
  //     value: "sessions.usage_count"
  //   },
  //   userSetup: {
  //     category: "users_id",
  //     group: "userss.type",
  //     value: "counts.active_user"
  //   }
  // });
  const renderBlade = (payload: {
    error: Error;
    props: usersAnalyticsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <UsersAnalytics
        {...props}
        variables={props.variables}
        result={payload.props}
        retry={payload.retry}
        // updateVariables={updateVariables}
      />
    );
  };
  return (
    <QueryRenderer<usersAnalyticsQuery>
      environment={relayEnvironment}
      variables={props.variables}
      query={graphqlQuery}
      render={renderBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
