import CryptoJS from "crypto-js";
import * as _ from "lodash";
import * as React from "react";
import { lazy } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Loader from "react-loaders";
import { Flex } from "reflexbox";
// @ts-ignore
import { fadeIn, fadeOut, slideInRight, slideOutRight } from "react-animations";
import styled, { keyframes } from "styled-components";
import "loaders.css/loaders.min.css";

import { appConnect } from "../../store/appConnect";
import { theme } from "../../theme";
import { getBladeColor, getSavedRoleTypeFromLocalSync } from "../../utils";
import { iconNameType } from "../icons";
import { AppModalConductor } from "./appDialog";
import { getGateway } from "../../api";
import {
  IBladeManagerProps,
  IBladeManagerState,
  IBladeManagerRoutesState,
  IOpenBlade,
  IBladeBaseProps,
  IOpen,
  OPEN_FROM_DASHBOARD_INDEX
} from "./types";
import bladeManagerContext from "./bladeManagerContext";
import bladeTypes from "./bladeTypes";
import { getClassName } from "../helpers";
import { VendorLogo } from "./vendorLogo";
import ApprovalPendingList from "blades/Approval/ApprovalPendingList";
import { FeedbackWidget } from "utils/FeedbackWidget";
import RoleSelectDialog from "../../roleSelect";
import { ProductCountWatcher } from "blades/Welcome/utils/ProductCountWatcher";
import { RelayEnvironmentProvider } from "react-relay";
import relayEnvironment from "../../api/relay";
import { OpenGuideButton } from "blades/Welcome/utils/OpenGuideButton";
import UserInfoBox from "./userInfoBox";
import { EntityRefreshMap } from "./entityRefreshMap";

const ElmSideBar = lazy(() => import("./sideBar"));
const CompanySelector = lazy(() => import("./companySelector"));
const SearchBar = lazy(() => import("./omniSearchBar"));

const transitionName = "blade";
const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;
const slideInAnimation = keyframes`${slideInRight}`;
const slideOutAnimation = keyframes`${slideOutRight}`;

// const margin = 12;
// const padding = 30;
const containerMargin = 24;
const BladeSectionContainer = styled((props: any) => {
  return <Flex {...props} />;
})`
  background-color: ${props => props.theme.colors.bladeBackground};
  padding-right: ${props => (props.isDashboard ? 0 : containerMargin)}px;
`;
const TransitionContainer = styled((props: any) => {
  return (
    <div
      {...props}
      className={getClassName({
        "elm-scroll-style": () => true,
        [props.className]: () => !!props.className
      })}
    />
  );
})`
  overflow-y: hidden;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  margin-bottom: 5px;
  padding-bottom: 10px;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
`;
const TransitionContainerDashboard = styled((props: any) => {
  return (
    <div
      {...props}
      className={getClassName({
        "elm-scroll-style": () => false,
        [props.className]: () => !!props.className
      })}
    />
  );
})`
  display: flex;
  padding-right: 24px;
  flex: 1;
  flex-wrap: nowrap;
  margin-bottom: 0px;
  padding-bottom: 0px;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`;
const FirstBladeContainer = styled.section`
  &.${transitionName}-appear {
    animation: 3s ${fadeInAnimation};
  }

  &.${transitionName}-enter {
    animation: 1s ${fadeInAnimation};
  }

  &.${transitionName}-leave {
    animation: 1s ${fadeOutAnimation};
  }
  margin-left: ${containerMargin}px;
`;
const RegularBladeContainer = styled.section`
  margin-left: ${containerMargin}px;
  &.${transitionName}-enter {
    animation: 1.5s ${fadeInAnimation};
  }

  &.${transitionName}-appear {
    animation: 1.5s ${fadeInAnimation};
  }

  &.${transitionName}-leave {
    animation: 1s ${fadeOutAnimation};
  }
`;
const HeaderContainer = styled(Flex)`
  height: 51px;
  background-color: ${props => props.theme.colors.blackTwo};
  justify-content: space-between;
  align-items: center;
`;
const BreadCrumbLink = styled.div<{ isLast?: boolean }>`
  ${props => props.theme.fontType.breadcrumb}
  display: flex;
  align-items: center;
  //border: ${props =>
    props.isLast ? `solid 1px ${props.theme.colors.black}` : "none"};
  margin: 0px;
  padding: 3px;
  cursor: pointer;
  
  @media (max-width: 1650px) {
    margin-right: 20px;
  }
  @media (min-width: 1651px) {
    margin-right: 24px;
  }
`;
export interface ISideBarLink {
  activeColor?: keyof theme["colors"];
  bladeKey: IOpenBlade["route"];
  label: string;
  icon: iconNameType;
  onClick: () => void;
}

export interface IUpdateBladeManagerUrl {
  tabIndex: number;
  bladeIndex: number;
}

const BreadCrumbColorCode = styled.div<{
  bladeKey: IOpenBlade["route"];
  blade: IOpenBlade;
}>`
  width: 12px;
  height: 12px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: ${props =>
    props.theme.colors[
      props.blade.route === "Analytics"
        ? getBladeColor(props.blade.routeData.type)
        : getBladeColor(props.bladeKey)
    ]};
`;
const SignOutContainer = styled(Flex)`
  :hover {
    cursor: pointer;
  }
  color: ${props => props.theme.colors.textPrimary};
`;

class BladeManager extends React.Component<
  IBladeManagerProps,
  IBladeManagerState & {
    isUserRoleTypeAssigned: boolean;
  }
> {
  private sKey = CryptoJS.enc.Hex.parse("000102030405060708090a0b0c0d0e0f");
  private iv = CryptoJS.enc.Hex.parse("101112131415161718191a1b1c1d1e1f");
  private bladeRefreshMap: Record<IOpen["route"], () => void>;
  private entityRefreshMap: Record<EntityRefreshMap, () => void>;
  public static getDerivedStateFromError(error: Error) {
    console.warn(error);
    return { appHasError: true, error };
  }
  constructor(props: IBladeManagerProps) {
    super(props);
    this.state = {
      appHasError: false,
      error: null,
      loader: {
        show: false
      },
      userInfo: null,
      isUserRoleTypeAssigned: false
    };
  }

  public componentDidMount = () => {
    this.getUserEmail();
    this.checkUserRoleAssign();
  };
  public checkUserRoleAssign = () => {
    const userRoleType = getSavedRoleTypeFromLocalSync();
    if (userRoleType && !this.state.isUserRoleTypeAssigned) {
      this.setState({ isUserRoleTypeAssigned: true });
    }
  };
  public isVendor = () =>
    getSavedRoleTypeFromLocalSync() === "vendor" ||
    _.get(this.props, "appState.activeRole.type") === "vendor";

  public navigateToAppFacet = (blade: IOpenBlade, noReset?: boolean) => () => {
    const isOnboardingMode =
      this.props.appState.onboardingMode?.status === "active";
    this.openBlade({
      ...blade,
      navigationType: noReset ? null : isOnboardingMode ? null : "reset",
      fromBladeIndex: isOnboardingMode ? 0 : blade.fromBladeIndex
    });
  };

  public showScreenLoader = () => {
    // disable app level loader
    // if (!this.state.loader.show) {
    //   this.setState({ loader: { show: false } });
    // }
  };
  public hideScreenLoader = () => {
    // if (this.state.loader.show) {
    //   this.setState({ loader: { show: false } });
    // }
  };
  public setPageTitle = (title: string) => {
    document.title = title;
  };

  public renderBreadCrumbs = () => {
    const currentState = this.decryptUrlState();

    const isLast = (index: number) =>
      index === currentState.openBlades.length - 1;
    return (
      <Flex marginBottom={27} marginLeft={24} marginTop={21}>
        {_.map(currentState.openBlades, (blade, i) => {
          //if (blade.route === "Dashboard") return;
          return (
            <BreadCrumbLink
              onClick={this.navigateToAppFacet(blade, true)}
              isLast={isLast(i)}
              key={`${blade.route}-${i}`}
            >
              <BreadCrumbColorCode bladeKey={blade.route} blade={blade} />
              {`${blade.routeName} `}
            </BreadCrumbLink>
          );
        })}
      </Flex>
    );
  };
  public navigateToOnboardingBlade = () => {
    this.openBlade({
      route: "Welcome",
      routeName: "Welcome",
      routeData: null,
      fromBladeIndex: OPEN_FROM_DASHBOARD_INDEX,
      navigationType: "reset"
    });
  };
  public renderScreenLoader = () => {
    const showLoader = this.state.loader.show;
    return (
      <div
        style={{
          alignItems: "center",
          backgroundColor: "rgba(215,215,215,0.2)",
          display: `${showLoader ? "flex" : "none"}`,
          height: `100%`,
          justifyContent: "center",
          position: "absolute",
          width: "100%",
          zIndex: 99999
        }}
      >
        <Loader
          type="line-scale"
          color={"rgb(98, 98, 130)"}
          active={showLoader}
        />
      </div>
    );
  };
  public encryptUrlState = (payload: any) => {
    return CryptoJS.AES.encrypt(JSON.stringify(payload), this.sKey, {
      iv: this.iv
    }).toString();
  };
  public getDefaultRouteState = () => {
    const isCompany =
      getSavedRoleTypeFromLocalSync() === "company" ||
      _.get(this.props, "appState.activeRole.type", "") === "company";

    return {
      openBlades: [
        {
          route: isCompany ? "Overview" : "Dashboard",
          routeData: [],
          routeName: isCompany ? "Customer portal" : "Dashboard"
        }
      ]
    } as IBladeManagerRoutesState;
  };
  public decryptUrlState = (): IBladeManagerRoutesState => {
    try {
      const isCompany =
        getSavedRoleTypeFromLocalSync() === "company" ||
        _.get(this.props, "appState.activeRole.type", "") === "company";

      const isRoot =
        this.props.location.pathname.startsWith("/") &&
        this.props.location.pathname.length === 1;
      if (this.props.location.pathname.startsWith("/dashboard") || isRoot) {
        return {
          openBlades: [
            {
              fromBladeIndex: 0,
              navigationType: "reset",
              route: isCompany ? "Overview" : "Dashboard",
              routeData: null,
              routeName: isCompany ? "Customer portal" : "Dashboard"
            }
          ]
        };
      }
      const bytes = CryptoJS.AES.decrypt(
        this.props.location.pathname.substring(8),
        this.sKey,
        { iv: this.iv }
      );
      const jsonData = bytes.toString(CryptoJS.enc.Utf8);
      const decryptedData = JSON.parse(jsonData);
      return decryptedData as IBladeManagerRoutesState;
    } catch (e) {
      console.error(e);
      return this.getDefaultRouteState();
    }
  };

  public updateBladeManagerState = (payload: IUpdateBladeManagerUrl) => {
    const { tabIndex, bladeIndex } = payload;
    const currentState = this.decryptUrlState();

    const bladeTest = currentState.openBlades[bladeIndex];

    if (bladeTest.routeData) bladeTest.routeData.tabIndex = tabIndex;
    if (!bladeTest.routeData) bladeTest.routeData = { tabIndex };
    currentState.openBlades[bladeIndex] = bladeTest;

    this.pushRouteTo(currentState);
  };
  public openBlade = (p: IOpenBlade) => {
    const payload = {
      ...p,
      fromBladeIndex: _.isFinite(p.fromBladeIndex) ? p.fromBladeIndex : 0
    };
    const { route, routeData, routeName, fromBladeIndex } = payload;
    // Open individual blade from dashboard uses blade index -1
    const currentState =
      fromBladeIndex !== OPEN_FROM_DASHBOARD_INDEX
        ? this.decryptUrlState()
        : {
            openBlades: [
              {
                fromBladeIndex: 0,
                route,
                routeData,
                routeName
              }
            ]
          };
    let shouldRefresh = false;
    if (payload.navigationType === "reset" && route === "Overview") {
      const foundDashboardIndex = _.findIndex(
        currentState.openBlades,
        blade => blade.route === route
      );
      if (foundDashboardIndex !== -1) {
        shouldRefresh = true;
      }
    }
    if (payload.navigationType === "reset") {
      currentState.openBlades = [];
    }

    const foundBladeIndex = _.findIndex(
      currentState.openBlades,
      blade => blade.route === route
    );
    if (foundBladeIndex === -1) {
      if (_.isFinite(payload.fromBladeIndex)) {
        currentState.openBlades.splice(payload.fromBladeIndex + 1);
      }
      currentState.openBlades.push(payload);
    } else {
      // if the blade we found is not the last one
      // drop all the blades to the right of it
      // and then refresh the blade with the new payload
      if (foundBladeIndex !== currentState.openBlades.length - 1) {
        const lastIndex = currentState.openBlades.length - 1;
        currentState.openBlades = _.dropRight(
          currentState.openBlades,
          lastIndex - foundBladeIndex
        );
        currentState.openBlades[foundBladeIndex] = payload;
      } else if (payload.fromBladeIndex < currentState.openBlades.length) {
        currentState.openBlades.splice(payload.fromBladeIndex + 1);
        currentState.openBlades.push(payload);
      }
    }
    this.pushRouteTo(currentState);

    if (shouldRefresh) {
      this.refreshAllOpenBlades();
    }
  };
  public closeBlade = (
    payload:
      | {
          route?: IOpen["route"];
          fromBladeIndex: number;
        }
      | { route: IOpen["route"]; fromBladeIndex?: number }
  ) => {
    const currentState = this.decryptUrlState();
    let indexToClose = payload.fromBladeIndex;
    if (_.isString(payload.route)) {
      const bladeToClose = _.findIndex(
        currentState.openBlades,
        blade => blade.route === payload.route
      );
      indexToClose = bladeToClose;
    }
    // currentState.openBlades = _.filter(
    //   currentState.openBlades,
    //   (b, i) => i !== indexToClose
    // );
    currentState.openBlades.splice(indexToClose);
    if (!currentState.openBlades.length) {
      currentState.openBlades = this.getDefaultRouteState().openBlades;
    }
    this.pushRouteTo(currentState);
  };

  public pushRouteTo = (currentState: IBladeManagerRoutesState) => {
    const firstBlade = _.get(currentState, "openBlades[0].route", "");
    const isDashboard = firstBlade === "Dashboard" || firstBlade === "/";
    if (isDashboard) this.props.history.push("/dashboard");
    if (!isDashboard)
      this.props.history.push(`/blades/${this.encryptUrlState(currentState)}`);
  };

  public renderHeader = () => {
    return (
      <HeaderContainer id="area">
        <Flex style={{ paddingLeft: "22px" }} flexBasis={"33%"} height={32}>
          <VendorLogo />
        </Flex>

        {/* style={{ marginLeft: "80px" }} */}
        <Flex
          style={{
            justifySelf: "flex-end",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "10px",
            paddingTop: "7.5px",
            paddingBottom: "7.5px"
            //width: "500px",
          }}
          flex={1}
        >
          <SearchBar openBlade={this.openBlade} />
          <Flex alignItems="center">
            <CompanySelector
              openBlade={this.openBlade}
              closeBlade={this.closeBlade}
              textPositionInversed={true}
              style={{ marginRight: "38px" }}
            />

            {this.isVendor() ? (
              <ApprovalPendingList
                openBlade={this.openBlade}
                index={
                  this.decryptUrlState()?.openBlades?.length - 1 ||
                  OPEN_FROM_DASHBOARD_INDEX
                }
              />
            ) : null}
            <UserInfoBox />
          </Flex>
        </Flex>
      </HeaderContainer>
    );
  };
  public getBladeId = (blade: IOpenBlade) => {
    return `blade-${blade.routeName}`;
  };
  public openDialog: IBladeBaseProps["openDialog"] = (name, payload) => {
    this.props.appDispatch.bladeManagerActions.openDialog({ name, payload });
  };
  public openAccountAction = () => () => {
    this.props.appDispatch.bladeManagerActions.openDialog({
      name: "AccountDialog",
      payload: null
    });
  };
  public closeDialog: IBladeBaseProps["closeDialog"] = name => {
    this.props.appDispatch.bladeManagerActions.closeDialog({ name });
  };
  public refreshBlade = (route: IOpen["route"]) => {
    if (this.bladeRefreshMap) {
      if (_.isFunction(this.bladeRefreshMap[route])) {
        this.bladeRefreshMap[route]();
      } else {
        console.error(`no refresh fn found for ${route}`);
      }
    }
  };
  public refreshAllOpenBlades = () => {
    const currentState = this.decryptUrlState();
    _.each(currentState.openBlades, blade => {
      this.refreshBlade(blade.route);
    });
    Object.keys(this.entityRefreshMap)?.forEach(key => {
      if (_.isFunction(this.entityRefreshMap[key as EntityRefreshMap])) {
        this.entityRefreshMap[key as EntityRefreshMap]();
      }
    });
  };
  public renderBlades = () => {
    const currentState = this.decryptUrlState();
    const setRefreshFn = (route: IOpen["route"]) => (fn: any) => {
      this.bladeRefreshMap =
        this.bladeRefreshMap || ({} as Record<IOpen["route"], () => void>);
      if (!_.isFunction(this.bladeRefreshMap[route])) {
        this.bladeRefreshMap[route] = fn;
      }
    };
    const setEntityRefreshFn = (entity: EntityRefreshMap) => (fn: any) => {
      this.entityRefreshMap =
        this.entityRefreshMap || ({} as Record<EntityRefreshMap, () => void>);
      if (!_.isFunction(this.entityRefreshMap[entity])) {
        this.entityRefreshMap[entity] = fn;
      }
    };
    const removeEntityRefreshFn = (entity: EntityRefreshMap) => {
      if (_.isFunction(this.entityRefreshMap[entity])) {
        this.entityRefreshMap[entity] = null;
      }
    };
    let Blade:
      | React.SFC<IBladeBaseProps>
      | React.ComponentClass<IBladeBaseProps>;
    let ContainerToUse;

    const Blades = _.map(
      currentState.openBlades,
      (blade: IOpenBlade, index) => {
        Blade = bladeTypes[blade.route] as
          | React.SFC<IBladeBaseProps>
          | React.ComponentClass<IBladeBaseProps>;
        ContainerToUse =
          index === 0 ? FirstBladeContainer : RegularBladeContainer;
        return (
          <ContainerToUse key={blade.route} id={this.getBladeId(blade)}>
            <bladeManagerContext.Provider
              value={{
                lastBladeIndex: currentState.openBlades.length - 1
              }}
            >
              <Blade
                routeData={blade.routeData}
                index={index}
                openBlade={this.openBlade}
                closeBlade={this.closeBlade}
                closeDialog={this.closeDialog}
                openDialog={this.openDialog}
                showScreenLoader={this.showScreenLoader}
                hideScreenLoader={this.hideScreenLoader}
                gateway={getGateway()}
                isVendor={this.isVendor()}
                setRefreshFn={setRefreshFn(blade.route)}
                setEntityRefreshFn={setEntityRefreshFn}
                removeRefreshFn={removeEntityRefreshFn}
                refreshAllOpenBlades={this.refreshAllOpenBlades}
                refreshBlade={this.refreshBlade}
                updateBladeManagerState={this.updateBladeManagerState}
              />
            </bladeManagerContext.Provider>
          </ContainerToUse>
        );
      }
    );

    return Blades;
  };
  public renderSideBar = () => {
    return (
      <ElmSideBar
        activeRole={this.props.appState.activeRole}
        bladeManagerState={this.decryptUrlState()}
        navigateToAppFacet={this.navigateToAppFacet}
        openAccountAction={this.openAccountAction}
        activeMode={this.props.appState.activeMode}
      />
    );
  };
  public getUserEmail = async () => {
    const activeRoleType = _.get(this.props, "appState.activeRole.type");
    if (!activeRoleType || activeRoleType === "company") return;
    const gateway = getGateway();
    const userInfo = await gateway.request.getCurrentUser();
    if (_.isObject(userInfo.data)) {
      this.setState({ userInfo: userInfo.data });
    }
  };
  public render() {
    const currentState = this.decryptUrlState();
    const isDashboardOpen =
      _.get(_.first(currentState.openBlades), "route", "") === "Dashboard"
        ? true
        : false;
    return (
      <React.Fragment>
        <AppModalConductor closeModal={this.closeDialog} />
        {!this.state.isUserRoleTypeAssigned ? (
          <RoleSelectDialog />
        ) : (
          <Flex
            flex={1}
            flexDirection={"column"}
            style={{ height: "100%", width: "100%", position: "absolute" }}
          >
            {this.renderHeader()}
            <Flex flex={1} overflowY="hidden">
              {this.renderSideBar()}
              <BladeSectionContainer
                flex={1}
                flexDirection={"column"}
                isDashboard={isDashboardOpen}
              >
                {!isDashboardOpen && (
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    {this.renderBreadCrumbs()}
                    <OpenGuideButton onClick={this.navigateToOnboardingBlade} />
                  </Flex>
                )}
                <RelayEnvironmentProvider environment={relayEnvironment}>
                  <ProductCountWatcher
                    alertCount={this.navigateToOnboardingBlade}
                  />
                </RelayEnvironmentProvider>
                <ReactCSSTransitionGroup
                  transitionName={transitionName}
                  transitionAppear={true}
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={300}
                  transitionAppearTimeout={500}
                  component={
                    isDashboardOpen
                      ? TransitionContainerDashboard
                      : TransitionContainer
                  }
                >
                  {this.renderBlades()}
                </ReactCSSTransitionGroup>
              </BladeSectionContainer>
            </Flex>
            <FeedbackWidget />
          </Flex>
        )}
        {this.renderScreenLoader()}
      </React.Fragment>
    );
  }
}

export default appConnect(BladeManager, {
  selectors: {
    isAuthenticated: "isSignedInSelector",
    email: "userEmailSelector",
    roles: "userRolesSelector",
    activeRole: "activeRoleSelector",
    currentUser: "currentUserSelector",
    name: "userNameSelector",
    activeMode: "activeModeSelector",
    userDefinedMode: "userDefinedModeSelector",
    onboardingMode: "onboardingModeSelector"
  }
});
