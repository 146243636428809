/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type hooksAnalyticsQueryVariables = {
  bladeScope?: string | null | undefined;
  setup: unknown;
  startTime: string;
  endTime?: string | null | undefined;
  first?: number | null | undefined;
  sortColumn?: string | null | undefined;
  sortDirection?: string | null | undefined;
  filterColumn?: Array<string> | null | undefined;
  filterValues?: Array<Array<string>> | null | undefined;
  skip: boolean;
};
export type hooksAnalyticsQueryResponse = {
  readonly analytics?:
    | {
        readonly categories: ReadonlyArray<string> | null;
        readonly groups: ReadonlyArray<string | null> | null;
        readonly values: ReadonlyArray<unknown>;
        readonly categoryMetadata: ReadonlyArray<{
          readonly id: string | null;
          readonly bladeType: string | null;
        }> | null;
        readonly groupMetadata: ReadonlyArray<{
          readonly id: string | null;
          readonly bladeType: string | null;
        }> | null;
      }
    | null
    | undefined;
};
export type hooksAnalyticsQuery = {
  readonly response: hooksAnalyticsQueryResponse;
  readonly variables: hooksAnalyticsQueryVariables;
};

/*
query hooksAnalyticsQuery(
  $bladeScope: String
  $setup: JSON!
  $startTime: String!
  $endTime: String
  $first: Int
  $sortColumn: String
  $sortDirection: String
  $filterColumn: [String!]
  $filterValues: [[String!]!]
  $skip: Boolean!
) {
  analytics(bladeScope: $bladeScope, setup: $setup, startTime: $startTime, endTime: $endTime, sortColumn: $sortColumn, sortDirection: $sortDirection, first: $first, filterColumn: $filterColumn, filterValues: $filterValues) @skip(if: $skip) {
    categories
    groups
    values
    categoryMetadata {
      id
      bladeType
    }
    groupMetadata {
      id
      bladeType
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "bladeScope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "endTime"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "first"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "setup"
    } as any,
    v6 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "skip"
    } as any,
    v7 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v8 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v9 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "startTime"
    } as any,
    v10 = [
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "id",
        storageKey: null
      } as any,
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "bladeType",
        storageKey: null
      } as any
    ],
    v11 = [
      {
        condition: "skip",
        kind: "Condition",
        passingValue: false,
        selections: [
          {
            alias: null,
            args: [
              {
                kind: "Variable",
                name: "bladeScope",
                variableName: "bladeScope"
              },
              {
                kind: "Variable",
                name: "endTime",
                variableName: "endTime"
              },
              {
                kind: "Variable",
                name: "filterColumn",
                variableName: "filterColumn"
              },
              {
                kind: "Variable",
                name: "filterValues",
                variableName: "filterValues"
              },
              {
                kind: "Variable",
                name: "first",
                variableName: "first"
              },
              {
                kind: "Variable",
                name: "setup",
                variableName: "setup"
              },
              {
                kind: "Variable",
                name: "sortColumn",
                variableName: "sortColumn"
              },
              {
                kind: "Variable",
                name: "sortDirection",
                variableName: "sortDirection"
              },
              {
                kind: "Variable",
                name: "startTime",
                variableName: "startTime"
              }
            ],
            concreteType: "AnalyticsResult",
            kind: "LinkedField",
            name: "analytics",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "categories",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "groups",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "values",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: "AnalyticsMetadata",
                kind: "LinkedField",
                name: "categoryMetadata",
                plural: true,
                selections: v10 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: "AnalyticsMetadata",
                kind: "LinkedField",
                name: "groupMetadata",
                plural: true,
                selections: v10 /*: any*/,
                storageKey: null
              }
            ],
            storageKey: null
          }
        ]
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/,
        v9 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "hooksAnalyticsQuery",
      selections: v11 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v5 /*: any*/,
        v9 /*: any*/,
        v1 /*: any*/,
        v4 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v6 /*: any*/
      ],
      kind: "Operation",
      name: "hooksAnalyticsQuery",
      selections: v11 /*: any*/
    },
    params: {
      cacheID: "d22e576cf0b5f9db14ba07def56e19f2",
      id: null,
      metadata: {},
      name: "hooksAnalyticsQuery",
      operationKind: "query",
      text:
        "query hooksAnalyticsQuery(\n  $bladeScope: String\n  $setup: JSON!\n  $startTime: String!\n  $endTime: String\n  $first: Int\n  $sortColumn: String\n  $sortDirection: String\n  $filterColumn: [String!]\n  $filterValues: [[String!]!]\n  $skip: Boolean!\n) {\n  analytics(bladeScope: $bladeScope, setup: $setup, startTime: $startTime, endTime: $endTime, sortColumn: $sortColumn, sortDirection: $sortDirection, first: $first, filterColumn: $filterColumn, filterValues: $filterValues) @skip(if: $skip) {\n    categories\n    groups\n    values\n    categoryMetadata {\n      id\n      bladeType\n    }\n    groupMetadata {\n      id\n      bladeType\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "d5b40d1c02c02b17aece99a1c8c5a228";
export default node;
