import React from "react";
import { TableRowProps } from "react-virtualized";
import _ from "lodash";
import { ElmButton } from "../elmButton";
import clsx from "clsx";
import { StyledRow } from "./styles";
import theme from "../../theme";

export interface ITableRowProps extends TableRowProps {
  handleClick?: (e: React.MouseEvent<any>) => void;
  handleEditClick?: () => void;
  noNav?: boolean;
  isSelected?: boolean;
  editableRow?: boolean;
  renderActionButtons?: (rowData: TableRowProps["rowData"]) => JSX.Element;
}
export interface ITableRowState {
  isHovered: boolean;
}
export class TableRow extends React.Component<ITableRowProps, ITableRowState> {
  public state = {
    isHovered: false
  };
  public handleMouseOver = () => {
    this.setState({ isHovered: true });
  };
  public handleMouseOut = () => {
    this.setState({ isHovered: false });
  };
  public handleClick = (e: React.MouseEvent<any>) => {
    if (_.isFunction(this.props.handleClick)) {
      this.props.handleClick(e);
    }
  };
  public handleEditClick = () => {
    if (_.isFunction(this.props.handleEditClick)) {
      this.props.handleEditClick();
    }
  };
  public renderActionButtons = () => {
    // if (!this.state.isHovered) {
    //   return null;
    // }
    if (_.isFunction(this.props.renderActionButtons)) {
      return (
        <div
          style={{
            //position: "absolute",
            right: "0px",
            zIndex: 99
          }}
        >
          {this.props.renderActionButtons(this.props.rowData)}
        </div>
      );
    }
    return this.props.editableRow ? (
      <div
        style={{
          position: "absolute",
          right: "0px",
          zIndex: 99,
          display: "flex",
          width: "37px"
        }}
      >
        <ElmButton
          className="darkModeTransparentBtn"
          //style={{ color: theme.colors.black }}
          colorVariance="subtle"
          onClick={this.handleEditClick}
          label=""
          variance="icon-button"
          icon="pen"
          iconPrefix="fas"
        />
      </div>
    ) : null;
  };
  public getClassName = () => {
    return clsx({
      [this.props.className]: !!this.props.className,
      hovered: this.state.isHovered,
      selected: this.props.isSelected,
      "elm-table-row": true
    });
  };
  public render() {
    return (
      <StyledRow
        {..._.omit(this.props, [
          "onRowClick",
          "onRowDoubleClick",
          "onRowMouseOut",
          "onRowMouseOver",
          "onRowRightClick"
        ])}
        isHovered={this.state.isHovered}
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseOut}
        isSelected={this.props.isSelected}
        onClick={this.handleClick}
        className={this.getClassName()}
      >
        {this.props.columns}
        {/* {this.renderActionButtons()} */}
      </StyledRow>
    );
  }
}
