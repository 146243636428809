/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseAttributeAdvanced_license = {
  readonly key: string;
  readonly isTrial: boolean;
  readonly isVendor: boolean;
  readonly product: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly restriction: {
    readonly usersPerInstance: number | null;
    readonly instancesPerUser: number | null;
    readonly sessionsPerUser: number | null;
    readonly sessionsPerInstance: number | null;
    readonly sessionsPerUserPerInstance: number | null;
    readonly requireEmailActivation: boolean | null;
  } | null;
  readonly type: {
    readonly id: string;
  };
  readonly latestTerm: {
    readonly endDate: unknown | null;
    readonly startDate: unknown | null;
  } | null;
  readonly instancesCount: number;
  readonly entitlementsCount: number;
  readonly " $refType": "licenseAttributeAdvanced_license";
};
export type licenseAttributeAdvanced_license$data = licenseAttributeAdvanced_license;
export type licenseAttributeAdvanced_license$key = {
  readonly " $data"?: licenseAttributeAdvanced_license$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"licenseAttributeAdvanced_license">;
};

const node: ReaderFragment = (function() {
  var v0 = {
    alias: null,
    args: null,
    kind: "ScalarField",
    name: "id",
    storageKey: null
  } as any;
  return {
    argumentDefinitions: [],
    kind: "Fragment",
    metadata: null,
    name: "licenseAttributeAdvanced_license",
    selections: [
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "key",
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "isTrial",
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "isVendor",
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: "Product",
        kind: "LinkedField",
        name: "product",
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "name",
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: "Restriction",
        kind: "LinkedField",
        name: "restriction",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "usersPerInstance",
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "instancesPerUser",
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "sessionsPerUser",
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "sessionsPerInstance",
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "sessionsPerUserPerInstance",
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "requireEmailActivation",
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: "LicenseModel",
        kind: "LinkedField",
        name: "type",
        plural: false,
        selections: [v0 /*: any*/],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: "Term",
        kind: "LinkedField",
        name: "latestTerm",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "endDate",
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "startDate",
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "instancesCount",
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "entitlementsCount",
        storageKey: null
      }
    ],
    type: "License",
    abstractKey: null
  } as any;
})();
(node as any).hash = "a15bea1e3ba1b15c515c5062558f4033";
export default node;
