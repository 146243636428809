import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { getGateway } from "../../../../api";
import relayEnvironment from "../../../../api/relay";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { ElmButton } from "../../../../components/elmButton";
import { ElmInput } from "../../../../components/elmInput";
import { DialogContainer } from "components/bladeManager/appDialog/helpers";
import {
  renderErrorNotification,
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { base64DecodeId } from "utils/base64EncodeDecode";

const DialogTitle = styled.span`
  ${props => props.theme.fontType.visualizationTitle}
`;

type state = {
  loading: boolean;
  company_id: string;
  error_email: boolean;
  edit: {
    name: string;
    contact_name: string;
    contact_email: string;
    contact_phone: string;
  };
};

export type IDialogProps = {
  companyId: string;
  editMode?: boolean;
};
export class EditBusinessUnitDialog extends DialogBase<
  IModalProps<IDialogProps> & {
    result?: any;
  },
  state
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          width: "330px"
        }
      }
    });
  }
  businessUnit: any = {};

  state: state = {
    loading: false,
    company_id: this.props.payload?.companyId,
    error_email: false,
    edit: {
      name: this.businessUnit.name || "",
      contact_name: this.businessUnit.contactName || "",
      contact_email: this.businessUnit.contactEmail || "",
      contact_phone: this.businessUnit.contactPhone || ""
    }
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditBusinessUnitDialog"
    });
  };
  public validateEmail = (input: string) => {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input);
    if (!isValid) {
      this.setState({ error_email: true });
      return false;
    }
    this.setState({ error_email: false });
    return true;
  };

  public onConfirm = async () => {
    const isValid = this.validateEmail(this.state.edit?.contact_email);
    if (!isValid) {
      renderErrorNotification("Email not valid !");
      return;
    }
    const gateway = getGateway();
    let response: { graphql_id?: string; id: string };
    const dataToSend = {
      company_id: base64DecodeId(this.state.company_id),
      ...this.state.edit
    };
    try {
      const dataInfo = this.props.payload?.editMode
        ? await gateway.request.editCompany(dataToSend, {
            id: this.props.payload.companyId
          })
        : await gateway.request.newBusinessUnit(dataToSend);

      if (dataInfo.error) {
        console.error("Save failed: ", dataInfo.error);
        renderFailureNotification("Save failed !");
        throw new Error("Error");
      }
      response = dataInfo.data;
      renderSuccessNotification(
        this.props.payload?.editMode
          ? `Business unit info updated !`
          : `New business unit created !`
      );
      if (_.isFunction(_.get(this.props.payload, "onConfirm"))) {
        this.props.payload.onConfirm(response);
      }
      this.closeModal();
    } catch (error) {
      console.error("Save failed: ", error);
    }
  };

  public getUpdateFunction = (value: keyof state["edit"]) => (val: string) => {
    const editCompany = this.state.edit;
    editCompany[value] = val;
    this.setState({ edit: editCompany, error_email: false });
  };
  public renderInfo = () => {
    return (
      <div style={{ marginTop: "24px", marginBottom: "64px" }}>
        <ElmInput
          placeholder={"Name"}
          label={"Enter name"}
          updatedValue={this.getUpdateFunction("name")}
          style={{ marginBottom: "20px" }}
          defaultValue={this.state.edit.name}
        />
        <ElmInput
          placeholder={"Enter contact name"}
          label="Primary Contact"
          updatedValue={this.getUpdateFunction("contact_name")}
          style={{ marginBottom: "20px" }}
          defaultValue={this.state.edit.contact_name}
        />
        <ElmInput
          placeholder={"Email"}
          label="Email"
          updatedValue={this.getUpdateFunction("contact_email")}
          style={{ marginBottom: "20px" }}
          error={this.state.error_email}
          errorMessage={"Email is not valid"}
          defaultValue={this.state.edit.contact_email}
        />
        <ElmInput
          defaultValue={this.state.edit.contact_phone}
          placeholder={"Phone number"}
          style={{ marginBottom: "20px" }}
          label="Phone"
          type="tel"
          updatedValue={this.getUpdateFunction("contact_phone")}
        />
      </div>
    );
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>
          {this.props.payload?.editMode ? "Edit business unit" : "Add a unit"}
        </DialogTitle>
        {this.renderInfo()}
        <Flex justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            label={this.props.payload?.editMode ? "Update" : "Add unit"}
            colorVariance="primary"
            onClick={this.onConfirm}
          />
        </Flex>
      </DialogContainer>
    );
  }
}

export default EditBusinessUnitDialog;

// export const DataContainer = (props: IModalProps<IDialogProps>) => {
//     console.log("PROPS", props)
//   //   const renderEditCompany = (payload: { props: editCompanyQueryResponse }) => {
//   //     if (!payload.props) {
//   //       return null;
//   //     }
//   //     return <EditCompanyDialog {...props} result={payload.props} />;
//   //   };
//   return (
//     <DialogContainer>
//       {props?.payload && !props.payload.editMode && (
//         <EditBusinessUnitDialog {...props} />
//       )}
//       {props?.payload && props.payload.editMode && (
//         <QueryRenderer<editCompanyQuery>
//           environment={relayEnvironment}
//           variables={{
//             id: _.get(props, "payload.companyId")
//           }}
//           query={graphqlQuery}
//           render={renderEditCompany}
//         />
//       )}
//     </DialogContainer>
//   );
// };

// export default DataContainer;
