import * as _ from "lodash";
import * as React from "react";
import NumericInput from "react-numeric-input";
import styled from "styled-components";
import theme from "../../theme";

export interface IElmCounterProps {
  defaultValue?: number;
  onChange?: (value: number) => void;
}
export interface IElmCounterState {
  value: number;
  isFocused: boolean;
}

const StyledCounter = styled(NumericInput)`
  &&.elmcounter {
    border: none !important;
    :focus {
      outline: none !important;
    }
  }
`;

const buttonStyles = {
  color: theme.colors.lightishBlue,
  background: "none",
  border: "none",
  boxShadow: "none"
};
export class ElmCounter extends React.Component<
  IElmCounterProps,
  IElmCounterState
> {
  public state = {
    isFocused: false,
    value: this.props.defaultValue || 0
  };
  public getCustomStyles = () => {
    const activeColor = !this.state.isFocused
      ? theme.colors.black
      : theme.colors.lightishBlue;
    return {
      btnDown: {
        ...buttonStyles
      },
      btnUp: {
        ...buttonStyles
      },
      arrowDown: {
        borderColor: `${activeColor} transparent transparent`
      },
      arrowUp: {
        borderColor: `transparent transparent ${activeColor}`
      },
      input: {
        border: "none",
        maxWidth: "100%"
      },
      wrap: {
        width: "84px",
        border: "none",
        display: "flex",
        alignItems: "center",
        borderBottom: `solid 1px ${activeColor}`
      }
    };
  };
  public handleOnChange = (valueAsNumber: number) => {
    if (_.isFinite(valueAsNumber)) {
      this.setState(
        {
          value: valueAsNumber
        },
        () => {
          if (_.isFunction(this.props.onChange)) {
            this.props.onChange(valueAsNumber);
          }
        }
      );
    }
  };
  public handleOnBlur = () => {
    this.setState({ isFocused: false });
  };
  public handleOnFocus = () => {
    this.setState({ isFocused: true });
  };
  public render() {
    return (
      <StyledCounter
        className={"elmcounter"}
        style={this.getCustomStyles()}
        value={this.state.value}
        onChange={this.handleOnChange}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
      />
    );
  }
}

export default ElmCounter;
