import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import { ElmInput } from "../../../elmInput";
import { notification } from "antd";
import { renderErrorNotification } from "utils/ant-notifications";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;

export interface IEditAdminDialogProps {
  onConfirm: (state: any) => void;
}
export interface IEditAdminDialogState {
  email: string;
  isLoading: boolean;
  error: boolean;
}

export class EditCompanyAdminDialog extends DialogBase<
  IModalProps<IEditAdminDialogProps>
  // &
  // { onConfirm: () => void; }
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  state: IEditAdminDialogState = {
    email: "",
    isLoading: false,
    error: false
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditCompanyAdminDialog"
    });
  };
  public setLoading = (isLoading: boolean) => {
    this.setState({ isLoading });
  };
  public editAdmin = () => {
    const isValid = this.validateEmail(this.state.email);
    if (!isValid) {
      renderErrorNotification("Email not valid !");
      return;
    }
    const { onConfirm } = this.props.payload;
    if (_.isFunction(onConfirm)) {
      this.setLoading(true);
      this.props.payload.onConfirm(this.state);
    }
  };
  public getTitle = () => {
    return "Add administrator";
  };

  public updateEmail = (email: string) => {
    this.setState({ email });
  };
  public validateEmail = (input: string) => {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input);
    if (!isValid) {
      this.setState({ error: true });
      return false;
    }
    this.setState({ error: false });
    return true;
  };

  public render() {
    const { isLoading } = this.state;
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <Flex flexDirection="column">
          <ElmInput
            label={"Email"}
            placeholder="Email"
            style={{ marginTop: "15px" }}
            error={this.state.error}
            errorMessage={"Email is not valid"}
            //disabled={this.props?.payload?.isEdit ? true : false}
            value={this.state.email || ""}
            updatedValue={this.updateEmail}
            controlled={true}
          />
        </Flex>
        <Flex justifyContent={"flex-end"} style={{ marginTop: "15px" }}>
          <ElmButton
            label={"Cancel"}
            colorVariance="outline-secondary"
            onClick={this.closeModal}
          />
          <ElmButton
            colorVariance="primary"
            label={"Confirm"}
            onClick={this.editAdmin}
            disabled={isLoading ? true : false}
            loading={isLoading ? true : false}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
