import React from "react";
import { tableGenerator } from "../../../utils";
import { fragments_companiesTable } from "../../gql/__generated__/fragments_companiesTable.graphql";
import _ from "lodash";
import { AdminsTableList } from "Companies/gql/adminsTableQuery";
import { fragments_adminsTable } from "../../gql/__generated__/fragments_adminsTable.graphql";
import { adminsTable_companies } from "Companies/gql/fragments";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { Flex } from "reflexbox";
import { graphql } from "babel-plugin-relay/macro";
import { shallowEqual } from "fast-equals";
import {
  AdminsTableQuery,
  AdminsTableQueryResponse
} from "./__generated__/AdminsTableQuery.graphql";

export type companyTableEdges = fragments_adminsTable["company"]["companySystemUsers"]["edges"];
export type companyTableType = companyTableEdges[number]["node"];
export type ICompanyTableProps = {
  admins: fragments_companiesTable;
};

export const GeneratedTable = tableGenerator<ICompanyTableProps>({
  tableName: "companies",
  rowHeight: 42,
  columns: [
    {
      Header: "Email",
      accessor: "email",
      sortKey: "system_users.email"
    },
    {
      Header: "Last login",
      accessor: "lastLogin",
      isDateFormat: true,
      disableSortBy: true
    },
    {
      Header: "Actions",
      accessor: "",
      cellRenderer: () => "",
      width: 0.1,
      disableSortBy: true
    }
  ],
  getTotalCount: data => {
    return _.get(data, "admins.company.companySystemUsersCount") || 0;
  },
  connectionQuery: AdminsTableList,
  dataKey: "admins.company.companySystemUsers",
  fragmentSpec: {
    admins: adminsTable_companies
  }
});

const graphqlQuery = graphql`
  query AdminsTableQuery($id: ID!, $blade_scope: String!) {
    ...fragments_adminsTable @arguments(id: $id, blade_scope: $blade_scope)
  }
`;

class AdminsTable extends React.Component<
  IGeneratedTableProps & {
    result: AdminsTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        admins={res}
      />
    );
  }
}

export const CompanyAdminsTable = (
  props: IGeneratedTableProps & { id: string; blade_scope: string }
) => {
  const renderTable = (payload: {
    error: Error;
    props: AdminsTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <AdminsTable {...props} result={payload.props} retry={payload.retry} />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<AdminsTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.id,
        blade_scope: props.blade_scope
      }}
      query={graphqlQuery}
      // fetchPolicy="network-only"
      render={renderTable}
    />
  );
};

export default CompanyAdminsTable;
