import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import React from "react";
import { instanceHistoryTable_instance } from "./__generated__/instanceHistoryTable_instance.graphql";
import { tableGenerator } from "../../utils/tableGenerator";
import { TableCell } from "../elmTable";

export type instanceHistoryEdges = instanceHistoryTable_instance["instance"]["modificationRecords"]["edges"];
export type instanceTableType = instanceHistoryEdges[number]["node"];
export type IInstanceHistoryProps = {
  instance: instanceHistoryTable_instance;
};
const InstanceHistoryTable = tableGenerator<IInstanceHistoryProps>({
  dataKey: "instance.instance.modificationRecords",
  // getNodesTransform: props => {
  //   const edges = _.get(
  //     props,
  //     "history.history.productUsers.edges"
  //   ) as userProductHistoryTable_history["history"]["productUsers"]["edges"];
  //   const transform = _.reduce(
  //     edges,
  //     (historyInfo, info) => {
  //       _.each(info.node.modificationRecords.edges, record => {
  //         historyInfo.push({
  //           name: info.node.product.name,
  //           ...record.node
  //         });
  //       });
  //       return historyInfo;
  //     },
  //     []
  //   );
  //   return transform;
  // },
  columns: [
    // {
    //   Header: "Product",
    //   accessor: "name"
    // },
    {
      Header: "Activity",
      accessor: "enabled",
      cellRenderer: payload => {
        return (
          <TableCell>
            {payload.cellData === 1 ? "Enabled" : "Disabled"}
          </TableCell>
        );
      }
    },
    {
      Header: "Time",
      accessor: "createdAt",
      dateFormat: "hh:ss A",
      isDateFormat: true
    },
    {
      Header: "Date",
      accessor: "createdAt",
      isDateFormat: true
    }
  ],
  connectionQuery: graphql`
    query instanceHistoryTablePaginationQuery(
      $id: ID!
      $count: Int!
      $cursor: String #$search: String
    ) {
      ...instanceHistoryTable_instance
        @arguments(id: $id, count: $count, cursor: $cursor)
    }
  `,
  fragmentSpec: {
    instance: graphql`
      fragment instanceHistoryTable_instance on Query
        @argumentDefinitions(
          id: { type: "ID!" }
          count: { type: "Int", defaultValue: 6 }
          cursor: { type: "String" }
          #search: { type: "String", defaultValue: null }
        ) {
        instance(id: $id) {
          modificationRecords(first: $count, after: $cursor)
            @connection(key: "instanceHistoryTable_modificationRecords") {
            edges {
              node {
                enabled
                createdAt
              }
            }
          }
        }
      }
    `
  }
});
export default InstanceHistoryTable;
