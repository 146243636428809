/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type companyAnalyticsQueryVariables = {
  currentTimeSpanStart: string;
  currentTimeSpanEnd: string;
  productId: string;
};
export type companyAnalyticsQueryResponse = {
  readonly LicenseAnalyticsCurrentExpiringLicenses: {
    readonly nodes: ReadonlyArray<{
      readonly type: {
        readonly name: string;
        readonly id: string;
      };
    } | null> | null;
  } | null;
};
export type companyAnalyticsQuery = {
  readonly response: companyAnalyticsQueryResponse;
  readonly variables: companyAnalyticsQueryVariables;
};

/*
query companyAnalyticsQuery(
  $currentTimeSpanStart: String!
  $currentTimeSpanEnd: String!
  $productId: String!
) {
  LicenseAnalyticsCurrentExpiringLicenses: licenses(filterColumn: ["latestTerm.endDate", "latestTerm.endDate"], filterOp: [[">", "="], ["<", "="]], filterValues: [[$currentTimeSpanStart, $currentTimeSpanStart], [$currentTimeSpanEnd, $currentTimeSpanEnd]], bladeScope: $productId) {
    nodes {
      type {
        name
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "currentTimeSpanEnd"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "currentTimeSpanStart"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "productId"
    } as any,
    v3 = [
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "productId"
      } as any,
      {
        kind: "Literal",
        name: "filterColumn",
        value: ["latestTerm.endDate", "latestTerm.endDate"]
      } as any,
      {
        kind: "Literal",
        name: "filterOp",
        value: [
          [">", "="],
          ["<", "="]
        ]
      } as any,
      {
        items: [
          {
            items: [
              {
                kind: "Variable",
                name: "filterValues.0.0",
                variableName: "currentTimeSpanStart"
              },
              {
                kind: "Variable",
                name: "filterValues.0.1",
                variableName: "currentTimeSpanStart"
              }
            ],
            kind: "ListValue",
            name: "filterValues.0"
          },
          {
            items: [
              {
                kind: "Variable",
                name: "filterValues.1.0",
                variableName: "currentTimeSpanEnd"
              },
              {
                kind: "Variable",
                name: "filterValues.1.1",
                variableName: "currentTimeSpanEnd"
              }
            ],
            kind: "ListValue",
            name: "filterValues.1"
          }
        ],
        kind: "ListValue",
        name: "filterValues"
      } as any
    ],
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      concreteType: "LicenseModel",
      kind: "LinkedField",
      name: "type",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "name",
          storageKey: null
        },
        v4 /*: any*/
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: "Fragment",
      metadata: null,
      name: "companyAnalyticsQuery",
      selections: [
        {
          alias: "LicenseAnalyticsCurrentExpiringLicenses",
          args: v3 /*: any*/,
          concreteType: "LicenseConnection",
          kind: "LinkedField",
          name: "licenses",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "License",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [v5 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/, v2 /*: any*/],
      kind: "Operation",
      name: "companyAnalyticsQuery",
      selections: [
        {
          alias: "LicenseAnalyticsCurrentExpiringLicenses",
          args: v3 /*: any*/,
          concreteType: "LicenseConnection",
          kind: "LinkedField",
          name: "licenses",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "License",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [v5 /*: any*/, v4 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "0bff5aa72c3feda755fe6b453b92fe25",
      id: null,
      metadata: {},
      name: "companyAnalyticsQuery",
      operationKind: "query",
      text:
        'query companyAnalyticsQuery(\n  $currentTimeSpanStart: String!\n  $currentTimeSpanEnd: String!\n  $productId: String!\n) {\n  LicenseAnalyticsCurrentExpiringLicenses: licenses(filterColumn: ["latestTerm.endDate", "latestTerm.endDate"], filterOp: [[">", "="], ["<", "="]], filterValues: [[$currentTimeSpanStart, $currentTimeSpanStart], [$currentTimeSpanEnd, $currentTimeSpanEnd]], bladeScope: $productId) {\n    nodes {\n      type {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "ab18583e638d1315ab15186b1d711c48";
export default node;
