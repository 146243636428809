import * as _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { getGateway } from "../../../../api";
import { LoaderContainer } from "../../../../components/helpers";
import {
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { ElmButton, ElmInput } from "components";
import { getEnvironment } from "api/relay";
import { InfoMessage } from "Products/components/dialogs/editComponent";
import {
  editLicenseComponentRequirementsQuery,
  editLicenseComponentRequirementsQueryResponse
} from "./__generated__/editLicenseComponentRequirementsQuery.graphql";
import { ElmSelectOption, ElmSelectWithLabel } from "components/elmSelect";
import ElmRadio from "components/elmRadio";
import ElmDatePicker from "components/elmDate";
import { DATE_FORMAT } from "const";
import dayjs from "dayjs";
import { LicenseQueryResponse } from "Licenses/blades/License/__generated__/LicenseQuery.graphql";

const graphqlQuery = graphql`
  query editLicenseComponentRequirementsQuery($id: ID!) {
    component(id: $id) {
      id
      name
      componentType
      componentGroup {
        id
        name
        components {
          nodes {
            id
            name
            componentType
          }
        }
      }
      productComponentRequirements {
        canInherit
        freeTrial
        freeTrialActive
        freeTrialExpiration
        isIncluded
        isInherited
        productVersionId
        tokenCount
        wouldInheritFromBaseLicense
      }
      product {
        id
        name
        iconUrl
        licenses {
          nodes {
            id
            owner {
              id
              name
            }
          }
        }
        productVersions {
          nodes {
            componentRequirementsCount
            id
            ordinal
            versionString
          }
        }
      }
    }
  }
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 330px;
`;
const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
  margin-bottom: 21px;
  margin-top: 0;
`;

const ToggleText = styled.p`
  font-size: ${props => props.theme.fontSizes.ySmall};
  line-height: ${props => props.theme.fontSizes.ySmall};
  margin: 0;
`;

const DatePickerLabel = styled.p`
  font-size: ${props => props.theme.fontSizes.xSmall};
  line-height: ${props => props.theme.fontSizes.xSmall};
  color: ${props => props.theme.colors.warmGrey};
  margin: 0;
`;

type LicenseComponentRequirementNode = LicenseQueryResponse["license"]["licenseComponents"]["nodes"][number]["licenseComponentRequirements"];
type ProductComponentRequirementNode = LicenseQueryResponse["license"]["licenseComponents"]["nodes"][number]["productComponentRequirements"];
type ProductVersionNode = editLicenseComponentRequirementsQueryResponse["component"]["product"]["productVersions"]["nodes"][number];

interface IDialogProps {
  id: string;
  isEditFreeTrial: boolean;
  licenseComponentRequirements: LicenseComponentRequirementNode;
  productComponentRequirements: ProductComponentRequirementNode;
  licenseId: string;
  productId: string;
  refresh?: () => void;
}
type state = {
  isSameAsPreviousVersion: boolean;
  selectedVersion: "latest" | "allVersions" | string;
  baseRequirements: string;
  canInherit: boolean;
  wouldInheritFromBaseLicense: boolean;
  productVersionId: string;
  licenseId: string;
  tokenCount: string;
  isIncluded: boolean;
  isInherited: boolean;
  freeTrialActive: boolean;
  freeTrialExpiration: string;
};
class DialogBody extends DialogBase<
  IModalProps<IDialogProps> & {
    result: editLicenseComponentRequirementsQueryResponse;
    retry: () => void;
  },
  state
> {
  licenseComponentRequirements = _.get(
    this.props.payload,
    "licenseComponentRequirements"
  );
  productComponentRequirements = _.get(
    this.props.payload,
    "productComponentRequirements"
  );
  latestVersionRequirements = _.last(this.licenseComponentRequirements);
  latestProductRequirements = _.last(this.productComponentRequirements);
  productVersions: ProductVersionNode[] = _.get(
    this.props.result,
    "component.product.productVersions.nodes",
    []
  );
  latestProductVersion = _.last(this.productVersions);

  state: state = {
    isSameAsPreviousVersion: true,
    selectedVersion: this.latestProductVersion?.id,
    baseRequirements: this.latestProductRequirements?.tokenCount,
    wouldInheritFromBaseLicense: this.latestVersionRequirements
      ?.wouldInheritFromBaseLicense,
    productVersionId: this.latestVersionRequirements?.productVersionId,
    licenseId: _.get(this.props.payload, "licenseId"),
    canInherit: this.latestVersionRequirements?.canInherit,
    tokenCount: this.latestVersionRequirements?.tokenCount,
    freeTrialActive: this.latestVersionRequirements?.freeTrial,
    freeTrialExpiration: this.latestVersionRequirements?.freeTrialExpiration as
      | string
      | null,
    isIncluded: this.latestVersionRequirements?.isIncluded,
    isInherited: this.latestVersionRequirements?.isInherited
  };

  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditLicenseComponentRequirementsDialog"
    });
  };
  public handleConfirm = async () => {
    const gateway = getGateway();
    if (
      this.props?.payload?.isEditFreeTrial &&
      this.state.selectedVersion === "allVersions"
    ) {
      try {
        await Promise.all(
          this.licenseComponentRequirements?.map(async version => {
            await gateway.request["editLicenseComponentRequirement"](
              {
                would_inherit_from_base_license:
                  version.wouldInheritFromBaseLicense,
                product_version_id: version.productVersionId,
                license_id: this.state.licenseId,
                can_inherit: version.canInherit,
                is_included: true,
                is_inherited: false,
                token_count: version.tokenCount,
                free_trial: true,
                free_trial_expiration: this.state.freeTrialExpiration
              },
              {
                id: this.props.payload?.id
              }
            );
          })
        );
        renderSuccessNotification(`Updated Free trial for license component`);
      } catch (e) {
        console.error(e);
        renderFailureNotification(
          `Failed to update Free trial for license component`
        );
      }
      this.closeModal();
      this.props.payload.refresh();
      return;
    }

    gateway.request["editLicenseComponentRequirement"](
      {
        would_inherit_from_base_license: this.state.wouldInheritFromBaseLicense,
        product_version_id:
          this.state.productVersionId === "latest"
            ? this.latestProductVersion.id
            : this.state.productVersionId,
        license_id: this.state.licenseId,
        can_inherit: this.state.canInherit,
        is_included: true,
        is_inherited: false,
        token_count: this.state.tokenCount,
        free_trial: this.state.freeTrialActive || false,
        free_trial_expiration: this.state.freeTrialExpiration
      },
      {
        id: this.props.payload?.id
      }
    )
      .then(res => {
        if (!res.error) {
          renderSuccessNotification("Updated component requirements !");
          this.closeModal();
          this.props.payload.refresh();
        }
      })
      .catch(err => {
        renderFailureNotification("Update component requirements failed !");
        console.error(err);
      });
  };

  public handleRadioChange = () => {
    this.setState({
      isSameAsPreviousVersion: !this.state.isSameAsPreviousVersion
    });
  };

  public renderEditTokenRequirements = () => {
    const options = _.reverse([...this.productVersions]);
    return (
      <>
        <InfoMessage
          description="Editing the requirement for this version will affect any subsequent versions inherited from this version."
          style={{ width: "100%", marginBottom: 21 }}
          type="warning"
        />
        <ElmSelectWithLabel
          label="Component version"
          value={
            _.get(this.state, "productVersionId") ===
            this.latestProductVersion?.id
              ? "Latest"
              : _.get(this.state, "productVersionId")
          }
          onSelect={v => {
            const versionRequirements = this.licenseComponentRequirements?.find(
              r => r.productVersionId === v
            );
            const productRequirements = this.productComponentRequirements?.find(
              r => r.productVersionId === v
            );
            if (versionRequirements) {
              //@ts-ignore
              this.setState({
                ...this.state,
                ...versionRequirements,
                baseRequirements: productRequirements?.tokenCount
              });
            }
          }}
        >
          {options?.map(v => {
            return (
              <ElmSelectOption key={v.id} value={v.id} label={v.versionString}>
                {v.versionString}
              </ElmSelectOption>
            );
          })}
        </ElmSelectWithLabel>
        <Flex
          style={{
            marginTop: 21,
            marginBottom: 21,
            flexDirection: "column",
            gap: 8
          }}
        >
          <Flex alignItems={"center"}>
            <ElmRadio
              checked={this.state.isSameAsPreviousVersion}
              onChange={this.handleRadioChange}
            />
            <ToggleText>Same as previous version</ToggleText>
          </Flex>
          <Flex alignItems={"center"}>
            <ElmRadio
              checked={!this.state.isSameAsPreviousVersion}
              onChange={this.handleRadioChange}
            />
            <ToggleText>Custom requirements</ToggleText>
          </Flex>
        </Flex>

        {this.state.isSameAsPreviousVersion ? null : (
          <ElmInput
            updatedValue={val => this.setState({ tokenCount: val })}
            controlled={true}
            value={_.get(this.state, "tokenCount")}
            style={{ marginBottom: 21 }}
            label={"Token cost"}
            note={
              this.state.baseRequirements
                ? `Base requirements: ${this.state.baseRequirements} tokens`
                : ""
            }
            type="number"
            required={true}
            // error={this.state.error.user_count}
            errorMessage={"Token value is required"}
            placeholder={"Enter token value"}
          />
        )}
      </>
    );
  };

  public renderEditFreeTrial = () => {
    return (
      <Flex style={{ marginBottom: 21, flexDirection: "column" }}>
        <DatePickerLabel>Free trial until</DatePickerLabel>
        <ElmDatePicker
          format={DATE_FORMAT}
          defaultValue={
            this.latestVersionRequirements?.freeTrialExpiration
              ? dayjs(`${this.latestVersionRequirements?.freeTrialExpiration}`)
              : null
          }
          onChange={date =>
            this.setState({
              freeTrialActive: date.isAfter(dayjs()),
              freeTrialExpiration: date.toISOString()
            })
          }
          style={{ marginBottom: 21 }}
        />
        <ElmSelectWithLabel
          label="Version"
          value={
            this.state.selectedVersion === "allVersions"
              ? "All versions"
              : "Latest"
          }
          onSelect={v => {
            if (v === "latest") {
              const versionRequirements = this.licenseComponentRequirements?.find(
                r => r.productVersionId === v
              );
              const productRequirements = this.productComponentRequirements?.find(
                r => r.productVersionId === v
              );
              if (versionRequirements) {
                this.setState({
                  ...this.state,
                  ...versionRequirements,
                  baseRequirements: productRequirements?.tokenCount
                });
              }
            }
            this.setState({
              selectedVersion: v
            });
          }}
        >
          <ElmSelectOption value={"latest"}>Latest</ElmSelectOption>
          <ElmSelectOption value={"allVersions"}>All versions</ElmSelectOption>
        </ElmSelectWithLabel>
      </Flex>
    );
  };

  public renderBody = () => {
    return (
      <DialogContainer>
        <DialogTitle>
          {this.props?.payload?.isEditFreeTrial
            ? this.latestVersionRequirements?.freeTrialExpiration
              ? "Edit free trial for Component"
              : "Add free trial for Component"
            : "Edit Component requirements"}
        </DialogTitle>
        <Flex
          flexDirection={"column"}
          height={"100%"}
          justifyContent={"space-between"}
        >
          <Flex flexDirection={"column"}>
            {this.props?.payload?.isEditFreeTrial
              ? this.renderEditFreeTrial()
              : this.renderEditTokenRequirements()}
          </Flex>

          <Flex
            alignItems="flex-end"
            justifyContent={"flex-end"}
            marginTop={24}
          >
            <ElmButton
              label={"Cancel"}
              colorVariance={"outline-secondary"}
              onClick={this.closeModal}
            />
            <ElmButton
              label={"Update"}
              colorVariance="primary"
              onClick={this.handleConfirm}
            />
          </Flex>
        </Flex>
      </DialogContainer>
    );
  };

  public render() {
    return this.renderBody();
  }
}

export const EditLicenseComponentRequirementsDialog = (props: any) => {
  const renderDialog = (payload: {
    error: Error;
    props: editLicenseComponentRequirementsQueryResponse;
    retry: () => void;
  }) => {
    if (!payload.props) {
      return <DialogContainer>{LoaderContainer()}</DialogContainer>;
    }
    return (
      <DialogBody {...props} result={payload.props} retry={payload.retry} />
    );
  };
  return (
    <QueryRenderer<editLicenseComponentRequirementsQuery>
      environment={getEnvironment()}
      cacheConfig={{
        force: true
      }}
      variables={{ id: props.payload?.id }}
      query={graphqlQuery}
      render={renderDialog}
    />
  );
};
