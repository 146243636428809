/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type productsTable_products = {
  readonly productsCount: number;
  readonly products: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly activeLicenseCount?: number | undefined;
        readonly id: string;
        readonly name: string | null;
        readonly iconUrl: string | null;
        readonly activeUsers: number;
        readonly type: {
          readonly name: string;
        };
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $refType": "productsTable_products";
};
export type productsTable_products$data = productsTable_products;
export type productsTable_products$key = {
  readonly " $data"?: productsTable_products$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"productsTable_products">;
};

const node: ReaderFragment = (function() {
  var v0 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    v1 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "blade_scope"
      },
      {
        defaultValue: 25,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      },
      {
        defaultValue: false,
        kind: "LocalArgument",
        name: "skipLicenseCount"
      },
      {
        defaultValue: ["product.name"],
        kind: "LocalArgument",
        name: "sortColumn"
      },
      {
        defaultValue: ["asc"],
        kind: "LocalArgument",
        name: "sortDirection"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["products"]
        }
      ]
    },
    name: "productsTable_products",
    selections: [
      {
        alias: null,
        args: [v0 /*: any*/, v1 /*: any*/],
        kind: "ScalarField",
        name: "productsCount",
        storageKey: null
      },
      {
        alias: "products",
        args: [
          v0 /*: any*/,
          v1 /*: any*/,
          {
            kind: "Variable",
            name: "sortColumn",
            variableName: "sortColumn"
          },
          {
            kind: "Variable",
            name: "sortDirection",
            variableName: "sortDirection"
          }
        ],
        concreteType: "ProductConnection",
        kind: "LinkedField",
        name: "__productsTable_products_connection",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "ProductEdge",
            kind: "LinkedField",
            name: "edges",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "cursor",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: "Product",
                kind: "LinkedField",
                name: "node",
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "id",
                    storageKey: null
                  },
                  v2 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "iconUrl",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "activeUsers",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: "ProductCategory",
                    kind: "LinkedField",
                    name: "type",
                    plural: false,
                    selections: [v2 /*: any*/],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "__typename",
                    storageKey: null
                  },
                  {
                    condition: "skipLicenseCount",
                    kind: "Condition",
                    passingValue: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "activeLicenseCount",
                        storageKey: null
                      }
                    ]
                  }
                ],
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: "PageInfo",
            kind: "LinkedField",
            name: "pageInfo",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "hasNextPage",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "endCursor",
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "f8086fd7faf7b514518a0698e001ca4b";
export default node;
