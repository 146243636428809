import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { InfoMessage } from "../../../helpers";
import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { ElmCheckBox } from "../../../elmCheckBox";
import { ElmInput } from "../../../elmInput";
import { DialogBase } from "../base";
import { IModalProps } from "../types";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
export interface INewApiTokenDialogProps {
  scopes: { description: string; id: string }[];
}
export interface INewApiTokenDialogState {
  description: string;
  scopes: { description: string; id: string }[];
}
export class NewApiTokenDialog extends DialogBase<
  IModalProps<INewApiTokenDialogProps>,
  INewApiTokenDialogState
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  state: INewApiTokenDialogState = {
    description: "",
    scopes: []
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "NewApiTokenDialog"
    });
  };
  public createApiToken = () => {
    if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
      this.props.payload.onConfirm(this.state);
    }
  };
  public getTitle = () => {
    return "Add a new API Token";
  };
  public getDescription = () => {
    return "Add a description below and elm will autogenerate a token with that description.";
  };
  public getUpdateFunction = (type: "description") => (val: string) => {
    this.setState({ [type]: val });
  };
  public getScopes = () => {
    if (
      !_.isObject(this.props.payload) ||
      !_.isArray(this.props.payload.scopes)
    ) {
      return [];
    }
    return this.props.payload.scopes;
  };
  public handleScopeSelectionChange = (scope: {
    id: string;
    description: string;
  }) => (e: CheckboxChangeEvent) => {
    const newScopes = _.cloneDeep(this.state.scopes);
    if (e.target.checked) {
      this.setState({ scopes: [...newScopes, scope] });
    } else {
      this.setState({ scopes: _.filter(newScopes, s => s.id !== scope.id) });
    }
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <InfoMessage description={this.getDescription()} type="warning" />
        <Flex>
          <ElmInput
            style={{ marginTop: "15px" }}
            label={"Description"}
            updatedValue={this.getUpdateFunction("description")}
          />
        </Flex>
        <p style={{ fontWeight: 700, marginTop: "10px" }}>Scope: </p>
        <Flex style={{ marginBottom: "15px" }} flexDirection="column">
          {_.map(this.getScopes(), scope => (
            <Flex style={{ marginRight: "10px" }}>
              <ElmCheckBox
                value={scope.id}
                onChange={this.handleScopeSelectionChange(scope)}
              />
              <span style={{ marginLeft: "5px" }}>{scope.description}</span>
            </Flex>
          ))}
        </Flex>
        <Flex justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance="outline-secondary"
            onClick={this.closeModal}
          />
          <ElmButton
            colorVariance="primary"
            label={"Confirm"}
            onClick={this.createApiToken}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
