/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type editLicenseComponentRequirementsQueryVariables = {
  id: string;
};
export type editLicenseComponentRequirementsQueryResponse = {
  readonly component: {
    readonly id: string;
    readonly name: string | null;
    readonly componentType: string;
    readonly componentGroup: {
      readonly id: string;
      readonly name: string | null;
      readonly components: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly name: string | null;
          readonly componentType: string;
        } | null> | null;
      } | null;
    } | null;
    readonly productComponentRequirements: ReadonlyArray<{
      readonly canInherit: boolean;
      readonly freeTrial: boolean;
      readonly freeTrialActive: boolean | null;
      readonly freeTrialExpiration: unknown | null;
      readonly isIncluded: boolean;
      readonly isInherited: boolean;
      readonly productVersionId: string;
      readonly tokenCount: number | null;
      readonly wouldInheritFromBaseLicense: boolean;
    }> | null;
    readonly product: {
      readonly id: string;
      readonly name: string | null;
      readonly iconUrl: string | null;
      readonly licenses: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly owner: {
            readonly id: string;
            readonly name: string | null;
          } | null;
        } | null> | null;
      } | null;
      readonly productVersions: {
        readonly nodes: ReadonlyArray<{
          readonly componentRequirementsCount: number;
          readonly id: string;
          readonly ordinal: number;
          readonly versionString: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type editLicenseComponentRequirementsQuery = {
  readonly response: editLicenseComponentRequirementsQueryResponse;
  readonly variables: editLicenseComponentRequirementsQueryVariables;
};

/*
query editLicenseComponentRequirementsQuery(
  $id: ID!
) {
  component(id: $id) {
    id
    name
    componentType
    componentGroup {
      id
      name
      components {
        nodes {
          id
          name
          componentType
        }
      }
    }
    productComponentRequirements {
      canInherit
      freeTrial
      freeTrialActive
      freeTrialExpiration
      isIncluded
      isInherited
      productVersionId
      tokenCount
      wouldInheritFromBaseLicense
    }
    product {
      id
      name
      iconUrl
      licenses {
        nodes {
          id
          owner {
            __typename
            id
            name
          }
        }
      }
      productVersions {
        nodes {
          componentRequirementsCount
          id
          ordinal
          versionString
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "componentType",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      concreteType: "ComponentGroup",
      kind: "LinkedField",
      name: "componentGroup",
      plural: false,
      selections: [
        v2 /*: any*/,
        v3 /*: any*/,
        {
          alias: null,
          args: null,
          concreteType: "ComponentConnection",
          kind: "LinkedField",
          name: "components",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Component",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      concreteType: "ComponentRequirement",
      kind: "LinkedField",
      name: "productComponentRequirements",
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "canInherit",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "freeTrial",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "freeTrialActive",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "freeTrialExpiration",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "isIncluded",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "isInherited",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "productVersionId",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "tokenCount",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "wouldInheritFromBaseLicense",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "iconUrl",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      concreteType: "ProductVersionConnection",
      kind: "LinkedField",
      name: "productVersions",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ProductVersion",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "componentRequirementsCount",
              storageKey: null
            },
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "ordinal",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "versionString",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "editLicenseComponentRequirementsQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Component",
          kind: "LinkedField",
          name: "component",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                v7 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "LicenseConnection",
                  kind: "LinkedField",
                  name: "licenses",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "License",
                      kind: "LinkedField",
                      name: "nodes",
                      plural: true,
                      selections: [
                        v2 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: null,
                          kind: "LinkedField",
                          name: "owner",
                          plural: false,
                          selections: [v2 /*: any*/, v3 /*: any*/],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                v8 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "editLicenseComponentRequirementsQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Component",
          kind: "LinkedField",
          name: "component",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                v7 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "LicenseConnection",
                  kind: "LinkedField",
                  name: "licenses",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "License",
                      kind: "LinkedField",
                      name: "nodes",
                      plural: true,
                      selections: [
                        v2 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: null,
                          kind: "LinkedField",
                          name: "owner",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "__typename",
                              storageKey: null
                            },
                            v2 /*: any*/,
                            v3 /*: any*/
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                v8 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "57cd40cdc8a6c659aa1bc05ecec4e2f0",
      id: null,
      metadata: {},
      name: "editLicenseComponentRequirementsQuery",
      operationKind: "query",
      text:
        "query editLicenseComponentRequirementsQuery(\n  $id: ID!\n) {\n  component(id: $id) {\n    id\n    name\n    componentType\n    componentGroup {\n      id\n      name\n      components {\n        nodes {\n          id\n          name\n          componentType\n        }\n      }\n    }\n    productComponentRequirements {\n      canInherit\n      freeTrial\n      freeTrialActive\n      freeTrialExpiration\n      isIncluded\n      isInherited\n      productVersionId\n      tokenCount\n      wouldInheritFromBaseLicense\n    }\n    product {\n      id\n      name\n      iconUrl\n      licenses {\n        nodes {\n          id\n          owner {\n            __typename\n            id\n            name\n          }\n        }\n      }\n      productVersions {\n        nodes {\n          componentRequirementsCount\n          id\n          ordinal\n          versionString\n        }\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "dca069021f6cb0215222fdfa839ba65f";
export default node;
