import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Flex } from "reflexbox/styled-components";
import { tableGenerator } from "../../../utils";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import { productUsersTable_users } from "./__generated__/productUsersTable_users.graphql";
import { UsersTableColumnConfig } from "Users/components/usersTable";
import _ from "lodash";
import {
  productUsersTableQuery,
  productUsersTableQueryResponse
} from "./__generated__/productUsersTableQuery.graphql";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { shallowEqual } from "fast-equals";

export type ProductUsersTableType = productUsersTable_users["users"]["edges"][number]["node"];

const GeneratedTable = tableGenerator<{
  users: productUsersTable_users;
}>({
  tableName: "productUsersTable",
  dataKey: "users.users",
  getTotalCount: data => {
    return _.get(data, "users.usersCount") || 0;
  },
  columns: [
    UsersTableColumnConfig.Status,
    UsersTableColumnConfig.User,
    UsersTableColumnConfig.Email,
    UsersTableColumnConfig.Installs
  ],
  connectionQuery: graphql`
    query productUsersTablePaginationQuery(
      $count: Int!
      $cursor: String
      $blade_scope: String
      $search: String
      $filterColumn: [String!]
      $filterValues: [[String!]!]
      $filterOp: [[String!]!]
    ) {
      ...productUsersTable_users
        @arguments(
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          count: $count
          cursor: $cursor
          blade_scope: $blade_scope
          search: $search
        )
    }
  `,
  fragmentSpec: {
    users: graphql`
      fragment productUsersTable_users on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          blade_scope: { type: "String", defaultValue: null }
          search: { type: "String", defaultValue: null }
          filterColumn: { type: "[String!]", defaultValue: ["users.active"] }
          filterOp: { type: "[[String!]!]", defaultValue: [["="]] }
          filterValues: { type: "[[String!]!]", defaultValue: [["true"]] }
        ) {
        usersCount(
          search: $search
          bladeScope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        )
        users(
          first: $count
          after: $cursor
          bladeScope: $blade_scope
          search: $search
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        ) @connection(key: "productUsersTable_users") {
          edges {
            node {
              id
              name
              email
              active
              instancesCount(bladeScope: $blade_scope)
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query productUsersTableQuery($blade_scope: String!) {
    ...productUsersTable_users @arguments(blade_scope: $blade_scope)
  }
`;

class UsersTable extends React.Component<
  IGeneratedTableProps & {
    result: productUsersTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        users={res}
      />
    );
  }
}

export const ProductUsersTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: productUsersTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <UsersTable {...props} result={payload.props} retry={payload.retry} />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<productUsersTableQuery>
      environment={relayEnvironment}
      variables={{
        blade_scope: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};
