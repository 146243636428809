/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userAttributeAdvanced_user = {
  readonly linkedUsersCount: number;
  readonly instancesCount: number;
  readonly guid: string;
  readonly info: unknown | null;
  readonly owner: {
    readonly name: string | null;
  } | null;
  readonly location: {
    readonly city: string | null;
  } | null;
  readonly " $refType": "userAttributeAdvanced_user";
};
export type userAttributeAdvanced_user$data = userAttributeAdvanced_user;
export type userAttributeAdvanced_user$key = {
  readonly " $data"?: userAttributeAdvanced_user$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"userAttributeAdvanced_user">;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: "Fragment",
  metadata: null,
  name: "userAttributeAdvanced_user",
  selections: [
    {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "linkedUsersCount",
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "instancesCount",
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "guid",
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "info",
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: null,
      kind: "LinkedField",
      name: "owner",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "name",
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: "Location",
      kind: "LinkedField",
      name: "location",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "city",
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "User",
  abstractKey: null
} as any;
(node as any).hash = "0106f51e1095ab980dd6742b2d160f17";
export default node;
