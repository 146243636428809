import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import { FieldsContainer } from "../../../../../components/bladeManager/appDialog/helpers";
import { DialogBase } from "../../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../../components/bladeManager/appDialog";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import { getIcon } from "../../../../../components/icons";
//@ts-ignore
import { useSelector } from "react-redux";
import { ILicenseModel } from "api/gatewayMap";
import { getGateway } from "api";
import Loader from "react-loaders";
import dayjs from "dayjs";
import selectors from "store/selectors";
import { IOnboardingState } from "store/types";
import { getOnboardingConfigSync } from "blades/Welcome/utils/storage";
import {
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { base64EncodeId } from "utils/base64EncodeDecode";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
`;
const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const Subtitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.warmGrey};
`;
const StyledInputLabel = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  color: ${props => props.theme.colors.warmGrey};
  letter-spacing: 1.5px;
  margin-top: 33px;
  padding-bottom: 8px;
  border-bottom: 1px solid #bebdbc;
`;
const CopyContainer = styled(Flex)`
  align-items: center;
  padding: 2px;
  margin-top: 5px;
  border-radius: 3px;
  height: 18px;
  :hover {
    cursor: pointer;
  }

  span {
    height: 15px;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    width: 95%;
    color: ${props => props.theme.colors.textPrimary};
  }
`;
const ClipboardIcon = styled(getIcon("MdContentCopy"))`
  color: ${props => props.theme.colors.iconColor};
`;
export const AttributeLabel = styled.p<{ labelColor?: string }>`
  ${props => props.theme.fontType.paragraph}
  color: ${props => props.labelColor || props.theme.colors.textPrimary};
`;
export interface ILicenseCreatedDialogProps {
  companyId: string;
  companyName: string;
}
export interface ILicenseCreatedDialogState {
  role: string;
}
type state = {
  loading: boolean;
  licenseKey: string;
};
export class LicenseCreatedDialog extends DialogBase<
  IModalProps<ILicenseCreatedDialogProps> & {
    onboardingState: IOnboardingState;
  },
  state
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          width: "330px"
        }
      }
    });
  }
  static getAppConnectOptions() {
    return super.getAppConnectOptions({
      selectors: {
        onboardingMode: "onboardingModeSelector"
      }
    });
  }
  state: state = {
    loading: true,
    licenseKey: ""
  };
  public componentDidMount(): void {
    const companyName = this.props.payload?.companyName;
    const companyId =
      this.props.payload?.companyId ||
      getOnboardingConfigSync("CreateCompany")?.companyId;
    const productId =
      this.props.onboardingState?.productId ||
      getOnboardingConfigSync("CreateProduct")?.productId;
    if (!companyName || !companyId || !productId) {
      return;
    }
    const licenseData: ILicenseModel = {
      license: {
        number_of_users: 0,
        default_token_duration: 0,
        owner: {
          name: companyName,
          owner_id:
            typeof companyId === "number"
              ? btoa(`Company-${companyId}`)
              : companyId,
          owner_type: "Company"
        },
        license_type_id: 3,
        owner_id:
          typeof companyId === "number"
            ? btoa(`Company-${companyId}`)
            : companyId,
        product_id:
          typeof productId === "number"
            ? btoa(`Product-${productId}`)
            : productId,
        owner_type: "Company",
        grace_period: 1,
        feature_group_ids: [null],
        feature_ids: [],
        component_group_ids: [null],
        component_ids: [],
        commercial_model_id: btoa(`CommercialModel-${5}`)
      },
      entitlement: {
        user_count: 1,
        instance_count: 1,
        session_count: 1
      },
      restriction: {
        max_instances_per_user: null,
        instances_per_user: null,
        users_per_instance: null,
        sessions_per_user: null,
        sessions_per_instance: null,
        sessions_per_user_per_instance: null,
        require_email_activation: null,
        number_of_installs: null,
        number_of_users: null
      },
      term: {
        start_date: dayjs().toISOString(),
        end_date: dayjs().toISOString()
      }
    };
    const gateway = getGateway();
    gateway.request
      .newLicense(licenseData)
      .then(res => {
        if (!res.error) {
          setTimeout(() => {
            renderSuccessNotification(`License created !`);
            this.setState({
              licenseKey: base64EncodeId("License", res.data?.id)
            });
          }, 1000);
        }
      })
      .catch(e => {
        renderFailureNotification(`License create failed !`);
        console.error(e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "LicenseCreatedDialog"
    });
  };
  public getTitle = () => {
    return this.state.loading
      ? "Company created successfully!"
      : "License created successfully!";
  };
  public getSubtitle = () => {
    return this.state.loading
      ? "Please wait while we create a license for you with the product and company that you just provided..."
      : "Please copy license key, you will need it later...";
  };
  public openCopiedMessage = (type: string) => () => {
    renderSuccessNotification(`${type} copied to clipboard`);
  };
  public renderBody = () => {
    return (
      <Flex flexDirection={"column"} marginTop={10}>
        <Flex
          style={{ alignItems: "left", justifyContent: "space-between" }}
          flexDirection={"column"}
        >
          <StyledInputLabel>LICENSE KEY</StyledInputLabel>
          <CopyToClipboard
            text={this.state.licenseKey} //value of license key to be copied
            onCopy={this.openCopiedMessage("License key")}
          >
            <CopyContainer>
              <span>{this.state.licenseKey || "license key"}</span>
              <ClipboardIcon />
            </CopyContainer>
          </CopyToClipboard>
        </Flex>
      </Flex>
    );
  };
  public renderLoader = () => {
    return (
      <Flex justifyContent={"center"} marginTop={40} marginBottom={80}>
        <Loader
          type="line-scale-pulse-out"
          active={true}
          color={"rgb(98, 98, 130)"}
          style={{ transform: "scale(1.0)" }}
        />
      </Flex>
    );
  };

  public render() {
    return (
      <DialogContainer
        className={"edit-product-dialog"}
        id="licenseCreatedDialogId"
      >
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <Subtitle>{this.getSubtitle()}</Subtitle>
        <FieldsContainer>
          {!this.state.loading ? this.renderBody() : this.renderLoader()}
        </FieldsContainer>
      </DialogContainer>
    );
  }
}
export const DataContainer = (
  props: IModalProps<ILicenseCreatedDialogProps>
) => {
  const onboardingState = useSelector(selectors.onboardingModeSelector);
  return (
    <DialogContainer>
      {props?.payload && (
        <LicenseCreatedDialog {...props} onboardingState={onboardingState} />
      )}
    </DialogContainer>
  );
};
export default DataContainer;
