import { SelectProps } from "antd/lib/select";
import { Flex } from "reflexbox/styled-components";
import Fuse from "fuse.js";
import * as _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { appConnect } from "../../../store/appConnect";
import {
  companySelectorQuery,
  companySelectorQueryResponse
} from "./__generated__/companySelectorQuery.graphql";
import {
  ElmDefaultSelect,
  ElmSelectWithLabel
} from "../../../components/elmSelect";
import { getIcon } from "../../../components/icons";
import {
  getClassName,
  getComponentWithQueryRenderer
} from "../../../components/helpers";
import { Divider } from "antd";

export type companyType = companySelectorQueryResponse["companies"]["nodes"][number];
export interface ICompanySelectorProps extends SelectProps<any> {
  onChange?: (company: companyType) => void;
  createNewCompany?: boolean;
  style?: React.CSSProperties;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  className?: string;
  label?: string;
}

const graphqlQuery = graphql`
  query companySelectorQuery {
    companies {
      nodes {
        name
        id
        type
      }
    }
  }
`;
export class CompanySelectorControl extends React.Component<
  ICompanySelectorProps & {
    result: companySelectorQueryResponse;
    retry: () => void;
  },
  { searchQuery: string; value: string }
> {
  state = {
    searchQuery: "",
    //@ts-ignore
    value: this.props.defaultValue
  };
  public getCompanies = () => {
    if (!this.props.result) {
      return null;
    }
    let companies = this.props.result.companies.nodes;
    const filtered: { label: string; value: string }[] = [];
    if (this.state.searchQuery) {
      const results = new Fuse(companies, {
        keys: ["name"],
        isCaseSensitive: false
      }).search(this.state.searchQuery);
      return _.map(results, i => ({ label: i.item.name, value: i.item.id }));
    }
    _.each(companies, company => {
      filtered.push({
        label: company.name,
        value: company.id
      });
    });
    return _.sortBy(filtered, p => p.label.toLowerCase());
    // return _.map(companies, (node) => {
    //   return <ElmSelectOption key={node.id}>{node.name}</ElmSelectOption>;
    // });
  };
  public handleChange = (companyId: string) => {
    const company = _.find(
      this.props.result.companies.nodes,
      node => node.id === companyId
    );
    this.setState({ value: company.id }, () => {
      if (_.isFunction(this.props.onChange) && companyId) {
        this.props.onChange(company);
      }
    });
  };
  public handleSearch = (searchQuery: string) => {
    this.setState({ searchQuery });
  };
  public createNewCompany = () => {
    //@ts-ignore
    this.props.appDispatch.bladeManagerActions.openDialog({
      name: "EditCompanyDialog",
      payload: {
        onConfirm: ({
          graphql_id,
          name
        }: {
          graphql_id: string;
          name: string;
        }) => {
          const companyId = graphql_id;
          const company = {
            id: companyId,
            name: name,
            type: "Company"
          };
          this.setState({ value: companyId }, () => {
            if (_.isFunction(this.props.onChange) && companyId) {
              this.props.onChange(company);
            }
          });
          if (_.isFunction(this.props.retry)) {
            this.props.retry();
          }
        }
      }
    });
  };
  public renderSelector = () => {
    return (
      <Flex
        className="elm-company-selector-container"
        style={{ flexDirection: "column" }}
      >
        <ElmSelectWithLabel
          {...this.props}
          defaultValue={this.state.value}
          value={this.state.value}
          // @ts-ignore
          onSelect={this.handleChange}
          className={getClassName({
            "elm-company-selector": () => true,
            [this.props.className]: () => !!this.props.className
          })}
          showSearch={true}
          onSearch={this.handleSearch}
          filterOption={false}
          options={this.getCompanies()}
          dropdownRender={menu =>
            this.props.createNewCompany ? (
              <div>
                <div
                  style={{ height: 34, display: "flex", alignItems: "center" }}
                >
                  <span
                    className="create-new-company"
                    onClick={this.createNewCompany}
                  >
                    Create new
                  </span>
                </div>
                <Divider style={{ margin: "4px 0" }} />
                {menu}
              </div>
            ) : (
              menu
            )
          }
        />
      </Flex>
    );
  };
  public render() {
    return this.renderSelector();
  }
}
const Container = (props: any) => <div children={props.children} />;
const CompanySelectorWithQr = getComponentWithQueryRenderer<
  companySelectorQuery,
  null,
  ICompanySelectorProps
>(Container);
const CompanySelector = (props: ICompanySelectorProps) => {
  return (
    <CompanySelectorWithQr
      query={graphqlQuery}
      variables={null}
      componentProps={props}
    >
      {(res, retry) => (
        <CompanySelectorControl result={res} retry={retry} {...props} />
      )}
    </CompanySelectorWithQr>
  );
};
export default appConnect<ICompanySelectorProps>(CompanySelector, {
  selectors: {}
});
