import React from "react";
import { Flex } from "reflexbox/styled-components";
import { useSelector } from "react-redux";
import { checkPermissions, InfoMessage } from "components/helpers";
import { IUserRole } from "store/types";
import styled, { useTheme } from "styled-components";
import { ElmButton } from "components/elmButton";
import { getGateway } from "api";
import { notification } from "antd";
import { getFaIcon } from "components/icons";
import { TOpenDialog } from "components/bladeManager/types";

const InfoContainer = styled.div`
  border-radius: 50%;
  background-color: ${props => props.theme.colors.orange};
  margin-right: 17.5px;
  width: 17.5px;
  height: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InfoIcon = styled(getFaIcon({ iconName: "info", prefix: "far" }))`
  color: ${props => props.theme.colors.white};
  height: 10px;
`;

interface IProps {
  requestedBy: string;
  licenseId: string;
  licenseActionId: string;
  permissions: string[];
  refreshAllOpenBlades: () => void;
  openDialog: TOpenDialog;
  closeDialog: () => void;
}

const TerminateLicenseDialog = (props: IProps) => {
  const theme = useTheme();

  const userInfo = useSelector(
    (state: { app: { activeRole: IUserRole } }) => state.app.activeRole
  );

  const havePermissions = checkPermissions({
    userPermissions: userInfo.permissions,
    requiredPermissions: props.permissions
  });

  const gateway = getGateway();

  const handleApproveTerminateLicense = () => {
    gateway.request["approveLicense"]({
      license_id: props.licenseId,
      license_action_id: props.licenseActionId
    })
      .then(res => {
        if (!res.error) {
          notification.open({
            type: "success",
            message: "Success",
            description: "License terminated!"
          });
          props.refreshAllOpenBlades();
          props.closeDialog();
        }
      })
      .catch(err => {
        notification.open({
          type: "error",
          message: "Failure",
          description: "License terminate failed!"
        });
      });
  };

  const handleRejectTerminateLicense = (comment?: string) => {
    gateway.request["rejectLicense"]({
      license_id: props.licenseId,
      license_action_id: props.licenseActionId,
      comment
    })
      .then(res => {
        if (!res.error) {
          notification.open({
            type: "success",
            message: "Success",
            description: "License terminate rejected !"
          });
          props.refreshAllOpenBlades();
          props.closeDialog();
        }
      })
      .catch(err => {
        notification.open({
          type: "error",
          message: "Failure",
          description: "Reject failed!"
        });
      });
  };

  const showRejectDialog = () => {
    props.openDialog("ConfirmationDialog", {
      description: "key",
      entityType: "terminate",
      title: "Reject terminate license ?",
      warningMessage: (
        <Flex flexDirection="column" flex={1} marginTop={"6px"}>
          <Flex marginBottom={"16px"}>
            You are about to deny request for terminating license. Are you sure?
          </Flex>
        </Flex>
      ),
      hasComment: true,
      isSimpleConfirmation: true,
      onConfirm: handleRejectTerminateLicense
    });
  };

  return (
    <>
      {havePermissions ? (
        <Flex marginLeft={21} marginRight={21} marginBottom={"7px"}>
          <InfoMessage
            style={{ width: "100%", maxWidth: "unset" }}
            description={
              <div style={{ display: "flex", alignItems: "center" }}>
                <InfoContainer>
                  <InfoIcon />
                </InfoContainer>
                {`${props.requestedBy} sent a request to terminate this license. Approve?`}
              </div>
            }
            type="warning"
            action={
              <Flex>
                <ElmButton
                  label={"No"}
                  variance={"primary"}
                  colorVariance={"subtle"}
                  permissions={props.permissions}
                  onClick={showRejectDialog}
                  style={{
                    color: theme.colors.black
                  }}
                />
                <ElmButton
                  label={"Yes"}
                  variance={"primary"}
                  colorVariance={"subtle"}
                  permissions={props.permissions}
                  onClick={handleApproveTerminateLicense}
                  style={{
                    color: theme.colors.trashButtonColor
                  }}
                />
              </Flex>
            }
          />
        </Flex>
      ) : null}
    </>
  );
};

export default TerminateLicenseDialog;
