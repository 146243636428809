/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type releaseLicenseOwnerTableQueryVariables = {
  count: number;
  cursor?: string | null | undefined;
  id: string;
  search?: string | null | undefined;
};
export type releaseLicenseOwnerTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"releaseLicenseOwnerTable_licenses">;
};
export type releaseLicenseOwnerTableQuery = {
  readonly response: releaseLicenseOwnerTableQueryResponse;
  readonly variables: releaseLicenseOwnerTableQueryVariables;
};

/*
query releaseLicenseOwnerTableQuery(
  $count: Int!
  $cursor: String
  $id: ID!
  $search: String
) {
  ...releaseLicenseOwnerTable_licenses_2jDTBs
}

fragment releaseLicenseOwnerTable_licenses_2jDTBs on Query {
  releaseChannel(id: $id) {
    id
    licenses(first: $count, after: $cursor, search: $search) {
      edges {
        node {
          id
          owner {
            __typename
            name
            type
            id
          }
          key
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "count"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      } as any
    ],
    v1 = {
      kind: "Variable",
      name: "id",
      variableName: "id"
    } as any,
    v2 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v4 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      v2 /*: any*/
    ],
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "__typename",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "releaseLicenseOwnerTableQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v1 /*: any*/,
            v2 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "releaseLicenseOwnerTable_licenses"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "releaseLicenseOwnerTableQuery",
      selections: [
        {
          alias: null,
          args: [v1 /*: any*/],
          concreteType: "ReleaseChannel",
          kind: "LinkedField",
          name: "releaseChannel",
          plural: false,
          selections: [
            v3 /*: any*/,
            {
              alias: null,
              args: v4 /*: any*/,
              concreteType: "LicenseConnection",
              kind: "LinkedField",
              name: "licenses",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "LicenseEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "License",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: null,
                          kind: "LinkedField",
                          name: "owner",
                          plural: false,
                          selections: [
                            v5 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "name",
                              storageKey: null
                            },
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "type",
                              storageKey: null
                            },
                            v3 /*: any*/
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "key",
                          storageKey: null
                        },
                        v5 /*: any*/
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: v4 /*: any*/,
              filters: ["search"],
              handle: "connection",
              key: "releaseLicenseOwnerTable_licenses",
              kind: "LinkedHandle",
              name: "licenses"
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "0c9d16ba4f1a8c24a8a562997f4a2d86",
      id: null,
      metadata: {},
      name: "releaseLicenseOwnerTableQuery",
      operationKind: "query",
      text:
        "query releaseLicenseOwnerTableQuery(\n  $count: Int!\n  $cursor: String\n  $id: ID!\n  $search: String\n) {\n  ...releaseLicenseOwnerTable_licenses_2jDTBs\n}\n\nfragment releaseLicenseOwnerTable_licenses_2jDTBs on Query {\n  releaseChannel(id: $id) {\n    id\n    licenses(first: $count, after: $cursor, search: $search) {\n      edges {\n        node {\n          id\n          owner {\n            __typename\n            name\n            type\n            id\n          }\n          key\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "2462286c2542ab73e4ab050bc0c3fc72";
export default node;
