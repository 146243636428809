import { notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import * as React from "react";
import { createFragmentContainer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import {
  AttributesBox,
  IAttributValuePair,
  IAttributesBoxProps
} from "../../../components/attributesBox";
import { getTermDetails } from "../../../utils";
import { getLink } from "../../../components/helpers";
import { getIcon } from "../../../components/icons";
import { ElmRangeDatePicker } from "../../../components/elmDate";
import { licenseAttributeAdvanced_license } from "./__generated__/licenseAttributeAdvanced_license.graphql";
import moment from "moment";
import { DATE_FORMAT_ALT } from "const";
// import { RangePickerValue } from "antd/lib/date-picker/";

const OtherText = styled.text`
  min-width: max-content;
  height: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #302d28;
`;

const ActiveCheck = styled(getIcon("FaRegCheckCircle"))`
  color: #4fdf2c;
`;

export interface ILicenseAttributeAdvancedProps extends IAttributesBoxProps {
  license: licenseAttributeAdvanced_license;
  onClickProduct?: (
    product: licenseAttributeAdvanced_license["product"]
  ) => void;
  onChangeTerm?: (newTerm: {
    startMoment: moment.Moment;
    endMoment: moment.Moment;
  }) => void;
}

const ActiveLabel = styled.text`
  width: 49px;
  height: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #4fdf2c;
  margin-left: 3px;
`;
const ExpiredLabel = styled.text`
  width: 49px;
  height: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #ff6565;
`;

export class LicenseAttributeAdvanced extends React.Component<
  ILicenseAttributeAdvancedProps
> {
  public getStatus = () => {
    const commercialModel = _.get(this.props.license, "commercialModel.name");
    const { date: startDate } = getTermDetails(
      _.get(this.props.license, "latestTerm.startDate") as string,
      { dateFormat: DATE_FORMAT_ALT }
    );
    const { date: endDate, isExpired } = getTermDetails(
      _.get(this.props.license, "latestTerm.endDate") as string,
      { commercialModel, dateFormat: DATE_FORMAT_ALT }
    );
    const Status = isExpired ? (
      <ExpiredLabel>Expired</ExpiredLabel>
    ) : (
      <Flex style={{ alignItems: "center" }}>
        <ActiveCheck />
        <ActiveLabel>Active</ActiveLabel>
      </Flex>
    );
    return { Status, startDate, endDate };
  };
  public handleProductLick = () => {
    if (_.isFunction(this.props.onClickProduct)) {
      this.props.onClickProduct(this.props.license.product);
    }
  };
  public openCopiedMessage = () => {
    notification.open({
      message: "Success",
      description: "License copied to clipboard"
    });
  };
  public disabledDate = (current: moment.Moment) => {
    // Can not select days before today and today
    return (
      current &&
      current <
        moment()
          .subtract(1, "d")
          .endOf("day")
    );
  };
  public updateDateMoments = (date: any) => {
    const newState = {} as {
      startMoment: moment.Moment;
      endMoment: moment.Moment;
    };
    if (moment.isMoment(date[0])) {
      newState.startMoment = date[0];
    }
    if (moment.isMoment(date[1])) {
      newState.endMoment = date[1];
    }

    if (newState) {
      if (_.isFunction(this.props.onChangeTerm)) {
        this.props.onChangeTerm(newState);
      }
    }
  };
  public getItems = (): IAttributValuePair[] => {
    if (!this.props.license) {
      return null;
    }
    const { startDate, endDate } = this.getStatus();
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    const defaultValue = [startMoment, endMoment] as any;
    return [
      {
        label: "Term",
        value:
          this.props.mode === "edit" ? (
            <ElmRangeDatePicker
              defaultValue={defaultValue}
              disabledDate={this.disabledDate}
              onChange={this.updateDateMoments}
              separator={` -> `}
            />
          ) : (
            <OtherText>{`${startDate} -> ${endDate}`}</OtherText>
          )
      },
      {
        label: "Entitlements",
        value: (
          <OtherText>{this.props.license.instancesCount.toString()}</OtherText>
        )
      },
      {
        label: "Instances",
        value: (
          <OtherText>{this.props.license.instancesCount.toString()}</OtherText>
        )
      },

      ...this.renderRestrictions()
    ];
  };
  public renderRestrictions = () => {
    let restrictionKeys = _.keys(this.props.license.restriction);
    restrictionKeys = _.filter(
      restrictionKeys,
      k => this.props.license.restriction[k]
    ) as string[];
    return _.map(restrictionKeys, key => {
      return {
        label: _.startCase(key),
        value: <OtherText>{this.props.license.restriction[key]}</OtherText>
      };
    });
  };

  public render() {
    return (
      <AttributesBox
        items={this.getItems()}
        title={"Advanced"}
        mode={this.props.mode}
      />
    );
  }
}

export default createFragmentContainer(LicenseAttributeAdvanced, {
  license: graphql`
    fragment licenseAttributeAdvanced_license on License {
      key
      isTrial
      isVendor
      product {
        id
        name
      }
      restriction {
        usersPerInstance
        instancesPerUser
        sessionsPerUser
        sessionsPerInstance
        sessionsPerUserPerInstance
        requireEmailActivation
      }
      type {
        id
      }
      latestTerm {
        endDate
        startDate
      }
      instancesCount
      entitlementsCount
    }
  `
});
