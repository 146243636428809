import React, { useEffect } from "react";
import { EvoleapLogo } from "../../assets";
import { Flex } from "reflexbox";
import { getGateway } from "api";
import AppImageDictionary from "utils/AppImageDictionary/AppImageDictionary";

export const VendorLogo: React.FC = () => {
  const [state, setState] = React.useState<{
    company: string;
    header_logo_url: string;
  }>();

  useEffect(() => {
    const getVendorInfo = async () => {
      const gateway = getGateway();
      const res = await gateway.request.getCurrentVendorInformation();
      const header_logo_url = await AppImageDictionary.getImageSrc(
        `vendor-${res.data?.id}`,
        res.data?.header_logo_url
      );
      setState({ ...res.data, header_logo_url });
    };
    getVendorInfo();
  }, []);

  if (!state?.company && !state?.header_logo_url) {
    return <img src={EvoleapLogo} alt={"Evoleap Logo"} />;
  }
  return (
    <Flex style={{ color: "#ffffff", gap: 8 }} alignItems={"center"}>
      <img src={state.header_logo_url} alt={EvoleapLogo || "Evoleap Logo"} />
      <span>{state.company}</span>
    </Flex>
  );
};
