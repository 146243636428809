import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { hooksFeaturesAndComponentsQuery } from "./__generated__/hooksFeaturesAndComponentsQuery.graphql";
import { hooksfeaturesAndGroupsToggleQuery } from "./__generated__/hooksfeaturesAndGroupsToggleQuery.graphql";
import { hooksLicenseComponentsAndEntitlementsQuery } from "./__generated__/hooksLicenseComponentsAndEntitlementsQuery.graphql";

export type FeaturesAndComponentsType = {
  productId: string;
};

export type FeaturesAndGroupsType = {
  licenseId?: string;
  productId?: string;
};

export const useLicenseComponentsAndEntitlementsData = (licenseId: string) => {
  const query = graphql`
    query hooksLicenseComponentsAndEntitlementsQuery($licenseId: ID!) {
      license(id: $licenseId) {
        components {
          nodes {
            id
            name
            componentType
            componentGroup {
              name
              id
            }
            productComponentRequirements {
              canInherit
              freeTrial
              freeTrialActive
              freeTrialExpiration
              isIncluded
              isInherited
              productVersionId
              tokenCount
              wouldInheritFromBaseLicense
            }
            licenseComponentRequirements {
              canInherit
              freeTrial
              freeTrialActive
              freeTrialExpiration
              isIncluded
              isInherited
              productVersionId
              tokenCount
              wouldInheritFromBaseLicense
            }
          }
        }
        componentEntitlements {
          nodes {
            id
            tokenCount
            sessionCount
            currencyCount
            numCurrencyUsed
            latestTerm {
              startDate
              endDate
              isActive
            }
            components {
              nodes {
                name
                componentType
                id
              }
            }
            componentGroup {
              id
              name
              componentType
              components {
                nodes {
                  name
                  componentType
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  const data = useLazyLoadQuery<hooksLicenseComponentsAndEntitlementsQuery>(
    query,
    { licenseId },
    { fetchPolicy: "network-only" }
  );
  return data;
};

export const useComponentsAndFeaturesData = (
  props: FeaturesAndComponentsType
) => {
  const query = graphql`
    query hooksFeaturesAndComponentsQuery($id: ID!, $skip: Boolean!) {
      product(id: $id) @skip(if: $skip) {
        name
        components {
          nodes {
            id
            name
            componentType
            componentGroup {
              name
              id
            }
          }
        }
        componentGroups {
          nodes {
            name
            id
            componentType
            components {
              nodes {
                name
                id
                componentType
              }
            }
          }
        }
      }
    }
  `;

  const data = useLazyLoadQuery<hooksFeaturesAndComponentsQuery>(
    query,
    {
      id: props.productId,
      skip: !props.productId
    },
    { fetchPolicy: "network-only" }
  );

  return data;
};

export const useFeaturesAndGroupsData = (props: FeaturesAndGroupsType) => {
  const query = graphql`
    query hooksfeaturesAndGroupsToggleQuery(
      $licenseId: ID!
      $productId: ID!
      $skipLicense: Boolean!
      $skipProduct: Boolean!
    ) {
      license(id: $licenseId) @skip(if: $skipLicense) {
        features {
          nodes {
            id
            name
          }
        }
        featureGroups {
          nodes {
            id
            name
            features {
              nodes {
                id
                name
              }
            }
          }
        }
        product {
          features {
            nodes {
              id
              name
              featureGroupsCount
            }
          }
          featureGroups {
            nodes {
              id
              name
              features {
                nodes {
                  id
                  name
                }
              }
            }
          }
        }
      }
      product(id: $productId) @skip(if: $skipProduct) {
        features {
          nodes {
            id
            name
            featureGroupsCount
          }
        }
        featureGroups {
          nodes {
            id
            name
            features {
              nodes {
                id
                name
              }
            }
          }
        }
      }
    }
  `;

  const data = useLazyLoadQuery<hooksfeaturesAndGroupsToggleQuery>(
    query,
    {
      licenseId: props.licenseId,
      skipLicense: !props.licenseId,
      productId: props.productId,
      skipProduct: !props.productId
    },
    { fetchPolicy: "network-only" }
  );

  return data;
};
