/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UsersQueryVariables = {};
export type UsersQueryResponse = {
  readonly allUsersCount: number;
  readonly allProductsCount: number;
};
export type UsersQuery = {
  readonly response: UsersQueryResponse;
  readonly variables: UsersQueryVariables;
};

/*
query UsersQuery {
  allUsersCount: usersCount
  allProductsCount: productsCount
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
    {
      alias: "allUsersCount",
      args: null,
      kind: "ScalarField",
      name: "usersCount",
      storageKey: null
    } as any,
    {
      alias: "allProductsCount",
      args: null,
      kind: "ScalarField",
      name: "productsCount",
      storageKey: null
    } as any
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "UsersQuery",
      selections: v0 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "UsersQuery",
      selections: v0 /*: any*/
    },
    params: {
      cacheID: "797bcb4734158591589c3fbfa614df50",
      id: null,
      metadata: {},
      name: "UsersQuery",
      operationKind: "query",
      text:
        "query UsersQuery {\n  allUsersCount: usersCount\n  allProductsCount: productsCount\n}\n"
    }
  } as any;
})();
(node as any).hash = "5bd69ffe9d464c45bff05a48e531472a";
export default node;
