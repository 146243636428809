import _ from "lodash";
import { getSavedRoleFromLocal, getSavedRoleFromLocalSync } from "../../utils";
type baseSaveTablePayload = {
  bladeName: string;
  tableName: string;
  type: "filters" | "selectedRow";
};

const getDataKey = (payload: {
  bladeName: string;
  tableName: string;
  type: string;
  roleType: string;
  roleName: string;
}) =>
  `${payload.bladeName}.${payload.tableName}.${payload.type}.${payload.roleName}.${payload.roleType}`;
export function getSavedDataForTable(payload: baseSaveTablePayload) {
  if (_.isString(payload.bladeName) && payload.bladeName) {
    if (_.isString(payload.tableName) && payload.tableName) {
      const role = getSavedRoleFromLocalSync();
      return window.localStorage.getItem(
        getDataKey({
          ...payload,
          roleType: role.type,
          roleName: role.name
        })
      ) as string;
    }
  }
  return null;
}
export function saveDataForTable(
  payload: baseSaveTablePayload,
  saveData: string
) {
  if (_.isString(payload.bladeName) && payload.bladeName) {
    if (_.isString(payload.tableName) && payload.tableName) {
      getSavedRoleFromLocal().then(role => {
        if (role) {
          window.localStorage.setItem(
            getDataKey({
              ...payload,
              roleType: role.type,
              roleName: role.name
            }),
            saveData
          );
        }
      });
    }
  }
}
