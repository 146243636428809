import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import React from "react";
import { TableCellProps } from "react-virtualized";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import { TableCell } from "../../../components/elmTable";
import { getIcon } from "../../../components/icons";
import { tableGenerator } from "../../../utils";

import { Tooltip } from "antd";
import { releaseLicenseOwnerTable_licenses } from "./__generated__/releaseLicenseOwnerTable_licenses.graphql";

export type licenseTableEdges = releaseLicenseOwnerTable_licenses["releaseChannel"]["licenses"]["edges"];
export type licenseTableType = licenseTableEdges[number]["node"];

export type ILicensesTableProps = {
  licenses: releaseLicenseOwnerTable_licenses;
};

const CopyIcon = styled(getIcon("MdContentCopy"))`
  color: ${props => props.theme.colors.iconColor};
`;
const CopiedIcon = styled(getIcon("FaCheck"))`
  margin-right: 5.5px;
  color: inherit;
`;

const CopyContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  .copyMessage {
    color: #4dbf40;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
  }
  .licenseKey {
    display: block;
  }
  .licenseKeyMessage {
    display: none;
    color: ${props => props.theme.colors.linkButtonLabel};
  }

  :hover {
    color: ${props => props.theme.colors.lightBlue};
    cursor: pointer;
    .licenseKey {
      display: none;
    }
    .license-copy-icon {
      display: none;
    }
    .licenseKeyMessage {
      display: block;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: ${props => props.theme.colors.linkButtonLabel};
    }
  }
`;
const LicenseKey = (props: { licenseKey: string; fullKey: string }) => {
  const [showCopiedMessage, updateShowCopyMessage] = React.useState(false);
  const openCopiedMessage = () => {
    // notification.open({
    //   message: 'Success',
    //   description: 'License copied to clipboard',
    // });
    updateShowCopyMessage(true);
    _.delay(() => {
      updateShowCopyMessage(false);
    }, 5000);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <TableCell>
      <CopyToClipboard text={props.fullKey} onCopy={openCopiedMessage}>
        <CopyContainer onClick={handleClick}>
          {showCopiedMessage ? (
            <div
              className="copyMessage"
              style={{
                display: showCopiedMessage ? "flex" : "none",
                alignItems: "center"
              }}
            >
              <CopiedIcon />
              <span>Copied!</span>
            </div>
          ) : (
            <React.Fragment>
              <div className="licenseKey">{`${props.licenseKey}-...`}</div>
              <div className="licenseKeyMessage">{`Click to copy`}</div>
              <CopyIcon className="license-copy-icon" />
            </React.Fragment>
          )}
        </CopyContainer>
      </CopyToClipboard>
    </TableCell>
  );
};
const renderLicenseKey = (cellProps: TableCellProps) => {
  const fullKey = cellProps.cellData;
  const key = _.isString(fullKey) ? fullKey.substring(0, 5) : "";

  // return `${key}-...`;
  return (
    <Tooltip placement="top" title={fullKey}>
      {" "}
      <LicenseKey licenseKey={key} fullKey={fullKey} />{" "}
    </Tooltip>
  );
};

const ReleaseLicensesTable = tableGenerator<ILicensesTableProps>({
  tableName: "releaseLicenseOwnerTable",
  rowHeight: 42,
  columns: [
    {
      accessor: "owner.name",
      sortKey: "owner.name",
      Header: "Owner"
    },

    {
      Header: "Key",
      width: 0.5,
      accessor: "key",
      disableSortBy: true,
      cellRenderer: payload => renderLicenseKey(payload)
    }
  ],
  connectionQuery: graphql`
    query releaseLicenseOwnerTableQuery(
      $count: Int!
      $cursor: String
      #$blade_scope: String
      $id: ID!
      $search: String
    ) {
      ...releaseLicenseOwnerTable_licenses
        @arguments(
          count: $count
          cursor: $cursor
          #blade_scope: $blade_scope
          id: $id
          search: $search
        )
    }
  `,
  dataKey: "licenses.releaseChannel.licenses",
  fragmentSpec: {
    licenses: graphql`
      fragment releaseLicenseOwnerTable_licenses on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          #blade_scope: { type: "String", defaultValue: null }
          id: { type: "ID!" }
          search: { type: "String", defaultValue: null }
        ) {
        releaseChannel(id: $id) {
          id
          licenses(
            first: $count
            after: $cursor
            #bladeScope: $blade_scope
            search: $search
          ) @connection(key: "releaseLicenseOwnerTable_licenses") {
            edges {
              node {
                id
                owner {
                  name
                  type
                }
                key
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `
  }
});
export default ReleaseLicensesTable;
