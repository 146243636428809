import * as _ from "lodash";
// @ts-ignore
import { openDialog, closeDialog } from "redux-dialog";
import { addNamespace } from "../../../utils";
import { modalTypes } from "../../../components/bladeManager/appDialog/types";

const actionDefinitions = addNamespace(
  {
    updateBladeManagerState: "updatebladeManagerState"
  },
  "bladeManager"
);

const actions = {
  updateBladeManagerState: (data: { route: string; routeData: any }) => ({
    type: actionDefinitions.updateBladeManagerState,
    data
  }),
  openDialog: (data: {
    name: modalTypes;
    payload?: {
      onConfirm?: (state: any) => void;
      [key: string]: any;
    };
  }) => openDialog(data.name, data.payload),
  closeDialog: (data: { name: modalTypes }) => closeDialog(data.name)
};

export { actionDefinitions, actions };
