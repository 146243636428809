import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { licenseActiveSessionsTable_activeSessions } from "./__generated__/licenseActiveSessionsTable_activeSessions.graphql";
import { tableGenerator } from "../../../utils";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox";
import { LoaderContainer } from "components/helpers";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { getEnvironment } from "api/relay";
import {
  licenseActiveSessionsTableQuery,
  licenseActiveSessionsTableQueryResponse
} from "./__generated__/licenseActiveSessionsTableQuery.graphql";
import _ from "lodash";
import { shallowEqual } from "fast-equals";

export type userTableEdges = licenseActiveSessionsTable_activeSessions["license"]["activeSessions"]["edges"];
export type userTableType = userTableEdges[number]["node"];
export type ILicenseActiveSessionsTableProps = {
  activeSessions: licenseActiveSessionsTable_activeSessions;
};

export const GeneratedTable = tableGenerator<ILicenseActiveSessionsTableProps>({
  columns: [
    {
      Header: "User",
      accessor: "user.displayName",
      disableSortBy: true
    },
    {
      Header: "Expiration",
      accessor: "endTime",
      disableSortBy: true,
      isDateFormat: true
    },
    {
      Header: "Actions",
      accessor: "",
      cellRenderer: () => "",
      width: 0.1,
      disableSortBy: true
    }
  ],
  connectionQuery: graphql`
    query licenseActiveSessionsTablePaginationQuery(
      $id: ID!
      $count: Int!
      $cursor: String
    ) {
      ...licenseActiveSessionsTable_activeSessions
        @arguments(id: $id, count: $count, cursor: $cursor)
    }
  `,
  dataKey: "activeSessions.license.activeSessions",
  fragmentSpec: {
    activeSessions: graphql`
      fragment licenseActiveSessionsTable_activeSessions on Query
        @argumentDefinitions(
          id: { type: "ID!" }
          count: { type: "Int", defaultValue: 6 }
          cursor: { type: "String" }
        ) {
        license(id: $id) {
          activeSessions(first: $count, after: $cursor)
            @connection(key: "licenseActiveSessionsTable_activeSessions") {
            edges {
              node {
                id
                startTime
                endTime
                user {
                  displayName
                }
                instance {
                  hardwareIdentifier {
                    info
                  }
                }
                versionString
              }
            }
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query licenseActiveSessionsTableQuery($id: ID!) {
    ...licenseActiveSessionsTable_activeSessions @arguments(id: $id)
  }
`;

class LicenseActiveSessionsPaginationTable extends React.Component<
  IGeneratedTableProps & {
    result: licenseActiveSessionsTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        activeSessions={res}
      />
    );
  }
}

const relayEnvironment = getEnvironment();
const LicenseActiveSessionsTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: licenseActiveSessionsTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <LicenseActiveSessionsPaginationTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<licenseActiveSessionsTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.bladeScope || ""
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default LicenseActiveSessionsTable;
