import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../api/relay";
import { appConnect } from "../../store/appConnect";
import { WidgetContainer } from "../../components/analyticsComponent/widgetContainer";
import {
  licenseAnalyticsQuery,
  licenseAnalyticsQueryResponse
} from "./__generated__/licenseAnalyticsQuery.graphql";
import _ from "lodash";
import { getAnimatedComponent } from "../../components/helpers";
import { getFriendlyLicenseName } from "../../utils";
import {
  TopCountFilter,
  topCounts
} from "../../components/analyticsComponent/filters/topCount";
import { TabbedAnalyticsComponent } from "../../components/analyticsComponent/tabbedAnalytics";
import {
  AnalyticsComponentProps,
  queryType
} from "../../components/analyticsComponent/";
import { Flex } from "reflexbox";
import { WidgetTitle, sharedTabStyle } from "./shared";
import styled from "styled-components";
import { DateRange } from "blades/Dashboard/utils";
import { IOpenBladeFromChart } from "components/bladeManager/types";

const AnimatedContainer = getAnimatedComponent({
  element: "div",
  transitionName: "license-banner",
  animations: {
    appear: {
      duration: "1.5s",
      animationType: "slideInRight"
    },
    enter: {
      duration: "1s",
      animationType: "slideInRight"
    }
  }
});

const FlexHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 1650px) {
    margin-bottom: 8px !important;
  }
  @media (min-width: 1651px) {
    margin-bottom: 12px !important;
  }
`;

const graphqlQuery = graphql`
  query licenseAnalyticsQuery(
    $currentTimeSpanStart: String!
    $currentTimeSpanEnd: String!
    $productId: String!
  ) {
    LicenseAnalyticsCurrentExpiringLicenses: licenses(
      filterColumn: ["latestTerm.endDate", "latestTerm.endDate"]
      filterOp: [[">", "="], ["<", "="]]
      filterValues: [
        [$currentTimeSpanStart, $currentTimeSpanStart]
        [$currentTimeSpanEnd, $currentTimeSpanEnd]
      ]
      bladeScope: $productId
    ) {
      nodes {
        type {
          name
          id
        }
      }
    }
  }
`;

export interface ILicenseAnalyticsProps {
  result: licenseAnalyticsQueryResponse;
  retry: () => void;
  openBlade?: (payload: IOpenBladeFromChart) => void;
  timespan: "week" | "month" | "day";
  licenseId: string;
  variables: {
    timeRangeOverride?: DateRange;
    allTimeFilterStart?: string;
    currentTimeSpanStart: string;
    currentTimeSpanEnd: string;
    previousTimeSpanStart: string;
    previousTimeSpanEnd: string;
    productId: string | null;
  };
}

type state = {
  topUsersCount: number;
  topComponentsCount: number;
};
export class LicenseAnalytics extends React.Component<ILicenseAnalyticsProps> {
  state: state = {
    topComponentsCount: 5,
    topUsersCount: 5
  };
  public renderLicenseInfoBanner = () => {
    if (
      !this.props.result ||
      !this.props.result.LicenseAnalyticsCurrentExpiringLicenses
    ) {
      return null;
    }
    const getActiveLicensesByType = () => {
      if (
        !this.props.result ||
        !this.props.result.LicenseAnalyticsCurrentExpiringLicenses
      ) {
        return null;
      }
      const types = _.groupBy(
        this.props.result.LicenseAnalyticsCurrentExpiringLicenses.nodes,
        node => node.type.name
      );
      const typeNames = _.keys(types);
      return _.map(typeNames, typeName => {
        return (
          <div>
            <span className="expiring-value">{types[typeName].length}</span>
            <span className="expiring-label">
              {_.startCase(getFriendlyLicenseName(typeName))}
            </span>
          </div>
        );
      });
    };
    const navigateToLicenses = () => {
      // this.props.openBlade({
      //   route: 'Licenses',
      //   routeName: 'Licenses',
      //   routeData: null,
      //   fromBladeIndex: this.props.index,
      //   navigationType: 'reset',
      // });
    };
    return (
      <AnimatedContainer>
        <div className="banner" onClick={navigateToLicenses}>
          <div className="accent" />
          <div className="message-container">
            <span className="current-expiring-value">
              {this.props.result.LicenseAnalyticsCurrentExpiringLicenses}
            </span>
            <span className="current-expiring-label">{`License${
              this.props.result.LicenseAnalyticsCurrentExpiringLicenses.nodes
                .length > 1
                ? "s"
                : ""
            } expiring this ${this.props.timespan}`}</span>
            <div className="extra-expiring-container">
              {getActiveLicensesByType()}
            </div>
          </div>
        </div>
      </AnimatedContainer>
    );
  };
  public getHighlightAnalytics = () => {
    return (
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session count",
              style: {
                marginLeft: "16px",
                ...sharedTabStyle
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: this.getSessionCountAnalyticsSetup(),
              type: "line",
              allowVendorAnalytics: true
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: this.getSessionDurationAnalyticsSetup(),
              type: "line",
              allowVendorAnalytics: true
            }
          }
        ]}
      />
    );
  };
  public getTopUsers = () => {
    return (
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session activity",
              style: {
                ...sharedTabStyle
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: this.getSessionActivity({
                value: "sessions.usage_count",
                category: "users.name",
                first: this.state.topUsersCount
              }),
              type: "bar",
              allowVendorAnalytics: true
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: this.getSessionActivity({
                value: "sessions.usage_duration",
                category: "users.name",
                first: this.state.topUsersCount
              }),
              type: "bar",
              allowVendorAnalytics: true
            }
          }
        ]}
      />
    );
  };
  public getTopComponents = () => {
    return (
      <TabbedAnalyticsComponent
        //style={{ height: "417px" }}
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session activity",
              style: {
                ...sharedTabStyle
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: this.getSessionActivity({
                value: "components.usage_count",
                category: "components.name",
                first: this.state.topComponentsCount
              }),
              type: "bar",
              allowVendorAnalytics: true
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: this.getSessionActivity({
                value: "components.usage_duration",
                category: "components.name",
                first: this.state.topComponentsCount
              }),
              type: "bar",
              allowVendorAnalytics: true
            }
          }
        ]}
      />
    );
  };
  public getSessionActivity = (payload: any) => {
    return {
      bladeScope: this.props.licenseId,
      valueTitle: "Session Duration",
      setup: {
        category: payload.category,
        value: payload.value,
        group: null
      },
      first: payload?.first || 5,
      options: {
        lineWidth: 25,
        chartMaxHeight: 200
      },
      sortColumn: "value",
      sortDirection: "DESC",
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd
    } as queryType;
  };

  public getSessionDurationAnalyticsSetup = () => {
    return {
      bladeScope: this.props.licenseId,
      valueTitle: "Session Duration",
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_duration",
        group: null
      },
      options: {
        chartMaxHeight: 200
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd
    } as queryType;
  };
  public getSessionCountAnalyticsSetup = () => {
    return {
      bladeScope: this.props.licenseId,
      valueTitle: "Session Count",
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_count",
        group: null
      },
      options: {
        chartMaxHeight: 200
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd
    } as queryType;
  };

  public updateTopCount = (type: keyof state) => (count: topCounts) => {
    this.setState({ [type]: parseInt(count, 10) });
  };
  public render() {
    return (
      // <>
      <div className="license-analytics analytics-container">
        {this.renderLicenseInfoBanner()}
        <WidgetContainer size="x-large">
          {this.getHighlightAnalytics()}
        </WidgetContainer>

        <Flex
          justifyContent="space-between"
          flexBasis="100%"
          style={{ gap: "20px" }}
        >
          <WidgetContainer size="medium-large">
            <FlexHeader>
              <WidgetTitle>Top users</WidgetTitle>
              <TopCountFilter onChange={this.updateTopCount("topUsersCount")} />
            </FlexHeader>
            {this.getTopUsers()}
          </WidgetContainer>

          <WidgetContainer size="medium-large">
            <FlexHeader>
              <WidgetTitle>Top components</WidgetTitle>
              <TopCountFilter
                onChange={this.updateTopCount("topComponentsCount")}
              />
            </FlexHeader>
            {this.getTopComponents()}
          </WidgetContainer>
        </Flex>
      </div>
      // </>
    );
  }
}

const RenderQuery = (props: ILicenseAnalyticsProps) => {
  const renderBlade = (payload: {
    error: Error;
    props: licenseAnalyticsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <LicenseAnalytics
        {...props}
        variables={props.variables}
        result={payload.props}
        retry={payload.retry}
        // updateVariables={updateVariables}
      />
    );
  };
  return (
    <QueryRenderer<licenseAnalyticsQuery>
      environment={relayEnvironment}
      variables={props.variables}
      query={graphqlQuery}
      render={renderBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
