/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type companySelectorQueryVariables = {};
export type companySelectorQueryResponse = {
  readonly companies: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly id: string;
      readonly type: string | null;
    } | null> | null;
  } | null;
};
export type companySelectorQuery = {
  readonly response: companySelectorQueryResponse;
  readonly variables: companySelectorQueryVariables;
};

/*
query companySelectorQuery {
  companies {
    nodes {
      name
      id
      type
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: "CompanyConnection",
      kind: "LinkedField",
      name: "companies",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Company",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "name",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "type",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "companySelectorQuery",
      selections: v0 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "companySelectorQuery",
      selections: v0 /*: any*/
    },
    params: {
      cacheID: "0bcff685b63ceda8a40853bf1aa85669",
      id: null,
      metadata: {},
      name: "companySelectorQuery",
      operationKind: "query",
      text:
        "query companySelectorQuery {\n  companies {\n    nodes {\n      name\n      id\n      type\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "79172aca120bd2f615d0704715dd30c9";
export default node;
