import * as _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import React, { RefObject } from "react";
import { useTheme } from "styled-components";
import { QueryRenderer, RelayEnvironmentProvider } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import { getEnvironment } from "../../../api/relay";
import { getGateway } from "../../../api";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenAddLicenseBlade
} from "../../../components/bladeManager/types";
import {
  BladeTemplate,
  BladeTitle,
  ElmButton,
  ElmInput,
  TabView
} from "../../../components";
import { userTableType } from "../../../Users/components/usersTable";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../components/elmSelect";
import CompanySelector, {
  companyType
} from "../../../Companies/components/companySelector";
import { productType } from "../../../Products/components/productSelector";
import ProductSelectorComponent from "../../../Products/components/productSelectorComponent";
import ElmCheckBox from "../../../components/elmCheckBox";
import { Tooltip } from "antd";
import { SectionHeader, StyledTemplateBtn } from "./styled";

import ElmDatePicker from "../../../components/elmDate";
import { ITab } from "../../../components/tabView";
import LoadingWrapper from "../../../components/elmChart/loadingWrapper";
import {
  AddLicenseQuery,
  AddLicenseQueryResponse
} from "./__generated__/AddLicenseQuery.graphql";
import {
  FeaturesAndGroupsToggle,
  ComponentOrGroup,
  ComponentsAndGroupsToggle,
  FeatureOrGroup
} from "../../components/featuresAndGroups";
import { Owner } from "../../../store/ducks/app";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import LicenseEnforcementSelector from "../../../Licenses/components/licenseEnforcementSelector";
import { licenseTypeToName } from "../../../utils";
import { sharedTabStyle } from "blades/Analytics/shared";
import dayjs, { Dayjs } from "dayjs";
import { ILicenseModel, ILicenseTemplate } from "../../../api/gatewayMap";
import { commercialModel, commercialModelType } from "Licenses/common";
import { renderNotification } from "utils/ant-notifications";
import { base64EncodeId } from "utils/base64EncodeDecode";
import { DATE_FORMAT } from "const";

const relayEnvironment = getEnvironment();

//check the backend implementation to assign the values correctly

export enum enforcementModel {
  namedUser = "Named User",
  concurrent = "Concurrent",
  nodeLocked = "Node Locked",
  unrestricted = "Unrestricted"
}

export const termStart = {
  Today: dayjs(),
  Tomorrow: dayjs().add(1, "day"),
  "1st of next month": dayjs()
    .add(1, "M")
    .startOf("month")
  // "End of current term": moment()
  //   .utc()
  //   .format("DD.MM.YYYY"),
};

export const termEnd = {
  "1 year": 12,
  "2 years": 24,
  "3 years": 36,
  "5 years": 60
};

export const termEndFreeTrial = {
  "1 month": 1,
  "2 months": 2,
  "3 months": 3,
  "6 months": 6
};

export const calculateTermEnd = (durationInMonths: number, date: Dayjs) => {
  let djs = dayjs;
  const is = dayjs.isDayjs(date); ///dayjs.isDayjs(date);
  if (!date) return;
  return dayjs(date).add(durationInMonths, "months");
};

export interface IAddLicenseBladeProps
  extends IBladeBaseProps<IOpenAddLicenseBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
  tabSwitch: any;
}

export interface IAddLicenseModel {
  number_of_installs: number;
  number_of_users: number;
  max_instances_per_user: number;
  default_token_duration: number;
  owner: Owner;
  product_id: string;
  license_type_id: number;
  is_trial: boolean;
  is_vendor: boolean;
  owner_id: string;
  owner_type: string;
  user_count: number;
  grace_period: number;
  instance_count: number;
  session_count: number;
  instances_per_user: number;
  users_per_instance: number;
  sessions_per_user: number;
  sessions_per_instance: number;
  sessions_per_user_per_instance: number;
  minimum_assignment_length: number;
  require_email_activation: boolean;
  value?: number; //currency
  license_value?: number; //currency
  term_value?: number; //currency
  licenseTypeId?: number;
}

interface ILicenseBladeState {
  mode: "edit" | "normal";
  commercialModel?: commercialModel;
  commercialModelId?: number;
  enforcementModel?: enforcementModel;
  termStartDate?: Dayjs;
  termStartDateHumanized: string;
  termEndDate?: Dayjs;
  termEndDateHumanized: string;
  termEndDateRangeMonths?: number;
  activeTabIndex: number;
  activeTabMainIndex: number;
  type: "componentGroup" | "component";
  selections: { id: string; name?: string; componentType?: string }[];
  individualComponentNames: string[];
  sourceSelectedKeys: string[];
  searchQuery: string;
  productId: string;
  features: { id: string; name: string }[];
  featureGroup: { id: string; name: string }[];
  components: { id: string; name: string }[];
  componentGroup: { id: string; name: string }[];
  templateId: string;
  newLicense: IAddLicenseModel;
  loading: boolean;
  company: companyType;
  product: productType;
  isTermStartPickerOpen: boolean;
  isTermEndPickerOpen: boolean;
  templateName?: string;
  error?: Record<
    | "company"
    | "product"
    | "commercialModel"
    | "enforcementModel"
    | "termStartDate"
    | "termEndDate"
    | "user_count"
    | "session_count"
    | "instance_count",
    boolean
  >;
}

class AddLicenseBlade extends React.Component<
  IAddLicenseBladeProps & {
    //onConfirm: (fn: any) => void;
    result: AddLicenseQueryResponse;
    retry: () => void;
    tabSwitch: any;
    license?: IAddLicenseModel;
    licenseState?: ILicenseBladeState;
    productSelectorRef?: any;
    //theme: any;
  },
  ILicenseBladeState
> {
  tabSwitch = React.createRef() as RefObject<TabView>;
  isUsedForTemplates = _.get(this.props, "routeData.isUsedForTemplates", false);

  public state: ILicenseBladeState = {
    ...this.props.licenseState,
    error: {
      commercialModel: false,
      enforcementModel: false,
      company: false,
      product: false,
      termStartDate: false,
      termEndDate: false,
      user_count: false,
      session_count: false,
      instance_count: false
    }
  };

  componentDidUpdate(
    _prevProps: any,
    prevState: Readonly<ILicenseBladeState>
  ): void {
    if (
      this.state.termEndDateRangeMonths &&
      !_.isEqual(this.state.termStartDate, prevState.termStartDate)
    ) {
      const termEndDate = calculateTermEnd(
        this.state.termEndDateRangeMonths,
        dayjs(this.state.termStartDate)
      );
      this.setState({ termEndDate });
    }
  }

  public createNewLicense = () => {
    //let graphqlId: string;
    this.setState({ loading: true });
    const gateway = getGateway();
    const features = _.get(this.state, "features", []).length
      ? this.state.features.map(item => item.id)
      : [];
    const components = _.get(this.state, "components", []).length
      ? this.state.components.map(item => item.id)
      : [];
    const licenseData: ILicenseModel = {
      license: {
        number_of_users: _.get(this.state, "newLicense.number_of_users", 0),
        default_token_duration: _.get(
          this.state,
          "newLicense.default_token_duration",
          0
        ),
        owner: _.get(this.state, "newLicense.owner"),
        product_id: _.get(this.state, "newLicense.product_id"),
        license_type_id: _.get(this.state, "newLicense.license_type_id"),
        owner_id: _.get(this.state, "newLicense.owner.owner_id"),
        owner_type: _.get(this.state, "newLicense.owner.owner_type"),
        grace_period: _.get(this.state, "newLicense.grace_period", 1),
        feature_group_ids: [_.get(this.state, "featureGroup[0].id", null)],
        feature_ids: features,
        component_group_ids: [_.get(this.state, "componentGroup[0].id", null)],
        component_ids: components,
        commercial_model_id: `${_.get(this.state, "commercialModelId")}`,
        is_vendor: _.get(this.state, "newLicense.is_vendor")
      },
      entitlement: {
        user_count:
          this.state.enforcementModel === enforcementModel.namedUser
            ? _.get(this.state, "newLicense.user_count", 1)
            : null,
        instance_count:
          this.state.enforcementModel === enforcementModel.nodeLocked
            ? _.get(this.state, "newLicense.instance_count", 1)
            : null,
        session_count:
          this.state.enforcementModel === enforcementModel.concurrent
            ? _.get(this.state, "newLicense.session_count", 1)
            : null
      },
      restriction: {
        max_instances_per_user: _.get(
          this.state,
          "newLicense.max_instances_per_user",
          null
        ),
        instances_per_user: _.get(
          this.state,
          "newLicense.instances_per_user",
          null
        ),
        users_per_instance: _.get(
          this.state,
          "newLicense.users_per_instance",
          null
        ),
        sessions_per_user: _.get(
          this.state,
          "newLicense.sessions_per_user",
          null
        ),
        sessions_per_instance: _.get(
          this.state,
          "newLicense.sessions_per_instance",
          null
        ),
        sessions_per_user_per_instance: _.get(
          this.state,
          "newLicense.sessions_per_user_per_instance",
          null
        ),
        minimum_assignment_length: _.get(
          this.state,
          "newLicense.minimum_assignment_length",
          null
        ),
        require_email_activation: _.get(
          this.state,
          "newLicense.require_email_activation",
          null
        ),
        number_of_installs: _.get(
          this.state,
          "newLicense.number_of_installs",
          null
        ),
        number_of_users: _.get(this.state, "newLicense.number_of_users", null)
      },
      term: {
        start_date: _.get(this.state, "termStartDate", dayjs()).toISOString(),
        end_date: _.get(this.state, "termEndDate", dayjs()).toISOString()
      }
    };
    gateway.request
      .newLicense(licenseData)
      .then(res => {
        this.setState({ loading: false });
        if (!res.error) {
          const license_id = btoa(`License-${_.get(res, "data.license_id")}`);
          this.props.closeBlade({ route: "AddLicense" });
          this.props.openBlade({
            route: "License",
            routeData: {
              id: license_id, // licenseResponse.graphql_id,
              productId: this.state.product.id
            },
            fromBladeIndex: this.props.index,
            routeName: "License"
          });
          this.props.refreshAllOpenBlades();
        }

        // if (_.isFunction(_.get(this.props.payload, "onConfirm"))) {
        //   this.props.payload.onConfirm({
        //     termResponse: res.data,
        //     licenseResponse
        //   });
        // }
      })
      .catch(e => {
        console.error(e);
        //this.setState({ loading: false });
        // this.props.openDialog("ErrorDialog", { message: e.message });
      });
  };

  private handleCatch = (error: any) => {
    console.error(error);
  };

  public editTemplate = (createDuplicate: boolean = false) => {
    const commericalModelTypeId = _.get(this.state, "commercialModelId");
    const templateBody = {
      license: this.state.newLicense,
      commercialModel: this.state.commercialModel || "",
      enforcementModel: this.state.enforcementModel,
      features: _.get(this.state, "features", []),
      feature_group: _.get(this.state, "featureGroup", []),
      components: _.get(this.state, "components", []),
      component_group: _.get(this.state, "componentGroup", []),
      product: _.get(this.state, "product", null),
      productId: _.get(this.state, "product.id"),
      license_type_id: _.get(this.state, "newLicense.license_type_id"),
      commercial_model_id: base64EncodeId(
        "CommercialModel",
        commericalModelTypeId
      ),
      company: _.get(this.state, "company")
    };

    const templateData: ILicenseTemplate = {
      product_id: _.get(this.state, "product.id"),
      name: _.get(this.state, "templateName", ""),
      template_body: JSON.stringify(templateBody)
    };
    const gateway = getGateway();

    const isTemplateEdit = _.get(this.props, "routeData.templateId", "");

    if (!isTemplateEdit) {
      gateway.request
        .create_template(templateData)
        .then(res => {
          if (!res.error) {
            this.props.closeBlade({ route: "AddLicense" });
            renderNotification("New template added");
            this.props.refreshBlade("Account");
          }
        })
        .catch(this.handleCatch);
      return;
    }

    if (createDuplicate) {
      gateway.request
        .create_template(templateData)
        .then(res => {
          if (!res.error) {
            //alert("Template added");
            renderNotification("Template duplicated");
            this.props.refreshBlade("Account");
          }
        })
        .catch(this.handleCatch);
    } else {
      gateway.request
        .edit_template(templateData, { id: _.get(this.state, "templateId") })
        .then(res => {
          if (!res.error) {
            //alert("Template added");
            renderNotification("Template changes saved");
            this.props.closeBlade({ route: "AddLicense" });
            this.props.refreshBlade("Account");
          }
        })
        .catch(this.handleCatch);
    }
  };

  public onCompanyChange = (company: any) => {
    this.setState({
      company,
      newLicense: {
        ...this.state.newLicense,
        owner: {
          name: company.name,
          owner_id: company.id,
          owner_type: company.type
        },
        owner_id: company.id,
        owner_type: company.type
      },
      error: { ...this.state.error, company: false }
    });
  };

  public onProductChange = (product: any) => {
    this.setState({
      product,
      enforcementModel: null,
      newLicense: {
        ...this.state.newLicense,
        product_id: product.id,
        license_type_id: null
      },
      error: { ...this.state.error, product: false }
    });
  };

  public updateIntValueOfRequest = (
    key: keyof Pick<
      IAddLicenseModel,
      | "number_of_installs"
      | "instance_count"
      | "grace_period"
      | "user_count"
      | "instances_per_user"
      | "number_of_users"
      | "max_instances_per_user"
      | "users_per_instance"
      | "session_count"
      | "sessions_per_user"
      | "sessions_per_instance"
      | "sessions_per_user_per_instance"
      | "minimum_assignment_length"
      | "default_token_duration"
      | "value"
      | "license_value"
      | "term_value"
    >
  ) => (value: string) => {
    const newLicenseState = {
      ...this.state.newLicense
    };
    newLicenseState[key] = parseInt(value, 10);
    this.setState({
      ...this.state,
      newLicense: newLicenseState
    });
  };

  public navAway = () => {
    this.props.openBlade({
      routeName: "Customer portal",
      fromBladeIndex: this.props.index,
      route: "Overview",
      routeData: null
    });
  };

  public navToUserBlade = (user: userTableType) => {
    this.props.openBlade({
      route: "User",
      routeName: `${user.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: user.id,
        name: user.name
        // productId: ''
      }
    });
  };

  public openTemplateSelectDialog = () => {
    this.props.openDialog("SelectTemplateDialog", {
      // licenseId: this.props.routeData.id,
      onConfirm: template => {
        //this.props.refreshAllOpenBlades();
        //const tmplStateData = { ..._.omit(template, "license")}
        this.setState({
          newLicense: {
            ...this.state.newLicense,
            ...template?.license,
            owner: this.state.newLicense.owner,
            owner_id: this.state.newLicense.owner_id,
            owner_type: this.state.newLicense.owner_type
          },
          product: template?.product,
          productId: template?.productId,
          commercialModel: template?.commercialModel,
          enforcementModel: template?.enforcementModel,
          featureGroup: template?.feature_group,
          features: template?.features,
          componentGroup: template?.component_group,
          components: template?.components
        });
        this.tabSwitch.current.updateActiveTab(0)();
        this.props.closeDialog("SelectTemplateDialog");
      }
    });
  };

  public openTemplateAddDialog = () => {
    const templateData = {
      license: this.state.newLicense,
      commercialModel: this.state.commercialModel || "",
      enforcementModel: this.state.enforcementModel,
      features: _.get(this.state, "features", []),
      feature_group: _.get(this.state, "featureGroup", []),
      components: _.get(this.state, "components", []),
      component_group: _.get(this.state, "componentGroup", []),
      product: _.get(this.state, "product", null),
      productId: _.get(this.state, "product.id"),
      license_type_id: _.get(this.state, "newLicense.license_type_id"),
      commercial_model_id: _.get(this.state, "commercialModelId"),
      company: _.get(this.state, "company")
    };
    this.props.openDialog("AddTemplateDialog", {
      licenseId: this.props.routeData.id,
      templateData,
      onConfirm: () => {
        this.props.refreshAllOpenBlades();
        this.props.closeDialog("AddTemplateDialog");
      }
    });
  };

  public renderLeftSideHeader = () => {
    const isTemplateIdUsed = !!_.get(this.props, "routeData.templateId", "");
    let templateHeaderTitle = "Create a license";
    if (this.isUsedForTemplates && isTemplateIdUsed)
      templateHeaderTitle = "Edit template";
    if (this.isUsedForTemplates && !isTemplateIdUsed)
      templateHeaderTitle = "Add template";
    return (
      <Flex flexDirection="column">
        <BladeTitle>{templateHeaderTitle}</BladeTitle>
        <Flex>
          {!this.isUsedForTemplates && (
            <StyledTemplateBtn onClick={() => this.openTemplateSelectDialog()}>
              Select template
            </StyledTemplateBtn>
          )}

          {/* <StyledTemplateBtn style={{ cursor: "not-allowed" }}>
            Import from Salesforce
          </StyledTemplateBtn> */}
        </Flex>
      </Flex>
    );
  };

  public renderSectionHeader = (sectionName: string) => {
    return <SectionHeader>{sectionName}</SectionHeader>;
  };

  public renderCommercialModelFields = () => {
    return (
      <Flex flexDirection="column">
        {this.state.commercialModel === commercialModel.perpetualMS
          ? this.renderTermStartEndFields()
          : null}
        {this.state.commercialModel === commercialModel.perpetualMS ? (
          <>
            {!this.isUsedForTemplates && (
              <>
                {this.renderLicenseValue()}
                <div style={{ marginTop: "23px" }}>
                  {this.renderTermValueField()}
                </div>
              </>
            )}
          </>
        ) : null}

        {this.isUsedForTemplates &&
        (this.state.commercialModel === commercialModel.lease ||
          this.state.commercialModel === commercialModel.saas)
          ? this.renderTermValueField()
          : null}

        {this.state.commercialModel === commercialModel.lease ||
        this.state.commercialModel === commercialModel.saas ||
        this.state.commercialModel === commercialModel.freeTrial
          ? this.renderTermStartEndFields()
          : null}
      </Flex>
    );
  };

  public renderUsersField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("user_count")}
        defaultValue={_.get(this.state, "newLicense.user_count")}
        style={{ marginBottom: "23px" }}
        label={"Users"}
        type="number"
        required={true}
        error={this.state.error.user_count}
        errorMessage={"User count is required"}
        placeholder={"Enter user count"}
      />
    );
  };

  public renderNumberOfUsersRestrictionField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("number_of_users")}
        style={{ marginBottom: "23px" }}
        defaultValue={_.get(
          { ...this.state.newLicense },
          "number_of_users",
          null
        )}
        key="number_of_users"
        type="number"
        label={"Number of Users"}
        placeholder={"Enter value"}
      />
    );
  };
  public renderNumberOfInstallsRestrictionField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("number_of_installs")}
        style={{ marginBottom: "23px" }}
        defaultValue={_.get(
          { ...this.state.newLicense },
          "number_of_installs",
          null
        )}
        key="number_of_installs_restriction"
        type="number"
        label={"Number of Installs"}
        placeholder={"Enter value"}
      />
    );
  };

  public renderGracePeriodFields = () => {
    return (
      <Flex>
        <ElmInput
          updatedValue={this.updateIntValueOfRequest("grace_period")}
          defaultValue={_.get(this.state, "newLicense.grace_period")}
          label={"Grace period (days)"}
          type="number"
          placeholder={"Enter value"}
        />
        {/* <ElmSelectWithLabel
          label=" "
          placeholder="Choose period"
          style={{ width: "146px", marginRight: "9px" }}
          // onChange={(date) => {
          //   this.setState({ termEnd: date });
          // }}
        >
          <ElmSelectOption key={1} value={"period 1"} label={"Period 1"}>
            Period 1
          </ElmSelectOption>
          <ElmSelectOption key={2} value={"period 2"} label={"Period 2"}>
            Period 2
          </ElmSelectOption>
        </ElmSelectWithLabel> */}
      </Flex>
    );
  };

  public renderNumberOfSessionField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("session_count")}
        style={{ marginBottom: "23px" }}
        defaultValue={_.get(
          { ...this.state.newLicense },
          "session_count",
          null
        )}
        key="session_count"
        type="number"
        label={"Number of sessions"}
        error={this.state.error.session_count}
        errorMessage={"Session count is required"}
        required={true}
        placeholder={"Enter sessions count"}
      />
    );
  };

  public renderDefaultSessionLengthField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("default_token_duration")}
        defaultValue={_.get(this.state, "newLicense.default_token_duration", 0)}
        style={{ marginBottom: "23px" }}
        type="number"
        label={"Default session length (sec)"}
        placeholder={"Enter session length value"}
      />
    );
  };

  public renderNumberOfInstallsField = () => {
    return (
      <ElmInput
        key="instance_count"
        updatedValue={this.updateIntValueOfRequest("instance_count")}
        defaultValue={_.get(this.state.newLicense, "instance_count", null)}
        style={{ marginBottom: "23px" }}
        type="number"
        error={this.state.error.instance_count}
        errorMessage={"Installs count is required"}
        required={true}
        label={"Number of installs"}
        placeholder={"Enter installs count"}
      />
    );
  };
  public renderInstancesPerUserField = () => {
    return (
      <ElmInput
        key="instances_per_user"
        updatedValue={this.updateIntValueOfRequest("instances_per_user")}
        defaultValue={_.get(this.state.newLicense, "instances_per_user", null)}
        style={{ marginBottom: "23px" }}
        type="number"
        label={"Instances per user"}
        placeholder={"Enter value"}
      />
    );
  };

  public renderTermValueField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("term_value")}
        defaultValue={_.get(this.state, "newLicense.term_value")}
        style={{ marginBottom: "23px", width: "300px" }}
        prefix="$"
        label={
          this.state.commercialModel !== commercialModel.perpetualMS
            ? "Term value"
            : "Maintenance term value"
        }
        placeholder={"Enter amount"}
        type={"number"}
        isDecimalNumber={true}
      />
    );
  };

  public renderTermStartEndFields = () => {
    const handleChange = (type: "termStartDate" | "termEndDate") => (
      p: Dayjs
    ) => {
      const newState = { ...this.state };
      newState[type] = p;
      newState[`${type}Humanized`] = p.format(DATE_FORMAT);
      this.setState(newState);
    };
    if (this.isUsedForTemplates) return null;
    return (
      <Flex marginBottom="23px">
        <ElmSelectWithLabel
          label="Term start"
          error={this.state.error.termStartDate}
          errorMessage={"Required"}
          required={true}
          onClick={() => this.setState({ isTermStartPickerOpen: true })}
          onBlur={() => this.setState({ isTermStartPickerOpen: false })}
          open={this.state.isTermStartPickerOpen}
          value={_.get(this.state, "termStartDateHumanized", null)}
          style={{ width: "146px", marginRight: "9px" }}
          onChange={date => {
            if (date === "custom") {
              this.setState({
                termStartDateHumanized: date
              });
              return;
            }
            const sDate: Dayjs = termStart[date];
            this.setState({
              termStartDate: sDate,
              termStartDateHumanized: date,
              error: { ...this.state.error, termStartDate: false }
            });
          }}
        >
          {_.map(Object.keys(termStart), item => {
            return (
              <ElmSelectOption key={item} value={item} label={item}>
                <Tooltip title="">{item}</Tooltip>
              </ElmSelectOption>
            );
          })}
          <ElmSelectOption key={"custom"} value={"custom"} label={"custom"}>
            <ElmDatePicker
              format={DATE_FORMAT}
              onChange={handleChange("termStartDate")}
              value={this.state.termStartDate ? this.state.termStartDate : null}
            />
          </ElmSelectOption>
        </ElmSelectWithLabel>
        <ElmSelectWithLabel
          label="Term end"
          error={this.state.error.termEndDate}
          errorMessage={"Required"}
          required={true}
          style={{ width: "146px", marginRight: "9px" }}
          onClick={() => this.setState({ isTermEndPickerOpen: true })}
          onBlur={() => this.setState({ isTermEndPickerOpen: false })}
          open={this.state.isTermEndPickerOpen}
          value={_.get(this.state, "termEndDateHumanized", null)}
          onChange={durationInMonths => {
            if (durationInMonths === "custom") {
              return;
            }
            const eDate = calculateTermEnd(
              durationInMonths,
              this.state.termStartDate
                ? dayjs(this.state.termStartDate)
                : dayjs()
            );
            this.setState({
              termEndDate: eDate,
              termEndDateRangeMonths: durationInMonths,
              termEndDateHumanized: durationInMonths,
              error: { ...this.state.error, termEndDate: false }
            });
          }}
        >
          {this.state.commercialModel === commercialModel.freeTrial
            ? _.map(Object.keys(termEndFreeTrial), item => {
                return (
                  <ElmSelectOption
                    key={item}
                    value={termEndFreeTrial[item]}
                    label={item}
                  >
                    <Tooltip title="">{item}</Tooltip>
                  </ElmSelectOption>
                );
              })
            : _.map(Object.keys(termEnd), item => {
                return (
                  <ElmSelectOption
                    key={item}
                    value={termEnd[item]}
                    label={item}
                  >
                    <Tooltip title="">{item}</Tooltip>
                  </ElmSelectOption>
                );
              })}
          <ElmSelectOption key={"custom"} value={"custom"} label={"custom"}>
            <ElmDatePicker
              onChange={handleChange("termEndDate")}
              format={DATE_FORMAT}
              value={this.state?.termEndDate ? this.state?.termEndDate : null}
              disabledDate={d => d.isBefore(this.state.termStartDate?.toDate())}
            />
          </ElmSelectOption>
        </ElmSelectWithLabel>
      </Flex>
    );
  };

  public generateFieldsForEnforcement = () => {
    const {
      commercialModel: commercial,
      enforcementModel: enforcement
    } = this.state;

    /// commercialmodel = PerpetualMS
    if (
      commercial === commercialModel.perpetualMS &&
      enforcement === enforcementModel.namedUser
    ) {
      return [this.renderUsersField, this.renderGracePeriodFields];
    }
    if (
      commercial === commercialModel.perpetualMS &&
      enforcement === enforcementModel.concurrent
    ) {
      return [
        this.renderNumberOfSessionField,
        this.renderDefaultSessionLengthField
      ];
    }
    if (
      commercial === commercialModel.perpetualMS &&
      enforcement === enforcementModel.nodeLocked
    ) {
      return [this.renderNumberOfInstallsField, this.renderGracePeriodFields];
    }
    if (
      commercial === commercialModel.perpetualMS &&
      enforcement === enforcementModel.unrestricted
    ) {
      return [this.renderGracePeriodFields];
    }
    /// commercialModel = SaaS || Lease
    if (
      (commercial === commercialModel.saas ||
        commercial === commercialModel.lease) &&
      enforcement === enforcementModel.namedUser
    ) {
      return [this.renderUsersField, this.renderGracePeriodFields];
    }
    if (
      (commercial === commercialModel.saas ||
        commercial === commercialModel.lease) &&
      enforcement === enforcementModel.concurrent
    ) {
      return [
        this.renderNumberOfSessionField,
        this.renderDefaultSessionLengthField
      ];
    }
    if (
      (commercial === commercialModel.saas ||
        commercial === commercialModel.lease) &&
      enforcement === enforcementModel.unrestricted
    ) {
      return [this.renderGracePeriodFields];
    }
    if (
      (commercial === commercialModel.saas ||
        commercial === commercialModel.lease) &&
      enforcement === enforcementModel.nodeLocked
    ) {
      return [this.renderNumberOfInstallsField, this.renderGracePeriodFields];
    }

    /// commercialModel = free trial
    if (
      commercial === commercialModel.freeTrial &&
      enforcement === enforcementModel.namedUser
    ) {
      return [this.renderUsersField, this.renderGracePeriodFields];
    }
    if (
      commercial === commercialModel.freeTrial &&
      enforcement === enforcementModel.concurrent
    ) {
      return [
        this.renderNumberOfSessionField,
        this.renderDefaultSessionLengthField
      ];
    }
    if (
      commercial === commercialModel.freeTrial &&
      enforcement === enforcementModel.nodeLocked
    ) {
      return [this.renderNumberOfInstallsField, this.renderGracePeriodFields];
    }
    if (
      commercial === commercialModel.freeTrial &&
      enforcement === enforcementModel.unrestricted
    ) {
      return [this.renderGracePeriodFields];
    }

    /// commercialModel = Freeware
    if (
      commercial === commercialModel.freeware &&
      enforcement === enforcementModel.unrestricted
    ) {
      return [this.renderGracePeriodFields];
    }
  };

  public renderEnforceEmailActivationField = () => {
    const onEmailActivation = (e: CheckboxChangeEvent) => {
      this.setState({
        newLicense: {
          ...this.state.newLicense,
          require_email_activation: e.target.checked
        }
      });
    };
    return (
      <ElmCheckBox
        onChange={onEmailActivation}
        defaultChecked={_.get(
          this.state,
          "newLicense.require_email_activation",
          false
        )}
      >
        Enforce email activation
      </ElmCheckBox>
    );
  };

  public renderSessionsPerUserField = () => {
    return (
      <ElmInput
        style={{ marginBottom: "23px" }}
        updatedValue={this.updateIntValueOfRequest("sessions_per_user")}
        defaultValue={_.get(this.state, "newLicense.sessions_per_user")}
        label={"Sessions per user"}
        placeholder={"Enter value"}
        type={"number"}
      />
    );
  };

  public renderSessionsPerInstallField = () => {
    return (
      <ElmInput
        style={{ marginBottom: "23px" }}
        updatedValue={this.updateIntValueOfRequest("sessions_per_instance")}
        defaultValue={_.get(this.state, "newLicense.sessions_per_instance")}
        label={"Sessions per install"}
        placeholder={"Enter value"}
        type={"number"}
      />
    );
  };

  public renderUsersPerInstallField = () => {
    return (
      <ElmInput
        style={{ marginBottom: "23px" }}
        updatedValue={this.updateIntValueOfRequest("users_per_instance")}
        defaultValue={_.get(this.state, "newLicense.users_per_instance")}
        label={"Users per install"}
        placeholder={"Enter value"}
        type={"number"}
      />
    );
  };

  public renderLicenseValue = () => {
    return (
      <ElmInput
        style={{ width: "300px" }}
        updatedValue={this.updateIntValueOfRequest("license_value")}
        defaultValue={_.get(this.state, "newLicense.license_value")}
        prefix={"$"}
        type={"number"}
        isDecimalNumber={true}
        label={"License value"}
        placeholder={"Enter amount"}
      />
    );
  };

  public renderSessionsPerUserPerInstallField = () => {
    return (
      <ElmInput
        type={"number"}
        style={{ marginBottom: "23px" }}
        updatedValue={this.updateIntValueOfRequest(
          "sessions_per_user_per_instance"
        )}
        defaultValue={_.get(
          this.state,
          "newLicense.sessions_per_user_per_instance"
        )}
        label={"Sessions per user per install"}
        placeholder={"Enter value"}
      />
    );
  };
  public renderMinimumAssignmentLengthField = () => {
    return (
      <ElmInput
        type={"number"}
        style={{ marginBottom: "23px" }}
        updatedValue={this.updateIntValueOfRequest("minimum_assignment_length")}
        defaultValue={_.get(this.state, "newLicense.minimum_assignment_length")}
        label={"Minimum assignment length"}
        placeholder={"Enter value"}
      />
    );
  };
  public generateFieldsForRestrictions = () => {
    const { enforcementModel: enforcement } = this.state;

    switch (enforcement) {
      case enforcementModel.namedUser:
        return [
          this.renderInstancesPerUserField,
          this.renderMinimumAssignmentLengthField,
          this.renderEnforceEmailActivationField
        ];
      case enforcementModel.nodeLocked:
        return [
          this.renderUsersPerInstallField,
          this.renderSessionsPerUserField,
          this.renderSessionsPerInstallField,
          this.renderSessionsPerUserPerInstallField,
          this.renderEnforceEmailActivationField
        ];
      case enforcementModel.concurrent:
        return [
          this.renderSessionsPerUserField,
          this.renderSessionsPerInstallField,
          this.renderSessionsPerUserPerInstallField,
          this.renderNumberOfUsersRestrictionField,
          this.renderNumberOfInstallsRestrictionField,
          this.renderEnforceEmailActivationField
        ];
      default:
        return [this.renderEnforceEmailActivationField];
    }
  };

  public updateLicenseType = (licenseTypeId: any) => {
    const enforcementModelName = (licenseTypeToName(
      licenseTypeId
    ) as unknown) as enforcementModel;
    this.setState({
      ...this.state,
      enforcementModel: enforcementModelName,
      newLicense: {
        ...this.state.newLicense,
        license_type_id: licenseTypeId
      },
      error: {
        ...this.state.error,
        enforcementModel: false
      }
    });
  };

  public validateForm = () => {
    let isValid = true;
    let error = { ...this.state.error };
    this.setState({ error: null });
    if (!this.state.enforcementModel) {
      error.enforcementModel = true;
      isValid = false;
    } else {
      error.enforcementModel = false;
    }
    if (
      this.state.commercialModel !== commercialModel.freeware &&
      this.state.enforcementModel === enforcementModel.namedUser &&
      !this.state.newLicense.user_count
    ) {
      error.user_count = true;
      isValid = false;
    } else {
      error.user_count = false;
    }
    if (
      this.state.commercialModel !== commercialModel.freeware &&
      this.state.enforcementModel === enforcementModel.concurrent &&
      !this.state.newLicense.session_count
    ) {
      error.session_count = true;
      isValid = false;
    } else {
      error.session_count = false;
    }
    if (
      this.state.commercialModel !== commercialModel.freeware &&
      this.state.enforcementModel === enforcementModel.nodeLocked &&
      !this.state.newLicense.instance_count
    ) {
      error.instance_count = true;
      isValid = false;
    } else {
      error.instance_count = false;
    }
    if (!this.state.commercialModel) {
      error.commercialModel = true;
      isValid = false;
    } else {
      error.commercialModel = false;
    }
    if (
      this.state.commercialModel !== commercialModel.freeware &&
      (!this.state.termStartDate || !this.state.termEndDate)
    ) {
      if (!this.state.termStartDate) {
        error.termStartDate = true;
      } else {
        error.termStartDate = false;
      }
      if (!this.state.termEndDate) {
        error.termEndDate = true;
      } else {
        error.termEndDate = false;
      }
      isValid = false;
    }
    if (!this.state.product) {
      error.product = true;
      isValid = false;
    } else {
      error.product = false;
    }
    if (!this.state.company) {
      error.company = true;
      isValid = false;
    } else {
      error.company = false;
    }
    this.setState({ error });
    return isValid;
  };

  public renderLicenseDetails = () => {
    const updateVendor = (e: CheckboxChangeEvent) => {
      this.setState({
        newLicense: { ...this.state.newLicense, is_vendor: e.target.checked }
      });
    };

    //const enforceFieldsGenerated = this.generateFieldsForEnforcement();
    return (
      <Flex flexDirection="column" marginTop="19px">
        {this.renderSectionHeader("Basic information")}
        <Flex justifyItems="center" flex={1}>
          {!this.isUsedForTemplates && (
            <CompanySelector
              onChange={this.onCompanyChange}
              className="subtle"
              createNewCompany={true}
              label="Company"
              placeholder="Select company"
              error={this.state.error?.company}
              errorMessage={"Company is required"}
              required={true}
              style={{ width: "300px", marginRight: "32px" }}
              //disabled
              defaultValue={_.get(this.state, "newLicense.owner_id", null)}
              value={_.get(this.state, "newLicense.owner_id", null)}
            />
          )}

          <LoadingWrapper>
            <ProductSelectorComponent
              className="subtle"
              label="Product"
              placeholder="Select product"
              style={{ width: "300px" }}
              error={this.state.error?.product}
              errorMessage={"Product is required"}
              required={true}
              defaultValue={_.get(this.state, "newLicense.product_id", null)}
              value={_.get(this.state, "newLicense.product_id", null)}
              onChange={this.onProductChange}
            />
          </LoadingWrapper>
        </Flex>
        {!this.isUsedForTemplates && (
          <Flex flex={1}>
            <ElmCheckBox
              onChange={updateVendor}
              style={{ alignSelf: "flex-start", marginTop: "15px" }}
              defaultChecked={_.get(this.state, "newLicense.is_vendor", null)}
            >
              Vendor
            </ElmCheckBox>
          </Flex>
        )}

        <Flex marginTop="40px">
          {this.renderSectionHeader("Commercial information")}
        </Flex>

        <Flex style={{ width: "300px", marginBottom: "23px" }}>
          <ElmSelectWithLabel
            error={this.state.error?.commercialModel}
            errorMessage={"Commercial model is required"}
            required={true}
            label="Commercial model"
            placeholder="Select model"
            defaultValue={_.get(this.state, "commercialModel", null)}
            // status="error"
            // errorMessage="test"
            value={this.state.commercialModel}
            onChange={model => {
              const modelTypeId = commercialModelType[model];
              this.setState({
                commercialModel: commercialModel[model],
                commercialModelId: +modelTypeId,
                error: { ...this.state.error, commercialModel: false }
              });
            }}
          >
            {_.map(Object.keys(commercialModel), item => {
              return (
                <ElmSelectOption
                  key={item}
                  value={item}
                  label={commercialModel[item]}
                >
                  {commercialModel[item]}
                </ElmSelectOption>
              );
            })}
          </ElmSelectWithLabel>
        </Flex>
        <Flex>{this.renderCommercialModelFields()}</Flex>
        <Flex marginTop="40px" flex={1}>
          <Flex flexGrow={1} flexDirection="column" marginRight="40px">
            {this.renderSectionHeader("Enforcement information")}
            <div>
              <div style={{ marginBottom: "26px" }}>
                <LicenseEnforcementSelector
                  className="subtle"
                  product={this.state.product}
                  defaultValue={_.get(
                    this.state,
                    "newLicense.license_type_id",
                    null
                  )}
                  value={_.get(this.state, "newLicense.license_type_id", null)}
                  updateLicenseType={this.updateLicenseType}
                  label="Enforcement model"
                  placeholder="Select model"
                  required={true}
                  disabled={!this.state.product}
                  error={this.state.error?.enforcementModel}
                  errorMessage={"Enforcement model is required"}
                  style={{ width: "300px" }}
                />
              </div>
              <div>
                {_.map(this.generateFieldsForEnforcement(), field => field())}
              </div>
            </div>
          </Flex>
          {this.state.enforcementModel && (
            <Flex flexGrow={1} width={300} flexDirection="column">
              {this.renderSectionHeader("Restrictions")}
              {_.map(this.generateFieldsForRestrictions(), field => field())}
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  };

  public renderFeatures = () => {
    const onChange = (payload: {
      type: FeatureOrGroup;
      id?: string;
      name?: string;
      value?: boolean;
      updateQuery?: () => void;
      features?: { id: string; name: string }[];
      featureGroup?: { id: string; name: string }[];
    }) => {
      if (payload.type === "feature") {
        this.setState({ features: payload.features });
      }
      if (payload.type === "group") {
        this.setState({ featureGroup: payload.featureGroup });
      }

      // this.props.gateway.request[
      //   payload.value ? "enableFeature" : "disableFeature"
      // ]({ feature_group: payload.name }, { id: this.props.routeData.id }).then(
      //   () => {
      //     this.props.refreshAllOpenBlades();
      //     payload.updateQuery();
      //   }
      // );
    };
    return (
      <LoadingWrapper>
        <FeaturesAndGroupsToggle
          onChange={onChange}
          productId={_.get(this.state, "product.id", null)}
          licenseId={""} //this.state.productId
          features={this.state.features}
          featureGroup={this.state.featureGroup}
          templateId={this.state.templateId}
        />
      </LoadingWrapper>
    );
  };

  public renderComponents = () => {
    const onChange = (payload: {
      type: ComponentOrGroup;
      id?: string;
      name?: string;
      value?: boolean;
      updateQuery?: () => void;
      components?: { id: string; name: string }[];
      componentGroup?: { id: string; name: string }[];
    }) => {
      if (payload.type === "component") {
        this.setState({ components: payload.components });
      }
      if (payload.type === "group") {
        this.setState({ componentGroup: payload.componentGroup });
      }
    };
    return (
      <LoadingWrapper>
        <ComponentsAndGroupsToggle
          onChange={onChange}
          productId={_.get(this.state, "product.id", null)}
          licenseId={""}
          components={this.state.components}
          componentGroup={this.state.componentGroup}
          templateId={this.state.templateId}
        />
      </LoadingWrapper>
    );
  };

  public getTabs = () => {
    //if (res === null) return [];
    let ret = [];
    ret.push({
      title: "Features",
      Component: this.renderFeatures()
    });
    ret.push({
      title: "Components",
      Component: this.renderComponents()
    });

    return ret as ITab[];
  };

  public updateActiveTab = (activeTabIndex: number) => {
    this.setState({
      activeTabIndex
    });
  };

  public updateActiveMainTab = (activeTabMainIndex: number) => {
    this.setState({
      activeTabMainIndex
    });
  };

  public renderFeaturesAndComponents = () => {
    return (
      <TabView
        showBottomBorder={true}
        leftAlign={true}
        tabList={this.getTabs()}
        onUpdateActiveTab={this.updateActiveTab}
        orientation={"vertical"}
      />
    );
  };

  public renderComponentsTab = () => {
    const views = [
      {
        title: "License details",
        Component: this.renderLicenseDetails(),
        style: sharedTabStyle
        //Component: this.renderLicenseDetailsSimplified(res),
      },
      {
        title: "Features and components",
        Component: this.renderFeaturesAndComponents(),
        style: sharedTabStyle
      }
    ] as ITab[];
    // return <ToggleView views={views} />;
    return (
      <TabView
        ref={this.tabSwitch}
        showBottomBorder={true}
        leftAlign={true}
        tabList={views}
        onUpdateActiveTab={this.updateActiveMainTab}
        orientation={"horizontal"}
      />
    );
  };

  public renderLicenseInfo = () => {
    // if (!payload.props) {
    //   this.props.showScreenLoader();
    //   return null;
    // }
    // this.props.hideScreenLoader();
    //const res = this.props.result;
    //const license = _.get(res, "license");
    const getCancelButtonLabel = () => {
      switch (this.state.activeTabMainIndex) {
        case 0:
          return "Cancel";
        default:
          return "Back";
      }
      //return this.state.activeTabMainIndex === 0 ? "Next" : "Create"
    };
    const getButtonLabel = () => {
      switch (this.state.activeTabMainIndex) {
        case 0:
          return "Next";
        default:
          return "Create";
      }
      //return this.state.activeTabMainIndex === 0 ? "Next" : "Create"
    };

    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"License"}
        bladeType="License"
        closeBlade={this.props.closeBlade}
        //topAccentColor={"mango"}
        accentColor={"mango"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={() => <></>}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        //loading={!res}
      >
        <RelayEnvironmentProvider environment={relayEnvironment}>
          <Flex
            flexDirection="column"
            flex={1}
            justifyContent="space-between"
            style={{
              marginTop: "32px",
              marginBottom: "56px",
              marginLeft: "21px",
              marginRight: "21px",
              overflowX: "hidden"
            }}
          >
            <Flex flexDirection="column">
              {this.isUsedForTemplates && (
                <ElmInput
                  label="Template name"
                  placeholder="e.g. flotools Enterprise"
                  note="Name your template for easier recognisibility"
                  style={{ marginBottom: "32px", width: "300px" }}
                  defaultValue={_.get(this.state, "templateName")}
                  updatedValue={value => this.setState({ templateName: value })}
                />
              )}
              {this.renderComponentsTab()}
            </Flex>
          </Flex>
          <Flex
            height={56}
            padding={12}
            style={{
              boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
              position: "absolute",
              bottom: 0,
              width: "100%"
              //zIndex: 999,
            }}
            //flex={1}
            justifyContent="space-between"
          >
            {this.isUsedForTemplates && (
              <Flex>
                <ElmButton
                  colorVariance="outline-secondary"
                  label="Cancel"
                  style={{ marginLeft: "0px" }}
                  onClick={() => this.props.closeBlade({ route: "AddLicense" })}
                />
                <ElmButton
                  label="Save"
                  //style={{ marginLeft: "0px" }}
                  onClick={this.editTemplate}
                />
              </Flex>
            )}
            {!this.isUsedForTemplates && (
              <Flex>
                <ElmButton
                  colorVariance={"outline-secondary"}
                  label={getCancelButtonLabel()}
                  style={{ marginLeft: "0px" }}
                  onClick={() => {
                    if (this.state.activeTabMainIndex === 1) {
                      //this.updateActiveMainTab(1);
                      this.tabSwitch.current.updateActiveTab(0)();
                    }
                    if (this.state.activeTabMainIndex === 0) {
                      this.props.closeBlade({ route: "AddLicense" });
                    }
                  }}
                />
                <ElmButton
                  onClick={() => {
                    const isValid = this.validateForm();
                    if (!isValid) {
                      this.tabSwitch.current.updateActiveTab(0)();
                      return;
                    }
                    if (this.state.activeTabMainIndex === 0) {
                      //this.updateActiveMainTab(1);
                      this.tabSwitch.current.updateActiveTab(1)();
                    }
                    if (this.state.activeTabMainIndex === 1) {
                      this.createNewLicense();
                      //alert("Create license");
                    }
                  }}
                  label={getButtonLabel()}
                />
              </Flex>
            )}
            {!this.isUsedForTemplates && (
              <Flex>
                <ElmButton
                  variance={"plain-icon-button"}
                  colorVariance={"subtle"}
                  icon={"plus"}
                  iconPrefix={"fas"}
                  label={"Add to templates"}
                  permissions="modify_assets"
                  style={{ maxWidth: "200px" }}
                  onClick={this.openTemplateAddDialog}
                  // onClick={() =>
                  //   this.setState({
                  //     features: [{ id: "RmVhdHVyZS01Mg==", name: "EnablePi" }],
                  //     selections: [{ name: "Corrosion", id: "Q29tcG9uZW50LTI=" }],
                  //     templateId: "1244242",
                  //   })
                  // }
                />
              </Flex>
            )}
          </Flex>
        </RelayEnvironmentProvider>
      </BladeTemplate>
    );
  };

  public render() {
    return this.renderLicenseInfo();
  }
}

const graphqlQuery = graphql`
  query AddLicenseQuery(
    $id: ID!
    $templateId: ID!
    $skip: Boolean!
    $skipTemplate: Boolean!
  ) {
    licenseTemplate(id: $templateId) @skip(if: $skipTemplate) {
      id
      name
      product {
        id
        name
      }
      templateBody
    }
    license(id: $id) @skip(if: $skip) {
      type {
        id
        name
      }
      gracePeriod
      defaultTokenDuration
      latestTerm {
        startDate
        endDate
        value
      }
      isTrial
      isVendor
      usersCount
      sessionsCount
      instancesCount
      totalSeatCount
      lifetimeValue
      entitlements {
        nodes {
          instanceCount
          sessionCount
          userCount
        }
      }
      activeEntitlements {
        nodes {
          id
          value
          latestTerm {
            endDate
          }
        }
      }
      commercialModel {
        id
        name
      }
      restriction {
        instancesPerUser
        requireEmailActivation
        sessionsPerInstance
        sessionsPerUser
        sessionsPerUserPerInstance
        usersPerInstance
        numberOfInstalls
        numberOfUsers
      }
      owner {
        id
        name
        type
      }
      product {
        id
        name
        iconUrl
        type {
          name
          id
        }
      }
    }
  }
`;

//const relayEnvironment = getEnvironment();
const RenderQuery = (props: IAddLicenseBladeProps) => {
  const theme = useTheme();
  const renderLicenseInfo = (payload: {
    error: Error;
    props: AddLicenseQueryResponse;
    retry: () => void;
  }) => {
    const tmplId = _.get(props, "routeData.templateId", "");
    const licenseId = _.get(props, "routeData.id", "");
    const isUsedForTemplates = _.get(
      props,
      "routeData.isUsedForTemplates",
      false
    );

    const licenseState: ILicenseBladeState = {
      mode: "normal",
      // commercialModel: _.get(
      //   license,
      //   "commercialModel.name",
      //   null
      // ), // commercialModel.perpetualMS,
      commercialModel: null,
      commercialModelId: null,
      // enforcementModel: licenseTypeToName(
      //   _.get(license, "type.id", null) as licenseTypeId
      // ) as enforcementModel, // enforcementModel.concurrent,
      enforcementModel: null,
      termStartDate: undefined,
      termStartDateHumanized: "",
      termEndDate: undefined,
      termEndDateHumanized: "",
      activeTabIndex: 0,
      activeTabMainIndex: 0,
      type: "component",
      selections: [],
      individualComponentNames: [],
      sourceSelectedKeys: [],
      searchQuery: null,
      productId: null, // _.get(license, "product.id", null), //_.get(this.props.result, "license.product.id", null),
      features: [],
      featureGroup: [],
      components: [],
      componentGroup: [],
      templateId: null,
      loading: false,
      newLicense: {} as IAddLicenseModel,
      company: null,
      product: null, //_.get(license, "product", null),
      isTermEndPickerOpen: false,
      isTermStartPickerOpen: false,
      templateName: ""
    };
    if (!tmplId && isUsedForTemplates) {
      return (
        <AddLicenseBlade
          {...props}
          result={payload.props}
          retry={payload.retry}
          //license={licenseData}
          licenseState={licenseState}
          //theme={theme}
          //{..._.omit(payload, "props")}
        />
      );
    }
    if (tmplId && !payload.props) {
      //props.showScreenLoader();
      return (
        <BladeTemplate
          bladeIndex={props.index}
          title={"License"}
          bladeType="License"
          closeBlade={null}
          //topAccentColor={"mango"}
          accentColor={"mango"}
          renderLeftSideHeader={() => <></>}
          renderRightSideHeader={() => <></>}
          //setRefreshFn={this.props.setRefreshFn}

          //refreshFn={this.props.retry}
          loading={true}
        />
      );
    } else {
      const license = tmplId
        ? _.get(payload, "props.licenseTemplate.templateBody.license", "")
        : _.get(payload, "props.license");
      const tmpl = _.get(payload, "props.licenseTemplate.templateBody", null);
      // const licenseData: IAddLicenseModel = {
      //   number_of_users: license?.restriction?.numberOfUsers,
      //   number_of_installs: license?.restriction?.numberOfInstalls,
      //   max_instances_per_user: 0,
      //   default_token_duration: license?.defaultTokenDuration,
      //   owner: {
      //     name: license?.owner?.name,
      //     owner_id: license?.owner?.id,
      //     owner_type: license?.owner?.type,
      //   },
      //   product_id: license?.product?.id,
      //   is_vendor: license?.isVendor,
      //   owner_id: license?.owner?.id,
      //   owner_type: license?.owner?.type,
      //   user_count: license?.entitlements?.nodes[0]?.userCount,
      //   grace_period: license?.gracePeriod,

      //   instance_count: license?.entitlements?.nodes[0].instanceCount,
      //   session_count: license?.entitlements?.nodes[0].sessionCount,
      //   instances_per_user: license?.restriction?.instancesPerUser,
      //   users_per_instance: license?.restriction?.usersPerInstance,
      //   sessions_per_user: license?.restriction?.sessionsPerUser,
      //   sessions_per_instance: license?.restriction?.sessionsPerInstance,
      //   sessions_per_user_per_instance:
      //     license?.restriction?.sessionsPerUserPerInstance,
      //   require_email_activation: license?.restriction?.requireEmailActivation,
      //   value: 0,
      //   license_value: _.get(license, "lifetimeValue", 0),
      //   term_value: _.get(license, "latestTerm.value", 0),
      //   //licenseTypeId: +decodeProductTypeId(_.get(license, "type.id", 0)),
      //   licenseTypeId: 0,
      //   is_trial: false,
      //   license_type_id: +decodeProductTypeId(_.get(license, "type.id", 0)),
      // };

      if (tmpl) {
        const template = JSON.parse(tmpl);
        //override state values with the ones from template
        licenseState.newLicense = template?.license;
        licenseState.commercialModel = template?.commercialModel;
        licenseState.commercialModelId = template?.commercialModelId;
        licenseState.enforcementModel = template?.enforcementModel;
        licenseState.templateName = _.get(
          payload,
          "props.licenseTemplate.name",
          ""
        );
        licenseState.productId = template?.productId;
        licenseState.product = template?.product;
        licenseState.templateId = _.get(
          payload,
          "props.licenseTemplate.id",
          ""
        );
      }
      // props.hideScreenLoader();
      return (
        <AddLicenseBlade
          {...props}
          result={payload.props}
          retry={payload.retry}
          //license={licenseData}
          licenseState={licenseState}
          //theme={theme}
          //{..._.omit(payload, "props")}
        />
      );
    }
  };
  //return renderLicenseInfo();
  return (
    <QueryRenderer<AddLicenseQuery>
      environment={relayEnvironment}
      variables={{
        id:
          _.get(props, "routeData.id", "") === null ? "" : props?.routeData?.id, // "TGljZW5zZS0xOTQ=",
        skip: !props?.routeData?.id,
        skipTemplate: !_.get(props, "routeData.templateId", null),
        templateId: _.get(props, "routeData.templateId", "")
        // blade_scope: props.routeData.id,
        // productId: props.routeData.productId
      }}
      query={graphqlQuery}
      render={renderLicenseInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
