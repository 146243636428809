/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type NewEntitlementCreateQueryVariables = {
  id: string;
};
export type NewEntitlementCreateQueryResponse = {
  readonly product: {
    readonly name: string | null;
    readonly components: {
      readonly nodes: ReadonlyArray<{
        readonly name: string | null;
        readonly componentType: string;
        readonly id: string;
      } | null> | null;
    } | null;
    readonly productGroups: {
      readonly nodes: ReadonlyArray<{
        readonly name: string | null;
        readonly id: string;
        readonly components: {
          readonly nodes: ReadonlyArray<{
            readonly name: string | null;
            readonly id: string;
            readonly componentType: string;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type NewEntitlementCreateQuery = {
  readonly response: NewEntitlementCreateQueryResponse;
  readonly variables: NewEntitlementCreateQueryVariables;
};

/*
query NewEntitlementCreateQuery(
  $id: ID!
) {
  product(id: $id) {
    name
    components {
      nodes {
        name
        componentType
        id
      }
    }
    productGroups: componentGroups {
      nodes {
        name
        id
        components {
          nodes {
            name
            id
            componentType
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "componentType",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v6 = {
      alias: "productGroups",
      args: null,
      concreteType: "ComponentGroupConnection",
      kind: "LinkedField",
      name: "componentGroups",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ComponentGroup",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentConnection",
              kind: "LinkedField",
              name: "components",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Component",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [v2 /*: any*/, v4 /*: any*/, v3 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "NewEntitlementCreateQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v2 /*: any*/, v5 /*: any*/, v6 /*: any*/],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "NewEntitlementCreateQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v2 /*: any*/, v5 /*: any*/, v6 /*: any*/, v4 /*: any*/],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "f74da5f46e1331075fec33f893d7bf41",
      id: null,
      metadata: {},
      name: "NewEntitlementCreateQuery",
      operationKind: "query",
      text:
        "query NewEntitlementCreateQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    name\n    components {\n      nodes {\n        name\n        componentType\n        id\n      }\n    }\n    productGroups: componentGroups {\n      nodes {\n        name\n        id\n        components {\n          nodes {\n            name\n            id\n            componentType\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "6de7fde6745c3c321008cc36d742e948";
export default node;
