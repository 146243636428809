import React from "react";
import { appConnect } from "../../store/appConnect";
import { productsAnalyticsQueryResponse } from "./__generated__/productsAnalyticsQuery.graphql";
import _ from "lodash";
import { WidgetContainer } from "../../components/analyticsComponent/widgetContainer";
import { TopCountFilter } from "../../components/analyticsComponent/filters/topCount";
import { queryType } from "../../components/analyticsComponent/";
import { TabbedAnalyticsComponent } from "../../components/analyticsComponent/tabbedAnalytics";
import AnalyticsComponent from "../../components/analyticsComponent";
import { Flex } from "reflexbox";
import { sharedTabStyle } from "./shared";
import { DateRange } from "blades/Dashboard/utils";
import { IOpenBladeFromChart } from "components/bladeManager/types";

export interface IProductsAnalyticsProps {
  result: productsAnalyticsQueryResponse;
  retry: () => void;
  openBlade?: (payload: IOpenBladeFromChart) => void;
  timespan: "week" | "month" | "day";
  productsId: string;
  companyId: string;
  variables: {
    timeRangeOverride?: DateRange;
    currentTimeSpanStart: string;
    currentTimeSpanEnd: string;
    previousTimeSpanStart: string;
    previousTimeSpanEnd: string;
    productId: string | null;
  };
}

type state = {
  topProductsCount: number;
};
export class ProductsAnalytics extends React.Component<
  IProductsAnalyticsProps,
  state
> {
  state: state = {
    topProductsCount: 5
  };
  public renderTabHeader = (header: string) => {
    return <span className={"session-tab-header"}>{header}</span>;
  };
  public getHighlightAnalytics = () => {
    return (
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session count",
              style: {
                ...sharedTabStyle
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              analytics: this.getSessionCountAnalyticsSetup(),
              openBlade: this.props.openBlade,
              type: "line"
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: {
                ...sharedTabStyle
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              analytics: this.getSessionDurationAnalyticsSetup(),
              openBlade: this.props.openBlade,
              type: "line"
            }
          }
        ]}
      />
    );
  };
  public getSessionDurationAnalyticsSetup = () => {
    return {
      bladeScope: null,
      ...this.getFilterValuesAndColumns(),
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_duration",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd,
      options: {
        chartMaxHeight: 268
      }
    } as queryType;
  };
  public getFilterValuesAndColumns = () => {
    const filterColumn = [];
    const filterValues = [];
    if (this.props.companyId) {
      filterColumn.push("companies.id");
      filterValues.push([this.props.companyId]);
    }
    // if (this.props.productsId) {
    //   filterColumn.push("products.id");
    //   filterValues.push([this.props.productsId]);
    // }
    if (this.props.variables?.productId) {
      filterColumn.push("products.id");
      filterValues.push([this.props.variables.productId]);
    }
    return { filterColumn, filterValues };
  };
  public getSessionCountAnalyticsSetup = () => {
    return {
      bladeScope: null,
      ...this.getFilterValuesAndColumns(),
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_count",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd,
      options: {
        chartMaxHeight: 268
      }
    } as queryType;
  };
  public updateTopCount = (key: keyof state) => (value: string) => {
    this.setState({ [key]: parseInt(value, 10) } as any);
  };
  public getTopProducts = () => {
    return (
      <AnalyticsComponent
        analytics={{
          bladeScope: null,
          ...this.getFilterValuesAndColumns(),
          setup: {
            category: `products.name`,
            value: "sessions.usage_count",
            group: null
          },
          first: this.state.topProductsCount,
          sortColumn: "value",
          sortDirection: "ASC",
          startTime: this.props.variables.currentTimeSpanStart,
          endTime: this.props.variables.currentTimeSpanEnd
        }}
        type="bar"
        allowVendorAnalytics={false}
        openBlade={this.props.openBlade}
      />
    );
  };
  public render() {
    return (
      <div className="analytics-container">
        <WidgetContainer size="x-large">
          {this.getHighlightAnalytics()}
        </WidgetContainer>
        <WidgetContainer size="x-large">
          <Flex>
            <span className="widget-title">Top products</span>
            <TopCountFilter
              onChange={this.updateTopCount("topProductsCount")}
            />
          </Flex>
          {this.getTopProducts()}
        </WidgetContainer>
      </div>
    );
  }
}

const RenderQuery = (props: IProductsAnalyticsProps) => {
  return <ProductsAnalytics {...props} variables={props.variables} />;
};
export default appConnect(RenderQuery, {
  selectors: {}
});
