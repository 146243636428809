import * as _ from "lodash";
import { createSelector } from "reselect";
import { IStore } from "../../store/types";

export const reduxTokenAuthSelector = (state: IStore, ownProps: any) =>
  state.reduxTokenAuth;

export const currentUserSelector = createSelector(
  [reduxTokenAuthSelector],
  tokenAuthState => tokenAuthState.currentUser
);
export const userEmailSelector = createSelector(
  [currentUserSelector],
  userState => _.get(userState, "attributes.email")
);

export const userNameSelector = createSelector(
  [currentUserSelector],
  userState => _.get(userState, "attributes.name")
);

export const hasVerificationBeenAttemptedSelector = createSelector(
  [currentUserSelector],
  currentUser => currentUser.hasVerificationBeenAttempted
);

export const signedInFailed = createSelector(
  [currentUserSelector],
  state => state.signInFailed
);
export const isSignedInSelector = createSelector(
  [currentUserSelector],
  currentUser => currentUser.isSignedIn
);
export const isUserLoadingSelector = createSelector(
  [currentUserSelector],
  currentUser => currentUser.isLoading
);
