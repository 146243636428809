import * as _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { appConnect, appDispatch } from "../../store/appConnect";
import relayEnvironment from "../../api/relay";
import {
  IBladeBaseProps,
  IOpenUserBlade
} from "../../components/bladeManager/types";
// import { ITab } from '../../components/tabView';
import {
  BladeTemplate,
  BladeTitle,
  ElmToggle,
  InstanceHistoryTable
} from "../../components";
import InstanceFeaturesAndGroups from "./instanceFeaturesAndGroups";
import { LicenseTableType } from "../../Licenses/components/licensesTable";
import {
  InstanceQuery,
  InstanceQueryResponse
} from "./__generated__/InstanceQuery.graphql";
import CollapsibleAttributeBox, {
  IAttributeValuePair
} from "../../components/collapsibleAttributesBox";
import TabView, { ITab } from "../../components/tabView";
import theme from "../../theme";
import { useTheme } from "styled-components";
import { getSavedRoleTypeFromLocalSync } from "utils";
import { getGateway } from "api";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { shallowEqual } from "fast-equals";
import {
  EntityRefreshMap,
  REFRESH_KEY
} from "components/bladeManager/entityRefreshMap";

const graphqlQuery = graphql`
  query InstanceQuery($id: ID!) {
    ...instanceHistoryTable_instance @arguments(id: $id)
    instance(id: $id) {
      ...instanceAttribute_instance
      ...instanceAttributeAdvanced_instance
      guid
      hardwareIdentifier {
        info
      }
      users {
        nodes {
          displayName
          id
        }
      }
      license {
        product {
          name
        }
        owner {
          id
          name
        }
      }
      features {
        nodes {
          id
          name
        }
      }
      featureGroups {
        nodes {
          id
          name
          features {
            nodes {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export interface IInstallsBladeProps extends IBladeBaseProps<IOpenUserBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
  result: InstanceQueryResponse;
  theme: any;
  retry: () => void;
  refreshKey: EntityRefreshMap;
}

export interface IInstanceState {
  userId: string;
  loadingEnabled: boolean;
  loadingUpdateNextTime: boolean;
  instaceInfo: {
    activated: boolean;
    enabled: boolean;
    hardware_update_flag: boolean;
  };
}

export class InstallsBlade extends React.Component<
  IInstallsBladeProps,
  IInstanceState
> {
  constructor(props: IInstallsBladeProps) {
    super(props);

    this.state = {
      userId: null,
      loadingUpdateNextTime: false,
      loadingEnabled: false,
      instaceInfo: {
        activated: false,
        enabled: false,
        hardware_update_flag: false
      }
    };
  }
  public componentDidMount() {
    this.updateInstanceInfo();
    this.setRefreshFn();
  }
  public componentDidUpdate(
    prevProps: typeof this.props,
    prevState: any,
    snapshot: any
  ) {
    if (prevProps.routeData.id !== this.props.routeData.id) {
      this.updateInstanceInfo();
    }
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public isVendor = () =>
    getSavedRoleTypeFromLocalSync() === "vendor" ||
    _.get(this.props, "appState.activeRole.type") === "vendor";

  public updateInstanceInfo = () => {
    this.props.gateway.request
      .getInstanceInfo({ id: this.props.routeData.id })
      .then(res => {
        if (!res.error) {
          const info = {
            enabled: res.data?.enabled,
            activated: res.data?.activated,
            hardware_update_flag: res.data.hardware_update_flag
          };
          this.setState({ instaceInfo: info });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  public navAway = () => {
    this.props.openBlade({
      routeName: "Customer portal",
      fromBladeIndex: this.props.index,
      route: "Overview",
      routeData: null
    });
  };
  public navToLicenseBlade = (license: LicenseTableType) => {
    this.props.openBlade({
      route: "License",
      routeName: "License",
      fromBladeIndex: this.props.index,
      routeData: {
        id: license.id
      }
    });
  };
  public navToProductBlade = (product: {
    name: string;
    id: string | number;
  }) => {
    this.props.openBlade({
      route: "Product",
      routeName: product.name,
      fromBladeIndex: this.props.index,
      routeData: {
        id: product.id.toString()
      }
    });
  };
  // public renderLicenseHistoryTable = (res: InstanceQueryResponse) => {
  //   return <LicenseHistoryTable terms={res} />;
  // };
  public renderLeftSideHeader = () => {
    if (!this.props.result) {
      return null;
    }
    return (
      <BladeTitle>{this.props.result.instance.license.product.name}</BladeTitle>
    );
  };
  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: "anana",
      routeData: null,
      fromBladeIndex: this.props.index
    });
  };
  public renderRightSideHeader = () => {
    const updateNextTimeHandler = (update: boolean) => {
      this.setState({ loadingUpdateNextTime: true }, () => {
        const requestToBeSent = update
          ? this.props.gateway.request.enableHardwareUpdate
          : this.props.gateway.request.disableHardwareUpdate;
        requestToBeSent(
          {
            id: this.props.routeData.id,
            guid: this.props.result.instance.guid
          },
          { id: this.props.routeData.id }
        )
          .then(res => {
            this.setState(
              { loadingUpdateNextTime: false },
              this.updateInstanceInfo
            );
          })
          .catch(err => {
            this.setState(
              { loadingUpdateNextTime: false },
              this.updateInstanceInfo
            );
            console.error(err);
          });
      });
    };
    const enabledHandler = (enabled: boolean) => {
      this.setState({ loadingEnabled: true }, () => {
        const requestToBeSent = enabled
          ? this.props.gateway.request.enableInstance
          : this.props.gateway.request.disableInstance;
        requestToBeSent(
          {
            id: this.props.routeData.id,
            guid: this.props.result.instance.guid
          },
          { id: this.props.routeData.id }
        )
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.setState({ loadingEnabled: false }, this.updateInstanceInfo);
          });
      });
    };
    return (
      <Flex>
        <div style={{ marginRight: "25px" }}>
          <ElmToggle
            loading={this.state.loadingUpdateNextTime}
            disabled={this.state.loadingUpdateNextTime}
            size="small"
            rightLabel="Update next time"
            onChange={updateNextTimeHandler}
            permissions="modify_assets"
            checked={this.state.instaceInfo.hardware_update_flag}
          />
        </div>
        <ElmToggle
          loading={this.state.loadingEnabled}
          disabled={this.state.loadingEnabled}
          size="small"
          rightLabel="Enabled"
          onChange={enabledHandler}
          permissions="modify_assets"
          checked={this.state.instaceInfo.enabled}
        />
      </Flex>
    );
  };
  public renderHistoryTable = () => {
    return (
      <InstanceHistoryTable
        instance={this.props.result}
        hideColumnsWithHeaders={!this.props.isVendor ? ["Time"] : []}
      />
    );
  };

  public renderFeaturesAndGroups = () => {
    const onChange = (data: {
      name: string;
      value: boolean;
      type: "feature" | "group";
    }) => {
      const gateway = getGateway();
      if (data.type === "feature") {
        gateway.request[
          data?.value ? "enableInstanceFeature" : "disableInstanceFeature"
        ](
          {
            feature: data.name
          },
          {
            id: this.props.routeData.id
          }
        )
          .then(() => {
            renderSuccessNotification(
              `${data?.value ? "Enabled" : "Disabled"} feature!`
            );
            this.props.refreshAllOpenBlades();
          })
          .catch(e => {
            renderErrorNotification(
              `Failed to ${data?.value ? "enable" : "disable"} feature!`
            );
            console.error(e);
          });
      }
      if (data.type === "group") {
        gateway.request[
          data?.value
            ? "enableInstanceFeatureGroup"
            : "disableInstanceFeatureGroup"
        ](
          {
            feature_group: data.name
          },
          {
            id: this.props.routeData.id
          }
        )
          .then(() => {
            renderSuccessNotification(
              `${data?.value ? "Enabled" : "Disabled"} feature group!`
            );
            this.props.refreshAllOpenBlades();
          })
          .catch(e => {
            renderErrorNotification(
              `Failed to ${data?.value ? "enable" : "disable"} feature group!`
            );
            console.error(e);
          });
      }
    };
    return (
      <InstanceFeaturesAndGroups
        id={this.props.routeData?.id}
        onChange={onChange}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.InstanceFeaturesTable
        )}
        refreshKey={REFRESH_KEY.InstanceFeaturesTable}
        removeRefreshFn={this.props.removeRefreshFn}
      />
    );
  };

  public getAttributeItems = (): IAttributeValuePair[] => {
    if (!this.props.result) {
      return null;
    }
    const infoKeys = _.keys(this.props.result.instance.hardwareIdentifier.info);
    return _.reduce(
      infoKeys,
      (values, key) => {
        if (
          !_.isArray(this.props.result.instance.hardwareIdentifier.info[key])
        ) {
          if (key !== "hostName") {
            values.push({
              label: _.startCase(key),
              value: this.props.result.instance.hardwareIdentifier.info[key]
            });
          }
        }
        return values;
      },
      [
        {
          label: "Device ID",
          value: this.props.result.instance.guid
        }
      ] as IAttributeValuePair[]
    );
  };
  public getDynamicTabs = () => {
    if (!this.props.result) {
      return null;
    }
    const infoKeys = _.keys(this.props.result.instance.hardwareIdentifier.info);
    const ArrayValues = (props: { values: string[]; title: string }) => {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div style={{ height: "40px", padding: "12px" }}>
            <span
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                height: "17px",
                color: this.props.theme.colors.textPrimary
              }}
            >{`${props.title}: ${props.values.length}`}</span>
          </div>

          {_.map(props.values, value => (
            <div
              style={{
                height: "26px",
                padding: "5px",
                paddingLeft: "12px",
                borderBottom: `1px solid ${theme.colors.lightGrey}`
              }}
            >
              <span
                style={{
                  lineHeight: "15px",
                  height: "15px",
                  color: this.props.theme.colors.textPrimary,
                  fontSize: "12px"
                }}
              >
                {value}
              </span>
            </div>
          ))}
        </div>
      );
    };
    if (!this.props.isVendor) {
      return [
        {
          title: "History",
          Component: this.renderHistoryTable()
        }
      ] as ITab[];
    }
    return _.reduce(
      infoKeys,
      (tabs, key) => {
        if (
          _.isArray(this.props.result.instance.hardwareIdentifier.info[key])
        ) {
          tabs.unshift({
            title: _.startCase(key),
            Component: (
              <ArrayValues
                values={this.props.result.instance.hardwareIdentifier.info[key]}
                title={_.startCase(key)}
              />
            )
          });
        }
        return tabs;
      },
      [
        {
          title: "Features",
          Component: this.renderFeaturesAndGroups()
        },
        {
          title: "History",
          Component: this.renderHistoryTable()
        }
      ] as ITab[]
    );
  };
  public renderHeaderRowForAttributeBox = () => {
    if (!this.props.result) {
      return null;
    }
    return (
      <span
        style={{
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "24px"
        }}
      >
        {_.get(this.props.result.instance.hardwareIdentifier.info, "hostName")}
      </span>
    );
  };
  public renderInstanceInfo = () => {
    const dynamicTabs = this.getDynamicTabs();
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Install"}
        // topAccentColor={"userGreen"}
        accentColor={"userGreen"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        closeBlade={this.props.closeBlade}
        bladeType="Instance"
        bladeTypeName="Install"
        loading={!this.props.result}
      >
        {this.props.isVendor ? (
          <CollapsibleAttributeBox
            style={{ marginBottom: "20px" }}
            bottomToggleText={""}
            topToggleText={""}
            renderHeaderRow={this.renderHeaderRowForAttributeBox}
            items={this.getAttributeItems()}
          />
        ) : null}
        <Flex
          style={{
            marginTop: !this.props.isVendor ? "21px" : 0,
            marginBottom: "35px",
            paddingLeft: "21px",
            paddingRight: "21px",
            flexDirection: "column",
            flex: 1
          }}
        >
          <TabView
            tabList={dynamicTabs}
            showBottomBorder={true}
            leftAlign={true}
            orientation={"vertical"}
          />
        </Flex>
      </BladeTemplate>
    );
  };

  public render() {
    return this.renderInstanceInfo();
  }
}
const RenderQuery = (props: IInstallsBladeProps) => {
  const theme = useTheme();
  const renderBlade = (payload: {
    error: Error;
    props: InstanceQueryResponse;
    retry: () => void;
  }) => {
    return (
      <InstallsBlade
        {...props}
        result={payload.props}
        retry={payload.retry}
        theme={theme}
      />
    );
  };
  return (
    <QueryRenderer<InstanceQuery>
      environment={relayEnvironment}
      variables={{
        id: props.routeData.id
      }}
      query={graphqlQuery}
      render={renderBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
