import * as _ from "lodash";
import React, { Component } from "react";
import styled, { css } from "styled-components";
import AnalyticsChart, { AnalyticsComponentProps } from "../analyticsComponent";
// import { Carousel } from "antd";
// import { CarouselProps } from "antd/lib/carousel";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  WithStore,
  CarouselStoreInterface
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { getFaIcon } from "../icons";
import { IOpenBladeFromChart } from "components/bladeManager/types";

//TODO: Check back if lazy loading is supported in library below
// import Carousel from '@brainhubeu/react-carousel';
// import '@brainhubeu/react-carousel/lib/style.css';

const ButtonContainerStyle = css<{ disabled?: boolean }>`
  position: absolute;
  background-color: transparent;
  border: none;
  height: 24px;
  width: 24px;
  top: 47%;
  color: ${props =>
    props.disabled ? "white" : props.theme.colors.textPrimary};
  opacity: ${props => (props.disabled ? "0" : 1)};
  z-index: 9;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};

  :hover {
    background-color: ${props =>
      !props.disabled ? props.theme.colors.highlight : "transparent"};
    color: ${props => props.theme.colors.textPrimary};
  }

  :focus {
    outline-color: white;
  }
`;
const TitleStrip = styled.div`
  height: 20px;
  display: none;
  justify-content: end;

  p {
    margin: 0px;
    font-size: 14px;
  }
`;
const ForwardButtonContainer = styled(ButtonNext)`
  ${ButtonContainerStyle};
  right: 0px;
`;
const BackButtonContainer = styled(ButtonBack)`
  ${ButtonContainerStyle};
  left: 0px;
`;
const ForwardButton = styled(
  getFaIcon({ iconName: "arrow-right", prefix: "fal" })
)`
  color: inherit;
`;
const BackButton = styled(getFaIcon({ iconName: "arrow-left", prefix: "fal" }))`
  color: inherit;
`;
export type AnalyticsCarouselProps = {
  analyticsSlides: AnalyticsComponentProps[];
  currentIndex?: number;
  currentSlide?: number;
  carouselStore?: CarouselStoreInterface;
  openBlade?: (data: IOpenBladeFromChart) => void;
};

export type AnalyticsCarouselState = {
  // currentIndex: number;
};
class AnalyticsCarousel extends Component<
  AnalyticsCarouselProps,
  AnalyticsCarouselState
> {
  // public componentDidUpdate(prevProps: AnalyticsCarouselProps) {
  //   if (this.props.currentIndex !== this.state.currentIndex) {
  //     this.setState({ currentIndex: this.props.currentIndex });
  //   }
  // }

  public getCharts = () => {
    const currentIndex = this.props.currentSlide || 0;
    return _.map(this.props.analyticsSlides, (slide, i) => {
      return (
        <Slide
          index={i}
          key={i}
          classNameVisible="carouselVisible"
          classNameHidden="carouselHidden"
        >
          <AnalyticsChart
            key={i}
            {...slide}
            openBlade={this.props.openBlade}
            className={i == currentIndex ? "carouselVisible" : "carouselHidden"}
            chartStyle={{ margin: "10px 44px 12px 44px" }}
          />
        </Slide>
      );
    });
  };
  public updateCurrentIndex = (currentIndex: number) => {
    this.props.carouselStore.setStoreState({ currentSlide: currentIndex });
  };
  public shouldComponentUpdate(
    nextProps: AnalyticsCarouselProps,
    nextState: AnalyticsCarouselState
  ): boolean {
    if (this.props.currentIndex != nextProps.currentIndex)
      this.updateCurrentIndex(nextProps.currentIndex);
    if (
      _.isEqual(this.props.analyticsSlides, nextProps.analyticsSlides) &&
      this.props.currentSlide === nextProps.currentSlide &&
      _.isEqual(this.state, nextState)
    )
      return false;
    return true;
  }
  public renderCarousel = () => {
    return (
      <React.Fragment>
        <ForwardButtonContainer
          disabled={
            this.props.currentSlide === this.props.analyticsSlides.length - 1
          }
        >
          <ForwardButton />
        </ForwardButtonContainer>
        <BackButtonContainer disabled={this.props.currentSlide === 0}>
          <BackButton />
        </BackButtonContainer>
        <TitleStrip>
          <p>{`${this.props.currentSlide + 1} / ${
            this.props.analyticsSlides.length
          }`}</p>
        </TitleStrip>
        <Slider
          style={{
            width: "100%",
            height: "196px"
          }}
        >
          {this.getCharts()}
        </Slider>
      </React.Fragment>
    );
  };
  public render() {
    return this.renderCarousel();
  }
}
// @ts-ignore
const ACarousel = WithStore(AnalyticsCarousel, state => ({
  currentSlide: state.currentSlide
}));
export default class ConnectedCarousel extends React.Component<
  AnalyticsCarouselProps
> {
  private getCarouselProps = () => {
    return {
      naturalSlideHeight: 200,
      naturalSlideWidth: 200,
      totalSlides: this.props.analyticsSlides.length
    };
  };
  public render() {
    return (
      <div
        style={{
          display: "block",
          height: "196px",
          overflowY: "hidden",
          width: "100%",
          position: "relative"
        }}
      >
        <CarouselProvider {...this.getCarouselProps()}>
          <ACarousel {...this.props} />
        </CarouselProvider>
      </div>
    );
  }
}
