import _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import { Flex } from "reflexbox/styled-components";
import React from "react";
import { appConnect, appDispatch } from "../../store/appConnect";
import BladeTemplate from "../../components/bladeManager/bladeTemplate";
import { IBladeBaseProps } from "../../components/bladeManager/types";
import { BladeTitle } from "../../components";
import { useTheme } from "styled-components";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../api/relay";
import { sharedTabStyle } from "blades/Analytics/shared";
import TabView, { ITab } from "components/tabView";
import {
  ApprovalActionListQuery,
  ApprovalActionListQueryResponse
} from "./__generated__/ApprovalActionListQuery.graphql";
import moment from "moment";
import {
  ActionComment,
  ActionCreatedAt,
  ActionDescription,
  ApprovalSubheader,
  ApprovedLicenseIcon,
  ApproveLicenseIcon,
  CallToAction,
  CircleWrap,
  DeactivateLicenseIcon,
  licenseActionsColor,
  TermLicenseIcon
} from "./styled";
import theme from "theme";
import { AutoSizer } from "react-virtualized";
import { updateBladeUrlState } from "utils";
import { ActiveMode } from "store/types";
import { DATE_FORMAT } from "const";

export const graphqlQuery = graphql`
  query ApprovalActionListQuery(
    $sortColumn: [String!]
    $sortDirection: [String!]
  ) {
    licenseActions(sortColumn: $sortColumn, sortDirection: $sortDirection) {
      nodes {
        id
        action
        description
        comment
        createdAt
        requireApproval
        status
        licenseId
        license {
          id
          owner {
            name
          }
          product {
            id
            name
          }
        }
        vendorSystemUser {
          id
          name
          email
        }
      }
    }
  }
`;

export const licenseActions = {
  create_license: "create_license",
  approve_action: "approve_action",
  create_term: "create_term",
  modify_entitlement: "modify_entitlement",
  modify_license: "modify_license",
  activate_license: "activate_license",
  deactivate_license: "deactivate_license",
  approved: "approved",
  terminate_license: "Terminate license"
};

// enum licenseActionTabIndex {
//   create_license = 0,
//   create_term = 3
// }
export const isApprovableAction = (item: ActionNode) => {
  return (
    item?.action === licenseActions.create_license ||
    (item?.action === licenseActions.create_term &&
      item?.description !== licenseActions.terminate_license)
  );
};

export type ActionNodes = ApprovalActionListQueryResponse["licenseActions"]["nodes"];
export type ActionNode = ActionNodes[number];

export const iconWrapper = (licenseAction: string) => {
  const getCorrespondingIcon = () => {
    switch (licenseAction) {
      case licenseActions.create_license:
        return <ApproveLicenseIcon />;
      case licenseActions.create_term:
        return <TermLicenseIcon />;
      case licenseActions.deactivate_license:
        return <DeactivateLicenseIcon />;
      case licenseActions.approved:
        return <ApprovedLicenseIcon />;
      default:
        return <ApproveLicenseIcon />;
    }
  };
  return (
    <CircleWrap color={licenseActionsColor[licenseAction]}>
      {getCorrespondingIcon()}
    </CircleWrap>
  );
};

const getHumanizedDescription = (
  description: string,
  action: string,
  companyName: string,
  productName: string,
  vendorSystemUserName: string
) => {
  if (action === licenseActions.create_term) {
    if (description?.startsWith("Extend"))
      return `${vendorSystemUserName} extended ${companyName} ${productName} license`;
    if (description?.startsWith("Terminate"))
      return `${vendorSystemUserName} terminated ${companyName} ${productName} license`;
  }
  if (action === licenseActions.deactivate_license) {
    return `${vendorSystemUserName} deactivated ${companyName} ${productName} license`;
  }
  if (action === licenseActions.modify_license) {
    return `${vendorSystemUserName} modified ${companyName} ${productName} license`;
  }
  if (action === licenseActions.create_license) {
    return `${vendorSystemUserName} created license for ${companyName} ${productName}`;
  }
  if (action === licenseActions.activate_license) {
    return `${vendorSystemUserName} activated ${companyName} ${productName} license`;
  }
  if (action === licenseActions.modify_entitlement) {
    return `${vendorSystemUserName} modified entitlements for ${companyName} ${productName} license`;
  }
  if (action === licenseActions.terminate_license) {
    return `${vendorSystemUserName} terminated ${companyName} ${productName} license`;
  }

  return `Action by ${vendorSystemUserName} on ${companyName} ${productName} license`;
};
interface IGenerateItem {
  node: ActionNode;
  callToActionCallback: (node: ActionNode, action: string) => void;
}

export const generateItem = ({ node, callToActionCallback }: IGenerateItem) => {
  const {
    description = "",
    comment = "",
    createdAt,
    action,
    license,
    vendorSystemUser
  } = node;
  const companyName = license?.owner?.name;
  const productName = license?.product?.name;
  const actionDescription = getHumanizedDescription(
    description,
    action,
    companyName,
    productName,
    vendorSystemUser?.name || vendorSystemUser?.email
  );
  return (
    <Flex marginBottom={10}>
      <Flex width={32} justifyContent={"center"}>
        {iconWrapper(action)}
      </Flex>
      <Flex flexDirection="column" maxWidth={"90%"}>
        <ActionDescription>{actionDescription} </ActionDescription>
        <ActionComment>{description}</ActionComment>
        <ActionComment>{comment}</ActionComment>
        <Flex marginTop={10}>
          <ActionCreatedAt>
            {moment(createdAt).format(DATE_FORMAT)}
          </ActionCreatedAt>
          {isApprovableAction(node) && (
            <CallToAction onClick={() => callToActionCallback(node, action)}>
              Open License
            </CallToAction>
          )}
          {action === licenseActions.create_term &&
            description === licenseActions.terminate_license && (
              <CallToAction
                color={theme.colors.orangeRed}
                onClick={() =>
                  callToActionCallback(node, licenseActions.terminate_license)
                }
              >
                Terminate
              </CallToAction>
            )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export interface IApprovalBladeProps extends IBladeBaseProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    activeMode: ActiveMode;
    userDefinedMode: boolean;
  };
  theme: any;
}

type ApprovalBladeState = {
  loading: boolean;
};

export class ApprovalBlade extends React.Component<
  IApprovalBladeProps & {
    result: ApprovalActionListQueryResponse;
  },
  ApprovalBladeState
> {
  state: ApprovalBladeState = {
    loading: false
  };

  public renderLeftSideHeader = () => {
    return (
      <div style={{ marginLeft: "18px", marginTop: "-45px" }}>
        <BladeTitle>Actions</BladeTitle>
        <ApprovalSubheader>
          Review and manage pending and approved license adjustments
        </ApprovalSubheader>
      </div>
    );
  };
  public navToAccountBlade = () => {
    this.props.openBlade({
      route: "Account",
      routeName: "Account",
      fromBladeIndex: this.props.index,
      routeData: null
    });
  };

  public executeLicenseAction = (licenseActionItem: any, action: string) => {
    const { license } = licenseActionItem;
    this.props.openBlade({
      route: "License",
      routeName: "License",
      fromBladeIndex: this.props.index,
      routeData: {
        id: license?.id,
        productId: license?.product?.id,
        licenseAction: action,
        openInChangelogTab: true
      }
    });
  };

  public renderPending = (
    nodes: ApprovalActionListQueryResponse["licenseActions"]["nodes"]
  ) => {
    const items = nodes.map(item =>
      generateItem({
        node: item,
        callToActionCallback: this.executeLicenseAction
      })
    );

    return (
      <Flex flexDirection="column" flex={1} style={{ overflowY: "scroll" }}>
        {items}
      </Flex>
    );
  };

  public renderApproved = (
    nodes: ApprovalActionListQueryResponse["licenseActions"]["nodes"]
  ) => {
    const items = nodes.map(item => {
      const {
        description = "",
        comment = "",
        createdAt,
        action,
        license,
        vendorSystemUser
      } = item;
      const companyName = license?.owner?.name;
      const productName = license?.product?.name;
      const actionDescription = getHumanizedDescription(
        description,
        action,
        companyName,
        productName,
        vendorSystemUser?.name || vendorSystemUser?.email
      );
      return (
        <Flex marginBottom={10} flex={1}>
          <Flex width={32}>{iconWrapper("approved")}</Flex>
          <Flex flexDirection="column">
            <ActionDescription>{actionDescription}</ActionDescription>
            <ActionComment>{description}</ActionComment>
            <ActionComment>{comment}</ActionComment>
            <Flex marginTop={10}>
              <ActionCreatedAt>
                {moment(createdAt).format(DATE_FORMAT)}
              </ActionCreatedAt>
            </Flex>
          </Flex>
        </Flex>
      );
    });
    return (
      <Flex flexDirection="column" flex={1} style={{ overflowY: "scroll" }}>
        {items}
      </Flex>
    );
  };

  public updateActiveTab = (activeTabIndex: number) => {
    updateBladeUrlState(this.props, activeTabIndex);
  };

  public renderComponentsTab = (res: ApprovalActionListQueryResponse) => {
    let pending: ActionNodes = [],
      approved: ActionNodes = [];
    const nodes = _.get(res, "licenseActions.nodes", []) as ActionNodes;
    if (nodes) {
      pending = nodes.filter(item => item.requireApproval === true);
      approved = nodes.filter(item => item.requireApproval === false);
    }
    const views = [
      {
        title: "Pending",
        Component: this.renderPending([...pending]),
        style: sharedTabStyle
      },
      {
        title: "Approved",
        Component: this.renderApproved([...approved]),
        style: sharedTabStyle
      }
    ] as ITab[];
    return (
      <AutoSizer defaultHeight={300}>
        {({ height, width }) => (
          <TabView
            titleContainerStyle={{ justifyContent: "flex-start" }}
            tabList={views}
            defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
            onUpdateActiveTab={this.updateActiveTab}
            style={{
              maxHeight: height,
              minHeight: height,
              maxWidth: width,
              minWidth: width
            }}
          />
        )}
      </AutoSizer>
    );
  };

  public render() {
    const res = this.props.result;
    return (
      <BladeTemplate
        renderLeftSideHeader={this.renderLeftSideHeader}
        accentColor={"pink"}
        bladeTypeName="Approval"
        bladeIndex={this.props.index}
        bladeType="Approval"
        closeBlade={this.props.closeBlade}
        hideBladeTypeName={true}
        title={"Approval"}
        bladeBodyStyle={{ paddingRight: "40px", paddingLeft: "40px" }}
        loading={!res}
      >
        <Flex flex={1}>{this.renderComponentsTab(res)}</Flex>
      </BladeTemplate>
    );
  }
}
const RenderQuery = (props: IApprovalBladeProps) => {
  const theme = useTheme();
  const renderAccountInfo = (payload: {
    error: Error;
    props: ApprovalActionListQueryResponse;
    retry: () => void;
  }) => {
    return <ApprovalBlade {...props} result={payload.props} theme={theme} />;
  };
  return (
    <QueryRenderer<ApprovalActionListQuery>
      environment={relayEnvironment}
      variables={{
        sortColumn: ["createdAt"],
        sortDirection: ["DESC"]
      }}
      query={graphqlQuery}
      render={renderAccountInfo}
    />
  );
};

export default appConnect(RenderQuery, {
  selectors: {}
});
