import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox";
import moment from "moment";
import {
  Message,
  ProductInfo,
  VersionInfo,
  TimeInfo,
  TypeInfo
} from "./styles";
import { appConnect } from "store/appConnect";
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { getEnvironment } from "api/relay";
import {
  feedbackListQuery,
  feedbackListQueryResponse
} from "./__generated__/feedbackListQuery.graphql";
import { LoaderContainer } from "components/helpers";

type feedback = {
  message: string;
  product: string;
  time: string;
  type: string;
  version: string;
};

interface IFeedbackList {
  id: string;
}

const getTimeFormatted = (createdAt: string) => {
  const getAbsoluteDuration = (d: number) => Math.round(Math.abs(d));
  const startDate = moment(createdAt);
  const termDiff = moment(new Date()).diff(moment(startDate));
  const year = getAbsoluteDuration(moment.duration(termDiff).as("years"));
  const day = getAbsoluteDuration(moment.duration(termDiff).as("days"));
  const month = getAbsoluteDuration(moment.duration(termDiff).as("month"));
  const getLabel = () => {
    if (year > 0) {
      return `${year} year${year > 1 ? "s" : ""} ago`;
    }
    if (month > 0) {
      return `${month} month${month > 1 ? "s" : ""} ago`;
    }
    if (day > 0) {
      return `${day} day${day > 1 ? "s" : ""} ago`;
    }
    return "";
  };
  return getLabel();
};

const FeedbackList = (
  props: IFeedbackList & {
    result: feedbackListQueryResponse;
    retry: () => void;
  }
) => {
  const items: feedback[] = _.get(props.result, "user.feedback", []);

  const renderList = () => {
    return (
      <Flex
        justifyContent="flex-start"
        flex={1}
        style={{
          height: "400px",
          marginLeft: "25px",
          paddingRight: "5px",
          overflowY: "scroll"
        }}
        flexDirection="column"
      >
        {items?.length ? (
          _.map(items, item => {
            return (
              <Flex flexDirection="column" marginBottom="39px">
                <Flex justifyContent="space-between" alignItems="center">
                  <Flex alignItems="center" lineHeight="normal">
                    <ProductInfo>{item?.product}</ProductInfo>{" "}
                    <VersionInfo>{item?.version}</VersionInfo>
                  </Flex>
                  <Flex>
                    <TypeInfo type={item?.type?.toLowerCase()}>
                      {item?.type}
                    </TypeInfo>
                  </Flex>
                </Flex>
                <Flex>
                  <Message>{item.message}</Message>
                </Flex>
                <Flex>
                  <TimeInfo>
                    {item?.time && getTimeFormatted(item.time)}
                  </TimeInfo>
                </Flex>
              </Flex>
            );
          })
        ) : (
          <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
            {LoaderContainer()}
          </Flex>
        )}
      </Flex>
    );
  };
  return renderList();
};

const graphqlQuery = graphql`
  query feedbackListQuery($id: ID!) {
    user(id: $id) {
      feedback {
        message
        product
        type
        time
        version
      }
    }
  }
`;

const relayEnvironment = getEnvironment();
const RenderQuery = (props: IFeedbackList) => {
  const renderProductAttribute = (payload: {
    error: Error;
    props: feedbackListQueryResponse;
    retry: () => void;
  }) => {
    return (
      <FeedbackList {...props} result={payload.props} retry={payload.retry} />
    );
  };
  return (
    <QueryRenderer<feedbackListQuery>
      environment={relayEnvironment}
      cacheConfig={{
        force: true
      }}
      variables={{
        id: props.id
      }}
      query={graphqlQuery}
      render={renderProductAttribute}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
