import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import * as React from "react";
import { QueryRenderer } from "react-relay";
import { CollapsibleAttributeBox } from "../../../components/collapsibleAttributesBox";
import { getCountryName } from "../../../utils";
import { getEnvironment } from "api/relay";
import LoadingWrapper from "components/elmChart/loadingWrapper";
import { appConnect } from "store/appConnect";
import {
  companyAttributeQuery,
  companyAttributeQueryResponse
} from "./__generated__/companyAttributeQuery.graphql";
import { shallowEqual } from "fast-equals";
import { EntityRefreshMap } from "components/bladeManager/entityRefreshMap";

export interface ICompanyAttributeProps {
  id: string;
  setRefreshFn?: (payload: () => void) => void;
  refreshKey: EntityRefreshMap;
  removeRefreshFn?: (entity: EntityRefreshMap) => void;
}

export class CompanyAttribute extends React.Component<
  ICompanyAttributeProps & {
    result: companyAttributeQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    this.props.removeRefreshFn(this.props.refreshKey);
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public getItems = () => {
    if (!this.props.result?.company) {
      return [];
    }
    return [
      // {
      //   label: "Location",
      //   value: _.get(this.props.company, "location.city")
      // },
      {
        label: "Primary account",
        value: this.props.result.company.contactName
      },
      {
        label: "Email",
        value: this.props.result.company.contactEmail
      },
      {
        label: "Phone number",
        value: this.props.result.company.contactPhone
      }

      // {
      //   label: "Business Units",
      //   value: this.props.company.businessUnitsCount.toString()
      // },
      // {
      //   label: "Users",
      //   value: this.props.company.usersCount.toString()
      // },
      // {
      //   label: "Active Licenses",
      //   value: this.getActiveLicenses().length.toString()
      // },
      // {
      //   label: "Expired Licenses",
      //   value: this.getExpiredLicenses().length.toString()
      // },
      // {
      //   label: "Licenses",
      //   value: this.props.company.licensesCount.toString()
      // },
      // {
      //   label: "Products",
      //   value: this.props.company.productsCount.toString()
      // }
    ];
  };
  public renderHeaderRow = () => {
    if (!this.props.result?.company) {
      return (
        <p className="subheading-value">
          <span className="subheading-label">Loading stats...</span>
        </p>
      );
    }
    return (
      <div style={{ display: "flex" }}>
        {this.props.result.company.location ? (
          <span className={"company-location"}>{`${getCountryName(
            this.props.result.company.location.countryCode
          )} ${
            this.props.result.company.location.city
              ? `(${this.props.result.company.location.city})`
              : ""
          }`}</span>
        ) : null}
      </div>
    );
  };
  public render() {
    return (
      <LoadingWrapper>
        <CollapsibleAttributeBox
          renderHeaderRow={this.renderHeaderRow}
          topToggleText={
            this.props.result?.company ? "Show company details" : ""
          }
          bottomToggleText={"Hide company details"}
          items={this.getItems()}
          isCollapsedDefault={true}
          className={"company-attribute-box"}
          permissions={"modify_assets"}
        />
      </LoadingWrapper>
    );
  }
}

const graphqlQuery = graphql`
  query companyAttributeQuery($id: ID!) {
    company(id: $id) {
      name
      contactEmail
      contactName
      contactPhone
      location {
        city
        countryCode
      }
    }
  }
`;

const relayEnvironment = getEnvironment();
const RenderQuery = (props: ICompanyAttributeProps) => {
  const renderProductAttribute = (payload: {
    error: Error;
    props: companyAttributeQueryResponse;
    retry: () => void;
  }) => {
    return (
      <CompanyAttribute
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    );
  };
  return (
    <QueryRenderer<companyAttributeQuery>
      environment={relayEnvironment}
      cacheConfig={{
        force: true
      }}
      variables={{
        id: props.id
      }}
      query={graphqlQuery}
      render={renderProductAttribute}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
