import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import ElmInput from "../../../elmInput";
import { MessageDetails } from "../delete";
import CommentField from "components/commentField";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
export interface IConfirmationDialogProps {
  name?: string;
  description?: string;
  warningMessage?: string | React.ReactNode;
  entityType: string;
  isSimpleConfirmation?: boolean;
  hasComment?: boolean;
  title?: string;
}
type state = {
  name: string;
  comment?: string;
};
export class ConfirmationDialog extends DialogBase<
  IModalProps<IConfirmationDialogProps>,
  state
> {
  state: state = {
    name: ""
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          height: "max-content",
          //width: "444px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "ConfirmationDialog"
    });
  };
  public onConfirm = () => {
    if (_.isFunction(this.props.payload.onConfirm)) {
      if (this.props.payload?.hasComment) {
        this.props.payload.onConfirm(this.state.comment);
      } else {
        this.props.payload.onConfirm();
      }
    }
  };
  public getTitle = () => {
    if (this.props?.payload?.title) return _.get(this.props, "payload.title");
    return `Confirm ${_.get(this.props.payload, "entityType")}`;
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };
  public updateComment = (comment: string) => {
    this.setState({ comment });
  };
  public isVowel(character: string) {
    if (character && character.length > 1) {
      character = character[0];
    }
    return ["a", "e", "i", "o", "u"].indexOf(character.toLowerCase()) !== -1;
  }
  public getDescription = () => {
    if (
      _.get(this.props.payload, "warningMessage") &&
      _.isString(_.get(this.props.payload, "warningMessage"))
    ) {
      return _.get(this.props.payload, "warningMessage");
    } else return _.get(this.props.payload, "warningMessage");
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        {/* <InfoMessage description={this.getDescription()} type="warning" /> */}
        <MessageDetails>
          <>
            {this.getDescription()}
            {this.props.payload?.hasComment ? (
              <Flex justifyContent={"end"} marginBottom={"16px"}>
                <CommentField updateComment={this.updateComment} />
              </Flex>
            ) : null}
          </>
        </MessageDetails>
        {!this.props?.payload?.isSimpleConfirmation && (
          /* <Flex marginTop="10px">{`Type in the word ${_.get(
              this.props.payload,
              "entityType"
            )} ${_.get(this.props.payload, "description") ||
              "name"} to confirm .`}</Flex> 
             <p
              style={{ fontStyle: "italic", margin: "0px", fontSize: "14px" }}
            >{`${_.get(this.props.payload, "name")}`}</p> */
          <Flex style={{ marginBottom: "5px" }}>
            <ElmInput
              updatedValue={this.updateName}
              placeholder={"Enter here"}
            />
          </Flex>
        )}

        <Flex justifyContent={"flex-end"} marginTop="15px">
          <ElmButton
            label={"Cancel"}
            colorVariance="outline-secondary"
            onClick={this.closeModal}
          />
          <ElmButton
            label={"Confirm"}
            onClick={this.onConfirm}
            disabled={
              !this.props?.payload?.isSimpleConfirmation &&
              this.state.name !== _.get(this.props.payload, "name")
            }
          />
        </Flex>
      </DialogContainer>
    );
  }
}
