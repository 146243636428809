import { Collapse, Dropdown, MenuProps, Tooltip } from "antd";
import * as _ from "lodash";
import { Flex } from "reflexbox/styled-components";
import * as React from "react";
import styled, { useTheme } from "styled-components";
import { ElmToggle } from "../../../components/elmToggle";
import { LoaderContainer, SearchBar } from "../../../components/helpers";
import { useEffect, useState } from "react";
import { useLicenseComponentsAndEntitlementsData } from "../../blades/AddLicense/hooks";
import { hooksFeaturesAndComponentsQueryResponse } from "Licenses/blades/AddLicense/__generated__/hooksFeaturesAndComponentsQuery.graphql";
import { ComponentOrGroup } from "Licenses/components/featuresAndGroups";
import { FeatureLabel } from "Licenses/components/featuresAndGroups/styles";
import { featuresAndGroupsToggleQueryResponse } from "Licenses/components/featuresAndGroups/__generated__/featuresAndGroupsToggleQuery.graphql";
import { ElmButton, ElmTable } from "components";
import { getTermDetails, searchInObjectArray } from "utils";
import { TableCellProps } from "react-virtualized";
import { TableText } from "Licenses/components/licensesTable";
import dayjs from "dayjs";
import { DATE_FORMAT } from "const";
import { bladeWidth } from "components/bladeManager/bladeTemplate";
import { IOpenBlade, TOpenDialog } from "components/bladeManager/types";
import { getGateway } from "api";
import {
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { hooksLicenseComponentsAndEntitlementsQueryResponse } from "../AddLicense/__generated__/hooksLicenseComponentsAndEntitlementsQuery.graphql";

type ComponentNode = hooksLicenseComponentsAndEntitlementsQueryResponse["license"]["components"]["nodes"][number];
type TransformedComponentNode = ComponentNode & {
  isIncluded: boolean;
  hasFreeTrial: boolean;
  hasEntitlement: boolean;
  isEntitlementExpired: boolean;
};
type ComponentGroupNode = hooksFeaturesAndComponentsQueryResponse["product"]["componentGroups"]["nodes"][number];
type TransformedGroupNode = Pick<
  ComponentGroupNode,
  "id" | "name" | "componentType"
> & {
  hasActiveEntitlement: boolean;
  components: Array<TransformedComponentNode>;
};
type VersionRequirementNode = ComponentNode["licenseComponentRequirements"][number];

const StyledCollapse = styled(Collapse)`
  background-color: transparent;
  margin-bottom: 6px;
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
  }
  .ant-collapse-item {
    border: none;
    padding: 0px;
    margin: 0px;
    color: ${props => props.theme.colors.textPrimary};
    .ant-collapse-header {
      padding: 0px;
      padding-top: 8px;
    }
  }
  .header-text {
    min-width: 291px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
  }
`;

const IncludedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6px;
  color: white;
  height: 12px;
  width: 12px;
  border-radius: 2px;
  background-color: #ffa033;
`;

const FreeTrialIcon = styled(IncludedIcon)`
  color: #666009;
  background-color: #e9df56;
`;

const ComponentActions = ({
  licenseId,
  payload,
  checkRequirementStatus,
  openEdit,
  refresh
}: {
  licenseId: string;
  payload: ComponentNode;
  refresh: () => void;
  checkRequirementStatus: (
    reqs: ComponentNode["licenseComponentRequirements"]
  ) => { componentIsIncluded: boolean; componentHasFreeTrial: boolean };
  openEdit: ({
    component,
    isEditFreeTrial
  }: {
    component: ComponentNode;
    isEditFreeTrial: boolean;
  }) => void;
}) => {
  const { componentIsIncluded, componentHasFreeTrial } = checkRequirementStatus(
    payload?.licenseComponentRequirements
  );

  const theme = useTheme();

  const sharedBtnStyle: React.CSSProperties = {
    color: theme.colors.black,
    margin: 2,
    padding: 0,
    paddingLeft: 4,
    fontSize: theme.fontSizes.xLarge
  };

  const handleIncludeAction = async (activate: boolean) => {
    const gateway = getGateway();
    try {
      await Promise.all(
        payload?.licenseComponentRequirements?.map(
          async (version: VersionRequirementNode) => {
            await gateway.request["editLicenseComponentRequirement"](
              {
                would_inherit_from_base_license:
                  version.wouldInheritFromBaseLicense,
                product_version_id: version.productVersionId,
                license_id: licenseId,
                can_inherit: version.canInherit,
                is_included: activate,
                is_inherited: false,
                token_count: activate ? 0 : version.tokenCount,
                free_trial: activate ? false : version.freeTrialActive || false,
                free_trial_expiration: activate
                  ? null
                  : //@ts-ignore
                    latest.freeTrialExpiration
              },
              {
                id: payload?.id
              }
            );
          }
        )
      );

      renderSuccessNotification(
        `Component is ${activate ? "included in" : "excluded from"} license`
      );
      refresh();
    } catch (e) {
      console.error(e);
      renderFailureNotification(
        `Failed to ${activate ? "include" : "exclude"} license`
      );
    }
  };

  let key = 0;

  const items: MenuProps["items"] = [
    {
      key: key++,
      onClick: () =>
        openEdit({
          component: payload,
          isEditFreeTrial: false
        }),
      label: "Edit requirements",
      style: {
        height: "34px",
        color: theme.colors.black
      }
    },
    componentHasFreeTrial
      ? {
          key: key++,
          onClick: () =>
            openEdit({
              component: payload,
              isEditFreeTrial: true
            }),
          label: "Edit free trial length",
          style: {
            height: "34px",
            color: theme.colors.black
          }
        }
      : null,
    {
      key: key++,
      type: "divider"
    },
    {
      key: key++,
      onClick: e => {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      },
      label: (
        <Flex alignItems={"flex-end"}>
          <ElmToggle
            size="small"
            permissions={"modify_assets"}
            checked={componentIsIncluded}
            rightLabel={"Included in license"}
            onChange={handleIncludeAction}
          />
        </Flex>
      ),
      style: {
        paddingTop: "12px",
        height: "34px"
      }
    },
    {
      key: key++,
      label: (
        <Flex alignItems={"flex-end"}>
          <ElmToggle
            size="small"
            permissions={"modify_assets"}
            rightLabel={"Free trial"}
            checked={componentHasFreeTrial}
          />
        </Flex>
      ),
      onClick: () => {
        if (componentHasFreeTrial) {
          return;
        }
        openEdit({
          component: payload,
          isEditFreeTrial: true
        });
      },
      style: {
        paddingTop: "12px",
        height: "34px"
      }
    }
  ];
  return (
    <div onClick={e => e.stopPropagation()}>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <ElmButton
          label=""
          title="Edit"
          permissions={"modify_assets"}
          className="darkModeTransparentBtn"
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon={"ellipsis"}
          iconPrefix={"fas"}
          style={sharedBtnStyle}
        />
      </Dropdown>
    </div>
  );
};

export interface IComponentsAndGroupsProps {
  isVendor: boolean;
  openBlade: (payload: IOpenBlade) => void;
  fromBladeIndex: number;
  license?: featuresAndGroupsToggleQueryResponse["license"];
  components?: { id: string; name: string }[];
  productId?: string;
  licenseId?: string;
  templateId?: string;
  componentGroup?: { id: string; name: string }[];
  openDialog: TOpenDialog;
  retry?: () => void;
  onChange?: (payload: {
    type: ComponentOrGroup;
    id: string;
    name: string;
    value: boolean;
    updateQuery: () => void;
  }) => void;
  refreshAllOpenBlades?: () => void;
  setRefreshFn?: (payload: () => void) => void;
}

const { Panel } = Collapse;

interface IComponentToggle {
  isVendor?: boolean;
  enabled: boolean;
  id: string;
  name?: string;
  type: ComponentOrGroup;
  additionalInfo?: string;
  additionalIcon?: () => React.ReactNode;
  totalEnabled?: number;
  disabled?: boolean;
  helou?: string;
  onReportChange: (
    type: ComponentOrGroup,
    id: string,
    name: string
  ) => (value: boolean) => void;
}

type Status = {
  [key: string]: boolean;
  isLoading: boolean;
};

const StyledLabel = styled.span`
  font-size: ${props => props.theme.fontSizes.xSmall};
  font-weight: 400;
  line-height: 14.5px;
`;
const StyledSubLabel = styled.span`
  font-size: ${props => props.theme.fontSizes.xSmall};
  color: ${props => props.theme.colors.warmGrey};
  font-weight: 400;
  line-height: 14.5px;
`;

function ToggleActivation({
  isVendor,
  enabled,
  id,
  name,
  type,
  totalEnabled,
  additionalInfo,
  additionalIcon,
  onReportChange,
  disabled
}: IComponentToggle) {
  const [status, setStatus] = useState<Status>({
    [id]: enabled,
    isLoading: false
  });

  useEffect(() => {
    setStatus({ [id]: enabled, isLoading: false });
  }, [enabled, id]);

  const handleActivation = (state: boolean, id: string) => {
    setStatus({ [id]: state, isLoading: false });
    onReportChange(type, id, name)(state);
  };
  return isVendor ? (
    <ElmToggle
      key={id}
      //loading={status.isLoading}
      size="small"
      className="elm-toggle-features"
      permissions={"modify_assets"}
      leftLabel={
        <Flex style={{ gap: "4px", alignItems: "center" }}>
          <StyledLabel>{name}</StyledLabel>
          <StyledSubLabel>{additionalInfo}</StyledSubLabel>
          {_.isFunction(additionalIcon) ? additionalIcon() : null}
        </Flex>
      }
      onChange={(state: boolean) => handleActivation(state, id)}
      defaultChecked={enabled}
      checked={enabled}
      disabled={disabled}
    />
  ) : (
    <Flex style={{ gap: "4px", alignItems: "center" }}>
      <StyledLabel>{name}</StyledLabel>
      <StyledSubLabel>{additionalInfo}</StyledSubLabel>
      {_.isFunction(additionalIcon) ? additionalIcon() : null}
    </Flex>
  );
}

interface IComponentsAndGroupsState {
  search_term: string;
  components: { id: string; name: string }[];
  componentGroup: { id: string; name: string }[];
}

export function LicenseComponents({
  isVendor,
  openBlade,
  fromBladeIndex,
  components,
  componentGroup,
  onChange,
  productId,
  licenseId,
  openDialog,
  refreshAllOpenBlades,
  setRefreshFn
}: IComponentsAndGroupsProps) {
  const theme = useTheme();
  const [state, setState] = useState<IComponentsAndGroupsState>({
    search_term: "",
    components: components || [],
    componentGroup: componentGroup || []
  });
  const licenseComponentsAndEntitlementsData = useLicenseComponentsAndEntitlementsData(
    licenseId
  );
  const licenseComponents =
    licenseComponentsAndEntitlementsData?.license?.components?.nodes;
  const componentEntitlements =
    licenseComponentsAndEntitlementsData?.license?.componentEntitlements?.nodes;

  const reportChange = (type: ComponentOrGroup, id: string, name: string) => (
    value: boolean
  ) => {
    let components = [...state.components];
    let componentGroup = [...state.componentGroup];
    if (value) {
      type === "component" && components.push({ id, name });
      if (type === "group") {
        componentGroup.push({ id, name });
      }
    }
    if (!value) {
      if (type === "component")
        components = _.filter(components, item => item.id !== id);
      if (type === "group")
        componentGroup = _.filter(componentGroup, item => item.id !== id);
    }
    setState({ ...state, components, componentGroup });
    if (_.isFunction(onChange)) {
      //@ts-ignore
      onChange({ type, components: components as any, componentGroup });
    }
  };
  const isChecked = (payload: { id: string; type: ComponentOrGroup }) => {
    // if (payload.type === "component") {
    //   const filtered = _.filter(state.components, n => n.id === payload.id);

    //   return !!filtered.length;
    // }
    if (payload.type === "group") {
      const filtered = _.filter(state.componentGroup, n => n.id === payload.id);

      return !!filtered.length;
    }
    return false;
  };

  const renderComponents = (
    components: TransformedComponentNode[],
    groupNodes: TransformedGroupNode[]
  ) => {
    const checkRequirementStatus = (
      reqs: ComponentNode["licenseComponentRequirements"]
    ) => {
      const componentIsIncluded = reqs?.every(
        (v: VersionRequirementNode) => v?.isIncluded && !v?.tokenCount
      );
      const componentHasFreeTrial = _.last(reqs)?.freeTrialActive;
      return { componentIsIncluded, componentHasFreeTrial };
    };

    const renderEnableToggle = (payload: TableCellProps["rowData"]) => {
      if (payload?.isIncluded) {
        return (
          <Flex onClick={e => e.stopPropagation()}>
            <ElmToggle
              size="small"
              // onChange={handleChange}
              checked={payload?.isIncluded}
              // permissions={"modify_assets"}
            />
          </Flex>
        );
      }
      if (payload?.hasFreeTrial) {
        return (
          <Flex onClick={e => e.stopPropagation()}>
            <ElmToggle
              size="small"
              // onChange={handleChange}
              checked={payload?.hasFreeTrial}
              // permissions={"modify_assets"}
            />
          </Flex>
        );
      }
      const handleChange = (activate: boolean) => {
        const notIncludedComponents = components.filter(c => {
          if (
            c?.isIncluded ||
            c?.hasFreeTrial ||
            (c?.hasEntitlement && !c?.isEntitlementExpired)
          ) {
            return false;
          }
          return true;
        });
        if (payload?.isEntitlementExpired && activate) {
          openBlade({
            route: "Entitlement",
            routeData: {
              id: null,
              productId: productId,
              licenseId: licenseId,
              entitlementId: null,
              availableComponents: notIncludedComponents?.map(c => c.id)
            },
            routeName: "Entitlement",
            fromBladeIndex: fromBladeIndex
          });
        }
      };
      return (
        <Flex onClick={e => e.stopPropagation()}>
          <ElmToggle
            size="small"
            onChange={handleChange}
            checked={payload?.hasEntitlement && !payload?.isEntitlementExpired}
            permissions={"modify_assets"}
          />
        </Flex>
      );
    };
    const renderComponentName = (payload: TableCellProps) => {
      const latestProductVersion: VersionRequirementNode = _.last(
        payload?.rowData?.licenseComponentRequirements
      );
      const freeTrialExpires = latestProductVersion?.freeTrialExpiration;

      if (payload.rowData?.isIncluded) {
        return (
          <Flex alignItems={"center"} style={{ gap: 4 }}>
            <span>{_.get(payload.rowData, "name")}</span>
            <Tooltip title="Included in license">
              <IncludedIcon>L</IncludedIcon>
            </Tooltip>
          </Flex>
        );
      }
      if (payload.rowData?.hasFreeTrial) {
        return (
          <Flex alignItems={"center"} style={{ gap: 4 }}>
            <span>{_.get(payload.rowData, "name")}</span>
            <Tooltip
              title={
                <div>
                  <span>{"Free trial"}</span>
                  <div>
                    {`Expires on ${dayjs(freeTrialExpires as string)?.format(
                      DATE_FORMAT
                    )}`}
                  </div>
                </div>
              }
            >
              <FreeTrialIcon>T</FreeTrialIcon>
            </Tooltip>
          </Flex>
        );
      }
      return (
        <TableText isExpired={payload.rowData?.isEntitlementExpired}>
          {_.get(payload.rowData, "name")}
        </TableText>
      );
    };
    const renderComponentLicensingModel = (payload: TableCellProps) => {
      if (payload.rowData?.isIncluded || payload.rowData?.hasFreeTrial) {
        return <TableText>{_.get(payload.rowData, "componentType")}</TableText>;
      }
      return (
        <TableText isExpired={payload.rowData?.isEntitlementExpired}>
          {_.get(payload.rowData, "componentType")}
        </TableText>
      );
    };
    const filteredComponents = state.search_term
      ? searchInObjectArray(state.search_term, components)
      : components;
    return (
      <Flex
        style={{
          flexDirection: "column",
          flex: 1,
          minHeight: "max-content"
        }}
      >
        <div
          style={{
            minHeight: "max-content",
            width: "580px",
            height: "80%",
            position: "relative",
            maxHeight: "100%"
          }}
        >
          {components?.length && groupNodes?.length ? (
            <FeatureLabel style={{ marginBottom: 0 }}>
              Other components available on this product
            </FeatureLabel>
          ) : null}
          <ElmTable
            data={filteredComponents}
            hideFooter={true}
            hideSearchBar={true}
            onRowClick={openComponentBlade}
            renderToggleColumn={renderEnableToggle}
            hideColumnsWithHeaders={isVendor ? [] : ["Include", "Actions"]}
            columns={[
              {
                Header: "Include",
                accessor: "",
                disableSortBy: true,
                sortKey: null,
                width: 0.08
              },
              {
                Header: "Name",
                accessor: "name",
                cellRenderer: e => renderComponentName(e),
                disableSortBy: true,
                sortKey: null,
                width: 0.5
              },
              {
                Header: "Licensing Model",
                accessor: "componentType",
                cellRenderer: e => renderComponentLicensingModel(e),
                disableSortBy: true,
                sortKey: null
              },
              {
                Header: "Actions",
                width: 0.1,
                accessor: "",
                cellRenderer: () => "",
                disableSortBy: true,
                sortKey: null
              }
            ]}
            renderRowActionButtons={data => (
              <ComponentActions
                licenseId={licenseId}
                checkRequirementStatus={checkRequirementStatus}
                payload={data}
                openEdit={({
                  component,
                  isEditFreeTrial
                }: {
                  component: ComponentNode;
                  isEditFreeTrial: boolean;
                }) => {
                  openDialog("EditLicenseComponentRequirementsDialog", {
                    ...data,
                    licenseId,
                    isEditFreeTrial,
                    onConfirm: () => {
                      refreshAllOpenBlades();
                    }
                  });
                }}
                refresh={refreshAllOpenBlades}
              />
            )}
          />
        </div>
      </Flex>
    );
  };
  const openComponentBlade = (routeData: {
    id: string;
    name?: string;
    groupName?: string;
    licenseComponentRequirements?: any;
  }) => {
    openBlade({
      route: "ProductComponent",
      routeName: `${routeData?.groupName || routeData?.name}`,
      routeData: {
        ...routeData,
        name: routeData?.groupName || routeData?.name,
        isGroup: !!routeData?.groupName,
        licenseId: licenseId,
        //@ts-ignore
        licenseComponentRequirements: routeData?.licenseComponentRequirements
      },
      fromBladeIndex: fromBladeIndex
    });
  };
  const renderComponentGroups = (
    groupNodes: TransformedGroupNode[],
    checkRequirementStatus: (
      reqs: ComponentNode["licenseComponentRequirements"]
    ) => { componentIsIncluded: boolean; componentHasFreeTrial: boolean }
  ) => {
    const renderComponentName = (payload: TableCellProps, groupInfo: any) => {
      const latestProductVersion: VersionRequirementNode = _.last(
        payload?.rowData?.licenseComponentRequirements
      );
      const freeTrialExpires = latestProductVersion?.freeTrialExpiration;

      if (payload?.rowData?.isIncluded) {
        return (
          <Flex alignItems={"center"} style={{ gap: 4 }}>
            <span>{_.get(payload.rowData, "name")}</span>
            <Tooltip title="Included in license">
              <IncludedIcon>L</IncludedIcon>
            </Tooltip>
          </Flex>
        );
      }
      if (payload?.rowData?.hasFreeTrial) {
        return (
          <Flex alignItems={"center"} style={{ gap: 4 }}>
            <span>{_.get(payload.rowData, "name")}</span>
            <Tooltip
              title={
                <div>
                  <span>{"Free trial"}</span>
                  <div>
                    {`Expires on ${dayjs(freeTrialExpires as string)?.format(
                      DATE_FORMAT
                    )}`}
                  </div>
                </div>
              }
            >
              <FreeTrialIcon>T</FreeTrialIcon>
            </Tooltip>
          </Flex>
        );
      }
      return (
        <TableText isExpired={payload?.rowData?.isEntitlementExpired}>
          {_.get(payload.rowData, "name")}
        </TableText>
      );
    };
    const renderComponentLicensingModel = (
      payload: TableCellProps,
      groupInfo: any
    ) => {
      if (payload?.rowData?.isIncluded || payload?.rowData?.hasFreeTrial) {
        return <TableText>{_.get(payload.rowData, "componentType")}</TableText>;
      }
      return (
        <TableText isExpired={payload?.rowData?.isEntitlementExpired}>
          {_.get(payload.rowData, "componentType")}
        </TableText>
      );
    };
    return (
      <>
        {groupNodes?.length ? (
          <FeatureLabel style={{ marginTop: "12px", marginBottom: 0 }}>
            Component groups
          </FeatureLabel>
        ) : null}
        <StyledCollapse bordered={false} accordion={true} style={{ margin: 0 }}>
          {_.map(groupNodes, group => {
            const groupIsIncluded = _.every(group.components, c => {
              return checkRequirementStatus(c?.licenseComponentRequirements)
                .componentIsIncluded;
            });
            const groupHasFreeTrial = _.every(group.components, c => {
              return checkRequirementStatus(c?.licenseComponentRequirements)
                .componentHasFreeTrial;
            });
            const groupChecked =
              groupIsIncluded ||
              groupHasFreeTrial ||
              group.hasActiveEntitlement;

            const groupComponents = state.search_term
              ? searchInObjectArray(state.search_term, group.components)
              : group.components;

            // const numberOfComponentsChecked = group.components.nodes.filter(
            //   item => isChecked({ id: item.id, type: "component" })
            // );
            // const numberOfChecked = groupChecked
            //   ? group.components.nodes.length
            //   : numberOfComponentsChecked.length;
            return (
              <Panel
                style={{ width: `${bladeWidth - 210}px` }}
                header={
                  <ToggleActivation
                    type={"group"}
                    id={group.id}
                    isVendor={isVendor}
                    // name={`${group.name} (${numberOfChecked}/${group.components.nodes.length})`}
                    name={group.name}
                    additionalInfo={group.componentType}
                    additionalIcon={() => {
                      if (groupIsIncluded) {
                        return (
                          <Tooltip title="Included in license">
                            <IncludedIcon>L</IncludedIcon>
                          </Tooltip>
                        );
                      }
                      if (groupHasFreeTrial) {
                        return (
                          <Tooltip title={"Free trial"}>
                            <FreeTrialIcon>T</FreeTrialIcon>
                          </Tooltip>
                        );
                      }
                      return null;
                    }}
                    enabled={groupChecked}
                    onReportChange={() =>
                      reportChange("group", group.id, group.name)
                    }
                  />
                }
                key={group.id}
                className={"componentGroup"}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: `${bladeWidth - 210}px`,
                    height: 210
                  }}
                >
                  <ElmTable
                    data={groupComponents}
                    hideFooter={true}
                    hideSearchBar={true}
                    onRowClick={openComponentBlade}
                    hideColumnsWithHeaders={isVendor ? [] : ["Actions"]}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                        cellRenderer: e => renderComponentName(e, group),
                        sortKey: null
                      },
                      {
                        Header: "Licensing Model",
                        accessor: "componentType",
                        cellRenderer: e =>
                          renderComponentLicensingModel(e, group),
                        sortKey: null
                      },
                      {
                        Header: "Actions",
                        width: 0.1,
                        accessor: "",
                        cellRenderer: () => "",
                        sortKey: null
                      }
                    ]}
                    renderRowActionButtons={data => {
                      const { componentIsIncluded } = checkRequirementStatus(
                        data?.licenseComponentRequirements
                      );
                      if (componentIsIncluded) {
                        return null;
                      }
                      return (
                        <ElmButton
                          onClick={() => {
                            openDialog(
                              "EditLicenseComponentRequirementsDialog",
                              {
                                ...data,
                                licenseId,
                                onConfirm: () => {
                                  refreshAllOpenBlades();
                                }
                              }
                            );
                          }}
                          icon="pen"
                          iconPrefix="fas"
                          label=""
                          variance="icon-button"
                          colorVariance="subtle"
                          style={{
                            color: theme.colors.black,
                            margin: "2px"
                          }}
                          className="darkModeTransparentBtn"
                        />
                      );
                    }}
                  />
                </div>
              </Panel>
            );
          })}
        </StyledCollapse>
      </>
    );
  };
  const updateSearchTerm = (search_term: string) => {
    setState({ ...state, search_term });
  };
  const renderFields = () => {
    let licenseComponentNodes: ComponentNode[] = _.get(
      licenseComponentsAndEntitlementsData,
      "license.components.nodes",
      []
    );
    let componentNodes: TransformedComponentNode[] = [];
    let mappedGroupData: Record<string, TransformedGroupNode> = {};

    const checkRequirementStatus = (
      reqs: ComponentNode["licenseComponentRequirements"]
    ) => {
      const componentIsIncluded = reqs?.every(
        (v: VersionRequirementNode) => v?.isIncluded && !v?.tokenCount
      );
      const componentHasFreeTrial = _.last(reqs)?.freeTrialActive;
      return { componentIsIncluded, componentHasFreeTrial };
    };

    licenseComponentNodes.forEach(c => {
      const requirementData = checkRequirementStatus(
        c.licenseComponentRequirements
      );

      const entitlementData = {
        hasEntitlement: false,
        isExpired: true,
        componentId: c.id
      };
      componentEntitlements?.forEach(e => {
        e?.components?.nodes?.forEach(n => {
          if (n?.id === entitlementData.componentId) {
            entitlementData.hasEntitlement = true;
            entitlementData.isExpired = getTermDetails(
              _.get(e, "latestTerm.endDate")
            ).isExpired;
          }
        });
      });

      const transformedData = {
        ...c,
        isIncluded: requirementData.componentIsIncluded,
        hasFreeTrial: requirementData.componentHasFreeTrial,
        hasEntitlement: entitlementData.hasEntitlement,
        isEntitlementExpired: entitlementData.isExpired
      };
      if (!c.componentGroup) {
        componentNodes.push(transformedData);
      }
      if (c.componentGroup && mappedGroupData[c.componentGroup?.id]) {
        mappedGroupData[c.componentGroup.id].components?.push(transformedData);
      }
      if (c.componentGroup && !mappedGroupData[c.componentGroup.id]) {
        let groupHasActiveEntitlement = false;
        componentEntitlements?.forEach(e => {
          if (
            e?.componentGroup?.id === c.componentGroup.id &&
            e?.latestTerm?.isActive
          ) {
            groupHasActiveEntitlement = true;
          }
        });
        mappedGroupData[c.componentGroup.id] = {
          ...c.componentGroup,
          hasActiveEntitlement: groupHasActiveEntitlement,
          componentType: c.componentType,
          components: [transformedData]
        };
      }
    });

    let componentGroupNodes = Object.keys(mappedGroupData)?.map(
      id => mappedGroupData[id]
    );

    if (!licenseComponents) {
      return (
        <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
          {LoaderContainer()}
        </Flex>
      );
    }

    if (!licenseComponents?.length) {
      return (
        <FeatureLabel>
          There are no components or component groups for this product.
        </FeatureLabel>
      );
    }
    return (
      <>
        {renderComponentGroups(componentGroupNodes, checkRequirementStatus)}
        {renderComponents(componentNodes, componentGroupNodes)}
      </>
    );
  };
  return (
    <Flex
      flexDirection={"column"}
      flex={1}
      style={{ overflowY: "scroll", maxHeight: "100%" }}
    >
      <SearchBar className={"global-search-bar"} onSearch={updateSearchTerm} />
      {renderFields()}
    </Flex>
  );
}
