import React, { Suspense } from "react";
import Loader from "react-loaders";
import { Flex } from "reflexbox";

const LoadingWrapper: React.FC = ({ children }) => {
  const loader = (
    <Flex
      flex={1}
      justifyContent="center"
      alignItems="center"
      minHeight="inherit"
      height="inherit"
      //height="250px"
    >
      <Loader
        type="line-scale-pulse-out"
        active={true}
        color={"rgb(98, 98, 130)"}
        style={{ transform: "scale(1.0)" }}
      />
    </Flex>
  );
  return <Suspense fallback={loader}>{children}</Suspense>;
};

export default LoadingWrapper;
