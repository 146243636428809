/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type allProductsQueryVariables = {};
export type allProductsQueryResponse = {
  readonly allProductsFilter: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly id: string;
    } | null> | null;
  } | null;
};
export type allProductsQuery = {
  readonly response: allProductsQueryResponse;
  readonly variables: allProductsQueryVariables;
};

/*
query allProductsQuery {
  allProductsFilter: products(sortColumn: ["product.name"], sortDirection: ["asc"]) {
    nodes {
      name
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
    {
      alias: "allProductsFilter",
      args: [
        {
          kind: "Literal",
          name: "sortColumn",
          value: ["product.name"]
        },
        {
          kind: "Literal",
          name: "sortDirection",
          value: ["asc"]
        }
      ],
      concreteType: "ProductConnection",
      kind: "LinkedField",
      name: "products",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Product",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "name",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: 'products(sortColumn:["product.name"],sortDirection:["asc"])'
    } as any
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "allProductsQuery",
      selections: v0 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "allProductsQuery",
      selections: v0 /*: any*/
    },
    params: {
      cacheID: "7c972f3e09b13f803a0ebc27555af4d1",
      id: null,
      metadata: {},
      name: "allProductsQuery",
      operationKind: "query",
      text:
        'query allProductsQuery {\n  allProductsFilter: products(sortColumn: ["product.name"], sortDirection: ["asc"]) {\n    nodes {\n      name\n      id\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "ed185789499d75c9ded212d46da5258a";
export default node;
