import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import { FieldsContainer } from "../../../../../components/bladeManager/appDialog/helpers";
import { DialogBase } from "../../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../../components/bladeManager/appDialog";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import { getIcon } from "../../../../../components/icons";
import { Tooltip, notification } from "antd";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
`;
const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const Subtitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.warmGrey};
`;
const StyledInputLabel = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  color: ${props => props.theme.colors.warmGrey};
  letter-spacing: 1.5px;
  margin-top: 33px;
  padding-bottom: 8px;
  border-bottom: 1px solid #bebdbc;
`;
const CopyContainer = styled(Flex)`
  align-items: center;
  padding: 2px;
  margin-top: 5px;
  border-radius: 3px;
  height: 18px;
  :hover {
    cursor: pointer;
  }

  span {
    height: 15px;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    width: 95%;
    color: ${props => props.theme.colors.textPrimary};
  }
`;
const ClipboardIcon = styled(getIcon("MdContentCopy"))`
  color: ${props => props.theme.colors.iconColor};
`;
export const AttributeLabel = styled.p<{ labelColor?: string }>`
  ${props => props.theme.fontType.paragraph}
  color: ${props => props.labelColor || props.theme.colors.textPrimary};
`;
export interface IproductCreatedDialogProps {
  productId: string;
  publicKey: string;
}
export interface IproductCreatedDialogState {
  role: string;
}
type state = {
  productId: string;
};
export class ProductCreatedDialog extends DialogBase<
  IModalProps<IproductCreatedDialogProps>,
  state
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          width: "330px"
        }
      }
    });
  }
  public componentDidMount(): void {
    this.setState({
      productId: this.props.payload?.productId
    });
  }
  state: state = {
    productId: ""
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "ProductCreatedDialog"
    });
  };
  public getTitle = () => {
    return "New product created!";
  };
  public getPublicKeyValue = () => {
    if (!this.props.payload?.publicKey) {
      return null;
    }
    const formattedKey = this.props.payload.publicKey
      .replace("-----BEGIN PUBLIC KEY-----", "")
      .replace("-----END PUBLIC KEY-----", "");

    return formattedKey;
  };
  public getPublicKeyDisplayLabel = () => {
    if (!this.props.payload?.publicKey) {
      return null;
    }
    const formattedKey = this.getPublicKeyValue();
    const output = formattedKey?.substring(0, 35)?.concat("...");
    return <Tooltip title={formattedKey}>{output}</Tooltip>;
  };
  public openCopiedMessage = (type: string) => () => {
    notification.open({
      message: "Success",
      description: `${type} copied to clipboard`
    });
  };
  public renderBody = () => {
    return (
      <Flex flexDirection={"column"} marginTop={10}>
        <Flex
          style={{ alignItems: "left", justifyContent: "space-between" }}
          flexDirection={"column"}
        >
          <StyledInputLabel>PRODUCT ID</StyledInputLabel>
          <CopyToClipboard
            text={this.props.payload?.productId} //value of product id to be copied
            onCopy={this.openCopiedMessage("Product ID")}
          >
            <CopyContainer>
              <span>{this.props.payload?.productId || "product id"}</span>
              <ClipboardIcon />
            </CopyContainer>
          </CopyToClipboard>
          <StyledInputLabel>PUBLIC KEY</StyledInputLabel>
          <CopyToClipboard
            text={this.getPublicKeyValue()} //value of public key to be copied
            onCopy={this.openCopiedMessage("Public key")}
          >
            <CopyContainer>
              <span>
                {this.getPublicKeyValue()
                  ?.substring(0, 35)
                  ?.concat("...") || "Click to copy your public key"}
              </span>
              <ClipboardIcon />
            </CopyContainer>
          </CopyToClipboard>
        </Flex>
      </Flex>
    );
  };

  public render() {
    return (
      <DialogContainer className={"edit-product-dialog"}>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <Subtitle>
          Please copy product ID and public key, you will need these later
        </Subtitle>
        <FieldsContainer>{this.renderBody()}</FieldsContainer>
      </DialogContainer>
    );
  }
}
export const DataContainer = (
  props: IModalProps<IproductCreatedDialogProps>
) => {
  return (
    <DialogContainer>
      {props?.payload && <ProductCreatedDialog {...props} />}
    </DialogContainer>
  );
};
export default DataContainer;
