import Entitlement from "../../Licenses/blades/Entitlement";
import Instance from "../../blades/Instance";
import License from "../../Licenses/blades/License";
import LicenseComponent from "../../Licenses/blades/LicenseComponent";
import Licenses from "../../Licenses/blades/Licenses";
import Overview from "../../blades/Overview";
import Account from "../../blades/Account";
import Analytics from "../../blades/Analytics";
import Users from "../../Users/blades/Users";
import User from "../../Users/blades/User";
import Products from "../../Products/blades/Products";
import Product from "../../Products/blades/Product";
import ProductComponent from "../../Products/blades/ProductComponent";
import Companies from "../../Companies/blades/Companies";
import Company from "../../Companies/blades/Company";
import Tos from "../../blades/TermsOfService";
import NewEntitlement from "../../Licenses/blades/NewEntitlement";
import NewProduct from "../../Products/blades/NewProduct";
import ProductReleaseConfig from "../../Products/blades/ProductReleaseConfig";
import ProductRelease from "../../Products/blades/ProductRelease";
import ProductReleaseChannel from "../../Products/blades/ProductReleaseChannel";
import Dashboard from "../../blades/Dashboard";
import Configuration from "../../blades/Configuration";
import EmailPreferences from "../../blades/Account/blades/EmailPreferences/EmailPreferences";
import AddLicense from "../../Licenses/blades/AddLicense";
import Welcome from "../../blades/Welcome";
import Approval from "../../blades/Approval";
import Reports from "Reports/blades/Reports";
import Report from "Reports/blades/Report";
import AddReport from "Reports/blades/AddReport";

const bladeModules = {
  Account,
  Analytics,
  Company,
  Companies,
  Entitlement,
  NewEntitlement,
  Instance,
  License,
  LicenseComponent,
  Licenses,
  Reports,
  Report,
  AddReport,
  NewProduct,
  Overview,
  Product,
  Products,
  ProductComponent,
  ProductRelease,
  ProductReleaseChannel,
  ProductReleaseConfig,
  Tos,
  User,
  Users,
  Dashboard,
  Configuration,
  EmailPreferences,
  AddLicense,
  Welcome,
  Approval
};

export default bladeModules;
