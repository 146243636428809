import React, { Suspense, useState } from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { getEnvironment } from "api/relay";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay";
import ElmCheckBox from "components/elmCheckBox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ElmSelectOption, ElmSelectWithLabel } from "components/elmSelect";
import { SearchBar } from "components/elmTable/helpers";
import { formatInlineDisplayValues } from "Reports/common/utils";
import {
  companiesSelectQuery,
  companiesSelectQueryResponse
} from "./__generated__/companiesSelectQuery.graphql";
import { appConnect } from "store/appConnect";
import { base64EncodeId } from "utils/base64EncodeDecode";

interface ICompaniesSelectProps {
  handleSelect: (
    companies: companiesSelectQueryResponse["companies"]["nodes"][number]["id"][]
  ) => void;
  defaultValue?: (string | number)[];
}

const graphqlQuery = graphql`
  query companiesSelectQuery {
    companies {
      nodes {
        id
        name
      }
    }
  }
`;

const SelectionContainer = styled(Flex)`
  color: ${props => props.theme.colors.textPrimary};
`;

const CompaniesSelect: React.FC<ICompaniesSelectProps> = ({
  handleSelect,
  defaultValue
}) => {
  const res = useLazyLoadQuery<companiesSelectQuery>(
    graphqlQuery,
    {},
    { fetchPolicy: "network-only" }
  );

  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [selection, setSelection] = useState<string[]>(
    defaultValue
      ?.map(companyId => {
        return res?.companies?.nodes?.find(
          comp => comp.id === base64EncodeId("Company", companyId)
        )?.id;
      })
      ?.filter(item => item) || []
  );

  const toggleSelect = (state: boolean) => {
    setOpen(state);
  };

  const handleSelection = (payload: string[]) => {
    setSelection(payload);
    handleSelect(payload);
  };

  const updateSelection = (payload: {
    id: string;
    name: string;
    checked: boolean;
  }) => {
    if (payload.checked) {
      const newSelection = [...selection, payload.id];
      handleSelection(newSelection);
    } else {
      const filtered = selection?.filter(comp => {
        if (comp !== payload.id) return true;
        return false;
      });
      handleSelection(filtered);
    }
  };
  const options = res?.companies?.nodes?.toSorted((a, b) =>
    a.name.localeCompare(b.name)
  );
  const captureSelectValue = (id: string) => {
    // if (id === "selectAll") {
    //   handleSelection(
    //     selection?.length === options.length ? [] : options.map(c => c.id)
    //   );
    //   return;
    // }
    const exists = selection?.find(option => option === id);
    if (exists) {
      const newCompanies = [...selection].filter(item => item !== exists);
      handleSelection(newCompanies);
      return;
    }
    const target = options.find(option => option.id === id)?.id;
    if (target) {
      handleSelection([...selection, target]);
    }
  };
  const handleChange = (selection: { id: string; name: string }) => (
    e: CheckboxChangeEvent
  ) => {
    updateSelection({
      checked: e.target.checked,
      ...selection
    });
  };
  return (
    <SelectionContainer onClick={e => e.preventDefault()}>
      <Flex style={{ margin: 12 }} flexDirection="column" flex={1}>
        <ElmSelectWithLabel
          label="Companies"
          placeholder="All companies"
          onClick={() => toggleSelect(true)}
          onBlur={() => toggleSelect(false)}
          onSelect={e => captureSelectValue(e)}
          open={open}
          value={
            selection?.length > 0
              ? formatInlineDisplayValues(
                  selection.map(
                    item =>
                      res?.companies?.nodes?.find(comp => comp.id === item)
                        ?.name || ""
                  )
                )
              : null
          }
          defaultValue={
            selection?.length > 0
              ? formatInlineDisplayValues(
                  selection.map(
                    item =>
                      res?.companies?.nodes?.find(comp => comp.id === item)
                        ?.name || ""
                  )
                )
              : null
          }
        >
          {options?.length ? (
            <ElmSelectOption key={"search"} value={"search"}>
              <SearchBar
                onSearch={search => setSearch(search.toLowerCase())}
                style={{ margin: 0 }}
              />
            </ElmSelectOption>
          ) : null}
          {/* <ElmSelectOption key={"selectAll"} value={"selectAll"}>
            <ElmCheckBox
              checked={selection?.length === options.length}
              onClick={() => {
                handleSelection(
                  selection?.length === options.length ? [] : options.map(c => c.id)
                );
              }}
            >
              {"Select all"}
            </ElmCheckBox>
          </ElmSelectOption> */}
          {options
            ?.filter(node => node.name?.toLowerCase().includes(search))
            ?.map(node => {
              return (
                <ElmSelectOption
                  key={node.id}
                  value={node.id}
                  label={node.name}
                >
                  <ElmCheckBox
                    onChange={handleChange(node)}
                    checked={
                      selection?.find(item => item === node.id) ? true : false
                    }
                  >
                    {node.name}
                  </ElmCheckBox>
                </ElmSelectOption>
              );
            })}
        </ElmSelectWithLabel>
      </Flex>
    </SelectionContainer>
  );
};
const relayEnvironment = getEnvironment();
const RenderQuery: React.FC<ICompaniesSelectProps> = props => {
  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <Suspense
        fallback={
          <Flex style={{ margin: 12 }} flexDirection="column" flex={1}>
            <ElmSelectWithLabel label="Companies" placeholder="All companies" />
          </Flex>
        }
      >
        <CompaniesSelect {...props} />
      </Suspense>
    </RelayEnvironmentProvider>
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
