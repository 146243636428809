/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ApprovalActionListQueryVariables = {
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
};
export type ApprovalActionListQueryResponse = {
  readonly licenseActions: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly action: string;
      readonly description: string | null;
      readonly comment: string | null;
      readonly createdAt: unknown;
      readonly requireApproval: boolean;
      readonly status: number;
      readonly licenseId: string;
      readonly license: {
        readonly id: string;
        readonly owner: {
          readonly name: string | null;
        } | null;
        readonly product: {
          readonly id: string;
          readonly name: string | null;
        } | null;
      } | null;
      readonly vendorSystemUser: {
        readonly id: string;
        readonly name: string | null;
        readonly email: string | null;
      } | null;
    } | null> | null;
  } | null;
};
export type ApprovalActionListQuery = {
  readonly response: ApprovalActionListQueryResponse;
  readonly variables: ApprovalActionListQueryVariables;
};

/*
query ApprovalActionListQuery(
  $sortColumn: [String!]
  $sortDirection: [String!]
) {
  licenseActions(sortColumn: $sortColumn, sortDirection: $sortDirection) {
    nodes {
      id
      action
      description
      comment
      createdAt
      requireApproval
      status
      licenseId
      license {
        id
        owner {
          __typename
          name
          id
        }
        product {
          id
          name
        }
      }
      vendorSystemUser {
        id
        name
        email
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "sortColumn"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "sortDirection"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "sortColumn",
        variableName: "sortColumn"
      } as any,
      {
        kind: "Variable",
        name: "sortDirection",
        variableName: "sortDirection"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "action",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "description",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "comment",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "createdAt",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "requireApproval",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "status",
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "licenseId",
      storageKey: null
    } as any,
    v10 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v11 = {
      alias: null,
      args: null,
      concreteType: "Product",
      kind: "LinkedField",
      name: "product",
      plural: false,
      selections: [v2 /*: any*/, v10 /*: any*/],
      storageKey: null
    } as any,
    v12 = {
      alias: null,
      args: null,
      concreteType: "VendorSystemUser",
      kind: "LinkedField",
      name: "vendorSystemUser",
      plural: false,
      selections: [
        v2 /*: any*/,
        v10 /*: any*/,
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "email",
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "ApprovalActionListQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "LicenseActionConnection",
          kind: "LinkedField",
          name: "licenseActions",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "LicenseAction",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/,
                v7 /*: any*/,
                v8 /*: any*/,
                v9 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "License",
                  kind: "LinkedField",
                  name: "license",
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: "LinkedField",
                      name: "owner",
                      plural: false,
                      selections: [v10 /*: any*/],
                      storageKey: null
                    },
                    v11 /*: any*/
                  ],
                  storageKey: null
                },
                v12 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "ApprovalActionListQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "LicenseActionConnection",
          kind: "LinkedField",
          name: "licenseActions",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "LicenseAction",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/,
                v7 /*: any*/,
                v8 /*: any*/,
                v9 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "License",
                  kind: "LinkedField",
                  name: "license",
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: "LinkedField",
                      name: "owner",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        },
                        v10 /*: any*/,
                        v2 /*: any*/
                      ],
                      storageKey: null
                    },
                    v11 /*: any*/
                  ],
                  storageKey: null
                },
                v12 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "7b9a7b666b9fa7c437bba230d5c9a7e7",
      id: null,
      metadata: {},
      name: "ApprovalActionListQuery",
      operationKind: "query",
      text:
        "query ApprovalActionListQuery(\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n) {\n  licenseActions(sortColumn: $sortColumn, sortDirection: $sortDirection) {\n    nodes {\n      id\n      action\n      description\n      comment\n      createdAt\n      requireApproval\n      status\n      licenseId\n      license {\n        id\n        owner {\n          __typename\n          name\n          id\n        }\n        product {\n          id\n          name\n        }\n      }\n      vendorSystemUser {\n        id\n        name\n        email\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "82f03e92f00cb69910a911cf05839823";
export default node;
