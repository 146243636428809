import * as _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import styled, { useTheme } from "styled-components";
import relayEnvironment from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenProductComponentBlade
} from "../../../components/bladeManager/types";
import {
  BladeTemplate,
  BladeTitle,
  ElmTable,
  TabView
} from "../../../components";
import { ElmButton } from "../../../components/elmButton";
import { getIcon } from "../../../components/icons";
import {
  ProductComponentQuery,
  ProductComponentQueryResponse
} from "./__generated__/ProductComponentQuery.graphql";
import { IAttributValuePair } from "../../../components/attributesBox";
import moment from "moment";
import theme from "../../../theme";
import { AutoSizer } from "react-virtualized";
import { ITab } from "components/tabView";
import LicensesTable, {
  LicensesTableColumns,
  LicenseTableType
} from "Licenses/components/licensesTable";
import {
  CompaniesTableColumns,
  companyTableType
} from "Companies/components/CompaniesTable";
import CompaniesTable from "Companies/components/CompaniesTable";
import { UsersTable, userTableType } from "Users/components/usersTable";
import { LicenseAction } from "Licenses/blades/Licenses";
import LoadingWrapper from "components/elmChart/loadingWrapper";
import { LineChart } from "components/elmChart";
import { EmptyTableAction } from "../Product";
import { updateBladeUrlState } from "utils";
import AttributeActionsDropdown from "components/bladeAttributeActionsDropdown";
import { REFRESH_KEY } from "components/bladeManager/entityRefreshMap";

type ComponentNode = ProductComponentQueryResponse["component"];
type VersionRequirementNode = ComponentNode["productComponentRequirements"][number];

const graphqlQuery = graphql`
  query ProductComponentQuery($id: ID!) {
    # ...productGroupComponentsListTable_components @arguments(id: $id)
    # ...usersTable_users @arguments(blade_scope: $blade_scope)

    component(id: $id) {
      id
      name
      componentType
      componentGroup {
        id
        name
        components {
          nodes {
            id
            name
            componentType
          }
        }
      }
      productComponentRequirements {
        canInherit
        freeTrial
        freeTrialActive
        freeTrialExpiration
        isIncluded
        isInherited
        productVersionId
        tokenCount
        wouldInheritFromBaseLicense
      }
      product {
        id
        name
        iconUrl
        licenses {
          nodes {
            id
            owner {
              id
              name
            }
          }
        }
        productVersions {
          nodes {
            componentRequirementsCount
            id
            ordinal
            #product
            versionString
          }
        }
      }
    }
  }
`;

const StyledProductComponentInfo = styled(Flex)`
  color: ${props => props.theme.colors.textPrimary};
`;
const TableHeaderContainer = styled.div`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.warmGrey};
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSizes.xSmall};
  color: ${props => props.theme.colors.warmGrey};
`;

const CheckCircle = styled(getIcon("FaRegCheckCircle"))`
  width: 16px;
  height: 16px;
`;
const MinusCircle = styled(getIcon("circle-minus", "far"))`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.warmGrey};
`;

const ArrowUp = styled(getIcon("FaArrowUp"))`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.warmGrey};
`;

const ChartWrapper = styled.div(props => ({
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
  margin: "21px",
  padding: "20px"
}));

export interface IProductComponentBladeProps
  extends IBladeBaseProps<IOpenProductComponentBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}

// type licenseInfo = ProductComponentQueryResponse['license'];
interface IProductComponentBladeState {
  mode: "edit" | "normal";
  activeTabIndex: number;
}
export class ProductComponentBlade extends React.Component<
  IProductComponentBladeProps & {
    result: ProductComponentQueryResponse;
    retry: () => void;
    theme: any;
  },
  IProductComponentBladeState
> {
  public state: IProductComponentBladeState = {
    mode: "normal",
    activeTabIndex: 0
  };

  public updateActiveTab = (activeTabIndex: number) => {
    updateBladeUrlState(this.props, activeTabIndex);
    this.setState({
      activeTabIndex
    });
  };

  public getTabs = (res: any) => {
    const isGroup = _.get(this.props, "routeData.isGroup", false);
    let tabs = [
      {
        title: isGroup ? "Components" : "Requirements",
        Component: isGroup ? this.renderComponents() : this.renderRequirements()
      }
      /**
       * Temporarily disable those tabs as we need a better way of filtering down
       * to the licenses/companies/users under the product component scope
       */
      /*
      {
        title: "Licenses",
        Component: this.renderLicenses(res)
      },
      {
        title: "Companies",
        Component: this.renderCompanies(res)
      },
      {
        title: "Users",
        Component: this.renderUsers(res)
      }
      */
    ];
    return tabs as ITab[];
  };

  public getLicenseOwner = () => {
    const license = this.getLicenseForComponent();
    if (license) {
      return license.owner.name;
    }
    return "";
  };

  public renderLeftSideHeader = () => {
    const isGroup = _.get(this.props, "routeData.isGroup", false);
    return (
      <Flex>
        <Flex flexDirection="column">
          <BladeTitle>
            {isGroup
              ? _.get(this.props.result, "component.componentGroup.name")
              : _.get(this.props.result, "component.name")}
          </BladeTitle>
        </Flex>
      </Flex>
    );
  };

  public enterEditMode = () => {
    this.setState({ mode: "edit" });
  };
  public enterNormalMode = () => {
    this.setState({ mode: "normal" });
  };
  public getLicenseForComponent = () => {
    if (this.props.routeData.licenseId && this.props.result) {
      return _.find(
        this.props.result.component.product.licenses.nodes,
        n => n.id === this.props.routeData.licenseId
      );
    }
    return null;
  };
  public renderRightSideHeader = () => {
    const showEditComponentDialog = () => () => {
      this.props.openDialog("NewComponentDialog", {
        onConfirm: () => {
          this.props.refreshAllOpenBlades();
        },
        productId: this.props.routeData.id,
        productName: this.props.result.component.name,
        isComponentEdit: true,
        componentId: this.props.routeData.id
      });
    };
    const showDeleteDialog = () => {
      this.props.openDialog("DeleteDialog", {
        entityType: "productComponent",
        name: this.props.result.component.name,
        onConfirm: () => {
          this.props.gateway.request
            .deleteComponent(null, {
              id: this.props.routeData.id
            })
            .then(res => {
              if (res.error) {
                console.error(res.error);
              } else {
                this.props.closeDialog("DeleteDialog");
                this.props.closeBlade({
                  route: "ProductComponent",
                  fromBladeIndex: this.props.index
                });
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      });
    };
    return (
      <Flex>
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon="pen"
          iconPrefix="fas"
          label={"Edit"}
          permissions="modify_assets"
          onClick={showEditComponentDialog()}
        />
        <AttributeActionsDropdown
          permissions={["modify_assets"]}
          showDeleteDialog={showDeleteDialog}
        />
      </Flex>
    );
  };

  public renderAttributesBox = () => {
    const items = [
      {
        label: "Group",
        value: _.get(this.props.result, "component.componentGroup.name")
      },
      {
        label: "License Model",
        value: _.get(this.props.result, "component.componentType")
      }
    ] as IAttributValuePair[];
    //return <AttributesBox items={items} />;
    const renderItems = () => {
      if (_.isArray(items)) {
        return (
          <Flex
            flexWrap="wrap"
            flex={1}
            style={_.merge(
              {
                height: "max-content",
                fontSize: "12px",
                fontWeight: 400
              }
              //this.props.containerStyle
            )}
          >
            <div style={{ marginRight: "12px" }}>
              {` Group: `}
              <span style={{ color: theme.colors.warmGrey }}>
                {items[0]?.value || "N/A"}
              </span>
            </div>
            <div style={{ marginRight: "12px" }}>
              {` Model: `}
              <span style={{ color: theme.colors.warmGrey }}>
                {items[1]?.value || "N/A"}
              </span>
            </div>
          </Flex>
        );
      }

      return null;
    };
    return (
      <div
        style={{
          padding: "10px 10px 10px 0px",
          flex: "1",
          position: "relative"
          //marginTop: "12px",
        }}
      >
        {renderItems()}
      </div>
    );
  };

  public renderComponents = () => {
    const showAddNewComponentDialog = () => {
      this.props.openDialog("NewComponentDialog", {
        onConfirm: () => {
          this.props.retry();
        },
        productId: this.props.routeData.id,
        productName: "" //res.product.name
      });
    };
    const openProductComponentBlade = (routeData: {
      id: string;
      name: string;
    }) => {
      this.props.openBlade({
        route: "ProductComponent",
        routeName: `${routeData.name}`,
        routeData: {
          ...routeData
        },
        fromBladeIndex: this.props.index
      });
    };
    const nodes =
      this.props.result?.component?.componentGroup?.components?.nodes || [];
    return (
      <ElmTable
        data={nodes}
        columns={[
          {
            Header: "Name",
            accessor: "name",
            sortKey: "name"
          },
          {
            Header: "Model",
            accessor: "componentType",
            disableSortBy: true
          }
        ]}
        onRowClick={openProductComponentBlade}
        useDefaultAddButton={showAddNewComponentDialog}
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message="We couldn't find components."
            addButtonCallback={showAddNewComponentDialog}
            actionMessage="Add component"
          />
        )}
      />
      /**
       * Temporarily disable those tabs as we need a better way of filtering down
       * to the licenses/companies/users under the product component scope
       */
      /* 
      <ProductGroupComponentsListTable
        componentGroup={this.props.result}
        onRowClick={openProductComponentBlade}
        useDefaultAddButton={showAddNewComponentDialog}
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message="We couldn't find components."
            addButtonCallback={showAddNewComponentDialog}
            actionMessage="Add component"
          />
        )}
      />
      */
    );
  };

  public renderRequirements = () => {
    const showEditComponentDialog = (data: {
      id: string;
      version: string;
      selected: boolean;
      isInherited?: boolean;
      tokenCount?: number;
      freeTrial?: boolean;
      freeTrialDate?: string;
      licenseId?: string;
    }) => () => {
      this.props.openDialog("EditComponentDialog", {
        onConfirm: () => {
          this.props.refreshAllOpenBlades();
          if (data.licenseId) {
            this.props.closeBlade({
              route: "ProductComponent"
            });
          }
        },
        component_id: this.props.routeData.id,
        version: data.version,
        product_version_id: data.id,
        id: this.props.routeData.id,
        selected: data.selected,
        isInherited: data.isInherited,
        freeTrialDate: data.freeTrialDate,
        freeTrial: data.freeTrial,
        tokenCount: data.tokenCount,
        licenseId: data.licenseId
      });
    };

    let nodes = _.get(
      this.props.result,
      "component.product.productVersions.nodes"
    ) as ProductComponentQueryResponse["component"]["product"]["productVersions"]["nodes"];
    let requirements = _.get(
      this.props.result,
      "component.productComponentRequirements"
    ) as ProductComponentQueryResponse["component"]["productComponentRequirements"];
    nodes = nodes || [];
    requirements = requirements || [];
    let versions = nodes.map(item => {
      const requirement =
        requirements.find(r => r.productVersionId === item.id) || {};
      const version = {
        id: item.id,
        versionString: item.versionString,
        ordinal: item.ordinal,
        tokenCount: requirement?.tokenCount || 0,
        freeTrial: !!requirement?.freeTrialActive,
        freeTrialExpiration: requirement.freeTrialExpiration,
        isIncluded: !!requirement?.isIncluded,
        isInherited: !!requirement?.isInherited
      };
      return version;
    });
    let licenseComponentRequirements = _.get(
      this.props.routeData,
      "licenseComponentRequirements",
      []
    ) as ProductComponentQueryResponse["component"]["productComponentRequirements"];
    let licenseComponentVersions = nodes.map(item => {
      const requirement =
        licenseComponentRequirements.find(
          r => r.productVersionId === item.id
        ) || {};
      const version = {
        id: item.id,
        versionString: item.versionString,
        ordinal: item.ordinal,
        tokenCount: requirement?.tokenCount || 0,
        freeTrial: !!requirement?.freeTrialActive,
        freeTrialExpiration: requirement.freeTrialExpiration,
        isIncluded: !!requirement?.isIncluded,
        isInherited: !!requirement?.isInherited
      };
      return version;
    });
    //nodes = _.sortBy(versions, (n) => n.ordinal);
    const renderVersions = (
      versions,
      {
        allowEdit,
        isLicenseComponent
      }: { allowEdit: boolean; isLicenseComponent?: boolean }
    ) => {
      return _.map(versions, (node, index) => {
        const compareToBaseRequirements = (
          key: keyof VersionRequirementNode,
          value: React.ReactNode
        ) => {
          if (_.isEqual(requirements[index][key], node[key])) {
            return "Same as base";
          }
          return value;
        };
        return (
          <Flex
            fontSize="13px"
            justifyContent="space-between"
            style={{
              height: "29px",
              paddingTop: "6px",
              marginBottom: "5px",
              color: this.props.theme.colors.black
            }}
          >
            <div style={{ width: "55px", flexBasis: "150px" }}>
              {node.versionString}
            </div>
            {isLicenseComponent ? (
              <>
                <div style={{ flexBasis: "150px" }}>
                  {compareToBaseRequirements(
                    "isIncluded",
                    node.isIncluded ? (
                      <CheckCircle style={{ color: "green" }} />
                    ) : (
                      <MinusCircle />
                    )
                  )}
                </div>
                <div style={{ flexBasis: "150px" }}>
                  {compareToBaseRequirements("tokenCount", node.tokenCount)}
                </div>
                <div style={{ flexBasis: "150px" }}>
                  {compareToBaseRequirements(
                    "freeTrialExpiration",
                    node.freeTrial ? (
                      `Expires ${
                        node.freeTrialExpiration
                          ? moment(node.freeTrialExpiration).format(
                              "MM/DD/YY HH:mm"
                            )
                          : ""
                      }`
                    ) : (
                      <MinusCircle />
                    )
                  )}
                </div>
              </>
            ) : node.isInherited ? (
              <>
                <div style={{ flexBasis: "150px" }}>
                  <ArrowUp />
                </div>
                <div style={{ flexBasis: "150px" }}>
                  <ArrowUp />
                </div>
                <div style={{ flexBasis: "150px" }}>
                  <ArrowUp />
                </div>
              </>
            ) : (
              <>
                <div style={{ flexBasis: "150px" }}>
                  {node.isIncluded ? (
                    <CheckCircle style={{ color: "green" }} />
                  ) : (
                    <MinusCircle />
                  )}
                </div>
                <div style={{ flexBasis: "150px" }}>{node.tokenCount}</div>
                <div style={{ flexBasis: "150px" }}>
                  {node.freeTrial ? (
                    `Expires ${
                      node.freeTrialExpiration
                        ? moment(node.freeTrialExpiration).format(
                            "MM/DD/YY HH:mm"
                          )
                        : ""
                    }`
                  ) : (
                    <MinusCircle />
                  )}
                </div>
              </>
            )}

            {allowEdit ? (
              <div>
                <ElmButton
                  className="darkModeTransparentBtn"
                  variance="icon-button"
                  icon="pen"
                  iconPrefix="fas"
                  //style={{ marginRight: "5px", width: "91px", height: "20px" }}
                  style={{ color: this.props.theme.colors.iconColor }}
                  label=""
                  alt="Edit"
                  permissions="modify_assets"
                  colorVariance="subtle"
                  onClick={showEditComponentDialog({
                    version: node.versionString,
                    id: node.id,
                    selected: !node.isIncluded,
                    isInherited: node.isInherited,
                    tokenCount: node.tokenCount,
                    freeTrialDate: node.freeTrialExpiration,
                    freeTrial: node.freeTrial,
                    licenseId: this.props.routeData?.licenseId
                  })}
                />
              </div>
            ) : null}
          </Flex>
        );
      });
    };
    return (
      <Flex flexDirection="column" flex={1}>
        {this.props.routeData?.licenseComponentRequirements ? (
          <>
            <TableHeaderContainer>
              {_.map(
                ["Version", "Included", "Tokens", "Free Trial", "Actions"],
                header => (
                  <Flex>{header}</Flex>
                )
              )}
            </TableHeaderContainer>
            {renderVersions(licenseComponentVersions, {
              allowEdit: true,
              isLicenseComponent: true
            })}
          </>
        ) : null}
        {this.props.routeData?.licenseComponentRequirements ? (
          <span
            style={{
              marginTop: "24px",
              marginBottom: "12px",
              fontSize: "small",
              color: this.props.theme.colors.black
            }}
          >
            Base requirements
          </span>
        ) : null}
        <TableHeaderContainer>
          {_.map(
            [
              "Version",
              "Included",
              "Tokens",
              "Free Trial",
              !this.props.routeData?.licenseComponentRequirements
                ? "Actions"
                : null
            ],
            header => (
              <Flex>{header}</Flex>
            )
          )}
        </TableHeaderContainer>
        {renderVersions(versions, {
          allowEdit: !this.props.routeData?.licenseComponentRequirements
        })}
      </Flex>
    );
  };

  public renderUsers = (res: ProductComponentQueryResponse) => {
    const navToUserBlade = (user: userTableType) => {
      this.props.openBlade({
        route: "User",
        routeName: `${user.name}`,
        fromBladeIndex: this.props.index,
        routeData: {
          id: user.id.toString(),
          name: user.name
          // productId: ''V
        }
      });
    };
    return (
      <UsersTable
        products={this.props.result}
        companies={this.props.result}
        hideColumnsWithHeaders={[
          "Status",
          "Company",
          "Product",
          "Enable",
          "Last active session",
          "Actions"
        ]}
        onRowClick={navToUserBlade}
        bladeName={"Users"}
      />
    );
  };

  public renderCompanies = (res: ProductComponentQueryResponse) => {
    const navToCompanyBlade = (company: companyTableType) => {
      this.props.openBlade({
        route: "Company",
        routeName: company.name,
        fromBladeIndex: this.props.index,
        routeData: {
          id: company.id
        }
      });
    };
    return (
      <CompaniesTable
        onRowClick={navToCompanyBlade}
        hideColumnsWithHeaders={[CompaniesTableColumns.Product]}
        bladeName={"ProductComponent"}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ComponentCompaniesTable
        )}
        refreshKey={REFRESH_KEY.ComponentCompaniesTable}
        removeRefreshFn={this.props.removeRefreshFn}
      />
    );
  };

  public renderLicenses = () => {
    const navToLicenseBlade = (license: LicenseTableType) => {
      this.props.openBlade({
        route: "License",
        routeName: "License",
        fromBladeIndex: this.props.index,
        routeData: {
          id: license.id,
          productId: license.product.id
        }
      });
    };

    return (
      <LicensesTable
        bladeName="ProductComponent"
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.ComponentLicensesTable
        )}
        refreshKey={REFRESH_KEY.ComponentLicensesTable}
        removeRefreshFn={this.props.removeRefreshFn}
        bladeScope={this.props.routeData?.id}
        onRowClick={navToLicenseBlade}
        hideColumnsWithHeaders={[
          LicensesTableColumns.EnforcementModel,
          LicensesTableColumns.LicenseKey,
          LicensesTableColumns.Usage
        ]}
        renderRowActionButtons={data => (
          <LicenseAction
            data={data}
            onConfirm={this.props.refreshAllOpenBlades}
          />
        )}
        columnAndHeaderStyles={{
          justifyFirstHeaderColumnContent: "flex-start",
          justifyColumnContent: "flex-start",
          justifyLastHeaderColumnContent: "flex-end"
        }}
      />
    );
  };

  public renderProductComponentInfo = () => {
    const res = this.props.result;
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Component"}
        accentColor={
          this.props.routeData?.licenseComponentRequirements
            ? "mango"
            : "productsBlue"
        }
        bladeTypeName="Component"
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        loading={!res}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        closeBlade={this.props.closeBlade}
        bladeType="ProductComponent"
      >
        <StyledProductComponentInfo>
          <Flex
            style={{
              flex: 1,
              flexDirection: "column",
              maxHeight: "65px",
              paddingLeft: "20px",
              paddingRight: "20px",
              borderTop: `1px solid ${theme.colors.whiteTwo}`
            }}
          >
            {this.renderAttributesBox()}
            {/* {this.renderRequirements()} */}
          </Flex>
        </StyledProductComponentInfo>
        <ChartWrapper>
          <LoadingWrapper>
            <LineChart
              showProductFilter={false}
              hideTimeRangeFilter={true}
              timeRangeOverride="months3"
              queryParams={{
                setup: {
                  category: "time.day",
                  value: "components.usage_count", // "counts.active_user"
                  group: null // "products.name"
                },
                filterColumn: ["is_vendor"],
                filterValues: [["false"]],
                bladeScope: this.props.routeData?.id
              }}
              options={{
                chartMaxHeight: 210
              }}
            />
          </LoadingWrapper>
        </ChartWrapper>
        <Flex flex={1}>
          <AutoSizer defaultHeight={300}>
            {({ height, width }) => (
              <TabView
                showBottomBorder={true}
                leftAlign={true}
                tabList={this.getTabs(res)}
                defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
                onUpdateActiveTab={this.updateActiveTab}
                orientation={"vertical"}
                style={{
                  maxHeight: height,
                  minHeight: height,
                  maxWidth: width,
                  minWidth: width,
                  paddingLeft: "21px",
                  paddingRight: "21px"
                }}
              />
            )}
          </AutoSizer>
        </Flex>
      </BladeTemplate>
    );
  };

  public render() {
    return this.renderProductComponentInfo();
  }
}
const RenderQuery = (props: IProductComponentBladeProps) => {
  const theme = useTheme();
  const renderProductComponentInfo = (payload: {
    error: Error;
    props: ProductComponentQueryResponse;
    retry: () => void;
  }) => {
    return (
      <ProductComponentBlade
        {...props}
        result={payload.props}
        theme={theme}
        retry={payload.retry}
      />
    );
  };
  return (
    <QueryRenderer<ProductComponentQuery>
      environment={relayEnvironment}
      variables={{ id: props.routeData.id, blade_scope: props.routeData.id }}
      query={graphqlQuery}
      render={renderProductComponentInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
