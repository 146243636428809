/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type companiesSelectQueryVariables = {};
export type companiesSelectQueryResponse = {
  readonly companies: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
    } | null> | null;
  } | null;
};
export type companiesSelectQuery = {
  readonly response: companiesSelectQueryResponse;
  readonly variables: companiesSelectQueryVariables;
};

/*
query companiesSelectQuery {
  companies {
    nodes {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: "CompanyConnection",
      kind: "LinkedField",
      name: "companies",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Company",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "name",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "companiesSelectQuery",
      selections: v0 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "companiesSelectQuery",
      selections: v0 /*: any*/
    },
    params: {
      cacheID: "46f1e39bea2d0b7ccd3ef2a02155f6e6",
      id: null,
      metadata: {},
      name: "companiesSelectQuery",
      operationKind: "query",
      text:
        "query companiesSelectQuery {\n  companies {\n    nodes {\n      id\n      name\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "b736734fb37b4e608047c43667d9c380";
export default node;
