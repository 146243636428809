import { notification } from "antd";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { getGateway } from "../../../../api";
import { ElmButton, ElmInput } from "components";
import { InfoMessage } from "components/helpers";
import {
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import _ from "lodash";
import { base64DecodeId } from "utils/base64EncodeDecode";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: 280px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;

export interface IProductVersionDialogProps {
  product_id: string;
  version_id?: string;
  version_string?: string;
}

interface state {
  version_string: string;
  isInputValid: boolean;
}

export class ProductVersionDialog extends DialogBase<
  IModalProps<IProductVersionDialogProps>,
  state
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          minHeight: "200px",
          width: "280px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          border: "none"
        }
      }
    });
  }
  state: state = {
    version_string: this.props.payload?.version_string,
    isInputValid: false
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "ProductVersionDialog"
    });
  };
  public handleSubmit = () => {
    const gateway = getGateway();
    if (!this.props.payload?.version_id) {
      // We need to pass in the numbered productId instead of the graphql id, doesn't work
      const b64DecodeProductId = _.isNumber(this.props.payload?.product_id)
        ? this.props.payload?.product_id
        : base64DecodeId(this.props.payload?.product_id);

      gateway.request
        .newProductVersion({
          product_version: {
            product_id: b64DecodeProductId,
            version_string: this.state.version_string
          }
        })
        .then(res => {
          if (res.error) {
            console.error(res.error);
            renderFailureNotification("Add version failed !");
          } else {
            renderSuccessNotification("Added new verson to product !");
            this.props.payload.onConfirm(this.state);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
    // We need to pass in the numbered id instead of the graphql id, doesn't work
    const b64DecodeVersionId = atob(this.props.payload?.version_id)?.replace(
      "ProductVersion-",
      ""
    );
    gateway.request
      .editProductVersion(
        {
          id: b64DecodeVersionId,
          version_string: this.state.version_string
        },
        {
          id: b64DecodeVersionId
        }
      )
      .then(res => {
        if (res.error) {
          console.error(res.error);
          renderFailureNotification("Edit Version Failed !");
        } else {
          renderSuccessNotification("Product Version Updated !");
          this.props.payload.onConfirm(this.state);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
  public getTitle = () => {
    return this.props.payload?.version_id
      ? `Edit Version ${this.props.payload?.version_string}`
      : "Add New Version";
  };
  public updateVersionValue = (version_string: string) => {
    const validInputRegex = /^(?!0\d)(\d+(\.0*|\.\d+)?)+$/;
    if (validInputRegex.test(version_string)) {
      this.setState({ version_string, isInputValid: true });
      return;
    }
    this.setState({ isInputValid: false });
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        {this.props.payload?.version_id ? (
          <InfoMessage
            style={{ width: "100%", maxWidth: "unset" }}
            description={`
                Warning: If this product version is used as 
                a reference version for any license or feature entitlements, 
                changing the product version will update those entitlements 
                to apply to the new version number, which can have 
                significant licensing implications.
            `}
            type="warning"
          />
        ) : null}
        <Flex>
          <ElmInput
            label={"Version number (of the form X.X.X.X)"}
            style={{ marginTop: 16, marginBottom: 28 }}
            defaultValue={this.state.version_string}
            updatedValue={this.updateVersionValue}
          />
        </Flex>
        <Flex flex={1} alignItems="flex-end" justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            style={{ minWidth: "127px" }}
            colorVariance="primary"
            label={this.props.payload?.version_id ? "Save Changes" : "Add"}
            onClick={this.handleSubmit}
            disabled={!this.state.version_string || !this.state.isInputValid}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
