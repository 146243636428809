import _ from "lodash";
import * as React from "react";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { getGateway } from "../../../../api";
import {
  ICreateTerm,
  IEditLicenseModel,
  IModifyEntitlement,
  IModifyRestrictions
} from "../../../../api/gatewayMap";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import {
  ElmDefaultSelect,
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../../components/elmSelect";
import { ElmButton } from "../../../../components/elmButton";
import { ElmInput } from "../../../../components/elmInput";
import ElmDatePicker from "../../../../components/elmDate";
import { ElmCheckBox } from "../../../../components/elmCheckBox";
import {
  licenseTypeToName,
  licenseTypeTemplates,
  licenseTypeId,
  getTermDetails
} from "../../../../utils";
import { LicenseEnforcementSelector } from "../../licenseEnforcementSelector";
import relayEnvironment from "../../../../api/relay";
import {
  editLicenseQuery,
  editLicenseQueryResponse
} from "./__generated__/editLicenseQuery.graphql";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DialogTitle } from "../../../../components/bladeManager/appDialog/helpers";
import TabView, { ITab } from "../../../../components/tabView";
import styled from "styled-components";
import {
  calculateTermEnd,
  enforcementModel,
  //commercialModel,
  termEnd,
  termEndFreeTrial,
  termStart
} from "../../../../Licenses/blades/AddLicense";
import { SectionHeader } from "../../../../Licenses/blades/AddLicense/styled";
import { LoaderContainer } from "../../../../components/helpers";
import dayjs, { Dayjs } from "dayjs";
import { Input, Tooltip } from "antd";
import {
  commercialModel,
  editLicenseState,
  ILicenseModel,
  IEditLicenseDialogProps,
  tabName,
  commercialModelType
} from "../../../common";
import { ElmTable } from "components";
import { TableCellProps } from "react-virtualized";
import {
  renderErrorNotification,
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { DATE_FORMAT } from "const";

const { TextArea } = Input;

const graphqlQuery = graphql`
  query editLicenseQuery($id: ID!) {
    license(id: $id) {
      type {
        id
        name
      }
      gracePeriod
      defaultTokenDuration
      latestTerm {
        startDate
        endDate
        value
      }
      isTrial
      isVendor
      usersCount
      sessionsCount
      instancesCount
      totalSeatCount
      lifetimeValue
      entitlements {
        nodes {
          instanceCount
          sessionCount
          userCount
        }
      }
      activeEntitlements {
        nodes {
          id
          value
          latestTerm {
            startDate
            endDate
          }
          instanceCount
          sessionCount
          userCount
        }
      }
      commercialModel {
        id
        name
      }
      restriction {
        instancesPerUser
        requireEmailActivation
        sessionsPerInstance
        sessionsPerUser
        sessionsPerUserPerInstance
        minimumAssignmentLength
        usersPerInstance
        numberOfInstalls
        numberOfUsers
      }
      owner {
        id
        name
        type
      }
      product {
        id
        name
        # availableSeats
        # totalSeats
        iconUrl
        type {
          name
          id
        }
      }
    }
  }
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
  min-height: 571px;
`;
const StyledLabelSecondary = styled.span`
  font-size: 10px;
  color: ${props => props.theme.colors.black};
  margin-inline: 5px;
`;

export class EditLicenseDialog extends DialogBase<
  IModalProps<IEditLicenseDialogProps> & {
    result: editLicenseQueryResponse;
    theme?: any;
  },
  editLicenseState
> {
  static getAppConnectOptions() {
    return super.getAppConnectOptions({
      selectors: {
        activeRole: "activeRoleSelector"
      }
    });
  }
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          background: "yellow"
          //width: "440px"
        }
      }
    });
  }

  license = this.props?.result?.license;

  state: editLicenseState = {
    loading: false,
    error: null,
    editLicense: {
      enforcement_model_id: _.get(this.license, "commercialModel.id"),
      number_of_users: this.license?.restriction?.numberOfUsers,
      number_of_installs: this.license?.restriction?.numberOfInstalls,
      max_instances_per_user: 0,
      default_token_duration: this.license?.defaultTokenDuration,
      owner: {
        name: this.license?.owner?.name,
        owner_id: this.license?.owner?.id,
        owner_type: this.license?.owner?.type
      },
      product_id: this.license?.product.id,
      is_vendor: this.license?.isVendor,
      owner_id: this.license?.owner?.id,
      owner_type: this.license?.owner?.type,
      user_count: this.license?.entitlements?.nodes[0]?.userCount,
      grace_period: this.license?.gracePeriod,
      entitlement_id: this.license.activeEntitlements?.nodes[0]?.id,
      instance_count: this.license?.entitlements?.nodes[0]?.instanceCount,
      session_count: this.license?.entitlements?.nodes[0]?.sessionCount,
      instances_per_user: this.license?.restriction?.instancesPerUser,
      users_per_instance: this.license?.restriction?.usersPerInstance,
      sessions_per_user: this.license?.restriction?.sessionsPerUser,
      sessions_per_instance: this.license?.restriction?.sessionsPerInstance,
      sessions_per_user_per_instance: this.license?.restriction
        ?.sessionsPerUserPerInstance,
      minimum_assignment_length: this.license?.restriction
        ?.minimumAssignmentLength,
      require_email_activation: this.license?.restriction
        ?.requireEmailActivation,
      value: 0,
      license_value: _.get(this.license, "lifetimeValue", 0),
      term_value: _.get(this.license, "latestTerm.value", 0),
      current_seats: _.get(this.license, "totalSeatCount", 0)
    },
    company: null,
    product: this.license?.product,
    startMoment: null,
    endMoment: null,
    activeTabMainIndex: 0,
    activeTabTitle:
      _.get(this.license, "commercialModel.name") === "Freeware"
        ? tabName.restrictions
        : tabName.renewTerm,
    termStartDate: _.get(this.license, "latestTerm.startDate", "")
      ? dayjs(_.get(this.license, "latestTerm.startDate"))
      : null,
    termStartDateHumanized: _.get(this.license, "latestTerm.startDate", "")
      ? dayjs(_.get(this.license, "latestTerm.startDate")).format(DATE_FORMAT)
      : "",
    termEndDate: _.get(this.license, "latestTerm.endDate", "")
      ? dayjs(_.get(this.license, "latestTerm.endDate"))
      : null,
    termEndDateHumanized: _.get(this.license, "latestTerm.endDate", "")
      ? dayjs(_.get(this.license, "latestTerm.endDate")).format(DATE_FORMAT)
      : "",
    isTermEndPickerOpen: false,
    isTermStartPickerOpen: false,
    commercialModelId: null,
    commercialModel: _.get(this.license, "commercialModel.name", null), //commercialModel.perpetualMS,
    licenseTypeId: this.props.payload.licenseTypeId,
    enforcementModel: licenseTypeToName(
      this.props.payload.licenseTypeId as licenseTypeId
    ) as enforcementModel,
    //enforcementModel: enforcementModel.concurrent,
    isChangeSeatsCheckboxEnabled: false,
    isAddTermCheckboxEnabled: false,
    isCommentEnabled: false,
    isModelChanged: false
    //comment: undefined,
  };

  componentDidMount(): void {
    const isLease = this.checkIsLeaseCommercialModel();
    if (!isLease) {
      const term = _.get(this.license, "latestTerm");
      const prevTermStart = dayjs(term?.startDate as string);
      const prevTermEnd = dayjs(term?.endDate as string);
      const nextTermStart = this.isExpired()
        ? this.getExpiredLicenseTermStart()
        : prevTermEnd;

      const difference = prevTermEnd.diff(prevTermStart);
      const nextTermEnd = difference
        ? dayjs(nextTermStart).add(difference, "milliseconds")
        : dayjs(nextTermStart).add(1, "year");

      this.setState({
        termStartDate: nextTermStart,
        termEndDate: nextTermEnd,
        termStartDateHumanized: dayjs(nextTermStart).format(DATE_FORMAT),
        termEndDateHumanized: dayjs(nextTermEnd).format(DATE_FORMAT)
      });
      return;
    }

    const activeEntitlements = this.getActiveEntitlements();
    if (activeEntitlements.length > 1) {
      return;
    }

    if (activeEntitlements.length === 0) {
      const nextTermStart = dayjs();
      const nextTermEnd = dayjs().add(1, "year");
      this.setState({
        termStartDate: nextTermStart,
        termEndDate: nextTermEnd,
        termStartDateHumanized: dayjs(nextTermStart).format(DATE_FORMAT),
        termEndDateHumanized: dayjs(nextTermEnd).format(DATE_FORMAT)
      });
    }
    if (activeEntitlements.length === 1) {
      const term = this.getEntitlementProps(activeEntitlements[0].id)
        .latestTerm;
      const prevTermStart = dayjs(term?.startDate as string);
      const nextTermStart = dayjs(term?.endDate as string);

      const difference = nextTermStart.diff(prevTermStart);
      const nextTermEnd = nextTermStart.add(difference, "milliseconds");

      this.setState({
        termStartDate: nextTermStart,
        termEndDate: nextTermEnd,
        termStartDateHumanized: dayjs(nextTermStart).format(DATE_FORMAT),
        termEndDateHumanized: dayjs(nextTermEnd).format(DATE_FORMAT)
      });
    }
  }

  componentDidUpdate(
    _prevProps: any,
    prevState: Readonly<editLicenseState>
  ): void {
    if (
      !_.isEqual(this.state.termStartDate, prevState.termStartDate) &&
      this.state.termEndDateRangeMonths
    ) {
      const termEndDate = calculateTermEnd(
        this.state.termEndDateRangeMonths,
        dayjs(this.state.termStartDate)
      );
      this.setState({ termEndDate });
    }
  }

  public updateIntValueOfRequest = (
    key: keyof Pick<
      ILicenseModel,
      | "number_of_installs"
      | "instance_count"
      | "grace_period"
      | "user_count"
      | "instances_per_user"
      | "number_of_users"
      | "users_per_instance"
      | "max_instances_per_user"
      | "session_count"
      | "sessions_per_user"
      | "sessions_per_instance"
      | "sessions_per_user_per_instance"
      | "minimum_assignment_length"
      | "default_token_duration"
      | "value"
      | "license_value"
      | "term_value"
      | "current_seats"
    >
  ) => (value: string) => {
    const newLicenseState = {
      ...this.state.editLicense
    };
    newLicenseState[key] = parseInt(value, 10);
    this.setState({
      ...this.state,
      editLicense: newLicenseState
    });
  };

  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditLicenseDialog"
    });
  };
  public editAdmin = () => {
    if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
      this.props.payload.onConfirm(this.state);
    }
  };
  public getTitle = () => {
    return "Edit License";
  };
  public getDescription = () => {
    return `Change the role you would like your new administrator to have.`;
  };

  public getSelectOptions = () => {
    return [
      {
        label: "Administrator",
        value: "license_admin"
      },
      {
        label: "Owner",
        value: "owner"
      },
      {
        label: "Stakeholder",
        value: "stakeholder"
      }
    ];
  };

  public getActiveEntitlements = () => {
    const ent = _.get(this.license, "activeEntitlements");
    if (!ent?.nodes?.length) {
      return [];
    }
    return ent.nodes;
  };

  public getSeatCountFromEnforcementModel = (payload: {
    userCount?: number;
    sessionCount?: number;
    instanceCount?: number;
  }) => {
    const eM = licenseTypeToName(this.license.type.id);

    if (eM === enforcementModel.namedUser) {
      return payload.userCount || 0;
    }
    if (eM === enforcementModel.concurrent) {
      return payload.sessionCount || 0;
    }
    if (eM === enforcementModel.nodeLocked) {
      return payload.instanceCount || 0;
    }
    return 0;
  };

  public getEntitlementProps = (entitlementId: string) => {
    const target = this.getActiveEntitlements()?.find(
      entitlement => entitlement.id === entitlementId
    );
    if (!target) {
      return null;
    }
    const { latestTerm, userCount, sessionCount, instanceCount } = target;

    const seats = this.getSeatCountFromEnforcementModel({
      userCount,
      sessionCount,
      instanceCount
    });
    return { latestTerm, seats };
  };

  public checkIsLeaseCommercialModel = () => {
    return (
      _.get(this.license, "commercialModel.name", null) ===
      commercialModel.lease
    );
  };
  public checkIsPerpetualCommercialModel = () => {
    return (
      _.get(this.license, "commercialModel.name", null) ===
      commercialModel.perpetualMS
    );
  };

  public renderLeaseActiveEntitlementSelection = () => {
    const ent = this.getActiveEntitlements();
    if (!ent?.length) {
      return null;
    }
    const hasSingleActiveEntitlement = ent.length === 1;

    return (
      <Flex style={{ width: "300px", marginBottom: "23px" }}>
        <ElmSelectWithLabel
          label="Entitlement"
          placeholder="Select Entitlement"
          disabled={hasSingleActiveEntitlement}
          defaultValue={hasSingleActiveEntitlement ? ent[0].id : null}
          onChange={entitlement_id => {
            const target = ent.find(
              entitlement => entitlement.id === entitlement_id
            );
            if (!target) {
              this.setState({
                editLicense: {
                  ...this.state.editLicense,
                  entitlement_id
                }
              });
              return;
            }
            const nextTermStart = target
              ? dayjs(target.latestTerm.endDate as string)
              : dayjs();
            const nextTermEnd = target
              ? dayjs(target.latestTerm.endDate as string).add(1, "year")
              : dayjs().add(1, "year");
            this.setState({
              editLicense: {
                ...this.state.editLicense,
                entitlement_id
              },
              termStartDate: nextTermStart,
              termEndDate: nextTermEnd,
              termStartDateHumanized: "End of Current Term",
              termEndDateHumanized: dayjs(nextTermEnd).format(DATE_FORMAT)
            });
          }}
        >
          {ent.map(node => {
            const { userCount, sessionCount, instanceCount } = node;
            const seats = this.getSeatCountFromEnforcementModel({
              userCount,
              sessionCount,
              instanceCount
            });

            const displayLabelValue = `${dayjs(
              node.latestTerm?.startDate as string
            ).format(DATE_FORMAT)} - ${dayjs(
              node.latestTerm?.endDate as string
            ).format(DATE_FORMAT)}`;
            return (
              <ElmSelectOption
                key={node.id}
                value={node.id}
                label={`${displayLabelValue}, ${seats} Seats`}
              >
                {displayLabelValue}{" "}
                <StyledLabelSecondary>{seats} Seats</StyledLabelSecondary>
              </ElmSelectOption>
            );
          })}
        </ElmSelectWithLabel>
      </Flex>
    );
  };

  public renderEnforcementSelector = (props: editLicenseQueryResponse) => {
    if (!props) {
      return null;
    }
    let types = null;
    const enforcementModel = _.find(
      licenseTypeTemplates,
      licenseType =>
        licenseType.product_type_id.toString() === props.license.product.type.id
    );
    if (_.isObject(enforcementModel)) {
      types = _.map(enforcementModel.license_types, licenseType => {
        return (
          <ElmSelectOption value={licenseType.id}>
            {licenseType.name}
          </ElmSelectOption>
        );
      });
    }

    // @ts-ignore
    return (
      <Flex style={{ flexDirection: "column" }}>
        <ElmDefaultSelect>{types}</ElmDefaultSelect>
        <span>Licensing Model</span>
      </Flex>
    );
  };

  public isLicenseTypeEqualToAny = (typeIds: number[], licenseTypeId: number) =>
    _.some(typeIds, typeId => typeId === licenseTypeId);

  public renderAdvancedLabel = (payload: {
    onInputChange?: (value: string) => void;
    onCheckboxChange?: (e: boolean) => void;
    suffixLabel?: string;
    inputType?: string;
    isDecimalNumber?: boolean;
    showCheckBox?: boolean;
    showInput?: boolean;
    isInputDisabled?: boolean;
    permittedLicenseTypeIds: number[];
    licenseTypeId: number;
    checkBoxLabel?: string;
    label?: string;
    inputLabel?: string;
    containerWidth?: string;
    minValue?: number;
    maxValue?: number;
    inputDefaultValue?: string | number;
    checkBoxDefaultChecked?: boolean;
  }) => {
    if (
      this.isLicenseTypeEqualToAny(
        payload.permittedLicenseTypeIds,
        this.state.licenseTypeId
      )
    ) {
      const handleChangeEvent = (e: CheckboxChangeEvent) => {
        if (_.isFunction(payload.onCheckboxChange)) {
          payload.onCheckboxChange(e.target.checked);
        }
      };
      return (
        <Flex
          style={{
            alignItems: "end",
            width: payload.containerWidth || "142px",
            marginRight: "4px"
          }}
        >
          {payload.showCheckBox ? (
            <ElmCheckBox
              onChange={handleChangeEvent}
              defaultChecked={payload.checkBoxDefaultChecked}
            >
              {payload.checkBoxLabel}
            </ElmCheckBox>
          ) : null}
          {payload.showInput ? (
            <ElmInput
              label={payload.inputLabel}
              type={payload.inputType}
              isDecimalNumber={payload.isDecimalNumber}
              updatedValue={payload.onInputChange}
              disabled={payload.isInputDisabled}
              units={payload.suffixLabel}
              defaultValue={payload.inputDefaultValue}
              numericConstraints={{
                minValue: payload.minValue,
                maxValue: payload.maxValue
              }}
            />
          ) : null}
        </Flex>
      );
    }
    return null;
  };
  public getEnforcementType = (props: editLicenseQueryResponse) => {
    const enforcementModel = _.find(
      licenseTypeTemplates,
      licenseType =>
        licenseType.product_type_id.toString() === props.license.product.type.id
    );
    return _.find(
      enforcementModel.license_types,
      type => type.id === parseInt(props.license.type.id, 10)
    );
  };
  public getPrimaryRequirementType = (typeId: licenseTypeId) => {
    switch (licenseTypeToName(typeId)) {
      case "Concurrent":
        return {
          label: "Sessions",
          value: _.get(this.license?.entitlements, "nodes[0].sessionCount")
        };
      case "Named User":
        return {
          label: "Users",
          value: _.get(this.license?.entitlements, "nodes[0].userCount")
        };
      case "Node Locked":
        return {
          label: "Instances",
          value: _.get(this.license?.entitlements, "nodes[0].instanceCount")
        };
    }
    return null;
  };

  public updateLicenseType = (licenseTypeId: any) => {
    const enforcementModelName = (licenseTypeToName(
      licenseTypeId
    ) as unknown) as enforcementModel;
    this.setState({
      ...this.state,
      enforcementModel: enforcementModelName,
      // editLicense: {
      //   ...this.state.editLicense,
      // },
      licenseTypeId: licenseTypeId,
      isModelChanged: true
    });
  };
  private gateway = getGateway();
  private handleDataResponse = (res: any) => {
    if (!res.error) {
      renderSuccessNotification(`${res.data?.description}`);
      this.props.payload.onConfirm(null);
    }
    // TODO - Failures are still being processed through handleResponse, need to investigate
    if (res.status === 500) {
      renderFailureNotification("Modify License Failed !");
    }
    if (res.status >= 400) {
      renderFailureNotification(
        `${res.data?.description}` || "Modify License Failed !"
      );
    }
  };
  private handleCatch = (error: any) => {
    renderFailureNotification("Modify License Failed !");
    console.error(error);
  };
  public createTerm = () => {
    const gateway = getGateway();
    const termData: ICreateTerm = {
      license_id: this.props.payload.licenseId,
      term: {
        start_date: _.get(this.state, "termStartDate", dayjs()).toISOString(),
        end_date: _.get(this.state, "termEndDate", dayjs()).toISOString(),
        value: _.get(this.state, "editLicense.term_value", 0), // this should be populated based on user role, value is for vendor
        reseller_value: null
      },
      entitlement: {}
    };
    if (
      this.state.editLicense.current_seats &&
      this.state.isChangeSeatsCheckboxEnabled
    ) {
      termData["entitlement"] = {
        seat_count: this.state.editLicense.current_seats
      };
    }

    const commentEnabled = _.get(this.state, "isCommentEnabled", false);
    if (commentEnabled) {
      termData["comment"] = _.get(this.state, "comment", "");
    }

    if (
      this.checkIsPerpetualCommercialModel() &&
      this.state.editLicense.current_seats > this.license.totalSeatCount
    ) {
      renderErrorNotification("Invalid input, cannot add seats!");
      return;
    }

    gateway.request
      .create_term(termData)
      .then(this.handleDataResponse)
      .catch(this.handleCatch);
    //edit should call api endpoints corresponding to the active tab
  };

  public changeSeats = () => {
    if (
      typeof this.state.editLicense.current_seats !== "number" ||
      this.state.editLicense.current_seats === null ||
      _.isNaN(this.state.editLicense.current_seats)
    ) {
      renderErrorNotification("Invalid input!");
      return;
    }
    const seatData: IModifyEntitlement = {
      license_id: _.get(this.props, "payload.licenseId"),
      entitlement: {
        seat_count: _.get(this.state, "editLicense.current_seats", 0)
      }
    };
    const commentEnabled = _.get(this.state, "isCommentEnabled", false);
    if (commentEnabled) {
      seatData["comment"] = _.get(this.state, "comment", "");
    }

    this.gateway.request
      .modify_entitlement(seatData)
      .then(this.handleDataResponse)
      .catch(this.handleCatch);
  };

  public editRestrictions = () => {
    const restrictions: IModifyRestrictions = {
      license_id: _.get(this.props, "payload.licenseId"),
      restriction: {
        max_instances_per_user: _.get(
          this.state,
          "editLicense.max_instances_per_user"
        ),
        instances_per_user: _.get(this.state, "editLicense.instances_per_user"),
        users_per_instance: _.get(this.state, "editLicense.users_per_instance"),
        sessions_per_user: _.get(this.state, "editLicense.sessions_per_user"),
        sessions_per_instance: _.get(
          this.state,
          "editLicense.sessions_per_instance"
        ),
        sessions_per_user_per_instance: _.get(
          this.state,
          "editLicense.sessions_per_user_per_instance"
        ),
        minimum_assignment_length: _.get(
          this.state,
          "editLicense.minimum_assignment_length"
        ),
        require_email_activation: _.get(
          this.state,
          "editLicense.require_email_activation"
        ),
        number_of_installs: _.get(this.state, "editLicense.number_of_installs"),
        number_of_users: _.get(this.state, "editLicense.number_of_user")
      }
    };

    const commentEnabled = _.get(this.state, "isCommentEnabled", false);
    if (commentEnabled) {
      restrictions["comment"] = _.get(this.state, "comment", "");
    }

    this.gateway.request
      .modify_restrictions(restrictions)
      .then(this.handleDataResponse)
      .catch(this.handleCatch);
  };

  public convertLicense = () => {
    const commericalModelTypeId = _.get(this.state, "commercialModelId");
    const licenseData: IEditLicenseModel = {
      license_id: this.props.payload.licenseId,
      license: {
        number_of_users: _.get(this.state, "editLicense.number_of_users", 0),
        default_token_duration: _.get(
          this.state,
          "editLicense.default_token_duration",
          0
        ),
        owner: _.get(this.state, "editLicense.owner"),
        product_id: _.get(this.state, "editLicense.product_id"),
        license_type_id: _.get(this.state, "licenseTypeId"),
        owner_id: _.get(this.state, "editLicense.owner.owner_id"),
        owner_type: _.get(this.state, "editLicense.owner.owner_type"),
        grace_period: _.get(this.state, "editLicense.grace_period", 1),
        commercial_model_id: btoa(`CommercialModel-${commericalModelTypeId}`),
        is_vendor: _.get(this.state, "editLicense.is_vendor")
      },
      term: {
        start_date: _.get(this.state, "termStartDate", dayjs())?.toISOString(),
        end_date: _.get(this.state, "termEndDate", dayjs())?.toISOString(),
        value: _.get(this.state, "editLicense.term_value", 0), // this should be populated based on user role, value is for vendor
        reseller_value: null
      },
      entitlement: {}
    };
    if (!this.checkIsLeaseCommercialModel()) {
      const {
        user_count: userCount,
        session_count: sessionCount,
        instance_count: instanceCount
      } = this.state.editLicense;

      const seat_count = this.getSeatCountFromEnforcementModel({
        userCount,
        sessionCount,
        instanceCount
      });
      if (seat_count) {
        licenseData["entitlement"] = {
          seat_count
        };
      }
    }
    const commentEnabled = _.get(this.state, "isCommentEnabled", false);
    if (commentEnabled) {
      licenseData["comment"] = _.get(this.state, "comment", "");
    }

    this.gateway.request
      .modify_license(licenseData)
      .then(this.handleDataResponse)
      .catch(this.handleCatch);
  };

  public editLicense = () => {
    const activeTab = this.state.activeTabTitle;
    switch (activeTab) {
      case tabName.renewTerm:
      case tabName.extendTerm:
        this.createTerm();
        return;
      case tabName.addSeats:
      case tabName.changeSeats:
        this.changeSeats();
        return;
      case tabName.restrictions:
        this.editRestrictions();
        return;
      case tabName.convert:
        this.convertLicense();
        return;
      default:
        //no corresponding active tab
        return;
    }
  };

  public isUpdateDisabled = () => {
    return this.state.loading;
  };

  public updateActiveMainTab = (
    activeTabMainIndex: number,
    activeTabTitle: string
  ) => {
    this.setState({
      activeTabMainIndex,
      activeTabTitle,
      comment: null,
      isCommentEnabled: false
    });
  };

  public getLeaseTypeUiRestrictions = () => {
    const isLeaseType = this.checkIsLeaseCommercialModel();
    if (isLeaseType) {
      const hasActiveEntitlements = this.getActiveEntitlements()?.length;
      return !!hasActiveEntitlements;
    }
    return false;
  };

  public isExpired = () => {
    const timestamp = _.get(this.license, "latestTerm.endDate");
    const commercialModel = _.get(this.license, "commercialModel.name");
    let isExpired = true;
    if (timestamp) {
      const termDetails = getTermDetails(
        _.get(this.license, "latestTerm.endDate"),
        { commercialModel }
      );
      isExpired = termDetails.isExpired;
    }
    return isExpired;
  };

  public getExpiredLicenseTermStart = () => {
    const timestamp = _.get(this.license, "latestTerm.endDate");
    const today = dayjs();
    const diff = today.diff(timestamp, "month");
    if (diff) {
      return today;
    }
    return dayjs(timestamp);
  };

  public renderTermStartEndFields = () => {
    const handleChange = (type: "termStartDate" | "termEndDate") => (
      p: Dayjs
    ) => {
      const newState = { ...this.state };
      newState[type] = p;
      newState[`${type}Humanized`] = p.format(DATE_FORMAT);
      this.setState(newState, () => {
        // const { termStartDate, termEndDate } = {...this.state};
        // if (dayjs.isDayjs(termStartDate) && dayjs.isDayjs(termEndDate)) {
        //   const diff = termStartDate.diff(termEndDate);
        //   if (diff > 0) {
        //     this.setState({ termEndDate: termStartDate });
        //   }
        // }
      });
    };

    /*
     for a lease license, we cannot allow the user to create a term that overlaps with the existing term. 
    */
    let isTermStartDisabled = !this.isExpired();
    // this.getLeaseTypeUiRestrictions() &&
    // [tabName.renewTerm, tabName.extendTerm].includes(
    //   this.state.activeTabTitle as tabName
    // );
    this.getExpiredLicenseTermStart();
    const endOfCurrentTerm = this.isExpired()
      ? this.getExpiredLicenseTermStart().format(DATE_FORMAT)
      : ((this.getEntitlementProps(this.state.editLicense.entitlement_id)
          ?.latestTerm?.endDate || this.state.termEndDate) as string);

    return (
      <Flex marginBottom="23px">
        <ElmSelectWithLabel
          label="Term start"
          onClick={() => this.setState({ isTermStartPickerOpen: true })}
          onBlur={() => this.setState({ isTermStartPickerOpen: false })}
          open={this.state.isTermStartPickerOpen}
          value={
            isTermStartDisabled
              ? dayjs(endOfCurrentTerm).format(DATE_FORMAT)
              : _.get(this.state, "termStartDateHumanized", null)
          }
          style={{ width: "141px", marginRight: "9px" }}
          disabled={isTermStartDisabled}
          placeholder={"End of current term"}
          note={
            isTermStartDisabled
              ? "Active licenses will renew at the end of current term"
              : null
          }
          onChange={date => {
            if (date === "Custom") {
              this.setState({
                termStartDateHumanized: date
              });
              return;
            }
            const sDate: Dayjs = termStart[date];
            this.setState({
              termStartDate: sDate,
              termStartDateHumanized: date
            });
          }}
        >
          {_.map(Object.keys(termStart), item => {
            return (
              <ElmSelectOption key={item} value={item} label={item}>
                <Tooltip title="">{item}</Tooltip>
              </ElmSelectOption>
            );
          })}
          <ElmSelectOption
            key={"custom"}
            value={"Custom"}
            // label={
            //   dayjs.isDayjs(this.state.termStartDate)
            //     ? this.state.termStartDate.format("MM.DD.YYYY")
            //     : "Custom"
            // }
            label={"Custom"}
          >
            <ElmDatePicker
              format={DATE_FORMAT}
              onChange={handleChange("termStartDate")}
              // @ts-ignore
              //value={this.state?.termStart ? moment(this.state?.termStart).format("DD.MM.YYYY") : null}
              value={this.state.termStartDate ? this.state.termStartDate : null}
            />
          </ElmSelectOption>
        </ElmSelectWithLabel>
        <ElmSelectWithLabel
          label="Term end"
          style={{ width: "141px", marginRight: "9px" }}
          onClick={() => this.setState({ isTermEndPickerOpen: true })}
          onBlur={() => this.setState({ isTermEndPickerOpen: false })}
          open={this.state.isTermEndPickerOpen}
          value={_.get(this.state, "termEndDateHumanized", null)}
          onChange={durationInMonths => {
            if (durationInMonths === "Custom") {
              // this.setState({
              //   termEndDateHumanized: _.get(this.state, "termEndDate").format("MMM DD, YYYY"), //durationInYears,
              // });
              return;
            }
            const eDate = calculateTermEnd(
              durationInMonths,
              this.state.termStartDate
                ? dayjs(this.state.termStartDate)
                : dayjs()
            );
            this.setState({
              termEndDate: eDate,
              termEndDateRangeMonths: durationInMonths,
              termEndDateHumanized: durationInMonths
            });
          }}
        >
          {this.state.commercialModel === commercialModel.freeTrial
            ? _.map(Object.keys(termEndFreeTrial), item => {
                return (
                  <ElmSelectOption
                    key={item}
                    value={termEndFreeTrial[item]}
                    label={item}
                  >
                    <Tooltip title="">{item}</Tooltip>
                  </ElmSelectOption>
                );
              })
            : _.map(Object.keys(termEnd), item => {
                return (
                  <ElmSelectOption
                    key={item}
                    value={termEnd[item]}
                    label={item}
                  >
                    <Tooltip title="">{item}</Tooltip>
                  </ElmSelectOption>
                );
              })}
          <ElmSelectOption
            key={"custom"}
            value={"Custom"}
            // label={
            //   dayjs.isDayjs(this.state.termEndDate)
            //     ? this.state.termEndDate.format("MM.DD.YYYY")
            //     : "Custom"
            // }
            label={"Custom"}
          >
            <ElmDatePicker
              onChange={handleChange("termEndDate")}
              format={DATE_FORMAT}
              // @ts-ignore
              value={this.state?.termEndDate ? this.state?.termEndDate : null}
              //disabledDate={d => d.isBefore(this.state.termStartDate.toDate())}
            />
          </ElmSelectOption>
        </ElmSelectWithLabel>
      </Flex>
    );
  };

  public renderTermValueField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("term_value")}
        defaultValue={_.get(this.state, "editLicense.term_value", null)}
        style={{ marginBottom: "23px", width: "290px" }}
        prefix="$"
        label={
          this.state.commercialModel !== commercialModel.perpetualMS
            ? "Term value"
            : "Maintenance term value"
        }
        type={"number"}
        isDecimalNumber={true}
        placeholder={"Enter amount"}
      />
    );
  };

  public getTotalNumberOfSeats = () => {
    const enforcementM = licenseTypeToName(this.license.type.id);
    const nodes = _.get(this.license, "entitlements.nodes", []);

    if (enforcementM === enforcementModel.concurrent) {
      return _.reduce(nodes, (sum, item) => sum + item.sessionCount, 0);
    }
    if (enforcementM === enforcementModel.namedUser) {
      return _.reduce(nodes, (sum, item) => sum + item.userCount, 0);
    }
    if (enforcementM === enforcementModel.nodeLocked) {
      return _.reduce(nodes, (sum, item) => sum + item.instanceCount, 0);
    }

    return 0;
  };

  public renderNumberOfSeatsField = () => {
    const currentNumberOfSeats = _.get(this.license, "totalSeatCount"); //this.getTotalNumberOfSeats();

    //add check for reducing number of seats and show the warning messag
    return (
      <ElmInput
        updatedValue={value => {
          this.updateIntValueOfRequest("current_seats")(value);
          if (this.state.enforcementModel === "Named User") {
            // add node locked in condition
            if (+value < currentNumberOfSeats)
              this.setState({ error: { seatCountWarning: true } });
            if (+value > currentNumberOfSeats)
              this.setState({ error: { seatCountWarning: false } });
          }
        }}
        //defaultValue={_.get(this.state, "editLicense.currentSeats", null)}
        //note="Number of seats: N/A"
        type="number"
        style={{ marginBottom: "23px", width: "290px" }}
        label={"Number of seats"}
        note={`Current seats: ${currentNumberOfSeats}`}
        error={_.get(this.state, "error.seatCountWarning")}
        errorMessage={`By reducing number of seats you are responsible for disabling users and bringing the active users count within the new limit. Current seats: ${currentNumberOfSeats}`}
        placeholder={"Enter seats count"}
      />
    );
  };

  public renderCommentField = () => {
    let commentEnabled = _.get(this.state, "isCommentEnabled");
    return (
      <Flex flexDirection="column">
        <Flex justifyContent="flex-end">
          {!commentEnabled && (
            <ElmButton
              onClick={() =>
                this.setState({ isCommentEnabled: !commentEnabled })
              }
              className="subtle"
              colorVariance="subtle"
              label="Add comment"
              icon="plus"
              variance="plain-icon-button"
              iconPrefix="fas"
              style={{}}
            />
          )}
        </Flex>

        {commentEnabled && (
          <TextArea
            showCount
            //disabled
            rows={6}
            maxLength={250}
            allowClear={true}
            onBlur={e => {
              this.setState({ comment: e.currentTarget.textContent });
            }}
            //maxLength={100}
            //value={_.get(this.props.result, "release.data")}
            //style={{ height: 120, width: "100%", resize: "none" }}
            //onChange={onChange}
            placeholder="Enter comment..."
          />
        )}
      </Flex>
    );
  };

  public renderLicenseValue = () => {
    return (
      <ElmInput
        style={{ width: "300px" }}
        updatedValue={this.updateIntValueOfRequest("license_value")}
        defaultValue={_.get(this.state, "editLicense.license_value")}
        prefix={"$"}
        type="number"
        isDecimalNumber={true}
        label={"License value"}
        placeholder={"Enter amount"}
      />
    );
  };

  public renderUsersField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("user_count")}
        defaultValue={_.get(this.state, "editLicense.user_count")}
        style={{ marginBottom: "23px" }}
        label={"Users"}
        type="number"
        placeholder={"Enter user count"}
      />
    );
  };

  public renderInstancesPerUserField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("instances_per_user")}
        defaultValue={_.get(this.state, "editLicense.instances_per_user", null)}
        type="number"
        label={"Instances per user"}
        placeholder={"Enter value"}
      />
    );
  };

  public renderGracePeriodFields = () => {
    return (
      <Flex>
        <ElmInput
          updatedValue={this.updateIntValueOfRequest("grace_period")}
          defaultValue={_.get(this.state, "editLicense.grace_period")}
          label={"Grace period (days)"}
          type="number"
          placeholder={"Enter value"}
        />
      </Flex>
    );
  };

  public renderNumberOfSessionField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("session_count")}
        style={{ marginBottom: "23px" }}
        defaultValue={_.get(
          { ...this.state.editLicense },
          "session_count",
          null
        )}
        key="session_count"
        type="number"
        label={"Number of sessions"}
        placeholder={"Enter sessions count"}
      />
    );
  };

  public renderDefaultSessionLengthField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("default_token_duration")}
        defaultValue={_.get(
          this.state,
          "editLicense.default_token_duration",
          0
        )}
        style={{ marginBottom: "23px" }}
        type="number"
        label={"Default session length (sec)"}
        placeholder={"Enter session length value"}
      />
    );
  };

  public renderNumberOfInstallsField = () => {
    return (
      <ElmInput
        key="number_of_installs"
        updatedValue={this.updateIntValueOfRequest("number_of_installs")}
        defaultValue={_.get(this.state.editLicense, "number_of_installs", null)}
        style={{ marginBottom: "23px" }}
        type="number"
        label={"Number of installs"}
        placeholder={"Enter installs count"}
      />
    );
  };

  public renderNumberOfUsersRestrictionField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("number_of_users")}
        defaultValue={_.get(this.state.editLicense, "number_of_users", null)}
        style={{ marginBottom: "23px" }}
        type="number"
        label={"Number of Users"}
        placeholder={"Enter value"}
      />
    );
  };

  public renderNumberOfInstallsRestrictionField = () => {
    return (
      <ElmInput
        updatedValue={this.updateIntValueOfRequest("number_of_installs")}
        style={{ marginBottom: "23px" }}
        defaultValue={_.get(this.state.editLicense, "number_of_installs", null)}
        type="number"
        label={"Number of Installs"}
        placeholder={"Enter value"}
      />
    );
  };
  public renderEnforceEmailActivationField = () => {
    const onEmailActivation = (e: CheckboxChangeEvent) => {
      this.setState({
        editLicense: {
          ...this.state.editLicense,
          require_email_activation: e.target.checked
        }
      });
    };
    return (
      <ElmCheckBox
        onChange={onEmailActivation}
        defaultChecked={_.get(
          this.state,
          "editLicense.require_email_activation",
          false
        )}
      >
        Enforce email activation
      </ElmCheckBox>
    );
  };
  public renderVendorCheckbox = () => {
    const onChange = (e: CheckboxChangeEvent) => {
      this.setState({
        editLicense: {
          ...this.state.editLicense,
          is_vendor: e.target.checked
        }
      });
    };
    return (
      <ElmCheckBox
        onChange={onChange}
        defaultChecked={_.get(this.state, "editLicense.is_vendor", false)}
      >
        Vendor
      </ElmCheckBox>
    );
  };
  public renderChangeSeatsCheckbox = () => {
    const onChangeSeatsCheckbox = (e: CheckboxChangeEvent) => {
      this.setState({
        ...this.state,
        isChangeSeatsCheckboxEnabled: e.target.checked
      });
    };
    const isChangeSeatAllowed = _.get(
      this.state,
      "isChangeSeatsCheckboxEnabled",
      false
    );
    const hasPerpetualUiRestricitons = this.checkIsPerpetualCommercialModel();
    return (
      <Flex flexDirection="column">
        <ElmCheckBox
          onChange={onChangeSeatsCheckbox}
          defaultChecked={isChangeSeatAllowed}
        >
          {hasPerpetualUiRestricitons ? "Reduce Seats" : "Change Seats"}
        </ElmCheckBox>
        {/* {isChangeSeatAllowed && this.renderNumberOfSeatsField()} */}
      </Flex>
    );
  };

  public renderChangeSeatsAdvanced = () => {
    return (
      <Flex flexDirection="column">
        {this.renderChangeSeatsCheckbox()}
        {this.state.isChangeSeatsCheckboxEnabled &&
          this.renderNumberOfSeatsField()}
      </Flex>
    );
  };

  public renderAddTermCheckbox = () => {
    const onChange = (e: CheckboxChangeEvent) => {
      this.setState({
        ...this.state,
        isAddTermCheckboxEnabled: e.target.checked
      });
    };
    return (
      <ElmCheckBox
        onChange={onChange}
        defaultChecked={_.get(this.state, "isAddTermCheckboxEnabled", false)}
      >
        Add term
      </ElmCheckBox>
    );
  };

  public renderAdvancedTerm = () => {
    return (
      <Flex flexDirection="column">
        {this.renderAddTermCheckbox()}
        {this.state.isAddTermCheckboxEnabled && (
          <Flex flexDirection="column">
            {this.renderTermStartEndFields()}
            {this.renderTermValueField()}
          </Flex>
        )}
      </Flex>
    );
  };

  public renderSessionsPerUserField = () => {
    return (
      <ElmInput
        type="number"
        style={{ marginBottom: "23px" }}
        updatedValue={this.updateIntValueOfRequest("sessions_per_user")}
        defaultValue={_.get(this.state, "editLicense.sessions_per_user")}
        label={"Sessions per user"}
        placeholder={"Enter value"}
      />
    );
  };

  public renderSessionsPerInstallField = () => {
    return (
      <ElmInput
        type="number"
        style={{ marginBottom: "23px" }}
        updatedValue={this.updateIntValueOfRequest("sessions_per_instance")}
        defaultValue={_.get(this.state, "editLicense.sessions_per_instance")}
        label={"Sessions per install"}
        placeholder={"Enter value"}
      />
    );
  };

  public renderUsersPerInstallField = () => {
    return (
      <ElmInput
        style={{ marginBottom: "23px" }}
        updatedValue={this.updateIntValueOfRequest("users_per_instance")}
        defaultValue={_.get(this.state, "editLicense.users_per_instance")}
        label={"Users per install"}
        placeholder={"Enter value"}
        type={"number"}
      />
    );
  };

  public renderSessionsPerUserPerInstallField = () => {
    return (
      <ElmInput
        type="number"
        style={{ marginBottom: "23px" }}
        updatedValue={this.updateIntValueOfRequest(
          "sessions_per_user_per_instance"
        )}
        defaultValue={_.get(
          this.state,
          "editLicense.sessions_per_user_per_instance"
        )}
        label={"Sessions per user per install"}
        placeholder={"Enter value"}
      />
    );
  };
  public renderMinimumAssignmentLengthField = () => {
    return (
      <ElmInput
        type={"number"}
        style={{ marginBottom: "23px", marginTop: "23px" }}
        updatedValue={this.updateIntValueOfRequest("minimum_assignment_length")}
        defaultValue={_.get(this.state, "newLicense.minimum_assignment_length")}
        label={"Minimum assignment length"}
        placeholder={"Enter value"}
      />
    );
  };
  public generateFieldsForEnforcement = () => {
    const {
      commercialModel: commercial,
      enforcementModel: enforcement
    } = this.state;

    /// commercialmodel = PerpetualMS
    if (
      commercial === commercialModel.perpetualMS &&
      enforcement === enforcementModel.namedUser
    ) {
      return [this.renderUsersField, this.renderGracePeriodFields];
    }
    if (
      commercial === commercialModel.perpetualMS &&
      enforcement === enforcementModel.concurrent
    ) {
      return [
        this.renderNumberOfSessionField,
        this.renderDefaultSessionLengthField
      ];
    }
    if (
      commercial === commercialModel.perpetualMS &&
      enforcement === enforcementModel.nodeLocked
    ) {
      return [this.renderNumberOfInstallsField, this.renderGracePeriodFields];
    }
    if (
      commercial === commercialModel.perpetualMS &&
      enforcement === enforcementModel.unrestricted
    ) {
      return [this.renderGracePeriodFields];
    }
    /// commercialModel = SaaS || Lease
    if (
      (commercial === commercialModel.saas ||
        commercial === commercialModel.lease) &&
      enforcement === enforcementModel.namedUser
    ) {
      return [this.renderUsersField];
    }
    if (
      commercial === commercialModel.saas &&
      enforcement === enforcementModel.concurrent
    ) {
      return [
        this.renderNumberOfSessionField,
        this.renderDefaultSessionLengthField
      ];
    }
    if (
      commercial === commercialModel.lease &&
      enforcement === enforcementModel.concurrent
    ) {
      return [
        this.renderNumberOfSessionField,
        this.renderDefaultSessionLengthField
      ];
    }
    if (
      (commercial === commercialModel.saas ||
        commercial === commercialModel.lease) &&
      enforcement === enforcementModel.unrestricted
    ) {
      return [this.renderGracePeriodFields];
    }

    /// commercialModel = free trial
    if (
      (commercial === commercialModel.freeTrial || !commercial) &&
      enforcement === enforcementModel.namedUser
    ) {
      return [this.renderUsersField, this.renderGracePeriodFields];
    }
    if (
      (commercial === commercialModel.freeTrial || !commercial) &&
      enforcement === enforcementModel.concurrent
    ) {
      return [
        this.renderNumberOfSessionField,
        this.renderDefaultSessionLengthField
      ];
    }
    if (
      (commercial === commercialModel.freeTrial || !commercial) &&
      enforcement === enforcementModel.nodeLocked
    ) {
      return [this.renderNumberOfInstallsField, this.renderGracePeriodFields];
    }
    if (
      (commercial === commercialModel.freeTrial || !commercial) &&
      enforcement === enforcementModel.unrestricted
    ) {
      return [this.renderGracePeriodFields];
    }

    /// commercialModel = Freeware
    if (
      commercial === commercialModel.freeware &&
      enforcement === enforcementModel.unrestricted
    ) {
      return [this.renderGracePeriodFields];
    }
  };

  public generateFieldsForTerm = () => {
    const commercial = this.state.commercialModel;

    if (commercial === commercialModel.perpetualMS) {
      return [
        this.renderTermStartEndFields,
        this.renderTermValueField,
        this.renderChangeSeatsAdvanced
      ];
    }

    if (commercial === commercialModel.lease) {
      return [
        this.renderLeaseActiveEntitlementSelection,
        this.renderTermStartEndFields,
        this.renderTermValueField,
        this.renderChangeSeatsAdvanced
      ];
    }

    if (commercial === commercialModel.saas) {
      return [
        this.renderTermStartEndFields,
        this.renderTermValueField,
        this.renderChangeSeatsAdvanced
      ];
    }
  };

  public generateFieldsForSeats = () => {
    const commercial = this.state.commercialModel;

    if (commercial === commercialModel.perpetualMS) {
      return [this.renderNumberOfSeatsField, this.renderTermValueField];
    }

    if (commercial === commercialModel.lease) {
      return [
        this.renderNumberOfSeatsField,
        this.renderTermValueField,
        this.renderAdvancedTerm
      ];
    }

    if (commercial === commercialModel.freeTrial || !commercial) {
      return [this.renderNumberOfSeatsField];
    }

    if (commercial === commercialModel.saas) {
      return [this.renderNumberOfSeatsField];
    }
  };

  public generateFieldsForRestrictions = () => {
    const enforcement = this.state.enforcementModel;
    if (enforcement === enforcementModel.unrestricted) {
      return [this.renderEnforceEmailActivationField];
    }
    if (enforcement === enforcementModel.namedUser) {
      return [
        this.renderInstancesPerUserField,
        this.renderMinimumAssignmentLengthField,
        this.renderEnforceEmailActivationField
      ];
    }
    if (enforcement === enforcementModel.nodeLocked) {
      return [
        this.renderUsersPerInstallField,
        this.renderSessionsPerUserField,
        this.renderSessionsPerInstallField,
        this.renderSessionsPerUserPerInstallField,
        this.renderEnforceEmailActivationField
      ];
    }
    if (enforcement === enforcementModel.concurrent) {
      return [
        this.renderSessionsPerUserField,
        this.renderSessionsPerInstallField,
        this.renderSessionsPerUserPerInstallField,
        this.renderNumberOfUsersRestrictionField,
        this.renderNumberOfInstallsRestrictionField,
        this.renderEnforceEmailActivationField
      ];
    }
    return [];
  };

  public renderAddTerm = () => {
    const fields = _.map(this.generateFieldsForTerm(), field => field());
    return (
      <div>
        {fields}
        {fields?.length > 0 ? this.renderCommentField() : null}
      </div>
    );
  };

  public renderChangeSeats = () => {
    const fields = _.map(this.generateFieldsForSeats(), field => field());
    return (
      <div>
        {fields}
        {fields?.length > 0 ? this.renderCommentField() : null}
      </div>
    );
  };

  public renderCommercialModelFields = () => {
    return (
      <Flex flexDirection="column">
        {this.state.commercialModel === commercialModel.perpetualMS
          ? this.renderTermStartEndFields()
          : null}
        {this.state.commercialModel === commercialModel.perpetualMS ? (
          <>
            {this.renderLicenseValue()}
            <div style={{ marginTop: "23px" }}>
              {this.renderTermValueField()}
            </div>
          </>
        ) : null}

        {(this.state.commercialModel === commercialModel.lease ||
          this.state.commercialModel === commercialModel.saas) &&
        this.state.enforcementModel !== enforcementModel.nodeLocked
          ? this.renderTermStartEndFields()
          : null}

        {this.state.commercialModel === commercialModel.lease ||
        this.state.commercialModel === commercialModel.saas
          ? this.renderTermValueField()
          : null}

        {this.state.commercialModel === commercialModel.freeTrial ||
        !this.state.commercialModel
          ? this.renderTermStartEndFields()
          : null}
      </Flex>
    );
  };

  public getConvertToOptions = () => {
    //const cM = this.state.commercialModel;
    const cM = _.get(this.license, "commercialModel.name", null);
    if (
      cM === commercialModel.perpetualMS ||
      cM === commercialModel.lease ||
      cM === commercialModel.saas
    ) {
      return _.omit(commercialModel, "freeTrial", "freeware");
    }
    if (cM === commercialModel.freeTrial) {
      return _.omit(commercialModel, "freeware");
    }
    if (cM === commercialModel.freeware) {
      return _.pick(commercialModel, "freeware");
    }

    return commercialModel;
  };

  public renderConvert = () => {
    return (
      <Flex flexDirection="column">
        <Flex>{this.renderSectionHeader("Commercial information")}</Flex>
        <Flex style={{ width: "300px", marginBottom: "23px" }}>
          <ElmSelectWithLabel
            label="Commercial model"
            defaultValue={_.get(this.state, "commercialModel", null)}
            // status="error"
            // errorMessage="test"
            // _.get(
            //   this.license,
            //   "commercialModel.name",
            //   commercialModel.freeTrial
            // )
            value={_.get(this.state, "commercialModel", null)}
            onChange={model => {
              const modelTypeId = commercialModelType[model];
              this.setState({
                commercialModel: commercialModel[model],
                commercialModelId: +modelTypeId,
                isModelChanged: true
              });
            }}
          >
            {_.map(Object.keys(this.getConvertToOptions()), item => {
              return (
                <ElmSelectOption
                  key={item}
                  value={item}
                  label={commercialModel[item]}
                >
                  {commercialModel[item]}
                </ElmSelectOption>
              );
            })}
          </ElmSelectWithLabel>
        </Flex>
        {this.renderVendorCheckbox()}
        <Flex>
          {this.state.isModelChanged && this.renderCommercialModelFields()}
        </Flex>
        <Flex flexDirection="column">
          <Flex flex={1} flexDirection="column">
            {this.renderSectionHeader("Enforcement information")}
            <div>
              <LicenseEnforcementSelector
                className="subtle"
                product={this.state.product}
                value={licenseTypeToName(
                  _.get(this.state, "licenseTypeId", null)
                )}
                selectedEnfocrmentModelId={_.get(
                  this.props,
                  "payload.licenseTypeId",
                  null
                )}
                useConversionRestriction={true}
                // licenseTypeToName(
                //   licenseTypeId
                // ) as unknown) as enforcementModel
                //defaultValue={null}
                updateLicenseType={this.updateLicenseType}
                label="Licensing Model"
                placeholder="Select model"
                style={{ marginBottom: "26px" }}
              />
              <div>
                {// this.state.isModelChanged &&
                _.map(this.generateFieldsForEnforcement(), field => field())}
              </div>
            </div>
          </Flex>
          {this.state.enforcementModel && this.state.isModelChanged && (
            <Flex flex={1} flexDirection="column">
              {this.renderSectionHeader("Restrictions")}
              {/* <Flex>{this.renderEnforceEmailActivationField()}</Flex>*/}

              {_.map(this.generateFieldsForRestrictions(), field => field())}

              {/* {_.map(this.generateFieldsForRestrictions(), field => field())} */}
            </Flex>
          )}
          {// this.state.isModelChanged &&
          this.renderCommentField()}
        </Flex>
      </Flex>
    );
  };

  public renderSectionHeader = (sectionName: string) => {
    return <SectionHeader>{sectionName}</SectionHeader>;
  };

  public renderRestrictions = () => {
    const fields = _.map(this.generateFieldsForRestrictions(), field =>
      field()
    );
    return (
      <Flex flexDirection="column" flex={1}>
        <div>
          {fields}
          {fields?.length > 0 ? this.renderCommentField() : null}
        </div>
      </Flex>
    );
  };

  public renderEntitlements = () => {
    const ent = this.getActiveEntitlements();
    if (!ent) {
      return null;
    }
    const renderDuration = (payload: TableCellProps) => {
      if (!payload?.rowData) {
        return null;
      }
      const term = payload.rowData.latestTerm;
      const { userCount, sessionCount, instanceCount } = payload.rowData;
      const seats = this.getSeatCountFromEnforcementModel({
        userCount,
        sessionCount,
        instanceCount
      });

      return (
        <Flex>
          {dayjs(term.startDate).format(DATE_FORMAT)} -{" "}
          {dayjs(term.endDate).format(DATE_FORMAT)}
          <StyledLabelSecondary>{seats} Seats</StyledLabelSecondary>
        </Flex>
      );
    };
    // const renderAction = (payload: any) => {
    //   return (
    //     <Tooltip title="Terminate Entitlement">
    //       <ElmButton
    //         label=""
    //         variance="icon-button"
    //         colorVariance="subtle"
    //         className="darkModeTransparentBtn"
    //         icon="ban"
    //         iconPrefix="fas"
    //       />
    //     </Tooltip>
    //   );
    // };
    return (
      <ElmTable
        columns={[
          {
            Header: "Duration",
            accessor: "",
            sortKey: null,
            disableSortBy: true,
            cellRenderer: payload => renderDuration(payload)
          }
          // {
          //   Header: "Actions",
          //   accessor: "",
          //   disableSortBy: true,
          //   sortKey: null,
          //   width: 0.14,
          //   cellRenderer: () => null,
          // },
        ]}
        // renderRowActionButtons={(rowData) => renderAction(rowData)}
        data={ent}
        hideSearchBar
        hideFooter
      />
    );
  };

  public renderExtendTerm = () => {
    return (
      <Flex flexDirection="column">
        {this.renderTermStartEndFields()}
        {this.renderCommentField()}
      </Flex>
    );
  };

  public renderComponentsTab = () => {
    const commercialM = _.get(this.license, "commercialModel.name", null);

    const tabStyle = {
      paddingLeft: "12px",
      paddingRight: "12px",
      fontSize: "14px"
    };

    const freeTrialViews = [
      {
        title: tabName.extendTerm,
        Component: this.renderExtendTerm(),
        style: tabStyle
      },
      {
        title: tabName.changeSeats,
        Component: this.renderChangeSeats(),
        style: tabStyle
      },
      {
        title: tabName.convert,
        Component: this.renderConvert(),
        style: tabStyle
      },
      {
        title: tabName.restrictions,
        Component: this.renderRestrictions(),
        style: tabStyle
      }
    ] as ITab[];

    const freewareViews: ITab[] = [
      {
        title: tabName.restrictions,
        Component: this.renderRestrictions(),
        style: tabStyle
      }
    ];

    let views = [
      {
        title: tabName.renewTerm,
        // commercialM === commercialModel.lease ||
        // commercialM === commercialModel.saas
        //   ? "Renew term"
        //   : "Add term",
        Component: this.renderAddTerm(),
        style: tabStyle
      },
      {
        title:
          commercialM === commercialModel.saas
            ? tabName.changeSeats
            : tabName.addSeats,
        Component: this.renderChangeSeats(),
        style: tabStyle
      },
      {
        title: tabName.convert,
        Component: this.renderConvert(),
        style: tabStyle
      },
      {
        title: tabName.restrictions,
        Component: this.renderRestrictions(),
        style: tabStyle
      }
    ] as ITab[];
    // return <ToggleView views={views} />;

    if (commercialM === commercialModel.lease) {
      const hasActiveEntitlemens = this.getActiveEntitlements()?.length;
      hasActiveEntitlemens &&
        views.push({
          title: tabName.entitlements,
          Component: this.renderEntitlements(),
          style: tabStyle
        });
    }
    const applyLeaseUiRestrictions =
      this.checkIsLeaseCommercialModel() && !this.getLeaseTypeUiRestrictions();

    if (applyLeaseUiRestrictions) {
      views = views.filter(
        item =>
          item.title === tabName.renewTerm || item.title === tabName.convert
      );
    }

    return (
      <TabView
        //ref={this.tabSwitch}
        showBottomBorder={true}
        leftAlign={true}
        tabList={
          /*
            Free trials and classic licenses (those with no commercial_model_id set) should use freeTrialView
            Freeware licenses only have restrictions as editable options.
          */
          commercialM === commercialModel.freeTrial || !commercialM
            ? freeTrialViews
            : commercialM === commercialModel.freeware
            ? freewareViews
            : views
        }
        onUpdateActiveTab={this.updateActiveMainTab}
        orientation={"vertical"}
        style={{
          marginTop: "24px"
        }}
      />
    );
  };

  public render() {
    return (
      <DialogContainer
        className={"edit-license-dialog"}
        style={{ height: 571 }}
      >
        <DialogTitle>{this.getTitle()}</DialogTitle>
        {/* <FieldsContainer>{this.renderLicenseInfo()}</FieldsContainer> */}
        <Flex flex={1} style={{ overflowY: "scroll" }}>
          {this.renderComponentsTab()}
        </Flex>
        <Flex justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            label={"Save"}
            disabled={this.isUpdateDisabled()}
            colorVariance="primary"
            onClick={this.editLicense}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
export const DataContainer = (props: IModalProps<IEditLicenseDialogProps>) => {
  const renderEditLicense = (payload: { props: editLicenseQueryResponse }) => {
    if (!payload.props) {
      return (
        <DialogContainer style={{ justifyContent: "space-around" }}>
          {LoaderContainer()}
        </DialogContainer>
      );
    }
    return <EditLicenseDialog {...props} result={payload.props} />;
  };
  return (
    <DialogContainer>
      <QueryRenderer<editLicenseQuery>
        environment={relayEnvironment}
        variables={{
          id: _.get(props, "payload.licenseId")
        }}
        query={graphqlQuery}
        render={renderEditLicense}
      />
    </DialogContainer>
  );
};
export default DataContainer;
