import { notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import styled from "styled-components";
import { createFragmentContainer } from "react-relay";
import {
  AttributesBox,
  IAttributesBoxProps,
  AttributeLabel
} from "../../../components/attributesBox";
import { productAttributeAdvanced_product } from "./__generated__/productAttributeAdvanced_product.graphql";
import { Flex } from "reflexbox/styled-components";
import { getIcon } from "../../../components/icons";

export interface IProductAttributeAdvancedProps {
  product: productAttributeAdvanced_product;
  mode: IAttributesBoxProps["mode"];
}

const PublicKeyContainer = styled.div`
  width: 100%;
  height: 69px;
  border: solid 1px ${props => props.theme.colors.black};
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  flex-wrap: wrap;
  overflow-x: hidden;
`;
const PublicKeyText = styled.p`
  height: 62px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  margin: 0px;
`;
const GuidText = styled.p`
  width: 284px;
  height: 15px;
  font-family: Inter;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  margin: 0px;
  color: black;
`;
const CopyContainer = styled(Flex)`
  align-items: center;
  padding: 2px;
  background-color: ${props => props.theme.colors.lightishBlue};
  color: ${props => props.theme.colors.white};
  margin-top: 5px;
  border-radius: 3px;
  width: 89px;
  height: 18px;
  :hover {
    cursor: pointer;
  }

  span {
    height: 15px;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: right;
  }
`;
const ClipboardIcon = styled(getIcon("FaClipboard"))`
  color: ${props => props.theme.colors.white};
`;
export class ProductAttributeAdvanced extends React.Component<
  IProductAttributeAdvancedProps
> {
  public openCopiedMessage = () => {
    notification.open({
      message: "Success",
      description: "Key copied to clipboard"
    });
  };
  public getItems = () => {
    if (!_.isObject(this.props.product)) {
      return [];
    }
    return [
      {
        label: this.props.product.publicKey ? (
          <Flex
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <AttributeLabel>Public Key</AttributeLabel>
            <CopyToClipboard
              text={this.props.product.publicKey}
              onCopy={this.openCopiedMessage}
            >
              <CopyContainer>
                <ClipboardIcon />
                <span>To clipboard</span>
              </CopyContainer>
            </CopyToClipboard>
          </Flex>
        ) : null,
        value: (
          <PublicKeyContainer>
            <PublicKeyText
              style={{
                marginTop: this.props.product.publicKey ? "1px" : "28px"
              }}
            >
              {this.props.product.publicKey ||
                "No public key available for this product."}
            </PublicKeyText>
          </PublicKeyContainer>
        )
      },
      {
        label: "Guid",
        value: <GuidText>{this.props.product.guid}</GuidText>
      }
    ];
  };
  public render() {
    return (
      <AttributesBox
        title={"Advanced"}
        items={this.getItems()}
        mode={"normal"}
      />
    );
  }
}

export default createFragmentContainer(ProductAttributeAdvanced, {
  product: graphql`
    fragment productAttributeAdvanced_product on Product {
      publicKey
      guid
    }
  `
});
