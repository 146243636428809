import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import React from "react";
import { userProductHistoryTable_history } from "./__generated__/userProductHistoryTable_history.graphql";
import {
  IGeneratedTableProps,
  tableGenerator
} from "../../../utils/tableGenerator";
import { TableCell } from "../../../components/elmTable";
import { Flex } from "reflexbox";
import { LoaderContainer } from "components/helpers";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "api/relay";
import {
  userProductHistoryTableQuery,
  userProductHistoryTableQueryResponse
} from "./__generated__/userProductHistoryTableQuery.graphql";
import { shallowEqual } from "fast-equals";

export type userProductHistoryEdges = userProductHistoryTable_history["history"]["productUsers"]["edges"];
export type userProductHistoryTableType = userProductHistoryEdges[number]["node"];
export type IUserProductHistoryProps = {
  history: userProductHistoryTable_history;
};

const GeneratedTable = tableGenerator<IUserProductHistoryProps>({
  dataKey: "history.history.productUsers",
  getNodesTransform: props => {
    const edges = _.get(
      props,
      "history.history.productUsers.edges"
    ) as userProductHistoryTable_history["history"]["productUsers"]["edges"];
    const transform = _.reduce(
      edges,
      (historyInfo, info) => {
        _.each(info.node.modificationRecords.edges, record => {
          historyInfo.push({
            name: info.node.product.name,
            ...record.node
          });
        });
        return historyInfo;
      },
      []
    );
    return transform.toSorted(
      (a, b) => -a.createdAt.localeCompare(b.createdAt)
    );
  },
  columns: [
    {
      Header: "Product",
      accessor: "name",
      filterable: {
        filterName: "product.name",
        dataKey: "history.history.productUsers.edges",
        dataMap: payload => _.get(payload, "node.product.name")
      },
      disableSortBy: true,
      sortKey: null
    },
    {
      Header: "Activity",
      accessor: "enabled",
      disableSortBy: true,
      sortKey: null,
      cellRenderer: payload => {
        return (
          <TableCell>
            {payload.cellData === 1 ? "Enabled" : "Disabled"}
          </TableCell>
        );
      }
    },
    {
      Header: "Time",
      accessor: "createdAt",
      isDateFormat: true,
      disableSortBy: true,
      sortKey: null
    }
  ],
  connectionQuery: graphql`
    query userProductHistoryTablePaginationQuery(
      $id: ID!
      $count: Int!
      $cursor: String
      $search: String
      $filterColumn: [String!]
      $filterValues: [[String!]!]
      $filterOp: [[String!]!]
      $sortColumn: [String!]
      $sortDirection: [String!]
    ) {
      ...userProductHistoryTable_history
        @arguments(
          id: $id
          count: $count
          cursor: $cursor
          search: $search
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        )
    }
  `,
  fragmentSpec: {
    history: graphql`
      fragment userProductHistoryTable_history on Query
        @argumentDefinitions(
          id: { type: "ID!" }
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
          filterColumn: { type: "[String!]", defaultValue: null }
          filterOp: { type: "[[String!]!]", defaultValue: null }
          filterValues: { type: "[[String!]!]", defaultValue: null }
          sortColumn: { type: "[String!]", defaultValue: null }
          sortDirection: { type: "[String!]", defaultValue: null }
        ) {
        history: user(id: $id) {
          productUsers(
            first: $count
            after: $cursor
            search: $search
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            filterColumn: $filterColumn
            filterOp: $filterOp
            filterValues: $filterValues
          ) @connection(key: "userProductHistoryTable_productUsers") {
            edges {
              node {
                product {
                  name
                }
                modificationRecords {
                  edges {
                    node {
                      enabled
                      createdAt
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  },
  tableName: "userProductHistory"
});

const graphqlQuery = graphql`
  query userProductHistoryTableQuery($id: ID!) {
    ...userProductHistoryTable_history @arguments(id: $id)
  }
`;

class UserProductHistoryPaginationTable extends React.Component<
  IGeneratedTableProps & {
    result: userProductHistoryTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        history={res}
      />
    );
  }
}

export const UserProductHistoryTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: userProductHistoryTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <UserProductHistoryPaginationTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<userProductHistoryTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.bladeScope || ""
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default UserProductHistoryTable;
