import moment from "moment";
import * as _ from "lodash";
import {
  AnalyticsComponentProps,
  queryType
} from "../components/analyticsComponent";

const queryTemplate: queryType = {
  setup: {
    category: "",
    value: "",
    group: null
  },
  startTime: null,
  bladeScope: null,
  sortColumn: "value",
  sortDirection: "DESC",
  first: 5
};

export interface analyticsQueryDelegate {
  (
    bladeScope: string,
    startTime?: moment.Moment,
    allowVendorAnalytics?: boolean,
    groupBy?: string
  ): AnalyticsComponentProps;
}

export const topUsers: analyticsQueryDelegate = (
  bladeScope: string,
  startTime?: moment.Moment,
  allowVendorAnalytics?: boolean
): AnalyticsComponentProps => {
  let query = _.cloneDeep(queryTemplate);
  query.setup.category = "users.name";
  query.setup.value = "sessions.usage_count";
  query.setup.group = "products.name";
  query.valueTitle = "Session Count by User";
  query.startTime = (startTime || moment().utc()).subtract(3, "M").format();
  query.bladeScope = bladeScope;
  return {
    analytics: query,
    type: "bar",
    title: "Top users",
    description: "Top users by session count over the last 3 months",
    allowVendorAnalytics: !!allowVendorAnalytics
  };
};

export const topCompanies: analyticsQueryDelegate = (
  bladeScope: string,
  startTime?: moment.Moment,
  allowVendorAnalytics?: boolean,
  groupBy?: string
): AnalyticsComponentProps => {
  let query = _.cloneDeep(queryTemplate);
  query.setup.category = "companies.name";
  query.setup.value = "sessions.usage_count";
  if (groupBy) query.setup.group = groupBy;
  query.valueTitle = "Session Count by Company";
  query.startTime = (startTime || moment().utc()).subtract(3, "M").format();
  query.bladeScope = bladeScope;
  return {
    analytics: query,
    type: "bar",
    title: "Top Companies",
    description: "Top companies by session count over the last 3 months",
    allowVendorAnalytics: !!allowVendorAnalytics
  };
};

export const sessionCount: analyticsQueryDelegate = (
  bladeScope: string,
  startTime?: moment.Moment,
  allowVendorAnalytics?: boolean,
  groupBy?: string
): AnalyticsComponentProps => {
  let query = _.cloneDeep(queryTemplate);
  query.setup.category = "time.week";
  query.setup.value = "sessions.usage_count";
  query.setup.group = groupBy || "products.name";
  query.valueTitle = "Session Count";
  query.startTime = (startTime || moment().utc()).subtract(6, "M").format();
  query.first = null;
  query.sortColumn = null;
  query.sortDirection = null;
  query.bladeScope = bladeScope;
  return {
    analytics: query,
    type: "line",
    title: "Session Count by Week",
    allowVendorAnalytics: !!allowVendorAnalytics
  };
};

export const sessionDuration: analyticsQueryDelegate = (
  bladeScope: string,
  startTime?: moment.Moment,
  allowVendorAnalytics?: boolean,
  groupBy?: string
): AnalyticsComponentProps => {
  let query = _.cloneDeep(queryTemplate);
  query.setup.category = "time.week";
  query.setup.value = "sessions.usage_duration";
  query.setup.group = groupBy || "products.name";
  query.valueTitle = "Session Duration";
  query.startTime = (startTime || moment().utc()).subtract(6, "M").format();
  query.first = null;
  query.sortColumn = null;
  query.sortDirection = null;
  query.bladeScope = bladeScope;
  return {
    analytics: query,
    type: "line",
    title: "Session Duration by Week",
    allowVendorAnalytics: !!allowVendorAnalytics
  };
};

export const topComponents: analyticsQueryDelegate = (
  bladeScope: string,
  startTime?: moment.Moment,
  allowVendorAnalytics?: boolean,
  groupBy?: string
): AnalyticsComponentProps => {
  let query = _.cloneDeep(queryTemplate);
  query.setup.category = "components.name";
  query.setup.value = "components.usage_count";
  if (groupBy) query.setup.group = groupBy;
  query.valueTitle = "Top Components";
  query.startTime = (startTime || moment().utc()).subtract(3, "M").format();
  query.bladeScope = bladeScope;
  return {
    analytics: query,
    type: groupBy ? "heatMap" : "bar",
    title: "Top Components",
    description: "Top components by session count over the last 3 months",
    allowVendorAnalytics: !!allowVendorAnalytics
  };
};

export const componentEntitlementCheckouts: analyticsQueryDelegate = (
  bladeScope: string,
  startTime?: moment.Moment,
  allowVendorAnalytics?: boolean,
  groupBy?: string
): AnalyticsComponentProps => {
  let query = _.cloneDeep(queryTemplate);
  query.setup.category = "time.week";
  query.setup.value = "components.usage_count";
  if (groupBy) query.setup.group = groupBy;
  query.valueTitle = "Checkouts";
  query.startTime = (startTime || moment().utc()).subtract(3, "M").format();
  query.bladeScope = bladeScope;
  query.sortColumn = "value";
  query.sortDirection = "DESC";
  return {
    analytics: query,
    type: groupBy ? "heatMap" : "bar",
    title: "Checkouts",
    allowVendorAnalytics: !!allowVendorAnalytics
  };
};

export const activeUserCount: analyticsQueryDelegate = (
  bladeScope: string,
  startTime?: moment.Moment,
  allowVendorAnalytics?: boolean,
  groupBy?: string
): AnalyticsComponentProps => {
  let query = _.cloneDeep(queryTemplate);
  query.setup.category = "time.month";
  query.setup.value = "counts.active_user";
  query.setup.group = groupBy || "products.name";
  query.valueTitle = "Active User Count";
  query.startTime = (startTime || moment().utc()).subtract(6, "M").format();
  query.first = null;
  query.sortColumn = null;
  query.sortDirection = null;
  query.bladeScope = bladeScope;
  return {
    analytics: query,
    type: "stepLine",
    title: "Number of Users",
    allowVendorAnalytics: !!allowVendorAnalytics
  };
};

export default {
  topUsers,
  topCompanies,
  sessionCount,
  topComponents,
  activeUserCount
};
