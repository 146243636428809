/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type licensesAnalyticsRegistrationQueryVariables = {
  count?: number | null | undefined;
  productId: string;
};
export type licensesAnalyticsRegistrationQueryResponse = {
  readonly registrationEntries: {
    readonly nodes: ReadonlyArray<{
      readonly user: {
        readonly displayName: string | null;
      } | null;
      readonly license: {
        readonly product: {
          readonly name: string | null;
        } | null;
        readonly owner: {
          readonly name: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};
export type licensesAnalyticsRegistrationQuery = {
  readonly response: licensesAnalyticsRegistrationQueryResponse;
  readonly variables: licensesAnalyticsRegistrationQueryVariables;
};

/*
query licensesAnalyticsRegistrationQuery(
  $count: Int
  $productId: String!
) {
  registrationEntries(first: $count, bladeScope: $productId) {
    nodes {
      user {
        displayName
        id
      }
      license {
        product {
          name
          id
        }
        owner {
          __typename
          name
          id
        }
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "count"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "productId"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "productId"
      } as any,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "displayName",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = [v3 /*: any*/],
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "licensesAnalyticsRegistrationQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "RegistrationEntryConnection",
          kind: "LinkedField",
          name: "registrationEntries",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "RegistrationEntry",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "User",
                  kind: "LinkedField",
                  name: "user",
                  plural: false,
                  selections: [v2 /*: any*/],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "License",
                  kind: "LinkedField",
                  name: "license",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Product",
                      kind: "LinkedField",
                      name: "product",
                      plural: false,
                      selections: v4 /*: any*/,
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: "LinkedField",
                      name: "owner",
                      plural: false,
                      selections: v4 /*: any*/,
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "licensesAnalyticsRegistrationQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "RegistrationEntryConnection",
          kind: "LinkedField",
          name: "registrationEntries",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "RegistrationEntry",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "User",
                  kind: "LinkedField",
                  name: "user",
                  plural: false,
                  selections: [v2 /*: any*/, v5 /*: any*/],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "License",
                  kind: "LinkedField",
                  name: "license",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Product",
                      kind: "LinkedField",
                      name: "product",
                      plural: false,
                      selections: [v3 /*: any*/, v5 /*: any*/],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: "LinkedField",
                      name: "owner",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        },
                        v3 /*: any*/,
                        v5 /*: any*/
                      ],
                      storageKey: null
                    },
                    v5 /*: any*/
                  ],
                  storageKey: null
                },
                v5 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "d586ac5d816f5a04a516b8d267608343",
      id: null,
      metadata: {},
      name: "licensesAnalyticsRegistrationQuery",
      operationKind: "query",
      text:
        "query licensesAnalyticsRegistrationQuery(\n  $count: Int\n  $productId: String!\n) {\n  registrationEntries(first: $count, bladeScope: $productId) {\n    nodes {\n      user {\n        displayName\n        id\n      }\n      license {\n        product {\n          name\n          id\n        }\n        owner {\n          __typename\n          name\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "3b866efe0d7a4318af0a24eda18e1651";
export default node;
