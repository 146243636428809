import React, { useRef } from "react";
import { IChart } from "./types";
import _ from "lodash";
import FiltersContainer from "./filtersContainer";
import { useChartData } from "./hooks";
import { useTheme } from "styled-components";
import NoDataPlaceholder from "./noDataPlaceholder";
import { Row } from "antd";
import { ColStyled, RenderStatus } from "../elmStats/ElmStats";
import { getChartData } from "./helpers";

function getStatistics(
  data: { category: any; group: string; value: number }[],
  statistic: string
) {
  switch (statistic) {
    case "sum":
      return _.sumBy(data, "value");
    case "avg":
      return _.meanBy(data, "value");
    case "max":
      return _.maxBy(data, "value");
    case "min":
      return _.minBy(data, "value");
    case "distinctGroupCount":
      return _.uniqBy(
        _.filter(data, d => d.value > 0),
        "group"
      ).length;
    case "topGroup":
      // first sum the values of each group
      const groupedData = _.groupBy(data, "group");
      const summedData = _.map(groupedData, (group, key) => {
        return {
          group: key,
          value: _.sumBy(group, "value")
        };
      });
      return _.maxBy(summedData, "value")?.group;
    case "bottomGroup":
      // first sum the values of each group
      const groupedData2 = _.groupBy(data, "group");
      const summedData2 = _.map(groupedData, (group, key) => {
        return {
          group: key,
          value: _.sumBy(group, "value")
        };
      });
      return _.minBy(data, "value")?.group;
    default:
      return 0;
  }
}
function DataValuesComponent(
  props: Pick<IChart, "queryParams" | "options" | "hideTimeRangeFilter">
) {
  const theme = useTheme();
  const hasData = useRef();

  const { data } = useChartData({
    ...props.queryParams,
    useLocalData: false
  });
  hasData.current = _.get(data, "analytics.values", []).length;
  const generatedData = _.get(data, "analytics.values", []);

  const calculateStatistic = () => {
    // props.options.groups is an array of format {name: string, value: number, statistic: string}
    // we will take the last "n" points (where n is the value of the group) in the analytics data
    // and compute the statistic and show as value for the group with a title `Last #{group.name}`
    // we will also show a green up arrow if the statistic of the last n periods
    // is greater than the previous n periods, and a red down arrow if it is less than the previous n periods
    const skipLast = _.get(props, "options.skipLast", false);
    return _.map(
      props.options?.groups,
      (group: { name: string; value: number }, _index) => {
        const distinctCategories = _.get(data, "analytics.categories", []);
        // If options.skipLast is true, then ignore the last category
        const lastNCategories = distinctCategories.slice(
          skipLast ? -group.value - 1 : -group.value,
          skipLast ? -1 : undefined
        );
        const previousNCategories = distinctCategories.slice(
          skipLast ? -group.value * 2 - 1 : -group.value * 2,
          skipLast ? -group.value - 1 : -group.value
        );
        const lastN = _.filter(generatedData, d =>
          _.includes(lastNCategories, d.category)
        );
        const previousN = _.filter(generatedData, d =>
          _.includes(previousNCategories, d.category)
        );
        const lastNAggregated = getStatistics(lastN, group.statistic);
        const previousNAggregated = getStatistics(previousN, group.statistic);
        const valueTrend =
          lastNAggregated > previousNAggregated ? "up" : "down";

        return {
          lastN,
          previousN,
          valueTrend,
          groupName: group.name,
          lastNAggregated
        };
      }
    );
  };

  return (
    <>
      {!!hasData.current && (
        <div
          style={{
            width: "100%",
            borderRadius: "4px",
            backgroundColor: "#0F649A"
          }}
        >
          <Row
            gutter={[2, 0]}
            style={{
              marginTop: "20px",
              marginRight: "0px",
              padding: "20px"
            }}
          >
            {_.map(calculateStatistic(), item => {
              return (
                <ColStyled span={12} style={{ textAlign: "center" }}>
                  <RenderStatus
                    title={`Last ${item.groupName}`}
                    valueTrend={item?.valueTrend}
                    currency={""}
                    value={item?.lastNAggregated}
                    styleOverride={{ width: "100%" }}
                  />
                </ColStyled>
              );
            })}
          </Row>
        </div>
      )}
      {!hasData.current && (
        <div style={{ minHeight: "180px" }}>
          <NoDataPlaceholder />
        </div>
      )}
    </>
  );
}

export default function DataValues(
  props: Pick<IChart, "queryParams" | "options" | "hideTimeRangeFilter">
) {
  return (
    <FiltersContainer containerProps={props} Component={DataValuesComponent} />
  );
}
