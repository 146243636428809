import * as _ from "lodash";
import * as React from "react";
import { useTheme } from "styled-components";
import { IAppConnectOptions } from "../../../store/appConnect";
import { IModalProps } from "./types";

export interface IGetModalProps {
  style?: {
    overlay?: React.CSSProperties;
    content?: React.CSSProperties;
  };
}

export class DialogBase<T extends IModalProps, S = any> extends React.Component<
  T,
  S
> {
  static getModalProps(override: IGetModalProps): IGetModalProps {
    const defaultProps = {
      style: {
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 99
        },
        content: {
          position: "initial",
          overflow: "initial",
          width: "fit-content",
          minWidth: "fit-content",
          height: "fit-content",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.160784)",
          borderRadius: "6px",
          zIndex: 99,
          border: "none"
        }
      }
    } as IGetModalProps;
    if (_.isObject(override)) {
      return _.merge(defaultProps, override);
    }
    return defaultProps;
  }

  static getAppConnectOptions(options: IAppConnectOptions): IAppConnectOptions {
    if (_.isObject(options)) {
      return options;
    }
    return { selectors: {} };
  }
}
