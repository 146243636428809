/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type productsTablePaginationQueryVariables = {
  count: number;
  cursor?: string | null | undefined;
  blade_scope?: string | null | undefined;
  search?: string | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
  skipLicenseCount: boolean;
};
export type productsTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"productsTable_products">;
};
export type productsTablePaginationQuery = {
  readonly response: productsTablePaginationQueryResponse;
  readonly variables: productsTablePaginationQueryVariables;
};

/*
query productsTablePaginationQuery(
  $count: Int!
  $cursor: String
  $blade_scope: String
  $search: String
  $sortColumn: [String!]
  $sortDirection: [String!]
  $skipLicenseCount: Boolean!
) {
  ...productsTable_products_4mwNRr
}

fragment productsTable_products_4mwNRr on Query {
  productsCount(search: $search, bladeScope: $blade_scope)
  products(first: $count, after: $cursor, bladeScope: $blade_scope, search: $search, sortColumn: $sortColumn, sortDirection: $sortDirection) {
    edges {
      cursor
      node {
        activeLicenseCount @skip(if: $skipLicenseCount)
        id
        name
        iconUrl
        activeUsers
        type {
          name
          id
        }
        __typename
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "blade_scope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "skipLicenseCount"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v6 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v7 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v8 = {
      kind: "Variable",
      name: "sortColumn",
      variableName: "sortColumn"
    } as any,
    v9 = {
      kind: "Variable",
      name: "sortDirection",
      variableName: "sortDirection"
    } as any,
    v10 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    v11 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      v10 /*: any*/,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      v7 /*: any*/,
      v8 /*: any*/,
      v9 /*: any*/
    ],
    v12 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v13 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "productsTablePaginationQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "blade_scope",
              variableName: "blade_scope"
            },
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v7 /*: any*/,
            {
              kind: "Variable",
              name: "skipLicenseCount",
              variableName: "skipLicenseCount"
            },
            v8 /*: any*/,
            v9 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "productsTable_products"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v1 /*: any*/,
        v2 /*: any*/,
        v0 /*: any*/,
        v3 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v4 /*: any*/
      ],
      kind: "Operation",
      name: "productsTablePaginationQuery",
      selections: [
        {
          alias: null,
          args: [v10 /*: any*/, v7 /*: any*/],
          kind: "ScalarField",
          name: "productsCount",
          storageKey: null
        },
        {
          alias: null,
          args: v11 /*: any*/,
          concreteType: "ProductConnection",
          kind: "LinkedField",
          name: "products",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ProductEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    v12 /*: any*/,
                    v13 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "iconUrl",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "activeUsers",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "ProductCategory",
                      kind: "LinkedField",
                      name: "type",
                      plural: false,
                      selections: [v13 /*: any*/, v12 /*: any*/],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    },
                    {
                      condition: "skipLicenseCount",
                      kind: "Condition",
                      passingValue: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "activeLicenseCount",
                          storageKey: null
                        }
                      ]
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v11 /*: any*/,
          filters: ["bladeScope", "search", "sortColumn", "sortDirection"],
          handle: "connection",
          key: "productsTable_products",
          kind: "LinkedHandle",
          name: "products"
        }
      ]
    },
    params: {
      cacheID: "e4eb2293419d777319c5dab58f005e46",
      id: null,
      metadata: {},
      name: "productsTablePaginationQuery",
      operationKind: "query",
      text:
        "query productsTablePaginationQuery(\n  $count: Int!\n  $cursor: String\n  $blade_scope: String\n  $search: String\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n  $skipLicenseCount: Boolean!\n) {\n  ...productsTable_products_4mwNRr\n}\n\nfragment productsTable_products_4mwNRr on Query {\n  productsCount(search: $search, bladeScope: $blade_scope)\n  products(first: $count, after: $cursor, bladeScope: $blade_scope, search: $search, sortColumn: $sortColumn, sortDirection: $sortDirection) {\n    edges {\n      cursor\n      node {\n        activeLicenseCount @skip(if: $skipLicenseCount)\n        id\n        name\n        iconUrl\n        activeUsers\n        type {\n          name\n          id\n        }\n        __typename\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "4fe37e07d1d0a30b19e1521cb74db3b6";
export default node;
