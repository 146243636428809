/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type productReleasesTable_releases = {
  readonly releaseConfiguration: {
    readonly releases: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly tag: string;
          readonly releaseChannelsCount: number;
          readonly createdAt: unknown;
        } | null;
      } | null> | null;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    } | null;
  } | null;
  readonly " $refType": "productReleasesTable_releases";
};
export type productReleasesTable_releases$data = productReleasesTable_releases;
export type productReleasesTable_releases$key = {
  readonly " $data"?: productReleasesTable_releases$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"productReleasesTable_releases">;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    },
    {
      defaultValue: ["releases.createdAt"],
      kind: "LocalArgument",
      name: "sortColumn"
    },
    {
      defaultValue: ["DESC"],
      kind: "LocalArgument",
      name: "sortDirection"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: "cursor",
        direction: "forward",
        path: ["releaseConfiguration", "releases"]
      }
    ]
  },
  name: "productReleasesTable_releases",
  selections: [
    {
      alias: null,
      args: [
        {
          kind: "Variable",
          name: "id",
          variableName: "id"
        }
      ],
      concreteType: "ReleaseConfiguration",
      kind: "LinkedField",
      name: "releaseConfiguration",
      plural: false,
      selections: [
        {
          alias: "releases",
          args: [
            {
              kind: "Variable",
              name: "search",
              variableName: "search"
            },
            {
              kind: "Variable",
              name: "sortColumn",
              variableName: "sortColumn"
            },
            {
              kind: "Variable",
              name: "sortDirection",
              variableName: "sortDirection"
            }
          ],
          concreteType: "ReleaseConnection",
          kind: "LinkedField",
          name: "__productReleasesTable_releases_connection",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ReleaseEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Release",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "tag",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "releaseChannelsCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "createdAt",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "e4e9938195fbe029e00d208f66ad63d2";
export default node;
