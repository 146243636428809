import { companyType } from "Companies/components/companySelector";
import { enforcementModel, termEnd } from "Licenses/blades/AddLicense";
import { productType } from "Products/components/productSelector";
import { Owner } from "store/ducks/app";
import { Dayjs } from "dayjs";

export enum commercialModel {
  perpetualMS = "Perpetual with M&S",
  saas = "SaaS",
  lease = "Lease",
  freeTrial = "Free trial",
  freeware = "Freeware"
}

export enum tabName {
  extendTerm = "Extend term",
  changeSeats = "Change seats",
  addSeats = "Add seats",
  convert = "Convert",
  restrictions = "Restrictions",
  renewTerm = "Renew term",
  entitlements = "Entitlements"
}

type ErrorInfo = {
  [key: string]: boolean;
};

export type editLicenseState = {
  editLicense: ILicenseModel;
  licenseTypeId: number;
  company: companyType;
  product: productType;
  startMoment: Dayjs;
  endMoment: Dayjs;
  loading: boolean;
  error: ErrorInfo;
  activeTabMainIndex: number;
  activeTabTitle: string;
  termStartDate?: Dayjs;
  termStartDateHumanized: string;
  termEndDateRangeMonths?: number;
  termEndDate?: Dayjs;
  termEndDateHumanized: string;
  isTermStartPickerOpen: boolean;
  isTermEndPickerOpen: boolean;
  commercialModel?: commercialModel;
  enforcementModel?: enforcementModel;
  isChangeSeatsCheckboxEnabled: boolean;
  isAddTermCheckboxEnabled: boolean;
  isCommentEnabled: boolean;
  comment?: string;
  commercialModelId?: number;
  isModelChanged?: boolean;
};

export interface ILicenseModel {
  enforcement_model_id: string; // enforcementModel.concurrent,
  number_of_installs: number;
  number_of_users: number;
  max_instances_per_user: number;
  default_token_duration: number;
  owner: Owner;
  product_id: string;
  //license_type_id: number;
  //is_trial: boolean;
  is_vendor: boolean;
  owner_id: string;
  owner_type: string;
  user_count: number;
  entitlement_id?: string;
  grace_period: number;
  instance_count: number;
  session_count: number;
  instances_per_user: number;
  users_per_instance: number;
  sessions_per_user: number;
  sessions_per_instance: number;
  sessions_per_user_per_instance: number;
  minimum_assignment_length: number;
  require_email_activation: boolean;
  value?: number; //currency
  license_value?: number; //currency
  term_value?: number; //currency
  current_seats?: number;
}

export interface IEditLicenseDialogProps {
  licenseId: string;
  licenseTypeId: number;
  activeRole?: any;
}
export interface IEditLicenseDialogState {
  role: string;
}

export enum commercialModelType {
  perpetualMS = 1,
  saas,
  lease,
  freeTrial,
  freeware
}

export enum LicenseActionStatus {
  Error = -2,
  Rejected = -1,
  Pending = 0,
  Approved = 1
}
