import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Flex } from "reflexbox/styled-components";
import { tableGenerator } from "../../../utils";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import { producReleaseConfigurationsTable_releaseConfigurations } from "./__generated__/producReleaseConfigurationsTable_releaseConfigurations.graphql";
import {
  producReleaseConfigurationsTableQuery,
  producReleaseConfigurationsTableQueryResponse
} from "./__generated__/producReleaseConfigurationsTableQuery.graphql";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { IColumn } from "components/elmTable";
import _ from "lodash";
import { shallowEqual } from "fast-equals";

enum ProductReleaseConfigurationsTableColumns {
  Name = "Name",
  Releases = "Releases"
}
export const ProductReleaseConfigurationsTableColumnConfig: Record<
  ProductReleaseConfigurationsTableColumns,
  IColumn
> = {
  Name: {
    Header: "Name",
    accessor: "name",
    disableSortBy: true
  },
  Releases: {
    Header: "# Releases",
    accessor: "releasesCount",
    disableSortBy: true
  }
};

const GeneratedTable = tableGenerator<{
  releaseConfigurations: producReleaseConfigurationsTable_releaseConfigurations;
}>({
  tableName: "releaseConfigurationsTable",
  dataKey: "releaseConfigurations.releaseConfigurations",
  columns: [
    ...Object.keys(ProductReleaseConfigurationsTableColumnConfig).map(
      key =>
        ProductReleaseConfigurationsTableColumnConfig[
          key as ProductReleaseConfigurationsTableColumns
        ]
    )
  ],
  connectionQuery: graphql`
    query producReleaseConfigurationsTablePaginationQuery(
      $count: Int!
      $cursor: String
      $search: String
      $bladeScope: String!
    ) {
      ...producReleaseConfigurationsTable_releaseConfigurations
        @arguments(
          count: $count
          cursor: $cursor
          search: $search
          bladeScope: $bladeScope
        )
    }
  `,
  fragmentSpec: {
    releaseConfigurations: graphql`
      fragment producReleaseConfigurationsTable_releaseConfigurations on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 6 }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
          bladeScope: { type: "String!", defaultValue: "" }
        ) {
        releaseConfigurations(
          bladeScope: $bladeScope
          first: $count
          after: $cursor
          search: $search
        )
          @connection(
            key: "producReleaseConfigurationsTable_releaseConfigurations"
          ) {
          edges {
            node {
              id
              name
              releasesCount
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query producReleaseConfigurationsTableQuery($bladeScope: String!) {
    ...producReleaseConfigurationsTable_releaseConfigurations
      @arguments(bladeScope: $bladeScope)
  }
`;

class ReleaseConfigurationsTable extends React.Component<
  IGeneratedTableProps & {
    result: producReleaseConfigurationsTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        releaseConfigurations={res}
      />
    );
  }
}

export const ProductReleaseConfigurationsTable = (
  props: IGeneratedTableProps
) => {
  const renderTable = (payload: {
    error: Error;
    props: producReleaseConfigurationsTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <ReleaseConfigurationsTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<producReleaseConfigurationsTableQuery>
      environment={relayEnvironment}
      variables={{
        bladeScope: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};
