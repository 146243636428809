/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type editLicenseQueryVariables = {
  id: string;
};
export type editLicenseQueryResponse = {
  readonly license: {
    readonly type: {
      readonly id: string;
      readonly name: string;
    };
    readonly gracePeriod: number;
    readonly defaultTokenDuration: number | null;
    readonly latestTerm: {
      readonly startDate: unknown | null;
      readonly endDate: unknown | null;
      readonly value: number;
    } | null;
    readonly isTrial: boolean;
    readonly isVendor: boolean;
    readonly usersCount: number;
    readonly sessionsCount: number;
    readonly instancesCount: number;
    readonly totalSeatCount: number | null;
    readonly lifetimeValue: number | null;
    readonly entitlements: {
      readonly nodes: ReadonlyArray<{
        readonly instanceCount: number | null;
        readonly sessionCount: number | null;
        readonly userCount: number | null;
      } | null> | null;
    } | null;
    readonly activeEntitlements: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly value: number;
        readonly latestTerm: {
          readonly startDate: unknown | null;
          readonly endDate: unknown | null;
        } | null;
        readonly instanceCount: number | null;
        readonly sessionCount: number | null;
        readonly userCount: number | null;
      } | null> | null;
    } | null;
    readonly commercialModel: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly restriction: {
      readonly instancesPerUser: number | null;
      readonly requireEmailActivation: boolean | null;
      readonly sessionsPerInstance: number | null;
      readonly sessionsPerUser: number | null;
      readonly sessionsPerUserPerInstance: number | null;
      readonly minimumAssignmentLength: number | null;
      readonly usersPerInstance: number | null;
      readonly numberOfInstalls: number | null;
      readonly numberOfUsers: number | null;
    } | null;
    readonly owner: {
      readonly id: string;
      readonly name: string | null;
      readonly type: string | null;
    } | null;
    readonly product: {
      readonly id: string;
      readonly name: string | null;
      readonly iconUrl: string | null;
      readonly type: {
        readonly name: string;
        readonly id: string;
      };
    } | null;
  } | null;
};
export type editLicenseQuery = {
  readonly response: editLicenseQueryResponse;
  readonly variables: editLicenseQueryVariables;
};

/*
query editLicenseQuery(
  $id: ID!
) {
  license(id: $id) {
    type {
      id
      name
    }
    gracePeriod
    defaultTokenDuration
    latestTerm {
      startDate
      endDate
      value
    }
    isTrial
    isVendor
    usersCount
    sessionsCount
    instancesCount
    totalSeatCount
    lifetimeValue
    entitlements {
      nodes {
        instanceCount
        sessionCount
        userCount
        id
      }
    }
    activeEntitlements {
      nodes {
        id
        value
        latestTerm {
          startDate
          endDate
        }
        instanceCount
        sessionCount
        userCount
      }
    }
    commercialModel {
      id
      name
    }
    restriction {
      instancesPerUser
      requireEmailActivation
      sessionsPerInstance
      sessionsPerUser
      sessionsPerUserPerInstance
      minimumAssignmentLength
      usersPerInstance
      numberOfInstalls
      numberOfUsers
    }
    owner {
      __typename
      id
      name
      type
    }
    product {
      id
      name
      iconUrl
      type {
        name
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = [v2 /*: any*/, v3 /*: any*/],
    v5 = {
      alias: null,
      args: null,
      concreteType: "LicenseModel",
      kind: "LinkedField",
      name: "type",
      plural: false,
      selections: v4 /*: any*/,
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "gracePeriod",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "defaultTokenDuration",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "startDate",
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "endDate",
      storageKey: null
    } as any,
    v10 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "value",
      storageKey: null
    } as any,
    v11 = {
      alias: null,
      args: null,
      concreteType: "Term",
      kind: "LinkedField",
      name: "latestTerm",
      plural: false,
      selections: [v8 /*: any*/, v9 /*: any*/, v10 /*: any*/],
      storageKey: null
    } as any,
    v12 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "isTrial",
      storageKey: null
    } as any,
    v13 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "isVendor",
      storageKey: null
    } as any,
    v14 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "usersCount",
      storageKey: null
    } as any,
    v15 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "sessionsCount",
      storageKey: null
    } as any,
    v16 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "instancesCount",
      storageKey: null
    } as any,
    v17 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "totalSeatCount",
      storageKey: null
    } as any,
    v18 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "lifetimeValue",
      storageKey: null
    } as any,
    v19 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "instanceCount",
      storageKey: null
    } as any,
    v20 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "sessionCount",
      storageKey: null
    } as any,
    v21 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "userCount",
      storageKey: null
    } as any,
    v22 = {
      alias: null,
      args: null,
      concreteType: "EntitlementConnection",
      kind: "LinkedField",
      name: "activeEntitlements",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Entitlement",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            v10 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Term",
              kind: "LinkedField",
              name: "latestTerm",
              plural: false,
              selections: [v8 /*: any*/, v9 /*: any*/],
              storageKey: null
            },
            v19 /*: any*/,
            v20 /*: any*/,
            v21 /*: any*/
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v23 = {
      alias: null,
      args: null,
      concreteType: "CommercialModel",
      kind: "LinkedField",
      name: "commercialModel",
      plural: false,
      selections: v4 /*: any*/,
      storageKey: null
    } as any,
    v24 = {
      alias: null,
      args: null,
      concreteType: "Restriction",
      kind: "LinkedField",
      name: "restriction",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "instancesPerUser",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "requireEmailActivation",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "sessionsPerInstance",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "sessionsPerUser",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "sessionsPerUserPerInstance",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "minimumAssignmentLength",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "usersPerInstance",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "numberOfInstalls",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "numberOfUsers",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v25 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "type",
      storageKey: null
    } as any,
    v26 = {
      alias: null,
      args: null,
      concreteType: "Product",
      kind: "LinkedField",
      name: "product",
      plural: false,
      selections: [
        v2 /*: any*/,
        v3 /*: any*/,
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "iconUrl",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          concreteType: "ProductCategory",
          kind: "LinkedField",
          name: "type",
          plural: false,
          selections: [v3 /*: any*/, v2 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "editLicenseQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v11 /*: any*/,
            v12 /*: any*/,
            v13 /*: any*/,
            v14 /*: any*/,
            v15 /*: any*/,
            v16 /*: any*/,
            v17 /*: any*/,
            v18 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "EntitlementConnection",
              kind: "LinkedField",
              name: "entitlements",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Entitlement",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [v19 /*: any*/, v20 /*: any*/, v21 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v22 /*: any*/,
            v23 /*: any*/,
            v24 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: "LinkedField",
              name: "owner",
              plural: false,
              selections: [v2 /*: any*/, v3 /*: any*/, v25 /*: any*/],
              storageKey: null
            },
            v26 /*: any*/
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "editLicenseQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v11 /*: any*/,
            v12 /*: any*/,
            v13 /*: any*/,
            v14 /*: any*/,
            v15 /*: any*/,
            v16 /*: any*/,
            v17 /*: any*/,
            v18 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "EntitlementConnection",
              kind: "LinkedField",
              name: "entitlements",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Entitlement",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [
                    v19 /*: any*/,
                    v20 /*: any*/,
                    v21 /*: any*/,
                    v2 /*: any*/
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v22 /*: any*/,
            v23 /*: any*/,
            v24 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: "LinkedField",
              name: "owner",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "__typename",
                  storageKey: null
                },
                v2 /*: any*/,
                v3 /*: any*/,
                v25 /*: any*/
              ],
              storageKey: null
            },
            v26 /*: any*/,
            v2 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "096a2441de6e2003d5c5bf1f5e9b2e10",
      id: null,
      metadata: {},
      name: "editLicenseQuery",
      operationKind: "query",
      text:
        "query editLicenseQuery(\n  $id: ID!\n) {\n  license(id: $id) {\n    type {\n      id\n      name\n    }\n    gracePeriod\n    defaultTokenDuration\n    latestTerm {\n      startDate\n      endDate\n      value\n    }\n    isTrial\n    isVendor\n    usersCount\n    sessionsCount\n    instancesCount\n    totalSeatCount\n    lifetimeValue\n    entitlements {\n      nodes {\n        instanceCount\n        sessionCount\n        userCount\n        id\n      }\n    }\n    activeEntitlements {\n      nodes {\n        id\n        value\n        latestTerm {\n          startDate\n          endDate\n        }\n        instanceCount\n        sessionCount\n        userCount\n      }\n    }\n    commercialModel {\n      id\n      name\n    }\n    restriction {\n      instancesPerUser\n      requireEmailActivation\n      sessionsPerInstance\n      sessionsPerUser\n      sessionsPerUserPerInstance\n      minimumAssignmentLength\n      usersPerInstance\n      numberOfInstalls\n      numberOfUsers\n    }\n    owner {\n      __typename\n      id\n      name\n      type\n    }\n    product {\n      id\n      name\n      iconUrl\n      type {\n        name\n        id\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "ad32a2cdc957a2d0781860759f6ab395";
export default node;
