import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import UpDown from "./upDown";
import "../../style/components/index.scss";
import {
  InputContainer,
  StyledErrorLabel,
  StyledInput,
  StyledLabel,
  StyledLabelNote,
  UnitsSpan
} from "./styles";
import clsx from "clsx";

type inputProps = React.HTMLProps<HTMLInputElement>;
type numericInputProps = {
  minValue?: number;
  maxValue?: number;
};
export interface IElmInputProps extends inputProps {
  placeholder?: string;
  label?: string;
  controlled?: boolean;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  value?: string;
  type?: inputProps["type"];
  isDecimalNumber?: boolean;
  updatedValue?: (value: string) => void;
  units?: string;
  numericConstraints?: numericInputProps;
  note?: string;
}
export interface IElmInputState {
  activeText?: inputProps["defaultValue"];
}
export class ElmInput extends React.Component<IElmInputProps, IElmInputState> {
  constructor(props: IElmInputProps) {
    super(props);
    this.state = {
      activeText: props.defaultValue
    };
  }
  disallowedNumberInput = [
    "e",
    "E",
    "+",
    "-",
    !this.props.isDecimalNumber ? "." : null
  ];
  public isNumberInput = () => this.props.type === "number";

  public updateActiveText = (activeText: string) => {
    if (this.isNumberInput() && activeText) {
      if (
        _.isNaN(parseInt(activeText)) ||
        this.disallowedNumberInput.includes(activeText)
      ) {
        return;
      }
    }
    this.setState({ activeText }, () => {
      if (_.isFunction(this.props.updatedValue)) {
        this.props.updatedValue(
          this.isNumberInput() ? parseInt(activeText, 10) : activeText
        );
      }
    });
  };
  public handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.isNumberInput() && this.disallowedNumberInput.includes(e.key)) {
      e.preventDefault();
    }
  };
  public requestUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.updateActiveText(e.target.value);
  };
  public getInputValue = (): string => {
    if (this.props.controlled) {
      return this.props.value;
    }
    return this.state.activeText ? this.state.activeText.toString() : "";
  };
  private incrementOrDecrementValue = (change: number) => {
    if (this.props.type !== "number") {
      console.error("Why is increment/decrement called on a non-number type?");
      return;
    }
    let inputNum = this.getNumericInput();
    inputNum = inputNum + change;
    if (this.props.numericConstraints) {
      if (this.props.numericConstraints.minValue) {
        inputNum = Math.max(this.props.numericConstraints.minValue, inputNum);
      }
      if (this.props.numericConstraints.maxValue) {
        inputNum = Math.min(this.props.numericConstraints.maxValue, inputNum);
      }
    }
    this.updateActiveText(_.toString(inputNum));
  };
  private getNumericInput() {
    const input = this.getInputValue();
    let inputNum = parseInt(input, 10) || 0;
    return inputNum;
  }

  public render() {
    return (
      <Flex
        flexDirection={"column"}
        flex={1}
        style={{ ...(this.props.style || {}) }}
        //className={getClassName({ [this.props.className]: () => true, "elm-input": () => true })}
        //className={clsx({[this.props.className]: true, "elm-input": true})}
      >
        <StyledLabel className="label">
          {this.props.label} {this.props.required ? "*" : ""}
        </StyledLabel>
        <InputContainer error={this.props.error}>
          {!!this.props.prefix ? (
            <UnitsSpan> {this.props.prefix} </UnitsSpan>
          ) : null}
          <StyledInput
            type={this.props.type}
            min={this.isNumberInput() ? 0 : null}
            onChange={this.requestUpdate}
            onKeyDown={this.handleKeyDown}
            //@ts-ignore disable scroll event
            onWheel={e => e.target.blur()}
            defaultValue={this.props.defaultValue}
            value={this.getInputValue()}
            placeholder={this.props.placeholder}
            error={this.props.error}
            disabled={this.props.disabled}
          />
          {!!this.props.units ? (
            <UnitsSpan> {this.props.units} </UnitsSpan>
          ) : null}
          {/* {!!(this.props.type === "number") ? (
            <UpDown
              onUpPressed={() => this.incrementOrDecrementValue(1)}
              onDownPressed={() => this.incrementOrDecrementValue(-1)}
              upDisabled={
                this.props.numericConstraints &&
                this.props.numericConstraints.maxValue !== undefined &&
                this.props.numericConstraints.maxValue <= this.getNumericInput()
              }
              downDisabled={
                this.props.numericConstraints &&
                this.props.numericConstraints.minValue !== undefined &&
                this.props.numericConstraints.minValue >= this.getNumericInput()
              }
            />
          ) : null} */}
        </InputContainer>
        <StyledLabelNote className="label">{this.props.note}</StyledLabelNote>
        <StyledErrorLabel
          error={this.props.error}
          errorMessage={this.props.errorMessage}
        >
          {this.props.errorMessage}
        </StyledErrorLabel>
      </Flex>
    );
  }
}

export default ElmInput;
