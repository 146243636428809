import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import {
  allUsersQuery,
  allUsersQueryResponse
} from "./__generated__/allUsersQuery.graphql";
import _ from "lodash";
import { ElmFilterSelect } from "../../elmSelect";
import { ElmSelectOption } from "../../elmSelect";
import clsx from "clsx";
const graphqlQuery = graphql`
  query allUsersQuery {
    allUsersFilter: users(
      sortColumn: ["product.name"]
      sortDirection: ["asc"]
    ) {
      nodes {
        name
        id
      }
    }
  }
`;
export interface IFilterProp {
  userId?: string;
  disabled?: boolean;
  onChange?: (userId: string) => void;
}
export class AllUsersFilter extends React.Component<
  IFilterProp & { result: allUsersQueryResponse },
  { userId: string; filtered: boolean }
> {
  state = {
    userId: this.props.userId || "-1",
    filtered: this.props.userId && this.props.userId !== "-1"
  };
  public renderFilter = () => {
    if (!this.props.result || !this.props.result.allUsersFilter) {
      return null;
    }

    const options = [
      {
        id: -1,
        name: "All users"
      },
      ...this.props.result.allUsersFilter.nodes
    ];
    const handleChange = (payload: any) => {
      let userId: string = payload;
      let filtered: boolean = true;
      if (payload === "-1") {
        userId = null;
        filtered = false;
      }
      this.setState({ userId, filtered }, () => {
        if (_.isFunction(this.props.onChange)) {
          this.props.onChange(userId);
        }
      });
    };
    return (
      <ElmFilterSelect
        disabled={this.props.disabled}
        value={this.state.userId}
        onChange={handleChange}
        className={clsx(this.state.filtered ? "filtered" : "", "filterBorder")}
        dropdownMatchSelectWidth={false}
      >
        {_.map(options, node => {
          return (
            <ElmSelectOption key={node.id}>
              <span className="filterOption">{node.name}</span>
            </ElmSelectOption>
          );
        })}
      </ElmFilterSelect>
    );
  };
  public render() {
    return this.renderFilter();
  }
}
const RenderQuery = (props: IFilterProp) => {
  const renderComponent = (payload: {
    error: Error;
    props: allUsersQueryResponse;
    retry: () => void;
  }) => {
    return (
      <AllUsersFilter
        {...props}
        result={payload.props}
        {..._.omit(payload, "props")}
      />
    );
  };
  return (
    <QueryRenderer<allUsersQuery>
      environment={relayEnvironment}
      variables={null}
      query={graphqlQuery}
      render={renderComponent}
    />
  );
};
export default RenderQuery;
