import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import {
  allLicensesQuery,
  allLicensesQueryResponse
} from "./__generated__/allLicensesQuery.graphql";
import _ from "lodash";
import { ElmFilterSelect } from "../../elmSelect";
import { ElmSelectOption } from "../../elmSelect";
import clsx from "clsx";
import dayjs from "dayjs";
import { getTermDetails, licenseTypeToName, searchInObjectArray } from "utils";
import { DATE_FORMAT_ALT } from "const";
import { Flex } from "reflexbox";
import styled from "styled-components";

const ProductName = styled.p`
  font-size: 13px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 0px;
`;
const LicenseTerm = styled.p`
  color: ${props => props.theme.colors.warmGrey};
  font-size: 11px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 0px;
`;
const LicenseDetail = styled.p`
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 4px;
`;
const Divider = styled.p`
  border-left: 1px solid ${props => props.theme.colors.warmGrey};
  margin-inline: 8px;
  margin-bottom: 4px;
  position: relative;
  bottom: 2px;
  height: 12px;
  width: 1px;
`;
const graphqlQuery = graphql`
  query allLicensesQuery {
    allLicensesFilter: licenses(
      sortColumn: ["product.name"]
      sortDirection: ["asc"]
    ) {
      nodes {
        id
        owner {
          name
        }
        product {
          name
        }
        commercialModel {
          name
        }
        type {
          id
        }
        totalSeatCount
        latestTerm {
          endDate
        }
      }
    }
  }
`;
interface IFilterProp {
  licenseId?: string;
  onChange?: (licenseId: string) => void;
}
export class AllLicensesFilter extends React.Component<
  IFilterProp & { result: allLicensesQueryResponse },
  { licenseId: string; searchTerm?: string; filtered: boolean }
> {
  state = {
    searchTerm: "",
    licenseId: this.props.licenseId || "-1",
    filtered: this.props.licenseId && this.props.licenseId !== "-1"
  };
  DISPLAY_LENGTH = 40;
  public getValues = (): {
    id: string;
    label: string;
    value: JSX.Element;
  }[] => {
    const res = this.props.result.allLicensesFilter.nodes
      ?.filter(
        license =>
          !getTermDetails(_.get(license, "latestTerm.endDate"))?.isExpired
      )
      .map(node => {
        const termEnd = dayjs(node?.latestTerm?.endDate)?.format(
          DATE_FORMAT_ALT
        );
        return {
          id: node.id,
          company: node?.owner?.name,
          product: node?.product?.name,
          commercialModel: node?.commercialModel?.name || "Classic",
          enforcementModel: licenseTypeToName(node?.type?.id),
          seats: `${node?.totalSeatCount} seats`,
          termEnd
        };
      });

    res?.forEach((license, index) => {
      let isLicenseProductNameUnique = true;
      let isCummulativeCommercialModelUnique = true;
      let isCummulativeEnforcementModelUnique = true;
      let isCummulativeSeatCountUnique = true;

      res.forEach(other => {
        if (other.id !== license.id && other.product === license.product) {
          isLicenseProductNameUnique = false;
        }
      });
      res.forEach(other => {
        if (
          other.id !== license.id &&
          other.product === license.product &&
          other.commercialModel === license.commercialModel
        ) {
          isCummulativeCommercialModelUnique = false;
        }
      });
      res.forEach(other => {
        if (
          other.id !== license.id &&
          other.product === license.product &&
          other.commercialModel === license.commercialModel &&
          other.enforcementModel === license.enforcementModel
        ) {
          isCummulativeEnforcementModelUnique = false;
        }
      });
      res.forEach(other => {
        if (
          other.id !== license.id &&
          other.product === license.product &&
          other.commercialModel === license.commercialModel &&
          other.enforcementModel === license.enforcementModel &&
          other.seats === license.seats
        ) {
          isCummulativeSeatCountUnique = false;
        }
      });
      if (isLicenseProductNameUnique) {
        res[index].commercialModel = null;
        res[index].enforcementModel = null;
        res[index].seats = null;
        res[index].termEnd = null;
      }
      if (isCummulativeCommercialModelUnique) {
        res[index].enforcementModel = null;
        res[index].seats = null;
        res[index].termEnd = null;
      }
      if (isCummulativeEnforcementModelUnique) {
        res[index].seats = null;
        res[index].termEnd = null;
      }
      if (isCummulativeSeatCountUnique) {
        res[index].termEnd = null;
      }
    });

    const filteredData = this.state.searchTerm
      ? searchInObjectArray(this.state.searchTerm, res)
      : res;

    return filteredData?.map(license => {
      const values = Object.keys(license)
        ?.map(key => {
          if (["id", "company"].includes(key)) {
            return null;
          }
          return license[key];
        })
        .filter(value => !!value);
      const displayValue = values.join(", ")?.slice(0, this.DISPLAY_LENGTH);
      return {
        id: license.id,
        label:
          displayValue.length > this.DISPLAY_LENGTH - 1
            ? displayValue.concat("...")
            : displayValue,
        value: (
          <Flex flexDirection={"column"} minWidth={325}>
            <Flex justifyContent={"space-between"}>
              <ProductName>{license.product}</ProductName>
              {license.termEnd ? (
                <LicenseTerm>{license.termEnd}</LicenseTerm>
              ) : null}
            </Flex>
            <Flex>
              {license.commercialModel ? (
                <LicenseDetail>{license.commercialModel}</LicenseDetail>
              ) : null}
              {license.enforcementModel ? (
                <>
                  <Divider />
                  <LicenseDetail>{license.enforcementModel}</LicenseDetail>
                </>
              ) : null}
              {license.seats ? (
                <>
                  <Divider />
                  <LicenseDetail>{license.seats}</LicenseDetail>
                </>
              ) : null}
            </Flex>
          </Flex>
        )
      };
    });
  };
  public renderFilter = () => {
    if (!this.props.result || !this.props.result.allLicensesFilter) {
      return null;
    }

    const options = [
      {
        id: "-1",
        label: "All licenses",
        value: "All licenses"
      },
      ...this.getValues()
    ];
    const handleChange = (payload: any) => {
      let licenseId: string = payload;
      let filtered: boolean = true;
      if (payload === "-1") {
        licenseId = null;
        filtered = false;
      }
      this.setState({ licenseId, filtered }, () => {
        if (_.isFunction(this.props.onChange)) {
          this.props.onChange(licenseId);
        }
      });
    };
    return (
      <ElmFilterSelect
        value={
          options.find(option => option.id === this.state.licenseId)?.label
        }
        defaultValue={
          options.find(option => option.id === this.state.licenseId)?.label
        }
        onChange={handleChange}
        className={clsx(this.state.filtered ? "filtered" : "", "filterBorder")}
        dropdownMatchSelectWidth={false}
      >
        {_.map(options, node => {
          return (
            <ElmSelectOption key={node.id} value={node.id}>
              <span className="filterOption">{node?.value}</span>
            </ElmSelectOption>
          );
        })}
      </ElmFilterSelect>
    );
  };
  public render() {
    return this.renderFilter();
  }
}
const RenderQuery = (props: IFilterProp) => {
  const renderComponent = (payload: {
    error: Error;
    props: allLicensesQueryResponse;
    retry: () => void;
  }) => {
    return (
      <AllLicensesFilter
        {...props}
        result={payload.props}
        {..._.omit(payload, "props")}
      />
    );
  };
  return (
    <QueryRenderer<allLicensesQuery>
      environment={relayEnvironment}
      variables={null}
      query={graphqlQuery}
      render={renderComponent}
    />
  );
};
export default RenderQuery;
