/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type accountDialogQueryVariables = {};
export type accountDialogQueryResponse = {
  readonly currentUser: {
    readonly email: string | null;
    readonly id: string;
    readonly name: string | null;
    readonly thumbUrl: string | null;
    readonly avatarUrl: string | null;
    readonly emailPreferences: unknown;
  } | null;
};
export type accountDialogQuery = {
  readonly response: accountDialogQueryResponse;
  readonly variables: accountDialogQueryVariables;
};

/*
query accountDialogQuery {
  currentUser {
    __typename
    email
    id
    name
    thumbUrl
    avatarUrl
    emailPreferences
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "email",
      storageKey: null
    } as any,
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "thumbUrl",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "avatarUrl",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "emailPreferences",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "accountDialogQuery",
      selections: [
        {
          alias: null,
          args: null,
          concreteType: null,
          kind: "LinkedField",
          name: "currentUser",
          plural: false,
          selections: [
            v0 /*: any*/,
            v1 /*: any*/,
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "accountDialogQuery",
      selections: [
        {
          alias: null,
          args: null,
          concreteType: null,
          kind: "LinkedField",
          name: "currentUser",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "__typename",
              storageKey: null
            },
            v0 /*: any*/,
            v1 /*: any*/,
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "eb516b146c8b999de2d6dfce8c15976f",
      id: null,
      metadata: {},
      name: "accountDialogQuery",
      operationKind: "query",
      text:
        "query accountDialogQuery {\n  currentUser {\n    __typename\n    email\n    id\n    name\n    thumbUrl\n    avatarUrl\n    emailPreferences\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "022ed30bfa69de256ac6f477ed90188f";
export default node;
