import React from "react";
import _ from "lodash";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { getFaIcon } from "../icons";

const ToggleText = styled.span`
  position: absolute;
  right: 0px;
  margin-right: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  text-align: right;

  /* LightBlue */

  color: ${props => props.theme.colors.toggleText};
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;
const FooterToggleIcon = styled(
  getFaIcon({
    iconName: "angle-double-up",
    prefix: "far"
  })
)`
  color: ${props => props.theme.colors.warmGrey};
`;
const HeaderToggleIcon = styled(
  getFaIcon({
    iconName: "angle-double-down",
    prefix: "far"
  })
)`
  color: ${props => props.theme.colors.warmGrey};
`;
export const CollapsibleAttributeLabel = styled.p<{ labelColor?: string }>`
  ${props => props.theme.fontType.paragraph}
  color: ${props => props.labelColor || props.theme.colors.textPrimary};
`;
const ValueContainer = styled.div`
  min-width: 75px;
  max-width: 270px;
  overflow: hidden;
  height: 22px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.textPrimary};
`;
const LabelContainer = styled.div`
  height: 22px;
  display: flex;
  align-items: center;
  min-width: 30px;
  max-width: 100px;
  overflow: hidden;
  margin-right: 10px;
`;
const Value = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Black */

  color: ${props => props.theme.colors.pureBlack};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Label = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* warmGrey */

  color: ${props => props.theme.colors.warmGrey};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CollapsibleAttributeContainer = styled(Flex)<{ isCollapsed?: boolean }>`
  background-color: ${props =>
    props.isCollapsed ? "transparent" : props.theme.colors.detailsContainer};
  color: ${props => props.theme.colors.textPrimary};
  max-height: max-content;
  width: 100%;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid ${props => props.theme.colors.whiteTwo};
`;

export interface IAttributeValuePair {
  label: JSX.Element | string;
  value: JSX.Element | string;
  // itemStyle?: React.CSSProperties;
}
// export interface ICollapsibleAttributeColumn {
//   top?: IAttributeValuePair;
//   bottom?: IAttributeValuePair;
// }
export interface ICollapsibleAttributeBoxProps {
  items?: IAttributeValuePair[];
  renderHeaderRow: () => JSX.Element;
  renderFooterRow?: () => JSX.Element;
  style?: React.CSSProperties;
  className?: string;
  topToggleText: string;
  bottomToggleText: string;
  isCollapsedDefault?: boolean;
  permissions?: string | string[];
}
export type state = {
  isEditMode: boolean;
  isCollapsed: boolean;
};
export class CollapsibleAttributeBox extends React.Component<
  ICollapsibleAttributeBoxProps
> {
  state: state = {
    isEditMode: false,
    isCollapsed: this.props.isCollapsedDefault
  };
  public renderOneOfValuePair = (
    payload: IAttributeValuePair,
    type: "label" | "value"
  ) => {
    if (type === "label") {
      return (
        <LabelContainer>
          {_.isString(payload.label) ? (
            <Label>{payload.label}</Label>
          ) : (
            payload.label
          )}
        </LabelContainer>
      );
    }
    return (
      <ValueContainer>
        {_.isString(payload.value) ? (
          <Value>{payload.value || "N/A"}</Value>
        ) : (
          payload.value
        )}
      </ValueContainer>
    );
  };
  public renderValueLabelPair = (
    payload: IAttributeValuePair,
    index: number
  ) => {
    if (!_.isObject(payload)) {
      return null;
    }

    return (
      <Flex
        style={{ alignItems: "center", minHeight: "22px", maxHeight: "22px" }}
      >
        {this.renderOneOfValuePair(payload, "label")}
        {this.renderOneOfValuePair(payload, "value")}
      </Flex>
    );
  };
  public renderColumn = (items: IAttributeValuePair[]) => {
    return (
      <Flex flex={1}>
        <Flex flexDirection={"column"}>
          {_.map(items, item => this.renderOneOfValuePair(item, "label"))}
        </Flex>
        <Flex
          flexDirection={"column"}
          style={{ marginLeft: "16px", marginRight: "10px" }}
        >
          {_.map(items, item => this.renderOneOfValuePair(item, "value"))}
        </Flex>
      </Flex>
    );
  };
  public renderItems = () => {
    const columnMinCount = 2;

    if (_.isArray(this.props.items)) {
      if (this.props.items.length <= columnMinCount) {
        return this.renderColumn(this.props.items);
      }
      let firstColumnCount;

      firstColumnCount = Math.round(this.props.items.length / 2);
      if (firstColumnCount < columnMinCount) {
        firstColumnCount = columnMinCount - firstColumnCount + firstColumnCount;
      }
      // check if first column will be less than second column, if so switch
      if (firstColumnCount < this.props.items.length - firstColumnCount) {
        firstColumnCount = this.props.items.length - firstColumnCount;
      }
      return (
        <Flex style={{ flex: 1 }}>
          {this.renderColumn(_.slice(this.props.items, 0, firstColumnCount))}
          {this.renderColumn(
            _.slice(this.props.items, firstColumnCount, this.props.items.length)
          )}
        </Flex>
      );
    }

    return null;
  };
  public toggleCollapsedState = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed
    });
  };
  public renderToggleButton = (location?: "header" | "footer") => {
    let toggleText =
      location === "header"
        ? this.props.topToggleText
        : this.props.bottomToggleText;
    let Icon = HeaderToggleIcon;
    if (location === "footer") {
      toggleText = this.props.bottomToggleText;
      Icon = FooterToggleIcon;
    }
    if (!toggleText) {
      return null;
    }
    return (
      <ToggleText onClick={this.toggleCollapsedState}>
        <CollapsibleAttributeLabel labelColor="#707070">
          {toggleText}
        </CollapsibleAttributeLabel>
        <Icon style={{ marginLeft: "6px", width: "8px" }} />
      </ToggleText>
    );
  };
  public renderHeaderRow = () => {
    return (
      <Flex
        className={"collapsible-attribute-box-header-row"}
        style={{
          flex: 1,
          justifyContent: "space-between",
          marginBottom: this.state.isCollapsed ? "0px" : "5px"
        }}
      >
        {_.isFunction(this.props.renderHeaderRow) ? (
          this.props.renderHeaderRow()
        ) : (
          <div></div>
        )}
        {this.state.isCollapsed ? this.renderToggleButton("header") : null}
      </Flex>
    );
  };

  public renderFooterRow = () => {
    return (
      <Flex
        style={{
          maxHeight: "24px",
          minHeight: "24px",
          flex: 1,
          overflow: "hidden",
          justifyContent: "space-between",
          marginTop: "10px"
        }}
      >
        {_.isFunction(this.props.renderFooterRow)
          ? this.props.renderFooterRow()
          : null}
        {!this.state.isCollapsed ? this.renderToggleButton("footer") : null}
      </Flex>
    );
  };
  public render() {
    return (
      <CollapsibleAttributeContainer
        isCollapsed={this.state.isCollapsed}
        style={this.props.style}
        className={this.props.className}
      >
        {this.renderHeaderRow()}
        {this.state.isCollapsed ? null : this.renderItems()}
        {this.state.isCollapsed ? null : this.renderFooterRow()}
      </CollapsibleAttributeContainer>
    );
  }
}

export default CollapsibleAttributeBox;
