import _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import { getEnvironment } from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenAddReportBlade,
  IOpenReportBlade
} from "../../../components/bladeManager/types";
import { BladeTemplate, BladeTitle, ElmTable } from "../../../components";
import { deepEqual } from "fast-equals";
import ExportOptionsDropdown from "Reports/components/exportOptionsDropdown";
import ReportActionsDropdown from "Reports/components/reportActionsDropdown";
import { getGateway } from "api";
import {
  ReportQuery,
  ReportQueryResponse
} from "./__generated__/ReportQuery.graphql";
import { IColumn } from "components/elmTable";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { NoData } from "assets";
import { SearchBar } from "components/elmTable/helpers";

import { Report } from "Reports/common/types";
import { notification } from "antd";
import { getSavedRoleFromLocalSync, searchInObjectArray } from "utils";

const LoadingInfo = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${props => props.theme.colors.warmGrey};
`;

export const getLabelFromKey = ({
  key,
  isTableColumn
}: {
  key: any;
  isTableColumn?: boolean;
}) => {
  switch (key) {
    case "timestamp":
      return "Timestamp";
    case "date":
      return "Date";
    case "company":
      return "Company";
    case "product":
      return "Product";
    case "display_name":
    case "user.display_name":
      return "User Name";
    case "email":
    case "user.email":
      return "Email";
    case "owner":
    case "user.owner":
    case "license.owner":
    case "user.owner.name":
    case "license.owner.name":
      return "Company";
    case "component":
      return "Component";
    case "license":
    case "license.key":
      return "License";
    case "last_checkout":
      return "Last Checkout";
    case "last_used":
      return "Last Used";
    case "last_session":
      return "Last Session";
    case "latest_version":
      return "Latest Version";
    case "license_key":
      return "License key";
    case "num_checkouts":
      return isTableColumn ? "Checkouts" : "Number of checkouts";
    case "num_failures":
      return isTableColumn ? "Failures" : "Failure Count";
    case "num_sessions":
      return isTableColumn ? "Sessions" : "Number of sessions";
    case "session_count":
      return isTableColumn ? "Sessions" : "Session Count";
    case "max_session_count":
      return isTableColumn ? "Max Sessions" : "Max Session Count";
    case "usage_minutes":
      return "Usage (minutes)";
    case "frequency":
      return "Frequency";
    default:
      return key;
  }
};

const graphqlQuery = graphql`
  query ReportQuery($id: ID!) {
    report(id: $id) {
      id
      name
      options
      createdAt
      reportType
      isPrivate
      vendorSystemUser {
        id
        name
        email
      }
    }
    currentUser {
      id
      name
      email
    }
  }
`;

export interface IReportBladeProps extends IBladeBaseProps<IOpenReportBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}

interface IReportBladeState {
  searchTerm: string;
}

interface RenderReportData {
  id: string;
  data: any[];
  loading: boolean;
}

class ReportBlade extends React.Component<
  IReportBladeProps & {
    renderReport: RenderReportData;
    result: ReportQueryResponse;
    retry: () => void;
    theme: any;
  },
  IReportBladeState
> {
  public state: IReportBladeState = {
    searchTerm: ""
  };
  public componentDidMount() {}
  public navAway = () => {
    this.props.openBlade({
      routeName: "Customer portal",
      fromBladeIndex: this.props.index,
      route: "Overview",
      routeData: null
    });
  };
  public renderLeftSideHeader = () => (
    <Flex style={{ alignItems: "center" }} flexDirection={"column"}>
      <BladeTitle style={{ marginLeft: "18px" }}>
        {this.props.routeData?.name || this.props.result?.report?.name}
      </BladeTitle>
    </Flex>
  );
  public renderRightSideHeader = () => {
    const isActionAllowed =
      getSavedRoleFromLocalSync()?.role === "admin" ||
      getSavedRoleFromLocalSync()?.role === "owner" ||
      this.props.result?.report?.vendorSystemUser?.email ===
        this.props.result?.currentUser?.email;
    return (
      <Flex marginX={20}>
        <ExportOptionsDropdown
          data={this.props.renderReport.data}
          header={this.props.routeData?.name || this.props.result?.report?.name}
        />
        {isActionAllowed ? (
          <ReportActionsDropdown
            data={this.props.result?.report}
            currentUser={this.props.result?.currentUser}
            openEdit={this.openEditReportBlade}
            duplicateAction={this.duplicateReportAction}
            deleteAction={this.deleteReportAction}
            disabled={!this.props.result}
          />
        ) : null}
      </Flex>
    );
  };
  public openEditReportBlade = (
    routeData: IOpenAddReportBlade["routeData"]
  ) => {
    this.props.openBlade({
      route: "AddReport",
      routeName: "Edit Report",
      fromBladeIndex: this.props.index - 1,
      routeData
    });
  };
  public duplicateReportAction = () => {
    const res = this.props.result;
    if (!res) {
      return;
    }
    const { report } = res;
    const newReport: Report = {
      name: report?.name + " (copy)",
      report_type: report?.reportType,
      options: {
        filters: {
          company_id: report?.options?.options?.filters?.companyId,
          product_id: report?.options?.options?.filters?.productId,
          component_id: report?.options?.options?.filters?.componentId,
          duration: report?.options?.options?.filters?.duration,
          start_time: report?.options?.options?.filters?.startTime,
          end_time: report?.options?.options?.filters?.endTime
        },
        columns: report?.options?.options?.columns,
        include_charts: report?.options?.options?.includeCharts,
        raw_data: report?.options?.options?.isRawData,
        minimum_session_length: report?.options?.options?.sessionLength,
        aggregate_field: report?.options?.options?.aggregateField
      },
      reporting_interval: report?.options?.options?.reportingInterval,
      duration: report?.options?.options?.filters?.duration,
      start_time: report?.options?.options?.filters?.startTime,
      end_time: report?.options?.options?.filters?.endTime,
      is_private: report?.isPrivate
    };
    const gateway = getGateway();
    gateway.request["newReport"](newReport)
      .then((res: any) => {
        if (!res.error && res?.data?.id) {
          notification.open({
            type: "success",
            message: "Success",
            description: `Successfully created a copy of ${report?.name ||
              "report"} !`
          });
          this.props.closeBlade({ route: "Report" });
          this.openEditReportBlade({
            id: btoa(`${"Report"}-${res.data.id}`),
            name: res.data.name
          });
        }
      })
      .catch(err => {
        notification.open({
          type: "error",
          message: "Failure",
          description: "Report copying failed, please try again !"
        });
      });
  };
  public deleteReportAction = () => {
    const res = this.props.result;
    if (!res) {
      return;
    }
    const gateway = getGateway();
    gateway.request["deleteReport"](null, { id: _.get(res.report, "id") })
      .then(res => {
        if (!res.error) {
          notification.open({
            type: "success",
            message: "Success",
            description: "Report Deleted !"
          });
          this.props.closeBlade({ route: "Report" });
        }
      })
      .catch(err => {
        notification.open({
          type: "error",
          message: "Failure",
          description: "Error deleting report !"
        });
      });
  };
  public renderReportInfo = () => {
    const res = this.props.result;
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Report"}
        bladeType="Report"
        bladeTypeName={"REPORTS"}
        closeBlade={this.props.closeBlade}
        accentColor={"filterBlue"}
        topAccentColor={"filterBlue"}
        leftAccentColor={"filterBlue"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
      >
        {this.renderReport(res)}
      </BladeTemplate>
    );
  };

  public renderReport = (res: ReportQueryResponse) => {
    if (this.props.renderReport.loading) {
      return (
        <Flex
          flexDirection={"column"}
          height={"50%"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{ gap: 12 }}
        >
          <Loading3QuartersOutlined
            style={{
              fontSize: 55,
              color: this.props.theme.colors.primary,
              marginBottom: 24
            }}
            spin
          />
          <LoadingInfo>We're crunching data for this report.</LoadingInfo>
          <LoadingInfo>Please hold on...</LoadingInfo>
        </Flex>
      );
    }
    if (!this.props.result || !this.props.renderReport.data?.length) {
      return (
        <Flex
          flexDirection={"column"}
          height={"50%"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{ gap: 12 }}
        >
          <img style={{ height: 32 }} src={NoData} alt="No data available" />
          <LoadingInfo>No Data</LoadingInfo>
        </Flex>
      );
    }
    const columns: IColumn[] = [];
    const searchColumns: string[] = [];
    Object.keys(this.props.renderReport.data[0])?.forEach(key => {
      if (key.startsWith("_")) {
        // Exclude metadata properties which start with _
        return;
      }
      if (typeof this.props.renderReport.data[0][key] !== "object") {
        columns.push({
          Header: getLabelFromKey({ key, isTableColumn: true }),
          accessor: key,
          sortKey: null,
          disableSortBy: true,
          isDateFormat: key === "date" || key === "timestamp",
          width: key === "date" ? 0.22 : null
        });
        searchColumns.push(key);
      }
      if (typeof this.props.renderReport.data[0][key] === "object") {
        Object.keys(this.props.renderReport.data[0][key]).forEach(k => {
          columns.push({
            Header: getLabelFromKey({ key: k, isTableColumn: true }),
            accessor: `${key}.${k}`,
            sortKey: null,
            disableSortBy: true,
            width: 0.11
          });
          searchColumns.push(k);
        });
      }
    });

    const filteredData = this.state.searchTerm
      ? searchInObjectArray(this.state.searchTerm, this.props.renderReport.data)
      : this.props.renderReport.data;

    return (
      <div
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
          display: "flex",
          flex: 1
        }}
      >
        <Flex flexDirection={"column"} width={"100%"} marginTop={10}>
          <SearchBar
            placeholder="Search for keyword"
            onSearch={searchTerm => this.setState({ searchTerm })}
          />
          <>{this.renderTable(columns, filteredData)}</>
        </Flex>
      </div>
    );
  };

  public renderTable(columns: IColumn[], data: any) {
    if (!data) {
      return null;
    }
    return (
      <ElmTable
        noNav
        hideSearchBar={true}
        columns={columns}
        data={data}
        totalCount={data.length}
      />
    );
  }

  public render() {
    return this.renderReportInfo();
  }
}

const relayEnvironment = getEnvironment();
const RenderQuery = (props: IReportBladeProps) => {
  const theme = useTheme();
  const [renderReportData, setRenderReportData] = useState<RenderReportData>({
    id: "",
    loading: true,
    data: []
  });

  useEffect(() => {
    if (renderReportData.id === props.routeData?.id) {
      return;
    }
    setRenderReportData({
      id: null,
      loading: true,
      data: []
    });
    const gateway = getGateway();
    gateway.request["renderReport"]({ id: props.routeData?.id }).then(
      ({ data }) => {
        setRenderReportData({
          id: props.routeData.id,
          loading: false,
          data: data
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.routeData?.id]);

  const renderReportInfo = (payload: {
    error: Error;
    props: ReportQueryResponse;
    retry: () => void;
  }) => {
    return (
      <ReportBlade
        {...props}
        result={payload.props}
        renderReport={renderReportData}
        retry={payload.retry}
        theme={theme}
      />
    );
  };
  return (
    <QueryRenderer<ReportQuery>
      environment={relayEnvironment}
      variables={{
        id: props.routeData.id
      }}
      query={graphqlQuery}
      render={renderReportInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {
    activeRole: "activeRoleSelector"
  }
});
