import { ReduxAction } from "../../deviseAuth/types";
import { Action, IAnalyticsState } from "../../types";
import {} from "../../types";

const initialState = {
  filters: {
    product: "-1",
    company: "-1",
    user: "-1"
  }
};

export interface UserSignInCredentials {
  readonly [key: string]: any;
}

export type ANALYTICS_FILTER_UPDATE = "REQUEST_ANALYTICS_FILTER_UPDATE";
export const REQUEST_ANALYTICS_FILTER_UPDATE =
  "REQUEST_ANALYTICS_FILTER_UPDATE";

export type RequestFilterUpdateAction = Action<
  ANALYTICS_FILTER_UPDATE,
  UserSignInCredentials
>;
export interface AnalyticsFilterUpdate {
  readonly type: ANALYTICS_FILTER_UPDATE;
}
type ReduxAnalyticsAction = RequestFilterUpdateAction;

const analytics = (
  state: IAnalyticsState = initialState,
  action: ReduxAnalyticsAction
): IAnalyticsState => {
  switch (action.type) {
    case REQUEST_ANALYTICS_FILTER_UPDATE:
      return { ...initialState, filters: action.data };
    default:
      return initialState;
  }
};

export default analytics;
