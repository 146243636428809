/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Reports_reports = {
  readonly reportsCount: number;
  readonly reports: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly options: unknown | null;
        readonly createdAt: unknown;
        readonly reportType: number;
        readonly isPrivate: boolean;
        readonly vendorSystemUser: {
          readonly id: string;
          readonly name: string | null;
          readonly email: string | null;
        } | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $refType": "Reports_reports";
};
export type Reports_reports$data = Reports_reports;
export type Reports_reports$key = {
  readonly " $data"?: Reports_reports$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"Reports_reports">;
};

const node: ReaderFragment = (function() {
  var v0 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "bladeScope"
    } as any,
    v1 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "bladeScope"
      },
      {
        defaultValue: 25,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      },
      {
        defaultValue: ["reports.createdAt"],
        kind: "LocalArgument",
        name: "sortColumn"
      },
      {
        defaultValue: ["DESC"],
        kind: "LocalArgument",
        name: "sortDirection"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["reports"]
        }
      ]
    },
    name: "Reports_reports",
    selections: [
      {
        alias: null,
        args: [v0 /*: any*/, v1 /*: any*/],
        kind: "ScalarField",
        name: "reportsCount",
        storageKey: null
      },
      {
        alias: "reports",
        args: [
          v0 /*: any*/,
          v1 /*: any*/,
          {
            kind: "Variable",
            name: "sortColumn",
            variableName: "sortColumn"
          },
          {
            kind: "Variable",
            name: "sortDirection",
            variableName: "sortDirection"
          }
        ],
        concreteType: "ReportConnection",
        kind: "LinkedField",
        name: "__Reports_reports_connection",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "ReportEdge",
            kind: "LinkedField",
            name: "edges",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "Report",
                kind: "LinkedField",
                name: "node",
                plural: false,
                selections: [
                  v2 /*: any*/,
                  v3 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "options",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "createdAt",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "reportType",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "isPrivate",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: "VendorSystemUser",
                    kind: "LinkedField",
                    name: "vendorSystemUser",
                    plural: false,
                    selections: [
                      v2 /*: any*/,
                      v3 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "email",
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "__typename",
                    storageKey: null
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "cursor",
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: "PageInfo",
            kind: "LinkedField",
            name: "pageInfo",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "hasNextPage",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "endCursor",
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "d5b702a87be4c59d275a2b8b2b3a52a6";
export default node;
