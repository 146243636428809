import * as _ from "lodash";
import { Flex } from "reflexbox/styled-components";
import * as React from "react";
import { useTheme } from "styled-components";
import { useState } from "react";
import { useLicenseComponentsAndEntitlementsData } from "../../blades/AddLicense/hooks";
import { featuresAndGroupsToggleQueryResponse } from "Licenses/components/featuresAndGroups/__generated__/featuresAndGroupsToggleQuery.graphql";
import { ElmButton, ElmTable } from "components";
import { getTermDetails, searchInObjectArray } from "utils";
import { TableCellProps } from "react-virtualized";
import { TableText } from "Licenses/components/licensesTable";
import { IOpenBlade, TOpenDialog } from "components/bladeManager/types";
import { getGateway } from "api";
import {
  renderErrorNotification,
  renderInfoNotification
} from "utils/ant-notifications";
import { getDateTimeFormatted } from "components/elmTable/helpers";
import { modalTypes } from "components/bladeManager/appDialog";
import { hooksLicenseComponentsAndEntitlementsQueryResponse } from "../AddLicense/__generated__/hooksLicenseComponentsAndEntitlementsQuery.graphql";

type ComponentNode = hooksLicenseComponentsAndEntitlementsQueryResponse["license"]["components"]["nodes"][number];
type VersionRequirementNode = ComponentNode["licenseComponentRequirements"][number];

interface IComponentsAndGroupsProps {
  isVendor: boolean;
  openBlade: (payload: IOpenBlade) => void;
  fromBladeIndex: number;
  license?: featuresAndGroupsToggleQueryResponse["license"];
  components?: { id: string; name: string }[];
  productId?: string;
  licenseId?: string;
  templateId?: string;
  componentGroup?: { id: string; name: string }[];
  openDialog: TOpenDialog;
  closeDialog: (name: modalTypes) => void;
  retry?: () => void;
  refreshAllOpenBlades?: () => void;
}

interface IComponentsAndGroupsState {
  search_term: string;
  components: { id: string; name: string }[];
  componentGroup: { id: string; name: string }[];
}

export function LicenseComponentEntitlements({
  isVendor,
  openBlade,
  fromBladeIndex,
  components,
  componentGroup,
  productId,
  licenseId,
  openDialog,
  closeDialog,
  refreshAllOpenBlades
}: IComponentsAndGroupsProps) {
  const theme = useTheme();
  const [state, setState] = useState<IComponentsAndGroupsState>({
    search_term: "",
    components: components || [],
    componentGroup: componentGroup || []
  });

  const licenseComponentsData = useLicenseComponentsAndEntitlementsData(
    licenseId
  );
  const licenseComponents = licenseComponentsData?.license?.components?.nodes;
  const componentEntitlements =
    licenseComponentsData?.license?.componentEntitlements?.nodes;

  const updateSearchTerm = ({ search_term }) => {
    setState({ ...state, search_term });
  };

  const checkRequirementStatus = (
    reqs: ComponentNode["licenseComponentRequirements"]
  ) => {
    const componentIsIncluded = reqs?.every(
      (v: VersionRequirementNode) => v?.isIncluded && !v?.tokenCount
    );
    const componentHasFreeTrial = _.last(reqs)?.freeTrialActive;
    return { componentIsIncluded, componentHasFreeTrial };
  };

  const notIncludedComponents = licenseComponents.filter(c => {
    const {
      componentIsIncluded,
      componentHasFreeTrial
    } = checkRequirementStatus(c?.licenseComponentRequirements);
    let hasEntitlement = false;
    let isExpired = true;
    componentEntitlements?.forEach(ent => {
      if (
        ent?.componentGroup?.id &&
        ent?.componentGroup?.id === c?.componentGroup?.id
      ) {
        hasEntitlement = true;
        isExpired = getTermDetails(_.get(ent, "latestTerm.endDate"))?.isExpired;
      }
      ent?.components?.nodes?.forEach(n => {
        if (n?.id === c.id) {
          hasEntitlement = true;
          isExpired = getTermDetails(_.get(ent, "latestTerm.endDate"))
            ?.isExpired;
        }
      });
    });
    if (
      componentIsIncluded ||
      componentHasFreeTrial ||
      (hasEntitlement && !isExpired)
    ) {
      return false;
    }
    return true;
  });
  const filteredEntitlements = state.search_term
    ? searchInObjectArray(state.search_term, componentEntitlements)
    : componentEntitlements;
  const sortedEntitlements = filteredEntitlements.toSorted((a, b) =>
    b?.latestTerm?.endDate?.localeCompare(a?.latestTerm?.endDate)
  );
  const sharedBtnStyle: React.CSSProperties = {
    color: theme.colors.warmGrey,
    margin: 2,
    padding: 0
  };
  const formatInlineDisplayValues = (input: string[]) => {
    const MAX_DISPLAY_VALUE = 28;

    if (input.length < 3) {
      const display = input.join(", ");
      const output =
        display.length > MAX_DISPLAY_VALUE
          ? `${display.substring(0, MAX_DISPLAY_VALUE - 1)}...`
          : display;
      return output;
    }

    const [first, second, ...rest] = input;
    const display = `${first}, ${second}`;

    const output =
      display.length > MAX_DISPLAY_VALUE
        ? `${display.substring(0, MAX_DISPLAY_VALUE - 1)}...`
        : display;

    return `${output} + ${rest.length} more`;
  };
  const renderEntitlementComponents = (payload: TableCellProps) => {
    const { isExpired } = getTermDetails(
      _.get(payload.rowData, "latestTerm.endDate")
    );
    const displayValue =
      payload?.rowData?.componentGroup?.name ||
      formatInlineDisplayValues(
        payload?.rowData?.components?.nodes?.map(({ name }) => name)
      );
    return <TableText isExpired={isExpired}>{displayValue}</TableText>;
  };
  const renderEntitlementUsage = (payload: TableCellProps) => {
    const { isExpired } = getTermDetails(
      _.get(payload.rowData, "latestTerm.endDate")
    );
    let displayValue = "";
    if (_.isNumber(payload.rowData?.tokenCount)) {
      displayValue = `${payload.rowData?.tokenCount} tokens`;
    }
    if (_.isNumber(payload.rowData?.sessionCount)) {
      displayValue = `${payload.rowData?.sessionCount} sessions`;
    }
    if (_.isNumber(payload.rowData?.currencyCount)) {
      displayValue = `${payload?.rowData?.numCurrencyUsed || 0}/${
        payload?.rowData?.currencyCount
      }`;
    }
    return <TableText isExpired={isExpired}>{displayValue}</TableText>;
  };
  const renderEntitlementModel = (payload: TableCellProps) => {
    const entitlementType =
      _.get(payload.rowData, "componentGroup.componentType") ||
      _.get(payload.rowData, "components.nodes[0].componentType");

    const { isExpired } = getTermDetails(
      _.get(payload.rowData, "latestTerm.endDate")
    );
    return <TableText isExpired={isExpired}>{entitlementType}</TableText>;
  };
  const renderEntitlementExpiration = (payload: TableCellProps) => {
    const timestamp = _.get(payload.rowData, "latestTerm.endDate");
    const { isExpired } = getTermDetails(timestamp);
    return (
      <TableText isExpired={isExpired}>
        {getDateTimeFormatted(timestamp)}
      </TableText>
    );
  };
  const openNewEntitlementBlade = () => {
    openBlade({
      route: "Entitlement",
      routeData: {
        id: null,
        productId: productId,
        licenseId: licenseId,
        entitlementId: null,
        availableComponents: notIncludedComponents?.map(c => c.id)
      },
      routeName: "Entitlement",
      fromBladeIndex: fromBladeIndex
    });
  };
  const openEntitlementBlade = (rowData: { id: string }) => {
    openBlade({
      route: "Entitlement",
      routeData: {
        id: null,
        productId: productId,
        entitlementId: rowData.id,
        licenseId: licenseId,
        availableComponents: notIncludedComponents?.map(c => c.id)
      },
      routeName: "Entitlement",
      fromBladeIndex: fromBladeIndex
    });
  };
  return (
    <ElmTable
      data={sortedEntitlements}
      hideFooter={true}
      onCriteriaChange={updateSearchTerm}
      onRowClick={openEntitlementBlade}
      useDefaultAddButton={openNewEntitlementBlade}
      hideColumnsWithHeaders={isVendor ? [] : ["Actions"]}
      columns={[
        {
          Header: "Components",
          accessor: "",
          disableSortBy: true,
          sortKey: null,
          cellRenderer: e => renderEntitlementComponents(e),
          width: 0.3
        },
        {
          Header: "Entitlement Model",
          accessor: "",
          cellRenderer: e => renderEntitlementModel(e),
          disableSortBy: true,
          sortKey: null,
          width: 0.25
        },
        {
          Header: "Entitlement",
          accessor: "",
          cellRenderer: e => renderEntitlementUsage(e),
          disableSortBy: true,
          sortKey: null,
          width: 0.2
        },
        {
          Header: "Expiration",
          accessor: "latestTerm.endDate",
          isDateFormat: true,
          cellRenderer: e => renderEntitlementExpiration(e),
          sortKey: null,
          width: 0.3
        },
        {
          Header: "Actions",
          accessor: "",
          cellRenderer: data => {
            const deleteEntitlement = () => {
              const gateway = getGateway();
              gateway.request
                .deleteEntitlement(null, { id: data.rowData.id })
                .then(res => {
                  if (res.error) {
                    renderErrorNotification("Failed to delete entitlement");
                  } else {
                    renderInfoNotification("Entitlement Deleted");
                    closeDialog("DeleteDialog");
                    refreshAllOpenBlades();
                  }
                })
                .catch(err => {
                  console.error(err);
                });
            };
            const showDeleteDialog = () => {
              openDialog("DeleteDialog", {
                entityType: "entitlement",
                name: "confirm",
                isSimpleConfirmation: true,
                onConfirm: deleteEntitlement
              });
            };
            const { isExpired } = getTermDetails(
              _.get(data.rowData, "latestTerm.endDate")
            );
            return (
              <Flex onClick={e => e.stopPropagation()}>
                <ElmButton
                  label=""
                  title="Delete"
                  colorVariance="subtle"
                  className="darkModeTransparentBtn"
                  permissions={"modify_assets"}
                  variance={"icon-button"}
                  icon="trash-can"
                  iconPrefix="far"
                  onClick={e => {
                    showDeleteDialog();
                  }}
                  style={sharedBtnStyle}
                />
                {isExpired ? (
                  <ElmButton
                    label=""
                    title="Renew term"
                    colorVariance="subtle"
                    className="darkModeTransparentBtn"
                    permissions={"modify_assets"}
                    variance={"icon-button"}
                    icon="redo"
                    iconPrefix="fas"
                    onClick={() => {
                      openDialog("RenewEntitlementDialog", {
                        ...data.rowData,
                        onConfirm: () => refreshAllOpenBlades()
                      });
                    }}
                    style={sharedBtnStyle}
                  />
                ) : null}
              </Flex>
            );
          },
          sortKey: null,
          width: 0.125
        }
      ]}
    />
  );
}
