/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type allLicensesQueryVariables = {};
export type allLicensesQueryResponse = {
  readonly allLicensesFilter: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly owner: {
        readonly name: string | null;
      } | null;
      readonly product: {
        readonly name: string | null;
      } | null;
      readonly commercialModel: {
        readonly name: string;
      } | null;
      readonly type: {
        readonly id: string;
      };
      readonly totalSeatCount: number | null;
      readonly latestTerm: {
        readonly endDate: unknown | null;
      } | null;
    } | null> | null;
  } | null;
};
export type allLicensesQuery = {
  readonly response: allLicensesQueryResponse;
  readonly variables: allLicensesQueryVariables;
};

/*
query allLicensesQuery {
  allLicensesFilter: licenses(sortColumn: ["product.name"], sortDirection: ["asc"]) {
    nodes {
      id
      owner {
        __typename
        name
        id
      }
      product {
        name
        id
      }
      commercialModel {
        name
        id
      }
      type {
        id
      }
      totalSeatCount
      latestTerm {
        endDate
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        kind: "Literal",
        name: "sortColumn",
        value: ["product.name"]
      } as any,
      {
        kind: "Literal",
        name: "sortDirection",
        value: ["asc"]
      } as any
    ],
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = [v2 /*: any*/],
    v4 = {
      alias: null,
      args: null,
      concreteType: "LicenseModel",
      kind: "LinkedField",
      name: "type",
      plural: false,
      selections: [v1 /*: any*/],
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "totalSeatCount",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      concreteType: "Term",
      kind: "LinkedField",
      name: "latestTerm",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "endDate",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v7 = [v2 /*: any*/, v1 /*: any*/];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "allLicensesQuery",
      selections: [
        {
          alias: "allLicensesFilter",
          args: v0 /*: any*/,
          concreteType: "LicenseConnection",
          kind: "LinkedField",
          name: "licenses",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "License",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v1 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: null,
                  kind: "LinkedField",
                  name: "owner",
                  plural: false,
                  selections: v3 /*: any*/,
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: v3 /*: any*/,
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "CommercialModel",
                  kind: "LinkedField",
                  name: "commercialModel",
                  plural: false,
                  selections: v3 /*: any*/,
                  storageKey: null
                },
                v4 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey:
            'licenses(sortColumn:["product.name"],sortDirection:["asc"])'
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "allLicensesQuery",
      selections: [
        {
          alias: "allLicensesFilter",
          args: v0 /*: any*/,
          concreteType: "LicenseConnection",
          kind: "LinkedField",
          name: "licenses",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "License",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v1 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: null,
                  kind: "LinkedField",
                  name: "owner",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    },
                    v2 /*: any*/,
                    v1 /*: any*/
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: v7 /*: any*/,
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "CommercialModel",
                  kind: "LinkedField",
                  name: "commercialModel",
                  plural: false,
                  selections: v7 /*: any*/,
                  storageKey: null
                },
                v4 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey:
            'licenses(sortColumn:["product.name"],sortDirection:["asc"])'
        }
      ]
    },
    params: {
      cacheID: "137f0ca4af7850af515f7d5e1fdda719",
      id: null,
      metadata: {},
      name: "allLicensesQuery",
      operationKind: "query",
      text:
        'query allLicensesQuery {\n  allLicensesFilter: licenses(sortColumn: ["product.name"], sortDirection: ["asc"]) {\n    nodes {\n      id\n      owner {\n        __typename\n        name\n        id\n      }\n      product {\n        name\n        id\n      }\n      commercialModel {\n        name\n        id\n      }\n      type {\n        id\n      }\n      totalSeatCount\n      latestTerm {\n        endDate\n      }\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "c4ecf8b45687149ab4bf689965e114dc";
export default node;
