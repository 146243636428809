import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { ElmCheckBox } from "../../../../components/elmCheckBox";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import ElmInput from "../../../../components/elmInput";
import relayEnvironment from "../../../../api/relay";
import { getGateway } from "../../../../api";
import {
  newProductFeatureGroupQuery,
  newProductFeatureGroupQueryResponse
} from "./__generated__/newProductFeatureGroupQuery.graphql";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../../components/elmSelect";
import { LoaderContainer } from "components/helpers";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  //height: 320px;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;

const featureGroupSelectionQuery = graphql`
  query newProductFeatureGroupQuery(
    $productId: ID!
    $featureGroupId: ID!
    $skip: Boolean!
  ) {
    product(id: $productId) {
      name
      features {
        nodes {
          name
          id
          featureGroups {
            nodes {
              name
            }
          }
        }
      }
    }
    featureGroup(id: $featureGroupId) @skip(if: $skip) {
      name
      features {
        nodes {
          id
          name
        }
      }
    }
  }
`;

const StyledGroupSelection = styled(Flex)`
  color: ${props => props.theme.colors.textPrimary};
`;

export interface INewProductFeatureGroupDialogProps {
  title?: string;
  description?: string;
  productId: string;
  productName: string;
}
type state = {
  name: string;
  disabled: boolean;
  error: boolean;
  selectedFeatures: { id: string; name: string }[];
  isSelectOpen: boolean;
};

export interface INewProductFeatureDialogProps {
  id: string;
  title?: string;
  description?: string;
  productId: string;
  productName: string;
  editMode?: boolean;
  groups?: { id?: string; name: string }[];
  name?: string;
}

export class NewProductFeatureGroupDialog extends DialogBase<
  IModalProps<INewProductFeatureDialogProps> & {
    result: newProductFeatureGroupQueryResponse;
  },
  state
> {
  state: state = {
    name: _.get(this.props, "result.featureGroup.name", ""),
    disabled: false,
    selectedFeatures: _.get(
      this.props,
      "result.featureGroup.features.nodes",
      []
    ),
    isSelectOpen: false,
    error: false
  };

  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          //flexDirection: "column",
          minHeight: "320px",
          width: "330px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white",
          border: "none"
        }
      }
    });
  }

  public toggleSelect = (isOpen: boolean) => {
    this.setState({ isSelectOpen: isOpen });
  };

  public GroupSelection = () => {
    const updateGroups = (payload: {
      id: string;
      name: string;
      checked: boolean;
    }) => {
      if (payload.checked) {
        const newComponents = [
          ...this.state.selectedFeatures,
          { name: payload.name, id: payload.id }
        ];
        this.setState({ selectedFeatures: newComponents });
      } else {
        const filtered = this.state.selectedFeatures.filter(f => {
          if (f.id && f.id !== payload.id) return true;
          if (f.name && f.name !== payload.name) return true;
          return false;
        });
        this.setState({ selectedFeatures: filtered });
      }
    };

    const handleChange = (group: { id: string; name: string }) => (
      e: CheckboxChangeEvent
    ) => {
      updateGroups({
        checked: e.target.checked,
        ...group
      });
    };
    const product = this.props.result.product;
    return (
      <StyledGroupSelection
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Flex
          style={{ height: "158px", marginTop: "7px" }}
          flexDirection="column"
          flex={1}
        >
          <ElmSelectWithLabel
            label="Features"
            placeholder="Select features"
            style={{ flexDirection: "row" }}
            onClick={() => this.toggleSelect(true)}
            onBlur={() => this.toggleSelect(false)}
            open={this.state.isSelectOpen}
            value={
              this.state.selectedFeatures.length > 0
                ? this.state.selectedFeatures.map(item => item.name).join()
                : undefined
            }
          >
            <ElmSelectOption key={"selectAll"} value={"selectAll"}>
              <ElmCheckBox
                onClick={() => {
                  const filterValues = product?.features?.nodes;
                  this.setState({
                    ...this.state,
                    selectedFeatures:
                      this.state.selectedFeatures.length ===
                      filterValues?.length
                        ? []
                        : [...filterValues]
                  });
                }}
                // style={{ marginRight: "40px" }}
              >
                {"Select all"}
              </ElmCheckBox>
            </ElmSelectOption>
            {_.map(product.features.nodes, node => {
              return (
                <ElmSelectOption
                  key={node.id}
                  value={node.name}
                  label={node.name}
                >
                  <ElmCheckBox
                    onChange={handleChange(node)}
                    checked={
                      this.state.selectedFeatures.find(
                        item => item.id === node.id || item.name === node.name
                      )
                        ? true
                        : false
                    }
                  >
                    {node.name}
                  </ElmCheckBox>
                </ElmSelectOption>
              );
            })}
          </ElmSelectWithLabel>
          {product && !product?.features?.nodes?.length && (
            <Flex
              style={{ marginTop: "10px", justifyContent: "center" }}
            >{`No features found for ${product.name}. Create one.`}</Flex>
          )}
        </Flex>
      </StyledGroupSelection>
    );
  };

  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "NewProductFeatureGroupDialog"
    });
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };
  public getTitle = () => {
    return this.props?.payload?.editMode
      ? "Edit Feature Group"
      : "Add Feature Group";
  };

  public getDescription = () => {
    return "Create a new feature for this product";
  };
  public createNewProductFeature = () => {
    this.setState(
      {
        disabled: true
      },
      () => {
        const gateway = getGateway();
        const data = {
          id: _.get(this.props, "payload.featureGroupId", ""),
          //product_id: this.props.payload.productId,
          features: this.state.selectedFeatures.map(item => item.id),
          feature_group: { name: this.props.payload.name || this.state.name }
        };
        this.props.payload.editMode
          ? gateway.request
              .editProductFeatureGroup(data, {
                id: data.id
              })
              .then(res => {
                if (!res.error) {
                  if (_.isFunction(this.props.payload.onConfirm)) {
                    this.props.payload.onConfirm(res);
                  }
                  return this.closeModal();
                }
              })
              .catch(err => {
                console.error(err);
              })
          : gateway.request
              .newProductFeatureGroup({
                product_id: this.props.payload.productId,
                features: this.state.selectedFeatures,
                name: this.state.name
              })
              .then(res => {
                if (res.error) {
                  this.setState({ error: true, disabled: false });
                } else {
                  if (_.isFunction(this.props.payload.onConfirm)) {
                    this.props.payload.onConfirm();
                  }
                  this.closeModal();
                }
              })
              .catch(err => {
                console.error(err);
              });
      }
    );
  };
  public render() {
    const editMode = this.props?.payload?.editMode;
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <Flex>
          <ElmInput
            style={{
              marginBottom: "19px"
            }}
            //disabled={this.props.payload.editMode ? true : false}
            defaultValue={this.state.name}
            //value={this.state.name}
            updatedValue={this.updateName}
            controlled={true}
            label={"Group name"}
            placeholder={"Enter group name"}
          />
        </Flex>
        {/* {this.renderQueryRenderer()} */}
        {this.GroupSelection()}
        <Flex flex={1} alignItems="flex-end" justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            //style={{ maxWidth: "150px" }}
            disabled={this.state.disabled}
            label={editMode ? "Save changes" : "Create Feature Group"}
            onClick={this.createNewProductFeature}
          />
        </Flex>
      </DialogContainer>
    );
  }
}

export const DataContainer = (
  props: IModalProps<INewProductFeatureDialogProps>
) => {
  const renderGroupSelection = (payload: {
    error: Error;
    props: newProductFeatureGroupQueryResponse;
  }) => {
    if (!payload.props) {
      return <DialogContainer>{LoaderContainer()}</DialogContainer>;
    }
    if (payload.error) {
      return null;
    }
    return <NewProductFeatureGroupDialog {...props} result={payload.props} />;
  };
  return (
    <DialogContainer>
      <QueryRenderer<newProductFeatureGroupQuery>
        variables={{
          productId: _.get(props.payload, "productId"),
          featureGroupId: _.get(props, "payload.featureGroupId", ""),
          skip: !_.get(props, "payload.featureGroupId")
        }}
        environment={relayEnvironment}
        query={featureGroupSelectionQuery}
        render={renderGroupSelection}
      />
    </DialogContainer>
  );
};

export default DataContainer;
