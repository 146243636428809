import React from "react";
import { useTheme } from "styled-components";
import { getOnboardingConfigSync } from "./storage";
import { ElmButton } from "components";

export const OpenGuideButton: React.FC<{ onClick: () => void }> = ({
  onClick
}) => {
  const theme = useTheme();
  const isPending = getOnboardingConfigSync("Status")?.status === "pending";
  return isPending ? (
    <ElmButton
      label={"Open Guide"}
      colorVariance={"primary"}
      variance={"plain-icon-button"}
      style={{
        background: theme.colors.white,
        color: theme.colors.elmButtonTextPrimary
      }}
      icon={"lightbulb"}
      iconPrefix={"far"}
      onClick={onClick}
    />
  ) : null;
};
