import { lazy } from "react";
import _ from "lodash";
import moment from "moment";
import countries from "i18n-iso-countries";
import { IOpenBlade } from "../components/bladeManager/types";
import { theme } from "../theme";
import asyncStorage from "./AsyncLocalStorage";
import toSuperScript from "./superscript";
import LicensePlaceHolderLogo from "./placeholderLicenseLogo.svg";
import UserPlaceHolder from "./userPlaceholder.svg";
import tableGenerator from "./tableGenerator";
import appConfig from "../config";
import { IUserRole, UserRoleType } from "../store/types";
import { RcFile } from "antd/lib/upload";
import { message } from "antd";
import { decodeProductTypeId } from "../Licenses/components/licenseEnforcementSelector";
import { IUpdateBladeManagerUrl } from "components/bladeManager";
import { DATE_FORMAT } from "const";
import { commercialModel } from "Licenses/common";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
export {
  asyncStorage,
  toSuperScript,
  LicensePlaceHolderLogo,
  UserPlaceHolder,
  tableGenerator
};

export const getLazyImport = (compPath: string) => lazy(() => import(compPath));

export const saveUserRoleToLocal = (userRole: IUserRole) => {
  if (userRole) {
    asyncStorage.setItem(`elm_user_role`, JSON.stringify(userRole));
  }
};
export const saveActiveModeToLocal = (activeMode: string) => {
  if (activeMode) {
    asyncStorage.setItem(`elm_active_mode`, JSON.stringify(activeMode));
  }
};
export const getActiveModeFromLocalSync = () => {
  const activeMode = window.localStorage.getItem(`elm_active_mode`);
  if (activeMode) {
    return JSON.parse(activeMode) as string;
  }
  return null;
};
export const getSavedRoleFromLocal = async () => {
  const userRole = await asyncStorage.getItem(`elm_user_role`);
  if (userRole) {
    return JSON.parse(userRole) as IUserRole;
  }
  return null;
};
export const getSavedRoleFromLocalSync = () => {
  const userRole = window.localStorage.getItem(`elm_user_role`);
  if (userRole) {
    return JSON.parse(userRole) as IUserRole;
  }
  return null;
};
export const getSavedRoleTypeFromLocalSync = () => {
  const roleType = window.localStorage.getItem(`elm_user_role_type`);
  if (roleType) {
    return roleType as UserRoleType;
  }
  return null;
};
export const saveUserRoleTypeToLocalSync = (roleType: UserRoleType) => {
  if (roleType) {
    window.localStorage.setItem(`elm_user_role_type`, roleType);
  }
};
export const clearUserRoleFromLocalSync = () => {
  try {
    window.localStorage.removeItem(`elm_user_role`);
    window.localStorage.removeItem(`elm_user_role_type`);
  } catch (error) {
    console.error(error);
  }
};
export const getCountryName = (countryCode: string) =>
  countries.getName(countryCode, "en", { select: "official" }) || "";
export const isTestEnvironment = () =>
  process.env.REACT_APP_NODE_ENV === "test";

export const isComponentIncludedInVersion = (type: number) => {
  if (type !== 0) {
    return true;
  }
  return false;
};
export const getBladeColor = (
  bladeKey: IOpenBlade["route"]
): keyof theme["colors"] => {
  switch (bladeKey) {
    case "Licenses":
      return "mango";
    case "License":
      return "mango";
    case "LicenseComponent":
      return "mango";
    case "NewEntitlement":
      return "mango";
    case "Companies":
      return "companyRed";
    case "Company":
      return "companyRed";
    case "Products":
      return "productsBlue";
    case "ProductComponent":
      return "productsBlue";
    case "Product":
      return "productsBlue";
    case "Users":
      return "userGreen";
    case "User":
      return "userGreen";
    case "Report":
    case "Reports":
    case "AddReport":
      return "filterBlue";
    case "Instance":
      return "userGreen";
    case "Account":
      return "warmGrey";
    case "Overview":
      return "warmGrey";
    case "EmailPreferences":
      return "warmGrey";
    default:
      return "pink";
  }
};

export type licenseTypeId = 1 | 2 | 3 | 4 | string;
export type licenseTypeNames =
  | "Named User"
  | "Concurrent"
  | "Unrestricted"
  | "Node Locked";
export const licenseNameToType = (name: licenseTypeNames) => {
  switch (name) {
    case "Named User":
      return 1;
    case "Concurrent":
      return 2;
    case "Unrestricted":
      return 3;
    case "Node Locked":
      return 4;
    default:
      return -1;
  }
};
export const getFriendlyLicenseName = (name: string) => {
  if (name === "Free license with email activation") {
    return "Unlimited License";
  }
  return name;
};
export const getShortLicenseName = (name: string) => {
  const friendlyName = getFriendlyLicenseName(name);
  let shortName = _.replace(friendlyName, / [Ll]icense/, "");
  shortName = _.replace(shortName, "-", " ");
  return shortName;
};
export const licenseTypeToName = (typeId: licenseTypeId): licenseTypeNames => {
  const decode = decodeProductTypeId(typeId as string);
  switch (_.isString(decode) ? parseInt(decode, 10) : typeId) {
    case 1:
      return "Named User";
    case 2:
      return "Concurrent";
    case 3:
      return "Unrestricted";
    case 4:
      return "Node Locked";
    default:
      return null;
  }
};

export const licenseTypeTemplates = [
  {
    product_type_id: 1, // desktop
    license_types: [
      {
        id: 1,
        name: "Named User",
        primary_entitlements: ["UT"],
        primary_restrictions: ["GP", "IU", "IA"],
        /* conversion restrictions will be used on Edit license dialog when
         we want to convert from one enforcement model to another  */
        conversion_restrictions: [1, 2, 3] // allowed license types ids -> this one includes itself and Concurrent + Unrestricted
      },
      {
        id: 2,
        name: "Concurrent",
        primary_entitlements: ["ST"],
        primary_restrictions: ["SL", "SU", "SI", "SUI", "IA"],
        conversion_restrictions: [2, 3]
      },
      {
        id: 3,
        name: "Unrestricted",
        primary_entitlements: [],
        primary_restrictions: ["GP", "IA"],
        conversion_restrictions: [3]
      },
      {
        id: 4,
        name: "Node Locked",
        primary_entitlements: ["IT"],
        primary_restrictions: ["GP", "UI", "SU", "SI", "SUI"],
        conversion_restrictions: [2, 3, 4]
      }
    ]
  },
  {
    product_type_id: 2, // server
    license_types: [
      {
        id: 4,
        name: "Node Locked",
        primary_entitlements: ["IT"],
        primary_restrictions: ["GP"],
        conversion_restrictions: [4]
      }
    ]
  },
  {
    product_type_id: 3, // web app
    license_types: [
      {
        id: 1,
        name: "Named User",
        primary_entitlements: ["IT", "UT"],
        primary_restrictions: ["GP"],
        conversion_restrictions: [1, 2, 3]
      },
      {
        id: 2,
        name: "Concurrent",
        primary_entitlements: ["IT", "ST"],
        primary_restrictions: ["SL", "SU"],
        conversion_restrictions: [2, 3]
      },
      {
        id: 3,
        name: "Unrestricted",
        primary_entitlements: ["IT"],
        primary_restrictions: ["GP"],
        conversion_restrictions: [3]
      }
    ]
  },
  {
    product_type_id: 4, // web API
    license_types: [
      {
        id: 3,
        name: "Unrestricted",
        primary_entitlements: [],
        primary_restrictions: [],
        conversion_restrictions: [3]
      }
    ]
  }
];
export const getStaticMediaFile = (opts: { url: string }) => {
  if (!_.isObject(opts)) {
    return "";
  }
  const url = opts.url || "";
  const isAbsoluteUrl = url.includes("http") || url.includes("https");
  const startsWithSlash = url.startsWith("/");
  return isAbsoluteUrl
    ? url
    : `${appConfig.gateway}${startsWithSlash ? "" : "/"}${url}`;
};
export const componentEntitlements = [
  {
    id: 1,
    label: "Checked-out Token"
  },
  {
    id: 2,
    label: "Session"
  },
  {
    id: 3,
    label: "Consumable Token"
  }
];

export const userRoles = [
  {
    label: "Owner",
    value: "owner"
  },
  {
    label: "Administrator",
    value: "admin"
  },
  {
    label: "Product administrator",
    value: "product_admin"
  },
  {
    label: "License administrator",
    value: "license_admin"
  },
  {
    label: "License maintainer",
    value: "license_maintainer"
  },
  {
    label: "Stakeholder",
    value: "stakeholder"
  }
];
export const componentTypeToId = (name: string) => {
  const enforcementType = _.find(componentEntitlements, entitlement =>
    _.includes(entitlement.label.toLowerCase(), name.toLowerCase())
  );
  if (enforcementType) {
    return enforcementType.id;
  }
  return null;
};
export const getComponentEntitlement = (typeId: number) =>
  _.find(componentEntitlements, entitlement => entitlement.id === typeId);
export const getComponentEntitlementName = (typeId: number) => {
  const entitlement = getComponentEntitlement(typeId);
  return entitlement ? entitlement.label : "";
};
export const getAllDates3MonthsFromNow = () => {
  const monthsInDays = 90;
  return _.map(_.range(monthsInDays), day => moment().add(day, "d"));
};
export const getTermDetails = (
  timestamp: string,
  config?: {
    commercialModel?: commercialModel;
    dateFormat?: string;
  }
) => {
  const now = moment();
  const d = moment(timestamp);
  const date = d.format(config?.dateFormat || DATE_FORMAT);
  const canExpire =
    config?.commercialModel !== commercialModel.perpetualMS &&
    config?.commercialModel !== commercialModel.freeware;
  const isExpired = canExpire ? (timestamp ? now.isAfter(d) : true) : false;
  const diffInYears = moment.duration(d.diff(now)).asYears();
  const diffInMonths = moment.duration(d.diff(now)).asMonths();
  const diffInDays = moment.duration(d.diff(now)).asDays();
  const is3Months = !isExpired && diffInMonths <= 3;

  return {
    isExpired,
    is3Months,
    date,
    diffInDays,
    diffInMonths,
    diffInYears,
    momentObject: d,
    humanized: moment.duration(d.diff(now)).humanize(true),
    duration: moment.duration(d.diff(now))
  };
};
export const isFutureTerm = (timestamp: string) => {
  return moment().isBefore(moment(timestamp));
};
export const addNamespace = <T extends object>(
  types: T,
  namespace: string
): T => {
  return _.reduce(
    types,
    (namespaceTypes, value, key) => {
      namespaceTypes[key] = `${namespace}/reducer/${value}`;
      return namespaceTypes;
    },
    {}
  ) as typeof types;
};

export const getBase64 = (
  img: File | Blob,
  callback: (val: string, img: File | Blob) => void
) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string, img));
  reader.readAsDataURL(img);
};

export const fileActionHandler = (
  file: RcFile,
  callbackFn: (
    val: string,
    img: File | Blob,
    res: (url: string) => void
  ) => void
) => {
  return new Promise((res: (url: string) => void, rej) => {
    getBase64(file, (imageUrl, image) => callbackFn(imageUrl, image, res));
  });
};

export const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Please select either a JPG or a PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Please make sure that the image is smaller than 2 MB");
  }
  return isJpgOrPng && isLt2M;
};

export const getTextWidth: any = (text: string) => {
  let canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = "13px Inter";
  let metrics = context.measureText(text);
  return metrics.width;
};

const formatting_options = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0
};
export const dollarString = new Intl.NumberFormat("en-US", formatting_options);
interface IUpdateBladeUrlParams {
  updateBladeManagerState: (props: IUpdateBladeManagerUrl) => void;
  index: number;
}
export const updateBladeUrlState = (
  props: IUpdateBladeUrlParams,
  activeTabIndex: number
) => {
  const { updateBladeManagerState, index: bladeIndex = 0 } = props;
  updateBladeManagerState({
    tabIndex: activeTabIndex,
    bladeIndex: bladeIndex
  });
};
export const searchInObjectArray = <T extends object>(
  query: string,
  data: T[] | readonly T[]
): T[] => {
  return data.filter(item => {
    const isMatch = Object.values(item).some(value => {
      if (typeof value === "object" && value !== null) {
        return searchInObjectArray(query, [value]).length > 0;
      } else {
        return `${value}`?.toLowerCase().includes(query.toLowerCase());
      }
    });
    return isMatch;
  });
};

const retVal = {
  getCountryName,
  addNamespace,
  componentEntitlements,
  getComponentEntitlement,
  getStaticMediaFile,
  isComponentIncludedInVersion,
  UserPlaceHolder,
  LicensePlaceHolderLogo,
  licenseTypeToName,
  licenseTypeTemplates,
  asyncStorage,
  getAllDates3MonthsFromNow,
  getTermDetails,
  tableGenerator,
  getBladeColor,
  isTestEnvironment,
  toSuperScript,
  fileActionHandler,
  getTextWidth,
  userRoles,
  updateBladeUrlState
};
export default retVal;
