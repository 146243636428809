import { notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import * as React from "react";
import { createFragmentContainer } from "react-relay";
import {
  AttributesBox,
  IAttributValuePair,
  IAttributesBoxProps
} from "../attributesBox";
import { getLink } from "../helpers";
import { instanceAttribute_instance } from "./__generated__/instanceAttribute_instance.graphql";

export interface ILicenseAttributeProps extends IAttributesBoxProps {
  instance: instanceAttribute_instance;
  onClickProduct?: (
    product: instanceAttribute_instance["license"]["product"]
  ) => void;
}

export class InstanceAttribute extends React.Component<ILicenseAttributeProps> {
  public handleProductLick = () => {
    if (_.isFunction(this.props.onClickProduct)) {
      this.props.onClickProduct(this.props.instance.license.product);
    }
  };
  public openCopiedMessage = () => {
    notification.open({
      message: "Success",
      description: "License copied to clipboard"
    });
  };
  public getItems = (): IAttributValuePair[] => {
    if (!_.isObject(this.props.instance)) {
      return null;
    }
    const ProductLink = getLink({
      label: this.props.instance.license.product.name,
      linkType: "Product",
      onClick: this.handleProductLick
    });

    const getOthers = () => {
      let keys = _.keys(this.props.instance.hardwareIdentifier.info);
      keys = _.without(keys, "macAddresses", "ipAddress");
      if (!keys.length) {
        return [];
      }
      return _.map(keys, key => {
        return {
          label: _.startCase(key),
          value: this.props.instance.hardwareIdentifier.info[key]
        };
      });
    };
    return [
      {
        label: "Product",
        value: ProductLink
      },
      ...getOthers()
    ];
  };

  public render() {
    return <AttributesBox items={this.getItems()} mode={this.props.mode} />;
  }
}

export default createFragmentContainer(InstanceAttribute, {
  instance: graphql`
    fragment instanceAttribute_instance on Instance {
      guid
      hardwareIdentifier {
        info
      }
      license {
        product {
          id
          name
        }
      }
    }
  `
});
