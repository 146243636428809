import * as _ from "lodash";
import { getGateway } from "../../../api";
import {
  addNamespace,
  saveUserRoleToLocal,
  getSavedRoleFromLocal,
  saveActiveModeToLocal,
  getActiveModeFromLocalSync,
  getSavedRoleFromLocalSync,
  saveUserRoleTypeToLocalSync,
  clearUserRoleFromLocalSync
} from "../../../utils";
import { actions as bladeManagerActions } from "../bladeManager";
import { createSelector } from "reselect";
import { IStore, Action, IUserRole, IOnboardingState } from "../../types";
import { combineReducers, Reducer, CombinedState } from "redux";
import { all, delay, put, select, takeLatest } from "redux-saga/effects";
import { EmailNotification } from "components/bladeManager/types";
import { setOnboardingConfigSync } from "blades/Welcome/utils/storage";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";

export interface ILicenseModel {
  number_of_installs: number;
  number_of_users: number;
  max_instances_per_user: number;
  default_token_duration: number;
  owner: Owner;
  product_id: string;
  license_type_id: number;
  is_trial: boolean;
  is_vendor: boolean;
  owner_id: string;
  owner_type: string;
  user_count: number;
  grace_period: number;
  instance_count: number;
  session_count: number;
  instances_per_user: number;
  users_per_instance: number;
  sessions_per_user: number;
  sessions_per_instance: number;
  sessions_per_user_per_instance: number;
  require_email_activation: boolean;
}
export interface IEditLicenseRequest {
  number_of_users: number;
  max_instances_per_user: number;
  default_token_duration: number;
  owner: Owner;
  notify_customer: boolean;
  product_id: string;
  license_type_id: number;
  is_trial: boolean;
  is_vendor: boolean;
  owner_id: string;
  owner_type: string;
  user_count: number;
  grace_period: number;
  instance_count: number;
  session_count: number;
  instances_per_user: number;
  users_per_instance: number;
  sessions_per_user: number;
  sessions_per_instance: number;
  sessions_per_user_per_instance: number;
  require_email_activation: boolean;
}

export interface Owner {
  name: string;
  owner_type: string;
  owner_id: string;
}

const appSelector = (state: IStore, ownProps: any) => state.app;

const activeModeSelector = createSelector(
  appSelector,
  appState => appState.activeMode
);

const userDefinedModeSelector = createSelector(
  appSelector,
  appState => appState.userDefinedMode
);

const onboardingModeSelector = createSelector(
  appSelector,
  appState => appState.onboardingMode
);

const emailPreferencesSelector = createSelector(
  appSelector,
  appState => appState.emailPreferences
);

const userRolesSelector = createSelector(
  appSelector,
  appState => appState.userRoles
);

const activeRoleSelector = createSelector(
  appSelector,
  appState => appState.activeRole
);
const activeRoleTokenSelector = createSelector(
  activeRoleSelector,
  activeRole => activeRole.token
);
const activeRoleTypeSelector = createSelector(
  activeRoleSelector,
  activeRole => activeRole.type
);

const activeUserPermissions = createSelector(
  activeRoleSelector,
  activeRole => activeRole.permissions
);

const companiesFromRolesSelector = createSelector(
  userRolesSelector,
  userRoles => {
    const names = _.map(userRoles, role => role.name);
    return _.uniq(names);
  }
);
const currentRolesForCompanySelector = createSelector(
  activeRoleSelector,
  userRolesSelector,
  (activeRole, userRoles) => {
    return _.filter(userRoles, role => role.name === activeRole.name);
  }
);

const dialogsSelector = createSelector(appSelector, app => app.dialogs);
const newLicenseDialogsSelector = createSelector(
  dialogsSelector,
  dialogsState => dialogsState.newLicense
);

const actionDefinitions = addNamespace(
  {
    createNewCompany: "createNewCompany",
    createNewLicense: "createNewLicense",
    createNewLicenseTerm: "createNewLicenseTerm",
    updateAppActiveRole: "updateActiveRole",
    reportUpdateActiveRoleUpdate: "reportUpdateActiveRoleUpdate",
    getUserRoles: "getUserRoles",
    saveUserRoles: "saveUserRoles",
    clearUserRoles: "clearUserRoles",
    clearActiveRole: "clearActiveRole",
    updateActiveCompany: "updateActiveCompany",
    updateDialogState: "updateDialogState",
    updateActiveMode: "updateActiveMode",
    updateUserDefinedMode: "updateUserDefinedMode",
    getEmailPreferences: "getEmailPreferences",
    saveEmailPreferences: "saveEmailPreferences",
    updateEmailPreferences: "updateEmailPreferences",
    onboardingStart: "onboardingStart",
    onboardingPause: "onboardingPause",
    onboardingContinue: "onboardingContinue",
    onboardingNextStep: "onboardingNextStep",
    onboardingEnd: "onboardingEnd",
    onboardingCompleted: "onboardingCompleted",
    onboardingSkip: "onboardingSkip",
    onboardingDefer: "onboardingDefer"
  },
  "app"
);

const actions = {
  createNewCompany: (data: {
    name: string;
    contact_name: string;
    contact_phone: string;
    contact_email: string;
  }) => ({
    data,
    type: actionDefinitions.createNewCompany
  }),
  createNewLicense: (data: {
    licenseRequest: ILicenseModel;
    termRequest: {
      start_date: string;
      end_date: string;
    };
  }) => ({
    data,
    type: actionDefinitions.createNewLicense
  }),
  createNewLicenseTerm: (data: {
    start_date: string;
    end_date: string;
    termable_id: number;
    termable_type: string;
  }) => ({
    data,
    type: actionDefinitions.createNewLicenseTerm
  }),
  getUserRoles: () => ({
    type: actionDefinitions.getUserRoles
  }),
  clearUserRoles: () => ({
    type: actionDefinitions.clearUserRoles
  }),
  saveUserRoles: (data: IUserRole[]) => ({
    data,
    type: actionDefinitions.saveUserRoles
  }),
  saveEmailPreferences: (data: EmailNotification[]) => ({
    data,
    type: actionDefinitions.saveEmailPreferences
  }),
  getEmailPreferences: () => ({
    type: actionDefinitions.getEmailPreferences
  }),
  updateEmailPreferences: (
    data: EmailNotification[],
    id: string,
    refreshFn: () => void
  ) => ({
    data,
    id,
    refreshFn,
    type: actionDefinitions.updateEmailPreferences
  }),
  updateActiveCompany: (data: string) => ({
    data,
    type: actionDefinitions.updateActiveCompany
  }),
  updateActiveMode: (data: string) => ({
    data,
    type: actionDefinitions.updateActiveMode
  }),
  updateUserDefinedMode: (data: boolean) => ({
    data,
    type: actionDefinitions.updateUserDefinedMode
  }),
  onboardingStart: (data: IOnboardingState["type"]) => ({
    data,
    type: actionDefinitions.onboardingStart
  }),
  onboardingPause: () => ({
    type: actionDefinitions.onboardingPause
  }),
  onboardingContinue: () => ({
    type: actionDefinitions.onboardingContinue
  }),
  onboardingEnd: () => ({
    type: actionDefinitions.onboardingEnd
  }),
  onboardingCompleted: (
    data?: { productId: string } | { companyId: string }
  ) => ({
    data,
    type: actionDefinitions.onboardingCompleted
  }),
  onboardingSkip: () => ({
    type: actionDefinitions.onboardingSkip
  }),
  onboardingDefer: () => ({
    type: actionDefinitions.onboardingDefer
  }),
  updateDialogState: (data: {
    key: keyof IStore["app"]["dialogs"];
    state: any;
  }) => ({
    data,
    type: actionDefinitions.updateDialogState
  }),
  updateActiveRole: (data: IUserRole) => ({
    data,
    type: actionDefinitions.updateAppActiveRole
  }),
  clearActiveRole: () => ({
    type: actionDefinitions.clearActiveRole
  }),
  reportUpdateActiveRoleUpdate: () => ({
    type: actionDefinitions.reportUpdateActiveRoleUpdate
  })
};

const activeModeReducer = (
  state: IStore["app"]["activeMode"],
  action: ReturnType<typeof actions["updateActiveMode"]>
): string => {
  const isDarkMode = window?.matchMedia
    ? window?.matchMedia("(prefers-color-scheme: dark)").matches
    : "light";
  let mode = isDarkMode ? "dark" : "light"; //initial system preferences

  const localStorageMode = getActiveModeFromLocalSync();

  if (localStorageMode) {
    mode = localStorageMode;
  }

  if (action.type === actionDefinitions.updateActiveMode) {
    return action.data || state || mode;
  }
  return state || mode;
};
const userDefinedModeReducer = (
  state: IStore["app"]["userDefinedMode"],
  action: ReturnType<typeof actions["updateUserDefinedMode"]>
): boolean => {
  const localStorageMode = getActiveModeFromLocalSync();
  const existInLocalStorage = localStorageMode ? true : false;

  if (action.type === actionDefinitions.updateUserDefinedMode) {
    return action.data || state || existInLocalStorage;
  }

  return state || existInLocalStorage;
};
const activeRoleReducer = (
  state: IStore["app"]["activeRole"],
  action: ReturnType<typeof actions["updateActiveRole"]>
): IUserRole => {
  if (action.type === actionDefinitions.updateAppActiveRole) {
    return action.data || state || ({} as IUserRole);
  }
  if (action.type === actionDefinitions.clearActiveRole) {
    return {} as IUserRole;
  }
  return state || ({} as IUserRole);
};
const userRolesReducer = (
  state: IStore["app"]["userRoles"],
  action: Action<typeof actionDefinitions["saveUserRoles"], IUserRole[]>
) => {
  if (action.type === actionDefinitions.saveUserRoles) {
    return action.data || [];
  }
  return state || [];
};
const emailPreferencesReducer = (
  state: IStore["app"]["emailPreferences"],
  action: Action<
    typeof actionDefinitions["saveEmailPreferences"],
    EmailNotification[]
  >
) => {
  if (action.type === actionDefinitions.saveEmailPreferences) {
    return action.data || [];
  }
  return state || [];
};
const onboardingModeReducer = (
  state: IStore["app"]["onboardingMode"] = {
    open: false,
    index: 0,
    status: null,
    type: null
  },
  action: ReturnType<
    | typeof actions["onboardingStart"]
    | typeof actions["onboardingPause"]
    | typeof actions["onboardingContinue"]
    | typeof actions["onboardingEnd"]
    | typeof actions["onboardingCompleted"]
    | typeof actions["onboardingSkip"]
    | typeof actions["onboardingDefer"]
  >
): IOnboardingState => {
  switch (action.type) {
    case actionDefinitions.onboardingStart:
      return { status: "active", type: action?.data, open: true, index: 0 };
    case actionDefinitions.onboardingPause:
      return { ...state, open: false };
    case actionDefinitions.onboardingContinue:
      return { ...state, open: true, index: state.index + 1 };
    case actionDefinitions.onboardingEnd:
      return { status: null, type: null, open: false, index: 0 };
    case actionDefinitions.onboardingCompleted:
      setOnboardingConfigSync({
        ...state,
        status: "completed",
        productId: action?.data?.productId,
        companyId: action?.data?.companyId
      });
      return {
        status: null,
        type: null,
        open: false,
        index: 0,
        productId: action?.data?.productId,
        companyId: action?.data?.companyId
      };
    case actionDefinitions.onboardingSkip:
      setOnboardingConfigSync({ ...state, type: "Status", status: "skip" });
      return { ...state, type: "Status", status: "skip" };
    case actionDefinitions.onboardingDefer:
      setOnboardingConfigSync({ ...state, type: "Status", status: "pending" });
      return { ...state, type: "Status", status: "pending" };
    default:
      return state;
  }
};
const dialogReducer = (
  state: IStore["app"]["dialogs"],
  action: ReturnType<typeof actions["updateDialogState"]>
): IStore["app"]["dialogs"] => {
  if (action.type === actionDefinitions.updateDialogState) {
    if (state[action.data.key]) {
      const newState = { ...state };
      newState[action.data.key] = action.data.state;
      return newState;
    }
  }
  return (
    state || {
      newLicense: {
        error: null
      }
    }
  );
};
const reducer: Reducer<CombinedState<IStore["app"]>> = combineReducers({
  activeRole: activeRoleReducer,
  userRoles: userRolesReducer,
  dialogs: dialogReducer,
  activeMode: activeModeReducer,
  userDefinedMode: userDefinedModeReducer,
  emailPreferences: emailPreferencesReducer,
  onboardingMode: onboardingModeReducer
});
const selectors = {
  appSelector,
  activeRoleTokenSelector,
  activeRoleTypeSelector,
  dialogsSelector,
  newLicenseDialogsSelector,
  userRolesSelector,
  activeRoleSelector,
  companiesFromRolesSelector,
  currentRolesForCompanySelector,
  activeModeSelector,
  userDefinedModeSelector,
  onboardingModeSelector,
  emailPreferencesSelector,
  activeUserPermissions
};

// used to get new active role on company switch or saving using roles
const getNewActiveRole = (payload: {
  roles: IUserRole[];
  previousRole: IUserRole;
}) => {
  let newRole = _.find(
    payload.roles,
    role => role.token === _.get(payload, "previousRole.token")
  );
  if (!!!newRole) {
    newRole = _.find(
      payload.roles,
      role =>
        role.type === _.get(payload, "previousRole.type") &&
        role.name === _.get(payload, "previousRole.name")
    );
  }
  // temporarily included localRole because saveUserRoleSaga is failing on app reload in staging environment only.
  return newRole || payload.roles[0] || getSavedRoleFromLocalSync();
};

function* getUserRolesSaga() {
  const gateway = getGateway();
  const response = yield gateway.request.getUserRoles();
  const userRoles = _.get(response, "data.roles") as IUserRole[];
  yield put(actions.saveUserRoles(userRoles));
}
function* clearUserRolesSaga() {
  yield put(actions.saveUserRoles([]));
}
function* clearActiveRoleSaga() {
  yield all([put(actions.clearActiveRole()), clearUserRoleFromLocalSync()]);
}
function* getEmailPreferencesSaga() {
  const gateway = getGateway();
  const response = yield gateway.request.getNotifications();
  const emailPreferences = _.get(
    response,
    "data.email_preferences"
  ) as EmailNotification[];
  yield put(actions.saveEmailPreferences(emailPreferences));
}
function* onUpdateEmailPreferencesSaga(
  action: ReturnType<typeof actions.updateEmailPreferences>
) {
  const gateway = getGateway();
  yield gateway.request
    .editNotifications(
      {
        preferences: {
          email_preference: action.data
        }
      },
      {
        id: action.id
      }
    )
    .then(res => {
      if (res?.error) {
        renderErrorNotification("Failed to update preferences !");
      }
      renderSuccessNotification("Updated Email preferences !");
      action?.refreshFn();
    })
    .catch(e => {
      renderErrorNotification("Failed to update preferences !");
      console.error(e);
    });
  yield put(actions.saveEmailPreferences(action.data));
}
function* onSaveUserRolesSaga(
  action: ReturnType<typeof actions.saveUserRoles>
) {
  const savedRole = yield getSavedRoleFromLocal();
  const role = getNewActiveRole({
    previousRole: savedRole,
    roles: action.data
  });
  yield all([put(actions.updateActiveRole(role)), saveUserRoleToLocal(role)]);
}
function* onUpdateActiveModeSaga(
  action: ReturnType<typeof actions.updateActiveMode>
) {
  yield all([saveActiveModeToLocal(action.data)]);
}
function* onUpdateCompanyNameSaga(
  action: ReturnType<typeof actions.updateActiveCompany>
) {
  const roles: IUserRole[] = yield select(state =>
    selectors.userRolesSelector(state, {})
  );
  const previousRole: IUserRole = yield select(state =>
    selectors.activeRoleSelector(state, {})
  );
  const newRoles: IUserRole[] = _.filter(
    roles,
    role => role.token === action.data
  );
  const newRole = getNewActiveRole({
    previousRole,
    roles: newRoles
  });
  yield all([
    put(actions.updateActiveRole(newRole)),
    saveUserRoleTypeToLocalSync(newRole.type),
    saveUserRoleToLocal(newRole)
  ]);
}
function* onUpdateActiveRoleSaga() {
  yield delay(500);
  yield put(actions.reportUpdateActiveRoleUpdate());
}
function* createNewCompanySaga(
  action: ReturnType<typeof actions["createNewCompany"]>
) {
  const gateway = getGateway();
  yield gateway.request.newCompany(action.data);
}
function* createNewLicenseSaga(
  action: ReturnType<typeof actions["createNewLicense"]>
) {
  const gateway = getGateway();
  const res = yield gateway.request.newLicense(action.data.licenseRequest);
  if (res.error) {
    yield put(
      bladeManagerActions.openDialog({
        name: "ErrorDialog",
        payload: {
          description: _.get(res, "data[0]"),
          onConfirm: () => null
        }
      })
    );
  } else {
    const termableId = _.get(res, "data.id");
    yield put(
      actions.createNewLicenseTerm({
        ...action.data.termRequest,
        termable_type: "License",
        termable_id: _.isFinite(termableId) ? termableId : -1
      })
    );
  }
}
function* createNewLicenseTermSaga(
  action: ReturnType<typeof actions["createNewLicenseTerm"]>
) {
  const gateway = getGateway();
  yield gateway.request.newLicenseTerm(action.data);
}
function* saga() {
  try {
    yield all([
      takeLatest(actionDefinitions.getUserRoles, getUserRolesSaga),
      takeLatest(
        actionDefinitions.updateActiveCompany,
        onUpdateCompanyNameSaga
      ),
      takeLatest(
        actionDefinitions.getEmailPreferences,
        getEmailPreferencesSaga
      ),
      takeLatest(actionDefinitions.saveUserRoles, onSaveUserRolesSaga),
      takeLatest(
        actionDefinitions.updateEmailPreferences,
        onUpdateEmailPreferencesSaga
      ),
      takeLatest(actionDefinitions.updateAppActiveRole, onUpdateActiveRoleSaga),
      takeLatest(actionDefinitions.createNewCompany, createNewCompanySaga),
      takeLatest(actionDefinitions.createNewLicense, createNewLicenseSaga),
      takeLatest(
        actionDefinitions.createNewLicenseTerm,
        createNewLicenseTermSaga
      ),
      takeLatest(actionDefinitions.updateActiveMode, onUpdateActiveModeSaga),
      takeLatest(actionDefinitions.clearUserRoles, clearActiveRoleSaga),
      takeLatest(actionDefinitions.clearUserRoles, clearUserRolesSaga)
    ]);
  } catch (e) {
    console.error(e);
  }
}

export { actionDefinitions, actions, reducer, saga, selectors };
