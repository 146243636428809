import { Radio } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import theme from "../../../../theme";
import { ElmButton } from "../../../elmButton";
import { ElmCheckBox } from "../../../elmCheckBox";
import { ElmInput } from "../../../elmInput";
import { DialogBase } from "../base";
import { IModalProps } from "../types";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 465px;
  min-height: 570px;
`;

const ScopeName = styled.span`
  color: ${props => props.theme.colors.pureBlack};
  font-size: 14px;
  text-transform: capitalize;
  margin-top: 24px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const DialogSubTitle = styled.p`
  ${props => props.theme.fontType.visualizationSubTitle}
`;
export interface IEditApiTokenDialogProps {
  scopes: { description: string; id: string }[];
  selectedScopes: string[];
  isEdit?: boolean;
}
export interface IEditApiTokenDialogState {
  scopes: { description: string; id: string }[];
  scopeAccessType: "full" | "custom";
  name: string;
}
export class EditApiTokenDialog extends DialogBase<
  IModalProps<IEditApiTokenDialogProps>,
  IEditApiTokenDialogState
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          //width: "465px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public getDefaultSelectedScopes = () => {
    const selectedScopes = _.get(this.props.payload, "selectedScopes");
    const scopes = _.get(this.props.payload, "scopes");
    if (_.isArray(scopes)) {
      return _.filter(scopes, scope => _.includes(selectedScopes, scope.id));
    }
    return [];
  };
  state: IEditApiTokenDialogState = {
    scopes: this.getDefaultSelectedScopes(),
    scopeAccessType: "custom",
    name: ""
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditApiTokenDialog"
    });
  };
  public createApiToken = () => {
    if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
      this.props.payload.onConfirm({ ...this.state });
    }
  };
  public getTitle = () => {
    return this.props?.payload?.isEdit ? "Edit Api Token" : "Add token";
  };
  public getDescription = () => {
    return "";
    // return _.get(this.props.payload, 'description');
  };

  public getScopes = () => {
    if (
      !_.isObject(this.props.payload) ||
      !_.isArray(this.props.payload.scopes)
    ) {
      return [];
    }

    let scopes = {};
    this.props.payload.scopes.forEach(item => {
      const sectionName = item.description.slice(
        0,
        item.description.indexOf("(") - 1
      );
      const action = item.description.slice(item.description.indexOf("("));
      if (scopes[sectionName])
        scopes[sectionName] = [
          ...scopes[sectionName],
          { id: item.id, description: action }
        ];
      else scopes[sectionName] = [{ id: item.id, description: action }];
    });
    return scopes;
  };
  public handleScopeSelectionChange = (scope: {
    id: string;
    description: string;
  }) => (e: CheckboxChangeEvent) => {
    const newScopes = _.cloneDeep(this.state.scopes);
    if (e.target.checked) {
      this.setState({ scopes: [...newScopes, scope] });
    } else {
      this.setState({ scopes: _.filter(newScopes, s => s.id !== scope.id) });
    }
  };

  public getUpdateFunction = (type: "name") => (val: string) => {
    this.setState({ [type]: val });
  };

  public render() {
    const scopes = this.getScopes();
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <DialogSubTitle>{this.getDescription()}</DialogSubTitle>
        <Flex>
          <ElmInput
            label={"Name"}
            disabled={this.props?.payload?.isEdit ? true : false}
            controlled={true}
            defaultValue={_.get(this.props.payload, "description")}
            updatedValue={this.getUpdateFunction("name")}
          />
        </Flex>
        <Flex flexDirection="column" alignItems="flex-start">
          <span style={{ fontWeight: 700, marginTop: "10px" }}>
            Authorized Scopes:{" "}
          </span>
          <span style={{ fontSize: "12px", color: theme.colors.warmGrey }}>
            Authorize the scope of access associated with this token
          </span>
          <Flex style={{ marginTop: "12px" }} flex="1">
            <Radio.Group
              style={{ width: "100%" }}
              value={this.state.scopeAccessType}
              onChange={e => {
                this.setState({
                  ...this.state,
                  scopeAccessType: e.target.value
                });
              }}
            >
              <Radio value={"full"}>Full access</Radio>
              <Radio value={"custom"}>Custom access</Radio>
            </Radio.Group>
          </Flex>
          {this.state.scopeAccessType === "custom" && (
            <Flex style={{ marginBottom: "15px" }} flexDirection="column">
              {Object.keys(scopes).map(scope => {
                return (
                  <Flex flexDirection="column">
                    <Flex>
                      <ScopeName>{scope}</ScopeName>
                    </Flex>
                    <Flex flexDirection="row" style={{ marginTop: "12px" }}>
                      {scopes[scope].map(scopeItem => (
                        <Flex style={{ marginRight: "10px" }}>
                          <ElmCheckBox
                            //value={scopeItem.id}
                            onChange={this.handleScopeSelectionChange(
                              scopeItem
                            )}
                            defaultChecked={_.includes(
                              this.props.payload.selectedScopes,
                              scopeItem.id
                            )}
                          />
                          <span style={{ marginLeft: "5px", fontSize: "13px" }}>
                            {scopeItem.description}
                          </span>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          )}
        </Flex>
        <Flex justifyContent={"flex-end"} alignItems="flex-end" flex="1">
          <ElmButton
            label={"Cancel"}
            colorVariance="outline-secondary"
            onClick={this.closeModal}
          />
          <ElmButton
            colorVariance="primary"
            label={"Confirm"}
            onClick={this.createApiToken}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
