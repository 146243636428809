import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import { ElmInput } from "../../../elmInput";
import { getGateway } from "../../../../api";
import { IOnboardingState } from "store/types";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
export interface IEditProfileDialogProps {
  id: string;
  userData: {
    name: string;
    email: string;
    avatar_url: string;
    thumb_url: string;
    role: string;
  };
}

interface IEditDialogState {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  thumb_url: string;
  role: string;
  isDisabled: boolean;
}

export class EditProfileDialog extends DialogBase<
  IModalProps<IEditProfileDialogProps> & {
    appState?: { onboardingMode: IOnboardingState };
  }
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          minHeight: "320px",
          width: "330px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          border: "none"
        }
      }
    });
  }
  static getAppConnectOptions() {
    return super.getAppConnectOptions({
      selectors: {
        onboardingMode: "onboardingModeSelector"
      }
    });
  }
  state: IEditDialogState = {
    id: this.props.payload.id,
    name: this.props.payload.userData.name,
    email: this.props.payload.userData.email,
    avatar_url: this.props.payload.userData.avatar_url,
    thumb_url: this.props.payload.userData.thumb_url,
    role: this.props.payload.userData.role,
    isDisabled: false
  };
  componentDidMount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingPause();
  }
  componentWillUnmount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingContinue();
  }
  public checkOnboardingMode = () => {
    return this.props.appState?.onboardingMode?.status === "active";
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditProfileDialog"
    });
  };
  public editProfile = () => {
    const gateway = getGateway();
    this.setState({ isDisabled: true }, () => {
      gateway.request
        .editAdminUser(
          {
            email: this.state.email,
            name: this.state.name,
            avatar_url: this.state.avatar_url,
            thumb_url: this.state.thumb_url
          },
          {
            email: this.state.email,
            name: this.state.name,
            avatar_url: this.state.avatar_url,
            thumb_url: this.state.thumb_url,
            id: this.state.id
          }
        )
        .then(res => {
          if (res.data) {
            if (res && !res.error) {
              if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
                this.props.payload.onConfirm(this.state);
              }
            }
            this.setState({ isDisabled: false });
          }
        })
        .catch(error => {
          console.error(error);
        });
    });
  };
  public getTitle = () => {
    return "Edit Profile";
  };

  public updateEmail = (email: string) => {
    this.setState({ email });
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };
  public render() {
    const { name, email, isDisabled } = this.state;
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <Flex>
          <ElmInput
            label={"Name"}
            placeholder="John Doe"
            style={{ marginTop: "15px" }}
            disabled={false}
            defaultValue={name}
            updatedValue={this.updateName}
          />
        </Flex>
        {/* <Flex>
          <ElmInput
            label={"Email"}
            placeholder="john@doe.com"
            style={{ marginTop: "15px" }}
            disabled={false}
            defaultValue={email}
            updatedValue={this.updateEmail}
          />
        </Flex> */}

        <Flex flex={1} alignItems="flex-end" justifyContent={"flex-end"}>
          {this.checkOnboardingMode() ? null : (
            <ElmButton
              label={"Cancel"}
              colorVariance={"outline-secondary"}
              onClick={this.closeModal}
            />
          )}
          <ElmButton
            style={{ minWidth: "127px" }}
            colorVariance="primary"
            label={"Save Changes"}
            onClick={this.editProfile}
            disabled={isDisabled}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
