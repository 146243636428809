/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userInstallsTablePaginationQueryVariables = {
  id: string;
  count: number;
  cursor?: string | null | undefined;
  search?: string | null | undefined;
  blade_scope?: string | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
};
export type userInstallsTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"userInstallsTable_installs">;
};
export type userInstallsTablePaginationQuery = {
  readonly response: userInstallsTablePaginationQueryResponse;
  readonly variables: userInstallsTablePaginationQueryVariables;
};

/*
query userInstallsTablePaginationQuery(
  $id: ID!
  $count: Int!
  $cursor: String
  $search: String
  $blade_scope: String
  $sortColumn: [String!]
  $sortDirection: [String!]
) {
  ...userInstallsTable_installs_30LSkJ
}

fragment userInstallsTable_installs_30LSkJ on Query {
  user(id: $id) {
    installs: instances(first: $count, after: $cursor, search: $search, bladeScope: $blade_scope, sortColumn: $sortColumn, sortDirection: $sortDirection) {
      edges {
        node {
          id
          guid
          license {
            product {
              name
              id
            }
            id
          }
          hostName
          validationEntries(last: 1) {
            nodes {
              createdAt
              version
            }
          }
          active
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "blade_scope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v6 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v7 = {
      kind: "Variable",
      name: "id",
      variableName: "id"
    } as any,
    v8 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v9 = {
      kind: "Variable",
      name: "sortColumn",
      variableName: "sortColumn"
    } as any,
    v10 = {
      kind: "Variable",
      name: "sortDirection",
      variableName: "sortDirection"
    } as any,
    v11 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "blade_scope"
      } as any,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      v8 /*: any*/,
      v9 /*: any*/,
      v10 /*: any*/
    ],
    v12 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "userInstallsTablePaginationQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "blade_scope",
              variableName: "blade_scope"
            },
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v7 /*: any*/,
            v8 /*: any*/,
            v9 /*: any*/,
            v10 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "userInstallsTable_installs"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v3 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v4 /*: any*/,
        v0 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/
      ],
      kind: "Operation",
      name: "userInstallsTablePaginationQuery",
      selections: [
        {
          alias: null,
          args: [v7 /*: any*/],
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            {
              alias: "installs",
              args: v11 /*: any*/,
              concreteType: "InstanceConnection",
              kind: "LinkedField",
              name: "instances",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "InstanceEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Instance",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v12 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "guid",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: "License",
                          kind: "LinkedField",
                          name: "license",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "Product",
                              kind: "LinkedField",
                              name: "product",
                              plural: false,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "name",
                                  storageKey: null
                                },
                                v12 /*: any*/
                              ],
                              storageKey: null
                            },
                            v12 /*: any*/
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "hostName",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: [
                            {
                              kind: "Literal",
                              name: "last",
                              value: 1
                            }
                          ],
                          concreteType: "ValidationEntryConnection",
                          kind: "LinkedField",
                          name: "validationEntries",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "ValidationEntry",
                              kind: "LinkedField",
                              name: "nodes",
                              plural: true,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "createdAt",
                                  storageKey: null
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "version",
                                  storageKey: null
                                }
                              ],
                              storageKey: null
                            }
                          ],
                          storageKey: "validationEntries(last:1)"
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "active",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: "installs",
              args: v11 /*: any*/,
              filters: ["search", "bladeScope", "sortColumn", "sortDirection"],
              handle: "connection",
              key: "userInstallsTable_installs",
              kind: "LinkedHandle",
              name: "instances"
            },
            v12 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "4384e60017d35d1e6989793863b71da0",
      id: null,
      metadata: {},
      name: "userInstallsTablePaginationQuery",
      operationKind: "query",
      text:
        "query userInstallsTablePaginationQuery(\n  $id: ID!\n  $count: Int!\n  $cursor: String\n  $search: String\n  $blade_scope: String\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n) {\n  ...userInstallsTable_installs_30LSkJ\n}\n\nfragment userInstallsTable_installs_30LSkJ on Query {\n  user(id: $id) {\n    installs: instances(first: $count, after: $cursor, search: $search, bladeScope: $blade_scope, sortColumn: $sortColumn, sortDirection: $sortDirection) {\n      edges {\n        node {\n          id\n          guid\n          license {\n            product {\n              name\n              id\n            }\n            id\n          }\n          hostName\n          validationEntries(last: 1) {\n            nodes {\n              createdAt\n              version\n            }\n          }\n          active\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "5acc21f1d85f7f457898834dde9f5615";
export default node;
