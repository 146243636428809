import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../api/relay";
import { appConnect } from "../../store/appConnect";
import _ from "lodash";
import { TabView } from "../../components";

import {
  productCohortAnalyticsQuery,
  productCohortAnalyticsQueryResponse
} from "./__generated__/productCohortAnalyticsQuery.graphql";
import { ITab } from "components/tabView";
import CohortChart from "components/elmChart/cohortChart";
import moment from "moment";
import LoadingWrapper from "components/elmChart/loadingWrapper";

const graphqlQuery = graphql`
  query productCohortAnalyticsQuery(
    $productId: String!
    $cohortStartDate: String!
  ) {
    ProductCohortAnalyticsUserRegistrations: registrationEntries(
      filterColumn: ["createdAt"]
      filterOp: [[">", "="]]
      filterValues: [[$cohortStartDate]]
      bladeScope: $productId
    ) {
      nodes {
        user {
          id
          displayName
        }
        createdAt
        id
      }
    }
  }
`;

export interface IProductCohortAnalyticsProps {
  result: productCohortAnalyticsQueryResponse;
  retry: () => void;
  variables: {
    productId: string | null;
    cohortStartDate: string | null;
  };
  updateVariables: React.Dispatch<
    React.SetStateAction<{
      productId: string | null;
      cohortStartDate: string | null;
    }>
  >;
}

type state = {
  activeTab: string;
};
export class ProductCohortAnalytics extends React.Component<
  IProductCohortAnalyticsProps
> {
  state: state = {
    activeTab: "daily"
  };
  public renderCohortChart = (timePeriod: "day" | "week" | "month") => {
    let userIds = [] as string[];
    let userRegistrationData = [] as {
      userId: number;
      registrationTime: string;
    }[];
    if (this.props.result !== null) {
      this.props.result?.ProductCohortAnalyticsUserRegistrations?.nodes.map(
        item => {
          const decodedUserId = atob(item.user.id).substring(5);
          const date = item.createdAt.toString().substring(0, 10);
          if (
            !userRegistrationData.find(
              e => e.userId === parseInt(decodedUserId)
            )
          ) {
            userRegistrationData.push({
              userId: parseInt(decodedUserId),
              registrationTime: date
            });
          }
          if (!userIds.includes(decodedUserId)) {
            userIds.push(decodedUserId);
          }
        }
      );
    }
    return (
      <LoadingWrapper>
        <CohortChart
          queryParams={{
            setup: {
              category: `time.${timePeriod}`,
              value: "sessions.usage_count",
              group: "user_id"
            },
            filterColumn: ["user_id"],
            filterValues: [userIds],
            startTime: this.props.variables.cohortStartDate
          }}
          registrationData={userRegistrationData}
          timePeriod={timePeriod}
          hideTimeRangeFilter
        />
      </LoadingWrapper>
    );
  };
  public getTabs = (): ITab[] => {
    const tabStyle = {
      lineHeight: "14px",
      fontSize: "14px",
      padding: "12px",
      marginRight: "0px",
      marginBottom: "20px"
    };
    return [
      {
        title: "Daily",
        Component: this.renderCohortChart("day"),
        style: tabStyle
      },
      {
        title: "Weekly",
        Component: this.renderCohortChart("week"),
        style: tabStyle
      },
      {
        title: "Monthly",
        Component: this.renderCohortChart("month"),
        style: tabStyle
      }
    ];
  };
  public onUpdateActiveTab = (activeTab: number) => {
    switch (activeTab) {
      case 0:
        this.props.updateVariables({
          ...this.props.variables,
          cohortStartDate: moment()
            .subtract(14, "days")
            .format("YYYY/MM/DD")
        });
        break;
      case 1:
        this.props.updateVariables({
          ...this.props.variables,
          cohortStartDate: moment()
            .subtract(1, "months")
            .format("YYYY/MM/DD")
        });
        break;
      case 2:
        this.props.updateVariables({
          ...this.props.variables,
          cohortStartDate: moment()
            .subtract(12, "months")
            .format("YYYY/MM/DD")
        });
        break;
    }
  };
  public render() {
    return (
      <TabView
        leftAlign={true}
        defaultTab={0}
        tabList={this.getTabs()}
        style={{ height: "290px", marginTop: "20px" }}
        onUpdateActiveTab={this.onUpdateActiveTab}
      />
    );
  }
}

const RenderQuery = (props: IProductCohortAnalyticsProps) => {
  const [variables, updateVariables] = React.useState(props.variables);

  const renderBlade = (payload: {
    error: Error;
    props: productCohortAnalyticsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <ProductCohortAnalytics
        {...props}
        variables={variables}
        result={payload.props}
        retry={payload.retry}
        updateVariables={updateVariables}
      />
    );
  };
  return (
    <QueryRenderer<productCohortAnalyticsQuery>
      environment={relayEnvironment}
      variables={variables}
      query={graphqlQuery}
      render={renderBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
