/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type usersTableExportQueryVariables = {
  bladeScope?: string | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
  filterColumn?: Array<string> | null | undefined;
  filterOp?: Array<Array<string>> | null | undefined;
  filterValues?: Array<Array<string>> | null | undefined;
};
export type usersTableExportQueryResponse = {
  readonly users: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
      readonly email: string | null;
      readonly active: boolean;
      readonly owner: {
        readonly id: string;
        readonly name: string | null;
      } | null;
      readonly products: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly name: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};
export type usersTableExportQuery = {
  readonly response: usersTableExportQueryResponse;
  readonly variables: usersTableExportQueryVariables;
};

/*
query usersTableExportQuery(
  $bladeScope: String
  $sortColumn: [String!]
  $sortDirection: [String!]
  $filterColumn: [String!]
  $filterOp: [[String!]!]
  $filterValues: [[String!]!]
) {
  users(bladeScope: $bladeScope, sortColumn: $sortColumn, sortDirection: $sortDirection, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues) {
    nodes {
      id
      name
      email
      active
      owner {
        __typename
        id
        name
      }
      products {
        nodes {
          id
          name
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "bladeScope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterOp"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v6 = [
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "bladeScope"
      } as any,
      {
        kind: "Variable",
        name: "filterColumn",
        variableName: "filterColumn"
      } as any,
      {
        kind: "Variable",
        name: "filterOp",
        variableName: "filterOp"
      } as any,
      {
        kind: "Variable",
        name: "filterValues",
        variableName: "filterValues"
      } as any,
      {
        kind: "Variable",
        name: "sortColumn",
        variableName: "sortColumn"
      } as any,
      {
        kind: "Variable",
        name: "sortDirection",
        variableName: "sortDirection"
      } as any
    ],
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "email",
      storageKey: null
    } as any,
    v10 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "active",
      storageKey: null
    } as any,
    v11 = [v7 /*: any*/, v8 /*: any*/],
    v12 = {
      alias: null,
      args: null,
      concreteType: "ProductConnection",
      kind: "LinkedField",
      name: "products",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Product",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: v11 /*: any*/,
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "usersTableExportQuery",
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: "UserConnection",
          kind: "LinkedField",
          name: "users",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "User",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v7 /*: any*/,
                v8 /*: any*/,
                v9 /*: any*/,
                v10 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: null,
                  kind: "LinkedField",
                  name: "owner",
                  plural: false,
                  selections: v11 /*: any*/,
                  storageKey: null
                },
                v12 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/
      ],
      kind: "Operation",
      name: "usersTableExportQuery",
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: "UserConnection",
          kind: "LinkedField",
          name: "users",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "User",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v7 /*: any*/,
                v8 /*: any*/,
                v9 /*: any*/,
                v10 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: null,
                  kind: "LinkedField",
                  name: "owner",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    },
                    v7 /*: any*/,
                    v8 /*: any*/
                  ],
                  storageKey: null
                },
                v12 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "247cf74e147cbde030de6f040b11dd73",
      id: null,
      metadata: {},
      name: "usersTableExportQuery",
      operationKind: "query",
      text:
        "query usersTableExportQuery(\n  $bladeScope: String\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n  $filterColumn: [String!]\n  $filterOp: [[String!]!]\n  $filterValues: [[String!]!]\n) {\n  users(bladeScope: $bladeScope, sortColumn: $sortColumn, sortDirection: $sortDirection, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues) {\n    nodes {\n      id\n      name\n      email\n      active\n      owner {\n        __typename\n        id\n        name\n      }\n      products {\n        nodes {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "0e851bec0db7d3e0a8a00c89811a0a7e";
export default node;
