/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type NewEntitlementQueryVariables = {
  id: string;
  entitlementId: string;
};
export type NewEntitlementQueryResponse = {
  readonly product: {
    readonly name: string | null;
    readonly components: {
      readonly nodes: ReadonlyArray<{
        readonly name: string | null;
        readonly componentType: string;
        readonly id: string;
      } | null> | null;
    } | null;
    readonly productGroups: {
      readonly nodes: ReadonlyArray<{
        readonly name: string | null;
        readonly id: string;
        readonly components: {
          readonly nodes: ReadonlyArray<{
            readonly name: string | null;
            readonly id: string;
            readonly componentType: string;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly componentEntitlement: {
    readonly tokenCount: number | null;
    readonly currencyCount: number | null;
    readonly sessionCount: number | null;
    readonly components: {
      readonly nodes: ReadonlyArray<{
        readonly name: string | null;
        readonly componentType: string;
        readonly id: string;
      } | null> | null;
    } | null;
    readonly componentGroup: {
      readonly name: string | null;
      readonly id: string;
      readonly components: {
        readonly nodes: ReadonlyArray<{
          readonly name: string | null;
          readonly componentType: string;
          readonly id: string;
        } | null> | null;
      } | null;
    } | null;
    readonly latestTerm: {
      readonly endDate: unknown | null;
      readonly startDate: unknown | null;
    } | null;
  } | null;
};
export type NewEntitlementQuery = {
  readonly response: NewEntitlementQueryResponse;
  readonly variables: NewEntitlementQueryVariables;
};

/*
query NewEntitlementQuery(
  $id: ID!
  $entitlementId: ID!
) {
  product(id: $id) {
    name
    components {
      nodes {
        name
        componentType
        id
      }
    }
    productGroups: componentGroups {
      nodes {
        name
        id
        components {
          nodes {
            name
            id
            componentType
          }
        }
      }
    }
    id
  }
  componentEntitlement(id: $entitlementId) {
    tokenCount
    currencyCount
    sessionCount
    components {
      nodes {
        name
        componentType
        id
      }
    }
    componentGroup {
      name
      id
      components {
        nodes {
          name
          componentType
          id
        }
      }
    }
    latestTerm {
      endDate
      startDate
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "entitlementId"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    } as any,
    v2 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "componentType",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v7 = {
      alias: "productGroups",
      args: null,
      concreteType: "ComponentGroupConnection",
      kind: "LinkedField",
      name: "componentGroups",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ComponentGroup",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v3 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentConnection",
              kind: "LinkedField",
              name: "components",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Component",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [v3 /*: any*/, v5 /*: any*/, v4 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v8 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "entitlementId"
      } as any
    ],
    v9 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "tokenCount",
      storageKey: null
    } as any,
    v10 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "currencyCount",
      storageKey: null
    } as any,
    v11 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "sessionCount",
      storageKey: null
    } as any,
    v12 = {
      alias: null,
      args: null,
      concreteType: "ComponentGroup",
      kind: "LinkedField",
      name: "componentGroup",
      plural: false,
      selections: [v3 /*: any*/, v5 /*: any*/, v6 /*: any*/],
      storageKey: null
    } as any,
    v13 = {
      alias: null,
      args: null,
      concreteType: "Term",
      kind: "LinkedField",
      name: "latestTerm",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "endDate",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "startDate",
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: "Fragment",
      metadata: null,
      name: "NewEntitlementQuery",
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v3 /*: any*/, v6 /*: any*/, v7 /*: any*/],
          storageKey: null
        },
        {
          alias: null,
          args: v8 /*: any*/,
          concreteType: "ComponentEntitlement",
          kind: "LinkedField",
          name: "componentEntitlement",
          plural: false,
          selections: [
            v9 /*: any*/,
            v10 /*: any*/,
            v11 /*: any*/,
            v6 /*: any*/,
            v12 /*: any*/,
            v13 /*: any*/
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: "Operation",
      name: "NewEntitlementQuery",
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v3 /*: any*/, v6 /*: any*/, v7 /*: any*/, v5 /*: any*/],
          storageKey: null
        },
        {
          alias: null,
          args: v8 /*: any*/,
          concreteType: "ComponentEntitlement",
          kind: "LinkedField",
          name: "componentEntitlement",
          plural: false,
          selections: [
            v9 /*: any*/,
            v10 /*: any*/,
            v11 /*: any*/,
            v6 /*: any*/,
            v12 /*: any*/,
            v13 /*: any*/,
            v5 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "dc3636803b0a763191f05d894a577fa4",
      id: null,
      metadata: {},
      name: "NewEntitlementQuery",
      operationKind: "query",
      text:
        "query NewEntitlementQuery(\n  $id: ID!\n  $entitlementId: ID!\n) {\n  product(id: $id) {\n    name\n    components {\n      nodes {\n        name\n        componentType\n        id\n      }\n    }\n    productGroups: componentGroups {\n      nodes {\n        name\n        id\n        components {\n          nodes {\n            name\n            id\n            componentType\n          }\n        }\n      }\n    }\n    id\n  }\n  componentEntitlement(id: $entitlementId) {\n    tokenCount\n    currencyCount\n    sessionCount\n    components {\n      nodes {\n        name\n        componentType\n        id\n      }\n    }\n    componentGroup {\n      name\n      id\n      components {\n        nodes {\n          name\n          componentType\n          id\n        }\n      }\n    }\n    latestTerm {\n      endDate\n      startDate\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "ebaf46f7ef290956fbfc28fc300de0f0";
export default node;
