import React, { useEffect, useRef, useState } from "react";
import { getIcon } from "components";
import { useTheme } from "styled-components";

export const FeedbackWidget = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const timeoutRef = useRef<number | null>(null);

  const HelpIcon = getIcon("message", "far");
  const theme = useTheme();

  const handleMouseOver = () => {
    setIsHover(true);
    clearTimeout(timeoutRef.current);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setIsHover(false);
    }, 500);
  };

  const toggleWidgetState = () => {
    // @ts-ignore
    FreshworksWidget(isOpen ? "close" : "open");
    setIsOpen(!isOpen);
  };

  const widgetButtonContainer: React.CSSProperties = {
    position: "absolute",
    cursor: "pointer",
    bottom: 26,
    right: 26,
    padding: 8,
    borderRadius: 6,
    height: 16,
    display: "inline-flex",
    alignItems: "center",
    overflow: "hidden",
    width: isHover ? 90 : 16,
    color: theme.colors.buttonTextPrimary,
    backgroundColor: isHover
      ? theme.colors.elmButtonPrimaryHover
      : theme.colors.primary,
    transition: "width 0.5s ease-out",
    boxShadow: theme.colors.loginBoxShadow
  };

  const labelStyle: React.CSSProperties = {
    transform: isHover ? "translateX(0)" : "translateX(100%)",
    transition: "transform 0.5s ease-out",
    fontSize: theme.fontSizes.small,
    width: isHover ? "initial" : 0,
    whiteSpace: "nowrap",
    marginLeft: 8
  };

  return (
    <div
      style={widgetButtonContainer}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={toggleWidgetState}
    >
      <HelpIcon />
      <span style={labelStyle}>{"Feedback"}</span>
    </div>
  );
};

export const useFeedbackWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerText = `
      window.fwSettings={ 'widget_id':73000004708 };
      !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;
    document.body.appendChild(script);
    const script2 = document.createElement("script");
    script2.src = "https://widget.freshworks.com/widgets/73000004708.js";
    script2.async = true;
    script2.defer = true;
    document.body.appendChild(script2);
    const script3 = document.createElement("script");
    script3.innerText = `FreshworksWidget('hide', 'launcher')`;
    document.body.appendChild(script3);
  }, []);

  useEffect(() => {}, []);
};
