/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ProductCountWatcherQueryVariables = {};
export type ProductCountWatcherQueryResponse = {
  readonly productsCount: number;
};
export type ProductCountWatcherQuery = {
  readonly response: ProductCountWatcherQueryResponse;
  readonly variables: ProductCountWatcherQueryVariables;
};

/*
query ProductCountWatcherQuery {
  productsCount
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
    {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "productsCount",
      storageKey: null
    } as any
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "ProductCountWatcherQuery",
      selections: v0 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "ProductCountWatcherQuery",
      selections: v0 /*: any*/
    },
    params: {
      cacheID: "248e7e39c4ce225a2d30dff8d6eb7de4",
      id: null,
      metadata: {},
      name: "ProductCountWatcherQuery",
      operationKind: "query",
      text: "query ProductCountWatcherQuery {\n  productsCount\n}\n"
    }
  } as any;
})();
(node as any).hash = "dfda5d107d92d3dcc9a459ec7525a07a";
export default node;
