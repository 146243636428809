/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userLinkingQueryVariables = {};
export type userLinkingQueryResponse = {
  readonly usersCount: number;
  readonly " $fragmentRefs": FragmentRefs<"usersShortListTable_users">;
};
export type userLinkingQuery = {
  readonly response: userLinkingQueryResponse;
  readonly variables: userLinkingQueryVariables;
};

/*
query userLinkingQuery {
  ...usersShortListTable_users
  usersCount
}

fragment usersShortListTable_users on Query {
  usersShortListTableCount: usersCount(filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]])
  usersShortListTableUsers: users(filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]]) {
    edges {
      node {
        id
        name
        email
        active
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "usersCount",
      storageKey: null
    } as any,
    v1 = [
      {
        kind: "Literal",
        name: "filterColumn",
        value: ["users.active"]
      } as any,
      {
        kind: "Literal",
        name: "filterOp",
        value: [["="]]
      } as any,
      {
        kind: "Literal",
        name: "filterValues",
        value: [["true"]]
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "userLinkingQuery",
      selections: [
        v0 /*: any*/,
        {
          args: null,
          kind: "FragmentSpread",
          name: "usersShortListTable_users"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "userLinkingQuery",
      selections: [
        {
          alias: "usersShortListTableCount",
          args: v1 /*: any*/,
          kind: "ScalarField",
          name: "usersCount",
          storageKey:
            'usersCount(filterColumn:["users.active"],filterOp:[["="]],filterValues:[["true"]])'
        },
        {
          alias: "usersShortListTableUsers",
          args: v1 /*: any*/,
          concreteType: "UserConnection",
          kind: "LinkedField",
          name: "users",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "UserEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "User",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "name",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "email",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "active",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey:
            'users(filterColumn:["users.active"],filterOp:[["="]],filterValues:[["true"]])'
        },
        {
          alias: "usersShortListTableUsers",
          args: v1 /*: any*/,
          filters: [
            "bladeScope",
            "search",
            "filterColumn",
            "filterOp",
            "filterValues"
          ],
          handle: "connection",
          key: "UsersShortListTable_usersShortListTableUsers",
          kind: "LinkedHandle",
          name: "users"
        },
        v0 /*: any*/
      ]
    },
    params: {
      cacheID: "072279916cdc9e96786b2e23852ce65a",
      id: null,
      metadata: {},
      name: "userLinkingQuery",
      operationKind: "query",
      text:
        'query userLinkingQuery {\n  ...usersShortListTable_users\n  usersCount\n}\n\nfragment usersShortListTable_users on Query {\n  usersShortListTableCount: usersCount(filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]])\n  usersShortListTableUsers: users(filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]]) {\n    edges {\n      node {\n        id\n        name\n        email\n        active\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "3656852d2a56de89e2a69925e0290922";
export default node;
