import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import { licenseUsersTable_users } from "./__generated__/licenseUsersTable_users.graphql";
import { tableGenerator } from "../../../utils";
import { UsersTableColumnConfig } from "Users/components/usersTable";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { Flex } from "reflexbox";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import {
  licenseUsersTableQuery,
  licenseUsersTableQueryResponse
} from "./__generated__/licenseUsersTableQuery.graphql";
import { LoaderContainer } from "components/helpers";

export type LicenseUsersTableType = licenseUsersTable_users["users"]["edges"][number]["node"];

const GeneratedTable = tableGenerator<{
  users: licenseUsersTable_users;
}>({
  tableName: "licenseUsersTable",
  dataKey: "users.users",
  getTotalCount: data => {
    return _.get(data, "users.usersCount") || 0;
  },
  columns: [
    UsersTableColumnConfig.Status,
    UsersTableColumnConfig.User,
    UsersTableColumnConfig.Email,
    UsersTableColumnConfig.LastActiveSession,
    UsersTableColumnConfig.Installs
  ],
  connectionQuery: graphql`
    query licenseUsersTablePaginationQuery(
      $count: Int!
      $cursor: String
      $blade_scope: String
      $search: String
      $filterColumn: [String!]
      $filterValues: [[String!]!]
      $filterOp: [[String!]!]
    ) {
      ...licenseUsersTable_users
        @arguments(
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          count: $count
          cursor: $cursor
          blade_scope: $blade_scope
          search: $search
        )
    }
  `,
  fragmentSpec: {
    users: graphql`
      fragment licenseUsersTable_users on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          blade_scope: { type: "String", defaultValue: null }
          search: { type: "String", defaultValue: null }
          filterColumn: { type: "[String!]", defaultValue: ["users.active"] }
          filterOp: { type: "[[String!]!]", defaultValue: [["="]] }
          filterValues: { type: "[[String!]!]", defaultValue: [["true"]] }
        ) {
        usersCount(
          search: $search
          bladeScope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        )
        users(
          first: $count
          after: $cursor
          bladeScope: $blade_scope
          search: $search
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        ) @connection(key: "licenseUsersTable_users") {
          edges {
            node {
              id
              name
              email
              createdAt
              instancesCount(bladeScope: $blade_scope)
              hasActiveSession
              lastActiveSession {
                endTime
              }
              active
              products {
                nodes {
                  id
                  iconUrl
                  name
                }
              }
              productUsers {
                nodes {
                  product {
                    id
                    name
                  }
                  enabled
                  user {
                    id
                  }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query licenseUsersTableQuery($blade_scope: String!) {
    ...licenseUsersTable_users @arguments(blade_scope: $blade_scope)
  }
`;

class UsersTable extends React.Component<
  IGeneratedTableProps & {
    result: licenseUsersTableQueryResponse;
    retry: () => void;
  }
> {
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        users={res}
      />
    );
  }
}

export const LicenseUsersTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: licenseUsersTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <UsersTable {...props} result={payload.props} retry={payload.retry} />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<licenseUsersTableQuery>
      environment={relayEnvironment}
      variables={{
        blade_scope: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};
