/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicenseQueryVariables = {
  id: string;
  productId: string;
};
export type LicenseQueryResponse = {
  readonly productComponents: {
    readonly components: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      } | null> | null;
    } | null;
    readonly componentGroups: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      } | null> | null;
    } | null;
  } | null;
  readonly license: {
    readonly id: string;
    readonly type: {
      readonly id: string;
    };
    readonly createdAt: unknown;
    readonly isVendor: boolean;
    readonly owner: {
      readonly name: string | null;
      readonly id: string;
    } | null;
    readonly commercialModel: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly activeEntitlements: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly value: number;
        readonly latestTerm: {
          readonly startDate: unknown | null;
          readonly endDate: unknown | null;
        } | null;
        readonly instanceCount: number | null;
        readonly sessionCount: number | null;
        readonly userCount: number | null;
      } | null> | null;
    } | null;
    readonly product: {
      readonly id: string;
      readonly name: string | null;
      readonly iconUrl: string | null;
      readonly componentsCount: number;
      readonly featuresCount: number;
    } | null;
    readonly licenseActions: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly action: string;
        readonly status: number;
        readonly requireApproval: boolean;
        readonly vendorSystemUser: {
          readonly id: string;
          readonly name: string | null;
          readonly email: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly key: string;
    readonly latestTerm: {
      readonly startDate: unknown | null;
      readonly endDate: unknown | null;
    } | null;
    readonly componentsCount: number;
    readonly featuresCount: number;
    readonly releaseChannelsCount: number;
  } | null;
};
export type LicenseQuery = {
  readonly response: LicenseQueryResponse;
  readonly variables: LicenseQueryVariables;
};

/*
query LicenseQuery(
  $id: ID!
  $productId: ID!
) {
  productComponents: product(id: $productId) {
    components {
      nodes {
        id
      }
    }
    componentGroups {
      nodes {
        id
      }
    }
    id
  }
  license(id: $id) {
    id
    type {
      id
    }
    createdAt
    isVendor
    owner {
      __typename
      name
      id
    }
    commercialModel {
      id
      name
    }
    activeEntitlements {
      nodes {
        id
        value
        latestTerm {
          startDate
          endDate
        }
        instanceCount
        sessionCount
        userCount
      }
    }
    product {
      id
      name
      iconUrl
      componentsCount
      featuresCount
    }
    licenseActions {
      nodes {
        id
        action
        status
        requireApproval
        vendorSystemUser {
          id
          name
          email
        }
      }
    }
    key
    latestTerm {
      startDate
      endDate
    }
    componentsCount
    featuresCount
    releaseChannelsCount
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "productId"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "productId"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = [v2 /*: any*/],
    v4 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: v3 /*: any*/,
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      concreteType: "ComponentGroupConnection",
      kind: "LinkedField",
      name: "componentGroups",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ComponentGroup",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: v3 /*: any*/,
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v6 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v7 = {
      alias: null,
      args: null,
      concreteType: "LicenseModel",
      kind: "LinkedField",
      name: "type",
      plural: false,
      selections: v3 /*: any*/,
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "createdAt",
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "isVendor",
      storageKey: null
    } as any,
    v10 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v11 = {
      alias: null,
      args: null,
      concreteType: "CommercialModel",
      kind: "LinkedField",
      name: "commercialModel",
      plural: false,
      selections: [v2 /*: any*/, v10 /*: any*/],
      storageKey: null
    } as any,
    v12 = {
      alias: null,
      args: null,
      concreteType: "Term",
      kind: "LinkedField",
      name: "latestTerm",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "startDate",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "endDate",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v13 = {
      alias: null,
      args: null,
      concreteType: "EntitlementConnection",
      kind: "LinkedField",
      name: "activeEntitlements",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Entitlement",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "value",
              storageKey: null
            },
            v12 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "instanceCount",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "sessionCount",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "userCount",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v14 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "componentsCount",
      storageKey: null
    } as any,
    v15 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "featuresCount",
      storageKey: null
    } as any,
    v16 = {
      alias: null,
      args: null,
      concreteType: "Product",
      kind: "LinkedField",
      name: "product",
      plural: false,
      selections: [
        v2 /*: any*/,
        v10 /*: any*/,
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "iconUrl",
          storageKey: null
        },
        v14 /*: any*/,
        v15 /*: any*/
      ],
      storageKey: null
    } as any,
    v17 = {
      alias: null,
      args: null,
      concreteType: "LicenseActionConnection",
      kind: "LinkedField",
      name: "licenseActions",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "LicenseAction",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "action",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "status",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "requireApproval",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "VendorSystemUser",
              kind: "LinkedField",
              name: "vendorSystemUser",
              plural: false,
              selections: [
                v2 /*: any*/,
                v10 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "email",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v18 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "key",
      storageKey: null
    } as any,
    v19 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "releaseChannelsCount",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "LicenseQuery",
      selections: [
        {
          alias: "productComponents",
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v4 /*: any*/, v5 /*: any*/],
          storageKey: null
        },
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            v2 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            v9 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: "LinkedField",
              name: "owner",
              plural: false,
              selections: [v10 /*: any*/, v2 /*: any*/],
              storageKey: null
            },
            v11 /*: any*/,
            v13 /*: any*/,
            v16 /*: any*/,
            v17 /*: any*/,
            v18 /*: any*/,
            v12 /*: any*/,
            v14 /*: any*/,
            v15 /*: any*/,
            v19 /*: any*/
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "LicenseQuery",
      selections: [
        {
          alias: "productComponents",
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v4 /*: any*/, v5 /*: any*/, v2 /*: any*/],
          storageKey: null
        },
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            v2 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            v9 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: "LinkedField",
              name: "owner",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "__typename",
                  storageKey: null
                },
                v10 /*: any*/,
                v2 /*: any*/
              ],
              storageKey: null
            },
            v11 /*: any*/,
            v13 /*: any*/,
            v16 /*: any*/,
            v17 /*: any*/,
            v18 /*: any*/,
            v12 /*: any*/,
            v14 /*: any*/,
            v15 /*: any*/,
            v19 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "77694e791feb76fa8a68338de369feea",
      id: null,
      metadata: {},
      name: "LicenseQuery",
      operationKind: "query",
      text:
        "query LicenseQuery(\n  $id: ID!\n  $productId: ID!\n) {\n  productComponents: product(id: $productId) {\n    components {\n      nodes {\n        id\n      }\n    }\n    componentGroups {\n      nodes {\n        id\n      }\n    }\n    id\n  }\n  license(id: $id) {\n    id\n    type {\n      id\n    }\n    createdAt\n    isVendor\n    owner {\n      __typename\n      name\n      id\n    }\n    commercialModel {\n      id\n      name\n    }\n    activeEntitlements {\n      nodes {\n        id\n        value\n        latestTerm {\n          startDate\n          endDate\n        }\n        instanceCount\n        sessionCount\n        userCount\n      }\n    }\n    product {\n      id\n      name\n      iconUrl\n      componentsCount\n      featuresCount\n    }\n    licenseActions {\n      nodes {\n        id\n        action\n        status\n        requireApproval\n        vendorSystemUser {\n          id\n          name\n          email\n        }\n      }\n    }\n    key\n    latestTerm {\n      startDate\n      endDate\n    }\n    componentsCount\n    featuresCount\n    releaseChannelsCount\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "851aa42a42f1dc849a72bc288fd94363";
export default node;
