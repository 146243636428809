/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SidebarFilterQueryVariables = {};
export type SidebarFilterQueryResponse = {
  readonly allProducts: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly id: string;
    } | null> | null;
  } | null;
  readonly allCompanies: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly id: string;
    } | null> | null;
  } | null;
  readonly allUsers: {
    readonly nodes: ReadonlyArray<{
      readonly displayName: string | null;
      readonly id: string;
    } | null> | null;
  } | null;
};
export type SidebarFilterQuery = {
  readonly response: SidebarFilterQueryResponse;
  readonly variables: SidebarFilterQueryVariables;
};

/*
query SidebarFilterQuery {
  allProducts: products {
    nodes {
      name
      id
    }
  }
  allCompanies: companies {
    nodes {
      name
      id
    }
  }
  allUsers: users {
    nodes {
      displayName
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v1 = [
      {
        alias: null,
        args: null,
        kind: "ScalarField",
        name: "name",
        storageKey: null
      } as any,
      v0 /*: any*/
    ],
    v2 = [
      {
        alias: "allProducts",
        args: null,
        concreteType: "ProductConnection",
        kind: "LinkedField",
        name: "products",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "Product",
            kind: "LinkedField",
            name: "nodes",
            plural: true,
            selections: v1 /*: any*/,
            storageKey: null
          }
        ],
        storageKey: null
      } as any,
      {
        alias: "allCompanies",
        args: null,
        concreteType: "CompanyConnection",
        kind: "LinkedField",
        name: "companies",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "Company",
            kind: "LinkedField",
            name: "nodes",
            plural: true,
            selections: v1 /*: any*/,
            storageKey: null
          }
        ],
        storageKey: null
      } as any,
      {
        alias: "allUsers",
        args: null,
        concreteType: "UserConnection",
        kind: "LinkedField",
        name: "users",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "User",
            kind: "LinkedField",
            name: "nodes",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "displayName",
                storageKey: null
              },
              v0 /*: any*/
            ],
            storageKey: null
          }
        ],
        storageKey: null
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "SidebarFilterQuery",
      selections: v2 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "SidebarFilterQuery",
      selections: v2 /*: any*/
    },
    params: {
      cacheID: "123e4ba35a5f06cb4c29734d4557cee4",
      id: null,
      metadata: {},
      name: "SidebarFilterQuery",
      operationKind: "query",
      text:
        "query SidebarFilterQuery {\n  allProducts: products {\n    nodes {\n      name\n      id\n    }\n  }\n  allCompanies: companies {\n    nodes {\n      name\n      id\n    }\n  }\n  allUsers: users {\n    nodes {\n      displayName\n      id\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "a77b6af93de4cc986e2cda2b9d51f3c9";
export default node;
