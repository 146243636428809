import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Flex } from "reflexbox/styled-components";
import { searchInObjectArray } from "../../../utils";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import {
  productComponentGroupsTableQuery,
  productComponentGroupsTableQueryResponse
} from "./__generated__/productComponentGroupsTableQuery.graphql";
import { IGeneratedTableProps } from "utils/tableGenerator";
import ElmTable, { IColumn, IElmTableProps } from "components/elmTable";
import _ from "lodash";
import { shallowEqual } from "fast-equals";
import { EmptyTableAction } from "Products/blades/Product";

enum ComponentGroupsTableColumns {
  Name = "Name",
  Components = "Components",
  Entitlements = "Entitlements",
  Actions = "Actions"
}
export const ComponentGroupsTableColumnConfig: Record<
  ComponentGroupsTableColumns,
  IColumn
> = {
  Name: {
    Header: "Name",
    accessor: "groupName",
    sortKey: "name"
  },
  Components: {
    Header: "Components",
    accessor: "components",
    sortKey: null,
    cellRenderer: p => {
      const names = _.map(p.cellData, r => r) || [];
      const comps = names.slice(0, 3);
      const first3Components = _.join(comps, ",");
      const suffix = () => {
        if (names.length <= 3) {
          return "";
        }
        return `and ${names.length - comps.length} more.`;
      };
      return `${first3Components} ${suffix()}`;
    },
    width: 0.5,
    disableSortBy: true
  },
  Entitlements: {
    Header: "Model",
    accessor: "componentType",
    sortKey: null,
    disableSortBy: true
  },
  Actions: {
    Header: "Actions",
    accessor: "id",
    cellRenderer: () => "",
    sortKey: null,
    width: 0.15,
    disableSortBy: true
  }
};

const graphqlQuery = graphql`
  query productComponentGroupsTableQuery($id: ID!) {
    product(id: $id) {
      componentGroupsCount
      componentGroups {
        nodes {
          id
          name
          componentType
          componentEntitlementsCount
          components {
            nodes {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface IComponentGroupsTableProps
  extends Omit<IElmTableProps, "columns" | "data">,
    Pick<
      IGeneratedTableProps,
      "bladeScope" | "setRefreshFn" | "refreshKey" | "removeRefreshFn"
    > {
  addComponentGroup: () => void;
}

interface state {
  search_term?: string;
}

class ProductComponentGroupsTable extends React.Component<
  IComponentGroupsTableProps & {
    result: productComponentGroupsTableQueryResponse;
    retry: () => void;
  },
  state
> {
  state: Readonly<state> = {
    search_term: ""
  };
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    this.props.removeRefreshFn(this.props.refreshKey);
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render = () => {
    const nodes = _.get(this.props.result, "product.componentGroups.nodes", []);

    let componentGroup: {
      groupName: string;
      components: string[];
      componentType: string;
      componentEntitlementsCount: number;
      id: string;
    };
    const transform = _.reduce(
      nodes,
      (all, node) => {
        componentGroup = {
          groupName: node.name,
          components: [],
          componentType: node.componentType,
          componentEntitlementsCount: node.componentEntitlementsCount,
          id: node.id
        };
        all.push(componentGroup);
        _.each(node.components.nodes, component => {
          componentGroup.components.push(component.name);
        });
        return all;
      },
      []
    );

    const filteredData = this.state.search_term
      ? searchInObjectArray(this.state.search_term, transform)
      : transform;

    return (
      <ElmTable
        {...this.props}
        data={filteredData}
        totalCount={nodes?.length}
        onCriteriaChange={({ search_term }) => this.setState({ search_term })}
        columns={[
          ...Object.keys(ComponentGroupsTableColumnConfig).map(
            key =>
              ComponentGroupsTableColumnConfig[
                key as ComponentGroupsTableColumns
              ]
          )
        ]}
        useDefaultAddButton={this.props.addComponentGroup}
        onRowClick={this.props.onRowClick}
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message={"Product has no component groups"}
            addButtonCallback={this.props.addComponentGroup}
            actionMessage="Add new group?"
          />
        )}
      />
    );
  };
}

const RenderQuery = (props: IComponentGroupsTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: productComponentGroupsTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <ProductComponentGroupsTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<productComponentGroupsTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default RenderQuery;
