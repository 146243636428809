/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type productAttributeAdvanced_product = {
  readonly publicKey: string | null;
  readonly guid: string;
  readonly " $refType": "productAttributeAdvanced_product";
};
export type productAttributeAdvanced_product$data = productAttributeAdvanced_product;
export type productAttributeAdvanced_product$key = {
  readonly " $data"?: productAttributeAdvanced_product$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"productAttributeAdvanced_product">;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: "Fragment",
  metadata: null,
  name: "productAttributeAdvanced_product",
  selections: [
    {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "publicKey",
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "guid",
      storageKey: null
    }
  ],
  type: "Product",
  abstractKey: null
} as any;
(node as any).hash = "d9a0123333388e2990e834b58a76a552";
export default node;
