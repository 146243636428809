//import BarChart from "./barChart";
import LineChart from "./lineChart";
import TreemapChart from "./treemapChart";
import BarChartLogo from "./barChartLogo";

const obj = {
  LineChart,
  TreemapChart,
  BarChartLogo
};

export default obj;

export { LineChart, TreemapChart, BarChartLogo };
