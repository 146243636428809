import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import { ElmSelectWithLabel } from "../../../elmSelect";
import {
  CompanyName,
  RoleType,
  StyledOption
} from "components/bladeManager/companySelector";
import { IUserRole, UserRoleType } from "store/types";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
export interface IConfirmationDialogProps {
  name?: string;
  description?: string;
  warningMessage?: string;
  entityType: string;
  isSimpleConfirmation?: boolean;
  roles?: any;
  onCancel?: () => void;
}
type state = {
  name: string;
  type?: UserRoleType;
};
export class RoleSelectorDialog extends DialogBase<
  IModalProps<IConfirmationDialogProps>,
  state
> {
  state: state = {
    name: ""
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 999,
          background: "rgb(255, 255, 255, 0.7)"
        },
        content: {
          display: "flex",
          flexDirection: "column",
          height: "max-content",
          //width: "444px",
          borderRadius: "6px",
          //boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          boxShadow: "0px 4px 32px -8px rgba(48, 44, 40 / 15%)",
          backgroundColor: "white",
          zIndex: 99
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "RoleSelectorDialog"
    });
    this.props.payload.onCancel();
  };
  public onConfirm = () => {
    if (_.isFunction(this.props.payload.onConfirm)) {
      this.props.payload.onConfirm({
        name: this.state.name,
        type: this.state.type
      });
    }
  };

  public updateRole = (token: string) => {
    const roles: IUserRole[] = _.get(this.props.payload, "roles", []);
    const target = roles.find(role => role.token === token);
    if (target) {
      this.setState({ name: target.name, type: target.type });
    }
  };

  public render() {
    const roles: IUserRole[] = _.get(this.props.payload, "roles", []);
    return (
      <DialogContainer>
        <DialogTitle>Select role</DialogTitle>
        <ElmSelectWithLabel
          onChange={this.updateRole}
          //style={{ marginBottom: "20px" }}
          className="elm-product-selector"
          label="Role"
          placeholder="Select role"
          //open={true}
        >
          {roles.map(op => (
            <StyledOption value={op.token} label={`${op?.name} (${op?.type})`}>
              <Flex flex={1} justifyContent="space-between">
                <CompanyName>{op?.name}</CompanyName>
                <RoleType>{op?.type}</RoleType>
              </Flex>
            </StyledOption>
          ))}
        </ElmSelectWithLabel>

        <Flex justifyContent={"flex-end"} marginTop="15px">
          <ElmButton
            label={"Cancel"}
            colorVariance="outline-secondary"
            onClick={this.closeModal}
          />
          <ElmButton label={"Continue"} onClick={this.onConfirm} />
        </Flex>
      </DialogContainer>
    );
  }
}
