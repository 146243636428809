import styled from "styled-components";
import { Flex } from "reflexbox/styled-components";
import React from "react";
import { appConnect, appDispatch } from "../../store/appConnect";
import BladeTemplate from "../../components/bladeManager/bladeTemplate";
import { IBladeBaseProps } from "../../components/bladeManager/types";
import { BladeTitle, getIcon } from "../../components";
import { ElmButton } from "../../components/elmButton";
import { useTheme } from "styled-components";
import { iconNameType } from "../../components/icons";
import { TourProps, TourStepProps } from "antd";
import { Tour } from "antd";
import { getOnboardingConfigSync } from "./utils/storage";
import { getSavedRoleFromLocalSync } from "utils";
import { ActiveMode, IOnboardingState } from "store/types";
import { TourGuideConfigState, TourGuideId } from "./utils/types";
import { getGateway } from "api";

const StyledSubtitle = styled.p(props => ({
  color: props.theme.colors.warmGrey,
  fontSize: props.theme.fontSizes.small,
  fontWeight: 400,
  lineHeight: "14px",
  marginTop: "8px"
}));

const RowTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 16px;
  width: 100%;
  color: ${props => props.theme.colors.textPrimary};
`;
const RowSubtitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: ${props => props.theme.colors.warmGrey};
`;

const GuideRow = (props: {
  row: any;
  icon: iconNameType;
  iconPrefix?: "fad" | "fal" | "fas" | "far";
  isPrimaryAction: boolean;
  onButtonClick: (e?: any) => void;
  disabledAction?: boolean;
  isComplete: boolean;
}) => {
  const RowIcon = styled(getIcon(props.icon, props.iconPrefix))`
    margin-top: 4.67px;
    width: 23px;
    height: 23px;
    color: ${props => props.theme.colors.greyish};
  `;
  const DoneIcon = styled(getIcon("FaCheck"))`
    height: 12px;
    color: ${props => props.theme.colors.userGreen};
  `;
  const DoneLabel = styled.span`
    font-size: 12px;
    color: ${props => props.theme.colors.userGreen};
  `;
  return (
    <Flex
      style={{
        height: "24px",
        marginTop: "32px",
        marginBottom: "32px",
        width: "100%"
      }}
    >
      <RowIcon />
      <RowTitle>
        {props.row.title}
        <br></br>
        <RowSubtitle>{props.row.subtitle}</RowSubtitle>
      </RowTitle>
      <div style={{ width: "30%" }}>
        {!props.isComplete ? (
          <ElmButton
            label={props.row.buttonLabel}
            disabled={props.disabledAction}
            colorVariance={
              !props.isPrimaryAction ? "outline-secondary" : "primary"
            }
            style={{ float: "right", marginRight: "16px" }}
            onClick={props.onButtonClick}
          />
        ) : (
          <Flex
            justifyContent={"flex-end"}
            alignItems={"center"}
            style={{ gap: 5, paddingRight: 16 }}
          >
            <DoneIcon />
            <DoneLabel>Done</DoneLabel>
          </Flex>
        )}
      </div>
    </Flex>
  );
};

export interface IWelcomeBladeProps extends IBladeBaseProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    activeMode: ActiveMode;
    userDefinedMode: boolean;
    onboardingMode: IOnboardingState;
  };
  theme: any;
}

interface IWelcomeBladeState extends TourGuideConfigState {
  loading: boolean;
  isAccountSetupComplete: boolean;
  isUserInviteComplete: boolean;
  isProductSetupComplete: boolean;
  isCompanySetupComplete: boolean;
}
export class WelcomeBlade extends React.Component<
  IWelcomeBladeProps,
  IWelcomeBladeState
> {
  state: IWelcomeBladeState = {
    loading: false,
    isAccountSetupComplete: false,
    isUserInviteComplete: false,
    isProductSetupComplete: false,
    isCompanySetupComplete: false,
    tourGuideOpen: false
  };
  public componentDidMount() {
    this.checkCompletionStatus();
  }
  public componentDidUpdate() {
    this.checkOnboardingStatus();
    this.checkCompletionStatus();
  }
  public checkOnboardingMode = () => {
    return this.props.appState.onboardingMode?.status === "active";
  };
  public checkOnboardingStatus = () => {
    if (!this.state.tourSteps) {
      this.setState({
        tourSteps: this.getSteps()
      });
    }
  };
  public getSteps = (): TourProps["steps"] => {
    const propsOverride: Partial<TourStepProps> = {
      nextButtonProps: {
        children: <></>
      },
      prevButtonProps: {
        children: <></>
      },
      onClose: () => {
        this.checkOnboardingMode() &&
          this.props.appDispatch.appActions.onboardingEnd();
      }
    };
    switch (this.props.appState.onboardingMode?.type) {
      case "SetupAccount":
        return [
          {
            title: "Go to account",
            description: "Click here to open Account blade",
            placement: "right",
            mask: true,
            target: () => document.getElementById(TourGuideId.OpenAccount),
            ...propsOverride
          },
          {
            title: "Edit profile",
            description: "You can edit your name and email here",
            placement: "bottom",
            target: () =>
              document.getElementById(TourGuideId.AccountInfo) ||
              document.getElementById(TourGuideId.OpenAccount),
            ...propsOverride
          },
          {
            title: "Notifications",
            description:
              "Here you can turn on and off individual notifications",
            placement: "right",
            target: () =>
              document.getElementById(TourGuideId.Notifications) ||
              document.getElementById(TourGuideId.OpenAccount),
            ...propsOverride
          },
          {
            title: "Edit Preferences",
            description: "Adjust type and frequency of notifications here",
            placement: "topLeft",
            target: () =>
              document.getElementById(TourGuideId.EmailPreferences) ||
              document.getElementById(TourGuideId.Notifications) ||
              document.getElementById(TourGuideId.OpenAccount),
            ...propsOverride
          }
        ];
      case "InviteUsers":
        return [
          {
            title: "Go to account",
            description: "Click here to open Account blade",
            placement: "right",
            mask: true,
            target: () => document.getElementById(TourGuideId.OpenAccount),
            ...propsOverride
          },
          {
            title: "Add users",
            description: "Click on this button to add users.",
            target: () =>
              document.getElementById(TourGuideId.AddButton) ||
              document.getElementById(TourGuideId.OpenAccount),
            ...propsOverride
          }
        ];
      case "CreateProduct":
        return [
          {
            title: "Go to products",
            description: "Click here to open Products blade",
            placement: "right",
            mask: true,
            target: () => document.getElementById(TourGuideId.OpenProducts),
            ...propsOverride
          },
          {
            title: "Add product",
            description: "Click on this button to add products",
            placement: "left",
            target: () =>
              document.getElementById(TourGuideId.CreateProduct) ||
              document.getElementById(TourGuideId.OpenProducts),
            ...propsOverride
          }
        ];
      case "CreateCompany":
        return [
          {
            title: "Go to companies",
            description: "Click here to open Companies blade",
            placement: "right",
            mask: true,
            target: () => document.getElementById(TourGuideId.OpenCompanies),
            ...propsOverride
          },
          {
            title: "Add company",
            description: "Click on this button to add company",
            placement: "left",
            target: () =>
              document.getElementById(TourGuideId.CreateCompany) ||
              document.getElementById(TourGuideId.OpenCompanies),
            ...propsOverride
          }
        ];
      case "Integrations":
        return [];
      default:
        return [];
    }
  };
  public componentWillUnmount() {
    this.props.appDispatch.appActions.onboardingEnd();
  }
  public checkCompletionStatus = () => {
    const isAccountSetupComplete =
      getOnboardingConfigSync("SetupAccount")?.status === "completed";
    const isProductSetupComplete =
      getOnboardingConfigSync("CreateProduct")?.status === "completed";
    const isCompanySetupComplete =
      getOnboardingConfigSync("CreateCompany")?.status === "completed";

    if (isAccountSetupComplete && !this.state.isAccountSetupComplete) {
      this.setState({ isAccountSetupComplete: true });
    }
    if (isProductSetupComplete && !this.state.isProductSetupComplete) {
      this.setState({ isProductSetupComplete: true });
    }
    if (isCompanySetupComplete && !this.state.isCompanySetupComplete) {
      this.setState({ isCompanySetupComplete: true });
    }
    if (!this.state.isUserInviteComplete) {
      const gateway = getGateway();
      gateway.request
        .getAdminUsers()
        .then(res => {
          if (res?.data?.length > 1) {
            this.setState({ isUserInviteComplete: true });
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };
  public renderLeftSideHeader = () => {
    return (
      <div style={{ marginTop: "48px" }}>
        <BladeTitle>Let’s guide you through elm quickly</BladeTitle>
        <StyledSubtitle>
          We would like to offer you some options to explore before starting
        </StyledSubtitle>
      </div>
    );
  };
  public renderRows = () => {
    return (
      <Flex style={{ flexDirection: "column", width: "100%" }}>
        <GuideRow
          row={{
            title: "Setup account",
            subtitle: "Edit your profile and setup your email preferences",
            buttonLabel: "Setup account"
          }}
          isComplete={this.state.isAccountSetupComplete}
          icon={"user-cog"}
          iconPrefix={"fal"}
          isPrimaryAction={true}
          disabledAction={
            this.props.appState.onboardingMode?.status === "active" || false
          }
          onButtonClick={() => {
            const isOnboardingMode = this.checkOnboardingMode();
            if (isOnboardingMode) {
              return;
            }
            this.props.appDispatch.appActions.onboardingStart("SetupAccount");
          }}
        />
        {getSavedRoleFromLocalSync()?.role === "owner" ? (
          <GuideRow
            row={{
              title: "Invite colleagues",
              subtitle:
                "You can invite as many users within your company as you want to elm",
              buttonLabel: "Invite users"
            }}
            disabledAction={
              this.props.appState.onboardingMode?.status === "active" || false
            }
            icon={"users-medical"}
            iconPrefix={"fal"}
            isPrimaryAction={false}
            onButtonClick={() => {
              const isOnboardingMode = this.checkOnboardingMode();
              if (isOnboardingMode) {
                return;
              }
              this.props.appDispatch.appActions.onboardingStart("InviteUsers");
            }}
            isComplete={this.state.isUserInviteComplete}
          />
        ) : null}
        <GuideRow
          row={{
            title: "Create a product",
            subtitle: "Nothing can be done in elm before a product is created",
            buttonLabel: "Add product"
          }}
          icon={"plus-square"}
          iconPrefix={"fal"}
          isPrimaryAction={false}
          disabledAction={this.checkOnboardingMode() || false}
          onButtonClick={() => {
            if (this.checkOnboardingMode()) {
              return;
            }
            this.props.appDispatch.appActions.onboardingStart("CreateProduct");
          }}
          isComplete={this.state.isProductSetupComplete}
        />
        <GuideRow
          row={{
            title: "Create a company",
            subtitle: "Add your company to the system",
            buttonLabel: "Add company"
          }}
          icon={"building"}
          iconPrefix={"fal"}
          isPrimaryAction={false}
          disabledAction={this.checkOnboardingMode() || false}
          onButtonClick={() => {
            if (this.checkOnboardingMode()) {
              return;
            }
            this.props.appDispatch.appActions.onboardingStart("CreateCompany");
          }}
          isComplete={this.state.isCompanySetupComplete}
        />
        {/* <GuideRow
          row={{
            title: "Salesforce integration",
            subtitle:
              "Streamline your processes and easily manage them across multiple systems",
            buttonLabel: "Integrations"
          }}
          icon={"FaSalesforce"}
          isPrimaryAction={false}
          onButtonClick={() => this.navToAccountBlade("integrations")}
        /> */}
        {/* <GuideRow
          row={{
            title: "Watch videos",
            subtitle:
              "Explore our educational library and learn more about elm features",
            buttonLabel: "View videos"
          }}
          icon={"tv"}
          iconPrefix={"fal"}
          isPrimaryAction={false}
          onButtonClick={() => {}}
        /> */}
      </Flex>
    );
  };
  public skipOnboardingWorkflow = () => {
    this.props.openDialog("ConfirmationDialog", {
      title: "Skip guide?",
      isSimpleConfirmation: true,
      warningMessage: `
        You are about to skip our guide.
        Are your sure you want to skip it?
      `,
      onConfirm: () => {
        this.props.appDispatch.appActions.onboardingSkip();
        this.props.closeDialog("ConfirmationDialog");
        this.closeWelcomeGuide();
      }
    });
  };
  public deferOnboardingWorkflow = () => {
    this.props.appDispatch.appActions.onboardingDefer();
    this.closeWelcomeGuide();
  };
  public renderActionButtons = () => {
    let isOnboardingComplete = false;
    if (getSavedRoleFromLocalSync()?.role === "owner") {
      if (
        this.state.isAccountSetupComplete &&
        this.state.isUserInviteComplete &&
        this.state.isProductSetupComplete &&
        this.state.isCompanySetupComplete
      ) {
        isOnboardingComplete = true;
      }
    } else {
      if (
        this.state.isAccountSetupComplete &&
        this.state.isProductSetupComplete &&
        this.state.isCompanySetupComplete
      ) {
        isOnboardingComplete = true;
      }
    }
    return (
      <>
        {isOnboardingComplete ? (
          <ElmButton
            colorVariance={"primary"}
            label={"Finish"}
            onClick={this.closeWelcomeGuide}
          />
        ) : (
          <>
            <ElmButton
              colorVariance={"outline-secondary"}
              label={"Skip"}
              style={{ marginLeft: "0px" }}
              onClick={this.skipOnboardingWorkflow}
            />
            <ElmButton
              label={"Continue later"}
              colorVariance={"primary"}
              onClick={this.deferOnboardingWorkflow}
            />
          </>
        )}
      </>
    );
  };
  public closeWelcomeGuide = () => {
    this.props.closeBlade({
      route: "Welcome",
      fromBladeIndex: this.props.index
    });
    this.props.openBlade({
      routeName: "Products",
      routeData: null,
      fromBladeIndex: this.props.index - 1,
      route: "Products"
    });
  };
  public render() {
    return (
      <BladeTemplate
        renderLeftSideHeader={this.renderLeftSideHeader}
        accentColor={"pink"}
        bladeTypeName="Welcome"
        bladeIndex={this.props.index}
        bladeType="Welcome"
        closeBlade={this.props.closeBlade}
        title={"Welcome"}
        bladeBodyStyle={{ paddingRight: "24px", paddingLeft: "24px" }}
        loading={this.state.loading}
      >
        <Flex marginTop={40}>{this.renderRows()}</Flex>
        <Flex marginTop={40}>{this.renderActionButtons()}</Flex>
        <Tour
          open={this.props.appState.onboardingMode?.open || false}
          mask={false}
          type="primary"
          indicatorsRender={null}
          steps={this.getSteps()}
          current={this.props.appState.onboardingMode?.index || 0}
        />
      </BladeTemplate>
    );
  }
}
const RenderQuery = (props: IWelcomeBladeProps) => {
  const theme = useTheme();
  return <WelcomeBlade {...props} theme={theme} />;
};

export default appConnect(RenderQuery, {
  selectors: {
    activeMode: "activeModeSelector",
    userDefinedMode: "userDefinedModeSelector",
    onboardingMode: "onboardingModeSelector"
  }
});
