/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CompaniesQueryVariables = {};
export type CompaniesQueryResponse = {
  readonly allCompaniesCount: number;
  readonly allCompanies: {
    readonly nodes: ReadonlyArray<{
      readonly usersCount: number;
    } | null> | null;
  } | null;
};
export type CompaniesQuery = {
  readonly response: CompaniesQueryResponse;
  readonly variables: CompaniesQueryVariables;
};

/*
query CompaniesQuery {
  allCompaniesCount: companiesCount
  allCompanies: companies {
    nodes {
      usersCount
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      alias: "allCompaniesCount",
      args: null,
      kind: "ScalarField",
      name: "companiesCount",
      storageKey: null
    } as any,
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "usersCount",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "CompaniesQuery",
      selections: [
        v0 /*: any*/,
        {
          alias: "allCompanies",
          args: null,
          concreteType: "CompanyConnection",
          kind: "LinkedField",
          name: "companies",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Company",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [v1 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "CompaniesQuery",
      selections: [
        v0 /*: any*/,
        {
          alias: "allCompanies",
          args: null,
          concreteType: "CompanyConnection",
          kind: "LinkedField",
          name: "companies",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Company",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v1 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "id",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "e464541166348540e04dcdba38e9d885",
      id: null,
      metadata: {},
      name: "CompaniesQuery",
      operationKind: "query",
      text:
        "query CompaniesQuery {\n  allCompaniesCount: companiesCount\n  allCompanies: companies {\n    nodes {\n      usersCount\n      id\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "dd14fbb9e65db98aabc4b8a8985ec821";
export default node;
