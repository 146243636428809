import React from "react";
import { ReactComponent as SortAsc } from "./sort/asc.svg";
import { ReactComponent as SortDsc } from "./sort/dsc.svg";

export const ElmAsc = (props: any) => {
  return <SortAsc />;
};
export const ElmDsc = (props: any) => {
  return <SortDsc />;
};
