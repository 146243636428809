import { graphql } from "babel-plugin-relay/macro";
import { tableGenerator } from "../../../utils/tableGenerator";
import { licenseEntitlementsListTable_componentEntitlements } from "./__generated__/licenseEntitlementsListTable_componentEntitlements.graphql";

export type licenseEntitlementListEdges = licenseEntitlementsListTable_componentEntitlements["license"]["componentEntitlements"]["edges"];
export type licenseTableType = licenseEntitlementListEdges[number]["node"];

export type ILicenseHistoryTableProps = {
  componentEntitlements: licenseEntitlementsListTable_componentEntitlements;
};

export const LicenseEntitlementListTable = tableGenerator<
  ILicenseHistoryTableProps
>({
  columns: [
    {
      Header: "Start",
      accessor: "latestTerm.startDate",
      isDateFormat: true
    },
    {
      Header: "Components",
      accessor: "componentGroup.name"
    },
    {
      Header: "Expires",
      accessor: "latestTerm.endDate",
      isDateFormat: true
    }
  ],
  connectionQuery: graphql`
    query licenseEntitlementsListTablePaginationQuery(
      $id: ID!
      $count: Int!
      $cursor: String #$search: String
    ) {
      ...licenseEntitlementsListTable_componentEntitlements
        @arguments(id: $id, count: $count, cursor: $cursor)
    }
  `,
  dataKey: "componentEntitlements.license.componentEntitlements",
  fragmentSpec: {
    componentEntitlements: graphql`
      fragment licenseEntitlementsListTable_componentEntitlements on Query
        @argumentDefinitions(
          id: { type: "ID!" }
          count: { type: "Int", defaultValue: 6 }
          cursor: { type: "String" }
          #search: { type: "String", defaultValue: null }
        ) {
        license(id: $id) {
          componentEntitlements(first: $count, after: $cursor)
            @connection(
              key: "LicenseEntitlementsListTable_componentEntitlements"
            ) {
            edges {
              node {
                componentGroup {
                  name
                }
                latestTerm {
                  endDate
                  startDate
                }
                tokenCount
                currencyCount
                componentsCount
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `
  }
});
export default LicenseEntitlementListTable;
