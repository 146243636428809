/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type releaseLicenseOwnerTable_licenses = {
  readonly releaseChannel: {
    readonly id: string;
    readonly licenses: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly owner: {
            readonly name: string | null;
            readonly type: string | null;
          } | null;
          readonly key: string;
        } | null;
      } | null> | null;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    } | null;
  } | null;
  readonly " $refType": "releaseLicenseOwnerTable_licenses";
};
export type releaseLicenseOwnerTable_licenses$data = releaseLicenseOwnerTable_licenses;
export type releaseLicenseOwnerTable_licenses$key = {
  readonly " $data"?: releaseLicenseOwnerTable_licenses$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"releaseLicenseOwnerTable_licenses">;
};

const node: ReaderFragment = (function() {
  var v0 = {
    alias: null,
    args: null,
    kind: "ScalarField",
    name: "id",
    storageKey: null
  } as any;
  return {
    argumentDefinitions: [
      {
        defaultValue: 25,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["releaseChannel", "licenses"]
        }
      ]
    },
    name: "releaseLicenseOwnerTable_licenses",
    selections: [
      {
        alias: null,
        args: [
          {
            kind: "Variable",
            name: "id",
            variableName: "id"
          }
        ],
        concreteType: "ReleaseChannel",
        kind: "LinkedField",
        name: "releaseChannel",
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: "licenses",
            args: [
              {
                kind: "Variable",
                name: "search",
                variableName: "search"
              }
            ],
            concreteType: "LicenseConnection",
            kind: "LinkedField",
            name: "__releaseLicenseOwnerTable_licenses_connection",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "LicenseEdge",
                kind: "LinkedField",
                name: "edges",
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: "License",
                    kind: "LinkedField",
                    name: "node",
                    plural: false,
                    selections: [
                      v0 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        concreteType: null,
                        kind: "LinkedField",
                        name: "owner",
                        plural: false,
                        selections: [
                          {
                            alias: null,
                            args: null,
                            kind: "ScalarField",
                            name: "name",
                            storageKey: null
                          },
                          {
                            alias: null,
                            args: null,
                            kind: "ScalarField",
                            name: "type",
                            storageKey: null
                          }
                        ],
                        storageKey: null
                      },
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "key",
                        storageKey: null
                      },
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "__typename",
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "cursor",
                    storageKey: null
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: "PageInfo",
                kind: "LinkedField",
                name: "pageInfo",
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "hasNextPage",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "endCursor",
                    storageKey: null
                  }
                ],
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "e5f787a7f0d734628a9aa41388781912";
export default node;
