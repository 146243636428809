import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import { TableCellProps } from "react-virtualized";
import * as _ from "lodash";
import {
  getStaticMediaFile,
  LicensePlaceHolderLogo,
  tableGenerator
} from "../../../utils";
import { IColumn, TableCellPTag } from "../../../components/elmTable";
import { productsTable_products } from "./__generated__/productsTable_products.graphql";
import { ElmAppImage } from "utils/AppImageDictionary/ElmAppImage";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { shallowEqual } from "fast-equals";
import { LoaderContainer } from "components/helpers";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import {
  productsTableQuery,
  productsTableQueryResponse
} from "./__generated__/productsTableQuery.graphql";

export type productTableEdges = productsTable_products["products"]["edges"];
export type productTableType = productTableEdges[number]["node"];
export type IProductTableProps = {
  products?: productsTable_products;
};
const renderProductImageAndName = (payload: TableCellProps) => {
  return (
    <Flex
      alignItems="center"
      style={{ minWidth: "max-content", height: "42px" }}
    >
      <div
        style={{
          marginRight: "5px"
        }}
      >
        <ElmAppImage
          id={payload.cellData?.id || payload.cellData?.name}
          url={payload.cellData?.iconUrl}
          name={payload.cellData?.name}
        />
      </div>
      <TableCellPTag
        style={{
          minWidth: "max-content",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          margin: "0px"
        }}
        title={_.get(payload.cellData, "name")}
      >
        {_.get(payload.cellData, "name")}
      </TableCellPTag>
    </Flex>
  );
};

export enum ProductsTableColumns {
  Products = "Products",
  ActiveLicenses = "ActiveLicenses",
  ActiveUsers = "ActiveUsers",
  Type = "Type"
}
export const ProductsTableColumnConfig: Record<
  ProductsTableColumns,
  IColumn
> = {
  [ProductsTableColumns.Products]: {
    Header: "Product",
    sortKey: "product.name",
    accessor: "product.name",
    cellDataGetter: payload => {
      return payload.rowData;
    },
    cellRenderer: payload => renderProductImageAndName(payload),
    width: 0.4
  },
  [ProductsTableColumns.ActiveLicenses]: {
    Header: "Active Licenses",
    //width: 0.1957,
    isNumber: true,
    accessor: "activeLicenseCount",
    sortKey: "activeLicenseCount",
    showZeroAsInactive: true
    // filterable: {
    //   filterName: "products.active_license_count",
    //   filterGenerator: payload => {
    //     if (
    //       payload.filterColumnSelection.includes("Inactive") &&
    //       payload.filterColumnSelection.includes("Active")
    //     ) {
    //       payload.filterState.filterColumn = null;
    //       payload.filterState.filterValues = null;
    //       payload.filterState.filterOp = null;
    //       return payload.filterState;
    //     }
    //     if (payload.filterColumnSelection.includes("Active")) {
    //       payload.filterState.filterColumn.push(
    //         "products.active_license_count"
    //       );
    //       payload.filterState.filterValues.push(["0"]);
    //       payload.filterState.filterOp.push([">"]);
    //     }

    //     if (payload.filterColumnSelection.includes("Inactive")) {
    //       payload.filterState.filterColumn.push(
    //         "products.active_license_count"
    //       );
    //       payload.filterState.filterValues.push(["1"]);
    //       payload.filterState.filterOp.push(["<"]);
    //     }
    //     return payload.filterState;
    //   },
    //   overrideData: () => ["Active", "Inactive"],
    //   defaultSelected: ["Active"],
    //   dataKey: null,
    //   dataMap: payload => payload
    // }
  },
  [ProductsTableColumns.ActiveUsers]: {
    Header: "Active Users",
    //width: 0.1957,
    isNumber: true,
    accessor: "activeUsers",
    disableSortBy: true,
    showZeroAsInactive: true
  },
  [ProductsTableColumns.Type]: {
    Header: "Type",
    //width: 0.3,
    accessor: "type.name",
    disableSortBy: true
  }
};

export const GeneratedTable = tableGenerator<IProductTableProps>({
  rowHeight: 42,
  isTableRowInActive: (
    rowData: productsTable_products["products"]["edges"][number]["node"]
  ) => {
    return false;
  },
  columns: [
    ...Object.keys(ProductsTableColumnConfig).map(
      key => ProductsTableColumnConfig[key as ProductsTableColumns]
    )
  ],
  connectionQuery: graphql`
    query productsTablePaginationQuery(
      $count: Int!
      $cursor: String
      $blade_scope: String
      $search: String
      $sortColumn: [String!]
      $sortDirection: [String!]
      $skipLicenseCount: Boolean!
    ) {
      ...productsTable_products
        @arguments(
          count: $count
          cursor: $cursor
          blade_scope: $blade_scope
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
          skipLicenseCount: $skipLicenseCount
        )
    }
  `,
  dataKey: "products.products",
  getTotalCount: data => {
    return data?.products?.productsCount || 0;
  },
  fragmentSpec: {
    products: graphql`
      fragment productsTable_products on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          blade_scope: { type: "String", defaultValue: null }
          search: { type: "String", defaultValue: null }
          sortColumn: { type: "[String!]", defaultValue: ["product.name"] }
          sortDirection: { type: "[String!]", defaultValue: ["asc"] }
          skipLicenseCount: { type: "Boolean!", defaultValue: false }
        ) {
        productsCount(search: $search, bladeScope: $blade_scope)
        products(
          first: $count
          after: $cursor
          bladeScope: $blade_scope
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        ) @connection(key: "productsTable_products") {
          edges {
            cursor
            node {
              activeLicenseCount @skip(if: $skipLicenseCount)
              id
              name
              iconUrl
              activeUsers
              type {
                name
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  },
  tableName: "productsTable"
});

const graphqlQuery = graphql`
  query productsTableQuery($blade_scope: String!, $skipLicenseCount: Boolean!) {
    ...productsTable_products
      @arguments(blade_scope: $blade_scope, skipLicenseCount: $skipLicenseCount)
  }
`;

class ProductsPaginationTable extends React.Component<
  IGeneratedTableProps & {
    result: productsTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        products={res}
      />
    );
  }
}

export const ProductsTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: productsTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <ProductsPaginationTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<productsTableQuery>
      environment={relayEnvironment}
      variables={{
        blade_scope: props.bladeScope || "",
        sortColumn: ["product.name"],
        sortDirection: ["ASC"],
        skipLicenseCount:
          props.hideColumnsWithHeaders?.includes(
            ProductsTableColumns.ActiveLicenses
          ) || false
      }}
      query={graphqlQuery}
      fetchPolicy="network-only"
      render={renderTable}
    />
  );
};

export default ProductsTable;
