import { IOnboardingState } from "store/types";

export const getOnboardingConfigSync = (
  payload: NonNullable<IOnboardingState["type"]>
) => {
  const data = localStorage.getItem(`onboarding${payload}`);
  return data ? (JSON.parse(data) as IOnboardingState) : null;
};

export const setOnboardingConfigSync = (payload: IOnboardingState) => {
  localStorage.setItem(`onboarding${payload.type}`, JSON.stringify(payload));
};
