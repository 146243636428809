import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import React from "react";
import { userProductsTable_activation } from "./__generated__/userProductsTable_activation.graphql";
import {
  IGeneratedTableProps,
  tableGenerator
} from "../../../utils/tableGenerator";
import { TableCell } from "../../../components/elmTable";
import ToggleActivation from "./toggleActivation";
import { Flex } from "reflexbox";
import { LoaderContainer } from "components/helpers";
import { QueryRenderer } from "react-relay";
import { getEnvironment } from "api/relay";
import {
  userProductsActivationTableQuery,
  userProductsActivationTableQueryResponse
} from "./__generated__/userProductsActivationTableQuery.graphql";

export type userProductHistoryEdges = userProductsTable_activation["status"]["productUsers"]["edges"];
export type userProductHistoryTableType = userProductHistoryEdges[number]["node"];
export type IUserProductHistoryProps = {
  status: userProductsTable_activation;
  retry?: any;
};

const GeneratedTable = tableGenerator<IUserProductHistoryProps>({
  dataKey: "status.status.productStatus.productUsers",
  rowHeight: 30,
  getNodesTransform: props => {
    const products = _.get(
      props,
      "status.status.productUsers.edges"
    ) as userProductsTable_activation["status"]["productUsers"]["edges"];
    const instances = _.get(
      props,
      "status.status.instances.edges"
    ) as userProductsTable_activation["status"]["instances"]["edges"];

    const transformedProducts = _.reduce(
      products,
      (productsInfo, info) => {
        productsInfo.push({
          enabled: info.node.enabled,
          ...info.node.product
        });
        return productsInfo;
      },
      []
    );
    const transformedInstances = _.reduce(
      instances,
      (instancesInfo, info) => {
        let productId = info.node.license.product.id;
        let isEnabled = !!_.first(info.node.modificationRecords.edges).node
          .enabled;
        const record = {
          name: info.node.license.product.name,
          totalEnabled: instancesInfo[productId]?.totalEnabled || 0
        };
        instancesInfo[productId] = record;
        if (isEnabled) instancesInfo[productId]["totalEnabled"]++;
        return instancesInfo;
      },
      {}
    );

    const transfrom = transformedProducts.map(product => {
      return {
        id: product.id,
        name: product.name,
        enabled: product.enabled,
        totalEnabled: transformedInstances[product.id]?.totalEnabled || 0
        // retry: props?.retry
      };
    });
    return transfrom;
  },
  columns: [
    {
      Header: "Product",
      accessor: "name"
    },
    {
      Header: "Activation",
      accessor: "action",
      cellRenderer: () => ""
    }
  ],
  connectionQuery: graphql`
    query userProductsActivationQuery(
      $id: ID!
      $count: Int!
      $cursor: String
      $search: String
    ) {
      ...userProductsTable_activation
        @arguments(id: $id, count: $count, cursor: $cursor, search: $search)
    }
  `,
  fragmentSpec: {
    status: graphql`
      fragment userProductsTable_activation on Query
        @argumentDefinitions(
          id: { type: "ID!" }
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
        ) {
        status: user(id: $id) {
          displayName
          productUsers(first: $count, after: $cursor, search: $search)
            @connection(key: "userProductsTable_productUsers") {
            edges {
              node {
                product {
                  id
                  name
                }
                enabled
              }
            }
          }
          instances {
            edges {
              node {
                id
                #hostName
                license {
                  product {
                    id
                    name
                  }
                }
                modificationRecords(last: 1) {
                  edges {
                    node {
                      enabled
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  },
  tableName: "userProducts"
});

const graphqlQuery = graphql`
  query userProductsActivationTableQuery($id: ID!) {
    ...userProductsTable_activation @arguments(id: $id)
  }
`;

class UserProductHistoryPaginationTable extends React.Component<
  IGeneratedTableProps & {
    result: userProductsActivationTableQueryResponse;
    retry: () => void;
  }
> {
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        status={res}
      />
    );
  }
}

const relayEnvironment = getEnvironment();
const UserProductActivationTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: userProductsActivationTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <UserProductHistoryPaginationTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<userProductsActivationTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.bladeScope || ""
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default UserProductActivationTable;
