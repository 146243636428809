/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type analyticsComponentQueryVariables = {
  bladeScope?: string | null | undefined;
  setup: unknown;
  startTime: string;
  first?: number | null | undefined;
  sortColumn?: string | null | undefined;
  sortDirection?: string | null | undefined;
  filterColumn?: Array<string> | null | undefined;
  filterValues?: Array<Array<string>> | null | undefined;
};
export type analyticsComponentQueryResponse = {
  readonly analytics: {
    readonly categories: ReadonlyArray<string> | null;
    readonly groups: ReadonlyArray<string | null> | null;
    readonly values: ReadonlyArray<unknown>;
  } | null;
};
export type analyticsComponentQuery = {
  readonly response: analyticsComponentQueryResponse;
  readonly variables: analyticsComponentQueryVariables;
};

/*
query analyticsComponentQuery(
  $bladeScope: String
  $setup: JSON!
  $startTime: String!
  $first: Int
  $sortColumn: String
  $sortDirection: String
  $filterColumn: [String!]
  $filterValues: [[String!]!]
) {
  analytics(bladeScope: $bladeScope, setup: $setup, startTime: $startTime, sortColumn: $sortColumn, sortDirection: $sortDirection, first: $first, filterColumn: $filterColumn, filterValues: $filterValues) {
    categories
    groups
    values
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "bladeScope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "first"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "setup"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v6 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v7 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "startTime"
    } as any,
    v8 = [
      {
        alias: null,
        args: [
          {
            kind: "Variable",
            name: "bladeScope",
            variableName: "bladeScope"
          },
          {
            kind: "Variable",
            name: "filterColumn",
            variableName: "filterColumn"
          },
          {
            kind: "Variable",
            name: "filterValues",
            variableName: "filterValues"
          },
          {
            kind: "Variable",
            name: "first",
            variableName: "first"
          },
          {
            kind: "Variable",
            name: "setup",
            variableName: "setup"
          },
          {
            kind: "Variable",
            name: "sortColumn",
            variableName: "sortColumn"
          },
          {
            kind: "Variable",
            name: "sortDirection",
            variableName: "sortDirection"
          },
          {
            kind: "Variable",
            name: "startTime",
            variableName: "startTime"
          }
        ],
        concreteType: "AnalyticsResult",
        kind: "LinkedField",
        name: "analytics",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "categories",
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "groups",
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "values",
            storageKey: null
          }
        ],
        storageKey: null
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "analyticsComponentQuery",
      selections: v8 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v4 /*: any*/,
        v7 /*: any*/,
        v3 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/
      ],
      kind: "Operation",
      name: "analyticsComponentQuery",
      selections: v8 /*: any*/
    },
    params: {
      cacheID: "6f86ce137b02a9cc260aee08dfc26a07",
      id: null,
      metadata: {},
      name: "analyticsComponentQuery",
      operationKind: "query",
      text:
        "query analyticsComponentQuery(\n  $bladeScope: String\n  $setup: JSON!\n  $startTime: String!\n  $first: Int\n  $sortColumn: String\n  $sortDirection: String\n  $filterColumn: [String!]\n  $filterValues: [[String!]!]\n) {\n  analytics(bladeScope: $bladeScope, setup: $setup, startTime: $startTime, sortColumn: $sortColumn, sortDirection: $sortDirection, first: $first, filterColumn: $filterColumn, filterValues: $filterValues) {\n    categories\n    groups\n    values\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "efbce338ce2948de658eb4d17b6abd70";
export default node;
