import { TourProps } from "antd";

export interface TourGuideConfigState {
  tourGuideOpen: boolean;
  tourSteps?: TourProps["steps"];
}

export enum TourGuideId {
  OpenAccount = "account-sidebar-link-id",
  AccountInfo = "account-info-id",
  Notifications = "notifications-tab-id",
  EmailPreferences = "email-preferences-button-id",
  OpenProducts = "products-sidebar-link-id",
  CreateProduct = "create-product-button-id",
  OpenCompanies = "companies-sidebar-link-id",
  CreateCompany = "create-company-button-id",
  OpenLicense = "licenses-sidebar-link-id",
  AddButton = "elm-table-add-button"
}
