import { Select } from "antd";
import { Flex } from "reflexbox/styled-components";
import * as _ from "lodash";
import React, { useState } from "react";
import { SelectProps } from "antd/lib/select";

import styled, { css } from "styled-components";
import { getIcon } from "../icons";
import { StyledErrorLabel, StyledLabel } from "../elmInput/styles";
import StyledLabelNote from "../elmLabel";

const NoOutline = css<{ borderColor?: string }>`
  outline: none;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  opacity: 1;
`;
const BorderlessSelect = (props: SelectProps<any>) => (
  <Select
    {...props}
    bordered={false}
    popupClassName="elm-ant-select-dropdown"
  />
);
const BorderlessFilterSelect = (props: SelectProps<any>) => (
  <Select
    {...props}
    bordered={false}
    popupClassName="elm-ant-select-dropdown"
    className={`filter ${props.className}`}
  />
);

const BorderlessFilterSelectNew = (props: SelectProps<any>) => (
  <Select {...props} bordered={true} className={`filter ${props.className}`} />
);
const ElmSelect = styled(BorderlessSelect)<
  { borderColor?: string; error?: boolean } & SelectProps<any>
>`
  width: 100%;
  height: 18px;
  ${NoOutline}
  display: flex;
  vertical-align: center;
  font-size: ${props => props.theme.fontSizes.xSmall};
  &:focus {
    border-bottom: 1px solid
      ${props => props.borderColor || props.theme.colors.lightBlue};
  }
  & {
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    outline: none !important;
    & > .ant-select-selector {
      & > .ant-select-selection-placeholder {
        line-height: unset;
        font-size: ${props => props.theme.fontSizes.xSmall};
        color: ${props => props.theme.colors.greyish};
      }
    }
  }
  & > .ant-select-selector {
    height: 18px !important;
    padding: 0px !important;
    span.ant-select-selection-search {
      top: unset;
      right: unset;
      bottom: -4.5px;
      left: unset;
    }
  }
  & > .ant-select-arrow {
    right: 0px;
    width: 16px;
    height: 19px;
    top: 0px;
    margin-top: unset;
    vertical-align: unset;
    color: #707070;
  }

  &:not(.ant-select-open) {
    border-bottom: 1px solid
      ${props =>
        props.error
          ? props.theme.colors.orangeRed
          : props.borderColor || props.theme.colors.greyish} !important;
    :hover {
      border-bottom: 1px solid
        ${props => props.borderColor || props.theme.colors.lightBlue} !important;
    }
  }
  &.ant-select-open {
    border-bottom: 1px solid
      ${props => props.borderColor || props.theme.colors.lightBlue} !important;
  }

  .ant-select-selection--single .ant-select-selection__rendered {
    ${NoOutline}
  }

  li {
    :hover {
      background-color: ${props => props.theme.colors.lightGrey};
    }
  }

  &.ant-select-disabled {
    background-color: ${props => props.theme.colors.lightGrey};
  }
`;

const ElmFilterSelectBaseNew = styled(BorderlessFilterSelectNew)<
  { borderColor?: string } & SelectProps<any>
>`
  width: 100%;

  font-size: ${props => props.theme.fontSizes.ySmall};
  & > .ant-select-arrow {
    right: 2px;
    width: 16px;
    height: 16px;
    color: #707070;
  }

  & > .anticon-search {
    display: flex;
    order: 1;
  }
  & > .ant-select-selector {
    padding-left: 6px;
    background-color: transparent !important;
    margin-right: 12px;
  }
  width: fit-content;
  border: ${props => props.theme.colors.lightGrey};
`;
const ElmSelectOption = styled(Select.Option)`
  :hover {
    background-color: ${props => props.theme.colors.lightGrey};
  }
`;

const Icon = getIcon("chevron-down", "fas");
const Up = getIcon("chevron-up", "fas");
const SuffixIcon = styled(Icon)`
  .ant-select:not(.ant-select-open) & {
    color: ${props => props.theme.colors.warmGrey};
  }
  .filter.ant-select & {
    color: ${props => props.theme.colors.textPrimary};
  }
  .ant-select:not(.filter).ant-select-open &,
  .ant-select.filter:not(.filtered).ant-select-open & {
    color: ${props => props.theme.colors.lightBlue};
  }
  .ant-select & {
    height: unset;
    width: 10px;
    transform: translateY(-2px);
  }
`;
const SuffixIconUp = styled(Up)`
  .ant-select:not(.ant-select-open) & {
    color: ${props => props.theme.colors.warmGrey};
  }
  .filter.ant-select & {
    color: ${props => props.theme.colors.textPrimary};
  }
  .ant-select:not(.filter).ant-select-open &,
  .ant-select.filter:not(.filtered).ant-select-open & {
    color: ${props => props.theme.colors.lightBlue};
  }
  .ant-select & {
    height: unset;
    width: 10px;
    transform: translateY(-2px);
  }
`;

interface ICaret {
  error?: boolean;
  isOpen?: boolean;
}

const getCaretIcon = ({ error, isOpen }: ICaret) => {
  if (isOpen)
    return <SuffixIconUp style={{ color: error ? "red" : "inherit" }} />;
  return <SuffixIcon style={{ color: error ? "red" : "inherit" }} />;
};

export const ElmSelectWithLabel: React.FunctionComponent<SelectProps<any> & {
  label?: string;
  borderColor?: string;
  note?: string;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
}> = props => {
  // const [isMenuOpen, setMenuOpen] = useState<boolean>(props.open || false);
  const handleOnChange = (payload: any) => {
    if (_.isFunction(props.onChange)) {
      props.onChange(payload);
    }
  };
  return (
    <Flex className={"elm-select-with-label"}>
      {!!props.label ? (
        <StyledLabel className="label">
          {props.label} {props.required ? "*" : ""}
        </StyledLabel>
      ) : null}
      <ElmSelect
        {...props}
        loading
        defaultValue={props.defaultValue}
        optionLabelProp="label"
        suffixIcon={getCaretIcon({
          error: props.error
          //isOpen: isMenuOpen
        })}
        onChange={handleOnChange}
        value={props.value}
        // onClick={() => setMenuOpen(!isMenuOpen)}
        // open={isMenuOpen}
        // onBlur={() => setMenuOpen(false)}
      >
        {props.children}
      </ElmSelect>
      {props?.note ? <StyledLabelNote label={props.note} /> : null}
      <StyledErrorLabel error={props.error} errorMessage={props.errorMessage}>
        {props.errorMessage}
      </StyledErrorLabel>
    </Flex>
  );
};
const ElmDefaultSelect: React.FunctionComponent<SelectProps<any>> = props => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const handleOnChange = (payload: any) => {
    if (_.isFunction(props.onChange)) {
      props.onChange(payload);
    }
  };
  return (
    <ElmSelect
      {...props}
      suffixIcon={getCaretIcon({ isOpen: isMenuOpen })}
      onChange={handleOnChange}
      onClick={() => setMenuOpen(!isMenuOpen)}
      open={isMenuOpen}
      onBlur={() => setMenuOpen(false)}
    >
      {props.children}
    </ElmSelect>
  );
};
const ElmFilterSelect: React.FunctionComponent<SelectProps<any>> = props => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const handleOnChange = (payload: any) => {
    if (_.isFunction(props.onChange)) {
      props.onChange(payload);
    }
  };

  return (
    <ElmFilterSelectBaseNew
      {...props}
      showSearch={props.showSearch}
      //open={true}
      //allowClear={true}
      suffixIcon={getCaretIcon({ isOpen: isMenuOpen })}
      //className={`filter ${props.className}`}
      onChange={handleOnChange}
      onClick={() => setMenuOpen(!isMenuOpen)}
      open={isMenuOpen}
      onBlur={() => setMenuOpen(false)}
    >
      {props.children}
    </ElmFilterSelectBaseNew>
  );
};
export {
  ElmSelect,
  ElmSelectOption,
  ElmDefaultSelect,
  ElmFilterSelect,
  getCaretIcon
};
export default ElmSelect;
