import { ReduxTokenAuthState } from "./types";

const initialState: ReduxTokenAuthState = {
  currentUser: {
    isSignedIn: false,
    isLoading: false,
    signInFailed: false,
    hasVerificationBeenAttempted: false,
    attributes: {},
    currentError: null
  }
};

export default initialState;
