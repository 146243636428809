/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type editReleaseConfigQueryVariables = {
  id: string;
};
export type editReleaseConfigQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"productReleasesTable_releases">;
};
export type editReleaseConfigQuery = {
  readonly response: editReleaseConfigQueryResponse;
  readonly variables: editReleaseConfigQueryVariables;
};

/*
query editReleaseConfigQuery(
  $id: ID!
) {
  ...productReleasesTable_releases_1Bmzm5
}

fragment productReleasesTable_releases_1Bmzm5 on Query {
  releaseConfiguration(id: $id) {
    releases(sortColumn: ["releases.createdAt"], sortDirection: ["DESC"]) {
      edges {
        node {
          id
          tag
          releaseChannelsCount
          createdAt
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = [
      {
        kind: "Literal",
        name: "sortColumn",
        value: ["releases.createdAt"]
      } as any,
      {
        kind: "Literal",
        name: "sortDirection",
        value: ["DESC"]
      } as any
    ],
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "editReleaseConfigQuery",
      selections: [
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "productReleasesTable_releases"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "editReleaseConfigQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ReleaseConfiguration",
          kind: "LinkedField",
          name: "releaseConfiguration",
          plural: false,
          selections: [
            {
              alias: null,
              args: v2 /*: any*/,
              concreteType: "ReleaseConnection",
              kind: "LinkedField",
              name: "releases",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Release",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "tag",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "releaseChannelsCount",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "createdAt",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey:
                'releases(sortColumn:["releases.createdAt"],sortDirection:["DESC"])'
            },
            {
              alias: null,
              args: v2 /*: any*/,
              filters: ["search", "sortColumn", "sortDirection"],
              handle: "connection",
              key: "productReleasesTable_releases",
              kind: "LinkedHandle",
              name: "releases"
            },
            v3 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "6e8ca745a4bf42b5fbabcbc9f7251c92",
      id: null,
      metadata: {},
      name: "editReleaseConfigQuery",
      operationKind: "query",
      text:
        'query editReleaseConfigQuery(\n  $id: ID!\n) {\n  ...productReleasesTable_releases_1Bmzm5\n}\n\nfragment productReleasesTable_releases_1Bmzm5 on Query {\n  releaseConfiguration(id: $id) {\n    releases(sortColumn: ["releases.createdAt"], sortDirection: ["DESC"]) {\n      edges {\n        node {\n          id\n          tag\n          releaseChannelsCount\n          createdAt\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n    id\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "f4abe7a7b8d40aee9f01dff6eb5a5051";
export default node;
