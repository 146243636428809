import React from "react";
import ReactImageFallback from "react-image-fallback";
import { getStaticMediaFile } from "utils";
import { generateProductIcon } from "../../components/productImages/productImages";
import AppImageDictionary from "./AppImageDictionary";

export const ElmAppImage = ({
  id,
  url,
  name,
  size
}: {
  id: string;
  url: string;
  name: string;
  size?: number;
}) => {
  const [icon, setIcon] = React.useState<string>(url);
  React.useEffect(() => {
    AppImageDictionary.getImageSrc(id, url).then(imgSrc => setIcon(imgSrc));
  }, [id, url]);

  return icon ? (
    <ReactImageFallback
      key={`r-${id}`}
      src={getStaticMediaFile({ url: icon })}
      fallbackImage={generateProductIcon(name)}
      initialImage={generateProductIcon(name)}
      alt={name || "Name not specified"}
      height={size || "16px"}
      width={size || "16px"}
    />
  ) : (
    <ReactImageFallback
      key={id}
      src={getStaticMediaFile({ url })}
      fallbackImage={generateProductIcon(name)}
      initialImage={generateProductIcon(name)}
      alt={name || "Name not specified"}
      height={size || "16px"}
      width={size || "16px"}
    />
  );
};
