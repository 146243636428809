/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReportsPaginationQueryVariables = {
  count: number;
  cursor?: string | null | undefined;
  search?: string | null | undefined;
  bladeScope?: string | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
};
export type ReportsPaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"Reports_reports">;
};
export type ReportsPaginationQuery = {
  readonly response: ReportsPaginationQueryResponse;
  readonly variables: ReportsPaginationQueryVariables;
};

/*
query ReportsPaginationQuery(
  $count: Int!
  $cursor: String
  $search: String
  $bladeScope: String
  $sortColumn: [String!]
  $sortDirection: [String!]
) {
  ...Reports_reports_2DDvAO
}

fragment Reports_reports_2DDvAO on Query {
  reportsCount(search: $search, bladeScope: $bladeScope)
  reports(first: $count, after: $cursor, search: $search, bladeScope: $bladeScope, sortColumn: $sortColumn, sortDirection: $sortDirection) {
    edges {
      node {
        id
        name
        options
        createdAt
        reportType
        isPrivate
        vendorSystemUser {
          id
          name
          email
        }
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "bladeScope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v6 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "bladeScope"
    } as any,
    v7 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v8 = {
      kind: "Variable",
      name: "sortColumn",
      variableName: "sortColumn"
    } as any,
    v9 = {
      kind: "Variable",
      name: "sortDirection",
      variableName: "sortDirection"
    } as any,
    v10 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      v6 /*: any*/,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      v7 /*: any*/,
      v8 /*: any*/,
      v9 /*: any*/
    ],
    v11 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v12 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "ReportsPaginationQuery",
      selections: [
        {
          args: [
            v6 /*: any*/,
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v7 /*: any*/,
            v8 /*: any*/,
            v9 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "Reports_reports"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v0 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/
      ],
      kind: "Operation",
      name: "ReportsPaginationQuery",
      selections: [
        {
          alias: null,
          args: [v6 /*: any*/, v7 /*: any*/],
          kind: "ScalarField",
          name: "reportsCount",
          storageKey: null
        },
        {
          alias: null,
          args: v10 /*: any*/,
          concreteType: "ReportConnection",
          kind: "LinkedField",
          name: "reports",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ReportEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Report",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    v11 /*: any*/,
                    v12 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "options",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "createdAt",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "reportType",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "isPrivate",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "VendorSystemUser",
                      kind: "LinkedField",
                      name: "vendorSystemUser",
                      plural: false,
                      selections: [
                        v11 /*: any*/,
                        v12 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "email",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v10 /*: any*/,
          filters: ["search", "bladeScope", "sortColumn", "sortDirection"],
          handle: "connection",
          key: "Reports_reports",
          kind: "LinkedHandle",
          name: "reports"
        }
      ]
    },
    params: {
      cacheID: "9a97839d20a5602559233325609fdefd",
      id: null,
      metadata: {},
      name: "ReportsPaginationQuery",
      operationKind: "query",
      text:
        "query ReportsPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $search: String\n  $bladeScope: String\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n) {\n  ...Reports_reports_2DDvAO\n}\n\nfragment Reports_reports_2DDvAO on Query {\n  reportsCount(search: $search, bladeScope: $bladeScope)\n  reports(first: $count, after: $cursor, search: $search, bladeScope: $bladeScope, sortColumn: $sortColumn, sortDirection: $sortDirection) {\n    edges {\n      node {\n        id\n        name\n        options\n        createdAt\n        reportType\n        isPrivate\n        vendorSystemUser {\n          id\n          name\n          email\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "4d76ee88eb53d3e6c4b97b2da1643e89";
export default node;
