/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type productCohortAnalyticsQueryVariables = {
  productId: string;
  cohortStartDate: string;
};
export type productCohortAnalyticsQueryResponse = {
  readonly ProductCohortAnalyticsUserRegistrations: {
    readonly nodes: ReadonlyArray<{
      readonly user: {
        readonly id: string;
        readonly displayName: string | null;
      } | null;
      readonly createdAt: unknown;
      readonly id: string;
    } | null> | null;
  } | null;
};
export type productCohortAnalyticsQuery = {
  readonly response: productCohortAnalyticsQueryResponse;
  readonly variables: productCohortAnalyticsQueryVariables;
};

/*
query productCohortAnalyticsQuery(
  $productId: String!
  $cohortStartDate: String!
) {
  ProductCohortAnalyticsUserRegistrations: registrationEntries(filterColumn: ["createdAt"], filterOp: [[">", "="]], filterValues: [[$cohortStartDate]], bladeScope: $productId) {
    nodes {
      user {
        id
        displayName
      }
      createdAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cohortStartDate"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "productId"
    } as any,
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = [
      {
        alias: "ProductCohortAnalyticsUserRegistrations",
        args: [
          {
            kind: "Variable",
            name: "bladeScope",
            variableName: "productId"
          },
          {
            kind: "Literal",
            name: "filterColumn",
            value: ["createdAt"]
          },
          {
            kind: "Literal",
            name: "filterOp",
            value: [[">", "="]]
          },
          {
            items: [
              {
                items: [
                  {
                    kind: "Variable",
                    name: "filterValues.0.0",
                    variableName: "cohortStartDate"
                  }
                ],
                kind: "ListValue",
                name: "filterValues.0"
              }
            ],
            kind: "ListValue",
            name: "filterValues"
          }
        ],
        concreteType: "RegistrationEntryConnection",
        kind: "LinkedField",
        name: "registrationEntries",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "RegistrationEntry",
            kind: "LinkedField",
            name: "nodes",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "User",
                kind: "LinkedField",
                name: "user",
                plural: false,
                selections: [
                  v2 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "displayName",
                    storageKey: null
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "createdAt",
                storageKey: null
              },
              v2 /*: any*/
            ],
            storageKey: null
          }
        ],
        storageKey: null
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: "Fragment",
      metadata: null,
      name: "productCohortAnalyticsQuery",
      selections: v3 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: "Operation",
      name: "productCohortAnalyticsQuery",
      selections: v3 /*: any*/
    },
    params: {
      cacheID: "7190f55f49cb5a2500a7c26a9c94de27",
      id: null,
      metadata: {},
      name: "productCohortAnalyticsQuery",
      operationKind: "query",
      text:
        'query productCohortAnalyticsQuery(\n  $productId: String!\n  $cohortStartDate: String!\n) {\n  ProductCohortAnalyticsUserRegistrations: registrationEntries(filterColumn: ["createdAt"], filterOp: [[">", "="]], filterValues: [[$cohortStartDate]], bladeScope: $productId) {\n    nodes {\n      user {\n        id\n        displayName\n      }\n      createdAt\n      id\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "2504b33848df0cc3c40fcf4d5632b7ea";
export default node;
