import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import ElmInput from "../../../../components/elmInput";
import { getGateway } from "../../../../api";
import { InfoMessage } from "../../../../components/helpers";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const DialogSubTitle = styled.p`
  ${props => props.theme.fontType.visualizationSubTitle}
`;

export interface INewProductReleaseConfigDialogProps {
  productId: string;
  name: string;
  isEdit?: boolean;
  releaseId?: string;
}
type state = {
  name: string;
  disabled: boolean;
};
export class NewProductReleaseConfigDialog extends DialogBase<
  IModalProps<INewProductReleaseConfigDialogProps>,
  state
> {
  state: state = {
    name: _.get(this.props.payload, "name", ""),
    disabled: false
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "NewProductReleaseConfigDialog"
    });
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };
  public getTitle = () => {
    return this.props.payload?.isEdit
      ? "Edit Release Configuration"
      : "Add a new Release Configuration";
  };

  public getDescription = () => {
    return `You are adding a new Release Configuration to ${_.get(
      this.props.payload,
      "productName"
    )}.`;
  };
  public createNewProductFeature = () => {
    this.setState(
      {
        disabled: true
      },
      () => {
        const gateway = getGateway();
        gateway.request
          .newProductReleaseConfig({
            product_id: this.props.payload.productId,
            name: this.state.name
          })
          .then(res => {
            if (!res.error) {
              if (res.data.id) {
                if (_.isFunction(this.props.payload.onConfirm)) {
                  this.props.payload.onConfirm();
                }
                return this.closeModal();
              }
            } else {
              console.error(res.error);
            }
            this.setState({ disabled: false });
            return false;
          })
          .catch(err => {
            console.error(err);
          });
      }
    );
  };

  public updateReleaseConfiguration = () => {
    this.props.payload.onConfirm({ name: this.state.name });
    if (_.isFunction(this.props.payload.onConfirm)) {
      this.props.payload.onConfirm({ name: this.state.name });
    }
  };

  public render() {
    const isEdit = _.get(this.props.payload, "isEdit", false);
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        {isEdit ? (
          <InfoMessage
            description={`Changing the name of a release configuration is permanent. Any releases using the old name will not be redirected to the new name. This action takes effect immediately.`}
            type="warning"
          />
        ) : (
          <DialogSubTitle>{this.getDescription()}</DialogSubTitle>
        )}

        <Flex
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            height: "102px"
          }}
        >
          <ElmInput
            defaultValue={this.state.name}
            updatedValue={this.updateName}
            label={"Release Configuration Name"}
            placeholder={"Name"}
          />
        </Flex>
        <Flex justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            disabled={this.state.disabled}
            label={"Confirm"}
            colorVariance="primary"
            onClick={
              isEdit
                ? this.updateReleaseConfiguration
                : this.createNewProductFeature
            }
          />
        </Flex>
      </DialogContainer>
    );
  }
}
