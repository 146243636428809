import React, { useState } from "react";
import { Flex } from "reflexbox";
import styled, { useTheme } from "styled-components";
import { getIcon } from "../icons";
import { Dropdown, MenuProps } from "antd";
import { ElmButton } from "components/elmButton";

interface IExportChart {
  root: any;
  data: any;
}

const icon = getIcon("AiFillInfoCircle");
export const InfoIcon = styled(icon)`
  width: 14px;
  height: 14px;
  margin-left: 2px;
  margin-right: 7px;
  margin-top: -7px;
  color: ${props => props.theme.colors.buttonIcon};
`;

export default function useChartExportButton() {
  const theme = useTheme();
  const [chartData, setChartData] = useState<IExportChart>();

  const updateChartData = (data: IExportChart) => {
    setChartData(data);
  };

  const exportChart = (exportType: "csv" | "png") => {
    if (!chartData?.root || !chartData?.data) {
      return;
    }
    let exporting = am5plugins_exporting.Exporting.new(chartData.root, {
      dataSource: chartData.data
      // menu: am5plugins_exporting.ExportingMenu.new(chartData, {})
    });
    exporting.download(exportType);
  };

  const categoryMenuStyle: React.CSSProperties = {
    fontSize: 10,
    fontWeight: 500,
    color: theme.colors.warmGrey
  };

  const btnStyle: React.CSSProperties = {
    color: theme.colors.black
  };

  const items: MenuProps["items"] = [
    {
      key: "data",
      label: "DATA",
      type: "group",
      style: categoryMenuStyle
    },
    {
      key: "csv",
      label: "CSV",
      style: btnStyle,
      onClick: () => exportChart("csv")
    },
    {
      key: "image",
      label: "IMAGE",
      type: "group",
      style: categoryMenuStyle
    },
    {
      key: "png",
      label: "PNG",
      style: btnStyle,
      onClick: () => exportChart("png")
    }
  ];

  const renderExportButton = () => {
    return (
      <Flex>
        {chartData?.data?.length ? (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <ElmButton
              style={{
                color: theme.colors.black,
                borderColor: theme.colors.warmGrey
              }}
              className={"darkModeTransparentBtn"}
              variance={"icon-button"}
              colorVariance={"outline-primary"}
              icon={"download"}
              iconPrefix={"far"}
              label=""
            />
          </Dropdown>
        ) : null}
      </Flex>
    );
  };

  return { updateChartData, renderExportButton } as const;
}
