import React from "react";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { getEnvironment } from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import { IBladeBaseProps } from "../../../components/bladeManager/types";
import { BladeTitle, BladeTemplate, ElmButton } from "../../../components";
import {
  UsersTable,
  UsersTableColumns,
  userTableType
} from "../../components/usersTable";
import {
  UsersQuery,
  UsersQueryResponse
} from "./__generated__/UsersQuery.graphql";

import ExportButton from "../../../components/elmTable/exportButton";
import { useTheme } from "styled-components";
import { REFRESH_KEY } from "components/bladeManager/entityRefreshMap";

export interface IUsersBladeProps extends IBladeBaseProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}

const graphqlQuery = graphql`
  query UsersQuery {
    allUsersCount: usersCount
    allProductsCount: productsCount
  }
`;

export const AnalyticsAction = ({
  data,
  analyticsAction
}: {
  data: userTableType;
  analyticsAction: (user: userTableType) => void;
}) => {
  const theme = useTheme();

  return (
    <div onClick={e => e.stopPropagation()} style={{ padding: "2px" }}>
      <ElmButton
        variance={"icon-button"}
        colorVariance={"subtle"}
        className="darkModeTransparentBtn"
        icon={"chart-mixed"}
        iconPrefix={"far"}
        label={""}
        style={{ color: theme.colors.black }}
        onClick={() => analyticsAction(data)}
      />
    </div>
  );
};
export class UsersBlade extends React.Component<
  IUsersBladeProps & {
    result: UsersQueryResponse;
    retry: () => void;
    theme: any;
  }
> {
  public navToUserBlade = (user: userTableType) => {
    this.props.openBlade({
      route: "User",
      routeName: `${user.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: user.id.toString(),
        name: user.name,
        tabIndex: 0
      }
    });
  };
  public renderUsersTable = () => {
    const analyticsTableAction = (payload: userTableType) => {
      this.props.openBlade({
        route: "Analytics",
        routeName: `${payload.name} - Analytics`,
        fromBladeIndex: this.props.index,
        routeData: {
          type: "User",
          title: payload.name,
          id: payload.id,
          allTimeStartDate: `${payload.createdAt}`
        }
      });
    };
    return (
      <div
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
          display: "flex",
          flex: 1
        }}
      >
        <UsersTable
          onRowClick={this.navToUserBlade}
          setRefreshFn={this.props.setEntityRefreshFn(REFRESH_KEY.UsersTable)}
          refreshKey={REFRESH_KEY.UsersTable}
          removeRefreshFn={this.props.removeRefreshFn}
          hideColumnsWithHeaders={
            this.props.isVendor
              ? [
                  UsersTableColumns.Installs,
                  UsersTableColumns.Enable,
                  UsersTableColumns.LastActiveSession,
                  UsersTableColumns.Actions
                ]
              : [
                  UsersTableColumns.Installs,
                  UsersTableColumns.Enable,
                  UsersTableColumns.Company,
                  UsersTableColumns.LastActiveSession,
                  this.props.result?.allProductsCount <= 1
                    ? UsersTableColumns.Product
                    : null
                ]
          }
          bladeName={"Users"}
          renderRowActionButtons={payload => (
            <AnalyticsAction
              data={payload}
              analyticsAction={analyticsTableAction}
            />
          )}
        />
      </div>
    );
  };
  public renderLeftSideHeader = () => {
    return (
      <div style={{ marginLeft: "18px" }}>
        <BladeTitle>Users</BladeTitle>
      </div>
    );
  };
  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: "Users Analytics",
      routeData: {
        type: "Users",
        title: "Users"
      },
      fromBladeIndex: this.props.index
    });
  };
  public renderRightSideHeader = () => {
    return (
      <Flex style={{ marginRight: "18px" }}>
        <ExportButton
          style={{
            border: !this.props.isVendor
              ? `1px solid ${this.props.theme.colors.warmGrey}`
              : "none"
          }}
          tableType="users"
        />
        {this.props.isVendor ? (
          <ElmButton
            variance={"plain-icon-button"}
            colorVariance={"primary"}
            icon={"chart-mixed"}
            iconPrefix={"far"}
            label={"Analytics"}
            onClick={this.navigateToAnalytics}
          />
        ) : null}
      </Flex>
    );
  };
  public renderQueryRenderer = () => {
    return (
      <QueryRenderer<UsersQuery>
        environment={relayEnvironment}
        variables={{}}
        query={graphqlQuery}
        render={this.renderUsersTable}
      />
    );
  };
  public render() {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Users"}
        leftAccentColor={"userGreen"}
        topAccentColor={"userGreen"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        closeBlade={this.props.closeBlade}
        bladeType="Users"
        hideBladeTypeName={true}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
      >
        <div className="metrics-container">
          {this.props.result ? (
            <p className="subheading-value">
              {this.props.result.allUsersCount}
              <span className="subheading-label">Total Users</span>
            </p>
          ) : (
            <p className="subheading-value">
              <span className="subheading-label">Loading stats...</span>
            </p>
          )}
        </div>
        {this.renderUsersTable()}
      </BladeTemplate>
    );
  }
}
const relayEnvironment = getEnvironment();
const RenderQuery = (props: IUsersBladeProps) => {
  const theme = useTheme();
  const renderUsersInfo = (payload: {
    error: Error;
    props: UsersQueryResponse;
    retry: () => void;
  }) => {
    return (
      <UsersBlade
        {...props}
        result={payload.props}
        retry={payload.retry}
        theme={theme}
      />
    );
  };
  return (
    <QueryRenderer<UsersQuery>
      environment={relayEnvironment}
      variables={{}}
      query={graphqlQuery}
      render={renderUsersInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
