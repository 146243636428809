import * as _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { getGateway } from "../../api";
import { appConnect, appDispatch } from "../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenUserBlade
} from "../../components/bladeManager/types";
import {
  BladeFeatureImage,
  BladeSubTitle,
  BladeTemplate,
  BladeTitle
} from "../../components";
import { getIcon } from "../../components/icons";
import { CustomerTerms, VendorTerms } from "./terms";
import { getSavedRoleTypeFromLocalSync } from "utils";

export interface ITOSProps extends IBladeBaseProps<IOpenUserBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}

const TermsContainer = styled.div`
  overflow-y: scroll;
  font-family: Inter;

  p.MsoNormal,
  li.MsoNormal,
  div.MsoNormal {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0in;
    line-height: 107%;
    font-size: 11pt;
    color: ${props => props.theme.colors.textPrimary};
  }
  h1 {
    mso-style-link: "Heading 1 Char";
    margin-right: 0in;
    margin-left: 0in;
    font-size: 18pt;
    font-weight: bold;
    color: ${props => props.theme.colors.textPrimary};
  }
  h2 {
    mso-style-link: "Heading 2 Char";
    margin-right: 0in;
    margin-left: 0in;
    font-size: 12pt;
    font-weight: bold;
    color: ${props => props.theme.colors.textPrimary};
  }
  h3 {
    mso-style-link: "Heading 3 Char";
    margin-right: 0in;
    margin-left: 0in;
    font-size: 13.5pt;
    font-weight: bold;
    color: ${props => props.theme.colors.textPrimary};
  }
  h4 {
    mso-style-link: "Heading 4 Char";
    margin-right: 0in;
    margin-left: 0in;
    font-size: 12pt;
    font-weight: bold;
    color: ${props => props.theme.colors.textPrimary};
  }
  a:link,
  span.MsoHyperlink {
    color: blue;
    text-decoration: underline;
  }
  a:visited,
  span.MsoHyperlinkFollowed {
    color: #954f72;
    text-decoration: underline;
  }
  p {
    margin-right: 0in;
    margin-left: 0in;
    font-size: 12pt;
  }
  p.MsoAcetate,
  li.MsoAcetate,
  div.MsoAcetate {
    mso-style-link: "Balloon Text Char";
    margin: 0in;
    margin-bottom: 0.0001pt;
    font-size: 9pt;
  }
  span.Heading2Char {
    mso-style-name: "Heading 2 Char";
    mso-style-link: "Heading 2";
    font-weight: bold;
  }
  span.Heading3Char {
    mso-style-name: "Heading 3 Char";
    mso-style-link: "Heading 3";
    font-weight: bold;
  }
  span.Heading4Char {
    mso-style-name: "Heading 4 Char";
    mso-style-link: "Heading 4";
    font-weight: bold;
  }
  span.apple-converted-space {
    mso-style-name: apple-converted-space;
  }
  span.BalloonTextChar {
    mso-style-name: "Balloon Text Char";
    mso-style-link: "Balloon Text";
  }
  span.Heading1Char {
    mso-style-name: "Heading 1 Char";
    mso-style-link: "Heading 1";
    font-weight: bold;
  }
  .MsoPapDefault {
    margin-bottom: 8pt;
    line-height: 107%;
  }
  @page WordSection1 {
    size: 8.5in 11in;
    margin: 1in 1in 1in 1in;
  }
  div.WordSection1 {
    page: WordSection1;
  }
`;
const IconContainer = styled.div`
  display: flex;
  flex: 1;

  svg {
    height: 100%;
    width: 100%;
  }
`;
const TermsIcon = getIcon("FaBalanceScale");
export class TOS extends React.Component<ITOSProps> {
  state = {
    termsHtml: ""
  };
  public isVendor = () =>
    getSavedRoleTypeFromLocalSync() === "vendor" ||
    _.get(this.props, "appState.activeRole.type") === "vendor";

  public componentWillMount() {
    this.fetchTerms();
  }
  public renderLeftSideHeader = () => {
    return (
      <Flex>
        <div className="tos-blade-image">
          <IconContainer>
            <TermsIcon />
          </IconContainer>
        </div>

        <Flex flexDirection="column">
          <BladeTitle>Terms of Service</BladeTitle>
          <BladeSubTitle>elm by evoleap llc.</BladeSubTitle>
        </Flex>
      </Flex>
    );
  };
  public fetchTerms = async () => {
    const gateway = getGateway();
    const terms = await gateway.prepareGenericRequest(
      this.isVendor()
        ? "https://evoleap.com/elm%20-%20Vendor%20Terms%20of%20Service.htm"
        : "https://evoleap.com/elm%20-%20Customer%20Terms%20of%20Service.htm",
      "get"
    )();
    this.setState({
      termsHtml: _.get(terms, "data")
    });
  };
  // public renderTerms = () => {

  // }
  public render() {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title="Terms of Service"
        renderLeftSideHeader={this.renderLeftSideHeader}
        closeBlade={this.props.closeBlade}
        bladeType="Tos"
        bladeTypeName="Agreement"
      >
        <TermsContainer className="tos-blade-container">
          {this.isVendor() ? <VendorTerms /> : <CustomerTerms />}
        </TermsContainer>
      </BladeTemplate>
    );
  }
}

export default appConnect(TOS, { selectors: {} });
