import moment from "moment";
import React from "react";
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import styled, { useTheme } from "styled-components";
import { Flex } from "reflexbox/styled-components";
import { getEnvironment } from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import { IBladeBaseProps } from "../../../components/bladeManager/types";
import { useSelector } from "react-redux";
import { selectors } from "../../../store/ducks/app";
import {
  BladeTitle,
  BladeTemplate,
  ElmButton,
  getIcon
} from "../../../components";
import { Cell } from "react-table";
import {
  LicensesQuery,
  LicensesQueryResponse
} from "./__generated__/LicensesQuery.graphql";
import LicensesTable, {
  LicensesTableColumns,
  LicenseTableType
} from "../../components/licensesTable";
import { Tooltip } from "antd";
import { getGateway } from "api";
import CopyToClipboard from "react-copy-to-clipboard";
import BladePrimaryActionsDropdown from "components/bladePrimaryActionsDropdown";
import { WarningSignIcon } from "assets";
import { LicenseActionStatus } from "Licenses/common";
import { renderSuccessNotification } from "utils/ant-notifications";
import ExportButton from "components/elmTable/exportButton";
import { DATE_FORMAT_ALT } from "const";
import { REFRESH_KEY } from "components/bladeManager/entityRefreshMap";
export interface ILicensesBladeProps extends IBladeBaseProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}

const graphqlQuery = graphql`
  query LicensesQuery(
    $activeLicenseFilterValues: [[String!]!]
    $expiredFilterValues: [[String!]!]
    $expiringSoonFilterValues: [[String!]!]
    $skipTotalSeats: Boolean!
  ) {
    allLicensesCount: licensesCount
    activeLicensesCount: licensesCount(
      filterColumn: ["latestTerm.endDate", "latestTerm.startDate"]
      filterValues: $activeLicenseFilterValues
      filterOp: [[">"], ["<", "="]]
    )
    expiredLicensesCount: licensesCount(
      filterColumn: ["latestTerm.endDate"]
      filterOp: [["<"]]
      filterValues: $expiredFilterValues
    )
    expiringSoonLicensesCount: licensesCount(
      filterColumn: ["latestTerm.endDate", "latestTerm.endDate"]
      filterOp: [[">"], ["<"]]
      filterValues: $expiringSoonFilterValues
    )
    allLicensesSeats: licenses @skip(if: $skipTotalSeats) {
      nodes {
        totalSeatCount
      }
    }
  }
`;
export interface ILicenseActionComponent {
  data: any;
  onConfirm: () => void;
  openBladeAction?: (license: LicenseTableType) => void;
  customerAction?: {
    isCompany: boolean;
    analyticsTableAction: (license: LicenseTableType) => void;
  };
}
export const LicenseAction = ({
  data: payload,
  onConfirm,
  openBladeAction,
  customerAction
}: ILicenseActionComponent) => {
  const theme = useTheme();

  const permissions = useSelector(selectors.activeUserPermissions);
  if (customerAction?.isCompany) {
    return (
      <div onClick={e => e.stopPropagation()} style={{ padding: "2px" }}>
        <ElmButton
          variance={"icon-button"}
          colorVariance={"subtle"}
          className="darkModeTransparentBtn"
          icon={"chart-mixed"}
          iconPrefix={"far"}
          label={""}
          style={{ color: theme.colors.black }}
          onClick={() => customerAction?.analyticsTableAction(payload)}
        />
      </div>
    );
  }
  const canModifyLicense = _.find(
    permissions,
    item => item === "modify_licenses"
  );
  const licenseActionNodes = _.get(payload, "licenseActions.nodes", []);
  const licenseActionId = _.filter(
    licenseActionNodes,
    data => data.action === "create_license"
  )[0]?.id;

  if (!payload?.approved && canModifyLicense) {
    const ApproveIcon = styled(getIcon("circle-check", "fas"))`
      width: 16px;
      height: 16px;
      color: #4dbf40;
    `;
    const RejectIcon = styled(getIcon("circle-xmark", "fal"))`
      width: 16px;
      height: 16px;
      color: #707070;
      margin-right: 8px;
    `;

    const doApproval = (isApproved: boolean) => {
      const gateway = getGateway();
      gateway.request[isApproved ? "approveLicense" : "rejectLicense"]({
        license_id: _.get(payload, "id"),
        license_action_id: licenseActionId
      })
        .then(res => {
          if (!res.error) {
            onConfirm();
          }
        })
        .catch(e => {
          console.error(e);
        });
    };

    return (
      <Flex>
        <Tooltip placement="top" title={"Reject license"}>
          <RejectIcon
            onClick={(e: Event) => {
              e.preventDefault();
              e.stopPropagation();
              doApproval(false);
            }}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Approve license"}>
          <ApproveIcon
            onClick={(e: Event) => {
              e.preventDefault();
              e.stopPropagation();
              doApproval(true);
            }}
          />
        </Tooltip>
      </Flex>
    );
  }

  let havePendingActions = false;
  const pendingLicenseActionTypes = ["deactivate_license"];
  _.filter(licenseActionNodes, data => {
    if (
      canModifyLicense &&
      pendingLicenseActionTypes.includes(data?.action) &&
      data?.status === LicenseActionStatus.Pending
    ) {
      havePendingActions = true;
    }
  });

  const onMessageCopy = (e: any) => {
    renderSuccessNotification("License copied to clipboard");
  };

  return (
    <Flex
      padding="2px"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <CopyToClipboard text={payload?.key || ""} onCopy={onMessageCopy}>
        <ElmButton
          style={{
            color: theme.colors.black,
            marginLeft: 0
          }}
          className="darkModeTransparentBtn"
          variance="icon-button"
          colorVariance="subtle"
          label=""
          icon="copy"
          iconPrefix="fal"
        />
      </CopyToClipboard>
      {havePendingActions && (
        <Tooltip placement="top" title={"Require approval"}>
          <img
            onClick={() => openBladeAction(payload)}
            style={{ marginLeft: "4px" }}
            src={WarningSignIcon}
            alt="warning"
          />
        </Tooltip>
      )}
    </Flex>
  );
};

export class LicensesBlade extends React.Component<
  ILicensesBladeProps & {
    result: LicensesQueryResponse;
    retry: () => void;
    theme: any;
  }
> {
  public navToLicenseBlade = (license: LicenseTableType) => {
    this.props.openBlade({
      route: "License",
      routeName: "License",
      fromBladeIndex: this.props.index,
      routeData: {
        id: license.id,
        productId: license.product.id
      }
    });
  };

  public openLicenseBladeInChangelogTab = (license: LicenseTableType) => {
    this.props.openBlade({
      route: "License",
      routeName: "License",
      fromBladeIndex: this.props.index,
      routeData: {
        id: license.id,
        productId: license.product.id,
        openInChangelogTab: true
      }
    });
  };

  public renderLicensesTable = () => {
    const analyticsTableAction = (payload: LicenseTableType) => {
      this.props.openBlade({
        route: "Analytics",
        routeName: "License Analytics",
        routeData: {
          type: "License",
          title: `${payload?.owner?.name} / ${payload?.product?.name}`,
          id: payload?.id,
          allTimeStartDate: `${payload?.createdAt}`
        },
        fromBladeIndex: this.props.index
      });
    };
    return (
      <div
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
          display: "flex",
          flex: 1
        }}
      >
        <LicensesTable
          bladeName="Licenses"
          setRefreshFn={this.props.setEntityRefreshFn(
            REFRESH_KEY.LicensesTable
          )}
          refreshKey={REFRESH_KEY.LicensesTable}
          removeRefreshFn={this.props.removeRefreshFn}
          onRowClick={this.navToLicenseBlade}
          renderRowActionButtons={data => (
            <LicenseAction
              data={data}
              onConfirm={this.props.refreshAllOpenBlades}
              openBladeAction={this.openLicenseBladeInChangelogTab}
              customerAction={{
                isCompany: !this.props.isVendor,
                analyticsTableAction
              }}
            />
          )}
          columnAndHeaderStyles={{
            justifyFirstHeaderColumnContent: "flex-start",
            justifyColumnContent: "flex-start",
            justifyLastHeaderColumnContent: "center"
          }}
          hideColumnsWithHeaders={
            this.props.isVendor
              ? [LicensesTableColumns.LicenseKey, LicensesTableColumns.Usage]
              : [
                  LicensesTableColumns.Company,
                  LicensesTableColumns.CommercialModel
                ]
          }
        />
      </div>
    );
  };
  public getPath = (cell: Cell, path: string) => {
    return _.get(cell.row.original, path);
  };

  public renderLeftSideHeader = () => {
    return (
      <div style={{ marginLeft: "18px" }}>
        <BladeTitle>Licenses</BladeTitle>
      </div>
    );
  };
  public openNewLicenseDialog = () => {
    this.props.openBlade({
      route: "AddLicense",
      routeData: {
        id: ""
      },
      routeName: "New License",
      fromBladeIndex: this.props.index
    });
  };
  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: "Licenses Analytics",
      routeData: {
        type: "Licenses",
        title: "Licenses"
      },
      fromBladeIndex: this.props.index
    });
  };
  public renderRightSideHeader = () => {
    return (
      <Flex>
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon={"plus"}
          iconPrefix={"fas"}
          label={"License"}
          permissions={["modify_assets", "request_modify_licenses"]}
          onClick={this.openNewLicenseDialog}
        />
        {this.props.isVendor ? (
          <BladePrimaryActionsDropdown
            analyticsAction={this.navigateToAnalytics}
            export={"licensesTable"}
          />
        ) : (
          <ExportButton
            tableType="licensesTable"
            colorVariance={"outline-secondary"}
            style={{ marginRight: 18 }}
          />
        )}
      </Flex>
    );
  };

  public render() {
    const InfoIcon = styled(getIcon("AiFillInfoCircle"))`
      width: 14px;
      height: 14px;
      margin-left: 5px;
      color: ${props => props.theme.colors.buttonIcon};
    `;
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        bladeType="Licenses"
        closeBlade={this.props.closeBlade}
        title={"Licenses"}
        leftAccentColor={"mango"}
        topAccentColor={"mango"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        hideBladeTypeName={true}
      >
        <div
          className={`metrics-container ${
            !this.props.result ? "loading" : null
          }`}
        >
          {this.props.result ? (
            <>
              <p className="subheading-value">
                {this.props.result?.activeLicensesCount || 0}
                <span className="subheading-label">Active Licenses</span>
              </p>
              {this.props.result?.expiringSoonLicensesCount ? (
                <p className="subheading-value">
                  <span className="bordered" />
                  {this.props.result.expiringSoonLicensesCount}
                  <span
                    className="subheading-label"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Expiring Soon
                  </span>
                  <Tooltip
                    placement="bottom"
                    title={"Licenses expiring within 90 days"}
                  >
                    <InfoIcon />
                  </Tooltip>
                </p>
              ) : null}
              {this.props.isVendor ? (
                <p className="subheading-value">
                  <span className="bordered" />
                  {this.props.result.expiredLicensesCount || 0}
                  <span className="subheading-label"> Expired</span>
                </p>
              ) : (
                <p className="subheading-value">
                  <span className="bordered" />
                  {this.props.result?.allLicensesSeats?.nodes?.reduce(
                    (total, node) => total + node.totalSeatCount,
                    0
                  )}
                  <span className="subheading-label">Total seats</span>
                </p>
              )}
            </>
          ) : (
            <p className="subheading-value">
              <span className="subheading-label">Loading stats...</span>
            </p>
          )}
        </div>
        {this.renderLicensesTable()}
      </BladeTemplate>
    );
  }
}
const relayEnvironment = getEnvironment();
const RenderQuery = (props: ILicensesBladeProps) => {
  const theme = useTheme();
  const renderLicensesInfo = (payload: {
    error: Error;
    props: LicensesQueryResponse;
    retry: () => void;
  }) => {
    return (
      <LicensesBlade
        {...props}
        result={payload.props}
        retry={payload.retry}
        theme={theme}
      />
    );
  };
  return (
    <QueryRenderer<LicensesQuery>
      environment={relayEnvironment}
      cacheConfig={{
        force: true
      }}
      variables={{
        skipTotalSeats: props?.isVendor,
        activeLicenseFilterValues: [
          [moment().format(DATE_FORMAT_ALT)],
          [moment().format(DATE_FORMAT_ALT), moment().format(DATE_FORMAT_ALT)]
        ],
        expiredFilterValues: [[moment().format(DATE_FORMAT_ALT)]],
        expiringSoonFilterValues: [
          [moment().format(DATE_FORMAT_ALT)],
          [
            moment()
              .add(90, "d")
              .format(DATE_FORMAT_ALT)
          ]
        ]
      }}
      query={graphqlQuery}
      render={renderLicensesInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {
    activeRole: "activeRoleSelector"
  }
});
