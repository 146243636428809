import { graphql } from "babel-plugin-relay/macro";

export const companiesTable_companies = graphql`
  fragment fragments_companiesTable on Query
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 25 }
      cursor: { type: "String" }
      blade_scope: { type: "String", defaultValue: null }
      filterColumn: {
        type: "[String!]"
        defaultValue: ["companies.active_license_count"]
      }
      filterOp: { type: "[[String!]!]", defaultValue: [[">"]] }
      filterValues: { type: "[[String!]!]", defaultValue: [["0"]] }
      search: { type: "String", defaultValue: null }
      sortColumn: { type: "[String!]", defaultValue: ["companies.name"] }
      sortDirection: { type: "[String!]", defaultValue: ["asc"] }
      skipProduct: { type: "Boolean!", defaultValue: false }
    ) {
    companiesCount(
      search: $search
      bladeScope: $blade_scope
      filterColumn: $filterColumn
      filterOp: $filterOp
      filterValues: $filterValues
    )
    companiesTableCompanies: companies(
      first: $count
      after: $cursor
      bladeScope: $blade_scope
      filterColumn: $filterColumn
      filterOp: $filterOp
      filterValues: $filterValues
      search: $search
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) @connection(key: "companiesTable_companiesTableCompanies") {
      edges {
        node {
          id
          name
          activeUserCount
          licensesCount
          activeLicenseCount
          products @skip(if: $skipProduct) {
            product: nodes {
              name
              id
              iconUrl
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const adminsTable_companies = graphql`
  fragment fragments_adminsTable on Query
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 20 }
      blade_scope: { type: "String", defaultValue: null }
      id: { type: "ID!" }
      search: { type: "String", defaultValue: null }
      sortColumn: { type: "[String!]", defaultValue: ["system_users.email"] }
      sortDirection: { type: "[String!]", defaultValue: ["ASC"] }
    ) {
    company(id: $id) {
      companySystemUsers(
        first: $count
        bladeScope: $blade_scope
        search: $search
        sortColumn: $sortColumn
        sortDirection: $sortDirection
      ) @connection(key: "adminsTable_companySystemUsers") {
        edges {
          node {
            name
            email
            lastLogin
            avatarUrl
            thumbUrl
            id
          }
        }
      }
      companySystemUsersCount
    }
  }
`;
