import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox";
import relayEnvironment from "../../api/relay";
import { appConnect } from "../../store/appConnect";
import { WidgetContainer } from "../../components/analyticsComponent/widgetContainer";
import {
  companyAnalyticsQuery,
  companyAnalyticsQueryResponse
} from "./__generated__/companyAnalyticsQuery.graphql";
import _ from "lodash";
import { getAnimatedComponent } from "../../components/helpers";
import { getFriendlyLicenseName } from "../../utils";
import {
  TopCountFilter,
  topCounts
} from "../../components/analyticsComponent/filters/topCount";
import { TabbedAnalyticsComponent } from "../../components/analyticsComponent/tabbedAnalytics";
import {
  AnalyticsComponentProps,
  queryType
} from "../../components/analyticsComponent";
import { IOpenBladeFromChart } from "components/bladeManager/types";
import { DateRange } from "blades/Dashboard/utils";

const AnimatedContainer = getAnimatedComponent({
  element: "div",
  transitionName: "license-banner",
  animations: {
    appear: {
      duration: "1.5s",
      animationType: "slideInRight"
    },
    enter: {
      duration: "1s",
      animationType: "slideInRight"
    }
  }
});

const graphqlQuery = graphql`
  query companyAnalyticsQuery(
    $currentTimeSpanStart: String!
    $currentTimeSpanEnd: String!
    $productId: String!
  ) {
    LicenseAnalyticsCurrentExpiringLicenses: licenses(
      filterColumn: ["latestTerm.endDate", "latestTerm.endDate"]
      filterOp: [[">", "="], ["<", "="]]
      filterValues: [
        [$currentTimeSpanStart, $currentTimeSpanStart]
        [$currentTimeSpanEnd, $currentTimeSpanEnd]
      ]
      bladeScope: $productId
    ) {
      nodes {
        type {
          name
          id
        }
      }
    }
  }
`;

export interface ICompanyAnalyticsProps {
  result: companyAnalyticsQueryResponse;
  retry: () => void;
  openBlade?: (payload: IOpenBladeFromChart) => void;
  timespan: "week" | "month" | "day";
  companyId: string;
  variables: {
    allTimeFilterStart?: string;
    timeRangeOverride: DateRange;
    currentTimeSpanStart: string;
    currentTimeSpanEnd: string;
    previousTimeSpanStart: string;
    previousTimeSpanEnd: string;
    productId: string | null;
  };
}

type state = {
  topUsersCount: number;
  topComponentsCount: number;
};
export class CompanyAnalytics extends React.Component<ICompanyAnalyticsProps> {
  state: state = {
    topComponentsCount: 5,
    topUsersCount: 5
  };
  public renderLicenseInfoBanner = () => {
    if (
      !this.props.result ||
      !this.props.result.LicenseAnalyticsCurrentExpiringLicenses
    ) {
      return null;
    }
    const getActiveLicensesByType = () => {
      if (
        !this.props.result ||
        !this.props.result.LicenseAnalyticsCurrentExpiringLicenses
      ) {
        return null;
      }
      const types = _.groupBy(
        this.props.result.LicenseAnalyticsCurrentExpiringLicenses.nodes,
        node => node.type.name
      );
      const typeNames = _.keys(types);
      return _.map(typeNames, typeName => {
        return (
          <div>
            <span className="expiring-value">{types[typeName].length}</span>
            <span className="expiring-label">
              {_.startCase(getFriendlyLicenseName(typeName))}
            </span>
          </div>
        );
      });
    };
    const navigateToLicenses = () => {
      // this.props.openBlade({
      //   route: 'Licenses',
      //   routeName: 'Licenses',
      //   routeData: null,
      //   fromBladeIndex: this.props.index,
      //   navigationType: 'reset',
      // });
    };
    return (
      <AnimatedContainer>
        <div className="banner" onClick={navigateToLicenses}>
          <div className="accent" />
          <div className="message-container">
            <span className="current-expiring-value">
              {this.props.result.LicenseAnalyticsCurrentExpiringLicenses}
            </span>
            <span className="current-expiring-label">{`License${
              this.props.result.LicenseAnalyticsCurrentExpiringLicenses.nodes
                .length > 1
                ? "s"
                : ""
            } expiring this ${this.props.timespan}`}</span>
            <div className="extra-expiring-container">
              {getActiveLicensesByType()}
            </div>
          </div>
        </div>
      </AnimatedContainer>
    );
  };
  public getHighlightAnalytics = () => {
    return (
      <TabbedAnalyticsComponent
        //style={{ height: "417px" }}
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session count",
              style: {
                marginRight: "0px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "12px",
                paddingRight: "12px",
                height: "40px",
                display: "flex",
                alignItems: "center"
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              analytics: this.getSessionCountAnalyticsSetup(),
              openBlade: this.props.openBlade,
              type: "line",
              allowVendorAnalytics: true
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: {
                marginRight: "0px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "12px",
                paddingRight: "12px",
                height: "40px",
                display: "flex",
                alignItems: "center"
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              analytics: this.getSessionDurationAnalyticsSetup(),
              openBlade: this.props.openBlade,
              type: "bar",
              allowVendorAnalytics: true
            }
          }
        ]}
      />
    );
  };
  public getSessionDurationAnalyticsSetup = () => {
    return {
      bladeScope: this.props.companyId,
      valueTitle: "Session Duration",
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_duration",
        group: null
      },
      first: 5,
      options: {
        chartMaxHeight: 400
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd
    } as queryType;
  };
  public getSessionCountAnalyticsSetup = () => {
    return {
      bladeScope: this.props.companyId,
      valueTitle: "Session Count",
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_count",
        group: null
      },
      options: {
        chartMaxHeight: 400
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd
    } as queryType;
  };
  public getTabAnalytics = (payload: {
    category: string;
    valueTable?: string;
    firstTabType?: AnalyticsComponentProps["type"];
    secondTabType?: AnalyticsComponentProps["type"];
    first: number;
  }) => {
    return (
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session count",
              style: {
                marginRight: "0px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "12px",
                paddingRight: "12px",
                height: "40px",
                display: "flex",
                alignItems: "center"
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: {
                bladeScope: this.props.companyId,
                valueTitle: "Session Count",
                setup: {
                  category: payload.category,
                  value: `${payload.valueTable || "sessions"}.usage_count`,
                  group: null
                },
                first: payload.first,
                sortColumn: "value",
                sortDirection: "DESC",
                startTime: this.props.variables.currentTimeSpanStart,
                endTime: this.props.variables.currentTimeSpanEnd
              },
              type: payload.firstTabType,
              allowVendorAnalytics: true
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: {
                marginRight: "0px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "12px",
                paddingRight: "12px",
                height: "40px",
                display: "flex",
                alignItems: "center"
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: {
                bladeScope: this.props.companyId,
                valueTitle: "Session Duration",
                setup: {
                  category: payload.category,
                  value: `${payload.valueTable || "sessions"}.usage_duration`,
                  group: null
                },
                first: payload.first,
                sortColumn: "value",
                sortDirection: "DESC",
                startTime: this.props.variables.currentTimeSpanStart,
                endTime: this.props.variables.currentTimeSpanEnd
              },
              type: payload.secondTabType,
              allowVendorAnalytics: true
            }
          }
        ]}
      />
    );
  };
  public updateTopCount = (type: keyof state) => (count: topCounts) => {
    this.setState({ [type]: parseInt(count, 10) });
  };
  public render() {
    return (
      <div className="license-analytics analytics-container">
        {this.renderLicenseInfoBanner()}
        <WidgetContainer size="x-large">
          {this.getHighlightAnalytics()}
        </WidgetContainer>
        <WidgetContainer size="medium-large">
          <Flex>
            <span className="widget-title">Top users</span>
            <TopCountFilter onChange={this.updateTopCount("topUsersCount")} />
          </Flex>
          {this.getTabAnalytics({
            category: "users.name",
            firstTabType: "bar",
            secondTabType: "bar",
            first: this.state.topUsersCount
          })}
        </WidgetContainer>
        <WidgetContainer size="medium-large">
          <Flex>
            <span className="widget-title">Top Components</span>
            <TopCountFilter
              onChange={this.updateTopCount("topComponentsCount")}
            />
          </Flex>
          {this.getTabAnalytics({
            category: "components.name",
            firstTabType: "bar",
            secondTabType: "bar",
            valueTable: "components",
            first: this.state.topComponentsCount
          })}
        </WidgetContainer>
      </div>
    );
  }
}

const RenderQuery = (props: ICompanyAnalyticsProps) => {
  // const [variables, updateVariables] = React.useState({
  //   ...getQueryTimeVariables("week"),

  //   sessionSetup: {
  //     category: "time.day",
  //     group: "user_id",
  //     value: "sessions.usage_count"
  //   },
  //   userSetup: {
  //     category: "license_id",
  //     group: "licenses.type",
  //     value: "counts.active_user"
  //   }
  // });
  const renderBlade = (payload: {
    error: Error;
    props: companyAnalyticsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <CompanyAnalytics
        {...props}
        variables={props.variables}
        result={payload.props}
        retry={payload.retry}
        // updateVariables={updateVariables}
      />
    );
  };
  return (
    <QueryRenderer<companyAnalyticsQuery>
      environment={relayEnvironment}
      variables={props.variables}
      query={graphqlQuery}
      render={renderBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
