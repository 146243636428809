import React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../../components/elmButton";
import { DialogBase } from "../../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../../components/bladeManager/appDialog/types";
import { DialogTitle } from "../../../../../components/bladeManager/appDialog/helpers";
import ElmTable from "components/elmTable";
import { userTableType } from "Users/components/usersTable";
import { ElmToggle } from "components";
import { getGateway } from "api";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { SearchBar } from "components/helpers";
import { searchInObjectArray } from "utils";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 440px;
  width: 360px;
`;

interface ISelectEnableProductDialogProps {
  userId: string;
  productUsers: userTableType["productUsers"]["nodes"];
}
interface state {
  search: string;
  enabledProducts: string[];
}
export class SelectEnableProductDialog extends DialogBase<
  IModalProps<ISelectEnableProductDialogProps>,
  state
> {
  state: state = {
    search: "",
    enabledProducts:
      [
        ...this.props.payload?.productUsers
          ?.filter(product => product.enabled)
          ?.map(product => product.product.id)
      ] || []
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 999,
          background: "rgb(255, 255, 255, 0.7)"
        },
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "SelectEnableProductDialog"
    });
  };

  public render = () => {
    const toggleProductEnable = (activate: boolean, product_id: string) => {
      const gateway = getGateway();
      const id = this.props.payload.userId;
      if (activate) {
        gateway.request
          .enableProduct({ product_id }, { id })
          .then(res => {
            if (!res.error) {
              this.setState({
                enabledProducts: [...this.state.enabledProducts, product_id]
              });
              renderSuccessNotification("Enabled product");
            }
          })
          .catch(err => {
            renderErrorNotification("Error on product activation");
            console.error(err);
          });
      }
      if (!activate) {
        gateway.request
          .disableProduct({ product_id }, { id })
          .then(res => {
            if (!res.error) {
              this.setState({
                enabledProducts: [...this.state.enabledProducts].filter(
                  prod => prod !== product_id
                )
              });
              renderSuccessNotification("Deactivated product");
            }
          })
          .catch(err => {
            renderErrorNotification("Error on product deactivation");
            console.error(err);
          });
      }
    };

    const data = this.props.payload?.productUsers;

    const filteredData = this.state.search
      ? searchInObjectArray(this.state.search, data)
      : data;

    return (
      <DialogContainer>
        <DialogTitle>Products</DialogTitle>

        <Flex
          flexDirection="column"
          flex={1}
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            height: "190px"
          }}
        >
          <SearchBar onSearch={search => this.setState({ search })} />
          <ElmTable
            columns={[
              {
                Header: "Product",
                accessor: "product.name",
                sortKey: null,
                disableSortBy: true
              },
              {
                Header: "Enable",
                accessor: "",
                sortKey: null,
                disableSortBy: true,
                cellRenderer: row => (
                  <ElmToggle
                    checked={this.state.enabledProducts.includes(
                      row.rowData?.product?.id
                    )}
                    size="small"
                    permissions={"modify_assets"}
                    onChange={e =>
                      toggleProductEnable(e, row.rowData?.product?.id)
                    }
                  />
                ),
                width: 0.2
              }
            ]}
            data={filteredData}
            hideFooter
            hideSearchBar
          />
        </Flex>
        <Flex alignItems="flex-end" justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance="outline-secondary"
            onClick={this.closeModal}
          />
          <ElmButton
            label={"Done"}
            colorVariance="primary"
            onClick={this.closeModal}
          />
        </Flex>
      </DialogContainer>
    );
  };
}
