import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { IChart, IChartQuery } from "./types";

export const useChartData = (props: IChartQuery) => {
  const query = graphql`
    query hooksAnalyticsQuery(
      $bladeScope: String
      $setup: JSON!
      $startTime: String!
      $endTime: String
      $first: Int
      $sortColumn: String
      $sortDirection: String
      $filterColumn: [String!]
      $filterValues: [[String!]!]
      $skip: Boolean!
    ) {
      analytics(
        bladeScope: $bladeScope
        setup: $setup
        startTime: $startTime
        endTime: $endTime
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        first: $first
        filterColumn: $filterColumn
        filterValues: $filterValues
      ) @skip(if: $skip) {
        categories
        groups
        values
        categoryMetadata {
          id
          bladeType
        }
        groupMetadata {
          id
          bladeType
        }
      }
    }
  `;

  const data = useLazyLoadQuery(
    query,
    {
      bladeScope: props.bladeScope,
      setup: props.setup,
      startTime: props.startTime,
      endTime: props.endTime,
      sortColumn: props.sortColumn,
      sortDirection: props.sortDirection,
      first: props.first,
      filterColumn: props.filterColumn,
      filterValues: props.filterValues,
      skip: !!props.useLocalData
    }
    // { fetchPolicy: "store-and-network" }
  );

  return {
    data
    // refetch,
    // refetchTranslation,
    // isRefetching
  };
};
