import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { ElmInput } from "../../../../components/elmInput";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../../components/elmSelect";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { getGateway } from "../../../../api";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  //height: 284px;
  width: 330px;
`;

const componentTypes = [
  {
    id: 1,
    label: "Checked-out Token"
  },
  {
    id: 2,
    label: "Session"
  },
  {
    id: 3,
    label: "Consumable Token"
  }
];
const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const DialogSubTitle = styled.p`
  ${props => props.theme.fontType.visualizationSubTitle}
`;
export interface INewComponentGroupDialogProps {
  productId: string;
  productName: string;
}
export interface INewComponentGroupDialogState {
  name: string;
  component_type: number;
  error: boolean;
  disabled: boolean;
}
export class NewComponentGroupDialog extends DialogBase<
  IModalProps<INewComponentGroupDialogProps>,
  INewComponentGroupDialogState
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          //flexDirection: "column",
          minHeight: "284px",
          width: "330px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white",
          border: "none"
        }
      }
    });
  }
  public state: INewComponentGroupDialogState = {
    name: "",
    component_type: null,
    error: false,
    disabled: false
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "NewComponentGroupDialog"
    });
  };
  public createNewComponentGroup = () => {
    this.setState({ disabled: true, error: false }, () => {
      const newComponentGroup = {
        ..._.pick(this.state, ["name", "component_type"]),
        product_id: this.props.payload.productId
      };
      const gateway = getGateway();
      gateway.request
        .newComponentGroup(newComponentGroup)
        .then(res => {
          if (_.get(res, "error")) {
            this.setState({ error: true, disabled: true });
          } else {
            if (_.isFunction(this.props.payload.onConfirm)) {
              this.props.payload.onConfirm(this.state);
            }
            this.closeModal();
          }
        })
        .catch(err => {
          console.error(err);
        });
    });
  };
  public getTitle = () => {
    return "Add a new component Group";
  };
  public getDescription = () => {
    return `You are adding a new component group to ${_.get(
      this.props.payload,
      "productName"
    )}.`;
  };
  public updateType = (componentType: any) => {
    const comp = parseInt(componentType, 10);
    if (_.isFinite(comp)) {
      this.setState({ component_type: comp });
    }
  };
  public updateName = (name: any) => {
    this.setState({ name });
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <Flex flexDirection={"column"} marginTop={16} style={{ gap: 16 }}>
          <ElmInput
            error={this.state.error}
            updatedValue={this.updateName}
            placeholder={"Type name here"}
            label={this.state.error ? "Request Failed" : "Component Group Name"}
          />
          <ElmSelectWithLabel
            label="License Model"
            placeholder="Select a license model"
            style={{ minWidth: "160px" }}
            onChange={this.updateType}
          >
            {_.map(componentTypes, type => {
              return (
                <ElmSelectOption
                  key={type.id}
                  value={type.id}
                  label={type.label}
                >
                  {type.label}
                </ElmSelectOption>
              );
            })}
          </ElmSelectWithLabel>
        </Flex>
        <Flex flex={1} alignItems="flex-end" justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            style={{ maxWidth: "150px" }}
            label={"Confirm"}
            onClick={this.createNewComponentGroup}
            disabled={this.state.disabled}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
