/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseEntitlementsListTable_componentEntitlements = {
  readonly license: {
    readonly componentEntitlements: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly componentGroup: {
            readonly name: string | null;
          } | null;
          readonly latestTerm: {
            readonly endDate: unknown | null;
            readonly startDate: unknown | null;
          } | null;
          readonly tokenCount: number | null;
          readonly currencyCount: number | null;
          readonly componentsCount: number;
        } | null;
      } | null> | null;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    } | null;
  } | null;
  readonly " $refType": "licenseEntitlementsListTable_componentEntitlements";
};
export type licenseEntitlementsListTable_componentEntitlements$data = licenseEntitlementsListTable_componentEntitlements;
export type licenseEntitlementsListTable_componentEntitlements$key = {
  readonly " $data"?:
    | licenseEntitlementsListTable_componentEntitlements$data
    | undefined;
  readonly " $fragmentRefs": FragmentRefs<
    "licenseEntitlementsListTable_componentEntitlements"
  >;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: 6,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: "cursor",
        direction: "forward",
        path: ["license", "componentEntitlements"]
      }
    ]
  },
  name: "licenseEntitlementsListTable_componentEntitlements",
  selections: [
    {
      alias: null,
      args: [
        {
          kind: "Variable",
          name: "id",
          variableName: "id"
        }
      ],
      concreteType: "License",
      kind: "LinkedField",
      name: "license",
      plural: false,
      selections: [
        {
          alias: "componentEntitlements",
          args: null,
          concreteType: "ComponentEntitlementConnection",
          kind: "LinkedField",
          name:
            "__LicenseEntitlementsListTable_componentEntitlements_connection",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ComponentEntitlementEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ComponentEntitlement",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ComponentGroup",
                      kind: "LinkedField",
                      name: "componentGroup",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "name",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "Term",
                      kind: "LinkedField",
                      name: "latestTerm",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "endDate",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "startDate",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "tokenCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "currencyCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "componentsCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "929747351e134589e007c9e121ab1f3d";
export default node;
