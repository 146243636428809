import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import { getGateway } from "../../../../api";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import {
  ElmDefaultSelect,
  ElmSelectOption
} from "../../../../components/elmSelect";
import { ElmButton } from "../../../../components/elmButton";
import { ElmInput } from "../../../../components/elmInput";
import { ElmDatePickerWithLabel } from "../../../../components/elmDate";
import { ElmCheckBox } from "../../../../components/elmCheckBox";
import { addLicenseQueryResponse } from "./__generated__/addLicenseQuery.graphql";
import { licenseTypeTemplates } from "../../../../utils";
import { ILicenseModel } from "../../../../store/ducks/app";
import CompanySelector, {
  companyType
} from "../../../../Companies/components/companySelector";
import ProductSelector, {
  productType
} from "../../../../Products/components/productSelector";
import { LicenseEnforcementSelector } from "../../licenseEnforcementSelector";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  DialogContainer,
  FieldsContainer,
  MultiFieldRowContainer,
  DialogTitle
} from "../../../../components/bladeManager/appDialog/helpers";
import dayjs, { Dayjs } from "dayjs";

export interface IAddLicenseDialogProps {
  licenseId: string;
}
export interface IAddLicenseDialogState {
  role: string;
}

export type state = {
  newLicense: ILicenseModel;
  licenseTypeId: number;
  company: companyType;
  product: productType;
  startMoment: Dayjs;
  endMoment: Dayjs;
  loading: boolean;
};
export class AddLicenseDialog extends DialogBase<
  IModalProps<IAddLicenseDialogProps>,
  state
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        overlay: {
          zIndex: 9
        },
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          width: "330px",
          border: "none"
        }
      }
    });
  }
  state: state = {
    newLicense: null,
    licenseTypeId: null,
    company: null,
    product: null,
    startMoment: dayjs(),
    endMoment: null,
    loading: false
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "AddLicenseDialog"
    });
  };
  public editAdmin = () => {
    if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
      this.props.payload.onConfirm(this.state);
    }
  };
  public getTitle = () => {
    return "Add License";
  };
  public getDescription = () => {
    return `Change the role you would like your new administrator to have.`;
  };

  public getSelectOptions = () => {
    return [
      {
        label: "Administrator",
        value: "license_admin"
      },
      {
        label: "Owner",
        value: "owner"
      },
      {
        label: "Stakeholder",
        value: "stakeholder"
      }
    ];
  };
  public renderEnforcementSelector = (props: addLicenseQueryResponse) => {
    if (!props) {
      return null;
    }
    let types = null;
    const enforcementModel = _.find(
      licenseTypeTemplates,
      licenseType =>
        licenseType.product_type_id.toString() === props.license.product.type.id
    );
    if (_.isObject(enforcementModel)) {
      types = _.map(enforcementModel.license_types, licenseType => {
        return (
          <ElmSelectOption value={licenseType.id}>
            {licenseType.name}
          </ElmSelectOption>
        );
      });
    }

    // @ts-ignore
    return (
      <Flex style={{ flexDirection: "column" }}>
        <ElmDefaultSelect>{types}</ElmDefaultSelect>
        <span>Licensing Model</span>
      </Flex>
    );
  };
  public renderStartAndEndDate = () => {
    const handleChange = (type: "startMoment" | "endMoment") => (p: Dayjs) => {
      const newState = this.state;
      newState[type] = p;
      this.setState(newState, () => {
        if (this.state.startMoment > this.state.endMoment) {
          //this.setState({ endMoment: p });
        }
      });
    };
    return (
      <React.Fragment>
        <Flex style={{ flexDirection: "column" }}>
          <ElmDatePickerWithLabel
            onChange={handleChange("startMoment")}
            // @ts-ignore
            value={this.state.startMoment}
            disabledDate={d => d.isBefore(dayjs().toDate())}
            label="Start date"
          />
        </Flex>
        <Flex style={{ flexDirection: "column" }}>
          <ElmDatePickerWithLabel
            onChange={handleChange("endMoment")}
            // @ts-ignore
            value={this.state.endMoment ? this.state.endMoment : null}
            disabledDate={d => d.isBefore(this.state.startMoment.toDate())}
            label="End date"
          />
        </Flex>
      </React.Fragment>
    );
  };
  public isLicenseTypeEqualToAny = (typeIds: number[], licenseTypeId: number) =>
    _.some(typeIds, typeId => typeId === licenseTypeId);

  public renderAdvancedLabel = (payload: {
    onInputChange?: (value: string) => void;
    onCheckboxChange?: (e: boolean) => void;
    suffixLabel?: string;
    inputType?: string;
    showCheckBox?: boolean;
    showInput?: boolean;
    isInputDisabled?: boolean;
    isDecimalNumber?: boolean;
    permittedLicenseTypeIds: number[];
    licenseTypeId: number;
    checkBoxLabel?: string;
    label?: string;
    inputLabel?: string;
    containerWidth?: string;
    minValue?: number;
    maxValue?: number;
  }) => {
    if (
      this.isLicenseTypeEqualToAny(
        payload.permittedLicenseTypeIds,
        this.state.licenseTypeId
      )
    ) {
      const handleChangeEvent = (e: CheckboxChangeEvent) => {
        if (_.isFunction(payload.onCheckboxChange)) {
          payload.onCheckboxChange(e.target.checked);
        }
      };
      return (
        <Flex
          flexDirection="row"
          style={{
            alignItems: "flex-start",
            width: payload.containerWidth || "auto"
          }}
        >
          {payload.showCheckBox ? (
            <ElmCheckBox onChange={handleChangeEvent}>
              {payload.checkBoxLabel}
            </ElmCheckBox>
          ) : null}
          {payload.showInput ? (
            <ElmInput
              label={payload.inputLabel}
              type={payload.inputType}
              isDecimalNumber={payload.isDecimalNumber}
              updatedValue={payload.onInputChange}
              disabled={payload.isInputDisabled}
              units={payload.suffixLabel}
              numericConstraints={{
                minValue: payload.minValue,
                maxValue: payload.maxValue
              }}
            />
          ) : null}
        </Flex>
      );
    }
    return null;
  };
  public getEnforcementType = (props: addLicenseQueryResponse) => {
    const enforcementModel = _.find(
      licenseTypeTemplates,
      licenseType =>
        licenseType.product_type_id.toString() === props.license.product.type.id
    );
    return _.find(
      enforcementModel.license_types,
      type => type.id === parseInt(props.license.type.id, 10)
    );
  };
  public updateIntValueOfRequest = (
    key: keyof Pick<
      ILicenseModel,
      | "number_of_installs"
      | "instance_count"
      | "grace_period"
      | "user_count"
      | "instances_per_user"
      | "number_of_users"
      | "max_instances_per_user"
      | "session_count"
      | "sessions_per_user"
      | "sessions_per_instance"
      | "sessions_per_user_per_instance"
      | "default_token_duration"
    >
  ) => (value: string) => {
    const newLicenseState = {
      ...this.state.newLicense
    };
    newLicenseState[key] = parseInt(value, 10);
    this.setState({
      newLicense: newLicenseState
    });
  };
  public onCompanyChange = (company: any) => {
    this.setState({
      company,
      newLicense: {
        ...this.state.newLicense,
        owner: {
          name: company.name,
          owner_id: company.id,
          owner_type: company.type
        },
        owner_id: company.id,
        owner_type: company.type
      }
    });
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditCompanyDialog"
    });
  };
  public onProductChange = (product: any) => {
    this.setState({
      product,
      newLicense: {
        ...this.state.newLicense,
        product_id: product.id
      }
    });
  };
  public updateLicenseType = (licenseTypeId: any) => {
    this.setState({
      licenseTypeId,
      newLicense: {
        ...this.state.newLicense,
        license_type_id: licenseTypeId
      }
    });
  };
  public renderLicenseInfo = () => {
    const updateEmailActivation = (e: boolean) => {
      this.setState({
        newLicense: {
          ...this.state.newLicense,
          require_email_activation: e
        }
      });
    };
    const updateTrial = (e: CheckboxChangeEvent) => {
      this.setState({
        newLicense: { ...this.state.newLicense, is_trial: e.target.checked }
      });
    };
    const updateVendor = (e: CheckboxChangeEvent) => {
      this.setState({
        newLicense: { ...this.state.newLicense, is_vendor: e.target.checked }
      });
    };
    return (
      <React.Fragment>
        <Flex>
          <Flex
            flexDirection="row"
            style={{ width: "100%", justifyContent: "flex-start" }}
          >
            <CompanySelector
              onChange={this.onCompanyChange}
              className="subtle"
              createNewCompany={true}
              label="Company"
              placeholder="Select company"
            />
            <Flex
              style={{ marginLeft: "9px", borderColor: "black !important" }}
            >
              <ElmCheckBox onChange={updateVendor} style={{ alignSelf: "end" }}>
                Vendor
              </ElmCheckBox>
            </Flex>
          </Flex>
        </Flex>
        <Flex style={{ flexDirection: "column" }}>
          <ProductSelector
            onChange={this.onProductChange}
            className="subtle"
            label="Product"
            placeholder="Select product"
          />
        </Flex>
        <MultiFieldRowContainer>
          <LicenseEnforcementSelector
            className="subtle"
            product={this.state.product}
            updateLicenseType={this.updateLicenseType}
            label="Licensing Model"
            placeholder="Select model"
          />
          {this.renderAdvancedLabel({
            permittedLicenseTypeIds: [1],
            inputLabel: "Total # of users",
            showInput: true,
            inputType: "number",
            licenseTypeId: this.state.licenseTypeId,
            onInputChange: this.updateIntValueOfRequest("user_count"),
            minValue: 1
          })}
          {this.renderAdvancedLabel({
            permittedLicenseTypeIds: [2],
            inputLabel: "Total # of sessions",
            showInput: true,
            inputType: "number",
            licenseTypeId: this.state.licenseTypeId,
            onInputChange: this.updateIntValueOfRequest("session_count"),
            minValue: 1
          })}
          {this.renderAdvancedLabel({
            permittedLicenseTypeIds: [3, 4],
            inputLabel: "Total # of installs",
            showInput: true,
            inputType: "number",
            licenseTypeId: this.state.licenseTypeId,
            // onInputChange: this.updateIntValueOfRequest("number_of_installs"),
            onInputChange: this.updateIntValueOfRequest("instance_count"),
            minValue: 1
          })}
        </MultiFieldRowContainer>
        {/* {this.getEnforcementType(payload.props).name}
        {this.renderStartAndEndDate(payload.props)} */}

        <MultiFieldRowContainer>
          {this.renderStartAndEndDate()}
          {this.renderAdvancedLabel({
            permittedLicenseTypeIds: [1, 3, 4],
            inputLabel: "Grace period",
            showInput: true,
            inputType: "number",
            onInputChange: this.updateIntValueOfRequest("grace_period"),
            suffixLabel: "days",
            licenseTypeId: this.state.licenseTypeId,
            minValue: 0
          })}
          {this.renderAdvancedLabel({
            licenseTypeId: this.state.licenseTypeId,
            permittedLicenseTypeIds: [2],
            inputLabel: "Default session length",
            suffixLabel: "sec",
            showInput: true,
            inputType: "number",
            onInputChange: this.updateIntValueOfRequest(
              "default_token_duration"
            ),
            minValue: 1
          })}
          {this.renderAdvancedLabel({
            // onCheckboxChange: this.updateEnabledControl('sessionsPerUser'),
            licenseTypeId: this.state.licenseTypeId,
            // isInputDisabled: !this.state.enabledInputControls.sessionsPerUser,
            inputLabel: "Sessions per user",
            // showCheckBox: true,
            permittedLicenseTypeIds: [2, 4],
            showInput: true,
            inputType: "number",
            onInputChange: this.updateIntValueOfRequest("sessions_per_user"),
            minValue: 1
          })}
          {this.renderAdvancedLabel({
            inputLabel: "Sessions per install",

            licenseTypeId: this.state.licenseTypeId,
            // onCheckboxChange: this.updateEnabledControl("sessionsPerInstall"),
            // isInputDisabled: !this.state.enabledInputControls.sessionsPerInstall,
            // showCheckBox: true,
            permittedLicenseTypeIds: [2, 4],
            showInput: true,
            inputType: "number",
            onInputChange: this.updateIntValueOfRequest(
              "sessions_per_instance"
            ),
            minValue: 1
          })}

          {this.renderAdvancedLabel({
            inputLabel: "Number of users",

            licenseTypeId: this.state.licenseTypeId,
            // onCheckboxChange: this.updateEnabledControl("sessionsPerInstall"),
            // isInputDisabled: !this.state.enabledInputControls.sessionsPerInstall,
            // showCheckBox: true,
            permittedLicenseTypeIds: [2],
            showInput: true,
            inputType: "number",
            onInputChange: this.updateIntValueOfRequest("number_of_users"),
            minValue: 1
          })}

          {this.renderAdvancedLabel({
            inputLabel: "Number of installs",

            licenseTypeId: this.state.licenseTypeId,
            // onCheckboxChange: this.updateEnabledControl("sessionsPerInstall"),
            // isInputDisabled: !this.state.enabledInputControls.sessionsPerInstall,
            // showCheckBox: true,
            permittedLicenseTypeIds: [2],
            showInput: true,
            inputType: "number",
            onInputChange: this.updateIntValueOfRequest("number_of_installs"),
            minValue: 1
          })}

          {this.renderAdvancedLabel({
            licenseTypeId: this.state.licenseTypeId,
            // isInputDisabled: !this.state.enabledInputControls
            //   .sessionsPerUserPerInstall,
            // onCheckboxChange: this.updateEnabledControl(
            //   "sessionsPerUserPerInstall"
            // ),
            inputLabel: "Sessions per user per install",
            // showCheckBox: true,
            permittedLicenseTypeIds: [2, 4],
            onInputChange: this.updateIntValueOfRequest(
              "sessions_per_user_per_instance"
            ),
            showInput: true,
            inputType: "number",
            minValue: 1
          })}
        </MultiFieldRowContainer>
        {/* <MultiFieldRowContainer>
          {this.renderAdvancedLabel({
            licenseTypeId: this.state.licenseTypeId,
            checkBoxLabel: "Enforce email activation",
            showCheckBox: true,
            onCheckboxChange: updateEmailActivation,
            permittedLicenseTypeIds: [1, 2, 3],
            containerWidth: "100%"            
          })}
        </MultiFieldRowContainer> */}
        <Flex
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            flexGrow: 2
          }}
        >
          {this.renderAdvancedLabel({
            licenseTypeId: this.state.licenseTypeId,
            checkBoxLabel: "Enforce email activation",
            showCheckBox: true,
            onCheckboxChange: updateEmailActivation,
            permittedLicenseTypeIds: [1, 2, 3],
            containerWidth: "100%"
          })}
          <ElmCheckBox onChange={updateTrial}>Trial</ElmCheckBox>
          <ElmCheckBox>Notify customer of changes</ElmCheckBox>
        </Flex>
      </React.Fragment>
    );
  };
  public createNewLicense = () => {
    let termableId: number;
    let graphqlId: string;
    this.setState({ loading: true });
    const gateway = getGateway();
    let licenseResponse: { id: number; graphql_id: string };

    gateway.request
      .newLicense(this.state.newLicense)
      .then(res => {
        if (!res.error) {
          licenseResponse = res.data;
          termableId = _.get(res, "data.id");
          graphqlId = _.get(res, "data.graphql_id");
          return gateway.request.newLicenseTerm({
            termable_type: "License",
            termable_id: _.isFinite(termableId) ? termableId : -1,
            start_date: !dayjs.isDayjs(this.state.startMoment)
              ? dayjs().format()
              : this.state.startMoment.format(),
            end_date: !dayjs.isDayjs(this.state.endMoment)
              ? null
              : this.state.endMoment.format()
          });
        }
        throw new Error("Failed");
      })
      .then(res => {
        if (_.isFunction(_.get(this.props.payload, "onConfirm"))) {
          this.props.payload.onConfirm({
            termResponse: res.data,
            licenseResponse
          });
        }
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  public isUpdateDisabled = () => {
    return (
      !_.isObject(this.state.product) ||
      !_.isObject(this.state.company) ||
      !_.isFinite(this.state.licenseTypeId) ||
      !this.state.endMoment ||
      this.state.loading
    );
  };
  public render() {
    return (
      <DialogContainer
        className={"add-license-dialog"}
        style={{ minHeight: 571 }}
      >
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <FieldsContainer>{this.renderLicenseInfo()}</FieldsContainer>
        <Flex justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            label={"Add"}
            disabled={this.isUpdateDisabled()}
            colorVariance="primary"
            onClick={this.createNewLicense}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
export default AddLicenseDialog;
