/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type productSelectQueryVariables = {};
export type productSelectQueryResponse = {
  readonly products: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
      readonly components: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly name: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};
export type productSelectQuery = {
  readonly response: productSelectQueryResponse;
  readonly variables: productSelectQueryVariables;
};

/*
query productSelectQuery {
  products {
    nodes {
      id
      name
      components {
        nodes {
          id
          name
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v2 = [
      {
        alias: null,
        args: null,
        concreteType: "ProductConnection",
        kind: "LinkedField",
        name: "products",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "Product",
            kind: "LinkedField",
            name: "nodes",
            plural: true,
            selections: [
              v0 /*: any*/,
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: "ComponentConnection",
                kind: "LinkedField",
                name: "components",
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: "Component",
                    kind: "LinkedField",
                    name: "nodes",
                    plural: true,
                    selections: [v0 /*: any*/, v1 /*: any*/],
                    storageKey: null
                  }
                ],
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "productSelectQuery",
      selections: v2 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "productSelectQuery",
      selections: v2 /*: any*/
    },
    params: {
      cacheID: "683840eb81cf5d37e9aec57d7c72f1e0",
      id: null,
      metadata: {},
      name: "productSelectQuery",
      operationKind: "query",
      text:
        "query productSelectQuery {\n  products {\n    nodes {\n      id\n      name\n      components {\n        nodes {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "5b7f980afc8a0d3ddefe82cbab7020af";
export default node;
