/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type productFeaturesTableQueryVariables = {
  id: string;
};
export type productFeaturesTableQueryResponse = {
  readonly product: {
    readonly featuresCount: number;
    readonly features: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly featureGroups: {
          readonly nodes: ReadonlyArray<{
            readonly name: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type productFeaturesTableQuery = {
  readonly response: productFeaturesTableQueryResponse;
  readonly variables: productFeaturesTableQueryVariables;
};

/*
query productFeaturesTableQuery(
  $id: ID!
) {
  product(id: $id) {
    featuresCount
    features {
      nodes {
        id
        name
        featureGroups {
          nodes {
            name
            id
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "featuresCount",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "productFeaturesTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "FeatureConnection",
              kind: "LinkedField",
              name: "features",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Feature",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [
                    v3 /*: any*/,
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: "FeatureGroupConnection",
                      kind: "LinkedField",
                      name: "featureGroups",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "FeatureGroup",
                          kind: "LinkedField",
                          name: "nodes",
                          plural: true,
                          selections: [v4 /*: any*/],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "productFeaturesTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "FeatureConnection",
              kind: "LinkedField",
              name: "features",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Feature",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [
                    v3 /*: any*/,
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: "FeatureGroupConnection",
                      kind: "LinkedField",
                      name: "featureGroups",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "FeatureGroup",
                          kind: "LinkedField",
                          name: "nodes",
                          plural: true,
                          selections: [v4 /*: any*/, v3 /*: any*/],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v3 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "1dfeba2693e45ae024785178e94c7152",
      id: null,
      metadata: {},
      name: "productFeaturesTableQuery",
      operationKind: "query",
      text:
        "query productFeaturesTableQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    featuresCount\n    features {\n      nodes {\n        id\n        name\n        featureGroups {\n          nodes {\n            name\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "0258b4c301373dfb5817eac61d533bed";
export default node;
