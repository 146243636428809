/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type editComponentGroupQueryVariables = {
  id: string;
  productId: string;
};
export type editComponentGroupQueryResponse = {
  readonly components: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
      readonly componentGroup: {
        readonly name: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly componentGroup: {
    readonly name: string | null;
    readonly product: {
      readonly name: string | null;
    } | null;
  } | null;
};
export type editComponentGroupQuery = {
  readonly response: editComponentGroupQueryResponse;
  readonly variables: editComponentGroupQueryVariables;
};

/*
query editComponentGroupQuery(
  $id: ID!
  $productId: String!
) {
  components(bladeScope: $productId) {
    nodes {
      id
      name
      componentGroup {
        name
        id
      }
    }
  }
  componentGroup(id: $id) {
    name
    product {
      name
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "productId"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "productId"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v4 = [v3 /*: any*/],
    v5 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v6 = [v3 /*: any*/, v2 /*: any*/];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "editComponentGroupQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ComponentConnection",
          kind: "LinkedField",
          name: "components",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Component",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "ComponentGroup",
                  kind: "LinkedField",
                  name: "componentGroup",
                  plural: false,
                  selections: v4 /*: any*/,
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v5 /*: any*/,
          concreteType: "ComponentGroup",
          kind: "LinkedField",
          name: "componentGroup",
          plural: false,
          selections: [
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: v4 /*: any*/,
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "editComponentGroupQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ComponentConnection",
          kind: "LinkedField",
          name: "components",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Component",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "ComponentGroup",
                  kind: "LinkedField",
                  name: "componentGroup",
                  plural: false,
                  selections: v6 /*: any*/,
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v5 /*: any*/,
          concreteType: "ComponentGroup",
          kind: "LinkedField",
          name: "componentGroup",
          plural: false,
          selections: [
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: v6 /*: any*/,
              storageKey: null
            },
            v2 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "ca879f4efd777902c49ba778f4dcb39e",
      id: null,
      metadata: {},
      name: "editComponentGroupQuery",
      operationKind: "query",
      text:
        "query editComponentGroupQuery(\n  $id: ID!\n  $productId: String!\n) {\n  components(bladeScope: $productId) {\n    nodes {\n      id\n      name\n      componentGroup {\n        name\n        id\n      }\n    }\n  }\n  componentGroup(id: $id) {\n    name\n    product {\n      name\n      id\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "e25df14472b20842478281ba7542cdab";
export default node;
