/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type productSelectorComponentQueryVariables = {
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
};
export type productSelectorComponentQueryResponse = {
  readonly products: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly id: string;
      readonly type: {
        readonly id: string;
        readonly name: string;
      };
    } | null> | null;
  } | null;
};
export type productSelectorComponentQuery = {
  readonly response: productSelectorComponentQueryResponse;
  readonly variables: productSelectorComponentQueryVariables;
};

/*
query productSelectorComponentQuery(
  $sortColumn: [String!]
  $sortDirection: [String!]
) {
  products(sortColumn: $sortColumn, sortDirection: $sortDirection) {
    nodes {
      name
      id
      type {
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "sortColumn"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "sortDirection"
      } as any
    ],
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: "Variable",
            name: "sortColumn",
            variableName: "sortColumn"
          },
          {
            kind: "Variable",
            name: "sortDirection",
            variableName: "sortDirection"
          }
        ],
        concreteType: "ProductConnection",
        kind: "LinkedField",
        name: "products",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "Product",
            kind: "LinkedField",
            name: "nodes",
            plural: true,
            selections: [
              v1 /*: any*/,
              v2 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: "ProductCategory",
                kind: "LinkedField",
                name: "type",
                plural: false,
                selections: [v2 /*: any*/, v1 /*: any*/],
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "productSelectorComponentQuery",
      selections: v3 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "productSelectorComponentQuery",
      selections: v3 /*: any*/
    },
    params: {
      cacheID: "b8cbcc4915014d09634110bbb8120616",
      id: null,
      metadata: {},
      name: "productSelectorComponentQuery",
      operationKind: "query",
      text:
        "query productSelectorComponentQuery(\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n) {\n  products(sortColumn: $sortColumn, sortDirection: $sortDirection) {\n    nodes {\n      name\n      id\n      type {\n        id\n        name\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "279800d6f2227b8f578de01d4a309a2e";
export default node;
