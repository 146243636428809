import styled from "styled-components";

export const GroupName = styled.span`
  margin-left: 20px;
  height: 24px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  /* Black */

  color: ${props => props.theme.colors.black};
`;

export const GroupSelectionContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.whiteTwo};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-height: 198px;
  max-height: 198px;
  overflow-y: scroll;
  margin-top: 5px;
`;

export const ValueLabel = styled.span`
  height: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* warmGrey */

  color: ${props => props.theme.colors.whiteTwo};
  margin-bottom: 22px;
`;

export const ValueText = styled.span`
  height: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Black */

  color: ${props => props.theme.colors.black};
`;

export const BladeDescription = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Greyish */

  color: ${props => props.theme.colors.warmGrey};
  margin-top: 9.5px;
  margin-bottom: 23px;
`;

export const GroupSelectionDesc = styled.span`
  height: 24px;
  left: 0px;
  top: -4.5px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  /* Black */

  color: ${props => props.theme.colors.textPrimary};
`;

export const SectionTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  /* Black */

  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 12px;
`;
