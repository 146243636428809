/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseActiveSessionsTablePaginationQueryVariables = {
  id: string;
  count: number;
  cursor?: string | null | undefined;
};
export type licenseActiveSessionsTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<
    "licenseActiveSessionsTable_activeSessions"
  >;
};
export type licenseActiveSessionsTablePaginationQuery = {
  readonly response: licenseActiveSessionsTablePaginationQueryResponse;
  readonly variables: licenseActiveSessionsTablePaginationQueryVariables;
};

/*
query licenseActiveSessionsTablePaginationQuery(
  $id: ID!
  $count: Int!
  $cursor: String
) {
  ...licenseActiveSessionsTable_activeSessions_3T5kGn
}

fragment licenseActiveSessionsTable_activeSessions_3T5kGn on Query {
  license(id: $id) {
    activeSessions(first: $count, after: $cursor) {
      edges {
        node {
          id
          startTime
          endTime
          user {
            displayName
            id
          }
          instance {
            hardwareIdentifier {
              info
            }
            id
          }
          versionString
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    } as any,
    v3 = {
      kind: "Variable",
      name: "id",
      variableName: "id"
    } as any,
    v4 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any
    ],
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: "Fragment",
      metadata: null,
      name: "licenseActiveSessionsTablePaginationQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v3 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "licenseActiveSessionsTable_activeSessions"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/],
      kind: "Operation",
      name: "licenseActiveSessionsTablePaginationQuery",
      selections: [
        {
          alias: null,
          args: [v3 /*: any*/],
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            {
              alias: null,
              args: v4 /*: any*/,
              concreteType: "SessionConnection",
              kind: "LinkedField",
              name: "activeSessions",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "SessionEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Session",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v5 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "startTime",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "endTime",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: "User",
                          kind: "LinkedField",
                          name: "user",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "displayName",
                              storageKey: null
                            },
                            v5 /*: any*/
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: "Instance",
                          kind: "LinkedField",
                          name: "instance",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "HardwareIdentifier",
                              kind: "LinkedField",
                              name: "hardwareIdentifier",
                              plural: false,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "info",
                                  storageKey: null
                                }
                              ],
                              storageKey: null
                            },
                            v5 /*: any*/
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "versionString",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: v4 /*: any*/,
              filters: null,
              handle: "connection",
              key: "licenseActiveSessionsTable_activeSessions",
              kind: "LinkedHandle",
              name: "activeSessions"
            },
            v5 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "ef8130f647524ea332a3b670a3be8ecb",
      id: null,
      metadata: {},
      name: "licenseActiveSessionsTablePaginationQuery",
      operationKind: "query",
      text:
        "query licenseActiveSessionsTablePaginationQuery(\n  $id: ID!\n  $count: Int!\n  $cursor: String\n) {\n  ...licenseActiveSessionsTable_activeSessions_3T5kGn\n}\n\nfragment licenseActiveSessionsTable_activeSessions_3T5kGn on Query {\n  license(id: $id) {\n    activeSessions(first: $count, after: $cursor) {\n      edges {\n        node {\n          id\n          startTime\n          endTime\n          user {\n            displayName\n            id\n          }\n          instance {\n            hardwareIdentifier {\n              info\n            }\n            id\n          }\n          versionString\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "67fe6d6fd8313628af9a61a2242aa0d2";
export default node;
