import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import { ElmSelectOption, ElmSelectWithLabel } from "../../../elmSelect";
import { ElmInput } from "../../../elmInput";
import { userRoles } from "../../../../utils";
import { notification } from "antd";
import { renderErrorNotification } from "utils/ant-notifications";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;

export interface IEditAdminDialogProps {
  token: string;
  email: string;
  currentRole: string;
  isEdit?: boolean;
}
export interface IEditAdminDialogState {
  role: string;
  email: string;
  error: boolean;
}

export class EditAdminDialog extends DialogBase<
  IModalProps<IEditAdminDialogProps>
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  static getAppConnectOptions() {
    return super.getAppConnectOptions({
      selectors: {
        onboardingMode: "onboardingModeSelector"
      }
    });
  }
  componentDidMount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingPause();
  }
  componentWillUnmount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingCompleted();
  }
  public checkOnboardingMode = () => {
    return this.props.appState?.onboardingMode?.status === "active";
  };
  state: IEditAdminDialogState = {
    role: "",
    email: this.props?.payload?.email || null,
    error: false
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditAdminDialog"
    });
  };
  public editAdmin = () => {
    const isValid = this.validateEmail(this.state.email);
    if (!isValid) {
      renderErrorNotification("Email not valid !");
      return;
    }
    if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
      this.props.payload.onConfirm(this.state);
    }
  };
  public getTitle = () => {
    return this.props?.payload?.isEdit
      ? "Edit administrator"
      : "Add administrator";
  };
  public getDescription = () => {
    return `Change the role you would like your new administrator to have.`;
  };

  public onRoleChange = (role: any) => {
    role = role as string;
    this.setState({ role });
  };
  public updateEmail = (email: string) => {
    this.setState({ email });
  };
  public validateEmail = (input: string) => {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input);
    if (!isValid) {
      this.setState({ error: true });
      return false;
    }
    this.setState({ error: false });
    return true;
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <Flex flexDirection="column">
          <ElmInput
            label={"Email"}
            placeholder="Email"
            style={{ marginTop: "15px" }}
            disabled={this.props?.payload?.isEdit ? true : false}
            value={this.state.email || ""}
            updatedValue={this.updateEmail}
            error={this.state.error}
            errorMessage={"Email is not valid"}
            controlled={true}
          />
          <ElmSelectWithLabel
            label="Role"
            onChange={this.onRoleChange}
            defaultValue={_.get(this.props.payload, "currentRole")}
          >
            {_.map(userRoles, option => (
              <ElmSelectOption key={option.value} label={option.label}>
                {option.label}
              </ElmSelectOption>
            ))}
          </ElmSelectWithLabel>
        </Flex>
        <Flex justifyContent={"flex-end"} style={{ marginTop: "15px" }}>
          {this.checkOnboardingMode() ? null : (
            <ElmButton
              label={"Cancel"}
              colorVariance="outline-secondary"
              onClick={this.closeModal}
            />
          )}
          <ElmButton
            colorVariance="primary"
            label={"Confirm"}
            onClick={this.editAdmin}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
