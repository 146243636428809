import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { GlobalFilters, IChart } from "./types";
import { Flex } from "reflexbox";
import { generateQueryFilter } from "./helpers";
import ProductsFilter from "../analyticsComponent/filters/productsFilter";
import TimeFilter from "../analyticsComponent/filters/timeFilter";
import { selectors } from "../../store/ducks/analytics";
//@ts-ignore
import { useSelector } from "react-redux";
import LoadingWrapper from "./loadingWrapper";
import styled, { useTheme } from "styled-components";
import { getIcon } from "../icons";
import { Tooltip } from "antd";
import useChartExportButton from "./chartExportButton";
import ErrorPlaceholder from "./chartErrorPlaceholder";

class ErrorBoundary extends React.Component<any, { error: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { error: false };
  }
  static getDerivedStateFromError() {
    return { error: true };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo);
  }
  public render() {
    if (this.state.error) {
      return <ErrorPlaceholder />;
    }
    return this.props.children;
  }
}

export interface IExportData {
  [key: string]: any;
  category?: string;
  data?: number;
  percentage?: number;
}

export interface IExportChart {
  root: any;
  data: any;
}

interface IFilterContainer {
  containerProps: IChart & {
    registrationData?: any[];
    allTimeFilterStart?: string;
  };
  Component: React.FC<IChart>;
}
const icon = getIcon("AiFillInfoCircle");
export const InfoIcon = styled(icon)`
  width: 14px;
  height: 14px;
  margin-left: 2px;
  margin-right: 7px;
  margin-top: -7px;
  color: ${props => props.theme.colors.buttonIcon};
`;

export default function FiltersContainer({
  containerProps,
  Component
}: IFilterContainer) {
  const selectorData = useSelector(selectors.filtersSelector);
  const theme = useTheme();
  const [filters, setFilters] = useState<GlobalFilters>({
    companyName: null,
    productName: null,
    timerange: null
  });

  const getTimeRangeDefaultValue = () => {
    return containerProps.hideTimeRangeFilter
      ? containerProps.timeRangeOverride
        ? containerProps.timeRangeOverride
        : filters?.timerange
        ? filters?.timerange
        : ""
      : containerProps.timeRangeOverride
      ? containerProps.timeRangeOverride
      : filters?.timerange
      ? filters?.timerange
      : "";
  };

  const [timeRange, setTimeRange] = useState<string>(
    getTimeRangeDefaultValue()
  );

  const getTimeRangeValue = () => {
    return containerProps?.hideTimeRangeFilter &&
      (!containerProps?.timeRangeOverride || "")
      ? timeRange
      : timeRange;
  };

  const qData = generateQueryFilter(
    containerProps.queryParams,
    getTimeRangeValue(),
    filters?.productName || "",
    filters?.companyName || "",
    containerProps?.allTimeFilterStart
  );

  useEffect(() => {
    setFilters({
      productName:
        selectorData?.product !== "-1" ? selectorData?.product : null,
      companyName:
        selectorData?.company !== "-1" ? selectorData?.company : null,
      timerange: selectorData?.timerange !== "-1" ? selectorData.timerange : ""
    });
  }, [selectorData?.product, selectorData?.company, selectorData?.timerange]);

  useLayoutEffect(() => {
    if (containerProps?.timeRangeOverride) {
      setTimeRange(containerProps.timeRangeOverride);
    }
  }, [containerProps.timeRangeOverride]);

  const handleChange = (value: string) => {
    setTimeout(() => setTimeRange(value), 100);
  };
  const handleProductChange = (productId: string, productName: string) => {
    setFilters({ ...filters, productName: productName });
  };

  const { updateChartData, renderExportButton } = useChartExportButton();

  const componentMemo = useMemo(
    () => (
      <ErrorBoundary>
        <Component
          {...containerProps}
          queryParams={qData}
          setChartData={updateChartData}
        />
      </ErrorBoundary>
    ),
    [Component, containerProps, qData, updateChartData]
  );

  return (
    <div
      style={{
        minHeight: containerProps.options?.chartMaxHeight,
        width: "100%"
      }}
    >
      {(!containerProps.hideTimeRangeFilter ||
        containerProps?.plot?.title ||
        containerProps.showProductFilter) && (
        <div
          style={{
            minHeight: "1px",
            maxHeight: "62px",
            color: theme.colors.textPrimary,
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Flex
            style={{
              fontSize: "14px",
              lineHeight: "14px",
              color: theme.colors.warmGrey
            }}
          >
            {containerProps.plot?.title ? containerProps.plot.title : ""}
            {containerProps.plot?.description ? (
              <Tooltip placement="top" title={containerProps.plot?.description}>
                <InfoIcon />
              </Tooltip>
            ) : null}
          </Flex>
          <Flex>
            {containerProps.showProductFilter && (
              <ProductsFilter
                productId={filters?.productName || ""}
                onChange={handleProductChange}
              />
            )}
            {!containerProps.hideTimeRangeFilter && (
              <TimeFilter timeRange={timeRange} handleChange={handleChange} />
            )}
            {containerProps?.exportOptions?.showCustomExportButton &&
            !containerProps.exportOptions?.disableExport
              ? renderExportButton()
              : null}
          </Flex>
        </div>
      )}
      <div
        //id={instanceId}
        style={{ width: "100%", minHeight: "inherit", maxHeight: "400px" }}
      >
        <LoadingWrapper>{componentMemo}</LoadingWrapper>
      </div>
    </div>
  );
}
