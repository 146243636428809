import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenProductReleaseConfigBlade
} from "../../../components/bladeManager/types";
import {
  BladeTemplate,
  BladeTitle,
  ProductReleaseChannelsTable,
  ProductReleasesTable,
  ToggleView,
  TabView
} from "../../../components";
import { ElmButton } from "../../../components/elmButton";
import { IToggleViewProps } from "../../../components/toggleView";
import {
  ProductReleaseConfigQuery,
  ProductReleaseConfigQueryResponse
} from "./__generated__/ProductReleaseConfigQuery.graphql";

import { deepEqual } from "fast-equals";
import { productReleaseTableType } from "../../components/productReleasesTable";
import { ITab } from "components/tabView";
import { productReleaseConfigTableType } from "Products/components/productReleaseConfigurationsTable";
import theme from "../../../theme";
import { useTheme } from "styled-components";
import { AutoSizer } from "react-virtualized";
import { updateBladeUrlState } from "utils";

const graphqlQuery = graphql`
  query ProductReleaseConfigQuery($id: ID!) {
    ...productReleaseChannelsTable_releaseChannels @arguments(id: $id)
    ...productReleasesTable_releases @arguments(id: $id)
    releaseConfiguration(id: $id) {
      name
      product {
        iconUrl
        name
      }
      fallback {
        id
        name
      }
      configReleasesChannel: releaseChannels {
        nodes {
          id
          name
          currentRelease {
            tag
          }
          licensesCount
        }
      }
      configReleases: releases {
        nodes {
          id
          tag
          releaseChannelsCount
        }
      }
    }
  }
`;
export interface IProductReleaseConfigBladeProps
  extends IBladeBaseProps<IOpenProductReleaseConfigBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}
export type state = {
  mode: "edit" | "normal";
  activeTabIndex: number;
  updatePayload: {
    releaseConfigName: string;
  };
};
export class ProductReleaseConfigBlade extends React.Component<
  IProductReleaseConfigBladeProps & {
    result: ProductReleaseConfigQueryResponse;
    retry: () => void;
    theme: any;
  },
  state
> {
  state: state = {
    mode: "normal",
    updatePayload: {
      releaseConfigName: ""
    },
    activeTabIndex: 0
  };
  public shouldComponentUpdate(
    nextProps: IProductReleaseConfigBladeProps & {
      result: ProductReleaseConfigQueryResponse;
    },
    nextState: state
  ) {
    return (
      !deepEqual(this.state, nextState) ||
      !deepEqual(this.props.routeData, nextProps.routeData) ||
      !deepEqual(this.props.result, nextProps.result)
    );
  }
  public renderLeftSideHeader = (
    info: ProductReleaseConfigQueryResponse
  ) => () => {
    const releaseConfigName = _.get(info, "releaseConfiguration.name");
    const updateReleaseConfigName = (name: string) => {
      this.setState({
        updatePayload: {
          releaseConfigName: name
        }
      });
    };
    return (
      <Flex style={{ alignItems: "center" }}>
        <BladeTitle>
          {_.get(info, "releaseConfiguration.product.name")} /{" "}
          {releaseConfigName}
        </BladeTitle>
      </Flex>
    );
  };
  public navAway = () => {
    this.props.openBlade({
      routeName: "Customer portal",
      fromBladeIndex: this.props.index,
      route: "Overview",
      routeData: null
    });
  };

  public renderReleaseChannelsTable = () => {
    let clickedOnActions = false;
    const navToReleaseChannelBlade = (
      payload: productReleaseConfigTableType
    ) => {
      if (clickedOnActions) {
        clickedOnActions = false;
        return;
      }
      this.props.openBlade({
        fromBladeIndex: this.props.index,
        routeName: payload.name,
        route: "ProductReleaseChannel",
        routeData: {
          id: payload.id,
          releaseId: this.props.routeData.id
        }
      });
    };
    const renderRowActionButtons = (rowData: any) => {
      const openFallbackConfirmation = () => {
        this.props.openDialog("ConfirmationDialog", {
          entityType: "channel fallback",
          name: rowData?.name || "",
          isSimpleConfirmation: true,
          warningMessage: `The fallback release channel will be used by 
          all licenses that are not configured to use a specific release channel.`,
          onConfirm: () => {
            const releaseChannels = _.get(
              this.props.result,
              "configReleasesChannel.nodes",
              []
            );
            const releases = _.get(
              this.props.result,
              "configReleases.nodes",
              []
            );
            this.props.gateway.request
              .editReleaseConfig(
                {
                  fallback_release_channel_id: rowData.id,
                  id: this.props.routeData.id,
                  name: this.props.result?.releaseConfiguration?.name,
                  release_channels: releaseChannels,
                  releases: releases
                },
                { id: this.props.routeData.id }
              )
              .then(res => {
                if (res.error) {
                  this.props.openDialog("ErrorDialog", {
                    onConfirm: null
                  });
                } else {
                  this.props.refreshAllOpenBlades();
                  this.props.closeDialog("ConfirmationDialog");
                }
              });
          }
        });
      };

      const openDeleteDialog = (e: any) => {
        clickedOnActions = true;
        this.props.openDialog("DeleteDialog", {
          entityType: "Release channel",
          name: rowData?.name,
          onConfirm: () => {
            this.props.gateway.request
              .deleteReleaseChannel(null, { id: rowData.id })
              .then(res => {
                if (!_.get(res, "error")) {
                  this.props.closeDialog("DeleteDialog");
                  this.props.refreshAllOpenBlades();
                }
              })
              .catch(error => {
                console.error(error);
              });
          }
        });
      };
      const isFallback =
        rowData.name === rowData.releaseConfiguration?.fallback?.name
          ? true
          : false;
      return (
        <Flex
          onClick={e => {
            e.stopPropagation();
          }}
          justifyContent="flex-end"
          padding="2px"
          style={{ minWidth: "74px" }}
        >
          {isFallback ? null : (
            <ElmButton
              className="darkModeTransparentBtn"
              variance="icon-button"
              icon="rotate-left"
              iconPrefix="fas"
              label=""
              alt="Edit"
              colorVariance="subtle"
              permissions="modify_assets"
              style={{
                fontSize: "17px",
                color: this.props.theme.colors.iconColor
              }}
              onClick={() => openFallbackConfirmation()}
            />
          )}
          <ElmButton
            className="darkModeTransparentBtn"
            variance="icon-button"
            icon={"trash"}
            iconPrefix={"fas"}
            alt="Delete"
            label=""
            style={{
              color: this.props.theme.colors.iconColor
            }}
            colorVariance="subtle"
            permissions="modify_assets"
            onClick={e => openDeleteDialog(e)}
          />
        </Flex>
      );
    };
    const showNewReleaseChannelDialog = () => {
      this.props.openDialog("NewProductReleaseChannelDialog", {
        onConfirm: () => {
          this.props.refreshAllOpenBlades();
        },
        configurationId: this.props.routeData.id,
        name: this.props.routeData.name
      });
    };

    return (
      <ProductReleaseChannelsTable
        releaseChannels={this.props.result}
        renderRowActionButtons={renderRowActionButtons}
        useDefaultAddButton={showNewReleaseChannelDialog}
        onRowClick={clickedOnActions ? null : navToReleaseChannelBlade}
      />
    );
  };
  public renderReleasesTable = () => {
    const showNewReleaseDialog = () => {
      this.props.openDialog("NewProductReleaseDialog", {
        onConfirm: () => {
          this.props.refreshAllOpenBlades();
        },
        configurationId: this.props.routeData.id,
        name: this.props.routeData.name
      });
    };
    const navToReleaseBlade = (payload: productReleaseTableType) => {
      this.props.openBlade({
        fromBladeIndex: this.props.index,
        routeName: payload.tag,
        route: "ProductRelease",
        routeData: {
          id: payload.id
        }
      });
    };

    return (
      <ProductReleasesTable
        releases={this.props.result}
        onRowClick={navToReleaseBlade}
        useDefaultAddButton={showNewReleaseDialog}
      />
    );
  };
  public renderTable = () => {
    const views = [
      {
        text: "Channels",
        Component: this.renderReleaseChannelsTable()
      },
      {
        text: "Releases",
        Component: this.renderReleasesTable()
      }
    ] as IToggleViewProps["views"];
    return <ToggleView views={views} />;
  };
  public renderRightSideHeader = () => {
    const updateReleaseConfig = () => {
      const releaseChannels = _.get(
        this.props.result,
        "releaseConfiguration.configReleasesChannel.nodes",
        []
      );
      const releases = _.get(
        this.props.result,
        "releaseConfiguration.configReleases.nodes",
        []
      );
      const fallbackId = _.get(
        this.props.result,
        "releaseConfiguration.fallback.id",
        null
      );
      this.props.openDialog("NewProductReleaseConfigDialog", {
        entityType: "release configuration",
        name: this.props.result.releaseConfiguration.name,
        isEdit: true,
        releaseId: this.props.routeData.id,
        onConfirm: ({ name }) => {
          this.props.gateway.request
            .editReleaseConfig(
              {
                fallback_release_channel_id: fallbackId,
                id: this.props.routeData.id,
                name: name,
                release_channels: releaseChannels,
                releases: releases
              },
              { id: this.props.routeData.id }
            )
            .then(res => {
              if (res.error) {
                console.error(
                  "Updating release configuration name went wrong!"
                );
              } else {
                this.props.closeDialog("NewProductReleaseConfigDialog");
                this.props.refreshAllOpenBlades();
              }
            });
        }
      });
    };
    const showDeleteDialog = () => {
      this.props.openDialog("DeleteDialog", {
        entityType: "release configuration",
        name: this.props.result.releaseConfiguration.name,
        onConfirm: () => {
          this.props.gateway.request
            .deleteReleaseConfig(null, {
              id: this.props.routeData.id
            })
            .then(res => {
              if (res.error) {
                console.error(res.error);
              } else {
                this.props.closeBlade({
                  route: "ProductReleaseConfig",
                  fromBladeIndex: this.props.index
                });
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      });
    };

    return (
      <Flex>
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon="pen"
          iconPrefix="fas"
          label={"Edit"}
          permissions="modify_assets"
          onClick={() => updateReleaseConfig()}
        />

        <ElmButton
          style={{
            color: this.props.theme.colors.trashButtonColor
          }}
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon={"trash"}
          iconPrefix={"fas"}
          label={"Delete"}
          permissions="modify_assets"
          onClick={showDeleteDialog}
        />
      </Flex>
    );
  };

  public getTabs = (height: number = 500) => {
    //if (res === null) return [];
    let ret = [];
    ret.push({
      title: "Channels",
      Component: this.renderReleaseChannelsTable()
      // supScript: _.get(res, 'product.componentsCount'),
    });
    ret.push({
      title: "Releases",
      Component: this.renderReleasesTable()
      // supScript: _.get(res, 'product.featuresCount'),
    });

    return ret as ITab[];
  };

  public updateActiveTab = (activeTabIndex: number) => {
    updateBladeUrlState(this.props, activeTabIndex);
    this.setState({
      activeTabIndex
    });
  };

  public renderProductInfo = () => {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Product"}
        bladeTypeName={"Releases"}
        accentColor={"productsBlue"}
        renderLeftSideHeader={this.renderLeftSideHeader(this.props.result)}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        loading={!this.props.result}
        closeBlade={this.props.closeBlade}
        bladeType="ProductReleaseConfig"
      >
        <Flex
          style={{
            maxHeight: "159",
            width: "100%",
            borderBottom: `1px solid ${theme.colors.whiteTwo}`
          }}
        ></Flex>

        <Flex
          style={{
            marginTop: "24px",
            paddingLeft: "21px",
            paddingRight: "21px",
            flexDirection: "column",
            flex: 1
          }}
        >
          <AutoSizer defaultHeight={300}>
            {({ height, width }) => (
              <TabView
                showBottomBorder={true}
                leftAlign={true}
                tabList={this.getTabs(height)}
                defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
                onUpdateActiveTab={this.updateActiveTab}
                orientation={"vertical"}
                style={{
                  width,
                  height
                }}
              />
            )}
          </AutoSizer>
          {/* </div> */}
        </Flex>
      </BladeTemplate>
    );
  };

  public render() {
    return this.renderProductInfo();
  }
}
const RenderQuery = (props: IProductReleaseConfigBladeProps) => {
  const theme = useTheme();
  const renderProductInfo = (payload: {
    error: Error;
    props: ProductReleaseConfigQueryResponse;
    retry: () => void;
  }) => {
    return (
      <ProductReleaseConfigBlade
        {...props}
        result={payload.props}
        theme={theme}
        retry={payload.retry}
      />
    );
  };
  return (
    <QueryRenderer<ProductReleaseConfigQuery>
      environment={relayEnvironment}
      variables={{
        id: props.routeData.id
      }}
      query={graphqlQuery}
      render={renderProductInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
