import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { getGateway } from "../../../../api";
import { LoaderContainer, SearchBar } from "../../../../components/helpers";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../../api/relay";
import { graphql } from "babel-plugin-relay/macro";
import { Divider } from "antd";
import { getFaIcon } from "components/icons";
import ElmRadio from "components/elmRadio";
import ElmTable from "components/elmTable";
import {
  editReleaseConfigurationQuery,
  editReleaseConfigurationQueryResponse
} from "./__generated__/editReleaseConfigurationQuery.graphql";
import { getDateTimeFormatted } from "components/elmTable/helpers";
import moment from "moment";
import {
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { searchInObjectArray } from "utils";

const graphqlQuery = graphql`
  query editReleaseConfigurationQuery($id: ID!) {
    product(id: $id) {
      id
      releaseConfigurations {
        edges {
          node {
            releaseChannels {
              edges {
                node {
                  id
                  name
                  currentRelease {
                    id
                    tag
                    createdAt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 540px;
  width: 440px;
`;
const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;

const DialogSubtitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle};
  color: ${props => props.theme.colors.warmGrey};
`;

const CreatedAt = styled.p`
  ${props => props.theme.fontType.paragraph};
  color: ${props => props.theme.colors.warmGrey};
  font-weight: bold;
`;

const Paragraph = styled.p`
  ${props => props.theme.fontType.paragraph};
  color: ${props => props.theme.colors.warmGrey};
  padding-inline: 4px;
`;

const TagIcon = styled(getFaIcon({ iconName: "tag", prefix: "far" }))`
  color: ${props => props.theme.colors.primary};
`;

interface CurrentRelease {
  id: string;
  tag: string;
  createdAt: string;
}

interface ReleaseConfiguration {
  id: string;
  name: string;
}

interface ReleaseChannel {
  id: string;
  name: string;
  currentRelease: CurrentRelease;
  releaseConfiguration: ReleaseConfiguration;
}

interface IDialogProps {
  licenseId: string;
  productId: string;
  currentChannel?: ReleaseChannel;
  refresh?: () => void;
}
type state = {
  licenseId: string;
  productId: string;
  currentChannel: ReleaseChannel;
  newChannel?: ReleaseChannel;
  loading: boolean;
  search: string;
};
export class EditLicenseReleaseConfigDialog extends DialogBase<
  IModalProps<IDialogProps> & {
    result: editReleaseConfigurationQueryResponse;
    retry: () => void;
    theme: any;
  },
  state
> {
  state: state = {
    licenseId: this.props.payload?.licenseId,
    productId: this.props.payload?.productId,
    currentChannel: this.props.payload?.currentChannel,
    newChannel: null,
    loading: false,
    search: null
  };

  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditLicenseReleaseConfigDialog"
    });
  };
  public handleAssignChannel = () => {
    const gateway = getGateway();
    this.state.newChannel &&
      gateway.request["updateLicenseReleaseChannel"]({
        license_id: this.props?.payload?.licenseId,
        release_channel_id: this.state.newChannel?.id,
        release_configuration_id: this.state.newChannel?.currentRelease?.id
      })
        .then(res => {
          if (!res.error) {
            renderSuccessNotification(
              "Updated Release Configuration to selected channel !"
            );
            this.props.payload.refresh();
            this.closeModal();
          }
        })
        .catch(err => {
          renderFailureNotification("Update Release Configuration failed !");
          console.error(err);
        });
  };

  public renderCurrentReleaseSection = () => {
    const dateTime = moment(
      this.state?.currentChannel?.currentRelease?.createdAt
    );
    return (
      <>
        <DialogSubtitle>CURRENT RELEASE</DialogSubtitle>
        <Divider style={{ marginTop: 0, marginBottom: 12 }} />
        <Flex alignItems={"center"} style={{ gap: 4 }}>
          <TagIcon />
          <Paragraph>
            {this.state?.currentChannel?.currentRelease?.tag}
          </Paragraph>
          <CreatedAt>Created At: </CreatedAt>
          {getDateTimeFormatted(dateTime.toString())}
        </Flex>
      </>
    );
  };

  public renderNewReleaseSection = () => {
    if (
      !this.state.newChannel ||
      this.state.newChannel?.id === this.state.currentChannel?.id
    ) {
      return null;
    }
    const dateTime = moment(this.state?.newChannel?.currentRelease?.createdAt);
    return (
      <>
        <DialogSubtitle>NEW RELEASE</DialogSubtitle>
        <Divider style={{ marginTop: 0, marginBottom: 12 }} />
        <Flex alignItems={"center"} style={{ gap: 4 }}>
          {this.state?.newChannel?.currentRelease?.tag ? <TagIcon /> : null}
          <Paragraph>{this.state?.newChannel?.currentRelease?.tag}</Paragraph>
          <CreatedAt>Created At: </CreatedAt>
          {getDateTimeFormatted(dateTime.toString())}
        </Flex>
      </>
    );
  };

  public renderQueryRenderer = () => {
    const renderReleaseTable = (payload: {
      error: Error;
      props: editReleaseConfigurationQueryResponse;
      retry: () => void;
    }) => {
      if (payload.error || !_.isObject(payload.props)) {
        return <DialogContainer>{LoaderContainer()}</DialogContainer>;
      }

      const releaseChannels = payload.props?.product?.releaseConfigurations?.edges?.map(
        ({ node }) => node.releaseChannels
      )[0];
      const nodes = releaseChannels?.edges?.map(({ node }) => node);

      const filteredData = this.state.search
        ? searchInObjectArray(this.state.search, nodes)
        : nodes;

      const handleClick = (row: any, _index: number) => {
        if (this.state.newChannel?.id === row?.id) {
          this.setState({ newChannel: null });
          return;
        }
        const target = nodes?.find(item => item.id === row?.id);
        this.setState({
          newChannel: target ? { ...(target as ReleaseChannel) } : null
        });
      };

      return (
        <DialogContainer>
          <DialogTitle>
            Update release{" "}
            {this.props.payload?.currentChannel?.releaseConfiguration?.name
              ? `: { ${this.props.payload.currentChannel.releaseConfiguration.name} }`
              : ""}
          </DialogTitle>
          <SearchBar
            placeholder="Search for keyword"
            onSearch={search => this.setState({ search })}
            style={{ marginTop: 24 }}
          />
          <Flex
            flexDirection="column"
            flex={1}
            style={{
              width: "100%",
              marginTop: 24,
              marginBottom: 0
            }}
          >
            <ElmTable
              hideSearchBar
              data={filteredData}
              columns={[
                {
                  Header: "",
                  accessor: "",
                  sortKey: null,
                  disableSortBy: true,
                  width: 0.05,
                  cellRenderer: data => {
                    return (
                      <Flex alignItems={"center"}>
                        <ElmRadio
                          checked={
                            this.state.newChannel?.id
                              ? data?.rowData?.id === this.state.newChannel?.id
                              : data?.rowData?.id ===
                                this.state.currentChannel?.id
                          }
                        />
                      </Flex>
                    );
                  }
                },
                {
                  Header: "Channel",
                  accessor: "name",
                  sortKey: null,
                  disableSortBy: true
                },
                {
                  Header: "Current release",
                  accessor: "currentRelease.tag",
                  sortKey: null,
                  disableSortBy: true
                },
                {
                  Header: "Date",
                  accessor: "",
                  sortKey: null,
                  disableSortBy: true,
                  width: 0.5,
                  cellRenderer: data =>
                    getDateTimeFormatted(
                      data?.rowData?.currentRelease?.createdAt
                    )
                }
              ]}
              onRowClick={handleClick}
              hideFooter
            />
          </Flex>
          {this.renderCurrentReleaseSection()}
          {this.state.newChannel ? this.renderNewReleaseSection() : null}
          <Flex
            alignItems="flex-end"
            justifyContent={"flex-end"}
            marginTop={24}
          >
            <ElmButton
              label={"Cancel"}
              colorVariance={"outline-secondary"}
              onClick={this.closeModal}
            />
            <ElmButton
              disabled={
                this.state.loading ||
                !this.state.newChannel ||
                this.state.newChannel?.id === this.state.currentChannel?.id
              }
              label={"Update"}
              colorVariance="primary"
              onClick={this.handleAssignChannel}
            />
          </Flex>
        </DialogContainer>
      );
    };
    return (
      <QueryRenderer<editReleaseConfigurationQuery>
        variables={{ id: this.props.payload?.productId }}
        environment={relayEnvironment}
        query={graphqlQuery}
        render={renderReleaseTable}
      />
    );
  };

  public render() {
    return this.renderQueryRenderer();
  }
}
