import * as _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { getGateway } from "../../../../api";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { ElmButton, ElmToggle } from "components";
import { ElmDatePickerWithLabel } from "components/elmDate";
import { DATE_FORMAT } from "const";
import { Radio } from "antd";
import dayjs from "dayjs";
import { LicenseQueryResponse } from "Licenses/blades/License/__generated__/LicenseQuery.graphql";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 280px;
`;
const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
  margin-bottom: 21px;
  margin-top: 0;
`;

type state = {
  termType: "limited" | "unlimited";
  start_date: string;
  startType: "immediately" | "specific";
  end_date?: string;
};
export class RenewEntitlementDialog extends DialogBase<
  IModalProps<
    LicenseQueryResponse["license"]["componentEntitlements"]["nodes"][number]
  >,
  state
> {
  state: state = {
    termType: this.props.payload?.latestTerm?.endDate ? "limited" : "unlimited",
    start_date: this.props.payload?.latestTerm?.startDate as string,
    startType: "specific",
    end_date: this.props.payload?.latestTerm?.endDate as string
  };

  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "RenewEntitlementDialog"
    });
  };

  public renderLimitedTermOptionsEdit = () => {
    if (this.state.termType === "unlimited") {
      return null;
    }

    return (
      <Flex style={{ marginTop: "14px" }} flexDirection="column">
        <Flex style={{ marginBottom: "24px" }}>
          <Radio.Group
            value={this.state.startType}
            onChange={e => {
              this.setState({
                startType: e.target.value
              });
            }}
          >
            <Radio value={"immediately"}>Immediately</Radio>
            <Radio value={"specific"}>Specific date</Radio>
          </Radio.Group>
        </Flex>
        {this.state.startType === "specific" && (
          <Flex marginBottom={12}>
            <ElmDatePickerWithLabel
              label="Start date"
              defaultValue={
                this.state?.start_date ? dayjs(this.state.start_date) : null
              }
              style={{ width: "280px" }}
              format={DATE_FORMAT}
              onChange={d => {
                this.setState({
                  start_date: d.toISOString()
                });
              }}
            />
          </Flex>
        )}
        <Flex>
          <ElmDatePickerWithLabel
            label="End date"
            defaultValue={
              this.state?.end_date ? dayjs(this.state.end_date) : null
            }
            style={{ width: "280px" }}
            format={DATE_FORMAT}
            onChange={d => {
              this.setState({
                end_date: d.toISOString()
              });
            }}
          />
        </Flex>
      </Flex>
    );
  };

  public handleConfirm = () => {
    const dataToSend = {
      entitlement: {
        id: this.props.payload?.id,
        allow_unlimited_usage:
          this.props.payload?.tokenCount ||
          this.props.payload?.currencyCount ||
          this.props.payload?.sessionCount
            ? false
            : true,
        start_date:
          this.state.termType === "unlimited"
            ? dayjs().toISOString()
            : this.state.start_date,
        end_date:
          this.state.termType === "unlimited"
            ? null
            : _.get(this.state, "end_date", dayjs().toISOString()),
        component_group_id: this.props?.payload?.componentGroup?.id || null,
        component_ids: this.props?.payload?.components?.nodes
          ? _.map(this.props?.payload?.components?.nodes, n => n.id)
          : null,
        overage_allowed_count: 0,
        overage_allowed_percentage: 0
      }
    };

    if (_.isNumber(this.props.payload?.sessionCount)) {
      dataToSend.entitlement["session_count"] = this.props.payload.sessionCount;
    }
    if (_.isNumber(this.props.payload?.currencyCount)) {
      dataToSend.entitlement[
        "currency_count"
      ] = this.props.payload.currencyCount;
    }
    if (_.isNumber(this.props.payload?.tokenCount)) {
      dataToSend.entitlement["token_count"] = this.props.payload.tokenCount;
    }

    const gateway = getGateway();
    gateway.request
      .editEntitlement(dataToSend, {
        id: dataToSend.entitlement.id
      })
      .then(res => {
        if (res.error) {
          renderErrorNotification(res.data?.error || res.data?.errors[0]);
          console.error(res.error);
        } else {
          renderSuccessNotification("Extended term");
          _.isFunction(this.props.payload.onConfirm) &&
            this.props.payload.onConfirm(this.state);
          this.closeModal();
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  public updateTermType = (activate: boolean) => {
    this.setState({ termType: activate ? "limited" : "unlimited" });
  };

  public renderBody = () => {
    return (
      <DialogContainer>
        <DialogTitle>Renew term</DialogTitle>
        <Flex flexDirection="column" flex="1">
          <div style={{ width: "100px" }}>
            <ElmToggle
              style={{ fontSize: "13px", color: "red" }}
              size="small"
              onChange={this.updateTermType}
              rightLabel="Limited"
              leftLabel="Unlimited"
              checked={this.state.termType === "limited"}
            />
          </div>
          {this.renderLimitedTermOptionsEdit()}
          <Flex
            alignItems="flex-end"
            justifyContent={"flex-end"}
            marginTop={48}
          >
            <ElmButton
              label={"Cancel"}
              colorVariance={"outline-secondary"}
              onClick={this.closeModal}
            />
            <ElmButton
              label={"Update"}
              colorVariance="primary"
              onClick={this.handleConfirm}
            />
          </Flex>
        </Flex>
      </DialogContainer>
    );
  };

  public render() {
    return this.renderBody();
  }
}
