import * as _ from "lodash";
import baseStyled, { ThemedStyledInterface } from "styled-components";

const colors = {
  highlight: "#F4F6F6",
  hover: "#f2f2f2",
  primary: "#3366ff",

  pureBlack: "#000000",
  black: "#302c28",
  blackTwo: "#272726",
  cornflower: "#688eff",
  companyRed: "#EB5740",
  greyish: "#BEBDBC",
  lightGrey: "#f4f6f6",
  lightBlue: "#3F68F6",
  lightishBlue: "#3366ff",
  filterBlue: "#0039E5",
  mango: "#ff9d1d",
  orangeyRed: "#ff4833",
  paleLilac: "#d8e2ff",
  pink: "#E34DFF",
  pinkishGrey: "#cbcbcb",
  productsBlue: "#68ace8",
  realBlack: "#000000",
  salmon: "#ff8274",
  userGreen: "#5bc94a",
  warmGrey: "#707070",
  white: "#ffffff",
  whiteTwo: "#dadada",
  newPurple: "#9D02D7",
  orangeRed: "#EB5740",
  orange: "#ffa033",

  buttonDisabled: "#302c28",
  buttonTextPrimary: "#ffffff",
  buttonTextSecondary: "#ffffff",
  textPrimary: "#302c28",
  textSecondary: "#707070",
  topBarText: "#ffffff",
  bladeContainer: "#ffffff",
  bladeBackground: "#F4F6F6",
  filterBackground: "#ffffff",
  navigationBackground: "#ffffff",
  detailsContainer: "#F4F6F6",
  iconColor: "#161616",
  modalContainer: "#ffffff",
  alertDescription: "#000000",
  tooltipBackground: "#ffffff",
  linkButtonLabel: "#3F68F6",
  buttonIcon: "#3F68F6",
  loginBoxShadow: "rgba(48, 44, 40, 0.15)",
  highlightBoxShadow: "rgba(167, 169, 172, 0.5)",
  elmButtonSubtleIcon: "#3366ff",
  elmButtonSubtleBackground: "transparent",
  elmButtonSubtleHover: "#F4F6F6",
  elmButtonSubtleFocus: "#ffffff",
  elmButtonSubtlePressed: "#DAE2FC",
  elmButtonSubtleDisabled: "transparent",
  elmButtonSubtleLabelDisabled: "#BEBDBC",
  elmButtonOutlineSecondaryBackground: "transparent",
  elmButtonOutlineSecondaryHover: "#F4F6F6",
  elmButtonOutlineSecondaryFocus: "transparent",
  elmButtonOutlineSecondaryPressed: "#DADADA",
  elmButtonOutlineSecondaryDisabled: "#F4F6F6",
  elmButtonOutlineSecondaryLabelDisabled: "#BEBDBC",
  elmButtonPrimaryHover: "#0039e5",
  elmButtonTextPrimary: "#3366ff",
  inputLabels: "#707070",
  inputTextDefault: "#BEBDBC",
  inputTextActive: "#000000",
  trashButtonColor: "#ff4833",
  activeTab: "#302C28", //"#3F68F6",
  toggleText: "#3F68F6",
  dropdownLinkBackground: "#0039E5",
  searchBarFiltersButtonBackground: "#F4F6F6",
  searchBarFiltersButtonText: "#302C28",
  elmToggleBackground: "#707070"
};
const chartColors = {
  labels: "#707070",
  axes: "#DADADA"
};
const font = {
  main: "Inter"
};
const fontSizes = {
  xxSmall: "10px",
  xSmall: "12px",
  ySmall: "13px",
  small: "14px",
  medium: "16px",
  large: "18px",
  xLarge: "24px",
  xxLarge: "26px"
};
const fontWeight = {
  normal: "normal",
  bold: "bold",
  medium: "500",
  heavy: "600",
  xxHeavy: "800"
};
const fontType = {
  breadcrumb: {
    "font-family": font.main,
    "font-size": fontSizes.medium,
    "font-weight": fontWeight.medium,
    height: "15px",
    color: colors.black,
    "line-height": 1.25,
    "text-align": "left"
  },
  input: {
    "border-bottom": `1px ${colors.black} solid`,
    "font-family": font.main,
    "font-size": fontSizes.xSmall,
    height: "20px",
    "line-height": 1.21,
    "text-align": "left"
  },
  attributeTitle: {
    "font-family": font.main,
    "font-size": fontSizes.xSmall,
    "font-weight": fontWeight.heavy,
    "font-stretch": "normal",
    "font-style": "normal",
    color: colors.black,
    "line-height": "normal",
    margin: "0px"
  },
  buttonText: {
    "font-family": font.main,
    "font-size": fontSizes.xSmall,
    "font-weight": fontWeight.heavy,
    "font-stretch": "normal",
    "font-style": "normal",
    color: colors.black,
    "line-height": "normal",
    margin: "0px"
  },
  fieldText: {
    "font-family": font.main,
    "font-size": fontSizes.medium,
    "font-weight": fontWeight.heavy,
    "font-stretch": "normal",
    "font-style": "normal",
    color: colors.black,
    "line-height": "normal",
    margin: "0px"
  },
  paragraph: {
    "font-family": font.main,
    "font-size": fontSizes.xSmall,
    color: colors.black,
    "font-weight": "normal",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": "normal",
    margin: "0px"
  },
  pageTitle: {
    "font-family": font.main,
    "font-size": fontSizes.xxLarge,
    "font-weight": "bold",
    color: colors.black
  },
  objectTitle: {
    "font-family": font.main,
    "font-size": fontSizes.xxLarge,
    "font-weight": "bold",
    color: colors.realBlack,
    margin: "0px",
    height: "31px",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": "1.19",
    "letter-spacing": "normal",
    "text-align": "left"
  },
  objectSubTitle: {
    "font-family": font.main,
    "font-size": fontSizes.large,
    "font-weight": "normal",
    "line-height": "1.17",
    color: colors.black,
    margin: "0px"
  },
  visualizationTitle: {
    "font-family": font.main,
    "font-size": fontSizes.medium,
    "font-weight": "bold",
    color: colors.realBlack,
    "margin-bottom": "10px"
  },
  visualizationSubTitle: {
    "font-family": font.main,
    "font-size": fontSizes.small,
    "font-weight": "normal",
    color: colors.textPrimary
  },
  tab: {
    "border-bottom": `1px ${colors.black} solid`,
    "font-family": font.main,
    "font-size": fontSizes.ySmall,
    height: "15px",
    "line-height": 1,
    "min-width": "59px",
    "text-align": "left"
  },
  tableHeader: {
    "font-family": font.main,
    "font-size": fontSizes.xSmall,
    height: "15px",
    "text-align": "left",
    "font-weight": fontWeight.xxHeavy
  }
};
const customBgOpacity = {
  opacity: 0.2
};
export const theme = {
  colors,
  chartColors,
  font,
  fontSizes,
  fontWeight,
  fontType,
  customBgOpacity
};
export type theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<theme>;
export default theme;
