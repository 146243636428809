import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import {
  productComponentsTableQuery,
  productComponentsTableQueryResponse
} from "./__generated__/productComponentsTableQuery.graphql";
import ElmTable, { IColumn, IElmTableProps } from "components/elmTable";
import { shallowEqual } from "fast-equals";
import _ from "lodash";
import { searchInObjectArray } from "utils";
import { EmptyTableAction } from "Products/blades/Product";
import { IGeneratedTableProps } from "utils/tableGenerator";

enum ComponentsTableColumns {
  Name = "Name",
  GroupName = "GroupName",
  LicenseModel = "LicenseModel"
}
export const ComponentsTableColumnConfig: Record<
  ComponentsTableColumns,
  IColumn
> = {
  Name: {
    Header: "Name",
    accessor: "name",
    sortKey: "name"
  },
  GroupName: {
    Header: "Group Name",
    accessor: "componentGroup.name",
    sortKey: "component_groups.name"
  },
  LicenseModel: {
    Header: "License Model",
    accessor: "componentType",
    sortKey: "componentType"
  }
};

const graphqlQuery = graphql`
  query productComponentsTableQuery($id: ID!) {
    product(id: $id) {
      componentsCount
      components {
        nodes {
          id
          name
          componentType
          componentGroup {
            name
            id
          }
        }
      }
    }
  }
`;

interface IComponentsTableProps
  extends Omit<IElmTableProps, "columns" | "data">,
    Pick<
      IGeneratedTableProps,
      "bladeScope" | "setRefreshFn" | "refreshKey" | "removeRefreshFn"
    > {
  addNewComponent: () => void;
}

interface state {
  search_term?: string;
}

class ProductComponentsTable extends React.Component<
  IComponentsTableProps & {
    result: productComponentsTableQueryResponse;
    retry: () => void;
  },
  state
> {
  state: Readonly<state> = {
    search_term: ""
  };
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    this.props.removeRefreshFn(this.props.refreshKey);
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render = () => {
    const nodes = _.get(this.props.result, "product.components.nodes", []);

    const filteredData = this.state.search_term
      ? searchInObjectArray(this.state.search_term, nodes)
      : nodes;

    return (
      <ElmTable
        {...this.props}
        data={filteredData}
        totalCount={nodes?.length}
        onCriteriaChange={({ search_term }) => this.setState({ search_term })}
        columns={[
          ...Object.keys(ComponentsTableColumnConfig).map(
            key => ComponentsTableColumnConfig[key as ComponentsTableColumns]
          )
        ]}
        useDefaultAddButton={this.props.addNewComponent}
        onRowClick={this.props.onRowClick}
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message={"Product has no components"}
            addButtonCallback={this.props.addNewComponent}
            actionMessage="Add new component?"
          />
        )}
      />
    );
  };
}

const RenderQuery = (props: IComponentsTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: productComponentsTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <ProductComponentsTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<productComponentsTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default RenderQuery;
