import {
  RegistrationRequestFailedAction,
  RegistrationRequestSentAction,
  RegistrationRequestSucceededAction,
  REGISTRATION_REQUEST_FAILED,
  REGISTRATION_REQUEST_SENT,
  REGISTRATION_REQUEST_SUCCEEDED,
  RequestSignInAction,
  RequestSignOutAction,
  RequestVerifyCredentialsAction,
  RequestVerifyTokenAction,
  REQUEST_SIGNIN,
  REQUEST_SIGNOUT,
  REQUEST_VERIFY_CREDENTIALS,
  REQUEST_VERIFY_TOKEN,
  SetHasVerificationBeenAttemptedAction,
  SET_HAS_VERIFICATION_BEEN_ATTEMPTED,
  SignInRequestFailedAction,
  SignInRequestSentAction,
  SignInRequestSucceededAction,
  SIGNIN_REQUEST_FAILED,
  SIGNIN_REQUEST_SENT,
  SIGNIN_REQUEST_SUCCEEDED,
  SignOutRequestFailedAction,
  SignOutRequestSentAction,
  SignOutRequestSucceededAction,
  SIGNOUT_REQUEST_FAILED,
  SIGNOUT_REQUEST_SENT,
  SIGNOUT_REQUEST_SUCCEEDED,
  UserAttributes,
  UserSignInCredentials,
  VerificationParams,
  VerifyTokenRequestFailedAction,
  VerifyTokenRequestSentAction,
  VerifyTokenRequestSucceededAction,
  VERIFY_TOKEN_REQUEST_FAILED,
  VERIFY_TOKEN_REQUEST_SENT,
  VERIFY_TOKEN_REQUEST_SUCCEEDED
} from "./types";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Pure Redux actions:
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const registrationRequestSent = (): RegistrationRequestSentAction => ({
  type: REGISTRATION_REQUEST_SENT
});

export const registrationRequestSucceeded = (
  userAttributes: UserAttributes
): RegistrationRequestSucceededAction => ({
  type: REGISTRATION_REQUEST_SUCCEEDED,
  data: {
    userAttributes
  }
});

export const registrationRequestFailed = (
  data?: any
): RegistrationRequestFailedAction => ({
  type: REGISTRATION_REQUEST_FAILED,
  data
});
export const requestVerifyToken = (
  data: VerificationParams
): RequestVerifyTokenAction => ({
  data,
  type: REQUEST_VERIFY_TOKEN
});
export const requestVerifyCredentials = (): RequestVerifyCredentialsAction => ({
  type: REQUEST_VERIFY_CREDENTIALS
});
export const verifyTokenRequestSent = (): VerifyTokenRequestSentAction => ({
  type: VERIFY_TOKEN_REQUEST_SENT
});

export const verifyTokenRequestSucceeded = (
  userAttributes: UserAttributes
): VerifyTokenRequestSucceededAction => ({
  type: VERIFY_TOKEN_REQUEST_SUCCEEDED,
  data: {
    userAttributes
  }
});

export const verifyTokenRequestFailed = (): VerifyTokenRequestFailedAction => ({
  type: VERIFY_TOKEN_REQUEST_FAILED
});

export const requestSignIn = (
  data: UserSignInCredentials
): RequestSignInAction => ({
  type: REQUEST_SIGNIN,
  data
});
export const signInRequestSent = (): SignInRequestSentAction => ({
  type: SIGNIN_REQUEST_SENT
});

export const signInRequestSucceeded = (
  userAttributes: UserAttributes
): SignInRequestSucceededAction => ({
  type: SIGNIN_REQUEST_SUCCEEDED,
  data: {
    userAttributes
  }
});

export const signInRequestFailed = (data?: any): SignInRequestFailedAction => ({
  type: SIGNIN_REQUEST_FAILED,
  data
});

export const requestSignOut = (): RequestSignOutAction => ({
  type: REQUEST_SIGNOUT
});
export const signOutRequestSent = (): SignOutRequestSentAction => ({
  type: SIGNOUT_REQUEST_SENT
});

export const signOutRequestSucceeded = (): SignOutRequestSucceededAction => ({
  type: SIGNOUT_REQUEST_SUCCEEDED
});

export const signOutRequestFailed = (): SignOutRequestFailedAction => ({
  type: SIGNOUT_REQUEST_FAILED
});

export const setHasVerificationBeenAttempted = (
  hasVerificationBeenAttempted: boolean
): SetHasVerificationBeenAttemptedAction => ({
  type: SET_HAS_VERIFICATION_BEEN_ATTEMPTED,
  data: {
    hasVerificationBeenAttempted
  }
});
