import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Flex } from "reflexbox/styled-components";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import ElmTable, { IColumn } from "components/elmTable";
import { searchInObjectArray } from "utils";
import _ from "lodash";
import {
  linkedUsersTableQuery,
  linkedUsersTableQueryResponse
} from "./__generated__/linkedUsersTableQuery.graphql";
import styled from "styled-components";
import { getIcon } from "components";

enum LinkedUsersTableColumns {
  Name = "Name",
  Company = "Company",
  Actions = "Actions"
}
export const LinkedUsersTableColumnConfig: Record<
  LinkedUsersTableColumns,
  IColumn
> = {
  Name: {
    Header: "Name",
    accessor: "linkedUser.name",
    disableSortBy: true,
    sortKey: null
  },
  Company: {
    Header: "Company",
    accessor: "linkedUser.owner.name",
    disableSortBy: true,
    sortKey: null
  },
  Actions: {
    Header: "Actions",
    accessor: "",
    cellRenderer: () => "",
    disableSortBy: true,
    sortKey: null,
    width: 0.05
  }
};

const graphqlQuery = graphql`
  query linkedUsersTableQuery($id: ID!) {
    user(id: $id) {
      userLinks {
        nodes {
          id
          linkedUser {
            id
            name
            owner {
              name
            }
          }
        }
      }
      linkedUsersCount
    }
  }
`;

interface ILinkedUsersTableProps {
  id: string;
  unlinkUser: (linked_user_id: string) => void;
}

interface state {
  search_term?: string;
}

class LinkedUsersTable extends React.Component<
  ILinkedUsersTableProps & {
    result: linkedUsersTableQueryResponse;
    retry: () => void;
  },
  state
> {
  state: Readonly<state> = {
    search_term: ""
  };
  public render = () => {
    const nodes = _.get(this.props.result, "user.userLinks.nodes", []);

    const UnlinkIcon = styled(getIcon("link-simple-slash", "far"))`
      margin-inline: 4px;
    `;

    const filteredData = this.state.search_term
      ? searchInObjectArray(this.state.search_term, nodes)
      : nodes;

    return (
      <ElmTable
        data={filteredData}
        totalCount={_.get(this.props.result, "user.linkedUsersCount")}
        onCriteriaChange={({ search_term }) => this.setState({ search_term })}
        columns={[
          ...Object.keys(LinkedUsersTableColumnConfig).map(
            key => LinkedUsersTableColumnConfig[key as LinkedUsersTableColumns]
          )
        ]}
        renderRowActionButtons={data => (
          <UnlinkIcon
            onClick={() => this.props.unlinkUser(data?.linkedUser?.id)}
          />
        )}
      />
    );
  };
}

const RenderQuery = (props: ILinkedUsersTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: linkedUsersTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <LinkedUsersTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<linkedUsersTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.id
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default RenderQuery;
