import { graphql } from "babel-plugin-relay/macro";

export const AdminsTableList = graphql`
  query adminsTableQuery(
    $count: Int
    $blade_scope: String
    $id: ID!
    $search: String
    $sortColumn: [String!]
    $sortDirection: [String!]
  ) {
    ...fragments_adminsTable
      @arguments(
        count: $count
        blade_scope: $blade_scope
        id: $id
        search: $search
        sortColumn: $sortColumn
        sortDirection: $sortDirection
      )
  }
`;
