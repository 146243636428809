import * as _ from "lodash";
import React from "react";
import { OperationType } from "relay-runtime";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Flex } from "reflexbox/styled-components";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import { AutoSizer } from "react-virtualized";
//@ts-ignore
import { fadeIn, fadeOut, slideInRight, slideOutRight } from "react-animations";
import styled, { keyframes } from "styled-components";
import theme from "../../theme";
import { ElmButton } from "../elmButton";
import Loader from "react-loaders";
import { getClassName, ScrollStyle } from "../helpers";

// import { GraphQLTaggedNode, QueryRenderer } from "react-relay";
// import relayEnvironment from "../../api/relay";
import { shallowEqual } from "fast-equals";
import { bladeType, IBladeBaseProps } from "./types";
import bladeManagerContext from "./bladeManagerContext";

const transitionName = "bladeTemplate";
const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;
const slideInAnimation = keyframes`${slideInRight}`;
const slideOutAnimation = keyframes`${slideOutRight}`;
const TransitionContainer = styled.div`
  ${ScrollStyle}
`;
const LoaderContainer = styled.section`
  height: 100%;
  &.${transitionName}-appear {
    animation: 1s ${fadeInAnimation};
  }

  &.${transitionName}-enter {
    animation: 1s ${fadeInAnimation};
  }

  &.${transitionName}-leave {
    animation: 0.5s ${fadeOutAnimation};
  }
`;
const RegularContainer = styled.section`
  height: 100%;
  &.${transitionName}-enter {
    animation: 1s ${fadeInAnimation};
  }

  &.${transitionName}-appear {
    opacity: 0.01;
    animation: 1s ${fadeInAnimation};
  }

  &.${transitionName}-leave {
    animation: 0.5s ${fadeOutAnimation};
    opacity: 0;
  }
`;

const margin = 12;
const padding = 30;
const breadCrumbHeight = 23 + 15 + 27; // 15 is top margin, 27 bottom margin
const AppBarHeight = 51;
const bladeAllowance = margin * 2 + padding * 2;
const bladeHeight = 947 - padding * 2 - margin;

// const bladeHeight =
//   window.outerHeight - (breadCrumbHeight + AppBarHeight + bladeAllowance + 30);
export const bladeWidth = 782;
const BladeContainer = styled.div`
  width: ${bladeWidth}px;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.18);
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: ${props => props.theme.colors.white};
`;
// const CloseIcon = styled(getIcon('FaTimes'))`
// color: ${props => props.theme.colors.black};
// `

const BladeTypeName = styled.span<{
  color?: string;
}>`
  position: absolute;
  min-width: max-content;
  margin-top: 24px;
  margin-left: 21px;
  height: 16px;
  left: 0px;
  top: 0px;
  background-color: ${props => props.theme.colors[props.color] || ""};
  border-radius: 6px;
  padding-left: 8px;
  padding-right: 8px;

  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.xxSmall};
  line-height: 17px;
  /* identical to box height, or 240% */

  letter-spacing: 1px;
  text-transform: uppercase;

  /* PinkishGrey */
  //use grey color if there's not background color set
  color: ${props =>
    props.color ? props.theme.colors.white : props.theme.colors.pinkishGrey};
`;
const HeaderSection = styled(Flex)<{
  bladeType?: string;
}>`
  height: 24px;
  max-height: 24px;
  flex: 1;
  justify-content: space-between;
  position: relative;
  align-items: center;
  //margin: 24px;
  margin-bottom: 8px;
  margin-top: ${props => (props.bladeType === "Dashboard" ? "0px" : "65px")};
`;
const BodySection = styled(Flex)`
  overflow-y: hidden;
  padding: ${padding}px;
  padding-top: 0px;
  padding-bottom: 0px;
  flex: 1;
  flex-direction: column;
`;
const LeftAccentColor = styled.div`
  background-color: ${props => props.theme.colors[props.color] || ""};
  width: 44px;
  height: 23px;
  position: absolute;
  left: 0px;
  top: 65px;
`;
const TopAccentColor = styled.div`
  background-color: ${props => props.theme.colors[props.color] || ""};
  width: 24px;
  height: 15px;
  position: absolute;
  left: 0%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  span {
    color: ${props => props.theme.colors.textPrimary};
    color: #302d28cf;
  }
`;
const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 24px;
  margin-right: 24px;
  position: absolute;
  width: 16px;
  height: 16px;
  right: 0px;
  border-radius: 1px;

  button {
    border: none;
    color: ${props => props.theme.colors.iconColor};
    span {
      color: ${props => props.theme.colors.iconColor};
    }
  }
`;
export interface IBladeTemplateProps<BladeQuery extends OperationType> {
  leftAccentColor?: keyof theme["colors"];
  bladeContainerStyle?: React.CSSProperties;
  bladeBodyStyle?: React.CSSProperties;
  bladeType: bladeType;
  hideBladeTypeName?: boolean;
  bladeTypeName?: string;
  className?: string;
  closeBlade: IBladeBaseProps["closeBlade"];
  title: string;
  bladeIndex: number;
  topAccentColor?: keyof theme["colors"];
  accentColor?: string;
  renderLeftSideHeader?: () => JSX.Element;
  renderRightSideHeader?: () => JSX.Element;
  refreshFn?: () => void;
  setRefreshFn?: (payload: () => void) => void;
  loading?: boolean;
  hideCloseButton?: boolean;
}

export default class BladeTemplate<
  T extends OperationType
> extends React.Component<IBladeTemplateProps<T>, { checkIfInView: boolean }> {
  state = {
    checkIfInView: false
  };
  public componentDidMount() {
    this.setRefreshFn();
    _.delay(() => {
      this.setState({ checkIfInView: true });
    }, 500);
  }
  public componentWillUnmount() {
    this.setState({ checkIfInView: false });
  }
  public componentDidUpdate(prevProps: IBladeTemplateProps<T>) {
    if (!shallowEqual(this.props.refreshFn, prevProps.refreshFn)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.refreshFn);
    }
  };
  public closeBlade = () => {
    if (_.isFunction(this.props.closeBlade)) {
      this.props.closeBlade({ route: this.props.bladeType });
    }
  };
  public renderHeader = () => {
    return (
      <HeaderSection bladeType={this.props.bladeType}>
        {this.props.topAccentColor ? (
          <TopAccentColor color={this.props.topAccentColor} />
        ) : null}
        {_.isFunction(this.props.renderLeftSideHeader) ? (
          <Flex
            style={{
              justifySelf: "start",
              marginLeft: this.props.bladeType === "Dashboard" ? "0px" : "22px",
              maxWidth: this.props.bladeType === "Dashboard" ? "unset" : "65%"
            }}
          >
            {this.props.renderLeftSideHeader()}
          </Flex>
        ) : null}
        {_.isFunction(this.props.renderRightSideHeader) ? (
          <Flex
            style={{
              justifySelf: "end",
              marginRight: this.props.bladeType === "Dashboard" ? "0px" : "22px"
            }}
          >
            {this.props.renderRightSideHeader()}
          </Flex>
        ) : null}
      </HeaderSection>
    );
  };
  public renderLoadingBody = () => {
    return (
      <LoaderContainer>
        <Flex
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginLeft: "45px"
          }}
        >
          <Loader
            type="line-scale-pulse-out"
            active={true}
            color={"rgb(98, 98, 130)"}
            style={{ transform: "scale(1.0)" }}
          />
        </Flex>
      </LoaderContainer>
    );
  };
  public renderBladeTypeName = () => {
    if (this.props.hideBladeTypeName) return null;
    return (
      <BladeTypeName color={this.props.accentColor}>
        {this.props.bladeTypeName || this.props.bladeType}
      </BladeTypeName>
    );
  };
  public renderBlade = () => {
    return (
      <RegularContainer
        className={getClassName({
          "blade-template": () => true,
          [this.props.bladeType]: () => true
        })}
      >
        <BladeContainer
          className={getClassName({
            "blade-container": () => true,
            [this.props.className]: () => !!this.props.className
          })}
          style={{
            padding: "0px",
            ...(this.props.bladeContainerStyle || {})
          }}
        >
          <AutoSizer>
            {({ height, width }) => (
              <bladeManagerContext.Consumer>
                {contextValue => (
                  <ScrollIntoViewIfNeeded
                    options={{
                      behavior: actions => {
                        // actions list is sorted from innermost (closest parent to your target) to outermost (often the document.body or viewport)
                        actions.forEach(({ el, top, left }) => {
                          el.scrollLeft = left + bladeWidth;
                        });
                      }
                    }}
                    active={
                      contextValue.lastBladeIndex === this.props.bladeIndex &&
                      this.state.checkIfInView
                    }
                  >
                    <Flex
                      flexDirection={"column"}
                      style={{ height, width, paddingBottom: "7px" }}
                    >
                      {this.props.hideCloseButton ? null : (
                        <CloseContainer>
                          <ElmButton
                            className="darkModeTransparentBtn"
                            style={{
                              width: "100%",
                              height: "100%"
                            }}
                            label=""
                            variance="icon-button"
                            icon="times"
                            iconPrefix="far"
                            colorVariance="subtle"
                            onClick={this.closeBlade}
                          />
                        </CloseContainer>
                      )}
                      {this.renderBladeTypeName()}

                      {/* {this.props.topAccentColor ? (
                        <TopAccentColor color={this.props.topAccentColor} />
                      ) : null} */}
                      {/* {this.props.bladeType !== "Dashboard" && this.renderHeader()} */}
                      {this.renderHeader()}
                      <BodySection
                        className="blade-body"
                        style={{
                          padding: "0px",
                          ...(this.props.bladeBodyStyle || {})
                        }}
                      >
                        <ReactCSSTransitionGroup
                          transitionName={transitionName}
                          transitionAppear={true}
                          transitionEnterTimeout={100}
                          transitionLeaveTimeout={100}
                          component={React.Fragment}
                          // component={TransitionContainer}
                        >
                          {/* {this.renderBlades()} */}
                          {this.props.loading
                            ? this.renderLoadingBody()
                            : this.props.children}
                        </ReactCSSTransitionGroup>
                      </BodySection>
                    </Flex>
                  </ScrollIntoViewIfNeeded>
                )}
              </bladeManagerContext.Consumer>
            )}
          </AutoSizer>
        </BladeContainer>
      </RegularContainer>
    );
  };
  // TODO: maybe bladetemplate should handle query fetches for all blades
  // so this is not repeated everywhere else
  // public renderQuery = () => {
  //   const renderWithResult = (payload: {
  //     error: Error;
  //     props: T['response'];
  //     retry: () => void;
  //   }) => {
  //     if (_.isFunction(this.props.getQueryResult)) {
  //       this.props.getQueryResult(payload);
  //     }
  //   };
  //   if (this.props.graphqlQuery && this.props.variables) {
  //     return (
  //       <QueryRenderer<T>
  //         environment={relayEnvironment}
  //         variables={this.props.variables}
  //         query={this.props.graphqlQuery}
  //         render={renderWithResult}
  //       />
  //     );
  //   }
  //   return null;
  // };
  public render() {
    return this.renderBlade();
  }
}
