/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type productReleaseConfigurationsTablePaginationQueryVariables = {
  count: number;
  cursor?: string | null | undefined;
  search?: string | null | undefined;
  id: string;
};
export type productReleaseConfigurationsTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<
    "productReleaseConfigurationsTable_releaseConfigurations"
  >;
};
export type productReleaseConfigurationsTablePaginationQuery = {
  readonly response: productReleaseConfigurationsTablePaginationQueryResponse;
  readonly variables: productReleaseConfigurationsTablePaginationQueryVariables;
};

/*
query productReleaseConfigurationsTablePaginationQuery(
  $count: Int!
  $cursor: String
  $search: String
  $id: ID!
) {
  ...productReleaseConfigurationsTable_releaseConfigurations_2jDTBs
}

fragment productReleaseConfigurationsTable_releaseConfigurations_2jDTBs on Query {
  product(id: $id) {
    releaseConfigurations(first: $count, after: $cursor, search: $search) {
      edges {
        node {
          id
          name
          releasesCount
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    } as any,
    v4 = {
      kind: "Variable",
      name: "id",
      variableName: "id"
    } as any,
    v5 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v6 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      v5 /*: any*/
    ],
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "productReleaseConfigurationsTablePaginationQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v4 /*: any*/,
            v5 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "productReleaseConfigurationsTable_releaseConfigurations"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v3 /*: any*/,
        v2 /*: any*/
      ],
      kind: "Operation",
      name: "productReleaseConfigurationsTablePaginationQuery",
      selections: [
        {
          alias: null,
          args: [v4 /*: any*/],
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            {
              alias: null,
              args: v6 /*: any*/,
              concreteType: "ReleaseConfigurationConnection",
              kind: "LinkedField",
              name: "releaseConfigurations",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseConfigurationEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ReleaseConfiguration",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v7 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "name",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "releasesCount",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: v6 /*: any*/,
              filters: ["search"],
              handle: "connection",
              key: "productReleaseConfigurationsTable_releaseConfigurations",
              kind: "LinkedHandle",
              name: "releaseConfigurations"
            },
            v7 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "af548e94e831fb49a46facd93b750e1b",
      id: null,
      metadata: {},
      name: "productReleaseConfigurationsTablePaginationQuery",
      operationKind: "query",
      text:
        "query productReleaseConfigurationsTablePaginationQuery(\n  $count: Int!\n  $cursor: String\n  $search: String\n  $id: ID!\n) {\n  ...productReleaseConfigurationsTable_releaseConfigurations_2jDTBs\n}\n\nfragment productReleaseConfigurationsTable_releaseConfigurations_2jDTBs on Query {\n  product(id: $id) {\n    releaseConfigurations(first: $count, after: $cursor, search: $search) {\n      edges {\n        node {\n          id\n          name\n          releasesCount\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "7428b608bea6d36d47588bd7469b883c";
export default node;
