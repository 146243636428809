import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import { tableGenerator } from "../../../utils";
import { renderMultipleLabels } from "../featureGroupsTable";
import { featuresTable_features } from "./__generated__/featuresTable_features.graphql";

export type IFeaturesTableProps = {
  features: featuresTable_features;
};
export const FeaturesTable = tableGenerator<IFeaturesTableProps>({
  columns: [
    {
      Header: "Feature",
      accessor: "name",
      sortKey: "features.name"
    },
    {
      Header: "Groups",
      accessor: "groups",
      sortKey: "feature_groups.name",
      cellRenderer: payload => renderMultipleLabels(payload.cellData)
    },
    {
      Header: "Actions",
      accessor: "",
      cellRenderer: () => "",
      width: 0.15,
      disableSortBy: true
    }
  ],
  connectionQuery: graphql`
    query featuresTablePaginationQuery(
      $count: Int!
      $cursor: String
      #$bladeScope: String!
      $id: ID!
      $search: String
      $sortColumn: [String!]
      $sortDirection: [String!]
    ) {
      ...featuresTable_features
        @arguments(
          count: $count
          cursor: $cursor
          #bladeScope: $bladeScope
          id: $id
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        )
    }
  `,
  dataKey: "features.product.features",
  getNodesTransform: (props: IFeaturesTableProps) => {
    const edges = props.features.product.features.edges;
    const transform = _.reduce(
      edges,
      (all, edge) => {
        const feature = {
          id: edge.node.id,
          name: edge.node.name,
          groups: [] as string[]
        };
        all.push(feature);
        _.each(edge.node.featureGroups.nodes, featureGroup => {
          feature.groups.push(featureGroup.name);
        });
        return all;
      },
      []
    );
    return transform;
  },
  fragmentSpec: {
    features: graphql`
      fragment featuresTable_features on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 6 }
          cursor: { type: "String" }
          #bladeScope: { type: "String!" }
          id: { type: "ID!" }
          search: { type: "String", defaultValue: null }
          sortColumn: {
            type: "[String!]"
            defaultValue: ["features.name", "feature_groups.name"]
          }
          sortDirection: { type: "[String!]", defaultValue: ["ASC", "ASC"] }
        ) {
        product(id: $id) {
          features(
            first: $count
            after: $cursor
            #bladeScope: $bladeScope
            search: $search
            sortColumn: $sortColumn
            sortDirection: $sortDirection
          ) @connection(key: "featuresTable_features") {
            edges {
              node {
                id
                name
                featureGroups {
                  nodes {
                    name
                  }
                }
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `
  },
  tableName: "productFeaturesTable"
});
export default FeaturesTable;
