import clsx from "clsx";
import React from "react";
import { ElmFilterSelect, ElmSelectOption } from "../../elmSelect";
import { DateRange } from "blades/Dashboard/utils";

interface IFilterProps {
  timeRange: string;
  handleChange: (param: DateRange) => void;
  useTransparentVariant?: boolean;
}
function TimeFilter({
  timeRange,
  handleChange,
  useTransparentVariant
}: IFilterProps) {
  return (
    <ElmFilterSelect
      className={useTransparentVariant ? "transparentButton" : "filterBorder"}
      defaultValue={timeRange}
      value={timeRange}
      style={{
        //width: 120,
        marginLeft: "8px"
      }}
      dropdownMatchSelectWidth={false}
      onChange={handleChange}
    >
      <ElmSelectOption value="last24">Last 24h</ElmSelectOption>
      <ElmSelectOption value="days7">Last week</ElmSelectOption>
      <ElmSelectOption value="month1">Last month</ElmSelectOption>
      <ElmSelectOption value="months3">Last quarter</ElmSelectOption>
      <ElmSelectOption value="months6">Last 6 months</ElmSelectOption>
      <ElmSelectOption value="year1">Last year</ElmSelectOption>
      <ElmSelectOption value="year2">Last 2 years</ElmSelectOption>
    </ElmFilterSelect>
  );
}

export default TimeFilter;
