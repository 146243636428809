/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type productComponentsTableQueryVariables = {
  id: string;
};
export type productComponentsTableQueryResponse = {
  readonly product: {
    readonly componentsCount: number;
    readonly components: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly componentType: string;
        readonly componentGroup: {
          readonly name: string | null;
          readonly id: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type productComponentsTableQuery = {
  readonly response: productComponentsTableQueryResponse;
  readonly variables: productComponentsTableQueryVariables;
};

/*
query productComponentsTableQuery(
  $id: ID!
) {
  product(id: $id) {
    componentsCount
    components {
      nodes {
        id
        name
        componentType
        componentGroup {
          name
          id
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "componentsCount",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v3 /*: any*/,
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "componentType",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "ComponentGroup",
              kind: "LinkedField",
              name: "componentGroup",
              plural: false,
              selections: [v4 /*: any*/, v3 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "productComponentsTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v2 /*: any*/, v5 /*: any*/],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "productComponentsTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v2 /*: any*/, v5 /*: any*/, v3 /*: any*/],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "43ef2c077909abf9f99e0366a00cec65",
      id: null,
      metadata: {},
      name: "productComponentsTableQuery",
      operationKind: "query",
      text:
        "query productComponentsTableQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    componentsCount\n    components {\n      nodes {\n        id\n        name\n        componentType\n        componentGroup {\n          name\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "d386b97142688b2c8962449f409dc795";
export default node;
