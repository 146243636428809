import * as _ from "lodash";
import * as React from "react";
import styled, { css } from "styled-components";
import { getIcon, iconNameType } from "../icons";
import { AuthorizedComponent } from "../helpers";
import clsx from "clsx";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

type buttonProps = React.HTMLProps<HTMLButtonElement>;
export interface IElmButtonProps extends buttonProps {
  label: string;
  icon?: iconNameType;
  iconPrefix?: "fad" | "fal" | "fas" | "far";
  iconPosition?: "beforeLabel" | "afterLabel";
  variance?: "primary" | "icon-button" | "plain-icon-button";
  colorVariance?:
    | "primary"
    | "secondary"
    | "tetiary"
    | "subtle"
    | "outline-primary"
    | "outline-secondary";
  onClick?: (e?: any) => void;
  style?: React.CSSProperties;
  permissions?: string | string[];
  loading?: boolean;
}
export interface IElmButtonState {
  activeText?: string;
}

const ButtonPaddingStyle = css`
  padding-top: 7px;
  padding-right: 12px;
  padding-bottom: 7px;
  padding-left: 12px;
`;
const ButtonContentAlign = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const disabledStyle = css<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")} !important;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")} !important;
  background-color: #cccccc;
  color: ${props => props.theme.colors.buttonDisabled};
`;

const elmButtonLabelClass = "elm-button-label";

const iconButtonClass = "elm-icon-button";

const secondaryButtonClass = "elm-secondary-button";
const tetiaryButtonClass = "elm-tetiary-button";
const subtleButtonClass = "elm-subtle-button";
const outlinePrimaryButtonClass = "elm-outline-primary-button";
const outlineSecondaryButtonClass = "elm-outline-secondary-button";

const BaseButtonStyle = css<{ disabled?: boolean }>`
  ${ButtonPaddingStyle}
  ${ButtonContentAlign}
  ${disabledStyle}
  box-sizing: border-box;
  line-height: 1;
  border-radius: 4px;
  border-color: transparent;
  font-family: ${props => props.theme.font.main};
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors.buttonTextPrimary};
  max-height: 32px;
  // min-width: 90px;
  // max-width: 130px;

  &.${iconButtonClass} {
  min-width: 16px;
  max-width: 30px;
  background-color: ${props => props.theme.colors.elmButtonSubtleHover}
  &.${elmButtonLabelClass} {
  display: none;   
  }
  span {
   margin: 0px;
  }
  }
  padding-bottom: 7px;
  padding-top: 7px;
  margin-left: 7px;
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.buttonDisabled
      : props.theme.colors.lightishBlue};
  :focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px rgba(63, 104, 246, 0.33);
    border-radius: 4px;
  }

  :hover {
    cursor: pointer;
    background-color: ${props =>
      props.disabled
        ? props.theme.colors.buttonDisabled
        : props.theme.colors.elmButtonPrimaryHover};
    };
  }
  .${elmButtonLabelClass} {
    margin-left: 4px;
  }
  &.${secondaryButtonClass} {
   background-color: ${props =>
     props.disabled
       ? props.theme.colors.buttonDisabled
       : props.theme.colors.orangeyRed};
  }
  &.${secondaryButtonClass}:hover {
   background-color: #e81800;
  }
  &.${secondaryButtonClass}:focus {
   background-color: #cc1500;
  }
  &.${tetiaryButtonClass} {
   background-color: #00b107;
  }
  &.${tetiaryButtonClass}:hover {
   background-color: #0a800e;
  }
  &.${tetiaryButtonClass}:focus {
   background-color: #0c640e;
  }
  &.${subtleButtonClass} {
   color: ${props => props.theme.colors.elmButtonSubtleIcon};
   background-color: ${props => props.theme.colors.elmButtonSubtleBackground};
  }
  &.${subtleButtonClass}:hover {
   color: ${props => props.theme.colors.elmButtonSubtleIcon};
   background-color: ${props => props.theme.colors.elmButtonSubtleHover};
  }
  &.${subtleButtonClass}:focus {
   color: ${props => props.theme.colors.elmButtonSubtleIcon};
   background-color: ${props => props.theme.colors.elmButtonSubtleFocus};
   box-shadow: 0px 0px 0px 2px rgba(63, 104, 246, 0.33);
   border-radius: 4px;
  }
  &.${subtleButtonClass}:active {
    color: ${props => props.theme.colors.elmButtonSubtleIcon};
    background-color: ${props => props.theme.colors.elmButtonSubtlePressed};
   }
   &.${subtleButtonClass}:disabled {
    color: ${props => props.theme.colors.elmButtonSubtleIcon};
    background-color: ${props => props.theme.colors.elmButtonSubtleDisabled};
   }
  &.${outlinePrimaryButtonClass} {
   color: ${props => props.theme.colors.lightishBlue};
   background-color: transparent;
   border: 1px solid ${props => props.theme.colors.primary};
  }
  &.${outlinePrimaryButtonClass}:hover {
   color: ${props => props.theme.colors.lightishBlue};
   background-color: ${props => props.theme.colors.hover};
  }
  &.${outlinePrimaryButtonClass}:focus {
   color: #002eb9;
  }
  &.${outlineSecondaryButtonClass} {
   color: ${props => props.theme.colors.textPrimary};
   background-color: ${props =>
     props.theme.colors.elmButtonOutlineSecondaryBackground};
   border: 1px solid ${props => props.theme.colors.textPrimary};
  }
  &.${outlineSecondaryButtonClass}:hover {
   color: ${props => props.theme.colors.textPrimary};
   background-color: ${props =>
     props.theme.colors.elmButtonOutlineSecondaryHover};
  }
  &.${outlineSecondaryButtonClass}:focus {
   color: ${props => props.theme.colors.textPrimary};
   border: 1px solid #302C28;
   background-color: ${props =>
     props.theme.colors.elmButtonOutlineSecondaryFocus};

   box-shadow: 0px 0px 0px 2px ${props =>
     props.theme.colors.highlightBoxShadow};
   border-radius: 4px;
  }
  &.${outlineSecondaryButtonClass}:active {
    color: ${props => props.theme.colors.textPrimary};
    background-color: ${props =>
      props.theme.colors.elmButtonOutlineSecondaryPressed};
   }
   &.${outlineSecondaryButtonClass}:disabled {
    color: ${props =>
      props.theme.colors.elmButtonOutlineSecondaryLabelDisabled};
    background-color: ${props =>
      props.theme.colors.elmButtonOutlineSecondaryDisabled};
   }
`;

const ButtonContent = styled.div`
  ${ButtonContentAlign};
  flex: 1;
  height: inherit;
`;

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 20, color: "#ffffff", marginLeft: "5px" }}
    spin
  />
);

const loader = () => {
  return <Spin indicator={antIcon} />;
};

export const StyledNormalButton = styled.button`
  ${BaseButtonStyle}
`;

const ElmButtonWrapp = (props: IElmButtonProps) => {
  const renderIcon = () => {
    if (
      props.variance === "icon-button" ||
      props.variance === "plain-icon-button"
    ) {
      return <span style={{ display: "flex" }}>{getIconForButton()}</span>;
    }
    return null;
  };

  const getIconForButton = () => {
    if (props.icon) {
      const Icon = getIcon(props.icon, props?.iconPrefix || "far");
      return <Icon width={16} height={16} />;
    }

    return null;
  };
  const handleOnClick = () => {
    if (_.isFunction(props.onClick)) {
      props.onClick();
    }
  };

  return (
    <StyledNormalButton
      onClick={handleOnClick}
      // disabled={this.props.disabled}
      disabled={props.disabled}
      //hidden={requiresPermission}
      style={props.style}
      //className={this.getButtonColorClass()}
      className={clsx({
        [secondaryButtonClass]: props.colorVariance === "secondary",
        [subtleButtonClass]: props.colorVariance === "subtle",
        [iconButtonClass]: props.variance === "icon-button",
        [outlinePrimaryButtonClass]: props.colorVariance === "outline-primary",
        [outlineSecondaryButtonClass]:
          props.colorVariance === "outline-secondary",
        [props.className]: !!props.className
      })}
      id={props.id}
    >
      <ButtonContent>
        {!props?.iconPosition || props?.iconPosition !== "afterLabel"
          ? renderIcon()
          : null}
        <span
          className={clsx({
            [elmButtonLabelClass]:
              props.variance === "plain-icon-button" ? true : false
          })}
        >
          {props.label}
        </span>
        {props?.iconPosition === "afterLabel" ? renderIcon() : null}
      </ButtonContent>
      {props.loading && loader()}
    </StyledNormalButton>
  );
};

const AuthorizationWrapper = (props: IElmButtonProps) => (
  <AuthorizedComponent<IElmButtonProps>
    containerProps={props}
    Component={ElmButtonWrapp}
  />
);

const ElmButton = AuthorizationWrapper;
export { ElmButton };
