/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseActiveSessionsTableQueryVariables = {
  id: string;
};
export type licenseActiveSessionsTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<
    "licenseActiveSessionsTable_activeSessions"
  >;
};
export type licenseActiveSessionsTableQuery = {
  readonly response: licenseActiveSessionsTableQueryResponse;
  readonly variables: licenseActiveSessionsTableQueryVariables;
};

/*
query licenseActiveSessionsTableQuery(
  $id: ID!
) {
  ...licenseActiveSessionsTable_activeSessions_1Bmzm5
}

fragment licenseActiveSessionsTable_activeSessions_1Bmzm5 on Query {
  license(id: $id) {
    activeSessions(first: 6) {
      edges {
        node {
          id
          startTime
          endTime
          user {
            displayName
            id
          }
          instance {
            hardwareIdentifier {
              info
            }
            id
          }
          versionString
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = [
      {
        kind: "Literal",
        name: "first",
        value: 6
      } as any
    ],
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "licenseActiveSessionsTableQuery",
      selections: [
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "licenseActiveSessionsTable_activeSessions"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "licenseActiveSessionsTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            {
              alias: null,
              args: v2 /*: any*/,
              concreteType: "SessionConnection",
              kind: "LinkedField",
              name: "activeSessions",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "SessionEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Session",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "startTime",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "endTime",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: "User",
                          kind: "LinkedField",
                          name: "user",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "displayName",
                              storageKey: null
                            },
                            v3 /*: any*/
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: "Instance",
                          kind: "LinkedField",
                          name: "instance",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "HardwareIdentifier",
                              kind: "LinkedField",
                              name: "hardwareIdentifier",
                              plural: false,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "info",
                                  storageKey: null
                                }
                              ],
                              storageKey: null
                            },
                            v3 /*: any*/
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "versionString",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: "activeSessions(first:6)"
            },
            {
              alias: null,
              args: v2 /*: any*/,
              filters: null,
              handle: "connection",
              key: "licenseActiveSessionsTable_activeSessions",
              kind: "LinkedHandle",
              name: "activeSessions"
            },
            v3 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "88e3e6cfb0705e0d716bb981defaacbf",
      id: null,
      metadata: {},
      name: "licenseActiveSessionsTableQuery",
      operationKind: "query",
      text:
        "query licenseActiveSessionsTableQuery(\n  $id: ID!\n) {\n  ...licenseActiveSessionsTable_activeSessions_1Bmzm5\n}\n\nfragment licenseActiveSessionsTable_activeSessions_1Bmzm5 on Query {\n  license(id: $id) {\n    activeSessions(first: 6) {\n      edges {\n        node {\n          id\n          startTime\n          endTime\n          user {\n            displayName\n            id\n          }\n          instance {\n            hardwareIdentifier {\n              info\n            }\n            id\n          }\n          versionString\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "d19a26e4db403f33381fec6dda7c3d6c";
export default node;
