import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmInput } from "../../../elmInput";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import ImgCrop from "antd-img-crop";
import { getGateway } from "../../../../api";

const title = "Add Company Image";
const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 399px;
  width: 444px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const DialogSubTitle = styled.p`
  ${props => props.theme.fontType.visualizationSubTitle}
`;
const ImageSectionContainer = styled.div`
  width: 110px;
  border-right: 1px solid ${props => props.theme.colors.black};
`;
const SectionHeader = styled.p`
  height: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  margin: 0px;
  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 14px;
`;
export interface ICompanyImageUploadState {
  imageUrl: string;
  image: File | Blob;
  loading: boolean;
  companyLink: string;
}
export class CompanyImageUploadDialog extends DialogBase<
  IModalProps,
  ICompanyImageUploadState
> {
  constructor(props: IModalProps) {
    super(props);
    this.state = {
      imageUrl: "",
      image: null,
      loading: false,
      companyLink: ""
    };
  }
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          height: "319px",
          width: "432px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public getUpdateFunction = (value: keyof ICompanyImageUploadState) => (
    val: string
  ) => {
    const newInfo = {};
    newInfo[value] = val;
    this.setState(newInfo);
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "CompanyImageUploadDialog"
    });
  };
  public dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  public uploadLogo = () => {
    const formData = new FormData();
    const file = this.state.image;
    formData.append("file", file, _.get(file, "name"));
    const gateway = getGateway();
    gateway.request
      .companyUploadImage(formData, { id: this.props.payload.id })
      .then(res => {
        this.props.appDispatch.bladeManagerActions.closeDialog({
          name: "CompanyImageUploadDialog"
        });
      });
  };
  public handleImageChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, (imageUrl, image) =>
        this.setState({
          imageUrl,
          image,
          loading: false,
          companyLink: ""
        })
      );
    }
  };
  public getBase64 = (
    img: File | Blob,
    callback: (val: string, img: File | Blob) => void
  ) => {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      callback(reader.result as string, img)
    );
    reader.readAsDataURL(img);
  };

  public beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  public onUpdateCompanyLink = _.debounce((link: string) => {
    this.setState({ loading: true });
    fetch(`https://logo.clearbit.com/${link}`, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(buffer => {
          const image = new Blob([buffer]);
          const imageUrl = window.URL.createObjectURL(image);
          this.setState({ image, imageUrl, loading: false, companyLink: link });
        });
      })
      .catch(err => {
        console.error(err);
      });
  }, 500);
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{title}</DialogTitle>
        <DialogSubTitle>
          You can choose to upload an image from your computer or paste your
          company's url to select the company's logo from their website.
        </DialogSubTitle>
        <Flex>
          <ImageSectionContainer>
            <SectionHeader>Edit Image</SectionHeader>
            <ImgCrop>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={this.beforeUpload}
                onChange={this.handleImageChange}
              >
                {this.state.imageUrl ? (
                  <img
                    src={this.state.imageUrl}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  <div>
                    {this.state.loading ? (
                      <LoadingOutlined />
                    ) : (
                      <PlusOutlined />
                    )}
                    <div className="ant-upload-text">Upload</div>
                  </div>
                )}
              </Upload>
            </ImgCrop>
          </ImageSectionContainer>
          <Flex style={{ marginLeft: "11.5px", flexDirection: "column" }}>
            <SectionHeader>or, Paste Company URL</SectionHeader>
            <ElmInput
              updatedValue={this.onUpdateCompanyLink}
              placeholder={"http://myhomepage.com/"}
              label={"Company's Homepage URL"}
              value={this.state.companyLink}
            />
          </Flex>
        </Flex>
        <Flex justifyContent={"flex-end"}>
          <Flex marginRight={10}>
            <ElmButton label={"Confirm"} onClick={this.uploadLogo} />
          </Flex>
          <ElmButton
            label={"Discard"}
            colorVariance={"secondary"}
            onClick={this.closeModal}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
