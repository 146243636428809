import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import { Flex } from "reflexbox/styled-components";
import * as React from "react";
import relayEnvironment from "../../../api/relay";
import styled, { useTheme } from "styled-components";
import { QueryRenderer } from "react-relay";
import {
  userFeaturesAndGroupsQuery,
  userFeaturesAndGroupsQueryResponse
} from "./__generated__/userFeaturesAndGroupsQuery.graphql";
import { InfoMessage } from "../../../components/helpers";
import InstanceFeaturesAndGroups from "blades/Instance/instanceFeaturesAndGroups";
import { ElmButton, ElmFilterSelect } from "components";
import { ElmSelectOption } from "components/elmSelect";
import { getGateway } from "api";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { getFaIcon } from "components/icons";
import { EntityRefreshMap } from "components/bladeManager/entityRefreshMap";

export const InfoContainer = styled.div`
  border-radius: 50%;
  background-color: ${props => props.theme.colors.orange};
  margin-right: 12px;
  width: 28px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const InfoIcon = styled(getFaIcon({ iconName: "info", prefix: "far" }))`
  color: ${props => props.theme.colors.white};
  height: 14px;
`;

const SelectLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.theme.colors.warmGrey};
`;

interface IFeaturesAndGroupsProps {
  id: string;
  setRefreshFn: (payload: () => void) => void;
  refreshKey: EntityRefreshMap;
  removeRefreshFn: (entity: EntityRefreshMap) => void;
  refreshAllOpenBlades: () => void;
}
interface IFeaturesAndGroupsState {
  search_term: string;
  selectedOption: string;
  hasInstanceSelected: boolean;
  isEditEnabled: boolean;
  hideInfoMessage: boolean;
}

export class UserFeaturesAndGroups extends React.Component<
  IFeaturesAndGroupsProps & {
    result: userFeaturesAndGroupsQueryResponse;
    retry: () => void;
    theme: any;
  },
  IFeaturesAndGroupsState
> {
  state: IFeaturesAndGroupsState = {
    search_term: "",
    selectedOption: "",
    hasInstanceSelected: false,
    isEditEnabled: false,
    hideInfoMessage: false
  };

  public componentDidUpdate() {
    if (
      this.props.result?.instances?.nodes?.length &&
      !this.state.hasInstanceSelected
    ) {
      this.setState({
        selectedOption: this.props.result.instances.nodes[0].id,
        hasInstanceSelected: true
      });
    }
  }
  public unlockEditFeatures = () => {
    this.setState({ isEditEnabled: true });
  };
  public updateSearchTerm = (search_term: string) => {
    this.setState({ search_term });
  };
  public onToggleChange = (data: {
    name: string;
    value: boolean;
    type: "feature" | "group";
  }) => {
    const gateway = getGateway();
    if (data.type === "feature") {
      gateway.request[data?.value ? "enableUserFeature" : "disableUserFeature"](
        {
          feature: data.name,
          instance_id: this.state.selectedOption
        },
        {
          id: this.props.id
        }
      )
        .then(() => {
          renderSuccessNotification(
            `${data?.value ? "Enabled" : "Disabled"} feature!`
          );
          this.props.refreshAllOpenBlades();
        })
        .catch(e => {
          renderErrorNotification(
            `Failed to ${data?.value ? "enable" : "disable"} feature!`
          );
          console.error(e);
        });
    }
    if (data.type === "group") {
      gateway.request[
        data?.value ? "enableUserFeatureGroup" : "disableUserFeatureGroup"
      ](
        {
          feature_group: data.name,
          instance_id: this.state.selectedOption
        },
        {
          id: this.props.id
        }
      )
        .then(() => {
          renderSuccessNotification(
            `${data?.value ? "Enabled" : "Disabled"} feature group!`
          );
          this.props.refreshAllOpenBlades();
        })
        .catch(e => {
          renderErrorNotification(
            `Failed to ${data?.value ? "enable" : "disable"} feature group!`
          );
          console.error(e);
        });
    }
  };
  public render() {
    const options = this.props.result?.instances?.nodes || [];
    const handleChange = (payload: any) => {
      const target = options.find(o => o.id === payload);
      if (target) {
        this.setState({ selectedOption: target.id });
      }
    };
    const selectedOption = options.find(
      o => o.id === this.state.selectedOption
    );
    return (
      <Flex flexDirection={"column"} flex={1} width={"100%"} height={"100%"}>
        <Flex
          width={"100%"}
          marginBottom={12}
          style={{ gap: 4, flexDirection: "column" }}
        >
          {this.state.isEditEnabled || this.state.hideInfoMessage ? null : (
            <InfoMessage
              style={{ width: "100%", maxWidth: "unset", marginBottom: 12 }}
              description={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InfoContainer>
                    <InfoIcon />
                  </InfoContainer>
                  {`All the changes you are making will be applied to this user and to this installation only. Use unlock button below to enable editing.`}
                </div>
              }
              type="warning"
              action={
                <ElmButton
                  colorVariance="subtle"
                  style={{
                    color: this.props.theme.colors.black,
                    width: 32,
                    paddingLeft: 14
                  }}
                  onClick={() => this.setState({ hideInfoMessage: true })}
                  label=""
                  variance="plain-icon-button"
                  permissions="modify_assets"
                  icon="close"
                  iconPrefix="far"
                />
              }
            />
          )}
          <Flex alignItems={"center"}>
            <Flex
              width={"100%"}
              marginBottom={12}
              style={{ gap: 4, flexDirection: "column" }}
            >
              <SelectLabel>Select install</SelectLabel>
              <ElmFilterSelect
                disabled={!options?.length}
                value={
                  options?.length
                    ? selectedOption?.license?.product?.name
                      ? `${
                          selectedOption.license?.product?.name
                        } - ${selectedOption.hostName || selectedOption.guid}`
                      : selectedOption?.guid || ""
                    : "No active installs..."
                }
                onChange={handleChange}
                style={{ width: "100%" }}
                dropdownMatchSelectWidth={false}
              >
                {_.map(options, node => {
                  return (
                    <ElmSelectOption key={node.id}>
                      <span className="filterOption">
                        {node.license?.product?.name
                          ? `${node.license?.product?.name} - `
                          : ""}
                        {node.hostName || node.guid}
                      </span>
                    </ElmSelectOption>
                  );
                })}
              </ElmFilterSelect>
            </Flex>
            {this.state.isEditEnabled ? null : (
              <ElmButton
                onClick={this.unlockEditFeatures}
                colorVariance="subtle"
                style={{ color: this.props.theme.colors.black, width: 28 }}
                label=""
                variance="plain-icon-button"
                permissions="modify_assets"
                icon="unlock-keyhole"
                iconPrefix="far"
              />
            )}
          </Flex>
        </Flex>
        {this.props.result?.instances?.nodes?.length ? (
          <InstanceFeaturesAndGroups
            id={this.state.selectedOption}
            isEditEnabled={this.state.isEditEnabled}
            disableLockOption={true}
            onChange={this.onToggleChange}
            setRefreshFn={this.props.setRefreshFn}
            refreshKey={this.props.refreshKey}
            removeRefreshFn={this.props.removeRefreshFn}
          />
        ) : null}
      </Flex>
    );
  }
}

const graphqlQuery = graphql`
  query userFeaturesAndGroupsQuery($bladeScope: String!) {
    instances(bladeScope: $bladeScope) {
      nodes {
        id
        guid
        hostName
        active
        license {
          product {
            name
          }
        }
      }
    }
  }
`;

const RenderQuery = (props: any) => {
  const theme = useTheme();
  const renderComponent = (payload: {
    error: Error;
    props: userFeaturesAndGroupsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <UserFeaturesAndGroups {...props} result={payload.props} theme={theme} />
    );
  };
  return (
    <QueryRenderer<userFeaturesAndGroupsQuery>
      environment={relayEnvironment}
      variables={{ bladeScope: props.id }}
      query={graphqlQuery}
      render={renderComponent}
    />
  );
};

export default RenderQuery;
