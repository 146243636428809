/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userInstallsTable_installs = {
  readonly user: {
    readonly installs: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly guid: string;
          readonly license: {
            readonly product: {
              readonly name: string | null;
            } | null;
          } | null;
          readonly hostName: string | null;
          readonly validationEntries: {
            readonly nodes: ReadonlyArray<{
              readonly createdAt: unknown;
              readonly version: string | null;
            } | null> | null;
          } | null;
          readonly active: boolean;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $refType": "userInstallsTable_installs";
};
export type userInstallsTable_installs$data = userInstallsTable_installs;
export type userInstallsTable_installs$key = {
  readonly " $data"?: userInstallsTable_installs$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"userInstallsTable_installs">;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "blade_scope"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    },
    {
      defaultValue: ["hostname"],
      kind: "LocalArgument",
      name: "sortColumn"
    },
    {
      defaultValue: ["ASC"],
      kind: "LocalArgument",
      name: "sortDirection"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: "cursor",
        direction: "forward",
        path: ["user", "installs"]
      }
    ]
  },
  name: "userInstallsTable_installs",
  selections: [
    {
      alias: null,
      args: [
        {
          kind: "Variable",
          name: "id",
          variableName: "id"
        }
      ],
      concreteType: "User",
      kind: "LinkedField",
      name: "user",
      plural: false,
      selections: [
        {
          alias: "installs",
          args: [
            {
              kind: "Variable",
              name: "bladeScope",
              variableName: "blade_scope"
            },
            {
              kind: "Variable",
              name: "search",
              variableName: "search"
            },
            {
              kind: "Variable",
              name: "sortColumn",
              variableName: "sortColumn"
            },
            {
              kind: "Variable",
              name: "sortDirection",
              variableName: "sortDirection"
            }
          ],
          concreteType: "InstanceConnection",
          kind: "LinkedField",
          name: "__userInstallsTable_installs_connection",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "InstanceEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Instance",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "guid",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "License",
                      kind: "LinkedField",
                      name: "license",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "Product",
                          kind: "LinkedField",
                          name: "product",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "name",
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hostName",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: [
                        {
                          kind: "Literal",
                          name: "last",
                          value: 1
                        }
                      ],
                      concreteType: "ValidationEntryConnection",
                      kind: "LinkedField",
                      name: "validationEntries",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "ValidationEntry",
                          kind: "LinkedField",
                          name: "nodes",
                          plural: true,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "createdAt",
                              storageKey: null
                            },
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "version",
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: "validationEntries(last:1)"
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "active",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "1b7793f22c1b4bbd54eaf87e5937f8ec";
export default node;
