import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import { QueryRenderer } from "react-relay";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import { getEnvironment } from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import { IBladeBaseProps } from "../../../components/bladeManager/types";
import { BladeTitle, BladeTemplate, ElmButton } from "../../../components";
import CompaniesTable, {
  companyTableType
} from "Companies/components/CompaniesTable";
import {
  CompaniesQuery,
  CompaniesQueryResponse
} from "./__generated__/CompaniesQuery.graphql";
import BladePrimaryActionsDropdown from "components/bladePrimaryActionsDropdown";
import { TourGuideId } from "blades/Welcome/utils/types";
import { IOnboardingState } from "store/types";
import { notification } from "antd";
import { REFRESH_KEY } from "components/bladeManager/entityRefreshMap";

export interface ICompaniesBladeProps extends IBladeBaseProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    onboardingMode: IOnboardingState;
  };
}

const graphqlQuery = graphql`
  query CompaniesQuery {
    allCompaniesCount: companiesCount
    allCompanies: companies {
      nodes {
        usersCount
      }
    }
  }
`;
export class CompaniesBlade extends React.Component<
  ICompaniesBladeProps & { result: CompaniesQueryResponse; retry: () => void }
> {
  private retryFn: () => void;
  componentDidMount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingPause();
  }
  componentDidUpdate() {
    if (this.checkOnboardingMode()) {
      const target = document.getElementById(TourGuideId.CreateCompany);
      if (this.props.appState.onboardingMode.index === 0 && target) {
        this.props.appDispatch.appActions.onboardingContinue();
      }
    }
  }
  public checkOnboardingMode = () => {
    return this.props.appState.onboardingMode?.status === "active";
  };
  public componentWillUnmount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingEnd();
  }
  public navToCompanyBlade = (
    company: Pick<companyTableType, "id" | "name">
  ) => {
    this.props.openBlade({
      route: "Company",
      routeName: company.name,
      fromBladeIndex: this.props.index,
      routeData: {
        id: company.id,
        name: company?.name
      }
    });
  };

  public renderCompaniesTable = () => {
    return (
      <div
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
          display: "flex",
          flex: 1
        }}
      >
        <CompaniesTable
          onRowClick={this.navToCompanyBlade}
          setRefreshFn={this.props.setEntityRefreshFn(
            REFRESH_KEY.CompaniesTable
          )}
          refreshKey={REFRESH_KEY.CompaniesTable}
          removeRefreshFn={this.props.removeRefreshFn}
          bladeName={"Companies"}
        />
      </div>
    );
  };
  public renderLeftSideHeader = () => {
    return (
      <div style={{ marginLeft: "18px" }}>
        <BladeTitle>Companies</BladeTitle>
      </div>
    );
  };
  public openNewCompanyDialog = () => {
    this.props.openDialog("EditCompanyDialog", {
      editMode: false,
      onConfirm: payload => {
        notification.open({
          type: "success",
          message: "Success",
          description: `Company created !`
        });
        if (this.checkOnboardingMode()) {
          this.props.closeBlade({
            route: "Companies"
          });
          this.props.appDispatch.appActions.onboardingCompleted({
            companyId: _.get(payload, "id")
          });
          this.props.appDispatch.bladeManagerActions.openDialog({
            name: "LicenseCreatedDialog",
            payload: {
              companyId: _.get(payload, "id"),
              companyName: _.get(payload, "name")
            }
          });
        }
        this.props.refreshAllOpenBlades();
        this.props.openBlade({
          route: "Company",
          routeName: "Company",
          routeData: { id: payload?.graphql_id },
          fromBladeIndex: this.props.index
        });
      }
    });
  };

  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: "Companies Analytics",
      routeData: {
        type: "Companies",
        title: "Companies"
      },
      fromBladeIndex: this.props.index
    });
  };

  public renderRightSideHeader = () => {
    return (
      <Flex>
        <ElmButton
          id={TourGuideId.CreateCompany}
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon={"plus"}
          iconPrefix={"fas"}
          label={"Company"}
          permissions="modify_assets"
          onClick={this.openNewCompanyDialog}
        />
        <BladePrimaryActionsDropdown
          analyticsAction={this.navigateToAnalytics}
          export={"companies"}
        />
      </Flex>
    );
  };
  public getUsersCount = () => {
    let count = 0;
    _.each(
      this.props?.result?.allCompanies?.nodes,
      node => (count += node.usersCount)
    );
    return count;
  };
  public render() {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Companies"}
        leftAccentColor={"companyRed"}
        closeBlade={this.props.closeBlade}
        bladeType={"Companies"}
        topAccentColor={"companyRed"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        hideBladeTypeName={true}
        // loading={!this.props.result}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
      >
        <div
          className={`metrics-container ${
            !this.props.result ? "loading" : null
          }`}
        >
          {this.props.result ? (
            <>
              <p className="subheading-value">
                {this.props.result.allCompaniesCount}
                <span className="subheading-label">Companies</span>
              </p>
              <p className="subheading-value">
                <span className="bordered" />
                {this.getUsersCount()}
                <span className="subheading-label">Users</span>
              </p>
            </>
          ) : (
            <p className="subheading-value">
              <span className="subheading-label">Loading stats...</span>
            </p>
          )}
        </div>
        {this.renderCompaniesTable()}
      </BladeTemplate>
    );
  }
}
const relayEnvironment = getEnvironment();
const RenderQuery = (props: ICompaniesBladeProps) => {
  const renderCompaniesInfo = (payload: {
    error: Error;
    props: CompaniesQueryResponse;
    retry: () => void;
  }) => {
    return (
      <CompaniesBlade {...props} result={payload.props} retry={payload.retry} />
    );
  };
  return (
    <QueryRenderer<CompaniesQuery>
      environment={relayEnvironment}
      variables={{}}
      query={graphqlQuery}
      render={renderCompaniesInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {
    onboardingMode: "onboardingModeSelector"
  }
});
