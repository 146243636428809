/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type licenseAttributeQueryVariables = {
  id: string;
};
export type licenseAttributeQueryResponse = {
  readonly license: {
    readonly key: string;
    readonly isTrial: boolean;
    readonly isVendor: boolean;
    readonly product: {
      readonly id: string;
      readonly name: string | null;
    } | null;
    readonly owner: {
      readonly name: string | null;
      readonly id: string;
    } | null;
    readonly type: {
      readonly id: string;
    };
    readonly commercialModel: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly lifetimeValue: number | null;
    readonly totalSeatCount: number | null;
    readonly restriction: {
      readonly requireEmailActivation: boolean | null;
    } | null;
    readonly latestTerm: {
      readonly endDate: unknown | null;
      readonly startDate: unknown | null;
    } | null;
  } | null;
};
export type licenseAttributeQuery = {
  readonly response: licenseAttributeQueryResponse;
  readonly variables: licenseAttributeQueryVariables;
};

/*
query licenseAttributeQuery(
  $id: ID!
) {
  license(id: $id) {
    key
    isTrial
    isVendor
    product {
      id
      name
    }
    owner {
      __typename
      name
      id
    }
    type {
      id
    }
    commercialModel {
      id
      name
    }
    lifetimeValue
    totalSeatCount
    restriction {
      requireEmailActivation
    }
    latestTerm {
      endDate
      startDate
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "key",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "isTrial",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "isVendor",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v7 = [v5 /*: any*/, v6 /*: any*/],
    v8 = {
      alias: null,
      args: null,
      concreteType: "Product",
      kind: "LinkedField",
      name: "product",
      plural: false,
      selections: v7 /*: any*/,
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      concreteType: "LicenseModel",
      kind: "LinkedField",
      name: "type",
      plural: false,
      selections: [v5 /*: any*/],
      storageKey: null
    } as any,
    v10 = {
      alias: null,
      args: null,
      concreteType: "CommercialModel",
      kind: "LinkedField",
      name: "commercialModel",
      plural: false,
      selections: v7 /*: any*/,
      storageKey: null
    } as any,
    v11 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "lifetimeValue",
      storageKey: null
    } as any,
    v12 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "totalSeatCount",
      storageKey: null
    } as any,
    v13 = {
      alias: null,
      args: null,
      concreteType: "Restriction",
      kind: "LinkedField",
      name: "restriction",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "requireEmailActivation",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v14 = {
      alias: null,
      args: null,
      concreteType: "Term",
      kind: "LinkedField",
      name: "latestTerm",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "endDate",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "startDate",
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "licenseAttributeQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: "LinkedField",
              name: "owner",
              plural: false,
              selections: [v6 /*: any*/, v5 /*: any*/],
              storageKey: null
            },
            v9 /*: any*/,
            v10 /*: any*/,
            v11 /*: any*/,
            v12 /*: any*/,
            v13 /*: any*/,
            v14 /*: any*/
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "licenseAttributeQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: "LinkedField",
              name: "owner",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "__typename",
                  storageKey: null
                },
                v6 /*: any*/,
                v5 /*: any*/
              ],
              storageKey: null
            },
            v9 /*: any*/,
            v10 /*: any*/,
            v11 /*: any*/,
            v12 /*: any*/,
            v13 /*: any*/,
            v14 /*: any*/,
            v5 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "444be9dfbf67a66112429ff731c94371",
      id: null,
      metadata: {},
      name: "licenseAttributeQuery",
      operationKind: "query",
      text:
        "query licenseAttributeQuery(\n  $id: ID!\n) {\n  license(id: $id) {\n    key\n    isTrial\n    isVendor\n    product {\n      id\n      name\n    }\n    owner {\n      __typename\n      name\n      id\n    }\n    type {\n      id\n    }\n    commercialModel {\n      id\n      name\n    }\n    lifetimeValue\n    totalSeatCount\n    restriction {\n      requireEmailActivation\n    }\n    latestTerm {\n      endDate\n      startDate\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "f1649018ca3bb63d9cd5f007ed933577";
export default node;
