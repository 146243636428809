import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
// @ts-ignore
import { slideInRight, slideOutRight } from "react-animations";
import styled, { keyframes } from "styled-components";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Tabs } from "antd";

const HeaderText = styled.div<{ isActive: boolean }>`
  height: 22px;
  background-color: ${props =>
    props.isActive ? props.theme.colors.lightishBlue : "white"};
  border: solid 1px ${props => props.theme.colors.lightishBlue};
  min-width: max-content;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  margin-right: 3px;
  color: ${props =>
    !props.isActive ? props.theme.colors.lightishBlue : "white"};

  :first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  :last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.lightishBlue};
    color: white;
  }
  p {
    color: inherit;
    height: 15px;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    margin: 0px;
  }
`;
const HeaderContainer = styled(Flex)`
  flex: 1;
  align-items: center;
  max-height: 25px;
  margin-bottom: 5px;
  margin-top: 5px;
`;
const transitionName = "toggleView";

const slideInAnimation = keyframes`${slideInRight}`;
const slideOutAnimation = keyframes`${slideOutRight}`;

const TransitionContainer = styled.div`
  align-items: baseline;
  flex: 1;
  padding: 5px;
  width: 100%;
  overflow-x: hidden;
`;
const View = styled(Flex)`
  overflow-y: hidden;
  flex: 1;
  height: 100%;
  &.${transitionName}-enter {
    width: 100%;
    animation: 1.5s ${slideInAnimation};
  }
  &.${transitionName}-appear {
    animation: 0.5s ${slideInAnimation};
  }

  &.${transitionName}-leave {
    width: 0px;
    animation: 0.9s ${slideOutAnimation};
  }
`;

const TabsContainer = styled(Tabs)`
  // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //   //color: ${props => props.theme.colors.activeTab};
  // }
  .ant-tabs-ink-bar {
    background: ${props => props.theme.colors.lightBlue};
  }
`;
export type View = {
  text: string;
  Component: React.ComponentElement<any, any>;
  LeftSide: React.ComponentElement<any, any>;
  disabled?: boolean;
};
export type IToggleViewProps = {
  views: View[];
};

export type IToggleViewState = {
  activeView: number;
};

class ToggleView extends React.Component<IToggleViewProps, IToggleViewState> {
  constructor(props: IToggleViewProps) {
    super(props);
    this.state = {
      activeView: 0
    };
  }
  public updateActiveTab = (activeView: number) => {
    if (!this.props.views[activeView].disabled) {
      this.setState({ activeView });
    }
  };
  public renderHeaders = () => {
    const activeView = _.find(
      this.props.views,
      (tab, indx) => indx === this.state.activeView
    );
    const { TabPane } = Tabs;
    return (
      <HeaderContainer>
        {activeView.LeftSide && (
          <div style={{ justifySelf: "flex-start", minWidth: "50px" }}>
            {activeView.LeftSide}
          </div>
        )}
        <TabsContainer
          style={{ marginLeft: "5px" }}
          className="tabsOverride"
          defaultActiveKey={this.props.views[this.state.activeView].text}
          onChange={key => this.updateActiveTab(+key)}
        >
          {_.map(this.props.views, (header, index) => {
            return <TabPane tab={header.text} key={index} />;
          })}
        </TabsContainer>
      </HeaderContainer>
    );
  };
  public renderActiveView = () => {
    const activeView = _.find(
      this.props.views,
      (tab, indx) => indx === this.state.activeView
    );
    return (
      <View className="view-body" key={activeView.text} flex={1}>
        {activeView.Component}
      </View>
    );
  };
  public render() {
    return (
      <Flex
        flexDirection={"column"}
        justifyContent={"baseline"}
        flex={1}
        className={"tabview-container"}
      >
        {this.renderHeaders()}
        <ReactCSSTransitionGroup
          transitionName={transitionName}
          transitionAppear={true}
          transitionEnterTimeout={100}
          transitionLeaveTimeout={100}
          component={TransitionContainer}
        >
          {this.renderActiveView()}
        </ReactCSSTransitionGroup>
      </Flex>
    );
  }
}

export default ToggleView;
