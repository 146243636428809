/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseHistoryTable_terms = {
  readonly license: {
    readonly terms: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly startDate: unknown | null;
          readonly endDate: unknown | null;
          readonly createdAt: unknown;
          readonly isActive: boolean;
          readonly value: number;
        } | null;
      } | null> | null;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    } | null;
  } | null;
  readonly " $refType": "licenseHistoryTable_terms";
};
export type licenseHistoryTable_terms$data = licenseHistoryTable_terms;
export type licenseHistoryTable_terms$key = {
  readonly " $data"?: licenseHistoryTable_terms$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"licenseHistoryTable_terms">;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: 25,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: "cursor",
        direction: "forward",
        path: ["license", "terms"]
      }
    ]
  },
  name: "licenseHistoryTable_terms",
  selections: [
    {
      alias: null,
      args: [
        {
          kind: "Variable",
          name: "id",
          variableName: "id"
        }
      ],
      concreteType: "License",
      kind: "LinkedField",
      name: "license",
      plural: false,
      selections: [
        {
          alias: "terms",
          args: null,
          concreteType: "TermConnection",
          kind: "LinkedField",
          name: "__LicenseHistoryTable_terms_connection",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "TermEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Term",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "startDate",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endDate",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "createdAt",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "isActive",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "value",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "42402527b8d2a1ce337bfd024c024e23";
export default node;
