/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type productReleaseChannelsTable_releaseChannels = {
  readonly releaseConfiguration: {
    readonly releaseChannels: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly currentRelease: {
            readonly tag: string;
          } | null;
          readonly licensesCount: number;
          readonly releaseConfiguration: {
            readonly fallback: {
              readonly id: string;
              readonly name: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    } | null;
  } | null;
  readonly " $refType": "productReleaseChannelsTable_releaseChannels";
};
export type productReleaseChannelsTable_releaseChannels$data = productReleaseChannelsTable_releaseChannels;
export type productReleaseChannelsTable_releaseChannels$key = {
  readonly " $data"?:
    | productReleaseChannelsTable_releaseChannels$data
    | undefined;
  readonly " $fragmentRefs": FragmentRefs<
    "productReleaseChannelsTable_releaseChannels"
  >;
};

const node: ReaderFragment = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    argumentDefinitions: [
      {
        defaultValue: 6,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      },
      {
        defaultValue: ["release_channels.name"],
        kind: "LocalArgument",
        name: "sortColumn"
      },
      {
        defaultValue: ["ASC"],
        kind: "LocalArgument",
        name: "sortDirection"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["releaseConfiguration", "releaseChannels"]
        }
      ]
    },
    name: "productReleaseChannelsTable_releaseChannels",
    selections: [
      {
        alias: null,
        args: [
          {
            kind: "Variable",
            name: "id",
            variableName: "id"
          }
        ],
        concreteType: "ReleaseConfiguration",
        kind: "LinkedField",
        name: "releaseConfiguration",
        plural: false,
        selections: [
          {
            alias: "releaseChannels",
            args: [
              {
                kind: "Variable",
                name: "search",
                variableName: "search"
              },
              {
                kind: "Variable",
                name: "sortColumn",
                variableName: "sortColumn"
              },
              {
                kind: "Variable",
                name: "sortDirection",
                variableName: "sortDirection"
              }
            ],
            concreteType: "ReleaseChannelConnection",
            kind: "LinkedField",
            name: "__productReleaseChannelsTable_releaseChannels_connection",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "ReleaseChannelEdge",
                kind: "LinkedField",
                name: "edges",
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: "ReleaseChannel",
                    kind: "LinkedField",
                    name: "node",
                    plural: false,
                    selections: [
                      v0 /*: any*/,
                      v1 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        concreteType: "Release",
                        kind: "LinkedField",
                        name: "currentRelease",
                        plural: false,
                        selections: [
                          {
                            alias: null,
                            args: null,
                            kind: "ScalarField",
                            name: "tag",
                            storageKey: null
                          }
                        ],
                        storageKey: null
                      },
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "licensesCount",
                        storageKey: null
                      },
                      {
                        alias: null,
                        args: null,
                        concreteType: "ReleaseConfiguration",
                        kind: "LinkedField",
                        name: "releaseConfiguration",
                        plural: false,
                        selections: [
                          {
                            alias: null,
                            args: null,
                            concreteType: "ReleaseChannel",
                            kind: "LinkedField",
                            name: "fallback",
                            plural: false,
                            selections: [v0 /*: any*/, v1 /*: any*/],
                            storageKey: null
                          }
                        ],
                        storageKey: null
                      },
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "__typename",
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "cursor",
                    storageKey: null
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: "PageInfo",
                kind: "LinkedField",
                name: "pageInfo",
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "hasNextPage",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "endCursor",
                    storageKey: null
                  }
                ],
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "3adf32aefe8893d486f267bfb28965c9";
export default node;
