/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type feedbackListQueryVariables = {
  id: string;
};
export type feedbackListQueryResponse = {
  readonly user: {
    readonly feedback: ReadonlyArray<{
      readonly message: string | null;
      readonly product: string;
      readonly type: string;
      readonly time: unknown | null;
      readonly version: string | null;
    }> | null;
  } | null;
};
export type feedbackListQuery = {
  readonly response: feedbackListQueryResponse;
  readonly variables: feedbackListQueryVariables;
};

/*
query feedbackListQuery(
  $id: ID!
) {
  user(id: $id) {
    feedback {
      message
      product
      type
      time
      version
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      concreteType: "Feedback",
      kind: "LinkedField",
      name: "feedback",
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "message",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "product",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "type",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "time",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "version",
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "feedbackListQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [v2 /*: any*/],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "feedbackListQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "64c89ca71204ab5e8032c687d7af6f6d",
      id: null,
      metadata: {},
      name: "feedbackListQuery",
      operationKind: "query",
      text:
        "query feedbackListQuery(\n  $id: ID!\n) {\n  user(id: $id) {\n    feedback {\n      message\n      product\n      type\n      time\n      version\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "e99dfedf160db931840b431a184c976b";
export default node;
