import * as _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import ReactImageFallback from "react-image-fallback";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import relayEnvironment from "../../../api/relay";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenLicenseComponentBlade
} from "../../../components/bladeManager/types";
import {
  BladeSubTitle,
  BladeTemplate,
  BladeTitle,
  ElmButton,
  ToggleView
} from "../../../components";
import {
  LicensePlaceHolderLogo,
  isComponentIncludedInVersion
} from "../../../utils";
import {
  LicenseComponentQuery,
  LicenseComponentQueryResponse
} from "./__generated__/LicenseComponentQuery.graphql";
import { userTableType } from "../../../Users/components/usersTable";
import AttributesBox, {
  IAttributValuePair
} from "../../../components/attributesBox";

const graphqlQuery = graphql`
  query LicenseComponentQuery($id: ID!) {
    component(id: $id) {
      name
      componentType
      componentGroup {
        name
      }
      # componentRequirements {
      #   nodes {
      #     productVersion {
      #       versionString
      #       ordinal
      #     }
      #     tokenCount
      #     freeTrialExpiration
      #     component {
      #       componentRequirementsCount
      #     }
      #     id
      #     requirementType
      #   }
      # }
      product {
        name
        licenses {
          nodes {
            id
            owner {
              name
            }
          }
        }
      }
    }
  }
`;
const TableTitle = styled.p`
  height: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.colors.pureBlack};
  margin: 0px;
  margin-bottom: 10px;
`;
export interface ILicenseComponentBladeProps
  extends IBladeBaseProps<IOpenLicenseComponentBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}
const TableHeader = styled.p`
  margin: 0px;
`;
const TableHeaderContainer = styled.div`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;
// type licenseInfo = LicenseComponentQueryResponse['license'];
interface ILicenseComponentBladeState {
  mode: "edit" | "normal";
}
export class LicenseComponentBlade extends React.Component<
  ILicenseComponentBladeProps & {
    result: LicenseComponentQueryResponse;
    retry: () => void;
  },
  ILicenseComponentBladeState
> {
  public state: ILicenseComponentBladeState = {
    mode: "normal"
  };
  public close = () => {
    this.props.closeBlade({ fromBladeIndex: this.props.index });
  };
  public navToUserBlade = (user: userTableType) => {
    this.props.openBlade({
      route: "User",
      routeName: `${user.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: user.id,
        name: user.name
        // productId: ''
      }
    });
  };
  public navToProductBlade = (product: { id: string; name: string }) => {
    this.props.openBlade({
      route: "Product",
      routeName: `${product.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: product.id
        // productId: ''
      }
    });
  };
  public renderAttributesBox = () => {
    const items = [
      {
        label: "Group",
        value: _.get(this.props.result, "component.componentGroup.name")
      },
      {
        label: "License Model",
        value: _.get(this.props.result, "component.componentType")
      }
    ] as IAttributValuePair[];
    return <AttributesBox items={items} />;
  };
  public renderRequirements = () => {
    let nodes = _.get(
      this.props.result,
      "component.componentRequirements.nodes"
    ) as LicenseComponentQueryResponse["component"]["componentRequirements"]["nodes"];
    nodes = nodes || [];
    nodes = _.sortBy(nodes, n => n.productVersion.ordinal);
    const versions = _.map(nodes, node => {
      return (
        <Flex
          style={{ height: "29px", paddingTop: "6px", marginBottom: "5px" }}
        >
          <div style={{ marginRight: "150px", width: "80px" }}>
            {node.productVersion.versionString}
          </div>
          <div style={{ marginRight: "150px", width: "80px" }}>
            {isComponentIncludedInVersion(node.requirementType)
              ? "True"
              : "False"}
          </div>
          <div>{node.tokenCount}</div>
        </Flex>
      );
    });
    return (
      <Flex style={{ flexDirection: "column" }}>
        <Flex>
          <TableTitle>Usage Requirements</TableTitle>
        </Flex>
        <TableHeaderContainer>
          {_.map(["Version", "Included", "Tokens", "Free Trial"], header => (
            <TableHeader>{header}</TableHeader>
          ))}
        </TableHeaderContainer>
        <Flex style={{ flexDirection: "column" }}>{versions}</Flex>
      </Flex>
    );
  };
  public renderBaseRequirements = () => {
    const versions = _.map(
      _.get(this.props.result, "component.componentRequirements.nodes"),
      node => {
        return (
          <Flex
            style={{ height: "29px", paddingTop: "6px", marginBottom: "5px" }}
          >
            <div style={{ marginRight: "150px", width: "80px" }}>
              {node.productVersion.versionString}
            </div>
            <div style={{ marginRight: "150px", width: "80px" }}>
              {isComponentIncludedInVersion(node.requirementType)
                ? "True"
                : "False"}
            </div>
            <div>{node.tokenCount}</div>
          </Flex>
        );
      }
    );
    return (
      <Flex style={{ flexDirection: "column", marginTop: "35px" }}>
        <Flex>
          <TableTitle>Base Usage Requirements</TableTitle>
        </Flex>
        <TableHeaderContainer>
          {_.map(["Version", "Included", "Tokens", "Free Trial"], header => (
            <TableHeader>{header}</TableHeader>
          ))}
        </TableHeaderContainer>
        <Flex style={{ flexDirection: "column" }}>{versions}</Flex>
      </Flex>
    );
  };
  public renderLicenseComponentInfo = () => {
    const res = this.props.result;
    // const license = res.license;
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"License"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        loading={!res}
        closeBlade={this.props.closeBlade}
        bladeType={"LicenseComponent"}
      >
        <Flex style={{ flex: 1, maxHeight: "75px", marginBottom: "70px" }}>
          {this.renderAttributesBox()}
        </Flex>
        {this.renderRequirements()}
        {this.renderBaseRequirements()}
      </BladeTemplate>
    );
  };
  public getLicenseOwner = () => {
    const license = this.getLicenseForComponent();
    if (license) {
      return license.owner.name;
    }
    return "";
  };

  public renderLeftSideHeader = () => {
    return (
      <Flex>
        <div
          style={{
            height: "52px",
            width: "55px",
            marginRight: "10px"
          }}
        >
          <ReactImageFallback
            src={null}
            fallbackImage={LicensePlaceHolderLogo}
            initialImage={LicensePlaceHolderLogo}
            alt={""}
            height={"100%"}
            width={"100%"}
          />
        </div>
        <Flex flexDirection="column">
          <BladeTitle>{this.props.routeData.name}</BladeTitle>
          <BladeSubTitle>{this.getLicenseOwner()}</BladeSubTitle>
        </Flex>
      </Flex>
    );
  };

  public enterEditMode = () => {
    this.setState({ mode: "edit" });
  };
  public enterNormalMode = () => {
    this.setState({ mode: "normal" });
  };
  public getLicenseForComponent = () => {
    if (this.props.routeData.licenseId && this.props.result) {
      return _.find(
        this.props.result.component.product.licenses.nodes,
        n => n.id === this.props.routeData.licenseId
      );
    }
    return null;
  };
  public renderRightSideHeader = (): any => {
    return null;
  };

  public render() {
    return this.renderLicenseComponentInfo();
  }
}
const RenderQuery = (props: ILicenseComponentBladeProps) => {
  const renderLicenseComponentInfo = (payload: {
    error: Error;
    props: LicenseComponentQueryResponse;
    retry: () => void;
  }) => {
    return (
      <LicenseComponentBlade
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    );
  };
  return (
    <QueryRenderer<LicenseComponentQuery>
      environment={relayEnvironment}
      variables={{ id: props.routeData.id }}
      query={graphqlQuery}
      render={renderLicenseComponentInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
