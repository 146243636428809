import { Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox";
import { tableGenerator } from "../../../utils";
import { featureGroupsTable_featureGroups } from "./__generated__/featureGroupsTable_featureGroups.graphql";

export type IFeatureGroupsTableProps = {
  featureGroups: featureGroupsTable_featureGroups;
};

export const renderMultipleLabels = (labels: string[]) => {
  const extraCount = labels.length - 2;
  const labelNames = _.map(_.slice(labels, 0, 2), (item: string) => item).join(
    ", "
  );

  const restLabels = _.map(_.slice(labels, 2), (item: string) => item).join(
    ", "
  );

  return (
    <Flex style={{ alignItems: "center" }}>
      {labelNames}
      {extraCount > 0 ? (
        <Tooltip placement="top" title={restLabels}>
          {` + ${extraCount} more`}
        </Tooltip>
      ) : null}
    </Flex>
  );
};

export const FeatureGroupsTable = tableGenerator<IFeatureGroupsTableProps>({
  columns: [
    {
      Header: "Group",
      accessor: "name"
    },
    {
      Header: "Features",
      accessor: "components",
      cellRenderer: payload => renderMultipleLabels(payload.cellData)
    },
    {
      Header: "Actions",
      accessor: "",
      cellRenderer: () => "",
      width: 0.1
    }
  ],
  connectionQuery: graphql`
    query featureGroupsTablePaginationQuery(
      $count: Int!
      $cursor: String
      $bladeScope: String!
    ) {
      ...featureGroupsTable_featureGroups
        @arguments(count: $count, cursor: $cursor, bladeScope: $bladeScope)
    }
  `,
  dataKey: "featureGroups.featureGroups",
  getNodesTransform: (props: IFeatureGroupsTableProps) => {
    const edges = props.featureGroups.featureGroups.edges;
    let featureGroup: { id: string; name: string; components: string[] };
    const transform = _.reduce(
      edges,
      (all, edge) => {
        featureGroup = {
          id: edge.node.id,
          name: edge.node.name,
          components: []
        };
        all.push(featureGroup);
        _.each(edge.node.features.nodes, feature => {
          featureGroup.components.push(feature.name);
        });
        return all;
      },
      []
    );
    return transform;
  },
  fragmentSpec: {
    featureGroups: graphql`
      fragment featureGroupsTable_featureGroups on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 6 }
          cursor: { type: "String" }
          bladeScope: { type: "String!" }
        ) {
        featureGroups(first: $count, after: $cursor, bladeScope: $bladeScope)
          @connection(key: "featureGroupsTable_featureGroups") {
          edges {
            node {
              name
              id
              features {
                nodes {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `
  }
});
export default FeatureGroupsTable;
