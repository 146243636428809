import {
  MaximumUsageReportDataType,
  REPORT_TYPE,
  ReportType,
  TimeRangeOption
} from "./types";

export const TimeRange: Record<string, TimeRangeOption> = {
  last24: {
    key: "last24",
    label: "Last 24h",
    value: 1
  },
  days7: {
    key: "days7",
    label: "Last 7 days",
    value: 7
  },
  month1: {
    key: "month1",
    label: "Last 30 days",
    value: 30
  },
  months3: {
    key: "months3",
    label: "Last 3 months",
    value: 90
  },
  year1: {
    key: "year1",
    label: "Last 12 months",
    value: 365
  },
  year2: {
    key: "year2",
    label: "Last 2 years",
    value: 730
  }
} as const;

export const ColumnsConfig: Record<
  ReportType,
  {
    type: ReportType;
    columns: readonly string[];
    additionalColumns?: Record<MaximumUsageReportDataType, readonly string[]>;
  }
> = {
  [REPORT_TYPE.ComponentUsage]: {
    type: REPORT_TYPE.ComponentUsage,
    columns: ["num_checkouts", "last_checkout", "usage_minutes"]
  },
  [REPORT_TYPE.SessionUsage]: {
    type: REPORT_TYPE.SessionUsage,
    columns: ["num_sessions", "usage_minutes", "last_session", "latest_version"]
  },
  [REPORT_TYPE.ConcurrentSession]: {
    type: REPORT_TYPE.ConcurrentSession,
    columns: ["num_sessions", "num_failures", "license_key"]
  },
  [REPORT_TYPE.MaximumUsageReport]: {
    type: REPORT_TYPE.MaximumUsageReport,
    columns: ["license_key"],
    additionalColumns: {
      rawData: ["session_count"],
      consolidated: ["max_session_count", "frequency"]
    }
  }
} as const;
