import React from "react";
import * as faIcons from "react-icons/fa";
import * as ioIcons from "react-icons/io";
import * as mdIcons from "react-icons/md";
import * as aiIcons from "react-icons/ai";
import * as fcIcons from "react-icons/fc";
import * as fiIcons from "react-icons/fi";
import styled from "styled-components";
import {
  findIconDefinition,
  IconLookup,
  library
} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBalanceScale,
  faBoxesAlt,
  faBookAlt,
  faBuilding,
  faTachometerFastest,
  faUsers,
  faKey,
  faChartPieAlt,
  faSortUp,
  faSortDown,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faTimes,
  faCalendarDay,
  faCalendarAlt,
  faExclamationCircle,
  faUserCog,
  faUsersMedical,
  faPlusSquare,
  faTv,
  faMagnifyingGlass,
  faCircleXmark,
  faCircleInfo,
  faCopy
} from "@fortawesome/pro-light-svg-icons";
import {
  faSortUp as fdSortUp,
  faSortDown as fdSortDown
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faBarsFilter,
  faXmark,
  faArrowDownToBracket,
  faMessageLines,
  faMessage,
  faCircleMinus,
  faLightbulb,
  faInfo,
  faQuestionCircle,
  faTrashCan,
  faBriefcase,
  faTag,
  faChartMixed,
  faPen as faPenRegular,
  faDownload,
  faExternalLinkAlt,
  faLinkSimple,
  faLinkSimpleSlash,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faFileChartPie,
  faTriangleExclamation as faTriangleExclamationRegular,
  faCheck,
  faFloppyDisk as faSave,
  faArrowUpFromBracket as faUpload,
  faGrid4,
  faGrid2,
  faLockKeyhole,
  faUnlockKeyhole,
  faEnvelopeOpenText
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCaretRight,
  faCaretUp,
  faCaretDown,
  faCoffee,
  faChevronDown,
  faChevronUp,
  faArrowRightFromBracket,
  faChartPie,
  faCirclePlus,
  faRepeat,
  faRedo,
  faTrash,
  faPen,
  faPlus,
  faEllipsis,
  faRotateLeft,
  faCircleCheck,
  faTriangleExclamation,
  faDoNotEnter,
  faBan,
  faBell
} from "@fortawesome/pro-solid-svg-icons";
import { ElmAsc, ElmDsc } from "./elmIcons";
library.add(
  faBalanceScale,
  faBoxesAlt,
  faBookAlt,
  faBuilding,
  faCaretRight,
  faCaretDown,
  faCaretUp,
  faKey,
  faTachometerFastest,
  faUsers,
  faChartPie,
  faChartPieAlt,
  faChartMixed,
  faBriefcase,
  faTag,
  faSortUp,
  faSortDown,
  fdSortUp,
  fdSortDown,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faTimes,
  faRedo,
  faRepeat,
  faCalendarDay,
  faCalendarAlt,
  faExclamationCircle,
  faCoffee,
  faPlus,
  faCheck,
  faPen,
  faPenRegular,
  faUserCog,
  faUsersMedical,
  faPlusSquare,
  faTv,
  faLinkSimple,
  faLinkSimpleSlash,
  faExternalLinkAlt,
  faEllipsis,
  faDownload,
  faChevronUp,
  faChevronDown,
  faUnlockKeyhole,
  faLockKeyhole,
  faArrowRightFromBracket,
  faXmark,
  faBarsFilter,
  faArrowDownToBracket,
  faCirclePlus,
  faTrash,
  faTrashCan,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faMagnifyingGlass,
  faRotateLeft,
  faCircleCheck,
  faCircleXmark,
  faCircleInfo,
  faTriangleExclamation,
  faFileChartPie,
  faEnvelopeOpenText,
  faMessageLines,
  faMessage,
  faDoNotEnter,
  faCopy,
  faBan,
  faCircleMinus,
  faBell,
  faLightbulb,
  faInfo,
  faQuestionCircle,
  faTriangleExclamationRegular,
  faSave,
  faUpload,
  faGrid4,
  faGrid2
);
export const getFaIcon = (iconName: IconLookup) => (props: any) => {
  return (
    <FontAwesomeIcon icon={getFaIconDefinition(iconName)} {...(props || {})} />
  );
};

export const getFaIconDefinition = (iconName: IconLookup) => {
  return findIconDefinition(iconName);
};
const ArrowDown = styled(getFaIcon({ prefix: "fas", iconName: "caret-down" }))<{
  active?: boolean;
}>`
  height: 5px;
  width: 10px;

  color: ${props =>
    props.active
      ? props.theme.colors.whiteTwo
      : props.theme.colors.lightishBlue};
`;
const ArrowUp = styled(getFaIcon({ prefix: "fas", iconName: "caret-up" }))<{
  active?: boolean;
}>`
  margin-bottom: 2px;

  height: 5px;
  width: 10px;

  color: ${props =>
    props.active
      ? props.theme.colors.whiteTwo
      : props.theme.colors.lightishBlue};
`;
export const elmIcons = {
  ElmAsc,
  ElmDsc,
  elmArrowUp: () => {
    return (
      <div
        style={{
          width: "12px",
          height: "12px"
        }}
      >
        <ArrowUp active={true} />
        <ArrowDown />
      </div>
    );
  },
  elmArrowDown: () => {
    return (
      <div
        style={{
          width: "12px",
          height: "12px"
        }}
      >
        <ArrowUp />
        <ArrowDown active={true} />
      </div>
    );
  }
};
export type iconNameType =
  | keyof typeof mdIcons
  | keyof typeof faIcons
  | keyof typeof ioIcons
  | keyof typeof aiIcons
  | keyof typeof fcIcons
  | keyof typeof fiIcons
  | keyof typeof elmIcons
  | IconLookup["iconName"];
// see https://react-icons.netlify.com/#/icons/md for iconNames
export const getIcon = (
  iconName: iconNameType,
  prefix?: "fad" | "fal" | "fas" | "far"
) => {
  if (prefix) {
    const faIcon = getFaIcon({
      prefix: prefix,
      iconName: iconName as IconLookup["iconName"]
    });
    if (faIcon) return faIcon;
  }
  return (
    mdIcons[iconName] ||
    faIcons[iconName] ||
    ioIcons[iconName] ||
    aiIcons[iconName] ||
    elmIcons[iconName] ||
    fcIcons[iconName] ||
    fiIcons[iconName] ||
    getFaIcon({
      prefix: prefix || "fal",
      iconName: iconName as IconLookup["iconName"]
    })
  );
};
export { faIcons, ioIcons, mdIcons };
