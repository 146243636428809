/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type companyUsersTable_users = {
  readonly usersCount: number;
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly email: string | null;
        readonly createdAt: unknown;
        readonly instancesCount: number;
        readonly active: boolean;
        readonly products: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly iconUrl: string | null;
            readonly name: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $refType": "companyUsersTable_users";
};
export type companyUsersTable_users$data = companyUsersTable_users;
export type companyUsersTable_users$key = {
  readonly " $data"?: companyUsersTable_users$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"companyUsersTable_users">;
};

const node: ReaderFragment = (function() {
  var v0 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    v1 = [
      v0 /*: any*/,
      {
        kind: "Variable",
        name: "filterColumn",
        variableName: "filterColumn"
      } as any,
      {
        kind: "Variable",
        name: "filterOp",
        variableName: "filterOp"
      } as any,
      {
        kind: "Variable",
        name: "filterValues",
        variableName: "filterValues"
      } as any,
      {
        kind: "Variable",
        name: "search",
        variableName: "search"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "blade_scope"
      },
      {
        defaultValue: 25,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: ["users.active"],
        kind: "LocalArgument",
        name: "filterColumn"
      },
      {
        defaultValue: [["="]],
        kind: "LocalArgument",
        name: "filterOp"
      },
      {
        defaultValue: [["true"]],
        kind: "LocalArgument",
        name: "filterValues"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["users"]
        }
      ]
    },
    name: "companyUsersTable_users",
    selections: [
      {
        alias: null,
        args: v1 /*: any*/,
        kind: "ScalarField",
        name: "usersCount",
        storageKey: null
      },
      {
        alias: "users",
        args: v1 /*: any*/,
        concreteType: "UserConnection",
        kind: "LinkedField",
        name: "__companyUsersTable_users_connection",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "UserEdge",
            kind: "LinkedField",
            name: "edges",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "User",
                kind: "LinkedField",
                name: "node",
                plural: false,
                selections: [
                  v2 /*: any*/,
                  v3 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "email",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "createdAt",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: [v0 /*: any*/],
                    kind: "ScalarField",
                    name: "instancesCount",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "active",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: "ProductConnection",
                    kind: "LinkedField",
                    name: "products",
                    plural: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        concreteType: "Product",
                        kind: "LinkedField",
                        name: "nodes",
                        plural: true,
                        selections: [
                          v2 /*: any*/,
                          {
                            alias: null,
                            args: null,
                            kind: "ScalarField",
                            name: "iconUrl",
                            storageKey: null
                          },
                          v3 /*: any*/
                        ],
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "__typename",
                    storageKey: null
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "cursor",
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: "PageInfo",
            kind: "LinkedField",
            name: "pageInfo",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "hasNextPage",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "endCursor",
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "6ed339c1bae2fcd78334c34cf62572ee";
export default node;
