/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AddReportQueryVariables = {
  id: string;
  skip: boolean;
};
export type AddReportQueryResponse = {
  readonly report?:
    | {
        readonly id: string;
        readonly name: string;
        readonly reportType: number;
        readonly options: unknown | null;
        readonly isPrivate: boolean;
      }
    | null
    | undefined;
};
export type AddReportQuery = {
  readonly response: AddReportQueryResponse;
  readonly variables: AddReportQueryVariables;
};

/*
query AddReportQuery(
  $id: ID!
  $skip: Boolean!
) {
  report(id: $id) @skip(if: $skip) {
    id
    name
    reportType
    options
    isPrivate
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "skip"
      } as any
    ],
    v1 = [
      {
        condition: "skip",
        kind: "Condition",
        passingValue: false,
        selections: [
          {
            alias: null,
            args: [
              {
                kind: "Variable",
                name: "id",
                variableName: "id"
              }
            ],
            concreteType: "Report",
            kind: "LinkedField",
            name: "report",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "id",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "name",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "reportType",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "options",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "isPrivate",
                storageKey: null
              }
            ],
            storageKey: null
          }
        ]
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "AddReportQuery",
      selections: v1 /*: any*/,
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "AddReportQuery",
      selections: v1 /*: any*/
    },
    params: {
      cacheID: "e9a78852049f1799c8f66d9549044a9e",
      id: null,
      metadata: {},
      name: "AddReportQuery",
      operationKind: "query",
      text:
        "query AddReportQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  report(id: $id) @skip(if: $skip) {\n    id\n    name\n    reportType\n    options\n    isPrivate\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "74633f76e410bd5d16d73aa52f5b1187";
export default node;
