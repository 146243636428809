/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type usersTableQueryVariables = {};
export type usersTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<
    "usersTable_users" | "usersTable_products" | "usersTable_companies"
  >;
};
export type usersTableQuery = {
  readonly response: usersTableQueryResponse;
  readonly variables: usersTableQueryVariables;
};

/*
query usersTableQuery {
  ...usersTable_users
  ...usersTable_products
  ...usersTable_companies
}

fragment usersTable_companies on Query {
  usersTableCompaniesList: companies {
    edges {
      node {
        name
        id
      }
    }
  }
}

fragment usersTable_products on Query {
  usersTableProductsList: products {
    edges {
      node {
        name
        id
        iconUrl
      }
    }
  }
}

fragment usersTable_users on Query {
  usersTableCount: usersCount(filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]])
  usersTableUsers: users(first: 25, filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]]) {
    edges {
      node {
        id
        name
        email
        createdAt
        instancesCount
        hasActiveSession
        lastActiveSession {
          endTime
          id
        }
        active
        owner {
          __typename
          name
          id
        }
        products {
          nodes {
            id
            iconUrl
            name
          }
        }
        productUsers {
          nodes {
            product {
              id
              name
            }
            enabled
            user {
              id
            }
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      kind: "Literal",
      name: "filterColumn",
      value: ["users.active"]
    } as any,
    v1 = {
      kind: "Literal",
      name: "filterOp",
      value: [["="]]
    } as any,
    v2 = {
      kind: "Literal",
      name: "filterValues",
      value: [["true"]]
    } as any,
    v3 = [
      v0 /*: any*/,
      v1 /*: any*/,
      v2 /*: any*/,
      {
        kind: "Literal",
        name: "first",
        value: 25
      } as any
    ],
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "__typename",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "iconUrl",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [],
      kind: "Fragment",
      metadata: null,
      name: "usersTableQuery",
      selections: [
        {
          args: null,
          kind: "FragmentSpread",
          name: "usersTable_users"
        },
        {
          args: null,
          kind: "FragmentSpread",
          name: "usersTable_products"
        },
        {
          args: null,
          kind: "FragmentSpread",
          name: "usersTable_companies"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [],
      kind: "Operation",
      name: "usersTableQuery",
      selections: [
        {
          alias: "usersTableCount",
          args: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
          kind: "ScalarField",
          name: "usersCount",
          storageKey:
            'usersCount(filterColumn:["users.active"],filterOp:[["="]],filterValues:[["true"]])'
        },
        {
          alias: "usersTableUsers",
          args: v3 /*: any*/,
          concreteType: "UserConnection",
          kind: "LinkedField",
          name: "users",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "UserEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "User",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    v4 /*: any*/,
                    v5 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "email",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "createdAt",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "instancesCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasActiveSession",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "Session",
                      kind: "LinkedField",
                      name: "lastActiveSession",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "endTime",
                          storageKey: null
                        },
                        v4 /*: any*/
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "active",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: "LinkedField",
                      name: "owner",
                      plural: false,
                      selections: [v6 /*: any*/, v5 /*: any*/, v4 /*: any*/],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "ProductConnection",
                      kind: "LinkedField",
                      name: "products",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "Product",
                          kind: "LinkedField",
                          name: "nodes",
                          plural: true,
                          selections: [
                            v4 /*: any*/,
                            v7 /*: any*/,
                            v5 /*: any*/
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "ProductUserConnection",
                      kind: "LinkedField",
                      name: "productUsers",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "ProductUser",
                          kind: "LinkedField",
                          name: "nodes",
                          plural: true,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "Product",
                              kind: "LinkedField",
                              name: "product",
                              plural: false,
                              selections: [v4 /*: any*/, v5 /*: any*/],
                              storageKey: null
                            },
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "enabled",
                              storageKey: null
                            },
                            {
                              alias: null,
                              args: null,
                              concreteType: "User",
                              kind: "LinkedField",
                              name: "user",
                              plural: false,
                              selections: [v4 /*: any*/],
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    v6 /*: any*/
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey:
            'users(filterColumn:["users.active"],filterOp:[["="]],filterValues:[["true"]],first:25)'
        },
        {
          alias: "usersTableUsers",
          args: v3 /*: any*/,
          filters: [
            "bladeScope",
            "search",
            "filterColumn",
            "filterOp",
            "filterValues"
          ],
          handle: "connection",
          key: "UsersTable_usersTableUsers",
          kind: "LinkedHandle",
          name: "users"
        },
        {
          alias: "usersTableProductsList",
          args: null,
          concreteType: "ProductConnection",
          kind: "LinkedField",
          name: "products",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ProductEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [v5 /*: any*/, v4 /*: any*/, v7 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: "usersTableCompaniesList",
          args: null,
          concreteType: "CompanyConnection",
          kind: "LinkedField",
          name: "companies",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "CompanyEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Company",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [v5 /*: any*/, v4 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "ba529984123bbd2c9ace3494cae97f30",
      id: null,
      metadata: {},
      name: "usersTableQuery",
      operationKind: "query",
      text:
        'query usersTableQuery {\n  ...usersTable_users\n  ...usersTable_products\n  ...usersTable_companies\n}\n\nfragment usersTable_companies on Query {\n  usersTableCompaniesList: companies {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment usersTable_products on Query {\n  usersTableProductsList: products {\n    edges {\n      node {\n        name\n        id\n        iconUrl\n      }\n    }\n  }\n}\n\nfragment usersTable_users on Query {\n  usersTableCount: usersCount(filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]])\n  usersTableUsers: users(first: 25, filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]]) {\n    edges {\n      node {\n        id\n        name\n        email\n        createdAt\n        instancesCount\n        hasActiveSession\n        lastActiveSession {\n          endTime\n          id\n        }\n        active\n        owner {\n          __typename\n          name\n          id\n        }\n        products {\n          nodes {\n            id\n            iconUrl\n            name\n          }\n        }\n        productUsers {\n          nodes {\n            product {\n              id\n              name\n            }\n            enabled\n            user {\n              id\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "40896cdc63ef10d63f1fcbb06fa58787";
export default node;
