import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import ElmInput from "../../../../components/elmInput";
import { getGateway } from "../../../../api";
import { Input } from "antd";

const { TextArea } = Input;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const DialogSubTitle = styled.p`
  ${props => props.theme.fontType.visualizationSubTitle}
`;

export interface INewProductReleaseDialogProps {
  configurationId: string;
  name: string;
  data: string;
  tag: string;
  isEdit?: boolean;
}
type state = {
  data: string;
  tag: string;
  disabled: boolean;
};
export class NewProductReleaseDialog extends DialogBase<
  IModalProps<INewProductReleaseDialogProps>,
  state
> {
  state: state = {
    data: this.props?.payload?.data,
    tag: this.props?.payload?.tag,
    disabled: false
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "NewProductReleaseDialog"
    });
  };
  public updateTag = (tag: string) => {
    this.setState({ tag });
  };
  public updateData = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ data: e.target.value });
  };
  public getTitle = () => {
    return this.props?.payload?.isEdit ? "Edit release" : "Add a new Release";
  };

  public getDescription = () => {
    return `You are adding a new Release to ${_.get(
      this.props.payload,
      "name"
    )}.`;
  };
  public createNewProductRelease = () => {
    this.setState(
      {
        disabled: true
      },
      () => {
        const gateway = getGateway();
        const payload = {
          release_configuration_id: this.props.payload.configurationId,
          tag: this.state.tag,
          data: this.state.data
        };
        const response = this.props?.payload?.isEdit
          ? gateway.request.editProductRelease(payload, {
              id: payload.release_configuration_id
            })
          : gateway.request.newProductRelease(payload);

        response
          .then(res => {
            if (!res.error) {
              if (res.data.id) {
                if (_.isFunction(this.props.payload.onConfirm)) {
                  this.props.payload.onConfirm();
                }
                return this.closeModal();
              }
            }
            this.setState({ disabled: false });
            return false;
          })
          .catch(err => {
            console.error(err);
          });
      }
    );
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        {this.props?.payload?.isEdit ? null : (
          <DialogSubTitle>{this.getDescription()}</DialogSubTitle>
        )}

        <Flex>
          <ElmInput
            updatedValue={this.updateTag}
            label={"Release Tag"}
            placeholder={"Tag"}
            defaultValue={this.state.tag}
          />
        </Flex>
        <Flex
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            height: "102px"
          }}
        >
          <TextArea
            maxLength={250}
            defaultValue={this.state.data}
            onChange={this.updateData}
            placeholder={"Data"}
          />
        </Flex>
        <Flex flex={1} flexDirection={"column"} justifyContent={"flex-end"}>
          <Flex justifyContent={"flex-end"}>
            <ElmButton
              label={"Cancel"}
              colorVariance="outline-secondary"
              onClick={this.closeModal}
            />
            <ElmButton
              disabled={this.state.disabled}
              colorVariance="primary"
              label={"Confirm"}
              onClick={this.createNewProductRelease}
            />
          </Flex>
        </Flex>
      </DialogContainer>
    );
  }
}
