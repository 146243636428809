import React, { useLayoutEffect, useState } from "react";
import uuid from "uuid";
import { IChart, ProductFilter } from "./types";
import { useChartData } from "./hooks";
import { customChartTheme, generateQueryFilter } from "./helpers";
import { CardTitle } from "./styles";
import ProductsFilter from "../../components/analyticsComponent/filters/productsFilter";
import TimeFilter from "../../components/analyticsComponent/filters/timeFilter";
import { Flex } from "reflexbox";
import _ from "lodash";
import { useTheme } from "styled-components";

const exampleData = {
  children: [
    {
      children: [
        {
          name: "A1",
          value: 100
        },
        {
          name: "A2",
          value: 60
        },
        {
          name: "A3",
          value: 30
        }
      ]
    },
    {
      children: [
        {
          name: "C1",
          value: 335
        },
        {
          name: "C2",
          value: 148
        },
        {
          name: "C3",
          value: 126
        },
        {
          name: "C4",
          value: 26
        }
      ]
    },
    {
      children: [
        {
          name: "A",
          value: 89
        },
        {
          name: "B",
          value: 55
        }
      ]
    },
    {
      children: [
        {
          name: "A",
          value: 150
        },
        {
          name: "B",
          value: 96
        }
      ]
    }
  ]
};

export default function TreemapChart(props: IChart) {
  const [timeRange, setTimeRange] = useState<string>("months3");
  const [productFilter, setProductFilter] = useState<ProductFilter>();
  const qData = generateQueryFilter(
    props.queryParams,
    timeRange,
    productFilter?.productName || ""
  );
  const theme = useTheme();

  let instanceId = uuid();
  const { data } = useChartData(qData);

  useLayoutEffect(() => {
    let root = am5.Root.new(instanceId);

    let myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll(customChartTheme);

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    if (root._logo) {
      root._logo._privateSettings.visible = false;
    }

    // Create wrapper container
    let chart = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout
      })
    );

    let series = chart.children.push(
      am5hierarchy.Treemap.new(root, {
        sort: "descending",
        singleBranchOnly: false,
        downDepth: 1,
        topDepth: 1,
        initialDepth: 2,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        nodePaddingOuter: 0,
        nodePaddingInner: 0
      })
    );

    series
      .get("colors")
      .set("colors", [
        am5.color("#0F649A"),
        am5.color("#FFA033"),
        am5.color("#68B6E8"),
        am5.color("#E3D626"),
        am5.color("#70D165"),
        am5.color("#EF8879"),
        am5.color("#F4F6F6")
      ]);

    let opacity: number = 1;
    let fillColor: number = 0;
    series.rectangles.template.adapters.add("fillOpacity", function(
      fill,
      target
    ) {
      if (target.dataItem) {
        let bgcolor = target.dataItem.get("rectangle").get("fill");
        if (bgcolor?._hex && fillColor !== bgcolor?._hex) {
          fillColor = bgcolor?._hex;
          opacity = 1;
        }
        opacity = opacity - 0.25;
      }
      return opacity;
    });

    series.data.setAll([exampleData]);
    // series.rectangles.template.setAll({
    //   templateField: "nodeSettings",
    // });
    //series.set("selectedDataItem", series.dataItems[0]);
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, timeRange]);

  const handleChange = (value: string) => {
    setTimeRange(value);
  };
  const handleProductChange = (productId: string, productName: string) => {
    setProductFilter({ productId, productName });
  };
  return (
    <div style={{ minHeight: "250px", width: "100%" }}>
      <div
        style={{
          height: "62px",
          color: theme.colors.textPrimary,
          padding: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <CardTitle>{props?.plot?.title || ""}</CardTitle>
        <Flex>
          {props.showProductFilter && (
            <ProductsFilter
              productId={productFilter?.productId || ""}
              onChange={handleProductChange}
            />
          )}
          {!props.hideTimeRangeFilter && (
            <TimeFilter timeRange={timeRange} handleChange={handleChange} />
          )}
        </Flex>
      </div>
      <div
        id={instanceId}
        style={{ width: "100%", minHeight: "250px", maxHeight: "400px" }}
      ></div>
    </div>
  );
}
