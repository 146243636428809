import { ElmInput } from "../../../components/elmInput";
import styled from "styled-components";

export const SectionHeader = styled.span(props => ({
  color: props.theme.colors.textPrimary,
  fontWeight: props.theme.fontWeight.medium,
  lineHeight: "17px",
  fontSize: props.theme.fontSizes.small,
  marginBottom: "20px"
}));

export const StyledElmInput = styled(ElmInput)`
  margin-bottom: 23px !important;
`;

export const StyledTemplateBtn = styled.span(props => ({
  color: props.theme.colors.inputLabels,
  fontWeight: props.theme.fontWeight.normal,
  lineHeight: "14px",
  fontSize: props.theme.fontSizes.small,
  cursor: "pointer",
  textDecoration: "underline",
  marginRight: "12px"
}));
