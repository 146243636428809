/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProductReleaseChannelQueryVariables = {
  id: string;
};
export type ProductReleaseChannelQueryResponse = {
  readonly releaseChannel: {
    readonly name: string | null;
    readonly currentRelease: {
      readonly id: string;
      readonly tag: string;
    } | null;
    readonly releaseConfiguration: {
      readonly product: {
        readonly iconUrl: string | null;
      } | null;
    } | null;
  } | null;
  readonly " $fragmentRefs": FragmentRefs<"releaseLicenseOwnerTable_licenses">;
};
export type ProductReleaseChannelQuery = {
  readonly response: ProductReleaseChannelQueryResponse;
  readonly variables: ProductReleaseChannelQueryVariables;
};

/*
query ProductReleaseChannelQuery(
  $id: ID!
) {
  ...releaseLicenseOwnerTable_licenses_1Bmzm5
  releaseChannel(id: $id) {
    name
    currentRelease {
      id
      tag
    }
    releaseConfiguration {
      product {
        iconUrl
        id
      }
      id
    }
    id
  }
}

fragment releaseLicenseOwnerTable_licenses_1Bmzm5 on Query {
  releaseChannel(id: $id) {
    id
    licenses(first: 25) {
      edges {
        node {
          id
          owner {
            __typename
            name
            type
            id
          }
          key
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      concreteType: "Release",
      kind: "LinkedField",
      name: "currentRelease",
      plural: false,
      selections: [
        v3 /*: any*/,
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "tag",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "iconUrl",
      storageKey: null
    } as any,
    v6 = [
      {
        kind: "Literal",
        name: "first",
        value: 25
      } as any
    ],
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "__typename",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "ProductReleaseChannelQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ReleaseChannel",
          kind: "LinkedField",
          name: "releaseChannel",
          plural: false,
          selections: [
            v2 /*: any*/,
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfiguration",
              kind: "LinkedField",
              name: "releaseConfiguration",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: [v5 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "releaseLicenseOwnerTable_licenses"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "ProductReleaseChannelQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ReleaseChannel",
          kind: "LinkedField",
          name: "releaseChannel",
          plural: false,
          selections: [
            v3 /*: any*/,
            {
              alias: null,
              args: v6 /*: any*/,
              concreteType: "LicenseConnection",
              kind: "LinkedField",
              name: "licenses",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "LicenseEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "License",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: null,
                          kind: "LinkedField",
                          name: "owner",
                          plural: false,
                          selections: [
                            v7 /*: any*/,
                            v2 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "type",
                              storageKey: null
                            },
                            v3 /*: any*/
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "key",
                          storageKey: null
                        },
                        v7 /*: any*/
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: "licenses(first:25)"
            },
            {
              alias: null,
              args: v6 /*: any*/,
              filters: ["search"],
              handle: "connection",
              key: "releaseLicenseOwnerTable_licenses",
              kind: "LinkedHandle",
              name: "licenses"
            },
            v2 /*: any*/,
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfiguration",
              kind: "LinkedField",
              name: "releaseConfiguration",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: [v5 /*: any*/, v3 /*: any*/],
                  storageKey: null
                },
                v3 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "847ff5f33f8aee24479eef6a7666814e",
      id: null,
      metadata: {},
      name: "ProductReleaseChannelQuery",
      operationKind: "query",
      text:
        "query ProductReleaseChannelQuery(\n  $id: ID!\n) {\n  ...releaseLicenseOwnerTable_licenses_1Bmzm5\n  releaseChannel(id: $id) {\n    name\n    currentRelease {\n      id\n      tag\n    }\n    releaseConfiguration {\n      product {\n        iconUrl\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment releaseLicenseOwnerTable_licenses_1Bmzm5 on Query {\n  releaseChannel(id: $id) {\n    id\n    licenses(first: 25) {\n      edges {\n        node {\n          id\n          owner {\n            __typename\n            name\n            type\n            id\n          }\n          key\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "b23805bf6fa54c3d1f2ca6be63f18bf3";
export default node;
