import { graphql } from "babel-plugin-relay/macro";
import { tableGenerator } from "../../../utils";
import { TableCellProps } from "react-virtualized";
import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { LoaderContainer } from "components/helpers";
import { licenseChangeLogTable_licenseActions } from "./__generated__/licenseChangeLogTable_licenseActions.graphql";
import {
  licenseChangeLogTableQuery,
  licenseChangeLogTableQueryResponse
} from "./__generated__/licenseChangeLogTableQuery.graphql";
import { shallowEqual } from "fast-equals";

export type logTableEdges = licenseChangeLogTable_licenseActions["licenseActions"]["edges"];
export type logTableType = logTableEdges[number]["node"];

const renderValue = (cellProps: TableCellProps) => {
  const value = _.get(
    cellProps,
    "rowData.parameters.term.value",
    cellProps?.rowData?.parameters?.value
  );
  return value ? <Flex>${value}</Flex> : "";
};

const renderActionBy = (payload: TableCellProps) => {
  return (
    payload.rowData?.vendorSystemUser?.name ||
    payload.rowData?.vendorSystemUser?.email
  );
};

const GeneratedTable = tableGenerator<{
  changeLog?: licenseChangeLogTable_licenseActions;
}>({
  columns: [
    {
      Header: "Description",
      accessor: "description",
      disableSortBy: true
    },
    {
      Header: "Created on",
      accessor: "createdAt",
      sortKey: "createdAt",
      isDateFormat: true
    },
    {
      Header: "Action by",
      accessor: "vendorSystemUser.name",
      disableSortBy: true,
      cellRenderer: payload => renderActionBy(payload)
    },
    {
      Header: "Value",
      accessor: "",
      disableSortBy: true,
      cellRenderer: payload => renderValue(payload),
      width: 0.1
    },
    {
      Header: "Action",
      accessor: "",
      disableSortBy: true,
      cellRenderer: () => "",
      width: 0.15
    }
  ],
  connectionQuery: graphql`
    query licenseChangeLogTablePaginationQuery(
      $count: Int!
      $cursor: String
      $blade_scope: String
      $filterColumn: [String!]
      $filterValues: [[String!]!]
      $filterOp: [[String!]!]
      $search: String
      $sortColumn: [String!]
      $sortDirection: [String!]
    ) {
      ...licenseChangeLogTable_licenseActions
        @arguments(
          count: $count
          cursor: $cursor
          blade_scope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        )
    }
  `,
  dataKey: "changeLog.licenseActions",
  fragmentSpec: {
    changeLog: graphql`
      fragment licenseChangeLogTable_licenseActions on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: null }
          cursor: { type: "String" }
          blade_scope: { type: "String", defaultValue: null }
          filterColumn: { type: "[String!]", defaultValue: null }
          filterOp: { type: "[[String!]!]", defaultValue: null }
          filterValues: { type: "[[String!]!]", defaultValue: null }
          search: { type: "String", defaultValue: null }
          sortColumn: { type: "[String!]", defaultValue: ["createdAt"] }
          sortDirection: { type: "[String!]", defaultValue: ["desc"] }
        ) {
        licenseActions(
          first: $count
          after: $cursor
          bladeScope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        ) @connection(key: "licenseChangeLogTable_licenseActions") {
          edges {
            node {
              description
              createdAt
              vendorSystemUser {
                name
                email
              }
              id
              requireApproval
              status
              parameters
              comment
            }
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query licenseChangeLogTableQuery($blade_scope: String!) {
    ...licenseChangeLogTable_licenseActions
      @arguments(blade_scope: $blade_scope)
  }
`;

class ChangeLogTable extends React.Component<
  IGeneratedTableProps & {
    result: licenseChangeLogTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        changeLog={res}
      />
    );
  }
}

export const LicenseChangeLogTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: licenseChangeLogTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <ChangeLogTable {...props} result={payload.props} retry={payload.retry} />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<licenseChangeLogTableQuery>
      environment={relayEnvironment}
      variables={{
        blade_scope: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};
