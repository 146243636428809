/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type featureGroupsTable_featureGroups = {
  readonly featureGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null;
        readonly id: string;
        readonly features: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $refType": "featureGroupsTable_featureGroups";
};
export type featureGroupsTable_featureGroups$data = featureGroupsTable_featureGroups;
export type featureGroupsTable_featureGroups$key = {
  readonly " $data"?: featureGroupsTable_featureGroups$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"featureGroupsTable_featureGroups">;
};

const node: ReaderFragment = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "bladeScope"
      },
      {
        defaultValue: 6,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["featureGroups"]
        }
      ]
    },
    name: "featureGroupsTable_featureGroups",
    selections: [
      {
        alias: "featureGroups",
        args: [
          {
            kind: "Variable",
            name: "bladeScope",
            variableName: "bladeScope"
          }
        ],
        concreteType: "FeatureGroupConnection",
        kind: "LinkedField",
        name: "__featureGroupsTable_featureGroups_connection",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "FeatureGroupEdge",
            kind: "LinkedField",
            name: "edges",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "FeatureGroup",
                kind: "LinkedField",
                name: "node",
                plural: false,
                selections: [
                  v0 /*: any*/,
                  v1 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    concreteType: "FeatureConnection",
                    kind: "LinkedField",
                    name: "features",
                    plural: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        concreteType: "Feature",
                        kind: "LinkedField",
                        name: "nodes",
                        plural: true,
                        selections: [v1 /*: any*/, v0 /*: any*/],
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "__typename",
                    storageKey: null
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "cursor",
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: "PageInfo",
            kind: "LinkedField",
            name: "pageInfo",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "endCursor",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "hasNextPage",
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "e310c9f9830a9bc3a9c086d5e104bc0d";
export default node;
