/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type newProductFeatureQueryVariables = {
  productId: string;
  featureId: string;
  skip: boolean;
};
export type newProductFeatureQueryResponse = {
  readonly product: {
    readonly name: string | null;
    readonly featureGroups: {
      readonly nodes: ReadonlyArray<{
        readonly name: string | null;
        readonly id: string;
      } | null> | null;
    } | null;
  } | null;
  readonly feature?:
    | {
        readonly id: string;
        readonly name: string | null;
        readonly featureGroups: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
          } | null> | null;
        } | null;
      }
    | null
    | undefined;
};
export type newProductFeatureQuery = {
  readonly response: newProductFeatureQueryResponse;
  readonly variables: newProductFeatureQueryVariables;
};

/*
query newProductFeatureQuery(
  $productId: ID!
  $featureId: ID!
  $skip: Boolean!
) {
  product(id: $productId) {
    name
    featureGroups {
      nodes {
        name
        id
      }
    }
    id
  }
  feature(id: $featureId) @skip(if: $skip) {
    id
    name
    featureGroups {
      nodes {
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "featureId"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "productId"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "skip"
    } as any,
    v3 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "productId"
      } as any
    ],
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      concreteType: "FeatureGroupConnection",
      kind: "LinkedField",
      name: "featureGroups",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "FeatureGroup",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v4 /*: any*/, v5 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v7 = {
      condition: "skip",
      kind: "Condition",
      passingValue: false,
      selections: [
        {
          alias: null,
          args: [
            {
              kind: "Variable",
              name: "id",
              variableName: "featureId"
            }
          ],
          concreteType: "Feature",
          kind: "LinkedField",
          name: "feature",
          plural: false,
          selections: [
            v5 /*: any*/,
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "FeatureGroupConnection",
              kind: "LinkedField",
              name: "featureGroups",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "FeatureGroup",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [v5 /*: any*/, v4 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    } as any;
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: "Fragment",
      metadata: null,
      name: "newProductFeatureQuery",
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v4 /*: any*/, v6 /*: any*/],
          storageKey: null
        },
        v7 /*: any*/
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/, v2 /*: any*/],
      kind: "Operation",
      name: "newProductFeatureQuery",
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [v4 /*: any*/, v6 /*: any*/, v5 /*: any*/],
          storageKey: null
        },
        v7 /*: any*/
      ]
    },
    params: {
      cacheID: "f58013b630bf202b079176cceb633661",
      id: null,
      metadata: {},
      name: "newProductFeatureQuery",
      operationKind: "query",
      text:
        "query newProductFeatureQuery(\n  $productId: ID!\n  $featureId: ID!\n  $skip: Boolean!\n) {\n  product(id: $productId) {\n    name\n    featureGroups {\n      nodes {\n        name\n        id\n      }\n    }\n    id\n  }\n  feature(id: $featureId) @skip(if: $skip) {\n    id\n    name\n    featureGroups {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "f1ec17b5d0abdbcbe08f927fd89bf10f";
export default node;
