import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import ElmInput from "../../../../components/elmInput";
import { getGateway } from "../../../../api";
import { LoaderContainer } from "../../../../components/helpers";
import { getIcon, ProductReleasesTable } from "../../../../components";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../../api/relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  editReleaseConfigQuery,
  editReleaseConfigQueryResponse
} from "./__generated__/editReleaseConfigQuery.graphql";
import theme from "../../../../theme";

const graphqlQuery = graphql`
  query editReleaseConfigQuery($id: ID!) {
    ...productReleasesTable_releases @arguments(id: $id)
  }
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 480px;
  width: 440px;
`;
const SectionTitle = styled.p`
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.colors.warmGrey};
  border-bottom: 1px solid ${props => props.theme.colors.greyish};
`;
const StyledProductComponentInfo = styled(Flex)`
  color: ${props => props.theme.colors.textPrimary};
`;
const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;

export interface INewProductReleaseConfigDialogProps {
  id: string;
  name: string;
  isEdit?: boolean;
  releaseChannelId?: string;
  currentRelease?: { id: string; tag: string };
}
type state = {
  name: string;
  loading: boolean;
  currentRelease?: { id: string; tag: string; created_at?: string };
};
export class EditProductReleaseConfigDialog extends DialogBase<
  IModalProps<INewProductReleaseConfigDialogProps>,
  state
> {
  state: state = {
    name: _.get(this.props.payload, "name", ""),
    loading: false,
    currentRelease: _.get(this.props.payload, "currentRelease", null)
  };
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditProductReleaseConfigDialog"
    });
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };
  public getTitle = () => {
    return this.props.payload?.isEdit
      ? "Edit Release Configuration"
      : "Add a new Release Configuration";
  };

  public getDescription = () => {
    return `You are adding a new Release Configuration to ${_.get(
      this.props.payload,
      "productName"
    )}.`;
  };
  public updateRelease = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        const gateway = getGateway();
        gateway.request
          .updateReleaseForChannel(
            {
              id: this.props.payload.releaseChannelId,
              release_id: this.state.currentRelease?.id || null,
              name: this.state.name || ""
            },
            { id: this.props.payload.releaseChannelId }
          )
          .then(res => {
            gateway.request.updateReleaseNameForChannel(
              {
                id: this.props.payload.releaseChannelId,
                name: this.state.name || ""
              },
              { id: this.props.payload.releaseChannelId }
            );
            if (_.isObject(res.data)) {
              if (res.data.id) {
                if (_.isFunction(this.props.payload.onConfirm)) {
                  this.props.payload.onConfirm();
                }
                return this.closeModal();
              }
            }
            this.setState({ loading: false });
            return false;
          })
          .catch(err => {
            console.error(err);
          });
      }
    );
  };

  public renderItems = () => {
    const TagIcon = styled(getIcon("IoMdPricetag"))`
      color: ${props => props.theme.colors.productsBlue};
    `;
    const DotIcon = styled(getIcon("FaDotCircle"))`
      color: ${props => props.theme.colors.whiteTwo};
      font-size: 4px;
      display: flex;
      align-items: center;
    `;

    const selectedRelease = this.state.currentRelease;
    return (
      <Flex
        flexWrap="wrap"
        flex={1}
        style={_.merge({
          height: "max-content",
          fontSize: "12px",
          fontWeight: 400
        })}
        alignItems="center"
        justifyContent="space-between"
      >
        <StyledProductComponentInfo>
          <Flex>
            <div style={{ marginRight: "12px" }}>
              <TagIcon />
              <span style={{ color: theme.colors.warmGrey, marginLeft: "5px" }}>
                {selectedRelease?.tag || "N/A"}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <DotIcon />
            </div>
            <div
              style={{
                marginRight: "12px",
                marginLeft: "12px"
              }}
            >
              Created at:
              <span style={{ color: theme.colors.warmGrey, marginLeft: "5px" }}>
                {selectedRelease?.created_at || "N/A"}
              </span>
            </div>
          </Flex>
        </StyledProductComponentInfo>
        <Flex>
          <ElmButton
            variance="plain-icon-button"
            icon="IoMdClose"
            iconPosition="afterLabel"
            label={"Clear"}
            colorVariance="subtle"
            onClick={() => this.setState({ currentRelease: null })}
          >
            <TagIcon />
          </ElmButton>
        </Flex>
      </Flex>
    );
  };

  public renderQueryRenderer = () => {
    const renderComponentGroup = (payload: {
      error: Error;
      props: editReleaseConfigQueryResponse;
    }) => {
      if (payload.error || !_.isObject(payload.props)) {
        return <DialogContainer>{LoaderContainer()}</DialogContainer>;
      }
      const setSelectedChannel = (payload: any) => {
        this.setState({
          currentRelease: { id: payload?.id, tag: payload?.tag }
        });
      };
      return (
        <DialogContainer>
          <DialogTitle>{this.getTitle()}</DialogTitle>

          <Flex
            flexDirection="column"
            flex={1}
            style={{
              width: "100%",
              marginTop: "5px",
              marginBottom: "5px"
              //height: "190px",
            }}
          >
            <ElmInput
              defaultValue={this.state.name}
              updatedValue={this.updateName}
              label={"Release channel name"}
              placeholder={"Name"}
            />
            <ProductReleasesTable
              releases={payload.props}
              onRowClick={setSelectedChannel}
              //useDefaultAddButton={showNewReleaseDialog}
            />
            <SectionTitle>Selected</SectionTitle>
            <Flex
              style={{
                width: "100%",
                marginTop: "5px",
                marginBottom: "5px",
                height: "70px"
              }}
            >
              {this.state.currentRelease ? this.renderItems() : null}
            </Flex>
          </Flex>
          <Flex alignItems="flex-end" justifyContent={"flex-end"}>
            <ElmButton
              label={"Cancel"}
              colorVariance={"outline-secondary"}
              onClick={this.closeModal}
            />
            <ElmButton
              disabled={this.state.loading}
              label={"Update release"}
              colorVariance="primary"
              onClick={this.updateRelease}
            />
          </Flex>
        </DialogContainer>
      );
    };
    return (
      <QueryRenderer<editReleaseConfigQuery>
        variables={{
          id: _.get(this.props.payload, "id")
        }}
        environment={relayEnvironment}
        query={graphqlQuery}
        render={renderComponentGroup}
      />
    );
  };

  public render() {
    return this.renderQueryRenderer();
  }
}
