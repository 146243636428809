/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type instanceHistoryTablePaginationQueryVariables = {
  id: string;
  count: number;
  cursor?: string | null | undefined;
};
export type instanceHistoryTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"instanceHistoryTable_instance">;
};
export type instanceHistoryTablePaginationQuery = {
  readonly response: instanceHistoryTablePaginationQueryResponse;
  readonly variables: instanceHistoryTablePaginationQueryVariables;
};

/*
query instanceHistoryTablePaginationQuery(
  $id: ID!
  $count: Int!
  $cursor: String
) {
  ...instanceHistoryTable_instance_3T5kGn
}

fragment instanceHistoryTable_instance_3T5kGn on Query {
  instance(id: $id) {
    modificationRecords(first: $count, after: $cursor) {
      edges {
        node {
          enabled
          createdAt
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    } as any,
    v3 = {
      kind: "Variable",
      name: "id",
      variableName: "id"
    } as any,
    v4 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: "Fragment",
      metadata: null,
      name: "instanceHistoryTablePaginationQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v3 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "instanceHistoryTable_instance"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/],
      kind: "Operation",
      name: "instanceHistoryTablePaginationQuery",
      selections: [
        {
          alias: null,
          args: [v3 /*: any*/],
          concreteType: "Instance",
          kind: "LinkedField",
          name: "instance",
          plural: false,
          selections: [
            {
              alias: null,
              args: v4 /*: any*/,
              concreteType: "ModificationRecordConnection",
              kind: "LinkedField",
              name: "modificationRecords",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ModificationRecordEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ModificationRecord",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "enabled",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "createdAt",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: v4 /*: any*/,
              filters: null,
              handle: "connection",
              key: "instanceHistoryTable_modificationRecords",
              kind: "LinkedHandle",
              name: "modificationRecords"
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "1a7c80365b309cdde1facb73de13e48f",
      id: null,
      metadata: {},
      name: "instanceHistoryTablePaginationQuery",
      operationKind: "query",
      text:
        "query instanceHistoryTablePaginationQuery(\n  $id: ID!\n  $count: Int!\n  $cursor: String\n) {\n  ...instanceHistoryTable_instance_3T5kGn\n}\n\nfragment instanceHistoryTable_instance_3T5kGn on Query {\n  instance(id: $id) {\n    modificationRecords(first: $count, after: $cursor) {\n      edges {\n        node {\n          enabled\n          createdAt\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "e63217bc09dddbbe0a3997c99a07028a";
export default node;
