import * as _ from "lodash";
// @ts-ignore
import reduxDialog from "redux-dialog";
import {
  appConnect,
  appDispatch,
  IAppConnectOptions
} from "../../../store/appConnect";
import { CompanyImageUploadDialog } from "./companyImageUpload";
import { DeleteDialog } from "./delete";
import { EditComponentDialog } from "../../../Products/components/dialogs/editComponent";
import { EditReleaseDialog } from "./editReleaseConfig";
import { ErrorDialog } from "./error";
import { NewComponentDialog } from "../../../Products/components/dialogs/newComponent";
import NewProductFeatureDialog from "../../../Products/components/dialogs/newProductFeature";
import NewProductFeatureGroupDialog from "../../../Products/components/dialogs/newProductFeatureGroup";
import { NewComponentGroupDialog } from "../../../Products/components/dialogs/newComponentGroup";
import { EditComponentGroupDialog } from "../../../Products/components/dialogs/editComponentGroup";
import { NewProductReleaseConfigDialog } from "../../../Products/components/dialogs/newProductReleaseConfig";
import { NewProductReleaseChannelDialog } from "../../../Products/components/dialogs/newProductReleaseChannel";
import { NewProductReleaseDialog } from "../../../Products/components/dialogs/newProductRelease";
import { SelectReleaseDialog } from "./selectRelease";
import { NewApiTokenDialog } from "./newApiToken";
import { CopyApiTokenDialog } from "./copyApiToken";
import { EditApiTokenDialog } from "./editApiToken";
import { EditAdminDialog } from "./editAdmin";
import { EditCompanyAdminDialog } from "./editCompanyAdmin";
import { ChangePasswordDialog } from "./changePassword";
import { VendorImageUploadDialog } from "./vendorImageUpload";
import EditLicenseDialog from "../../../Licenses/components/dialogs/editLicense";
import EditProductDialog from "../../../Products/components/dialogs/editProduct";
import { AddLicenseDialog } from "../../../Licenses/components/dialogs/addLicense";
import EditCompanyDialog from "../../../Companies/components/dialogs/editCompany";
import EditBusinessUnitDialog from "../../../Companies/components/dialogs/editBusinessUnitDialog";
import { EditUserDialog } from "./editUser";
import { EditProductReleaseConfigDialog } from "../../../Products/components/dialogs/editRelease";
import { EditLicenseReleaseConfigDialog } from "Licenses/components/dialogs/editReleaseConfig";
import { EditLicenseComponentRequirementsDialog } from "Licenses/components/dialogs/editLicenseComponentRequirements";
import { RenewEntitlementDialog } from "Licenses/components/dialogs/renewEntitlementDialog";
import { ConfirmationDialog } from "./confirmation";
import { RoleSelectorDialog } from "./roleSelector/roleSelector";
import { EditProfileDialog } from "./editProfile";
import { AccountDialog } from "./accountDialog";
import { SelectEnableProductDialog } from "../../../blades/Overview/components/dialogs/selectEnableProduct";
import { ProductVersionDialog } from "Products/components/dialogs/editProductVersion";
import { SelectTemplateDialog } from "../../../Licenses/components/dialogs/selectTemplate";
import { UserLinkingDialog } from "../../../Users/components/dialogs/userLinking";
import { AddTemplateDialog } from "../../../Licenses/components/dialogs/addTemplate";
import { ProductCreatedDialog } from "../../../blades/Welcome/components/dialogs/productCreated";
import { LicenseCreatedDialog } from "../../../blades/Welcome/components/dialogs/licenseCreated";

let ConnectedDialog;
let modalPropsFunc: () => any;
let dialogConnectionOptions: () => IAppConnectOptions = () => ({
  selectors: {}
});
let modalProps;
export const appModalRegistry = _.mapValues(
  {
    EditCompanyDialog,
    EditBusinessUnitDialog,
    AddLicenseDialog,
    EditProductDialog,
    EditLicenseDialog,
    ChangePasswordDialog,
    CompanyImageUploadDialog,
    CopyApiTokenDialog,
    DeleteDialog,
    EditAdminDialog,
    EditCompanyAdminDialog,
    EditApiTokenDialog,
    EditComponentDialog,
    EditComponentGroupDialog,
    EditReleaseDialog,
    ErrorDialog,
    NewApiTokenDialog,
    NewComponentDialog,
    NewProductFeatureDialog,
    NewProductFeatureGroupDialog,
    NewComponentGroupDialog,
    NewProductReleaseConfigDialog,
    NewProductReleaseChannelDialog,
    NewProductReleaseDialog,
    SelectReleaseDialog,
    VendorImageUploadDialog,
    EditUserDialog,
    EditProfileDialog,
    AccountDialog,
    SelectEnableProductDialog,
    EditProductReleaseConfigDialog,
    EditLicenseReleaseConfigDialog,
    EditLicenseComponentRequirementsDialog,
    RenewEntitlementDialog,
    ProductVersionDialog,
    ConfirmationDialog,
    RoleSelectorDialog,
    SelectTemplateDialog,
    AddTemplateDialog,
    UserLinkingDialog,
    ProductCreatedDialog,
    LicenseCreatedDialog
  },
  (comp, key): React.ComponentClass<any> => {
    // connect dialog to store so it has access to dispatch i.e closing itself
    dialogConnectionOptions = _.get(
      comp,
      "getAppConnectOptions"
    ) as () => IAppConnectOptions;
    ConnectedDialog = appConnect(
      comp,
      _.isFunction(dialogConnectionOptions)
        ? dialogConnectionOptions()
        : { selectors: {} }
    );
    modalPropsFunc = _.get(comp, "getModalProps") as typeof modalPropsFunc;
    modalProps = _.isFunction(modalPropsFunc) ? modalPropsFunc() : {};
    return reduxDialog({ name: key, ariaHideApp: false, ...modalProps })(
      ConnectedDialog
    ) as React.ComponentClass<any>;
  }
);
export type modalTypes = keyof typeof appModalRegistry;
export interface IModalProps<T = any> {
  appDispatch: appDispatch;
  payload?: T & { onConfirm?: (state: any) => void };
}
export interface IAppModalConductorProps {
  closeModal: (modal: modalTypes) => void;
}
export type IAppModalRegistry = {
  [k in modalTypes]: React.ComponentClass<IModalProps>;
};
