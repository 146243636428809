/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type editProductQueryVariables = {
  id: string;
};
export type editProductQueryResponse = {
  readonly product: {
    readonly name: string | null;
    readonly supportEmail: string | null;
    readonly iconUrl: string | null;
    readonly guid: string;
    readonly vendorId: string;
    readonly type: {
      readonly id: string;
      readonly name: string;
    };
    readonly features: {
      readonly nodes: ReadonlyArray<{
        readonly name: string | null;
      } | null> | null;
    } | null;
  } | null;
};
export type editProductQuery = {
  readonly response: editProductQueryResponse;
  readonly variables: editProductQueryVariables;
};

/*
query editProductQuery(
  $id: ID!
) {
  product(id: $id) {
    name
    supportEmail
    iconUrl
    guid
    vendorId
    type {
      id
      name
    }
    features {
      nodes {
        name
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "supportEmail",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "iconUrl",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "guid",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "vendorId",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      concreteType: "ProductCategory",
      kind: "LinkedField",
      name: "type",
      plural: false,
      selections: [v7 /*: any*/, v2 /*: any*/],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "editProductQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "FeatureConnection",
              kind: "LinkedField",
              name: "features",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Feature",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [v2 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "editProductQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "FeatureConnection",
              kind: "LinkedField",
              name: "features",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Feature",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [v2 /*: any*/, v7 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v7 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "d324f0b81124a872a02736ac3567b032",
      id: null,
      metadata: {},
      name: "editProductQuery",
      operationKind: "query",
      text:
        "query editProductQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    name\n    supportEmail\n    iconUrl\n    guid\n    vendorId\n    type {\n      id\n      name\n    }\n    features {\n      nodes {\n        name\n        id\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "7ceca1c6ae2ae02ea4145ddbfe23abda";
export default node;
