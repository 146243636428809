import React, { useState } from "react";
import styled from "styled-components";
import { ElmButton } from "components/elmButton";
import TextArea from "antd/es/input/TextArea";

const CommentTextArea = styled(TextArea)`
  height: 120;
  width: 100%;
  & span > textarea {
    background: ${props => props.theme.colors.lightGrey};
  }
`;

export const CommentField: React.FC<{
  updateComment: (value: string) => void;
}> = ({ updateComment }) => {
  const [commentMode, setCommentMode] = useState<boolean>(false);

  return (
    <>
      {!commentMode ? (
        <ElmButton
          onClick={() => {
            setCommentMode(!commentMode);
          }}
          className="subtle"
          colorVariance="subtle"
          label="Add comment"
          icon="plus"
          variance="plain-icon-button"
          iconPrefix="fas"
        />
      ) : (
        <CommentTextArea
          showCount
          rows={6}
          maxLength={250}
          allowClear={true}
          placeholder="Enter comment..."
          onBlur={e => {
            updateComment(e.target.value);
          }}
          style={{ resize: "none" }}
        />
      )}
    </>
  );
};

export default CommentField;
