import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import {
  allProductsQuery,
  allProductsQueryResponse
} from "./__generated__/allProductsQuery.graphql";
import _ from "lodash";
import { ElmFilterSelect } from "../../elmSelect";
import { ElmSelectOption } from "../../elmSelect";
import clsx from "clsx";
const graphqlQuery = graphql`
  query allProductsQuery {
    allProductsFilter: products(
      sortColumn: ["product.name"]
      sortDirection: ["asc"]
    ) {
      nodes {
        name
        id
      }
    }
  }
`;
export interface IFilterProp {
  productId?: string;
  onChange?: (productId: string) => void;
}
export class AllProductsFilter extends React.Component<
  IFilterProp & { result: allProductsQueryResponse },
  { productId: string; filtered: boolean }
> {
  state = {
    productId: this.props.productId || "-1",
    filtered: this.props.productId && this.props.productId !== "-1"
  };
  public renderFilter = () => {
    if (!this.props.result || !this.props.result.allProductsFilter) {
      return null;
    }

    const options = [
      {
        id: -1,
        name: "All products"
      },
      ...this.props.result.allProductsFilter.nodes
    ];
    const handleChange = (payload: any) => {
      let productId: string = payload;
      let filtered: boolean = true;
      if (payload === "-1") {
        productId = null;
        filtered = false;
      }
      this.setState({ productId, filtered }, () => {
        if (_.isFunction(this.props.onChange)) {
          this.props.onChange(productId);
        }
      });
    };
    return (
      <ElmFilterSelect
        value={this.state.productId}
        onChange={handleChange}
        className={clsx(this.state.filtered ? "filtered" : "", "filterBorder")}
        dropdownMatchSelectWidth={false}
      >
        {_.map(options, node => {
          return (
            <ElmSelectOption key={node.id}>
              <span className="filterOption">{node.name}</span>
            </ElmSelectOption>
          );
        })}
      </ElmFilterSelect>
    );
  };
  public render() {
    return this.renderFilter();
  }
}
const RenderQuery = (props: IFilterProp) => {
  const renderComponent = (payload: {
    error: Error;
    props: allProductsQueryResponse;
    retry: () => void;
  }) => {
    // if (!payload.props) {
    //   props.showScreenLoader();
    //   return null;
    // }
    // props.hideScreenLoader();
    return (
      <AllProductsFilter
        {...props}
        result={payload.props}
        {..._.omit(payload, "props")}
      />
    );
  };
  return (
    <QueryRenderer<allProductsQuery>
      environment={relayEnvironment}
      variables={null}
      query={graphqlQuery}
      render={renderComponent}
    />
  );
};
export default RenderQuery;
