/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type entitlementComponentsListQueryVariables = {
  id: string;
};
export type entitlementComponentsListQueryResponse = {
  readonly license: {
    readonly componentEntitlements: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly tokenCount: number | null;
        readonly sessionCount: number | null;
        readonly numCurrencyUsed: number | null;
        readonly latestTerm: {
          readonly startDate: unknown | null;
          readonly endDate: unknown | null;
        } | null;
        readonly components: {
          readonly nodes: ReadonlyArray<{
            readonly name: string | null;
            readonly componentType: string;
            readonly id: string;
          } | null> | null;
        } | null;
        readonly componentGroup: {
          readonly id: string;
          readonly name: string | null;
          readonly componentType: string;
          readonly components: {
            readonly nodes: ReadonlyArray<{
              readonly name: string | null;
              readonly componentType: string;
              readonly id: string;
            } | null> | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly activeEntitlements: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly value: number;
        readonly latestTerm: {
          readonly startDate: unknown | null;
          readonly endDate: unknown | null;
        } | null;
        readonly instanceCount: number | null;
        readonly sessionCount: number | null;
        readonly userCount: number | null;
      } | null> | null;
    } | null;
    readonly product: {
      readonly id: string;
      readonly name: string | null;
      readonly iconUrl: string | null;
      readonly componentsCount: number;
      readonly featuresCount: number;
      readonly components: {
        readonly nodes: ReadonlyArray<{
          readonly name: string | null;
          readonly componentType: string;
          readonly id: string;
        } | null> | null;
      } | null;
      readonly componentGroups: {
        readonly nodes: ReadonlyArray<{
          readonly name: string | null;
          readonly id: string;
          readonly components: {
            readonly nodes: ReadonlyArray<{
              readonly name: string | null;
              readonly id: string;
              readonly componentType: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type entitlementComponentsListQuery = {
  readonly response: entitlementComponentsListQueryResponse;
  readonly variables: entitlementComponentsListQueryVariables;
};

/*
query entitlementComponentsListQuery(
  $id: ID!
) {
  license(id: $id) {
    componentEntitlements {
      nodes {
        id
        tokenCount
        sessionCount
        numCurrencyUsed
        latestTerm {
          startDate
          endDate
        }
        components {
          nodes {
            name
            componentType
            id
          }
        }
        componentGroup {
          id
          name
          componentType
          components {
            nodes {
              name
              componentType
              id
            }
          }
        }
      }
    }
    activeEntitlements {
      nodes {
        id
        value
        latestTerm {
          startDate
          endDate
        }
        instanceCount
        sessionCount
        userCount
      }
    }
    product {
      id
      name
      iconUrl
      componentsCount
      featuresCount
      components {
        nodes {
          name
          componentType
          id
        }
      }
      componentGroups {
        nodes {
          name
          id
          components {
            nodes {
              name
              id
              componentType
            }
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "sessionCount",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      concreteType: "Term",
      kind: "LinkedField",
      name: "latestTerm",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "startDate",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "endDate",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "componentType",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v5 /*: any*/, v6 /*: any*/, v2 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      concreteType: "ComponentEntitlementConnection",
      kind: "LinkedField",
      name: "componentEntitlements",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ComponentEntitlement",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "tokenCount",
              storageKey: null
            },
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "numCurrencyUsed",
              storageKey: null
            },
            v4 /*: any*/,
            v7 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentGroup",
              kind: "LinkedField",
              name: "componentGroup",
              plural: false,
              selections: [
                v2 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/,
                v7 /*: any*/
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      concreteType: "EntitlementConnection",
      kind: "LinkedField",
      name: "activeEntitlements",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Entitlement",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "value",
              storageKey: null
            },
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "instanceCount",
              storageKey: null
            },
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "userCount",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v10 = {
      alias: null,
      args: null,
      concreteType: "Product",
      kind: "LinkedField",
      name: "product",
      plural: false,
      selections: [
        v2 /*: any*/,
        v5 /*: any*/,
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "iconUrl",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "componentsCount",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "featuresCount",
          storageKey: null
        },
        v7 /*: any*/,
        {
          alias: null,
          args: null,
          concreteType: "ComponentGroupConnection",
          kind: "LinkedField",
          name: "componentGroups",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ComponentGroup",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v5 /*: any*/,
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "ComponentConnection",
                  kind: "LinkedField",
                  name: "components",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Component",
                      kind: "LinkedField",
                      name: "nodes",
                      plural: true,
                      selections: [v5 /*: any*/, v2 /*: any*/, v6 /*: any*/],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "entitlementComponentsListQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [v8 /*: any*/, v9 /*: any*/, v10 /*: any*/],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "entitlementComponentsListQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [v8 /*: any*/, v9 /*: any*/, v10 /*: any*/, v2 /*: any*/],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "86068117aab52bcbfc157570839499df",
      id: null,
      metadata: {},
      name: "entitlementComponentsListQuery",
      operationKind: "query",
      text:
        "query entitlementComponentsListQuery(\n  $id: ID!\n) {\n  license(id: $id) {\n    componentEntitlements {\n      nodes {\n        id\n        tokenCount\n        sessionCount\n        numCurrencyUsed\n        latestTerm {\n          startDate\n          endDate\n        }\n        components {\n          nodes {\n            name\n            componentType\n            id\n          }\n        }\n        componentGroup {\n          id\n          name\n          componentType\n          components {\n            nodes {\n              name\n              componentType\n              id\n            }\n          }\n        }\n      }\n    }\n    activeEntitlements {\n      nodes {\n        id\n        value\n        latestTerm {\n          startDate\n          endDate\n        }\n        instanceCount\n        sessionCount\n        userCount\n      }\n    }\n    product {\n      id\n      name\n      iconUrl\n      componentsCount\n      featuresCount\n      components {\n        nodes {\n          name\n          componentType\n          id\n        }\n      }\n      componentGroups {\n        nodes {\n          name\n          id\n          components {\n            nodes {\n              name\n              id\n              componentType\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "a0cbeb7da57340bdbad73445db7d7435";
export default node;
