import { graphql } from "babel-plugin-relay/macro";
import { tableGenerator } from "../../../utils/tableGenerator";
import { productReleaseConfigurationsTable_releaseConfigurations } from "./__generated__/productReleaseConfigurationsTable_releaseConfigurations.graphql";
import _ from "lodash";

export type productReleaseConfigurationsEdges = productReleaseConfigurationsTable_releaseConfigurations["product"]["releaseConfigurations"]["edges"];
export type productReleaseConfigTableType = productReleaseConfigurationsEdges[number]["node"];

export type IProductReleaseConfigurationsTableProps = {
  releaseConfigurations: productReleaseConfigurationsTable_releaseConfigurations;
};

export const ProductReleaseConfigurationsTable = tableGenerator<
  IProductReleaseConfigurationsTableProps
>({
  columns: [
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "# Releases",
      accessor: "releasesCount"
    }
  ],
  connectionQuery: graphql`
    query productReleaseConfigurationsTablePaginationQuery(
      $count: Int!
      $cursor: String
      $search: String
      $id: ID!
    ) {
      ...productReleaseConfigurationsTable_releaseConfigurations
        @arguments(count: $count, cursor: $cursor, search: $search, id: $id)
    }
  `,
  dataKey: "releaseConfigurations.product.releaseConfigurations",
  fragmentSpec: {
    releaseConfigurations: graphql`
      fragment productReleaseConfigurationsTable_releaseConfigurations on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 6 }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
          id: { type: "ID!" }
        ) {
        product(id: $id) {
          releaseConfigurations(first: $count, after: $cursor, search: $search)
            @connection(
              key: "productReleaseConfigurationsTable_releaseConfigurations"
            ) {
            edges {
              node {
                id
                name
                releasesCount
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `
  },
  tableName: "productReleasesConfigurations"
});
export default ProductReleaseConfigurationsTable;
