import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "styled-components";
import { MenuProps } from "antd";
import { Dropdown } from "antd";
import { ElmButton } from "components/elmButton";
import { Flex } from "reflexbox";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
//@ts-ignore
import { CSVLink } from "react-csv";
import { getLabelFromKey } from "Reports/blades/Report";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import dayjs from "dayjs";
import { DATE_FORMAT_ALT } from "const";

/*
  https://github.com/react-csv/react-csv/issues/47
  CSVDownload does not support filename, but CSVLink does,
  so this wrapper simulates CSVDownload implementation.
*/
const CSVDownload = (props: any) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  useEffect(() => btnRef.current?.click(), [btnRef]);
  return (
    <CSVLink {...props}>
      <span ref={btnRef} />
    </CSVLink>
  );
};

interface IDataExporter {
  data: object[];
  header?: string;
  dispose?: () => void;
}

const getTableValues = (data: object[]) => {
  const mappedData = data.map(report => {
    let values: Record<string, string | number | Date> = {};
    Object.keys(report)?.forEach(key => {
      if (key.startsWith("_")) {
        // Exclude metadata properties which start with _
        return;
      }
      if (typeof report[key] !== "object") {
        values[key] = report[key];
      }
      if (typeof report[key] === "object") {
        values = { ...values, ...report[key] };
      }
    });
    return values;
  });
  const headers = Object.keys(mappedData[0]).map(key => ({
    key,
    label: getLabelFromKey({ key })
  }));

  return { headers, values: mappedData };
};

const exportPDF = (data: object[], header?: string): void => {
  if (!data) {
    return null;
  }

  const unit = "pt";
  const size = "A4";
  const orientation = "portrait";
  const marginLeft = 40;
  const marginTop = 40;

  const doc = new jsPDF(orientation, unit, size);

  const { headers, values } = getTableValues(data);

  doc.text(header || "Report", marginLeft, marginTop);

  autoTable(doc, {
    startY: 50,
    head: [headers.map(({ label }) => label)],
    body: [
      ...values.map(value => {
        const displayValues: any[] = [];
        Object.keys(value).forEach(key => {
          displayValues.push(value[key]);
        });
        return displayValues;
      })
    ]
  });

  const filename = `${dayjs().format(DATE_FORMAT_ALT)}${
    header ? "-" + header : ""
  }`;
  doc.save(`${filename}.pdf`);
};

export function DataExporter({ data, header, dispose }: IDataExporter) {
  useEffect(() => {
    return dispose();
  }, [dispose]);

  if (!data) {
    return null;
  }

  const { headers, values } = getTableValues(data);

  const filename = `${dayjs().format(DATE_FORMAT_ALT)}${
    header ? "-" + header : ""
  }`;
  const csvReport = {
    data: values,
    headers: headers,
    filename: `${filename}.csv`
  };
  return <CSVDownload {...csvReport} />;
}

enum Export {
  CSV = "CSV",
  PDF = "PDF",
  PNG = "PNG"
}

const ExportOptionsDropdown = ({ data, header }: IDataExporter) => {
  const theme = useTheme();
  const [exportType, setExportType] = useState<Export>(Export.CSV);
  const [exportRunning, setExportRunning] = useState<boolean>(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const loader = (
    <Flex alignItems="center">
      <Spin indicator={antIcon}>Exporting..</Spin>
    </Flex>
  );

  const btnStyleOverride = {
    color: theme.colors.whiteTwo,
    padding: 3,
    paddingLeft: 0,
    margin: 0
  };

  const categoryMenuStyle: React.CSSProperties = {
    fontSize: 10,
    fontWeight: 500,
    color: theme.colors.warmGrey,
    pointerEvents: "none",
    cursor: "none"
  };

  const btnStyle: React.CSSProperties = {
    color: theme.colors.black
  };

  const items: MenuProps["items"] = [
    {
      key: "data",
      label: "DATA",
      disabled: true,
      style: categoryMenuStyle
    },
    {
      key: "csv",
      label: Export.CSV,
      style: btnStyle,
      onClick: () => {
        setExportType(Export.CSV);
        setExportRunning(true);
      }
    },
    {
      key: "pdf",
      label: Export.PDF,
      style: btnStyle,
      onClick: () => {
        setExportType(Export.PDF);
        exportPDF(data, header);
      }
    },
    {
      key: "image",
      label: "IMAGE",
      disabled: true,
      style: categoryMenuStyle
    },
    {
      key: "png",
      label: Export.PNG,
      style: btnStyle,
      disabled: true,
      onClick: () => setExportType(Export.PNG)
    }
  ];

  const handleExport = () => {
    switch (exportType) {
      case Export.CSV:
        setExportRunning(true);
        break;
      case Export.PDF:
        exportPDF(data, header);
        break;
      case Export.PNG:
        return;
      default:
        setExportRunning(true);
    }
  };

  return (
    <Flex>
      <React.Suspense fallback={loader}>
        <ElmButton
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          variance={"primary"}
          colorVariance={"primary"}
          label={`Export as ${exportType}`}
          onClick={handleExport}
          disabled={!data.length}
        />
        {exportRunning && (
          <DataExporter
            data={data}
            header={header}
            dispose={() => setExportRunning(false)}
          />
        )}
      </React.Suspense>
      <Dropdown menu={{ items }} trigger={["click"]} disabled={!data.length}>
        <ElmButton
          style={{
            ...btnStyleOverride,
            paddingLeft: 7,
            marginLeft: 1,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
          disabled={!data.length}
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon={"chevron-down"}
          iconPrefix={"fas"}
          label={""}
        />
      </Dropdown>
    </Flex>
  );
};

export default ExportOptionsDropdown;
