/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type newProductFeatureGroupQueryVariables = {
  productId: string;
  featureGroupId: string;
  skip: boolean;
};
export type newProductFeatureGroupQueryResponse = {
  readonly product: {
    readonly name: string | null;
    readonly features: {
      readonly nodes: ReadonlyArray<{
        readonly name: string | null;
        readonly id: string;
        readonly featureGroups: {
          readonly nodes: ReadonlyArray<{
            readonly name: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly featureGroup?:
    | {
        readonly name: string | null;
        readonly features: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
          } | null> | null;
        } | null;
      }
    | null
    | undefined;
};
export type newProductFeatureGroupQuery = {
  readonly response: newProductFeatureGroupQueryResponse;
  readonly variables: newProductFeatureGroupQueryVariables;
};

/*
query newProductFeatureGroupQuery(
  $productId: ID!
  $featureGroupId: ID!
  $skip: Boolean!
) {
  product(id: $productId) {
    name
    features {
      nodes {
        name
        id
        featureGroups {
          nodes {
            name
            id
          }
        }
      }
    }
    id
  }
  featureGroup(id: $featureGroupId) @skip(if: $skip) {
    name
    features {
      nodes {
        id
        name
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "featureGroupId"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "productId"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "skip"
    } as any,
    v3 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "productId"
      } as any
    ],
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v6 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "featureGroupId"
      } as any
    ],
    v7 = {
      alias: null,
      args: null,
      concreteType: "FeatureConnection",
      kind: "LinkedField",
      name: "features",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Feature",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v5 /*: any*/, v4 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: "Fragment",
      metadata: null,
      name: "newProductFeatureGroupQuery",
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "FeatureConnection",
              kind: "LinkedField",
              name: "features",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Feature",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [
                    v4 /*: any*/,
                    v5 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: "FeatureGroupConnection",
                      kind: "LinkedField",
                      name: "featureGroups",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "FeatureGroup",
                          kind: "LinkedField",
                          name: "nodes",
                          plural: true,
                          selections: [v4 /*: any*/],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          condition: "skip",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v6 /*: any*/,
              concreteType: "FeatureGroup",
              kind: "LinkedField",
              name: "featureGroup",
              plural: false,
              selections: [v4 /*: any*/, v7 /*: any*/],
              storageKey: null
            }
          ]
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/, v2 /*: any*/],
      kind: "Operation",
      name: "newProductFeatureGroupQuery",
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "FeatureConnection",
              kind: "LinkedField",
              name: "features",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Feature",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [
                    v4 /*: any*/,
                    v5 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: "FeatureGroupConnection",
                      kind: "LinkedField",
                      name: "featureGroups",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "FeatureGroup",
                          kind: "LinkedField",
                          name: "nodes",
                          plural: true,
                          selections: [v4 /*: any*/, v5 /*: any*/],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v5 /*: any*/
          ],
          storageKey: null
        },
        {
          condition: "skip",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v6 /*: any*/,
              concreteType: "FeatureGroup",
              kind: "LinkedField",
              name: "featureGroup",
              plural: false,
              selections: [v4 /*: any*/, v7 /*: any*/, v5 /*: any*/],
              storageKey: null
            }
          ]
        }
      ]
    },
    params: {
      cacheID: "a63d4b0a9c11246fde9301e380272122",
      id: null,
      metadata: {},
      name: "newProductFeatureGroupQuery",
      operationKind: "query",
      text:
        "query newProductFeatureGroupQuery(\n  $productId: ID!\n  $featureGroupId: ID!\n  $skip: Boolean!\n) {\n  product(id: $productId) {\n    name\n    features {\n      nodes {\n        name\n        id\n        featureGroups {\n          nodes {\n            name\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n  featureGroup(id: $featureGroupId) @skip(if: $skip) {\n    name\n    features {\n      nodes {\n        id\n        name\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "2d4072d1c7c9e03ddf2f771218fc09b6";
export default node;
