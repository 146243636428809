import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 254px;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const DialogSubTitle = styled.p`
  ${props => props.theme.fontType.visualizationSubTitle}
`;
export interface IErrorDialogProps {
  title?: string;
  description?: string;
}
export class ErrorDialog extends DialogBase<IModalProps<IErrorDialogProps>> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "ErrorDialog"
    });
  };
  public getTitle = () => {
    return _.get(this.props.payload, "title") || "Error";
  };
  public getDescription = () => {
    return (
      _.get(this.props.payload, "description") ||
      "elm has experienced an error."
    );
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <DialogSubTitle>{this.getDescription()}</DialogSubTitle>
        <Flex flex={1} justifyContent={"flex-end"} alignItems="flex-end">
          <ElmButton
            label={"Close"}
            colorVariance="outline-secondary"
            onClick={this.closeModal}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
