import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer, GraphQLTaggedNode } from "react-relay";
import { OperationType } from "relay-runtime";
import { getClassName } from "../helpers";
import { AllProductsFilter } from "./filters/allProducts";
import { TimeSpanFilter } from "./filters/timeSpan";
import { getEnvironment } from "../../api/relay";
const filterComponents = {
  AllProductsFilter,
  TimeSpanFilter
};
type filterComponentName = keyof typeof filterComponents;
export type widgetSizeTypes =
  | "x-large"
  | "large"
  | "medium-large"
  | "medium"
  | "small";
export interface IWidgetContainerProps {
  className?: string;
  size: widgetSizeTypes;
  showFilters?: {
    [key in filterComponentName]?: {
      onChange?: (props: any) => void;
      show: boolean;
    };
  };
  showSizeConfig?: boolean;
  children?: any;
}
export class WidgetContainer extends React.Component<IWidgetContainerProps> {
  public getWidgetSizeClass = () => {
    if (this.props.size) {
      return { [this.props.size]: () => !!this.props.size };
    }
    return {};
  };
  public render() {
    return (
      <div
        className={getClassName({
          [this.props.className]: () => !this.props.className,
          "analytics-widget": () => true,
          ...this.getWidgetSizeClass()
        })}
      >
        {this.props.children}
      </div>
    );
  }
}

export function getWidgetContainer<
  queryType extends OperationType,
  variablesType
>() {
  const relayEnvironment = getEnvironment();
  return (props: {
    query: GraphQLTaggedNode;
    variables: variablesType;
    widgetContainerProps: IWidgetContainerProps;
    children: (res: queryType["response"]) => React.ReactNode;
  }) => {
    const renderFn = (payload: {
      error: Error;
      props: queryType["response"];
      retry: () => void;
    }) => {
      return (
        <WidgetContainer
          {...props.widgetContainerProps}
          children={props.children(payload.props)}
          // variables={props.variables}
          // result={payload.props}
          // retry={payload.retry}
          // updateVariables={updateVariables}
        />
      );
    };
    return (
      <QueryRenderer<queryType>
        environment={relayEnvironment}
        variables={props.variables}
        query={props.query}
        render={renderFn}
      />
    );
  };
}
