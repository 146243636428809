import React from "react";
import { useTheme } from "styled-components";
import { MenuProps } from "antd";
import { Dropdown } from "antd";
import { ElmButton } from "components/elmButton";
import { IReportAction } from "Reports/blades/Reports";

const ReportActionsDropdown = ({
  data,
  openEdit,
  duplicateAction,
  deleteAction,
  disabled
}: IReportAction) => {
  const theme = useTheme();
  const btnStyleOverride = {
    color: theme.colors.black,
    padding: 3,
    paddingLeft: 0,
    margin: 0
  };

  const items: MenuProps["items"] = [
    {
      key: "edit",
      onClick: () => {
        openEdit({ id: data.id, name: data.name });
      },
      label: (
        <ElmButton
          style={btnStyleOverride}
          className={"darkModeTransparentBtn"}
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon={"pen"}
          iconPrefix={"far"}
          label={"Edit"}
        />
      )
    },
    {
      key: "copy",
      onClick: () => duplicateAction(),
      label: (
        <ElmButton
          style={btnStyleOverride}
          className={"darkModeTransparentBtn"}
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon={"copy"}
          iconPrefix={"fal"}
          label={"Duplicate"}
        />
      )
    },
    {
      key: "delete",
      onClick: () => deleteAction(),
      label: (
        <ElmButton
          style={btnStyleOverride}
          className={"darkModeTransparentBtn"}
          variance={"plain-icon-button"}
          colorVariance={"subtle"}
          icon={"trash-can"}
          iconPrefix={"far"}
          label={"Delete"}
        />
      )
    }
    // {
    //   key: "send",
    //   label: (
    //     <ElmButton
    //       style={btnStyleOverride}
    //       className={"darkModeTransparentBtn"}
    //       variance={"plain-icon-button"}
    //       colorVariance={"subtle"}
    //       icon={"envelope-open-text"}
    //       iconPrefix={"far"}
    //       label={"Send Report"}
    //     />
    //   )
    // }
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} disabled={disabled}>
      <ElmButton
        style={{
          ...btnStyleOverride,
          paddingLeft: 7,
          marginLeft: 4,
          background: theme.colors.highlight
        }}
        disabled={disabled}
        variance={"plain-icon-button"}
        colorVariance={"subtle"}
        icon={"ellipsis"}
        iconPrefix={"fas"}
        label={""}
      />
    </Dropdown>
  );
};

export default ReportActionsDropdown;
