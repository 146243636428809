import moment from "moment";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { appConnect } from "../../store/appConnect";
import relayEnvironment from "../../api/relay";

import { getIcon } from "../../components/icons";
import {
  BladeSubTitle,
  BladeTemplate,
  BladeTitle,
  ElmButton
} from "../../components";
import { getBladeColor, getSavedRoleTypeFromLocalSync } from "../../utils";
import {
  AnalyticsQuery,
  AnalyticsQueryResponse
} from "./__generated__/AnalyticsQuery.graphql";
import _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";

import { IAnalyticsBladeProps, IAnalyticsBladeState } from "./types";
import LicenseAnalytics from "./licenseAnalytics";
import CompanyAnalytics from "./companyAnalytics";
import CompaniesAnalytics from "./companiesAnalytics";
import LicensesAnalytics from "./licensesAnalytics";
import ProductAnalytics from "./productAnalytics";
import ProductsAnalytics from "./productsAnalytics";
import UserAnalytics from "./userAnalytics";
import UsersAnalytics from "./usersAnalytics";
import AllProductsFilter from "../../components/analyticsComponent/filters/allProducts";
import AllCompaniesFilter from "../../components/analyticsComponent/filters/allCompanies";
import {
  TimeSpanFilter,
  timeSpanDetail,
  getQueryTimeVariables
} from "../../components/analyticsComponent/filters/timeSpan";
import {
  AllowedChartBladeTypes,
  IOpenBladeFromChart
} from "components/bladeManager/types";

const CloseIcon = styled(getIcon("MdClose"))`
  :hover {
    cursor: pointer;
    color: ${props => props.theme.colors.orangeyRed};
  }
`;
const graphqlQuery = graphql`
  query AnalyticsQuery(
    $currentTimeSpanStart: String!
    $currentTimeSpanEnd: String!
    $previousTimeSpanStart: String!
    $previousTimeSpanEnd: String!
    $currentDate: String!
    $previousDate: String!
    $sessionSetup: JSON!
    $userSetup: JSON!
    $productId: String
  ) {
    AnalyticsAllProducts: products {
      nodes {
        name
        id
      }
    }
    AnalyticsUserCounts: analytics(
      filterColumn: ["is_active", "is_vendor"]
      filterValues: [["true"], ["false"]]
      startTime: $previousDate
      endTime: $currentDate
      setup: $userSetup
      bladeScope: $productId
    ) {
      values
    }
    AnalyticsAverageSessionsData: analytics(
      filterColumn: ["is_vendor"]
      filterValues: [["0"]]
      startTime: $previousDate
      setup: $sessionSetup
      bladeScope: $productId
    ) {
      values
    }
    AnalyticsCompaniesCount: companiesCount(bladeScope: $productId)
    AnalyticsProductsCount: productsCount(bladeScope: $productId)
    AnalyticsUsersCount: usersCount(bladeScope: $productId)
    AnalyticsInstancesCount: instancesCount(bladeScope: $productId)

    AnalyticsCurrentActiveLicensesCount: licensesCount(
      filterColumn: ["latestTerm.endDate", "latestTerm.startDate"]
      filterOp: [[">"], ["<", "="]]
      filterValues: [[$currentDate], [$currentDate, $currentDate]]
      bladeScope: $productId
    )
    AnalyticsCurrentExpiringLicenses: licenses(
      filterColumn: ["latestTerm.endDate", "latestTerm.endDate"]
      filterOp: [[">", "="], ["<", "="]]
      filterValues: [
        [$currentTimeSpanStart, $currentTimeSpanStart]
        [$currentTimeSpanEnd, $currentTimeSpanEnd]
      ]
      bladeScope: $productId
    ) {
      nodes {
        type {
          name
          id
        }
      }
    }
    AnalyticsCurrentExpiringLicensesCount: licensesCount(
      filterColumn: ["latestTerm.endDate", "latestTerm.endDate"]
      filterOp: [[">", "="], ["<", "="]]
      filterValues: [
        [$currentTimeSpanStart, $currentTimeSpanStart]
        [$currentTimeSpanEnd, $currentTimeSpanEnd]
      ]
      bladeScope: $productId
    )

    AnalyticsPreviousActiveLicensesCount: licensesCount(
      filterColumn: ["latestTerm.endDate", "latestTerm.startDate"]
      filterOp: [[">", "="], ["<", "="]]
      filterValues: [
        [$previousTimeSpanStart, $previousTimeSpanStart]
        [$previousTimeSpanEnd, $previousTimeSpanEnd]
      ]
      bladeScope: $productId
    )

    AnalyticsCurrentActiveLicenses: licenses(
      filterColumn: ["latestTerm.endDate", "latestTerm.startDate"]
      filterOp: [[">"], ["<", "="]]
      filterValues: [[$currentDate], [$currentDate, $currentDate]]
      bladeScope: $productId
    ) {
      nodes {
        key
        type {
          id
          name
        }
      }
    }
    AnalyticsPreviousActiveLicenses: licenses(
      filterColumn: ["latestTerm.endDate", "latestTerm.startDate"]
      filterOp: [[">"], ["<", "="]]
      filterValues: [[$previousDate], [$previousDate, $previousDate]]
      bladeScope: $productId
    ) {
      nodes {
        key
        type {
          id
          name
        }
      }
    }
  }
`;

export class AnalyticsBlade extends React.Component<
  IAnalyticsBladeProps,
  IAnalyticsBladeState
> {
  state: IAnalyticsBladeState = {
    mountMoment: moment(),
    timeSpanDetail: getQueryTimeVariables({
      filterType: "last7",
      groupBy: "day"
    }),
    productId: null,
    companyId: null
  };
  public isVendor = () =>
    getSavedRoleTypeFromLocalSync() === "vendor" ||
    _.get(this.props, "appState.activeRole.type") === "vendor";

  public navAway = () => {
    this.props.closeBlade({ fromBladeIndex: this.props.index });
  };
  public renderLeftSideHeader = () => {
    let title = "";
    let subTitle = "";
    let imageSrc = "";
    if (this.props.routeData) {
      title = this.props.routeData.title;
      subTitle = this.props.routeData.subTitle;
      imageSrc = this.props.routeData.imageSrc;
    }
    return (
      <Flex alignItems="center">
        {imageSrc ? (
          <div style={{ width: "24px", height: "24px", marginRight: "10px" }}>
            <img src={imageSrc} height="100%" width="100%" alt="logo" />
          </div>
        ) : null}
        <Flex flexDirection="column">
          <BladeTitle>{title}</BladeTitle>
          <BladeSubTitle>{subTitle}</BladeSubTitle>
        </Flex>
      </Flex>
    );
  };
  public renderRightSideHeader = () => {
    return !this.isVendor() ? (
      <>
        {this.renderTimeSpanSelections()}
        {/* <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon={"download"}
          iconPrefix={"far"}
          label={"Report"}
          disabled={true}
        /> */}
      </>
    ) : null;
  };
  public renderLicenseAnalytics = () => {
    return (
      <LicenseAnalytics
        variables={{
          ...this.state.timeSpanDetail,
          timeRangeOverride: this.state.timeSpanDetail.filterType,
          allTimeFilterStart: this.props.routeData?.allTimeStartDate
        }}
        timespan={this.state.timeSpanDetail.groupBy}
        licenseId={this.props.routeData.id}
        openBlade={this.openBladeFromChart}
      />
    );
  };
  public renderProductAnalytics = () => {
    return (
      <ProductAnalytics
        variables={{
          ...this.state.timeSpanDetail,
          productId: this.props.routeData.id,
          timeRangeOverride: this.state.timeSpanDetail.filterType,
          allTimeFilterStart: this.props.routeData?.allTimeStartDate
        }}
        timespan={this.state.timeSpanDetail.groupBy}
        openBlade={this.openBladeFromChart}
      />
    );
  };
  public renderLicensesAnalytics = () => {
    return (
      <LicensesAnalytics
        variables={{
          ...this.state.timeSpanDetail,
          productId: this.state.productId,
          timeRangeOverride: this.state.timeSpanDetail.filterType
        }}
        productId={this.state.productId}
        timespan={this.state.timeSpanDetail.groupBy}
      />
    );
  };
  public renderOverviewAnalytics = () => {
    return <div>Dashboard</div>;
  };
  public renderCompanyAnalytics = () => {
    return (
      <CompanyAnalytics
        variables={{
          ...this.state.timeSpanDetail,
          timeRangeOverride: this.state.timeSpanDetail.filterType,
          allTimeFilterStart: this.props.routeData?.allTimeStartDate
        }}
        timespan={this.state.timeSpanDetail.groupBy}
        companyId={this.props.routeData.id}
        openBlade={this.openBladeFromChart}
      />
    );
  };
  public renderCompaniesAnalytics = () => {
    return (
      <CompaniesAnalytics
        variables={{
          ...this.state.timeSpanDetail,
          timeRangeOverride: this.state.timeSpanDetail.filterType
        }}
        timespan={this.state.timeSpanDetail.groupBy}
        componentId={this.props.routeData.id}
      />
    );
  };
  public renderUserAnalytics = () => {
    return (
      <UserAnalytics
        variables={{
          ...this.state.timeSpanDetail,
          productId: this.state.productId,
          timeRangeOverride: this.state.timeSpanDetail.filterType,
          allTimeFilterStart: this.props.routeData?.allTimeStartDate
        }}
        userId={this.props.routeData.id}
        timespan={this.state.timeSpanDetail.groupBy}
      />
    );
  };
  public renderProductsAnalytics = () => {
    return (
      <ProductsAnalytics
        variables={{
          ...this.state.timeSpanDetail,
          productId: this.state.productId,
          timeRangeOverride: this.state.timeSpanDetail.filterType
        }}
        productId={this.state.productId}
        companyId={this.state.companyId}
        timespan={this.state.timeSpanDetail.groupBy}
        openBlade={this.openBladeFromChart}
      />
    );
  };
  public renderUsersAnalytics = () => {
    return (
      <UsersAnalytics
        variables={{
          ...this.state.timeSpanDetail,
          productId: this.state.productId,
          timeRangeOverride: this.state.timeSpanDetail.filterType
        }}
        productId={this.state.productId}
        timespan={this.state.timeSpanDetail.groupBy}
        openBlade={this.openBladeFromChart}
      />
    );
  };
  public openBladeFromChart = (payload: IOpenBladeFromChart) => {
    if (!payload?.id || !AllowedChartBladeTypes.includes(payload.bladeType)) {
      return;
    }
    this.props.openBlade({
      route: payload.bladeType,
      routeName: `${payload.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: payload.id
      }
    });
  };
  public renderCharts = () => {
    const renderMethodForContentnName = `render${_.startCase(
      this.props.routeData.type
    )}Analytics`;
    if (_.isFunction(this[renderMethodForContentnName])) {
      return this[renderMethodForContentnName]();
    }
  };

  public renderAllProducts = () => {
    const handleProductChange = (productId: string) => {
      this.setState({ productId });
    };
    switch (this.props.routeData.type) {
      case "Products":
      case "Licenses":
      case "Users":
        return <AllProductsFilter onChange={handleProductChange} />;
    }
    return null;
  };
  public renderAllCompanies = () => {
    if (this.props.routeData.type === "Products") {
      const handleCompaniesChange = (companyId: string) => {
        this.setState({ companyId });
      };
      return <AllCompaniesFilter onChange={handleCompaniesChange} />;
    }
    return null;
  };
  public renderTimeSpanSelections = () => {
    const handleTimeSpanChange = (timeSpanDetail: timeSpanDetail) => {
      this.setState({ timeSpanDetail });
    };
    return (
      <TimeSpanFilter
        timeSpan={this.state.timeSpanDetail.filterType}
        onChange={handleTimeSpanChange}
      />
    );
    // if (!this.props.result || !this.props.result.AnalyticsAllProducts) {
    //   return null;
    // }
    // const handleChange = (payload: any) => {
    //   switch (payload as string) {
    //     case 'Monthly':
    //       this.props.updateVariables({
    //         ...(this.props.variables as IQueryVariables),
    //         ...getQueryTimeVariables('month'),
    //         sessionSetup: {
    //           category: 'time.month',
    //           group: 'user_id',
    //           value: 'sessions.usage_count',
    //         },
    //       });
    //       break;
    //     case 'Weekly':
    //       this.props.updateVariables({
    //         ...(this.props.variables as IQueryVariables),
    //         ...getQueryTimeVariables('week'),
    //         sessionSetup: {
    //           category: 'time.week',
    //           group: 'user_id',
    //           value: 'sessions.usage_count',
    //         },
    //       });
    //       break;
    //     case 'Daily':
    //       this.props.updateVariables({
    //         ...(this.props.variables as IQueryVariables),
    //         ...getQueryTimeVariables('day'),
    //         sessionSetup: {
    //           category: 'time.day',
    //           group: 'user_id',
    //           value: 'sessions.usage_count',
    //         },
    //       });
    //       break;
    //   }
    // };
    // const getValue = () => {
    //   const variables = this.props.variables as IQueryVariables;
    //   switch (variables.sessionSetup.category) {
    //     case 'time.day':
    //       return 'Daily';
    //     case 'time.week':
    //       return 'Weekly';
    //     case 'time.month':
    //       return 'Monthly';
    //     default:
    //       return 'Weekly';
    //   }
    // };
    // return (
    //   <ElmSelect onChange={handleChange} value={getValue()}>
    //     {_.map(['Daily', 'Weekly', 'Monthly'], (timespan) => {
    //       return <ElmSelectOption key={timespan}>{timespan}</ElmSelectOption>;
    //     })}
    //   </ElmSelect>
    // );
  };
  public render() {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"Analytics"}
        bladeType={"Analytics"}
        closeBlade={this.props.closeBlade}
        accentColor={
          this.props.routeData
            ? getBladeColor(this.props.routeData.type)
            : "mango"
        }
        // topAccentColor={
        //   this.props.routeData
        //     ? getBladeColor(this.props.routeData.type)
        //     : "mango"
        // }
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        className="analytics-blade"
      >
        <div className={`analytics-body ${this.props.routeData.type}`}>
          <div className="analytics-action-bar">
            <span className="status-update-text">{`Last updated: ${this.state.mountMoment.fromNow()}`}</span>
            <div className="selections-container">
              {this.renderAllCompanies()}
              {this.renderAllProducts()}
              {this.isVendor() ? this.renderTimeSpanSelections() : null}
            </div>
          </div>
          {this.renderCharts()}
        </div>
      </BladeTemplate>
    );
  }
}
const RenderQuery = (props: IAnalyticsBladeProps) => {
  const [variables, updateVariables] = React.useState({
    ...getQueryTimeVariables({
      filterType: "last7",
      groupBy: "day",
      allTimeStart: moment(props.routeData?.allTimeStartDate)
    }),

    sessionSetup: {
      category: "time.day",
      group: "user_id",
      value: "sessions.usage_count"
    },
    userSetup: {
      category: "license_id",
      group: "licenses.type",
      value: "counts.active_user"
    }
  });
  const renderBlade = (payload: {
    error: Error;
    props: AnalyticsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <AnalyticsBlade
        {...props}
        variables={variables}
        result={payload.props}
        retry={payload.retry}
        updateVariables={updateVariables}
      />
    );
  };
  return (
    <QueryRenderer<AnalyticsQuery>
      environment={relayEnvironment}
      variables={variables}
      query={graphqlQuery}
      render={renderBlade}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
