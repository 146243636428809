/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type instanceHistoryTable_instance = {
  readonly instance: {
    readonly modificationRecords: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly enabled: number;
          readonly createdAt: unknown;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $refType": "instanceHistoryTable_instance";
};
export type instanceHistoryTable_instance$data = instanceHistoryTable_instance;
export type instanceHistoryTable_instance$key = {
  readonly " $data"?: instanceHistoryTable_instance$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"instanceHistoryTable_instance">;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: 6,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: "cursor",
        direction: "forward",
        path: ["instance", "modificationRecords"]
      }
    ]
  },
  name: "instanceHistoryTable_instance",
  selections: [
    {
      alias: null,
      args: [
        {
          kind: "Variable",
          name: "id",
          variableName: "id"
        }
      ],
      concreteType: "Instance",
      kind: "LinkedField",
      name: "instance",
      plural: false,
      selections: [
        {
          alias: "modificationRecords",
          args: null,
          concreteType: "ModificationRecordConnection",
          kind: "LinkedField",
          name: "__instanceHistoryTable_modificationRecords_connection",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ModificationRecordEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ModificationRecord",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "enabled",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "createdAt",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "331b3b6f9a14b15f7d091d6fe4fbe4f3";
export default node;
