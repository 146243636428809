import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import { userInstallsTable_installs } from "./__generated__/userInstallsTable_installs.graphql";
import {
  IGeneratedTableProps,
  tableGenerator
} from "../../../utils/tableGenerator";
import { TableCellProps } from "react-virtualized";
import { getIcon } from "components";
import styled from "styled-components";
import React from "react";
import moment from "moment";
import { Flex } from "reflexbox";
import { LoaderContainer } from "components/helpers";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "api/relay";
import {
  userInstallsTableQuery,
  userInstallsTableQueryResponse
} from "./__generated__/userInstallsTableQuery.graphql";
import { shallowEqual } from "fast-equals";

export type userInstallEdges = userInstallsTable_installs["installs"]["edges"];
export type userInstallsTableType = userInstallEdges[number]["node"];
export type IUserInstallsProps = {
  installs: userInstallsTable_installs;
};

const rendervalidationEntriesInfo = (payload: TableCellProps, key: string) => {
  const nodes = _.get(payload, "cellData.nodes", []);
  if (nodes.length) {
    const info = _.first(nodes);
    if (key === "version") return info[key];
    return info[key] ? moment(info[key]).format("MM/DD/YYYY") : "";
  }
  return "";
};

const renderActiveStatus = (payload: TableCellProps) => {
  const ApproveIcon = styled(getIcon("circle-check", "fas"))`
    width: 16px;
    height: 16px;
    color: #4dbf40;
  `;
  const RejectIcon = styled(getIcon("circle-xmark", "fal"))`
    width: 16px;
    height: 16px;
    color: #707070;
    margin-right: 8px;
  `;
  const isActive = _.get(payload, "cellData", false);
  if (isActive) return <ApproveIcon />;
  return <RejectIcon />;
};

const GeneratedTable = tableGenerator<IUserInstallsProps>({
  dataKey: "installs.user.installs",
  columns: [
    {
      Header: "Product",
      accessor: "license.product.name",
      sortKey: "license.product.name"
    },
    {
      Header: "Device",
      accessor: "hostName",
      disableSortBy: true
    },
    {
      Header: "Version",
      accessor: "validationEntries",
      disableSortBy: true,
      cellRenderer: props => rendervalidationEntriesInfo(props, "version")
    },
    {
      Header: "Last used",
      accessor: "validationEntries",
      sortKey: "validationEntries",
      cellRenderer: props => rendervalidationEntriesInfo(props, "createdAt")
      //isDateFormat: true,
    },
    {
      Header: "ID",
      accessor: "guid",
      disableSortBy: true,
      width: 0.15
    },
    {
      Header: "Active",
      accessor: "active",
      disableSortBy: true,
      cellRenderer: props => renderActiveStatus(props),
      width: 0.1
    }
  ],
  connectionQuery: graphql`
    query userInstallsTablePaginationQuery(
      $id: ID!
      $count: Int!
      $cursor: String
      $search: String
      $blade_scope: String
      $sortColumn: [String!]
      $sortDirection: [String!]
    ) {
      ...userInstallsTable_installs
        @arguments(
          id: $id
          count: $count
          cursor: $cursor
          blade_scope: $blade_scope
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        )
    }
  `,
  fragmentSpec: {
    installs: graphql`
      fragment userInstallsTable_installs on Query
        @argumentDefinitions(
          id: { type: "ID!" }
          count: { type: "Int", defaultValue: null }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
          blade_scope: { type: "String", defaultValue: null }
          sortColumn: { type: "[String!]", defaultValue: ["hostname"] }
          sortDirection: { type: "[String!]", defaultValue: ["ASC"] }
        ) {
        user(id: $id) {
          installs: instances(
            first: $count
            after: $cursor
            search: $search
            bladeScope: $blade_scope
            sortColumn: $sortColumn
            sortDirection: $sortDirection
          ) @connection(key: "userInstallsTable_installs") {
            edges {
              node {
                id
                guid
                license {
                  product {
                    name
                  }
                }
                hostName
                validationEntries(last: 1) {
                  nodes {
                    createdAt
                    version
                  }
                }
                active
              }
            }
          }
        }
      }
    `
  },
  tableName: "userProductHistory"
});

const graphqlQuery = graphql`
  query userInstallsTableQuery($id: ID!) {
    ...userInstallsTable_installs @arguments(id: $id)
  }
`;

class UserInstallsPaginationTable extends React.Component<
  IGeneratedTableProps & {
    result: userInstallsTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        installs={res}
      />
    );
  }
}

export const UserInstallsTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: userInstallsTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <UserInstallsPaginationTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<userInstallsTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.bladeScope || ""
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default UserInstallsTable;
