import { Collapse } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import { Flex } from "reflexbox/styled-components";
import * as React from "react";
import relayEnvironment from "../../../api/relay";
import styled, { useTheme } from "styled-components";
import { QueryRenderer } from "react-relay";
import Loader from "react-loaders";
import Fuse from "fuse.js";
import { ElmToggle } from "../../../components/elmToggle";
import {
  InfoMessage,
  LoaderContainer,
  SearchBar
} from "../../../components/helpers";
import {
  instanceFeaturesAndGroupsQuery,
  instanceFeaturesAndGroupsQueryResponse
} from "./__generated__/instanceFeaturesAndGroupsQuery.graphql";
import { EntityRefreshMap } from "components/bladeManager/entityRefreshMap";
import { ElmButton } from "components";
import {
  InfoContainer,
  InfoIcon
} from "Users/components/userFeaturesAndGroups";
import {
  featureGroupType,
  featureType
} from "Licenses/components/featuresAndGroups";
import { BulbContainer, BulbIcon } from "login";

interface IFeaturesAndGroupsProps {
  id: string;
  isEditEnabled?: boolean;
  disableLockOption?: boolean;
  instance: instanceFeaturesAndGroupsQueryResponse["instance"];
  retry: () => void;
  onChange?: (payload: {
    type: "feature" | "group";
    id: string;
    name: string;
    value: boolean;
    updateQuery: () => void;
  }) => void;
}
interface IFeaturesAndGroupsState {
  search_term: string;
  isEditEnabled: boolean;
  hideInfoMessage: boolean;
}
const StyledCollapse = styled(Collapse)`
  background-color: transparent;
  margin-bottom: 6px;
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
  }
  .ant-collapse-item {
    border: none;
    color: ${props => props.theme.colors.textPrimary};
  }
  .header-text {
    min-width: 291px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
  }
`;
const FeatureTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  margin-left: 16px;
`;
const FeatureLabel = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 16.5px;
`;

const { Panel } = Collapse;
const StyledLoader = styled(Loader)`
  .loader-inner {
    div {
      border-color: ${props => props.theme.colors.lightishBlue};
      width: 15px;
      height: 15px;
    }
  }
`;

const ToggleRow = (props: {
  row: featureGroupType | featureType;
  type: "group" | "feature";
  disabled: boolean;
  isChecked: (payload: { id: string; type: "feature" | "group" }) => boolean;
  reportChange: (
    type: "group" | "feature",
    id: string,
    name: string
  ) => (value: boolean) => void;
}) => {
  const isChecked = props.isChecked({
    id: props.row.id,
    type: props.type
  });
  const [requestState, updateRequestState] = React.useState({
    requesting: false,
    toggleState: isChecked
  });
  const handleChange = (value: boolean) => {
    updateRequestState({ requesting: true, toggleState: value });
    props.reportChange(props.type, props.row.id, props.row.name)(value);
  };
  const renderLoader = () => {
    if (requestState.requesting && isChecked !== requestState.toggleState) {
      return (
        <StyledLoader type="ball-clip-rotate" active={true} color={"#0F62FE"} />
      );
    }
    return null;
  };
  if (props.type === "feature") {
    return (
      <Flex height="31px">
        <FeatureTitle style={{ minWidth: "310px" }}>
          {props.row.name}
        </FeatureTitle>
        <Flex lineHeight="24px">
          <ElmToggle
            size={"small"}
            checked={isChecked}
            disabled={props.disabled}
            onChange={handleChange}
          />
          {renderLoader()}
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex height="31px">
      <span className={"header-text"}>{props.row.name}</span>
      <ElmToggle
        size={"small"}
        checked={isChecked}
        disabled={props.disabled}
        onChange={handleChange}
      />
      {renderLoader()}
    </Flex>
  );
};
export class FeaturesAndGroups extends React.Component<
  IFeaturesAndGroupsProps & { theme: any },
  IFeaturesAndGroupsState
> {
  state: IFeaturesAndGroupsState = {
    search_term: "",
    isEditEnabled: this.props.isEditEnabled || false,
    hideInfoMessage: false
  };
  public reportChange = (
    type: "group" | "feature",
    id: string,
    name: string
  ) => (value: boolean) => {
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange({
        type,
        id,
        name,
        value,
        updateQuery: this.props.retry
      });
    }
  };
  public isChecked = (payload: { id: string; type: "feature" | "group" }) => {
    if (payload.type === "feature") {
      const featureExists = this.props.instance.features?.nodes?.find(
        f => f.id === payload.id
      );
      if (featureExists) {
        return true;
      }
    }
    if (payload.type === "group") {
      const groupExists = this.props.instance.featureGroups?.nodes?.find(
        f => f.id === payload.id
      );
      if (groupExists) {
        return true;
      }
    }
    return false;
  };
  public renderFeatures = () => {
    const featuresWithoutGroups = _.map(
      this.getFeaturesWithoutGroups(),
      (feature: featureType) => (
        <ToggleRow
          isChecked={this.isChecked}
          reportChange={this.reportChange}
          disabled={
            this.props.disableLockOption
              ? !this.props.isEditEnabled
              : !this.state.isEditEnabled
          }
          row={feature}
          type={"feature"}
        />
      )
    );
    return featuresWithoutGroups?.length ? (
      <Flex
        style={{
          flexDirection: "column",
          color: this.props.theme.colors.textPrimary
        }}
      >
        <FeatureLabel>Other features available in the product.</FeatureLabel>
        <div
          style={{
            minHeight: "max-content",
            width: "100%"
          }}
        >
          {featuresWithoutGroups}
        </div>
      </Flex>
    ) : null;
  };
  public renderFeatureGroups = () => {
    let groupNodes = _.get(
      this.props.instance?.license?.product,
      "featureGroups.nodes"
    );
    if (this.state.search_term) {
      const results = new Fuse(
        _.get(this.props.instance?.license?.product, "featureGroups.nodes"),
        { keys: ["name"] }
      ).search(this.state.search_term);
      groupNodes = _.map(results, i => i.item);
    }
    return (
      <StyledCollapse bordered={false} accordion={true}>
        {_.map(groupNodes, (featureGroup: featureGroupType) => {
          return (
            <Panel
              header={
                <ToggleRow
                  isChecked={this.isChecked}
                  reportChange={this.reportChange}
                  disabled={
                    this.props.disableLockOption
                      ? !this.props.isEditEnabled
                      : !this.state.isEditEnabled
                  }
                  row={featureGroup}
                  type={"group"}
                />
              }
              key={featureGroup.id}
              className={"featureGroup"}
            >
              <div
                style={{
                  paddingLeft: "23px",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                {_.map(featureGroup.features.nodes, feature => {
                  return <span>{feature.name}</span>;
                })}
              </div>
            </Panel>
          );
        })}
      </StyledCollapse>
    );
  };
  public getFeaturesWithoutGroups = () => {
    const productFeatures = _.get(
      this.props.instance?.license?.product,
      "features.nodes"
    );
    let featuresWithoutGroups = _.filter(
      productFeatures,
      feature => !feature.featureGroupsCount
    );
    if (this.state.search_term) {
      const results = new Fuse(featuresWithoutGroups, {
        keys: ["name"]
      }).search(this.state.search_term);
      featuresWithoutGroups = _.map(results, i => i.item);
    }
    return featuresWithoutGroups;
  };
  public updateSearchTerm = (search_term: string) => {
    this.setState({ search_term });
  };
  public unlockEditFeatures = () => {
    this.setState({ isEditEnabled: true });
  };
  public render() {
    return (
      <Flex flexDirection={"column"} flex={1} width={"100%"} height={"100%"}>
        {this.props.disableLockOption ||
        this.state.isEditEnabled ||
        this.state.hideInfoMessage ? null : (
          <InfoMessage
            style={{ width: "100%", maxWidth: "unset", marginBottom: 12 }}
            description={
              <div style={{ display: "flex", alignItems: "center" }}>
                <InfoContainer>
                  <InfoIcon />
                </InfoContainer>
                {`All the changes you are making will be applied to this user and to this installation only. Use unlock button below to enable editing.`}
              </div>
            }
            type="warning"
            action={
              <ElmButton
                colorVariance="subtle"
                style={{
                  color: this.props.theme.colors.black,
                  width: 32,
                  paddingLeft: 14
                }}
                onClick={() => this.setState({ hideInfoMessage: true })}
                label=""
                variance="plain-icon-button"
                permissions="modify_assets"
                icon="close"
                iconPrefix="far"
              />
            }
          />
        )}
        <Flex alignContent={"center"}>
          <SearchBar
            className="elm-table-search-bar"
            onSearch={this.updateSearchTerm}
            style={{ marginBottom: 12 }}
          />
          {this.props.disableLockOption || this.state.isEditEnabled ? null : (
            <ElmButton
              onClick={this.unlockEditFeatures}
              colorVariance="subtle"
              style={{ color: this.props.theme.colors.black, width: 28 }}
              label=""
              variance="plain-icon-button"
              permissions="modify_assets"
              icon="unlock-keyhole"
              iconPrefix="far"
            />
          )}
        </Flex>
        <Flex
          flexDirection={"column"}
          height={"auto"}
          maxHeight={380}
          marginBottom={12}
          style={{ overflowY: "auto" }}
        >
          {this.renderFeatureGroups()}
          {this.renderFeatures()}
        </Flex>
        <InfoMessage
          icon={
            <BulbContainer>
              <BulbIcon />
            </BulbContainer>
          }
          showIcon
          description="Create feature groups for the product insted of adding individual features to the license."
          type="info"
          style={{ width: "100%", maxWidth: "100%" }}
        />
      </Flex>
    );
  }
}

const graphqlQuery = graphql`
  query instanceFeaturesAndGroupsQuery($id: ID!) {
    instance(id: $id) {
      license {
        product {
          features {
            nodes {
              id
              name
              featureGroupsCount
            }
          }
          featureGroups {
            nodes {
              id
              name
              features {
                nodes {
                  id
                  name
                }
              }
            }
          }
        }
      }
      features {
        nodes {
          id
        }
      }
      featureGroups {
        nodes {
          id
        }
      }
    }
  }
`;
const RenderQuery = (props: {
  id: string;
  isEditEnabled?: boolean;
  disableLockOption?: boolean;
  setRefreshFn: (payload: () => void) => void;
  refreshKey: EntityRefreshMap;
  removeRefreshFn: (entity: EntityRefreshMap) => void;
  onChange?: (payload: {
    type: "feature" | "group";
    id: string;
    name: string;
    value: boolean;
    updateQuery: () => void;
  }) => void;
}) => {
  const theme = useTheme();
  const renderFeatures = (payload: {
    props: instanceFeaturesAndGroupsQueryResponse;
    retry: () => void;
  }) => {
    return payload.props ? (
      <FeaturesAndGroups
        id={props.id}
        isEditEnabled={props.isEditEnabled}
        disableLockOption={props.disableLockOption}
        instance={_.get(payload.props, "instance")}
        onChange={props.onChange}
        retry={payload.retry}
        theme={theme}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<instanceFeaturesAndGroupsQuery>
      variables={{ id: props.id }}
      query={graphqlQuery}
      render={renderFeatures}
      environment={relayEnvironment}
    />
  );
};

export default RenderQuery;
