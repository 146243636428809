/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type producReleaseConfigurationsTablePaginationQueryVariables = {
  count: number;
  cursor?: string | null | undefined;
  search?: string | null | undefined;
  bladeScope: string;
};
export type producReleaseConfigurationsTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<
    "producReleaseConfigurationsTable_releaseConfigurations"
  >;
};
export type producReleaseConfigurationsTablePaginationQuery = {
  readonly response: producReleaseConfigurationsTablePaginationQueryResponse;
  readonly variables: producReleaseConfigurationsTablePaginationQueryVariables;
};

/*
query producReleaseConfigurationsTablePaginationQuery(
  $count: Int!
  $cursor: String
  $search: String
  $bladeScope: String!
) {
  ...producReleaseConfigurationsTable_releaseConfigurations_2ufnpq
}

fragment producReleaseConfigurationsTable_releaseConfigurations_2ufnpq on Query {
  releaseConfigurations(bladeScope: $bladeScope, first: $count, after: $cursor, search: $search) {
    edges {
      node {
        id
        name
        releasesCount
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "bladeScope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    } as any,
    v4 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "bladeScope"
    } as any,
    v5 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v6 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      v4 /*: any*/,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      v5 /*: any*/
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "producReleaseConfigurationsTablePaginationQuery",
      selections: [
        {
          args: [
            v4 /*: any*/,
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v5 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "producReleaseConfigurationsTable_releaseConfigurations"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v0 /*: any*/
      ],
      kind: "Operation",
      name: "producReleaseConfigurationsTablePaginationQuery",
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: "ReleaseConfigurationConnection",
          kind: "LinkedField",
          name: "releaseConfigurations",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfigurationEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseConfiguration",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "name",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "releasesCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v6 /*: any*/,
          filters: ["bladeScope", "search"],
          handle: "connection",
          key: "producReleaseConfigurationsTable_releaseConfigurations",
          kind: "LinkedHandle",
          name: "releaseConfigurations"
        }
      ]
    },
    params: {
      cacheID: "5870a2e06641f5d087aaaaf1a6fa966e",
      id: null,
      metadata: {},
      name: "producReleaseConfigurationsTablePaginationQuery",
      operationKind: "query",
      text:
        "query producReleaseConfigurationsTablePaginationQuery(\n  $count: Int!\n  $cursor: String\n  $search: String\n  $bladeScope: String!\n) {\n  ...producReleaseConfigurationsTable_releaseConfigurations_2ufnpq\n}\n\nfragment producReleaseConfigurationsTable_releaseConfigurations_2ufnpq on Query {\n  releaseConfigurations(bladeScope: $bladeScope, first: $count, after: $cursor, search: $search) {\n    edges {\n      node {\n        id\n        name\n        releasesCount\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "d5591d55e368fff5fa4aaff40fc45ccb";
export default node;
