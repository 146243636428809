/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type componentsSelectQueryVariables = {
  id: string;
  skip: boolean;
};
export type componentsSelectQueryResponse = {
  readonly product?:
    | {
        readonly components: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
          } | null> | null;
        } | null;
      }
    | null
    | undefined;
};
export type componentsSelectQuery = {
  readonly response: componentsSelectQueryResponse;
  readonly variables: componentsSelectQueryVariables;
};

/*
query componentsSelectQuery(
  $id: ID!
  $skip: Boolean!
) {
  product(id: $id) @skip(if: $skip) {
    components {
      nodes {
        id
        name
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "skip"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "name",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "componentsSelectQuery",
      selections: [
        {
          condition: "skip",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [v3 /*: any*/],
              storageKey: null
            }
          ]
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "componentsSelectQuery",
      selections: [
        {
          condition: "skip",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [v3 /*: any*/, v2 /*: any*/],
              storageKey: null
            }
          ]
        }
      ]
    },
    params: {
      cacheID: "ea4e8f1de5a24f8e6f013cce8c431ac3",
      id: null,
      metadata: {},
      name: "componentsSelectQuery",
      operationKind: "query",
      text:
        "query componentsSelectQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  product(id: $id) @skip(if: $skip) {\n    components {\n      nodes {\n        id\n        name\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "4dcb60c0879ed6a26ba7de40d19cf1dd";
export default node;
