/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type userFeaturesAndGroupsQueryVariables = {
  bladeScope: string;
};
export type userFeaturesAndGroupsQueryResponse = {
  readonly instances: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly guid: string;
      readonly hostName: string | null;
      readonly active: boolean;
      readonly license: {
        readonly product: {
          readonly name: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};
export type userFeaturesAndGroupsQuery = {
  readonly response: userFeaturesAndGroupsQueryResponse;
  readonly variables: userFeaturesAndGroupsQueryVariables;
};

/*
query userFeaturesAndGroupsQuery(
  $bladeScope: String!
) {
  instances(bladeScope: $bladeScope) {
    nodes {
      id
      guid
      hostName
      active
      license {
        product {
          name
          id
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "bladeScope"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "bladeScope"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "guid",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "hostName",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "active",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "userFeaturesAndGroupsQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "InstanceConnection",
          kind: "LinkedField",
          name: "instances",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Instance",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "License",
                  kind: "LinkedField",
                  name: "license",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Product",
                      kind: "LinkedField",
                      name: "product",
                      plural: false,
                      selections: [v6 /*: any*/],
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "userFeaturesAndGroupsQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "InstanceConnection",
          kind: "LinkedField",
          name: "instances",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "Instance",
              kind: "LinkedField",
              name: "nodes",
              plural: true,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: "License",
                  kind: "LinkedField",
                  name: "license",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Product",
                      kind: "LinkedField",
                      name: "product",
                      plural: false,
                      selections: [v6 /*: any*/, v2 /*: any*/],
                      storageKey: null
                    },
                    v2 /*: any*/
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "73a9d5d5c3dad7a1108852f09156c2e8",
      id: null,
      metadata: {},
      name: "userFeaturesAndGroupsQuery",
      operationKind: "query",
      text:
        "query userFeaturesAndGroupsQuery(\n  $bladeScope: String!\n) {\n  instances(bladeScope: $bladeScope) {\n    nodes {\n      id\n      guid\n      hostName\n      active\n      license {\n        product {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "a8d33b6b63eba79c57dc351458860555";
export default node;
