import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import { usersTable_users } from "./__generated__/usersTable_users.graphql";
import { usersTable_products } from "./__generated__/usersTable_products.graphql";
import { usersTable_companies } from "./__generated__/usersTable_companies.graphql";
import { tableGenerator } from "../../../utils";
import { TableCellProps } from "react-virtualized";
import renderProductImages from "../../../components/productImages";
import styled from "styled-components";
import { getDateTimeFormatted } from "components/elmTable/helpers";
import { IColumn } from "components/elmTable";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { Flex } from "reflexbox";
import { LoaderContainer } from "components/helpers";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import {
  usersTableQuery,
  usersTableQueryResponse
} from "./__generated__/usersTableQuery.graphql";
import { shallowEqual } from "fast-equals";

export type userTableEdges = usersTable_users["usersTableUsers"]["edges"];
export type userTableType = userTableEdges[number]["node"];
export type IUserTableProps = {
  users: usersTable_users;
  products: usersTable_products;
  companies: usersTable_companies;
};

const ActiveSession = styled.span`
  color: ${props => props.theme.colors.userGreen};
`;

const renderProductImageAndName = (payload: TableCellProps) => {
  const products = payload.rowData.products.nodes;
  const retVal = renderProductImages(products);
  return retVal;
};

const renderLastActiveSession = (payload: TableCellProps) => {
  if (payload.rowData?.hasActiveSession) {
    return <ActiveSession>Active now</ActiveSession>;
  }
  return getDateTimeFormatted(payload.rowData?.lastActiveSession?.endTime);
};

export enum UsersTableColumns {
  Status = "Status",
  Enable = "Enable",
  User = "User",
  Company = "Company",
  Product = "Product",
  Email = "Email",
  LastActiveSession = "Last active session",
  Installs = "Installs",
  Actions = "Actions"
}
export const UsersTableColumnConfig: Record<UsersTableColumns, IColumn> = {
  Status: {
    Header: "Status",
    accessor: "active",
    show: false,
    filterable: {
      dataKey: "active",
      filterName: "active",
      filterGenerator: payload => {
        if (
          payload.filterColumnSelection.includes("Inactive") &&
          payload.filterColumnSelection.includes("Active")
        ) {
          payload.filterState.filterColumn = null;
          payload.filterState.filterValues = null;
          payload.filterState.filterOp = null;
          return payload.filterState;
        }
        if (payload.filterColumnSelection.includes("Active")) {
          payload.filterState.filterColumn.push("users.active");
          payload.filterState.filterOp.push(["="]);
          payload.filterState.filterValues = [["true"]];
        }

        if (payload.filterColumnSelection.includes("Inactive")) {
          payload.filterState.filterColumn.push("users.active");
          payload.filterState.filterOp.push(["="]);
          payload.filterState.filterValues = [["false"]];
        }
        return payload.filterState;
      },
      overrideData: () => ["Active", "Inactive"],
      defaultSelected: ["Active"],
      dataMap: payload => payload,
      force: true
    }
  },
  Enable: {
    Header: "Enable",
    accessor: "",
    disableSortBy: true,
    sortKey: null,
    width: 0.075
  },
  User: {
    Header: "User",
    accessor: "name",
    disableSortBy: true,
    width: 0.2
  },
  Company: {
    Header: "Company",
    accessor: "owner.name",
    show: true,
    filterable: {
      filterName: "owner.name",
      dataKey: "companies.usersTableCompaniesList.edges",
      dataMap: payload => {
        return _.get(payload, "node.name");
      }
    },
    disableSortBy: true
  },
  Product: {
    Header: "Product",
    accessor: "product.name",
    show: true,
    cellRenderer: payload => renderProductImageAndName(payload),
    filterable: {
      filterName: "product.name",
      dataKey: "products.usersTableProductsList.edges",
      dataMap: payload => {
        return _.get(payload, "node.name");
      }
    },
    disableSortBy: true
  },
  Email: {
    Header: "Email",
    accessor: "email",
    disableSortBy: true,
    width: 0.25
  },
  LastActiveSession: {
    Header: "Last active session",
    accessor: "lastActiveSession",
    isNumber: true,
    disableSortBy: true,
    isDateFormat: true,
    width: 0.25,
    cellRenderer: payload => renderLastActiveSession(payload)
  },
  Installs: {
    Header: "Installs",
    accessor: "instancesCount",
    isNumber: true,
    disableSortBy: true,
    width: 0.125
  },
  Actions: {
    Header: "Actions",
    accessor: "",
    disableSortBy: true,
    cellRenderer: () => "",
    width: 0.1
  }
};
const GeneratedTable = tableGenerator<IUserTableProps>({
  columns: [
    ...Object.keys(UsersTableColumnConfig).map(
      key => UsersTableColumnConfig[key as UsersTableColumns]
    )
  ],
  dataKey: "users.usersTableUsers",
  connectionQuery: graphql`
    query usersTablePaginationQuery(
      $count: Int!
      $cursor: String
      $blade_scope: String
      $search: String
      $filterColumn: [String!]
      $filterValues: [[String!]!]
      $filterOp: [[String!]!]
    ) {
      ...usersTable_users
        @arguments(
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          count: $count
          cursor: $cursor
          blade_scope: $blade_scope
          search: $search
        )
    }
  `,
  getTotalCount: data => {
    return _.get(data, "users.usersTableCount") || 0;
  },
  fragmentSpec: {
    products: graphql`
      fragment usersTable_products on Query
        @argumentDefinitions(
          blade_scope: { type: "String", defaultValue: null }
          # search: { type: "String", defaultValue: null }
        ) {
        usersTableProductsList: products(bladeScope: $blade_scope) {
          edges {
            node {
              name
              id
              iconUrl
            }
          }
        }
      }
    `,
    companies: graphql`
      fragment usersTable_companies on Query
        @argumentDefinitions(
          blade_scope: { type: "String", defaultValue: null }
        ) {
        usersTableCompaniesList: companies(bladeScope: $blade_scope) {
          edges {
            node {
              name
            }
          }
        }
      }
    `,
    users: graphql`
      fragment usersTable_users on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          blade_scope: { type: "String", defaultValue: null }
          search: { type: "String", defaultValue: null }
          filterColumn: { type: "[String!]", defaultValue: ["users.active"] }
          filterOp: { type: "[[String!]!]", defaultValue: [["="]] }
          filterValues: { type: "[[String!]!]", defaultValue: [["true"]] }
        ) {
        usersTableCount: usersCount(
          search: $search
          bladeScope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        )
        usersTableUsers: users(
          first: $count
          after: $cursor
          bladeScope: $blade_scope
          search: $search
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        ) @connection(key: "UsersTable_usersTableUsers") {
          edges {
            node {
              id
              name
              email
              createdAt
              instancesCount(bladeScope: $blade_scope)
              hasActiveSession
              lastActiveSession {
                endTime
              }
              active
              owner {
                name
                id
              }
              products {
                # edges{
                nodes {
                  id
                  iconUrl
                  name
                }
                # }
              }
              productUsers {
                nodes {
                  product {
                    id
                    name
                  }
                  enabled
                  user {
                    id
                  }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  },
  tableName: "users"
});

const graphqlQuery = graphql`
  query usersTableQuery {
    ...usersTable_users
    ...usersTable_products
    ...usersTable_companies
  }
`;

class UsersGeneratedTable extends React.Component<
  IGeneratedTableProps & {
    result: usersTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        users={res}
        //@ts-ignore
        products={res}
        //@ts-ignore
        companies={res}
      />
    );
  }
}

export const UsersTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: usersTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <UsersGeneratedTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<usersTableQuery>
      environment={relayEnvironment}
      variables={{
        blade_scope: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};
