import { DATE_FORMAT } from "const";
import dayjs from "dayjs";

const DATE_RANGE = {
  Hours24: "last24",
  Days7: "days7",
  Month1: "month1",
  Months3: "months3",
  Months6: "months6",
  Year1: "year1",
  Years2: "year2"
} as const;

type ObjectValues<T> = T[keyof T];

export type DateRange = ObjectValues<typeof DATE_RANGE> | "-1";

export const formatDisplayGlobalFilter = (filter: DateRange) => {
  if (!filter || filter === "-1") {
    return "";
  }
  switch (filter) {
    case DATE_RANGE.Hours24:
      return `
          ${dayjs()
            .subtract(24, "hours")
            .format(DATE_FORMAT)} - ${dayjs().format(DATE_FORMAT)}
        `;
    case DATE_RANGE.Days7:
      return `
          ${dayjs()
            .subtract(7, "days")
            .format(DATE_FORMAT)} - ${dayjs().format(DATE_FORMAT)}
        `;
    case DATE_RANGE.Month1:
      return `
          ${dayjs()
            .subtract(1, "month")
            .format(DATE_FORMAT)} - ${dayjs().format(DATE_FORMAT)}
        `;
    case DATE_RANGE.Months3:
      return `
          ${dayjs()
            .subtract(3, "months")
            .format(DATE_FORMAT)} - ${dayjs().format(DATE_FORMAT)}
        `;
    case DATE_RANGE.Months6:
      return `
          ${dayjs()
            .subtract(6, "months")
            .format(DATE_FORMAT)} - ${dayjs().format(DATE_FORMAT)}
        `;
    case DATE_RANGE.Year1:
      return `
          ${dayjs()
            .subtract(1, "year")
            .format(DATE_FORMAT)} - ${dayjs().format(DATE_FORMAT)}
        `;
    case DATE_RANGE.Years2:
      return `
          ${dayjs()
            .subtract(2, "years")
            .format(DATE_FORMAT)} - ${dayjs().format(DATE_FORMAT)}
        `;
  }
};
