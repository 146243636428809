import { graphql } from "babel-plugin-relay/macro";

export const companiesTableExportQuery = graphql`
  query companiesTableExportQuery(
    $bladeScope: String
    $sortColumn: [String!]
    $sortDirection: [String!]
    $filterColumn: [String!]
    $filterOp: [[String!]!]
    $filterValues: [[String!]!]
  ) {
    companies(
      bladeScope: $bladeScope
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filterColumn: $filterColumn
      filterOp: $filterOp
      filterValues: $filterValues
    ) {
      nodes {
        id
        name
        activeUserCount
        licensesCount
        activeLicenseCount
        products {
          nodes {
            name
            id
            iconUrl
          }
        }
      }
    }
  }
`;
