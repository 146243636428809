import React, { useLayoutEffect, useRef } from "react";
import uuid from "uuid";
import { IChart, IChartQuery } from "./types";
import { useChartData } from "./hooks";
import { customChartTheme, exportMenuItems, getChartData } from "./helpers";
import _ from "lodash";
import FiltersContainer from "./filtersContainer";
import { options } from "@amcharts/amcharts4/core";
import NoDataPlaceholder from "./noDataPlaceholder";
import { useTheme } from "styled-components";

function HorizontalBarChartComponent(props: IChart) {
  const hasData = useRef();

  const theme = useTheme();

  let instanceId = uuid();
  const { data } = useChartData({
    ...props.queryParams,
    useLocalData: props.data ? true : false
  });
  // This code will only run one time
  useLayoutEffect(() => {
    let root = am5.Root.new(instanceId);
    const isDuration = props?.queryParams?.setup?.value?.includes("duration");
    isDuration && root.numberFormatter.set("durationFormat", "mm:ss");

    isDuration &&
      root.durationFormatter.setAll({
        baseUnit: "second",
        durationFormat: "m' minutes'",
        durationFields: ["valueX"]
      });

    let myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll(customChartTheme);

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    if (root._logo) {
      root._logo._privateSettings.visible = false;
    }

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        minHeight: 110,
        maxHeight: props.options?.chartMaxHeight || 170,
        panX: false,
        panY: false,
        layout: root.verticalLayout
      })
    );

    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );
    var yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 10 });
    yRenderer.labels.template.setAll({
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      fill: theme.chartColors.labels
    });
    yRenderer.setAll({
      stroke: theme.chartColors.axes
    });
    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        visible: _.first(props.plot?.yAxes)?.hideLabel ? false : true //show/hide y labels
      })
    );

    //   yAxis.get("renderer").labels.template.setAll({
    //     centerX: am5.p50,
    //     x: am5.p50
    // });
    const generatedData = getChartData(props.data ? props.data : data);
    yAxis.data.setAll(generatedData);

    yAxis.get("renderer").labels.template.setup = target => {
      target.setAll({
        cursorOverStyle: "pointer",
        background: am5.Rectangle.new(root, {
          fill: "transparent",
          fillOpacity: 0.2
        })
      });
    };

    yAxis.get("renderer").labels.template.events.on("click", e => {
      const identifier =
        e?.target?.dataItem?.dataContext?.category ||
        e?.target?._dataItem?.dataContext?.category;
      const targetIndex = data?.analytics?.categories?.findIndex(
        item => item === identifier
      );
      if (
        targetIndex >= 0 &&
        data?.analytics?.categoryMetadata[targetIndex]?.id
      ) {
        props.openBlade({
          name: identifier,
          bladeType: data.analytics.categoryMetadata[targetIndex].bladeType,
          id: data.analytics.categoryMetadata[targetIndex].id
        });
      }
    });

    let xAxis = chart.xAxes.push(
      isDuration
        ? am5xy.DurationAxis.new(root, {
            //baseUnit: "second",
            extraMax: 0.05,
            renderer: am5xy.AxisRendererX.new(root, {})
          })
        : am5xy.ValueAxis.new(root, {
            min: 0,
            extraMax: 0.05,
            renderer: am5xy.AxisRendererX.new(root, {})
          })
    );

    xAxis.get("renderer").labels.template.setAll({
      fill: theme.chartColors.labels
    });

    xAxis.get("renderer").setAll({
      stroke: theme.chartColors.axes
    });

    function createSeries(field, name, addBullets) {
      let color = chart.get("colors").next();
      if (props?.plot?.series) {
        if (props?.plot?.series[0]?.options?.fill) {
          color = am5.color(props?.plot?.series[0]?.options?.fill);
        }
      }
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: field,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: field,
          categoryYField: "category",
          fill: color,
          stacked: !!!props?.options?.useStackedBar
        })
      );

      series.columns.template.setAll({
        height: props?.options?.lineWidth
          ? am5.percent(props.options.lineWidth)
          : am5.percent(95),
        tooltipText: isDuration
          ? "[bold]duration[/]: {valueX}"
          : "[bold]{name}[/]: {valueX}",
        tooltipY: am5.percent(90)
      });

      addBullets &&
        series.bullets.push(function() {
          return am5.Bullet.new(root, {
            locationX: 1,
            locationY: 0,
            sprite: am5.Label.new(root, {
              centerY: am5.percent(35),
              text: "{categoryY}",
              populateText: true
            })
          });
        });
      var cellSize = 30;
      series.events.on("datavalidated", function(ev) {
        var series = ev.target;
        var chart = series.chart;
        //var xAxis = chart.xAxes.getIndex(0);
        var yAxis = chart.yAxes.getIndex(0);

        // Calculate how we need to adjust chart height
        var chartHeight =
          series.data.length * cellSize +
          yAxis.height() +
          chart.get("paddingTop", 0) +
          chart.get("paddingBottom", 0);

        // Set it on chart's container
        chart.root.dom.style.height = chartHeight + "px";
      });

      series.data.setAll(generatedData);

      if (_.isFunction(props.openBlade)) {
        series.events.on("click", function(ev) {
          const identifier = ev?.target?._settings?.name;
          const targetIndex = data?.analytics?.groups?.findIndex(
            item => item === identifier
          );
          if (
            targetIndex >= 0 &&
            data?.analytics?.groupMetadata[targetIndex]?.id
          ) {
            props.openBlade({
              name: identifier,
              bladeType: data.analytics.groupMetadata[targetIndex].bladeType,
              id: data.analytics.groupMetadata[targetIndex].id
            });
          }
        });
        series.setAll({ cursorOverStyle: "pointer" });
      }
      series.appear();

      !props.plot.hideLegend && legend.data.push(series);
      return series;
    }

    chart
      .get("colors")
      .set("colors", [
        am5.color("#0F649A"),
        am5.color("#FFA033"),
        am5.color("#68B6E8"),
        am5.color("#E3D626"),
        am5.color("#70D165"),
        am5.color("#EF8879"),
        am5.color("#F4F6F6")
      ]);

    if (props?.options?.useStackedBar) {
      createSeries("value", "value", true);
      createSeries("valueCompare", "valueCompare", false);
    } else {
      const groups = _.uniq(_.flatMap(generatedData, g => _.keys(g)));
      _.each(groups, g => {
        if (g !== "category") createSeries(g, "value", false);
      });
    }

    //series.data.setAll(generatedData);

    //series.appear();

    chart.appear(1000, 100);

    let exporting;
    let categoryName = _.get(props, "queryParams.setup.category", "Name");
    if (categoryName.includes(".")) {
      categoryName = categoryName.slice(0, categoryName.indexOf("."));
    }
    const exportData = generatedData.map(item => {
      if (Object.keys(item).includes("data")) {
        return {
          [categoryName]: _.get(item, "category"),
          count: _.get(item, "data", 0),
          ..._.omit(item, ["category", "data"])
        };
      }
      return {
        [categoryName]: _.get(item, "category"),
        ..._.omit(item, "category")
      };
    });

    if (
      !props.exportOptions?.showCustomExportButton &&
      !props.exportOptions?.disableExport
    ) {
      exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          //container: document.getElementById("exportdiv")
        }),
        dataSource: generatedData
      });

      exporting.events.on("dataprocessed", function(ev) {
        ev.data = exportData;
      });

      exporting.get("menu").set("items", exportMenuItems);
    }

    if (
      props.exportOptions?.showCustomExportButton &&
      !props.exportOptions?.disableExport &&
      _.isFunction(props?.setChartData)
    ) {
      props.setChartData({
        data: exportData,
        root
      });
    }

    return () => {
      root.dispose();
    };
  }, [data, props.data, theme]);

  hasData.current = _.get(data, "analytics.values", []).length;

  return (
    <>
      <div
        id={instanceId}
        style={{
          width: "100%",
          minHeight: "inherit",
          maxHeight: props.options?.chartMaxHeight || 170
        }}
        hidden={hasData.current ? false : true}
      ></div>
      {!hasData.current && <NoDataPlaceholder />}
    </>
  );
}

export default function HorizontalBarChart(props: IChart) {
  return (
    <FiltersContainer
      containerProps={props}
      Component={HorizontalBarChartComponent}
    />
  );
}
