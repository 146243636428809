import { createBrowserHistory } from "history";
import { createStore, IModuleStore } from "redux-dynamic-modules";
import { getSagaExtension, ISagaModule } from "redux-dynamic-modules-saga";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";

// import { apiMiddleware } from "../api/helpers";
import authSaga from "./deviseAuth/sagas";
import { saga as appSaga } from "./ducks/app";
import { appReducers } from "./reducers";
import { IStore } from "./types";

export const history = createBrowserHistory();

let store: IModuleStore<IStore>;
const sagas = [authSaga, appSaga];

const getModules = (): ISagaModule<any> => {
  return {
    id: "app-module",
    // middlewares: [apiMiddleware],
    reducerMap: appReducers,
    sagas
  };
};

export const getAppStore = () => store;
export const configureStore = (initialState?: any) => {
  store = createStore(
    {
      initialState,
      enhancers: [],
      extensions: [getSagaExtension({} /* saga context */)],
      advancedComposeEnhancers: composeWithDevTools({})
    },
    getModules()
  );

  return store;
};
export default configureStore;
