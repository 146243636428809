import React from "react";
import { tableGenerator } from "../../../utils";
import relayEnvironment from "../../../api/relay";
import _ from "lodash";
import renderProductImages from "../../../components/productImages";
import { IColumn } from "components/elmTable";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { LoaderContainer } from "components/helpers";
import { Flex } from "reflexbox";
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { CompaniesTable_companies } from "./__generated__/CompaniesTable_companies.graphql";
import {
  CompaniesTableQuery,
  CompaniesTableQueryResponse
} from "./__generated__/CompaniesTableQuery.graphql";
import { shallowEqual } from "fast-equals";

export type companyTableEdges = CompaniesTable_companies["companies"]["edges"];
export type companyTableType = companyTableEdges[number]["node"];

export const CompaniesTableColumns = {
  Company: "Company",
  Product: "Product",
  ActiveLicenses: "ActiveLicenses",
  ActiveUsers: "ActiveUsers"
} as const;
export const CompaniesTableColumnConfig: Record<
  keyof typeof CompaniesTableColumns,
  IColumn
> = {
  Company: {
    Header: "Company",
    accessor: "name",
    sortKey: "companies.name"
  },
  Product: {
    Header: "Product",
    accessor: "products.product",
    cellRenderer: payload => renderProductImages(payload.cellData),
    disableSortBy: true
  },
  ActiveLicenses: {
    Header: "Active Licenses",
    accessor: "activeLicenseCount",
    filterable: {
      filterName: "companies.active_license_count",
      filterGenerator: payload => {
        if (
          payload.filterColumnSelection.includes("Inactive") &&
          payload.filterColumnSelection.includes("Active")
        ) {
          payload.filterState.filterColumn = null;
          payload.filterState.filterValues = null;
          payload.filterState.filterOp = null;
          return payload.filterState;
        }
        if (
          payload.filterColumnSelection.includes("Active") &&
          payload.filterColumnSelection.length == 1
        ) {
          payload.filterState.filterColumn.push(
            "companies.active_license_count"
          );
          payload.filterState.filterValues.push(["0"]);
          payload.filterState.filterOp.push([">"]);
        }

        if (
          payload.filterColumnSelection.includes("Inactive") &&
          payload.filterColumnSelection.length == 1
        ) {
          payload.filterState.filterColumn.push(
            "companies.active_license_count"
          );
          payload.filterState.filterValues.push(["1"]);
          payload.filterState.filterOp.push(["<"]);
        }
        return payload.filterState;
      },
      overrideData: () => ["Active", "Inactive"],
      defaultSelected: ["Active"],
      dataKey: null,
      dataMap: payload => payload
    },
    width: 0.184,
    isNumber: true,
    showZeroAsInactive: true,
    disableSortBy: true
  },
  ActiveUsers: {
    Header: "Active Users",
    accessor: "activeUserCount",
    width: 0.184,
    isNumber: true,
    showZeroAsInactive: true,
    disableSortBy: true
  }
};

const GeneratedTable = tableGenerator<{
  companies: CompaniesTable_companies;
}>({
  tableName: "companiesTable",
  dataKey: "companies.companies",
  getTotalCount: data => {
    return _.get(data, "companies.companiesCount") || 0;
  },
  columns: [
    ...Object.keys(CompaniesTableColumnConfig).map(
      key =>
        CompaniesTableColumnConfig[
          key as keyof typeof CompaniesTableColumnConfig
        ]
    )
  ],
  isTableRowInActive: (rowData: companyTableType) => {
    return rowData.activeLicenseCount < 1;
  },
  connectionQuery: graphql`
    query CompaniesTablePaginationQuery(
      $count: Int!
      $cursor: String
      $blade_scope: String
      $filterColumn: [String!]
      $filterValues: [[String!]!]
      $filterOp: [[String!]!]
      $search: String
      $sortColumn: [String!]
      $sortDirection: [String!]
      $skipProduct: Boolean!
    ) {
      ...CompaniesTable_companies
        @arguments(
          count: $count
          cursor: $cursor
          blade_scope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
          skipProduct: $skipProduct
        )
    }
  `,
  fragmentSpec: {
    companies: graphql`
      fragment CompaniesTable_companies on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          blade_scope: { type: "String", defaultValue: null }
          filterColumn: { type: "[String!]", defaultValue: null }
          filterOp: { type: "[[String!]!]", defaultValue: null }
          filterValues: { type: "[[String!]!]", defaultValue: null }
          search: { type: "String", defaultValue: null }
          sortColumn: { type: "[String!]", defaultValue: null }
          sortDirection: { type: "[String!]", defaultValue: null }
          skipProduct: { type: "Boolean!", defaultValue: false }
        ) {
        companiesCount(
          search: $search
          bladeScope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
        )
        companies(
          first: $count
          after: $cursor
          bladeScope: $blade_scope
          filterColumn: $filterColumn
          filterOp: $filterOp
          filterValues: $filterValues
          search: $search
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        ) @connection(key: "CompaniesTable_companies") {
          edges {
            node {
              id
              name
              activeUserCount
              licensesCount
              activeLicenseCount
              products @skip(if: $skipProduct) {
                product: nodes {
                  name
                  id
                  iconUrl
                }
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query CompaniesTableQuery(
    $blade_scope: String!
    $filterColumn: [String!]
    $filterOp: [[String!]!]
    $filterValues: [[String!]!]
    $sortColumn: [String!]
    $sortDirection: [String!]
    $skipProduct: Boolean!
  ) {
    ...CompaniesTable_companies
      @arguments(
        blade_scope: $blade_scope
        filterColumn: $filterColumn
        filterOp: $filterOp
        filterValues: $filterValues
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        skipProduct: $skipProduct
      )
  }
`;

class CompaniesPaginationTable extends React.Component<
  IGeneratedTableProps & {
    result: CompaniesTableQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    if (_.isFunction(this.props.removeRefreshFn)) {
      this.props.removeRefreshFn(this.props.refreshKey);
    }
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        companies={res}
      />
    );
  }
}

export const CompaniesTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: CompaniesTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <CompaniesPaginationTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<CompaniesTableQuery>
      environment={relayEnvironment}
      variables={{
        skipProduct:
          props.hideColumnsWithHeaders?.includes(
            CompaniesTableColumns.Product
          ) || false,
        blade_scope: props.bladeScope || "",
        sortColumn: ["companies.name"],
        sortDirection: ["ASC"],
        filterValues: [["0"]],
        filterColumn: ["companies.active_license_count"],
        filterOp: [[">"]]
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default CompaniesTable;
