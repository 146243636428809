import * as _ from "lodash";
import { all, put, takeLatest } from "redux-saga/effects";
import { Action } from "../types";
import { getGateway } from "../../api";
import { actions as appActions } from "../ducks/app";
import {
  registrationRequestSent,
  registrationRequestFailed,
  registrationRequestSucceeded,
  verifyTokenRequestFailed,
  verifyTokenRequestSent,
  verifyTokenRequestSucceeded,
  setHasVerificationBeenAttempted,
  signInRequestFailed,
  signInRequestSent,
  signInRequestSucceeded,
  signOutRequestFailed,
  signOutRequestSent,
  signOutRequestSucceeded,
  requestVerifyToken
} from "./actions";
import {
  AuthResponse,
  REQUEST_REGISTRATION,
  REGISTRATION_REQUEST,
  REQUEST_SIGNIN,
  REQUEST_SIGNOUT,
  REQUEST_VERIFY_CREDENTIALS,
  REQUEST_VERIFY_TOKEN,
  VerificationParams,
  UserRegistrationDetails,
  UserSignInCredentials,
  UserSignOutCredentials
} from "./types";
import {
  deleteAuthHeaders,
  deleteAuthHeadersFromDeviceStorage,
  getUserAttributesFromResponse,
  persistAuthHeadersInDeviceStorage,
  setAuthHeaders
} from "./services/auth";
import relayEnvironment, { clearRelayStore } from "api/relay";

const gateway = getGateway();
const Storage = gateway.getStorage();
let config = {
  userAttributes: {
    name: "name",
    email: "email",
    uid: "uid"
  } as { [key: string]: string },
  userRegistrationAttributes: {} as { [key: string]: string }
};

export const setConfig = (newConfig: typeof config) => {
  config = _.cloneDeep(newConfig);
};

const { userRegistrationAttributes, userAttributes } = config;
function* registerUser(
  action: Action<REGISTRATION_REQUEST, UserRegistrationDetails>
) {
  yield put(registrationRequestSent());
  try {
    const { email, password, passwordConfirmation } = action.data;
    const data = {
      email,
      password,
      password_confirmation: passwordConfirmation
    };
    let backendKey;
    _.keys(userRegistrationAttributes).map(key => {
      backendKey = userRegistrationAttributes[key];
      data[backendKey] = action.data[key];
    });
    const response: AuthResponse = yield gateway.request.registerUser(data);
    setAuthHeaders(response.headers);
    persistAuthHeadersInDeviceStorage(Storage, response.headers);
    const userAttributesToSave = getUserAttributesFromResponse(
      userAttributes,
      response
    );
    yield put(registrationRequestSucceeded(userAttributesToSave));
  } catch (error) {
    yield put(registrationRequestFailed());
    // throw error;
  }
}

function* verifyToken(
  action: Action<REQUEST_VERIFY_TOKEN, VerificationParams>
) {
  yield put(verifyTokenRequestSent());
  try {
    const response: AuthResponse = yield gateway.request.verifyToken();
    setAuthHeaders({ ...response.headers, client: action.data.client });
    persistAuthHeadersInDeviceStorage(
      Storage,
      // @ts-ignore
      _.omit(
        { ...response.headers, client: action.data.client },
        "access-token"
      )
      /*
        TODO: 
        Need to investigate why reloading the app in normal mode yields a new clientId
        and a new (expired) access token, while incognito mode (Chrome) and Firefox does preserve it.
        Here we are implicitly preserving it to avoid being constantly kicked out of the app.
        If the token has expired, the next call to roles/graphql will fail as expected and force a login.
      */
    );
    const userAttributesToSave = getUserAttributesFromResponse(
      userAttributes,
      response
    );
    yield put(verifyTokenRequestSucceeded(userAttributesToSave));
    yield put(appActions.getUserRoles());
  } catch (error) {
    deleteAuthHeaders();
    deleteAuthHeadersFromDeviceStorage(Storage);
    yield put(verifyTokenRequestFailed());
    // throw error;
  }
}

function* signInUser(action: Action<REQUEST_SIGNIN, UserSignInCredentials>) {
  yield put(signInRequestSent());
  try {
    const response = yield gateway.request.signInUser(action.data);
    if (response.error) {
      throw new Error(response.data);
    }
    const userAttributesToSave = getUserAttributesFromResponse(
      userAttributes,
      response
    );
    setAuthHeaders(response.headers);
    persistAuthHeadersInDeviceStorage(Storage, response.headers);
    yield put(signInRequestSucceeded(userAttributesToSave));
    yield put(appActions.getUserRoles());
  } catch (error) {
    console.error(error);
    yield put(signInRequestFailed(error));
    // throw error;
  }
}

function* signOutUser() {
  try {
    const accessToken = yield Storage.getItem("access-token");
    const client = yield Storage.getItem("client");
    const uid = yield Storage.getItem("uid");
    const userSignOutCredentials: UserSignOutCredentials = {
      "access-token": accessToken,
      client,
      uid
    };
    yield put(signOutRequestSent());
    yield gateway.request.signOutUser(userSignOutCredentials);
    deleteAuthHeaders();
    deleteAuthHeadersFromDeviceStorage(Storage);
    yield put(appActions.clearUserRoles());
    clearRelayStore(relayEnvironment);
    yield put(signOutRequestSucceeded());
  } catch (error) {
    console.error(error);
    yield put(signOutRequestFailed());
    // throw error;
  }
}

function* verifyCredentials() {
  try {
    const accessToken = yield Storage.getItem("access-token");
    const client = yield Storage.getItem("client");
    const uid = yield Storage.getItem("uid");
    if (accessToken && accessToken !== "undefined") {
      const verificationParams: VerificationParams = {
        "access-token": accessToken,
        client,
        uid
      };
      yield put(requestVerifyToken(verificationParams));
    } else {
      yield put(setHasVerificationBeenAttempted(true));
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  try {
    yield all([
      takeLatest(REQUEST_VERIFY_TOKEN, verifyToken),
      takeLatest(REQUEST_VERIFY_CREDENTIALS, verifyCredentials),
      takeLatest(REQUEST_SIGNIN, signInUser),
      takeLatest(REQUEST_SIGNOUT, signOutUser),
      takeLatest(REQUEST_REGISTRATION, registerUser)
    ]);
  } catch (e) {
    console.error(e);
  }
}
