import { GraphQLResponse } from "relay-runtime";
import { deleteRequest, getRequest, postRequest, putRequest } from "./helpers";
import { ReportType } from "Reports/common/types";

export interface Owner {
  name: string;
  owner_type: string;
  owner_id: string;
}
// export interface ILicenseModel {
//   number_of_installs: number;
//   number_of_users: number;
//   max_instances_per_user: number;
//   default_token_duration: number;
//   owner: Owner;
//   product_id: string;
//   license_type_id: number;
//   is_trial: boolean;
//   is_vendor: boolean;
//   owner_id: string;
//   owner_type: string;
//   user_count: number;
//   grace_period: number;
//   instance_count: number;
//   session_count: number;
//   instances_per_user: number;
//   users_per_instance: number;
//   sessions_per_user: number;
//   sessions_per_instance: number;
//   sessions_per_user_per_instance: number;
//   require_email_activation: boolean;
// }
type commercialModel = {
  id: string;
  name: string;
};
type license = {
  number_of_users: number;
  default_token_duration: number;
  owner: Owner;
  product_id: string;
  license_type_id: number;
  owner_id: string;
  owner_type: string;
  grace_period: number;
  feature_group_ids?: string[];
  feature_ids?: string[];
  component_ids?: string[];
  component_group_ids?: string[];
  commercialModel?: commercialModel;
  commercial_model_id?: string;
  is_vendor?: boolean;
};
type entitlement = {
  user_count: number;
  instance_count: number;
  session_count: number;
};
type restriction = {
  max_instances_per_user: number;
  instances_per_user: number;
  users_per_instance: number;
  sessions_per_user: number;
  sessions_per_instance: number;
  sessions_per_user_per_instance: number;
  minimum_assignment_length: number;
  require_email_activation: boolean;
  number_of_installs: number;
  number_of_users: number;
};
type term = {
  start_date: string;
  end_date: string;
};
type seatCount = {
  seat_count?: number;
};
type createTerm = {
  start_date: string;
  end_date: string;
  value: number;
  reseller_value: number;
  //seat_count?: number;
};
interface IEditLicense {
  license_id: string;
  comment?: string;
}
export interface ICreateTerm extends IEditLicense {
  term: createTerm;
  entitlement: seatCount;
}
export interface IModifyEntitlement extends IEditLicense {
  entitlement: seatCount;
}

export interface IModifyRestrictions extends IEditLicense {
  restriction: restriction;
}
export interface ILicenseModel {
  license: license;
  entitlement: entitlement;
  restriction: restriction;
  term: term;
  comment?: string;
  // number_of_installs: number;
  // number_of_users: number;
  // max_instances_per_user: number;
  // default_token_duration: number;
  // owner: Owner;
  // product_id: string;
  // license_type_id: number;
  // is_trial: boolean;
  // is_vendor: boolean;
  // owner_id: string;
  // owner_type: string;
  // user_count: number;
  // grace_period: number;
  // instance_count: number;
  // session_count: number;
  // instances_per_user: number;
  // users_per_instance: number;
  // sessions_per_user: number;
  // sessions_per_instance: number;
  // sessions_per_user_per_instance: number;
  // require_email_activation: boolean;
}

export interface IEditLicenseModel {
  license_id: string;
  license: license;
  entitlement: seatCount;
  term: createTerm;
  comment?: string;
}

export interface ILicenceApprove {
  license_id?: string;
  license_action_id?: string;
  comment?: string;
}
export interface IEditLicenseRequest extends ILicenseModel {
  notify_customer: boolean;
}

export interface IToggleFeatures {
  feature_id: string;
  license_id: string;
}

export interface IToggleFeatureGroups {
  feature_group_id: string;
  license_id: string;
}

type FeatureGroup = {
  name: string;
};
export interface IFeatureGroupEdit {
  features: string[];
  feature_group: FeatureGroup;
}

type JSONValue =
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>;
export interface ILicenseTemplate {
  product_id: string;
  name: string;
  template_body: string;
}

export const gatewayMap = {
  createApiToken: postRequest<
    { id: number; description: string; token: string },
    { description: string; scopes: string[] }
  >("/api_tokens.json"),
  changePassword: putRequest<
    null,
    {
      current_password: string;
      password: string;
      password_confirmation: string;
    }
  >("/system_user_auth"),
  deleteApiToken: postRequest<{
    description: string;
    id: number;
    scopes: string[];
  }>("/api_tokens/{id}/revoke.json"),
  revokeSession: postRequest<{
    id: number;
  }>("/sessions/{id}/revoke.json"),
  deleteAdmin: postRequest<{
    id: number;
  }>("/vendor_system_users/delete.json"),
  deleteEntitlement: deleteRequest<null>("/component_entitlements/{id}.json"),
  deleteLicense: deleteRequest<null>("/licenses/{id}.json"),
  deleteProduct: deleteRequest<null>("/products/{id}.json"),
  deleteCompany: deleteRequest<null>("/companies/{id}.json"),

  editAdminUser: putRequest<
    {
      id: number;
      email: string;
      role: string;
      name: string;
      avatar_url: string;
      thumb_url: string;
    },
    {
      email?: string;
      name?: string;
      avatar_url?: string;
      thumb_url?: string;
      role?: string;
    }
  >("/vendor_system_users/{id}.json"),
  uploadAdminUserImage: postRequest(
    "/vendor_system_users/{id}/avatar",
    null,
    null,
    {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data"
    },
    { useMainThread: true }
  ),
  editApiTokenScope: putRequest<
    { description: string; id: number; scopes: string[] },
    { scopes: string[] }
  >("/api_tokens/{id}.json"),
  editReleaseConfig: putRequest<
    null,
    {
      fallback_release_channel_id: string;
      id: string;
      name: string;
      release_channels: string[];
      releases: string[];
    }
  >("/release_configurations/{id}.json"),
  deleteReleaseConfig: deleteRequest<null, { id: string }>(
    "/release_configurations/{id}.json"
  ),
  enableFeature: postRequest<null, IToggleFeatures>(
    "/license_actions/add_feature"
  ),
  enableFeatureGroup: postRequest<null, IToggleFeatureGroups>(
    "/license_actions/add_feature_group"
  ),
  disableFeature: postRequest<null, IToggleFeatures>(
    "/license_actions/remove_feature.json"
  ),
  disableFeatureGroup: postRequest<null, IToggleFeatureGroups>(
    "/license_actions/remove_feature_group.json"
  ),
  disableHardwareUpdate: postRequest<null>(
    "/instances/{id}/disable_hardware_update.json"
  ),
  enableHardwareUpdate: postRequest<null, { id: string; guid: string }>(
    "/instances/{id}/enable_hardware_update.json"
  ),
  enableInstanceFeature: postRequest<{ id: string }, { feature: string }>(
    "/instances/{id}/enable_feature.json"
  ),
  enableInstanceFeatureGroup: postRequest<{ feature_group: string }>(
    "/instances/{id}/enable_feature.json"
  ),
  disableInstanceFeature: postRequest<{ feature: string }>(
    "/instances/{id}/disable_feature.json"
  ),
  disableInstanceFeatureGroup: postRequest<{ feature_group: string }>(
    "/instances/{id}/disable_feature.json"
  ),
  enableUserFeature: postRequest<
    { id: string },
    { feature: string; instance_id: string }
  >("/users/{id}/enable_feature.json"),
  disableUserFeature: postRequest<{ feature: string; instance_id: string }>(
    "/users/{id}/disable_feature.json"
  ),
  enableUserFeatureGroup: postRequest<{
    feature_group: string;
    instance_id: string;
  }>("/users/{id}/enable_feature.json"),
  disableUserFeatureGroup: postRequest<{
    feature_group: string;
    instance_id: string;
  }>("/users/{id}/disable_feature.json"),
  getInstanceInfo: getRequest<{
    activated: boolean;
    enabled: boolean;
    hardware_update_flag: boolean;
  }>("/instances/{id}.json"),
  disableInstance: postRequest<null, { id: string; guid: string }>(
    "/instances/{id}/disable.json"
  ),
  enableInstance: postRequest<null, { id: string; guid: string }>(
    "/instances/{id}/enable.json"
  ),
  getCurrentUser: getRequest<{ id: number; email: string; role: string }>(
    "/vendor_system_users/current.json"
  ),
  getCurrentVendorInformation: getRequest<{
    id: number;
    company: string;
    header_logo_url: string;
    logo_url: string;
  }>("/vendors/current.json"),
  updateCurrentVendorInformation: postRequest<
    null,
    { id: number; company: string; header_logo_url: string; logo_url: string }
  >("/vendors/current.json"),
  getProductTypes: getRequest<{ id: number; description: string }[]>(
    "/product_types.json"
  ),
  getRedirectImageUrl: getRequest<{ redirect_url: string }>("{redirect_url}"),
  getAdminUsers: getRequest<{ id: number; email: string; role: string }[]>(
    "/vendor_system_users.json"
  ),
  getApiTokens: getRequest<
    { id: number; description: string; scopes: string[] }[]
  >("/api_tokens.json"),
  getApiTokenScopes: getRequest<{ id: string; description: string }[]>(
    "/api_tokens/scopes.json"
  ),
  getNotifications: getRequest<{
    id: number;
    name: string;
    role: string;
    thumb_url: string;
    avatar_url: string;
    email: string;
    email_preferences: {
      category: string;
      label: string;
      enabled: boolean;
      frequency: number[];
      reasons: {
        reason: string;
        label: string;
        enabled: boolean;
      }[];
    }[];
  }>("/vendor_system_users/current.json"),
  editNotifications: putRequest<
    { id: string },
    {
      preferences: {
        email_preference: {
          category: string;
          label: string;
          enabled: boolean;
          frequency: number[];
          reasons: {
            reason: string;
            label: string;
            enabled: boolean;
          }[];
        }[];
      };
    }
  >("/vendor_system_users/{id}/update_preferences"),
  updateUserPreference: putRequest<
    { id: string },
    {
      preferences: {
        [key: string]: any;
      };
    }
  >("/vendor_system_users/{id}/update_preferences"),
  registerUser: postRequest(""),
  companyUploadImage: postRequest(
    "/companies/{id}/logo",
    null,
    null,
    {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data"
    },
    { useMainThread: true }
  ),
  uploadProductImage: postRequest(
    "/products/{id}/icon",
    null,
    null,
    {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data"
    },
    { useMainThread: true }
  ),
  newAdmin: postRequest<
    { email: string; role: string; id: number },
    { email: string; role: string }
  >("/vendor_system_users.json"),
  newCompanyAdmin: postRequest<{ id: string }, { email: string }>(
    "/companies/{id}/create_admin.json"
  ),
  deleteCompanyAdmin: postRequest<{ id: string }, { admin_id: string }>(
    "/companies/{id}/delete_admin.json"
  ),
  newCompany: postRequest<{ graphql_id: string; id: string }>(
    "/companies.json"
  ),
  newBusinessUnit: postRequest<
    null,
    {
      company_id: string;
      contact_email: string;
      contact_name: string;
      contact_phone: string;
      name: string;
    }
  >("/business_units.json"),
  deleteBusinessUnit: deleteRequest<{ id: string }>(
    "/business_units/{id}.json"
  ),
  enableProduct: postRequest<{ id: string }, { product_id: string }>(
    "/users/{id}/enable.json"
  ),
  disableProduct: postRequest<{ id: string }, { product_id: string }>(
    "/users/{id}/disable.json"
  ),
  editUser: putRequest<
    { id: string },
    { id: string; name: string; email: string }
  >("/users/{id}.json"),
  linkUser: postRequest<{ id: string }, { id: string; linked_user_id: string }>(
    "/users/{id}/link.json"
  ),
  unlinkUser: postRequest<
    { id: string },
    { id: string; linked_user_id: string }
  >("/users/{id}/unlink.json"),
  newReport: postRequest<
    null,
    { name: string; report_type: ReportType; options: any; is_private: boolean }
  >("/reports.json"),
  editReport: putRequest<
    { id: string },
    { name: string; report_type: ReportType; options: any; is_private: boolean }
  >("/reports/{id}.json"),
  renderReport: getRequest("/reports/{id}/render"),
  deleteReport: deleteRequest<null, { id: string }>("/reports/{id}.json"),
  editCompany: putRequest<
    { id: string },
    {
      name: string;
      contact_phone: string;
      contact_name: string;
      contact_email: string;
      location?: {
        country_code: string;
        city: string;
      };
    }
  >("/companies/{id}.json"),
  editProduct: putRequest<
    {},
    {
      product: {
        name: string;
        support_email: string;
        guid: string;
        id: string;
        vendor_id: string;
      };
      features: string[];
    }
  >("/products/{id}.json"),
  editComponentRequirement: postRequest<
    { id: string },
    {
      free_trial: boolean;
      free_trial_expiration: string;
      is_included: boolean;
      is_inherited: boolean;
      product_version_id: string;
      token_count: number;
      would_inherit_from_base_license?: boolean;
    }
  >("/components/{id}/requirement.json"),
  editLicenseComponentRequirement: postRequest<
    { id: string },
    {
      free_trial: boolean;
      free_trial_expiration: string;
      is_included: boolean;
      is_inherited: boolean;
      can_inherit: boolean;
      product_version_id: string;
      license_id: string;
      token_count: number;
      would_inherit_from_base_license?: boolean;
    }
  >("/components/{id}/requirement.json"),
  newProductVersion: postRequest<
    null,
    { product_version: { product_id: string; version_string: string } }
  >("/product_versions.json"),
  editProductVersion: putRequest<
    { id: string },
    { id: string; version_string: string }
  >("/product_versions/{id}.json"),
  deleteProductVersion: deleteRequest<null, { id: string }>(
    "/product_versions/{id}.json"
  ),
  newComponent: postRequest("/components.json"),
  deleteComponent: deleteRequest<null, { id: string }>("/components/{id}.json"),
  editComponent: putRequest<{}, { component: { name: string } }>(
    "/components/{id}.json"
  ),
  newComponentGroup: postRequest<
    { component_type: { id: number; name: string }; id: number; name: string },
    { component_type: number; name: string; product_id: string }
  >("/component_groups.json"),
  deleteComponentGroup: deleteRequest<
    { component_type: { id: number; name: string }; id: string; name: string },
    { id: string; name: string }
  >("/component_groups/{id}.json"),
  editComponentGroup: putRequest<
    { component_type: { id: number; name: string }; id: string; name: string },
    { component_type: { id: number; name: string }; id: string; name: string }
  >("/component_groups/{id}.json"),
  editComponentGroupComponents: putRequest<
    {},
    {
      component_group: {
        name: string;
        component_ids: string[];
      };
    }
  >("/component_groups/{id}.json"),
  newComponentEntitlement: postRequest<
    null,
    {
      //id: string;
      license_id: string;
      entitlement: {
        allow_unlimited_usage: boolean;
        license_id: string;
        component_group_id: string;
        component_ids: string[];
        end_date: string;
        start_date: string;
        session_count: number;
        token_count: number;
        currency_count: number;
        overage_allowed_count: number;
        overage_allowed_percentage: number;
      };
    }
  >("/component_entitlements.json"),
  editEntitlement: putRequest<
    { id: string },
    {
      entitlement: {
        id: string;
        allow_unlimited_usage: boolean;
        component_group_id: string;
        component_ids: string[];
        end_date: string;
        start_date: string;
        session_count: number;
        token_count: number;
        currency_count: number;
        overage_allowed_count: number;
        overage_allowed_percentage: number;
      };
    }
  >("/component_entitlements/{id}.json"),
  newProduct: postRequest<
    null,
    { name: string; product_type_id: number; support_email: string }
  >("/products.json"),
  newProductFeature: postRequest<
    { group_memberships: string[]; groups: string[]; id: string; name: string },
    { feature_group_ids: string[]; name: string; product_id: string }
  >("/features.json"),
  deleteFeature: deleteRequest<{ id: string }>("/features/{id}.json"),
  editProductFeature: putRequest<
    { id: string },
    {
      id: string;
      product_id: string;
      name: string;
      feature_group_ids: string[];
      groups: string[];
      group_memberships: string[];
    }
  >("/features/{id}.json"),
  newProductReleaseConfig: postRequest<
    { id: string },
    { name: string; product_id: string }
  >("/release_configurations.json"),
  newProductReleaseChannel: postRequest<
    { id: string },
    { name: string; release_configuration_id: string }
  >("/release_channels.json"),
  newProductRelease: postRequest<
    { id: string },
    { tag: string; data: string; release_configuration_id: string }
  >("/releases.json"),
  editProductRelease: putRequest<
    { id: string },
    { tag: string; data: string; release_configuration_id: string }
  >("/releases/{id}.json"),
  deleteProductRelease: deleteRequest<null, { id: string }>(
    "/releases/{id}.json"
  ),
  newProductFeatureGroup: postRequest<
    null,
    {
      features: { id: string; name: string }[];
      name: string;
      product_id: string;
    }
  >("/feature_groups.json"),
  editProductFeatureGroup: putRequest<{ id: string }, IFeatureGroupEdit>(
    "/feature_groups/{id}"
  ),
  newLicense: postRequest<{ id: number; graphql_id: string }, ILicenseModel>(
    "/license_actions/create_license.json"
  ),
  approveLicense: postRequest<
    { id: number; graphql_id: string },
    ILicenceApprove
  >("/license_actions/approve_action.json"),
  rejectLicense: postRequest<
    { id: number; graphql_id: string },
    ILicenceApprove
  >("/license_actions/reject_action.json"),
  create_term: postRequest<null, ICreateTerm>(
    "/license_actions/create_term.json"
  ),
  modify_entitlement: postRequest<null, IModifyEntitlement>(
    "/license_actions/modify_entitlement"
  ),
  modify_restrictions: postRequest<null, IModifyRestrictions>(
    "/license_actions/modify_license"
  ),
  modify_license: postRequest<null, IEditLicenseModel>(
    "/license_actions/modify_license"
  ),
  create_template: postRequest<null, ILicenseTemplate>(
    "/license_templates.json"
  ),
  delete_template: deleteRequest<null, { id: string }>(
    "/license_templates/{id}.json"
  ),
  edit_template: putRequest<{ id: string }, ILicenseTemplate>(
    "/license_templates/{id}.json"
  ),
  get_template: getRequest<{ id: string }>("/license_templates/{id}.json"),
  deactivate_license: postRequest<
    null,
    { license_id: string; comment?: string }
  >("/license_actions/deactivate_license"),
  delete_license: deleteRequest<null, { id: string }>(
    "/license_actions/delete_license/{id}.json"
  ),
  activate_license: postRequest<null, { license_id: string }>(
    "/license_actions/activate_license"
  ),
  editLicense: putRequest<null, IEditLicenseRequest>("/licenses/{id}.json"),
  newLicenseTerm: postRequest("/terms.json"),
  getUserRoles: postRequest("/system_user_auth/roles"),
  signInUser: postRequest("/system_user_auth/sign_in"),
  signOutUser: deleteRequest("/system_user_auth/sign_out"),
  requireNewPassword: postRequest<
    null,
    { email: string; redirect_url: string }
  >("/system_user_auth/password"),
  setPassword: postRequest<
    null,
    {
      email: string;
      password: string;
      password_confirmation: string;
    }
  >("/system_users/set_password.json"),
  resetPassword: putRequest<
    null,
    {
      "access-token": string;
      client: string;
      password: string;
      password_confirmation: string;
      uid: string;
    }
  >("/system_user_auth/password"),
  getResetPasswordAuth: getRequest<{
    config: string;
    redirect_url: string;
    reset_password_token: string;
  }>(
    "/system_user_auth/password/edit?config={config}&redirect_url={redirect_url}&reset_password_token={reset_password_token}"
  ),
  uploadVendorLogo: postRequest(
    "/vendors/current/logo",
    null,
    null,
    {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data"
    },
    { useMainThread: true }
  ),
  updateReleaseForChannel: postRequest<
    { id: string },
    { release_id: string; id: string; name: string }
  >("/release_channels/{id}/set_current_release.json"),
  updateReleaseNameForChannel: putRequest<
    { id: string },
    { id: string; name: string }
  >("/release_channels/{id}.json"),
  deleteReleaseChannel: deleteRequest<null, { id: string }>(
    "/release_channels/{id}.json"
  ),
  updateLicenseReleaseChannel: putRequest<{
    license_id: string;
    release_channel_id: string;
    release_configuration_id: string;
  }>("/license_release_channels/set.json"),
  verifyToken: getRequest("/system_user_auth/validate_token"),
  executeGraphqlRequest: postRequest<GraphQLResponse, any>(
    "",
    "evoleapGraphqlGateway"
  )
};
