import React from "react";
import moment from "moment";
import _ from "lodash";
import { ElmSelectOption, ElmFilterSelect } from "../../elmSelect";
import clsx from "clsx";

export const DEFAULT_ALL_TIME_START_YEAR = 2014;

const timespan = {
  days7: "last7",
  month1: "last30",
  months3: "last90",
  months6: "months6",
  year1: "lastYear",
  allTime: "allTime"
} as const;

type ObjectValues<T> = T[keyof T];

type timeSpanKey = "day" | "hour" | "week" | "month" | "year";
type timeSpanFilterType = ObjectValues<typeof timespan>;
type timeSpanFilterOption = {
  label: string;
  groupBy: timeSpanKey;
  key?: timeSpanKey;
  filterType: timeSpanFilterType;
};

export const getQueryTimeVariables = (opt: {
  groupBy: timeSpanKey;
  label?: string;
  filterType: timeSpanFilterType;
  startTime?: moment.Moment;
  allTimeStart?: moment.Moment;
}) => {
  const { groupBy, filterType, label } = opt;
  const startTime = opt.startTime || moment().utc();
  const formattedStartTime = startTime.format();
  let result = {
    groupBy,
    filterType,
    label,
    currentTimeSpanStart: formattedStartTime,
    currentTimeSpanEnd: formattedStartTime,
    previousTimeSpanStart: formattedStartTime,
    previousTimeSpanEnd: formattedStartTime,
    currentDate: formattedStartTime,
    previousDate: formattedStartTime
  };
  const getLastXDays = (days: number) => ({
    currentTimeSpanStart: startTime
      .clone()
      .subtract(days, "day")
      .startOf("day")
      .utc()
      .format(),
    currentTimeSpanEnd: startTime
      .clone()
      .endOf("day")
      .utc()
      .format(),
    previousTimeSpanStart: startTime
      .clone()
      .subtract(days * 2, "day")
      .startOf("day")
      .utc()
      .format(),
    previousTimeSpanEnd: startTime
      .clone()
      .subtract(days, "day")
      .endOf("day")
      .utc()
      .format()
  });
  switch (filterType) {
    case timespan.days7:
      result = {
        ...result,
        ...getLastXDays(7)
      };
      break;
    case timespan.month1:
      result = {
        ...result,
        ...getLastXDays(30)
      };
      break;
    case timespan.months3:
      result = {
        ...result,
        ...getLastXDays(90)
      };
      break;
    case timespan.year1:
      result.currentTimeSpanStart = startTime
        .clone()
        .startOf("year")
        .utc()
        .format();
      result.currentTimeSpanEnd = startTime
        .clone()
        .endOf("year")
        .utc()
        .format();
      result.previousTimeSpanStart = startTime
        .clone()
        .subtract(1, "year")
        .startOf("year")
        .utc()
        .format();
      result.previousTimeSpanEnd = startTime
        .clone()
        .subtract(1, "year")
        .endOf("year")
        .utc()
        .format();
      break;
    case timespan.allTime:
      result.currentTimeSpanStart = opt?.startTime
        ? opt.startTime.utc().format()
        : startTime
            .clone()
            .set("year", DEFAULT_ALL_TIME_START_YEAR)
            .startOf("year")
            .utc()
            .format();
      result.currentTimeSpanEnd = opt?.startTime
        ? opt.startTime.utc().format()
        : startTime
            .clone()
            .set("year", DEFAULT_ALL_TIME_START_YEAR)
            .endOf("year")
            .utc()
            .format();
      result.previousTimeSpanStart = opt?.startTime
        ? opt.startTime.utc().format()
        : startTime
            .clone()
            .set("year", DEFAULT_ALL_TIME_START_YEAR)
            .startOf("year")
            .utc()
            .format();
      result.previousTimeSpanEnd = opt?.startTime
        ? opt.startTime.utc().format()
        : startTime
            .clone()
            .set("year", DEFAULT_ALL_TIME_START_YEAR)
            .endOf("year")
            .utc()
            .format();
      break;
  }
  return result;
};
const timespans: timeSpanFilterOption[] = [
  // {
  //   label: "Yesterday",
  //   groupBy: "hour",
  //   key: "week",
  //   filterType: "yesterday"
  // },
  { label: "Last 7 days", groupBy: "day", key: "month", filterType: "last7" },
  { label: "Last 30 days", groupBy: "day", filterType: "last30" },
  { label: "Last 90 days", groupBy: "day", filterType: "last90" },
  { label: "Last 6 months", groupBy: "day", filterType: "months6" },
  { label: "Last year", groupBy: "week", filterType: "lastYear" },
  { label: "All time", groupBy: "month", filterType: "allTime" }
];
export type timeSpanDetail = ReturnType<typeof getQueryTimeVariables>;
export interface IFilterProps {
  timeSpan: timeSpanFilterType;
  onChange?: (payload: timeSpanDetail) => void;
}
export class TimeSpanFilter extends React.Component<
  IFilterProps,
  { timeSpan: timeSpanFilterType; mountTime: moment.Moment }
> {
  state = {
    timeSpan: this.props.timeSpan || timespan.days7,
    mountTime: moment().utc()
  };
  public getTimeSpanObj = () => {
    return _.find(timespans, t => t.filterType === this.state.timeSpan);
  };
  public componentDidMount() {
    this.handleChange(this.state.timeSpan);
  }
  public handleChange = (timeSpan: any) => {
    this.setState({ timeSpan }, () => {
      if (_.isFunction(this.props.onChange)) {
        const timeSpanObj = this.getTimeSpanObj();
        this.props.onChange(
          getQueryTimeVariables({
            ...timeSpanObj,
            startTime: this.state.mountTime
          })
        );
      }
    });
  };

  public render() {
    return (
      <ElmFilterSelect
        onChange={this.handleChange}
        value={this.state.timeSpan}
        className={clsx("filtered", "filterBorder")}
        dropdownMatchSelectWidth={false}
      >
        {_.map(timespans, timespan => {
          return (
            <ElmSelectOption key={timespan.filterType}>
              <span className="filterOption">{timespan.label}</span>
            </ElmSelectOption>
          );
        })}
      </ElmFilterSelect>
    );
  }
}
