/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type userAttributeQueryVariables = {
  id: string;
};
export type userAttributeQueryResponse = {
  readonly user: {
    readonly linkedUsersCount: number;
    readonly guid: string;
    readonly info: unknown | null;
    readonly email: string | null;
    readonly name: string | null;
    readonly owner: {
      readonly id: string;
      readonly name: string | null;
    } | null;
    readonly location: {
      readonly city: string | null;
      readonly countryCode: string | null;
    } | null;
  } | null;
};
export type userAttributeQuery = {
  readonly response: userAttributeQueryResponse;
  readonly variables: userAttributeQueryVariables;
};

/*
query userAttributeQuery(
  $id: ID!
) {
  user(id: $id) {
    linkedUsersCount
    guid
    info
    email
    name
    owner {
      __typename
      id
      name
    }
    location {
      city
      countryCode
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "linkedUsersCount",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "guid",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "info",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "email",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "city",
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "countryCode",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "userAttributeQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: "LinkedField",
              name: "owner",
              plural: false,
              selections: [v7 /*: any*/, v6 /*: any*/],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "Location",
              kind: "LinkedField",
              name: "location",
              plural: false,
              selections: [v8 /*: any*/, v9 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "userAttributeQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: "LinkedField",
              name: "owner",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "__typename",
                  storageKey: null
                },
                v7 /*: any*/,
                v6 /*: any*/
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "Location",
              kind: "LinkedField",
              name: "location",
              plural: false,
              selections: [v8 /*: any*/, v9 /*: any*/, v7 /*: any*/],
              storageKey: null
            },
            v7 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "2e9fc5a2af68b89c581ce601c798c0e6",
      id: null,
      metadata: {},
      name: "userAttributeQuery",
      operationKind: "query",
      text:
        "query userAttributeQuery(\n  $id: ID!\n) {\n  user(id: $id) {\n    linkedUsersCount\n    guid\n    info\n    email\n    name\n    owner {\n      __typename\n      id\n      name\n    }\n    location {\n      city\n      countryCode\n      id\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "7267d670d79cabf114881001a68e24d7";
export default node;
