import { useLazyLoadQuery } from "react-relay";
import { usersTableExportQuery } from "./exportQueries/usersTable";
import { licensesTableExportQuery } from "./exportQueries/licensesTable";
import { companiesTableExportQuery } from "./exportQueries/companiesTable";
import { productsTableExportQuery } from "./exportQueries/productsTable";
import { tableType } from "./exportButton";

interface IExportQuery {
  bladeScope?: string;
  sortColumn?: string;
  sortDirection?: "ASC" | "DESC";
  filterColumn?: string[];
  filterOp?: string[];
  filterValues?: string[][];
  tableType?: tableType;
}

const getQueryByType = (tableType: IExportQuery["tableType"]) => {
  switch (tableType) {
    case "users":
      return usersTableExportQuery;
    case "licensesTable":
      return licensesTableExportQuery;
    case "companies":
      return companiesTableExportQuery;
    case "products":
      return productsTableExportQuery;
    default:
      return null;
  }
};

export const ExportData = (props: IExportQuery) => {
  const query = getQueryByType(props.tableType);

  const data = useLazyLoadQuery(query, {
    bladeScope: props.bladeScope,
    sortColumn: props.sortColumn,
    sortDirection: props.sortDirection,
    filterColumn: props.filterColumn,
    filterOp: props.filterOp,
    filterValues: props.filterValues
  });

  return data;
};
