import { LoadingOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import ImgCrop from "antd-img-crop";
import relayEnvironment from "../../../../api/relay";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
//@ts-ignore
import { useSelector } from "react-redux";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../../components/elmSelect";
import { ElmButton } from "../../../../components/elmButton";
import { ElmInput } from "../../../../components/elmInput";
import {
  editProductQuery,
  editProductQueryResponse
} from "./__generated__/editProductQuery.graphql";
import { getGateway } from "../../../../api";
import {
  fileActionHandler,
  getBase64,
  getStaticMediaFile
} from "../../../../utils";
import {
  FieldsContainer,
  MultiFieldRowContainer
} from "../../../../components/bladeManager/appDialog/helpers";

import { LogoPlaceholder } from "../../../../assets";
import { selectors } from "store/ducks/app";
import { IOnboardingState } from "store/types";

const graphqlQuery = graphql`
  query editProductQuery($id: ID!) {
    product(id: $id) {
      name
      supportEmail
      iconUrl
      guid
      vendorId
      type {
        id
        name
      }
      features {
        nodes {
          name
        }
      }
    }
  }
`;
const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
`;
const DialogTitle = styled.span`
  ${props => props.theme.fontType.visualizationTitle}
`;
const ImageDescription = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  letter-spacing: 0.16px;

  /* text / text-02 */

  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 11px;
`;
export interface IeditProductDialogProps {
  productId: string;
  editMode?: boolean;
}
export interface IeditProductDialogState {
  role: string;
}
const ImageSectionContainer = styled.div`
  width: 64px;
  height: 66px;
  margin-right: 17px;
`;
type state = {
  imageUrl: string;
  image: File | Blob;
  loading: boolean;
  productImageLink: string;
  editProduct: {
    id: string;
    vendor_id: string;
    guid: string;
    name: string;
    support_email: string;
    features: string[];
  };
  product_type_id: number;
  error_email: boolean;
};
const DialogBody = (props: {
  handleImageChange: (info: UploadChangeParam<UploadFile<any>>) => void;
  beforeUpload: (file: RcFile) => boolean;
  product?: editProductQueryResponse["product"];
  imageUrl: string;
  loading: boolean;
  removeImage: () => void;
  updatedName: (val: string) => void;
  updatedEmail: (val: string) => void;
  updatedType: (val: any) => void;
  editMode: boolean;
  error: boolean;
  fileAction: (file: RcFile) => Promise<string>;
}) => {
  const [types, setTypes] = React.useState<
    { id: number | string; description: string }[]
  >([]);
  const [fetchError, setFetchError] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!types.length && !fetchError && !props.editMode) {
      getGateway()
        .request.getProductTypes()
        .then(res => {
          if (res.error) {
            setFetchError(true);
            console.error(res.error);
          } else {
            setFetchError(false);
            setTypes(res.data);
          }
        });
    }
  });
  return (
    <div>
      <Flex style={{ flex: 1, marginBottom: "10px" }}>
        <ElmInput
          defaultValue={props?.product?.name}
          label={"Name"}
          placeholder="Enter Product Name"
          updatedValue={props.updatedName}
        />
      </Flex>
      <MultiFieldRowContainer>
        {props.editMode && (
          <ElmSelectWithLabel
            value={props?.product?.type?.name || null}
            disabled={true}
            label="Product type"
            className={"subtle"}
          >
            <ElmSelectOption key={props.product.type.id}>
              {props.product.type.name}
            </ElmSelectOption>
          </ElmSelectWithLabel>
        )}
        {!props.editMode && (
          <ElmSelectWithLabel
            loading={true}
            onChange={props.updatedType}
            disabled={false}
            label="Product type"
            placeholder="Select product type"
            className={"subtle"}
          >
            {types &&
              types.length &&
              types.map(item => {
                return (
                  <ElmSelectOption key={item.id} label={item.description}>
                    {item.description}
                  </ElmSelectOption>
                );
              })}
          </ElmSelectWithLabel>
        )}
      </MultiFieldRowContainer>
      <MultiFieldRowContainer>
        <ElmInput
          defaultValue={props?.product?.supportEmail}
          updatedValue={props.updatedEmail}
          label="Contact email"
          placeholder="john@example.com"
          error={props?.error}
          errorMessage={"Email is not valid"}
        />
      </MultiFieldRowContainer>
      <Flex style={{ marginBottom: "30px" }}>
        <ImageSectionContainer>
          <ImgCrop>
            {props.imageUrl ? (
              <img
                src={
                  /^data\:image/.test(props.imageUrl)
                    ? props.imageUrl
                    : getStaticMediaFile({ url: props.imageUrl })
                }
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              <Flex justifyContent={"center"} alignItems="center">
                {props.loading && <LoadingOutlined />}
                {!props.loading && (
                  <img src={LogoPlaceholder} alt="logo placeholder" />
                )}
              </Flex>
            )}
          </ImgCrop>
        </ImageSectionContainer>
        <Flex style={{ flexDirection: "column" }}>
          <span className="title">Logo</span>
          <ImageDescription>
            Only .jpg and .png files. Aspect ratio 1:1
          </ImageDescription>
          <Flex justifyContent={"flex-start"}>
            <ImgCrop>
              <Upload
                showUploadList={false}
                action={props.fileAction}
                beforeUpload={props.beforeUpload}
                onChange={props.handleImageChange}
              >
                <ElmButton
                  style={{ marginLeft: "0px" }}
                  label={"Choose logo"}
                  colorVariance="outline-secondary"
                />
              </Upload>
            </ImgCrop>

            {props.imageUrl && (
              <ElmButton
                label={"Remove"}
                colorVariance="outline-secondary"
                onClick={props.removeImage}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};
export class EditProductDialog extends DialogBase<
  IModalProps<IeditProductDialogProps> & {
    result?: editProductQueryResponse;
    onboardingState?: IOnboardingState;
  },
  state
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          width: "330px"
        }
      }
    });
  }
  static getAppConnectOptions() {
    return super.getAppConnectOptions({
      selectors: {
        onboardingMode: "onboardingModeSelector"
      }
    });
  }
  componentDidMount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingPause();
  }
  componentWillUnmount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingContinue();
  }
  public checkOnboardingMode = () => {
    return this.props.onboardingState?.status === "active";
  };
  product: any = this.props?.result?.product || {};
  state: state = {
    imageUrl: this.product.iconUrl || "",
    image: null,
    loading: false,
    productImageLink: "",
    editProduct: {
      id: this.props?.payload?.productId || null,
      guid: this.product.guid || "",
      name: this.product.name || "",
      support_email: this.product.supportEmail || "",
      vendor_id: this.product.vendorId || null,
      features: this.product?.features?.nodes.map(
        (val: { name: string }) => val.name
      )
    },
    product_type_id: null,
    error_email: false
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditProductDialog"
    });
  };
  public editAdmin = () => {
    if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
      this.props.payload.onConfirm(this.state);
    }
  };
  public getTitle = () => {
    return this.props.payload.editMode ? "Edit Product" : "Add Product";
  };
  public getDescription = () => {
    return `Change the role you would like your new administrator to have.`;
  };

  public getSelectOptions = () => {
    return [
      {
        label: "Administrator",
        value: "license_admin"
      },
      {
        label: "Owner",
        value: "owner"
      },
      {
        label: "Stakeholder",
        value: "stakeholder"
      }
    ];
  };
  public validateEmail = (input: string) => {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input);
    if (!isValid) {
      this.setState({ error_email: true });
      return false;
    }
    this.setState({ error_email: false });
    return true;
  };
  public handleImageChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl, image) =>
        this.setState({
          imageUrl,
          image,
          loading: false,
          productImageLink: ""
        })
      );
    }
  };

  public beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Please select either a JPG or a PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Please make sure that the image is smaller than 2 MB");
    }
    return isJpgOrPng && isLt2M;
  };
  public fileAction = (file: RcFile) => {
    return fileActionHandler(file, (imageUrl, image, res) =>
      this.setState(
        {
          imageUrl,
          image,
          loading: false,
          productImageLink: ""
        },
        () => {
          res(imageUrl);
        }
      )
    );
  };
  public renderProductInfo = () => {
    const updatedName = (name: string) => {
      this.setState({
        ...this.state,
        editProduct: { ...this.state.editProduct, name }
      });
    };
    const updatedEmail = (support_email: string) => {
      this.setState({
        ...this.state,
        error_email: false,
        editProduct: { ...this.state.editProduct, support_email }
      });
    };

    const updatedType = (type_id: number) => {
      this.setState({
        ...this.state,
        product_type_id: type_id
      });
    };

    const removeImageUrl = () => {
      this.setState({ image: null, imageUrl: "" });
    };
    return (
      <DialogBody
        loading={this.state.loading}
        product={this.props?.result?.product}
        beforeUpload={this.beforeUpload}
        handleImageChange={this.handleImageChange}
        imageUrl={this.state.imageUrl}
        removeImage={removeImageUrl}
        updatedName={updatedName}
        updatedEmail={updatedEmail}
        updatedType={updatedType}
        fileAction={this.fileAction}
        editMode={this.props.payload.editMode}
        error={this.state.error_email}
      />
    );
  };
  public editProduct = async () => {
    const isValid = this.validateEmail(this.state.editProduct?.support_email);
    if (!isValid) {
      return;
    }
    const gateway = getGateway();

    try {
      const dataInfo = this.props.payload.editMode
        ? await gateway.request.editProduct(
            {
              product: {
                ...this.state.editProduct
              },
              features: this.state.editProduct.features
            },
            { id: this.props.payload.productId }
          )
        : await gateway.request.newProduct({
            name: this.state.editProduct.name,
            product_type_id: this.state.product_type_id,
            support_email: this.state.editProduct.support_email
          });

      if (dataInfo.error) {
        console.error("Company data save failed: ", dataInfo.error);
        throw new Error("Error");
      }
      if (this.state.image) {
        const formData = new FormData();
        const file = this.state.image;
        formData.append("file", file, _.get(file, "name"));
        const uploadInfo = this.props.payload.editMode
          ? await gateway.request.uploadProductImage(formData, {
              id: this.props.payload.productId
            })
          : await gateway.request.uploadProductImage(formData, {
              id: dataInfo?.data?.graphql_id
            });
      }

      if (_.isFunction(this.props.payload.onConfirm)) {
        this.props.payload.onConfirm(dataInfo.data);
      }
    } catch (error) {
      console.error("Product overall data save failed: ", error);
    }
  };
  public render() {
    return (
      <DialogContainer className={"edit-product-dialog"}>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <FieldsContainer>{this.renderProductInfo()}</FieldsContainer>
        <Flex justifyContent={"flex-end"}>
          {this.checkOnboardingMode() ? null : (
            <ElmButton
              label={"Cancel"}
              colorVariance={"outline-secondary"}
              onClick={this.closeModal}
            />
          )}
          <ElmButton
            label={this.props.payload.editMode ? "Update" : "Create Product"}
            colorVariance="primary"
            onClick={this.editProduct}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
export const DataContainer = (props: IModalProps<IeditProductDialogProps>) => {
  const onboardingState = useSelector(selectors.onboardingModeSelector);
  const renderEditProduct = (payload: { props: editProductQueryResponse }) => {
    if (!payload.props) {
      return null;
    }
    return <EditProductDialog {...props} result={payload.props} />;
  };
  return (
    <DialogContainer>
      {props?.payload && !props.payload.editMode && (
        <EditProductDialog {...props} onboardingState={onboardingState} />
      )}
      {props?.payload && props.payload.editMode && (
        <QueryRenderer<editProductQuery>
          environment={relayEnvironment}
          variables={{
            id: _.get(props, "payload.productId")
          }}
          query={graphqlQuery}
          render={renderEditProduct}
        />
      )}
    </DialogContainer>
  );
};
export default DataContainer;
