import styled from "styled-components";
import { IElmInputProps } from ".";

export const StyledLabel = styled.label`
  font-family: ${props => props.theme.font.main};
  font-size: ${props => props.theme.fontSizes.xSmall};
  color: ${props => props.theme.colors.inputLabels};
  z-index: auto;
  margin-bottom: 4px;
  line-height: 1.25;
`;

export const StyledLabelNote = styled(StyledLabel)`
  font-size: ${props => props.theme.fontSizes.xxSmall};
  margin-top: 2px;
`;

export const InputContainer = styled.div<{
  error?: boolean;
}>`
  display: inline-flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  //margin-top: -6px;
  height: 18px !important;
  border-bottom: 1px solid
    ${props =>
      props.error ? props.theme.colors.orangeRed : props.theme.colors.greyish};
  :focus-within {
    border-bottom: 1px solid
      ${props =>
        props.error
          ? props.theme.colors.orangeRed
          : props.theme.colors.lightBlue};
  }
`;
export const StyledInput = styled.input<IElmInputProps>`
  ${props => props.theme.fontType.input}
  border: none;
  height: inherit !important;
  border-radius: unset;
  flex-grow: 2;
  width: calc(100% - 8px);
  min-width: 0;
  flex: 2 1 0px;
  justify-self: flex-start;
  align-self: flex-end;
  padding: 1px;
  background-color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.font.main};
  :focus {
    outline: none;
    border: none;
    border-width: initial;
    color: ${props => props.theme.colors.inputTextActive};
  }
  :not(:placeholder-shown) {
    color: ${props => props.theme.colors.realBlack};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  ::placeholder {
    color: ${props => props.theme.colors.inputTextDefault};
  }
`;
export const UnitsSpan = styled.span`
  margin: 0px 4px;
  font-size: ${props => props.theme.fontSizes.xSmall};
  color: ${props => props.theme.colors.warmGrey};
  height: 18px;
`;
export const StyledErrorLabel = styled.p<{
  error?: boolean;
  errorMessage?: string;
}>`
  font-size: ${props => props.theme.fontSizes.xxSmall};
  font-family: ${props => props.theme.font.main};
  margin-top: 2px;
  margin-bottom: 0px;
  color: ${props => props.theme.colors.orangeRed};
  display: ${props =>
    !!props.error && !!props.errorMessage ? "block" : "none"};
`;
