/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseUsersTableQueryVariables = {
  blade_scope: string;
};
export type licenseUsersTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"licenseUsersTable_users">;
};
export type licenseUsersTableQuery = {
  readonly response: licenseUsersTableQueryResponse;
  readonly variables: licenseUsersTableQueryVariables;
};

/*
query licenseUsersTableQuery(
  $blade_scope: String!
) {
  ...licenseUsersTable_users_6Y2LU
}

fragment licenseUsersTable_users_6Y2LU on Query {
  usersCount(bladeScope: $blade_scope, filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]])
  users(first: 25, bladeScope: $blade_scope, filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]]) {
    edges {
      node {
        id
        name
        email
        createdAt
        instancesCount(bladeScope: $blade_scope)
        hasActiveSession
        lastActiveSession {
          endTime
          id
        }
        active
        products {
          nodes {
            id
            iconUrl
            name
          }
        }
        productUsers {
          nodes {
            product {
              id
              name
            }
            enabled
            user {
              id
            }
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "blade_scope"
      } as any
    ],
    v1 = {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    v2 = {
      kind: "Literal",
      name: "filterColumn",
      value: ["users.active"]
    } as any,
    v3 = {
      kind: "Literal",
      name: "filterOp",
      value: [["="]]
    } as any,
    v4 = {
      kind: "Literal",
      name: "filterValues",
      value: [["true"]]
    } as any,
    v5 = [
      v1 /*: any*/,
      v2 /*: any*/,
      v3 /*: any*/,
      v4 /*: any*/,
      {
        kind: "Literal",
        name: "first",
        value: 25
      } as any
    ],
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "licenseUsersTableQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "blade_scope",
              variableName: "blade_scope"
            }
          ],
          kind: "FragmentSpread",
          name: "licenseUsersTable_users"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "licenseUsersTableQuery",
      selections: [
        {
          alias: null,
          args: [v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
          kind: "ScalarField",
          name: "usersCount",
          storageKey: null
        },
        {
          alias: null,
          args: v5 /*: any*/,
          concreteType: "UserConnection",
          kind: "LinkedField",
          name: "users",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "UserEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "User",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    v6 /*: any*/,
                    v7 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "email",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "createdAt",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: [v1 /*: any*/],
                      kind: "ScalarField",
                      name: "instancesCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasActiveSession",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "Session",
                      kind: "LinkedField",
                      name: "lastActiveSession",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "endTime",
                          storageKey: null
                        },
                        v6 /*: any*/
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "active",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "ProductConnection",
                      kind: "LinkedField",
                      name: "products",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "Product",
                          kind: "LinkedField",
                          name: "nodes",
                          plural: true,
                          selections: [
                            v6 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "iconUrl",
                              storageKey: null
                            },
                            v7 /*: any*/
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "ProductUserConnection",
                      kind: "LinkedField",
                      name: "productUsers",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "ProductUser",
                          kind: "LinkedField",
                          name: "nodes",
                          plural: true,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "Product",
                              kind: "LinkedField",
                              name: "product",
                              plural: false,
                              selections: [v6 /*: any*/, v7 /*: any*/],
                              storageKey: null
                            },
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "enabled",
                              storageKey: null
                            },
                            {
                              alias: null,
                              args: null,
                              concreteType: "User",
                              kind: "LinkedField",
                              name: "user",
                              plural: false,
                              selections: [v6 /*: any*/],
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: v5 /*: any*/,
          filters: [
            "bladeScope",
            "search",
            "filterColumn",
            "filterOp",
            "filterValues"
          ],
          handle: "connection",
          key: "licenseUsersTable_users",
          kind: "LinkedHandle",
          name: "users"
        }
      ]
    },
    params: {
      cacheID: "5d454537d62775d3c619764db006dc65",
      id: null,
      metadata: {},
      name: "licenseUsersTableQuery",
      operationKind: "query",
      text:
        'query licenseUsersTableQuery(\n  $blade_scope: String!\n) {\n  ...licenseUsersTable_users_6Y2LU\n}\n\nfragment licenseUsersTable_users_6Y2LU on Query {\n  usersCount(bladeScope: $blade_scope, filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]])\n  users(first: 25, bladeScope: $blade_scope, filterColumn: ["users.active"], filterOp: [["="]], filterValues: [["true"]]) {\n    edges {\n      node {\n        id\n        name\n        email\n        createdAt\n        instancesCount(bladeScope: $blade_scope)\n        hasActiveSession\n        lastActiveSession {\n          endTime\n          id\n        }\n        active\n        products {\n          nodes {\n            id\n            iconUrl\n            name\n          }\n        }\n        productUsers {\n          nodes {\n            product {\n              id\n              name\n            }\n            enabled\n            user {\n              id\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "7350d72b1a229ba5d63c98ef664787ec";
export default node;
