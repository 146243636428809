import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import * as React from "react";
import { createFragmentContainer } from "react-relay";
import {
  AttributesBox,
  IAttributValuePair,
  IAttributesBoxProps
} from "../../../components/attributesBox";
import ElmInput from "../../../components/elmInput";
import { userAttributeAdvanced_user } from "./__generated__/userAttributeAdvanced_user.graphql";

export interface IUserAttributeAdvancedProps {
  user: userAttributeAdvanced_user;
  mode?: IAttributesBoxProps["mode"];
}

export class UserAttributeAdvanced extends React.Component<
  IUserAttributeAdvancedProps
> {
  public getItems = (): IAttributValuePair[] => {
    if (this.props.mode === "edit") {
      return [
        {
          label: "Company",
          value: _.get(this.props.user, "owner.name")
        },

        {
          label: "Location",
          value: (
            <ElmInput placeholder={_.get(this.props.user, "location.city")} />
          )
        },
        {
          label: "Linked Accounts",
          value: this.props.user.linkedUsersCount.toString()
        },
        {
          label: "ID",
          value: this.props.user.guid
        },
        {
          label: "UPN",
          value: _.get(this.props.user.info, "uPN")
        }
      ];
    }
    return [
      {
        label: "Company",
        value: _.get(this.props.user, "owner.name")
      },
      {
        label: "Linked Accounts",
        value: this.props.user.linkedUsersCount.toString()
      },
      {
        label: "Location",
        value: _.get(this.props.user, "location.city")
      },
      {
        label: "ID",
        value: this.props.user.guid
      },
      {
        label: "UPN",
        value: _.get(this.props.user.info, "uPN")
      }
    ];
  };

  public render() {
    return (
      <AttributesBox
        title="Advanced"
        items={this.getItems()}
        mode={this.props.mode}
      />
    );
  }
}

export default createFragmentContainer(UserAttributeAdvanced, {
  user: graphql`
    fragment userAttributeAdvanced_user on User {
      linkedUsersCount
      instancesCount
      guid
      info

      owner {
        name
      }

      location {
        city
      }
    }
  `
});
