import { IStore } from "../../types";
import { createSelector } from "reselect";
import { addNamespace } from "../../../utils";

const actionDefinitions = addNamespace(
  {
    updateAnalyticsFilter: "updateAnalyticsFilter"
  },
  "analytics"
);

const analyticsSelector = (state: IStore, ownProps: any) => state.analytics;

const filtersSelector = createSelector(
  analyticsSelector,
  analyticsSelector => analyticsSelector.filters
);

const selectors = {
  filtersSelector
};

const actions = {
  updateFilter: (data: any) => ({
    data,
    type: "REQUEST_ANALYTICS_FILTER_UPDATE" //actionDefinitions.updateAnalyticsFilter,
  })
};

export { selectors, actions };
