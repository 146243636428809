/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userInstallsTableQueryVariables = {
  id: string;
};
export type userInstallsTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"userInstallsTable_installs">;
};
export type userInstallsTableQuery = {
  readonly response: userInstallsTableQueryResponse;
  readonly variables: userInstallsTableQueryVariables;
};

/*
query userInstallsTableQuery(
  $id: ID!
) {
  ...userInstallsTable_installs_1Bmzm5
}

fragment userInstallsTable_installs_1Bmzm5 on Query {
  user(id: $id) {
    installs: instances(sortColumn: ["hostname"], sortDirection: ["ASC"]) {
      edges {
        node {
          id
          guid
          license {
            product {
              name
              id
            }
            id
          }
          hostName
          validationEntries(last: 1) {
            nodes {
              createdAt
              version
            }
          }
          active
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = [
      {
        kind: "Literal",
        name: "sortColumn",
        value: ["hostname"]
      } as any,
      {
        kind: "Literal",
        name: "sortDirection",
        value: ["ASC"]
      } as any
    ],
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "userInstallsTableQuery",
      selections: [
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "userInstallsTable_installs"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "userInstallsTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            {
              alias: "installs",
              args: v2 /*: any*/,
              concreteType: "InstanceConnection",
              kind: "LinkedField",
              name: "instances",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "InstanceEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Instance",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "guid",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: "License",
                          kind: "LinkedField",
                          name: "license",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "Product",
                              kind: "LinkedField",
                              name: "product",
                              plural: false,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "name",
                                  storageKey: null
                                },
                                v3 /*: any*/
                              ],
                              storageKey: null
                            },
                            v3 /*: any*/
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "hostName",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: [
                            {
                              kind: "Literal",
                              name: "last",
                              value: 1
                            }
                          ],
                          concreteType: "ValidationEntryConnection",
                          kind: "LinkedField",
                          name: "validationEntries",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "ValidationEntry",
                              kind: "LinkedField",
                              name: "nodes",
                              plural: true,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "createdAt",
                                  storageKey: null
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "version",
                                  storageKey: null
                                }
                              ],
                              storageKey: null
                            }
                          ],
                          storageKey: "validationEntries(last:1)"
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "active",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey:
                'instances(sortColumn:["hostname"],sortDirection:["ASC"])'
            },
            {
              alias: "installs",
              args: v2 /*: any*/,
              filters: ["search", "bladeScope", "sortColumn", "sortDirection"],
              handle: "connection",
              key: "userInstallsTable_installs",
              kind: "LinkedHandle",
              name: "instances"
            },
            v3 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "f2b5951cbf5b66d204dcd0cf2a7d1c88",
      id: null,
      metadata: {},
      name: "userInstallsTableQuery",
      operationKind: "query",
      text:
        'query userInstallsTableQuery(\n  $id: ID!\n) {\n  ...userInstallsTable_installs_1Bmzm5\n}\n\nfragment userInstallsTable_installs_1Bmzm5 on Query {\n  user(id: $id) {\n    installs: instances(sortColumn: ["hostname"], sortDirection: ["ASC"]) {\n      edges {\n        node {\n          id\n          guid\n          license {\n            product {\n              name\n              id\n            }\n            id\n          }\n          hostName\n          validationEntries(last: 1) {\n            nodes {\n              createdAt\n              version\n            }\n          }\n          active\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "4078d598ac2f04f47645d3f57b699acd";
export default node;
