import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { licenseReleaseTable_releaseChannels } from "./__generated__/licenseReleaseTable_releaseChannels.graphql";
import { tableGenerator } from "../../../utils";
import { LoaderContainer } from "components/helpers";
import { Flex } from "reflexbox";
import { QueryRenderer } from "react-relay";
import { IGeneratedTableProps } from "utils/tableGenerator";
import { getEnvironment } from "api/relay";
import {
  licenseReleaseTableQuery,
  licenseReleaseTableQueryResponse
} from "./__generated__/licenseReleaseTableQuery.graphql";

export type userTableEdges = licenseReleaseTable_releaseChannels["license"]["releaseChannels"]["edges"];
export type userTableType = userTableEdges[number]["node"];
export type ILicenseReleaseTableProps = {
  releaseChannels: licenseReleaseTable_releaseChannels;
};

export const GeneratedTable = tableGenerator<ILicenseReleaseTableProps>({
  columns: [
    {
      Header: "Release Configuration",
      accessor: "releaseConfiguration.name"
    },
    {
      Header: "Current Release",
      accessor: "currentRelease.tag"
    },
    {
      Header: "Channel",
      accessor: "name"
    }
  ],
  connectionQuery: graphql`
    query licenseReleaseTablePaginationQuery(
      $id: ID!
      $count: Int!
      $cursor: String
      $search: String
    ) {
      ...licenseReleaseTable_releaseChannels
        @arguments(id: $id, count: $count, cursor: $cursor, search: $search)
    }
  `,
  dataKey: "releaseChannels.license.releaseChannels",
  fragmentSpec: {
    releaseChannels: graphql`
      fragment licenseReleaseTable_releaseChannels on Query
        @argumentDefinitions(
          id: { type: "ID!" }
          count: { type: "Int", defaultValue: 6 }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
        ) {
        license(id: $id) {
          releaseChannels(first: $count, after: $cursor, search: $search)
            @connection(key: "licenseReleasesTable_releaseChannels") {
            edges {
              node {
                id
                name
                releaseConfiguration {
                  id
                  name
                }
                currentRelease {
                  id
                  tag
                  createdAt
                }
              }
            }
          }
        }
      }
    `
  }
});

const graphqlQuery = graphql`
  query licenseReleaseTableQuery($id: ID!) {
    ...licenseReleaseTable_releaseChannels @arguments(id: $id)
  }
`;

class ReleaseChannelsPaginationTable extends React.Component<
  IGeneratedTableProps & {
    result: licenseReleaseTableQueryResponse;
    retry: () => void;
  }
> {
  public render() {
    const res = this.props.result;
    return (
      <GeneratedTable
        {...this.props}
        //@ts-ignore
        releaseChannels={res}
      />
    );
  }
}

const relayEnvironment = getEnvironment();
const LicenseReleaseTable = (props: IGeneratedTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: licenseReleaseTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <ReleaseChannelsPaginationTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<licenseReleaseTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.bladeScope || ""
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default LicenseReleaseTable;
