import React, { useState } from "react";
import { useTheme } from "styled-components";
import { Flex } from "reflexbox/styled-components";
import { appConnect, appDispatch } from "../../../store/appConnect";
import {
  IBladeBaseProps,
  IOpenAddReportBlade
} from "../../../components/bladeManager/types";
import { BladeTitle, BladeTemplate, ElmButton } from "../../../components";
import { notification } from "antd";
import { getGateway } from "api";
import _ from "lodash";
import { getEnvironment } from "api/relay";
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  ReportsQuery,
  ReportsQueryResponse
} from "./__generated__/ReportsQuery.graphql";
import { getSavedRoleFromLocalSync, tableGenerator } from "../../../utils";
import { Reports_reports } from "./__generated__/Reports_reports.graphql";
import { Report } from "Reports/common/types";
import { TimeRange } from "Reports/common/const";
import { TableCellProps } from "react-virtualized";
import dayjs from "dayjs";
import { ReportQueryResponse } from "../Report/__generated__/ReportQuery.graphql";
import { DataExporter } from "Reports/components/exportOptionsDropdown";

export type reportTableEdges = Reports_reports["reports"]["edges"];
export type reportTableType = reportTableEdges[number]["node"];
export type IReportTableProps = {
  reports: Reports_reports;
};

const formatDateColumn = (payload: TableCellProps) => {
  const DATE_FORMAT = "YYYY/MM/DD";
  const startDate = payload.cellData?.options?.filters?.startTime;
  const endDate = payload.cellData?.options?.filters?.endTime;
  const duration = payload.cellData?.options?.filters?.duration;

  const exact = Object.keys(TimeRange).find(
    key => TimeRange[key].value === duration
  );

  if (exact && exact !== "custom") {
    return TimeRange[exact].label;
  }

  if (startDate && !endDate) {
    return `From ${dayjs(startDate).format(DATE_FORMAT)}`;
  }

  return `${dayjs(startDate)
    .format(DATE_FORMAT)
    .concat(" - ")
    .concat(dayjs(endDate).format(DATE_FORMAT))}`;
};

export const ReportsTable = tableGenerator<IReportTableProps>({
  columns: [
    {
      Header: "Report",
      accessor: "name",
      sortKey: "reports.name"
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      sortKey: "reports.createdAt",
      isDateFormat: true
    },
    {
      Header: "Created By",
      accessor: "vendorsystemUser",
      cellRenderer: payload =>
        payload.rowData?.vendorSystemUser?.name ||
        payload.rowData?.vendorSystemUser?.email,
      disableSortBy: true
    },
    {
      Header: "Time range",
      accessor: "options",
      cellRenderer: payload => formatDateColumn(payload),
      disableSortBy: true
    },
    {
      Header: "Actions",
      accessor: "",
      cellRenderer: () => "",
      disableSortBy: true,
      width: 0.18
    }
  ],
  dataKey: "reports.reports",
  connectionQuery: graphql`
    query ReportsPaginationQuery(
      $count: Int!
      $cursor: String
      $search: String
      $bladeScope: String
      $sortColumn: [String!]
      $sortDirection: [String!]
    ) {
      ...Reports_reports
        @arguments(
          count: $count
          cursor: $cursor
          search: $search
          bladeScope: $bladeScope
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        )
    }
  `,
  getTotalCount: data => {
    return _.get(data, "reports.reportsCount") || 0;
  },
  fragmentSpec: {
    reports: graphql`
      fragment Reports_reports on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 25 }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
          sortColumn: { type: "[String!]", defaultValue: ["reports.createdAt"] }
          sortDirection: { type: "[String!]", defaultValue: ["DESC"] }
          bladeScope: { type: "String" }
        ) {
        reportsCount(search: $search, bladeScope: $bladeScope)
        reports(
          first: $count
          after: $cursor
          search: $search
          bladeScope: $bladeScope
          sortColumn: $sortColumn
          sortDirection: $sortDirection
        ) @connection(key: "Reports_reports") {
          edges {
            node {
              id
              name
              options
              createdAt
              reportType
              isPrivate
              vendorSystemUser {
                id
                name
                email
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  },
  tableName: "Reports"
});

const graphqlQuery = graphql`
  query ReportsQuery {
    ...Reports_reports
    currentUser {
      id
      name
      email
    }
  }
`;

export interface IReportsBladeProps extends IBladeBaseProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}

export interface IReportAction {
  data: ReportQueryResponse["report"];
  currentUser?: ReportsQueryResponse["currentUser"];
  openEdit: (routeData: IOpenAddReportBlade["routeData"]) => void;
  duplicateAction?: () => void;
  deleteAction?: () => void;
  onConfirm?: () => void;
  disabled?: boolean;
}

export const ReportAction = ({
  data: payload,
  currentUser,
  openEdit,
  onConfirm
}: IReportAction) => {
  const theme = useTheme();
  const [exportConfig, setExportConfig] = useState({
    active: false,
    data: [] as object[],
    header: ""
  });
  const downloadAction = () => {
    const gateway = getGateway();
    gateway.request["renderReport"]({ id: payload?.id }).then(
      ({ data }: any) => {
        setExportConfig({
          header: payload?.name,
          active: true,
          data
        });
      }
    );
  };
  const editAction = () => {
    openEdit({
      id: payload?.id,
      name: payload?.name
    });
  };
  const copyAction = () => {
    const newReport: Report = {
      name: payload?.name + " (copy)",
      report_type: payload?.reportType,
      options: {
        filters: {
          company_id: payload?.options?.options?.filters?.companyId,
          product_id: payload?.options?.options?.filters?.productId,
          component_id: payload?.options?.options?.filters?.componentId,
          duration: payload?.options?.options?.filters?.duration,
          start_time: payload?.options?.options?.filters?.startTime,
          end_time: payload?.options?.options?.filters?.endTime
        },
        columns: payload?.options?.options?.columns,
        include_charts: payload?.options?.options?.includeCharts,
        raw_data: payload?.options?.options?.isRawData,
        minimum_session_length: payload?.options?.options?.sessionLength,
        aggregate_field: payload?.options?.options?.aggregateField
      },
      reporting_interval: payload?.options?.options?.reportingInterval,
      duration: payload?.options?.options?.filters?.duration,
      start_time: payload?.options?.options?.filters?.startTime,
      end_time: payload?.options?.options?.filters?.endTime,
      is_private: payload?.isPrivate
    };
    const gateway = getGateway();
    gateway.request["newReport"](newReport)
      .then((res: any) => {
        if (!res.error && res?.data?.id) {
          notification.open({
            type: "success",
            message: "Success",
            description: `Successfully created a copy of ${payload?.name ||
              "report"} !`
          });
          openEdit({
            id: btoa(`${"Report"}-${res.data.id}`),
            name: res.data.name
          });
        }
      })
      .catch(err => {
        notification.open({
          type: "error",
          message: "Failure",
          description: "Report copying failed, please try again !"
        });
      });
  };
  const deleteAction = () => {
    const gateway = getGateway();
    gateway.request["deleteReport"](null, { id: _.get(payload, "id") })
      .then(res => {
        if (!res.error) {
          notification.open({
            type: "success",
            message: "Success",
            description: "Report Deleted !"
          });
          onConfirm();
        }
      })
      .catch(err => {
        notification.open({
          type: "error",
          message: "Failure",
          description: "Error deleting report !"
        });
      });
  };
  const sharedBtnStyle: React.CSSProperties = {
    color: theme.colors.warmGrey,
    margin: 2,
    padding: 0
  };
  const isActionAllowed =
    getSavedRoleFromLocalSync()?.role === "admin" ||
    getSavedRoleFromLocalSync()?.role === "owner" ||
    payload?.vendorSystemUser?.email === currentUser?.email;
  return (
    <div onClick={e => e.stopPropagation()} style={{ display: "flex", gap: 0 }}>
      {exportConfig.active && (
        <DataExporter
          data={exportConfig.data}
          header={exportConfig.header}
          dispose={() => setExportConfig(prev => ({ ...prev, active: false }))}
        />
      )}
      <ElmButton
        label=""
        title="Download"
        colorVariance="subtle"
        className="darkModeTransparentBtn"
        variance={"icon-button"}
        icon="download"
        iconPrefix="far"
        onClick={downloadAction}
        style={sharedBtnStyle}
      />
      {isActionAllowed ? (
        <ElmButton
          label=""
          title="Edit"
          colorVariance="subtle"
          className="darkModeTransparentBtn"
          variance={"icon-button"}
          icon="pen"
          iconPrefix="far"
          onClick={editAction}
          style={sharedBtnStyle}
        />
      ) : null}
      <ElmButton
        label=""
        title="Duplicate"
        colorVariance="subtle"
        className="darkModeTransparentBtn"
        variance={"icon-button"}
        icon="copy"
        iconPrefix="fal"
        onClick={copyAction}
        style={sharedBtnStyle}
      />
      {isActionAllowed ? (
        <ElmButton
          label=""
          title="Delete"
          colorVariance="subtle"
          className="darkModeTransparentBtn"
          variance={"icon-button"}
          icon="trash-can"
          iconPrefix="far"
          onClick={deleteAction}
          style={sharedBtnStyle}
        />
      ) : null}
    </div>
  );
};

export class ReportsBlade extends React.Component<
  IReportsBladeProps & {
    result: ReportsQueryResponse;
    retry: () => void;
    theme: any;
  }
> {
  public navToReportBlade = (payload: any) => {
    this.props.openBlade({
      route: "Report",
      routeName: "Report",
      fromBladeIndex: this.props.index,
      routeData: {
        id: payload.id,
        name: payload.name
      }
    });
  };

  public renderReportsTable = () => {
    return (
      <div
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
          display: "flex",
          flex: 1,
          marginTop: 12
        }}
      >
        <ReportsTable
          reports={this.props.result}
          onRowClick={this.navToReportBlade}
          bladeName={"Reports"}
          hideColumnsWithHeaders={["Created At"]}
          renderRowActionButtons={data => (
            <ReportAction
              data={data}
              currentUser={this.props.result?.currentUser}
              openEdit={this.openEditReportBlade}
              onConfirm={this.props.refreshAllOpenBlades}
            />
          )}
        />
      </div>
    );
  };
  public renderLeftSideHeader = () => {
    return (
      <div style={{ margin: 18 }}>
        <BladeTitle>Reports</BladeTitle>
      </div>
    );
  };
  public openNewReportBlade = () => {
    this.props.openBlade({
      route: "AddReport",
      routeName: "Add Report",
      fromBladeIndex: this.props.index,
      routeData: null
    });
  };
  public openEditReportBlade = (
    routeData: IOpenAddReportBlade["routeData"]
  ) => {
    this.props.openBlade({
      route: "AddReport",
      routeName: "Edit Report",
      fromBladeIndex: this.props.index,
      routeData
    });
  };
  public renderRightSideHeader = () => {
    return (
      <Flex marginRight={18}>
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon={"plus"}
          iconPrefix={"fas"}
          label={"Report"}
          onClick={this.openNewReportBlade}
        />
      </Flex>
    );
  };

  public render() {
    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        bladeType="Reports"
        closeBlade={() => {
          this.props.closeBlade({ route: "Reports" });
        }}
        title={"Reports"}
        leftAccentColor={"filterBlue"}
        topAccentColor={"filterBlue"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        hideBladeTypeName={true}
        loading={!this.props.result}
      >
        {this.renderReportsTable()}
      </BladeTemplate>
    );
  }
}
const relayEnvironment = getEnvironment();
const RenderQuery = (props: IReportsBladeProps) => {
  const renderReports = (payload: {
    error: Error;
    props: ReportsQueryResponse;
    retry: () => void;
  }) => {
    return (
      <ReportsBlade
        {...props}
        result={payload.props}
        retry={payload.retry}
        theme={null}
      />
    );
  };
  return (
    <QueryRenderer<ReportsQuery>
      environment={relayEnvironment}
      variables={{}}
      query={graphqlQuery}
      fetchPolicy="network-only"
      render={renderReports}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {
    activeRole: "activeRoleSelector"
  }
});
