/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InstanceQueryVariables = {
  id: string;
};
export type InstanceQueryResponse = {
  readonly instance: {
    readonly guid: string;
    readonly hardwareIdentifier: {
      readonly info: unknown | null;
    } | null;
    readonly users: {
      readonly nodes: ReadonlyArray<{
        readonly displayName: string | null;
        readonly id: string;
      } | null> | null;
    } | null;
    readonly license: {
      readonly product: {
        readonly name: string | null;
      } | null;
      readonly owner: {
        readonly id: string;
        readonly name: string | null;
      } | null;
    } | null;
    readonly features: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
      } | null> | null;
    } | null;
    readonly featureGroups: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly features: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<
      "instanceAttribute_instance" | "instanceAttributeAdvanced_instance"
    >;
  } | null;
  readonly " $fragmentRefs": FragmentRefs<"instanceHistoryTable_instance">;
};
export type InstanceQuery = {
  readonly response: InstanceQueryResponse;
  readonly variables: InstanceQueryVariables;
};

/*
query InstanceQuery(
  $id: ID!
) {
  ...instanceHistoryTable_instance_1Bmzm5
  instance(id: $id) {
    ...instanceAttribute_instance
    ...instanceAttributeAdvanced_instance
    guid
    hardwareIdentifier {
      info
    }
    users {
      nodes {
        displayName
        id
      }
    }
    license {
      product {
        name
        id
      }
      owner {
        __typename
        id
        name
      }
      id
    }
    features {
      nodes {
        id
        name
      }
    }
    featureGroups {
      nodes {
        id
        name
        features {
          nodes {
            id
            name
          }
        }
      }
    }
    id
  }
}

fragment instanceAttributeAdvanced_instance on Instance {
  guid
  hardwareIdentifier {
    info
  }
  license {
    product {
      id
      name
    }
    id
  }
}

fragment instanceAttribute_instance on Instance {
  guid
  hardwareIdentifier {
    info
  }
  license {
    product {
      id
      name
    }
    id
  }
}

fragment instanceHistoryTable_instance_1Bmzm5 on Query {
  instance(id: $id) {
    modificationRecords(first: 6) {
      edges {
        node {
          enabled
          createdAt
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "guid",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      concreteType: "HardwareIdentifier",
      kind: "LinkedField",
      name: "hardwareIdentifier",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "info",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      concreteType: "UserConnection",
      kind: "LinkedField",
      name: "users",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "User",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "displayName",
              storageKey: null
            },
            v4 /*: any*/
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v7 = [v4 /*: any*/, v6 /*: any*/],
    v8 = {
      alias: null,
      args: null,
      concreteType: "FeatureConnection",
      kind: "LinkedField",
      name: "features",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Feature",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: v7 /*: any*/,
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      concreteType: "FeatureGroupConnection",
      kind: "LinkedField",
      name: "featureGroups",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "FeatureGroup",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v4 /*: any*/, v6 /*: any*/, v8 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v10 = [
      {
        kind: "Literal",
        name: "first",
        value: 6
      } as any
    ],
    v11 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "__typename",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "InstanceQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Instance",
          kind: "LinkedField",
          name: "instance",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "License",
              kind: "LinkedField",
              name: "license",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: [v6 /*: any*/],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: null,
                  kind: "LinkedField",
                  name: "owner",
                  plural: false,
                  selections: v7 /*: any*/,
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v8 /*: any*/,
            v9 /*: any*/,
            {
              args: null,
              kind: "FragmentSpread",
              name: "instanceAttribute_instance"
            },
            {
              args: null,
              kind: "FragmentSpread",
              name: "instanceAttributeAdvanced_instance"
            }
          ],
          storageKey: null
        },
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "instanceHistoryTable_instance"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "InstanceQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Instance",
          kind: "LinkedField",
          name: "instance",
          plural: false,
          selections: [
            {
              alias: null,
              args: v10 /*: any*/,
              concreteType: "ModificationRecordConnection",
              kind: "LinkedField",
              name: "modificationRecords",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ModificationRecordEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ModificationRecord",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "enabled",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "createdAt",
                          storageKey: null
                        },
                        v11 /*: any*/
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: "modificationRecords(first:6)"
            },
            {
              alias: null,
              args: v10 /*: any*/,
              filters: null,
              handle: "connection",
              key: "instanceHistoryTable_modificationRecords",
              kind: "LinkedHandle",
              name: "modificationRecords"
            },
            v4 /*: any*/,
            v2 /*: any*/,
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "License",
              kind: "LinkedField",
              name: "license",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: v7 /*: any*/,
                  storageKey: null
                },
                v4 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: null,
                  kind: "LinkedField",
                  name: "owner",
                  plural: false,
                  selections: [v11 /*: any*/, v4 /*: any*/, v6 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v5 /*: any*/,
            v8 /*: any*/,
            v9 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "6343a94b7f7263ef0872f246518fe9c7",
      id: null,
      metadata: {},
      name: "InstanceQuery",
      operationKind: "query",
      text:
        "query InstanceQuery(\n  $id: ID!\n) {\n  ...instanceHistoryTable_instance_1Bmzm5\n  instance(id: $id) {\n    ...instanceAttribute_instance\n    ...instanceAttributeAdvanced_instance\n    guid\n    hardwareIdentifier {\n      info\n    }\n    users {\n      nodes {\n        displayName\n        id\n      }\n    }\n    license {\n      product {\n        name\n        id\n      }\n      owner {\n        __typename\n        id\n        name\n      }\n      id\n    }\n    features {\n      nodes {\n        id\n        name\n      }\n    }\n    featureGroups {\n      nodes {\n        id\n        name\n        features {\n          nodes {\n            id\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment instanceAttributeAdvanced_instance on Instance {\n  guid\n  hardwareIdentifier {\n    info\n  }\n  license {\n    product {\n      id\n      name\n    }\n    id\n  }\n}\n\nfragment instanceAttribute_instance on Instance {\n  guid\n  hardwareIdentifier {\n    info\n  }\n  license {\n    product {\n      id\n      name\n    }\n    id\n  }\n}\n\nfragment instanceHistoryTable_instance_1Bmzm5 on Query {\n  instance(id: $id) {\n    modificationRecords(first: 6) {\n      edges {\n        node {\n          enabled\n          createdAt\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "f63186f4592eaba3d56ed84774d99279";
export default node;
