import { combineReducers } from "redux";
// @ts-ignore
import { dialogReducer } from "redux-dialog";
import { reduxTokenAuthReducer } from "./deviseAuth";
import { reducer as app } from "./ducks/app";
import analytics from "./ducks/analytics/reducers";

export const appReducers = {
  app,
  dialogReducer,
  reduxTokenAuth: reduxTokenAuthReducer,
  analytics
};
export default combineReducers({
  app,
  dialogReducer,
  reduxTokenAuth: reduxTokenAuthReducer,
  analytics
});
