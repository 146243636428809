import * as _ from "lodash";
import React, { Component } from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import uuid from "uuid/v4";
import Loader from "react-loaders";
import NoData from "./nodata.svg";

import relayEnvironment from "../../api/relay";
import {
  analyticsComponentQuery,
  analyticsComponentQueryResponse
} from "./__generated__/analyticsComponentQuery.graphql";
import { QueryRenderer } from "react-relay";
import { getClassName } from "../helpers";
import { getShortLicenseName } from "../../utils";
import HorizontalBarChart from "../../components/elmChart/horizontalBarChart";
import LoadingWrapper from "../../components/elmChart/loadingWrapper";
import PieChart from "../../components/elmChart/pieChart";
import LineChart from "../../components/elmChart/lineChart";
import { Row, Tooltip } from "antd";
import { InfoIcon } from "../elmChart/filtersContainer";
import theme from "../../theme";
import HeatMapChart from "../elmChart/heatMapChart";
import DataValues from "../elmChart/dataValues";
import CohortChart from "components/elmChart/cohortChart";
import { IOpenBladeFromChart } from "components/bladeManager/types";
import { DateRange } from "blades/Dashboard/utils";

const Title = styled.p`
  margin: 0px;
  font-size: 20px;
  font-weight: normal;
  text-align: left;
  color: ${props => props.theme.colors.pureBlack};
  line-height: 1;
  font-family: Inter;
`;
const graphqlQuery = graphql`
  query analyticsComponentQuery(
    $bladeScope: String
    $setup: JSON!
    $startTime: String!
    $first: Int
    $sortColumn: String
    $sortDirection: String
    $filterColumn: [String!]
    $filterValues: [[String!]!]
  ) {
    analytics(
      bladeScope: $bladeScope
      setup: $setup
      startTime: $startTime
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      first: $first
      filterColumn: $filterColumn
      filterValues: $filterValues
    ) {
      categories
      groups
      values
    }
  }
`;
const filterOutVendorData = (query: queryType) => {
  query.filterColumn = [...(query.filterColumn || []), "is_vendor"];
  query.filterValues = [...(query.filterValues || []), ["false"]];
  return query;
};
export type AnalyticsSetup = {
  category: string;
  value: string;
  group: string;
};

export type queryType = {
  bladeScope: string;
  startTime: string;
  endTime?: string;
  sortColumn?: "category" | "group" | "value";
  sortDirection?: "ASC" | "DESC";
  first?: number;
  setup: AnalyticsSetup;
  filterColumn?: string[];
  filterValues?: string[][];
  formatValue?: (value: any) => any;
  options?: any;

  valueTitle?: string;
  categoryTitle?: string;
};
// export type analyticsSizeConfiguration = {
//   width: string;
//   height: string;
// };
// export type analyticsSizeTypes =
//   | "x-large"
//   | "large"
//   | "medium-large"
//   | "medium"
//   | "small";

export type AnalyticsComponentProps = {
  analytics: queryType;
  type?: visualizationTypes;
  title?: string;
  containerStyle?: React.CSSProperties;
  chartStyle?: React.CSSProperties;
  className?: string;
  allowVendorAnalytics?: boolean;
  description?: string;
  options?: any;
  registrationData?: any[];
  openBlade?: (data: IOpenBladeFromChart) => void;
  timeRangeOverride?: DateRange;
  allTimeFilterStart?: string;
};
export type visualizationTypes =
  | "stepLine"
  | "line"
  | "column"
  | "bar"
  | "pie"
  | "treeMap"
  | "sunburst"
  | "table"
  | "dataValue"
  | "heatMap"
  | "cohort";
export type AnalyticsComponentState = {
  currentType: visualizationTypes;
  loading: boolean;
};

const defaultType: visualizationTypes = "stepLine";
class AnalyticsComponent extends Component<
  AnalyticsComponentProps & {
    instanceId: string;
    loader?: JSX.Element;
    registrationData?: any[];
  },
  AnalyticsComponentState
> {
  constructor(
    props: AnalyticsComponentProps & {
      instanceId: string;
      loader?: JSX.Element;
    }
  ) {
    super(props);
    this.state = {
      currentType: this.props.type || defaultType,
      loading: false
    };
  }

  private preprocessResult = (
    res: analyticsComponentQueryResponse
  ): analyticsComponentQueryResponse => {
    let categories = null;
    let groups = null;
    let values = null;
    const TARGET = "licenses.type";
    if (this.props.analytics.setup.category === TARGET) {
      categories = _.map(res.analytics.categories, (c: string) =>
        getShortLicenseName(c)
      );
      values = _.map(res.analytics.values, (v: any) => ({
        group: _.get(v, "group"),
        category: getShortLicenseName(_.get(v, "category") as string),
        value: _.get(v, "value")
      }));
    }
    if (this.props.analytics.setup.group === TARGET) {
      groups = _.map(res.analytics.groups, (c: string) =>
        getShortLicenseName(c)
      );
      values = _.map(res.analytics.values, (v: any) => ({
        group: getShortLicenseName(_.get(v, "group") as string),
        category: _.get(v, "category"),
        value: _.get(v, "value")
      }));
    }
    return {
      analytics: {
        groups: groups || res.analytics.groups,
        categories: categories || res.analytics.categories,
        values: values || res.analytics.values
      }
    };
  };
  public getChartData = (res: analyticsComponentQueryResponse) => {
    const values = res.analytics.values;
    const data = [];
    let dataForCategory;
    return res;
  };
  public getNestedChartData = (res: analyticsComponentQueryResponse) => {
    const values = res.analytics.values;
    let data = [];
    let dataForGroup;

    const isGrouped = res.analytics.groups.length > 1;
    if (!isGrouped) {
      data = _.map(values, v => ({
        name: v["category"],
        children: [
          {
            name: v["category"],
            value: v["value"]
          }
        ]
      }));
    } else {
      for (const g of res.analytics.groups) {
        const dataPoint: { [key: string]: any } = {};
        dataForGroup = _.map(
          _.filter(values, v => {
            return v["group"] == g;
          }),
          v => {
            return { name: v["category"], value: v["value"] };
          }
        );
        dataPoint["name"] = g;
        dataPoint["children"] = dataForGroup;
        data.push(dataPoint);
      }
    }
    return data;
  };

  public chartContainerExists = () => true;

  public renderBarChart = () => {
    const finalVariables = this.props.allowVendorAnalytics
      ? this.props.analytics
      : filterOutVendorData(_.cloneDeep(this.props.analytics));
    return (
      <LoadingWrapper>
        <HorizontalBarChart
          openBlade={this.props.openBlade}
          queryParams={{ ...finalVariables }}
          timeRangeOverride={this.props.timeRangeOverride}
          allTimeFilterStart={this.props.allTimeFilterStart}
          hideTimeRangeFilter
          options={{
            useStackedBar: false,
            sortDirection: this.props.analytics?.sortDirection,
            chartMaxHeight:
              this.props?.analytics?.options?.chartMaxHeight || 180,
            lineWidth: this.props?.analytics?.options?.lineWidth // percent value
          }}
          plot={{
            //title: "Power users",
            hideLegend: true,
            series: [],
            yAxes: [
              {
                hideLabel: false
              }
            ]
          }}
        />
      </LoadingWrapper>
    );
  };
  public renderHeatMapChart = () => {
    const finalVariables = this.props.allowVendorAnalytics
      ? this.props.analytics
      : filterOutVendorData(_.cloneDeep(this.props.analytics));
    return (
      <LoadingWrapper>
        <HeatMapChart
          hideTimeRangeFilter
          queryParams={{ ...finalVariables }}
          timeRangeOverride={this.props.timeRangeOverride}
          allTimeFilterStart={this.props.allTimeFilterStart}
          options={{
            chartMaxHeight:
              this.props?.analytics?.options?.chartMaxHeight || 180,
            maxGroups: 20,
            maxCategories: 7
          }}
          plot={{
            hideLegend: true,
            series: [],
            yAxes: [
              {
                hideLabel: false
              }
            ]
          }}
        />
      </LoadingWrapper>
    );
  };

  public renderCohortChart = () => {
    const finalVariables = this.props.allowVendorAnalytics
      ? this.props.analytics
      : filterOutVendorData(_.cloneDeep(this.props.analytics));
    return (
      <LoadingWrapper>
        <CohortChart
          hideTimeRangeFilter
          queryParams={{ ...finalVariables }}
          options={{
            chartMaxHeight:
              this.props?.analytics?.options?.chartMaxHeight || 180,
            maxGroups: 20,
            maxCategories: 7
          }}
          plot={{
            hideLegend: true,
            series: [],
            yAxes: [
              {
                hideLabel: false
              }
            ]
          }}
          registrationData={this.props?.registrationData}
          allTimeFilterStart={this.props.allTimeFilterStart}
          timeRangeOverride={this.props.timeRangeOverride}
        />
      </LoadingWrapper>
    );
  };

  public renderLineChart = () => {
    const finalVariables = this.props.allowVendorAnalytics
      ? this.props.analytics
      : filterOutVendorData(_.cloneDeep(this.props.analytics));
    return (
      <LoadingWrapper>
        <LineChart
          queryParams={{ ...finalVariables }}
          timeRangeOverride={this.props.timeRangeOverride}
          allTimeFilterStart={this.props.allTimeFilterStart}
          hideTimeRangeFilter
          showProductFilter={false}
          options={{
            chartMaxHeight:
              this.props?.analytics?.options?.chartMaxHeight || 170
          }}
          plot={
            {
              //title: "Use over time",
            }
          }
        />
      </LoadingWrapper>
    );
  };

  public renderPieChart = () => {
    const finalVariables = this.props.allowVendorAnalytics
      ? this.props.analytics
      : filterOutVendorData(_.cloneDeep(this.props.analytics));
    return (
      <LoadingWrapper>
        <PieChart
          //data={data}
          queryParams={{ ...finalVariables }}
          timeRangeOverride={this.props.timeRangeOverride}
          allTimeFilterStart={this.props.allTimeFilterStart}
          hideTimeRangeFilter
          showProductFilter={false}
          options={{
            chartMaxHeight:
              this.props?.analytics?.options?.chartMaxHeight || 165
          }}
          plot={
            {
              //title: "Use over time",
            }
          }
        />
      </LoadingWrapper>
    );
  };

  public renderDataValue = () => {
    const finalVariables = this.props.allowVendorAnalytics
      ? this.props.analytics
      : filterOutVendorData(_.cloneDeep(this.props.analytics));
    return (
      <LoadingWrapper>
        <DataValues
          hideTimeRangeFilter
          queryParams={{ ...finalVariables }}
          options={this.props.options}
        />
      </LoadingWrapper>
    );
  };

  public renderChart = (): any => {
    switch (this.state.currentType) {
      case "stepLine":
      case "line":
        return this.renderLineChart();
      case "column":
      case "bar":
        //return this.renderXYChart(res, this.state.currentType);
        return this.renderBarChart();
      // case "treeMap":
      //   return this.renderTreeMapChart(res);
      // case "sunburst":
      //   return this.renderSunburstChart(res);
      case "pie":
        return this.renderPieChart();
      case "heatMap":
        return this.renderHeatMapChart();
      case "cohort":
        return this.renderCohortChart();
      case "dataValue":
        return this.renderDataValue();
      default:
        return this.renderLineChart();
    }
  };
  public showLoader = () => {
    // this.setState({
    //   loading: true,
    // });
  };
  public hideLoader = () => {
    // if (this.indicator) {
    //   this.indicator.hide();
    // }
    // this.setState({
    //   loading: false,
    // });
  };
  public getQuery = () => {
    return this.props.analytics;
  };

  public render() {
    return (
      <Flex
        style={{
          flexDirection: "column",
          flex: 1,
          ...(this.props.containerStyle || { alignItems: "stretch" })
        }}
        className={getClassName({
          [this.props.className]: () => !this.props.className
        })}
      >
        <div>
          <Title style={{ display: "inline", marginRight: 4 }}>
            {this.props.title}
          </Title>
          {this.props?.description ? (
            <Tooltip placement="top" title={this.props?.description}>
              <InfoIcon />
            </Tooltip>
          ) : null}
        </div>
        <Flex
          style={{
            flex: 1,
            display: "flex",
            ...(this.props.chartStyle || {})
          }}
          id={this.props.instanceId}
        >
          {/* {this.props.result && this.renderChart()} */}
          {this.renderChart()}
        </Flex>
      </Flex>
    );
  }
}
class RenderQuery extends React.Component<
  AnalyticsComponentProps,
  { hasData: boolean; cachedResult?: analyticsComponentQueryResponse }
> {
  constructor(props: AnalyticsComponentProps) {
    super(props);
    this.state = { hasData: false };
  }
  private loader = (
    <Flex
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        minHeight: "150px",
        marginLeft: "45px"
      }}
    >
      <Loader
        type="line-scale-pulse-out"
        active={true}
        color={"rgb(98, 98, 130)"}
        style={{ transform: "scale(1.0)" }}
      />
    </Flex>
  );
  private instanceId = uuid();
  private renderComponent = () => {
    return (
      <AnalyticsComponent
        {...this.props}
        instanceId={this.instanceId}
        loader={this.loader}
      />
    );
  };
  public render() {
    return this.renderComponent();
  }
}
export default RenderQuery;
