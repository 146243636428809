import { graphql } from "babel-plugin-relay/macro";

export const productsTableExportQuery = graphql`
  query productsTableExportQuery(
    $bladeScope: String
    $sortColumn: [String!]
    $sortDirection: [String!]
  ) {
    products(
      bladeScope: $bladeScope
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      nodes {
        activeLicenseCount
        id
        name
        activeUsers
        type {
          name
        }
      }
    }
  }
`;
