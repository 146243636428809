import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import * as _ from "lodash";
import React, { useState } from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { ElmCheckBox } from "../../../../components/elmCheckBox";
import { ElmInput } from "../../../../components/elmInput";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../../components/elmSelect";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import relayEnvironment from "../../../../api/relay";
import { getGateway } from "../../../../api";
import {
  editComponentGroupQuery,
  editComponentGroupQueryResponse
} from "./__generated__/editComponentGroupQuery.graphql";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { LoaderContainer } from "../../../../components/helpers";
import { formatInlineDisplayValues } from "Reports/common/utils";
import {
  renderErrorNotification,
  renderSuccessNotification
} from "utils/ant-notifications";

const query = graphql`
  query editComponentGroupQuery($id: ID!, $productId: String!) {
    components(bladeScope: $productId) {
      nodes {
        id
        name
        componentGroup {
          name
        }
      }
    }
    componentGroup(id: $id) {
      name
      product {
        name
      }
    }
  }
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 254px;
  width: 290px;
`;

const componentTypes = [
  {
    id: 1,
    label: "Checked-out Token"
  },
  {
    id: 2,
    label: "Session"
  },
  {
    id: 3,
    label: "Consumable Token"
  }
];
const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
export interface IEditComponentGroupDialogProps {
  productId: string;
  productName: string;
  refreshFn?: () => void;
  componentGroupId: string;
  components: { id: string; name: string; componentGroup?: { name: string } }[];
}
export interface IEditComponentGroupDialogState {
  name: string;
  component_type: { id: number; name: string };
  error: boolean;
  disabled: boolean;
  isSelectOpen: boolean;
  components: { id: string; name: string; componentGroup?: { name: string } }[];
}

const GroupSelectionDesc = styled.p`
  height: 15px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  max-width: 120px;
  min-width: 120px;
  margin: 0px;
  //margin-right: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ComponentSelection = (
  props: editComponentGroupQueryResponse & {
    comp: { id: string; name: string; componentGroup?: { name: string } }[];
    componentGroupId: string;
    refreshFn?: () => void;
    closeModal: () => void;
  }
) => {
  const [components, setComponents] = useState(props.comp || []);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [groupName, setGroupName] = useState(props?.componentGroup?.name || "");

  const toggleSelect = (isOpen: boolean) => {
    setIsSelectOpen(isOpen);
  };

  const updateFeatures = (payload: {
    id: string;
    name: string;
    checked: boolean;
  }) => {
    if (payload.checked) {
      const newComponents = [
        ...components,
        { name: payload.name, id: payload.id }
      ];
      setComponents(newComponents);
    } else {
      const filtered = _.filter(components, f => f.id !== payload.id);
      setComponents(filtered);
    }
  };

  const updateName = (name: string) => {
    setGroupName(name);
  };

  const editComponentGroup = () => {
    const gateway = getGateway();
    gateway.request
      .editComponentGroupComponents(
        {
          component_group: {
            name: groupName,
            component_ids: components.map(c => c.id)
          }
        },
        {
          id: props.componentGroupId
        }
      )
      .then(res => {
        if (_.get(res, "error")) {
          renderErrorNotification("Failed to update component group");
        } else {
          renderSuccessNotification("Updated component group");
          console.log(
            "_.isFunction(props.refreshFn",
            _.isFunction(props.refreshFn)
          );
          _.isFunction(props.refreshFn) && props.refreshFn();
          props.closeModal();
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const items = _.map(props.components.nodes, node => {
    const handleChange = (feature: { id: string; name: string }) => (
      e: CheckboxChangeEvent
    ) => {
      updateFeatures({
        checked: e.target.checked,
        ...feature
      });
    };

    return (
      <ElmSelectOption key={node.id} label={node.name}>
        <Flex flexDirection="row">
          <ElmCheckBox
            style={{ marginRight: "5px" }}
            checked={
              components.length && components.find(item => item.id === node.id)
                ? true
                : false
            }
            onChange={handleChange(node)}
          />
          <GroupSelectionDesc>{node.name}</GroupSelectionDesc>
          <GroupSelectionDesc>
            {_.get(node, "componentGroup.name")}
          </GroupSelectionDesc>
        </Flex>
      </ElmSelectOption>
    );
  });
  return (
    <DialogContainer>
      <DialogTitle>{`Edit ${props.componentGroup.name}.`}</DialogTitle>
      <Flex flexDirection={"column"} marginBottom={16} marginTop={16}>
        <ElmInput
          updatedValue={updateName}
          defaultValue={props?.componentGroup?.name || ""}
          placeholder={props.componentGroup.name}
          label="Component Group Name"
        />
      </Flex>
      <Flex
        style={{
          height: "300px",
          marginBottom: "15px",
          flexDirection: "column"
        }}
      >
        <Flex onClick={e => e.preventDefault()}>
          <ElmSelectWithLabel
            onClick={() => toggleSelect(true)}
            onBlur={() => toggleSelect(false)}
            open={isSelectOpen}
            placeholder={"Assign components"}
            value={
              components.length > 0
                ? formatInlineDisplayValues(components.map(item => item.name))
                : undefined
            }
            label="Components"
          >
            {items}
          </ElmSelectWithLabel>
        </Flex>
      </Flex>
      <Flex alignItems="flex-end" justifyContent={"flex-end"}>
        <ElmButton
          label={"Discard"}
          colorVariance="outline-secondary"
          onClick={props.closeModal}
        />
        <ElmButton label={"Confirm"} onClick={editComponentGroup} />
      </Flex>
    </DialogContainer>
  );
};

export class EditComponentGroupDialog extends DialogBase<
  IModalProps<IEditComponentGroupDialogProps>,
  IEditComponentGroupDialogState
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }

  public state: IEditComponentGroupDialogState = {
    name: "",
    component_type: null,
    error: false,
    disabled: false,
    components: this.props?.payload?.components || [],
    isSelectOpen: false
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditComponentGroupDialog"
    });
  };

  public createEditComponentGroup = () => {
    this.setState({ disabled: true, error: false }, () => {
      const editComponentGroup = {
        ..._.pick(this.state, ["name", "component_type"]),
        id: this.props.payload.componentGroupId
      };
      const gateway = getGateway();
      // gateway.request
      //   .editComponentGroup(editComponentGroup, {
      //     id: this.props.payload.componentGroupId,
      //   })
      //   .then((res) => {
      //     if (_.get(res, "error")) {
      //       this.setState({ error: true, disabled: true });
      //     } else {
      //       if (_.isFunction(this.props.payload.onConfirm)) {
      //         this.props.payload.onConfirm();
      //       }
      //       this.closeModal();
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
    });
  };
  public getTitle = () => {
    return "Add a new component Group";
  };
  public getDescription = () => {
    return `You are adding a new component group to ${_.get(
      this.props.payload,
      "productName"
    )}.`;
  };
  public updateType = (componentType: any) => {
    const compId = parseInt(componentType, 10);
    const comp = _.find(componentTypes, c => c.id === compId);
    if (_.isObject(comp)) {
      this.setState({ component_type: { name: comp.label, id: comp.id } });
    }
  };
  public updateName = (name: any) => {
    this.setState({ name });
  };

  public renderQueryRenderer = () => {
    const renderComponentGroup = (payload: {
      error: Error;
      props: editComponentGroupQueryResponse;
    }) => {
      if (payload.error || !_.isObject(payload.props)) {
        return <DialogContainer>{LoaderContainer()}</DialogContainer>;
      }
      const components = payload.props.components.nodes.filter(
        item => item.componentGroup?.name === payload.props.componentGroup?.name
      );
      return (
        <ComponentSelection
          {...payload.props}
          comp={components}
          componentGroupId={this.props.payload?.componentGroupId}
          refreshFn={this.props.payload?.refreshFn}
          closeModal={this.closeModal}
        />
      );
    };
    return (
      <QueryRenderer<editComponentGroupQuery>
        variables={{
          productId: _.get(this.props.payload, "productId"),
          id: _.get(this.props.payload, "componentGroupId")
        }}
        environment={relayEnvironment}
        query={query}
        render={renderComponentGroup}
      />
    );
  };
  public render() {
    return this.renderQueryRenderer();
  }
}
