import { notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import * as React from "react";
import { createFragmentContainer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import {
  AttributesBox,
  IAttributValuePair,
  IAttributesBoxProps
} from "../attributesBox";
import { getLink } from "../helpers";
import { getIcon } from "../icons";
import { instanceAttributeAdvanced_instance } from "./__generated__/instanceAttributeAdvanced_instance.graphql";

const MacAddressText = styled.text`
  border: solid 1px ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.textPrimary};

  margin-bottom: 11px;
  margin-right: 7px;

  padding-left: 1px;
  padding-right: 1px;
  padding-top: 1.5px;
  padding-bottom: 1.5px;

  width: 134px;
  height: 23px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
`;

export interface IInstanceAttributeAdvancedProps extends IAttributesBoxProps {
  instance: instanceAttributeAdvanced_instance;
}

const CopyContainer = styled(Flex)`
  align-items: center;
  padding: 2px;
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.lightGrey};
  }
`;

export class InstanceAttributeAdvanced extends React.Component<
  IInstanceAttributeAdvancedProps
> {
  public openCopiedMessage = () => {
    notification.open({
      message: "Success",
      description: "License copied to clipboard"
    });
  };
  public getItems = (): IAttributValuePair[] => {
    if (!_.isObject(this.props.instance)) {
      return null;
    }

    const getOthers = () => {
      let keys = _.keys(this.props.instance.hardwareIdentifier.info);
      keys = _.filter(keys, key => key === "macAddresses");
      if (!keys.length) {
        return [];
      }
      return _.map(keys, key => {
        return {
          label: _.startCase(key),
          value: (
            <Flex flexWrap="wrap">
              {_.map(
                this.props.instance.hardwareIdentifier.info[key],
                address => (
                  <MacAddressText key={address}>{address}</MacAddressText>
                )
              )}
            </Flex>
          )
        };
      });
    };
    return [
      ...getOthers(),
      {
        label: "Ip Address",
        value: this.props.instance.hardwareIdentifier.info["ipAddress"]
      }
    ];
  };

  public render() {
    return (
      <AttributesBox
        items={this.getItems()}
        title={"Advanced"}
        mode={this.props.mode}
      />
    );
  }
}

export default createFragmentContainer(InstanceAttributeAdvanced, {
  instance: graphql`
    fragment instanceAttributeAdvanced_instance on Instance {
      guid
      hardwareIdentifier {
        info
      }
      license {
        product {
          id
          name
        }
      }
    }
  `
});
