import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import { ElmInput } from "../../../elmInput";
import { getGateway } from "../../../../api";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
export interface IChangePasswordDialogProps {
  token: string;
  email: string;
  currentRole: string;
}
export interface IChangePasswordDialogState {
  password: string;
  passwordError: string;
  password_confirmation: string;
  password_confirm_error: string;
  current_password: string;
  current_pass_error: string;
}

export class ChangePasswordDialog extends DialogBase<
  IModalProps<IChangePasswordDialogProps>
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  state: IChangePasswordDialogState = {
    password: "",
    passwordError: "",
    password_confirmation: "",
    password_confirm_error: "",
    current_password: "",
    current_pass_error: ""
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "ChangePasswordDialog"
    });
  };
  public changePassword = () => {
    const dataToSend = {
      current_password: this.state.current_password,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation
    };
    const gateway = getGateway();
    gateway.request
      .changePassword(dataToSend)
      .then(res => {
        if (res?.data?.errors) {
          const errors = res.data.errors;
          //set errors
          this.setState({
            ...this.state,
            current_pass_error: _.first(errors?.current_password) || "",
            passwordError: _.first(errors?.password) || "",
            password_confirm_error: _.first(errors?.password_confirmation) || ""
          });
        }
        if (res?.data?.success) {
          if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
            this.props.payload.onConfirm(null);
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  public getTitle = () => {
    return "Change Password";
  };
  public getDescription = () => {
    return ``;
  };

  public updateField = (type?: keyof IChangePasswordDialogState) => (
    val: string
  ) => {
    this.setState({ [type]: val });
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        {/* <DialogSubTitle style={{ color: "red", fontStyle: "italic" }}>
          {this.getDescription()}
        </DialogSubTitle> */}
        <Flex flexDirection="column">
          <div>
            <ElmInput
              label={"Current Password"}
              style={{ marginTop: "15px" }}
              value={this.state.current_password}
              type="password"
              error={!!this.state.current_pass_error}
              errorMessage={this.state.current_pass_error}
              controlled={true}
              updatedValue={this.updateField("current_password")}
            />
          </div>
          <div>
            <ElmInput
              label={"New Password"}
              style={{ marginTop: "15px" }}
              value={this.state.password}
              error={!!this.state.passwordError}
              errorMessage={this.state.passwordError}
              type="password"
              controlled={true}
              updatedValue={this.updateField("password")}
            />
          </div>
          <div>
            <ElmInput
              label={"Confirmation Password"}
              style={{ marginTop: "15px" }}
              value={this.state.password_confirmation}
              type="password"
              error={!!this.state.password_confirm_error}
              errorMessage={this.state.password_confirm_error}
              controlled={true}
              updatedValue={this.updateField("password_confirmation")}
            />
          </div>
        </Flex>

        <Flex justifyContent={"flex-end"} style={{ marginTop: "15px" }}>
          <ElmButton
            label={"Cancel"}
            colorVariance="outline-secondary"
            onClick={this.closeModal}
          />
          <ElmButton
            colorVariance="primary"
            label={"Confirm"}
            onClick={this.changePassword}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
