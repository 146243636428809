/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProductReleaseConfigQueryVariables = {
  id: string;
};
export type ProductReleaseConfigQueryResponse = {
  readonly releaseConfiguration: {
    readonly name: string | null;
    readonly product: {
      readonly iconUrl: string | null;
      readonly name: string | null;
    } | null;
    readonly fallback: {
      readonly id: string;
      readonly name: string | null;
    } | null;
    readonly configReleasesChannel: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly currentRelease: {
          readonly tag: string;
        } | null;
        readonly licensesCount: number;
      } | null> | null;
    } | null;
    readonly configReleases: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly tag: string;
        readonly releaseChannelsCount: number;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentRefs": FragmentRefs<
    | "productReleaseChannelsTable_releaseChannels"
    | "productReleasesTable_releases"
  >;
};
export type ProductReleaseConfigQuery = {
  readonly response: ProductReleaseConfigQueryResponse;
  readonly variables: ProductReleaseConfigQueryVariables;
};

/*
query ProductReleaseConfigQuery(
  $id: ID!
) {
  ...productReleaseChannelsTable_releaseChannels_1Bmzm5
  ...productReleasesTable_releases_1Bmzm5
  releaseConfiguration(id: $id) {
    name
    product {
      iconUrl
      name
      id
    }
    fallback {
      id
      name
    }
    configReleasesChannel: releaseChannels {
      nodes {
        id
        name
        currentRelease {
          tag
          id
        }
        licensesCount
      }
    }
    configReleases: releases {
      nodes {
        id
        tag
        releaseChannelsCount
      }
    }
    id
  }
}

fragment productReleaseChannelsTable_releaseChannels_1Bmzm5 on Query {
  releaseConfiguration(id: $id) {
    releaseChannels(first: 6, sortColumn: ["release_channels.name"], sortDirection: ["ASC"]) {
      edges {
        node {
          id
          name
          currentRelease {
            tag
            id
          }
          licensesCount
          releaseConfiguration {
            fallback {
              id
              name
            }
            id
          }
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    id
  }
}

fragment productReleasesTable_releases_1Bmzm5 on Query {
  releaseConfiguration(id: $id) {
    releases(sortColumn: ["releases.createdAt"], sortDirection: ["DESC"]) {
      edges {
        node {
          id
          tag
          releaseChannelsCount
          createdAt
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "iconUrl",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      concreteType: "ReleaseChannel",
      kind: "LinkedField",
      name: "fallback",
      plural: false,
      selections: [v4 /*: any*/, v2 /*: any*/],
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "tag",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "licensesCount",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "releaseChannelsCount",
      storageKey: null
    } as any,
    v9 = {
      alias: "configReleases",
      args: null,
      concreteType: "ReleaseConnection",
      kind: "LinkedField",
      name: "releases",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Release",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [v4 /*: any*/, v6 /*: any*/, v8 /*: any*/],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v10 = [
      {
        kind: "Literal",
        name: "first",
        value: 6
      } as any,
      {
        kind: "Literal",
        name: "sortColumn",
        value: ["release_channels.name"]
      } as any,
      {
        kind: "Literal",
        name: "sortDirection",
        value: ["ASC"]
      } as any
    ],
    v11 = {
      alias: null,
      args: null,
      concreteType: "Release",
      kind: "LinkedField",
      name: "currentRelease",
      plural: false,
      selections: [v6 /*: any*/, v4 /*: any*/],
      storageKey: null
    } as any,
    v12 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "__typename",
      storageKey: null
    } as any,
    v13 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "cursor",
      storageKey: null
    } as any,
    v14 = {
      alias: null,
      args: null,
      concreteType: "PageInfo",
      kind: "LinkedField",
      name: "pageInfo",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "hasNextPage",
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "endCursor",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v15 = ["search", "sortColumn", "sortDirection"],
    v16 = [
      {
        kind: "Literal",
        name: "sortColumn",
        value: ["releases.createdAt"]
      } as any,
      {
        kind: "Literal",
        name: "sortDirection",
        value: ["DESC"]
      } as any
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "ProductReleaseConfigQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ReleaseConfiguration",
          kind: "LinkedField",
          name: "releaseConfiguration",
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [v3 /*: any*/, v2 /*: any*/],
              storageKey: null
            },
            v5 /*: any*/,
            {
              alias: "configReleasesChannel",
              args: null,
              concreteType: "ReleaseChannelConnection",
              kind: "LinkedField",
              name: "releaseChannels",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseChannel",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [
                    v4 /*: any*/,
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: "Release",
                      kind: "LinkedField",
                      name: "currentRelease",
                      plural: false,
                      selections: [v6 /*: any*/],
                      storageKey: null
                    },
                    v7 /*: any*/
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v9 /*: any*/
          ],
          storageKey: null
        },
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "productReleaseChannelsTable_releaseChannels"
        },
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "productReleasesTable_releases"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "ProductReleaseConfigQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "ReleaseConfiguration",
          kind: "LinkedField",
          name: "releaseConfiguration",
          plural: false,
          selections: [
            {
              alias: null,
              args: v10 /*: any*/,
              concreteType: "ReleaseChannelConnection",
              kind: "LinkedField",
              name: "releaseChannels",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseChannelEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ReleaseChannel",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v4 /*: any*/,
                        v2 /*: any*/,
                        v11 /*: any*/,
                        v7 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: "ReleaseConfiguration",
                          kind: "LinkedField",
                          name: "releaseConfiguration",
                          plural: false,
                          selections: [v5 /*: any*/, v4 /*: any*/],
                          storageKey: null
                        },
                        v12 /*: any*/
                      ],
                      storageKey: null
                    },
                    v13 /*: any*/
                  ],
                  storageKey: null
                },
                v14 /*: any*/
              ],
              storageKey:
                'releaseChannels(first:6,sortColumn:["release_channels.name"],sortDirection:["ASC"])'
            },
            {
              alias: null,
              args: v10 /*: any*/,
              filters: v15 /*: any*/,
              handle: "connection",
              key: "productReleaseChannelsTable_releaseChannels",
              kind: "LinkedHandle",
              name: "releaseChannels"
            },
            v4 /*: any*/,
            {
              alias: null,
              args: v16 /*: any*/,
              concreteType: "ReleaseConnection",
              kind: "LinkedField",
              name: "releases",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Release",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v4 /*: any*/,
                        v6 /*: any*/,
                        v8 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "createdAt",
                          storageKey: null
                        },
                        v12 /*: any*/
                      ],
                      storageKey: null
                    },
                    v13 /*: any*/
                  ],
                  storageKey: null
                },
                v14 /*: any*/
              ],
              storageKey:
                'releases(sortColumn:["releases.createdAt"],sortDirection:["DESC"])'
            },
            {
              alias: null,
              args: v16 /*: any*/,
              filters: v15 /*: any*/,
              handle: "connection",
              key: "productReleasesTable_releases",
              kind: "LinkedHandle",
              name: "releases"
            },
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [v3 /*: any*/, v2 /*: any*/, v4 /*: any*/],
              storageKey: null
            },
            v5 /*: any*/,
            {
              alias: "configReleasesChannel",
              args: null,
              concreteType: "ReleaseChannelConnection",
              kind: "LinkedField",
              name: "releaseChannels",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseChannel",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [
                    v4 /*: any*/,
                    v2 /*: any*/,
                    v11 /*: any*/,
                    v7 /*: any*/
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            v9 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "daeccb033ef8829685e0f66d6cf059f0",
      id: null,
      metadata: {},
      name: "ProductReleaseConfigQuery",
      operationKind: "query",
      text:
        'query ProductReleaseConfigQuery(\n  $id: ID!\n) {\n  ...productReleaseChannelsTable_releaseChannels_1Bmzm5\n  ...productReleasesTable_releases_1Bmzm5\n  releaseConfiguration(id: $id) {\n    name\n    product {\n      iconUrl\n      name\n      id\n    }\n    fallback {\n      id\n      name\n    }\n    configReleasesChannel: releaseChannels {\n      nodes {\n        id\n        name\n        currentRelease {\n          tag\n          id\n        }\n        licensesCount\n      }\n    }\n    configReleases: releases {\n      nodes {\n        id\n        tag\n        releaseChannelsCount\n      }\n    }\n    id\n  }\n}\n\nfragment productReleaseChannelsTable_releaseChannels_1Bmzm5 on Query {\n  releaseConfiguration(id: $id) {\n    releaseChannels(first: 6, sortColumn: ["release_channels.name"], sortDirection: ["ASC"]) {\n      edges {\n        node {\n          id\n          name\n          currentRelease {\n            tag\n            id\n          }\n          licensesCount\n          releaseConfiguration {\n            fallback {\n              id\n              name\n            }\n            id\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n    id\n  }\n}\n\nfragment productReleasesTable_releases_1Bmzm5 on Query {\n  releaseConfiguration(id: $id) {\n    releases(sortColumn: ["releases.createdAt"], sortDirection: ["DESC"]) {\n      edges {\n        node {\n          id\n          tag\n          releaseChannelsCount\n          createdAt\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n    id\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "52a690b7564386374e224a8a551ecb5a";
export default node;
