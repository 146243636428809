import { graphql } from "babel-plugin-relay/macro";
import { tableGenerator } from "../../../utils/tableGenerator";
import { licenseTemplatesTable_licenseTemplates } from "./__generated__/licenseTemplatesTable_licenseTemplates.graphql";
import _ from "lodash";
import { TableCellProps } from "react-virtualized";
import React from "react";
import ReactImageFallback from "react-image-fallback";
import { getStaticMediaFile, LicensePlaceHolderLogo } from "utils";
import { Flex } from "reflexbox";
import { generateProductIcon } from "components/productImages/productImages";

export type licenseTemplatesEdges = licenseTemplatesTable_licenseTemplates["licenseTemplates"]["edges"];
export type licenseTemplatesTableType = licenseTemplatesEdges[number]["node"];

export type ILicenseTemplatesTableProps = {
  templates: licenseTemplatesTable_licenseTemplates;
};

const renderProductImageAndName = (payload: TableCellProps) => {
  const iconUrl = _.get(payload.cellData, "product.iconUrl");
  const productName = _.get(
    payload.cellData,
    "product.name",
    payload?.cellData?.product?.name
  );
  return (
    <Flex
      alignItems="center"
      style={{ minWidth: "max-content", height: "42px" }}
    >
      <Flex
        style={{
          marginRight: 5,
          gap: 8
        }}
      >
        <ReactImageFallback
          src={getStaticMediaFile({
            url: iconUrl
          })}
          fallbackImage={generateProductIcon(productName)}
          initialImage={generateProductIcon(productName)}
          alt={_.get(payload.cellData, "name")}
          height={"16px"}
        />
        {productName}
      </Flex>
    </Flex>
  );
};

export const LicenseTemplatesTable = tableGenerator<
  ILicenseTemplatesTableProps
>({
  columns: [
    {
      Header: "Product",
      accessor: "",
      cellDataGetter: payload => {
        return payload.rowData;
      },
      cellRenderer: payload => renderProductImageAndName(payload)
    },
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Created by",
      accessor: "",
      cellRenderer: payload =>
        payload.rowData?.vendorSystemUser?.name ||
        payload.rowData?.vendorSystemUser?.email
    },
    {
      Header: "Actions",
      accessor: "",
      cellRenderer: () => "",
      width: 0.2
    }
  ],
  tableName: "licenseTemplatesTable",
  connectionQuery: graphql`
    query licenseTemplatesTablePaginationQuery(
      $count: Int!
      $cursor: String
      $search: String # $id: ID!
    ) {
      ...licenseTemplatesTable_licenseTemplates
        @arguments(count: $count, cursor: $cursor, search: $search)
    }
  `,
  dataKey: "templates.licenseTemplates",
  fragmentSpec: {
    templates: graphql`
      fragment licenseTemplatesTable_licenseTemplates on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: null }
          cursor: { type: "String" }
          search: { type: "String", defaultValue: null }
          # id: { type: "ID!" }
        ) {
        licenseTemplates(first: $count, after: $cursor, search: $search)
          @connection(key: "licenseTemplatesTable_licenseTemplates") {
          edges {
            node {
              id
              product {
                id
                name
                iconUrl
              }
              name
              templateBody
              # vendor {
              #   id
              # }
              vendorSystemUser {
                id
                name
                email
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `
  }
});
export default LicenseTemplatesTable;
