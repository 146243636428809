/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userProductsTable_activation = {
  readonly status: {
    readonly displayName: string | null;
    readonly productUsers: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly product: {
            readonly id: string;
            readonly name: string | null;
          } | null;
          readonly enabled: boolean;
        } | null;
      } | null> | null;
    } | null;
    readonly instances: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly license: {
            readonly product: {
              readonly id: string;
              readonly name: string | null;
            } | null;
          } | null;
          readonly modificationRecords: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly enabled: number;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $refType": "userProductsTable_activation";
};
export type userProductsTable_activation$data = userProductsTable_activation;
export type userProductsTable_activation$key = {
  readonly " $data"?: userProductsTable_activation$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"userProductsTable_activation">;
};

const node: ReaderFragment = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v1 = {
      alias: null,
      args: null,
      concreteType: "Product",
      kind: "LinkedField",
      name: "product",
      plural: false,
      selections: [
        v0 /*: any*/,
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "name",
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "enabled",
      storageKey: null
    } as any;
  return {
    argumentDefinitions: [
      {
        defaultValue: 25,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["status", "productUsers"]
        }
      ]
    },
    name: "userProductsTable_activation",
    selections: [
      {
        alias: "status",
        args: [
          {
            kind: "Variable",
            name: "id",
            variableName: "id"
          }
        ],
        concreteType: "User",
        kind: "LinkedField",
        name: "user",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: "ScalarField",
            name: "displayName",
            storageKey: null
          },
          {
            alias: "productUsers",
            args: [
              {
                kind: "Variable",
                name: "search",
                variableName: "search"
              }
            ],
            concreteType: "ProductUserConnection",
            kind: "LinkedField",
            name: "__userProductsTable_productUsers_connection",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "ProductUserEdge",
                kind: "LinkedField",
                name: "edges",
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: "ProductUser",
                    kind: "LinkedField",
                    name: "node",
                    plural: false,
                    selections: [
                      v1 /*: any*/,
                      v2 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "__typename",
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "cursor",
                    storageKey: null
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: "PageInfo",
                kind: "LinkedField",
                name: "pageInfo",
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "endCursor",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "hasNextPage",
                    storageKey: null
                  }
                ],
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: "InstanceConnection",
            kind: "LinkedField",
            name: "instances",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "InstanceEdge",
                kind: "LinkedField",
                name: "edges",
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: "Instance",
                    kind: "LinkedField",
                    name: "node",
                    plural: false,
                    selections: [
                      v0 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        concreteType: "License",
                        kind: "LinkedField",
                        name: "license",
                        plural: false,
                        selections: [v1 /*: any*/],
                        storageKey: null
                      },
                      {
                        alias: null,
                        args: [
                          {
                            kind: "Literal",
                            name: "last",
                            value: 1
                          }
                        ],
                        concreteType: "ModificationRecordConnection",
                        kind: "LinkedField",
                        name: "modificationRecords",
                        plural: false,
                        selections: [
                          {
                            alias: null,
                            args: null,
                            concreteType: "ModificationRecordEdge",
                            kind: "LinkedField",
                            name: "edges",
                            plural: true,
                            selections: [
                              {
                                alias: null,
                                args: null,
                                concreteType: "ModificationRecord",
                                kind: "LinkedField",
                                name: "node",
                                plural: false,
                                selections: [v2 /*: any*/],
                                storageKey: null
                              }
                            ],
                            storageKey: null
                          }
                        ],
                        storageKey: "modificationRecords(last:1)"
                      }
                    ],
                    storageKey: null
                  }
                ],
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "f3784507e5989f8ac50619025301845a";
export default node;
