import { Col, Row } from "antd";
import { BladeTitle, ElmButton } from "../../components";
import LineChart from "../../components/elmChart/lineChart";
import PieChart from "../../components/elmChart/pieChart";
import React, { useCallback, useState } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { Flex } from "reflexbox";
import styled from "styled-components";
import "./index.css";
import relayEnvironment from "../../api/relay";
import LoadingWrapper from "../../components/elmChart/loadingWrapper";
import HorizontalBarChart from "../../components/elmChart/horizontalBarChart";
import ElmStats from "../../components/elmStats";
import TimeFilter from "../../components/analyticsComponent/filters/timeFilter";
import { SidebarFilter } from ".";
import {
  AllowedChartBladeTypes,
  IOpenBladeFromChart,
  OPEN_FROM_DASHBOARD_INDEX
} from "components/bladeManager/types";
import { DateRange, formatDisplayGlobalFilter } from "./utils";

const renderSectionTitle = (
  sectionTitle: string,
  section?: string,
  handleTimeFilter?: (section: string, value: DateRange) => void,
  timeRange?: string
) => {
  const onChange = (value: DateRange) => {
    handleTimeFilter(section, value);
  };
  return (
    <Flex
      flex={1}
      justifyContent={"space-between"}
      style={{
        marginLeft: "18px",
        marginRight: "10px",
        marginTop: "52px",
        marginBottom: "32px"
      }}
    >
      <BladeTitle>{sectionTitle}</BladeTitle>
      {handleTimeFilter && (
        <TimeFilter timeRange={timeRange} handleChange={onChange} />
      )}
    </Flex>
  );
};

const renderRightSideHeader = (filterCallback: () => void) => {
  return (
    <ElmButton
      colorVariance="primary"
      variance={"plain-icon-button"}
      icon={"bars-filter"}
      label="Filters"
      onClick={() => filterCallback()}
    />
  );
};

const ColStyled = styled(Col)`
  color: white;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 4px;
`;

const RowCol = styled.div`
  min-height: 100%;
  height: 100%;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 10%);
`;

const DashboardTitle = styled.span(props => ({
  padding: "0px 10px",
  fontSize: 18,
  fontWeight: 500,
  color: props.theme.colors.black
}));

const DateRangeTitle = styled.span(props => ({
  padding: "0px 10px",
  fontSize: 18,
  fontWeight: 400,
  color: props.theme.colors.black
}));

const LayoutSwitchButtonStyle = {
  color: "#000000",
  width: "20px",
  height: "20px"
};
export type IFilterState = Record<string, DateRange>;

const GRID_DENSE_CHART_HEIGHT = 180;
const GRID_REGULAR_CHART_HEIGHT = 250;

export function DashboardBlade(props: any) {
  const [filters, setFilters] = useState<IFilterState>({
    account: "days7",
    validations: "days7",
    users: "last24",
    licenses: "days7",
    global: null,
    default: "months3"
  });

  const [collapsed, setCollapsed] = useState(false);
  const [isGridDense, setGridDense] = useState(false);

  const handleChange = (section: string, value: DateRange) => {
    const filter = { ...filters } as IFilterState;
    filter[section] = value;
    setFilters(filter);
  };

  const hideShowFilters = () => {
    setCollapsed(prev => !prev);
  };

  const openBladeFromChart = useCallback(
    (payload: IOpenBladeFromChart) => {
      if (!payload?.id || !AllowedChartBladeTypes.includes(payload.bladeType)) {
        return;
      }
      props.openBlade({
        route: payload.bladeType,
        routeName: `${payload.name}`,
        fromBladeIndex: OPEN_FROM_DASHBOARD_INDEX, // Open individual blade from dashboard uses blade index -1
        routeData: {
          id: payload.id
        }
      });
    },
    [props]
  );
  const renderPowerUsersChart = useCallback(
    ({ disableExport }: { disableExport: boolean }) => {
      return (
        <HorizontalBarChart
          key="power-users-chart"
          openBlade={openBladeFromChart}
          exportOptions={{
            disableExport: disableExport,
            showCustomExportButton: isGridDense ? false : true
          }}
          timeRangeOverride={filters["users"]}
          hideTimeRangeFilter
          queryParams={{
            //bladeScope: "Q29tcGFueS0xNg==",
            setup: {
              category: "users.name",
              value: "sessions.usage_count",
              group: "products.name"
            },
            filterColumn: ["is_vendor"],
            filterValues: [["false"]],
            sortColumn: "value",
            sortDirection: "DESC",
            first: 10
          }}
          options={{
            chartMaxHeight: isGridDense
              ? GRID_DENSE_CHART_HEIGHT
              : GRID_REGULAR_CHART_HEIGHT,
            lineWidth: 50
          }}
          plot={{
            title: "Power users",
            hideLegend: true
          }}
        />
      );
    },
    [filters, isGridDense, openBladeFromChart]
  );

  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <SidebarFilter
        isOpen={collapsed}
        closeFilterSidebar={hideShowFilters}
        dashboardFilters={filters}
        setDashboardFilters={setFilters}
      />
      <div
        id="bladeMainID"
        style={{ width: "100%", margin: "10px", height: "inherit" }}
      >
        <Flex
          flex={1}
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
          marginRight="10px"
        >
          <DashboardTitle>Dashboard</DashboardTitle>
          <DateRangeTitle>
            {formatDisplayGlobalFilter(isGridDense ? filters?.global : null)}
          </DateRangeTitle>
          <Flex
            flex={1}
            justifyContent="flex-end"
            alignContent="center"
            alignItems="center"
            marginRight="10px"
          >
            {isGridDense ? (
              <ElmButton
                colorVariance="subtle"
                variance={"icon-button"}
                icon={"grid-2"}
                iconPrefix={"far"}
                label=""
                style={{ ...LayoutSwitchButtonStyle }}
                onClick={() => setGridDense(!isGridDense)}
              />
            ) : (
              <ElmButton
                colorVariance="subtle"
                variance={"icon-button"}
                icon={"grid-4"}
                iconPrefix={"far"}
                label=""
                style={{ ...LayoutSwitchButtonStyle }}
                onClick={() => setGridDense(!isGridDense)}
              />
            )}
          </Flex>
          {renderRightSideHeader(hideShowFilters)}
        </Flex>
        <Row
          gutter={[20, 20]}
          style={{ marginTop: "20px", marginLeft: "0px", marginRight: "0px" }}
        >
          <ColStyled xs={24} sm={24} md={24} lg={24} xl={10} xxl={8}>
            <RowCol>
              <LoadingWrapper>
                <LineChart
                  showProductFilter={isGridDense ? false : true}
                  hideTimeRangeFilter={isGridDense ? true : false}
                  timeRangeOverride={filters["default"]}
                  exportOptions={{
                    showCustomExportButton: isGridDense ? false : true
                  }}
                  queryParams={{
                    setup: {
                      category: "time.day",
                      value: "counts.active_user",
                      group: "products.name"
                    },
                    filterColumn: ["is_vendor"],
                    filterValues: [["false"]]
                  }}
                  options={{
                    chartMaxHeight: isGridDense ? 200 : 250
                  }}
                  plot={{
                    title: "User count",
                    description: "User count over period for products"
                  }}
                />
              </LoadingWrapper>
            </RowCol>
          </ColStyled>
          <ColStyled xs={24} sm={24} md={24} lg={24} xl={14} xxl={10}>
            <RowCol>
              <LoadingWrapper>
                <ElmStats
                  height={isGridDense ? 244 : 314}
                  isGridDense={isGridDense}
                />
              </LoadingWrapper>
            </RowCol>
          </ColStyled>
          <ColStyled xs={24} sm={24} md={24} lg={12} xl={10} xxl={6}>
            <RowCol>
              <LoadingWrapper>
                <PieChart
                  openBlade={openBladeFromChart}
                  hideTimeRangeFilter={isGridDense ? true : false}
                  exportOptions={{
                    showCustomExportButton: isGridDense ? false : true
                  }}
                  timeRangeOverride={filters["default"]}
                  queryParams={{
                    setup: {
                      category: "products.name",
                      value: "sessions.usage_count",
                      group: null //"time.month"
                    },
                    filterColumn: ["is_vendor"],
                    filterValues: [["false"]]
                  }}
                  plot={{
                    title: "Session count"
                  }}
                  options={{
                    chartMaxHeight: isGridDense
                      ? GRID_DENSE_CHART_HEIGHT
                      : GRID_REGULAR_CHART_HEIGHT,
                    pieChartType: "donut",
                    hideLabels: true
                  }}
                />
              </LoadingWrapper>
            </RowCol>
          </ColStyled>
          <ColStyled xs={24} sm={24} md={24} lg={12} xl={14} xxl={10}>
            <RowCol>
              <LoadingWrapper>
                <LineChart
                  hideTimeRangeFilter={isGridDense ? true : false}
                  exportOptions={{
                    showCustomExportButton: isGridDense ? false : true
                  }}
                  timeRangeOverride={filters["default"]}
                  queryParams={{
                    setup: {
                      category: "time.day",
                      value: "sessions.usage_count",
                      group: "products.name"
                    },
                    filterColumn: ["is_vendor"],
                    filterValues: [["false"]]
                  }}
                  options={{
                    chartMaxHeight: isGridDense
                      ? GRID_DENSE_CHART_HEIGHT
                      : GRID_REGULAR_CHART_HEIGHT,
                    stacked: true
                  }}
                  plot={{
                    title: "Use over time"
                  }}
                />
              </LoadingWrapper>
            </RowCol>
          </ColStyled>
          {isGridDense ? (
            <ColStyled xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <RowCol>{renderPowerUsersChart({ disableExport: true })}</RowCol>
            </ColStyled>
          ) : null}
        </Row>
        {!isGridDense &&
          renderSectionTitle(
            "Account overview",
            "account",
            handleChange,
            filters["account"]
          )}
        <Row
          gutter={[20, 20]}
          style={{ marginTop: "20px", marginLeft: "0px", marginRight: "0px" }}
        >
          <ColStyled xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
            <RowCol>
              <LoadingWrapper>
                <PieChart
                  openBlade={openBladeFromChart}
                  hideTimeRangeFilter
                  exportOptions={{
                    showCustomExportButton: isGridDense ? false : true
                  }}
                  timeRangeOverride={filters["account"]}
                  queryParams={{
                    setup: {
                      category: "companies.name",
                      value: "sessions.usage_count",
                      group: null //"companies.name",
                    },
                    filterColumn: ["is_vendor"],
                    filterValues: [["false"]]
                  }}
                  options={{
                    chartMaxHeight: isGridDense
                      ? GRID_DENSE_CHART_HEIGHT
                      : GRID_REGULAR_CHART_HEIGHT,
                    hideLabels: true,
                    showLegend: true
                  }}
                  plot={{
                    title: "Session count"
                  }}
                />
              </LoadingWrapper>
            </RowCol>
          </ColStyled>
          <ColStyled xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
            <RowCol>
              <LoadingWrapper>
                <PieChart
                  openBlade={openBladeFromChart}
                  hideTimeRangeFilter
                  exportOptions={{
                    showCustomExportButton: isGridDense ? false : true
                  }}
                  timeRangeOverride={filters["account"]}
                  queryParams={{
                    setup: {
                      category: "companies.name",
                      value: "counts.active_user",
                      group: null //"companies.name",
                    },
                    filterColumn: ["is_vendor"],
                    filterValues: [["false"]]
                  }}
                  options={{
                    chartMaxHeight: isGridDense
                      ? GRID_DENSE_CHART_HEIGHT
                      : GRID_REGULAR_CHART_HEIGHT,
                    hideLabels: true,
                    showLegend: true
                    // showMaxItems: 2,
                    // consolidatedTitle: "And there's others"
                  }}
                  plot={{
                    title: "User count"
                  }}
                />
              </LoadingWrapper>
            </RowCol>
          </ColStyled>
          <ColStyled xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
            <RowCol>
              <LoadingWrapper>
                <PieChart
                  openBlade={openBladeFromChart}
                  hideTimeRangeFilter
                  exportOptions={{
                    showCustomExportButton: isGridDense ? false : true
                  }}
                  timeRangeOverride={filters["account"]}
                  queryParams={{
                    setup: {
                      category: "companies.name",
                      value: "sessions.usage_duration",
                      group: null //"companies.name",
                    },
                    filterColumn: ["is_vendor"],
                    filterValues: [["false"]]
                  }}
                  options={{
                    chartMaxHeight: isGridDense
                      ? GRID_DENSE_CHART_HEIGHT
                      : GRID_REGULAR_CHART_HEIGHT,
                    hideLabels: true,
                    showLegend: true
                  }}
                  plot={{
                    title: "Time usage"
                  }}
                />
                {/* <HorizontalBarChart
                    //hideTimeRangeFilter
                    queryParams={{
                      setup: {
                        category: "time.month",
                        value: "sessions.usage_count",
                        group: "users.name"
                      },
                      sortColumn: "value",
                      sortDirection: "DESC",
                      first: 5,
                      filterColumn: ["is_vendor"],
                      filterValues: [["false"]]
                    }}
                    options={{
                      useStackedBar: false,
                      chartMaxHeight: 250
                    }}
                    plot={{
                      //title: "Power users",
                      hideLegend: true,
                      series: [
                        {
                          options: {
                            lineWidth: 100 // percent value
                          }
                        }
                      ],
                      yAxes: [
                        {
                          hideLabel: true
                        }
                      ]
                    }}
                  /> */}
              </LoadingWrapper>
            </RowCol>
          </ColStyled>
        </Row>
        {!isGridDense &&
          renderSectionTitle(
            "Licenses overview",
            "licenses",
            handleChange,
            filters["licenses"]
          )}
        <Row
          gutter={[20, 20]}
          style={{ marginTop: "20px", marginLeft: "0px", marginRight: "0px" }}
        >
          <ColStyled xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <RowCol>
              <PieChart
                timeRangeOverride={filters["licenses"]}
                exportOptions={{
                  showCustomExportButton: isGridDense ? false : true
                }}
                hideTimeRangeFilter
                queryParams={{
                  setup: {
                    category: "products.type",
                    value: "counts.active_user",
                    group: null //"companies.name",
                  },
                  filterColumn: ["is_vendor"],
                  filterValues: [["false"]]
                }}
                options={{
                  chartMaxHeight: isGridDense
                    ? GRID_DENSE_CHART_HEIGHT
                    : GRID_REGULAR_CHART_HEIGHT,
                  hideLabels: true,
                  showLegend: true
                }}
                plot={{
                  title: "Platform"
                }}
              />
            </RowCol>
          </ColStyled>
          <ColStyled xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <RowCol>
              <PieChart
                timeRangeOverride={filters["licenses"]}
                exportOptions={{
                  showCustomExportButton: isGridDense ? false : true
                }}
                hideTimeRangeFilter
                queryParams={{
                  setup: {
                    category: "licenses.type",
                    value: "counts.active_user",
                    group: null //"companies.name",
                  },
                  filterColumn: ["is_vendor"],
                  filterValues: [["false"]]
                }}
                options={{
                  chartMaxHeight: isGridDense
                    ? GRID_DENSE_CHART_HEIGHT
                    : GRID_REGULAR_CHART_HEIGHT,
                  hideLabels: true,
                  showLegend: true
                }}
                plot={{
                  title: "License type"
                }}
              />
            </RowCol>
          </ColStyled>
          <ColStyled xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <RowCol>
              <PieChart
                timeRangeOverride={filters["licenses"]}
                exportOptions={{
                  showCustomExportButton: isGridDense ? false : true
                }}
                hideTimeRangeFilter
                queryParams={{
                  setup: {
                    category: "licenses.commercial_model",
                    value: "counts.active_user",
                    group: null //"companies.name",
                  },
                  filterColumn: ["is_vendor"],
                  filterValues: [["false"]]
                }}
                options={{
                  chartMaxHeight: isGridDense
                    ? GRID_DENSE_CHART_HEIGHT
                    : GRID_REGULAR_CHART_HEIGHT,
                  hideLabels: true,
                  showLegend: true
                }}
                plot={{
                  title: "Commercial model"
                }}
              />
            </RowCol>
          </ColStyled>
          <ColStyled xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <RowCol>
              <PieChart
                timeRangeOverride={filters["licenses"]}
                exportOptions={{
                  showCustomExportButton: isGridDense ? false : true
                }}
                hideTimeRangeFilter
                queryParams={{
                  setup: {
                    category: "sessions.version",
                    value: "sessions.usage_count",
                    group: null //"companies.name",
                  },
                  filterColumn: ["is_vendor"],
                  filterValues: [["false"]]
                }}
                options={{
                  chartMaxHeight: isGridDense
                    ? GRID_DENSE_CHART_HEIGHT
                    : GRID_REGULAR_CHART_HEIGHT,
                  hideLabels: true,
                  showLegend: true
                }}
                plot={{
                  title: "Version usage"
                }}
              />
            </RowCol>
          </ColStyled>
        </Row>
        {/* {renderSectionTitle(
            "Validations overview",
            "validations",
            handleChange,
            filters["validations"]
          )}
          <Row
            gutter={[20, 20]}
            style={{ marginTop: "10px", marginLeft: "0px", marginRight: "0px" }}
          ></Row> */}
        {!isGridDense &&
          renderSectionTitle(
            "Users overview",
            "users",
            handleChange,
            filters["users"]
          )}
        <Row
          gutter={[20, 20]}
          style={{ marginTop: "20px", marginLeft: "0px", marginRight: "0px" }}
        >
          {isGridDense ? null : (
            <ColStyled xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <RowCol>{renderPowerUsersChart({ disableExport: false })}</RowCol>
            </ColStyled>
          )}
        </Row>
      </div>
    </RelayEnvironmentProvider>
  );
}
