import { Avatar, message, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload";
import { ILicenseTemplate } from "api/gatewayMap";
import { graphql } from "babel-plugin-relay/macro";
import { getIcon } from "components/icons";
import Fuse from "fuse.js";
import _ from "lodash";
import React from "react";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import styled, { useTheme } from "styled-components";
import { getGateway } from "../../api";
import { getEnvironment } from "../../api/relay";
import { LogoPlaceholder } from "../../assets";
import { ElmInput, ElmTable, ElmToggle } from "../../components";
import BladeTemplate from "../../components/bladeManager/bladeTemplate";
import { IBladeBaseProps } from "../../components/bladeManager/types";
import { ElmButton } from "../../components/elmButton";
import { IElmTableProps } from "../../components/elmTable";
import TabView, { ITab } from "../../components/tabView";
import { SectionTitle } from "../../Licenses/blades/Entitlement/styles";
import { LicenseTemplatesTable } from "../../Licenses/components/licenseTemplatesTable";
import { appConnect, appDispatch } from "../../store/appConnect";
import {
  fileActionHandler,
  getBase64,
  getStaticMediaFile,
  searchInObjectArray
} from "../../utils";
import {
  AccountQuery,
  AccountQueryResponse
} from "./__generated__/AccountQuery.graphql";
import { ActiveMode, IOnboardingState } from "store/types";
import { TourGuideId } from "blades/Welcome/utils/types";
import { renderSuccessNotification } from "utils/ant-notifications";
import AppImageDictionary from "utils/AppImageDictionary/AppImageDictionary";

const relayEnvironment = getEnvironment();
const graphqlQuery = graphql`
  query AccountQuery {
    currentUser {
      email
      id
      name
      thumbUrl
      avatarUrl
      emailPreferences
      #role
    }
    ...licenseTemplatesTable_licenseTemplates
  }
`;

const UploadIcon = getIcon("arrow-up-from-bracket", "far");

const NotificationCategoryTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  margin-left: 16px;
`;

const ToggleRow = (props: {
  row: EmailNotification;
  isChecked: boolean;
  reportChange: (category: string) => (value: boolean) => void;
}) => {
  const isChecked = props.isChecked;
  const handleChange = (value: boolean) => {
    props.reportChange(props.row.category)(value);
  };
  let enabledReasons = 0;
  props.row.reasons.map(reason => {
    if (reason.enabled) enabledReasons++;
  });
  return (
    <Flex style={{ height: "24px", marginBottom: "10px" }}>
      <NotificationCategoryTitle style={{ minWidth: "310px" }}>
        {props.row.label}
      </NotificationCategoryTitle>
      <div>
        <ElmToggle
          size={"small"}
          checked={isChecked}
          onChange={handleChange}
          disabled
        />
      </div>
      <NotificationCategoryTitle style={{ minWidth: "310px" }}>
        {!props.row.enabled
          ? "Disabled"
          : `${enabledReasons}/${props.row.reasons.length} Enabled`}
      </NotificationCategoryTitle>
    </Flex>
  );
};

export interface IAccountBladeProps extends IBladeBaseProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    activeMode: ActiveMode;
    userDefinedMode: boolean;
    onboardingMode: IOnboardingState;
  };
  result: AccountQueryResponse;
  theme: any;
}

const ImageSectionContainer = styled.div`
  width: 64px;
  height: 66px;
  margin-right: 17px;
`;

const ProfileImageSectionContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const ImageDescription = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  max-width: 240px;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  letter-spacing: 0.16px;

  /* text / text-02 */

  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 11px;
`;

const StyledUserEmail = styled.p`
  margin: 0px;
  height: 15px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.colors.warmGrey};
`;

const StyledText = styled.span`
  font-size: 13px;
`;

const StyledUserFullName = styled.span`
  margin: 0px;
  height: 15px;
  font-family: Inter;
  font-size: 26px;
  font-weight: ${props => props.theme.fontWeight.heavy};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.colors.textPrimary};
`;

const StyledContainer = styled.div`
  .text {
    color: ${props => props.theme.colors.textPrimary};
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    width: 100%;
    border-radius: 0 0 150px 150px;
    opacity: 0;
    transition: 0.5s ease;
    margin-top: 40px;
    background-color: ${props => props.theme.colors.lightGrey};
  }
  :hover .overlay {
    opacity: 1;
  }
  height: 80px;
  justify-content: center;
  position: relative;
`;

export type AdminUser = { email: string; role: string; id: number };
type ApiToken = { scopes: string[]; description: string; id: number };
type EmailNotification = {
  category: string;
  label: string;
  enabled: boolean;
  frequency: number[];
  reasons: { reason: string; label: string; enabled: boolean }[];
};
type VendorInfo = {
  id: number;
  company: string;
  header_logo_url: string;
  logo_url: string;
};
interface AccountBladeState {
  adminUsers: AdminUser[];
  apiTokens: ApiToken[];
  apiTokenScopes: { id: string; description: string }[];
  filteredAdminUsers: AdminUser[];
  searchedApiTokens: ApiToken[];
  userInfo: {
    id: number;
    email: string;
    role: string;
    name: string;
    avatar_url: string;
    thumb_url: string;
  };
  profileImageUrl: string;
  profileImage: File | Blob;
  profileImageLoading: boolean;
  profileImageLink: string;
  vendorInfo: VendorInfo;
  updatedVendorName: string;
  loading: boolean;
  imageUrl: string;
  password: string;
  passwordError: string;
  password_confirmation: string;
  password_confirm_error: string;
  current_password: string;
  current_pass_error: string;
}
export class AccountBlade extends React.Component<
  IAccountBladeProps & {
    retry: () => void;
  },
  AccountBladeState
> {
  state: AccountBladeState = {
    userInfo: null,
    vendorInfo: null,
    adminUsers: [],
    apiTokens: [],
    apiTokenScopes: [],
    filteredAdminUsers: [],
    searchedApiTokens: [],
    updatedVendorName: "",
    loading: true,
    imageUrl: "",
    password: "",
    passwordError: "",
    password_confirmation: "",
    password_confirm_error: "",
    current_password: "",
    current_pass_error: "",
    profileImageUrl: "",
    profileImage: null,
    profileImageLoading: false,
    profileImageLink: ""
  };
  componentDidUpdate() {
    if (this.checkOnboardingMode()) {
      const accountInfo = document.getElementById(TourGuideId.AccountInfo);
      if (
        accountInfo &&
        this.props.appState.onboardingMode.index === 0 &&
        this.props.appState.onboardingMode.type === "SetupAccount"
      ) {
        this.props.appDispatch.appActions.onboardingContinue();
        return;
      }
      const addUsersBtn = document.getElementById(TourGuideId.AddButton);
      if (
        addUsersBtn &&
        this.props.appState.onboardingMode.index === 0 &&
        this.props.appState.onboardingMode.type === "InviteUsers"
      ) {
        this.props.appDispatch.appActions.onboardingContinue();
        return;
      }
      const emailPrefBtn = document.getElementById(
        TourGuideId.EmailPreferences
      );
      if (this.props.appState.onboardingMode.index === 2 && emailPrefBtn) {
        this.props.appDispatch.appActions.onboardingContinue();
      }
    }
  }
  public componentDidMount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingPause();
    const currentRole = _.get(this.props, "appState.activeRole.role", "");
    if (currentRole === "owner" || currentRole === "admin") {
      this.getAdminUsers();
      this.getApiTokens();
      this.getApiTokenScopes();
    }

    this.getUserEmail();
    this.getVendorInfo();
  }
  public checkOnboardingMode = () => {
    return this.props.appState.onboardingMode?.status === "active";
  };
  public componentWillUnmount() {
    this.checkOnboardingMode() &&
      this.props.appDispatch.appActions.onboardingEnd();
  }
  public getApiTokenScopes = () => {
    this.props.gateway.request.getApiTokenScopes().then(res => {
      if (_.isObject(res.data)) {
        this.setState({ apiTokenScopes: res.data, loading: false });
      }
    });
  };
  public getVendorInfo = () => {
    this.props.gateway.request.getCurrentVendorInformation().then(res => {
      if (_.isObject(res.data)) {
        this.setState({
          vendorInfo: res.data,
          updatedVendorName: res.data.company,
          loading: false
        });
      }
    });
  };
  public getUserEmail = async () => {
    const userInfo = await this.props.gateway.request.getCurrentUser();
    if (_.isObject(userInfo.data)) {
      const avatar_url = await AppImageDictionary.getImageSrc(
        `account-${userInfo.data?.id}`,
        userInfo.data?.avatar_url
      );
      this.setState({ userInfo: { ...userInfo.data, avatar_url } });
    }
  };
  public onAdminsTableCriteriaChange: IElmTableProps["onCriteriaChange"] = payload => {
    const data = [...this.state.adminUsers];
    if (payload.sortBy === "email") {
      if (payload.sortDirection === "ASC") {
        data.sort((a, b) => a.email.localeCompare(b.email));
      }
      if (payload.sortDirection === "DESC") {
        data.sort((a, b) => b.email.localeCompare(a.email));
      }
    }
    if (payload.search_term) {
      const searchResults = payload.search_term
        ? searchInObjectArray(payload.search_term, data)
        : data;
      this.setState({ filteredAdminUsers: searchResults });
    } else {
      this.setState({ filteredAdminUsers: data });
    }
  };
  public onApiTokensCriteriaChange: IElmTableProps["onCriteriaChange"] = payload => {
    if (payload.search_term) {
      const results = new Fuse(this.state.apiTokens, {
        keys: ["description"]
      }).search(payload.search_term);
      this.setState({ searchedApiTokens: _.map(results, i => i.item) });
    } else {
      this.setState({ searchedApiTokens: [] });
    }
  };

  public changePassword = () => {
    const dataToSend = {
      current_password: this.state.current_password,
      password: this.state.password_confirmation,
      password_confirmation: this.state.password_confirmation
    };
    const gateway = getGateway();
    gateway.request
      .changePassword(dataToSend)
      .then(res => {
        if (res?.data?.errors) {
          const errors = res.data.errors;
          message.error("Validation failed, check the errors on input.");
          //set errors
          this.setState({
            ...this.state,
            current_pass_error: _.first(errors?.current_password) || "",
            passwordError: _.first(errors?.password) || "",
            password_confirm_error: _.first(errors?.password_confirmation) || ""
          });
        }
        message.success("Password changed.");
      })
      .catch(err => {
        console.error(err);
      });
  };
  public updateField = (
    type?: "current_password" | "password_confirmation"
  ) => (val: string) => {
    if (type === "current_password") {
      this.setState({ current_password: val });
    }
    if (type === "password_confirmation") {
      this.setState({ password_confirmation: val });
    }
  };

  public handleOnModeChange = (mode: any) => {
    const theme = mode ? "dark" : "light";
    this.props.appDispatch.appActions.updateActiveMode(theme);
    this.props.appDispatch.appActions.updateUserDefinedMode(true);
  };

  public renderUserAccountAttributesBox = () => {
    return (
      <Flex
        flex={1}
        marginRight={20}
        flexDirection="column"
        style={{ marginBottom: "30px" }}
      >
        <SectionTitle>Change password</SectionTitle>
        <Flex flexDirection="column">
          <ElmInput
            label={"Current Password"}
            value={this.state.current_password}
            type="password"
            error={!!this.state.current_pass_error}
            errorMessage={this.state.current_pass_error}
            placeholder="********"
            controlled={true}
            autoComplete="current-password"
            updatedValue={this.updateField("current_password")}
          />

          <ElmInput
            label={"New Password"}
            value={this.state.password_confirmation}
            error={!!this.state.password_confirm_error}
            errorMessage={this.state.password_confirm_error}
            placeholder="********"
            type="password"
            controlled={true}
            autoComplete="new-password"
            updatedValue={this.updateField("password_confirmation")}
          />
        </Flex>

        <Flex
          justifyContent={"flex-start"}
          alignItems="flex-start"
          style={{ marginTop: "40px" }}
        >
          <ElmButton
            style={{ minWidth: "150px", marginLeft: "0px" }}
            colorVariance="outline-secondary"
            label="Update password"
            onClick={this.changePassword}
          />
        </Flex>
      </Flex>
    );
  };

  public renderVendorAttributesBox = () => {
    const openVendorImageDialog = () => {
      this.props.openDialog("VendorImageUploadDialog", {
        onConfirm: res => {
          this.getVendorInfo();
        }
      });
    };
    const updateVendorName = (updatedVendorName: string) => {
      this.setState({ updatedVendorName });
    };
    const saveVendorName = () => {
      this.props.gateway.request
        .updateCurrentVendorInformation({
          ...this.state.vendorInfo,
          company: this.state.updatedVendorName
        })
        .then(this.getVendorInfo);
    };
    return (
      <Flex
        flex={1}
        marginLeft={20}
        flexDirection="column"
        style={{ marginBottom: "30px" }}
      >
        <SectionTitle>Vendor information</SectionTitle>
        <Flex>
          <ElmInput
            label="Name"
            defaultValue={this.state?.vendorInfo?.company || ""}
            placeholder={
              this.state.vendorInfo ? this.state.vendorInfo.company : ""
            }
            updatedValue={updateVendorName}
          />
          {_.get(this.state.vendorInfo, "company") !==
            this.state.updatedVendorName && (
            <ElmButton
              variance="icon-button"
              label=""
              colorVariance="subtle"
              icon="save"
              iconPrefix="far"
              alt="Save"
              style={{
                color: this.props.theme.colors.textPrimary
              }}
              onClick={saveVendorName}
            />
          )}
        </Flex>

        <ImageSectionContainer>
          {this.state?.vendorInfo?.logo_url ? (
            <img
              src={
                /^data\:image/.test(this.state.vendorInfo.logo_url)
                  ? this.state.vendorInfo.logo_url
                  : getStaticMediaFile({
                      url: this.state.vendorInfo.logo_url
                    })
              }
              alt="avatar"
              style={{ width: "100%" }}
            />
          ) : (
            <Flex justifyContent={"center"} alignItems="center">
              {/* {this.state.imageUploading && <LoadingOutlined />} */}
              {!this.state.imageUrl && (
                <img src={LogoPlaceholder} alt="logo placeholder" />
              )}
            </Flex>
          )}
        </ImageSectionContainer>
        <Flex style={{ flexDirection: "column" }}>
          <ImageDescription>
            Only .jpg and .png files. Minimum height 32px. Horizontal
            orientation
          </ImageDescription>
          <Flex justifyContent={"flex-start"}>
            <ElmButton
              style={{ minWidth: "127px", marginLeft: 0 }}
              label={"Choose logo"}
              colorVariance="outline-secondary"
              onClick={openVendorImageDialog}
            />
            {this.state?.vendorInfo?.logo_url && (
              <ElmButton
                label={"Remove"}
                colorVariance="subtle"
                className="darkModeTransparentBtn"
                style={{ color: this.props.theme.colors.black }}
                //onClick={this.props?.removeImage}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  };
  public handleImageChange = async (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    if (info.file.status === "uploading") {
      this.setState({ profileImageLoading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (profileImageUrl, profileImage) =>
        this.setState({
          profileImageUrl,
          profileImage,
          profileImageLoading: false,
          profileImageLink: ""
        })
      );
      const gateway = getGateway();
      if (this.state.profileImage) {
        const formData = new FormData();
        const file = this.state.profileImage;
        formData.append("file", file, _.get(file, "name"));
        const uploadInfo = await gateway.request.uploadAdminUserImage(
          formData,
          {
            id: this.state.userInfo.id.toString()
          }
        );
      }
    }
  };
  public beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  };
  public fileAction = (file: RcFile) => {
    return fileActionHandler(file, (profileImageUrl, profileImage, res) =>
      this.setState(
        {
          profileImageUrl,
          profileImage,
          profileImageLoading: false,
          profileImageLink: ""
        },
        () => {
          res(profileImageUrl);
        }
      )
    );
  };
  public renderUserProfileBasicInfo = () => {
    const showEditDialog = () => {
      this.props.openDialog("EditProfileDialog", {
        editMode: true,
        id: this.state.userInfo?.id,
        userData: {
          name: this.state.userInfo?.name,
          email: this.state.userInfo?.email,
          avatar_url: this.state.userInfo?.avatar_url,
          thumb_url: this.state.userInfo?.thumb_url,
          role: this.state.userInfo?.role
        },
        onConfirm: res => {
          this.props.closeDialog("EditProfileDialog");
          this.props.refreshAllOpenBlades();
          this.getUserEmail();
        }
      });
    };
    const removeImageUrl = () => {
      this.setState({
        profileImage: null,
        profileImageUrl: ""
      });
      //to-do
    };
    const avatarName = _.get(this.state, "userInfo.name", "");
    return (
      <Flex style={{ marginBottom: "30px" }}>
        <Flex>
          <Flex style={{ flexDirection: "column" }}>
            <Flex justifyContent={"flex-end"}>
              <ProfileImageSectionContainer>
                <StyledContainer>
                  {this.state.userInfo?.avatar_url ? (
                    <img
                      src={this.state.userInfo?.avatar_url}
                      alt="avatar"
                      style={{ width: "100%", borderRadius: "50%" }}
                    />
                  ) : (
                    <Flex justifyContent={"center"} alignItems="center">
                      {!this.state.profileImageLoading && (
                        <Avatar
                          size={80}
                          style={{
                            backgroundColor: "#4DBF40",
                            fontSize: "24px"
                          }}
                        >
                          {avatarName
                            ? `${avatarName
                                .split(" ")
                                .map(name => name[0])
                                .join("")
                                .toUpperCase()}`
                            : "N/A"}
                        </Avatar>
                      )}
                    </Flex>
                  )}
                  <ImgCrop>
                    <Upload
                      showUploadList={false}
                      action={this.fileAction}
                      beforeUpload={this.beforeUpload}
                      onChange={this.handleImageChange}
                    >
                      <div className="overlay">
                        <div className="text">
                          <UploadIcon size={20} style={{ display: "block" }} />
                        </div>
                      </div>
                    </Upload>
                  </ImgCrop>
                </StyledContainer>
              </ProfileImageSectionContainer>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          style={{ marginLeft: "8px" }}
        >
          <Flex justifyContent="space-between" id={TourGuideId.AccountInfo}>
            <StyledUserFullName>
              {this.state.userInfo
                ? this.state.userInfo.name === null
                  ? "N/A"
                  : this.state.userInfo.name
                : "N/A"}
            </StyledUserFullName>
            <ElmButton
              onClick={showEditDialog}
              icon="pen"
              iconPrefix="fas"
              label=""
              variance="icon-button"
              colorVariance="subtle"
              permissions="modify_assets"
              style={{ color: this.props.theme.colors.iconColor }}
              className="darkModeTransparentBtn"
            />
          </Flex>

          <StyledUserEmail>
            {this.state.userInfo ? this.state.userInfo.email : ""}
          </StyledUserEmail>
        </Flex>
      </Flex>
    );
  };
  public renderTemplates = () => {
    const renderRowActionButtons = (row: any) => {
      const templateId = _.get(row, "id", null);

      const showConfirmationForDelete = () => {
        this.props.openDialog("ConfirmationDialog", {
          entityType: "delete",
          isSimpleConfirmation: true,
          warningMessage: `Are you sure you want to delete selected template ?`,
          onConfirm: () => deleteTemplate()
        });
      };
      const deleteTemplate = () => {
        const gateway = getGateway();

        gateway.request
          .delete_template(null, { id: templateId })
          .then(res => {
            renderSuccessNotification("Template deleted");
            this.props.closeDialog("ConfirmationDialog");
            this.props.refreshAllOpenBlades();
          })
          .catch(err => {
            console.error(err);
          });
      };

      const duplicateTemplate = () => {
        const templateData: ILicenseTemplate = {
          product_id: _.get(row, "product.id", null),
          name: _.get(row, "name", ""),
          template_body: _.get(row, "templateBody")
        };
        const gateway = getGateway();
        gateway.request
          .create_template(templateData)
          .then(res => {
            if (!res.error) {
              renderSuccessNotification("New template added");
              this.props.refreshAllOpenBlades();
            }
          })
          .catch(e => {
            console.error(e);
          });
      };

      const handeleEditTemplate = () => {
        this.props.openBlade({
          routeName: "AddLicense",
          fromBladeIndex: this.props.index,
          route: "AddLicense",
          routeData: {
            id: null,
            templateId: templateId,
            isUsedForTemplates: true
          }
        });
      };

      return (
        <Flex
          style={{
            display: "flex",
            padding: "2px",
            paddingRight: "3px"
          }}
        >
          <ElmButton
            className="darkModeTransparentBtn"
            variance="icon-button"
            icon="copy"
            iconPrefix="fal"
            style={{
              color: this.props.theme.colors.iconColor
            }}
            label=""
            alt="Duplicate"
            colorVariance="subtle"
            permissions={[
              "modify_assets",
              "modify_licenses",
              "request_modify_licenses"
            ]}
            onClick={duplicateTemplate}
          />
          <ElmButton
            className="darkModeTransparentBtn"
            variance="icon-button"
            icon="pen"
            iconPrefix="fas"
            style={{
              color: this.props.theme.colors.iconColor
            }}
            label=""
            alt="Edit"
            colorVariance="subtle"
            permissions={[
              "modify_assets",
              "modify_licenses",
              "request_modify_licenses"
            ]}
            onClick={handeleEditTemplate}
          />
          <ElmButton
            className="darkModeTransparentBtn"
            style={{
              color: this.props.theme.colors.iconColor
            }}
            variance="icon-button"
            icon={"trash"}
            iconPrefix={"fas"}
            alt="Delete"
            label=""
            colorVariance={"subtle"}
            permissions={[
              "modify_assets",
              "modify_licenses",
              "request_modify_licenses"
            ]}
            onClick={showConfirmationForDelete} //should ask for confirmation
          />
        </Flex>
      );
    };

    const handeleAddTemplate = () => {
      this.props.openBlade({
        routeName: "AddLicense",
        fromBladeIndex: this.props.index,
        route: "AddLicense",
        routeData: {
          id: null,
          templateId: "",
          isUsedForTemplates: true
        }
      });
    };

    return (
      <LicenseTemplatesTable
        templates={this.props.result}
        useDefaultAddButton={handeleAddTemplate}
        renderRowActionButtons={row => renderRowActionButtons(row)}
        permissions={[
          "modify_assets",
          "modify_licenses",
          "request_modify_licenses"
        ]}
        //onRowClick={deleteTemplate}
      />
    );
  };

  public updateActiveTab = (activeTabIndex: number) => {
    const bladeIndex = _.get(this.props, "index", 0);
    this.props.updateBladeManagerState({
      tabIndex: activeTabIndex,
      bladeIndex: bladeIndex
    });
  };

  public renderTabs = () => {
    const tokens = (
      <Flex flexDirection="column" flex={1}>
        {this.renderApiTokenAdd()}
        {this.renderApiTable()}
      </Flex>
    );
    const notifications = (
      <Flex flexDirection="column">
        {this.renderNotificationsEdit()}
        {this.renderNotificationsSettings()}
      </Flex>
    );

    const tabList: ITab[] = [
      {
        Component: notifications,
        title: "Notifications",
        tourGuideId: TourGuideId.Notifications
      },
      {
        Component: this.renderTemplates(),
        title: "Templates"
      }
    ];

    const currentRole = _.get(this.props, "appState.activeRole.role", "");
    if (currentRole === "owner" || currentRole === "admin") {
      tabList.unshift(
        {
          title: "Administrators",
          Component: this.renderAdminTable()
          //supScript: this.state.adminUsers.length
        },
        {
          Component: tokens,
          title: "Api Tokens"
          //supScript: this.state.apiTokens.length
        }
      );
    }

    return (
      <TabView
        leftAlign={true}
        showBottomBorder={true}
        orientation={"vertical"}
        defaultTab={_.get(this.props, "routeData.tabIndex", 0)}
        onUpdateActiveTab={this.updateActiveTab}
        tabList={tabList}
      />
    );
  };
  public getAdminUsers = () => {
    this.props.gateway.request
      .getAdminUsers()
      .then(res => {
        if (_.isArray(res.data)) {
          this.setState({ adminUsers: res.data, filteredAdminUsers: res.data });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  public getApiTokens = () => {
    this.props.gateway.request
      .getApiTokens()
      .then(res => {
        if (_.isArray(res.data)) {
          this.setState({ apiTokens: res.data });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  public renderActionButtons = (
    type: "apiToken" | "adminUser"
  ): IElmTableProps["renderRowActionButtons"] => {
    const handleEditAction = (editPayload: {
      apiToken?: {
        description: string;
        scopes: string[];
        id: string;
      };
      adminUser?: { id: number; email: string; role: string };
    }) => () => {
      const { apiToken, adminUser } = editPayload;
      if (type === "apiToken") {
        this.props.openDialog("EditApiTokenDialog", {
          selectedScopes: _.get(apiToken, "scopes"),
          scopes: this.state.apiTokenScopes,
          description: _.get(apiToken, "description"),
          isEdit: true,
          onConfirm: (payload: { scopes: { id: string }[] }) => {
            this.props.gateway.request
              .editApiTokenScope(
                { scopes: _.map(payload.scopes, scope => scope.id) },
                { id: apiToken.id }
              )
              .then(res => {
                if (res.data) {
                  this.props.closeDialog("EditApiTokenDialog");
                  this.getApiTokens();
                }
              })
              .catch(error => {
                console.error(error);
              });
          }
        });
      } else {
        this.props.openDialog("EditAdminDialog", {
          email: adminUser.email,
          currentRole: adminUser.role,
          isEdit: true,
          onConfirm: (payload: { role: string }) => {
            this.props.gateway.request
              .editAdminUser(
                { role: payload.role },
                { id: adminUser.id.toString() }
              )
              .then(res => {
                if (res.data) {
                  this.props.closeDialog("EditAdminDialog");
                  this.getAdminUsers();
                }
              })
              .catch(error => {
                console.error(error);
              });
          }
        });
      }
    };

    const handleDeleteAction = (deletePayload: {
      apiToken?: {
        description: string;
        scopes: string[];
        id: string;
      };
      adminUser?: { id: number; email: string; role: string };
    }) => () => {
      const { apiToken, adminUser } = deletePayload;
      if (type === "apiToken" && apiToken) {
        this.props.openDialog("DeleteDialog", {
          entityType: "Api Token",
          name: apiToken.description,
          onConfirm: () => {
            this.props.gateway.request
              .deleteApiToken({}, { id: apiToken.id })
              .then(res => {
                if (res.data) {
                  this.getApiTokens();
                  this.props.closeDialog("DeleteDialog");
                }
              })
              .catch(error => {
                console.error(error);
              });
          }
        });
      } else {
        this.props.openDialog("DeleteDialog", {
          entityType: "Administrator",
          name: adminUser.email,
          warningMessage: "",
          description: "email",
          onConfirm: () => {
            this.props.gateway.request
              .deleteAdmin({ id: adminUser.id })
              .then(res => {
                this.getAdminUsers();
                this.props.closeDialog("DeleteDialog");
              })
              .catch(error => {
                console.error(error);
              });
          }
        });
      }
    };
    return (
      payload:
        | {
            description: string;
            scopes: string[];
            id: string;
          }
        | { id: number; email: string; role: string }
    ) => (
      <Flex padding={"2px"}>
        <ElmButton
          className="darkModeTransparentBtn"
          variance="icon-button"
          label=""
          colorVariance="subtle"
          icon="pen"
          iconPrefix="fas"
          alt="Edit"
          permissions={
            type === "adminUser" ? "modify_users" : "modify_api_tokens"
          }
          style={{
            color: this.props.theme.colors.iconColor
          }}
          //@ts-ignore
          onClick={handleEditAction({ [type]: payload })}
        />
        <ElmButton
          className="darkModeTransparentBtn"
          //@ts-ignore
          onClick={handleDeleteAction({ [type]: payload })}
          label=""
          alt="Delete"
          style={{
            color: this.props.theme.colors.iconColor
          }}
          permissions={
            type === "adminUser" ? "modify_users" : "modify_api_tokens"
          }
          colorVariance="subtle"
          variance="icon-button"
          icon="trash"
          iconPrefix="fas"
        />
      </Flex>
    );
  };
  public renderAdminTable = () => {
    const handleAddAdmin = () => {
      this.props.openDialog("EditAdminDialog", {
        isEdit: false,
        onConfirm: (payload: { email: string; role: string }) => {
          this.props.gateway.request
            .newAdmin(payload)
            .then(res => {
              if (res.data) {
                this.getAdminUsers();
                this.props.closeDialog("EditAdminDialog");
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      });
    };
    return (
      <ElmTable
        data={
          this.state.filteredAdminUsers.length
            ? this.state.filteredAdminUsers
            : this.state.adminUsers
        }
        totalCount={this.state.adminUsers.length}
        useDefaultAddButton={handleAddAdmin}
        onCriteriaChange={this.onAdminsTableCriteriaChange}
        renderRowActionButtons={this.renderActionButtons("adminUser")}
        columns={[
          {
            Header: "Email",
            accessor: "email",
            sortKey: "email"
          },
          {
            Header: "Role",
            accessor: "role",
            cellRenderer: payload => (
              <StyledText>
                <span>{this.getNiceRoleText(payload.rowData.role)}</span>
              </StyledText>
            ),
            disableSortBy: true,
            sortKey: null
          },
          {
            Header: "Actions",
            accessor: "",
            cellRenderer: () => "",
            width: 0.15,
            disableSortBy: true,
            sortKey: null
          }
        ]}
      />
    );
  };
  public getNiceRoleText = (role: string) => {
    switch (role) {
      case "owner":
        return "Owner";
      case "license_admin":
        return "License administrator";
      case "product_admin":
        return "Product administrator";
      case "stakeholder":
        return "Stakeholder";
      case "license_maintainer":
        return "License maintainer";
      case "admin":
        return "Admin";
      default:
        return role;
    }
  };

  public renderApiTokenAdd = () => {
    const handleAddApiToken = () => {
      this.props.openDialog("EditApiTokenDialog", {
        selectedScopes: [],
        scopes: this.state.apiTokenScopes,
        onConfirm: (payload: {
          description: string;
          scopes: { id: string; description: string }[];
        }) => {
          this.props.gateway.request
            .createApiToken({
              description: payload.description,
              scopes: _.map(payload.scopes, s => s.id)
            })
            .then(res => {
              if (res.data) {
                this.props.closeDialog("EditApiTokenDialog");
                this.props.openDialog("CopyApiTokenDialog", {
                  onConfirm: () => {
                    this.getApiTokens();
                    this.props.closeDialog("CopyApiTokenDialog");
                  },
                  token: res.data.token
                });
              }
            });
        }
      });
    };
    return (
      <Flex flex={1} justifyContent="space-between">
        <ElmButton
          onClick={handleAddApiToken}
          colorVariance="primary"
          label="Add token"
          variance="plain-icon-button"
          permissions="modify_api_tokens"
          icon="plus"
          iconPrefix="fas"
        />
        <span
          style={{
            fontSize: this.props.theme.fontSizes.xSmall,
            color: this.props.theme.colors.textPrimary,
            flexBasis: "70%",
            height: "36px"
          }}
        >
          WARNING: API tokens are like passwords. Never share them with other
          users or applications. Do not publish them in public code
          repositories.
        </span>
      </Flex>
    );
  };
  public renderNotificationsEdit = () => {
    const handleEditPreferences = () => {
      this.props.openBlade({
        routeName: "Email Preferences",
        fromBladeIndex: this.props.index,
        route: "EmailPreferences",
        routeData: {
          userId: this.state.userInfo.id,
          emailPreferences: this.props.result.currentUser
            ?.emailPreferences as EmailNotification[]
        }
      });
      this.checkOnboardingMode() &&
        this.props.appDispatch.appActions.onboardingCompleted();
    };
    return (
      <Flex>
        <Flex
          style={{ marginRight: "20px" }}
          alignItems="center"
          justifyContent="flex-end"
          marginBottom={32}
        >
          <ElmButton
            id={TourGuideId.EmailPreferences}
            onClick={handleEditPreferences}
            colorVariance="outline-secondary"
            label="Edit preferences"
            variance="primary"
            //permissions="modify_api_tokens"
          />
        </Flex>
        <Flex flex={1} flexDirection="column">
          <span
            style={{
              fontSize: this.props.theme.fontSizes.xSmall,
              color: this.props.theme.colors.textPrimary
            }}
          >
            These are notifications you receive by email. You can adjust type
            and frequency by clicking on ‘Edit preferences’ button
          </span>
        </Flex>
      </Flex>
    );
  };

  public renderApiTable = () => {
    return (
      <ElmTable
        data={
          this.state.searchedApiTokens.length
            ? this.state.searchedApiTokens
            : this.state.apiTokens
        }
        totalCount={this.state.adminUsers.length}
        rowHeight={45}
        hideSearchBar
        //onCriteriaChange={this.onSearchApiTokens}
        renderRowActionButtons={this.renderActionButtons("apiToken")}
        columns={[
          {
            Header: "Token",
            accessor: "description",
            disableSortBy: true,
            sortKey: null
          },
          {
            Header: "Actions",
            accessor: "",
            cellRenderer: () => "",
            width: 0.15,
            disableSortBy: true,
            sortKey: null
          }
        ]}
      />
    );
  };
  public handleNotificationChange = (category: string, value: boolean) => {
    const editedNotifications: EmailNotification[] =
      (this.props.result.currentUser
        ?.emailPreferences as EmailNotification[]) || [];
    const newData = editedNotifications.map(notification => {
      if (notification.category === category) {
        return { ...notification, enabled: value };
      }
      return notification;
    });
    this.props.appDispatch.appActions.updateEmailPreferences(
      newData,
      this.state.userInfo.id.toString(),
      this.props.refreshAllOpenBlades
    );
  };
  public renderNotificationsSettings = () => {
    if (this.props.result) {
      const emailNotifications = _.map(
        this.props.result.currentUser?.emailPreferences as EmailNotification[],
        (notification: EmailNotification) => (
          <ToggleRow
            isChecked={notification.enabled}
            reportChange={(category: string) => (value: boolean) => {
              this.handleNotificationChange(category, value);
            }}
            row={notification}
          />
        )
      );
      return (
        <Flex
          style={{
            flexDirection: "column",
            minHeight: "max-content",
            color: this.props.theme.colors.textPrimary
          }}
        >
          <div
            style={{
              minHeight: "max-content",
              width: "100%",
              position: "relative",
              maxHeight: "100%"
            }}
          >
            {emailNotifications}
          </div>
        </Flex>
      );
    } else return null;
  };
  public render() {
    return (
      <BladeTemplate
        accentColor={"warmGrey"}
        bladeTypeName="Settings"
        bladeIndex={this.props.index}
        bladeType="Account"
        closeBlade={this.props.closeBlade}
        title={"Account"}
        bladeBodyStyle={{ paddingRight: "24px", paddingLeft: "24px" }}
        loading={this.state.loading}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
      >
        {this.renderUserProfileBasicInfo()}
        <Flex marginTop={10}>
          {this.renderUserAccountAttributesBox()}
          {this.renderVendorAttributesBox()}
        </Flex>
        {this.renderTabs()}
      </BladeTemplate>
    );
  }
}
const RenderQuery = (props: IAccountBladeProps) => {
  const theme = useTheme();
  const renderAccountInfo = (payload: {
    error: Error;
    props: AccountQueryResponse;
    retry: () => void;
  }) => {
    return (
      <AccountBlade
        {...props}
        result={payload.props}
        theme={theme}
        retry={payload.retry}
      />
    );
  };
  return (
    <QueryRenderer<AccountQuery>
      environment={relayEnvironment}
      cacheConfig={{
        force: true
      }}
      variables={{}}
      query={graphqlQuery}
      render={renderAccountInfo}
    />
  );
};

export default appConnect(RenderQuery, {
  selectors: {
    activeMode: "activeModeSelector",
    userDefinedMode: "userDefinedModeSelector",
    activeRole: "activeRoleSelector",
    onboardingMode: "onboardingModeSelector"
  }
});
