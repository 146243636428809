import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { ElmInput } from "../../../../components/elmInput";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../../components/elmSelect";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import { getGateway } from "../../../../api";
import { componentEntitlements } from "../../../../utils";
import { InfoMessage } from "../editComponent";
import { renderSuccessNotification } from "utils/ant-notifications";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  & > div {
    margin-bottom: 20px;
  }
  flex-grow: 2;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const DialogSubTitle = styled.p`
  ${props => props.theme.fontType.visualizationSubTitle}
`;
export interface INewComponentDialogProps {
  productId: number;
  productName: string;
  isComponentEdit?: boolean;
  componentId: string;
}
export interface INewComponentDialogState {
  name: string;
  component_type: number;
  error: boolean;
}
export class NewComponentDialog extends DialogBase<
  IModalProps<INewComponentDialogProps>,
  INewComponentDialogState
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          minHeight: "284px",
          width: "330px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white",
          border: "none"
        }
      }
    });
  }
  public state: INewComponentDialogState = {
    name: this.props.payload.productName || "",
    component_type: null,
    error: false
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "NewComponentDialog"
    });
  };
  public createNewComponent = () => {
    const newComponent = {
      ..._.pick(this.state, ["name", "component_type"]),
      product_id: this.props.payload.productId
    };
    const gateway = getGateway();
    gateway.request
      .newComponent(newComponent)
      .then(res => {
        if (_.get(res, "error")) {
          this.setState({ error: true });
        } else {
          renderSuccessNotification("Created new component!");
          if (_.isFunction(this.props.payload.onConfirm)) {
            this.props.payload.onConfirm(this.state);
          }
          this.closeModal();
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  public editComponent = () => {
    const componentChanges = {
      name: this.state.name
    };
    const gateway = getGateway();
    gateway.request
      .editComponent(
        { component: componentChanges },
        { id: this.props.payload?.componentId }
      )
      .then(res => {
        if (_.get(res, "error")) {
          this.setState({ error: true });
        } else {
          if (_.isFunction(this.props.payload.onConfirm)) {
            this.props.payload.onConfirm(this.state);
          }
          this.closeModal();
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  public getTitle = () => {
    return this.props.payload?.isComponentEdit
      ? "Edit Component"
      : "Add New Component";
  };
  public getDescription = () => {
    return "You are adding a new component to flotools.";
  };
  public updateType = (componentType: any) => {
    const comp = parseInt(componentType, 10);
    if (_.isFinite(comp)) {
      this.setState({ component_type: comp });
    }
  };
  public updateName = (name: any) => {
    this.setState({ name });
  };
  public render() {
    const isComponentEdit = !!this.props.payload?.isComponentEdit;
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <Flex flexDirection={"column"} flex={1}>
          {isComponentEdit && (
            <InfoMessage
              message="Warning"
              description={`Components are identified by name in the elm client API. 
              Changing the name of a component will change the name returned from the API to product implementations."`}
              type="warning"
            />
          )}
          <ElmInput
            style={{
              marginBottom: "19px",
              marginTop: isComponentEdit ? "10px" : "0px"
            }}
            error={this.state.error}
            defaultValue={this.state.name}
            updatedValue={this.updateName}
            placeholder={"Type name here"}
            label={this.state.error ? "Request Failed" : "Component Name"}
          />
          {!isComponentEdit ? (
            <ElmSelectWithLabel
              label="License Model"
              placeholder="Select a License Model"
              style={{ minWidth: "160px" }}
              onChange={this.updateType}
            >
              {_.map(componentEntitlements, type => {
                return (
                  <ElmSelectOption
                    key={type.id}
                    value={type.id}
                    label={type.label}
                  >
                    {type.label}
                  </ElmSelectOption>
                );
              })}
            </ElmSelectWithLabel>
          ) : null}
        </Flex>
        {/* </FieldsContainer> */}
        <Flex flex={1} alignItems="flex-end" justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            label={isComponentEdit ? "Save changes" : "Create Component"}
            onClick={
              isComponentEdit ? this.editComponent : this.createNewComponent
            }
          />
        </Flex>
        {/* </Flex> */}
      </DialogContainer>
    );
  }
}
