/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type hooksFeaturesAndComponentsQueryVariables = {
  id: string;
  skip: boolean;
};
export type hooksFeaturesAndComponentsQueryResponse = {
  readonly product?:
    | {
        readonly name: string | null;
        readonly components: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string | null;
            readonly componentType: string;
            readonly componentGroup: {
              readonly name: string | null;
              readonly id: string;
            } | null;
          } | null> | null;
        } | null;
        readonly componentGroups: {
          readonly nodes: ReadonlyArray<{
            readonly name: string | null;
            readonly id: string;
            readonly componentType: string;
            readonly components: {
              readonly nodes: ReadonlyArray<{
                readonly name: string | null;
                readonly id: string;
                readonly componentType: string;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | null
    | undefined;
};
export type hooksFeaturesAndComponentsQuery = {
  readonly response: hooksFeaturesAndComponentsQueryResponse;
  readonly variables: hooksFeaturesAndComponentsQueryVariables;
};

/*
query hooksFeaturesAndComponentsQuery(
  $id: ID!
  $skip: Boolean!
) {
  product(id: $id) @skip(if: $skip) {
    name
    components {
      nodes {
        id
        name
        componentType
        componentGroup {
          name
          id
        }
      }
    }
    componentGroups {
      nodes {
        name
        id
        componentType
        components {
          nodes {
            name
            id
            componentType
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any,
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "skip"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "componentType",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      concreteType: "ComponentConnection",
      kind: "LinkedField",
      name: "components",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Component",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v3 /*: any*/,
            v2 /*: any*/,
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentGroup",
              kind: "LinkedField",
              name: "componentGroup",
              plural: false,
              selections: [v2 /*: any*/, v3 /*: any*/],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      concreteType: "ComponentGroupConnection",
      kind: "LinkedField",
      name: "componentGroups",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ComponentGroup",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ComponentConnection",
              kind: "LinkedField",
              name: "components",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Component",
                  kind: "LinkedField",
                  name: "nodes",
                  plural: true,
                  selections: [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "hooksFeaturesAndComponentsQuery",
      selections: [
        {
          condition: "skip",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [v2 /*: any*/, v5 /*: any*/, v6 /*: any*/],
              storageKey: null
            }
          ]
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "hooksFeaturesAndComponentsQuery",
      selections: [
        {
          condition: "skip",
          kind: "Condition",
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: "Product",
              kind: "LinkedField",
              name: "product",
              plural: false,
              selections: [
                v2 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/,
                v3 /*: any*/
              ],
              storageKey: null
            }
          ]
        }
      ]
    },
    params: {
      cacheID: "cb44323ad506418aa47c781d8521a48e",
      id: null,
      metadata: {},
      name: "hooksFeaturesAndComponentsQuery",
      operationKind: "query",
      text:
        "query hooksFeaturesAndComponentsQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  product(id: $id) @skip(if: $skip) {\n    name\n    components {\n      nodes {\n        id\n        name\n        componentType\n        componentGroup {\n          name\n          id\n        }\n      }\n    }\n    componentGroups {\n      nodes {\n        name\n        id\n        componentType\n        components {\n          nodes {\n            name\n            id\n            componentType\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "de01df84b18ba6ea5ea538c863aef2c1";
export default node;
