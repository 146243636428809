/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UserQueryVariables = {
  id: string;
};
export type UserQueryResponse = {
  readonly user: {
    readonly id: string;
    readonly email: string | null;
    readonly createdAt: unknown;
    readonly displayName: string | null;
    readonly name: string | null;
    readonly linkedUsersCount: number;
    readonly productUsers: {
      readonly nodes: ReadonlyArray<{
        readonly modificationRecordsCount: number;
      } | null> | null;
    } | null;
    readonly owner: {
      readonly name: string | null;
      readonly id: string;
    } | null;
    readonly feedbackCount: ReadonlyArray<{
      readonly __typename: string;
    }> | null;
  } | null;
};
export type UserQuery = {
  readonly response: UserQueryResponse;
  readonly variables: UserQueryVariables;
};

/*
query UserQuery(
  $id: ID!
) {
  user(id: $id) {
    id
    email
    createdAt
    displayName
    name
    linkedUsersCount
    productUsers {
      nodes {
        modificationRecordsCount
      }
    }
    owner {
      __typename
      name
      id
    }
    feedbackCount: feedback {
      __typename
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "email",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "createdAt",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "displayName",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "linkedUsersCount",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      concreteType: "ProductUserConnection",
      kind: "LinkedField",
      name: "productUsers",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "ProductUser",
          kind: "LinkedField",
          name: "nodes",
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "modificationRecordsCount",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "__typename",
      storageKey: null
    } as any,
    v10 = {
      alias: "feedbackCount",
      args: null,
      concreteType: "Feedback",
      kind: "LinkedField",
      name: "feedback",
      plural: true,
      selections: [v9 /*: any*/],
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "UserQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: "LinkedField",
              name: "owner",
              plural: false,
              selections: [v6 /*: any*/, v2 /*: any*/],
              storageKey: null
            },
            v10 /*: any*/
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "UserQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: "LinkedField",
              name: "owner",
              plural: false,
              selections: [v9 /*: any*/, v6 /*: any*/, v2 /*: any*/],
              storageKey: null
            },
            v10 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "c33cd5f924bb3cd9283f40f6c3a93d77",
      id: null,
      metadata: {},
      name: "UserQuery",
      operationKind: "query",
      text:
        "query UserQuery(\n  $id: ID!\n) {\n  user(id: $id) {\n    id\n    email\n    createdAt\n    displayName\n    name\n    linkedUsersCount\n    productUsers {\n      nodes {\n        modificationRecordsCount\n      }\n    }\n    owner {\n      __typename\n      name\n      id\n    }\n    feedbackCount: feedback {\n      __typename\n    }\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "fd27420ee6a6c0e368da69472708f91a";
export default node;
