/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type instanceAttributeAdvanced_instance = {
  readonly guid: string;
  readonly hardwareIdentifier: {
    readonly info: unknown | null;
  } | null;
  readonly license: {
    readonly product: {
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
  readonly " $refType": "instanceAttributeAdvanced_instance";
};
export type instanceAttributeAdvanced_instance$data = instanceAttributeAdvanced_instance;
export type instanceAttributeAdvanced_instance$key = {
  readonly " $data"?: instanceAttributeAdvanced_instance$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"instanceAttributeAdvanced_instance">;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: "Fragment",
  metadata: null,
  name: "instanceAttributeAdvanced_instance",
  selections: [
    {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "guid",
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: "HardwareIdentifier",
      kind: "LinkedField",
      name: "hardwareIdentifier",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: "ScalarField",
          name: "info",
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      concreteType: "License",
      kind: "LinkedField",
      name: "license",
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "id",
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "name",
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Instance",
  abstractKey: null
} as any;
(node as any).hash = "9a1a34b49def998e71bd6c97eaf876c2";
export default node;
