/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseReleaseTableQueryVariables = {
  id: string;
};
export type licenseReleaseTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<
    "licenseReleaseTable_releaseChannels"
  >;
};
export type licenseReleaseTableQuery = {
  readonly response: licenseReleaseTableQueryResponse;
  readonly variables: licenseReleaseTableQueryVariables;
};

/*
query licenseReleaseTableQuery(
  $id: ID!
) {
  ...licenseReleaseTable_releaseChannels_1Bmzm5
}

fragment licenseReleaseTable_releaseChannels_1Bmzm5 on Query {
  license(id: $id) {
    releaseChannels(first: 6) {
      edges {
        node {
          id
          name
          releaseConfiguration {
            id
            name
          }
          currentRelease {
            id
            tag
            createdAt
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = [
      {
        kind: "Literal",
        name: "first",
        value: 6
      } as any
    ],
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "licenseReleaseTableQuery",
      selections: [
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "licenseReleaseTable_releaseChannels"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "licenseReleaseTableQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "License",
          kind: "LinkedField",
          name: "license",
          plural: false,
          selections: [
            {
              alias: null,
              args: v2 /*: any*/,
              concreteType: "ReleaseChannelConnection",
              kind: "LinkedField",
              name: "releaseChannels",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseChannelEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ReleaseChannel",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        v3 /*: any*/,
                        v4 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: "ReleaseConfiguration",
                          kind: "LinkedField",
                          name: "releaseConfiguration",
                          plural: false,
                          selections: [v3 /*: any*/, v4 /*: any*/],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: "Release",
                          kind: "LinkedField",
                          name: "currentRelease",
                          plural: false,
                          selections: [
                            v3 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "tag",
                              storageKey: null
                            },
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "createdAt",
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: "releaseChannels(first:6)"
            },
            {
              alias: null,
              args: v2 /*: any*/,
              filters: ["search"],
              handle: "connection",
              key: "licenseReleasesTable_releaseChannels",
              kind: "LinkedHandle",
              name: "releaseChannels"
            },
            v3 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "c631f90e03388952abcb170e1b70d2dd",
      id: null,
      metadata: {},
      name: "licenseReleaseTableQuery",
      operationKind: "query",
      text:
        "query licenseReleaseTableQuery(\n  $id: ID!\n) {\n  ...licenseReleaseTable_releaseChannels_1Bmzm5\n}\n\nfragment licenseReleaseTable_releaseChannels_1Bmzm5 on Query {\n  license(id: $id) {\n    releaseChannels(first: 6) {\n      edges {\n        node {\n          id\n          name\n          releaseConfiguration {\n            id\n            name\n          }\n          currentRelease {\n            id\n            tag\n            createdAt\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "2237ea3813f1e8e7b61da5dd6bfbe696";
export default node;
