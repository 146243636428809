import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import {
  CollapsibleAttributeBox,
  IAttributeValuePair
} from "../../../components/collapsibleAttributesBox";
import {
  dollarString,
  getTermDetails,
  isFutureTerm,
  licenseTypeToName
} from "../../../utils";
import { getLink, getClassName } from "../../../components/helpers";
import { getIcon } from "../../../components/icons";
import { commercialModel } from "Licenses/common";
import { ElmToggle } from "components/elmToggle";
import { getGateway } from "api";
import { appConnect, appDispatch } from "store/appConnect";
import {
  renderNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { getEnvironment } from "api/relay";
import {
  licenseAttributeQuery,
  licenseAttributeQueryResponse
} from "./__generated__/licenseAttributeQuery.graphql";
import LoadingWrapper from "components/elmChart/loadingWrapper";
import { shallowEqual } from "fast-equals";
import { EntityRefreshMap } from "components/bladeManager/entityRefreshMap";

const KeyText = styled.text`
  margin-right: 14px;
  width: 250px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  /* identical to box height, or 133% */

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;

  /* Black - darker */

  color: ${props => props.theme.colors.pureBlack};

  :hover {
    cursor: pointer;
  }
`;

const ExpiredText = styled.span`
  font-size: ${props => props.theme.fontSizes.xSmall};
  color: ${props => props.theme.colors.warmGrey};
  margin-right: 11px;
`;
const WarningInfoText = styled.span`
  font-size: ${props => props.theme.fontSizes.xxSmall};
  color: ${props => props.theme.colors.black};
`;

export const Separator = styled.div`
  width: 4px;
  height: 4px;
  top: 10px;

  /* PinkishGrey */

  background: #cbcacb;

  /* Inside Auto Layout */

  flex: none;
  align-self: center;
  margin-left: 8px;
  margin-right: 8px;
`;
export const AdditionalInfo = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  /* Black - darker */

  color: ${props => props.theme.colors.pureBlack};
`;
export const LicenseStatus = styled.span`
  width: 37px;
  height: 24px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  margin-right: 8px;
  &.active {
    /* Green */
    color: #4dbf40;
  }
  &.expired {
    color: ${props => props.theme.colors.orangeyRed};
  }
`;
const ClipboardIcon = styled(getIcon("MdContentCopy"))`
  color: ${props => props.theme.colors.iconColor};
`;
const HeaderInfoPillow = styled.span(props => ({
  borderRadius: "4px",
  padding: "2px 8px",
  textTransform: "uppercase",
  fontWeight: props.theme.fontWeight.heavy,
  fontSize: props.theme.fontSizes.xxSmall,
  lineHeight: "12px",
  marginLeft: "8px"
}));

const VendorInfo = styled(HeaderInfoPillow)`
  background-color: #95ccef;
  color: #0f649a;
`;
const TrialInfo = styled(HeaderInfoPillow)`
  background-color: #e9df56;
  color: #948b13;
`;
export interface ILicenseAttributeProps {
  id: string;
  isVendor: boolean;
  onClickProduct?: (
    product: licenseAttributeQueryResponse["license"]["product"]
  ) => void;
  onClickCompany?: (
    company: licenseAttributeQueryResponse["license"]["owner"]
  ) => void;
  setRefreshFn: (payload: () => void) => void;
  refreshKey: EntityRefreshMap;
  removeRefreshFn: (entity: EntityRefreshMap) => void;
  permissions?: string | string[];
}

const CopyContainer = styled(Flex)`
  align-items: center;
  padding: 2px;
  min-width: 265px;
  max-width: 265px;
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.lightGrey};
  }
`;

interface ILicenseAttributeState {
  loading: boolean;
}
export class LicenseAttribute extends React.Component<
  ILicenseAttributeProps & {
    appDispatch?: ReturnType<typeof appDispatch>;
    result: licenseAttributeQueryResponse;
    retry: () => void;
  }
> {
  public state: ILicenseAttributeState = {
    loading: false
  };
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    this.props.removeRefreshFn(this.props.refreshKey);
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public handleProductClick = () => {
    if (_.isFunction(this.props.onClickProduct)) {
      this.props.onClickProduct(this.props.result?.license.product);
    }
  };
  public handleCompanyClick = () => {
    if (_.isFunction(this.props.onClickCompany)) {
      this.props.onClickCompany(this.props.result?.license.owner);
    }
  };
  public openCopiedMessage = () => {
    renderNotification("License copied to clipboard");
  };
  public getPrimaryRequirementType = () => {
    switch (licenseTypeToName(this.props.result?.license.type.id)) {
      case "Concurrent":
        return {
          label: "Sessions",
          // value: _.get(this.props.license.entitlements, "nodes[0].sessionCount")
          value: _.get(this.props.result?.license, "totalSeatCount")
        };
      case "Named User":
        return {
          label: "Users",
          // value: _.get(this.props.license.entitlements, "nodes[0].userCount")
          value: _.get(this.props.result?.license, "totalSeatCount")
        };
      case "Node Locked":
        return {
          label: "Instances",
          // value: _.get(
          //   this.props.license.entitlements,
          //   "nodes[0].instanceCount"
          // )
          value: _.get(this.props.result?.license, "totalSeatCount")
        };
    }
    return null;
  };
  public getItems = (): IAttributeValuePair[] => {
    if (!this.props.result?.license) {
      return null;
    }
    const isExpired = this.getExpiredInfo();
    const ProductLink = getLink({
      label: this.props.result?.license.product.name,
      linkType: "Product",
      onClick: this.handleProductClick
    });
    const CompanyLink = getLink({
      linkType: "Company",
      label: this.props.result?.license.owner.name,
      onClick: this.handleCompanyClick
    });
    const items: IAttributeValuePair[] = [
      {
        label: "Company",
        value: CompanyLink
      },
      {
        label: "Product",
        value: ProductLink
      },

      {
        label: "Key",
        value: (
          // isExpired ? (
          //   <>
          //     <ExpiredText>Expired</ExpiredText> <ClipboardIcon />
          //   </>
          // ) :
          <CopyToClipboard
            text={_.get(this.props.result?.license, "key")}
            onCopy={this.openCopiedMessage}
          >
            <CopyContainer>
              <KeyText>{_.get(this.props.result?.license, "key")}</KeyText>
              <ClipboardIcon />
            </CopyContainer>
          </CopyToClipboard>
        )
      }
    ];
    const primaryRequirement = this.getPrimaryRequirementType();
    if (primaryRequirement) {
      items.push(primaryRequirement);
    }
    return [
      ...items
      // {
      //   label: "Mode",
      //   value: this.props.license.isTrial ? "Trial" : "Paid"
      // },

      // {
      //   label: "Customers",
      //   value: this.props.license.isVendor ? "Internal" : "External"
      // }
    ];
  };
  public renderHeaderRow = () => {
    const timestamp = _.get(
      this.props.result,
      "license.latestTerm.endDate"
    ) as string;
    const cM = _.get(this.props.result, "license.commercialModel.name", "");
    const { isExpired, date: endDate } = getTermDetails(timestamp, {
      commercialModel: cM
    });
    const startDate = _.get(
      this.props.result,
      "license.latestTerm.startDate"
    ) as string;
    const renderAdditionalInfo = (info: string) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: "max-content"
        }}
      >
        <Separator />
        <AdditionalInfo>{info}</AdditionalInfo>
      </div>
    );
    const isFreeware = cM === commercialModel.freeware;
    const isToggleLicenseAllowed =
      cM === commercialModel.perpetualMS || cM === commercialModel.freeware;

    const isValueShown =
      this.props.isVendor &&
      cM !== commercialModel.freeTrial &&
      cM !== commercialModel.freeware;
    const isCommercialTrial = cM === commercialModel.freeTrial;
    const licenseId = _.get(this.props, "license.id", null);

    const handleChange = (value: boolean) => {
      this.props.appDispatch?.bladeManagerActions.openDialog({
        name: "ConfirmationDialog",
        payload: {
          description: "key",
          entityType: "terminate",
          title: "Deactivate license ?",
          warningMessage: `You are about to deactivate this license. Are you sure you want to deactivate it?`,
          isSimpleConfirmation: true,
          ///name: this.props.result.license.key.slice(0, 5),
          onConfirm: () => {
            //Activate or deactivate license
            const gateway = getGateway();
            const actionType = value
              ? "activate_license"
              : "deactivate_license";
            gateway.request[actionType]({ license_id: licenseId }).then(res => {
              if (!res.error) {
                renderSuccessNotification(
                  value ? "License activated" : "License deactivated"
                );
                this.props.refreshFn();
                this.props.appDispatch?.bladeManagerActions.closeDialog({
                  name: "ConfirmationDialog"
                });
              }
            });
          }
        }
      });
    };
    return this.props.result?.license ? (
      <div style={{ display: "flex", alignItems: "center", height: "32px" }}>
        {isToggleLicenseAllowed && (
          <Flex marginRight={"8px"} marginTop={"6px"}>
            <ElmToggle
              size={"small"}
              loading={this.state.loading}
              checked={isExpired ? false : true}
              onChange={handleChange}
            />
          </Flex>
        )}
        <LicenseStatus
          className={getClassName({
            active: () => !isExpired
          })}
        >
          {isExpired ? "Expired" : "Active"}
        </LicenseStatus>
        {isFreeware || !timestamp
          ? null
          : renderAdditionalInfo(
              `${isExpired ? `${endDate}` : `Valid till ${endDate}`}`
            )}
        {renderAdditionalInfo(
          licenseTypeToName(this.props.result?.license.type.id)
        )}
        {isValueShown &&
          renderAdditionalInfo(`
        ${dollarString.format(_.get(this.props, "license.lifetimeValue", 0))}`)}
        {_.get(this.props.result, "license.isVendor", false) ? (
          <VendorInfo>Vendor</VendorInfo>
        ) : null}
        {!isCommercialTrial &&
        _.get(this.props.result, "license.isTrial", false) ? (
          <TrialInfo>Trial</TrialInfo>
        ) : null}
      </div>
    ) : (
      <div style={{ display: "flex", alignItems: "center", height: "32px" }}>
        <p className="subheading-value">
          <span className="subheading-label">Loading stats...</span>
        </p>
      </div>
    );
  };

  public getExpiredInfo = () => {
    const commercialModel = _.get(
      this.props.result,
      "license.commercialModel.name"
    );
    const { isExpired } = getTermDetails(
      _.get(this.props.result, "license.latestTerm.endDate") as string,
      { commercialModel }
    );

    return isExpired;
  };

  public renderFooterRow = () => {
    if (!this.props.result?.license) {
      return null;
    }
    const isExpired = this.getExpiredInfo();
    const Warning = styled(getIcon("triangle-exclamation", "fas"))`
      color: ${props => props.theme.colors.orange};
      margin-right: 4.5px;
    `;
    return (
      <Flex style={{ alignItems: "center" }}>
        {_.get(
          this.props.result,
          "license.restriction.requireEmailActivation",
          false
        ) ? (
          <span className="tag">
            <Warning />
            <WarningInfoText>License requires email activation</WarningInfoText>
          </span>
        ) : null}
        {isExpired ? (
          <span className="tag">
            <Warning />
            <WarningInfoText>This license has expired</WarningInfoText>
          </span>
        ) : null}
      </Flex>
    );
  };
  public render() {
    return (
      <LoadingWrapper>
        <CollapsibleAttributeBox
          className="license-attribute-box"
          items={this.getItems()}
          renderHeaderRow={this.renderHeaderRow}
          renderFooterRow={this.renderFooterRow}
          topToggleText={
            this.props.result?.license ? "Show license details" : ""
          }
          bottomToggleText={"Hide license details"}
          isCollapsedDefault={true}
          permissions={this.props.permissions}
        />
      </LoadingWrapper>
    );
  }
}

const graphqlQuery = graphql`
  query licenseAttributeQuery($id: ID!) {
    license(id: $id) {
      key
      isTrial
      isVendor
      product {
        id
        name
      }
      owner {
        name
        id
      }
      type {
        id
      }
      commercialModel {
        id
        name
      }
      lifetimeValue
      totalSeatCount
      restriction {
        requireEmailActivation
      }
      latestTerm {
        endDate
        startDate
      }
    }
  }
`;

const relayEnvironment = getEnvironment();
const RenderQuery = (props: ILicenseAttributeProps) => {
  const renderLicenseAttribute = (payload: {
    error: Error;
    props: licenseAttributeQueryResponse;
    retry: () => void;
  }) => {
    return (
      <LicenseAttribute
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    );
  };
  return (
    <QueryRenderer<licenseAttributeQuery>
      environment={relayEnvironment}
      cacheConfig={{
        force: true
      }}
      variables={{
        id: props.id
      }}
      query={graphqlQuery}
      render={renderLicenseAttribute}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
