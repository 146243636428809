import React from "react";
import { Flex } from "reflexbox";
import { appConnect } from "../../store/appConnect";
import { productAnalyticsQueryResponse } from "./__generated__/productAnalyticsQuery.graphql";
import _ from "lodash";
import { TabbedAnalyticsComponent } from "../../components/analyticsComponent/tabbedAnalytics";
import { WidgetContainer } from "../../components/analyticsComponent/widgetContainer";
import {
  TopCountFilter,
  topCounts
} from "../../components/analyticsComponent/filters/topCount";
import { queryType } from "../../components/analyticsComponent/";
import { sharedTabStyle, WidgetTitle } from "./shared";
import ProductCohortAnalytics from "./productCohortAnalytics";
import moment from "moment";
import { Tooltip } from "antd";
import { InfoIcon } from "components/elmChart/filtersContainer";
import { DateRange } from "blades/Dashboard/utils";
import { IOpenBladeFromChart } from "components/bladeManager/types";

export interface IProductAnalyticsProps {
  result: productAnalyticsQueryResponse;
  openBlade?: (payload: IOpenBladeFromChart) => void;
  retry: () => void;
  timespan: "week" | "month" | "day";
  variables: {
    timeRangeOverride?: DateRange;
    allTimeFilterStart?: string;
    currentTimeSpanStart: string;
    currentTimeSpanEnd: string;
    previousTimeSpanStart: string;
    previousTimeSpanEnd: string;
    productId: string | null;
  };
}

type state = {
  topUsersCount: number;
  topCompaniesCount: number;
  topComponentsCount: number;
};
export class ProductAnalytics extends React.Component<IProductAnalyticsProps> {
  state: state = {
    topCompaniesCount: 5,
    topComponentsCount: 5,
    topUsersCount: 5
  };
  public updateTopCount = (type: keyof state) => (count: topCounts) => {
    this.setState({ [type]: parseInt(count, 10) });
  };
  public renderTabHeader = (header: string) => {
    return <span className={"session-tab-header"}>{header}</span>;
  };
  public getHighlightAnalytics = () => {
    return (
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session count",
              style: {
                //marginLeft: "16px",
                ...sharedTabStyle
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: this.getSessionCountAnalyticsSetup(),
              type: "line"
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: this.getSessionDurationAnalyticsSetup(),
              type: "line"
            }
          }
        ]}
      />
    );
  };
  public getSessionDurationAnalyticsSetup = () => {
    return {
      bladeScope: this.props.variables.productId,
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_duration",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd,
      options: {
        chartMaxHeight: 250
      }
    } as queryType;
  };
  public getSessionCountAnalyticsSetup = () => {
    return {
      bladeScope: this.props.variables.productId,
      setup: {
        category: `time.${this.props.timespan}`,
        value: "sessions.usage_count",
        group: null
      },
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd,
      options: {
        chartMaxHeight: 400
      }
    } as queryType;
  };
  public getSessionActivity = (payload: any) => {
    return {
      bladeScope: this.props.variables.productId,
      valueTitle: "Session Duration",
      setup: {
        category: payload.category,
        value: payload.value,
        group: null
      },
      first: payload?.first || 5,
      options: {
        lineWidth: 25,
        chartMaxHeight: 200
      },
      sortColumn: "value",
      sortDirection: "DESC",
      startTime: this.props.variables.currentTimeSpanStart,
      endTime: this.props.variables.currentTimeSpanEnd
    } as queryType;
  };
  public getTopUsers = () => {
    return (
      <TabbedAnalyticsComponent
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session activity",
              style: {
                //marginLeft: "16px",
                ...sharedTabStyle
              }
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: this.getSessionActivity({
                value: "sessions.usage_count",
                category: "users.name",
                first: this.state.topUsersCount
              }),
              type: "bar",
              allowVendorAnalytics: true
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              timeRangeOverride: this.props.variables?.timeRangeOverride,
              allTimeFilterStart: this.props.variables?.allTimeFilterStart,
              openBlade: this.props.openBlade,
              analytics: this.getSessionActivity({
                value: "sessions.usage_duration",
                category: "users.name",
                first: this.state.topUsersCount
              }),
              type: "bar",
              allowVendorAnalytics: true
            }
          }
        ]}
      />
    );
  };
  public getTopComponents = () => {
    return (
      <TabbedAnalyticsComponent
        //style={{ height: "417px" }}
        titleContainerStyle={{ justifyContent: "flex-start" }}
        views={[
          {
            tabProps: {
              title: "Session activity",
              style: {
                //marginLeft: "16px",
                ...sharedTabStyle
              }
            },
            analyticsComponentProps: {
              analytics: this.getSessionActivity({
                value: "components.usage_count",
                category: "components.name",
                first: this.state.topComponentsCount
              }),
              type: "bar",
              allowVendorAnalytics: true
            }
          },
          {
            tabProps: {
              title: "Session duration",
              style: sharedTabStyle
            },
            analyticsComponentProps: {
              analytics: this.getSessionActivity({
                value: "components.usage_duration",
                category: "components.name",
                first: this.state.topComponentsCount
              }),
              type: "bar",
              allowVendorAnalytics: true
            }
          }
        ]}
      />
    );
  };
  public render() {
    return (
      <>
        <div className="product-analytics analytics-container">
          <WidgetContainer size="x-large">
            {this.getHighlightAnalytics()}
          </WidgetContainer>

          <Flex
            justifyContent="space-between"
            flexBasis="100%"
            style={{ gap: "20px" }}
          >
            <WidgetContainer size="medium-large">
              <Flex justifyContent="space-between" alignItems="center">
                <WidgetTitle>Top users</WidgetTitle>
                <TopCountFilter
                  onChange={this.updateTopCount("topUsersCount")}
                />
              </Flex>
              {this.getTopUsers()}
            </WidgetContainer>

            <WidgetContainer size="medium-large">
              <Flex
                //flex={1}
                justifyContent="space-between"
                alignItems="center"
                // style={{
                //   margin: "16px 16px 0px 16px",
                // }}
              >
                <WidgetTitle>Top components</WidgetTitle>
                <TopCountFilter
                  onChange={this.updateTopCount("topComponentsCount")}
                />
              </Flex>
              {this.getTopComponents()}
            </WidgetContainer>
          </Flex>
          <Flex
            justifyContent="space-between"
            flexBasis="100%"
            style={{ gap: "20px" }}
            minHeight={"max-content"}
          >
            <WidgetContainer size="large">
              <Flex justifyContent="space-between" alignItems="center">
                <span className="widget-title">
                  User adoption cohort analysis
                  <Tooltip
                    placement="top"
                    title={
                      "A cohort chart is a visual representation of user adoption and usage over time. It shows how different groups of users behave and engage with your product or service. The chart helps you identify trends in user behavior and measure the effectiveness of your product or feature changes. It's a powerful tool for understanding user retention and driving growth."
                    }
                  >
                    <InfoIcon />
                  </Tooltip>
                </span>
              </Flex>
              <ProductCohortAnalytics
                variables={{
                  productId: this.props.variables.productId,
                  cohortStartDate: moment()
                    .subtract(14, "days")
                    .format("YYYY/MM/DD")
                }}
              />
            </WidgetContainer>
          </Flex>
        </div>
      </>
    );
  }
}

const RenderQuery = (props: IProductAnalyticsProps) => {
  return <ProductAnalytics {...props} variables={props.variables} />;
};
export default appConnect(RenderQuery, {
  selectors: {}
});
