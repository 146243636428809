import { getGateway } from "api";

class AppImageDictionary {
  private static instance: AppImageDictionary;
  private gateway: ReturnType<typeof getGateway>;
  private images: Record<string, any>;

  private constructor() {
    this.images = {};
    this.gateway = getGateway();
  }

  public static getInstance(): AppImageDictionary {
    if (!AppImageDictionary.instance) {
      AppImageDictionary.instance = new AppImageDictionary();
    }
    return AppImageDictionary.instance;
  }

  public getImage(key: string) {
    return this.images[key];
  }

  public async getImageSrc(key: string, url?: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.images[key]) {
          resolve(this.images[key]);
          return;
        }
        if (!url) {
          resolve(null);
          return;
        }
        if (url?.startsWith("/rails/active_storage/")) {
          const redirect = await this.gateway.request.getRedirectImageUrl({
            redirect_url: url
          });
          this.addImage(key, redirect.data?.redirect_url);
          resolve(redirect.data?.redirect_url);
          return;
        } else {
          this.addImage(key, url);
          resolve(url);
        }
      } catch (error) {
        console.error(error);
        reject(url);
      }
    });
  }

  private addImage(key: string, value: any) {
    this.images[key] = value;
  }
}

export default AppImageDictionary.getInstance();
