import * as _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled, { useTheme } from "styled-components";
import { ElmButton } from "../../../../components/elmButton";
import { ElmCheckBox } from "../../../../components/elmCheckBox";
import { DialogBase } from "../../../../components/bladeManager/appDialog/base";
import { IModalProps } from "../../../../components/bladeManager/appDialog/types";
import ElmInput from "../../../../components/elmInput";
import relayEnvironment from "../../../../api/relay";
import { getGateway } from "../../../../api";
import {
  newProductFeatureQuery,
  newProductFeatureQueryResponse
} from "./__generated__/newProductFeatureQuery.graphql";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../../components/elmSelect";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { LoaderContainer } from "../../../../components/helpers";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 320px;
  width: 290px;
`;

const StyledGroupSelection = styled(Flex)`
  color: ${props => props.theme.colors.textPrimary};
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const groupSelectionQuery = graphql`
  query newProductFeatureQuery(
    $productId: ID!
    $featureId: ID!
    $skip: Boolean!
  ) {
    product(id: $productId) {
      name
      featureGroups {
        nodes {
          name
          id
        }
      }
    }
    feature(id: $featureId) @skip(if: $skip) {
      id
      name
      featureGroups {
        nodes {
          id
          name
        }
      }
    }
  }
`;

export interface INewProductFeatureDialogProps {
  id: string;
  title?: string;
  description?: string;
  productId: string;
  productName: string;
  editMode?: boolean;
  refreshFn?: () => void;
  groups?: { id?: string; name: string }[];
  name?: string;
}
type state = {
  name: string;
  disabled: boolean;
  selectedGroups: { id?: string; name: string }[];
  isSelectOpen: boolean;
};
export class NewProductFeatureDialog extends DialogBase<
  IModalProps<INewProductFeatureDialogProps> & {
    result: newProductFeatureQueryResponse;
  },
  state
> {
  state: state = {
    name: this.props.payload.name || "",
    disabled: false,
    selectedGroups: _.get(this.props, "result.feature.featureGroups.nodes", []),
    isSelectOpen: false
  };

  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          //flexDirection: "column",
          minHeight: "320px",
          width: "330px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white",
          border: "none"
        }
      }
    });
  }

  public toggleSelect = (isOpen: boolean) => {
    this.setState({ isSelectOpen: isOpen });
  };

  public GroupSelection = () => {
    const updateGroups = (payload: {
      id: string;
      name: string;
      checked: boolean;
    }) => {
      if (payload.checked) {
        const newComponents = [
          ...this.state.selectedGroups,
          { name: payload.name, id: payload.id }
        ];
        this.setState({ selectedGroups: newComponents });
      } else {
        const filtered = this.state.selectedGroups.filter(f => {
          if (f.id && f.id !== payload.id) return true;
          if (f.name && f.name !== payload.name) return true;
          return false;
        });
        this.setState({ selectedGroups: filtered });
      }
    };

    const handleChange = (group: { id: string; name: string }) => (
      e: CheckboxChangeEvent
    ) => {
      updateGroups({
        checked: e.target.checked,
        ...group
      });
    };
    const product = this.props.result.product;
    return (
      <StyledGroupSelection
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Flex
          style={{ height: "158px", marginTop: "7px" }}
          flexDirection="column"
          flex={1}
        >
          {product?.featureGroups?.nodes?.length ? (
            <ElmSelectWithLabel
              label="Feature Groups"
              placeholder="Select Feature Groups"
              style={{ flexDirection: "row" }}
              onClick={() => this.toggleSelect(true)}
              onBlur={() => this.toggleSelect(false)}
              open={this.state.isSelectOpen}
              value={
                this.state.selectedGroups.length > 0
                  ? this.state.selectedGroups.map(item => item.name).join()
                  : undefined
              }
            >
              <ElmSelectOption key={"selectAll"} value={"selectAll"}>
                <ElmCheckBox
                  onClick={() => {
                    const filterValues = product?.featureGroups?.nodes;
                    this.setState({
                      ...this.state,
                      selectedGroups:
                        this.state.selectedGroups.length ===
                        filterValues?.length
                          ? []
                          : [...filterValues]
                    });
                  }}
                  // style={{ marginRight: "40px" }}
                >
                  {"Select all"}
                </ElmCheckBox>
              </ElmSelectOption>
              {_.map(product.featureGroups?.nodes, node => {
                return (
                  <ElmSelectOption
                    key={node.id}
                    value={node.name}
                    label={node.name}
                  >
                    <ElmCheckBox
                      onChange={handleChange(node)}
                      checked={
                        this.state.selectedGroups.find(
                          item => item.id === node.id || item.name === node.name
                        )
                          ? true
                          : false
                      }
                    >
                      {node.name}
                    </ElmCheckBox>
                  </ElmSelectOption>
                );
              })}
            </ElmSelectWithLabel>
          ) : null}
          {product && !product?.featureGroups?.nodes?.length && (
            <Flex
              style={{ marginTop: "10px", justifyContent: "center" }}
            >{`No groups found for ${product.name}.`}</Flex>
          )}
        </Flex>
      </StyledGroupSelection>
    );
  };

  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "NewProductFeatureDialog"
    });
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };
  public getTitle = () => {
    return this.props?.payload?.editMode ? "Edit Feature" : "Add Feature";
  };

  public getDescription = () => {
    return "Create a new feature for this product";
  };
  public createNewProductFeature = () => {
    this.setState(
      {
        disabled: true
      },
      () => {
        const gateway = getGateway();
        const data = {
          id: this.props.payload.id,
          product_id: this.props.payload.productId,
          feature_group_ids: this.state.selectedGroups.map(item => item.id),
          name: this.props.payload.name || this.state.name,
          groups: [] as string[],
          group_memberships: []
        };
        this.props.payload.editMode
          ? gateway.request
              .editProductFeature(data, {
                id: this.props.payload.id
              })
              .then(res => {
                if (!res.error) {
                  if (_.isFunction(this.props.payload.onConfirm)) {
                    this.props.payload.onConfirm(res);
                  }
                  if (_.isFunction(this.props.payload.refreshFn)) {
                    this.props.payload.refreshFn();
                  }
                  return this.closeModal();
                }
              })
              .catch(err => {
                console.error(err);
              })
          : gateway.request
              .newProductFeature({
                product_id: this.props.payload.productId,
                feature_group_ids: this.state.selectedGroups.map(
                  item => item.id
                ),
                name: this.state.name
              })
              .then(res => {
                if (_.isObject(res.data)) {
                  if (res.data.id) {
                    if (_.isFunction(this.props.payload.onConfirm)) {
                      this.props.payload.onConfirm();
                    }
                    if (_.isFunction(this.props.payload.refreshFn)) {
                      this.props.payload.refreshFn();
                    }
                    return this.closeModal();
                  }
                }
                this.setState({ disabled: false });
                return false;
              })
              .catch(err => {
                console.error(err);
              });
      }
    );
  };
  public render() {
    const editMode = this.props?.payload?.editMode;
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <Flex>
          <ElmInput
            style={{
              marginBottom: "19px"
            }}
            //disabled={this.props.payload.editMode ? true : false}
            defaultValue={this.state.name}
            //value={this.state.name}
            updatedValue={this.updateName}
            controlled={true}
            label={"Feature Name"}
            placeholder={"Enter Feature Name"}
          />
        </Flex>
        {/* {this.renderQueryRenderer()} */}
        {this.GroupSelection()}
        <Flex flex={1} alignItems="flex-end" justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            style={{ maxWidth: "150px" }}
            disabled={this.state.disabled}
            label={editMode ? "Save changes" : "Create Feature"}
            onClick={this.createNewProductFeature}
          />
        </Flex>
      </DialogContainer>
    );
  }
}

export const DataContainer = (
  props: IModalProps<INewProductFeatureDialogProps>
) => {
  const renderGroupSelection = (payload: {
    error: Error;
    props: newProductFeatureQueryResponse;
  }) => {
    if (!payload.props) {
      return <DialogContainer>{LoaderContainer()}</DialogContainer>;
    }
    if (payload.error) {
      return null;
    }
    return <NewProductFeatureDialog {...props} result={payload.props} />;
  };
  return (
    <DialogContainer>
      <QueryRenderer<newProductFeatureQuery>
        variables={{
          productId: _.get(props.payload, "productId"),
          featureId: _.get(props, "payload.id", ""),
          skip: !_.get(props, "payload.id")
        }}
        environment={relayEnvironment}
        query={groupSelectionQuery}
        render={renderGroupSelection}
      />
    </DialogContainer>
  );
};

export default DataContainer;
