import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import { QueryRenderer } from "react-relay";
import { notification } from "antd";
import {
  CollapsibleAttributeBox,
  IAttributeValuePair
} from "../../../components/collapsibleAttributesBox";
import { getIcon } from "../../../components/icons";
import { getLink } from "../../../components/helpers";
import LoadingWrapper from "components/elmChart/loadingWrapper";
import { getEnvironment } from "api/relay";
import {
  userAttributeQuery,
  userAttributeQueryResponse
} from "./__generated__/userAttributeQuery.graphql";
import { appConnect } from "store/appConnect";

const Separator = styled.div`
  width: 4px;
  height: 4px;
  top: 10px;

  /* PinkishGrey */

  background: #cbcacb;

  /* Inside Auto Layout */

  flex: none;
  align-self: center;
  margin-left: 8px;
  margin-right: 8px;
`;
const AdditionalInfo = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  /* Black - darker */

  color: ${props => props.theme.colors.pureBlack};
`;
export interface IUserAttributeProps {
  onClickCompany: (owner: userAttributeQueryResponse["user"]["owner"]) => void;
  permissions?: string | string[];
}
const ClipboardIcon = styled(getIcon("MdContentCopy"))`
  color: ${props => props.theme.colors.iconColor};
  margin-left: 6.67px;
`;
export class UserAttribute extends React.Component<
  IUserAttributeProps & {
    result: userAttributeQueryResponse;
    retry: () => void;
  }
> {
  public getItems = (): IAttributeValuePair[] => {
    let items: IAttributeValuePair[] = [
      {
        label: "Registered Name",
        value: this.props.result?.user.name
      },
      {
        label: "User Name",
        value: _.get(this.props.result?.user.info, "userName")
      },
      {
        label: "ID",
        value: this.props.result?.user.guid
      }
    ];
    _.each(_.keys(this.props.result?.user.info), infoKey => {
      items.push({
        label: _.capitalize(infoKey),
        value: this.props.result?.user.info[infoKey]
      });
    });
    items = [
      ...items,
      {
        label: "Location",
        value: _.get(this.props.result?.user.location, "city")
      },
      {
        label: "No. of linked accounts",
        value: this.props.result?.user.linkedUsersCount.toString()
      }
    ];
    return items;
  };
  public openCopiedMessage = () => {
    notification.open({
      message: "Success",
      description: "email copied to clipboard"
    });
  };
  public renderHeaderRow = () => {
    if (!this.props.result?.user) {
      return (
        <p className="subheading-value">
          <span className="subheading-label">Loading stats...</span>
        </p>
      );
    }
    return (
      <div style={{ display: "flex", height: "32px" }}>
        {getLink({
          linkType: "Company",
          label: this.props.result.user.owner.name,
          onClick: this.handleCompanyClick
        })}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "max-content"
          }}
        >
          <Separator />
          <CopyToClipboard
            text={this.props.result.user.email}
            onCopy={this.openCopiedMessage}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <AdditionalInfo>{this.props.result.user.email}</AdditionalInfo>
              <ClipboardIcon />
            </div>
          </CopyToClipboard>
        </div>
      </div>
    );
  };
  public handleCompanyClick = () => {
    if (_.isFunction(this.props.onClickCompany)) {
      this.props.onClickCompany(this.props.result.user.owner);
    }
  };
  public render() {
    return (
      <LoadingWrapper>
        <CollapsibleAttributeBox
          renderHeaderRow={this.renderHeaderRow}
          topToggleText={"Show user details"}
          bottomToggleText={"Hide user details"}
          items={this.getItems()}
          isCollapsedDefault={true}
          permissions={this.props.permissions}
        />
      </LoadingWrapper>
    );
  }
}

const graphqlQuery = graphql`
  query userAttributeQuery($id: ID!) {
    user(id: $id) {
      linkedUsersCount
      # instancesCount
      guid
      info
      email
      name
      #  displayName
      # productsCount
      owner {
        id
        name
      }
      location {
        city
        countryCode
      }
    }
  }
`;

const relayEnvironment = getEnvironment();
const RenderQuery = (props: IUserAttributeProps & { id: string }) => {
  const renderProductAttribute = (payload: {
    error: Error;
    props: userAttributeQueryResponse;
    retry: () => void;
  }) => {
    return (
      <UserAttribute {...props} result={payload.props} retry={payload.retry} />
    );
  };
  return (
    <QueryRenderer<userAttributeQuery>
      environment={relayEnvironment}
      cacheConfig={{
        force: true
      }}
      variables={{
        id: props.id
      }}
      query={graphqlQuery}
      render={renderProductAttribute}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
