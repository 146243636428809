/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ProductReleaseQueryVariables = {
  id: string;
};
export type ProductReleaseQueryResponse = {
  readonly release: {
    readonly tag: string;
    readonly data: string | null;
    readonly releaseConfiguration: {
      readonly product: {
        readonly iconUrl: string | null;
      } | null;
    } | null;
  } | null;
};
export type ProductReleaseQuery = {
  readonly response: ProductReleaseQueryResponse;
  readonly variables: ProductReleaseQueryVariables;
};

/*
query ProductReleaseQuery(
  $id: ID!
) {
  release(id: $id) {
    tag
    data
    releaseConfiguration {
      product {
        iconUrl
        id
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "tag",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "data",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "iconUrl",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "ProductReleaseQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Release",
          kind: "LinkedField",
          name: "release",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfiguration",
              kind: "LinkedField",
              name: "releaseConfiguration",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: [v4 /*: any*/],
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "ProductReleaseQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Release",
          kind: "LinkedField",
          name: "release",
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfiguration",
              kind: "LinkedField",
              name: "releaseConfiguration",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Product",
                  kind: "LinkedField",
                  name: "product",
                  plural: false,
                  selections: [v4 /*: any*/, v5 /*: any*/],
                  storageKey: null
                },
                v5 /*: any*/
              ],
              storageKey: null
            },
            v5 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "f7950c7b67435d3ffaf1ef5db05543fc",
      id: null,
      metadata: {},
      name: "ProductReleaseQuery",
      operationKind: "query",
      text:
        "query ProductReleaseQuery(\n  $id: ID!\n) {\n  release(id: $id) {\n    tag\n    data\n    releaseConfiguration {\n      product {\n        iconUrl\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "09b1fdff449215432e812f75935bbfd6";
export default node;
