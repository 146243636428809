import React, { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { GateKeeper } from './components';
import LoginPage from "./login";
import PasswordUpdate from "./passwordUpdate";
import RegisterPage from "./register";
import { configureStore } from "./store";
import theme from "./theme";
import themeDark from "./themeDark";
// @ts-ignore
import { useSelector } from "react-redux";
//import "antd/dist/antd.css";
import "./App.scss";
import selectors from "./store/selectors";
import { useFeedbackWidget } from "utils/FeedbackWidget";
import PasswordUpdateRedirect from "passwordUpdateRedirect";

const store = configureStore();

const GateKeeper = lazy(() => import("./components/gateKeeper"));
const RenderApp = () => {
  const selectedTheme = useSelector(selectors.activeModeSelector);
  //data-theme attribute is used inside the scss files
  document.documentElement.setAttribute("data-theme", selectedTheme);
  useFeedbackWidget();
  return (
    <ThemeProvider theme={selectedTheme === "light" ? theme : themeDark}>
      <Router basename={process.env.REACT_APP_BASE_URL || "/"}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route
              path={"/system_user_auth/password/edit"}
              component={PasswordUpdateRedirect}
            />
            <Route path={"/auth/password_update"} component={PasswordUpdate} />
            <Route path={"/auth/register"} component={RegisterPage} />
            <Route path={"/login"} component={LoginPage} />
            <Route component={GateKeeper} path={"*"} />
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};
const App: React.FC = () => {
  return (
    <Provider store={store}>
      <RenderApp />
    </Provider>
  );
};

export default App;
