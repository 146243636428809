/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseActiveSessionsTable_activeSessions = {
  readonly license: {
    readonly activeSessions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly startTime: unknown | null;
          readonly endTime: unknown | null;
          readonly user: {
            readonly displayName: string | null;
          } | null;
          readonly instance: {
            readonly hardwareIdentifier: {
              readonly info: unknown | null;
            } | null;
          } | null;
          readonly versionString: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $refType": "licenseActiveSessionsTable_activeSessions";
};
export type licenseActiveSessionsTable_activeSessions$data = licenseActiveSessionsTable_activeSessions;
export type licenseActiveSessionsTable_activeSessions$key = {
  readonly " $data"?:
    | licenseActiveSessionsTable_activeSessions$data
    | undefined;
  readonly " $fragmentRefs": FragmentRefs<
    "licenseActiveSessionsTable_activeSessions"
  >;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: 6,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: "cursor",
        direction: "forward",
        path: ["license", "activeSessions"]
      }
    ]
  },
  name: "licenseActiveSessionsTable_activeSessions",
  selections: [
    {
      alias: null,
      args: [
        {
          kind: "Variable",
          name: "id",
          variableName: "id"
        }
      ],
      concreteType: "License",
      kind: "LinkedField",
      name: "license",
      plural: false,
      selections: [
        {
          alias: "activeSessions",
          args: null,
          concreteType: "SessionConnection",
          kind: "LinkedField",
          name: "__licenseActiveSessionsTable_activeSessions_connection",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "SessionEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "Session",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "startTime",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endTime",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "User",
                      kind: "LinkedField",
                      name: "user",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "displayName",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "Instance",
                      kind: "LinkedField",
                      name: "instance",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "HardwareIdentifier",
                          kind: "LinkedField",
                          name: "hardwareIdentifier",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "info",
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "versionString",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "029aeb0267219eed254323b798dffd8d";
export default node;
