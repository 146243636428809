import { notification } from "antd";
import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import { ElmInput } from "../../../elmInput";
import { getGateway } from "../../../../api";
import { renderErrorNotification } from "utils/ant-notifications";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  width: 290px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
export interface IEditUserDialogProps {
  id: string;
  userData: { name: string; email: string };
}

interface IEditDialogState {
  id: string;
  name: string;
  email: string;
  isDisabled: boolean;
  error: boolean;
}

export class EditUserDialog extends DialogBase<
  IModalProps<IEditUserDialogProps>
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          minHeight: "320px",
          width: "330px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          border: "none"
        }
      }
    });
  }
  state: IEditDialogState = {
    id: this.props.payload.id,
    name: this.props.payload.userData.name,
    email: this.props.payload.userData.email,
    isDisabled: false,
    error: false
  };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditUserDialog"
    });
  };
  public editUser = () => {
    const isValid = this.validateEmail(this.state.email);
    if (!isValid) {
      renderErrorNotification("Email not valid !");
      return;
    }
    const gateway = getGateway();
    this.setState({ isDisabled: true }, () => {
      gateway.request
        .editUser(
          {
            id: this.state.id,
            email: this.state.email,
            name: this.state.name
          },
          { id: this.state.id }
        )
        .then(res => {
          if (res && !res.error) {
            if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
              this.props.payload.onConfirm(this.state);
            }
          }
          if (res?.error) {
            renderErrorNotification(res?.data?.error || "Something went wrong");
          }
          this.setState({ isDisabled: false });
        })
        .catch(error => {
          console.error(error);
        });
    });
  };
  public getTitle = () => {
    return "Edit User";
  };

  public updateEmail = (email: string) => {
    this.setState({ email });
  };
  public validateEmail = (input: string) => {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input);
    if (!isValid) {
      this.setState({ error: true });
      return false;
    }
    this.setState({ error: false });
    return true;
  };
  public updateName = (name: string) => {
    this.setState({ name });
  };
  public render() {
    const { name, email, isDisabled } = this.state;
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <Flex>
          <ElmInput
            label={"Name"}
            placeholder="John Doe"
            style={{ marginTop: "15px" }}
            disabled={false}
            defaultValue={name}
            updatedValue={this.updateName}
          />
        </Flex>
        <Flex>
          <ElmInput
            label={"Email"}
            placeholder="john@doe.com"
            style={{ marginTop: "15px" }}
            disabled={false}
            defaultValue={email}
            error={this.state.error}
            errorMessage={"Email is not valid"}
            updatedValue={this.updateEmail}
          />
        </Flex>

        <Flex flex={1} alignItems="flex-end" justifyContent={"flex-end"}>
          <ElmButton
            label={"Cancel"}
            colorVariance={"outline-secondary"}
            onClick={this.closeModal}
          />
          <ElmButton
            style={{ minWidth: "127px" }}
            colorVariance="primary"
            label={"Save Changes"}
            onClick={this.editUser}
            disabled={isDisabled}
          />
        </Flex>
      </DialogContainer>
    );
  }
}
