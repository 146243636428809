import { notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import * as _ from "lodash";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import styled from "styled-components";
import { QueryRenderer } from "react-relay";
import { CollapsibleAttributeBox } from "../../../components/collapsibleAttributesBox";
import { Flex } from "reflexbox/styled-components";
import { getIcon } from "../../../components/icons";
import { getEnvironment } from "api/relay";
import { appConnect } from "store/appConnect";
import {
  productAttributeQuery,
  productAttributeQueryResponse
} from "./__generated__/productAttributeQuery.graphql";
import LoadingWrapper from "components/elmChart/loadingWrapper";
import { shallowEqual } from "fast-equals";
import { EntityRefreshMap } from "components/bladeManager/entityRefreshMap";

export interface IProductAttributeProps {
  id: string;
  setRefreshFn: (payload: () => void) => void;
  refreshKey: EntityRefreshMap;
  removeRefreshFn: (entity: EntityRefreshMap) => void;
}

const PublicKeyContainer = styled.div`
  width: 298px;
  height: 69px;
  border: solid 1px ${props => props.theme.colors.black};
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;
const CopyContainer = styled(Flex)`
  align-items: center;
  padding: 2px;
  min-width: 290px;
  max-width: 290px;
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.lightGrey};
  }
`;
const ClipboardIcon = styled(getIcon("MdContentCopy"))`
  color: ${props => props.theme.colors.iconColor};
`;
const KeyText = styled.text`
  margin-right: 14px;
  width: 270px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  /* identical to box height, or 133% */

  text-transform: lowercase;

  /* Black - darker */

  color: ${props => props.theme.colors.pureBlack};

  :hover {
    cursor: pointer;
  }
`;
export class ProductAttribute extends React.Component<
  IProductAttributeProps & {
    result: productAttributeQueryResponse;
    retry: () => void;
  }
> {
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    this.props.removeRefreshFn(this.props.refreshKey);
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public openCopiedMessage = (type: string) => () => {
    notification.open({
      message: "Success",
      description: `${type} copied to clipboard`
    });
  };
  public getItems = () => {
    if (!_.isObject(this.props.result?.product)) {
      return [];
    }
    return [
      {
        label: "Name",
        value: this.props.result?.product?.name
      },
      {
        label: "Type",
        value: this.props.result?.product.type.name
      },
      {
        label: "Support Email",
        value: this.props.result?.product.supportEmail
      },
      {
        label: "Versions",
        value: this.props.result?.product.productVersionsCount
      },
      {
        label: "GUID",
        value: (
          <CopyToClipboard
            text={_.get(this.props.result?.product, "guid")}
            onCopy={this.openCopiedMessage("Guid")}
          >
            <CopyContainer>
              <KeyText>{_.get(this.props.result?.product, "guid")}</KeyText>
              <ClipboardIcon />
            </CopyContainer>
          </CopyToClipboard>
        )
      },
      {
        label: "Public Key",
        value: (
          <CopyToClipboard
            text={_.get(this.props.result?.product, "publicKey")}
            onCopy={this.openCopiedMessage("Public Key")}
          >
            <CopyContainer>
              <KeyText>
                {_.get(this.props.result?.product, "publicKey")}
              </KeyText>
              <ClipboardIcon />
            </CopyContainer>
          </CopyToClipboard>
        )
      }
    ];
  };
  public renderHeaderRow = () => {
    return (
      <div style={{ display: "flex", height: "32px" }}>
        {this.props.result ? (
          <div style={{ display: "flex" }}>
            <p className="subheading-value">
              {this.props.result.product?.totalSeats}
              <span className="subheading-label">Licensed Seats</span>
            </p>
            <p className="subheading-value">
              <span className="bordered" />
              {this.props.result.product?.usersCount}
              <span className="subheading-label">Users</span>
            </p>
            <p className="subheading-value">
              <span className="bordered" />
              {this.props.result.product?.licenseeCount}
              <span className="subheading-label">
                {this.props.result.product?.licenseeCount > 1
                  ? "Companies"
                  : "Company"}
              </span>
            </p>
          </div>
        ) : (
          <p className="subheading-value">
            <span className="subheading-label">Loading stats...</span>
          </p>
        )}
      </div>
    );
  };
  public render() {
    return (
      <LoadingWrapper>
        <CollapsibleAttributeBox
          renderHeaderRow={this.renderHeaderRow}
          topToggleText={
            this.props.result?.product ? "Show product details" : ""
          }
          bottomToggleText={"Hide product details"}
          items={this.getItems()}
          isCollapsedDefault={true}
        />
      </LoadingWrapper>
    );
  }
}

const graphqlQuery = graphql`
  query productAttributeQuery($id: ID!) {
    product(id: $id) {
      type {
        name
      }
      name
      guid
      publicKey
      supportEmail
      usersCount
      licenseeCount
      totalSeats
      productVersionsCount
    }
  }
`;

const relayEnvironment = getEnvironment();
const RenderQuery = (props: IProductAttributeProps) => {
  const renderProductAttribute = (payload: {
    error: Error;
    props: productAttributeQueryResponse;
    retry: () => void;
  }) => {
    return (
      <ProductAttribute
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    );
  };
  return (
    <QueryRenderer<productAttributeQuery>
      environment={relayEnvironment}
      cacheConfig={{
        force: true
      }}
      variables={{
        id: props.id
      }}
      query={graphqlQuery}
      render={renderProductAttribute}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {}
});
