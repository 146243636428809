import { Menu } from "antd";
import Fuse from "fuse.js";
import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import styled, { useTheme } from "styled-components";
import { getIcon } from "../icons";
import { getSavedDataForTable, saveDataForTable } from "./utils";
import {
  IFilterSearchProps,
  IFilterSearchState,
  IFilterSelectProps,
  IColumn
} from "./types";
import FilterSelect from "./filterSelect";
import {
  CategorySearchContainer,
  FilterSearch,
  FilterSelectionRow
} from "./helpers";
import { SearchBar } from "./helpers";

import { getCaretIcon } from "./filterSelect";
import {
  ClearAllContainer,
  FilterSectionContainer,
  FilterSelectionCloseIcon,
  FilterSelectionOptionBox,
  FilterSelectionOptionText,
  FilterTextContainerSearchBar,
  SelectionContainer,
  StyledDropSearchBar,
  StyledFilterNumber,
  StyledFilterTextSearchBar,
  TotalContainer,
  TotalLabel,
  TotalText
} from "./styles";
import { AiTwotoneHome } from "react-icons/ai";

const closeIcon = getIcon("times", "fal");
const CloseIcon = styled(closeIcon)`
  margin: 1px 3.625px;
  transform: translateY(-1px);
`;

const FilterSelectBody = (payload: {
  categoryName: string;
  selections: {
    [selection: string]: boolean;
  };
  handleCheckBoxChange: IFilterSelectProps["handleCheckboxFilterChange"];
  handleClearAll: (column: IColumn) => void;
  column: IColumn;
}) => {
  const [searchTerm, updateSearchTerm] = React.useState("");
  const theme = useTheme();
  const filterBasedOnSearchTerm = () => {
    if (searchTerm) {
      const results = new Fuse(_.keys(payload.selections), {}).search(
        searchTerm
      );
      return _.map(results, i => i.item);
    }
    return _.keys(payload.selections);
  };
  const updateSearch = _.debounce((newSearchTerm: string) => {
    updateSearchTerm(newSearchTerm);
  }, 500);
  // const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   updateSearch(e.target.value);
  // };
  const handleClearAll = () => {
    if (_.isFunction(payload.handleClearAll)) {
      payload.handleClearAll(payload.column);
    }
  };
  const handleRowCheckChange = (eventPayload: {
    value: string;
    isChecked: boolean;
  }) => {
    payload.handleCheckBoxChange({
      ...eventPayload,
      column: payload.column
    });
  };
  const getSearchContainer = (): JSX.Element | null => {
    if (_.keys(payload.selections).length > 4) {
      return (
        <CategorySearchContainer>
          <FilterSearch
            onChange={updateSearch}
            placeholder={`Search for ${payload.categoryName}`}
            searchContainerStyle={{ borderRadius: "0" }}
          />
        </CategorySearchContainer>
      );
    }
    return null;
  };
  return (
    <Flex flexDirection="column">
      <ClearAllContainer onClick={handleClearAll}>
        <CloseIcon />
        <span>Clear</span>
      </ClearAllContainer>
      {getSearchContainer()}
      <div
        style={{
          overflowY: "scroll",
          maxHeight: "306px",
          backgroundColor: theme.colors.filterBackground
        }}
      >
        {_.map(filterBasedOnSearchTerm(), selection => {
          return (
            <FilterSelectionRow
              label={selection}
              isChecked={payload.selections[selection]}
              onChange={handleRowCheckChange}
            />
          );
        })}
      </div>
    </Flex>
  );
};
class ElmTableSearchBar extends React.Component<
  IFilterSearchProps & { theme: any },
  IFilterSearchState
> {
  constructor(props: IFilterSearchProps) {
    super(props);
    this.state = {
      filterSelectedMap: this.getDefaultFilterSelected()
    };
  }
  public componentDidMount() {
    if (_.keys(this.state.filterSelectedMap)) {
      this.reportFilterChange();
    }
  }
  public getDefaultFilterSelected = () => {
    const filterColumns = this.getFilterColumns();
    if (filterColumns.length) {
      const savedFilters = this.getSavedFiltersForTable();

      /*
        An empty object means that we manually cleared filters.
        In that case, we save that as a user preference, not apply default.
      */
      if (JSON.stringify(savedFilters) === "{}") {
        return {};
      }

      if (savedFilters && _.keys(savedFilters).length) {
        return savedFilters;
      }
      const defaultSelectedMap = {};
      _.each(filterColumns, col => {
        if (_.isArray(col.filterable.defaultSelected)) {
          defaultSelectedMap[col.filterable.filterName] =
            col.filterable.defaultSelected;
        }
      });
      return defaultSelectedMap;
    }

    return {};
  };

  public getSavedFiltersForTable = () => {
    if (_.isString(this.props.bladeName) && this.props.bladeName) {
      if (_.isString(this.props.tableName) && this.props.tableName) {
        const savedDataString = getSavedDataForTable({
          type: "filters",
          bladeName: this.props.bladeName,
          tableName: this.props.tableName
        });
        if (savedDataString) {
          return JSON.parse(
            savedDataString
          ) as IFilterSearchProps["filterData"];
        }
      }
    }
    return null;
  };
  public saveFiltersForTable = () => {
    if (_.isString(this.props.bladeName) && this.props.bladeName) {
      if (_.isString(this.props.tableName) && this.props.tableName) {
        saveDataForTable(
          {
            tableName: this.props.tableName,
            bladeName: this.props.bladeName,
            type: "filters"
          },
          JSON.stringify(this.state.filterSelectedMap)
        );
      }
    }
  };
  public onChangeSearch = _.debounce((searchTerm: string) => {
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange(searchTerm);
    }
  }, 500);
  public handleChange = (value: string) => {
    if (_.isFunction(this.props.onChange)) {
      this.onChangeSearch(value);
    }
  };

  public getFilterColumns = () => {
    return _.filter(this.props.tableColumns, column =>
      _.isObject(column.filterable)
    );
  };
  public handleCheckboxFilterChange: IFilterSelectProps["handleCheckboxFilterChange"] = payload => {
    const filterName = payload.column.filterable.filterName;
    this.updateFilterSelectedMap({
      filterName,
      value: payload.value
    });
  };
  public reportFilterChange = () => {
    if (_.isFunction(this.props.onFilterChange)) {
      this.saveFiltersForTable();
      this.props.onFilterChange({
        filterValues: this.state.filterSelectedMap
        // filterColumns: this.state.filterColumns,
      });
    }
  };
  public clearAllFilterCategories = () => {
    this.setState({ filterSelectedMap: {} }, this.reportFilterChange);
  };
  public clearFilterCategory = (column: IColumn) => {
    const filterSelectedMap = _.cloneDeep(this.state.filterSelectedMap);
    delete filterSelectedMap[column.filterable.filterName];
    this.setState(
      {
        filterSelectedMap
      },
      this.reportFilterChange
    );
  };
  public updateFilterSelectedMap = (payload: {
    filterName: string;
    value: string;
  }) => {
    const filterSelectedMap = _.cloneDeep(this.state.filterSelectedMap);
    filterSelectedMap[payload.filterName] =
      filterSelectedMap[payload.filterName] || [];
    if (_.includes(filterSelectedMap[payload.filterName], payload.value)) {
      filterSelectedMap[payload.filterName] = _.without(
        filterSelectedMap[payload.filterName],
        payload.value
      );
    } else {
      filterSelectedMap[payload.filterName].push(payload.value);
      filterSelectedMap[payload.filterName] = _.uniq(
        filterSelectedMap[payload.filterName]
      );
    }
    if (!_.isArray(filterSelectedMap[payload.filterName])) {
      delete filterSelectedMap[payload.filterName];
    }
    if (
      _.isArray(filterSelectedMap[payload.filterName]) &&
      !filterSelectedMap[payload.filterName].length
    ) {
      delete filterSelectedMap[payload.filterName];
    }
    this.setState(
      {
        filterSelectedMap
      },
      this.reportFilterChange
    );
  };
  public removeCheckboxFilterOption = (payload: {
    label: string;
    value: string;
    filterName: string;
  }) => () => {
    this.updateFilterSelectedMap({
      filterName: payload.filterName,
      value: payload.value
    });
  };
  public hasSelectedFilters = () =>
    !!_.keys(this.state.filterSelectedMap).length;
  public renderCategoryDropDowns = () => {
    const categoryMap = {} as {
      [categoryName: string]: {
        [selectionName: string]: boolean;
      };
    };
    const filterColumns = this.getFilterColumns();
    let categoryName: string;
    let filters: string[];
    let selectedFilters: string[];
    _.each(filterColumns, column => {
      categoryName = column.Header;
      selectedFilters =
        this.state.filterSelectedMap[column.filterable.filterName] || [];
      filters = this.props.filterData[column.filterable.filterName];
      if (selectedFilters.length) {
        _.each(filters, filterValue => {
          categoryMap[categoryName] = categoryMap[categoryName] || {};
          categoryMap[categoryName][filterValue] = _.includes(
            this.state.filterSelectedMap[column.filterable.filterName],
            filterValue
          );
        });
      }
    });
    const getSelectedCount = (payload: { [key: string]: boolean }) => {
      return _.filter(_.keys(payload), key => payload[key]).length;
    };
    const handleClearAll = (column: IColumn) => {
      this.clearFilterCategory(column);
    };
    return (
      <Flex style={{ minWidth: "max-content" }}>
        {_.map(_.keys(categoryMap), category => {
          return (
            <StyledDropSearchBar
              // onVisibleChange={this.handleVisibleChange}
              // visible={this.state.isOpen}
              overlay={
                <Menu expandIcon={getCaretIcon("right")}>
                  <FilterSelectBody
                    selections={categoryMap[category]}
                    categoryName={category}
                    handleCheckBoxChange={this.handleCheckboxFilterChange}
                    handleClearAll={handleClearAll}
                    column={_.find(
                      this.getFilterColumns(),
                      c => c.Header === category
                    )}
                  />
                </Menu>
              }
              trigger={["click"]}
            >
              <FilterTextContainerSearchBar disabled={false} variant="">
                <StyledFilterTextSearchBar className="ant-dropdown-link">
                  {`${category}`}
                </StyledFilterTextSearchBar>
                <StyledFilterNumber>
                  {`(${getSelectedCount(categoryMap[category])})`}
                </StyledFilterNumber>
                {getCaretIcon("down")()}
              </FilterTextContainerSearchBar>
            </StyledDropSearchBar>
          );
        })}
      </Flex>
    );
  };
  public renderCheckboxSelections = () => {
    const checkboxSelections = [] as {
      label: string;
      value: string;
      filterName: string;
    }[];
    const filterColumns = this.getFilterColumns();
    _.each(filterColumns, column => {
      _.each(
        this.state.filterSelectedMap[column.filterable.filterName],
        value => {
          checkboxSelections.push({
            filterName: column.filterable.filterName,
            label: column.Header,
            value
          });
        }
      );
    });
    return (
      <SelectionContainer style={{ overflowX: "scroll" }}>
        {_.map(checkboxSelections, filterSelection => {
          return (
            <FilterSelectionOptionBox
              key={filterSelection.label + filterSelection.value}
              onClick={this.removeCheckboxFilterOption(filterSelection)}
            >
              <FilterSelectionOptionText>
                {`${filterSelection.label}: `}
              </FilterSelectionOptionText>
              <FilterSelectionOptionText>
                {filterSelection.value}
              </FilterSelectionOptionText>
              <FilterSelectionCloseIcon />
            </FilterSelectionOptionBox>
          );
        })}
      </SelectionContainer>
    );
  };
  public handleClearAllFilters = () => {
    this.clearAllFilterCategories();
  };
  public renderFilterSelect = () => {
    const columns = this.getFilterColumns();
    if (columns.length) {
      return (
        <FilterSelect
          columns={this.getFilterColumns()}
          filterData={this.props.filterData}
          filterSelectedMap={this.state.filterSelectedMap}
          handleCheckboxFilterChange={this.handleCheckboxFilterChange}
          handleClearAllFilterCategories={this.clearAllFilterCategories}
          handleClearAllFilters={this.handleClearAllFilters}
        />
      );
    }
    return null;
  };
  public renderTotal = () => {
    if (!this.props.showTotal) {
      return false;
    }
    return (
      <TotalContainer>
        <TotalLabel>Total:</TotalLabel>
        <TotalText>{this.props.count}</TotalText>
      </TotalContainer>
    );
  };

  public render() {
    const { theme } = this.props;

    return (
      <Flex flexDirection="column" flex={1} style={{ marginBottom: "0px" }}>
        <FilterSectionContainer>
          <SearchBar
            placeholder={"Search for keyword"}
            onSearch={this.handleChange}
            className={"elm-table-search-bar"}
          />
          {/* {this.renderCheckboxSelections()} */}
          {this.renderCategoryDropDowns()}
          {this.renderFilterSelect()}
        </FilterSectionContainer>
      </Flex>
    );
  }
}

export default function TableSearchBar(props) {
  const theme = useTheme();

  return <ElmTableSearchBar {...props} theme={theme} />;
}

//export default ElmTableSearchBar;
