import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Flex } from "reflexbox/styled-components";
import { searchInObjectArray } from "../../../utils";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import { LoaderContainer } from "components/helpers";
import {
  productFeatureGroupsTableQuery,
  productFeatureGroupsTableQueryResponse
} from "./__generated__/productFeatureGroupsTableQuery.graphql";
import { IGeneratedTableProps } from "utils/tableGenerator";
import ElmTable, { IColumn, IElmTableProps } from "components/elmTable";
import _ from "lodash";
import { renderMultipleLabels } from "../featureGroupsTable";
import { shallowEqual } from "fast-equals";
import { EmptyTableAction } from "Products/blades/Product";

enum FeatureGroupsTableColumns {
  Group = "Group",
  Features = "Features",
  Actions = "Actions"
}
export const FeatureGroupsTableColumnConfig: Record<
  FeatureGroupsTableColumns,
  IColumn
> = {
  Group: {
    Header: "Group",
    accessor: "name",
    sortKey: null,
    disableSortBy: true
  },
  Features: {
    Header: "Features",
    accessor: "components",
    sortKey: null,
    disableSortBy: true,
    cellRenderer: payload => renderMultipleLabels(payload.cellData)
  },
  Actions: {
    Header: "Actions",
    accessor: "",
    cellRenderer: () => "",
    sortKey: null,
    disableSortBy: true,
    width: 0.1
  }
};

const graphqlQuery = graphql`
  query productFeatureGroupsTableQuery($id: ID!) {
    product(id: $id) {
      featureGroupsCount
      featureGroups {
        nodes {
          name
          id
          features {
            nodes {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface IFeatureGroupsTableProps
  extends Omit<IElmTableProps, "columns" | "data">,
    Pick<
      IGeneratedTableProps,
      "bladeScope" | "setRefreshFn" | "refreshKey" | "removeRefreshFn"
    > {
  addNewGroup: () => void;
}

interface state {
  search_term?: string;
}

class ProductFeatureGroupsTable extends React.Component<
  IFeatureGroupsTableProps & {
    result: productFeatureGroupsTableQueryResponse;
    retry: () => void;
  },
  state
> {
  state: Readonly<state> = {
    search_term: ""
  };
  public componentDidMount() {
    this.setRefreshFn();
  }
  public componentWillUnmount() {
    this.props.removeRefreshFn(this.props.refreshKey);
  }
  public componentDidUpdate(prevProps: typeof this.props) {
    if (!shallowEqual(this.props.retry, prevProps.retry)) {
      this.setRefreshFn();
    }
  }
  public setRefreshFn = () => {
    if (_.isFunction(this.props.setRefreshFn)) {
      this.props.setRefreshFn(this.props.retry);
    }
  };
  public render = () => {
    const nodes = _.get(this.props.result, "product.featureGroups.nodes", []);

    let featureGroup: { id: string; name: string; components: string[] };
    const transform = _.reduce(
      nodes,
      (all, node) => {
        featureGroup = {
          id: node.id,
          name: node.name,
          components: []
        };
        all.push(featureGroup);
        _.each(node.features.nodes, feature => {
          featureGroup.components.push(feature.name);
        });
        return all;
      },
      []
    );

    const filteredData = this.state.search_term
      ? searchInObjectArray(this.state.search_term, transform)
      : transform;

    return (
      <ElmTable
        {...this.props}
        data={filteredData}
        totalCount={nodes?.length}
        onCriteriaChange={({ search_term }) => this.setState({ search_term })}
        columns={[
          ...Object.keys(FeatureGroupsTableColumnConfig).map(
            key =>
              FeatureGroupsTableColumnConfig[key as FeatureGroupsTableColumns]
          )
        ]}
        useDefaultAddButton={this.props.addNewGroup}
        onRowClick={this.props.onRowClick}
        renderEmptyTableAction={() => (
          <EmptyTableAction
            message={"Product has no featur groups"}
            addButtonCallback={this.props.addNewGroup}
            actionMessage="Add new group?"
          />
        )}
      />
    );
  };
}

const RenderQuery = (props: IFeatureGroupsTableProps) => {
  const renderTable = (payload: {
    error: Error;
    props: productFeatureGroupsTableQueryResponse;
    retry: () => void;
  }) => {
    return payload?.props ? (
      <ProductFeatureGroupsTable
        {...props}
        result={payload.props}
        retry={payload.retry}
      />
    ) : (
      <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
        {LoaderContainer()}
      </Flex>
    );
  };
  return (
    <QueryRenderer<productFeatureGroupsTableQuery>
      environment={relayEnvironment}
      variables={{
        id: props.bladeScope
      }}
      query={graphqlQuery}
      render={renderTable}
    />
  );
};

export default RenderQuery;
