import { Checkbox } from "antd";

import styled from "styled-components";

const ElmCheckBox = styled(Checkbox)`
  & .ant-checkbox {
    //font-size: 13px;
    line-height: 13px;
    top: 0 !important;
  }
  & .ant-checkbox-wrapper {
    //align-items: center !important;
    font-size: 13px !important;
  }
  & .ant-checkbox + span {
    padding-right: unset;
  }
  .ant-checkbox-disabled + span {
    color: ${props => props.theme.colors.greyish};
  }
  & .ant-checkbox-inner {
    width: 12px;
    height: 12px;
    line-height: 13px;
    border-color: inherit;
    border-radius: 2px;
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4285f4 !important;
    border: none;
  }
  & .ant-checkbox-checked .ant-checkbox-inner::after {
    border-top: 0;
    border-left: 0;
  }
  & .ant-checkbox-inner::after {
    top: 45%;
    left: 22%;
    width: 4.5px;
    height: 8.5px;
  }
  & .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #bebdbc !important;
  }
  & .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white !important;
  }
`;

export { ElmCheckBox };

export default ElmCheckBox;
