/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AdminsTableQueryVariables = {
  id: string;
  blade_scope: string;
};
export type AdminsTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"fragments_adminsTable">;
};
export type AdminsTableQuery = {
  readonly response: AdminsTableQueryResponse;
  readonly variables: AdminsTableQueryVariables;
};

/*
query AdminsTableQuery(
  $id: ID!
  $blade_scope: String!
) {
  ...fragments_adminsTable_nf0ro
}

fragment fragments_adminsTable_nf0ro on Query {
  company(id: $id) {
    companySystemUsers(first: 20, bladeScope: $blade_scope, sortColumn: ["system_users.email"], sortDirection: ["ASC"]) {
      edges {
        node {
          name
          email
          lastLogin
          avatarUrl
          thumbUrl
          id
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    companySystemUsersCount
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "blade_scope"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    } as any,
    v2 = {
      kind: "Variable",
      name: "id",
      variableName: "id"
    } as any,
    v3 = [
      {
        kind: "Variable",
        name: "bladeScope",
        variableName: "blade_scope"
      } as any,
      {
        kind: "Literal",
        name: "first",
        value: 20
      } as any,
      {
        kind: "Literal",
        name: "sortColumn",
        value: ["system_users.email"]
      } as any,
      {
        kind: "Literal",
        name: "sortDirection",
        value: ["ASC"]
      } as any
    ],
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: "Fragment",
      metadata: null,
      name: "AdminsTableQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "blade_scope",
              variableName: "blade_scope"
            },
            v2 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "fragments_adminsTable"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: "Operation",
      name: "AdminsTableQuery",
      selections: [
        {
          alias: null,
          args: [v2 /*: any*/],
          concreteType: "Company",
          kind: "LinkedField",
          name: "company",
          plural: false,
          selections: [
            {
              alias: null,
              args: v3 /*: any*/,
              concreteType: "CompanySystemUserConnection",
              kind: "LinkedField",
              name: "companySystemUsers",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "CompanySystemUserEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "CompanySystemUser",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "name",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "email",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "lastLogin",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "avatarUrl",
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "thumbUrl",
                          storageKey: null
                        },
                        v4 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: v3 /*: any*/,
              filters: ["bladeScope", "search", "sortColumn", "sortDirection"],
              handle: "connection",
              key: "adminsTable_companySystemUsers",
              kind: "LinkedHandle",
              name: "companySystemUsers"
            },
            {
              alias: null,
              args: null,
              kind: "ScalarField",
              name: "companySystemUsersCount",
              storageKey: null
            },
            v4 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "4cbdd57eea6573a3ee1e7ea6364a9add",
      id: null,
      metadata: {},
      name: "AdminsTableQuery",
      operationKind: "query",
      text:
        'query AdminsTableQuery(\n  $id: ID!\n  $blade_scope: String!\n) {\n  ...fragments_adminsTable_nf0ro\n}\n\nfragment fragments_adminsTable_nf0ro on Query {\n  company(id: $id) {\n    companySystemUsers(first: 20, bladeScope: $blade_scope, sortColumn: ["system_users.email"], sortDirection: ["ASC"]) {\n      edges {\n        node {\n          name\n          email\n          lastLogin\n          avatarUrl\n          thumbUrl\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    companySystemUsersCount\n    id\n  }\n}\n'
    }
  } as any;
})();
(node as any).hash = "b25a413fcee666cac0558d20e93bf846";
export default node;
