import { CardTitle } from "../../components/elmChart/styles";
import React, { useState } from "react";
import { Flex } from "reflexbox";
import TimeFilter from "../../components/analyticsComponent/filters/timeFilter";
import { Col, Row } from "antd";
import styled from "styled-components";
import { getIcon } from "../icons";

export const ColStyled = styled(Col)`
  color: white;
  background-color: transparent;
`;

const ColStat = styled(ColStyled)((props: { isDense: boolean }) => ({
  marginBottom: props?.isDense ? 0 : 12
}));

interface ISingleStatus {
  title: string;
  valueTrend: "up" | "down";
  currency?: string;
  value: string;
  suffix?: string;
  styleOverride?: React.CSSProperties;
}

export const CaretUp = styled(getIcon("AiFillCaretUp"))`
  color: #5eff5e;
  width: 13px;
`;
export const CaretDown = styled(getIcon("AiOutlineCaretDown"))`
  color: #ef8879;
  width: 13px;
`;
export const RenderStatus: React.FC<ISingleStatus> = ({
  title,
  valueTrend,
  currency,
  value,
  suffix,
  styleOverride
}) => {
  return (
    <div
      style={{
        height: "48px",
        width: "150px",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        ...styleOverride
      }}
    >
      <div style={{ flex: 1 }}>
        <span style={{ fontSize: "13px", lineHeight: "13px" }}>
          {title}{" "}
          {valueTrend && (valueTrend === "up" ? <CaretUp /> : <CaretDown />)}
        </span>
      </div>
      <div style={{ flex: 1 }}>
        {currency && (
          <span
            style={{ fontSize: "24px", lineHeight: "32px" }}
          >{`${currency}${value} ${suffix || ""}`}</span>
        )}
        {!currency && (
          <span
            style={{ fontSize: "24px", lineHeight: "32px" }}
          >{`${value}${suffix || ""}`}</span>
        )}
      </div>
    </div>
  );
};
interface IElmStats {
  height: number;
  isGridDense?: boolean;
}
const ElmStats: React.FC<IElmStats> = ({ height, isGridDense }) => {
  const [timeRange, setTimeRange] = useState<string>("months3");

  const handleChange = (value: string) => {
    setTimeRange(value);
  };

  return (
    <div
      style={{
        height: `${height || 250}px`,
        width: "100%",
        borderRadius: "4px",
        backgroundColor: "#0F649A",
        overflowY: "scroll",
        overflowX: "hidden"
      }}
    >
      <div
        style={{
          color: "black",
          padding: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <CardTitle style={{ color: "#ffffff", lineHeight: "10px" }}>
          Key statistics
        </CardTitle>
        {!isGridDense && (
          <Flex>
            {/* {!props.hideTimeRangeFilter && ( */}
            <TimeFilter
              useTransparentVariant={true}
              timeRange={timeRange}
              handleChange={handleChange}
            />
            {/* )} */}
          </Flex>
        )}
      </div>
      <div
        id={"quick_stats"}
        style={{
          width: "100%",
          height: `${(height || 250) - 64}px`,
          //maxHeight: "400px",
          paddingLeft: "20px"
        }}
      >
        <Row
          gutter={isGridDense ? 0 : 10}
          style={{
            marginTop: "0px",
            paddingBottom: "10px",
            marginLeft: "0px",
            marginRight: "0px"
          }}
        >
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            {/* md={6} lg={6} xl={6} xxl={6} */}
            <RenderStatus
              title={"Licensed users"}
              valueTrend={"up"}
              currency={""}
              value={"728"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"Concurrent"}
              valueTrend={"up"}
              currency={""}
              value={"347"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"Unrestricted"}
              valueTrend={"down"}
              currency={""}
              value={"78"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"Named user"}
              valueTrend={"down"}
              currency={""}
              value={"40"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"Churn"}
              valueTrend={"up"}
              currency={""}
              value={"9.1"}
              suffix={"%"}
            />
          </ColStat>
          {/* </Row> */}

          {/* <Row
          gutter={[2, 0]}
          style={{ marginTop: "32px", marginLeft: "0px", marginRight: "0px" }}
        > */}
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"Active companies"}
              valueTrend={"up"}
              value={"15"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"Active licenses"}
              valueTrend={"down"}
              value={"127"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"Active users"}
              valueTrend={"down"}
              value={"452"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"New registrations"}
              valueTrend={"up"}
              value={"57"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"Churned companies"}
              valueTrend={"up"}
              value={"10"}
            />
          </ColStat>
          {/* </Row>

        <Row
          gutter={[2, 0]}
          style={{ marginTop: "32px", marginLeft: "0px", marginRight: "0px" }}
        > */}
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"New subscriptions"}
              valueTrend={"up"}
              value={"15"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus title={"Upgrades"} valueTrend={"down"} value={"70"} />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"Downgrades"}
              valueTrend={"down"}
              value={"22"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"On-going trials"}
              valueTrend={"up"}
              value={"5"}
            />
          </ColStat>
          <ColStat isDense={isGridDense} xs={24} sm={12} md={8} lg={6}>
            <RenderStatus
              title={"Churned users"}
              valueTrend={"up"}
              value={"68"}
            />
          </ColStat>
        </Row>
      </div>
    </div>
  );
};

export default ElmStats;
