import React from "react";
import { Flex } from "reflexbox";
import { useTheme } from "styled-components";
import { NoData } from "../../assets";
interface INoData {
  message?: string;
}
const NoDataPlaceholder: React.FC<INoData> = ({ message }) => {
  const theme = useTheme();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      minHeight="inherit"
      justifyContent="center"
    >
      <img style={{ height: 32 }} src={NoData} alt="No data available" />
      <span
        style={{
          color: theme.colors.warmGrey,
          fontSize: theme.fontSizes.ySmall,
          marginTop: 16
        }}
      >
        {message || "We can't find any data"}
      </span>
    </Flex>
  );
};

export default NoDataPlaceholder;
