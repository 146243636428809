/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type usersShortListTable_users = {
  readonly usersShortListTableCount: number;
  readonly usersShortListTableUsers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly email: string | null;
        readonly active: boolean;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $refType": "usersShortListTable_users";
};
export type usersShortListTable_users$data = usersShortListTable_users;
export type usersShortListTable_users$key = {
  readonly " $data"?: usersShortListTable_users$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"usersShortListTable_users">;
};

const node: ReaderFragment = (function() {
  var v0 = [
    {
      kind: "Variable",
      name: "bladeScope",
      variableName: "blade_scope"
    } as any,
    {
      kind: "Variable",
      name: "filterColumn",
      variableName: "filterColumn"
    } as any,
    {
      kind: "Variable",
      name: "filterOp",
      variableName: "filterOp"
    } as any,
    {
      kind: "Variable",
      name: "filterValues",
      variableName: "filterValues"
    } as any,
    {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any
  ];
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "blade_scope"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: ["users.active"],
        kind: "LocalArgument",
        name: "filterColumn"
      },
      {
        defaultValue: [["="]],
        kind: "LocalArgument",
        name: "filterOp"
      },
      {
        defaultValue: [["true"]],
        kind: "LocalArgument",
        name: "filterValues"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["usersShortListTableUsers"]
        }
      ]
    },
    name: "usersShortListTable_users",
    selections: [
      {
        alias: "usersShortListTableCount",
        args: v0 /*: any*/,
        kind: "ScalarField",
        name: "usersCount",
        storageKey: null
      },
      {
        alias: "usersShortListTableUsers",
        args: v0 /*: any*/,
        concreteType: "UserConnection",
        kind: "LinkedField",
        name: "__UsersShortListTable_usersShortListTableUsers_connection",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "UserEdge",
            kind: "LinkedField",
            name: "edges",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "User",
                kind: "LinkedField",
                name: "node",
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "id",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "name",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "email",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "active",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "__typename",
                    storageKey: null
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "cursor",
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: "PageInfo",
            kind: "LinkedField",
            name: "pageInfo",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "hasNextPage",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "endCursor",
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "b927dc0ec4e9d914dbce934745181d52";
export default node;
