import * as _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import styled, { useTheme } from "styled-components";
import { QueryRenderer } from "react-relay";
import { Flex } from "reflexbox/styled-components";
import { getEnvironment } from "../../../api/relay";
import { getGateway } from "../../../api";
import { appConnect, appDispatch } from "../../../store/appConnect";
import moment from "moment";
import {
  AllowedChartBladeTypes,
  IBladeBaseProps,
  IOpenBladeFromChart,
  IOpenLicenseBlade
} from "../../../components/bladeManager/types";
import { ITab } from "../../../components/tabView";
import {
  AnalyticsCarousel,
  BladeTemplate,
  ElmButton,
  FeaturesAndGroups,
  LicenseAttribute,
  TabView,
  LicenseReleaseTable,
  LicenseActiveSessionsTable,
  BladeTitle,
  getIcon,
  ElmTable,
  ElmToggle,
  EntitlementsComponentsList
} from "../../../components";
import { LicenseChangeLogTable } from "../../components/licenseChangeLogTable";
import { LicenseHistoryTable } from "../../components/licenseHistoryTable";
import {
  getTermDetails,
  licenseTypeToName,
  updateBladeUrlState
} from "../../../utils";
import {
  LicenseQuery,
  LicenseQueryResponse
} from "./__generated__/LicenseQuery.graphql";
import { AnalyticsComponentProps } from "../../../components/analyticsComponent";
import { deepEqual } from "fast-equals";
import { AnalyticsCarouselProps } from "../../../components/analyticsCarousel";
import { AutoSizer } from "react-virtualized";
import {
  topUsers,
  topComponents,
  sessionCount,
  activeUserCount
} from "../../../utils/commonAnalytics";
import { decodeProductTypeId } from "Licenses/components/licenseEnforcementSelector";
import { commercialModel, LicenseActionStatus } from "Licenses/common";
import { Tooltip } from "antd";
import AttributeActionsDropdown from "components/bladeAttributeActionsDropdown";
import TerminateLicenseDialog from "Licenses/components/licenseAttribute/licenseTerminateApproval";
import { renderCurrency } from "Licenses/components/licenseHistoryTable";
import { enforcementModel } from "../AddLicense";
import NoDataPlaceholder from "components/elmChart/noDataPlaceholder";
import {
  renderErrorNotification,
  renderFailureNotification,
  renderSuccessNotification
} from "utils/ant-notifications";
import { checkPermissions } from "components/helpers";
import { sharedTabStyle } from "blades/Analytics/shared";
import LoadingWrapper from "components/elmChart/loadingWrapper";
import { LicenseComponents } from "./licenseComponents";
import { LicenseComponentEntitlements } from "./licenseComponentEntitlemets";
import { hooksLicenseComponentsAndEntitlementsQueryResponse } from "../AddLicense/__generated__/hooksLicenseComponentsAndEntitlementsQuery.graphql";
import {
  LicenseUsersTable,
  LicenseUsersTableType
} from "Licenses/components/licenseUsersTable";
import { ElmAppImage } from "utils/AppImageDictionary/ElmAppImage";
import { REFRESH_KEY } from "components/bladeManager/entityRefreshMap";

type ComponentNode = hooksLicenseComponentsAndEntitlementsQueryResponse["license"]["components"]["nodes"][number];
type VersionRequirementNode = ComponentNode["licenseComponentRequirements"][number];

graphql`
  fragment LicenseComponentsListTable_components on Query
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 6 }
      cursor: { type: "String" }
      #search: { type: "String", defaultValue: null }
      id: { type: "ID!" }
    ) {
    license(id: $id) {
      product {
        components(first: $count, after: $cursor)
          @connection(key: "LicenseComponentsListTable_components") {
          edges {
            node {
              id
              name
              componentRequirementsCount
              componentEntitlementAssignmentCount
              componentGroup {
                name
                id
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    }
  }
`;

const graphqlQuery = graphql`
  query LicenseQuery($id: ID!, $productId: ID!) {
    #$blade_scope: String!,
    productComponents: product(id: $productId) {
      components {
        nodes {
          id
        }
      }
      componentGroups {
        nodes {
          id
        }
      }
    }
    license(id: $id) {
      id
      type {
        id
      }
      createdAt
      isVendor
      owner {
        name
        id
      }
      commercialModel {
        id
        name
      }
      activeEntitlements {
        nodes {
          id
          value
          latestTerm {
            startDate
            endDate
          }
          instanceCount
          sessionCount
          userCount
        }
      }
      product {
        id
        name
        iconUrl
        componentsCount
        featuresCount
      }
      # isTrial
      # approved
      licenseActions {
        nodes {
          id
          action
          status
          requireApproval
          vendorSystemUser {
            id
            name
            email
          }
        }
      }
      key
      latestTerm {
        startDate
        endDate
      }
      # usersCount
      componentsCount
      featuresCount
      # totalSeatCount
      releaseChannelsCount
      # releasesCount
      # termsCount
    }
  }
`;

const ProductTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;

  /* Black - darker */

  color: ${props => props.theme.colors.black};
`;

export const AnalyticsAction = ({
  data,
  analyticsAction
}: {
  data: LicenseUsersTableType;
  analyticsAction: (user: LicenseUsersTableType) => void;
}) => {
  const theme = useTheme();

  return (
    <div onClick={e => e.stopPropagation()} style={{ padding: "2px" }}>
      <ElmButton
        variance={"icon-button"}
        colorVariance={"subtle"}
        className="darkModeTransparentBtn"
        icon={"chart-mixed"}
        iconPrefix={"far"}
        label={""}
        style={{ color: theme.colors.black }}
        onClick={() => analyticsAction(data)}
      />
    </div>
  );
};

export interface ILicenseBladeProps extends IBladeBaseProps<IOpenLicenseBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}

interface ILicenseBladeState {
  mode: "edit" | "normal";
  activeTabIndex: number;
  updatePayload: {
    term: {
      startMoment: moment.Moment;
      endMoment: moment.Moment;
    };
  };
  bladeMountTime: moment.Moment;
}

class LicenseBlade extends React.Component<
  ILicenseBladeProps & {
    result: LicenseQueryResponse;
    retry: () => void;
    theme: any;
  },
  ILicenseBladeState
> {
  public state: ILicenseBladeState = {
    mode: "normal",
    activeTabIndex: _.get(this.props, "routeData.tabIndex", 0),
    updatePayload: {
      term: {
        startMoment: null,
        endMoment: null
      }
    },
    bladeMountTime: moment().utc()
  };
  public shouldComponentUpdate(
    nextProps: ILicenseBladeProps & { result: LicenseQueryResponse },
    nextState: ILicenseBladeState
  ) {
    return (
      !deepEqual(this.state, nextState) ||
      !deepEqual(this.props.routeData, nextProps.routeData) ||
      !deepEqual(this.props.result, nextProps.result)
    );
  }
  public navAway = () => {
    this.props.openBlade({
      routeName: "Customer portal",
      fromBladeIndex: this.props.index,
      route: "Overview",
      routeData: null
    });
  };
  public componentDidMount() {}
  public componentDidUpdate(prevProps: ILicenseBladeProps) {}
  public getAnalyticsQuery = (
    activeTabIndex: Number
  ): AnalyticsComponentProps => {
    const startTime = this.state.bladeMountTime.clone();
    switch (activeTabIndex) {
      case 0: // Components
        return topComponents(this.props.routeData.id, startTime, true);
      case 2: // Users
        return topUsers(this.props.routeData.id, startTime, true);
      case 3: // Active users
        return activeUserCount(this.props.routeData.id, startTime, true);
      default:
        return sessionCount(this.props.routeData.id, startTime, true);
    }
  };

  public navToUserBlade = (user: LicenseUsersTableType) => {
    this.props.openBlade({
      route: "User",
      routeName: `${user.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: user.id,
        name: user.name
        // productId: ''
      }
    });
  };

  public updateActiveTab = (activeTabIndex: number) => {
    updateBladeUrlState(this.props, activeTabIndex);

    this.setState({
      activeTabIndex
    });
  };
  public navToProductBlade = (product: { id: string; name: string }) => {
    this.props.openBlade({
      route: "Product",
      routeName: `${product.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: product.id
        // productId: ''
      }
    });
  };
  public navToCompanyBlade = (company: { id: string; name: string }) => {
    this.props.openBlade({
      route: "Company",
      routeName: `${company.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: company.id
        // productId: ''
      }
    });
  };
  public openBladeFromChart = (payload: IOpenBladeFromChart) => {
    if (!payload?.id || !AllowedChartBladeTypes.includes(payload.bladeType)) {
      return;
    }
    this.props.openBlade({
      route: payload.bladeType,
      routeName: `${payload.name}`,
      fromBladeIndex: this.props.index,
      routeData: {
        id: payload.id
      }
    });
  };
  public getChartIndex = () => {
    switch (this.state.activeTabIndex) {
      case 2: // Users
        return 2;
      case 0: // Components
        return 0;
      case 3: // History
        return 3;
      default:
        return 0;
    }
  };
  public getAnalyticsCarouselProps = (
    res: LicenseQueryResponse
  ): AnalyticsCarouselProps => {
    let analyticsSlides = [];
    if (res && res.license) {
      if (
        res.license.componentsCount > 0 &&
        res.license.product.componentsCount > 0
      ) {
        analyticsSlides.push(this.getAnalyticsQuery(0));
      }
      _.each([2, 3, -1], i => {
        analyticsSlides.push(this.getAnalyticsQuery(i));
      });
    }
    return {
      analyticsSlides,
      currentIndex: this.getChartIndex(),
      openBlade: this.openBladeFromChart
    };
  };
  public renderLicenseInfo = () => {
    // if (!payload.props) {
    //   this.props.showScreenLoader();
    //   return null;
    // }
    // this.props.hideScreenLoader();
    const res = this.props.result;
    const license = _.get(res, "license");

    const commercialModelName = _.get(
      license,
      "commercialModel.name",
      "Classic"
    );

    const {
      isPending,
      requestedBy,
      licenseId,
      licenseActionId
    } = this.getLicenseTerminateStatus();

    return (
      <BladeTemplate
        bladeIndex={this.props.index}
        title={"License"}
        bladeType="License"
        bladeTypeName={commercialModelName}
        closeBlade={this.props.closeBlade}
        //topAccentColor={"mango"}
        accentColor={"mango"}
        renderLeftSideHeader={this.renderLeftSideHeader}
        renderRightSideHeader={this.renderRightSideHeader}
        setRefreshFn={this.props.setRefreshFn}
        refreshFn={this.props.retry}
        loading={!res}
      >
        <Flex
          style={{
            marginBottom: "5px",
            width: "100%"
          }}
        >
          <LicenseAttribute
            id={this.props.routeData?.id}
            isVendor={this.props.isVendor}
            onClickProduct={this.navToProductBlade}
            onClickCompany={this.navToCompanyBlade}
            setRefreshFn={this.props.setEntityRefreshFn(
              REFRESH_KEY.LicenseAttribute
            )}
            refreshKey={REFRESH_KEY.LicenseAttribute}
            removeRefreshFn={this.props.removeRefreshFn}
            permissions={["modify_licenses", "request_modify_licenses"]}
          />
        </Flex>
        {/* _.get(this.props, "routeData.licenseAction", "") ===
          licenseActions.terminate_license */}
        {isPending ? (
          <TerminateLicenseDialog
            permissions={["modify_licenses"]}
            requestedBy={requestedBy}
            licenseId={licenseId}
            licenseActionId={licenseActionId}
            refreshAllOpenBlades={this.props.refreshAllOpenBlades}
            openDialog={this.props.openDialog}
            closeDialog={() => this.props.closeDialog("ConfirmationDialog")}
          />
        ) : null}
        <Flex
          style={{
            marginBottom: "35px",
            paddingLeft: "21px",
            paddingRight: "21px"
          }}
        >
          <AnalyticsCarousel {...this.getAnalyticsCarouselProps(res)} />
        </Flex>
        {this.renderTabs()}
      </BladeTemplate>
    );
  };

  // public renderQueryRenderer = () => {
  //   return (
  //     <QueryRenderer<LicenseQuery>
  //       environment={relayEnvironment}
  //       variables={{
  //         id: this.props.routeData.id,
  //         blade_scope: this.props.routeData.id,
  //       }}
  //       query={graphqlQuery}
  //       render={this.renderLicenseInfo}
  //     />
  //   );
  // };
  public renderLeftSideHeader = () => {
    if (!this.props.result || !this.props.result.license) {
      return null;
    }
    const license = this.props.result.license;
    // const CompanyLink = getLink({
    //   linkType: 'Company',
    //   label: license.owner.name,
    //   onClick: () =>
    //     this.props.openBlade({
    //       fromBladeIndex: this.props.index,
    //       route: 'Company',
    //       routeName: license.owner.name,
    //       routeData: {
    //         id: license.owner.id,
    //       },
    //     }),
    // });
    return (
      <Flex style={{ alignItems: "center" }}>
        <BladeTitle style={{ marginRight: "10px" }}>
          {license.owner.name}
        </BladeTitle>
        <div
          style={{
            fontSize: "24px",
            color: this.props.theme.colors.textPrimary
          }}
        >
          /
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              height: "24px",
              width: "24px",
              marginRight: "10px",
              marginLeft: "10px"
            }}
          >
            <ElmAppImage
              id={this.props.routeData?.id || license?.product?.name}
              url={license?.product?.iconUrl}
              name={license?.product?.name}
              size={24}
            />
          </div>
          <ProductTitle>{`${license.product.name}`}</ProductTitle>
        </div>
      </Flex>
    );
  };
  public navigateToAnalytics = () => {
    this.props.openBlade({
      route: "Analytics",
      routeName: "License Analytics",
      routeData: {
        type: "License",
        title: `${this.props.result.license.owner.name} / ${this.props.result.license.product.name}`,
        id: this.props.routeData.id,
        allTimeStartDate: `${this.props.result?.license?.createdAt}`
      },
      fromBladeIndex: this.props.index
    });
  };
  public enterEditMode = () => {
    this.setState({ mode: "edit" });
  };
  public enterNormalMode = () => {
    this.setState({ mode: "normal" });
  };
  public toggleBladeMode = () => {
    this.state.mode === "edit" ? this.enterNormalMode() : this.enterEditMode();
  };
  public updateLicenseTerm = async () => {
    const gateway = getGateway();
    return gateway.request.newLicenseTerm({
      termable_type: "License",
      termable_id: this.props.routeData.id,
      start_date: !moment.isMoment(this.state.updatePayload.term.startMoment)
        ? moment().format()
        : this.state.updatePayload.term.startMoment.format(),
      end_date: this.state.updatePayload.term.endMoment.format()
    });
  };
  public updateLocalTerm = (term: {
    startMoment: moment.Moment;
    endMoment: moment.Moment;
  }) => {
    this.setState({
      updatePayload: {
        ...this.state.updatePayload,
        term
      }
    });
  };
  public updateLicense = async () => {
    if (this.state.updatePayload.term) {
      const res = await this.updateLicenseTerm();
    }
    this.toggleBladeMode();
    this.props.refreshAllOpenBlades();
  };

  public showTerminateDialog = (havePermission?: boolean) => {
    this.props.openDialog("ConfirmationDialog", {
      description: "key",
      entityType: "terminate",
      title: "Terminate license ?",
      warningMessage: (
        <Flex flexDirection="column" flex={1} marginTop={"6px"}>
          <Flex marginBottom={"16px"}>
            {havePermission
              ? "Terminating the license will make it inactive effective immediately."
              : "Terminating the license will make it inactive after administrator approves actions."}
          </Flex>
          <Flex marginBottom={"16px"}>
            To reactivate it, edit the license and use the "Renew term" page to
            create a new valid term.
          </Flex>
        </Flex>
      ),
      hasComment: true,
      isSimpleConfirmation: true,
      name: this.props.result.license.key.slice(0, 5),
      onConfirm: comment => {
        const gateway = getGateway();
        const licenseId = _.get(this.props, "result.license.id");
        gateway.request["deactivate_license"]({
          license_id: licenseId,
          comment
        })
          .then(res => {
            if (!res.error) {
              renderSuccessNotification("Requested License terminate !");
              this.props.closeDialog("ConfirmationDialog");
              this.props.refreshAllOpenBlades();
            }
          })
          .catch(err => {
            renderFailureNotification("License termination request failed !");
          });
      }
    });
  };

  public getLicenseTerminateStatus() {
    let isPending = false;
    let requestedBy = "";
    let licenseId = "";
    let licenseActionId = "";
    this.props.result?.license?.licenseActions?.nodes?.forEach(value => {
      if (value?.action === "deactivate_license" && value?.status === 0) {
        isPending = true;
        licenseId = this.props.result.license.id;
        licenseActionId = value.id;
      }
      if (value?.vendorSystemUser?.email) {
        requestedBy = value.vendorSystemUser.email;
      }
      if (value?.vendorSystemUser?.name) {
        requestedBy = value.vendorSystemUser.name;
      }
    });
    return { isPending, requestedBy, licenseId, licenseActionId } as const;
  }

  public renderRightSideHeader = () => {
    const showDeleteDialog = () => {
      this.props.openDialog("ConfirmationDialog", {
        description: "key",
        entityType: `"delete"`,
        title: "Delete license ?",
        warningMessage: (
          <Flex flexDirection="column" flex={1} marginTop={"6px"}>
            <Flex marginBottom={"16px"}>
              Deleting is an irreversible process and you will not be able to
              find or take actions on this license once it is deleted.
            </Flex>
            <Flex marginBottom={"36px"}>
              Are you sure you want to delete it? Type in the word "delete" to
              confirm deletion.
            </Flex>
          </Flex>
        ),
        isSimpleConfirmation: false,
        name: "delete", //this.props.result.license.key.slice(0, 5),
        onConfirm: () => {
          this.props.gateway.request
            .delete_license(null, {
              id: this.props.routeData.id
            })
            .then(res => {
              if (res.error) {
                console.error(res.error);
              } else {
                this.props.closeBlade({
                  route: "License",
                  fromBladeIndex: this.props.index
                });
                this.props.closeDialog("ConfirmationDialog");
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
      });
    };

    const res = this.props.result;
    const license = _.get(res, "license");

    const cM = _.get(this.props, "result.license.commercialModel.name", "");
    const isLicenseTerminateAppliable =
      cM !== commercialModel.perpetualMS && cM !== commercialModel.freeware;

    const { isExpired, date: endDate } = getTermDetails(
      _.get(this.props, "result.license.latestTerm.endDate") as string,
      { commercialModel: cM }
    );

    const showEditDialog = () => {
      const licenseTypeId = decodeProductTypeId(license.type.id);
      this.props.openDialog("EditLicenseDialog", {
        licenseId: this.props.routeData.id,
        licenseTypeId: +licenseTypeId,
        theme: this.props.theme,
        onConfirm: () => {
          this.props.closeDialog("EditLicenseDialog");
          this.props.refreshAllOpenBlades();
        }
      });
    };

    const { isPending, requestedBy } = this.getLicenseTerminateStatus();
    return (
      <Flex>
        {/* {!this.props.isVendor ? (
          <ElmButton
            variance={"plain-icon-button"}
            colorVariance={"outline-secondary"}
            icon={"download"}
            iconPrefix={"far"}
            label={"Report"}
            disabled={true}
          />
        ) : null} */}
        <ElmButton
          variance={"plain-icon-button"}
          colorVariance={"primary"}
          icon={"chart-mixed"}
          iconPrefix={"far"}
          label={"Analytics"}
          onClick={this.navigateToAnalytics}
        />
        {this.props.isVendor ? (
          <AttributeActionsDropdown
            permissions={["modify_licenses", "request_modify_licenses"]}
            showEditDialog={showEditDialog}
            showDeleteDialog={showDeleteDialog}
            showTerminateDialog={this.showTerminateDialog}
            license={{
              isLicenseTerminateAppliable,
              isExpired,
              isPending,
              requestedBy
            }}
            disabled={!this.props.result}
          />
        ) : null}
      </Flex>
    );
  };

  public renderUsers = () => {
    const permissions = _.get(this.props.appState, "activeRole.permissions");
    const havePermissions = checkPermissions({
      userPermissions: permissions,
      requiredPermissions: ["modify_assets"]
    });
    const analyticsTableAction = (payload: LicenseUsersTableType) => {
      this.props.openBlade({
        route: "Analytics",
        routeName: `${payload.name} - Analytics`,
        fromBladeIndex: this.props.index,
        routeData: {
          type: "User",
          title: payload.name,
          id: payload.id,
          allTimeStartDate: `${payload.createdAt}`
        }
      });
    };
    const renderEnableToggle = (payload: LicenseUsersTableType) => {
      const userId = { id: _.get(payload, "id") };
      const productId = {
        product_id: _.get(this.props, "routeData.productId")
      };
      let isEnabled = false;
      payload?.productUsers?.nodes?.forEach(node => {
        if (node?.product?.id === productId.product_id && node?.enabled) {
          isEnabled = true;
        }
      });
      const handleChange = (activate: boolean) => {
        if (activate) {
          this.props.gateway.request
            .enableProduct(productId, userId)
            .then(res => {
              if (!res.error) {
                renderSuccessNotification("Enabled product");
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              renderErrorNotification("Error on product activation");
              console.error(err);
            });
        }
        if (!activate) {
          this.props.gateway.request
            .disableProduct(productId, userId)
            .then(res => {
              if (!res.error) {
                renderSuccessNotification("Deactivated product");
                this.props.refreshAllOpenBlades();
              }
            })
            .catch(err => {
              renderErrorNotification("Error on product deactivation");
              console.error(err);
            });
        }
      };

      return (
        <Flex onClick={e => e.stopPropagation()}>
          <ElmToggle
            size="small"
            onChange={handleChange}
            checked={isEnabled}
            permissions={"modify_assets"}
          />
        </Flex>
      );
    };
    return (
      <LicenseUsersTable
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.LicenseUsersTable
        )}
        refreshKey={REFRESH_KEY.LicenseUsersTable}
        removeRefreshFn={this.props.removeRefreshFn}
        bladeName="License"
        hideColumnsWithHeaders={
          this.props.isVendor
            ? ["Actions", "Enable"]
            : [
                "Company",
                "Product",
                "Email",
                !havePermissions ? "Enable" : null
              ]
        }
        onRowClick={this.navToUserBlade}
        renderToggleColumn={renderEnableToggle}
        renderRowActionButtons={payload => (
          <AnalyticsAction
            data={payload}
            analyticsAction={analyticsTableAction}
          />
        )}
      />
    );
  };
  public showTerminateTermDialog = () => {
    this.props.openDialog("ConfirmationDialog", {
      description: "key",
      entityType: "terminate",
      title: "Terminate term ?",
      warningMessage:
        "You are about to terminate this term. Are you sure you want to terminate it?",
      isSimpleConfirmation: true,
      name: this.props.result.license.key.slice(0, 5),
      onConfirm: () => {
        const gateway = getGateway();
        const licenseId = _.get(this.props, "result.license.id");
        gateway.request["deactivate_license"]({ license_id: licenseId })
          .then(res => {
            if (!res.error) {
              renderSuccessNotification("License terminated");
              this.props.closeDialog("ConfirmationDialog");
              this.props.refreshAllOpenBlades();
            }
          })
          .catch(err => {
            renderFailureNotification("License termination failed !");
          });
      }
    });
  };
  public renderLicenseHistoryTable = () => {
    const rowActionButton = (payload: any) => {
      return (
        <Flex padding={"2px"}>
          <ElmButton
            style={{
              color: this.props.theme.colors.black
            }}
            variance="icon-button"
            colorVariance={"subtle"}
            icon={"ban"}
            iconPrefix={"fas"}
            label=""
            onClick={this.showTerminateTermDialog}
          />
        </Flex>
      );
    };
    return (
      <LicenseHistoryTable
        bladeScope={this.props.routeData?.id}
        noNav={true}
        hideSearchBar={true}
        renderRowActionButtons={data => rowActionButton(data)}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.LicenseHistoryTable
        )}
        refreshKey={REFRESH_KEY.LicenseHistoryTable}
        removeRefreshFn={this.props.removeRefreshFn}
        hideColumnsWithHeaders={this.props.isVendor ? [] : ["Actions", "Value"]}
      />
    );
  };
  public renderEntitlementsTable = () => {
    const ent = this.getActiveEntitlements();
    if (!ent?.length) {
      return (
        <Flex width={"100%"} justifyContent={"center"}>
          <NoDataPlaceholder />
        </Flex>
      );
    }
    return (
      <ElmTable
        columns={[
          {
            Header: "Activation",
            accessor: "latestTerm.startDate",
            isDateFormat: true,
            sortKey: null,
            disableSortBy: true
          },
          {
            Header: "Expiration",
            accessor: "latestTerm.endDate",
            isDateFormat: true,
            sortKey: null,
            disableSortBy: true
          },
          {
            Header: "Seats",
            accessor: "",
            width: 0.15,
            cellRenderer: payload =>
              this.getEntitlementProps(payload.rowData?.id)?.seats,
            sortKey: null,
            disableSortBy: true
          },
          {
            Header: "Value",
            accessor: "value",
            width: 0.1,
            cellRenderer: payload => renderCurrency(payload),
            sortKey: null,
            disableSortBy: true
          }
          // {  // TODO - requires backend implementation
          //   Header: "Actions",
          //   accessor: "",
          //   width: 0.1,
          //   cellRenderer: payload => null,
          //   sortKey: null,
          //   disableSortBy: true
          // }
        ]}
        data={ent}
        hideSearchBar
        hideColumnsWithHeaders={!this.props.isVendor ? ["Actions"] : []}
      />
    );
  };
  public getEntitlementProps = (entitlementId: string) => {
    const target = this.getActiveEntitlements()?.find(
      entitlement => entitlement.id === entitlementId
    );
    if (!target) {
      return null;
    }
    const { latestTerm, userCount, sessionCount, instanceCount } = target;

    const seats = this.getSeatCountFromEnforcementModel({
      userCount,
      sessionCount,
      instanceCount
    });
    return { latestTerm, seats };
  };
  public getSeatCountFromEnforcementModel = (payload: {
    userCount?: number;
    sessionCount?: number;
    instanceCount?: number;
  }) => {
    const res = this.props.result;
    const licenseType = _.get(res, "license.type.id");
    const eM = licenseTypeToName(licenseType);

    if (eM === enforcementModel.namedUser) {
      return payload.userCount || 0;
    }
    if (eM === enforcementModel.concurrent) {
      return payload.sessionCount || 0;
    }
    if (eM === enforcementModel.nodeLocked) {
      return payload.instanceCount || 0;
    }
    return 0;
  };
  public renderLicenseReleaseTable = () => {
    return (
      <LicenseReleaseTable
        hideSearchBar={true}
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.LicenseReleaseTable
        )}
        refreshKey={REFRESH_KEY.LicenseReleaseTable}
        removeRefreshFn={this.props.removeRefreshFn}
        onRowClick={item => {
          this.props.openDialog("EditLicenseReleaseConfigDialog", {
            licenseId: this.props.result?.license?.id,
            productId: this.props.result?.license?.product?.id,
            currentChannel: item
          });
        }}
      />
    );
  };

  public renderChangeLogActions = (rowData: any) => {
    const Info = styled(getIcon("message-lines", "far"))`
      margin-inline: 2px;
    `;
    const Error = styled(getIcon("triangle-exclamation", "fas"))`
      color: ${props => props.theme.colors.orange};
      margin-inline: 4px;
    `;
    const permissions = _.get(
      this.props,
      "appState.activeRole.permissions",
      ""
    );
    const canModifyLicense = _.find(
      permissions,
      item => item === "modify_licenses"
    );
    const ApproveIcon = canModifyLicense
      ? styled(getIcon("circle-check", "fas"))`
          color: ${props => props.theme.colors.userGreen};
          margin-inline: 4px;
        `
      : null;
    const RejectIcon = canModifyLicense
      ? styled(getIcon("circle-xmark", "fal"))`
          color: ${props => props.theme.colors.warmGrey};
          margin-inline: 4px;
        `
      : null;

    const doApproval = (isApproved: boolean) => {
      const license_action_id = rowData?.id;
      const gateway = getGateway();
      gateway.request[isApproved ? "approveLicense" : "rejectLicense"]({
        license_id: _.get(rowData, "parameters.licenseId"),
        license_action_id
      })
        .then(res => {
          renderSuccessNotification(
            `License action ${isApproved ? "approved" : "rejected"} !`
          );
          !isApproved &&
            this.props.closeBlade({
              route: "License",
              fromBladeIndex: this.props.index
            });
          this.props.refreshAllOpenBlades();
        })
        .catch(e => {
          renderFailureNotification(
            `${isApproved ? "Approval" : "Reject"} Failed !`
          );
          this.props.refreshAllOpenBlades();
        });
    };

    const comment = rowData?.comment;
    const status = rowData?.status;
    const error = rowData?.error;

    if (status === LicenseActionStatus.Error)
      return (
        <Tooltip title={error} placement="top">
          <Error />
        </Tooltip>
      );
    return (
      <Flex style={{ gap: 4 }}>
        {comment.length ? (
          <Tooltip title={comment} placement="top">
            <Info />
          </Tooltip>
        ) : null}
        {canModifyLicense && status === LicenseActionStatus.Pending ? (
          <Flex>
            <Tooltip placement="top" title={"Reject"}>
              <RejectIcon onClick={() => doApproval(false)} />
            </Tooltip>
            <Tooltip placement="top" title={"Approve"}>
              <ApproveIcon onClick={() => doApproval(true)} />
            </Tooltip>
          </Flex>
        ) : null}
      </Flex>
    );
  };
  public renderLicenseChangeLogTable = () => {
    return (
      <LicenseChangeLogTable
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.LicenseChangeLogTable
        )}
        refreshKey={REFRESH_KEY.LicenseChangeLogTable}
        removeRefreshFn={this.props.removeRefreshFn}
        hideSearchBar={true}
        columnAndHeaderStyles={{
          justifyLastHeaderColumnContent: "flex-end"
        }}
        renderRowActionButtons={this.renderChangeLogActions}
      />
    );
  };

  public renderLicenseActiveSessionsTable = () => {
    const RefreshIcon = styled(getIcon("IoMdRefresh"))`
      margin-left: 16px;
    `;
    const showConfirmationForRevoke = (id: string) => {
      this.props.openDialog("ConfirmationDialog", {
        entityType: "Revoke session",
        isSimpleConfirmation: true,
        warningMessage: `Are you sure you want to immediately end that session ?`,
        onConfirm: () => {
          this.props.gateway.request
            .revokeSession(null, { id: id })
            .then(res => {
              if (res.error) {
                this.props.openDialog("ErrorDialog", {
                  onConfirm: null
                });
              } else {
                this.props.closeDialog("ConfirmationDialog");
                this.props.refreshAllOpenBlades();
              }
            });
        }
      });
    };
    const renderRowActionButtons = (rowData: { id: string; name: string }) => {
      return (
        <Flex
          style={{
            display: "flex"
          }}
        >
          <ElmButton
            className="darkModeTransparentBtn"
            style={{
              color: this.props.theme.colors.iconColor
            }}
            variance={"plain-icon-button"}
            icon={"trash"}
            iconPrefix={"fas"}
            alt="Delete"
            label=""
            colorVariance={"subtle"}
            permissions={["modify_licenses", "request_modify_licenses"]}
            onClick={() => showConfirmationForRevoke(rowData.id)}
          />
        </Flex>
      );
    };

    const renderRefreshButton = () => {
      const refreshTable = () => {
        this.props.refreshAllOpenBlades();
      };
      return <RefreshIcon onClick={() => refreshTable()} />;
    };
    return (
      <LicenseActiveSessionsTable
        renderRightOfSearchBar={renderRefreshButton}
        bladeScope={this.props.routeData?.id}
        setRefreshFn={this.props.setEntityRefreshFn(
          REFRESH_KEY.LicenseActiveSessionsTable
        )}
        refreshKey={REFRESH_KEY.LicenseActiveSessionsTable}
        removeRefreshFn={this.props.removeRefreshFn}
        renderRowActionButtons={renderRowActionButtons}
      />
    );
  };
  public getSuperScript = (value: number) => {
    return _.isFinite(value) && value > 0 ? value.toString() : "";
  };
  public getActiveEntitlements = () => {
    const res = this.props.result?.license;
    const ent = _.get(res, "activeEntitlements");
    if (!ent?.nodes?.length) {
      return [];
    }
    return ent.nodes;
  };
  public renderLicenseComponentsTable = () => {
    return (
      <LoadingWrapper>
        <LicenseComponents
          isVendor={this.props.isVendor}
          openBlade={this.props.openBlade}
          fromBladeIndex={this.props.index}
          productId={_.get(this.props.routeData, "productId", null)}
          licenseId={_.get(this.props.routeData, "id")}
          openDialog={this.props.openDialog}
          refreshAllOpenBlades={this.props.refreshAllOpenBlades}
          // setRefreshFn={this.props.setEntityRefreshFn("LicenseComponents")}
        />
      </LoadingWrapper>
    );
  };
  public renderComponentEntitlementsTable = () => {
    return (
      <LoadingWrapper>
        <LicenseComponentEntitlements
          isVendor={this.props.isVendor}
          openBlade={this.props.openBlade}
          closeDialog={this.props.closeDialog}
          productId={_.get(this.props.routeData, "productId", null)}
          licenseId={_.get(this.props.routeData, "id")}
          fromBladeIndex={this.props.index}
          openDialog={this.props.openDialog}
          refreshAllOpenBlades={this.props.refreshAllOpenBlades}
          // setRefreshFn={this.props.setEntityRefreshFn("LicenseEntitlements")}
        />
      </LoadingWrapper>
    );
  };
  public checkRequirementStatus = (
    reqs: ComponentNode["licenseComponentRequirements"]
  ) => {
    const componentIsIncluded = reqs?.every(
      (v: VersionRequirementNode) => v?.isIncluded && !v?.tokenCount
    );
    const componentHasFreeTrial = _.last(reqs)?.freeTrialActive;
    return { componentIsIncluded, componentHasFreeTrial };
  };
  public getComponentsAndEntitlements = () => {
    const views = [
      {
        title: "Components",
        Component: this.props.isVendor ? (
          this.renderLicenseComponentsTable()
        ) : (
          <EntitlementsComponentsList
            // setRefreshFn={this.props.setEntityRefreshFn("LicenseEntitlementComponents")}
            // refreshKey={REFRESH_KEY.LicenseEntitlementComponents}
            // removeRefreshFn={this.props.removeRefreshFn}
            licenseId={this.props.routeData?.id}
            isVendor={this.props.isVendor}
          />
        ),
        style: sharedTabStyle
      },
      {
        title: "Entitlements",
        Component: this.renderComponentEntitlementsTable(),
        style: sharedTabStyle
      }
    ] as ITab[];
    return (
      <TabView
        tabList={views}
        titleContainerStyle={{ justifyContent: "flex-start" }}
      />
    );
  };
  public renderFeaturesAndGroups = () => {
    const onChange = (payload: {
      type: "feature" | "group";
      id: string;
      name: string;
      value: boolean;
      updateQuery: () => void;
    }) => {
      if (payload.type === "group") {
        this.props.gateway.request[
          payload.value ? "enableFeatureGroup" : "disableFeatureGroup"
        ]({
          feature_group_id: payload.id,
          license_id: this.props.routeData.id
        }).then(() => {
          payload.updateQuery();
          this.props.refreshAllOpenBlades();
        });
      }
      if (payload.type === "feature") {
        this.props.gateway.request[
          payload.value ? "enableFeature" : "disableFeature"
        ]({ feature_id: payload.id, license_id: this.props.routeData.id }).then(
          () => {
            this.props.retry();
            payload.updateQuery();
          }
        );
      }
    };
    return (
      <FeaturesAndGroups
        onChange={onChange}
        id={this.props.routeData.id}
        // setRefreshFn={this.props.setEntityRefreshFn(REFRESH_KEY.LicenseFeatureGroupsTable)}
        // refreshKey={REFRESH_KEY.LicenseFeatureGroupsTable}
        // removeRefreshFn={this.props.removeRefreshFn}
      />
    );
  };
  public renderTabs() {
    const res = _.get(this.props, "result");
    const license = _.get(res, "license");
    if (!license) {
      return null;
    }
    let tabs: ITab[] = [];
    if (!this.props.isVendor) {
      tabs.push({
        title: "Users",
        Component: this.renderUsers()
      });
    }
    const productHaveComponents =
      _.get(res, "productComponents.componentGroups.nodes.length", false) ||
      _.get(res, "productComponents.components.nodes.length", false);
    if (productHaveComponents) {
      tabs.push({
        title: "Components",
        Component: this.getComponentsAndEntitlements()
      });
    }
    if (this.props.isVendor && license.product.featuresCount > 0) {
      tabs.push({
        title: "Features",
        Component: this.renderFeaturesAndGroups()
        // supScript: this.getSuperScript(license.featuresCount),
      });
    }
    const isLease =
      _.get(license, "commercialModel.name") === commercialModel.lease;
    if (this.props.isVendor) {
      tabs.push({
        title: "Users",
        Component: this.renderUsers()
        // supScript: this.getSuperScript(_.get(license, 'usersCount')),
      });
    }
    tabs.push({
      title: isLease ? "Entitlement" : "Term",
      Component: isLease
        ? this.renderEntitlementsTable()
        : this.renderLicenseHistoryTable()
      // supScript: this.getSuperScript(license.termsCount),
    });

    if (this.props.isVendor && license.releaseChannelsCount > 0) {
      tabs.push({
        title: "Release",
        Component: this.renderLicenseReleaseTable()
        // supScript: this.getSuperScript(license.releaseChannelsCount),
      });
    }
    tabs.push({
      title: "Active sessions",
      Component: this.renderLicenseActiveSessionsTable()
    });
    if (this.props.isVendor) {
      tabs.push({
        title: "Change log",
        Component: this.renderLicenseChangeLogTable()
      });
    }
    const permissions = _.get(
      this.props,
      "appState.activeRole.permissions",
      ""
    );
    const canModifyLicense = _.find(
      permissions,
      item => item === "modify_licenses"
    );
    const licenseActionNodes = _.get(license, "licenseActions.nodes", []);
    let havePendingActions = false;
    const pendingLicenseActionTypes = [
      "create_license",
      "modify_licenses",
      "deactivate_license"
    ];
    _.filter(licenseActionNodes, data => {
      if (
        canModifyLicense &&
        pendingLicenseActionTypes.includes(data?.action) &&
        data?.status === LicenseActionStatus.Pending
      ) {
        havePendingActions = true;
      }
    });
    const shouldToggleChangeLogsTab =
      havePendingActions &&
      _.get(this.props, "routeData.openInChangelogTab", false);
    if (shouldToggleChangeLogsTab) this.updateActiveTab(tabs.length - 1);
    const defaultTab = shouldToggleChangeLogsTab
      ? tabs.length - 1
      : _.get(this.props, "routeData.tabIndex", 0);

    return (
      <div style={{ display: "flex", flex: 1 }}>
        <AutoSizer defaultHeight={300}>
          {({ height, width }) => (
            <TabView
              showBottomBorder={true}
              leftAlign={true}
              defaultTab={defaultTab}
              tabList={tabs}
              onUpdateActiveTab={this.updateActiveTab}
              orientation={"vertical"}
              style={{
                maxHeight: height,
                minHeight: height,
                maxWidth: width,
                minWidth: width,
                paddingLeft: "21px",
                paddingRight: "21px"
              }}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
  public render() {
    return this.renderLicenseInfo();
  }
}

const relayEnvironment = getEnvironment();
const RenderQuery = (props: ILicenseBladeProps) => {
  const theme = useTheme();
  const renderLicenseInfo = (payload: {
    error: Error;
    props: LicenseQueryResponse;
    retry: () => void;
  }) => {
    // if (!payload.props) {
    //   props.showScreenLoader();
    //   return null;
    // }
    // props.hideScreenLoader();
    return (
      <LicenseBlade
        {...props}
        result={payload.props}
        theme={theme}
        {..._.omit(payload, "props")}
      />
    );
  };
  return (
    <QueryRenderer<LicenseQuery>
      environment={relayEnvironment}
      variables={{
        id: props.routeData.id,
        // blade_scope: props.routeData.id,
        productId: props.routeData.productId
      }}
      query={graphqlQuery}
      render={renderLicenseInfo}
    />
  );
};
export default appConnect(RenderQuery, {
  selectors: {
    activeRole: "activeRoleSelector"
  }
});
