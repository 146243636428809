import React from "react";
import { Flex } from "reflexbox";
import { useTheme } from "styled-components";
import { WarningSignIcon } from "../../assets";
import { ElmButton } from "components/elmButton";

const ErrorPlaceholder: React.FC<{ message?: string }> = ({ message }) => {
  const theme = useTheme();
  const textStyle = {
    color: theme.colors.warmGrey,
    fontSize: theme.fontSizes.ySmall
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      minHeight="inherit"
      justifyContent="center"
    >
      <img
        style={{ height: 32 }}
        src={WarningSignIcon}
        alt="Failed to load data"
      />
      <span
        style={{
          ...textStyle,
          marginTop: 16
        }}
      >
        {message || "Failed to load data"}
      </span>
      <ElmButton
        colorVariance={"subtle"}
        label={"Report a problem"}
        className={"darkModeTransparentBtn"}
        style={{
          textDecoration: "underline",
          ...textStyle
        }}
        //@ts-ignore
        onClick={() => {
          //@ts-ignore
          FreshworksWidget("identify", "ticketForm", {
            email: localStorage.getItem("uid"),
            subject: "Chart Failure"
          });
          //@ts-ignore
          FreshworksWidget("open");
        }}
      />
    </Flex>
  );
};

export default ErrorPlaceholder;
