/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type licenseTemplatesTable_licenseTemplates = {
  readonly licenseTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly product: {
          readonly id: string;
          readonly name: string | null;
          readonly iconUrl: string | null;
        } | null;
        readonly name: string;
        readonly templateBody: string | null;
        readonly vendorSystemUser: {
          readonly id: string;
          readonly name: string | null;
          readonly email: string | null;
        } | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $refType": "licenseTemplatesTable_licenseTemplates";
};
export type licenseTemplatesTable_licenseTemplates$data = licenseTemplatesTable_licenseTemplates;
export type licenseTemplatesTable_licenseTemplates$key = {
  readonly " $data"?: licenseTemplatesTable_licenseTemplates$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<
    "licenseTemplatesTable_licenseTemplates"
  >;
};

const node: ReaderFragment = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any,
    v1 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any;
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "count"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "cursor"
      },
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "search"
      }
    ],
    kind: "Fragment",
    metadata: {
      connection: [
        {
          count: "count",
          cursor: "cursor",
          direction: "forward",
          path: ["licenseTemplates"]
        }
      ]
    },
    name: "licenseTemplatesTable_licenseTemplates",
    selections: [
      {
        alias: "licenseTemplates",
        args: [
          {
            kind: "Variable",
            name: "search",
            variableName: "search"
          }
        ],
        concreteType: "LicenseTemplateConnection",
        kind: "LinkedField",
        name: "__licenseTemplatesTable_licenseTemplates_connection",
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: "LicenseTemplateEdge",
            kind: "LinkedField",
            name: "edges",
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: "LicenseTemplate",
                kind: "LinkedField",
                name: "node",
                plural: false,
                selections: [
                  v0 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    concreteType: "Product",
                    kind: "LinkedField",
                    name: "product",
                    plural: false,
                    selections: [
                      v0 /*: any*/,
                      v1 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "iconUrl",
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  v1 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "templateBody",
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: "VendorSystemUser",
                    kind: "LinkedField",
                    name: "vendorSystemUser",
                    plural: false,
                    selections: [
                      v0 /*: any*/,
                      v1 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: "ScalarField",
                        name: "email",
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: "ScalarField",
                    name: "__typename",
                    storageKey: null
                  }
                ],
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "cursor",
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: "PageInfo",
            kind: "LinkedField",
            name: "pageInfo",
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "hasNextPage",
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: "ScalarField",
                name: "endCursor",
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: "Query",
    abstractKey: null
  } as any;
})();
(node as any).hash = "9b41a8cb45d2e088343ec2da3b755397";
export default node;
