import React, { useLayoutEffect, useState } from "react";
import uuid from "uuid";
import { IChart, ProductFilter } from "./types";
import { useChartData } from "./hooks";
import { customChartTheme, generateQueryFilter } from "./helpers";
import { CardTitle } from "./styles";
import ProductsFilter from "../../components/analyticsComponent/filters/productsFilter";
import TimeFilter from "../../components/analyticsComponent/filters/timeFilter";
import { Flex } from "reflexbox";
import _ from "lodash";
import { useTheme } from "styled-components";
import FiltersContainer from "./filtersContainer";

function BarChartLogoComponent(props: IChart) {
  const [timeRange, setTimeRange] = useState<string>("months3");
  const [productFilter, setProductFilter] = useState<ProductFilter>();
  const qData = generateQueryFilter(
    props.queryParams,
    timeRange,
    productFilter?.productName || ""
  );
  const theme = useTheme();

  let instanceId = uuid();
  const { data } = useChartData(qData);

  useLayoutEffect(() => {
    let root = am5.Root.new(instanceId);

    let myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll(customChartTheme);

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    if (root._logo) {
      root._logo._privateSettings.visible = false;
    }

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        minHeight: 110,
        maxHeight: props.options?.chartMaxHeight || 170,
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none"
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "name",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xRenderer.grid.template.set("visible", false);

    var yRenderer = am5xy.AxisRendererY.new(root, {});
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        extraMax: 0.1,
        renderer: yRenderer
      })
    );

    yRenderer.grid.template.setAll({
      strokeDasharray: [2, 2]
    });
    yRenderer.labels.template.setAll({
      fill: theme.chartColors.labels
    });
    yRenderer.setAll({
      stroke: theme.chartColors.axes
    });

    xAxis.get("renderer").labels.template.setAll({
      fill: theme.chartColors.labels
    });
    xAxis.get("renderer").setAll({
      stroke: theme.chartColors.axes
    });

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: "name",
        tooltip: am5.Tooltip.new(root, { dy: -25, labelText: "{valueY}" })
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5
    });

    series.columns.template.adapters.add("fill", (fill, target) => {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", (stroke, target) => {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    // Set data
    const imageUrl = `https://portalvhdszg3zy2vcrqhxn.blob.core.windows.net/attachments-staging/products/icons/1/original/flotools_dots_on_trans.png?sp=r&sv=2016-05-31&sr=b&st=2022-09-23T12%3A56%3A06Z&se=2022-09-23T13%3A11%3A06Z&sig=y%2BOXK4fRRErlRCjoqRDeFrCm3t%2FBP82xzNVczgQ604Y%3D`;

    let data = [
      {
        name: "Flotools",
        value: 35654,
        bulletSettings: {
          src: imageUrl
        }
      },
      {
        name: "Multicorp",
        value: 65456,
        bulletSettings: {
          src: imageUrl
        }
      },
      {
        name: "Evoleap",
        value: 45724,
        bulletSettings: {
          src: imageUrl
        }
      },
      {
        name: "Test",
        value: 13654,
        bulletSettings: {
          src: imageUrl
        }
      }
    ];

    series.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Picture.new(root, {
          templateField: "bulletSettings",
          width: 50,
          height: 50,
          centerX: am5.p50,
          centerY: am5.p50,
          shadowColor: am5.color(0x000000),
          shadowBlur: 4,
          shadowOffsetX: 4,
          shadowOffsetY: 4,
          shadowOpacity: 0.6
        })
      });
    });

    xAxis.data.setAll(data);
    series.data.setAll(data);

    chart
      .get("colors")
      .set("colors", [
        am5.color("#0F649A"),
        am5.color("#68B6E8"),
        am5.color("#FFA033"),
        am5.color("#EF8879"),
        am5.color("#70D165"),
        am5.color("#E3D626"),
        am5.color("#F4F6F6")
      ]);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, [data, timeRange, theme]);

  return (
    <div
      id={instanceId}
      style={{
        width: "100%",
        minHeight: "inherit",
        maxHeight: props.options?.chartMaxHeight || 170
      }}
    ></div>
  );
}

export default function BarChartLogo(props: IChart) {
  return (
    <FiltersContainer
      containerProps={props}
      Component={BarChartLogoComponent}
    />
  );
}
