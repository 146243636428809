import _ from "lodash";
import React from "react";
import { Flex } from "reflexbox";
import { ElmButton, ElmInput } from "./components";
import { renderErrorNotification } from "utils/ant-notifications";
import { Redirect, RouteChildrenProps } from "react-router-dom";
import { InfoMessage } from "components/helpers";
import { getGateway } from "./api";
import elmLogoLight from "./logo.svg";
import elmLogoDark from "./logo-dark.svg";
import {
  LoginBox,
  LoginContainer,
  TagLine,
  ForgotPassword as ReturnToLogin,
  BulbContainer,
  BulbIcon
} from "login";
import { appConnect, appDispatch } from "store/appConnect";
import { ActiveMode } from "store/types";

interface IState {
  email: string;
  error_email?: boolean;
  password: string;
  error_password?: boolean;
  password_confirmation: string;
  error_password_confirmation?: boolean;
  isRequestPending: boolean;
  isRequestSent: boolean;
}

interface IProps extends RouteChildrenProps {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {
    isSignedIn: boolean;
    signedInFailed: boolean;
    isLoading: boolean;
    activeRole: any;
    activeMode: ActiveMode;
  };
}

export class RegisterBlade extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password_confirmation: "",
      isRequestPending: false,
      isRequestSent: false
    };
  }
  PASSWORD_MIN_LENGTH = 8;

  public requestNewPassword = () => {
    const gateway = getGateway();
    gateway.request
      .setPassword({
        email: this.state.email,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation
      })
      .then(res => {
        if (res?.error) {
          if (res?.data?.errors?.length) {
            res.data.errors.forEach(e => {
              renderErrorNotification(e);
            });
          } else {
            renderErrorNotification("Error setting new password");
          }
          return;
        }
        this.setState({
          isRequestSent: true,
          error_email: false,
          error_password: false,
          error_password_confirmation: false
        });
        // this.login();
      })
      .catch(err => {
        renderErrorNotification("Failed to set password");
        console.error(err);
      })
      .finally(() => {
        this.setState({
          isRequestPending: false
        });
      });
  };
  public returnToSignIn = () => {
    if (this.state.isRequestSent) {
      // this.login()
    }
    this.props.history.push("/login");
  };
  public login = () => {
    this.props.appDispatch.deviseActions.requestSignIn({
      email: this.state.email,
      password: this.state.password
    });
  };
  public validateEmail = (input: string) => {
    const isValid = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(input);
    if (!isValid) {
      this.setState({ error_email: true });
      return false;
    }
    this.setState({ error_email: false });
    return true;
  };
  public validateInputPassword = () => {
    const isValid = this.state.password?.length >= this.PASSWORD_MIN_LENGTH;
    if (!isValid) {
      this.setState({ error_password: true });
      return false;
    }
    this.setState({ error_password: false });
    return true;
  };
  public validatePasswordConfirmation = () => {
    const isValid = _.isEqual(
      this.state.password,
      this.state.password_confirmation
    );
    if (!isValid) {
      this.setState({ error_password_confirmation: true });
      return false;
    }
    this.setState({ error_password_confirmation: false });
    return true;
  };
  public onRequestedPassword = (e: React.FormEvent) => {
    e.preventDefault();
    const isEmailValid = this.validateEmail(this.state.email);
    const isInputPasswordValid = this.validateInputPassword();
    const isPasswordConfirmationValid = this.validatePasswordConfirmation();
    if (
      !isEmailValid ||
      !isInputPasswordValid ||
      !isPasswordConfirmationValid
    ) {
      return;
    }
    this.setState({
      error_email: false,
      error_password: false,
      error_password_confirmation: false
    });
    this.requestNewPassword();
  };

  public render() {
    if (this.props.appState.isSignedIn) {
      return (
        <Redirect
          to={{
            pathname: "/login"
          }}
        />
      );
    }
    return (
      <LoginContainer
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <LoginBox>
          <img
            src={
              this.props.appState.activeMode === "light"
                ? elmLogoLight
                : elmLogoDark
            }
            style={{ display: "flex", justifySelf: "self", height: "82px" }}
            alt="Elm logo"
          />
          <TagLine>a better way to license your software</TagLine>
          <form onSubmit={this.onRequestedPassword} style={{ flex: 1 }}>
            <Flex
              flex={1}
              className="signInContainer"
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={"100%"}
            >
              {this.state.isRequestSent ? (
                <InfoMessage
                  icon={
                    <BulbContainer>
                      <BulbIcon />
                    </BulbContainer>
                  }
                  showIcon
                  description="Your password has been successfully set"
                  type="info"
                />
              ) : (
                <Flex flexDirection={"column"} style={{ gap: "8px" }}>
                  <ElmInput
                    updatedValue={email =>
                      this.setState({
                        email,
                        error_email: false
                      })
                    }
                    label={"Email"}
                    placeholder={"youremail@email.com"}
                    error={this.state.error_email}
                    errorMessage={"Invalid email address!"}
                    disabled={this.state.isRequestPending}
                  />
                  <ElmInput
                    updatedValue={password =>
                      this.setState({
                        password,
                        error_password: false
                      })
                    }
                    label={"Password"}
                    placeholder={"************"}
                    type={"password"}
                    error={this.state.error_password}
                    errorMessage={"Password must be at least 8 characters long"}
                    disabled={this.state.isRequestPending}
                  />
                  <ElmInput
                    updatedValue={password_confirmation =>
                      this.setState({
                        password_confirmation,
                        error_password_confirmation: false
                      })
                    }
                    label={"Confirm password"}
                    placeholder={"************"}
                    type={"password"}
                    error={this.state.error_password_confirmation}
                    errorMessage={"Passwords do not match"}
                    disabled={this.state.isRequestPending}
                  />
                </Flex>
              )}

              <Flex alignItems="center" flexDirection="column">
                {this.state.isRequestSent ? null : (
                  <ElmButton
                    type={"submit"}
                    label={"Set password"}
                    disabled={this.state.isRequestPending}
                    style={{
                      minWidth: "100%",
                      maxHeight: "32px",
                      padding: "9px",
                      margin: "0px 0px 8px 0px"
                    }}
                  />
                )}

                <ReturnToLogin onClick={this.returnToSignIn}>
                  Return to sign in
                </ReturnToLogin>
              </Flex>
            </Flex>
          </form>
        </LoginBox>
      </LoginContainer>
    );
  }
}

export default appConnect(RegisterBlade, {
  selectors: {
    isSignedIn: "isSignedInSelector",
    isLoading: "isUserLoadingSelector",
    signedInFailed: "signedInFailed",
    activeMode: "activeModeSelector",
    activeRole: "activeRoleSelector"
  }
});
