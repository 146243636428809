import * as _ from "lodash";
import * as React from "react";
import { Cell } from "react-table";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
// import { ElmTable } from "../../../elmTable";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";

const title = "Edit: Release Alpha";
const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 254px;
  width: 444px;
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
const DialogSubTitle = styled.p`
  ${props => props.theme.fontType.visualizationSubTitle}
`;
export interface IEditReleaseConfigState {
  selected?: string;
}
export class EditReleaseDialog extends DialogBase<
  IModalProps,
  IEditReleaseConfigState
> {
  private tableColumns = [
    {
      Header: "Release Configuration",
      accessor: "model"
    },
    {
      Header: "Current Release",
      accessor: "model"
    },
    {
      Header: "Channel",
      accessor: "product"
    }
  ];
  constructor(props: IModalProps) {
    super(props);
    this.state = {};
  }
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          height: "254px",
          width: "444px",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }
  public renderTableCell = (cell: Cell, index: number) => {
    return <p>{Math.random()}</p>;
  };
  public renderFormContent = () => {
    return (
      <Flex flexDirection={"column"}>
        <p>form</p>
      </Flex>
    );
  };
  // public renderReleaseTable = () => {
  //   return (
  //     <ElmTable
  //       columns={this.tableColumns}
  //       data={[{ model: "someModel", product: { thumb: "somethumb" } }]}
  //       renderCellProp={this.renderTableCell}
  //       hideFilter={true}
  //       // onRowClick={this.navToLicenseBlade}
  //     />
  //   );
  // };
  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "EditReleaseDialog"
    });
  };
  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{title}</DialogTitle>
        <DialogSubTitle>
          Here you can change the release channel for Release Alpha's current
          release.
        </DialogSubTitle>
        {/* {this.renderReleaseTable()} */}
        <Flex justifyContent={"flex-end"}>
          <Flex marginRight={10}>
            <ElmButton label={"Confirm Changes"} />
          </Flex>
          <ElmButton label={"Discard"} colorVariance={"secondary"} />
        </Flex>
      </DialogContainer>
    );
  }
}
