/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userProductHistoryTablePaginationQueryVariables = {
  id: string;
  count: number;
  cursor?: string | null | undefined;
  search?: string | null | undefined;
  filterColumn?: Array<string> | null | undefined;
  filterValues?: Array<Array<string>> | null | undefined;
  filterOp?: Array<Array<string>> | null | undefined;
  sortColumn?: Array<string> | null | undefined;
  sortDirection?: Array<string> | null | undefined;
};
export type userProductHistoryTablePaginationQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"userProductHistoryTable_history">;
};
export type userProductHistoryTablePaginationQuery = {
  readonly response: userProductHistoryTablePaginationQueryResponse;
  readonly variables: userProductHistoryTablePaginationQueryVariables;
};

/*
query userProductHistoryTablePaginationQuery(
  $id: ID!
  $count: Int!
  $cursor: String
  $search: String
  $filterColumn: [String!]
  $filterValues: [[String!]!]
  $filterOp: [[String!]!]
  $sortColumn: [String!]
  $sortDirection: [String!]
) {
  ...userProductHistoryTable_history_2Ronxv
}

fragment userProductHistoryTable_history_2Ronxv on Query {
  history: user(id: $id) {
    productUsers(first: $count, after: $cursor, search: $search, sortColumn: $sortColumn, sortDirection: $sortDirection, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues) {
      edges {
        node {
          product {
            name
            id
          }
          modificationRecords {
            edges {
              node {
                enabled
                createdAt
              }
            }
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "count"
    } as any,
    v1 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    } as any,
    v2 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    } as any,
    v3 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterOp"
    } as any,
    v4 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    } as any,
    v5 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    } as any,
    v6 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    } as any,
    v7 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    } as any,
    v8 = {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    } as any,
    v9 = {
      kind: "Variable",
      name: "filterColumn",
      variableName: "filterColumn"
    } as any,
    v10 = {
      kind: "Variable",
      name: "filterOp",
      variableName: "filterOp"
    } as any,
    v11 = {
      kind: "Variable",
      name: "filterValues",
      variableName: "filterValues"
    } as any,
    v12 = {
      kind: "Variable",
      name: "id",
      variableName: "id"
    } as any,
    v13 = {
      kind: "Variable",
      name: "search",
      variableName: "search"
    } as any,
    v14 = {
      kind: "Variable",
      name: "sortColumn",
      variableName: "sortColumn"
    } as any,
    v15 = {
      kind: "Variable",
      name: "sortDirection",
      variableName: "sortDirection"
    } as any,
    v16 = [
      {
        kind: "Variable",
        name: "after",
        variableName: "cursor"
      } as any,
      v9 /*: any*/,
      v10 /*: any*/,
      v11 /*: any*/,
      {
        kind: "Variable",
        name: "first",
        variableName: "count"
      } as any,
      v13 /*: any*/,
      v14 /*: any*/,
      v15 /*: any*/
    ],
    v17 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/
      ],
      kind: "Fragment",
      metadata: null,
      name: "userProductHistoryTablePaginationQuery",
      selections: [
        {
          args: [
            {
              kind: "Variable",
              name: "count",
              variableName: "count"
            },
            {
              kind: "Variable",
              name: "cursor",
              variableName: "cursor"
            },
            v9 /*: any*/,
            v10 /*: any*/,
            v11 /*: any*/,
            v12 /*: any*/,
            v13 /*: any*/,
            v14 /*: any*/,
            v15 /*: any*/
          ],
          kind: "FragmentSpread",
          name: "userProductHistoryTable_history"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: [
        v5 /*: any*/,
        v0 /*: any*/,
        v1 /*: any*/,
        v6 /*: any*/,
        v2 /*: any*/,
        v4 /*: any*/,
        v3 /*: any*/,
        v7 /*: any*/,
        v8 /*: any*/
      ],
      kind: "Operation",
      name: "userProductHistoryTablePaginationQuery",
      selections: [
        {
          alias: "history",
          args: [v12 /*: any*/],
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            {
              alias: null,
              args: v16 /*: any*/,
              concreteType: "ProductUserConnection",
              kind: "LinkedField",
              name: "productUsers",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ProductUserEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ProductUser",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "Product",
                          kind: "LinkedField",
                          name: "product",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "name",
                              storageKey: null
                            },
                            v17 /*: any*/
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: "ModificationRecordConnection",
                          kind: "LinkedField",
                          name: "modificationRecords",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "ModificationRecordEdge",
                              kind: "LinkedField",
                              name: "edges",
                              plural: true,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  concreteType: "ModificationRecord",
                                  kind: "LinkedField",
                                  name: "node",
                                  plural: false,
                                  selections: [
                                    {
                                      alias: null,
                                      args: null,
                                      kind: "ScalarField",
                                      name: "enabled",
                                      storageKey: null
                                    },
                                    {
                                      alias: null,
                                      args: null,
                                      kind: "ScalarField",
                                      name: "createdAt",
                                      storageKey: null
                                    }
                                  ],
                                  storageKey: null
                                }
                              ],
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: v16 /*: any*/,
              filters: [
                "search",
                "sortColumn",
                "sortDirection",
                "filterColumn",
                "filterOp",
                "filterValues"
              ],
              handle: "connection",
              key: "userProductHistoryTable_productUsers",
              kind: "LinkedHandle",
              name: "productUsers"
            },
            v17 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "2157b2d13da2f9022ee4c233f4ea1fcd",
      id: null,
      metadata: {},
      name: "userProductHistoryTablePaginationQuery",
      operationKind: "query",
      text:
        "query userProductHistoryTablePaginationQuery(\n  $id: ID!\n  $count: Int!\n  $cursor: String\n  $search: String\n  $filterColumn: [String!]\n  $filterValues: [[String!]!]\n  $filterOp: [[String!]!]\n  $sortColumn: [String!]\n  $sortDirection: [String!]\n) {\n  ...userProductHistoryTable_history_2Ronxv\n}\n\nfragment userProductHistoryTable_history_2Ronxv on Query {\n  history: user(id: $id) {\n    productUsers(first: $count, after: $cursor, search: $search, sortColumn: $sortColumn, sortDirection: $sortDirection, filterColumn: $filterColumn, filterOp: $filterOp, filterValues: $filterValues) {\n      edges {\n        node {\n          product {\n            name\n            id\n          }\n          modificationRecords {\n            edges {\n              node {\n                enabled\n                createdAt\n              }\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "38a703594c379ad4818b89ce617dd2fb";
export default node;
