import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import relayEnvironment from "../../../api/relay";
import {
  allCompaniesQuery,
  allCompaniesQueryResponse
} from "./__generated__/allCompaniesQuery.graphql";
import _ from "lodash";
import { ElmFilterSelect } from "../../elmSelect";
import { ElmSelectOption } from "../../elmSelect";
const graphqlQuery = graphql`
  query allCompaniesQuery {
    allCompaniesFilter: companies(
      sortColumn: ["companies.name"]
      sortDirection: ["asc"]
    ) {
      nodes {
        name
        id
      }
    }
  }
`;
export interface IFilterProp {
  companyId?: string;
  onChange?: (companyId: string) => void;
}
export class AllCompaniesFilter extends React.Component<
  IFilterProp & { result: allCompaniesQueryResponse },
  { companyId: string }
> {
  state = {
    companyId: this.props.companyId
  };
  public renderFilter = () => {
    if (!this.props.result || !this.props.result.allCompaniesFilter) {
      return null;
    }

    const options = [
      {
        id: -1,
        name: "All companies"
      },
      ...this.props.result.allCompaniesFilter.nodes
    ];
    const handleChange = (payload: any) => {
      let companyId: string = payload;
      if (payload === "-1") {
        companyId = null;
      }
      this.setState({ companyId }, () => {
        if (_.isFunction(this.props.onChange)) {
          this.props.onChange(companyId);
        }
      });
    };
    return (
      <ElmFilterSelect
        value={this.state.companyId || "-1"}
        onChange={handleChange}
        className={"filterBorder"}
        dropdownMatchSelectWidth={false}
      >
        {_.map(options, node => {
          return (
            <ElmSelectOption key={node.id}>
              <span className="filterOption">{node.name}</span>
            </ElmSelectOption>
          );
        })}
      </ElmFilterSelect>
    );
  };
  public render() {
    return this.renderFilter();
  }
}
const RenderQuery = (props: IFilterProp) => {
  const renderComponent = (payload: {
    error: Error;
    props: allCompaniesQueryResponse;
    retry: () => void;
  }) => {
    // if (!payload.props) {
    //   props.showScreenLoader();
    //   return null;
    // }
    // props.hideScreenLoader();
    return (
      <AllCompaniesFilter
        {...props}
        result={payload.props}
        {..._.omit(payload, "props")}
      />
    );
  };
  return (
    <QueryRenderer<allCompaniesQuery>
      environment={relayEnvironment}
      variables={null}
      query={graphqlQuery}
      render={renderComponent}
    />
  );
};
export default RenderQuery;
