import React from "react";
import _ from "lodash";
import AnalyticsComponent, { AnalyticsComponentProps } from "./index";
import { TabView, ITab } from "../tabView";
// import { WidgetContainer, widgetSizeTypes } from './widgetContainer';

export interface ITabbedAnalyticsComponentProps {
  views: {
    analyticsComponentProps: AnalyticsComponentProps;
    tabProps: Omit<ITab, "Component">;
  }[];
  titleContainerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  // size?: widgetSizeTypes;
}
export class TabbedAnalyticsComponent extends React.Component<
  ITabbedAnalyticsComponentProps
> {
  public renderTabs = () => {
    const tabList = _.map(this.props.views, view => {
      return {
        ...view.tabProps,
        Component: (
          <AnalyticsComponent
            {...view.analyticsComponentProps}
            className="tabbed-analytics-mode"
          />
        )
      } as ITab;
    });
    return (
      <TabView
        tabList={tabList}
        titleContainerStyle={this.props.titleContainerStyle}
        style={{ height: "100%", ...(this.props.style || {}) }}
      />
    );
  };
  public render() {
    return <div className="tabbed-analytics">{this.renderTabs()}</div>;
  }
}
