/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userProductHistoryTable_history = {
  readonly history: {
    readonly productUsers: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly product: {
            readonly name: string | null;
          } | null;
          readonly modificationRecords: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly enabled: number;
                readonly createdAt: unknown;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $refType": "userProductHistoryTable_history";
};
export type userProductHistoryTable_history$data = userProductHistoryTable_history;
export type userProductHistoryTable_history$key = {
  readonly " $data"?: userProductHistoryTable_history$data | undefined;
  readonly " $fragmentRefs": FragmentRefs<"userProductHistoryTable_history">;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: 25,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterColumn"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterOp"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "filterValues"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortColumn"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "sortDirection"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: "cursor",
        direction: "forward",
        path: ["history", "productUsers"]
      }
    ]
  },
  name: "userProductHistoryTable_history",
  selections: [
    {
      alias: "history",
      args: [
        {
          kind: "Variable",
          name: "id",
          variableName: "id"
        }
      ],
      concreteType: "User",
      kind: "LinkedField",
      name: "user",
      plural: false,
      selections: [
        {
          alias: "productUsers",
          args: [
            {
              kind: "Variable",
              name: "filterColumn",
              variableName: "filterColumn"
            },
            {
              kind: "Variable",
              name: "filterOp",
              variableName: "filterOp"
            },
            {
              kind: "Variable",
              name: "filterValues",
              variableName: "filterValues"
            },
            {
              kind: "Variable",
              name: "search",
              variableName: "search"
            },
            {
              kind: "Variable",
              name: "sortColumn",
              variableName: "sortColumn"
            },
            {
              kind: "Variable",
              name: "sortDirection",
              variableName: "sortDirection"
            }
          ],
          concreteType: "ProductUserConnection",
          kind: "LinkedField",
          name: "__userProductHistoryTable_productUsers_connection",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ProductUserEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ProductUser",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "Product",
                      kind: "LinkedField",
                      name: "product",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "name",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: "ModificationRecordConnection",
                      kind: "LinkedField",
                      name: "modificationRecords",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "ModificationRecordEdge",
                          kind: "LinkedField",
                          name: "edges",
                          plural: true,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "ModificationRecord",
                              kind: "LinkedField",
                              name: "node",
                              plural: false,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "enabled",
                                  storageKey: null
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: "ScalarField",
                                  name: "createdAt",
                                  storageKey: null
                                }
                              ],
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "02cd2f3f44b6345cdf9631994ba7345c";
export default node;
