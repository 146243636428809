import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  flex-grow: 1;
`;
export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  & > div {
    margin-bottom: 20px;
  }
  flex-grow: 2;
`;
export const MultiFieldRowContainer = styled.div<{
  childWidth?: string;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  justify-items: stretch;
  margin: -10px -2px 10px -2px !important;
  & > * {
    margin: 10px 2px;
    flex: 1 1 160px;
  }
`;
export const DialogTitle = styled.span`
  ${props => props.theme.fontType.visualizationTitle}
`;

export default {
  DialogContainer,
  FieldsContainer,
  MultiFieldRowContainer,
  DialogTitle
};
