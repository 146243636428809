import _ from "lodash";
import React from "react";
import {
  createPaginationContainer,
  RelayPaginationProp,
  GraphQLTaggedNode,
  ConnectionConfig
} from "react-relay";
import { ElmTable, IColumn, IElmTableProps } from "../components/elmTable";
import { EntityRefreshMap } from "components/bladeManager/entityRefreshMap";

type Readonly<T> = {
  readonly [P in keyof T]: T[P];
};
type relaySubKeyType = {
  readonly edges: ReadonlyArray<{
    readonly node: any;
  } | null> | null;
  pageInfo?: {
    hasNextPage: boolean;
  };
} | null;

type relayResultKeyType =
  | Readonly<relaySubKeyType>
  | { [k3: string]: Readonly<relaySubKeyType> | relayResultKeyType }
  | Readonly<string>
  | Readonly<number>;

type relayResultType = {
  readonly " $refType": string;
} & {
  [k2: string]: relayResultKeyType;
};

interface ITableDescription<T = any> {
  columns: IColumn[];
  dataKey: string;
  tableName?: string;
  fragmentSpec: Record<string, GraphQLTaggedNode>;
  connectionQuery: GraphQLTaggedNode;
  rowHeight?: number;
  getTotalCount?: (props: tableDefaultProps<T>) => number;
  getNodesTransform?: (props: tableDefaultProps<T>) => any[];
  isTableRowInActive?: IElmTableProps["isRowInactive"];
}
type relayType<T = any> = {
  [k1: string]: relayResultType;
};

type tableDefaultProps<T, N = any> = T &
  Pick<
    IElmTableProps,
    | "hideSearchBar"
    | "hideFooter"
    | "useDefaultAddButton"
    | "renderBeforeSearchBar"
    | "renderLeftOfSearchBar"
    | "renderRightOfSearchBar"
    | "renderEmptyTableAction"
    | "renderBeforeTable"
    | "renderAfterTable"
    | "bladeName"
    | "editableRow"
    | "onEditClick"
    | "onRowClick"
    | "renderRowActionButtons"
    | "hideColumnsWithHeaders"
    | "columnAndHeaderStyles"
    | "renderToggleColumn"
    | "noNav"
    | "rowHeight"
    | "permissions"
  >;

export interface IGeneratedTableProps extends tableDefaultProps<{}> {
  bladeScope?: string;
  setRefreshFn: (payload: () => void) => void;
  refreshKey: EntityRefreshMap;
  removeRefreshFn: (entity: EntityRefreshMap) => void;
}

const getCountToUse = (
  pageInfo: { count: number },
  fragmentVariables: { count: number }
) => {
  if (_.isObject(pageInfo)) {
    if (_.isFinite(pageInfo.count) && pageInfo.count > 0) {
      return pageInfo.count;
    }
  }
  if (_.isObject(fragmentVariables)) {
    if (_.isFinite(fragmentVariables.count) && fragmentVariables.count > 0) {
      return fragmentVariables.count;
    }
  }
  return 20;
};

function getDefaultConnectionConfig<P>(tableDescription: ITableDescription) {
  return {
    direction: "forward",
    getConnectionFromProps: (p: P) => {
      const connectionData = _.get(p, tableDescription.dataKey.split("."));
      return connectionData;
    },
    getVariables: (props: any, pageInfo: any, fragmentVariables: any) => {
      const count = getCountToUse(pageInfo, fragmentVariables);
      if (tableDescription?.tableName)
        window[tableDescription.tableName] = fragmentVariables;

      return {
        ...fragmentVariables,
        ...pageInfo,
        count
      };
    },
    query: tableDescription.connectionQuery
  } as ConnectionConfig<P>;
}

export function tableGenerator<ComponentProps extends relayType>(
  tableDescription: ITableDescription<ComponentProps>
) {
  class ElmGeneratedTable extends React.Component<
    tableDefaultProps<ComponentProps> & {
      relay: RelayPaginationProp;
    }
  > {
    public tableColumns: IColumn[] = tableDescription.columns;
    public defaultSortKey = _.first(
      tableDescription.columns.filter(item => item.hasOwnProperty("sortKey"))
    )?.sortKey;
    public defaultSortDirection = _.first(
      tableDescription.columns.filter(item =>
        item.hasOwnProperty("sortDirection")
      )
    )?.sortDirection;
    public handleTableChanges: IElmTableProps["onCriteriaChange"] = payload => {
      const count = getCountToUse(null, null);
      const tableFilterColumns = _.filter(tableDescription.columns, column =>
        _.isObject(column.filterable)
      );
      const findColumnBasedOnFilterName = (filterName: string) => {
        return _.find(
          tableFilterColumns,
          column => filterName === column.filterable.filterName
        );
      };
      const findColumnBasedOnAccesor = (header: string) => {
        return _.find(
          tableDescription.columns,
          column => header === column.accessor
        );
      };

      const sortColumn = [
        _.get(
          findColumnBasedOnAccesor(payload.sortBy || this.defaultSortKey),
          "sortKey"
        ) || payload.sortBy
      ];
      const sortDirection = [payload.sortDirection];

      let foundColumn: IColumn;
      let columnFilterOp: string[];
      let columnFilterValues: string[];
      const { filterOp, filterValues, filterColumn } = _.reduce(
        _.keys(payload.filterValues),
        (filterState, filterColumnName) => {
          foundColumn = findColumnBasedOnFilterName(filterColumnName);
          if (foundColumn?.filterable?.filterGenerator) {
            return foundColumn.filterable.filterGenerator({
              filterState,
              filterColumnSelection: payload.filterValues[filterColumnName]
            });
          }

          columnFilterOp = _.map(
            payload.filterValues[filterColumnName],
            val => "="
          );
          columnFilterValues = _.map(
            payload.filterValues[filterColumnName],
            val => val
          );
          filterState.filterOp.push(columnFilterOp);
          filterState.filterValues.push(columnFilterValues);
          filterState.filterColumn.push(foundColumn.filterable.filterName);

          return filterState;
        },
        { filterOp: [], filterValues: [], filterColumn: [] }
      );
      this.props.relay.refetchConnection(count, null, {
        count,
        filterColumn,
        filterValues,
        filterOp,
        sortColumn,
        sortDirection,
        search: payload.search_term
      });
    };

    public getResultData = () => {
      const flatData = _.get(
        this.props,
        tableDescription.dataKey.split(".")
      ) as relaySubKeyType;

      return flatData;
    };
    public hasNextPage = () => {
      const resultData = this.getResultData();
      if (_.isObject(resultData)) {
        return resultData.pageInfo.hasNextPage;
      }
      return false;
    };
    public loadMore: IElmTableProps["loadMoreItems"] = payload =>
      new Promise((res, rej) => {
        this.props.relay.loadMore(getCountToUse(null, null), res);
      });
    public getNodes = () => {
      if (_.isFunction(tableDescription.getNodesTransform)) {
        return tableDescription.getNodesTransform(this.props);
      }
      return _.map(_.get(this.getResultData(), "edges"), p => p.node);
    };
    public compare = (a: string, b: string) => {
      return a.localeCompare(b, "en");
    };
    public getFilterData = () => {
      const filterColumns = _.filter(tableDescription.columns, column =>
        _.isObject(column.filterable)
      );
      if (_.isArray(filterColumns) && filterColumns.length) {
        const filterMap = {};
        let filterData;
        let filterName;
        _.each(filterColumns, filterColumn => {
          filterName = filterColumn.filterable.filterName;
          if (_.isFunction(filterColumn.filterable.overrideData)) {
            filterData = filterColumn.filterable.overrideData();
          } else {
            filterData = _.get(
              this.props,
              filterColumn.filterable.dataKey.split(".")
            );
          }
          if (_.isArray(filterData)) {
            let filterString: string;
            filterMap[filterName] = _.flatten(
              _.map(filterData, d => {
                if (_.isFunction(filterColumn.filterable.dataMap)) {
                  filterString = filterColumn.filterable.dataMap(d);
                }
                if (_.isString(filterString) || _.isArray(filterString)) {
                  return filterString;
                }
                return "";
              })
            );
            filterMap[filterName] = _.filter(filterMap[filterName], i => i);
            filterMap[filterName] = _.uniq(filterMap[filterName]).sort((a, b) =>
              this.compare(a as string, b as string)
            );
          }
        });
        return filterMap;
      }

      return {};
    };
    public getTotalCount = () => {
      if (_.isFunction(tableDescription.getTotalCount)) {
        return tableDescription.getTotalCount(this.props);
      }
      return null;
    };
    public renderTable = () => {
      return (
        <ElmTable
          columns={this.tableColumns}
          data={this.getNodes()}
          filterData={this.getFilterData()}
          hasNextPage={this.hasNextPage()}
          loadMoreItems={this.loadMore}
          onCriteriaChange={this.handleTableChanges}
          tableName={tableDescription.tableName}
          rowHeight={tableDescription.rowHeight}
          totalCount={this.getTotalCount()}
          isRowInactive={tableDescription.isTableRowInActive}
          defaultSortBy={this.defaultSortKey || ""}
          defaultSortDirection={this.defaultSortDirection || ""}
          {...this.props}
          // onRowClick={this.props.onRowClick}
          // editableRow={this.props.editableRow}
          // onEditClick={this.props.onEditClick}
          // bladeName={this.props.bladeName}
          // hideFilter={this.props.hideFilter}
        />
      );
    };
    public render() {
      return this.renderTable();
    }
  }

  return createPaginationContainer(
    ElmGeneratedTable,
    tableDescription.fragmentSpec,
    getDefaultConnectionConfig<ComponentProps>(tableDescription)
  );
}
export default tableGenerator;
