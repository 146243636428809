import React from "react";
import _ from "lodash";
import {
  EmailNotification,
  IBladeBaseProps,
  IOpenEmailPreferencesBlade
} from "../../../../components/bladeManager/types";
import { appConnect, appDispatch } from "../../../../store/appConnect";
import styled, { useTheme } from "styled-components";
import {
  BladeSubTitle,
  BladeTemplate,
  ElmButton,
  ElmToggle
} from "../../../../components";
import { Flex } from "reflexbox";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../../../components/elmSelect";
import { BladeDescription } from "../../../../Licenses/blades/Entitlement/styles";
import ElmCheckBox from "../../../../components/elmCheckBox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const NotificationCategoryTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  min-width: 300px !important;
  margin-left: 10px;
  color: ${props => props.theme.colors.textPrimary};
`;

const notificationFrequencies = [
  "Immediately",
  "Daily digest",
  "Weekly digest"
];

export interface IEmailPreferencesBladeProps
  extends IBladeBaseProps<IOpenEmailPreferencesBlade> {
  appDispatch: ReturnType<typeof appDispatch>;
  appState: {};
}
type state = {
  error: boolean;
  disabled: boolean;
  isLoading: boolean;
  isSelectOpen: boolean;
  editedNotifications: EmailNotification[];
};
export class EmailPreferencesBlade extends React.Component<
  IEmailPreferencesBladeProps & {
    theme: any;
    retry: () => void;
  }
> {
  state: state = {
    disabled: false,
    error: false,
    isLoading: false,
    isSelectOpen: false,
    editedNotifications: this.props.routeData.emailPreferences || []
  };
  public toggleSelect = (isOpen: boolean) => {
    this.setState({ isSelectOpen: isOpen });
  };
  public renderFrequencySelect = (notification: EmailNotification) => {
    let frequencies = notification.frequency || [];
    return (
      <Flex style={{ margin: "10px" }} onClick={e => e.preventDefault()}>
        <ElmSelectWithLabel
          label="Frequency"
          placeholder="Frequency"
          style={{ flexDirection: "row" }}
          onClick={() => this.toggleSelect(true)}
          onBlur={() => this.toggleSelect(false)}
          onSelect={e => {
            if (frequencies.includes(e)) {
              frequencies.splice(
                frequencies.findIndex(item => item === e),
                1
              );
            } else {
              frequencies.push(e);
            }
            this.handleNotificationFrequenciesChange(
              notification.category,
              frequencies
            );
          }}
          // TODO - temporarily disabled because no backend implementation
          disabled={true}
          // disabled={!notification.enabled}
          value={
            frequencies.length > 0
              ? frequencies.map(item => notificationFrequencies[item]).join()
              : undefined
          }
        >
          {notificationFrequencies.map((item, index) => {
            return (
              <ElmSelectOption key={index} value={index} label={item}>
                <ElmCheckBox checked={frequencies.includes(index, 0)}>
                  {item}
                </ElmCheckBox>
              </ElmSelectOption>
            );
          })}
        </ElmSelectWithLabel>
      </Flex>
    );
  };
  public handleNotificationFrequenciesChange = (
    categoryName: string,
    frequencies: number[]
  ) => {
    const { editedNotifications } = this.state;
    const newData = editedNotifications.map(notification => {
      if (notification.category === categoryName) {
        return { ...notification, frequency: frequencies };
      }
      return notification;
    });
    this.setState({
      editedNotifications: newData
    });
  };
  public handleNotificationCategoryChange = (categoryName: string) => (
    state: boolean
  ) => {
    const { editedNotifications } = this.state;
    const newData = editedNotifications.map(notification => {
      if (notification.category === categoryName) {
        return { ...notification, enabled: state };
      }
      return notification;
    });
    this.setState({
      editedNotifications: newData
    });
  };
  public handleNotificationCategoryReasonChange = (
    categoryName: string,
    reason: string,
    value: boolean
  ) => {
    const { editedNotifications } = this.state;
    const newData = editedNotifications.map(notification => {
      if (notification.category === categoryName) {
        const newReasons = notification.reasons.map(item => {
          if (item.reason === reason) {
            return { ...item, enabled: value };
          }
          return item;
        });
        return { ...notification, reasons: newReasons };
      }
      return notification;
    });
    this.setState({
      editedNotifications: newData
    });
  };
  public renderField = (
    categoryName: string,
    categoryEnabled: boolean,
    reason: string,
    label: string,
    enabled: boolean
  ) => {
    const updateField = (reason: string, e: CheckboxChangeEvent) => {
      this.handleNotificationCategoryReasonChange(
        categoryName,
        reason,
        e.target.checked
      );
    };
    return (
      <Flex margin={12}>
        <ElmCheckBox
          onChange={e => updateField(reason, e)}
          defaultChecked={false}
          checked={enabled}
          disabled={!categoryEnabled}
        >
          {label}
        </ElmCheckBox>
      </Flex>
    );
  };
  public renderLeftAttributesBox = () => {
    const notifications = this.state.editedNotifications;
    const leftSideNotifications = [] as EmailNotification[];
    notifications.map((item, index) => {
      if (index % 2 === 0) {
        leftSideNotifications.push(item);
      }
    });
    return (
      <Flex
        flex={1}
        marginRight={20}
        flexDirection="column"
        style={{ marginBottom: "30px" }}
      >
        {leftSideNotifications.map((notification: EmailNotification) => {
          return (
            <div style={{ marginBottom: "30px" }}>
              <Flex style={{ height: "24px", marginBottom: "10px" }}>
                <NotificationCategoryTitle style={{ minWidth: "310px" }}>
                  {notification.label}
                </NotificationCategoryTitle>
                <div>
                  <ElmToggle
                    disabled={false}
                    size="small"
                    onChange={this.handleNotificationCategoryChange(
                      notification.category
                    )}
                    permissions=""
                    checked={notification.enabled}
                  />
                </div>
              </Flex>
              {this.renderFrequencySelect(notification)}
              {notification.reasons.map(reason => {
                return this.renderField(
                  notification.category,
                  notification.enabled,
                  reason.reason,
                  reason.label,
                  reason.enabled
                );
              })}
            </div>
          );
        })}
      </Flex>
    );
  };

  public renderRightAttributesBox = () => {
    const notifications = this.state.editedNotifications;
    const rightSideNotifications = [] as EmailNotification[];
    notifications.map((item, index) => {
      if (index % 2 !== 0) {
        rightSideNotifications.push(item);
      }
    });
    return (
      <Flex
        flex={1}
        marginLeft={20}
        flexDirection="column"
        style={{ marginBottom: "30px" }}
      >
        {rightSideNotifications.map((notification: EmailNotification) => {
          return (
            <div style={{ marginBottom: "30px" }}>
              <Flex style={{ height: "24px", marginBottom: "10px" }}>
                <NotificationCategoryTitle style={{ minWidth: "310px" }}>
                  {notification.label}
                </NotificationCategoryTitle>
                <div>
                  <ElmToggle
                    disabled={false}
                    size="small"
                    onChange={this.handleNotificationCategoryChange(
                      notification.category
                    )}
                    permissions=""
                    checked={notification.enabled}
                  />
                </div>
              </Flex>
              {this.renderFrequencySelect(notification)}
              {notification.reasons.map(reason => {
                return this.renderField(
                  notification.category,
                  notification.enabled,
                  reason.reason,
                  reason.label,
                  reason.enabled
                );
              })}
            </div>
          );
        })}
      </Flex>
    );
  };

  public renderLeftSideHeader = () => {
    return (
      <div
        style={{
          marginLeft: "18px",
          marginTop: "65px",
          marginBottom: "25.5px"
        }}
      >
        <BladeSubTitle>Email preferences</BladeSubTitle>
        <BladeDescription>
          Select what types of emails you want to receive
        </BladeDescription>
      </div>
    );
  };
  public handleEditNotifications = async () => {
    const { editedNotifications } = this.state;
    this.props.appDispatch.appActions.updateEmailPreferences(
      editedNotifications,
      this.props.routeData.userId?.toString(),
      () => {
        this.props.closeBlade({ route: "EmailPreferences" });
        this.props.refreshAllOpenBlades();
      }
    );
  };
  public render() {
    return (
      <BladeTemplate
        bladeIndex={1}
        closeBlade={this.props.closeBlade}
        bladeType="EmailPreferences"
        title={""}
        bladeBodyStyle={{ padding: "24px 24px" }}
        accentColor={"warmGrey"}
        bladeTypeName="Email preferences"
        renderLeftSideHeader={this.renderLeftSideHeader}
        loading={false}
      >
        <Flex
          style={{
            flexDirection: "column",
            flex: 1,
            overflow: "auto"
          }}
          marginTop={25.5}
        >
          <Flex marginTop={25.5}>
            {this.renderLeftAttributesBox()}
            {this.renderRightAttributesBox()}
          </Flex>
        </Flex>
        <Flex marginTop={40}>
          <ElmButton
            colorVariance={"outline-secondary"}
            label={"Cancel"}
            style={{ marginLeft: "0px" }}
            onClick={() =>
              this.props.closeBlade({
                route: "EmailPreferences"
              })
            }
          />
          <ElmButton label={"Save"} onClick={this.handleEditNotifications} />
        </Flex>
      </BladeTemplate>
    );
  }
}
const RenderQuery = (props: IEmailPreferencesBladeProps) => {
  const theme = useTheme();
  const renderBlade = (payload: { error: Error; retry: () => void }) => {
    return (
      <EmailPreferencesBlade
        {...props}
        theme={theme}
        {..._.omit(payload, "props")}
      />
    );
  };
  return renderBlade({ error: null, retry: () => {} });
};
export default appConnect(RenderQuery, {
  selectors: {}
});
