import { notification } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import * as _ from "lodash";
import * as React from "react";
import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { ElmButton } from "../../../elmButton";
import { DialogBase } from "../base";
import { IModalProps } from "../types";
import { InfoMessage } from "../../../../components/helpers";

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
`;
const CopyContainer = styled.div`
  height: max-content;
  padding: 5px;
  background-color: ${props => props.theme.colors.lightGrey};
  word-break: break-word;
  margin-bottom: 10px;
  margin-top: 10px;
  overflow: hidden;
  overflow-y: scroll;
  :hover {
    cursor: pointer;
  }
`;

const DialogTitle = styled.p`
  ${props => props.theme.fontType.visualizationTitle}
`;
export interface ICopyApiTokenDialogProps {
  token: string;
}

export class CopyApiTokenDialog extends DialogBase<
  IModalProps<ICopyApiTokenDialogProps>
> {
  static getModalProps() {
    return super.getModalProps({
      style: {
        content: {
          display: "flex",
          flexDirection: "column",
          minHeight: "max-content",
          borderRadius: "6px",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white"
        }
      }
    });
  }

  public closeModal = () => {
    this.props.appDispatch.bladeManagerActions.closeDialog({
      name: "CopyApiTokenDialog"
    });
  };
  public confirm = () => {
    if (_.isFunction(_.get(this.props, "payload.onConfirm"))) {
      this.props.payload.onConfirm(this.state);
    }
  };
  public getTitle = () => {
    return "Your new API token";
  };
  public getDescription = () => {
    return `Make sure you copy the token now. We don't store it and won't be able to see it again.`;
  };
  public openCopiedMessage = () => {
    notification.open({
      message: "Success",
      description: "Api token copied to clipboard."
    });
  };

  public render() {
    return (
      <DialogContainer>
        <DialogTitle>{this.getTitle()}</DialogTitle>
        <InfoMessage description={this.getDescription()} type="warning" />
        <CopyToClipboard
          text={this.props.payload ? this.props.payload.token : "N/A"}
          onCopy={this.openCopiedMessage}
        >
          <CopyContainer>
            {this.props.payload ? this.props.payload.token : "N/A"}
          </CopyContainer>
        </CopyToClipboard>
        <Flex justifyContent={"flex-end"}>
          <ElmButton label={"Ok"} onClick={this.confirm} />
        </Flex>
      </DialogContainer>
    );
  }
}
