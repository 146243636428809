/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type productReleaseConfigurationsTable_releaseConfigurations = {
  readonly product: {
    readonly releaseConfigurations: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly releasesCount: number;
        } | null;
      } | null> | null;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    } | null;
  } | null;
  readonly " $refType": "productReleaseConfigurationsTable_releaseConfigurations";
};
export type productReleaseConfigurationsTable_releaseConfigurations$data = productReleaseConfigurationsTable_releaseConfigurations;
export type productReleaseConfigurationsTable_releaseConfigurations$key = {
  readonly " $data"?:
    | productReleaseConfigurationsTable_releaseConfigurations$data
    | undefined;
  readonly " $fragmentRefs": FragmentRefs<
    "productReleaseConfigurationsTable_releaseConfigurations"
  >;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      defaultValue: 6,
      kind: "LocalArgument",
      name: "count"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "cursor"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "id"
    },
    {
      defaultValue: null,
      kind: "LocalArgument",
      name: "search"
    }
  ],
  kind: "Fragment",
  metadata: {
    connection: [
      {
        count: "count",
        cursor: "cursor",
        direction: "forward",
        path: ["product", "releaseConfigurations"]
      }
    ]
  },
  name: "productReleaseConfigurationsTable_releaseConfigurations",
  selections: [
    {
      alias: null,
      args: [
        {
          kind: "Variable",
          name: "id",
          variableName: "id"
        }
      ],
      concreteType: "Product",
      kind: "LinkedField",
      name: "product",
      plural: false,
      selections: [
        {
          alias: "releaseConfigurations",
          args: [
            {
              kind: "Variable",
              name: "search",
              variableName: "search"
            }
          ],
          concreteType: "ReleaseConfigurationConnection",
          kind: "LinkedField",
          name:
            "__productReleaseConfigurationsTable_releaseConfigurations_connection",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ReleaseConfigurationEdge",
              kind: "LinkedField",
              name: "edges",
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ReleaseConfiguration",
                  kind: "LinkedField",
                  name: "node",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "id",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "name",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "releasesCount",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "__typename",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "cursor",
                  storageKey: null
                }
              ],
              storageKey: null
            },
            {
              alias: null,
              args: null,
              concreteType: "PageInfo",
              kind: "LinkedField",
              name: "pageInfo",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "hasNextPage",
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  kind: "ScalarField",
                  name: "endCursor",
                  storageKey: null
                }
              ],
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: "Query",
  abstractKey: null
} as any;
(node as any).hash = "64dbdc3d13e8922699bab84faa1fb17a";
export default node;
