/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userProductHistoryTableQueryVariables = {
  id: string;
};
export type userProductHistoryTableQueryResponse = {
  readonly " $fragmentRefs": FragmentRefs<"userProductHistoryTable_history">;
};
export type userProductHistoryTableQuery = {
  readonly response: userProductHistoryTableQueryResponse;
  readonly variables: userProductHistoryTableQueryVariables;
};

/*
query userProductHistoryTableQuery(
  $id: ID!
) {
  ...userProductHistoryTable_history_1Bmzm5
}

fragment userProductHistoryTable_history_1Bmzm5 on Query {
  history: user(id: $id) {
    productUsers(first: 25) {
      edges {
        node {
          product {
            name
            id
          }
          modificationRecords {
            edges {
              node {
                enabled
                createdAt
              }
            }
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = [
      {
        kind: "Literal",
        name: "first",
        value: 25
      } as any
    ],
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "userProductHistoryTableQuery",
      selections: [
        {
          args: v1 /*: any*/,
          kind: "FragmentSpread",
          name: "userProductHistoryTable_history"
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "userProductHistoryTableQuery",
      selections: [
        {
          alias: "history",
          args: v1 /*: any*/,
          concreteType: "User",
          kind: "LinkedField",
          name: "user",
          plural: false,
          selections: [
            {
              alias: null,
              args: v2 /*: any*/,
              concreteType: "ProductUserConnection",
              kind: "LinkedField",
              name: "productUsers",
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: "ProductUserEdge",
                  kind: "LinkedField",
                  name: "edges",
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: "ProductUser",
                      kind: "LinkedField",
                      name: "node",
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: "Product",
                          kind: "LinkedField",
                          name: "product",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              kind: "ScalarField",
                              name: "name",
                              storageKey: null
                            },
                            v3 /*: any*/
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: "ModificationRecordConnection",
                          kind: "LinkedField",
                          name: "modificationRecords",
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: "ModificationRecordEdge",
                              kind: "LinkedField",
                              name: "edges",
                              plural: true,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  concreteType: "ModificationRecord",
                                  kind: "LinkedField",
                                  name: "node",
                                  plural: false,
                                  selections: [
                                    {
                                      alias: null,
                                      args: null,
                                      kind: "ScalarField",
                                      name: "enabled",
                                      storageKey: null
                                    },
                                    {
                                      alias: null,
                                      args: null,
                                      kind: "ScalarField",
                                      name: "createdAt",
                                      storageKey: null
                                    }
                                  ],
                                  storageKey: null
                                }
                              ],
                              storageKey: null
                            }
                          ],
                          storageKey: null
                        },
                        {
                          alias: null,
                          args: null,
                          kind: "ScalarField",
                          name: "__typename",
                          storageKey: null
                        }
                      ],
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "cursor",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                },
                {
                  alias: null,
                  args: null,
                  concreteType: "PageInfo",
                  kind: "LinkedField",
                  name: "pageInfo",
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "endCursor",
                      storageKey: null
                    },
                    {
                      alias: null,
                      args: null,
                      kind: "ScalarField",
                      name: "hasNextPage",
                      storageKey: null
                    }
                  ],
                  storageKey: null
                }
              ],
              storageKey: "productUsers(first:25)"
            },
            {
              alias: null,
              args: v2 /*: any*/,
              filters: [
                "search",
                "sortColumn",
                "sortDirection",
                "filterColumn",
                "filterOp",
                "filterValues"
              ],
              handle: "connection",
              key: "userProductHistoryTable_productUsers",
              kind: "LinkedHandle",
              name: "productUsers"
            },
            v3 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "3cdfbc8910a0bd0c55994fc2c7845eb0",
      id: null,
      metadata: {},
      name: "userProductHistoryTableQuery",
      operationKind: "query",
      text:
        "query userProductHistoryTableQuery(\n  $id: ID!\n) {\n  ...userProductHistoryTable_history_1Bmzm5\n}\n\nfragment userProductHistoryTable_history_1Bmzm5 on Query {\n  history: user(id: $id) {\n    productUsers(first: 25) {\n      edges {\n        node {\n          product {\n            name\n            id\n          }\n          modificationRecords {\n            edges {\n              node {\n                enabled\n                createdAt\n              }\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "95f78427cd50601e451e0561e312fc61";
export default node;
