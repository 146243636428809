import React, { useEffect, useState } from "react";
import { Flex } from "reflexbox";
import styled, { useTheme } from "styled-components";
import {
  ElmSelectOption,
  ElmSelectWithLabel
} from "../../components/elmSelect";
import { getIcon } from "../../components/icons";
import { getAnimatedComponent } from "../../components/helpers";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import { actions } from "../../store/ducks/analytics";
import { useSelector, useDispatch } from "react-redux";
import { ElmButton } from "../../components";
import LoadingWrapper from "../../components/elmChart/loadingWrapper";
import { Drawer } from "antd";
import { IFilterState } from "./Dashboard";
import { DateRange } from "./utils";
import { SidebarFilterQuery } from "./__generated__/SidebarFilterQuery.graphql";

const graphqlQuery = graphql`
  query SidebarFilterQuery {
    allProducts: products {
      nodes {
        name
        id
      }
    }
    allCompanies: companies {
      nodes {
        name
        id
      }
    }
    allUsers: users {
      nodes {
        displayName
        id
      }
    }
    # allVersions: productVersions {
    #   nodes{
    #     versionString
    #   }
    # }
  }
`;

const SideBarHeader = styled.span`
  font-size: ${props => props.theme.fontSizes.small};
  marginbottom: 10px;
`;

const closeIcon = getIcon("times", "fal");
const CloseIcon = styled(closeIcon)`
  width: 18px;
  height: 14px;
  transform: translateY(-1px);
  cursor: pointer;
`;

interface IFilterSidebar {
  closeFilterSidebar: () => void;
  isOpen: boolean;
  dashboardFilters: IFilterState;
  setDashboardFilters: React.Dispatch<React.SetStateAction<IFilterState>>;
}

interface IFilterData {
  onChange: (value: any, type: string) => void;
  filters: { [key: string]: DateRange };
}

const AnimatedContainer = getAnimatedComponent({
  element: "div",
  transitionName: "dashboard-filters",
  animations: {
    appear: {
      duration: "0.5s",
      animationType: "slideInRight"
    },
    enter: {
      duration: "0.5s",
      animationType: "slideInRight"
    }
  }
});

const FilterData: React.FC<IFilterData> = ({ onChange, filters }) => {
  const data = useLazyLoadQuery<SidebarFilterQuery>(graphqlQuery, {});

  const companies =
    data?.allCompanies?.nodes?.toSorted((a, b) =>
      a.name.localeCompare(b.name)
    ) || [];
  const products =
    data?.allProducts?.nodes?.toSorted((a, b) =>
      a.name.localeCompare(b.name)
    ) || [];
  const users =
    // temporarily disabled // data?.allProducts?.nodes?.toSorted((a, b) => a.name.localeCompare(b.name)) ||
    [];

  return (
    <>
      <ElmSelectWithLabel
        onChange={val => onChange(val, "product")}
        style={{ marginBottom: "20px" }}
        className="subtle"
        label="Product"
        placeholder="Select product"
        value={filters?.product}
        //value={this.state.overageType}
      >
        <ElmSelectOption value={"-1"} label={"All"}>
          All
        </ElmSelectOption>
        {products?.map(op => (
          <ElmSelectOption value={op.name} label={op.name} key={op.id}>
            {op.name}
          </ElmSelectOption>
        ))}
      </ElmSelectWithLabel>

      <ElmSelectWithLabel
        onChange={val => onChange(val, "company")}
        style={{ marginBottom: "20px" }}
        className="subtle"
        label="Companies"
        placeholder="Select company"
        value={filters?.company}
      >
        <ElmSelectOption value={"-1"} label={"All"}>
          All
        </ElmSelectOption>
        {companies?.map(op => (
          <ElmSelectOption value={op.name} label={op.name} key={op.id}>
            {op.name}
          </ElmSelectOption>
        ))}
      </ElmSelectWithLabel>

      <ElmSelectWithLabel
        onChange={val => onChange(val, "user")}
        style={{ marginBottom: "20px" }}
        className="subtle"
        label="Users"
        placeholder="Select user"
        value={filters?.user}
        disabled
      >
        <ElmSelectOption value={"-1"} label={"All"}>
          All
        </ElmSelectOption>
        {users?.map(op => (
          <ElmSelectOption value={op.id} label={op.displayName} key={op.id}>
            {op.displayName}
          </ElmSelectOption>
        ))}
      </ElmSelectWithLabel>

      <ElmSelectWithLabel
        style={{ marginBottom: "20px" }}
        className="subtle"
        label="Timeframe"
        placeholder="Set timeframe"
        onChange={val => onChange(val, "timerange")}
      >
        <ElmSelectOption value="last24" label="Last 24h">
          Last 24h
        </ElmSelectOption>
        <ElmSelectOption value="days7" label="Last week">
          Last week
        </ElmSelectOption>
        <ElmSelectOption value="month1" label="Last month">
          Last month
        </ElmSelectOption>
        <ElmSelectOption value="months3" label="Last quarter">
          Last quarter
        </ElmSelectOption>
        <ElmSelectOption value="months6" label="Last 6 months">
          Last 6 months
        </ElmSelectOption>
        <ElmSelectOption value="year1" label="Last year">
          Last year
        </ElmSelectOption>
        <ElmSelectOption value="year2" label="Last 2 years">
          Last 2 years
        </ElmSelectOption>
      </ElmSelectWithLabel>
    </>
  );
};

function SidebarFilter({
  closeFilterSidebar,
  isOpen,
  dashboardFilters,
  setDashboardFilters
}: IFilterSidebar) {
  const theme = useTheme();
  const [filters, setFilters] = useState<Record<string, DateRange>>({
    product: "-1",
    company: "-1",
    user: "-1",
    timerange: "-1"
  });

  // useEffect(()  => {
  //   isOpen && setOpen(true);
  // }, [isOpen])

  // const [open, setOpen] = useState(isOpen);

  const onFilterChange = (value: any, type: string) => {
    const filterUpdated = { ...filters, [type]: value };
    setFilters(filterUpdated);
  };

  const dispatch = useDispatch();

  const applyFilters = () => {
    const activeFilters = { ...dashboardFilters };
    if (filters.timerange && filters.timerange !== "-1") {
      Object.keys(dashboardFilters).forEach(key => {
        activeFilters[key] = filters.timerange;
      });
      setDashboardFilters(activeFilters);
    }
    dispatch(actions.updateFilter(filters));
    closeFilterSidebar();
  };

  const onClose = () => {
    closeFilterSidebar();
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      open={isOpen}
      key="right"
      contentWrapperStyle={{
        width: "256px"
      }}
      style={{
        backgroundColor: theme.colors.bladeContainer,
        color: theme.colors.textPrimary
      }}
    >
      <AnimatedContainer>
        <Flex style={{ width: "100%" }} flexDirection={"column"}>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ marginBottom: "26px", height: "22px" }}
          >
            <SideBarHeader>Dashboard filters</SideBarHeader>
            <ElmButton
              variance="icon-button"
              label=""
              colorVariance="subtle"
              icon="xmark"
              alt="Close"
              style={{ color: theme.colors.textPrimary }}
              onClick={closeFilterSidebar}
            />
          </Flex>
          <LoadingWrapper>
            <FilterData onChange={onFilterChange} filters={filters} />
          </LoadingWrapper>
        </Flex>
        <Flex style={{ marginTop: "48px" }} justifyContent="flex-end">
          <ElmButton
            colorVariance={"outline-secondary"}
            onClick={closeFilterSidebar}
            label="Cancel"
          ></ElmButton>
          <ElmButton
            onClick={applyFilters}
            label="Apply"
            variance={"primary"}
          ></ElmButton>
        </Flex>
      </AnimatedContainer>
    </Drawer>
  );
}

export default SidebarFilter;
