import React from "react";
import _ from "lodash";
import { ElmFilterSelect, ElmSelectOption } from "../../elmSelect";

export type topCounts = "5" | "10" | "20";

const options = [
  {
    label: "Top 5",
    key: "5"
  },
  { label: "Top 10", key: "10" },
  { label: "Top 20", key: "20" }
] as { label: string; key: topCounts }[];
export interface IFilterProps {
  defaultCount?: topCounts;
  onChange?: (payload: topCounts) => void;
}
export class TopCountFilter extends React.Component<
  IFilterProps,
  {
    count?: topCounts;
  }
> {
  state = {
    count: this.props.defaultCount || "5"
  };
  public getOption = () => {
    return _.find(options, t => t.label === this.state.count);
  };
  public handleChange = (count: any) => {
    this.setState({ count }, () => {
      if (_.isFunction(this.props.onChange)) {
        this.props.onChange(this.state.count);
      }
    });
  };

  public render() {
    return (
      <ElmFilterSelect
        onChange={this.handleChange}
        value={this.state.count}
        className={"filterBorder"}
        dropdownMatchSelectWidth={false}
      >
        {_.map(options, option => {
          return (
            <ElmSelectOption key={option.key}>{option.label}</ElmSelectOption>
          );
        })}
      </ElmFilterSelect>
    );
  }
}
