import * as _ from "lodash";
import * as React from "react";
import { Switch } from "antd";
import Toggle from "react-toggle";
import styled from "styled-components";
import "react-toggle/style.css";
import { SwitchProps } from "antd/lib/switch";
import { AuthorizedComponent } from "../helpers";

type IElmToggleProps = {
  rightLabel?: string | React.ReactNode;
  leftLabel?: string | React.ReactNode;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (state: boolean) => void;
  switchStyle?: React.CSSProperties;
  permissions?: string | string[];
} & SwitchProps;

const ToggleLabel = styled.span<{ active?: boolean }>`
  // margin-right: 3px;
  // margin-left: 3px;
  color: ${props => props.theme.colors.black};
  font-weight: ${props => (props.active ? "bold" : "normal")};
  font-size: 13px;
`;

const StyledToggle = styled(Toggle)`
  &&.react-toggle {
    margin-left: 5px;
    margin-right: 5px;
  }
  .react-toggle-track {
    background-color: white;
    border: solid 3px ${props => props.theme.colors.black};
    width: 22.5px;
    height: 13.8px;
  }

  .react-toggle-thumb {
    background-color: ${props => props.theme.colors.black};
    width: 9.3px;
    height: 9.3px;
    top: 5.9px;
    left: 5.3px;
  }

  &&.react-toggle--focus .react-toggle-thumb {
    box-shadow: none;
  }

  &&.react-toggle--checked {
    .react-toggle-thumb {
      background-color: white;
      border-color: white;
      left: 14.3px;
    }
    .react-toggle-track {
      background-color: ${props => props.theme.colors.lightishBlue};
      border: solid 3px ${props => props.theme.colors.lightishBlue};
    }
  }

  &&.react-toggle:hover:not(.react-toggle--disabled) {
    .react-toggle-track {
      background-color: white;
    }
    .react-toggle-thumb {
      background-color: ${props => props.theme.colors.black};
    }
  }
  &&.react-toggle--checked:hover:not(.react-toggle--disabled) {
    .react-toggle-track {
      background-color: ${props => props.theme.colors.lightishBlue};
    }
    .react-toggle-thumb {
      background-color: white;
    }
  }
`;
const StyledSwitch = styled(Switch)`
  &.ant-switch:hover {
    opacity: 1;
  }
  &.ant-switch:not(.ant-switch-checked) {
    background-color: ${props => props.theme.colors.elmToggleBackground};
  }
  &.ant-switch-checked {
    background-color: ${props => props.theme.colors.lightishBlue};
  }
  &.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: ${props => props.theme.colors.lightishBlue};
  }
  &.ant-switch.ant-switch-small .ant-switch-handle {
    height: 10px;
    width: 10px;
    top: 3px;
  }
  &.ant-switch-small {
    width: 32px;
    height: 16px;
    padding: 0px;
    ::after {
      width: 10px;
      height: 10px;
      left: calc(50% - 10px / 2 - 8px);
      top: calc(50% - 10px / 2);
      position: absolute;
    }

    &.ant-switch-checked::after {
      left: calc(50% + 14px);
      top: calc(50% - 10px / 2);
    }
  }
`;
const ToggleComponent = (props: IElmToggleProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (_.isFunction(props.onChange)) {
      props.onChange(e.target.checked);
    }
  };
  const onSwitchChange = (checked: boolean) => {
    if (_.isFunction(props.onChange)) {
      props.onChange(checked);
    }
  };

  const getSwitch = () => {
    return (
      <StyledSwitch
        onChange={onSwitchChange}
        size={props.size}
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        disabled={props.disabled}
        style={props.switchStyle}
        loading={props.loading}
      />
    );
  };
  const getToggle = () => {
    return (
      <StyledToggle
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        onChange={handleChange}
        icons={false}
      />
    );
  };
  return (
    <label
      style={{
        display: "flex",
        marginBottom: "7px",
        alignItems: "center",
        justifyContent: "space-between"
      }}
      className="elm-toggle-container"
    >
      {props.leftLabel ? (
        <ToggleLabel
          style={{ marginRight: "8px" }}
          className="pre-label"
          active={!props.checked}
        >
          {props.leftLabel}
        </ToggleLabel>
      ) : null}
      {getSwitch()}
      {props.rightLabel ? (
        <ToggleLabel
          style={{ marginLeft: "8px" }}
          className="suffix-label"
          active={!!props.checked}
        >
          {props.rightLabel}
        </ToggleLabel>
      ) : null}
    </label>
  );
};

const AuthorizationWrapper = (props: IElmToggleProps) => (
  <AuthorizedComponent<IElmToggleProps>
    containerProps={props}
    Component={ToggleComponent}
  />
);

const ElmToggle = AuthorizationWrapper;

export { ElmToggle };
