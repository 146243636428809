/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type productAttributeQueryVariables = {
  id: string;
};
export type productAttributeQueryResponse = {
  readonly product: {
    readonly type: {
      readonly name: string;
    };
    readonly name: string | null;
    readonly guid: string;
    readonly publicKey: string | null;
    readonly supportEmail: string | null;
    readonly usersCount: number;
    readonly licenseeCount: number;
    readonly totalSeats: number;
    readonly productVersionsCount: number;
  } | null;
};
export type productAttributeQuery = {
  readonly response: productAttributeQueryResponse;
  readonly variables: productAttributeQueryVariables;
};

/*
query productAttributeQuery(
  $id: ID!
) {
  product(id: $id) {
    type {
      name
      id
    }
    name
    guid
    publicKey
    supportEmail
    usersCount
    licenseeCount
    totalSeats
    productVersionsCount
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: "LocalArgument",
        name: "id"
      } as any
    ],
    v1 = [
      {
        kind: "Variable",
        name: "id",
        variableName: "id"
      } as any
    ],
    v2 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "name",
      storageKey: null
    } as any,
    v3 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "guid",
      storageKey: null
    } as any,
    v4 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "publicKey",
      storageKey: null
    } as any,
    v5 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "supportEmail",
      storageKey: null
    } as any,
    v6 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "usersCount",
      storageKey: null
    } as any,
    v7 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "licenseeCount",
      storageKey: null
    } as any,
    v8 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "totalSeats",
      storageKey: null
    } as any,
    v9 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "productVersionsCount",
      storageKey: null
    } as any,
    v10 = {
      alias: null,
      args: null,
      kind: "ScalarField",
      name: "id",
      storageKey: null
    } as any;
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Fragment",
      metadata: null,
      name: "productAttributeQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ProductCategory",
              kind: "LinkedField",
              name: "type",
              plural: false,
              selections: [v2 /*: any*/],
              storageKey: null
            },
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            v9 /*: any*/
          ],
          storageKey: null
        }
      ],
      type: "Query",
      abstractKey: null
    },
    kind: "Request",
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: "Operation",
      name: "productAttributeQuery",
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: "Product",
          kind: "LinkedField",
          name: "product",
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: "ProductCategory",
              kind: "LinkedField",
              name: "type",
              plural: false,
              selections: [v2 /*: any*/, v10 /*: any*/],
              storageKey: null
            },
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            v9 /*: any*/,
            v10 /*: any*/
          ],
          storageKey: null
        }
      ]
    },
    params: {
      cacheID: "b2b334fce44ae39255755ca77274009b",
      id: null,
      metadata: {},
      name: "productAttributeQuery",
      operationKind: "query",
      text:
        "query productAttributeQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    type {\n      name\n      id\n    }\n    name\n    guid\n    publicKey\n    supportEmail\n    usersCount\n    licenseeCount\n    totalSeats\n    productVersionsCount\n    id\n  }\n}\n"
    }
  } as any;
})();
(node as any).hash = "28a4216309ce9ac5f68187570e6190a1";
export default node;
